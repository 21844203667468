const english = {
  attentionsTerms: {
    mainHeader: 'by accepting this you agree for our terms and policy.',
    firstTerm: '1. You approve for store your CV-related information, including images in your device’s storage unit and automatically delete them after you complete your CV',
    firstTermNecessary: '(Necessary to complete the CV creation service)',
    sec2Term: '2. You approve for storing information related to your CV permanently enables you to continue creating your CV at any time',
    notAcceptBtn: 'Not-Accept',
    acceptBtn: 'Accept'
  },
  section1: {
    nav: {
      homeBtn: "Home",
      aboutBtn: "About",
      profileBtn: "Profile",
      loginBtn: "Login",
      logoutBtn: "Logout",
    },
    makeYourTxt: "Make Your",
    yourCV: "Custome CV",
    nowBtn: "Now",
    ready4u: "20+ creatively-desigend CV templets ready for you",
    langWarning: "The chosen language will be used to create the CV file"
  },
  section2: {
    showMoreBtn: "Show More",
    allDesATSchecked: "All designs have been tested on the ATS system",
    getCvInfo: {
      forms: {
        form1: {
          fullName: { lbl: "Full Name", holder: "full name" },
          email: { lbl: "Email", holder: "email address" },
          phone: { lbl: "Phone", holder: "phone number" },
          address: {
            lbl: "Address",
            holder: { country: "country", city: "city" },
          },
          nationality: { lbl: "Nationality", holder: "nationality" },
          placeBirth: { lbl: "Place Of Birth", holder: "place of birth" },
          birthDate: {
            lbl: "Birth Date",
            holder: { day: "day", month: "month", year: "year" },
          },
        },
        form11: {
          gender: { lbl: "Gender", holder: { male: "Male", female: "Female" } },
          materialStatus: {
            lbl: "Material Status",
            holder: { single: "Single", marred: "Marred" },
          },
          jobTitle: { lbl: "Job Title", holder: "job title" },
          intro: { lbl: "Introduction", holder: "introduction" },
        },
        form2: {
          eduDegree: { lbl: "Education Degree", holder: "education degree" },
          schoolName: { lbl: "School Name", holder: "university name" },
          yearsStudy: {
            lbl: "Years Study",
            holder: { from: "from", to: "to" },
          },
          disc: {
            lbl: "Description",
            holder: "write aboute your professional qualifications",
          },
        },
        skillsLangsForm: {
          skillsLbl: "Skills",
          skillHolder: "skill",
          langsLbl: "Languages",
          langHolder: "language",
          level1: "Working knowledge",
          level2: "Good command",
          level3: "Proficient",
          level4: "Native speaker",
        },
        addInfoForm: {
          addMoreLbl: "add more info ?",
          addWork: "Work experience",
          addLink: "Website Link",
          addThesis: "thesis topic",
          addCourse: "courses",
          ifNotSave: "if not just press save",
        },
        addWorkForm: {
          jobTitle: { lbl: "Job Title", holder: "eg. sales representative" },
          empName: { lbl: "Employer Name", holder: "company name" },
          address: { lbl: "Address", holder: "city/town" },
          date: { lbl: "Work Date", from: "from", to: "to" },
        },
        addLinkForm: {
          linksLbl: "Links",
          linkHolder: "web title",
          linkURLHolder: "URL",
        },
        addThesisForm: {
          thesisTopic: "Thesis Topic",
          thesisHolder: "thesis topic title",
        },
        addCourseForm: {
          courseTitleLbl: "Course Title",
          courseTitleHolder: "eg. javaFx course",
          courseInstLbl: "Institution",
          courseInstHolder: "name of the institute",
        },
        btnGoNextLbl: "Go Next",
        btnFinish: "Save And Finish",
        paymentForm: {
          spanFree: "you can edit your cv FREE any time after login.",
          continueAnchor: "continue with ",
          price: '$3.75'
        },
      },
      frame: {
        mainTitle: {
          details: "Details",
          education: "Education",
          skills: "Skills",
          workExeperince: "Work Exeperince",
          websiteLinks: "Website Links",
          languages: "Languages",
          contacts: "Contacts",
        },
        city: "city",
        country: "country",
        fullName: "Full Name",
        jobTitle: "Job Title",
        intro:
          "mention your goals and what you are looking for, also talk about achievements and ambitions",
        nationality: "Nationality",
        placeBirth: "Place of birth",
        birthDate: "Birthdate",
        gender: "Gender",
        materialStatus: "Material status",
        degreeOf: "Degree of",
        educationDegree: "Education Degree",
        universityName: " University Name",
        thesisTopic: "Thesis Topic",
        courses: "Courses",
        courseFrom: "from",
        comma: ",",
        addSkill: "add one skill at least",
        nativeLanguage: "Native language",
        linguisticLevel: "Linguistic level",
        resumeValues: {
          fullName: "Osama Alser Nouri",
          address: {
            country: "Saudi Arabia",
            city: "Madinah",
          },
          phone: "0123456789",
          email: "osama2025@gmail.com",
          nationality: " Arabian",
          placeOfBirth: "North Afica",
          dateOfBirth: {
            day: "20",
            month: "4",
            year: "1999",
          },
          gender: "Male",
          relationship: "Single",
          jobTitle: "Information Technology Technician",
          introParagraph: `I have acquired the skill of working on
   software projects (group and individual work). I can deal with errors
   and software problems adapt to plan changes or learn a new tool
   quickly if necessary. In recent years I have focused my work on
   developing websites, mobile applications and desktop applications
   (fullstack). I have some work you can check out.`,
          eduForm: {
            eduDegree: "Bachelor (HONOURS) OF INFORMATION TECHNOLOGY ",
            uniName: "Blue Nile University of Scince and Technology",
            cerLevel: ".",
            yearsStudy: {
              from: { month: "Nov", year: "2017" },
              to: { month: "Mar", year: "2023" },
            },
            thesis:
              "sgin language generation using machine learning technology.",
            photo: {},
            eduDiscreption: `and software problems adapt to plan changes or learn a new tool
   quickly if necessary. In recent years I have focused my work on
   developing websites, mobile applications and desktop applications`,
          },
          skillsForm: {
            princ: { name: "JavaScript" },
            childs: [
              { name: "HTML, CSS" },
              { name: "Bootstrap5" },
              { name: "ExpressJS, MongoDB, MySQL" },
              { name: "Java, JavaFX" },
              { name: "Flutter" },
            ],
          },
          langsForm: {
            princ: { name: "Arabic", level: "Native Speaker" },
            childs: [{ name: "English", level: "Very good command" }],
          },
          workExpForm: [
            {
              jobTitle: "Security Approvals",
              address: "KSA , Madinah",
              employer: "Whites Moons",
              workDate: {
                from: { month: "jun", year: "2023" },
                to: { month: "jun", year: "2024" },
              },
            },
            {
              jobTitle: "Security Approvals",
              address: "KSA , Madinah",
              employer: "Whites Moons",
              workDate: {
                from: { month: "jun", year: "2023" },
                to: { month: "jun", year: "2024" },
              },
            },
          ],
          coursesForm: [
            { courseTitle: "java", institution: "youtube" },
            { courseTitle: "javaScript", institution: "youtube2" },
          ],
          webLinks: {
            princ: {
              webTitle: " Buying system project ",
              // webHref:
              //   "https://drive.google.com/file/d/1PGvMBadcOs05hsie1zwEQmZAwBWZfdx1/view",
            },
            childs: [
              {
                webTitle: "Online buying system project",
                // webHref:
                //   "https://drive.google.com/file/d/18LBGD-PXRPzyAe0qbu-niaoXNwGfzoZy/view",
              },
              {
                webTitle: "Educational content system",
                // webHref:
                //   "https://drive.google.com/file/d/1NI1ueB8YwGq4W2opO_zSf4kodAiLP915/view",
              },
              {
                webTitle: "Motorcycle rental project",
                // webHref:
                //   "https://drive.google.com/file/d/1e3UoVB9y9XvO1VSaRdF34pHMwXlhiCt3/view?usp=sharing",
              },
            ],
          },
        },
      },
    },
  },
  section3: {
    signInToEdit: "Login to edit your CV any time",
    or: "Or",
    registerBtn: "Register",
    accountCreated: "account created, login...",
    passwordChanged: "password changed, login...",
    formLogin: {
      email: "Email",
      password: "Password",
      loginBtn: "Login",
      passwordOrEmailNotCorrect: "password or email not correct, ",
      forgetPassword: "forget password ?",
    },
    formRegister: {
      name: "Full Name",
      email: "Email",
      password: "Password",
      reenterPassword: "Reenter Password",
      registerBtn: "Register",
      allrradyHaveTxt: "alrrady have acount ?",
      passwordsNotMatch: "passwords not match",
      emailAllreadyExist: "email allready exist",
    },
    formRegisterConfirm: {
      codeInput: "Enter verfication code",
      confirmBtn: "confirm",
      dontReciveCode: "don't recive number ?",
    },
    formResetPass: {
      password: "New password",
      reenterPassword: "Reenter new password",
      passwordsNotMatch: "passwords not match",
      saveBtn: "Save New Password",
    },
    formConfirmResSetPass: {
      codeInput: "Enter verfication code",
      confirmBtn: "confirm",
      dontReciveCode: "don't recive number ?",
    },
  },
  section4: {
    termsPolicy: {
      title: 'Terms & Policy',
      userPrivacy: 'User privacy',
      conditions: 'Storing and retrieving data',
      termsConditions: 'Terms and Conditions',
      readMore: 'Read more'
    },
    contacts: {
      title: 'Contact Us'
    },
    services: {
      title: 'Services',
      makeAsome: 'Create a CV file',
      allTimeFreeEdit: 'All time free edit',
      freeCreateAccount: 'Free create account',
      freePrint: 'All time free print CV file'
    }
  },
  termsPolicy: {
    termsTitle: 'Terms and policy',
    terms:
      [
        "A user's personal information is stored only after he or she creates an account",
        "Data related to the CV is stored for the purpose of re-use by the same user",
        "Any data related to a user who has not logged into his account will not be stored",
        "Personal data such as e-mail is only for the purpose of verifying the user’s identity, such as verification during the login process or verification when requesting a new password. It will not be used for any other purpose and will not be used for advertising and marketing purposes.",
        "Information related to the CV, including personal photos, is kept for the purpose of serving the user and enabling him to print his CV that he had previously created, amend it, and print the modified version for free at any time, and it will not be published, tampered with, or viewed by any other party. For any other purpose.",
        "If the user deletes any previously created CV file, the data related to the deleted file will be deleted from the database immediately, and a copy of this data will never be kept.",
        "General data is stored in the browser's local cache for the purpose of user experience, such as data related to choosing the user's preferred language",
        "The data used to create the CV is stored in the browser’s local memory for the purpose of quality use, such as using the data entered by the user last time in order to facilitate the process of modifying and completing the creation of his CV.",
        "Resume data will not be stored if the user does not log in, and he will not have the ability to reprint or edit for free",
        "After logging in, the user has the right to reprint or modify his previously created CV at any time and more than once for free.",
        "The data entered by the user for the purpose of creating a CV is data that belongs to the user only. We do not restrict, monitor, or track it, and the user bears full responsibility for writing it on his CV.",
        "The process of paying and receiving money takes place through a reliable financial party. We do not have the right to retain or even view any financial data related to the user for any reason whatsoever.",
        "If the design you obtained does not match the design you chose, or there is a problem with the format, or the data is not clear due to a technical malfunction, you can contact us to review the error.",
        "Information regarding the payment process will be stored for a temporary period after which it will be deleted",
        "If we decide to close the site, each subscriber will be notified so that he can print his files if he wishes",
        "Prices are subject to change and free services are for limited periods"

      ],
    close: 'Close'
  },
  donePayment: {
    downloading: "Downloading..",
    thank: "thank you",
  },
  profile: {
    helloCustomer: "Hello ",
    addFrameBtn: "add new cv file",
    deleteBtn: 'delete account',
    deleting: 'deleting...'
  },
};
const arabic = {
  attentionsTerms: {
    mainHeader: 'بقبولك هذا فإنك توافق على شروطنا وسياستنا',
    firstTerm: '1. أنت توافق على تخزين المعلومات المتعلقة بسيرتك الذاتية، بما في ذلك الصور في وحدة تخزين جهازك وحذفها تلقائيًا بعد إكمال سيرتك الذاتية ',
    firstTermNecessary: ' (ضرورية لإكمال خدمة إنشاء السيرة الذاتية).',
    sec2Term: ' 2. انت توافق على تخزين المعلومات المتعلقة بسيرتك الذاتية بشكل دائم مما يتيح لك الإستمرار في إنشاء سيرتك الذاتية في أي وقت',
    notAcceptBtn: 'لا أوافق',
    acceptBtn: 'أوافق',
  },
  section1: {
    nav: {
      homeBtn: "الرئيسية",
      aboutBtn: "تواصل",
      profileBtn: "الشخصية",
      loginBtn: "تسجيل الدخول",
      logoutBtn: "تسجيل الخروج",
    },
    makeYourTxt: "قـم بإنشــاء",
    yourCV: "سيــرتك الـذاتيـة",
    nowBtn: "الآن",
    ready4u: "أكثر من 20 نموذجًا للسيرة الذاتية مصممة بشكل إبداعي جاهزة لك",
    langWarning: "اللغة التي يتم إختيارها ستستخدم لإنشاء ملف السيرة الذاتية"
  },
  section2: {
    showMoreBtn: "رؤية المزيـد",
    allDesATSchecked: "تم اختبار جميع التصاميم على نظام ATS",
    getCvInfo: {
      forms: {
        form1: {
          fullName: { lbl: "الأسم بالكامل", holder: "قم بإدخال الأسم بالكامل" },
          email: { lbl: "عنوان البريد", holder: "أدخل عنوان البريد" },
          phone: { lbl: "رقم الهاتف", holder: "ادخل رقم هاتفك" },
          address: {
            lbl: "العنوان",
            holder: { country: "دولة", city: "مدينة" },
          },
          nationality: { lbl: "الجنسية", holder: "الجنسية" },
          placeBirth: { lbl: "مكان الميلاد", holder: "مكان الميلاد" },
          birthDate: {
            lbl: "تاريخ الميلاد",
            holder: { day: "اليوم", month: "الشهر", year: "السنة" },
          },
        },
        form11: {
          gender: { lbl: "النوع", holder: { male: "ذكر", female: "انثى" } },
          materialStatus: {
            lbl: "الحالة الإجتماعية",
            holder: { single: "أعزب", marred: "متزوج" },
          },
          jobTitle: { lbl: "المسمى الوظيفي", holder: "المسى الوظيفي" },
          intro: { lbl: "مقدمة", holder: "مقدمة تعريفية" },
        },
        form2: {
          eduDegree: { lbl: "الدرجة العلمية", holder: "الدرجة العلمية" },
          schoolName: { lbl: "إسم الجامعة", holder: "أسم الجامعة او المعهد" },
          yearsStudy: {
            lbl: "سنوات الدراسة",
            holder: { from: "من", to: "إلى" },
          },
          disc: { lbl: "مقدمة عن التعليم", holder: "نبذة عن المنهج التعليمي" },
        },
        skillsLangsForm: {
          skillsLbl: "المهارات",
          skillHolder: "أضف مهارة   ",
          langsLbl: "اللغات",
          langHolder: "أضف لغة",
          level1: "المعرفة العملية",
          level2: "متقن جيد",
          level3: "متمكن",
          level4: "اللغة الأم",
        },
        addInfoForm: {
          addMoreLbl: "إضافة معلومات ؟",
          addWork: "خبرات العمل",
          addLink: "رابط موقع",
          addThesis: "اطروحة التخرج",
          addCourse: "دورة علمية",
          ifNotSave: "أنقر علي زر الحفظ للإنهاء",
        },
        addWorkForm: {
          jobTitle: {
            lbl: "المسمى الوظيفي",
            holder: "قم بإدخال المسمى الوظيفي",
          },
          empName: {
            lbl: "أسم الجهة المعنية",
            holder: "قم بإدخال اسم صاحب العمل",
          },
          address: { lbl: "العنوان", holder: "عنوان العمل" },
          date: { lbl: "تاريخ العمل", from: "من", to: "إلى" },
        },
        addLinkForm: {
          linksLbl: "روابط لمواقع إلأكترونية",
          linkHolder: "عنوان الموقع",
          linkURLHolder: "رابط الموقع",
        },
        addThesisForm: {
          thesisTopic: "عنوان اطروحة التخرج",
          thesisHolder: "أدخل عنوان الاطروحة",
        },
        addCourseForm: {
          courseTitleLbl: "عنوان الدورة",
          courseTitleHolder: "ادخل اسم او عنوان الدورة",
          courseInstLbl: "الجهة المانحة",
          courseInstHolder: "ادخل إسم الجهة المانحة",
        },
        btnGoNextLbl: "التالي",
        btnFinish: "حفظ وإنهاء",
        paymentForm: {
          spanFree: "يمكنك تعديل البيانات مجاناً بعد تسجل الدخول",
          continueAnchor: " إتمام العملية مقابل",
          price: '$3.75'
        },
      },
      frame: {
        mainTitle: {
          details: "معلومات أساسية",
          education: "التعليم",
          skills: "المهارات",
          workExeperince: "خبرات العمل",
          websiteLinks: "موقع إلكتروني",
          languages: "اللغات",
          contacts: "جهات تواصل",
        },
        city: "مدينة",
        country: "دولة",
        fullName: "الأسم بالكامل",
        jobTitle: "عنوان الوظيفة",
        intro: "اذكر أهدافك وما تبحث عنه، وتحدث أيضًا عن الإنجازات والطموحات",
        nationality: "الجنسية",
        placeBirth: "مكان الميلاد",
        birthDate: "تاريخ الميلاد",
        gender: "النوع",
        materialStatus: "الحالة الإجتماعية",
        degreeOf: "شهادة",
        educationDegree: "الدرجة العلمية",
        universityName: " اسم الجامعة",
        thesisTopic: "اطروحة التخرج",
        courses: "دورات علمية",
        courseFrom: "من",
        comma: "،",
        addSkill: "أضف مهارة واحدة على الأقل",
        nativeLanguage: "اللغة الأم",
        linguisticLevel: "المستوى اللغوي",
        resumeValues: {
          fullName: "احمد صالح البكري",
          address: {
            country: "الإمارات المتحدة",
            city: "عجمان",
          },
          phone: "0123456789",
          email: "ahmad@mail.com",
          nationality: " إماراتي",
          placeOfBirth: "الإمارات",
          dateOfBirth: {
            day: "16",
            month: "3",
            year: "1998",
          },
          gender: "ذكر",
          relationship: "اعزب",
          jobTitle: "مدير مشاريع برمجية",
          introParagraph: `لقد اكتسبت مهارة العمل على مشاريع البرمجيات (العمل الجماعي والفردي). أستطيع التعامل مع الأخطاء ومشاكل البرمجيات والتكيف مع تغييرات الخطة أو تعلم أداة جديدة بسرعة إذا لزم الأمر في السنوات الأخيرة ركزت عملي على تطوير مواقع الويب وتطبيقات الهاتف المحمول وتطبيقات سطح المكتب  لدي بعض الأعمال التي يمكنك الاطلاع عليها.`,
          eduForm: {
            eduDegree: "بكالاريوس شرفي تقانة المعلومات",
            uniName: "جامعة الملك فيصل",
            cerLevel: ".",
            yearsStudy: {
              from: { month: "Nov", year: "2017" },
              to: { month: "Mar", year: "2023" },
            },
            thesis: "عمل نظام يقوم بإدارة الشركة من حيث الأمان",
            photo: {},
            eduDiscreption: `وتتكيف مشكلات البرامج مع تغييرات الخطة أو تتعلم أداة جديدة بسرعة إذا لزم الأمر. في السنوات الأخيرة ركزت عملي على تطوير مواقع الويب وتطبيقات الهاتف المحمول وتطبيقات سطح المكتب`,
          },
          skillsForm: {
            princ: { name: "التواصل" },
            childs: [
              { name: "إدارة الوقت" },
              { name: "التعاون" },
              { name: "القيادة" },
              { name: "المرونة" },
              { name: "القدرة على التكيف" },
            ],
          },
          langsForm: {
            princ: { name: "العربية", level: "اللغة الأم" },
            childs: [{ name: "الإنجليزية", level: "متقن" }],
          },
          workExpForm: [
            {
              jobTitle: "صيانة نظام",
              address: "عجمان،الإمارات المتحدة",
              employer: "الشركة الذهبية للتكمنولوجيبا",
              workDate: {
                from: { month: "jun", year: "2023" },
                to: { month: "jun", year: "2024" },
              },
            },
            {
              jobTitle: "صيانة نظام",
              address: "عجمان،الإمارات المتحدة",
              employer: "الشركة الذهبية للتكمنولوجيبا",
              workDate: {
                from: { month: "jun", year: "2023" },
                to: { month: "jun", year: "2024" },
              },
            },
          ],
          coursesForm: [
            {
              courseTitle: "دورة هندسة البرمجيات",
              institution: "معهد العلوم التكنولوجية",
            },
          ],
          webLinks: {
            princ: {
              webTitle: " مشروع تطوير نظام مطعم ",
              // webHref:
              //   "https://drive.google.com/file/d/1PGvMBadcOs05hsie1zwEQmZAwBWZfdx1/view",
            },
            childs: [
              {
                webTitle: "مشروع نظام متجر إلكتروني",
                // webHref:
                //   "https://drive.google.com/file/d/18LBGD-PXRPzyAe0qbu-niaoXNwGfzoZy/view",
              },
              {
                webTitle: "مشروع لإدارة المناهج التعليمية",
                // webHref:
                //   "https://drive.google.com/file/d/1NI1ueB8YwGq4W2opO_zSf4kodAiLP915/view",
              },
              {
                webTitle: "نظام إستئجار سيارات",
                // webHref:
                //   "https://drive.google.com/file/d/1e3UoVB9y9XvO1VSaRdF34pHMwXlhiCt3/view?usp=sharing",
              },
            ],
          },
        },
      },
    },
  },
  section3: {
    signInToEdit:
      "قم بتسجيل الدخول لتتمكن من التعديل على سيرتك الذاتية في أي وقت",
    or: "أو",
    registerBtn: "إنشاء حساب",
    accountCreated: "تم إنشاء الحساب، جارٍ تسجيل الدخول...",
    passwordChanged: "تم تغيير كلمة السر، جارٍ تسجيل الدخول...",
    formLogin: {
      email: "أدخل عنوان البريد الخاص بك",
      password: "أدخل كلمة المرور",
      loginBtn: "تسجيل الدخول",
      passwordOrEmailNotCorrect: "عنوان بريد او كلمة مرور غير صحيحة، ",
      forgetPassword: "نسيت كلمة السر ؟",
    },
    formRegister: {
      name: "الأسم بالكامل",
      email: "عنوان البريد الخاص بك",
      password: "كلمة المرور",
      reenterPassword: "أعد كتابة كلمة المرور",
      registerBtn: "تسجيل",
      allrradyHaveTxt: "هل لديك حساب ؟",
      passwordsNotMatch: "كلمات المرور ليست متطابقة",
      emailAllreadyExist: "عنوان البريد مسجل بالفعل",
    },
    formRegisterConfirm: {
      codeInput: "قم بإدخال رمز التحقق",
      confirmBtn: "تحقق",
      dontReciveCode: "إرسال الرمز مرة اخرى ؟",
    },
    formResetPass: {
      password: "كلمة المرور الجديدة",
      reenterPassword: "أعد كتابة كلمة المرور الجديدة",
      passwordsNotMatch: "كلمات المرور ليست متطابقة",
      saveBtn: "حفظ كلمة المرور",
    },
    formConfirmResSetPass: {
      codeInput: "قم بإدخال رمز التحقق",
      confirmBtn: "تحقق",
      dontReciveCode: "إرسال الرمز مرة اخرى ؟",
    },
  },
  section4: {
    termsPolicy: {
      title: 'الشروط والسـياسة',
      userPrivacy: 'خصوصية المستخدم',
      conditions: 'تخزين واسترجاع البيانات',
      termsConditions: 'الشروط والأحكام',
      readMore: 'إقرأ المزيد'
    }
    ,
    contacts: {
      title: 'تواصل معنا'
    },
    services: {
      title: 'خدمات',
      makeAsome: 'إنشاء سيرة ذاتية',
      allTimeFreeEdit: 'التعديل مجانا في اي وقت',
      freeCreateAccount: 'إنشاء حساب مجاني',
      freePrint: 'طباعة ملف السيرة الذاتية مجانًا طوال الوقت'
    }

  },
  termsPolicy: {
    termsTitle: 'الشروط و الأحكام',
    terms:
      [
        "يتم تخزين معلومات المستخدم الشخصية بعد أن يقوم بإنشاء حساب فقط",
        "البيانات المتعلقة بالسيرة الذاتية يتم تخزينها لغرض إعادة استخدامها مجددا من قبل المستخدم نفسه",
        "لن يتم تخزين اي بيانات متعلقة بمستخدم لم يقم بتسجيل الدخول إلى حسابه",
        "البيانات الشخصية مثل البريد الإلكتروني هي فقط لغرض التحقق من هوية المستخدم مثل التحقق اثناء عملية الدخول او التحقق عند طلب كلمة سر جديدة لن يتم استخدامها لأي غرض اخر ولن يتم نشرها مطلقا و لن يتم استخدامها لأغراض الاعلان وما شابه",
        "المعلومات المتعلقة بالسيرة الذاتية بما في ذلك الصور الشخصية يتم الإحتفاظ بها بغرض خدمة المستخدم و ان يتمكن من طباعة سيرته الذاتية التي قام بإنشائها مسبقا وان يقوم بالتعديل عليها وطباعة النسخة المعدلة مجانا في اي وقت ولن يتم نشرها او العبث بها او الإطلاع عليها من اي جهة أخرى لأي غرض آخر",
        "ان قام المستخدم بحذف اي ملف سيرة ذاتية منشأ مسبقا سيتم حذف البيانات المتعلقة بالملف المحذوف من قاعدة البيانات مباشرة ولن يتم الإحتفاظ بنسخة من هذه البيانات مطلقا",
        "يتم تخزين بيانات عامة على ذاكرة تخزين المتصفح المحلية بغرض جودة الاستخدام مثل البيانات المتعلقة بإختيار لغة المستخدم المفضلة",
        " يتم تخزين البيانات المستخدمة لإنشاء السيرة الذاتية في ذاكرة المتصفح  المحلية لغرض جودة الإستخدام مثل استخدام البيانات التي تم إدخالها من المستخدم في آخر مرة حتى تسهل عليه عملية التعديل و إتمام انشاء سيرته الذاتية.",
        "لن يتم تخزين بيانات سيرة ذاتية في حال لم يقم المستخدم بتسجيل الدخول ولن يحصل على خاصية إعادة الطباعة او التعديل مجاناً",
        "يحق للمستخدم بعد تسجيل الدخول إعادة طباعة سيرته الذاتية التي تم إنشائها مسبقا او التعديل عليها في اي وقت ولأكثر من مرة واحدة مجانا",
        "البيانات المدخلة من المستخدم بغرض إنشاء سيرة ذاتية هي بيانات تخص المستخدم فقط لا نقوم بتقيدها او مراقبتها او تتبعها ويتحمل المستخدم المسؤلية الكاملة بخصوص كتابتها على سيرته الذاتية",
        "عملية الدفع واستلام النقود تتم من خلال جهة مالية موثوقة لا نمتلك أحقية الاحتفاظ او حتى الإتطلاع على اي بيانات مالية متعلقة بالمستخدم لأي سبب كان",
        "في حال عدم تطابق التصميم الذي تحصلت عليه مع التصميم الذي قمت  بإختياره او وجود مشكلة بالتنسيق او عدم وضوح البيانات بسبب حدوث عطل فني يمكنك التواصل معنا لمراجعة الخطأ",
        "سيتم تخزين معلومات بخصوص عملية الدفع لفترة مؤقتة بعدها سوف تحذف",
        "في حال قررنا إغلاق الموقع سيتم إبلاغ كل مشترك ليتمكن من طباعة ملفاته في حال كان يرغب في ذلك",
        "الأسعار قابلة للتغير و الخدمات المجانية تكون لفترات محدودة"
      ],
    close: 'خروج'
  },
  donePayment: {
    downloading: "جاري التنزيل",
    thank: "شكراً لك",
  },
  profile: {
    helloCustomer: " مرحباً ",
    addFrameBtn: "إنشاء سيرة ذاتية جديدة",
    deleteBtn: 'حذف الحساب',
    deleting: 'جاري حذف الحساب...'
  },
};
const china = {
  attentionsTerms: {
    mainHeader: '接受这一点即表示您同意我们的条款和政策。',
    firstTerm: '1. 您同意将您的简历相关信​​息（包括图片）存储在您设备的存储单元中，并在您完成简历后自动删除它们',
    firstTermNecessary: '（完成简历制作服务所必需的）',
    sec2Term: '您同意永久存储与您的简历相关的信息，以便您可以随时继续创建您的简历',
    notAcceptBtn: '不接受',
    acceptBtn: '接受'
  },
  section1: {
    nav: {
      homeBtn: "家",
      aboutBtn: "关于",
      profileBtn: "轮廓",
      loginBtn: "登录",
      logoutBtn: "退出",
    },
    makeYourTxt: "让你的",
    yourCV: "定制简历",
    nowBtn: "现在",
    ready4u: "20多个创意设计的简历模板为您准备好了",
    langWarning: '所选语言将用于创建 CV 文件'
  },
  section2: {
    showMoreBtn: "显示更多",
    allDesATSchecked: "所有设计均已在 ATS 系统上进行测试",
    getCvInfo: {
      forms: {
        form1: {
          fullName: { lbl: "姓名", holder: "姓名" },
          email: { lbl: "电子邮件", holder: "电子邮件" },
          phone: { lbl: "电话", holder: "电话号码" },
          address: {
            lbl: "地址",
            holder: { country: "国家", city: "城市" },
          },
          nationality: { lbl: "国籍", holder: "国籍" },
          placeBirth: { lbl: "出生地", holder: "出生地" },
          birthDate: {
            lbl: "出生日期",
            holder: { day: "天", month: "月", year: "年" },
          },
        },
        form11: {
          gender: { lbl: "性别", holder: { male: "男性", female: "女性" } },
          materialStatus: {
            lbl: "物料状态",
            holder: { single: "Single", marred: "受损的" },
          },
          jobTitle: { lbl: "职称", holder: "职称" },
          intro: { lbl: "介绍", holder: "介绍" },
        },
        form2: {
          eduDegree: { lbl: "教育程度", holder: "教育程度" },
          schoolName: { lbl: "学校名称", holder: "大学名称" },
          yearsStudy: {
            lbl: "学习年限",
            holder: { from: "从", to: "到" },
          },
          disc: {
            lbl: "描述",
            holder: "写下你的专业资格",
          },
        },
        skillsLangsForm: {
          skillsLbl: "技能",
          skillHolder: "技能",
          langsLbl: "语言",
          langHolder: "语言",
          level1: "工作知识",
          level2: "指挥好",
          level3: "精通",
          level4: "母语人士",
        },
        addInfoForm: {
          addMoreLbl: "添加更多信息？",
          addWork: "工作经验",
          addLink: "网站链接",
          addThesis: "论文题目",
          addCourse: "课程",
          ifNotSave: "如果不直接按保存",
        },
        addWorkForm: {
          jobTitle: { lbl: "职称", holder: "例如。销售代表" },
          empName: { lbl: "雇主名称", holder: "公司名称" },
          address: { lbl: "地址", holder: "城市/城镇" },
          date: { lbl: "工作日期", from: "从", to: "到" },
        },
        addLinkForm: {
          linksLbl: "链接",
          linkHolder: "网页标题",
          linkURLHolder: "网址",
        },
        addThesisForm: {
          thesisTopic: "论文题目",
          thesisHolder: "论文题目",
        },
        addCourseForm: {
          courseTitleLbl: "课程名称",
          courseTitleHolder: "课程名称",
          courseInstLbl: "机构",
          courseInstHolder: "机构名称",
        },
        btnGoNextLbl: "下一步",
        btnFinish: "保存并完成",
        paymentForm: {
          spanFree: "登录后您可以随时免费编辑您的简历。",
          continueAnchor: "继续",
          price: '$3.75'
        },
      },
      frame: {
        mainTitle: {
          details: "细节",
          education: "教育",
          skills: "技能",
          workExeperince: "工作经验",
          websiteLinks: "网站链接",
          languages: "语言",
          contacts: "联系方式",
        },
        city: "城市",
        country: "国家",
        fullName: "姓名",
        jobTitle: "职称",
        intro: "提及你的目标和你正在寻找的东西，也谈论成就和抱负",
        nationality: "国籍",
        placeBirth: "出生地",
        birthDate: "出生日期",
        gender: "性别",
        materialStatus: "物料状况",
        degreeOf: "程度",
        educationDegree: "教育程度",
        universityName: "大学名称",
        thesisTopic: "论文题目",
        courses: "课程",
        courseFrom: "从",
        comma: ",",
        addSkill: "至少添加一项技能",
        nativeLanguage: "母语",
        linguisticLevel: "语言水平",
        resumeValues: {
          fullName: "Feng Nüying",
          address: {
            country: "中国",
            city: "北京",
          },
          phone: "0123456789",
          email: "osama2025@gmail.com",
          nationality: "中国人",
          placeOfBirth: "中国",
          dateOfBirth: {
            day: "01",
            month: "01",
            year: "2000",
          },
          gender: "男性",
          relationship: "单身的",
          jobTitle: "信息技术工程",
          introParagraph: `我已经掌握了从事软件项目（小组和个人工作）的技能。我可以处理错误和软件问题以适应计划的变化，或者在必要时快速学习新工具。近年来，我的工作重点是开发网站、移动应用程序和桌面应用程序。我有一些工作你可以看看。`,
          eduForm: {
            eduDegree: "信息技术学士（荣誉）",
            uniName: "青尼罗河宇宙",
            cerLevel: ".",
            yearsStudy: {
              from: { month: "六月", year: "2013" },
              to: { month: "六月", year: "2016" },
            },
            thesis:
              "",
            photo: {},
            eduDiscreption: `软件问题会适应计划更改或在必要时快速学习新工具。近年来我的工作重点是开发网站、移动应用程序和桌面应用程序`,
          },
          skillsForm: {
            princ: { name: "JavaScript" },
            childs: [
              { name: "HTML, CSS" },
              { name: "Bootstrap5" },
              { name: "ExpressJS, MongoDB, MySQL" },
              { name: "Java, JavaFX" },
              { name: "Flutter" },
            ],
          },
          langsForm: {
            princ: { name: "中文", level: "母语人士" },
            childs: [{ name: "英语", level: "非常好的指挥" }],
          },
          workExpForm: [
            {
              jobTitle: "安全审批",
              address: "出生地",
              employer: "怀特月亮",
              workDate: {
                from: { month: "", year: "" },
                to: { month: "", year: "" },
              },
            },
            {
              jobTitle: "安全审批",
              address: "出生地",
              employer: "怀特月亮",
              workDate: {
                from: { month: "六月", year: "2005" },
                to: { month: "六月", year: "2010" },
              },
            },
          ],
          coursesForm: [
            { courseTitle: "java课程", institution: "YouTube" },
            { courseTitle: "java课程", institution: "YouTube" },
          ],
          webLinks: {
            princ: {
              webTitle: "",
              // webHref:
              //   "https://drive.google.com/file/d/1PGvMBadcOs05hsie1zwEQmZAwBWZfdx1/view",
            },
            childs: [
              {
                webTitle: "",
                // webHref:
                //   "https://drive.google.com/file/d/18LBGD-PXRPzyAe0qbu-niaoXNwGfzoZy/view",
              },
              {
                webTitle: "",
                // webHref:
                //   "https://drive.google.com/file/d/1NI1ueB8YwGq4W2opO_zSf4kodAiLP915/view",
              },
              {
                webTitle: "",
                // webHref:
                //   "https://drive.google.com/file/d/1e3UoVB9y9XvO1VSaRdF34pHMwXlhiCt3/view?usp=sharing",
              },
            ],
          },
        },
      },
    },
  },
  section3: {
    signInToEdit: "登录即可随时编辑您的简历",
    or: "或者",
    registerBtn: "登记",
    accountCreated: "帐户已创建，登录...",
    passwordChanged: "密码已更改，登录...",
    formLogin: {
      email: "电子邮件",
      password: "密码",
      loginBtn: "登录",
      passwordOrEmailNotCorrect: "密码或电子邮件不正确，",
      forgetPassword: "忘记密码？",
    },
    formRegister: {
      name: "姓名",
      email: "电子邮件",
      password: "密码",
      reenterPassword: "重新输入密码",
      registerBtn: "登记",
      allrradyHaveTxt: "已经有帐号了吗？",
      passwordsNotMatch: "密码不匹配",
      emailAllreadyExist: "电子邮件已存在",
    },
    formRegisterConfirm: {
      codeInput: "输入验证码",
      confirmBtn: "确认",
      dontReciveCode: "没有收到号码？",
    },
    formResetPass: {
      password: "新密码",
      reenterPassword: "重新输入新密码",
      passwordsNotMatch: "密码匹配",
      saveBtn: "保存新密码",
    },
    formConfirmResSetPass: {
      codeInput: "输入验证码",
      confirmBtn: "确认",
      dontReciveCode: "没有收到号码？",
    },
  },
  section4: {
    termsPolicy: {
      title: '条款与政策',
      userPrivacy: '用户隐私',
      conditions: '存储和检索数据',
      termsConditions: '条款和条件',
      readMore: '阅读更多'
    },
    contacts: {
      title: '联系我们'
    },
    services: {
      title: '服务',
      makeAsome: '创建简历文件',
      allTimeFreeEdit: '全程免费编辑',
      freeCreateAccount: '免费创建帐户',
      freePrint: '随时免费打印简历文件'
    }
  },
  termsPolicy: {
    termsTitle: '条款和政策',
    terms:
      ["用户的个人信息仅在他或她创建帐户后才会被存储",
        "存储与简历相关的数据供同一用户重复使用",
        "与未登录其帐户的用户相关的任何数据都不会被存储",
        "电子邮件等个人数据仅用于验证用户身份的目的，例如登录过程中的验证或请求新密码时的验证。它不会用于任何其他目的，也不会用于广告和营销目的。",
        "保留与简历相关的信息，包括个人照片，是为了服务用户，以便用户可以随时免费打印、修改和打印修改后的简历，并且不会被任何其他方发布、篡改或查看。出于任何其他目的。",
        "如果用户删除任何先前创建的 CV 文件，则与已删除文件相关的数据将立即从数据库中删除，并且永远不会保留该数据的副本。",
        "一般数据出于用户体验的目的而存储在浏览器的本地缓存中，例如与选择用户首选语言相关的数据",
        "用于创建简历的数据存储在浏览器的本地内存中，目的是为了高质量使用，例如使用用户上次输入的数据，以便于修改和完成其简历的创建过程。",
        "如果用户未登录，简历数据将不会被存储，并且无法免费转载或编辑",
        "登录后，用户有权随时、多次免费重新打印或修改自己之前创建的简历。",
        "用户为创建简历而输入的数据仅属于该用户。我们不限制、监控或跟踪它，用户对将其写入简历承担全部责任。",
        "付款和收款过程是通过可靠的金融机构进行的。我们无权以任何理由保留甚至查看与用户相关的任何财务数据。",
        "如果您获得的设计与您选择的设计不符，或者格式有问题，或者由于技术故障导致数据不清楚，您可以联系我们查看错误。",
        "有关付款流程的信息将被暂时存储一段时间，之后将被删除",
        "如果我们决定关闭该网站，每个订阅者都会收到通知，以便他可以根据需要打印他的文件",
        "价格可能会发生变化，并且免费服务是有期限的"

      ],
    close: '关闭'
  },
  donePayment: {
    downloading: "正在下载..",
    thank: "谢谢",
  },
  profile: {
    helloCustomer: "你好 ",
    addFrameBtn: "添加新的简历文件",
    deleteBtn: '删除帐户',
    deleting: '删除...'
  },
};
const france = {
  attentionsTerms: {
    mainHeader: 'en acceptant ceci, vous acceptez nos conditions et notre politique.',
    firstTerm: "1. Vous autorisez le stockage des informations relatives à votre CV, y compris les images dans l'unité de stockage de votre appareil, et vous les supprimez automatiquement une fois votre CV terminé.",
    firstTermNecessary: '(Nécessaire pour compléter le service de création de CV)',
    sec2Term: '2. Vous acceptez que les informations relatives à votre CV soient stockées de manière permanente, ce qui vous permet de continuer à créer votre CV à tout moment',
    notAcceptBtn: 'Ne pas accepter',
    acceptBtn: 'Accepter'
  },
  section1: {
    nav: {
      homeBtn: "Maison",
      aboutBtn: "À propos ",
      profileBtn: "Profil",
      loginBtn: "Se connecter",
      logoutBtn: "Déconnexion",
    },
    makeYourTxt: "Faites votre",
    yourCV: "CV personnalisé",
    nowBtn: "Maintenant",
    ready4u: "Plus de 20 modèles de CV conçus de manière créative et prêts pour vous",
    langWarning: "La langue choisie sera utilisée pour créer le fichier CV"

  },
  section2: {
    showMoreBtn: "Afficher plus",
    allDesATSchecked: "Toutes les conceptions ont été testées sur le système ATS",
    getCvInfo: {
      forms: {
        form1: {
          fullName: { lbl: "Nom et prénom", holder: "Nom et prénom" },
          email: { lbl: "E-mail", holder: "adresse email" },
          phone: { lbl: "Téléphone", holder: "Numéro de téléphone" },
          address: {
            lbl: "Adresse",
            holder: { country: "pays", city: "ville" },
          },
          nationality: { lbl: "Nationalité", holder: "Nationalité" },
          placeBirth: { lbl: "Lieu de naissance", holder: "Lieu de naissance" },
          birthDate: {
            lbl: "Date de naissance",
            holder: { day: "jour", month: "mois", year: "année" },
          },
        },
        form11: {
          gender: { lbl: "Genre", holder: { male: "Mâle", female: "Femelle" } },
          materialStatus: {
            lbl: "État du matériel",
            holder: { single: "Célibataire", marred: "Marquée" },
          },
          jobTitle: { lbl: "Titre d'emploi", holder: "Titre d'emploi" },
          intro: { lbl: "Introduction", holder: "Introduction" },
        },
        form2: {
          eduDegree: { lbl: "Diplôme d'études", holder: "Diplôme d'études" },
          schoolName: { lbl: "Nom de l'école", holder: "nom de l'université" },
          yearsStudy: {
            lbl: "Années d'études",
            holder: { from: "depuis", to: "à" },
          },
          disc: {
            lbl: "Description",
            holder: "écrivez sur vos qualifications professionnelles",
          },
        },
        skillsLangsForm: {
          skillsLbl: "Compétences",
          skillHolder: "compétence",
          langsLbl: "Langues",
          langHolder: "Langues",
          level1: "Connaissances pratiques",
          level2: "Bon commandement",
          level3: "Compétente",
          level4: "Locuteur natif",
        },
        addInfoForm: {
          addMoreLbl: "ajouter plus d'informations ?",
          addWork: "Expérience professionnelle",
          addLink: "Lien vers le site Web",
          addThesis: "sujet de thèse",
          addCourse: "cours",
          ifNotSave: "sinon, appuyez simplement sur Enregistrer",
        },
        addWorkForm: {
          jobTitle: { lbl: "Titre d'emploi", holder: "p.ex. représentant commercial" },
          empName: { lbl: "Nom de l'employeur", holder: "Nom de l'entreprise" },
          address: { lbl: "Nom de l'entreprise", holder: "ville/village" },
          date: { lbl: "Date de travail", from: "depuis", to: "à" },
        },
        addLinkForm: {
          linksLbl: "Links",
          linkHolder: "titre du site web",
          linkURLHolder: "URL",
        },
        addThesisForm: {
          thesisTopic: "Sujet de thèse",
          thesisHolder: "titre du sujet de thèse",
        },
        addCourseForm: {
          courseTitleLbl: "Titre du cours",
          courseTitleHolder: "par exemple, cours javaFx",
          courseInstLbl: "Institution",
          courseInstHolder: "nom de l'institut",
        },
        btnGoNextLbl: "Aller au suivant",
        btnFinish: "Enregistrer et terminer",
        paymentForm: {
          spanFree: "vous pouvez modifier votre CV GRATUITEMENT à tout moment après vous être connecté.",
          continueAnchor: "continuer avec ",
          price: '$3.75'
        },
      },
      frame: {
        mainTitle: {
          details: "Détails",
          education: "Éducation",
          skills: "Compétences",
          workExeperince: "Expérience professionnelle",
          websiteLinks: "Liens vers les sites Web",
          languages: "Langues",
          contacts: "Contacts",
        },
        city: "ville",
        country: "pays",
        fullName: "Nom et prénom",
        jobTitle: "Titre d'emploi",
        intro: "mentionnez vos objectifs et ce que vous recherchez, parlez également de vos réalisations et de vos ambitions",
        nationality: "Nationalité",
        placeBirth: "Lieu de naissance",
        birthDate: "Date de naissance",
        gender: "Genre",
        materialStatus: "État du matériel",
        degreeOf: "Degré de",
        educationDegree: "Diplôme d'études",
        universityName: " Nom de l'université",
        thesisTopic: "Sujet de thèse",
        courses: "Cours",
        courseFrom: "depuis",
        comma: ",",
        addSkill: "ajouter au moins une compétence",
        nativeLanguage: "Langue maternelle",
        linguisticLevel: "Niveau linguistique",
        resumeValues: {
          fullName: "Cendrillon Lachapelle",
          address: {
            country: "France",
            city: "Paris",
          },
          phone: "0123456789",
          email: "cendrillon@gmail.com",
          nationality: " Français",
          placeOfBirth: "France",
          dateOfBirth: {
            day: "16",
            month: "3",
            year: "2002",
          },
          gender: "Mâle",
          relationship: "Célibataire",
          jobTitle: "Technicien en technologies de l'information",
          introParagraph: "J'ai acquis la compétence de travailler sur des projets logiciels (travail de groupe et individuel). Je peux gérer les erreurs et les problèmes logiciels, m'adapter aux changements de plan ou apprendre rapidement un nouvel outil si nécessaire. Ces dernières années, j'ai concentré mon travail sur le développement de sites Web, d'applications mobiles et d'applications de bureau (fullstack). J'ai du travail que vous pouvez consulter.",
          eduForm: {
            eduDegree: "Baccalauréat (avec distinction) en technologies de l'information ",
            uniName: "Université des sciences et technologies du Nil Bleu",
            cerLevel: ".",
            yearsStudy: {
              from: { month: "juin", year: "2017" },
              to: { month: "juin", year: "2023" },
            },
            thesis:
              "génération de la langue des signes à l'aide de la technologie d'apprentissage automatique.",
            photo: {},
            eduDiscreption: "et les problèmes de logiciels s'adaptent aux changements de plan ou apprennent rapidement un nouvel outil si nécessaire. Ces dernières années, j'ai concentré mon travail sur le développement de sites Web, d'applications mobiles et d'applications de bureau",
          },
          skillsForm: {
            princ: { name: "JavaScript" },
            childs: [
              { name: "HTML, CSS" },
              { name: "Bootstrap5" },
              { name: "ExpressJS, MongoDB, MySQL" },
              { name: "Java, JavaFX" },
              { name: "Flutter" },
            ],
          },
          langsForm: {
            princ: { name: "Français", level: "Locuteur natif" },
            childs: [{ name: "English", level: "Très bonne maîtrise" }],
          },
          workExpForm: [
            {
              jobTitle: "Approbations de sécurité",
              address: "France , paris",
              employer: "compagnie du Nil bleu",
              workDate: {
                from: { month: "juin", year: "2023" },
                to: { month: "juin", year: "2024" },
              },
            },
            {
              jobTitle: "Approbations de sécurité",
              address: "France , paris",
              employer: "compagnie du Nil bleu",
              workDate: {
                from: { month: "juin", year: "2023" },
                to: { month: "juin", year: "2024" },
              },
            },
          ],
          coursesForm: [
            { courseTitle: "java", institution: "youtube" },
            { courseTitle: "javaScript", institution: "youtube2" },
          ],
          webLinks: {
            princ: {
              webTitle: " Projet de système d'achat ",
              // webHref:
              //   "https://drive.google.com/file/d/1PGvMBadcOs05hsie1zwEQmZAwBWZfdx1/view",
            },
            childs: [
              {
                webTitle: "Projet de système d'achat en ligne",
                // webHref:
                //   "https://drive.google.com/file/d/18LBGD-PXRPzyAe0qbu-niaoXNwGfzoZy/view",
              },
              {
                webTitle: "Système de contenu éducatif",
                // webHref:
                //   "https://drive.google.com/file/d/1NI1ueB8YwGq4W2opO_zSf4kodAiLP915/view",
              },
              {
                webTitle: "Projet de location de motos",
                // webHref:
                //   "https://drive.google.com/file/d/1e3UoVB9y9XvO1VSaRdF34pHMwXlhiCt3/view?usp=sharing",
              },
            ],
          },
        },
      },
    },
  },
  section3: {
    signInToEdit: "Connectez-vous pour modifier votre CV à tout moment",
    or: "Ou",
    registerBtn: "Registre",
    accountCreated: "compte créé, connexion...",
    passwordChanged: "mot de passe modifié, connexion...",
    formLogin: {
      email: "E-mail",
      password: "Mot de passe",
      loginBtn: "Se connecter",
      passwordOrEmailNotCorrect: "mot de passe ou email incorrect, ",
      forgetPassword: "mot de passe oublié ?",
    },
    formRegister: {
      name: "Nom et prénom",
      email: "mail",
      password: "Mot de passe",
      reenterPassword: "Entrez à nouveau le mot de passe",
      registerBtn: "Registre",
      allrradyHaveTxt: "Vous avez déjà un compte ?",
      passwordsNotMatch: "les mots de passe ne correspondent pas",
      emailAllreadyExist: "l'email existe déjà",
    },
    formRegisterConfirm: {
      codeInput: "Entrez le code de vérification",
      confirmBtn: "confirmer",
      dontReciveCode: "je ne reçois pas le numéro ?",
    },
    formResetPass: {
      password: "Nouveau mot de passe",
      reenterPassword: "Ressaisissez le nouveau mot de passe",
      passwordsNotMatch: "les mots de passe ne correspondent pas",
      saveBtn: "Enregistrer le nouveau mot de passe",
    },
    formConfirmResSetPass: {
      codeInput: "Entrez le code de vérification",
      confirmBtn: "confirmer",
      dontReciveCode: "je ne reçois pas le numéro ?",
    },
  },
  section4: {
    termsPolicy: {
      title: 'Conditions et politique',
      userPrivacy: 'Confidentialité des utilisateurs',
      conditions: 'Stockage et récupération de données',
      termsConditions: 'Termes et conditions',
      readMore: 'En savoir plus'
    },
    contacts: {
      title: 'Contactez-nous'
    },
    services: {
      title: 'Services',
      makeAsome: 'Créer un fichier CV',
      allTimeFreeEdit: 'Modification gratuite à tout moment',
      freeCreateAccount: 'Créer un compte gratuitement',
      freePrint: 'Fichier CV imprimé gratuit à tout moment'
    }
  },
  termsPolicy: {
    termsTitle: 'Conditions et politique',
    terms:
      [
        "Les informations personnelles d'un utilisateur ne sont stockées qu'après la création d'un compte.",
        "Les données liées au CV sont conservées dans le but d'être réutilisées par le même utilisateur",
        "Aucune donnée relative à un utilisateur qui ne s'est pas connecté à son compte ne sera stockée",
        "Les données personnelles telles que l’e-mail sont uniquement destinées à vérifier l’identité de l’utilisateur, comme la vérification lors du processus de connexion ou la vérification lors de la demande d’un nouveau mot de passe. Il ne sera utilisé à aucune autre fin et ne sera pas utilisé à des fins publicitaires et marketing.",
        "Les informations relatives au CV, y compris les photos personnelles, sont conservées dans le but de servir l'utilisateur et de lui permettre d'imprimer son CV qu'il avait préalablement créé, de le modifier et d'imprimer gratuitement à tout moment la version modifiée, et elles ne seront pas être publié, falsifié ou consulté par toute autre partie. Pour tout autre but.",
        "Si l'utilisateur supprime un fichier CV précédemment créé, les données liées au fichier supprimé seront immédiatement supprimées de la base de données et une copie de ces données ne sera jamais conservée.",
        "Les données générales sont stockées dans le cache local du navigateur à des fins d'expérience utilisateur, telles que les données liées au choix de la langue préférée de l'utilisateur.",
        "Les données utilisées pour créer le CV sont stockées dans la mémoire locale du navigateur dans un but d'utilisation de qualité, comme par exemple l'utilisation des données saisies la dernière fois par l'utilisateur afin de faciliter le processus de modification et de finalisation de la création de son CV.",
        "Les données de reprise ne seront pas stockées si l'utilisateur ne se connecte pas et il n'aura pas la possibilité de réimprimer ou de modifier gratuitement",
        "Après s'être connecté, l'utilisateur a le droit de réimprimer ou de modifier à tout moment et plusieurs fois gratuitement son CV précédemment créé.",
        "Les données saisies par l'utilisateur dans le but de créer un CV sont des données qui appartiennent uniquement à l'utilisateur. Nous ne le limitons pas, ne le surveillons pas et ne le suivons pas, et l'utilisateur assume l'entière responsabilité de l'écrire sur son CV.",
        "Le processus de paiement et de réception d’argent s’effectue par l’intermédiaire d’un organisme financier fiable. Nous n'avons pas le droit de conserver ni même de consulter les données financières relatives à l'utilisateur pour quelque raison que ce soit.",
        "Les informations concernant le processus de paiement seront stockées pendant une période temporaire, après quoi elles seront supprimées.",
        "Si nous décidons de fermer le site, chaque abonné en sera informé afin qu'il puisse imprimer ses fichiers s'il le souhaite",
        "Les prix sont sujets à changement et les services gratuits sont pour des périodes limitées"
      ],
    close: 'Fermer'
  },
  donePayment: {
    downloading: "Téléchargement..",
    thank: "merci",
  },
  profile: {
    helloCustomer: "Bonjour ",
    addFrameBtn: "ajouter un nouveau fichier CV",
    deleteBtn: 'supprimer le compte',
    deleting: 'suppression...'
  },
};
const italy = {
  attentionsTerms: {
    mainHeader: 'accettando questo accetti i nostri termini e la nostra politica.',
    firstTerm: "1. Acconsenti all'archiviazione delle informazioni relative al tuo CV, comprese le immagini, nell'unità di archiviazione del tuo dispositivo e all'eliminazione automatica delle stesse dopo aver completato il tuo CV",
    firstTermNecessary: '(Necessario per completare il servizio di creazione CV)',
    sec2Term: "2. L'accettazione della memorizzazione permanente delle informazioni relative al tuo CV ti consente di continuare a creare il tuo CV in qualsiasi momento",
    notAcceptBtn: 'Non accettare',
    acceptBtn: 'Accettare'
  },
  section1: {
    nav: {
      homeBtn: "Casa",
      aboutBtn: "Di",
      profileBtn: "Profilo",
      loginBtn: "Login",
      logoutBtn: "Esci",
    },
    makeYourTxt: "Fai il tuo",
    yourCV: "CV personalizzato",
    nowBtn: "Ora",
    ready4u: "Oltre 20 modelli di CV dal design creativo pronti per te",
    langWarning: "La lingua scelta verrà utilizzata per creare il file CV"
  },
  section2: {
    showMoreBtn: "Mostra altro",
    allDesATSchecked: "Tutti i progetti sono stati testati sul sistema ATS",
    getCvInfo: {
      forms: {
        form1: {
          fullName: { lbl: "Nome e cognome", holder: "Nome e cognome" },
          email: { lbl: "E-mail", holder: "e-mail address" },
          phone: { lbl: "Telefono", holder: "Telefono" },
          address: {
            lbl: "Indirizzo",
            holder: { country: "Paese", city: "Paese" },
          },
          nationality: { lbl: "Nazionalità", holder: "nazionalità" },
          placeBirth: { lbl: "Luogo di nascita", holder: "Luogo di nascita" },
          birthDate: {
            lbl: "Data di nascita",
            holder: { day: "giorno", month: "mese", year: "anno" },
          },
        },
        form11: {
          gender: { lbl: "Genere", holder: { male: "Maschia", female: "Femmina" } },
          materialStatus: {
            lbl: "Stato del materiale",
            holder: { single: "Singola", marred: "Rovinato" },
          },
          jobTitle: { lbl: "Titolo di lavoro", holder: "Titolo di lavoro" },
          intro: { lbl: "Introduzione", holder: "Introduzione" },
        },
        form2: {
          eduDegree: { lbl: "Laurea in Istruzione", holder: "laurea in Istruzione" },
          schoolName: { lbl: "Nome della scuola", holder: "nome dell'università" },
          yearsStudy: {
            lbl: "Anni di studio",
            holder: { from: "da", to: "a" },
          },
          disc: {
            lbl: "Descrizione",
            holder: "scrivi sulle tue qualifiche professionali",
          },
        },
        skillsLangsForm: {
          skillsLbl: "Competenze",
          skillHolder: "abilità",
          langsLbl: "Lingue",
          langHolder: "lingua",
          level1: "Conoscenza pratica",
          level2: "Buona padronanza",
          level3: "Competente",
          level4: "Madrelingua",
        },
        addInfoForm: {
          addMoreLbl: "aggiungere altre informazioni?",
          addWork: "Esperienza di lavoro",
          addLink: "Collegamento al sito web",
          addThesis: "argomento della tesi",
          addCourse: "corsi",
          ifNotSave: "in caso contrario premi semplicemente salva",
        },
        addWorkForm: {
          jobTitle: { lbl: "Titolo di lavoro", holder: "ad esempio rappresentante di vendita" },
          empName: { lbl: "Nome del datore di lavoro", holder: "nome dell'azienda" },
          address: { lbl: "Indirizzo", holder: "città/paese" },
          date: { lbl: "Data di lavoro", from: "da", to: "a" },
        },
        addLinkForm: {
          linksLbl: "Collegamenti",
          linkHolder: "titolo web",
          linkURLHolder: "Indirizzo URL",
        },
        addThesisForm: {
          thesisTopic: "Argomento della tesi",
          thesisHolder: "titolo argomento tesi",
        },
        addCourseForm: {
          courseTitleLbl: "Titolo del corso",
          courseTitleHolder: "ad esempio corso javaFx",
          courseInstLbl: "Istituzione",
          courseInstHolder: "nome dell'istituto",
        },
        btnGoNextLbl: "Vai Avanti",
        btnFinish: "Salva e termina",
        paymentForm: {
          spanFree: "puoi modificare il tuo CV GRATIS in qualsiasi momento dopo aver effettuato l'accesso",
          continueAnchor: "continua con ",
          price: '$3.75'
        },
      },
      frame: {
        mainTitle: {
          details: "Dettagli",
          education: "Istruzione",
          skills: "Istruzione",
          workExeperince: "Esperienza di lavoro",
          websiteLinks: "Link al sito web",
          languages: "Lingue",
          contacts: "Contatti",
        },
        city: "città",
        country: "Paese",
        fullName: "Nome e cognome",
        jobTitle: "Titolo di lavoro",
        intro:
          "menziona i tuoi obiettivi e ciò che stai cercando, parla anche dei risultati e delle ambizioni",
        nationality: "Nazionalità",
        placeBirth: "Luogo di nascita",
        birthDate: "Data di nascita",
        gender: "Genere",
        materialStatus: "Stato del materiale",
        degreeOf: "Grado di",
        educationDegree: "Laurea in Istruzione",
        universityName: "Nome dell'università",
        thesisTopic: "Argomento della tesi",
        courses: "Corsi",
        courseFrom: "da",
        comma: ",",
        addSkill: "aggiungi almeno un'abilità",
        nativeLanguage: "Madrelingua",
        linguisticLevel: "Livello linguistico",
        resumeValues: {
          fullName: "Bruno Caruso Carbone",
          address: {
            country: "Italy",
            city: "Rome",
          },
          phone: "+0123456789",
          email: "bruono2323@gmail.com",
          nationality: " italian",
          placeOfBirth: "italy",
          dateOfBirth: {
            day: "16",
            month: "3",
            year: "2001",
          },
          gender: "Maschia",
          relationship: "Singola",
          jobTitle: "Tecnico informatico",
          introParagraph: "Ho acquisito la capacità di lavorare su progetti software(lavoro di gruppo e individuale).Sono in grado di gestire errori e problemi software, adattarmi ai cambiamenti di piano o imparare rapidamente un nuovo strumento se necessario.Negli ultimi anni ho concentrato il mio lavoro sullo sviluppo di siti web, applicazioni mobili e applicazioni desktop(fullstack).Ho alcuni lavori che puoi controllare.",
          eduForm: {
            eduDegree: "Laurea triennale (con lode) in tecnologie dell'informazione ",
            uniName: "Università di scienza e tecnologia del Nilo Azzurro",
            cerLevel: ".",
            yearsStudy: {
              from: { month: "Novembre", year: "2017" },
              to: { month: "Marzo", year: "2023" },
            },
            thesis:
              "generazione del linguaggio dei segni mediante tecnologia di apprendimento automatico.",
            photo: {},
            eduDiscreption: "e i problemi software si adattano ai cambiamenti di piano o imparano rapidamente un nuovo strumento se necessario.Negli ultimi anni ho concentrato il mio lavoro sullo sviluppo di siti web, applicazioni mobili e applicazioni desktop",
          },
          skillsForm: {
            princ: { name: "JavaScript" },
            childs: [
              { name: "HTML, CSS" },
              { name: "Bootstrap5" },
              { name: "ExpressJS, MongoDB, MySQL" },
              { name: "Java, JavaFX" },
              { name: "Flutter" },
            ],
          },
          langsForm: {
            princ: { name: "Italian", level: "Madrelingua" },
            childs: [{ name: "English", level: "Ottima padronanza" }],
          },
          workExpForm: [
            {
              jobTitle: "Approvazioni di sicurezza",
              address: "Italy , rome",
              employer: "compagnia del Nilo Azzurro",
              workDate: {
                from: { month: "gennaio", year: "2023" },
                to: { month: "gennaio", year: "2024" },
              },
            },
            {
              jobTitle: "Approvazioni di sicurezza",
              address: "Italy , rome",
              employer: "compagnia del Nilo Azzurro",
              workDate: {
                from: { month: "gennaio", year: "2023" },
                to: { month: "gennaio", year: "2024" },
              },
            },
          ],
          coursesForm: [
            { courseTitle: "java", institution: "youtube" },
            { courseTitle: "javaScript", institution: "youtube2" },
          ],
          webLinks: {
            princ: {
              webTitle: " Progetto di sistema di acquisto ",
              // webHref:
              //   "https://drive.google.com/file/d/1PGvMBadcOs05hsie1zwEQmZAwBWZfdx1/view",
            },
            childs: [
              {
                webTitle: "Progetto di sistema di acquisto online",
                // webHref:
                //   "https://drive.google.com/file/d/18LBGD-PXRPzyAe0qbu-niaoXNwGfzoZy/view",
              },
              {
                webTitle: "Sistema di contenuti educativi",
                // webHref:
                //   "https://drive.google.com/file/d/1NI1ueB8YwGq4W2opO_zSf4kodAiLP915/view",
              },
              {
                webTitle: "Progetto di noleggio moto",
                // webHref:
                //   "https://drive.google.com/file/d/1e3UoVB9y9XvO1VSaRdF34pHMwXlhiCt3/view?usp=sharing",
              },
            ],
          },
        },
      },
    },
  },
  section3: {
    signInToEdit: "Accedi per modificare il tuo CV in qualsiasi momento",
    or: "O",
    registerBtn: "Registro",
    accountCreated: "account creato, accedi...",
    passwordChanged: "password cambiata, accedi...",
    formLogin: {
      email: "E-mail",
      password: "Password",
      loginBtn: "Login",
      passwordOrEmailNotCorrect: "password o email non corrette",
      forgetPassword: "password dimenticata?",
    },
    formRegister: {
      name: "Nome e cognome",
      email: "E-mail",
      password: "Password",
      reenterPassword: "Reinserisci la password",
      registerBtn: "Registro",
      allrradyHaveTxt: "hai già un account?",
      passwordsNotMatch: "le password non corrispondono",
      emailAllreadyExist: "l'email esiste già",
    },
    formRegisterConfirm: {
      codeInput: "Inserisci il codice di verifica",
      confirmBtn: "confermare",
      dontReciveCode: "non ricevi il numero?",
    },
    formResetPass: {
      password: "Nuova password",
      reenterPassword: "Reinserisci la nuova password",
      passwordsNotMatch: "le password non corrispondono",
      saveBtn: "Salva la nuova password",
    },
    formConfirmResSetPass: {
      codeInput: "Inserisci il codice di verifica",
      confirmBtn: "confermare",
      dontReciveCode: "non ricevi il numero?",
    },
  },
  section4: {
    termsPolicy: {
      title: 'Termini e politica',
      userPrivacy: "Privacy dell'utente",
      conditions: 'Memorizzazione e recupero dei dati',
      termsConditions: 'Termini e Condizioni',
      readMore: 'Per saperne di più'
    },
    contacts: {
      title: 'Contattaci'
    },
    services: {
      title: 'Servizi',
      makeAsome: 'Crea un file CV',
      allTimeFreeEdit: 'Modifica gratuita in ogni momento',
      freeCreateAccount: 'Crea un account gratuitamente',
      freePrint: 'File CV stampato gratuitamente in qualsiasi momento'
    }
  },
  termsPolicy: {
    termsTitle: 'Termini e politica',
    terms:
      [
        "Le informazioni personali di un utente vengono memorizzate solo dopo che lui o lei ha creato un account ",
        "I dati relativi al CV vengono archiviati ai fini del riutilizzo da parte dello stesso utente",
        "Tutti i dati relativi a un utente che non ha effettuato l'accesso al proprio account non verranno archiviati",
        "I dati personali come l'e-mail servono solo allo scopo di verificare l'identità dell'utente, come la verifica durante il processo di accesso o la verifica quando si richiede una nuova password. Non verranno utilizzati per nessun altro scopo e non verranno utilizzati per la pubblicità e finalità di marketing.",
        "Le informazioni relative al CV, comprese le foto personali, vengono conservate allo scopo di servire l'utente e consentirgli di stampare il suo CV precedentemente creato, modificarlo e stampare gratuitamente la versione modificata in qualsiasi momento, e sarà non essere pubblicato, manomesso o visualizzato da terzi per qualsiasi altro scopo.",
        "Se l'utente elimina qualsiasi file CV creato in precedenza, i dati relativi al file eliminato verranno immediatamente eliminati dal database e una copia di questi dati non verrà mai conservata.",
        "I dati generali vengono archiviati nella cache locale del browser ai fini dell'esperienza dell'utente, come i dati relativi alla scelta della lingua preferita dell'utente",
        "I dati utilizzati per creare il CV vengono archiviati nella memoria locale del browser ai fini di un utilizzo di qualità, ad esempio utilizzando i dati inseriti dall'utente l'ultima volta per facilitare il processo di modifica e completamento della creazione del suo CV.",
        "I dati del curriculum non verranno archiviati se l'utente non effettua l'accesso e non avrà la possibilità di ristamparlo o modificarlo gratuitamente",
        "Dopo aver effettuato l'accesso, l'utente ha il diritto di ristampare o modificare in qualsiasi momento e più di una volta gratuitamente il proprio CV precedentemente creato.",
        "I dati inseriti dall'utente allo scopo di creare un CV sono dati che appartengono solo all'utente. Non li limitiamo, non li monitoriamo o non li tracciamo e l'utente si assume la piena responsabilità di scriverli sul suo CV.",
        "Il processo di pagamento e ricezione di denaro avviene tramite un soggetto finanziario affidabile. Non abbiamo il diritto di conservare o visualizzare alcun dato finanziario relativo all'utente per nessun motivo.",
        "Se il disegno che hai ottenuto non corrisponde al disegno che hai scelto, o c'è un problema con il formato, o i dati non sono chiari a causa di un malfunzionamento tecnico, puoi contattarci per verificare l'errore.",
        "Le informazioni relative al processo di pagamento verranno conservate per un periodo temporaneo, dopodiché verranno cancellate",
        "Se decidiamo di chiudere il sito, ogni iscritto verrà avvisato in modo che possa stampare i suoi file se lo desidera",
        "I prezzi sono soggetti a modifiche e i servizi gratuiti sono per periodi limitati"
      ],
    close: 'Vicina'
  },
  donePayment: {
    downloading: "Download in corso..",
    thank: "Grazie",
  },
  profile: {
    helloCustomer: "Ciao ",
    addFrameBtn: "aggiungi un nuovo file cv",
  },
}
const emptyOne = {
  attentionsTerms: {
    mainHeader: '',
    firstTerm: '',
    firstTermNecessary: '',
    sec2Term: '',
    notAcceptBtn: '',
    acceptBtn: ''
  },
  section1: {
    nav: {
      homeBtn: "",
      aboutBtn: "",
      profileBtn: "",
      loginBtn: "",
      logoutBtn: "",
    },
    makeYourTxt: "",
    yourCV: "",
    nowBtn: "",
  },
  section2: {
    showMoreBtn: "",
    getCvInfo: {
      forms: {
        form1: {
          fullName: { lbl: "", holder: "" },
          email: { lbl: "", holder: "" },
          phone: { lbl: "", holder: "" },
          address: {
            lbl: "",
            holder: { country: "", city: "" },
          },
          nationality: { lbl: "", holder: "" },
          placeBirth: { lbl: "", holder: "" },
          birthDate: {
            lbl: "",
            holder: { day: "", month: "", year: "" },
          },
        },
        form11: {
          gender: { lbl: "", holder: { male: "", female: "" } },
          materialStatus: {
            lbl: "",
            holder: { single: "", marred: "" },
          },
          jobTitle: { lbl: "", holder: "" },
          intro: { lbl: "", holder: "" },
        },
        form2: {
          eduDegree: { lbl: "", holder: "" },
          schoolName: { lbl: "", holder: "" },
          yearsStudy: {
            lbl: "",
            holder: { from: "", to: "" },
          },
          disc: {
            lbl: "",
            holder: "",
          },
        },
        skillsLangsForm: {
          skillsLbl: "",
          skillHolder: "",
          langsLbl: "",
          langHolder: "",
          level1: "",
          level2: "",
          level3: "",
          level4: "",
        },
        addInfoForm: {
          addMoreLbl: "",
          addWork: "",
          addLink: "",
          addThesis: "",
          addCourse: "",
          ifNotSave: "",
        },
        addWorkForm: {
          jobTitle: { lbl: "", holder: "" },
          empName: { lbl: "", holder: "" },
          address: { lbl: "", holder: "" },
          date: { lblb: "", from: "", to: "" },
        },
        addLinkForm: {
          linksLbl: "",
          linkHolder: "",
          linkURLHolder: "",
        },
        addThesisForm: {
          thesisTopic: "",
          thesisHolder: "",
        },
        addCourseForm: {
          courseTitleLbl: "",
          courseTitleHolder: "",
          courseInstLbl: "",
          courseInstHolder: "",
        },
        btnGoNextLbl: "",
        btnFinish: "",
        paymentForm: {
          spanFree: "",
          continueAnchor: "",
          price: ''
        },
      },
      frame: {
        mainTitle: {
          details: "",
          education: "",
          skills: "",
          workExeperince: "",
          websiteLinks: "",
          languages: "",
          contacts: "",
        },
        city: "",
        country: "",
        fullName: "",
        jobTitle: "",
        intro: "",
        nationality: "",
        placeBirth: "",
        birthDate: "",
        gender: "",
        materialStatus: "",
        degreeOf: "",
        educationDegree: "",
        universityName: "",
        thesisTopic: "",
        courses: "",
        courseFrom: "",
        comma: ",",
        addSkill: "",
        nativeLanguage: "",
        linguisticLevel: "",
        resumeValues: {
          fullName: "",
          address: {
            country: "",
            city: "",
          },
          phone: "",
          email: "",
          nationality: " ",
          placeOfBirth: "",
          dateOfBirth: {
            day: "",
            month: "",
            year: "",
          },
          gender: "",
          relationship: "",
          jobTitle: "",
          introParagraph: ``,
          eduForm: {
            eduDegree: "",
            uniName: "",
            cerLevel: ".",
            yearsStudy: {
              from: { month: "", year: "" },
              to: { month: "", year: "" },
            },
            thesis:
              "",
            photo: {},
            eduDiscreption: ``,
          },
          skillsForm: {
            princ: { name: "" },
            childs: [
              { name: "" },
              { name: "" },
              { name: "" },
              { name: "" },
              { name: "" },
            ],
          },
          langsForm: {
            princ: { name: "", level: "" },
            childs: [{ name: "", level: "" }],
          },
          workExpForm: [
            {
              jobTitle: " ",
              address: " ",
              employer: "",
              workDate: {
                from: { month: "", year: "" },
                to: { month: "", year: "" },
              },
            },
            {
              jobTitle: "",
              address: "",
              employer: "",
              workDate: {
                from: { month: "", year: "" },
                to: { month: "", year: "" },
              },
            },
          ],
          coursesForm: [
            { courseTitle: "", institution: "" },
            { courseTitle: "", institution: "" },
          ],
          webLinks: {
            princ: {
              webTitle: "",
              // webHref:
              //   "https://drive.google.com/file/d/1PGvMBadcOs05hsie1zwEQmZAwBWZfdx1/view",
            },
            childs: [
              {
                webTitle: "",
                // webHref:
                //   "https://drive.google.com/file/d/18LBGD-PXRPzyAe0qbu-niaoXNwGfzoZy/view",
              },
              {
                webTitle: "",
                // webHref:
                //   "https://drive.google.com/file/d/1NI1ueB8YwGq4W2opO_zSf4kodAiLP915/view",
              },
              {
                webTitle: "",
                // webHref:
                //   "https://drive.google.com/file/d/1e3UoVB9y9XvO1VSaRdF34pHMwXlhiCt3/view?usp=sharing",
              },
            ],
          },
        },
      },
    },
  },
  section3: {
    signInToEdit: "",
    or: "",
    registerBtn: "",
    formLogin: {
      email: "",
      password: "",
      loginBtn: "",
      passwordOrEmailNotCorrect: "",
      forgetPassword: "",
    },
    formRegister: {
      name: "",
      email: "",
      password: "",
      reenterPassword: "",
      registerBtn: "",
      allrradyHaveTxt: "",
      passwordsNotMatch: "",
      emailAllreadyExist: "",
    },
    formRegisterConfirm: {
      codeInput: "",
      confirmBtn: "",
      dontReciveCode: "",
    },
    formResetPass: {
      password: "",
      reenterPassword: "",
      passwordsNotMatch: "",
      saveBtn: "",
    },
    formConfirmResSetPass: {
      codeInput: "",
      confirmBtn: "",
      dontReciveCode: "",
    },
  },
  section4: {
    termsPolicy: {
      title: '',
      userPrivacy: '',
      conditions: '',
      termsConditions: '',
      readMore: ''
    },
    contacts: {
      title: ''
    },
    services: {
      title: '',
      makeAsome: '',
      allTimeFreeEdit: '',
      freeCreateAccount: '',
      freePrint: ''
    }
  },
  termsPolicy: {
    termsTitle: '',
    terms:
      [

      ],
    close: ''
  },
  donePayment: {
    downloading: "",
    thank: "",
  },
  profile: {
    helloCustomer: "",
    addFrameBtn: "",
  },
};
export const languages = [
  { name: "arabic", lang: arabic },
  { name: "english", lang: english },
  { name: "中文", lang: china },
  { name: "Français", lang: france },
  { name: "Italiano", lang: italy },
];



/*
A user's personal information is stored only after he or she creates an account 

Data related to the CV is stored for the purpose of re-use by the same user

Any data related to a user who has not logged into his account will not be stored

Personal data such as e-mail is only for the purpose of verifying the user’s identity, such as verification during the login process or verification when requesting a new password. It will not be used for any other purpose and will not be used for advertising and marketing purposes.

Information related to the CV, including personal photos, is kept for the purpose of serving the user and enabling him to print his CV that he had previously created, amend it, and print the modified version for free at any time, and it will not be published, tampered with, or viewed by any other party. For any other purpose.

If the user deletes any previously created CV file, the data related to the deleted file will be deleted from the database immediately, and a copy of this data will never be kept.
=================================================================================
"General data is stored in the browser's local cache for the purpose of user experience, such as data related to choosing the user's preferred language",

"The data used to create the CV is stored in the browser’s local memory for the purpose of quality use, such as using the data entered by the user last time in order to facilitate the process of modifying and completing the creation of his CV.",

"Resume data will not be stored if the user does not log in, and he will not have the ability to reprint or edit for free",

"After logging in, the user has the right to reprint or modify his previously created CV at any time and more than once for free.",

"The data entered by the user for the purpose of creating a CV is data that belongs to the user only. We do not restrict, monitor, or track it, and the user bears full responsibility for writing it on his CV.",

"The process of paying and receiving money takes place through a reliable financial party. We do not have the right to retain or even view any financial data related to the user for any reason whatsoever.",

"If the design you obtained does not match the design you chose, or there is a problem with the format, or the data is not clear due to a technical malfunction, you can contact us to review the error.",
*/