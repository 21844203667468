export const cities = [
  "Afghanistan",
  "Herat (Afghanistan)",
  "Kabul (Afghanistan)",
  "Kandahar (Afghanistan)",
  "Molah (Afghanistan)",
  "Rana (Afghanistan)",
  "Shar (Afghanistan)",
  "Sharif (Afghanistan)",
  "Wazir Akbar Khan (Afghanistan)",
  "Albania",
  "Elbasan (Albania)",
  "Petran (Albania)",
  "Pogradec (Albania)",
  "Shkoder (Albania)",
  "Tirana (Albania)",
  "Ura Vajgurore (Albania)",
  "Algeria",
  "Algiers (Algeria)",
  "Annaba (Algeria)",
  "Azazga (Algeria)",
  "Batna City (Algeria)",
  "Blida (Algeria)",
  "Bordj (Algeria)",
  "Bordj Bou Arreridj (Algeria)",
  "Bougara (Algeria)",
  "Cheraga (Algeria)",
  "Chlef (Algeria)",
  "Constantine (Algeria)",
  "Djelfa (Algeria)",
  "Draria (Algeria)",
  "El Tarf (Algeria)",
  "Hussein Dey (Algeria)",
  "Illizi (Algeria)",
  "Jijel (Algeria)",
  "Kouba (Algeria)",
  "Laghouat (Algeria)",
  "Oran (Algeria)",
  "Ouargla (Algeria)",
  "Oued Smar (Algeria)",
  "Relizane (Algeria)",
  "Rouiba (Algeria)",
  "Saida (Algeria)",
  "Souk Ahras (Algeria)",
  "Tamanghasset (Algeria)",
  "Tiaret (Algeria)",
  "Tissemsilt (Algeria)",
  "Tizi (Algeria)",
  "Tizi Ouzou (Algeria)",
  "Tlemcen (Algeria)",
  "Andorra",
  "Andorra la Vella (Andorra)",
  "Canillo (Andorra)",
  "Encamp (Andorra)",
  "Engordany (Andorra)",
  "Escaldes-Engordany (Andorra)",
  "La Massana (Andorra)",
  "Llorts (Andorra)",
  "Ordino (Andorra)",
  "Santa Coloma (Andorra)",
  "Sispony (Andorra)",
  "Soldeu (Andorra)",
  "Angola",
  "Ambriz (Angola)",
  "Benguela (Angola)",
  "Cabinda (Angola)",
  "Cacole (Angola)",
  "Camabatela (Angola)",
  "Cazeta (Angola)",
  "Huambo (Angola)",
  "Kuito (Angola)",
  "Lobito (Angola)",
  "Luanda (Angola)",
  "Lubango (Angola)",
  "Lucapa (Angola)",
  "Lumeje (Angola)",
  "Malanje (Angola)",
  "Menongue (Angola)",
  "Muxaluando (Angola)",
  "Namibe (Angola)",
  "Ondjiva (Angola)",
  "Piri (Angola)",
  "Saurimo (Angola)",
  "Talatona (Angola)",
  "Antigua and Barbuda",
  "All Saints (Antigua and Barbuda)",
  "Cassada Gardens (Antigua and Barbuda)",
  "Codrington (Antigua and Barbuda)",
  "Old Road (Antigua and Barbuda)",
  "Parham (Antigua and Barbuda)",
  "Woods (Antigua and Barbuda)",
  "Argentina",
  "28 de Noviembre (Argentina)",
  "Abasto (Argentina)",
  "Acassuso (Argentina)",
  "Acebal (Argentina)",
  "Acevedo (Argentina)",
  "Adelia Maria (Argentina)",
  "Agua de Oro (Argentina)",
  "Albardon (Argentina)",
  "Albarellos (Argentina)",
  "Alberdi (Argentina)",
  "Alberti (Argentina)",
  "Aldo Bonzi (Argentina)",
  "Alejandro Korn (Argentina)",
  "Alicia (Argentina)",
  "Allen (Argentina)",
  "Almafuerte (Argentina)",
  "Almagro (Argentina)",
  "Almirante Brown (Argentina)",
  "Alta Gracia (Argentina)",
  "Alta Italia (Argentina)",
  "Alvarez (Argentina)",
  "Alvear (Argentina)",
  "Anatuya (Argentina)",
  "Angelica (Argentina)",
  "Antonio Carboni (Argentina)",
  "Apostoles (Argentina)",
  "Arequito (Argentina)",
  "Armstrong (Argentina)",
  "Arrecifes (Argentina)",
  "Arroyito (Argentina)",
  "Arroyito Challaco (Argentina)",
  "Arroyo Seco (Argentina)",
  "Arteaga (Argentina)",
  "Asamblea (Argentina)",
  "Ascension (Argentina)",
  "Avellaneda (Argentina)",
  "Ayacucho (Argentina)",
  "Azara (Argentina)",
  "Azul (Argentina)",
  "Balcarce (Argentina)",
  "Balnearia (Argentina)",
  "Banda del Rio Sali (Argentina)",
  "Bandera (Argentina)",
  "Banfield (Argentina)",
  "Baradero (Argentina)",
  "Bariloche (Argentina)",
  "Barrio Fisherton (Argentina)",
  "Batan (Argentina)",
  "Beccar (Argentina)",
  "Belen de Escobar (Argentina)",
  "Belgrano (Argentina)",
  "Bell Ville (Argentina)",
  "Bella Vista (Argentina)",
  "Benavidez (Argentina)",
  "Berazategui (Argentina)",
  "Berisso (Argentina)",
  "Bernal (Argentina)",
  "Bernardo Larroude (Argentina)",
  "Bernasconi (Argentina)",
  "Bigand (Argentina)",
  "Bombal (Argentina)",
  "Bordenave (Argentina)",
  "Bosch (Argentina)",
  "Bosques (Argentina)",
  "Boulogne (Argentina)",
  "Bovril (Argentina)",
  "Bragado (Argentina)",
  "Brandsen (Argentina)",
  "Brinkmann (Argentina)",
  "Buenos Aires (Argentina)",
  "Burzaco (Argentina)",
  "Bustinza (Argentina)",
  "Caballito (Argentina)",
  "Calderon (Argentina)",
  "Caleta Olivia (Argentina)",
  "Caleufu (Argentina)",
  "Camilo Aldao (Argentina)",
  "Campana (Argentina)",
  "Canada de Gomez (Argentina)",
  "Canada del Ucle (Argentina)",
  "Canada Rosquin (Argentina)",
  "Canals (Argentina)",
  "Canning (Argentina)",
  "Canuelas (Argentina)",
  "Capilla del Monte (Argentina)",
  "Capilla del Senor (Argentina)",
  "Capitan Bermudez (Argentina)",
  "Carhue (Argentina)",
  "Carlos Casares (Argentina)",
  "Carlos Pellegrini (Argentina)",
  "Carlos Tejedor (Argentina)",
  "Caseros (Argentina)",
  "Casilda (Argentina)",
  "Castelar (Argentina)",
  "Castelli (Argentina)",
  "Castillo (Argentina)",
  "Catriel (Argentina)",
  "Catrilo (Argentina)",
  "Cavanagh (Argentina)",
  "Centenario (Argentina)",
  "Ceres (Argentina)",
  "Cervantes (Argentina)",
  "Chacabuco (Argentina)",
  "Chacarita (Argentina)",
  "Chajari (Argentina)",
  "Charata (Argentina)",
  "Chateaubriand (Argentina)",
  "Chilecito (Argentina)",
  "Chivilcoy (Argentina)",
  "Choele Choel (Argentina)",
  "Chorroarin (Argentina)",
  "Cinco Saltos (Argentina)",
  "Cipolletti (Argentina)",
  "City Bell (Argentina)",
  "Ciudad General Belgrano (Argentina)",
  "Ciudadela (Argentina)",
  "Claypole (Argentina)",
  "Clorinda (Argentina)",
  "Colon (Argentina)",
  "Colonia Baron (Argentina)",
  "Colonia Caroya (Argentina)",
  "Colonia San Miguel Arcangel (Argentina)",
  "Comodoro Rivadavia (Argentina)",
  "Concepcion (Argentina)",
  "Concordia (Argentina)",
  "Constituyentes (Argentina)",
  "Coronel Dorrego (Argentina)",
  "Coronel Martinez de Hoz (Argentina)",
  "Coronel Pringles (Argentina)",
  "Corral de Bustos (Argentina)",
  "Corralitos (Argentina)",
  "Corrientes (Argentina)",
  "Cosquin (Argentina)",
  "Coy Aike (Argentina)",
  "Cramer (Argentina)",
  "Crespo (Argentina)",
  "Cruz del Eje (Argentina)",
  "Curuzu Cuatia (Argentina)",
  "Cutral-Co (Argentina)",
  "Darregueira (Argentina)",
  "De Mayo (Argentina)",
  "Del Campillo (Argentina)",
  "Del Viso (Argentina)",
  "Despenaderos (Argentina)",
  "Devoto (Argentina)",
  "Diaz (Argentina)",
  "Diego de Alvear (Argentina)",
  "Doblas (Argentina)",
  "Dock Sud (Argentina)",
  "Dolores (Argentina)",
  "Don Bosco (Argentina)",
  "Don Torcuato (Argentina)",
  "Drabble (Argentina)",
  "Eduardo Castex (Argentina)",
  "El Calafate (Argentina)",
  "El Dorado (Argentina)",
  "El Hoyo (Argentina)",
  "El Palomar (Argentina)",
  "El Talar (Argentina)",
  "El Trebol (Argentina)",
  "Eldorado (Argentina)",
  "Embalse (Argentina)",
  "Empalme Lobos (Argentina)",
  "Ensenada (Argentina)",
  "Esperanza (Argentina)",
  "Esquel (Argentina)",
  "Esteban Echeverria (Argentina)",
  "Ezeiza (Argentina)",
  "Ezpeleta (Argentina)",
  "Famailla (Argentina)",
  "Fatima (Argentina)",
  "Federal (Argentina)",
  "Fernandez (Argentina)",
  "Firmat (Argentina)",
  "Florencio Varela (Argentina)",
  "Florentino Ameghino (Argentina)",
  "Flores (Argentina)",
  "Floresta (Argentina)",
  "Florida (Argentina)",
  "Formosa (Argentina)",
  "Francisco Alvarez (Argentina)",
  "Franck (Argentina)",
  "Fray Luis A. Beltran (Argentina)",
  "Freyre (Argentina)",
  "Frias (Argentina)",
  "Funes (Argentina)",
  "Gaiman (Argentina)",
  "Galvez (Argentina)",
  "Garin (Argentina)",
  "Garupa (Argentina)",
  "General Acha (Argentina)",
  "General Alvear (Argentina)",
  "General Cabrera (Argentina)",
  "General Deheza (Argentina)",
  "General Guido (Argentina)",
  "General Juan Madariaga (Argentina)",
  "General Lagos (Argentina)",
  "General Las Heras (Argentina)",
  "General Lavalle (Argentina)",
  "General Mansilla (Argentina)",
  "General Martin Miguel de Guemes (Argentina)",
  "General Pacheco (Argentina)",
  "General Paz (Argentina)",
  "General Pico (Argentina)",
  "General Roca (Argentina)",
  "General Rodriguez (Argentina)",
  "General San Martin (Argentina)",
  "General Viamonte (Argentina)",
  "General Villegas (Argentina)",
  "Germania (Argentina)",
  "Glew (Argentina)",
  "Gobernador Crespo (Argentina)",
  "Gobernador Galvez (Argentina)",
  "Godoy (Argentina)",
  "Godoy Cruz (Argentina)",
  "Gonzalez Catan (Argentina)",
  "Gonzalez Moreno (Argentina)",
  "Goya (Argentina)",
  "Granadero Baigorria (Argentina)",
  "Grand Bourg (Argentina)",
  "Gualeguay (Argentina)",
  "Guatrache (Argentina)",
  "Guernica (Argentina)",
  "Henderson (Argentina)",
  "Hernando (Argentina)",
  "Hersilia (Argentina)",
  "Hilario (Argentina)",
  "Hilario Ascasubi (Argentina)",
  "Hipatia (Argentina)",
  "Hipolito Yrigoyen (Argentina)",
  "Huanchillas (Argentina)",
  "Huanguelen (Argentina)",
  "Huinca Renanco (Argentina)",
  "Humahuaca (Argentina)",
  "Hurlingham (Argentina)",
  "Ibarlucea (Argentina)",
  "Ibicuy (Argentina)",
  "Independencia (Argentina)",
  "Ingeniero Beaugey (Argentina)",
  "Ingeniero Luiggi (Argentina)",
  "Ingeniero Maschwitz (Argentina)",
  "Intendente Alvear (Argentina)",
  "Isidro Casanova (Argentina)",
  "Ituzaingo (Argentina)",
  "James Craik (Argentina)",
  "Jauregui (Argentina)",
  "Jeppener (Argentina)",
  "Jesus Maria (Argentina)",
  "Jose Leon Suarez (Argentina)",
  "Jose Marmol (Argentina)",
  "Juan Pujol (Argentina)",
  "Justiniano Posse (Argentina)",
  "La Banda (Argentina)",
  "La Boca (Argentina)",
  "La Calera (Argentina)",
  "La Cumbre (Argentina)",
  "La Falda (Argentina)",
  "La Leonesa (Argentina)",
  "La Lucila (Argentina)",
  "La Madrid (Argentina)",
  "La Pampa (Argentina)",
  "La Para (Argentina)",
  "La Paz (Argentina)",
  "La Plata (Argentina)",
  "La Punta (Argentina)",
  "La Rioja (Argentina)",
  "La Tablada (Argentina)",
  "La Union (Argentina)",
  "La Violeta (Argentina)",
  "Laborde (Argentina)",
  "Laboulaye (Argentina)",
  "Laferrere (Argentina)",
  "Laguna Alsina (Argentina)",
  "Lanus (Argentina)",
  "Larroque (Argentina)",
  "Las Catitas (Argentina)",
  "Las Flores (Argentina)",
  "Las Heras (Argentina)",
  "Las Perdices (Argentina)",
  "Las Rosas (Argentina)",
  "Las Talitas (Argentina)",
  "Las Varillas (Argentina)",
  "Lavalle (Argentina)",
  "Leandro N. Alem (Argentina)",
  "Leones (Argentina)",
  "Libertad (Argentina)",
  "Lima (Argentina)",
  "Liniers (Argentina)",
  "Llavallol (Argentina)",
  "Lobos (Argentina)",
  "Lomas de Zamora (Argentina)",
  "Lomas del Mirador (Argentina)",
  "Longchamps (Argentina)",
  "Los Antiguos (Argentina)",
  "Los Cardales (Argentina)",
  "Los Molinos (Argentina)",
  "Los Polvorines (Argentina)",
  "Luis Guillon (Argentina)",
  "Lujan de Cuyo (Argentina)",
  "Luque (Argentina)",
  "Luzuriaga (Argentina)",
  "Lynch (Argentina)",
  "Macachin (Argentina)",
  "Magdalena (Argentina)",
  "Maggiolo (Argentina)",
  "Maipu (Argentina)",
  "Manantial (Argentina)",
  "Manfredi (Argentina)",
  "Manuel J. Cobo (Argentina)",
  "Maquinista Savio (Argentina)",
  "Mar de Ajo (Argentina)",
  "Mar del Plata (Argentina)",
  "Mar del Tuyu (Argentina)",
  "Marcos Juarez (Argentina)",
  "Marcos Paz (Argentina)",
  "Margarita (Argentina)",
  "Maria Ignacia (Argentina)",
  "Maria Juana (Argentina)",
  "Mariano Acosta (Argentina)",
  "Mariano J. Haedo (Argentina)",
  "Mariano Moreno (Argentina)",
  "Martinez (Argentina)",
  "Matheu (Argentina)",
  "Mayor Buratovich (Argentina)",
  "Melincue (Argentina)",
  "Mendiolaza (Argentina)",
  "Mendoza (Argentina)",
  "Mercedes (Argentina)",
  "Merlo (Argentina)",
  "Minacar (Argentina)",
  "Miramar (Argentina)",
  "Monje (Argentina)",
  "Monte Hermoso (Argentina)",
  "Monteros (Argentina)",
  "Montserrat (Argentina)",
  "Moreno (Argentina)",
  "Moron (Argentina)",
  "Morteros (Argentina)",
  "Muniz (Argentina)",
  "Munro (Argentina)",
  "Navarro (Argentina)",
  "Necochea (Argentina)",
  "Nogoya (Argentina)",
  "Nordelta (Argentina)",
  "Nunez (Argentina)",
  "Obera (Argentina)",
  "Oliva (Argentina)",
  "Oliveros (Argentina)",
  "Olivos (Argentina)",
  "Oncativo (Argentina)",
  "Open Door (Argentina)",
  "Ordonez (Argentina)",
  "Palermo (Argentina)",
  "Palmira (Argentina)",
  "Palpala (Argentina)",
  "Partido de Jose C. Paz (Argentina)",
  "Pasco (Argentina)",
  "Paso del Rey (Argentina)",
  "Paternal (Argentina)",
  "Pavon (Argentina)",
  "Pedernales (Argentina)",
  "Pedro Luro (Argentina)",
  "Pellegrini (Argentina)",
  "Perez (Argentina)",
  "Pergamino (Argentina)",
  "Perico (Argentina)",
  "Perito Moreno (Argentina)",
  "Piamonte (Argentina)",
  "Pico de Salamanca (Argentina)",
  "Pico Truncado (Argentina)",
  "Pigue (Argentina)",
  "Pilar (Argentina)",
  "Pinamar (Argentina)",
  "Piquete Cabado (Argentina)",
  "Platanos (Argentina)",
  "Plaza Huincul (Argentina)",
  "Plottier (Argentina)",
  "Pontevedra (Argentina)",
  "Portena (Argentina)",
  "Posadas (Argentina)",
  "Pozo del Molle (Argentina)",
  "Presidente Derqui (Argentina)",
  "Puan (Argentina)",
  "Pueblo San Jose (Argentina)",
  "Puerto Madryn (Argentina)",
  "Puerto Rico (Argentina)",
  "Pueyrredon (Argentina)",
  "Punta Alta (Argentina)",
  "Quilmes (Argentina)",
  "Rada Tilly (Argentina)",
  "Rafael Calzada (Argentina)",
  "Rafael Castillo (Argentina)",
  "Rafael Obligado (Argentina)",
  "Rafaela (Argentina)",
  "Ramallo (Argentina)",
  "Ramos Mejia (Argentina)",
  "Ranchos (Argentina)",
  "Rancul (Argentina)",
  "Ranelagh (Argentina)",
  "Rawson (Argentina)",
  "Realico (Argentina)",
  "Recoleta (Argentina)",
  "Reconquista (Argentina)",
  "Remedios de Escalada (Argentina)",
  "Resistencia (Argentina)",
  "Retiro (Argentina)",
  "Rio Ceballos (Argentina)",
  "Rio Colorado (Argentina)",
  "Rio Grande (Argentina)",
  "Rio Piedras (Argentina)",
  "Rio Segundo (Argentina)",
  "Rio Tercero (Argentina)",
  "Rivadavia (Argentina)",
  "Rocamora (Argentina)",
  "Rodriguez Pena (Argentina)",
  "Rojas (Argentina)",
  "Roldan (Argentina)",
  "Roque Perez (Argentina)",
  "Rosario (Argentina)",
  "Rosas (Argentina)",
  "Rufino (Argentina)",
  "Sacanta (Argentina)",
  "Saenz Pena (Argentina)",
  "Saladillo (Argentina)",
  "Salguero (Argentina)",
  "Salsipuedes (Argentina)",
  "Salta (Argentina)",
  "Salto (Argentina)",
  "Salto Grande (Argentina)",
  "Sampacho (Argentina)",
  "San Andres (Argentina)",
  "San Andres de Giles (Argentina)",
  "San Antonio de Areco (Argentina)",
  "San Antonio de Arredondo (Argentina)",
  "San Antonio de Obligado (Argentina)",
  "San Antonio de Padua (Argentina)",
  "San Antonio Oeste (Argentina)",
  "San Benito (Argentina)",
  "San Bernardo (Argentina)",
  "San Carlos de Bolivar (Argentina)",
  "San Cayetano (Argentina)",
  "San Clemente (Argentina)",
  "San Cristobal (Argentina)",
  "San Fernando (Argentina)",
  "San Fernando del Valle de Catamarca (Argentina)",
  "San Francisco (Argentina)",
  "San Francisco de Santa Fe (Argentina)",
  "San Francisco Solano (Argentina)",
  "San Genaro (Argentina)",
  "San Gregorio (Argentina)",
  "San Guillermo (Argentina)",
  "San Isidro (Argentina)",
  "San Isidro de Lules (Argentina)",
  "San Javier (Argentina)",
  "San Jeronimo Norte (Argentina)",
  "San Jorge (Argentina)",
  "San Jose (Argentina)",
  "San Jose de la Esquina (Argentina)",
  "San Juan (Argentina)",
  "San Justo (Argentina)",
  "San Lorenzo (Argentina)",
  "San Luis (Argentina)",
  "San Manuel (Argentina)",
  "San Martin (Argentina)",
  "San Martin de las Escobas (Argentina)",
  "San Miguel (Argentina)",
  "San Miguel del Monte (Argentina)",
  "San Nicolas (Argentina)",
  "San Pedro (Argentina)",
  "San Rafael (Argentina)",
  "San Salvador (Argentina)",
  "San Salvador de Jujuy (Argentina)",
  "San Telmo (Argentina)",
  "San Vicente (Argentina)",
  "Sanchez (Argentina)",
  "Santa Clara de Saguier (Argentina)",
  "Santa Elena (Argentina)",
  "Santa Fe (Argentina)",
  "Santa Lucia (Argentina)",
  "Santa Rita (Argentina)",
  "Santa Rosa (Argentina)",
  "Santa Teresita (Argentina)",
  "Santiago del Estero (Argentina)",
  "Santo Tome (Argentina)",
  "Santos Lugares (Argentina)",
  "Sarandi (Argentina)",
  "Sarmiento (Argentina)",
  "Segui (Argentina)",
  "Sierra de la Ventana (Argentina)",
  "Sierra de los Padres (Argentina)",
  "Sinsacate (Argentina)",
  "Suipacha (Argentina)",
  "Sunchales (Argentina)",
  "Tablada (Argentina)",
  "Tacuari (Argentina)",
  "Tafi Viejo (Argentina)",
  "Tandil (Argentina)",
  "Tapalque (Argentina)",
  "Tapiales (Argentina)",
  "Temperley (Argentina)",
  "Teodelina (Argentina)",
  "Thames (Argentina)",
  "Tigre (Argentina)",
  "Tio Pujio (Argentina)",
  "Todd (Argentina)",
  "Tornquist (Argentina)",
  "Tortuguitas (Argentina)",
  "Tostado (Argentina)",
  "Totoras (Argentina)",
  "Trelew (Argentina)",
  "Trenque Lauquen (Argentina)",
  "Tres Arroyos (Argentina)",
  "Trevelin (Argentina)",
  "Tristan Suarez (Argentina)",
  "Tunuyan (Argentina)",
  "Tupungato (Argentina)",
  "Turdera (Argentina)",
  "Ucacha (Argentina)",
  "Uriburu (Argentina)",
  "Ushuaia (Argentina)",
  "Valle Hermoso (Argentina)",
  "Vedia (Argentina)",
  "Veinticinco de Mayo (Argentina)",
  "Venado Tuerto (Argentina)",
  "Vera (Argentina)",
  "Veronica (Argentina)",
  "Viale (Argentina)",
  "Viamonte (Argentina)",
  "Vicente Lopez (Argentina)",
  "Victoria (Argentina)",
  "Vicuna Mackenna (Argentina)",
  "Viedma (Argentina)",
  "Villa Aberastain (Argentina)",
  "Villa Adelina (Argentina)",
  "Villa Allende (Argentina)",
  "Villa Alsina (Argentina)",
  "Villa Amelia (Argentina)",
  "Villa Angela (Argentina)",
  "Villa Ballester (Argentina)",
  "Villa Bosch (Argentina)",
  "Villa Canas (Argentina)",
  "Villa Carlos Paz (Argentina)",
  "Villa Constitucion (Argentina)",
  "Villa de Maria (Argentina)",
  "Villa de Mayo (Argentina)",
  "Villa del Parque (Argentina)",
  "Villa Dolores (Argentina)",
  "Villa Dominico (Argentina)",
  "Villa Elisa (Argentina)",
  "Villa General Belgrano (Argentina)",
  "Villa Gesell (Argentina)",
  "Villa Giardino (Argentina)",
  "Villa Huidobro (Argentina)",
  "Villa Insuperable (Argentina)",
  "Villa La Angostura (Argentina)",
  "Villa Las Rosas (Argentina)",
  "Villa Lugano (Argentina)",
  "Villa Luzuriaga (Argentina)",
  "Villa Madero (Argentina)",
  "Villa Maria Grande (Argentina)",
  "Villa Media Agua (Argentina)",
  "Villa Mercedes (Argentina)",
  "Villa Nueva (Argentina)",
  "Villa Ocampo (Argentina)",
  "Villa Paranacito (Argentina)",
  "Villa Regina (Argentina)",
  "Villa Robles (Argentina)",
  "Villa Rosa (Argentina)",
  "Villa Trinidad (Argentina)",
  "Villa Urquiza (Argentina)",
  "Villaguay (Argentina)",
  "Vuelta de Obligado (Argentina)",
  "Warnes (Argentina)",
  "Wheelwright (Argentina)",
  "Wilde (Argentina)",
  "Winifreda (Argentina)",
  "Yerba Buena (Argentina)",
  "Zenon Pereyra (Argentina)",
  "Armenia",
  "Abovyan (Armenia)",
  "Agarak (Armenia)",
  "Apaga (Armenia)",
  "Aparan (Armenia)",
  "Arabkir (Armenia)",
  "Ashtarak (Armenia)",
  "Erebuni Fortress (Armenia)",
  "Hrazdan (Armenia)",
  "Ijevan (Armenia)",
  "Jermuk (Armenia)",
  "Kapan (Armenia)",
  "Tsaghkadzor (Armenia)",
  "Vanadzor (Armenia)",
  "Yerevan (Armenia)",
  "Aruba",
  "Noord (Aruba)",
  "Oranjestad (Aruba)",
  "Palm Beach (Aruba)",
  "Paradera (Aruba)",
  "Ponton (Aruba)",
  "Sabaneta (Aruba)",
  "San Barbola (Aruba)",
  "Santa Cruz (Aruba)",
  "Sero Blanco (Aruba)",
  "Sint Nicolaas (Aruba)",
  "Tanki Lender (Aruba)",
  "Australia",
  "Abbotsford (Australia)",
  "Abbotsham (Australia)",
  "Aberdeen (Australia)",
  "Aberfoyle (Australia)",
  "Aberglasslyn (Australia)",
  "Abermain (Australia)",
  "Acacia Ridge (Australia)",
  "Adamstown (Australia)",
  "Adelaide (Australia)",
  "Adelong (Australia)",
  "Advancetown (Australia)",
  "Agnes Banks (Australia)",
  "Agnes Water (Australia)",
  "Airlie Beach (Australia)",
  "Aitkenvale (Australia)",
  "Albany (Australia)",
  "Albany Creek (Australia)",
  "Albert Park (Australia)",
  "Alberton (Australia)",
  "Albion (Australia)",
  "Albion Park (Australia)",
  "Albury (Australia)",
  "Aldgate (Australia)",
  "Aldinga (Australia)",
  "Aldinga Beach (Australia)",
  "Alexandra (Australia)",
  "Alexandria (Australia)",
  "Alfredton (Australia)",
  "Alice Springs (Australia)",
  "Allambee (Australia)",
  "Allansford (Australia)",
  "Allanson (Australia)",
  "Allora (Australia)",
  "Alma (Australia)",
  "Alphington (Australia)",
  "Alpine (Australia)",
  "Alstonville (Australia)",
  "Alton Downs (Australia)",
  "Altona (Australia)",
  "Amamoor (Australia)",
  "Amaroo (Australia)",
  "Angaston (Australia)",
  "Anglesea (Australia)",
  "Anna Bay (Australia)",
  "Annandale (Australia)",
  "Apollo Bay (Australia)",
  "Applecross (Australia)",
  "Applethorpe (Australia)",
  "Ararat (Australia)",
  "Arcadia (Australia)",
  "Argents Hill (Australia)",
  "Armadale (Australia)",
  "Armidale (Australia)",
  "Armstrong Creek (Australia)",
  "Arno Bay (Australia)",
  "Arrawarra (Australia)",
  "Artarmon (Australia)",
  "Ascot (Australia)",
  "Ascot Vale (Australia)",
  "Ashburton (Australia)",
  "Ashfield (Australia)",
  "Ashgrove (Australia)",
  "Ashton (Australia)",
  "Aspendale (Australia)",
  "Aspley (Australia)",
  "Asquith (Australia)",
  "Atherton (Australia)",
  "Attadale (Australia)",
  "Auburn (Australia)",
  "Augusta (Australia)",
  "Austinmer (Australia)",
  "Austins Ferry (Australia)",
  "Australind (Australia)",
  "Avalon (Australia)",
  "Avenue (Australia)",
  "Avoca Beach (Australia)",
  "Avondale (Australia)",
  "Axedale (Australia)",
  "Aylmerton (Australia)",
  "Ayr (Australia)",
  "Babinda (Australia)",
  "Bacchus Marsh (Australia)",
  "Bagdad (Australia)",
  "Bahrs Scrub (Australia)",
  "Bairnsdale (Australia)",
  "Baker (Australia)",
  "Balaclava (Australia)",
  "Balaklava (Australia)",
  "Bald Hills (Australia)",
  "Balgowlah (Australia)",
  "Balgownie (Australia)",
  "Balhannah (Australia)",
  "Ballan (Australia)",
  "Ballarat (Australia)",
  "Ballina (Australia)",
  "Balmain (Australia)",
  "Balmoral (Australia)",
  "Balnarring (Australia)",
  "Balwyn (Australia)",
  "Balwyn North (Australia)",
  "Bangalow (Australia)",
  "Bangor (Australia)",
  "Banksmeadow (Australia)",
  "Bankstown (Australia)",
  "Bannockburn (Australia)",
  "Barcoo (Australia)",
  "Barellan (Australia)",
  "Bargara (Australia)",
  "Bargo (Australia)",
  "Baringhup (Australia)",
  "Barkstead (Australia)",
  "Barnsley (Australia)",
  "Barry (Australia)",
  "Barwon Downs (Australia)",
  "Barwon Heads (Australia)",
  "Basin View (Australia)",
  "Bass Hill (Australia)",
  "Bassendean (Australia)",
  "Batchelor (Australia)",
  "Bateau Bay (Australia)",
  "Batesford (Australia)",
  "Bathurst (Australia)",
  "Batlow (Australia)",
  "Baulkham Hills (Australia)",
  "Baxter (Australia)",
  "Bayles (Australia)",
  "Baynton (Australia)",
  "Bayswater (Australia)",
  "Beachmere (Australia)",
  "Beacon (Australia)",
  "Beaconsfield (Australia)",
  "Beaconsfield Upper (Australia)",
  "Beaudesert (Australia)",
  "Beaumaris (Australia)",
  "Beaumont (Australia)",
  "Beauty Point (Australia)",
  "Bedford Park (Australia)",
  "Bedfordale (Australia)",
  "Beeac (Australia)",
  "Beechwood (Australia)",
  "Beechworth (Australia)",
  "Beenak (Australia)",
  "Beenleigh (Australia)",
  "Beerwah (Australia)",
  "Bega (Australia)",
  "Belair (Australia)",
  "Belconnen (Australia)",
  "Belgian Gardens (Australia)",
  "Belgrave (Australia)",
  "Belgrave Heights (Australia)",
  "Bell (Australia)",
  "Bellambi (Australia)",
  "Bellara (Australia)",
  "Bellbird (Australia)",
  "Bellbrae (Australia)",
  "Bellerive (Australia)",
  "Bellevue Hill (Australia)",
  "Belmont (Australia)",
  "Belrose (Australia)",
  "Benalla (Australia)",
  "Benambra (Australia)",
  "Bendigo (Australia)",
  "Benowa (Australia)",
  "Bensville (Australia)",
  "Bentleigh (Australia)",
  "Bentleigh East (Australia)",
  "Bentley (Australia)",
  "Beresfield (Australia)",
  "Berkeley (Australia)",
  "Berkeley Vale (Australia)",
  "Berowra (Australia)",
  "Berridale (Australia)",
  "Berrima (Australia)",
  "Berry (Australia)",
  "Berwick (Australia)",
  "Bethania Junction (Australia)",
  "Beveridge (Australia)",
  "Beverley (Australia)",
  "Bexley (Australia)",
  "Bicheno (Australia)",
  "Bicton (Australia)",
  "Big Pats Creek (Australia)",
  "Bilinga (Australia)",
  "Biloela (Australia)",
  "Bilpin (Australia)",
  "Binda (Australia)",
  "Binnaway (Australia)",
  "Birdwood (Australia)",
  "Birkdale (Australia)",
  "Birkenhead (Australia)",
  "Black Hill (Australia)",
  "Black Rock (Australia)",
  "Blackburn (Australia)",
  "Blackbutt (Australia)",
  "Blackheath (Australia)",
  "Blackmans Bay (Australia)",
  "Blacktown (Australia)",
  "Blackwall (Australia)",
  "Blackwarry (Australia)",
  "Blackwater (Australia)",
  "Blackwood (Australia)",
  "Blair Athol (Australia)",
  "Blakehurst (Australia)",
  "Blaxland (Australia)",
  "Blayney (Australia)",
  "Bli Bli (Australia)",
  "Boambee (Australia)",
  "Boat Harbour (Australia)",
  "Bobin (Australia)",
  "Boddington (Australia)",
  "Bogangar (Australia)",
  "Bohle Plains (Australia)",
  "Bolton (Australia)",
  "Bolwarra (Australia)",
  "Bomaderry (Australia)",
  "Bombala (Australia)",
  "Bonbeach (Australia)",
  "Bondi (Australia)",
  "Boneo (Australia)",
  "Bongaree (Australia)",
  "Bonnells Bay (Australia)",
  "Bonnet Bay (Australia)",
  "Bonython (Australia)",
  "Booker Bay (Australia)",
  "Bool Lagoon (Australia)",
  "Boolarra (Australia)",
  "Boonah (Australia)",
  "Boondall (Australia)",
  "Booral (Australia)",
  "Bordertown (Australia)",
  "Boronia (Australia)",
  "Botany (Australia)",
  "Boulder (Australia)",
  "Bourke (Australia)",
  "Bowen (Australia)",
  "Bowenfels (Australia)",
  "Bowral (Australia)",
  "Bowraville (Australia)",
  "Box Hill (Australia)",
  "Box Hill South (Australia)",
  "Boyup Brook (Australia)",
  "Bradbury (Australia)",
  "Braddon (Australia)",
  "Braeside (Australia)",
  "Braidwood (Australia)",
  "Brandon (Australia)",
  "Brandy Hill (Australia)",
  "Branxton (Australia)",
  "Brewarrina (Australia)",
  "Briar Hill (Australia)",
  "Bridgetown (Australia)",
  "Bridgewater (Australia)",
  "Brighton (Australia)",
  "Brighton East (Australia)",
  "Brighton-Le-Sands (Australia)",
  "Bringelly (Australia)",
  "Brisbane (Australia)",
  "Broadbeach (Australia)",
  "Broadford (Australia)",
  "Broadmeadows (Australia)",
  "Broadwater (Australia)",
  "Broke (Australia)",
  "Broken Hill (Australia)",
  "Bronte (Australia)",
  "Brookdale (Australia)",
  "Brookfield (Australia)",
  "Brooklyn (Australia)",
  "Brookvale (Australia)",
  "Broome (Australia)",
  "Brown Hill (Australia)",
  "Browns Plains (Australia)",
  "Bruce (Australia)",
  "Brunswick (Australia)",
  "Buchan (Australia)",
  "Buckland (Australia)",
  "Buderim (Australia)",
  "Budgewoi (Australia)",
  "Bulahdelah (Australia)",
  "Bulga (Australia)",
  "Bulimba (Australia)",
  "Bulla (Australia)",
  "Bulleen (Australia)",
  "Bulli (Australia)",
  "Bullsbrook (Australia)",
  "Bullyard (Australia)",
  "Buln Buln (Australia)",
  "Bunbury (Australia)",
  "Bundaberg (Australia)",
  "Bundalong (Australia)",
  "Bundamba (Australia)",
  "Bundanoon (Australia)",
  "Bundeena (Australia)",
  "Bundook (Australia)",
  "Bundoora (Australia)",
  "Bungendore (Australia)",
  "Bungonia (Australia)",
  "Buninyong (Australia)",
  "Burekup (Australia)",
  "Burleigh Heads (Australia)",
  "Burnett Heads (Australia)",
  "Burnie (Australia)",
  "Burnley (Australia)",
  "Burnside (Australia)",
  "Burpengary (Australia)",
  "Burradoo (Australia)",
  "Burraneer (Australia)",
  "Burrum (Australia)",
  "Burrum Heads (Australia)",
  "Burwood (Australia)",
  "Bushland Beach (Australia)",
  "Busselton (Australia)",
  "Butchers Ridge (Australia)",
  "Buxton (Australia)",
  "Byford (Australia)",
  "Byron Bay (Australia)",
  "Caboolture (Australia)",
  "Cairns (Australia)",
  "Calen (Australia)",
  "Calingiri (Australia)",
  "Callala Bay (Australia)",
  "Callington (Australia)",
  "Caloundra (Australia)",
  "Caltowie (Australia)",
  "Camberwell (Australia)",
  "Cambewarra (Australia)",
  "Cambridge (Australia)",
  "Camden (Australia)",
  "Camden Haven (Australia)",
  "Cammeray (Australia)",
  "Campbell (Australia)",
  "Campbellfield (Australia)",
  "Campbelltown (Australia)",
  "Camperdown (Australia)",
  "Canadian (Australia)",
  "Canberra (Australia)",
  "Cannington (Australia)",
  "Cannonvale (Australia)",
  "Canterbury (Australia)",
  "Canungra (Australia)",
  "Capalaba (Australia)",
  "Caragabal (Australia)",
  "Caravonica (Australia)",
  "Carbrook (Australia)",
  "Cardiff (Australia)",
  "Cardigan (Australia)",
  "Cardinia (Australia)",
  "Cardup (Australia)",
  "Caringbah (Australia)",
  "Carlingford (Australia)",
  "Carlsruhe (Australia)",
  "Carlton (Australia)",
  "Carlton North (Australia)",
  "Carlton South (Australia)",
  "Carnarvon (Australia)",
  "Carnegie (Australia)",
  "Caroline Springs (Australia)",
  "Carrick (Australia)",
  "Carrington (Australia)",
  "Carrum (Australia)",
  "Carrum Downs (Australia)",
  "Casino (Australia)",
  "Casterton (Australia)",
  "Castle Hill (Australia)",
  "Castlecrag (Australia)",
  "Castlemaine (Australia)",
  "Caulfield (Australia)",
  "Caulfield North (Australia)",
  "Caulfield South (Australia)",
  "Caversham (Australia)",
  "Cawarral (Australia)",
  "Cawongla (Australia)",
  "Cedar Grove (Australia)",
  "Ceduna (Australia)",
  "Central Coast (Australia)",
  "Cessnock (Australia)",
  "Charlestown (Australia)",
  "Chatswood (Australia)",
  "Chatsworth Island (Australia)",
  "Chelsea (Australia)",
  "Cheltenham (Australia)",
  "Chinchilla (Australia)",
  "Chippendale (Australia)",
  "Chipping Norton (Australia)",
  "Christies (Australia)",
  "Chullora (Australia)",
  "Churchill (Australia)",
  "City of Parramatta (Australia)",
  "City of West Torrens (Australia)",
  "Clare (Australia)",
  "Claremont (Australia)",
  "Clarence Town (Australia)",
  "Clareville (Australia)",
  "Clarinda (Australia)",
  "Clarkefield (Australia)",
  "Clayton North (Australia)",
  "Cleveland (Australia)",
  "Clifton (Australia)",
  "Clifton Hill (Australia)",
  "Clifton Springs (Australia)",
  "Clontarf (Australia)",
  "Closeburn (Australia)",
  "Clovelly (Australia)",
  "Clunes (Australia)",
  "Clyde (Australia)",
  "Clyde North (Australia)",
  "Cobbitty (Australia)",
  "Coburg (Australia)",
  "Cockatoo (Australia)",
  "Cockburn (Australia)",
  "Coffs Harbour (Australia)",
  "Colac (Australia)",
  "Coleambally (Australia)",
  "Coledale (Australia)",
  "Coleraine (Australia)",
  "Collaroy (Australia)",
  "Collie (Australia)",
  "Collingwood (Australia)",
  "Collinsvale (Australia)",
  "Colo (Australia)",
  "Colo Vale (Australia)",
  "Como (Australia)",
  "Concord (Australia)",
  "Condamine (Australia)",
  "Condobolin (Australia)",
  "Coniston (Australia)",
  "Connells Point (Australia)",
  "Coober Pedy (Australia)",
  "Cooee (Australia)",
  "Coogee (Australia)",
  "Cook (Australia)",
  "Cooktown (Australia)",
  "Coolah (Australia)",
  "Coolamon (Australia)",
  "Coolangatta (Australia)",
  "Cooloolabin (Australia)",
  "Coolum (Australia)",
  "Coolum Beach (Australia)",
  "Coolup (Australia)",
  "Cooma (Australia)",
  "Coomera (Australia)",
  "Coonawarra (Australia)",
  "Coondoo (Australia)",
  "Cooranbong (Australia)",
  "Cooroy (Australia)",
  "Coorparoo (Australia)",
  "Cootamundra (Australia)",
  "Copacabana (Australia)",
  "Copmanhurst (Australia)",
  "Corinda (Australia)",
  "Corinthia (Australia)",
  "Corio (Australia)",
  "Corlette (Australia)",
  "Coromandel Valley (Australia)",
  "Corrimal (Australia)",
  "Cotham (Australia)",
  "Cottesloe (Australia)",
  "Coutts Crossing (Australia)",
  "Cowan (Australia)",
  "Cowes (Australia)",
  "Cowra (Australia)",
  "Crafers (Australia)",
  "Craigieburn (Australia)",
  "Cranbourne (Australia)",
  "Crawford (Australia)",
  "Cremorne (Australia)",
  "Crescent Head (Australia)",
  "Creswick (Australia)",
  "Crib Point (Australia)",
  "Cringila (Australia)",
  "Cronulla (Australia)",
  "Crookwell (Australia)",
  "Crossley (Australia)",
  "Crows Nest (Australia)",
  "Croydon (Australia)",
  "Croydon North (Australia)",
  "Crystal Creek (Australia)",
  "Cudlee Creek (Australia)",
  "Cummins (Australia)",
  "Cundletown (Australia)",
  "Curl Curl (Australia)",
  "Currumbin (Australia)",
  "Cygnet (Australia)",
  "Daceyville (Australia)",
  "Daisy Hill (Australia)",
  "Dakabin (Australia)",
  "Dalby (Australia)",
  "Dalkeith (Australia)",
  "Dandenong (Australia)",
  "Dapto (Australia)",
  "Dardanup (Australia)",
  "Darkan (Australia)",
  "Darley (Australia)",
  "Darling (Australia)",
  "Darling Point (Australia)",
  "Darlinghurst (Australia)",
  "Darlington (Australia)",
  "Darra (Australia)",
  "Darwin (Australia)",
  "Davidson (Australia)",
  "Davistown (Australia)",
  "Dawson (Australia)",
  "Dayboro (Australia)",
  "Daylesford (Australia)",
  "Deagon (Australia)",
  "Deakin (Australia)",
  "Deception Bay (Australia)",
  "Deepdene (Australia)",
  "Deer Park (Australia)",
  "Deloraine (Australia)",
  "Denham (Australia)",
  "Denman (Australia)",
  "Dereel (Australia)",
  "Derrimut (Australia)",
  "Derwent (Australia)",
  "Devonport (Australia)",
  "Diamond Creek (Australia)",
  "Diggers Rest (Australia)",
  "Dilston (Australia)",
  "Dimboola (Australia)",
  "Dimbulah (Australia)",
  "Dingley (Australia)",
  "Dirranbandi (Australia)",
  "Dodges Ferry (Australia)",
  "Dolans Bay (Australia)",
  "Don (Australia)",
  "Doncaster (Australia)",
  "Doncaster East (Australia)",
  "Donnybrook (Australia)",
  "Donvale (Australia)",
  "Doonside (Australia)",
  "Doreen (Australia)",
  "Dorrigo (Australia)",
  "Doubtful Creek (Australia)",
  "Douglas (Australia)",
  "Douglas Park (Australia)",
  "Dover Heights (Australia)",
  "Drayton (Australia)",
  "Dromana (Australia)",
  "Drouin (Australia)",
  "Drouin South (Australia)",
  "Drouin West (Australia)",
  "Drummond Cove (Australia)",
  "Drummoyne (Australia)",
  "Drysdale (Australia)",
  "Dubbo (Australia)",
  "Dumbalk (Australia)",
  "Dumbleyung (Australia)",
  "Dundas (Australia)",
  "Dundowran (Australia)",
  "Dungog (Australia)",
  "Dunkeld (Australia)",
  "Dunmore (Australia)",
  "Dunolly (Australia)",
  "Dunsborough (Australia)",
  "Dural (Australia)",
  "Eagle Farm (Australia)",
  "Eagle Heights (Australia)",
  "Eagleby (Australia)",
  "Eaglehawk (Australia)",
  "Eaglemont (Australia)",
  "Earlwood (Australia)",
  "East Fremantle (Australia)",
  "East Gosford (Australia)",
  "East Maitland (Australia)",
  "East Malvern (Australia)",
  "Eastwood (Australia)",
  "Eaton (Australia)",
  "Ebbw Vale (Australia)",
  "Ebenezer (Australia)",
  "Echuca (Australia)",
  "Echunga (Australia)",
  "Eden (Australia)",
  "Eden Park (Australia)",
  "Edge Hill (Australia)",
  "Edgecliff (Australia)",
  "Edith (Australia)",
  "Edithvale (Australia)",
  "Edmonton (Australia)",
  "Edwardstown (Australia)",
  "Eight Mile Plains (Australia)",
  "Elderslie (Australia)",
  "Elizabeth (Australia)",
  "Ellalong (Australia)",
  "Ellangowan (Australia)",
  "Ellendale (Australia)",
  "Ellerslie (Australia)",
  "Elsternwick (Australia)",
  "Eltham (Australia)",
  "Eltham North (Australia)",
  "Elwood (Australia)",
  "Emerald (Australia)",
  "Empire Bay (Australia)",
  "Emu Park (Australia)",
  "Emu Plains (Australia)",
  "Emu Vale (Australia)",
  "Encounter Bay (Australia)",
  "Endeavour (Australia)",
  "Endeavour Hills (Australia)",
  "Enfield (Australia)",
  "Engadine (Australia)",
  "Enmore (Australia)",
  "Enoggera (Australia)",
  "Epping (Australia)",
  "Erina (Australia)",
  "Erskine (Australia)",
  "Eschol (Australia)",
  "Esk (Australia)",
  "Esperance (Australia)",
  "Essendon (Australia)",
  "Ettalong Beach (Australia)",
  "Eudlo (Australia)",
  "Eudunda (Australia)",
  "Eumundi (Australia)",
  "Euroa (Australia)",
  "Evandale (Australia)",
  "Everton (Australia)",
  "Exeter (Australia)",
  "Exmouth (Australia)",
  "Failford (Australia)",
  "Fairfield (Australia)",
  "Fairview (Australia)",
  "Fairy Meadow (Australia)",
  "Fassifern (Australia)",
  "Faulconbridge (Australia)",
  "Fawkner (Australia)",
  "Fern Tree (Australia)",
  "Fern Tree Gully (Australia)",
  "Ferndale (Australia)",
  "Ferntree Gully (Australia)",
  "Fernvale (Australia)",
  "Ferny Creek (Australia)",
  "Figtree (Australia)",
  "Findon (Australia)",
  "Fingal (Australia)",
  "Fingal Bay (Australia)",
  "Fitzroy (Australia)",
  "Flemington (Australia)",
  "Flinders (Australia)",
  "Flynn (Australia)",
  "Footscray (Australia)",
  "Forbes (Australia)",
  "Forest (Australia)",
  "Forest Hill (Australia)",
  "Forestville (Australia)",
  "Forrest (Australia)",
  "Forsayth (Australia)",
  "Forster (Australia)",
  "Foster (Australia)",
  "Franklin (Australia)",
  "Frankston (Australia)",
  "Freeling (Australia)",
  "Freemans Reach (Australia)",
  "Fremantle (Australia)",
  "Freshwater (Australia)",
  "Fulham (Australia)",
  "Fullerton (Australia)",
  "Fyshwick (Australia)",
  "Galston (Australia)",
  "Garfield (Australia)",
  "Gatton (Australia)",
  "Gawler (Australia)",
  "Gayndah (Australia)",
  "Gaythorne (Australia)",
  "Geelong (Australia)",
  "Geelong West (Australia)",
  "Gellibrand (Australia)",
  "George Town (Australia)",
  "Gepps Cross (Australia)",
  "Geraldton (Australia)",
  "Gerringong (Australia)",
  "Gheerulla (Australia)",
  "Gilberton (Australia)",
  "Gilmore (Australia)",
  "Gilston (Australia)",
  "Gin Gin (Australia)",
  "Girraween (Australia)",
  "Gisborne (Australia)",
  "Gladesville (Australia)",
  "Gladstone (Australia)",
  "Glass House Mountains (Australia)",
  "Glebe (Australia)",
  "Glen Huntly (Australia)",
  "Glen Huon (Australia)",
  "Glen Innes (Australia)",
  "Glen Iris (Australia)",
  "Glen Osmond (Australia)",
  "Glen Waverley (Australia)",
  "Glenbrook (Australia)",
  "Glenburn (Australia)",
  "Glenelg (Australia)",
  "Glenfield (Australia)",
  "Glengarry (Australia)",
  "Glenhaven (Australia)",
  "Glenorchy (Australia)",
  "Glenore Grove (Australia)",
  "Glenorie (Australia)",
  "Glenreagh (Australia)",
  "Glenroy (Australia)",
  "Glenvale (Australia)",
  "Glossodia (Australia)",
  "Gloucester (Australia)",
  "Golconda (Australia)",
  "Gold Coast (Australia)",
  "Golden Grove (Australia)",
  "Goodna (Australia)",
  "Goodwood (Australia)",
  "Goolwa (Australia)",
  "Goomeri (Australia)",
  "Goonellabah (Australia)",
  "Goornong (Australia)",
  "Gooseberry Hill (Australia)",
  "Gordon (Australia)",
  "Gordonvale (Australia)",
  "Gore Hill (Australia)",
  "Gorokan (Australia)",
  "Gosford (Australia)",
  "Gosforth (Australia)",
  "Gosnells (Australia)",
  "Goulburn (Australia)",
  "Gould (Australia)",
  "Gowrie (Australia)",
  "Gracemere (Australia)",
  "Grafton (Australia)",
  "Grandchester (Australia)",
  "Grange (Australia)",
  "Grant (Australia)",
  "Grantham (Australia)",
  "Granville (Australia)",
  "Grassmere (Australia)",
  "Gravesend (Australia)",
  "Green Hill (Australia)",
  "Green Point (Australia)",
  "Greenbank (Australia)",
  "Greendale (Australia)",
  "Greensborough (Australia)",
  "Greenvale (Australia)",
  "Greenwich (Australia)",
  "Gregadoo (Australia)",
  "Grenfell (Australia)",
  "Greta (Australia)",
  "Griffith (Australia)",
  "Grove (Australia)",
  "Grovedale (Australia)",
  "Guildford (Australia)",
  "Gundaroo (Australia)",
  "Gunnedah (Australia)",
  "Gymea (Australia)",
  "Gymea Bay (Australia)",
  "Gympie (Australia)",
  "Haberfield (Australia)",
  "Hackham (Australia)",
  "Haddon (Australia)",
  "Hadspen (Australia)",
  "Hagley (Australia)",
  "Hahndorf (Australia)",
  "Hall (Australia)",
  "Hallam (Australia)",
  "Hallett (Australia)",
  "Halls Gap (Australia)",
  "Hamilton (Australia)",
  "Hammond (Australia)",
  "Hampton (Australia)",
  "Hampton Park (Australia)",
  "Happy Valley (Australia)",
  "Harrington (Australia)",
  "Harristown (Australia)",
  "Harvey (Australia)",
  "Hastings (Australia)",
  "Hastings Point (Australia)",
  "Hat Head (Australia)",
  "Hawker (Australia)",
  "Hawkesbury Heights (Australia)",
  "Hawksburn (Australia)",
  "Hawthorn (Australia)",
  "Hawthorne (Australia)",
  "Haymarket (Australia)",
  "Hazelbrook (Australia)",
  "Hazelwood (Australia)",
  "Healesville (Australia)",
  "Heathcote (Australia)",
  "Heathcote Junction (Australia)",
  "Heatherton (Australia)",
  "Heathmont (Australia)",
  "Heddon Greta (Australia)",
  "Heidelberg (Australia)",
  "Helensburgh (Australia)",
  "Helensvale (Australia)",
  "Hendon (Australia)",
  "Hepburn Springs (Australia)",
  "Hervey Bay (Australia)",
  "Hexham (Australia)",
  "Highbury (Australia)",
  "Highett (Australia)",
  "Highfields (Australia)",
  "Hill Top (Australia)",
  "Hillcrest (Australia)",
  "Hindmarsh (Australia)",
  "Hobart (Australia)",
  "Hoddles Creek (Australia)",
  "Hollow Tree (Australia)",
  "Holroyd (Australia)",
  "Holsworthy (Australia)",
  "Homebush (Australia)",
  "Hope Island (Australia)",
  "Hope Valley (Australia)",
  "Hopetoun (Australia)",
  "Hoppers Crossing (Australia)",
  "Hornsby (Australia)",
  "Horsham (Australia)",
  "Hove (Australia)",
  "Howard (Australia)",
  "Howard Springs (Australia)",
  "Howden (Australia)",
  "Howe (Australia)",
  "Howlong (Australia)",
  "Hughesdale (Australia)",
  "Hunter (Australia)",
  "Hunters Hill (Australia)",
  "Huntly (Australia)",
  "Huonville (Australia)",
  "Hurstbridge (Australia)",
  "Hurstville (Australia)",
  "Hurstville Grove (Australia)",
  "Hyland Park (Australia)",
  "Illawarra (Australia)",
  "Illawong (Australia)",
  "Iluka (Australia)",
  "Indented Head (Australia)",
  "Ingham (Australia)",
  "Ingle Farm (Australia)",
  "Ingleburn (Australia)",
  "Inglewood (Australia)",
  "Innisfail (Australia)",
  "Inverell (Australia)",
  "Inverloch (Australia)",
  "Invermay (Australia)",
  "Ipswich (Australia)",
  "Isabella (Australia)",
  "Ivanhoe (Australia)",
  "Ivanhoe East (Australia)",
  "Ives (Australia)",
  "Jacobs Well (Australia)",
  "Jannali (Australia)",
  "Jarrahdale (Australia)",
  "Jensen (Australia)",
  "Jilliby (Australia)",
  "Jimboomba (Australia)",
  "Jindabyne (Australia)",
  "Jindivick (Australia)",
  "Judbury (Australia)",
  "Junee (Australia)",
  "Kadina (Australia)",
  "Kalgoorlie (Australia)",
  "Kallista (Australia)",
  "Kalorama (Australia)",
  "Kambalda (Australia)",
  "Kangaroo Flat (Australia)",
  "Kangaroo Point (Australia)",
  "Kanwal (Australia)",
  "Kapunda (Australia)",
  "Karalee (Australia)",
  "Karana Downs (Australia)",
  "Kardella (Australia)",
  "Kardella South (Australia)",
  "Kareela (Australia)",
  "Karnup (Australia)",
  "Karoonda (Australia)",
  "Karratha (Australia)",
  "Katherine (Australia)",
  "Katoomba (Australia)",
  "Kawungan (Australia)",
  "Keilor (Australia)",
  "Keiraville (Australia)",
  "Keith (Australia)",
  "Kellyville (Australia)",
  "Kelmscott (Australia)",
  "Kelso (Australia)",
  "Kelvin Grove (Australia)",
  "Kempsey (Australia)",
  "Kempton (Australia)",
  "Kendall (Australia)",
  "Kenilworth (Australia)",
  "Kenmore (Australia)",
  "Kennington (Australia)",
  "Kensington (Australia)",
  "Kensington and Norwood (Australia)",
  "Kenthurst (Australia)",
  "Kenwick (Australia)",
  "Kerang (Australia)",
  "Kerry (Australia)",
  "Keswick (Australia)",
  "Kettering (Australia)",
  "Kew (Australia)",
  "Kew East (Australia)",
  "Keysborough (Australia)",
  "Keysbrook (Australia)",
  "Kiama (Australia)",
  "Kilcoy (Australia)",
  "Kilcunda (Australia)",
  "Killara (Australia)",
  "Killarney (Australia)",
  "Killcare (Australia)",
  "Killingworth (Australia)",
  "Kilmore (Australia)",
  "Kilsyth (Australia)",
  "Kincumber (Australia)",
  "Kindred (Australia)",
  "Kingaroy (Australia)",
  "Kinglake (Australia)",
  "Kings Cross (Australia)",
  "Kings Park (Australia)",
  "Kingscliff (Australia)",
  "Kingsthorpe (Australia)",
  "Kingston (Australia)",
  "Kingswood (Australia)",
  "Kinross (Australia)",
  "Kirrawee (Australia)",
  "Kirribilli (Australia)",
  "Kirwan (Australia)",
  "Koah (Australia)",
  "Kobble (Australia)",
  "Koetong (Australia)",
  "Kogarah (Australia)",
  "Kojonup (Australia)",
  "Koo-Wee-Rup (Australia)",
  "Koolewong (Australia)",
  "Koorawatha (Australia)",
  "Koornalla (Australia)",
  "Kootingal (Australia)",
  "Kooyong (Australia)",
  "Koroit (Australia)",
  "Korumburra (Australia)",
  "Kotara (Australia)",
  "Kununurra (Australia)",
  "Kuranda (Australia)",
  "Kurnell (Australia)",
  "Kurrajong Heights (Australia)",
  "Kurri Kurri (Australia)",
  "Kyabram (Australia)",
  "Kyneton (Australia)",
  "Kyogle (Australia)",
  "Laang (Australia)",
  "Laguna (Australia)",
  "Laidley (Australia)",
  "Lake Cathie (Australia)",
  "Lake Heights (Australia)",
  "Lake Illawarra (Australia)",
  "Lake Munmorah (Australia)",
  "Lakemba (Australia)",
  "Lakes Entrance (Australia)",
  "Lalor (Australia)",
  "Lambton (Australia)",
  "Lamington (Australia)",
  "Lancefield (Australia)",
  "Lancelin (Australia)",
  "Landsborough (Australia)",
  "Lane Cove (Australia)",
  "Lang Lang (Australia)",
  "Langhorne Creek (Australia)",
  "Langley (Australia)",
  "Langwarrin (Australia)",
  "Lara (Australia)",
  "Largs North (Australia)",
  "Latrobe (Australia)",
  "Lauderdale (Australia)",
  "Launceston (Australia)",
  "Laverton (Australia)",
  "Lavington (Australia)",
  "Lawgi (Australia)",
  "Lawnton (Australia)",
  "Lawson (Australia)",
  "Lebrina (Australia)",
  "Leeton (Australia)",
  "Legana (Australia)",
  "Leichhardt (Australia)",
  "Lemon Tree Passage (Australia)",
  "Lennox Head (Australia)",
  "Leongatha (Australia)",
  "Leopold (Australia)",
  "Lethbridge (Australia)",
  "Leumeah (Australia)",
  "Leura (Australia)",
  "Lewiston (Australia)",
  "Lidcombe (Australia)",
  "Lilli Pilli (Australia)",
  "Lilydale (Australia)",
  "Limestone (Australia)",
  "Linden (Australia)",
  "Lindenow (Australia)",
  "Lindfield (Australia)",
  "Lindisfarne (Australia)",
  "Lisarow (Australia)",
  "Lismore (Australia)",
  "Lithgow (Australia)",
  "Little Bay (Australia)",
  "Little Hampton (Australia)",
  "Little River (Australia)",
  "Liverpool (Australia)",
  "Loch (Australia)",
  "Lockhart (Australia)",
  "Lockridge (Australia)",
  "Loftus (Australia)",
  "Logan (Australia)",
  "Logan City (Australia)",
  "Logan Village (Australia)",
  "Loganholme (Australia)",
  "Loganlea (Australia)",
  "Long Jetty (Australia)",
  "Long Plains (Australia)",
  "Long Point (Australia)",
  "Longford (Australia)",
  "Longreach (Australia)",
  "Longueville (Australia)",
  "Longwarry (Australia)",
  "Lovely Banks (Australia)",
  "Lowanna (Australia)",
  "Lower Chittering (Australia)",
  "Lower Plenty (Australia)",
  "Lowood (Australia)",
  "Loxton (Australia)",
  "Luddenham (Australia)",
  "Ludlow (Australia)",
  "Lugarno (Australia)",
  "Lulworth (Australia)",
  "Lutana (Australia)",
  "Lyndhurst (Australia)",
  "Lyndoch (Australia)",
  "Lyneham (Australia)",
  "Lyons (Australia)",
  "Lysterfield (Australia)",
  "Macarthur (Australia)",
  "Macedon (Australia)",
  "Mackay (Australia)",
  "Macksville (Australia)",
  "Maclean (Australia)",
  "Macleod (Australia)",
  "Macquarie Park (Australia)",
  "Maddington (Australia)",
  "Maffra (Australia)",
  "Magill (Australia)",
  "Maidenwell (Australia)",
  "Maitland (Australia)",
  "Malabar (Australia)",
  "Maleny (Australia)",
  "Mallala (Australia)",
  "Mallanganee (Australia)",
  "Malmsbury (Australia)",
  "Malvern (Australia)",
  "Mandalong (Australia)",
  "Mandurah (Australia)",
  "Mangalore (Australia)",
  "Mangerton (Australia)",
  "Manildra (Australia)",
  "Manjimup (Australia)",
  "Manly (Australia)",
  "Mannering Park (Australia)",
  "Manning (Australia)",
  "Manning Point (Australia)",
  "Mannum (Australia)",
  "Mansfield (Australia)",
  "Mapleton (Australia)",
  "Mareeba (Australia)",
  "Margaret River (Australia)",
  "Margate (Australia)",
  "Marian (Australia)",
  "Maribyrnong (Australia)",
  "Marino (Australia)",
  "Marion (Australia)",
  "Marlee (Australia)",
  "Marmor (Australia)",
  "Marong (Australia)",
  "Maroochydore (Australia)",
  "Maroota (Australia)",
  "Maroubra (Australia)",
  "Marrickville (Australia)",
  "Marsden (Australia)",
  "Marsden Park (Australia)",
  "Marshalltown (Australia)",
  "Martins Creek (Australia)",
  "Marulan (Australia)",
  "Maryborough (Australia)",
  "Maryland (Australia)",
  "Marysville (Australia)",
  "Mascot (Australia)",
  "Maslin Beach (Australia)",
  "Matcham (Australia)",
  "Matraville (Australia)",
  "Maudsland (Australia)",
  "Maybole (Australia)",
  "Mayfield (Australia)",
  "Maylands (Australia)",
  "McCrae (Australia)",
  "McGraths Hill (Australia)",
  "McKinnon (Australia)",
  "McLachlan (Australia)",
  "McLaren Flat (Australia)",
  "McLaren Vale (Australia)",
  "Meadows (Australia)",
  "Medlow Bath (Australia)",
  "Medowie (Australia)",
  "Melbourne (Australia)",
  "Melrose (Australia)",
  "Melton (Australia)",
  "Melton South (Australia)",
  "Melville (Australia)",
  "Menai (Australia)",
  "Menangle (Australia)",
  "Mentone (Australia)",
  "Meredith (Australia)",
  "Merewether (Australia)",
  "Meringandan (Australia)",
  "Merlynston (Australia)",
  "Mernda (Australia)",
  "Merredin (Australia)",
  "Merriwa (Australia)",
  "Merrylands (Australia)",
  "Middlemount (Australia)",
  "Middleton (Australia)",
  "Midway Point (Australia)",
  "Mildura (Australia)",
  "Mile End (Australia)",
  "Miles (Australia)",
  "Milton (Australia)",
  "Miners Rest (Australia)",
  "Minlaton (Australia)",
  "Minmi (Australia)",
  "Minto (Australia)",
  "Miranda (Australia)",
  "Mirani (Australia)",
  "Mirboo (Australia)",
  "Mirboo North (Australia)",
  "Mirrabooka (Australia)",
  "Mission Beach (Australia)",
  "Mitcham (Australia)",
  "Mitchell (Australia)",
  "Mitchelton (Australia)",
  "Mitiamo (Australia)",
  "Mittagong (Australia)",
  "Moama (Australia)",
  "Moana (Australia)",
  "Modbury (Australia)",
  "Moggill (Australia)",
  "Mona Vale (Australia)",
  "Monash (Australia)",
  "Monbulk (Australia)",
  "Mont Albert North (Australia)",
  "Monteagle (Australia)",
  "Monterey (Australia)",
  "Montmorency (Australia)",
  "Montrose (Australia)",
  "Montville (Australia)",
  "Mooloolaba (Australia)",
  "Mooloolah Valley (Australia)",
  "Moonah (Australia)",
  "Moonee Ponds (Australia)",
  "Mooney Mooney (Australia)",
  "Moorabbin (Australia)",
  "Mooralla (Australia)",
  "Moore (Australia)",
  "Moore Park (Australia)",
  "Moorooduc (Australia)",
  "Mooroolbark (Australia)",
  "Mooroopna (Australia)",
  "Moranbah (Australia)",
  "Morang (Australia)",
  "Morayfield (Australia)",
  "Mordialloc (Australia)",
  "Moree (Australia)",
  "Moriac (Australia)",
  "Morisset (Australia)",
  "Mornington (Australia)",
  "Morphett Vale (Australia)",
  "Mortdale (Australia)",
  "Mortlake (Australia)",
  "Moruya (Australia)",
  "Morwell (Australia)",
  "Mosman (Australia)",
  "Mosman Park (Australia)",
  "Moss Vale (Australia)",
  "Mossman (Australia)",
  "Mount Barker (Australia)",
  "Mount Colah (Australia)",
  "Mount Cotton (Australia)",
  "Mount Dandenong (Australia)",
  "Mount Direction (Australia)",
  "Mount Druitt (Australia)",
  "Mount Egerton (Australia)",
  "Mount Eliza (Australia)",
  "Mount Evelyn (Australia)",
  "Mount Gambier (Australia)",
  "Mount Gravatt (Australia)",
  "Mount Helen (Australia)",
  "Mount Helena (Australia)",
  "Mount Isa (Australia)",
  "Mount Julian (Australia)",
  "Mount Keira (Australia)",
  "Mount Larcom (Australia)",
  "Mount Lofty (Australia)",
  "Mount Macedon (Australia)",
  "Mount Martha (Australia)",
  "Mount Molloy (Australia)",
  "Mount Morgan (Australia)",
  "Mount Ousley (Australia)",
  "Mount Perry (Australia)",
  "Mount Pleasant (Australia)",
  "Mount Saint Thomas (Australia)",
  "Mount Waverley (Australia)",
  "Mount White (Australia)",
  "Mourilyan (Australia)",
  "Muchea (Australia)",
  "Mudgee (Australia)",
  "Mudgeeraba (Australia)",
  "Mudjimba (Australia)",
  "Mulgoa (Australia)",
  "Mulgrave (Australia)",
  "Mullewa (Australia)",
  "Mullumbimby (Australia)",
  "Mundaring (Australia)",
  "Mundijong (Australia)",
  "Murray Bridge (Australia)",
  "Murrumba (Australia)",
  "Murrumbateman (Australia)",
  "Murrumbeena (Australia)",
  "Murrurundi (Australia)",
  "Murtoa (Australia)",
  "Murwillumbah (Australia)",
  "Muswellbrook (Australia)",
  "Mylor (Australia)",
  "Myrrhee (Australia)",
  "Myrtleford (Australia)",
  "Nagambie (Australia)",
  "Nairne (Australia)",
  "Nambour (Australia)",
  "Nambucca Heads (Australia)",
  "Nana Glen (Australia)",
  "Nanango (Australia)",
  "Nannup (Australia)",
  "Napoleons (Australia)",
  "Nar Nar Goon (Australia)",
  "Narangba (Australia)",
  "Narara (Australia)",
  "Narellan (Australia)",
  "Narooma (Australia)",
  "Narrabeen (Australia)",
  "Narrabri (Australia)",
  "Narrandera (Australia)",
  "Narrawong (Australia)",
  "Narre Warren (Australia)",
  "Narre Warren North (Australia)",
  "Narrogin (Australia)",
  "Narromine (Australia)",
  "Neath (Australia)",
  "Nebo (Australia)",
  "Nedlands (Australia)",
  "Neerim South (Australia)",
  "Nelson Bay (Australia)",
  "Nerang (Australia)",
  "Nerrigundah (Australia)",
  "Nerrina (Australia)",
  "Neutral Bay (Australia)",
  "New Farm (Australia)",
  "New Lambton (Australia)",
  "New Town (Australia)",
  "Newcastle (Australia)",
  "Newmarket (Australia)",
  "Newnham (Australia)",
  "Newport (Australia)",
  "Newry (Australia)",
  "Newstead (Australia)",
  "Newtown (Australia)",
  "Nhill (Australia)",
  "Nindaroo (Australia)",
  "Ningi (Australia)",
  "Nirranda (Australia)",
  "Noarlunga (Australia)",
  "Noble Park (Australia)",
  "Noosa (Australia)",
  "Noosaville (Australia)",
  "Norah (Australia)",
  "Nords Wharf (Australia)",
  "Norlane (Australia)",
  "Norman Park (Australia)",
  "Normanhurst (Australia)",
  "Normanton (Australia)",
  "Normanville (Australia)",
  "North Beach (Australia)",
  "North Fitzroy (Australia)",
  "North Mackay (Australia)",
  "North Melbourne (Australia)",
  "North Tamborine (Australia)",
  "North Turramurra (Australia)",
  "Northam (Australia)",
  "Northcote (Australia)",
  "Northgate (Australia)",
  "Northmead (Australia)",
  "Norton Summit (Australia)",
  "Norwood (Australia)",
  "Notting Hill (Australia)",
  "Nowra (Australia)",
  "Nullawarre (Australia)",
  "Nunawading (Australia)",
  "Nuriootpa (Australia)",
  "Nyora (Australia)",
  "Oak Park (Australia)",
  "Oakdale (Australia)",
  "Oakey (Australia)",
  "Oakhurst (Australia)",
  "Oaklands (Australia)",
  "Oakleigh (Australia)",
  "Oakleigh South (Australia)",
  "Oatlands (Australia)",
  "Oatley (Australia)",
  "Oberon (Australia)",
  "Obi Obi (Australia)",
  "Ocean Shores (Australia)",
  "Officer (Australia)",
  "Old Bar (Australia)",
  "Old Beach (Australia)",
  "Olinda (Australia)",
  "Oman-Ama (Australia)",
  "One Tree Hill (Australia)",
  "Orange (Australia)",
  "Orchard Hills (Australia)",
  "Orford (Australia)",
  "Orient Point (Australia)",
  "Ormeau (Australia)",
  "Ormiston (Australia)",
  "Ormond (Australia)",
  "Orroroo (Australia)",
  "Osborne (Australia)",
  "Ourimbah (Australia)",
  "Oxenford (Australia)",
  "Oxley (Australia)",
  "Oyster Bay (Australia)",
  "Paddington (Australia)",
  "Padstow (Australia)",
  "Pakenham (Australia)",
  "Pallarenda (Australia)",
  "Palm Beach (Australia)",
  "Palm Cove (Australia)",
  "Palm Island (Australia)",
  "Palmer (Australia)",
  "Palmwoods (Australia)",
  "Pambula (Australia)",
  "Paradise (Australia)",
  "Paramatta (Australia)",
  "Parap (Australia)",
  "Park Ridge (Australia)",
  "Parkdale (Australia)",
  "Parkerville (Australia)",
  "Parkes (Australia)",
  "Parkhurst (Australia)",
  "Parkville (Australia)",
  "Parkwood (Australia)",
  "Pearce (Australia)",
  "Pearcedale (Australia)",
  "Pechey (Australia)",
  "Pelican (Australia)",
  "Pendle Hills (Australia)",
  "Penfield (Australia)",
  "Penguin (Australia)",
  "Pennant Hills (Australia)",
  "Penrith (Australia)",
  "Penshurst (Australia)",
  "Perenjori (Australia)",
  "Perth (Australia)",
  "Peterborough (Australia)",
  "Petrie (Australia)",
  "Phillip (Australia)",
  "Pialba (Australia)",
  "Piawaning (Australia)",
  "Picnic Bay (Australia)",
  "Picton (Australia)",
  "Piggabeen (Australia)",
  "Pimpama (Australia)",
  "Pine Mountain (Australia)",
  "Pinery (Australia)",
  "Pinjarra (Australia)",
  "Pinkenba (Australia)",
  "Pioneer (Australia)",
  "Pipers Brook (Australia)",
  "Pitt Town (Australia)",
  "Pittsworth (Australia)",
  "Plympton (Australia)",
  "Point Clare (Australia)",
  "Point Cook (Australia)",
  "Point Lonsdale (Australia)",
  "Point Lookout (Australia)",
  "Point Pass (Australia)",
  "Point Vernon (Australia)",
  "Pokolbin (Australia)",
  "Pomona (Australia)",
  "Pontville (Australia)",
  "Poowong (Australia)",
  "Port Adelaide (Australia)",
  "Port Augusta (Australia)",
  "Port Douglas (Australia)",
  "Port Fairy (Australia)",
  "Port Hacking (Australia)",
  "Port Hedland (Australia)",
  "Port Kembla (Australia)",
  "Port Lincoln (Australia)",
  "Port MacDonnell (Australia)",
  "Port Macquarie (Australia)",
  "Port Melbourne (Australia)",
  "Port Noarlunga (Australia)",
  "Port Pirie (Australia)",
  "Port Sorell (Australia)",
  "Portarlington (Australia)",
  "Portland (Australia)",
  "Portsea (Australia)",
  "Potts Point (Australia)",
  "Prahran (Australia)",
  "Preston (Australia)",
  "Proserpine (Australia)",
  "Prospect (Australia)",
  "Purnim (Australia)",
  "Pymble (Australia)",
  "Pyrmont (Australia)",
  "Quakers Hill (Australia)",
  "Queanbeyan (Australia)",
  "Queenscliff (Australia)",
  "Queensferry (Australia)",
  "Queenstown (Australia)",
  "Railton (Australia)",
  "Ramsgate (Australia)",
  "Randwick (Australia)",
  "Rathmines (Australia)",
  "Ravenswood (Australia)",
  "Raworth (Australia)",
  "Raymond Terrace (Australia)",
  "Red Hill (Australia)",
  "Redan (Australia)",
  "Redbank (Australia)",
  "Redcliffe (Australia)",
  "Redfern (Australia)",
  "Redhead (Australia)",
  "Redland Bay (Australia)",
  "Redlynch (Australia)",
  "Reedy Creek (Australia)",
  "Regents Park (Australia)",
  "Research (Australia)",
  "Reservoir (Australia)",
  "Retreat (Australia)",
  "Revesby (Australia)",
  "Reynella (Australia)",
  "Rhodes (Australia)",
  "Riana (Australia)",
  "Richmond (Australia)",
  "Riddell (Australia)",
  "Ridgley (Australia)",
  "Ringwood (Australia)",
  "Ringwood East (Australia)",
  "Ringwood North (Australia)",
  "Ripley (Australia)",
  "Risdon (Australia)",
  "River Heads (Australia)",
  "Riverside (Australia)",
  "Riverstone (Australia)",
  "Riverton (Australia)",
  "Riverview (Australia)",
  "Riverwood (Australia)",
  "Robertson (Australia)",
  "Robertstown (Australia)",
  "Robinvale (Australia)",
  "Rochedale (Australia)",
  "Rochester (Australia)",
  "Rockbank (Australia)",
  "Rockdale (Australia)",
  "Rockhampton (Australia)",
  "Rockingham (Australia)",
  "Rocklea (Australia)",
  "Rodborough (Australia)",
  "Rokeby (Australia)",
  "Roleystone (Australia)",
  "Roma (Australia)",
  "Romsey (Australia)",
  "Rooty Hill (Australia)",
  "Rosanna (Australia)",
  "Rose Bay (Australia)",
  "Rosebery (Australia)",
  "Rosebud (Australia)",
  "Rosehill (Australia)",
  "Roseville (Australia)",
  "Rosewood (Australia)",
  "Roseworthy (Australia)",
  "Ross (Australia)",
  "Ross Creek (Australia)",
  "Ross River (Australia)",
  "Rowville (Australia)",
  "Royal Park (Australia)",
  "Rozelle (Australia)",
  "Rudall (Australia)",
  "Rushworth (Australia)",
  "Rutherford (Australia)",
  "Rydalmere (Australia)",
  "Ryde (Australia)",
  "Rye (Australia)",
  "Rylstone (Australia)",
  "Safety Bay (Australia)",
  "Saint Albans (Australia)",
  "Saint Andrews (Australia)",
  "Saint George (Australia)",
  "Saint Georges Basin (Australia)",
  "Saint Helena (Australia)",
  "Saint Ives (Australia)",
  "Saint James (Australia)",
  "Saint Leonards (Australia)",
  "Saint Lucia (Australia)",
  "Saint Marys (Australia)",
  "Saint Peters (Australia)",
  "Saints (Australia)",
  "Salamander Bay (Australia)",
  "Sale (Australia)",
  "Salisbury (Australia)",
  "Salt Ash (Australia)",
  "Samford (Australia)",
  "San Remo (Australia)",
  "Sanctuary Point (Australia)",
  "Sandford (Australia)",
  "Sandgate (Australia)",
  "Sandringham (Australia)",
  "Sandstone Point (Australia)",
  "Sandy Bay (Australia)",
  "Sandy Hollow (Australia)",
  "Sans Souci (Australia)",
  "Sapphire (Australia)",
  "Sarina (Australia)",
  "Sarina Beach (Australia)",
  "Sassafras (Australia)",
  "Scarborough (Australia)",
  "Scarness (Australia)",
  "Scarsdale (Australia)",
  "Schofields (Australia)",
  "Scone (Australia)",
  "Scoresby (Australia)",
  "Scottsdale (Australia)",
  "Seacliff (Australia)",
  "Seacombe (Australia)",
  "Seaford (Australia)",
  "Seaforth (Australia)",
  "Seaton (Australia)",
  "Seaview (Australia)",
  "Sebastopol (Australia)",
  "Selbourne (Australia)",
  "Selby (Australia)",
  "Sellicks Beach (Australia)",
  "Sellicks Hill (Australia)",
  "Semaphore (Australia)",
  "Serpentine (Australia)",
  "Seven Hills (Australia)",
  "Seven Mile Beach (Australia)",
  "Seville (Australia)",
  "Seville East (Australia)",
  "Seymour (Australia)",
  "Sheffield (Australia)",
  "Shellharbour (Australia)",
  "Shenton Park (Australia)",
  "Sherbrook (Australia)",
  "Shoal Point (Australia)",
  "Shorncliffe (Australia)",
  "Silvan (Australia)",
  "Silverdale (Australia)",
  "Silverwater (Australia)",
  "Singleton (Australia)",
  "Sippy Downs (Australia)",
  "Slacks Creek (Australia)",
  "Smithfield (Australia)",
  "Smithton (Australia)",
  "Smythes Creek (Australia)",
  "Smythesdale (Australia)",
  "Snowtown (Australia)",
  "Somersby (Australia)",
  "Somerset (Australia)",
  "Somerton (Australia)",
  "Somerville (Australia)",
  "Sorrento (Australia)",
  "South Melbourne (Australia)",
  "South Perth (Australia)",
  "South West Rocks (Australia)",
  "South Yarra (Australia)",
  "Southbank (Australia)",
  "Southern Brook (Australia)",
  "Southport (Australia)",
  "Spearwood (Australia)",
  "Spencer (Australia)",
  "Spotswood (Australia)",
  "Spreyton (Australia)",
  "Spring Field (Australia)",
  "Spring Hill (Australia)",
  "Springfield (Australia)",
  "Springvale (Australia)",
  "Springvale South (Australia)",
  "Springwood (Australia)",
  "St Helens (Australia)",
  "St Kilda (Australia)",
  "Stanhope (Australia)",
  "Stanley (Australia)",
  "Stanmore (Australia)",
  "Stannum (Australia)",
  "Stanthorpe (Australia)",
  "Stapylton (Australia)",
  "Stawell (Australia)",
  "Stieglitz (Australia)",
  "Stirling (Australia)",
  "Stockton (Australia)",
  "Stockyard Creek (Australia)",
  "Stoneville (Australia)",
  "Stradbroke (Australia)",
  "Stratford (Australia)",
  "Strathalbyn (Australia)",
  "Strathfield (Australia)",
  "Strathfieldsaye (Australia)",
  "Strathmore (Australia)",
  "Strathpine (Australia)",
  "Streaky Bay (Australia)",
  "Stroud (Australia)",
  "Stuart Park (Australia)",
  "Sturt (Australia)",
  "Subiaco (Australia)",
  "Success (Australia)",
  "Suffolk Park (Australia)",
  "Sulphur Creek (Australia)",
  "Summerholm (Australia)",
  "Summertown (Australia)",
  "Sunbury (Australia)",
  "Sunnybank (Australia)",
  "Sunnyside (Australia)",
  "Sunshine (Australia)",
  "Surfers Paradise (Australia)",
  "Surrey Hills (Australia)",
  "Sutherland (Australia)",
  "Swan Hill (Australia)",
  "Swan View (Australia)",
  "Swansea (Australia)",
  "Sydenham (Australia)",
  "Sydney (Australia)",
  "Sylvania (Australia)",
  "Sylvania Waters (Australia)",
  "Tahmoor (Australia)",
  "Tallangatta (Australia)",
  "Tallarook (Australia)",
  "Tallebudgera (Australia)",
  "Talmalmo (Australia)",
  "Tambo Upper (Australia)",
  "Tamborine (Australia)",
  "Tammin (Australia)",
  "Tamworth (Australia)",
  "Tannum Sands (Australia)",
  "Tanunda (Australia)",
  "Taree (Australia)",
  "Taren Point (Australia)",
  "Tarlee (Australia)",
  "Tarneit (Australia)",
  "Taroona (Australia)",
  "Tarragindi (Australia)",
  "Tarrawanna (Australia)",
  "Tarro (Australia)",
  "Tarzali (Australia)",
  "Tatura (Australia)",
  "Tawonga (Australia)",
  "Teesdale (Australia)",
  "Telegraph Point (Australia)",
  "Temora (Australia)",
  "Tempe (Australia)",
  "Templestowe (Australia)",
  "Templestowe Lower (Australia)",
  "Tenambit (Australia)",
  "Tennyson (Australia)",
  "Tenterfield (Australia)",
  "Teralba (Australia)",
  "Terrigal (Australia)",
  "Terry Hills (Australia)",
  "Tewantin (Australia)",
  "Thagoona (Australia)",
  "Thargomindah (Australia)",
  "The Basin (Australia)",
  "The Entrance (Australia)",
  "The Oaks (Australia)",
  "The Patch (Australia)",
  "The Rocks (Australia)",
  "Thebarton (Australia)",
  "Thirlmere (Australia)",
  "Thirroul (Australia)",
  "Thomastown (Australia)",
  "Thompson (Australia)",
  "Thornbury (Australia)",
  "Thornlands (Australia)",
  "Thornleigh (Australia)",
  "Thornton (Australia)",
  "Thuringowa (Australia)",
  "Thursday Island (Australia)",
  "Tiaro (Australia)",
  "Tieri (Australia)",
  "Tinamba (Australia)",
  "Tincurrin (Australia)",
  "Tingoora (Australia)",
  "Tolga (Australia)",
  "Tom Price (Australia)",
  "Tomago (Australia)",
  "Tongala (Australia)",
  "Toogoolawah (Australia)",
  "Toogoom (Australia)",
  "Toolangi (Australia)",
  "Toolern Vale (Australia)",
  "Toongabbie (Australia)",
  "Toora (Australia)",
  "Tooradin (Australia)",
  "Toorak (Australia)",
  "Toorbul (Australia)",
  "Tooronga (Australia)",
  "Toowong (Australia)",
  "Toowoomba (Australia)",
  "Torbanlea (Australia)",
  "Toronto (Australia)",
  "Torquay (Australia)",
  "Tottenham (Australia)",
  "Toukley (Australia)",
  "Townsville (Australia)",
  "Towradgi (Australia)",
  "Trafalgar (Australia)",
  "Traralgon (Australia)",
  "Traralgon South (Australia)",
  "Trentham (Australia)",
  "Trinity Beach (Australia)",
  "Truganina (Australia)",
  "Trunkey (Australia)",
  "Tuggerah (Australia)",
  "Tuggeranong (Australia)",
  "Tugun (Australia)",
  "Tullamarine (Australia)",
  "Tully (Australia)",
  "Tumut (Australia)",
  "Tuncurry (Australia)",
  "Tunnack (Australia)",
  "Turners Beach (Australia)",
  "Turramurra (Australia)",
  "Tweed Heads (Australia)",
  "Two Rocks (Australia)",
  "Two Wells (Australia)",
  "Tyabb (Australia)",
  "Tyalgum (Australia)",
  "Tynong (Australia)",
  "Uki (Australia)",
  "Ulladulla (Australia)",
  "Ulmarra (Australia)",
  "Ultimo (Australia)",
  "Ulverstone (Australia)",
  "Umina (Australia)",
  "Unanderra (Australia)",
  "Underwood (Australia)",
  "Unley (Australia)",
  "Upper Brookfield (Australia)",
  "Upper Burringbar (Australia)",
  "Upper Caboolture (Australia)",
  "Upper Coomera (Australia)",
  "Upwey (Australia)",
  "Uraidla (Australia)",
  "Urangan (Australia)",
  "Valla Beach (Australia)",
  "Valley Heights (Australia)",
  "Vaucluse (Australia)",
  "Venus Bay (Australia)",
  "Verdun (Australia)",
  "Vermont (Australia)",
  "Victor Harbor (Australia)",
  "Victoria Park (Australia)",
  "Victoria Point (Australia)",
  "Villawood (Australia)",
  "Vineyard (Australia)",
  "Virginia (Australia)",
  "Waaia (Australia)",
  "Wacol (Australia)",
  "Wagga Wagga (Australia)",
  "Wahgunyah (Australia)",
  "Wahroonga (Australia)",
  "Waikerie (Australia)",
  "Waitara (Australia)",
  "Walgett (Australia)",
  "Walhalla (Australia)",
  "Wallacia (Australia)",
  "Wallan (Australia)",
  "Wallaroo (Australia)",
  "Wallington (Australia)",
  "Walliston (Australia)",
  "Wallsend (Australia)",
  "Walmer (Australia)",
  "Wamberal (Australia)",
  "Wamuran (Australia)",
  "Wanbi (Australia)",
  "Wandin North (Australia)",
  "Wandoan (Australia)",
  "Wangaratta (Australia)",
  "Wangi Wangi (Australia)",
  "Wanneroo (Australia)",
  "Wantirna (Australia)",
  "Wantirna South (Australia)",
  "Waratah (Australia)",
  "Warburton (Australia)",
  "Warnbro (Australia)",
  "Warnervale (Australia)",
  "Warragul (Australia)",
  "Warrandyte (Australia)",
  "Warrane (Australia)",
  "Warrawong (Australia)",
  "Warren (Australia)",
  "Warren Shire (Australia)",
  "Warrigal (Australia)",
  "Warrimoo (Australia)",
  "Warrnambool (Australia)",
  "Warwick (Australia)",
  "Waterford (Australia)",
  "Waterloo (Australia)",
  "Waterloo Corner (Australia)",
  "Watsonia (Australia)",
  "Wattle Grove (Australia)",
  "Waubra (Australia)",
  "Wauchope (Australia)",
  "Waurn Ponds (Australia)",
  "Waverley (Australia)",
  "Waverton (Australia)",
  "Wayville (Australia)",
  "Wedderburn (Australia)",
  "Weetah (Australia)",
  "Weethalle (Australia)",
  "Wellard (Australia)",
  "Wellington (Australia)",
  "Wellington Point (Australia)",
  "Welshpool (Australia)",
  "Wembley (Australia)",
  "Wendouree (Australia)",
  "Wentworth (Australia)",
  "Wentworth Falls (Australia)",
  "Wentworthville (Australia)",
  "Werribee (Australia)",
  "Werribee South (Australia)",
  "West Burleigh (Australia)",
  "West End (Australia)",
  "West Hoxton (Australia)",
  "West Pymble (Australia)",
  "West Wyalong (Australia)",
  "Westbrook (Australia)",
  "Westfield (Australia)",
  "Westmead (Australia)",
  "Weston (Australia)",
  "Weston Creek (Australia)",
  "Wheelers Hill (Australia)",
  "White Rock (Australia)",
  "Whitfield (Australia)",
  "Whittlesea (Australia)",
  "Whyalla (Australia)",
  "Wickham (Australia)",
  "Wilberforce (Australia)",
  "Willaston (Australia)",
  "Willaura (Australia)",
  "Williams (Australia)",
  "Williamstown (Australia)",
  "Willow Grove (Australia)",
  "Willow Vale (Australia)",
  "Willunga (Australia)",
  "Wilmot (Australia)",
  "Wilson (Australia)",
  "Wilton (Australia)",
  "Wiluna (Australia)",
  "Wimbledon (Australia)",
  "Winchelsea (Australia)",
  "Windang (Australia)",
  "Windaroo (Australia)",
  "Windellama (Australia)",
  "Windermere Park (Australia)",
  "Windsor (Australia)",
  "Windsor Downs (Australia)",
  "Wingham (Australia)",
  "Winkleigh (Australia)",
  "Wodonga (Australia)",
  "Wollar (Australia)",
  "Wollert (Australia)",
  "Wollongbar (Australia)",
  "Wollongong (Australia)",
  "Womboota (Australia)",
  "Won Wron (Australia)",
  "Wondai (Australia)",
  "Wonga Park (Australia)",
  "Wongan Hills (Australia)",
  "Wonthaggi (Australia)",
  "Woodburn (Australia)",
  "Woodend (Australia)",
  "Woodford (Australia)",
  "Woodgate (Australia)",
  "Woodleigh (Australia)",
  "Woodridge (Australia)",
  "Woodside (Australia)",
  "Woodstock (Australia)",
  "Woodvale (Australia)",
  "Woodville (Australia)",
  "Woolamai (Australia)",
  "Woolgoolga (Australia)",
  "Woolloomooloo (Australia)",
  "Woolooware (Australia)",
  "Wooloweyah (Australia)",
  "Woolwich (Australia)",
  "Woombah (Australia)",
  "Woombye (Australia)",
  "Woonona (Australia)",
  "Woori Yallock (Australia)",
  "Woorim (Australia)",
  "Wooroolin (Australia)",
  "Worongary (Australia)",
  "Woronora (Australia)",
  "Woy Woy (Australia)",
  "Wulkuraka (Australia)",
  "Wyalkatchem (Australia)",
  "Wyalong (Australia)",
  "Wyee (Australia)",
  "Wyndham (Australia)",
  "Wynnum (Australia)",
  "Wynyard (Australia)",
  "Wyong (Australia)",
  "Wyongah (Australia)",
  "Wyreema (Australia)",
  "Yaamba (Australia)",
  "Yackandandah (Australia)",
  "Yallingup (Australia)",
  "Yallourn (Australia)",
  "Yallourn North (Australia)",
  "Yamba (Australia)",
  "Yanchep (Australia)",
  "Yandina (Australia)",
  "Yangan (Australia)",
  "Yankalilla (Australia)",
  "Yapeen (Australia)",
  "Yaroomba (Australia)",
  "Yarpturk (Australia)",
  "Yarra Glen (Australia)",
  "Yarra Junction (Australia)",
  "Yarragon (Australia)",
  "Yarram (Australia)",
  "Yarraman (Australia)",
  "Yarrambat (Australia)",
  "Yarraville (Australia)",
  "Yarrawarrah (Australia)",
  "Yass (Australia)",
  "Yatala (Australia)",
  "Yea (Australia)",
  "Yellow Rock (Australia)",
  "Yenda (Australia)",
  "Yeoval (Australia)",
  "Yeppoon (Australia)",
  "Yerrinbool (Australia)",
  "Yinnar (Australia)",
  "Yolla (Australia)",
  "York (Australia)",
  "Yorkeys Knob (Australia)",
  "Young (Australia)",
  "Yowie Bay (Australia)",
  "Zeehan (Australia)",
  "Austria",
  "Absam (Austria)",
  "Absdorf (Austria)",
  "Abtenau (Austria)",
  "Abtsdorf (Austria)",
  "Ach (Austria)",
  "Achenkirch (Austria)",
  "Achensee (Austria)",
  "Admont (Austria)",
  "Adnet (Austria)",
  "Afritz (Austria)",
  "Aggsbach (Austria)",
  "Aichbach (Austria)",
  "Aichdorf (Austria)",
  "Aifersdorf (Austria)",
  "Aigen (Austria)",
  "Aigen im Muehlkreis (Austria)",
  "Ainet (Austria)",
  "Aistersheim (Austria)",
  "Alberndorf in der Riedmark (Austria)",
  "Alberschwende (Austria)",
  "Aldrans (Austria)",
  "Alkoven (Austria)",
  "Alland (Austria)",
  "Allhartsberg (Austria)",
  "Alpbach (Austria)",
  "Altach (Austria)",
  "Altenberg (Austria)",
  "Altenberg bei Linz (Austria)",
  "Altenburg (Austria)",
  "Altendorf (Austria)",
  "Altenhof am Hausruck (Austria)",
  "Altenmarkt an der Triesting (Austria)",
  "Altenmarkt im Pongau (Austria)",
  "Altheim (Austria)",
  "Althofen (Austria)",
  "Altlengbach (Austria)",
  "Altlichtenwarth (Austria)",
  "Altmunster (Austria)",
  "Altwaidhofen (Austria)",
  "Am See (Austria)",
  "Amaliendorf (Austria)",
  "Ampass (Austria)",
  "Ampfelwang (Austria)",
  "Amstetten (Austria)",
  "Andorf (Austria)",
  "Anger (Austria)",
  "Anif (Austria)",
  "Annenheim (Austria)",
  "Ansfelden (Austria)",
  "Anthering (Austria)",
  "Apfelberg (Austria)",
  "Arbesthal (Austria)",
  "Ardagger Markt (Austria)",
  "Ardning (Austria)",
  "Arnoldstein (Austria)",
  "Arriach (Austria)",
  "Asberg (Austria)",
  "Aschach an der Donau (Austria)",
  "Aschau (Austria)",
  "Aspang Markt (Austria)",
  "Asparn (Austria)",
  "Asperhofen (Austria)",
  "Asten (Austria)",
  "Attersee (Austria)",
  "Attnang-Puchheim (Austria)",
  "Atzbach (Austria)",
  "Au (Austria)",
  "Auersthal (Austria)",
  "Aufhausen (Austria)",
  "Augsdorf (Austria)",
  "Aurolzmunster (Austria)",
  "Axams (Austria)",
  "Bach (Austria)",
  "Bad Blumau (Austria)",
  "Bad Deutsch-Altenburg (Austria)",
  "Bad Erlach (Austria)",
  "Bad Fischau (Austria)",
  "Bad Gams (Austria)",
  "Bad Gastein (Austria)",
  "Bad Gleichenberg (Austria)",
  "Bad Goisern (Austria)",
  "Bad Hall (Austria)",
  "Bad Hofgastein (Austria)",
  "Bad Ischl (Austria)",
  "Bad Kreuzen (Austria)",
  "Bad Pirawarth (Austria)",
  "Bad Radkersburg (Austria)",
  "Bad Schallerbach (Austria)",
  "Bad Tatzmannsdorf (Austria)",
  "Bad Voslau (Austria)",
  "Baden bei Wien (Austria)",
  "Baernbach (Austria)",
  "Baldramsdorf (Austria)",
  "Bartholomaberg (Austria)",
  "Barwies (Austria)",
  "Bauer (Austria)",
  "Baumgarten im Burgenland (Austria)",
  "Baumgartenberg (Austria)",
  "Baumkirchen (Austria)",
  "Berg (Austria)",
  "Bergau (Austria)",
  "Bergheim (Austria)",
  "Berndorf (Austria)",
  "Bernhardsthal (Austria)",
  "Bernstein (Austria)",
  "Berwang (Austria)",
  "Bezau (Austria)",
  "Biberbach (Austria)",
  "Biberwier (Austria)",
  "Bichlbach (Austria)",
  "Biedermann (Austria)",
  "Biedermannsdorf (Austria)",
  "Birgitz (Austria)",
  "Bisamberg (Austria)",
  "Bizau (Austria)",
  "Bleiberg ob Villach (Austria)",
  "Bleiburg (Austria)",
  "Blindenmarkt (Austria)",
  "Bludenz (Austria)",
  "Bludesch (Austria)",
  "Blumau (Austria)",
  "Bockfliess (Austria)",
  "Bodensdorf (Austria)",
  "Boheimkirchen (Austria)",
  "Bosendurnbach (Austria)",
  "Bramberg am Wildkogel (Austria)",
  "Brandberg (Austria)",
  "Braunau am Inn (Austria)",
  "Bregenz (Austria)",
  "Breitenbrunn (Austria)",
  "Breitenfurt bei Wien (Austria)",
  "Breitenwang (Austria)",
  "Brixen im Thale (Austria)",
  "Bruck am Ziller (Austria)",
  "Bruck an der Grossglocknerstrasse (Austria)",
  "Bruck an der Leitha (Austria)",
  "Bruck an der Mur (Austria)",
  "Bruckl (Austria)",
  "Bruckneudorf (Austria)",
  "Bruendl (Austria)",
  "Bruggen (Austria)",
  "Brunn am Gebirge (Austria)",
  "Buch (Austria)",
  "Buch bei Jenbach (Austria)",
  "Buchkirchen (Austria)",
  "Burgkirchen (Austria)",
  "Burgschleinitz (Austria)",
  "Burmoos (Austria)",
  "Dalaas (Austria)",
  "Dechantskirchen (Austria)",
  "Dellach (Austria)",
  "Desselbrunn (Austria)",
  "Deutsch Goritz (Austria)",
  "Deutsch-Wagram (Austria)",
  "Deutschfeistritz (Austria)",
  "Deutschkreutz (Austria)",
  "Deutschlandsberg (Austria)",
  "Diepoltsham (Austria)",
  "Dietach (Austria)",
  "Dietersdorf am Gnasbach (Austria)",
  "Dobersberg (Austria)",
  "Dobersdorf (Austria)",
  "Doblbach (Austria)",
  "Dobldorf (Austria)",
  "Doebriach (Austria)",
  "Donnerskirchen (Austria)",
  "Doren (Austria)",
  "Dorf (Austria)",
  "Dorfgastein (Austria)",
  "Dorfla (Austria)",
  "Dornbirn (Austria)",
  "Drassburg (Austria)",
  "Drassmarkt (Austria)",
  "Dross (Austria)",
  "Durnstein (Austria)",
  "Ebbs (Austria)",
  "Eben im Pongau (Austria)",
  "Ebenau (Austria)",
  "Ebendorf (Austria)",
  "Ebenfurth (Austria)",
  "Ebensee (Austria)",
  "Ebenthal (Austria)",
  "Ebergassing (Austria)",
  "Eberndorf (Austria)",
  "Eberschwang (Austria)",
  "Ebersdorf (Austria)",
  "Eberstein (Austria)",
  "Ebreichsdorf (Austria)",
  "Eckartsau (Austria)",
  "Edelschrott (Austria)",
  "Edelstal (Austria)",
  "Edlitz (Austria)",
  "Edt bei Lambach (Austria)",
  "Eferding (Austria)",
  "Egg (Austria)",
  "Egg am Faaker See (Austria)",
  "Eggenburg (Austria)",
  "Eggendorf (Austria)",
  "Eggendorf im Thale (Austria)",
  "Eggersdorf bei Graz (Austria)",
  "Ehrenhausen (Austria)",
  "Ehrwald (Austria)",
  "Eibiswald (Austria)",
  "Eichgraben (Austria)",
  "Eisbach (Austria)",
  "Eisenberg (Austria)",
  "Eisenerz (Austria)",
  "Eisenstadt (Austria)",
  "Eisentratten (Austria)",
  "Eisgarn (Austria)",
  "Elixhausen (Austria)",
  "Ellmau (Austria)",
  "Elsbethen (Austria)",
  "Emberg (Austria)",
  "Emmersdorf an der Donau (Austria)",
  "Engelhartszell (Austria)",
  "Engersdorf (Austria)",
  "Engerwitzdorf (Austria)",
  "Enns (Austria)",
  "Ennsdorf (Austria)",
  "Enzenkirchen (Austria)",
  "Enzenreith (Austria)",
  "Enzersdorf an der Fischa (Austria)",
  "Enzersfeld (Austria)",
  "Enzesfeld (Austria)",
  "Erlauf (Austria)",
  "Ernst (Austria)",
  "Ernstbrunn (Austria)",
  "Ernsthofen (Austria)",
  "Erpersdorf (Austria)",
  "Erpfendorf (Austria)",
  "Esternberg (Austria)",
  "Eugendorf (Austria)",
  "Faak am See (Austria)",
  "Falkenburg (Austria)",
  "Fehring (Austria)",
  "Feistritz (Austria)",
  "Feistritz an der Drau (Austria)",
  "Feld am See (Austria)",
  "Feldbach (Austria)",
  "Feldkirch (Austria)",
  "Feldkirchen an der Donau (Austria)",
  "Feldkirchen bei Graz (Austria)",
  "Feldkirchen bei Mattighofen (Austria)",
  "Feldkirchen in Karnten (Austria)",
  "Felixdorf (Austria)",
  "Ferndorf (Austria)",
  "Fernitz (Austria)",
  "Feuchten (Austria)",
  "Feuersbrunn (Austria)",
  "Fieberbrunn (Austria)",
  "Filzmoos (Austria)",
  "Finkenberg (Austria)",
  "Finsing (Austria)",
  "Fischamend Dorf (Austria)",
  "Fischbach (Austria)",
  "Fischerndorf (Austria)",
  "Fiss (Austria)",
  "Flachau (Austria)",
  "Fladnitz im Raabtal (Austria)",
  "Flattach (Austria)",
  "Fliess (Austria)",
  "Flinsdorf (Austria)",
  "Foederlach (Austria)",
  "Fohnsdorf (Austria)",
  "Forst (Austria)",
  "Frankenburg (Austria)",
  "Frankenmarkt (Austria)",
  "Frattingsdorf (Austria)",
  "Frauenkirchen (Austria)",
  "Freienstein (Austria)",
  "Freilassing (Austria)",
  "Freinberg (Austria)",
  "Freistadt (Austria)",
  "Friedburg (Austria)",
  "Friesach (Austria)",
  "Frohnleiten (Austria)",
  "Fuernitz (Austria)",
  "Fuerstenfeld (Austria)",
  "Fugen (Austria)",
  "Fulpmes (Austria)",
  "Furthof (Austria)",
  "Fusch an der Glocknerstrasse (Austria)",
  "Fuschl am See (Austria)",
  "Fussach (Austria)",
  "Gaaden (Austria)",
  "Gaal (Austria)",
  "Gablitz (Austria)",
  "Gaflenz (Austria)",
  "Gailitz (Austria)",
  "Gaisberg (Austria)",
  "Gallbrunn (Austria)",
  "Gallneukirchen (Austria)",
  "Gallspach (Austria)",
  "Gallzein (Austria)",
  "Galtur (Austria)",
  "Gaming (Austria)",
  "Gampern (Austria)",
  "Gansbach (Austria)",
  "Ganserndorf (Austria)",
  "Gargellen (Austria)",
  "Gars am Kamp (Austria)",
  "Garsten (Austria)",
  "Gaschurn (Austria)",
  "Gaspoltshofen (Austria)",
  "Gasthof (Austria)",
  "Gattendorf (Austria)",
  "Gaweinstal (Austria)",
  "Geinberg (Austria)",
  "Geras (Austria)",
  "Gerasdorf am Steinfelde (Austria)",
  "Gerasdorf bei Wien (Austria)",
  "Gerersdorf (Austria)",
  "Gerlos (Austria)",
  "Getzersdorf (Austria)",
  "Gfohl (Austria)",
  "Giesshubl (Austria)",
  "Glan (Austria)",
  "Glaubendorf (Austria)",
  "Gleisdorf (Austria)",
  "Glinzendorf (Austria)",
  "Gloggnitz (Austria)",
  "Gmund (Austria)",
  "Gmunden (Austria)",
  "Gnadenwald (Austria)",
  "Gnas (Austria)",
  "Gobelsburg (Austria)",
  "Goedersdorf (Austria)",
  "Goesselsdorf (Austria)",
  "Gofis (Austria)",
  "Going (Austria)",
  "Goldegg (Austria)",
  "Gollersdorf (Austria)",
  "Golling an der Salzach (Austria)",
  "Gols (Austria)",
  "Gortipohl (Austria)",
  "Gosau (Austria)",
  "Gosdorf (Austria)",
  "Gosing (Austria)",
  "Gostling an der Ybbs (Austria)",
  "Gotzendorf (Austria)",
  "Gotzendorf an der Leitha (Austria)",
  "Gotzens (Austria)",
  "Gotzis (Austria)",
  "Grabelsdorf (Austria)",
  "Graen (Austria)",
  "Grafenbach (Austria)",
  "Grafendorf (Austria)",
  "Grafendorf bei Hartberg (Austria)",
  "Grafendorf bei Stainz (Austria)",
  "Grafenschlag (Austria)",
  "Grafenstein (Austria)",
  "Grafenweg (Austria)",
  "Grafenworth (Austria)",
  "Gramastetten (Austria)",
  "Gramatneusiedl (Austria)",
  "Grambach (Austria)",
  "Gratkorn (Austria)",
  "Gratwein (Austria)",
  "Graz (Austria)",
  "Grein (Austria)",
  "Gresten (Austria)",
  "Gries (Austria)",
  "Gries am Brenner (Austria)",
  "Grieselstein (Austria)",
  "Grieskirchen (Austria)",
  "Griffen (Austria)",
  "Grimmenstein (Austria)",
  "Grobming (Austria)",
  "Grodig (Austria)",
  "Gross (Austria)",
  "Gross-Enzersdorf (Austria)",
  "Gross-Schweinbarth (Austria)",
  "Gross-Siegharts (Austria)",
  "Grossarl (Austria)",
  "Grossebersdorf (Austria)",
  "Grossklein (Austria)",
  "Grosspesendorf (Austria)",
  "Grossraming (Austria)",
  "Grossrussbach (Austria)",
  "Grossweikersdorf (Austria)",
  "Grunbach am Schneeberg (Austria)",
  "Gruner Baum (Austria)",
  "Gschnitz (Austria)",
  "Guessing (Austria)",
  "Gugging (Austria)",
  "Guglwald (Austria)",
  "Gumpoldskirchen (Austria)",
  "Gunselsdorf (Austria)",
  "Gunskirchen (Austria)",
  "Guntersdorf (Austria)",
  "Guntramsdorf (Austria)",
  "Gurten (Austria)",
  "Gurtis (Austria)",
  "Gutau (Austria)",
  "Gutenstein (Austria)",
  "Guttaring (Austria)",
  "Haag (Austria)",
  "Haag am Hausruck (Austria)",
  "Hadersdorf am Kamp (Austria)",
  "Hadersfeld (Austria)",
  "Hadres (Austria)",
  "Hagenberg im Muhlkreis (Austria)",
  "Haibach ob der Donau (Austria)",
  "Haid (Austria)",
  "Haidershofen (Austria)",
  "Haiming (Austria)",
  "Haimingerberg (Austria)",
  "Hainburg an der Donau (Austria)",
  "Hainfeld (Austria)",
  "Halbturn (Austria)",
  "Haldensee (Austria)",
  "Hall (Austria)",
  "Hall in Tirol (Austria)",
  "Hallein (Austria)",
  "Haller (Austria)",
  "Hallstatt (Austria)",
  "Harbach (Austria)",
  "Hard (Austria)",
  "Hart (Austria)",
  "Hartberg (Austria)",
  "Hartkirchen (Austria)",
  "Haslau an der Donau (Austria)",
  "Hatting (Austria)",
  "Hatzendorf (Austria)",
  "Haus (Austria)",
  "Hausbrunn (Austria)",
  "Hausleiten (Austria)",
  "Hausmannstatten (Austria)",
  "Hautzendorf (Austria)",
  "Heidenreichstein (Austria)",
  "Heiligenkreuz am Waasen (Austria)",
  "Heimschuh (Austria)",
  "Heissberg (Austria)",
  "Heissingfelding (Austria)",
  "Heiterwang (Austria)",
  "Hellmonsodt (Austria)",
  "Henndorf am Wallersee (Austria)",
  "Hennersdorf (Austria)",
  "Hermagor (Austria)",
  "Hernstein (Austria)",
  "Herrnbaumgarten (Austria)",
  "Herzogenburg (Austria)",
  "Herzogsdorf (Austria)",
  "Himberg (Austria)",
  "Himmelreich (Austria)",
  "Hinterbruehl (Austria)",
  "Hinterburg (Austria)",
  "Hinterglemm (Austria)",
  "Hintersdorf (Austria)",
  "Hinterstoder (Austria)",
  "Hinterthiersee (Austria)",
  "Hintertux (Austria)",
  "Hinterwaldberg (Austria)",
  "Hipping (Austria)",
  "Hirm (Austria)",
  "Hirschegg (Austria)",
  "Hirtenberg (Austria)",
  "Hochburg (Austria)",
  "Hochgurgl (Austria)",
  "Hochleithen (Austria)",
  "Hochneukirchen (Austria)",
  "Hochsoelden (Austria)",
  "Hochst (Austria)",
  "Hoehe (Austria)",
  "Hof am Leithaberge (Austria)",
  "Hof bei Salzburg (Austria)",
  "Hofen (Austria)",
  "Hofkirchen im Traunkreis (Austria)",
  "Hoflein (Austria)",
  "Hofstatten an der Raab (Austria)",
  "Hofstetten (Austria)",
  "Hohenau (Austria)",
  "Hohenberg (Austria)",
  "Hohenburg (Austria)",
  "Hoheneich (Austria)",
  "Hohenems (Austria)",
  "Hohentauern (Austria)",
  "Hohenwarth (Austria)",
  "Hohenzell (Austria)",
  "Hohnhart (Austria)",
  "Hollabrunn (Austria)",
  "Hollenthon (Austria)",
  "Hollersbach im Pinzgau (Austria)",
  "Holzhausen (Austria)",
  "Hopfgarten im Brixental (Austria)",
  "Horbranz (Austria)",
  "Horersdorf (Austria)",
  "Horn (Austria)",
  "Hornstein (Austria)",
  "Horsching (Austria)",
  "Huetten (Austria)",
  "Huttschlag (Austria)",
  "Igls (Austria)",
  "Im Brand (Austria)",
  "Imst (Austria)",
  "Innerkrems (Austria)",
  "Innermanzing (Austria)",
  "Innsbruck (Austria)",
  "Inzersdorf im Kremstal (Austria)",
  "Inzing (Austria)",
  "Ischgl (Austria)",
  "Isper (Austria)",
  "Jagenbach (Austria)",
  "Jagerberg (Austria)",
  "Japons (Austria)",
  "Jeging (Austria)",
  "Jenbach (Austria)",
  "Jennersdorf (Austria)",
  "Jerzens (Austria)",
  "Jochberg (Austria)",
  "Jois (Austria)",
  "Judenau (Austria)",
  "Judenburg (Austria)",
  "Judendorf (Austria)",
  "Jungholz (Austria)",
  "Kainach bei Voitsberg (Austria)",
  "Kaindorf (Austria)",
  "Kalsdorf bei Graz (Austria)",
  "Kaltenbrunn (Austria)",
  "Kaltenleutgeben (Austria)",
  "Kanzelhoehe (Austria)",
  "Kapelln (Austria)",
  "Kapfenberg (Austria)",
  "Kapfing (Austria)",
  "Kappern (Austria)",
  "Kappl (Austria)",
  "Kaprun (Austria)",
  "Karl (Austria)",
  "Karlstetten (Austria)",
  "Karteis (Austria)",
  "Katsdorf (Austria)",
  "Katzelsdorf (Austria)",
  "Kaumberg (Austria)",
  "Kefermarkt (Austria)",
  "Kematen in Tirol (Austria)",
  "Kennelbach (Austria)",
  "Keutschach am See (Austria)",
  "Kienberg (Austria)",
  "Kierling (Austria)",
  "Kilb (Austria)",
  "Kindberg (Austria)",
  "Kirchbach in Steiermark (Austria)",
  "Kirchberg am Wagram (Austria)",
  "Kirchberg in Tirol (Austria)",
  "Kirchbichl (Austria)",
  "Kirchdorf an der Krems (Austria)",
  "Kirchdorf in Tirol (Austria)",
  "Kirchham (Austria)",
  "Kirchschlag (Austria)",
  "Kirchschlag bei Linz (Austria)",
  "Kittsee (Austria)",
  "Klagenfurt (Austria)",
  "Klam (Austria)",
  "Klaus (Austria)",
  "Klein-Harras (Austria)",
  "Kleinbergl (Austria)",
  "Kleinkirchheim (Austria)",
  "Kleinstubing (Austria)",
  "Klingfurth (Austria)",
  "Klosterle (Austria)",
  "Klostermarienberg (Austria)",
  "Klosterneuburg (Austria)",
  "Knappenberg (Austria)",
  "Knittelfeld (Austria)",
  "Kobenz (Austria)",
  "Kobersdorf (Austria)",
  "Koblach (Austria)",
  "Koestenberg (Austria)",
  "Koetschach (Austria)",
  "Koflach (Austria)",
  "Kollmitzberg (Austria)",
  "Konigstetten (Austria)",
  "Konigswiesen (Austria)",
  "Kopfing im Innkreis (Austria)",
  "Koppl (Austria)",
  "Korneuburg (Austria)",
  "Kossen (Austria)",
  "Kottingbrunn (Austria)",
  "Kottmannsdorf (Austria)",
  "Kraig (Austria)",
  "Kramsach (Austria)",
  "Krems (Austria)",
  "Kremsbruecke (Austria)",
  "Kremsdorf (Austria)",
  "Kremsmunster (Austria)",
  "Krenglbach (Austria)",
  "Kreuz (Austria)",
  "Krieglach (Austria)",
  "Krispl (Austria)",
  "Kritzendorf (Austria)",
  "Kroatisch Minihof (Austria)",
  "Krottendorf bei Ligist (Austria)",
  "Krumbach (Austria)",
  "Krumbach Markt (Austria)",
  "Krumegg (Austria)",
  "Krumpendorf (Austria)",
  "Kuchl (Austria)",
  "Kuehnsdorf (Austria)",
  "Kuehtai (Austria)",
  "Kufstein (Austria)",
  "Kumberg (Austria)",
  "Kundl (Austria)",
  "Laa an der Thaya (Austria)",
  "Laab im Walde (Austria)",
  "Laaben (Austria)",
  "Laakirchen (Austria)",
  "Labach (Austria)",
  "Lambrechten (Austria)",
  "Lamprechtshausen (Austria)",
  "Landeck (Austria)",
  "Landegg (Austria)",
  "Landskron (Austria)",
  "Lanersbach (Austria)",
  "Langeck im Burgenland (Austria)",
  "Langenlois (Austria)",
  "Langenrohr (Austria)",
  "Langenstein (Austria)",
  "Langenzersdorf (Austria)",
  "Lannach (Austria)",
  "Lanzenkirchen (Austria)",
  "Lassee (Austria)",
  "Lassnitzhohe (Austria)",
  "Latschach (Austria)",
  "Launsdorf (Austria)",
  "Lauterach (Austria)",
  "Lavamund (Austria)",
  "Lavant (Austria)",
  "Laxenburg (Austria)",
  "Lebring (Austria)",
  "Lech (Austria)",
  "Lechaschau (Austria)",
  "Leibnitz, Styria (Austria)",
  "Leisach (Austria)",
  "Leiten (Austria)",
  "Leitring (Austria)",
  "Lembach im Muhlkreis (Austria)",
  "Lend (Austria)",
  "Lendorf (Austria)",
  "Lenzing (Austria)",
  "Leoben (Austria)",
  "Leobendorf (Austria)",
  "Leobersdorf (Austria)",
  "Leogang (Austria)",
  "Leonding (Austria)",
  "Leonfelden (Austria)",
  "Leopoldsdorf (Austria)",
  "Lermoos (Austria)",
  "Leutasch (Austria)",
  "Leutschach (Austria)",
  "Lichtenau (Austria)",
  "Lichtenberg (Austria)",
  "Lichtenegg (Austria)",
  "Lichtenworth (Austria)",
  "Liebenfels (Austria)",
  "Lieboch (Austria)",
  "Lienz (Austria)",
  "Liesfeld (Austria)",
  "Liezen (Austria)",
  "Ligist (Austria)",
  "Lilienfeld (Austria)",
  "Lindenberg (Austria)",
  "Linz (Austria)",
  "Litschau (Austria)",
  "Lobenstein (Austria)",
  "Lochau (Austria)",
  "Lochen (Austria)",
  "Lofer (Austria)",
  "Lohnsburg (Austria)",
  "Loimersdorf (Austria)",
  "Loipersbach (Austria)",
  "Loosdorf (Austria)",
  "Loretto (Austria)",
  "Ludesch (Austria)",
  "Luftenberg an der Donau (Austria)",
  "Lungotz (Austria)",
  "Lustenau (Austria)",
  "Lutzmannsburg (Austria)",
  "Mader (Austria)",
  "Madseit (Austria)",
  "Maglern (Austria)",
  "Maiersdorf (Austria)",
  "Maildorf (Austria)",
  "Mairhof (Austria)",
  "Maissau (Austria)",
  "Mallnitz (Austria)",
  "Mandling (Austria)",
  "Mank (Austria)",
  "Mannersdorf am Leithagebirge (Austria)",
  "Mannersdorf an der Rabnitz (Austria)",
  "Mannsworth (Austria)",
  "Marbach (Austria)",
  "Marchegg (Austria)",
  "Marchtrenk (Austria)",
  "Margarethen am Moos (Austria)",
  "Maria Alm am Steinernen Meer (Austria)",
  "Maria Buch (Austria)",
  "Maria Elend (Austria)",
  "Maria Ellend (Austria)",
  "Maria Enzersdorf (Austria)",
  "Maria Lankowitz (Austria)",
  "Maria Lanzendorf (Austria)",
  "Maria Saal (Austria)",
  "Maria Schmolln (Austria)",
  "Maria Taferl (Austria)",
  "Maria Worth (Austria)",
  "Maria-Anzbach (Austria)",
  "Mariazell (Austria)",
  "Markgrafneusiedl (Austria)",
  "Markt Allhau (Austria)",
  "Markt Neuhodis (Austria)",
  "Markt Piesting (Austria)",
  "Marktl (Austria)",
  "Marz (Austria)",
  "Mathon (Austria)",
  "Matrei am Brenner (Austria)",
  "Matrei in Osttirol (Austria)",
  "Mattersburg (Austria)",
  "Mattsee (Austria)",
  "Mauerbach (Austria)",
  "Mauerkirchen (Austria)",
  "Maurach (Austria)",
  "Mauterndorf (Austria)",
  "Mauthausen (Austria)",
  "Mayrhofen (Austria)",
  "Meggenhofen (Austria)",
  "Mehrnbach (Austria)",
  "Meiningen (Austria)",
  "Meisten (Austria)",
  "Melk (Austria)",
  "Mellach (Austria)",
  "Mettmach (Austria)",
  "Michelbach Markt (Austria)",
  "Micheldorf in Oberoesterreich (Austria)",
  "Mieming (Austria)",
  "Miesenbach (Austria)",
  "Millstatt (Austria)",
  "Mistelbach (Austria)",
  "Mittelberg (Austria)",
  "Mitterdorf im Muerztal (Austria)",
  "Mitterkirchen im Machland (Austria)",
  "Mitterndorf an der Fischa (Austria)",
  "Mittersill (Austria)",
  "Moellbruecke (Austria)",
  "Mollersdorf (Austria)",
  "Molln (Austria)",
  "Mollram (Austria)",
  "Monchhof (Austria)",
  "Mondsee (Austria)",
  "Moos (Austria)",
  "Moosburg (Austria)",
  "Moosdorf (Austria)",
  "Mooskirchen (Austria)",
  "Mosern (Austria)",
  "Motz (Austria)",
  "Muckendorf an der Donau (Austria)",
  "Muerzhofen (Austria)",
  "Muerzsteg (Austria)",
  "Muerzzuschlag (Austria)",
  "Muhlbach am Manhartsberg (Austria)",
  "Munchendorf (Austria)",
  "Muntlix (Austria)",
  "Munzbach (Austria)",
  "Munzkirchen (Austria)",
  "Murau (Austria)",
  "Murfeld (Austria)",
  "Mutters (Austria)",
  "Naarn im Machlande (Austria)",
  "Nassereith (Austria)",
  "Natternbach (Austria)",
  "Nauders (Austria)",
  "Neckenmarkt (Austria)",
  "Neidling (Austria)",
  "Nenzing (Austria)",
  "Nesselwangle (Austria)",
  "Nestelbach bei Graz (Austria)",
  "Neudau (Austria)",
  "Neudorf bei Landsee (Austria)",
  "Neudorf bei Staatz (Austria)",
  "Neudorfl (Austria)",
  "Neufeld an der Leitha (Austria)",
  "Neuhofen (Austria)",
  "Neuhofen an der Krems (Austria)",
  "Neuhofen an der Ybbs (Austria)",
  "Neukirchen (Austria)",
  "Neukirchen am Walde (Austria)",
  "Neukirchen an der Enknach (Austria)",
  "Neukirchen an der Vockla (Austria)",
  "Neulengbach (Austria)",
  "Neumarkt am Wallersee (Austria)",
  "Neumarkt an der Ybbs (Austria)",
  "Neumarkt im Hausruckkreis (Austria)",
  "Neumarkt im Muhlkreis (Austria)",
  "Neumarkt in Steiermark (Austria)",
  "Neunkirchen (Austria)",
  "Neusiedl am See (Austria)",
  "Neusiedl bei Guessing (Austria)",
  "Neustift (Austria)",
  "Neutal (Austria)",
  "Neuzeug (Austria)",
  "Nickelsdorf (Austria)",
  "Niederachen (Austria)",
  "Niederalm (Austria)",
  "Niederdorf (Austria)",
  "Niederkreuzstetten (Austria)",
  "Niederland (Austria)",
  "Niederleis (Austria)",
  "Niederndorf (Austria)",
  "Niederneukirchen (Austria)",
  "Niederwaldkirchen (Austria)",
  "Niederwolz (Austria)",
  "Nochling (Austria)",
  "Nussdorf (Austria)",
  "Nussdorf am Haunsberg (Austria)",
  "Nuziders (Austria)",
  "Obdach (Austria)",
  "Ober-Grafendorf (Austria)",
  "Oberaich (Austria)",
  "Oberaichwald (Austria)",
  "Oberalm (Austria)",
  "Oberau (Austria)",
  "Obergruenburg (Austria)",
  "Obergurgl (Austria)",
  "Oberhausen (Austria)",
  "Oberhofen im Inntal (Austria)",
  "Oberkappel (Austria)",
  "Oberkirchbach (Austria)",
  "Oberkohlstaetten (Austria)",
  "Obernberg (Austria)",
  "Obernberg am Brenner (Austria)",
  "Oberndorf an der Melk (Austria)",
  "Oberndorf bei Salzburg (Austria)",
  "Oberneukirchen (Austria)",
  "Oberort (Austria)",
  "Oberperfuss (Austria)",
  "Oberpullendorf (Austria)",
  "Oberrohrbach (Austria)",
  "Oberschutzen (Austria)",
  "Obersdorf (Austria)",
  "Obersiebenbrunn (Austria)",
  "Obertauern (Austria)",
  "Obertrum am See (Austria)",
  "Obervellach (Austria)",
  "Oberwaltersdorf (Austria)",
  "Oberwart (Austria)",
  "Oberweis (Austria)",
  "Oberwolbling (Austria)",
  "Obritzberg (Austria)",
  "Obsteig (Austria)",
  "Oed (Austria)",
  "Oehling (Austria)",
  "Oepping (Austria)",
  "Oetz (Austria)",
  "OEtztal-Bahnhof (Austria)",
  "Offenhausen (Austria)",
  "Oftering (Austria)",
  "Oggau (Austria)",
  "Ohlsdorf (Austria)",
  "Ollern (Austria)",
  "Opponitz (Austria)",
  "Ort im Innkreis (Austria)",
  "Orth an der Donau (Austria)",
  "Oslip (Austria)",
  "Ossiach (Austria)",
  "Ostermiething (Austria)",
  "Ottendorf bei Gleisdorf (Austria)",
  "Ottenheim (Austria)",
  "Ottensheim (Austria)",
  "Pabneukirchen (Austria)",
  "Pachfurth (Austria)",
  "Paldau (Austria)",
  "Panzendorf (Austria)",
  "Parndorf (Austria)",
  "Parschlug (Austria)",
  "Pasching (Austria)",
  "Passail (Austria)",
  "Passthurn (Austria)",
  "Patsch (Austria)",
  "Pattergassen (Austria)",
  "Payerbach (Austria)",
  "Peggau (Austria)",
  "Perchtoldsdorf (Austria)",
  "Perg (Austria)",
  "Pernegg an der Mur (Austria)",
  "Pernitz (Austria)",
  "Pertisau (Austria)",
  "Perwang am Grabensee (Austria)",
  "Petersdorf (Austria)",
  "Petronell-Carnuntum (Austria)",
  "Pettenbach (Austria)",
  "Pettnau (Austria)",
  "Pettneu (Austria)",
  "Petzenkirchen (Austria)",
  "Peuerbach (Austria)",
  "Pfaffstatten (Austria)",
  "Pfons (Austria)",
  "Pfunds (Austria)",
  "Piberbach (Austria)",
  "Pichl (Austria)",
  "Pichl bei Wels (Austria)",
  "Pichling bei Stainz (Austria)",
  "Piesendorf (Austria)",
  "Pillberg (Austria)",
  "Pillichsdorf (Austria)",
  "Pinggau (Austria)",
  "Pinkafeld (Austria)",
  "Pinsdorf (Austria)",
  "Piringsdorf (Austria)",
  "Pirka (Austria)",
  "Pischeldorf (Austria)",
  "Pischelsdorf am Engelbach (Austria)",
  "Pischelsdorf in Steiermark (Austria)",
  "Pitten (Austria)",
  "Plangeross (Austria)",
  "Plankenau (Austria)",
  "Plescherken (Austria)",
  "Pochlarn (Austria)",
  "Poelten (Austria)",
  "Poertschach am Woerthersee (Austria)",
  "Poetting (Austria)",
  "Poggstall (Austria)",
  "Polfing (Austria)",
  "Pottelsdorf (Austria)",
  "Pottenbrunn (Austria)",
  "Pottendorf (Austria)",
  "Pottenstein (Austria)",
  "Pottsching (Austria)",
  "Potzneusiedl (Austria)",
  "Poysbrunn (Austria)",
  "Poysdorf (Austria)",
  "Prabach (Austria)",
  "Pram (Austria)",
  "Preding (Austria)",
  "Pregarten (Austria)",
  "Pressbaum (Austria)",
  "Prinzersdorf (Austria)",
  "Puch (Austria)",
  "Puch bei Hallein (Austria)",
  "Puchberg am Schneeberg (Austria)",
  "Puchenau (Austria)",
  "Pucking (Austria)",
  "Purbach (Austria)",
  "Purbach am Neusiedlersee (Austria)",
  "Purgg (Austria)",
  "Purgstall (Austria)",
  "Purkersdorf (Austria)",
  "Pyhra (Austria)",
  "Raab (Austria)",
  "Raaba (Austria)",
  "Raabs an der Thaya (Austria)",
  "Radfeld (Austria)",
  "Radstadt (Austria)",
  "Ramsau im Zillertal (Austria)",
  "Randegg (Austria)",
  "Rangersdorf (Austria)",
  "Rankweil (Austria)",
  "Rannersdorf (Austria)",
  "Rappoltenkirchen (Austria)",
  "Rauchenwarth (Austria)",
  "Rauris (Austria)",
  "Redlham (Austria)",
  "Regau (Austria)",
  "Regelsbrunn (Austria)",
  "Reichenau an der Rax (Austria)",
  "Reichenau im Muhlkreis (Austria)",
  "Reichendorf (Austria)",
  "Reichraming (Austria)",
  "Reifnitz (Austria)",
  "Reisenberg (Austria)",
  "Reitdorf (Austria)",
  "Reith (Austria)",
  "Reith bei Seefeld (Austria)",
  "Reith im Alpbachtal (Austria)",
  "Rekawinkel (Austria)",
  "Reutte (Austria)",
  "Ried im Innkreis (Austria)",
  "Ried im Traunkreis (Austria)",
  "Ried im Zillertal (Austria)",
  "Ried in der Riedmark (Austria)",
  "Riedenthal (Austria)",
  "Rieding (Austria)",
  "Riedlingsdorf (Austria)",
  "Riefensberg (Austria)",
  "Riegersburg (Austria)",
  "Riezlern (Austria)",
  "Ringelsdorf (Austria)",
  "Rinn (Austria)",
  "Rohrau (Austria)",
  "Rohrbach am Kulm (Austria)",
  "Rohrbach am Steinfelde (Austria)",
  "Rohrbach in Oberosterreich (Austria)",
  "Rohrmoos (Austria)",
  "Roppen (Austria)",
  "Rosegg (Austria)",
  "Rosenau am Hengstpass (Austria)",
  "Rosenbach (Austria)",
  "Rosental (Austria)",
  "Rotenbach (Austria)",
  "Rothis (Austria)",
  "Rotholz (Austria)",
  "Rottenbach (Austria)",
  "Rottenmann (Austria)",
  "Rubring (Austria)",
  "Ruden (Austria)",
  "Rudersdorf (Austria)",
  "Rum (Austria)",
  "Rutzenmoos (Austria)",
  "Saalbach (Austria)",
  "Saalfelden am Steinernen Meer (Austria)",
  "Sachendorf (Austria)",
  "Sachsenburg (Austria)",
  "Saint Anton (Austria)",
  "Salzburg (Austria)",
  "Sankt Agatha (Austria)",
  "Sankt Andra am Zicksee (Austria)",
  "Sankt Andrae (Austria)",
  "Sankt Florian (Austria)",
  "Sankt Gallenkirch (Austria)",
  "Sankt Georgen am Ybbsfelde (Austria)",
  "Sankt Georgen im Attergau (Austria)",
  "Sankt Johann im Pongau (Austria)",
  "Sankt Kanzian (Austria)",
  "Sankt Kathrein am Hauenstein (Austria)",
  "Sankt Lorenzen (Austria)",
  "Sankt Lorenzen im Muerztal (Austria)",
  "Sankt Margarethen im Burgenland (Austria)",
  "Sankt Marienkirchen am Hausruck (Austria)",
  "Sankt Martin am Techelsberg (Austria)",
  "Sankt Martin am Ybbsfelde (Austria)",
  "Sankt Michael im Burgenland (Austria)",
  "Sankt Michael in Obersteiermark (Austria)",
  "Sankt Oswald (Austria)",
  "Sankt Pankraz (Austria)",
  "Sankt Peter (Austria)",
  "Sankt Polten (Austria)",
  "Sankt Ruprecht an der Raab (Austria)",
  "Sankt Stefan im Rosental (Austria)",
  "Sankt Ulrich bei Steyr (Austria)",
  "Sankt Valentin (Austria)",
  "Sankt Veit an der Glan (Austria)",
  "Sankt Veit im Pongau (Austria)",
  "Sankt Wolfgang (Austria)",
  "Sarleinsbach (Austria)",
  "Satteins (Austria)",
  "Sauerbrunn (Austria)",
  "Sausenstein (Austria)",
  "Schachendorf (Austria)",
  "Schalchen (Austria)",
  "Schardenberg (Austria)",
  "Scharding (Austria)",
  "Scharnstein (Austria)",
  "Scharten (Austria)",
  "Schattendorf (Austria)",
  "Scheffau am Wilden Kaiser (Austria)",
  "Scheifling (Austria)",
  "Schiefling am See (Austria)",
  "Schladming (Austria)",
  "Schleinbach (Austria)",
  "Schlierbach (Austria)",
  "Schlins (Austria)",
  "Schlitters (Austria)",
  "Schmirn (Austria)",
  "Schneeberg (Austria)",
  "Schoenau (Austria)",
  "Schoenberg im Stubaital (Austria)",
  "Schoerfling (Austria)",
  "Schonbuhel an der Donau (Austria)",
  "Schongrabern (Austria)",
  "Schonkirchen (Austria)",
  "Schoppernau (Austria)",
  "Schrems (Austria)",
  "Schruns (Austria)",
  "Schutzen am Gebirge (Austria)",
  "Schwadorf (Austria)",
  "Schwaigs (Austria)",
  "Schwanberg (Austria)",
  "Schwanenstadt (Austria)",
  "Schwarzach (Austria)",
  "Schwarzach im Pongau (Austria)",
  "Schwarzau am Steinfelde (Austria)",
  "Schwarzau im Gebirge (Austria)",
  "Schwarzenau (Austria)",
  "Schwarzenberg am Boehmerwald (Austria)",
  "Schwaz (Austria)",
  "Schwechat (Austria)",
  "Schweiggers (Austria)",
  "Schwendau (Austria)",
  "Schwertberg (Austria)",
  "Schwoich (Austria)",
  "Sebersdorf (Austria)",
  "Seckau (Austria)",
  "Seeboden (Austria)",
  "Seefeld in Tirol (Austria)",
  "Seeham (Austria)",
  "Seekirchen am Wallersee (Austria)",
  "Seewalchen (Austria)",
  "Seiersberg (Austria)",
  "Seitenstetten Markt (Austria)",
  "Semmering (Austria)",
  "Semriach (Austria)",
  "Serfaus (Austria)",
  "Sernau (Austria)",
  "Sibratsgfall (Austria)",
  "Siebenhirten (Austria)",
  "Siegendorf im Burgenland (Austria)",
  "Sieghartskirchen (Austria)",
  "Sierndorf (Austria)",
  "Sierning (Austria)",
  "Siezenheim (Austria)",
  "Sillian (Austria)",
  "Silz (Austria)",
  "Sinabelkirchen (Austria)",
  "Sinnersdorf (Austria)",
  "Sipbachzell (Austria)",
  "Sirnitz (Austria)",
  "Sistrans (Austria)",
  "Sitzenberg (Austria)",
  "Solden (Austria)",
  "Soll (Austria)",
  "Sollenau (Austria)",
  "Sommerein (Austria)",
  "Sooss (Austria)",
  "Spielberg bei Knittelfeld (Austria)",
  "Spillern (Austria)",
  "Spital am Pyhrn (Austria)",
  "Spittal an der Drau (Austria)",
  "Spitz (Austria)",
  "Spratzern (Austria)",
  "Staatz (Austria)",
  "Stadelbach (Austria)",
  "Stadl-Paura (Austria)",
  "Stadtschlaining (Austria)",
  "Stainz (Austria)",
  "Stainz bei Straden (Austria)",
  "Stams (Austria)",
  "Stans (Austria)",
  "Stattegg (Austria)",
  "Statzendorf (Austria)",
  "Steg (Austria)",
  "Stegersbach (Austria)",
  "Stein an der Donau (Austria)",
  "Stein an der Enns (Austria)",
  "Stein im Jauntal (Austria)",
  "Steinabruckl (Austria)",
  "Steinach am Brenner (Austria)",
  "Steinakirchen am Forst (Austria)",
  "Steinberg bei Ligist (Austria)",
  "Steindorf am Ossiacher See (Austria)",
  "Steinerberg (Austria)",
  "Steinerkirchen an der Traun (Austria)",
  "Steinfeld (Austria)",
  "Steinhaus (Austria)",
  "Steinhaus am Semmering (Austria)",
  "Stettenhof (Austria)",
  "Steyr (Austria)",
  "Steyregg (Austria)",
  "Stiegl (Austria)",
  "Stockerau (Austria)",
  "Stoob (Austria)",
  "Straden (Austria)",
  "Strass (Austria)",
  "Strassen (Austria)",
  "Strassengel (Austria)",
  "Strasshof an der Nordbahn (Austria)",
  "Strasswalchen (Austria)",
  "Strau (Austria)",
  "Strobl (Austria)",
  "Stubach (Austria)",
  "Stubenberg (Austria)",
  "Studenzen (Austria)",
  "Stuhlfelden (Austria)",
  "Stuhlsdorf (Austria)",
  "Stumm (Austria)",
  "Stummerberg (Austria)",
  "Sulz (Austria)",
  "Sulz im Wienerwald (Austria)",
  "Sulzau (Austria)",
  "Sulzberg (Austria)",
  "Tadten (Austria)",
  "Tal (Austria)",
  "Tamsweg (Austria)",
  "Tannheim (Austria)",
  "Tarrenz (Austria)",
  "Tattendorf (Austria)",
  "Taufkirchen an der Pram (Austria)",
  "Taufkirchen an der Trattnach (Austria)",
  "Tauplitz (Austria)",
  "Taxenbach (Austria)",
  "Telfes im Stubai (Austria)",
  "Telfs (Austria)",
  "Ternberg (Austria)",
  "Ternitz (Austria)",
  "Teufenbach (Austria)",
  "Thal (Austria)",
  "Thalgau (Austria)",
  "Thalheim bei Wels (Austria)",
  "Thalling (Austria)",
  "Thaur (Austria)",
  "Thaya (Austria)",
  "Theresienfeld (Austria)",
  "Thoerl (Austria)",
  "Thuringen (Austria)",
  "Tiefenbach (Austria)",
  "Tiefgraben (Austria)",
  "Tieschen (Austria)",
  "Tirol (Austria)",
  "Tobaj (Austria)",
  "Toeschling (Austria)",
  "Toesenssteinach (Austria)",
  "Traboch (Austria)",
  "Traisen (Austria)",
  "Traiskirchen (Austria)",
  "Traismauer (Austria)",
  "Trasdorf (Austria)",
  "Traun (Austria)",
  "Traunkirchen (Austria)",
  "Traunstein (Austria)",
  "Trausdorf an der Wulka (Austria)",
  "Trautmannsdorf an der Leitha (Austria)",
  "Tribuswinkel (Austria)",
  "Trieben (Austria)",
  "Tristach (Austria)",
  "Troepolach (Austria)",
  "Trofaiach (Austria)",
  "Trumau (Austria)",
  "Tschagguns (Austria)",
  "Tschoeran (Austria)",
  "Tulln (Austria)",
  "Tullnerbach-Lawies (Austria)",
  "Tumeltsham (Austria)",
  "Turnitz (Austria)",
  "Turrach (Austria)",
  "Tweng (Austria)",
  "Uderns (Austria)",
  "Ulmerfeld (Austria)",
  "Ulrichsberg (Austria)",
  "Ulrichskirchen (Austria)",
  "Ungenach (Austria)",
  "Unterberg (Austria)",
  "Untergrub (Austria)",
  "Unterjoch (Austria)",
  "Unterlamm (Austria)",
  "Unterparschenbrunn (Austria)",
  "Unterpremstatten (Austria)",
  "Unterpullendorf (Austria)",
  "Unterpurkla (Austria)",
  "Unterradlberg (Austria)",
  "Unterrohr (Austria)",
  "Untersiebenbrunn (Austria)",
  "Unterwaltersdorf (Austria)",
  "Unterweitersdorf (Austria)",
  "Unzmarkt (Austria)",
  "Uttendorf (Austria)",
  "Utzenaich (Austria)",
  "Veitsch (Austria)",
  "Velden am Woerthersee (Austria)",
  "Vent (Austria)",
  "Vienna (Austria)",
  "Vigaun (Austria)",
  "Viktorsberg (Austria)",
  "Viktring (Austria)",
  "Villach (Austria)",
  "Virgen (Austria)",
  "Vitis (Austria)",
  "Vocklabruck (Austria)",
  "Vocklamarkt (Austria)",
  "Voelkermarkt (Austria)",
  "Voitsberg (Austria)",
  "Volders (Austria)",
  "Vols (Austria)",
  "Vorau (Austria)",
  "Vorchdorf (Austria)",
  "Vorderlanersbach (Austria)",
  "Vordernberg (Austria)",
  "Vosendorf (Austria)",
  "Wagna (Austria)",
  "Wagrain (Austria)",
  "Waidhofen an der Thaya (Austria)",
  "Waidhofen an der Ybbs (Austria)",
  "Waidmannsfeld (Austria)",
  "Waidring (Austria)",
  "Waizenkirchen (Austria)",
  "Walbersdorf (Austria)",
  "Walchsee (Austria)",
  "Wald am Arlberg (Austria)",
  "Waldenstein (Austria)",
  "Walding (Austria)",
  "Waldkirchen am Wesen (Austria)",
  "Waldzell (Austria)",
  "Wallern an der Trattnach (Austria)",
  "Wallern im Burgenland (Austria)",
  "Wallsee (Austria)",
  "Wals (Austria)",
  "Waltersdorf an der March (Austria)",
  "Waltersdorf in Oststeiermark (Austria)",
  "Wampersdorf (Austria)",
  "Wang (Austria)",
  "Wangle (Austria)",
  "Wartberg (Austria)",
  "Wartberg an der Krems (Austria)",
  "Warth (Austria)",
  "Wattens (Austria)",
  "Watzelsdorf (Austria)",
  "Watzendorf (Austria)",
  "Waxenberg (Austria)",
  "Weibern (Austria)",
  "Weiden am See (Austria)",
  "Weigelsdorf (Austria)",
  "Weikendorf (Austria)",
  "Weikersdorf am Steinfelde (Austria)",
  "Weilbach (Austria)",
  "Weiler (Austria)",
  "Weinburg am Sassbach (Austria)",
  "Weissbriach (Austria)",
  "Weissenbach am Lech (Austria)",
  "Weissenkirchen in der Wachau (Austria)",
  "Weisskirchen an der Traun (Austria)",
  "Weisskirchen in Steiermark (Austria)",
  "Weiten (Austria)",
  "Weitersfeld (Austria)",
  "Weitra (Austria)",
  "Weiz (Austria)",
  "Wels (Austria)",
  "Weng bei Admont (Austria)",
  "Weng im Innkreis (Austria)",
  "Wenns (Austria)",
  "Weppersdorf (Austria)",
  "Werfen (Austria)",
  "Werfenweng (Austria)",
  "Werndorf (Austria)",
  "Wernstein am Inn (Austria)",
  "Westendorf (Austria)",
  "Wettmannstatten (Austria)",
  "Wiener Neudorf (Austria)",
  "Wiener Neustadt (Austria)",
  "Wienerherberg (Austria)",
  "Wies (Austria)",
  "Wieselburg (Austria)",
  "Wiesing (Austria)",
  "Wiesmath (Austria)",
  "Wieting (Austria)",
  "Wildon (Austria)",
  "Wilfleinsdorf (Austria)",
  "Wilhelmsburg (Austria)",
  "Wilhering (Austria)",
  "Wimpassing an der Leitha (Austria)",
  "Wimpassing im Schwarzatale (Austria)",
  "Winden am See (Austria)",
  "Windischgarsten (Austria)",
  "Winkl (Austria)",
  "Winzendorf (Austria)",
  "Woelfnitz (Austria)",
  "Wolfau (Austria)",
  "Wolfpassing (Austria)",
  "Wolfsberg (Austria)",
  "Wolfsgraben (Austria)",
  "Wolfurt (Austria)",
  "Wolkersdorf im Weinviertel (Austria)",
  "Wollersdorf (Austria)",
  "Wordern (Austria)",
  "Worgl (Austria)",
  "Wulkaprodersdorf (Austria)",
  "Wundschuh (Austria)",
  "Wurflach (Austria)",
  "Wurmbrand (Austria)",
  "Ybbs an der Donau (Austria)",
  "Ybbsitz (Austria)",
  "Zams (Austria)",
  "Zeillern (Austria)",
  "Zeiselmauer (Austria)",
  "Zell am Moos (Austria)",
  "Zell am See (Austria)",
  "Zell am Ziller (Austria)",
  "Zell an der Ybbs (Austria)",
  "Zellerndorf (Austria)",
  "Zeltweg (Austria)",
  "Zemendorf (Austria)",
  "Zettling (Austria)",
  "Ziersdorf (Austria)",
  "Zillingdorf (Austria)",
  "Zillingtal (Austria)",
  "Zipf (Austria)",
  "Zirl (Austria)",
  "Zistersdorf (Austria)",
  "Zobing (Austria)",
  "Zuers (Austria)",
  "Zurndorf (Austria)",
  "Zwentendorf (Austria)",
  "Zwettl Stadt (Austria)",
  "Zwolfaxing (Austria)",
  "Azerbaijan",
  "Baku (Azerbaijan)",
  "Nakhchivan (Azerbaijan)",
  "Quba (Azerbaijan)",
  "Qusar (Azerbaijan)",
  "Sulutapa (Azerbaijan)",
  "Sumqayit (Azerbaijan)",
  "Xirdalan (Azerbaijan)",
  "Zurges (Azerbaijan)",
  "Bahamas",
  "Andros Town (Bahamas)",
  "Dunmore Town (Bahamas)",
  "Freeport (Bahamas)",
  "Marsh Harbour (Bahamas)",
  "Nassau (Bahamas)",
  "Palmetto Point (Bahamas)",
  "Spanish Wells (Bahamas)",
  "Bahrain",
  "Al Budayyi` (Bahrain)",
  "Al Hadd (Bahrain)",
  "Al Hamalah (Bahrain)",
  "Al Janabiyah (Bahrain)",
  "Al Markh (Bahrain)",
  "Al Muharraq (Bahrain)",
  "Bani Jamrah (Bahrain)",
  "Barbar (Bahrain)",
  "Jurdab (Bahrain)",
  "Madinat `Isa (Bahrain)",
  "Madinat Hamad (Bahrain)",
  "Manama (Bahrain)",
  "Oil City (Bahrain)",
  "Sanabis (Bahrain)",
  "Sanad (Bahrain)",
  "Sitrah (Bahrain)",
  "Tubli (Bahrain)",
  "Bangladesh",
  "Agrabad (Bangladesh)",
  "Bangla (Bangladesh)",
  "Barisal (Bangladesh)",
  "Bazar (Bangladesh)",
  "Chittagong (Bangladesh)",
  "Comilla (Bangladesh)",
  "Delta (Bangladesh)",
  "Dhaka (Bangladesh)",
  "Hossain (Bangladesh)",
  "Jessore (Bangladesh)",
  "Kabi (Bangladesh)",
  "Kazipur (Bangladesh)",
  "Mymensingh (Bangladesh)",
  "Narayanganj (Bangladesh)",
  "Rajshahi (Bangladesh)",
  "Tejgaon (Bangladesh)",
  "Uttara (Bangladesh)",
  "Barbados",
  "Atlantic Shores (Barbados)",
  "Bagatelle (Barbados)",
  "Bloomsbury (Barbados)",
  "Bridgetown (Barbados)",
  "Bruce Vale (Barbados)",
  "Cave Hill (Barbados)",
  "Clapham (Barbados)",
  "Hastings (Barbados)",
  "Holetown (Barbados)",
  "Husbands (Barbados)",
  "Jackmans (Barbados)",
  "Oistins (Barbados)",
  "Pine Housing Estate (Barbados)",
  "Porters (Barbados)",
  "Rendezvous (Barbados)",
  "Rockley (Barbados)",
  "The Garden (Barbados)",
  "Wanstead (Barbados)",
  "Warners (Barbados)",
  "Warrens (Barbados)",
  "White Hill (Barbados)",
  "Wildey (Barbados)",
  "Worthing (Barbados)",
  "Belarus",
  "Baranovichi (Belarus)",
  "Borisov (Belarus)",
  "Brest (Belarus)",
  "Dzyarzhynsk (Belarus)",
  "Horki (Belarus)",
  "Hrodna (Belarus)",
  "Lahoysk (Belarus)",
  "Lida (Belarus)",
  "Lyakhovichi (Belarus)",
  "Lyaskavichy (Belarus)",
  "Mazyr (Belarus)",
  "Minsk (Belarus)",
  "Mogilev (Belarus)",
  "Navapolatsk (Belarus)",
  "Orsha (Belarus)",
  "Pinsk (Belarus)",
  "Polatsk (Belarus)",
  "Radoshkovichi (Belarus)",
  "Shklow (Belarus)",
  "Vitebsk (Belarus)",
  "Zaslawye (Belarus)",
  "Zhodzina (Belarus)",
  "Belgium",
  "Aalbeke (Belgium)",
  "Aalst (Belgium)",
  "Aalter (Belgium)",
  "Aarschot (Belgium)",
  "Aarsele (Belgium)",
  "Aartrijke (Belgium)",
  "Aartselaar (Belgium)",
  "Achel (Belgium)",
  "Adegem (Belgium)",
  "Adinkerke (Belgium)",
  "Afsnee (Belgium)",
  "Agimont (Belgium)",
  "Alken (Belgium)",
  "Alleur (Belgium)",
  "Alsemberg (Belgium)",
  "Alveringem (Belgium)",
  "Amay (Belgium)",
  "Ampsin (Belgium)",
  "Andenne (Belgium)",
  "Anderlecht (Belgium)",
  "Anderlues (Belgium)",
  "Andrimont (Belgium)",
  "Angleur (Belgium)",
  "Ans (Belgium)",
  "Anseroeul (Belgium)",
  "Anthisnes (Belgium)",
  "Antwerp (Belgium)",
  "Anzegem (Belgium)",
  "Appels (Belgium)",
  "Appelterre-Eichem (Belgium)",
  "Arbrefontaine (Belgium)",
  "Ardooie (Belgium)",
  "Arendonk (Belgium)",
  "Argenteau (Belgium)",
  "Arlon (Belgium)",
  "Arquennes (Belgium)",
  "Arsimont (Belgium)",
  "As (Belgium)",
  "Asse (Belgium)",
  "Assebroek (Belgium)",
  "Assenede (Belgium)",
  "Assent (Belgium)",
  "Assesse (Belgium)",
  "Astene (Belgium)",
  "Astenet (Belgium)",
  "Ath (Belgium)",
  "Attenhoven (Belgium)",
  "Attre (Belgium)",
  "Aubange (Belgium)",
  "Aubel (Belgium)",
  "Auderghem (Belgium)",
  "Auvelais (Belgium)",
  "Avekapelle (Belgium)",
  "Avelgem (Belgium)",
  "Averbode (Belgium)",
  "Awans (Belgium)",
  "Awirs (Belgium)",
  "Aywaille (Belgium)",
  "Baal (Belgium)",
  "Baarle-Hertog (Belgium)",
  "Baasrode (Belgium)",
  "Baelen (Belgium)",
  "Baisy-Thy (Belgium)",
  "Balen (Belgium)",
  "Banneux (Belgium)",
  "Barchon (Belgium)",
  "Barry (Belgium)",
  "Basecles (Belgium)",
  "Bassenge (Belgium)",
  "Bassevelde (Belgium)",
  "Bastogne (Belgium)",
  "Battice (Belgium)",
  "Baudour (Belgium)",
  "Bavegem (Belgium)",
  "Bavikhove (Belgium)",
  "Bazel (Belgium)",
  "Beaufays (Belgium)",
  "Beauvechain (Belgium)",
  "Beernem (Belgium)",
  "Beerse (Belgium)",
  "Beersel (Belgium)",
  "Beervelde (Belgium)",
  "Beerzel (Belgium)",
  "Beez (Belgium)",
  "Begijnendijk (Belgium)",
  "Beigem (Belgium)",
  "Bekkevoort (Belgium)",
  "Belgrade (Belgium)",
  "Belle Fontaine (Belgium)",
  "Bellem (Belgium)",
  "Beloeil (Belgium)",
  "Belsele (Belgium)",
  "Berchem (Belgium)",
  "Berendrecht (Belgium)",
  "Beringen (Belgium)",
  "Berlaar (Belgium)",
  "Berlare (Belgium)",
  "Bernissart (Belgium)",
  "Bertem (Belgium)",
  "Bertrix (Belgium)",
  "Betekom (Belgium)",
  "Bevel (Belgium)",
  "Beveren (Belgium)",
  "Beverlo (Belgium)",
  "Beyne-Heusay (Belgium)",
  "Bierbeek (Belgium)",
  "Biercee (Belgium)",
  "Bierges (Belgium)",
  "Bierset (Belgium)",
  "Bilstain (Belgium)",
  "Bilzen (Belgium)",
  "Binche (Belgium)",
  "Bissegem (Belgium)",
  "Blaasveld (Belgium)",
  "Blanden (Belgium)",
  "Blankenberge (Belgium)",
  "Blegny (Belgium)",
  "Bocholt (Belgium)",
  "Boechout (Belgium)",
  "Boezinge (Belgium)",
  "Boncelles (Belgium)",
  "Bonheiden (Belgium)",
  "Bonlez (Belgium)",
  "Booischot (Belgium)",
  "Boom (Belgium)",
  "Boorsem (Belgium)",
  "Boortmeerbeek (Belgium)",
  "Borchtlombeek (Belgium)",
  "Borgerhout (Belgium)",
  "Borgloon (Belgium)",
  "Bornem (Belgium)",
  "Borsbeek (Belgium)",
  "Borsbeke (Belgium)",
  "Bouge (Belgium)",
  "Bouillon (Belgium)",
  "Boussu (Belgium)",
  "Bousval (Belgium)",
  "Boutersem (Belgium)",
  "Bouwel (Belgium)",
  "Bovekerke (Belgium)",
  "Bovesse (Belgium)",
  "Braine-le-Chateau (Belgium)",
  "Braine-le-Comte (Belgium)",
  "Braives (Belgium)",
  "Brasmenil (Belgium)",
  "Brasschaat (Belgium)",
  "Brecht (Belgium)",
  "Bredene (Belgium)",
  "Bree (Belgium)",
  "Bressoux (Belgium)",
  "Broechem (Belgium)",
  "Brugelette (Belgium)",
  "Bruges (Belgium)",
  "Brussegem (Belgium)",
  "Brussels (Belgium)",
  "Budingen (Belgium)",
  "Buggenhout (Belgium)",
  "Buizingen (Belgium)",
  "Bunsbeek (Belgium)",
  "Burcht (Belgium)",
  "Burdinne (Belgium)",
  "Burst (Belgium)",
  "Butgenbach (Belgium)",
  "Buvrinnes (Belgium)",
  "Carnieres (Belgium)",
  "Casteau (Belgium)",
  "Celles (Belgium)",
  "Cerexhe-Heuseux (Belgium)",
  "Chapelle-lez-Herlaimont (Belgium)",
  "Charleroi (Belgium)",
  "Charneux (Belgium)",
  "Chastre (Belgium)",
  "Chatelet (Belgium)",
  "Chatelineau (Belgium)",
  "Chaudfontaine (Belgium)",
  "Chaumont-Gistoux (Belgium)",
  "Chaussee-Notre-Dame-Louvignies (Belgium)",
  "Chenee (Belgium)",
  "Chercq (Belgium)",
  "Chevron (Belgium)",
  "Chievres (Belgium)",
  "Chimay (Belgium)",
  "Chiny (Belgium)",
  "Ciney (Belgium)",
  "Ciplet (Belgium)",
  "Clavier (Belgium)",
  "Colfontaine (Belgium)",
  "Comblain-au-Pont (Belgium)",
  "Comines (Belgium)",
  "Coret (Belgium)",
  "Corroy-le-Grand (Belgium)",
  "Couillet (Belgium)",
  "Cour-sur-Heure (Belgium)",
  "Courcelles (Belgium)",
  "Court-Saint-Etienne (Belgium)",
  "Couthuin (Belgium)",
  "Couvin (Belgium)",
  "Crisnee (Belgium)",
  "Cuesmes (Belgium)",
  "Dadizele (Belgium)",
  "Dalhem (Belgium)",
  "Damme (Belgium)",
  "Dampremy (Belgium)",
  "Dave (Belgium)",
  "De Haan (Belgium)",
  "De Klinge (Belgium)",
  "De Panne (Belgium)",
  "De Pinte (Belgium)",
  "Deerlijk (Belgium)",
  "Deftinge (Belgium)",
  "Deinze (Belgium)",
  "Denderbelle (Belgium)",
  "Denderhoutem (Belgium)",
  "Denderleeuw (Belgium)",
  "Dendermonde (Belgium)",
  "Dentergem (Belgium)",
  "Dessel (Belgium)",
  "Desselgem (Belgium)",
  "Destelbergen (Belgium)",
  "Deurle (Belgium)",
  "Deurne (Belgium)",
  "Diegem (Belgium)",
  "Diepenbeek (Belgium)",
  "Diest (Belgium)",
  "Diksmuide (Belgium)",
  "Dilbeek (Belgium)",
  "Dilsen (Belgium)",
  "Dinant (Belgium)",
  "Dison (Belgium)",
  "Donceel (Belgium)",
  "Dongelberg (Belgium)",
  "Dorinne (Belgium)",
  "Dottignies (Belgium)",
  "Dour (Belgium)",
  "Dranouter (Belgium)",
  "Drogenbos (Belgium)",
  "Drongen (Belgium)",
  "Dudzele (Belgium)",
  "Duffel (Belgium)",
  "Duisburg (Belgium)",
  "Dworp (Belgium)",
  "Ecaussinnes-Lalaing (Belgium)",
  "Edegem (Belgium)",
  "Eeklo (Belgium)",
  "Eernegem (Belgium)",
  "Eghezee (Belgium)",
  "Eigenbilzen (Belgium)",
  "Eke (Belgium)",
  "Ekeren (Belgium)",
  "Eksaarde (Belgium)",
  "Eksel (Belgium)",
  "Elen (Belgium)",
  "Elewijt (Belgium)",
  "Elingen (Belgium)",
  "Ellezelles (Belgium)",
  "Ellignies-Sainte-Anne (Belgium)",
  "Elverdinge (Belgium)",
  "Elversele (Belgium)",
  "Emblem (Belgium)",
  "Embourg (Belgium)",
  "Emptinne (Belgium)",
  "Enghien (Belgium)",
  "Engis (Belgium)",
  "Ensival (Belgium)",
  "Eppegem (Belgium)",
  "Erbaut (Belgium)",
  "Erembodegem (Belgium)",
  "Erezee (Belgium)",
  "Erondegem (Belgium)",
  "Erpe (Belgium)",
  "Erpent (Belgium)",
  "Erps-Kwerps (Belgium)",
  "Erquelinnes (Belgium)",
  "Ertvelde (Belgium)",
  "Esneux (Belgium)",
  "Essen (Belgium)",
  "Essene (Belgium)",
  "Estaimpuis (Belgium)",
  "Estinnes-au-Val (Belgium)",
  "Etalle (Belgium)",
  "Etikhove (Belgium)",
  "Etterbeek (Belgium)",
  "Eupen (Belgium)",
  "Everbeek (Belgium)",
  "Everberg (Belgium)",
  "Evere (Belgium)",
  "Evergem (Belgium)",
  "Faimes (Belgium)",
  "Falisolle (Belgium)",
  "Fallais (Belgium)",
  "Farciennes (Belgium)",
  "Fayt-lez-Manage (Belgium)",
  "Feluy (Belgium)",
  "Ferrieres (Belgium)",
  "Fexhe-le-Haut-Clocher (Belgium)",
  "Fexhe-Slins (Belgium)",
  "Filot (Belgium)",
  "Flawinne (Belgium)",
  "Flemalle-Grande (Belgium)",
  "Flemalle-Haute (Belgium)",
  "Flenu (Belgium)",
  "Fleron (Belgium)",
  "Fleurus (Belgium)",
  "Flobecq (Belgium)",
  "Floreffe (Belgium)",
  "Florennes (Belgium)",
  "Florenville (Belgium)",
  "Fontaine (Belgium)",
  "Fontaine-Valmont (Belgium)",
  "Forchies-la-Marche (Belgium)",
  "Forest (Belgium)",
  "Forrieres (Belgium)",
  "Fosse des Loups (Belgium)",
  "Frameries (Belgium)",
  "Franiere (Belgium)",
  "Frasnes-lez-Buissenal (Belgium)",
  "Freylange (Belgium)",
  "Gages (Belgium)",
  "Galmaarden (Belgium)",
  "Ganshoren (Belgium)",
  "Gavere (Belgium)",
  "Geel (Belgium)",
  "Geer (Belgium)",
  "Geetbets (Belgium)",
  "Gellik (Belgium)",
  "Gelrode (Belgium)",
  "Geluwe (Belgium)",
  "Gembloux (Belgium)",
  "Genk (Belgium)",
  "Gentbrugge (Belgium)",
  "Gentinnes (Belgium)",
  "Genval (Belgium)",
  "Geraardsbergen (Belgium)",
  "Gerpinnes (Belgium)",
  "Gesves (Belgium)",
  "Ghent (Belgium)",
  "Ghislenghien (Belgium)",
  "Ghlin (Belgium)",
  "Gierle (Belgium)",
  "Gijzegem (Belgium)",
  "Gilly (Belgium)",
  "Gingelom (Belgium)",
  "Gistel (Belgium)",
  "Gits (Belgium)",
  "Glabbeek (Belgium)",
  "Glimes (Belgium)",
  "Gobard (Belgium)",
  "Godarville (Belgium)",
  "Goe (Belgium)",
  "Gooik (Belgium)",
  "Goor (Belgium)",
  "Gosselies (Belgium)",
  "Gourdinne (Belgium)",
  "Goutroux (Belgium)",
  "Gouy-lez-Pieton (Belgium)",
  "Grand (Belgium)",
  "Grand-Leez (Belgium)",
  "Grand-Rechain (Belgium)",
  "Grand-Reng (Belgium)",
  "Grembergen (Belgium)",
  "Grez-Doiceau (Belgium)",
  "Grimbergen (Belgium)",
  "Grimminge (Belgium)",
  "Grivegnee (Belgium)",
  "Grobbendonk (Belgium)",
  "Groot-Bijgaarden (Belgium)",
  "Gruitrode (Belgium)",
  "Gullegem (Belgium)",
  "Haacht (Belgium)",
  "Haaltert (Belgium)",
  "Haasdonk (Belgium)",
  "Haasrode (Belgium)",
  "Haccourt (Belgium)",
  "Haine-Saint-Paul (Belgium)",
  "Halen (Belgium)",
  "Hallaar (Belgium)",
  "Halle (Belgium)",
  "Ham (Belgium)",
  "Ham-sur-Heure (Belgium)",
  "Ham-sur-Sambre (Belgium)",
  "Hamme (Belgium)",
  "Hamme-Mille (Belgium)",
  "Hamoir (Belgium)",
  "Hamont (Belgium)",
  "Hampteau (Belgium)",
  "Haneffe (Belgium)",
  "Hannut (Belgium)",
  "Hansbeke (Belgium)",
  "Harelbeke (Belgium)",
  "Haren (Belgium)",
  "Hasselt (Belgium)",
  "Haut-Ittre (Belgium)",
  "Hautrage (Belgium)",
  "Havay (Belgium)",
  "Havre (Belgium)",
  "Hechtel (Belgium)",
  "Heers (Belgium)",
  "Heestert (Belgium)",
  "Heffen (Belgium)",
  "Heindonk (Belgium)",
  "Heist (Belgium)",
  "Heist-op-den-Berg (Belgium)",
  "Hekelgem (Belgium)",
  "Helchteren (Belgium)",
  "Hemiksem (Belgium)",
  "Henri-Chapelle (Belgium)",
  "Hensies (Belgium)",
  "Heppen (Belgium)",
  "Herdersem (Belgium)",
  "Herent (Belgium)",
  "Herentals (Belgium)",
  "Herenthout (Belgium)",
  "Herinnes (Belgium)",
  "Herk-de-Stad (Belgium)",
  "Herlaimont (Belgium)",
  "Hermalle-sous-Argenteau (Belgium)",
  "Hermee (Belgium)",
  "Herne (Belgium)",
  "Herseaux (Belgium)",
  "Herselt (Belgium)",
  "Herstal (Belgium)",
  "Herve (Belgium)",
  "Herzele (Belgium)",
  "Heule (Belgium)",
  "Heure-le-Romain (Belgium)",
  "Heusden (Belgium)",
  "Heusy (Belgium)",
  "Hever (Belgium)",
  "Heverlee (Belgium)",
  "Hingene (Belgium)",
  "Hoboken (Belgium)",
  "Hodeige (Belgium)",
  "Hoegaarden (Belgium)",
  "Hoeilaart (Belgium)",
  "Hoeleden (Belgium)",
  "Hoeselt (Belgium)",
  "Hoevenen (Belgium)",
  "Hofstade (Belgium)",
  "Hognoul (Belgium)",
  "Hollain (Belgium)",
  "Holland (Belgium)",
  "Hollogne (Belgium)",
  "Holsbeek (Belgium)",
  "Hombeek (Belgium)",
  "Hooglede (Belgium)",
  "Hoogstraten (Belgium)",
  "Horion-Hozemont (Belgium)",
  "Hornu (Belgium)",
  "Hotton (Belgium)",
  "Houdeng-Goegnies (Belgium)",
  "Houffalize (Belgium)",
  "Housse (Belgium)",
  "Houtain-le-Val (Belgium)",
  "Houtaing (Belgium)",
  "Houthalen (Belgium)",
  "Houthem (Belgium)",
  "Houthulst (Belgium)",
  "Houtvenne (Belgium)",
  "Hove (Belgium)",
  "Huizingen (Belgium)",
  "Huldenberg (Belgium)",
  "Hulshout (Belgium)",
  "Humbeek (Belgium)",
  "Huy (Belgium)",
  "Hyon (Belgium)",
  "Ichtegem (Belgium)",
  "Iddergem (Belgium)",
  "Idegem (Belgium)",
  "Impe (Belgium)",
  "Incourt (Belgium)",
  "Ingelmunster (Belgium)",
  "Ingooigem (Belgium)",
  "Isnes (Belgium)",
  "Itegem (Belgium)",
  "Itterbeek (Belgium)",
  "Ittre (Belgium)",
  "Ixelles-Elsene (Belgium)",
  "Izegem (Belgium)",
  "Izel (Belgium)",
  "Jabbeke (Belgium)",
  "Jambes (Belgium)",
  "Jamioulx (Belgium)",
  "Jauche (Belgium)",
  "Jemappes (Belgium)",
  "Jemelle (Belgium)",
  "Jemeppe-sur-Meuse (Belgium)",
  "Jemeppe-sur-Sambre (Belgium)",
  "Jette (Belgium)",
  "Jodoigne (Belgium)",
  "Jumet (Belgium)",
  "Jupille-sur-Meuse (Belgium)",
  "Juprelle (Belgium)",
  "Jurbise (Belgium)",
  "Kachtem (Belgium)",
  "Kain (Belgium)",
  "Kalken (Belgium)",
  "Kalmthout (Belgium)",
  "Kampenhout (Belgium)",
  "Kanegem (Belgium)",
  "Kapelle-op-den-Bos (Belgium)",
  "Kapellen (Belgium)",
  "Kaprijke (Belgium)",
  "Kasterlee (Belgium)",
  "Kaulille (Belgium)",
  "Keerbergen (Belgium)",
  "Kemzeke (Belgium)",
  "Kerkhove (Belgium)",
  "Kerksken (Belgium)",
  "Kermt (Belgium)",
  "Kersbeek-Miskom (Belgium)",
  "Kessel (Belgium)",
  "Kessel-Lo (Belgium)",
  "Kettenis (Belgium)",
  "Kinrooi (Belgium)",
  "Klein-Gelmen (Belgium)",
  "Klemskerke (Belgium)",
  "Knesselare (Belgium)",
  "Knokke (Belgium)",
  "Knokke-Heist (Belgium)",
  "Koekelare (Belgium)",
  "Koersel (Belgium)",
  "Koksijde (Belgium)",
  "Koningshooikt (Belgium)",
  "Kontich (Belgium)",
  "Korbeek-Dijle (Belgium)",
  "Korbeek-Lo (Belgium)",
  "Kortemark (Belgium)",
  "Kortenaken (Belgium)",
  "Kortenberg (Belgium)",
  "Kortessem (Belgium)",
  "Kortrijk (Belgium)",
  "Kortrijk-Dutsel (Belgium)",
  "Kozen (Belgium)",
  "Kraainem (Belgium)",
  "Kruibeke (Belgium)",
  "Kruishoutem (Belgium)",
  "Kuringen (Belgium)",
  "Kuurne (Belgium)",
  "Kwaadmechelen (Belgium)",
  "La Calamine (Belgium)",
  "La Hestre (Belgium)",
  "La Hulpe (Belgium)",
  "Laarne (Belgium)",
  "Labuissiere (Belgium)",
  "Laken (Belgium)",
  "Lambermont (Belgium)",
  "Lanaken (Belgium)",
  "Landegem (Belgium)",
  "Landelies (Belgium)",
  "Landen (Belgium)",
  "Langdorp (Belgium)",
  "Langemark (Belgium)",
  "Lanklaar (Belgium)",
  "Latinne (Belgium)",
  "Lauwe (Belgium)",
  "Le Tilleul (Belgium)",
  "Lebbeke (Belgium)",
  "Lede (Belgium)",
  "Ledeberg (Belgium)",
  "Ledegem (Belgium)",
  "Leefdaal (Belgium)",
  "Leerbeek (Belgium)",
  "Leernes (Belgium)",
  "Leest (Belgium)",
  "Leffinge (Belgium)",
  "Leisele (Belgium)",
  "Lembeek (Belgium)",
  "Lendelede (Belgium)",
  "Lennik (Belgium)",
  "Leopoldsburg (Belgium)",
  "Lessines (Belgium)",
  "Letterhoutem (Belgium)",
  "Leuven (Belgium)",
  "Leuze (Belgium)",
  "Leval-Trahegnies (Belgium)",
  "Libramont (Belgium)",
  "Lichtaart (Belgium)",
  "Lichtervelde (Belgium)",
  "Liedekerke (Belgium)",
  "Lier (Belgium)",
  "Ligny (Belgium)",
  "Lille (Belgium)",
  "Lillois-Witterzee (Belgium)",
  "Limal (Belgium)",
  "Limbourg (Belgium)",
  "Limelette (Belgium)",
  "Lincent (Belgium)",
  "Linden (Belgium)",
  "Linkebeek (Belgium)",
  "Lint (Belgium)",
  "Lippelo (Belgium)",
  "Lisogne (Belgium)",
  "Lissewege (Belgium)",
  "Lives-sur-Meuse (Belgium)",
  "Lixhe (Belgium)",
  "Lobbes (Belgium)",
  "Lochristi (Belgium)",
  "Lodelinsart (Belgium)",
  "Loenhout (Belgium)",
  "Loker (Belgium)",
  "Lokeren (Belgium)",
  "Lommel (Belgium)",
  "Loncin (Belgium)",
  "Londerzeel (Belgium)",
  "Lontzen (Belgium)",
  "Loppem (Belgium)",
  "Lot (Belgium)",
  "Lotenhulle (Belgium)",
  "Loupoigne (Belgium)",
  "Louvain-la-Neuve (Belgium)",
  "Louveigne (Belgium)",
  "Lovendegem (Belgium)",
  "Loverval (Belgium)",
  "Lubbeek (Belgium)",
  "Lummen (Belgium)",
  "Luttre (Belgium)",
  "Maaseik (Belgium)",
  "Maasmechelen (Belgium)",
  "Machelen (Belgium)",
  "Magnee (Belgium)",
  "Maisieres (Belgium)",
  "Maldegem (Belgium)",
  "Malderen (Belgium)",
  "Malmedy (Belgium)",
  "Malonne (Belgium)",
  "Manage (Belgium)",
  "Manhay (Belgium)",
  "Marais (Belgium)",
  "Marche-en-Famenne (Belgium)",
  "Marche-lez-Ecaussinnes (Belgium)",
  "Marchienne-au-Pont (Belgium)",
  "Marchin (Belgium)",
  "Marcinelle (Belgium)",
  "Mariakerke (Belgium)",
  "Mariekerke (Belgium)",
  "Mariembourg (Belgium)",
  "Marke (Belgium)",
  "Markegem (Belgium)",
  "Massemen (Belgium)",
  "Maurage (Belgium)",
  "Mechelen (Belgium)",
  "Meensel-Kiezegem (Belgium)",
  "Meerbeke (Belgium)",
  "Meerhout (Belgium)",
  "Meerlaar (Belgium)",
  "Meerle (Belgium)",
  "Meersel (Belgium)",
  "Meeuwen (Belgium)",
  "Meigem (Belgium)",
  "Meise (Belgium)",
  "Meldert (Belgium)",
  "Melen (Belgium)",
  "Melkwezer (Belgium)",
  "Melle (Belgium)",
  "Melsbroek (Belgium)",
  "Melsele (Belgium)",
  "Membach (Belgium)",
  "Menen (Belgium)",
  "Merchtem (Belgium)",
  "Mere (Belgium)",
  "Merelbeke (Belgium)",
  "Merendree (Belgium)",
  "Merksem (Belgium)",
  "Merksplas (Belgium)",
  "Mespelare (Belgium)",
  "Messancy (Belgium)",
  "Mettet (Belgium)",
  "Meulebeke (Belgium)",
  "Middelburg (Belgium)",
  "Middelkerke (Belgium)",
  "Minderhout (Belgium)",
  "Modave (Belgium)",
  "Moen (Belgium)",
  "Moerbeke (Belgium)",
  "Moerzeke (Belgium)",
  "Moha (Belgium)",
  "Mol (Belgium)",
  "Molenbeek (Belgium)",
  "Molenbeek-Saint-Jean (Belgium)",
  "Molenstede (Belgium)",
  "Momalle (Belgium)",
  "Monceau-sur-Sambre (Belgium)",
  "Mons (Belgium)",
  "Mont-Saint-Guibert (Belgium)",
  "Mont-Sainte-Aldegonde (Belgium)",
  "Mont-sur-Marchienne (Belgium)",
  "Montegnee (Belgium)",
  "Montignies-lez-Lens (Belgium)",
  "Montignies-sur-Sambre (Belgium)",
  "Montzen (Belgium)",
  "Moorsel (Belgium)",
  "Moorslede (Belgium)",
  "Morialme (Belgium)",
  "Morkhoven (Belgium)",
  "Morlanwelz-Mariemont (Belgium)",
  "Mortsel (Belgium)",
  "Mouscron (Belgium)",
  "Muizen (Belgium)",
  "Munsterbilzen (Belgium)",
  "Naast (Belgium)",
  "Nalinnes (Belgium)",
  "Nameche (Belgium)",
  "Namur (Belgium)",
  "Nandrin (Belgium)",
  "Natoye (Belgium)",
  "Nazareth (Belgium)",
  "Neder-Over-Heembeek (Belgium)",
  "Nederbrakel (Belgium)",
  "Nederhasselt (Belgium)",
  "Neerharen (Belgium)",
  "Neerijse (Belgium)",
  "Neerlinter (Belgium)",
  "Neerpelt (Belgium)",
  "Nethen (Belgium)",
  "Neuville-en-Condroz (Belgium)",
  "Nevele (Belgium)",
  "Niel (Belgium)",
  "Nieuwenhove (Belgium)",
  "Nieuwerkerken (Belgium)",
  "Nieuwpoort (Belgium)",
  "Nieuwrode (Belgium)",
  "Nijlen (Belgium)",
  "Nil Saint-Vincent (Belgium)",
  "Nimy (Belgium)",
  "Ninove (Belgium)",
  "Nivelles (Belgium)",
  "Nodebais (Belgium)",
  "Nokere (Belgium)",
  "Nossegem (Belgium)",
  "Nouvelles (Belgium)",
  "Nukerke (Belgium)",
  "Obaix (Belgium)",
  "Obourg (Belgium)",
  "Oedelem (Belgium)",
  "Oelegem (Belgium)",
  "Oeselgem (Belgium)",
  "Ohain (Belgium)",
  "Ohey (Belgium)",
  "Okegem (Belgium)",
  "Olen (Belgium)",
  "Olne (Belgium)",
  "Onze-Lieve-Vrouw-Waver (Belgium)",
  "Ooigem (Belgium)",
  "Oordegem (Belgium)",
  "Oostakker (Belgium)",
  "Oosteeklo (Belgium)",
  "Oostende (Belgium)",
  "Oosterzele (Belgium)",
  "Oostham (Belgium)",
  "Oostkamp (Belgium)",
  "Oostmalle (Belgium)",
  "Oostrozebeke (Belgium)",
  "Oostvleteren (Belgium)",
  "Opbrakel (Belgium)",
  "Opglabbeek (Belgium)",
  "Opgrimbie (Belgium)",
  "Ophain (Belgium)",
  "Opitter (Belgium)",
  "Opoeteren (Belgium)",
  "Oppem (Belgium)",
  "Oppuurs (Belgium)",
  "Opwijk (Belgium)",
  "Orbais (Belgium)",
  "Orp-le-Grand (Belgium)",
  "Ostend (Belgium)",
  "Otegem (Belgium)",
  "Othee (Belgium)",
  "Ottignies (Belgium)",
  "Oud-Heverlee (Belgium)",
  "Oud-Turnhout (Belgium)",
  "Oudegem (Belgium)",
  "Oudenaarde (Belgium)",
  "Oudenburg (Belgium)",
  "Ougree (Belgium)",
  "Oupeye (Belgium)",
  "Outer (Belgium)",
  "Outrijve (Belgium)",
  "Overheide (Belgium)",
  "Overijse (Belgium)",
  "Overmere (Belgium)",
  "Overpelt (Belgium)",
  "Paal (Belgium)",
  "Pamel (Belgium)",
  "Parike (Belgium)",
  "Park (Belgium)",
  "Passendale (Belgium)",
  "Peer (Belgium)",
  "Pellenberg (Belgium)",
  "Pepinster (Belgium)",
  "Peronnes (Belgium)",
  "Pervijze (Belgium)",
  "Perwez (Belgium)",
  "Petegem (Belgium)",
  "Peutie (Belgium)",
  "Pipaix (Belgium)",
  "Pittem (Belgium)",
  "Plainevaux (Belgium)",
  "Plancenoit (Belgium)",
  "Ploegsteert (Belgium)",
  "Poederlee (Belgium)",
  "Poelkapelle (Belgium)",
  "Pollare (Belgium)",
  "Pont-a-Celles (Belgium)",
  "Pont-de-Loup (Belgium)",
  "Poperinge (Belgium)",
  "Poppel (Belgium)",
  "Post (Belgium)",
  "Presles (Belgium)",
  "Profondeville (Belgium)",
  "Pulderbos (Belgium)",
  "Pulle (Belgium)",
  "Putte (Belgium)",
  "Puurs (Belgium)",
  "Quaregnon (Belgium)",
  "Quenast (Belgium)",
  "Quevaucamps (Belgium)",
  "Quievrain (Belgium)",
  "Ramet (Belgium)",
  "Ramsdonk (Belgium)",
  "Ramsel (Belgium)",
  "Ramskapelle (Belgium)",
  "Rance (Belgium)",
  "Ransart (Belgium)",
  "Ranst (Belgium)",
  "Ravels (Belgium)",
  "Rebaix (Belgium)",
  "Recht (Belgium)",
  "Reet (Belgium)",
  "Rekem (Belgium)",
  "Rekkem (Belgium)",
  "Relegem (Belgium)",
  "Remersdaal (Belgium)",
  "Remicourt (Belgium)",
  "Reninge (Belgium)",
  "Renlies (Belgium)",
  "Ressaix (Belgium)",
  "Ressegem (Belgium)",
  "Retie (Belgium)",
  "Retinne (Belgium)",
  "Rhisnes (Belgium)",
  "Richelle (Belgium)",
  "Riemst (Belgium)",
  "Rijkevorsel (Belgium)",
  "Rijmenam (Belgium)",
  "Rillaar (Belgium)",
  "Rixensart (Belgium)",
  "Rochefort (Belgium)",
  "Rocourt (Belgium)",
  "Rode (Belgium)",
  "Roeselare (Belgium)",
  "Roeulx (Belgium)",
  "Romsee (Belgium)",
  "Ronse (Belgium)",
  "Rosieres (Belgium)",
  "Rosmeer (Belgium)",
  "Rotselaar (Belgium)",
  "Roux (Belgium)",
  "Ruddervoorde (Belgium)",
  "Ruien (Belgium)",
  "Ruisbroek (Belgium)",
  "Ruiselede (Belgium)",
  "Rumbeke (Belgium)",
  "Rummen (Belgium)",
  "Rumst (Belgium)",
  "Sablon (Belgium)",
  "Saint-Georges-sur-Meuse (Belgium)",
  "Saint-Ghislain (Belgium)",
  "Saint-Gilles (Belgium)",
  "Saint-Jean-Geest (Belgium)",
  "Saint-Josse-ten-Noode (Belgium)",
  "Saint-Leger (Belgium)",
  "Saint-Mard (Belgium)",
  "Saint-Nicolas (Belgium)",
  "Saint-Remy (Belgium)",
  "Saint-Sauveur (Belgium)",
  "Saint-Servais (Belgium)",
  "Saint-Symphorien (Belgium)",
  "Sainte-Ode (Belgium)",
  "Saintes (Belgium)",
  "Saive (Belgium)",
  "Sankt Vith (Belgium)",
  "Sart Melin (Belgium)",
  "Sart-Dames-Avelines (Belgium)",
  "Sart-en-Fagne (Belgium)",
  "Sart-lez-Spa (Belgium)",
  "Schaarbeek (Belgium)",
  "Schaffen (Belgium)",
  "Schalkhoven (Belgium)",
  "Scheldewindeke (Belgium)",
  "Schelle (Belgium)",
  "Schellebelle (Belgium)",
  "Schendelbeke (Belgium)",
  "Schepdaal (Belgium)",
  "Scherpenheuvel (Belgium)",
  "Schilde (Belgium)",
  "Schoonaarde (Belgium)",
  "Schorisse (Belgium)",
  "Schoten (Belgium)",
  "Schriek (Belgium)",
  "Schulen (Belgium)",
  "Sclayn (Belgium)",
  "Seilles (Belgium)",
  "Seneffe (Belgium)",
  "Seraing (Belgium)",
  "Serskamp (Belgium)",
  "Sijsele (Belgium)",
  "Sinaai (Belgium)",
  "Sint-Agatha-Berchem (Belgium)",
  "Sint-Agatha-Rode (Belgium)",
  "Sint-Amands (Belgium)",
  "Sint-Amandsberg (Belgium)",
  "Sint-Andries (Belgium)",
  "Sint-Baafs-Vijve (Belgium)",
  "Sint-Denijs-Westrem (Belgium)",
  "Sint-Genesius-Rode (Belgium)",
  "Sint-Gillis-bij-Dendermonde (Belgium)",
  "Sint-Gillis-Waas (Belgium)",
  "Sint-Katelijne-Waver (Belgium)",
  "Sint-Kornelis-Horebeke (Belgium)",
  "Sint-Lambrechts-Herk (Belgium)",
  "Sint-Laureins (Belgium)",
  "Sint-Lievens-Houtem (Belgium)",
  "Sint-Maria-Latem (Belgium)",
  "Sint-Martens-Latem (Belgium)",
  "Sint-Michiels (Belgium)",
  "Sint-Niklaas (Belgium)",
  "Sint-Pauwels (Belgium)",
  "Sint-Pieters op de Dijk (Belgium)",
  "Sint-Pieters-Leeuw (Belgium)",
  "Sint-Stevens-Woluwe (Belgium)",
  "Sint-Truiden (Belgium)",
  "Sirault (Belgium)",
  "Sleidinge (Belgium)",
  "Soignies (Belgium)",
  "Sombreffe (Belgium)",
  "Soumagne (Belgium)",
  "Souvret (Belgium)",
  "Spa (Belgium)",
  "Sprimont (Belgium)",
  "Stabroek (Belgium)",
  "Staden (Belgium)",
  "Stavelot (Belgium)",
  "Steendorp (Belgium)",
  "Steenokkerzeel (Belgium)",
  "Stekene (Belgium)",
  "Stembert (Belgium)",
  "Stene (Belgium)",
  "Ster (Belgium)",
  "Sterrebeek (Belgium)",
  "Stevoort (Belgium)",
  "Stokkem (Belgium)",
  "Stoumont (Belgium)",
  "Stree (Belgium)",
  "Strepy-Bracquegnies (Belgium)",
  "Strombeek-Bever (Belgium)",
  "Tamines (Belgium)",
  "Temse (Belgium)",
  "Teralfene (Belgium)",
  "Terhagen (Belgium)",
  "Ternat (Belgium)",
  "Tertre (Belgium)",
  "Tervuren (Belgium)",
  "Tessenderlo (Belgium)",
  "Testelt (Belgium)",
  "Theux (Belgium)",
  "Thimeon (Belgium)",
  "Thimister (Belgium)",
  "Thisnes (Belgium)",
  "Thuin (Belgium)",
  "Thulin (Belgium)",
  "Thy-le-Chateau (Belgium)",
  "Thynes (Belgium)",
  "Tiegem (Belgium)",
  "Tielt (Belgium)",
  "Tienen (Belgium)",
  "Tildonk (Belgium)",
  "Tilff (Belgium)",
  "Tilleur (Belgium)",
  "Tisselt (Belgium)",
  "Toernich (Belgium)",
  "Tohogne (Belgium)",
  "Tongeren (Belgium)",
  "Tongerlo (Belgium)",
  "Tongre-Notre-Dame (Belgium)",
  "Torhout (Belgium)",
  "Tournai (Belgium)",
  "Transinne (Belgium)",
  "Trazegnies (Belgium)",
  "Tremelo (Belgium)",
  "Trooz (Belgium)",
  "Tubize (Belgium)",
  "Turnhout (Belgium)",
  "Uccle (Belgium)",
  "Ulbeek (Belgium)",
  "Ursel (Belgium)",
  "Vance (Belgium)",
  "Varsenare (Belgium)",
  "Vaux (Belgium)",
  "Vaux-sous-Chevremont (Belgium)",
  "Vedrin (Belgium)",
  "Veerle (Belgium)",
  "Velaine-sur-Sambre (Belgium)",
  "Velaines (Belgium)",
  "Veldegem (Belgium)",
  "Velm (Belgium)",
  "Veltem-Beisem (Belgium)",
  "Verlaine (Belgium)",
  "Verrebroek (Belgium)",
  "Verviers (Belgium)",
  "Veurne (Belgium)",
  "Viane (Belgium)",
  "Vichte (Belgium)",
  "Vielsalm (Belgium)",
  "Viesville (Belgium)",
  "Villers-la-Ville (Belgium)",
  "Villers-le-Bouillet (Belgium)",
  "Villers-sur-Semois (Belgium)",
  "Vilvoorde (Belgium)",
  "Vinderhoute (Belgium)",
  "Virton (Belgium)",
  "Vise (Belgium)",
  "Vivegnis (Belgium)",
  "Vladslo (Belgium)",
  "Vlekkem (Belgium)",
  "Vlezenbeek (Belgium)",
  "Vliermaal (Belgium)",
  "Vliermaalroot (Belgium)",
  "Vlierzele (Belgium)",
  "Vlissegem (Belgium)",
  "Vollezele (Belgium)",
  "Voormezele (Belgium)",
  "Voroux-Goreux (Belgium)",
  "Vorselaar (Belgium)",
  "Vosselaar (Belgium)",
  "Vossem (Belgium)",
  "Vottem (Belgium)",
  "Vremde (Belgium)",
  "Waarloos (Belgium)",
  "Waarschoot (Belgium)",
  "Waasmunster (Belgium)",
  "Wachtebeke (Belgium)",
  "Wagnelee (Belgium)",
  "Wakken (Belgium)",
  "Waleffe Saint-Georges (Belgium)",
  "Walhain (Belgium)",
  "Walhorn (Belgium)",
  "Wanfercee-Baulet (Belgium)",
  "Wangenies (Belgium)",
  "Wanne (Belgium)",
  "Wanze (Belgium)",
  "Warchin (Belgium)",
  "Waregem (Belgium)",
  "Waremme (Belgium)",
  "Warneton (Belgium)",
  "Warquignies (Belgium)",
  "Warsage (Belgium)",
  "Wasmes (Belgium)",
  "Waterloo (Belgium)",
  "Watermael-Boitsfort (Belgium)",
  "Watervliet (Belgium)",
  "Waudrez (Belgium)",
  "Wavre (Belgium)",
  "Ways (Belgium)",
  "Wechelderzande (Belgium)",
  "Weelde (Belgium)",
  "Welkenraedt (Belgium)",
  "Welle (Belgium)",
  "Wellen (Belgium)",
  "Wemmel (Belgium)",
  "Wenduine (Belgium)",
  "Wepion (Belgium)",
  "Werchter (Belgium)",
  "Werken (Belgium)",
  "Wervik (Belgium)",
  "Wespelaar (Belgium)",
  "Westende (Belgium)",
  "Westerlo (Belgium)",
  "Westmalle (Belgium)",
  "Westouter (Belgium)",
  "Wetteren (Belgium)",
  "Wevelgem (Belgium)",
  "Wezemaal (Belgium)",
  "Wezembeek-Oppem (Belgium)",
  "Wichelen (Belgium)",
  "Wiekevorst (Belgium)",
  "Wielsbeke (Belgium)",
  "Wierde (Belgium)",
  "Wieze (Belgium)",
  "Wijgmaal (Belgium)",
  "Wijnegem (Belgium)",
  "Willebroek (Belgium)",
  "Wilrijk (Belgium)",
  "Wilsele (Belgium)",
  "Wingene (Belgium)",
  "Winksele (Belgium)",
  "Woesten (Belgium)",
  "Wolfsdonk (Belgium)",
  "Wolkrange (Belgium)",
  "Woluwe-Saint-Lambert (Belgium)",
  "Woluwe-Saint-Pierre - Sint-Pieters-Woluwe (Belgium)",
  "Wolvertem (Belgium)",
  "Wommelgem (Belgium)",
  "Wondelgem (Belgium)",
  "Wortegem (Belgium)",
  "Wuustwezel (Belgium)",
  "Xhendremael (Belgium)",
  "Xhoris (Belgium)",
  "Ypres (Belgium)",
  "Yvoir (Belgium)",
  "Zaffelare (Belgium)",
  "Zandbergen (Belgium)",
  "Zandhoven (Belgium)",
  "Zarren (Belgium)",
  "Zaventem (Belgium)",
  "Zedelgem (Belgium)",
  "Zeebrugge (Belgium)",
  "Zele (Belgium)",
  "Zellik (Belgium)",
  "Zelzate (Belgium)",
  "Zemst (Belgium)",
  "Zeveneken (Belgium)",
  "Zichem (Belgium)",
  "Zingem (Belgium)",
  "Zoersel (Belgium)",
  "Zolder (Belgium)",
  "Zomergem (Belgium)",
  "Zonhoven (Belgium)",
  "Zonnebeke (Belgium)",
  "Zottegem (Belgium)",
  "Zoutleeuw (Belgium)",
  "Zuienkerke (Belgium)",
  "Zulte (Belgium)",
  "Zutendaal (Belgium)",
  "Zwartenbroek (Belgium)",
  "Zwevegem (Belgium)",
  "Zwevezele (Belgium)",
  "Zwijnaarde (Belgium)",
  "Zwijndrecht (Belgium)",
  "Belize",
  "Belize City (Belize)",
  "Belmopan (Belize)",
  "Benque Viejo del Carmen (Belize)",
  "Freetown Sibun (Belize)",
  "Ladyville (Belize)",
  "San Ignacio (Belize)",
  "San Pedro Town (Belize)",
  "Bolivia",
  "Anillo (Bolivia)",
  "Aroma (Bolivia)",
  "Bermejo (Bolivia)",
  "Caracasa (Bolivia)",
  "Cobija (Bolivia)",
  "Cochabamba (Bolivia)",
  "Cotoca (Bolivia)",
  "Cruz (Bolivia)",
  "Guayaramerin (Bolivia)",
  "La Paz (Bolivia)",
  "Oruro (Bolivia)",
  "Riberalta (Bolivia)",
  "Santa Cruz (Bolivia)",
  "Sucre (Bolivia)",
  "Tarija (Bolivia)",
  "Trinidad (Bolivia)",
  "Bosnia and Herzegovina",
  "Banja (Bosnia and Herzegovina)",
  "Banja Luka (Bosnia and Herzegovina)",
  "Bijeljina (Bosnia and Herzegovina)",
  "Bosanska Dubica (Bosnia and Herzegovina)",
  "Bosanska Krupa (Bosnia and Herzegovina)",
  "Brcko (Bosnia and Herzegovina)",
  "Breza (Bosnia and Herzegovina)",
  "Bugojno (Bosnia and Herzegovina)",
  "Cazin (Bosnia and Herzegovina)",
  "Core (Bosnia and Herzegovina)",
  "Doboj (Bosnia and Herzegovina)",
  "Donja Mahala (Bosnia and Herzegovina)",
  "Gracanica (Bosnia and Herzegovina)",
  "Ilidza (Bosnia and Herzegovina)",
  "Jajce (Bosnia and Herzegovina)",
  "Jelah (Bosnia and Herzegovina)",
  "Kiseljak (Bosnia and Herzegovina)",
  "Livno (Bosnia and Herzegovina)",
  "Ljubuski (Bosnia and Herzegovina)",
  "Lopare (Bosnia and Herzegovina)",
  "Lukavac (Bosnia and Herzegovina)",
  "Modrica (Bosnia and Herzegovina)",
  "Mostar (Bosnia and Herzegovina)",
  "Novi Travnik (Bosnia and Herzegovina)",
  "Odzak (Bosnia and Herzegovina)",
  "Prijedor (Bosnia and Herzegovina)",
  "Sanski Most (Bosnia and Herzegovina)",
  "Sarajevo (Bosnia and Herzegovina)",
  "Siroki Brijeg (Bosnia and Herzegovina)",
  "Srpska (Bosnia and Herzegovina)",
  "Stup (Bosnia and Herzegovina)",
  "Tesanj (Bosnia and Herzegovina)",
  "Travnik (Bosnia and Herzegovina)",
  "Tuzla (Bosnia and Herzegovina)",
  "Visoko (Bosnia and Herzegovina)",
  "Vitez (Bosnia and Herzegovina)",
  "Vogosca (Bosnia and Herzegovina)",
  "Zenica (Bosnia and Herzegovina)",
  "Zepce (Bosnia and Herzegovina)",
  "Zivinice (Bosnia and Herzegovina)",
  "Botswana",
  "Francistown (Botswana)",
  "Gaborone (Botswana)",
  "Orapa (Botswana)",
  "Serowe (Botswana)",
  "Village (Botswana)",
  "Brazil",
  "Abadiania (Brazil)",
  "Abaetetuba (Brazil)",
  "Abelardo Luz (Brazil)",
  "Abidos (Brazil)",
  "Abrantes (Brazil)",
  "Abreu (Brazil)",
  "Abreu e Lima (Brazil)",
  "Acarau (Brazil)",
  "Acopiara (Brazil)",
  "Acorizal (Brazil)",
  "Acu (Brazil)",
  "Acucena (Brazil)",
  "Adamantina (Brazil)",
  "Adustina (Brazil)",
  "Afogados da Ingazeira (Brazil)",
  "Afonso Claudio (Brazil)",
  "Agrestina (Brazil)",
  "Agua Boa (Brazil)",
  "Agua Clara (Brazil)",
  "Aguai (Brazil)",
  "Aguanil (Brazil)",
  "Aguas Claras (Brazil)",
  "Aguas da Prata (Brazil)",
  "Aguas de Chapeco (Brazil)",
  "Aguas de Lindoia (Brazil)",
  "Aguas de Sao Pedro (Brazil)",
  "Aguas Lindas (Brazil)",
  "Agudo (Brazil)",
  "Agudos (Brazil)",
  "Agulha (Brazil)",
  "Alagoa Grande (Brazil)",
  "Alagoa Nova (Brazil)",
  "Alagoinha (Brazil)",
  "Alagoinhas (Brazil)",
  "Alambari (Brazil)",
  "Aldeia (Brazil)",
  "Alegre (Brazil)",
  "Alegres (Brazil)",
  "Alegrete (Brazil)",
  "Alem Paraiba (Brazil)",
  "Alfenas (Brazil)",
  "Alfredo Chaves (Brazil)",
  "Almeirim (Brazil)",
  "Almirante Tamandare (Brazil)",
  "Aloandia (Brazil)",
  "Alta Floresta (Brazil)",
  "Altamira (Brazil)",
  "Altaneira (Brazil)",
  "Alterosa (Brazil)",
  "Altinho (Brazil)",
  "Altinopolis (Brazil)",
  "Alto (Brazil)",
  "Alto Alegre (Brazil)",
  "Alto do Rodrigues (Brazil)",
  "Alto Paraiso de Goias (Brazil)",
  "Alto Piquiri (Brazil)",
  "Alto Santo (Brazil)",
  "Altos (Brazil)",
  "Alvares Machado (Brazil)",
  "Alvorada (Brazil)",
  "Alvorada do Sul (Brazil)",
  "Amambai (Brazil)",
  "Amaraji (Brazil)",
  "Amarante (Brazil)",
  "Amarante do Maranhao (Brazil)",
  "Amazonas (Brazil)",
  "America de Cima (Brazil)",
  "America Dourada (Brazil)",
  "Americana (Brazil)",
  "Americo Brasiliense (Brazil)",
  "Amparo (Brazil)",
  "Anadia (Brazil)",
  "Anajas (Brazil)",
  "Ananindeua (Brazil)",
  "Anchieta (Brazil)",
  "Andarai (Brazil)",
  "Andradas (Brazil)",
  "Andradina (Brazil)",
  "Andre (Brazil)",
  "Andre da Rocha (Brazil)",
  "Angatuba (Brazil)",
  "Angical do Piaui (Brazil)",
  "Angicos (Brazil)",
  "Angra dos Reis (Brazil)",
  "Anhembi (Brazil)",
  "Anhumas (Brazil)",
  "Antas (Brazil)",
  "Antonina (Brazil)",
  "Antonio Carlos (Brazil)",
  "Antonio Prado (Brazil)",
  "Aparecida (Brazil)",
  "Aparecida do Rio Negro (Brazil)",
  "Aperibe (Brazil)",
  "Apiai (Brazil)",
  "Apiuna (Brazil)",
  "Apodi (Brazil)",
  "Apora (Brazil)",
  "Apucarana (Brazil)",
  "Apuiares (Brazil)",
  "Aquidaba (Brazil)",
  "Aquidauana (Brazil)",
  "Aquiraz (Brazil)",
  "Aracai (Brazil)",
  "Aracaju (Brazil)",
  "Aracariguama (Brazil)",
  "Aracas (Brazil)",
  "Aracati (Brazil)",
  "Aracatuba (Brazil)",
  "Araci (Brazil)",
  "Aracoiaba da Serra (Brazil)",
  "Aracruz (Brazil)",
  "Aracuai (Brazil)",
  "Aragarcas (Brazil)",
  "Araguari (Brazil)",
  "Arandu (Brazil)",
  "Arapiraca (Brazil)",
  "Arapongas (Brazil)",
  "Arapoti (Brazil)",
  "Arapua (Brazil)",
  "Araquari (Brazil)",
  "Ararangua (Brazil)",
  "Araraquara (Brazil)",
  "Araras (Brazil)",
  "Ararica (Brazil)",
  "Araripina (Brazil)",
  "Araruama (Brazil)",
  "Araruna (Brazil)",
  "Araujos (Brazil)",
  "Arceburgo (Brazil)",
  "Arcos (Brazil)",
  "Arcoverde (Brazil)",
  "Areado (Brazil)",
  "Areal (Brazil)",
  "Arealva (Brazil)",
  "Areia Branca (Brazil)",
  "Areial (Brazil)",
  "Aricanduva (Brazil)",
  "Ariquemes (Brazil)",
  "Armacao de Buzios (Brazil)",
  "Armazem (Brazil)",
  "Aroeiras (Brazil)",
  "Arraial (Brazil)",
  "Arraial do Cabo (Brazil)",
  "Arroio do Meio (Brazil)",
  "Arroio do Silva (Brazil)",
  "Arroio do Tigre (Brazil)",
  "Arroio dos Ratos (Brazil)",
  "Arroio Grande (Brazil)",
  "Artur Nogueira (Brazil)",
  "Ascurra (Brazil)",
  "Assis (Brazil)",
  "Assis Chateaubriand (Brazil)",
  "Atibaia (Brazil)",
  "Auriflama (Brazil)",
  "Avanhandava (Brazil)",
  "Avare (Brazil)",
  "Azurita (Brazil)",
  "Bady Bassitt (Brazil)",
  "Baia da Traicao (Brazil)",
  "Baiao (Brazil)",
  "Bairro da Velha Central (Brazil)",
  "Baixo Guandu (Brazil)",
  "Balsa Nova (Brazil)",
  "Balsamo (Brazil)",
  "Balsas (Brazil)",
  "Bambui (Brazil)",
  "Banabuiu (Brazil)",
  "Bananal (Brazil)",
  "Bandeirantes (Brazil)",
  "Bangu (Brazil)",
  "Barao de Cocais (Brazil)",
  "Barao de Cotegipe (Brazil)",
  "Barao de Grajau (Brazil)",
  "Barauna (Brazil)",
  "Barbacena (Brazil)",
  "Barbalha (Brazil)",
  "Barbosa (Brazil)",
  "Barcarena (Brazil)",
  "Barra (Brazil)",
  "Barra Bonita (Brazil)",
  "Barra da Tijuca (Brazil)",
  "Barra de Sao Miguel (Brazil)",
  "Barra do Bugres (Brazil)",
  "Barra do Corda (Brazil)",
  "Barra do Jacare (Brazil)",
  "Barra do Ribeiro (Brazil)",
  "Barra Mansa (Brazil)",
  "Barra Velha (Brazil)",
  "Barracao (Brazil)",
  "Barras (Brazil)",
  "Barreiras (Brazil)",
  "Barretos (Brazil)",
  "Barrinha (Brazil)",
  "Barro (Brazil)",
  "Barrocas (Brazil)",
  "Barueri (Brazil)",
  "Batatais (Brazil)",
  "Batista (Brazil)",
  "Baturite (Brazil)",
  "Bauru (Brazil)",
  "Bayeux (Brazil)",
  "Bebedouro (Brazil)",
  "Beberibe (Brazil)",
  "Bela Vista (Brazil)",
  "Bela Vista de Goias (Brazil)",
  "Bela Vista do Paraiso (Brazil)",
  "Belem (Brazil)",
  "Belem do Brejo do Cruz (Brazil)",
  "Belford Roxo (Brazil)",
  "Belo Horizonte (Brazil)",
  "Belo Jardim (Brazil)",
  "Benedito Novo (Brazil)",
  "Benevides (Brazil)",
  "Bento (Brazil)",
  "Berilo (Brazil)",
  "Bernardo (Brazil)",
  "Bertioga (Brazil)",
  "Betania (Brazil)",
  "Betim (Brazil)",
  "Bezerros (Brazil)",
  "Bicas (Brazil)",
  "Biguacu (Brazil)",
  "Birigui (Brazil)",
  "Biritiba Mirim (Brazil)",
  "Biritinga (Brazil)",
  "Bituruna (Brazil)",
  "Blumenau (Brazil)",
  "Boa Esperanca (Brazil)",
  "Boa Ventura (Brazil)",
  "Boa Vista (Brazil)",
  "Boa Vista do Burica (Brazil)",
  "Bocaiuva (Brazil)",
  "Bocaiuva do Sul (Brazil)",
  "Boituva (Brazil)",
  "Bom Conselho (Brazil)",
  "Bom Despacho (Brazil)",
  "Bom Jardim (Brazil)",
  "Bom Jardim de Minas (Brazil)",
  "Bom Jesus (Brazil)",
  "Bom Jesus da Lapa (Brazil)",
  "Bom Jesus do Amparo (Brazil)",
  "Bom Jesus do Itabapoana (Brazil)",
  "Bom Jesus dos Perdoes (Brazil)",
  "Bom Principio (Brazil)",
  "Bom Retiro do Sul (Brazil)",
  "Bombinhas (Brazil)",
  "Bonfim (Brazil)",
  "Bonito (Brazil)",
  "Boqueirao (Brazil)",
  "Boquim (Brazil)",
  "Borda da Mata (Brazil)",
  "Borrazopolis (Brazil)",
  "Botafogo (Brazil)",
  "Botucatu (Brazil)",
  "Botupora (Brazil)",
  "Botuvera (Brazil)",
  "Braco do Norte (Brazil)",
  "Braganca (Brazil)",
  "Braganca Paulista (Brazil)",
  "Branco (Brazil)",
  "Bras (Brazil)",
  "Brasa (Brazil)",
  "Brasil (Brazil)",
  "Brasilandia (Brazil)",
  "Brasopolis (Brazil)",
  "Brejetuba (Brazil)",
  "Brejinho de Nazare (Brazil)",
  "Brejo da Madre de Deus (Brazil)",
  "Brejo Santo (Brazil)",
  "Breves (Brazil)",
  "Brochier (Brazil)",
  "Brodosqui (Brazil)",
  "Brotas (Brazil)",
  "Brumadinho (Brazil)",
  "Brumado (Brazil)",
  "Brusque (Brazil)",
  "Bueno Brandao (Brazil)",
  "Buique (Brazil)",
  "Buritama (Brazil)",
  "Buriti (Brazil)",
  "Buritizeiro (Brazil)",
  "Butia (Brazil)",
  "Buzios (Brazil)",
  "Caapora (Brazil)",
  "Caarapo (Brazil)",
  "Cabedelo (Brazil)",
  "Cabo de Santo Agostinho (Brazil)",
  "Cabo Frio (Brazil)",
  "Cabralia (Brazil)",
  "Cabralia Paulista (Brazil)",
  "Cabreuva (Brazil)",
  "Cabrobo (Brazil)",
  "Cacapava (Brazil)",
  "Cacapava do Sul (Brazil)",
  "Cacequi (Brazil)",
  "Caceres (Brazil)",
  "Cachoeira (Brazil)",
  "Cachoeira do Sul (Brazil)",
  "Cachoeira Grande (Brazil)",
  "Cachoeira Paulista (Brazil)",
  "Cachoeiras (Brazil)",
  "Cachoeiras de Macacu (Brazil)",
  "Cachoeirinha (Brazil)",
  "Cachoeiro de Itapemirim (Brazil)",
  "Cacimba de Dentro (Brazil)",
  "Cacique Doble (Brazil)",
  "Cacoal (Brazil)",
  "Caete (Brazil)",
  "Caetite (Brazil)",
  "Cafarnaum (Brazil)",
  "Cafelandia (Brazil)",
  "Caiana (Brazil)",
  "Caiaponia (Brazil)",
  "Caibate (Brazil)",
  "Caico (Brazil)",
  "Caieiras (Brazil)",
  "Caiua (Brazil)",
  "Cajamar (Brazil)",
  "Cajati (Brazil)",
  "Cajazeiras (Brazil)",
  "Cajobi (Brazil)",
  "Cajuru (Brazil)",
  "Calcado (Brazil)",
  "Calcoene (Brazil)",
  "Caldas (Brazil)",
  "Caldas Novas (Brazil)",
  "California (Brazil)",
  "Camacari (Brazil)",
  "Camamu (Brazil)",
  "Camanducaia (Brazil)",
  "Camaqua (Brazil)",
  "Camaragibe (Brazil)",
  "Cambe (Brazil)",
  "Camboriu (Brazil)",
  "Cambui (Brazil)",
  "Cambuquira (Brazil)",
  "Cameta (Brazil)",
  "Campestre (Brazil)",
  "Campina (Brazil)",
  "Campina Grande (Brazil)",
  "Campina Grande do Sul (Brazil)",
  "Campinas (Brazil)",
  "Campinas do Sul (Brazil)",
  "Campinorte (Brazil)",
  "Campo (Brazil)",
  "Campo Alegre (Brazil)",
  "Campo Bom (Brazil)",
  "Campo Ere (Brazil)",
  "Campo Formoso (Brazil)",
  "Campo Grande (Brazil)",
  "Campo Largo (Brazil)",
  "Campo Magro (Brazil)",
  "Campo Maior (Brazil)",
  "Campo Mourao (Brazil)",
  "Campo Novo (Brazil)",
  "Campo Verde (Brazil)",
  "Campos (Brazil)",
  "Campos do Jordao (Brazil)",
  "Campos dos Goytacazes (Brazil)",
  "Campos Gerais (Brazil)",
  "Campos Novos (Brazil)",
  "Campos Sales (Brazil)",
  "Canarana (Brazil)",
  "Canas (Brazil)",
  "Canavieiras (Brazil)",
  "Candeal (Brazil)",
  "Candeias (Brazil)",
  "Candelaria (Brazil)",
  "Candido Mota (Brazil)",
  "Candoi (Brazil)",
  "Canela (Brazil)",
  "Canelinha (Brazil)",
  "Cangucu (Brazil)",
  "Canhotinho (Brazil)",
  "Caninde (Brazil)",
  "Caninde de Sao Francisco (Brazil)",
  "Canoas (Brazil)",
  "Canoinhas (Brazil)",
  "Cansancao (Brazil)",
  "Cantagalo (Brazil)",
  "Cantanhede (Brazil)",
  "Capanema (Brazil)",
  "Capao Alto (Brazil)",
  "Capao Bonito (Brazil)",
  "Capao da Canoa (Brazil)",
  "Capao do Leao (Brazil)",
  "Caparao (Brazil)",
  "Capela (Brazil)",
  "Capela do Alto (Brazil)",
  "Capelinha (Brazil)",
  "Capetinga (Brazil)",
  "Capinopolis (Brazil)",
  "Capistrano (Brazil)",
  "Capitao Poco (Brazil)",
  "Capivari (Brazil)",
  "Caputira (Brazil)",
  "Caraa (Brazil)",
  "Caraguatatuba (Brazil)",
  "Carambei (Brazil)",
  "Carangola (Brazil)",
  "Carapebus (Brazil)",
  "Carapicuiba (Brazil)",
  "Caratinga (Brazil)",
  "Caraubas (Brazil)",
  "Carazinho (Brazil)",
  "Cardeal da Silva (Brazil)",
  "Cardoso (Brazil)",
  "Cariacica (Brazil)",
  "Carinhanha (Brazil)",
  "Caripi (Brazil)",
  "Carius (Brazil)",
  "Carlos Barbosa (Brazil)",
  "Carmo da Mata (Brazil)",
  "Carmo do Cajuru (Brazil)",
  "Carmo do Rio Claro (Brazil)",
  "Carmopolis (Brazil)",
  "Carmopolis de Minas (Brazil)",
  "Carnaiba (Brazil)",
  "Carolina (Brazil)",
  "Carpina (Brazil)",
  "Caruaru (Brazil)",
  "Casa Nova (Brazil)",
  "Casca (Brazil)",
  "Cascavel (Brazil)",
  "Casimiro de Abreu (Brazil)",
  "Cassia (Brazil)",
  "Cassilandia (Brazil)",
  "Cassino (Brazil)",
  "Castanhal (Brazil)",
  "Castelo (Brazil)",
  "Castro (Brazil)",
  "Cataguases (Brazil)",
  "Catalao (Brazil)",
  "Catanduva (Brazil)",
  "Catanduvas (Brazil)",
  "Catarina (Brazil)",
  "Catende (Brazil)",
  "Catu (Brazil)",
  "Caucaia (Brazil)",
  "Caxambu (Brazil)",
  "Caxias (Brazil)",
  "Caxias do Sul (Brazil)",
  "Ceara Mirim (Brazil)",
  "Cedral (Brazil)",
  "Cedro do Abaete (Brazil)",
  "Ceilandia (Brazil)",
  "Centenario do Sul (Brazil)",
  "Central (Brazil)",
  "Ceres (Brazil)",
  "Cerqueira Cesar (Brazil)",
  "Cerquilho (Brazil)",
  "Cerro Azul (Brazil)",
  "Cerro Branco (Brazil)",
  "Cerro Grande (Brazil)",
  "Cerro Largo (Brazil)",
  "Cesario Lange (Brazil)",
  "Ceu Azul (Brazil)",
  "Cha Grande (Brazil)",
  "Chapada (Brazil)",
  "Chapadao (Brazil)",
  "Chapadinha (Brazil)",
  "Charqueada (Brazil)",
  "Charqueadas (Brazil)",
  "Chaval (Brazil)",
  "Chavantes (Brazil)",
  "Chopinzinho (Brazil)",
  "Cianorte (Brazil)",
  "Cicero Dantas (Brazil)",
  "Cidade (Brazil)",
  "Cidade de Deus (Brazil)",
  "Cidade Nova (Brazil)",
  "Cipotanea (Brazil)",
  "Claro dos Pocoes (Brazil)",
  "Claudio (Brazil)",
  "Clementina (Brazil)",
  "Clevelandia (Brazil)",
  "Coari (Brazil)",
  "Cocal (Brazil)",
  "Cocalinho (Brazil)",
  "Cocos (Brazil)",
  "Coelho (Brazil)",
  "Colatina (Brazil)",
  "Colina (Brazil)",
  "Colinas (Brazil)",
  "Colombo (Brazil)",
  "Colorado (Brazil)",
  "Comodoro (Brazil)",
  "Conceicao (Brazil)",
  "Conceicao da Barra (Brazil)",
  "Conceicao das Alagoas (Brazil)",
  "Conceicao de Macabu (Brazil)",
  "Conceicao do Araguaia (Brazil)",
  "Conceicao do Coite (Brazil)",
  "Conceicao do Mato Dentro (Brazil)",
  "Conchal (Brazil)",
  "Conchas (Brazil)",
  "Concordia (Brazil)",
  "Condado (Brazil)",
  "Conde (Brazil)",
  "Congonhas (Brazil)",
  "Conselheiro Lafaiete (Brazil)",
  "Contagem (Brazil)",
  "Contenda (Brazil)",
  "Convento da Penha (Brazil)",
  "Cordeiro (Brazil)",
  "Cordeiropolis (Brazil)",
  "Cordilheira (Brazil)",
  "Coreau (Brazil)",
  "Coremas (Brazil)",
  "Corguinho (Brazil)",
  "Cornelio Procopio (Brazil)",
  "Coroados (Brazil)",
  "Coroas (Brazil)",
  "Coroata (Brazil)",
  "Coromandel (Brazil)",
  "Coronel (Brazil)",
  "Coronel Fabriciano (Brazil)",
  "Correia Pinto (Brazil)",
  "Correntina (Brazil)",
  "Cortes (Brazil)",
  "Corumba de Goias (Brazil)",
  "Coruripe (Brazil)",
  "Cosmopolis (Brazil)",
  "Cosmorama (Brazil)",
  "Cotia (Brazil)",
  "Crateus (Brazil)",
  "Crato (Brazil)",
  "Cravinhos (Brazil)",
  "Cristais (Brazil)",
  "Cristalina (Brazil)",
  "Cristiano Otoni (Brazil)",
  "Cruz (Brazil)",
  "Cruz Alta (Brazil)",
  "Cruz das Almas (Brazil)",
  "Cruzeiro (Brazil)",
  "Cruzeiro da Fortaleza (Brazil)",
  "Cruzeiro do Oeste (Brazil)",
  "Cruzeiro do Sul (Brazil)",
  "Cruzilia (Brazil)",
  "Cubatao (Brazil)",
  "Cuite (Brazil)",
  "Cunha Pora (Brazil)",
  "Cupira (Brazil)",
  "Curimata (Brazil)",
  "Curitiba (Brazil)",
  "Curitibanos (Brazil)",
  "Currais Novos (Brazil)",
  "Cururupu (Brazil)",
  "Curvelo (Brazil)",
  "Delfinopolis (Brazil)",
  "Descalvado (Brazil)",
  "Descanso (Brazil)",
  "Diadema (Brazil)",
  "Diamantina (Brazil)",
  "Diamantino (Brazil)",
  "Dias (Brazil)",
  "Dionisio Cerqueira (Brazil)",
  "Divinopolis (Brazil)",
  "Dix-sept Rosado (Brazil)",
  "Dobrada (Brazil)",
  "Dois Corregos (Brazil)",
  "Dois Irmaos (Brazil)",
  "Dois Vizinhos (Brazil)",
  "Dom Feliciano (Brazil)",
  "Dom Pedrito (Brazil)",
  "Dom Silverio (Brazil)",
  "Domingos Martins (Brazil)",
  "Dores do Indaia (Brazil)",
  "Dourado (Brazil)",
  "Dourados (Brazil)",
  "Doutor Camargo (Brazil)",
  "Doutor Mauricio Cardoso (Brazil)",
  "Dracena (Brazil)",
  "Duartina (Brazil)",
  "Duas Barras (Brazil)",
  "Dumont (Brazil)",
  "Duque de Caxias (Brazil)",
  "Eldorado (Brazil)",
  "Elias Fausto (Brazil)",
  "Elisiario (Brazil)",
  "Eloi Mendes (Brazil)",
  "Embu (Brazil)",
  "Embu Guacu (Brazil)",
  "Encantado (Brazil)",
  "Encruzilhada do Sul (Brazil)",
  "Engenheiro Beltrao (Brazil)",
  "Engenheiro Schmidt (Brazil)",
  "Entre Rios (Brazil)",
  "Entre Rios de Minas (Brazil)",
  "Erechim (Brazil)",
  "Erval Grande (Brazil)",
  "Erval Velho (Brazil)",
  "Ervalia (Brazil)",
  "Escada (Brazil)",
  "Esmeraldas (Brazil)",
  "Espera Feliz (Brazil)",
  "Esperanca (Brazil)",
  "Esperantina (Brazil)",
  "Espinosa (Brazil)",
  "Espirito Santo (Brazil)",
  "Espirito Santo do Pinhal (Brazil)",
  "Espumoso (Brazil)",
  "Estancia (Brazil)",
  "Estancia Nova (Brazil)",
  "Estancia Velha (Brazil)",
  "Esteio (Brazil)",
  "Estiva (Brazil)",
  "Estreito (Brazil)",
  "Estrela (Brazil)",
  "Eugenio (Brazil)",
  "Eugenopolis (Brazil)",
  "Eusebio (Brazil)",
  "Extrema (Brazil)",
  "Extremoz (Brazil)",
  "Exu (Brazil)",
  "Farroupilha (Brazil)",
  "Fartura (Brazil)",
  "Fatima (Brazil)",
  "Faxinal (Brazil)",
  "Faxinal do Soturno (Brazil)",
  "Faxinal dos Guedes (Brazil)",
  "Fazenda Rio Grande (Brazil)",
  "Feira de Santana (Brazil)",
  "Feira Nova (Brazil)",
  "Feliz (Brazil)",
  "Fenix (Brazil)",
  "Fernando Prestes (Brazil)",
  "Fernandopolis (Brazil)",
  "Ferraz de Vasconcelos (Brazil)",
  "Flexeiras (Brazil)",
  "Florania (Brazil)",
  "Flores (Brazil)",
  "Flores da Cunha (Brazil)",
  "Floresta Azul (Brazil)",
  "Floriano (Brazil)",
  "Florinia (Brazil)",
  "Formiga (Brazil)",
  "Formosa (Brazil)",
  "Forquilha (Brazil)",
  "Forquilhinha (Brazil)",
  "Fortaleza (Brazil)",
  "Fortuna (Brazil)",
  "Foz do Jordao (Brazil)",
  "Fragoso (Brazil)",
  "Franca (Brazil)",
  "Francisco Beltrao (Brazil)",
  "Francisco Morato (Brazil)",
  "Francisco Sa (Brazil)",
  "Franco da Rocha (Brazil)",
  "Frederico Westphalen (Brazil)",
  "Frei Paulo (Brazil)",
  "Freitas (Brazil)",
  "Fronteira (Brazil)",
  "Frutal (Brazil)",
  "Fundao (Brazil)",
  "Gameleira (Brazil)",
  "Gandu (Brazil)",
  "Garanhuns (Brazil)",
  "Garca (Brazil)",
  "Gardenia Azul (Brazil)",
  "Garibaldi (Brazil)",
  "Garimpo Novo (Brazil)",
  "Garopaba (Brazil)",
  "Garuva (Brazil)",
  "Gaspar (Brazil)",
  "Gaviao Peixoto (Brazil)",
  "General Carneiro (Brazil)",
  "General Salgado (Brazil)",
  "Getulio Vargas (Brazil)",
  "Girau do Ponciano (Brazil)",
  "Gloria (Brazil)",
  "Gloria do Goita (Brazil)",
  "Glorinha (Brazil)",
  "Goiana (Brazil)",
  "Goianapolis (Brazil)",
  "Goianesia (Brazil)",
  "Goianinha (Brazil)",
  "Goianira (Brazil)",
  "Goias (Brazil)",
  "Gomes (Brazil)",
  "Goncalves (Brazil)",
  "Governador Valadares (Brazil)",
  "Graca Aranha (Brazil)",
  "Grajau (Brazil)",
  "Gramado (Brazil)",
  "Granito (Brazil)",
  "Granja (Brazil)",
  "Grao Mogol (Brazil)",
  "Groairas (Brazil)",
  "Guacui (Brazil)",
  "Guai (Brazil)",
  "Guaianases (Brazil)",
  "Guaicara (Brazil)",
  "Guaira (Brazil)",
  "Guajeru (Brazil)",
  "Guamare (Brazil)",
  "Guanambi (Brazil)",
  "Guanhaes (Brazil)",
  "Guapiacu (Brazil)",
  "Guapimirim (Brazil)",
  "Guapo (Brazil)",
  "Guapore (Brazil)",
  "Guara (Brazil)",
  "Guarabira (Brazil)",
  "Guaraci (Brazil)",
  "Guaraciaba (Brazil)",
  "Guaragi (Brazil)",
  "Guaramirim (Brazil)",
  "Guaranesia (Brazil)",
  "Guarani dOeste (Brazil)",
  "Guaranta (Brazil)",
  "Guarapari (Brazil)",
  "Guarapuava (Brazil)",
  "Guararapes (Brazil)",
  "Guararema (Brazil)",
  "Guaratingueta (Brazil)",
  "Guaratuba (Brazil)",
  "Guarei (Brazil)",
  "Guariba (Brazil)",
  "Guaruja (Brazil)",
  "Guarulhos (Brazil)",
  "Guaxupe (Brazil)",
  "Gurjao (Brazil)",
  "Gurupi (Brazil)",
  "Heliodora (Brazil)",
  "Heliopolis (Brazil)",
  "Horizontina (Brazil)",
  "Iacanga (Brazil)",
  "Iacu (Brazil)",
  "Ibaiti (Brazil)",
  "Ibate (Brazil)",
  "Ibatiba (Brazil)",
  "Ibiaca (Brazil)",
  "Ibicoara (Brazil)",
  "Ibipeba (Brazil)",
  "Ibipora (Brazil)",
  "Ibirama (Brazil)",
  "Ibirarema (Brazil)",
  "Ibirataia (Brazil)",
  "Ibirite (Brazil)",
  "Ibiruba (Brazil)",
  "Ibitinga (Brazil)",
  "Ibiuna (Brazil)",
  "Icapui (Brazil)",
  "Icara (Brazil)",
  "Icarai (Brazil)",
  "Icem (Brazil)",
  "Ico (Brazil)",
  "Icoraci (Brazil)",
  "Igaracu do Tiete (Brazil)",
  "Igarapava (Brazil)",
  "Igarape (Brazil)",
  "Igarassu (Brazil)",
  "Igarata (Brazil)",
  "Igreja Nova (Brazil)",
  "Igrejinha (Brazil)",
  "Iguaba Grande (Brazil)",
  "Iguai (Brazil)",
  "Iguape (Brazil)",
  "Iguaracu (Brazil)",
  "Iguatu (Brazil)",
  "Ijui (Brazil)",
  "Ilha Solteira (Brazil)",
  "Ilhabela (Brazil)",
  "Ilhota (Brazil)",
  "Imbituba (Brazil)",
  "Imbituva (Brazil)",
  "Imperatriz (Brazil)",
  "Indaial (Brazil)",
  "Indaiatuba (Brazil)",
  "Indianopolis (Brazil)",
  "Inga (Brazil)",
  "Inhacora (Brazil)",
  "Inhangapi (Brazil)",
  "Inhauma (Brazil)",
  "Inhumas (Brazil)",
  "Inocencia (Brazil)",
  "Inubia Paulista (Brazil)",
  "Ipaba (Brazil)",
  "Ipameri (Brazil)",
  "Ipatinga (Brazil)",
  "Ipaucu (Brazil)",
  "Ipero (Brazil)",
  "Ipeuna (Brazil)",
  "Ipiau (Brazil)",
  "Ipira (Brazil)",
  "Ipiranga (Brazil)",
  "Ipojuca (Brazil)",
  "Ipora (Brazil)",
  "Ipu (Brazil)",
  "Ipua (Brazil)",
  "Ipubi (Brazil)",
  "Ipumirim (Brazil)",
  "Iracema (Brazil)",
  "Iracemapolis (Brazil)",
  "Iraceminha (Brazil)",
  "Irai (Brazil)",
  "Iranduba (Brazil)",
  "Irati (Brazil)",
  "Irece (Brazil)",
  "Iretama (Brazil)",
  "Irupi (Brazil)",
  "Itabaiana (Brazil)",
  "Itabaianinha (Brazil)",
  "Itabapoana (Brazil)",
  "Itaberaba (Brazil)",
  "Itabira (Brazil)",
  "Itabirinha de Mantena (Brazil)",
  "Itabirito (Brazil)",
  "Itaborai (Brazil)",
  "Itabuna (Brazil)",
  "Itacare (Brazil)",
  "Itaete (Brazil)",
  "Itagi (Brazil)",
  "Itagiba (Brazil)",
  "Itaguacu (Brazil)",
  "Itaguai (Brazil)",
  "Itaguatins (Brazil)",
  "Itaipava (Brazil)",
  "Itaipu (Brazil)",
  "Itaitinga (Brazil)",
  "Itaituba (Brazil)",
  "Itajobi (Brazil)",
  "Itajuba (Brazil)",
  "Itajuipe (Brazil)",
  "Italva (Brazil)",
  "Itamaraca (Brazil)",
  "Itamarati (Brazil)",
  "Itambaraca (Brazil)",
  "Itambe (Brazil)",
  "Itamogi (Brazil)",
  "Itamonte (Brazil)",
  "Itanhaem (Brazil)",
  "Itanhandu (Brazil)",
  "Itanhomi (Brazil)",
  "Itaocara (Brazil)",
  "Itapaci (Brazil)",
  "Itapagipe (Brazil)",
  "Itaparica (Brazil)",
  "Itape (Brazil)",
  "Itapecerica (Brazil)",
  "Itapecerica da Serra (Brazil)",
  "Itapecuru Mirim (Brazil)",
  "Itapejara (Brazil)",
  "Itapema (Brazil)",
  "Itaperuna (Brazil)",
  "Itapetinga (Brazil)",
  "Itapetininga (Brazil)",
  "Itapeva (Brazil)",
  "Itapevi (Brazil)",
  "Itapicuru (Brazil)",
  "Itapipoca (Brazil)",
  "Itapira (Brazil)",
  "Itapiranga (Brazil)",
  "Itapissuma (Brazil)",
  "Itapixuna (Brazil)",
  "Itapo (Brazil)",
  "Itapolis (Brazil)",
  "Itaporanga (Brazil)",
  "Itapua (Brazil)",
  "Itapuranga (Brazil)",
  "Itaquaquecetuba (Brazil)",
  "Itaqui (Brazil)",
  "Itarantim (Brazil)",
  "Itarare (Brazil)",
  "Itatiaia (Brazil)",
  "Itatiba (Brazil)",
  "Itatinga (Brazil)",
  "Itatira (Brazil)",
  "Itau de Minas (Brazil)",
  "Itauna (Brazil)",
  "Itinga da Serra (Brazil)",
  "Itirapina (Brazil)",
  "Itiuba (Brazil)",
  "Itobi (Brazil)",
  "Itororo (Brazil)",
  "Itu (Brazil)",
  "Itubera (Brazil)",
  "Ituiutaba (Brazil)",
  "Itumbiara (Brazil)",
  "Ituporanga (Brazil)",
  "Iturama (Brazil)",
  "Ituverava (Brazil)",
  "Iuna (Brazil)",
  "Ivaipora (Brazil)",
  "Ivoti (Brazil)",
  "Jaboatao dos Guararapes (Brazil)",
  "Jabora (Brazil)",
  "Jaborandi (Brazil)",
  "Jaboticabal (Brazil)",
  "Jaboticatubas (Brazil)",
  "Jacarau (Brazil)",
  "Jacarepagua (Brazil)",
  "Jacarezinho (Brazil)",
  "Jaci (Brazil)",
  "Jaciara (Brazil)",
  "Jacobina (Brazil)",
  "Jacuizinho (Brazil)",
  "Jacupiranga (Brazil)",
  "Jacutinga (Brazil)",
  "Jaguapita (Brazil)",
  "Jaguaquara (Brazil)",
  "Jaguarao (Brazil)",
  "Jaguaras (Brazil)",
  "Jaguariaiva (Brazil)",
  "Jaguaribe (Brazil)",
  "Jaguaripe (Brazil)",
  "Jaguariuna (Brazil)",
  "Jaguaruana (Brazil)",
  "Jaguaruna (Brazil)",
  "Jaiba (Brazil)",
  "Jales (Brazil)",
  "Jampruca (Brazil)",
  "Janauba (Brazil)",
  "Jandaia do Sul (Brazil)",
  "Jandira (Brazil)",
  "Japaratinga (Brazil)",
  "Japeri (Brazil)",
  "Japira (Brazil)",
  "Jardim Alegre (Brazil)",
  "Jardim de Piranhas (Brazil)",
  "Jardinopolis (Brazil)",
  "Jarinu (Brazil)",
  "Jaru (Brazil)",
  "Jatai (Brazil)",
  "Jataizinho (Brazil)",
  "Jequitinhonha (Brazil)",
  "Jerico (Brazil)",
  "Jeronimo Monteiro (Brazil)",
  "Jesuania (Brazil)",
  "Jetiba (Brazil)",
  "Ji Parana (Brazil)",
  "Jiquirica (Brazil)",
  "Joacaba (Brazil)",
  "Joanopolis (Brazil)",
  "Joao Camara (Brazil)",
  "Joao Lisboa (Brazil)",
  "Joao Monlevade (Brazil)",
  "Joao Neiva (Brazil)",
  "Joao Pinheiro (Brazil)",
  "Joaquim (Brazil)",
  "Joaquim Tavora (Brazil)",
  "Joinville (Brazil)",
  "Jose Boiteux (Brazil)",
  "Jose Bonifacio (Brazil)",
  "Jose de Freitas (Brazil)",
  "Juatuba (Brazil)",
  "Juazeiro (Brazil)",
  "Juazeiro do Norte (Brazil)",
  "Jucara (Brazil)",
  "Jucurutu (Brazil)",
  "Juiz de Fora (Brazil)",
  "Julio de Castilhos (Brazil)",
  "Julio Mesquita (Brazil)",
  "Jumirim (Brazil)",
  "Jundiai do Sul (Brazil)",
  "Junqueiro (Brazil)",
  "Junqueiropolis (Brazil)",
  "Juquia (Brazil)",
  "Juquitiba (Brazil)",
  "Juripiranga (Brazil)",
  "Juru (Brazil)",
  "Juruaia (Brazil)",
  "Juruena (Brazil)",
  "Juruti (Brazil)",
  "Jussara (Brazil)",
  "Jussari (Brazil)",
  "Ladario (Brazil)",
  "Lagarto (Brazil)",
  "Lages (Brazil)",
  "Lagoa (Brazil)",
  "Lagoa da Canoa (Brazil)",
  "Lagoa da Prata (Brazil)",
  "Lagoa do Carro (Brazil)",
  "Lagoa dos Gatos (Brazil)",
  "Lagoa Salgada (Brazil)",
  "Lagoa Santa (Brazil)",
  "Lagoa Seca (Brazil)",
  "Lagoa Vermelha (Brazil)",
  "Laguna (Brazil)",
  "Laguna Carapa (Brazil)",
  "Lajeado (Brazil)",
  "Lajinha (Brazil)",
  "Lambari (Brazil)",
  "Lapa (Brazil)",
  "Laranjal Paulista (Brazil)",
  "Laranjeiras (Brazil)",
  "Laranjeiras do Sul (Brazil)",
  "Lauro (Brazil)",
  "Lavinia (Brazil)",
  "Lavras (Brazil)",
  "Lavras da Mangabeira (Brazil)",
  "Lavras do Sul (Brazil)",
  "Lavrinhas (Brazil)",
  "Lebon Regis (Brazil)",
  "Leme (Brazil)",
  "Lencois (Brazil)",
  "Lencois Paulista (Brazil)",
  "Leopoldina (Brazil)",
  "Liberdade (Brazil)",
  "Lima Campos (Brazil)",
  "Limeira (Brazil)",
  "Limoeiro (Brazil)",
  "Limoeiro do Norte (Brazil)",
  "Linhares (Brazil)",
  "Lins (Brazil)",
  "Loanda (Brazil)",
  "Londrina (Brazil)",
  "Lontra (Brazil)",
  "Lorena (Brazil)",
  "Louveira (Brazil)",
  "Lucena (Brazil)",
  "Luis Antonio (Brazil)",
  "Luis Correia (Brazil)",
  "Lumiar (Brazil)",
  "Luminarias (Brazil)",
  "Luz (Brazil)",
  "Luzerna (Brazil)",
  "Luziania (Brazil)",
  "Macaiba (Brazil)",
  "Macaparana (Brazil)",
  "Macatuba (Brazil)",
  "Macau (Brazil)",
  "Macaubas (Brazil)",
  "Macedonia (Brazil)",
  "Machadinho (Brazil)",
  "Machado (Brazil)",
  "Machados (Brazil)",
  "Macuco (Brazil)",
  "Madre de Deus (Brazil)",
  "Mafra (Brazil)",
  "Mage (Brazil)",
  "Mairi (Brazil)",
  "Mairinque (Brazil)",
  "Malacacheta (Brazil)",
  "Mamanguape (Brazil)",
  "Mambore (Brazil)",
  "Mamonas (Brazil)",
  "Manacapuru (Brazil)",
  "Manaus (Brazil)",
  "Mandaguacu (Brazil)",
  "Mandaguari (Brazil)",
  "Mandirituba (Brazil)",
  "Manga (Brazil)",
  "Mangabeira (Brazil)",
  "Mangaratiba (Brazil)",
  "Mangueirinha (Brazil)",
  "Manhuacu (Brazil)",
  "Manhumirim (Brazil)",
  "Mantena (Brazil)",
  "Mantenopolis (Brazil)",
  "Mar de Espanha (Brazil)",
  "Maracaju (Brazil)",
  "Maracana (Brazil)",
  "Maracanau (Brazil)",
  "Maragogipe (Brazil)",
  "Maraial (Brazil)",
  "Maranguape (Brazil)",
  "Marataizes (Brazil)",
  "Marau (Brazil)",
  "Maravilha (Brazil)",
  "Marcelino Ramos (Brazil)",
  "Marcelino Vieira (Brazil)",
  "Marco (Brazil)",
  "Marcolandia (Brazil)",
  "Marechal Candido Rondon (Brazil)",
  "Marechal Deodoro (Brazil)",
  "Maria da Fe (Brazil)",
  "Marialva (Brazil)",
  "Mariana (Brazil)",
  "Marica (Brazil)",
  "Marilandia (Brazil)",
  "Mariluz (Brazil)",
  "Marituba (Brazil)",
  "Marques (Brazil)",
  "Martinopolis (Brazil)",
  "Maruim (Brazil)",
  "Marumbi (Brazil)",
  "Marzagao (Brazil)",
  "Massape (Brazil)",
  "Massaranduba (Brazil)",
  "Matao (Brazil)",
  "Matelandia (Brazil)",
  "Mateus Leme (Brazil)",
  "Matina (Brazil)",
  "Matinhos (Brazil)",
  "Matipo (Brazil)",
  "Mato Grosso (Brazil)",
  "Matozinhos (Brazil)",
  "Matutina (Brazil)",
  "Maua (Brazil)",
  "Maxaranguape (Brazil)",
  "Medeiros Neto (Brazil)",
  "Medianeira (Brazil)",
  "Meier (Brazil)",
  "Mendes (Brazil)",
  "Mendes Pimentel (Brazil)",
  "Mendonca (Brazil)",
  "Mesquita (Brazil)",
  "Messias (Brazil)",
  "Miguel Pereira (Brazil)",
  "Miguelopolis (Brazil)",
  "Milagres (Brazil)",
  "Minas (Brazil)",
  "Mineiros (Brazil)",
  "Mineiros do Tiete (Brazil)",
  "Miracatu (Brazil)",
  "Miracema (Brazil)",
  "Miracema do Tocantins (Brazil)",
  "Miraima (Brazil)",
  "Miranda (Brazil)",
  "Mirandopolis (Brazil)",
  "Mirassol (Brazil)",
  "Mirim Doce (Brazil)",
  "Mirinzal (Brazil)",
  "Missao Velha (Brazil)",
  "Mococa (Brazil)",
  "Moema (Brazil)",
  "Mogeiro (Brazil)",
  "Mogi das Cruzes (Brazil)",
  "Mogi Mirim (Brazil)",
  "Mogi-Gaucu (Brazil)",
  "Mongagua (Brazil)",
  "Monte Alegre (Brazil)",
  "Monte Alto (Brazil)",
  "Monte Aprazivel (Brazil)",
  "Monte Azul (Brazil)",
  "Monte Azul Paulista (Brazil)",
  "Monte Belo (Brazil)",
  "Monte Carmelo (Brazil)",
  "Monte Dourado (Brazil)",
  "Monte Mor (Brazil)",
  "Monte Santo (Brazil)",
  "Monte Siao (Brazil)",
  "Monteiro (Brazil)",
  "Monteiro Lobato (Brazil)",
  "Montenegro (Brazil)",
  "Montes Altos (Brazil)",
  "Montes Claros (Brazil)",
  "Montividiu (Brazil)",
  "Morada Nova (Brazil)",
  "Moreno (Brazil)",
  "Morretes (Brazil)",
  "Morrinhos (Brazil)",
  "Morro Agudo (Brazil)",
  "Morro da Fumaca (Brazil)",
  "Morro de Sao Paulo (Brazil)",
  "Morro do Chapeu (Brazil)",
  "Morro Reuter (Brazil)",
  "Morungaba (Brazil)",
  "Mossoro (Brazil)",
  "Mostardas (Brazil)",
  "Mucuri (Brazil)",
  "Muliterno (Brazil)",
  "Munhoz (Brazil)",
  "Muniz Ferreira (Brazil)",
  "Muniz Freire (Brazil)",
  "Muqui (Brazil)",
  "Muritiba (Brazil)",
  "Mutum (Brazil)",
  "Muzambinho (Brazil)",
  "Nanuque (Brazil)",
  "Nao Me Toque (Brazil)",
  "Narandiba (Brazil)",
  "Natal (Brazil)",
  "Natercia (Brazil)",
  "Navegantes (Brazil)",
  "Navirai (Brazil)",
  "Nazare (Brazil)",
  "Nazare da Mata (Brazil)",
  "Nazare Paulista (Brazil)",
  "Neopolis (Brazil)",
  "Neropolis (Brazil)",
  "Nilo Pecanha (Brazil)",
  "Nilopolis (Brazil)",
  "Niquelandia (Brazil)",
  "Niteroi (Brazil)",
  "Nobres (Brazil)",
  "Norte (Brazil)",
  "Nossa Senhora (Brazil)",
  "Nossa Senhora da Gloria (Brazil)",
  "Nossa Senhora Das Gracas (Brazil)",
  "Nossa Senhora do Socorro (Brazil)",
  "Nova (Brazil)",
  "Nova Alianca (Brazil)",
  "Nova Araca (Brazil)",
  "Nova Aurora (Brazil)",
  "Nova Bassano (Brazil)",
  "Nova Brescia (Brazil)",
  "Nova Canaa (Brazil)",
  "Nova Cruz (Brazil)",
  "Nova Esperanca (Brazil)",
  "Nova Friburgo (Brazil)",
  "Nova Independencia (Brazil)",
  "Nova Itarana (Brazil)",
  "Nova Lima (Brazil)",
  "Nova Londrina (Brazil)",
  "Nova Odessa (Brazil)",
  "Nova Palma (Brazil)",
  "Nova Petropolis (Brazil)",
  "Nova Ponte (Brazil)",
  "Nova Prata (Brazil)",
  "Nova Resende (Brazil)",
  "Nova Serrana (Brazil)",
  "Nova Timboteua (Brazil)",
  "Nova Trento (Brazil)",
  "Nova Venecia (Brazil)",
  "Novo Hamburgo (Brazil)",
  "Novo Horizonte (Brazil)",
  "Novo Mundo (Brazil)",
  "Novo Oriente (Brazil)",
  "Novo Recreio (Brazil)",
  "Nucleo Bandeirante (Brazil)",
  "Nunes Freire (Brazil)",
  "Nuporanga (Brazil)",
  "Ocara (Brazil)",
  "Oeiras (Brazil)",
  "Olimpia (Brazil)",
  "Olinda (Brazil)",
  "Oliveira (Brazil)",
  "Oratorios (Brazil)",
  "Orlandia (Brazil)",
  "Orleans (Brazil)",
  "Orobo (Brazil)",
  "Osasco (Brazil)",
  "Osorio (Brazil)",
  "Otacilio Costa (Brazil)",
  "Ouricangas (Brazil)",
  "Ouricuri (Brazil)",
  "Ourinhos (Brazil)",
  "Ouro Branco (Brazil)",
  "Ouro Fino (Brazil)",
  "Ouro Preto (Brazil)",
  "Ouro Preto do Oeste (Brazil)",
  "Ouro Verde (Brazil)",
  "Ouvidor (Brazil)",
  "Pacaembu (Brazil)",
  "Pacatuba (Brazil)",
  "Pachecos (Brazil)",
  "Padre (Brazil)",
  "Paicandu (Brazil)",
  "Palestina (Brazil)",
  "Palhoca (Brazil)",
  "Palma (Brazil)",
  "Palmares (Brazil)",
  "Palmares do Sul (Brazil)",
  "Palmas (Brazil)",
  "Palmeira (Brazil)",
  "Palmeira das Missoes (Brazil)",
  "Palmeira dos Indios (Brazil)",
  "Palmeiras de Goias (Brazil)",
  "Palmelo (Brazil)",
  "Palmital (Brazil)",
  "Palmitinho (Brazil)",
  "Palmitos (Brazil)",
  "Palotina (Brazil)",
  "Panambi (Brazil)",
  "Pancas (Brazil)",
  "Panorama (Brazil)",
  "Pao de Acucar (Brazil)",
  "Papagaios (Brazil)",
  "Para de Minas (Brazil)",
  "Paracambi (Brazil)",
  "Paracuru (Brazil)",
  "Paragominas (Brazil)",
  "Paraguacu (Brazil)",
  "Paraguacu Paulista (Brazil)",
  "Parai (Brazil)",
  "Paraiba do Sul (Brazil)",
  "Paraibano (Brazil)",
  "Paraibuna (Brazil)",
  "Paraipaba (Brazil)",
  "Paraiso (Brazil)",
  "Paraiso do Tocantins (Brazil)",
  "Paraisopolis (Brazil)",
  "Paraitinga (Brazil)",
  "Parana (Brazil)",
  "Paranaiba (Brazil)",
  "Paranapanema (Brazil)",
  "Paranavai (Brazil)",
  "Paranoa (Brazil)",
  "Paraopeba (Brazil)",
  "Parapeuna (Brazil)",
  "Paraty (Brazil)",
  "Parelhas (Brazil)",
  "Pariquera Acu (Brazil)",
  "Parnaiba (Brazil)",
  "Parnamirim (Brazil)",
  "Parobe (Brazil)",
  "Passo Fundo (Brazil)",
  "Passos (Brazil)",
  "Pastos Bons (Brazil)",
  "Pato Branco (Brazil)",
  "Patos (Brazil)",
  "Patos de Minas (Brazil)",
  "Patrocinio (Brazil)",
  "Patrocinio Paulista (Brazil)",
  "Pau Brasil (Brazil)",
  "Pau dos Ferros (Brazil)",
  "Pauini (Brazil)",
  "Pauliceia (Brazil)",
  "Paulinia (Brazil)",
  "Paulista (Brazil)",
  "Paulo Afonso (Brazil)",
  "Paulo Jacinto (Brazil)",
  "Paulo Lopes (Brazil)",
  "Paverama (Brazil)",
  "Peabiru (Brazil)",
  "Pecem (Brazil)",
  "Pederneiras (Brazil)",
  "Pedra Bonita (Brazil)",
  "Pedra Grande (Brazil)",
  "Pedranopolis (Brazil)",
  "Pedreira (Brazil)",
  "Pedro Afonso (Brazil)",
  "Pedro Avelino (Brazil)",
  "Pedro de Toledo (Brazil)",
  "Pedro Leopoldo (Brazil)",
  "Pelotas (Brazil)",
  "Pena (Brazil)",
  "Penapolis (Brazil)",
  "Pendencias (Brazil)",
  "Penedo (Brazil)",
  "Penha (Brazil)",
  "Perdigao (Brazil)",
  "Perdoes (Brazil)",
  "Pereira Barreto (Brazil)",
  "Pereiro (Brazil)",
  "Peruibe (Brazil)",
  "Pesqueira (Brazil)",
  "Petrolina (Brazil)",
  "Piabeta (Brazil)",
  "Picada (Brazil)",
  "Picos (Brazil)",
  "Picui (Brazil)",
  "Piedade (Brazil)",
  "Piedade do Rio Grande (Brazil)",
  "Piedade dos Gerais (Brazil)",
  "Pien (Brazil)",
  "Pilao Arcado (Brazil)",
  "Pilar do Sul (Brazil)",
  "Pimenta (Brazil)",
  "Pimenta Bueno (Brazil)",
  "Pindamonhangaba (Brazil)",
  "Pindobacu (Brazil)",
  "Pindoretama (Brazil)",
  "Pinhais (Brazil)",
  "Pinhalao (Brazil)",
  "Pinhalzinho (Brazil)",
  "Pinheiral (Brazil)",
  "Pinhoes (Brazil)",
  "Piquet Carneiro (Brazil)",
  "Piracaia (Brazil)",
  "Piracanjuba (Brazil)",
  "Piracema (Brazil)",
  "Piracicaba (Brazil)",
  "Pirai (Brazil)",
  "Pirai do Sul (Brazil)",
  "Piraju (Brazil)",
  "Pirajuba (Brazil)",
  "Pirajui (Brazil)",
  "Pirangi (Brazil)",
  "Pirapora do Bom Jesus (Brazil)",
  "Pirapozinho (Brazil)",
  "Piraquara (Brazil)",
  "Pirassununga (Brazil)",
  "Piratininga (Brazil)",
  "Piritiba (Brazil)",
  "Pitanga (Brazil)",
  "Pitangueiras (Brazil)",
  "Pitangui (Brazil)",
  "Piuma (Brazil)",
  "Planalto (Brazil)",
  "Platina (Brazil)",
  "Poa (Brazil)",
  "Poco Branco (Brazil)",
  "Poco das Antas (Brazil)",
  "Poco Verde (Brazil)",
  "Pojuca (Brazil)",
  "Pombal (Brazil)",
  "Pomerode (Brazil)",
  "Pompeia (Brazil)",
  "Pompeu (Brazil)",
  "Pongai (Brazil)",
  "Ponta Grossa (Brazil)",
  "Ponta Pora (Brazil)",
  "Pontal (Brazil)",
  "Pontal do Parana (Brazil)",
  "Pontalina (Brazil)",
  "Ponte de Itabapoana (Brazil)",
  "Ponte Nova (Brazil)",
  "Ponte Serrada (Brazil)",
  "Pontes e Lacerda (Brazil)",
  "Porangatu (Brazil)",
  "Porecatu (Brazil)",
  "Portao (Brazil)",
  "Porteira do Pinhal (Brazil)",
  "Porto (Brazil)",
  "Porto Alegre (Brazil)",
  "Porto Amazonas (Brazil)",
  "Porto Belo (Brazil)",
  "Porto Esperidiao (Brazil)",
  "Porto Feliz (Brazil)",
  "Porto Ferreira (Brazil)",
  "Porto Grande (Brazil)",
  "Porto Nacional (Brazil)",
  "Porto Real (Brazil)",
  "Porto Sao Jose Velho (Brazil)",
  "Porto Seguro (Brazil)",
  "Porto Uniao (Brazil)",
  "Porto Velho (Brazil)",
  "Posse (Brazil)",
  "Posto Fiscal Rolim de Moura (Brazil)",
  "Pote (Brazil)",
  "Potirendaba (Brazil)",
  "Pouso Alegre (Brazil)",
  "Pradopolis (Brazil)",
  "Praia Grande (Brazil)",
  "Prata (Brazil)",
  "Pratania (Brazil)",
  "Pratapolis (Brazil)",
  "Presidente Bernardes (Brazil)",
  "Presidente Dutra (Brazil)",
  "Presidente Epitacio (Brazil)",
  "Presidente Getulio (Brazil)",
  "Presidente Kennedy (Brazil)",
  "Presidente Medici (Brazil)",
  "Presidente Olegario (Brazil)",
  "Presidente Prudente (Brazil)",
  "Presidente Venceslau (Brazil)",
  "Primavera (Brazil)",
  "Primeiro de Maio (Brazil)",
  "Progresso (Brazil)",
  "Promissao (Brazil)",
  "Propria (Brazil)",
  "Prudentopolis (Brazil)",
  "Putinga (Brazil)",
  "Puxinana (Brazil)",
  "Quarai (Brazil)",
  "Quata (Brazil)",
  "Quatigua (Brazil)",
  "Quatis (Brazil)",
  "Quatro Barras (Brazil)",
  "Quatro Pontes (Brazil)",
  "Quebrangulo (Brazil)",
  "Queimadas (Brazil)",
  "Queimados (Brazil)",
  "Querencia (Brazil)",
  "Querencia do Norte (Brazil)",
  "Quilombo (Brazil)",
  "Quipapa (Brazil)",
  "Quirinopolis (Brazil)",
  "Quissama (Brazil)",
  "Quitandinha (Brazil)",
  "Quixada (Brazil)",
  "Quixeramobim (Brazil)",
  "Quixere (Brazil)",
  "Rafard (Brazil)",
  "Rancharia (Brazil)",
  "Rancho Queimado (Brazil)",
  "Raposa (1) (Brazil)",
  "Raposos (Brazil)",
  "Raul Soares (Brazil)",
  "Realeza (Brazil)",
  "Reboucas (Brazil)",
  "Recanto (Brazil)",
  "Recife (Brazil)",
  "Redencao (Brazil)",
  "Reduto (Brazil)",
  "Registro (Brazil)",
  "Remigio (Brazil)",
  "Renascenca (Brazil)",
  "Repartimento (Brazil)",
  "Resende (Brazil)",
  "Resende Costa (Brazil)",
  "Restinga (Brazil)",
  "Restinga Seca (Brazil)",
  "Riachao do Jacuipe (Brazil)",
  "Riacho das Almas (Brazil)",
  "Riacho Fundo (Brazil)",
  "Rialma (Brazil)",
  "Ribas do Rio Pardo (Brazil)",
  "Ribeira do Pombal (Brazil)",
  "Ribeirao (Brazil)",
  "Ribeirao Bonito (Brazil)",
  "Ribeirao Corrente (Brazil)",
  "Ribeirao do Pinhal (Brazil)",
  "Ribeirao Pires (Brazil)",
  "Rio Acima (Brazil)",
  "Rio Azul (Brazil)",
  "Rio Bananal (Brazil)",
  "Rio Bonito (Brazil)",
  "Rio Branco (Brazil)",
  "Rio Branco do Sul (Brazil)",
  "Rio Brilhante (Brazil)",
  "Rio Casca (Brazil)",
  "Rio Claro (Brazil)",
  "Rio das Ostras (Brazil)",
  "Rio das Pedras (Brazil)",
  "Rio de Janeiro (Brazil)",
  "Rio do Pires (Brazil)",
  "Rio do Sul (Brazil)",
  "Rio dos Cedros (Brazil)",
  "Rio Formoso (Brazil)",
  "Rio Grande (Brazil)",
  "Rio Grande da Serra (Brazil)",
  "Rio Largo (Brazil)",
  "Rio Manso (Brazil)",
  "Rio Negrinho (Brazil)",
  "Rio Negro (Brazil)",
  "Rio Novo (Brazil)",
  "Rio Novo do Sul (Brazil)",
  "Rio Paranaiba (Brazil)",
  "Rio Pardo (Brazil)",
  "Rio Pardo de Minas (Brazil)",
  "Rio Piracicaba (Brazil)",
  "Rio Tinto (Brazil)",
  "Rio Verde de Mato Grosso (Brazil)",
  "Roca Sales (Brazil)",
  "Rodeio Bonito (Brazil)",
  "Rodeiro (Brazil)",
  "Rodrigues (Brazil)",
  "Rolandia (Brazil)",
  "Rolante (Brazil)",
  "Ronda Alta (Brazil)",
  "Rondon (Brazil)",
  "Rosalia (Brazil)",
  "Rosario (Brazil)",
  "Rosario do Sul (Brazil)",
  "Roseira (Brazil)",
  "Rubiataba (Brazil)",
  "Rubim (Brazil)",
  "Russas (Brazil)",
  "Sabara (Brazil)",
  "Sabaudia (Brazil)",
  "Sabinopolis (Brazil)",
  "Sacramento (Brazil)",
  "Sales (Brazil)",
  "Sales Oliveira (Brazil)",
  "Salesopolis (Brazil)",
  "Salgado (Brazil)",
  "Salgueiro (Brazil)",
  "Salinas da Margarida (Brazil)",
  "Salto (Brazil)",
  "Salto de Pirapora (Brazil)",
  "Salto Grande (Brazil)",
  "Salvador (Brazil)",
  "Samambaia (Brazil)",
  "Sampaio Correia (Brazil)",
  "Sananduva (Brazil)",
  "Sangao (Brazil)",
  "Sanharo (Brazil)",
  "Santa (Brazil)",
  "Santa Adelia (Brazil)",
  "Santa Barbara (Brazil)",
  "Santa Barbara do Sul (Brazil)",
  "Santa Branca (Brazil)",
  "Santa Cruz (Brazil)",
  "Santa Cruz Cabralia (Brazil)",
  "Santa Cruz da Baixa Verde (Brazil)",
  "Santa Cruz do Capibaribe (Brazil)",
  "Santa Cruz do Rio Pardo (Brazil)",
  "Santa Cruz do Sul (Brazil)",
  "Santa Fe (Brazil)",
  "Santa Fe do Sul (Brazil)",
  "Santa Gertrudes (Brazil)",
  "Santa Helena (Brazil)",
  "Santa Ines (Brazil)",
  "Santa Isabel (Brazil)",
  "Santa Isabel do Ivai (Brazil)",
  "Santa Isabel do Para (Brazil)",
  "Santa Leopoldina (Brazil)",
  "Santa Lucia (Brazil)",
  "Santa Luzia (Brazil)",
  "Santa Maria (Brazil)",
  "Santa Maria da Vitoria (Brazil)",
  "Santa Maria de Itabira (Brazil)",
  "Santa Rita (Brazil)",
  "Santa Rita do Passa Quatro (Brazil)",
  "Santa Rita do Sapucai (Brazil)",
  "Santa Rosa (Brazil)",
  "Santa Teresa (Brazil)",
  "Santa Teresinha (Brazil)",
  "Santa Terezinha (Brazil)",
  "Santana (Brazil)",
  "Santana da Vargem (Brazil)",
  "Santana de Parnaiba (Brazil)",
  "Santana do Cariri (Brazil)",
  "Santana do Ipanema (Brazil)",
  "Santana do Livramento (Brazil)",
  "Santana do Matos (Brazil)",
  "Santana do Paraiso (Brazil)",
  "Santarem Novo (Brazil)",
  "Santiago (Brazil)",
  "Santo (Brazil)",
  "Santo Aleixo (Brazil)",
  "Santo Amaro (Brazil)",
  "Santo Amaro da Imperatriz (Brazil)",
  "Santo Anastacio (Brazil)",
  "Santo Antonio (Brazil)",
  "Santo Antonio da Patrulha (Brazil)",
  "Santo Antonio da Platina (Brazil)",
  "Santo Antonio de Jesus (Brazil)",
  "Santo Antonio de Lisboa (Brazil)",
  "Santo Antonio de Padua (Brazil)",
  "Santo Antonio de Posse (Brazil)",
  "Santo Antonio do Amparo (Brazil)",
  "Santo Antonio do Caiua (Brazil)",
  "Santo Antonio do Grama (Brazil)",
  "Santo Antonio do Jardim (Brazil)",
  "Santo Antonio do Leverger (Brazil)",
  "Santo Antonio do Monte (Brazil)",
  "Santo Antonio do Pinhal (Brazil)",
  "Santo Antonio do Retiro (Brazil)",
  "Santo Estevao (Brazil)",
  "Santo Expedito (Brazil)",
  "Santo Inacio (Brazil)",
  "Santos (Brazil)",
  "Santos Dumont (Brazil)",
  "Sao Bento (Brazil)",
  "Sao Bento do Sapucai (Brazil)",
  "Sao Bernardo (Brazil)",
  "Sao Caetano (Brazil)",
  "Sao Caetano do Sul (Brazil)",
  "Sao Carlos (Brazil)",
  "Sao Cristovao (Brazil)",
  "Sao Domingos (Brazil)",
  "Sao Felipe (Brazil)",
  "Sao Felix (Brazil)",
  "Sao Fidelis (Brazil)",
  "Sao Francisco (Brazil)",
  "Sao Francisco de Paula (Brazil)",
  "Sao Francisco do Conde (Brazil)",
  "Sao Francisco do Sul (Brazil)",
  "Sao Gabriel (Brazil)",
  "Sao Gabriel da Cachoeira (Brazil)",
  "Sao Geraldo do Araguaia (Brazil)",
  "Sao Goncalo (Brazil)",
  "Sao Goncalo do Amarante (Brazil)",
  "Sao Goncalo do Para (Brazil)",
  "Sao Goncalo do Sapucai (Brazil)",
  "Sao Goncalo dos Campos (Brazil)",
  "Sao Gotardo (Brazil)",
  "Sao Jeronimo (Brazil)",
  "Sao Joao (Brazil)",
  "Sao Joao Batista (Brazil)",
  "Sao Joao da Barra (Brazil)",
  "Sao Joao da Boa Vista (Brazil)",
  "Sao Joao de Meriti (Brazil)",
  "Sao Joao de Pirabas (Brazil)",
  "Sao Joao do Paraiso (Brazil)",
  "Sao Joao Evangelista (Brazil)",
  "Sao Joao Nepomuceno (Brazil)",
  "Sao Joaquim (Brazil)",
  "Sao Joaquim da Barra (Brazil)",
  "Sao Joaquim de Bicas (Brazil)",
  "Sao Joaquim do Monte (Brazil)",
  "Sao Jose (Brazil)",
  "Sao Jose da Barra (Brazil)",
  "Sao Jose da Laje (Brazil)",
  "Sao Jose de Mipibu (Brazil)",
  "Sao Jose de Piranhas (Brazil)",
  "Sao Jose de Ribamar (Brazil)",
  "Sao Jose do Calcado (Brazil)",
  "Sao Jose do Cedro (Brazil)",
  "Sao Jose do Egito (Brazil)",
  "Sao Jose do Goiabal (Brazil)",
  "Sao Jose do Inhacora (Brazil)",
  "Sao Jose do Ouro (Brazil)",
  "Sao Jose do Rio Pardo (Brazil)",
  "Sao Jose do Rio Preto (Brazil)",
  "Sao Lourenco da Mata (Brazil)",
  "Sao Lourenco da Serra (Brazil)",
  "Sao Lourenco do Sul (Brazil)",
  "Sao Lourenco dOeste (Brazil)",
  "Sao Luis (Brazil)",
  "Sao Luis de Montes Belos (Brazil)",
  "Sao Luis do Paraitinga (Brazil)",
  "Sao Luiz Gonzaga (Brazil)",
  "Sao Manoel (Brazil)",
  "Sao Manuel (Brazil)",
  "Sao Marcos (Brazil)",
  "Sao Miguel (Brazil)",
  "Sao Miguel Arcanjo (Brazil)",
  "Sao Miguel do Araguaia (Brazil)",
  "Sao Miguel do Iguacu (Brazil)",
  "Sao Miguel dos Campos (Brazil)",
  "Sao Paulo (Brazil)",
  "Sao Paulo do Potengi (Brazil)",
  "Sao Pedro (Brazil)",
  "Sao Pedro da Aldeia (Brazil)",
  "Sao Pedro do Ivai (Brazil)",
  "Sao Pedro do Sul (Brazil)",
  "Sao Pedro dos Ferros (Brazil)",
  "Sao Roque (Brazil)",
  "Sao Salvador (Brazil)",
  "Sao Sebastiao (Brazil)",
  "Sao Sebastiao da Amoreira (Brazil)",
  "Sao Sebastiao da Grama (Brazil)",
  "Sao Sebastiao do Cai (Brazil)",
  "Sao Sebastiao do Maranhao (Brazil)",
  "Sao Sebastiao do Paraiso (Brazil)",
  "Sao Sepe (Brazil)",
  "Sao Simao (Brazil)",
  "Sao Tome (Brazil)",
  "Sao Vicente (Brazil)",
  "Sao Vicente de Paula (Brazil)",
  "Sape (Brazil)",
  "Sapeacu (Brazil)",
  "Sapiranga (Brazil)",
  "Sapucaia (Brazil)",
  "Sapucaia do Sul (Brazil)",
  "Saquarema (Brazil)",
  "Sarandi (Brazil)",
  "Sarapui (Brazil)",
  "Sardoa (Brazil)",
  "Sarzedo (Brazil)",
  "Satuba (Brazil)",
  "Saudades (Brazil)",
  "Schroeder (Brazil)",
  "Seabra (Brazil)",
  "Seara (Brazil)",
  "Sebastianopolis do Sul (Brazil)",
  "Seberi (Brazil)",
  "Sede Nova (Brazil)",
  "Sem Peixe (Brazil)",
  "Senador Canedo (Brazil)",
  "Senhor do Bonfim (Brazil)",
  "Serafina (Brazil)",
  "Seropedica (Brazil)",
  "Serra (Brazil)",
  "Serra Azul (Brazil)",
  "Serra Negra (Brazil)",
  "Serra Preta (Brazil)",
  "Serra Talhada (Brazil)",
  "Serrana (Brazil)",
  "Serrania (Brazil)",
  "Serrinha (Brazil)",
  "Serro (Brazil)",
  "Sertania (Brazil)",
  "Sertanopolis (Brazil)",
  "Sertao (Brazil)",
  "Sertaozinho (Brazil)",
  "Sete Lagoas (Brazil)",
  "Sidrolandia (Brazil)",
  "Silva Jardim (Brazil)",
  "Silveiras (Brazil)",
  "Simao Dias (Brazil)",
  "Simao Pereira (Brazil)",
  "Simoes (Brazil)",
  "Simplicio Mendes (Brazil)",
  "Siqueira Campos (Brazil)",
  "Sitio Figueira (Brazil)",
  "Sitio Novo (Brazil)",
  "Sobradinho (Brazil)",
  "Sobral (Brazil)",
  "Socorro (Brazil)",
  "Soledade (Brazil)",
  "Sombrio (Brazil)",
  "Sorocaba (Brazil)",
  "Sorriso (Brazil)",
  "Sousa (Brazil)",
  "Stio Ouro Fino (Brazil)",
  "Sulina (Brazil)",
  "Sumare (Brazil)",
  "Suzano (Brazil)",
  "Tabatinga (Brazil)",
  "Tabira (Brazil)",
  "Taboao da Serra (Brazil)",
  "Tabuleiro do Norte (Brazil)",
  "Taguatinga (Brazil)",
  "Taiacu (Brazil)",
  "Taiobeiras (Brazil)",
  "Taiuva (Brazil)",
  "Tamandare (Brazil)",
  "Tambau (Brazil)",
  "Tamboril (Brazil)",
  "Tamoios (Brazil)",
  "Tanabi (Brazil)",
  "Tangua (Brazil)",
  "Tanque Novo (Brazil)",
  "Tapejara (Brazil)",
  "Tapira (Brazil)",
  "Tapirai (Brazil)",
  "Taquara (Brazil)",
  "Taquaral (Brazil)",
  "Taquarana (Brazil)",
  "Taquari (Brazil)",
  "Taquaritinga (Brazil)",
  "Tarumirim (Brazil)",
  "Tatuamunha (Brazil)",
  "Taua (Brazil)",
  "Taubate (Brazil)",
  "Teixeira (Brazil)",
  "Telemaco Borba (Brazil)",
  "Tenente Ananias (Brazil)",
  "Tenorio (Brazil)",
  "Teodoro Sampaio (Brazil)",
  "Terenos (Brazil)",
  "Teresina (Brazil)",
  "Teresopolis (Brazil)",
  "Terra Boa (Brazil)",
  "Terra Nova (Brazil)",
  "Terra Rica (Brazil)",
  "Terra Roxa (Brazil)",
  "Teutonia (Brazil)",
  "Tiangua (Brazil)",
  "Tibagi (Brazil)",
  "Tibau (Brazil)",
  "Tiete (Brazil)",
  "Tijuca (Brazil)",
  "Tijucas (Brazil)",
  "Timbauba (Brazil)",
  "Timbe (Brazil)",
  "Timbo (Brazil)",
  "Timon (Brazil)",
  "Timoteo (Brazil)",
  "Tiradentes (Brazil)",
  "Tobias Barreto (Brazil)",
  "Tocantins (Brazil)",
  "Toledo (Brazil)",
  "Tomar do Geru (Brazil)",
  "Tome Acu (Brazil)",
  "Toritama (Brazil)",
  "Torres (Brazil)",
  "Touros (Brazil)",
  "Tramandai (Brazil)",
  "Tremembe (Brazil)",
  "Tres Barras (Brazil)",
  "Tres Cachoeiras (Brazil)",
  "Tres Coroas (Brazil)",
  "Tres Marias (Brazil)",
  "Tres Passos (Brazil)",
  "Tres Pontas (Brazil)",
  "Tres Rios (Brazil)",
  "Trindade (Brazil)",
  "Triunfo (Brazil)",
  "Tubarao (Brazil)",
  "Tucum (Brazil)",
  "Tucunduva (Brazil)",
  "Tucurui (Brazil)",
  "Tuiuti (Brazil)",
  "Tumiritinga (Brazil)",
  "Tupa (Brazil)",
  "Tupaciguara (Brazil)",
  "Tupanatinga (Brazil)",
  "Tupancireta (Brazil)",
  "Tuparendi (Brazil)",
  "Tupi Paulista (Brazil)",
  "Turmalina (Brazil)",
  "Turvo (Brazil)",
  "Uaua (Brazil)",
  "Uba (Brazil)",
  "Ubaira (Brazil)",
  "Ubaitaba (Brazil)",
  "Ubarana (Brazil)",
  "Ubata (Brazil)",
  "Ubatuba (Brazil)",
  "Uberaba (Brazil)",
  "Uberlandia (Brazil)",
  "Uchoa (Brazil)",
  "Uibai (Brazil)",
  "Uirauna (Brazil)",
  "Umbauba (Brazil)",
  "Umbuzeiro (Brazil)",
  "Umirim (Brazil)",
  "Umuarama (Brazil)",
  "Unai (Brazil)",
  "Uniao da Vitoria (Brazil)",
  "Urai (Brazil)",
  "Urania (Brazil)",
  "Urubici (Brazil)",
  "Uruburetama (Brazil)",
  "Urucania (Brazil)",
  "Urucuca (Brazil)",
  "Uruguaiana (Brazil)",
  "Urupes (Brazil)",
  "Urussanga (Brazil)",
  "Vacaria (Brazil)",
  "Valenca (Brazil)",
  "Valente (Brazil)",
  "Valentim Gentil (Brazil)",
  "Valerio (Brazil)",
  "Valinhos (Brazil)",
  "Valparaiso (Brazil)",
  "Vargem Alta (Brazil)",
  "Vargem Grande do Sul (Brazil)",
  "Vargem Grande Paulista (Brazil)",
  "Varginha (Brazil)",
  "Varjota (Brazil)",
  "Varzea (Brazil)",
  "Varzea Paulista (Brazil)",
  "Varzelandia (Brazil)",
  "Vassouras (Brazil)",
  "Velha (Brazil)",
  "Venancio Aires (Brazil)",
  "Venda Nova (Brazil)",
  "Vera Cruz (Brazil)",
  "Veranopolis (Brazil)",
  "Vespasiano (Brazil)",
  "Vespasiano Correa (Brazil)",
  "Viana (Brazil)",
  "Vicencia (Brazil)",
  "Vicente (Brazil)",
  "Vicosa (Brazil)",
  "Vicosa do Ceara (Brazil)",
  "Videira (Brazil)",
  "Vila Maria (Brazil)",
  "Vila Muriqui (Brazil)",
  "Vila Rica (Brazil)",
  "Vila Velha (Brazil)",
  "Vilar dos Teles (Brazil)",
  "Vilhena (Brazil)",
  "Vinhedo (Brazil)",
  "Virgem da Lapa (Brazil)",
  "Virginia (Brazil)",
  "Virginopolis (Brazil)",
  "Virmond (Brazil)",
  "Visconde do Rio Branco (Brazil)",
  "Vista Alegre do Alto (Brazil)",
  "Vitoria de Santo Antao (Brazil)",
  "Vitorino Freire (Brazil)",
  "Volta Grande (Brazil)",
  "Volta Redonda (Brazil)",
  "Votorantim (Brazil)",
  "Votuporanga (Brazil)",
  "Wenceslau Braz (Brazil)",
  "Witmarsum (Brazil)",
  "Xanxere (Brazil)",
  "Xavantina (Brazil)",
  "Xaxim (Brazil)",
  "Brunei",
  "Bandar Seri Begawan (Brunei)",
  "Bangar (Brunei)",
  "Kampong Jerudong (Brunei)",
  "Kuala Balai (Brunei)",
  "Kuala Belait (Brunei)",
  "Panaga (Brunei)",
  "Seria (Brunei)",
  "Tutong (Brunei)",
  "Bulgaria",
  "Akhtopol (Bulgaria)",
  "Aksakovo (Bulgaria)",
  "Aleksandriya (Bulgaria)",
  "Alfatar (Bulgaria)",
  "Anton (Bulgaria)",
  "Antonovo (Bulgaria)",
  "Ardino (Bulgaria)",
  "Asenovgrad (Bulgaria)",
  "Aytos (Bulgaria)",
  "Babovo (Bulgaria)",
  "Baltchik (Bulgaria)",
  "Banite (Bulgaria)",
  "Bankya (Bulgaria)",
  "Bansko (Bulgaria)",
  "Barutin (Bulgaria)",
  "Batak (Bulgaria)",
  "Belene (Bulgaria)",
  "Belogradchik (Bulgaria)",
  "Beloslav (Bulgaria)",
  "Belovo (Bulgaria)",
  "Berkovitsa (Bulgaria)",
  "Bistritsa (Bulgaria)",
  "Blagoevgrad (Bulgaria)",
  "Boboshevo (Bulgaria)",
  "Bobovdol (Bulgaria)",
  "Borovan (Bulgaria)",
  "Borovo (Bulgaria)",
  "Botevgrad (Bulgaria)",
  "Bratsigovo (Bulgaria)",
  "Bregare (Bulgaria)",
  "Bukovo (Bulgaria)",
  "Burgas (Bulgaria)",
  "Byala (Bulgaria)",
  "Byala Slatina (Bulgaria)",
  "Chakalarovo (Bulgaria)",
  "Chernolik (Bulgaria)",
  "Chernomorets (Bulgaria)",
  "Cherven (Bulgaria)",
  "Cherven Bryag (Bulgaria)",
  "Chiprovtsi (Bulgaria)",
  "Chirpan (Bulgaria)",
  "Choba (Bulgaria)",
  "Debelets (Bulgaria)",
  "Devin (Bulgaria)",
  "Dimitrovgrad (Bulgaria)",
  "Dobrich (Bulgaria)",
  "Dolna Banya (Bulgaria)",
  "Dolna Mitropoliya (Bulgaria)",
  "Dolna Oryakhovitsa (Bulgaria)",
  "Dospat (Bulgaria)",
  "Dragash Voyvoda (Bulgaria)",
  "Draginovo (Bulgaria)",
  "Dragizhevo (Bulgaria)",
  "Dryanovo (Bulgaria)",
  "Dulgopol (Bulgaria)",
  "Dulovo (Bulgaria)",
  "Dupnitsa (Bulgaria)",
  "Elena (Bulgaria)",
  "Elin Pelin (Bulgaria)",
  "Elkhovo (Bulgaria)",
  "Etropole (Bulgaria)",
  "Gabrovo (Bulgaria)",
  "Ganchovo (Bulgaria)",
  "General-Toshevo (Bulgaria)",
  "Glogovo (Bulgaria)",
  "Godech (Bulgaria)",
  "Gorna Oryakhovitsa (Bulgaria)",
  "Gotse Delchev (Bulgaria)",
  "Gulubovo (Bulgaria)",
  "Gurkovo (Bulgaria)",
  "Gurmazovo (Bulgaria)",
  "Haskovo (Bulgaria)",
  "Ikhtiman (Bulgaria)",
  "Iskrets (Bulgaria)",
  "Isperikh (Bulgaria)",
  "Isperikhovo (Bulgaria)",
  "Ivaylovgrad (Bulgaria)",
  "Kableshkovo (Bulgaria)",
  "Kamenovo (Bulgaria)",
  "Kardzhali (Bulgaria)",
  "Karlovo (Bulgaria)",
  "Karnobat (Bulgaria)",
  "Kavarna (Bulgaria)",
  "Kazanlak (Bulgaria)",
  "Kazashka Reka (Bulgaria)",
  "Kharmanli (Bulgaria)",
  "Khisarya (Bulgaria)",
  "Knezha (Bulgaria)",
  "Konush (Bulgaria)",
  "Koprivets (Bulgaria)",
  "Koprivlen (Bulgaria)",
  "Kostenets (Bulgaria)",
  "Kostievo (Bulgaria)",
  "Kostinbrod (Bulgaria)",
  "Kovachite (Bulgaria)",
  "Kozloduy (Bulgaria)",
  "Kran (Bulgaria)",
  "Krichim (Bulgaria)",
  "Krivodol (Bulgaria)",
  "Krumovgrad (Bulgaria)",
  "Krupnik (Bulgaria)",
  "Kula (Bulgaria)",
  "Kyustendil (Bulgaria)",
  "Lom (Bulgaria)",
  "Lovech (Bulgaria)",
  "Lozno (Bulgaria)",
  "Lukovit (Bulgaria)",
  "Lyaskovets (Bulgaria)",
  "Lyulyakovo (Bulgaria)",
  "Madan (Bulgaria)",
  "Malo Konare (Bulgaria)",
  "Marikostinovo (Bulgaria)",
  "Markovo (Bulgaria)",
  "Mesta (Bulgaria)",
  "Mezdra (Bulgaria)",
  "Mikrevo (Bulgaria)",
  "Moderno Predgradie (Bulgaria)",
  "Momchilgrad (Bulgaria)",
  "Montana (Bulgaria)",
  "Moravka (Bulgaria)",
  "Muglizh (Bulgaria)",
  "Nesebar (Bulgaria)",
  "Nikopol (Bulgaria)",
  "Nova Zagora (Bulgaria)",
  "Novi Iskur (Bulgaria)",
  "Novi Pazar (Bulgaria)",
  "Obzor (Bulgaria)",
  "Omurtag (Bulgaria)",
  "Orizovo (Bulgaria)",
  "Orlovets (Bulgaria)",
  "Oryakhovets (Bulgaria)",
  "Oryakhovitsa (Bulgaria)",
  "Ovoshtnik (Bulgaria)",
  "Panagyurishte (Bulgaria)",
  "Pavel Banya (Bulgaria)",
  "Pavlikeni (Bulgaria)",
  "Pazardzhik (Bulgaria)",
  "Pchelishte (Bulgaria)",
  "Pernik (Bulgaria)",
  "Peshtera (Bulgaria)",
  "Petrich (Bulgaria)",
  "Pleven (Bulgaria)",
  "Plovdiv (Bulgaria)",
  "Poleto (Bulgaria)",
  "Polski Trumbesh (Bulgaria)",
  "Pomorie (Bulgaria)",
  "Popovo (Bulgaria)",
  "Pravets (Bulgaria)",
  "Prespa (Bulgaria)",
  "Provadiya (Bulgaria)",
  "Radnevo (Bulgaria)",
  "Raduil (Bulgaria)",
  "Rakitovo (Bulgaria)",
  "Rakovski (Bulgaria)",
  "Ravda (Bulgaria)",
  "Razgrad (Bulgaria)",
  "Razlog (Bulgaria)",
  "Rila (Bulgaria)",
  "Rodopi (Bulgaria)",
  "Rousse (Bulgaria)",
  "Rozino (Bulgaria)",
  "Rudozem (Bulgaria)",
  "Ruen (Bulgaria)",
  "Samokov (Bulgaria)",
  "Samovodene (Bulgaria)",
  "Samuil (Bulgaria)",
  "Sandanski (Bulgaria)",
  "Sandrovo (Bulgaria)",
  "Septemvri (Bulgaria)",
  "Sevlievo (Bulgaria)",
  "Shabla (Bulgaria)",
  "Shivachevo (Bulgaria)",
  "Shumen (Bulgaria)",
  "Silistra (Bulgaria)",
  "Simeonovgrad (Bulgaria)",
  "Simitli (Bulgaria)",
  "Skravena (Bulgaria)",
  "Slatina (Bulgaria)",
  "Sliven (Bulgaria)",
  "Slivnitsa (Bulgaria)",
  "Smolyan (Bulgaria)",
  "Smyadovo (Bulgaria)",
  "Sofia (Bulgaria)",
  "Sofronievo (Bulgaria)",
  "Sokolets (Bulgaria)",
  "Sopot (Bulgaria)",
  "Sozopol (Bulgaria)",
  "Sredets (Bulgaria)",
  "Stamboliyski (Bulgaria)",
  "Stara Zagora (Bulgaria)",
  "Strazhitsa (Bulgaria)",
  "Strelcha (Bulgaria)",
  "Suedinenie (Bulgaria)",
  "Svilengrad (Bulgaria)",
  "Svishtov (Bulgaria)",
  "Svoge (Bulgaria)",
  "Targovishte (Bulgaria)",
  "Tervel (Bulgaria)",
  "Tochilari (Bulgaria)",
  "Topoli (Bulgaria)",
  "Topolovo (Bulgaria)",
  "Troyan (Bulgaria)",
  "Trustenik (Bulgaria)",
  "Tryavna (Bulgaria)",
  "Tutrakan (Bulgaria)",
  "Varna (Bulgaria)",
  "Veliko (Bulgaria)",
  "Veliko Tarnovo (Bulgaria)",
  "Velingrad (Bulgaria)",
  "Vetren (Bulgaria)",
  "Vidin (Bulgaria)",
  "Vievo (Bulgaria)",
  "Vlado Trichkov (Bulgaria)",
  "Vrachesh (Bulgaria)",
  "Vratsa (Bulgaria)",
  "Yakoruda (Bulgaria)",
  "Yambol (Bulgaria)",
  "Zavet (Bulgaria)",
  "Zlatitrap (Bulgaria)",
  "Zlatograd (Bulgaria)",
  "Cambodia",
  "Moung Roessei (Cambodia)",
  "Phnom Penh (Cambodia)",
  "Phumi Boeng (1) (Cambodia)",
  "Phumi Chhuk (Cambodia)",
  "Phumi Preah Haoh (Cambodia)",
  "Phumi Prei (Cambodia)",
  "Phumi Prek Mrinh (Cambodia)",
  "Phumi Siem Reab (Cambodia)",
  "Phumi Thmei (Cambodia)",
  "Phumi Thnal (Cambodia)",
  "Phumi Vott Phnum (Cambodia)",
  "Sihanoukville (Cambodia)",
  "Tuol Kok (Cambodia)",
  "Vott Kampong Svay (Cambodia)",
  "Cameroon",
  "Bafia (Cameroon)",
  "Bafoussam (Cameroon)",
  "Bamenda (Cameroon)",
  "Buea (Cameroon)",
  "Douala (Cameroon)",
  "Kribi (Cameroon)",
  "Kumba (Cameroon)",
  "Ringo (Cameroon)",
  "Tibati (Cameroon)",
  "Canada",
  "100 Mile House (Canada)",
  "Abbey (Canada)",
  "Abbotsford (Canada)",
  "Acadia Valley (Canada)",
  "Acme (Canada)",
  "Acton (Canada)",
  "Acton Vale (Canada)",
  "Agassiz (Canada)",
  "Agincourt (Canada)",
  "Ailsa Craig (Canada)",
  "Airdrie (Canada)",
  "Ajax (Canada)",
  "Aklavik (Canada)",
  "Alameda (Canada)",
  "Albanel (Canada)",
  "Alberton (Canada)",
  "Aldergrove (Canada)",
  "Alert Bay (Canada)",
  "Alexander (Canada)",
  "Alexandria (Canada)",
  "Alfred (Canada)",
  "Allan (Canada)",
  "Allardville (Canada)",
  "Allenford (Canada)",
  "Alliston (Canada)",
  "Alma (Canada)",
  "Almonte (Canada)",
  "Alton (Canada)",
  "Altona (Canada)",
  "Alvinston (Canada)",
  "Amherst (Canada)",
  "Amherstburg (Canada)",
  "Amos (Canada)",
  "Amqui (Canada)",
  "Ancaster (Canada)",
  "Andrew (Canada)",
  "Ange-Gardien (Canada)",
  "Angliers (Canada)",
  "Angus (Canada)",
  "Annapolis Royal (Canada)",
  "Antigonish (Canada)",
  "Apohaqui (Canada)",
  "Apple Hill (Canada)",
  "Apsley (Canada)",
  "Arborfield (Canada)",
  "Arborg (Canada)",
  "Arcadia (Canada)",
  "Arcola (Canada)",
  "Arctic Bay (Canada)",
  "Ardmore (Canada)",
  "Ardrossan (Canada)",
  "Arelee (Canada)",
  "Arichat (Canada)",
  "Arkona (Canada)",
  "Armagh (Canada)",
  "Armstrong (Canada)",
  "Arnprior (Canada)",
  "Arthabaska (Canada)",
  "Arthur (Canada)",
  "Arundel (Canada)",
  "Arva (Canada)",
  "Arviat (Canada)",
  "Asbestos (Canada)",
  "Ascot Corner (Canada)",
  "Ashcroft (Canada)",
  "Ashmont (Canada)",
  "Ashville (Canada)",
  "Assiniboia (Canada)",
  "Aston-Jonction (Canada)",
  "Athabasca (Canada)",
  "Athens (Canada)",
  "Atikameg (Canada)",
  "Atikokan (Canada)",
  "Atlin (Canada)",
  "Attawapiskat (Canada)",
  "Atwood (Canada)",
  "Audet (Canada)",
  "Aurora (Canada)",
  "Austin (Canada)",
  "Authier (Canada)",
  "Avonmore (Canada)",
  "Aylesford (Canada)",
  "Aylmer (Canada)",
  "Aylsham (Canada)",
  "Ayr (Canada)",
  "Ayton (Canada)",
  "Baddeck (Canada)",
  "Baden (Canada)",
  "Baie Verte (Canada)",
  "Baie-Comeau (Canada)",
  "Baie-Des-Sables (Canada)",
  "Baie-du-Febvre (Canada)",
  "Baie-Saint-Paul (Canada)",
  "Baie-Sainte-Catherine (Canada)",
  "Bala (Canada)",
  "Balcarres (Canada)",
  "Baldonnel (Canada)",
  "Baldur (Canada)",
  "Baltimore (Canada)",
  "Bamfield (Canada)",
  "Bancroft (Canada)",
  "Banff (Canada)",
  "Barkerville (Canada)",
  "Barnaby River (Canada)",
  "Barons (Canada)",
  "Barraute (Canada)",
  "Barrhead (Canada)",
  "Barrie (Canada)",
  "Barriere (Canada)",
  "Barrington (Canada)",
  "Barrington Passage (Canada)",
  "Bashaw (Canada)",
  "Bassano (Canada)",
  "Bath (Canada)",
  "Bathurst (Canada)",
  "Batiscan (Canada)",
  "Battleford (Canada)",
  "Bay Bulls (Canada)",
  "Bay de Verde (Canada)",
  "Bay Roberts (Canada)",
  "Bayfield (Canada)",
  "Beachburg (Canada)",
  "Beaconsfield (Canada)",
  "Beamsville (Canada)",
  "Bear River (Canada)",
  "Bearn (Canada)",
  "Bearskin Lake (Canada)",
  "Beauceville (Canada)",
  "Beauharnois (Canada)",
  "Beaumont (Canada)",
  "Beauport (Canada)",
  "Beaupre (Canada)",
  "Beausejour (Canada)",
  "Beauval (Canada)",
  "Beaux-Rivages--Lac-des-Ecorces--Val-Barrette (Canada)",
  "Beaver Bank (Canada)",
  "Beaver Crossing (Canada)",
  "Beaverlodge (Canada)",
  "Beaverton (Canada)",
  "Becancour (Canada)",
  "Bedford (Canada)",
  "Beeton (Canada)",
  "Begin (Canada)",
  "Beiseker (Canada)",
  "Bell Island (Canada)",
  "Bella Bella (Canada)",
  "Belle River (Canada)",
  "Belleville (Canada)",
  "Bellevue (Canada)",
  "Beloeil (Canada)",
  "Belwood (Canada)",
  "Bentley (Canada)",
  "Berry Mills (Canada)",
  "Berthier-Sur-Mer (Canada)",
  "Berthierville (Canada)",
  "Berwick (Canada)",
  "Berwyn (Canada)",
  "Bethune (Canada)",
  "Betsiamites (Canada)",
  "Bic (Canada)",
  "Biencourt (Canada)",
  "Bienfait (Canada)",
  "Big River (Canada)",
  "Big Valley (Canada)",
  "Biggar (Canada)",
  "Binscarth (Canada)",
  "Birch Hills (Canada)",
  "Birchy Bay (Canada)",
  "Birtle (Canada)",
  "Black Creek (Canada)",
  "Black Diamond (Canada)",
  "Blackfalds (Canada)",
  "Blackie (Canada)",
  "Blacks Harbour (Canada)",
  "Blackville (Canada)",
  "Blaine Lake (Canada)",
  "Blainville (Canada)",
  "Blairmore (Canada)",
  "Blandford (Canada)",
  "Blenheim (Canada)",
  "Blind River (Canada)",
  "Bloomfield (Canada)",
  "Blue River (Canada)",
  "Bluewater (Canada)",
  "Bobcaygeon (Canada)",
  "Bois-des-Filion (Canada)",
  "Boisbriand (Canada)",
  "Boischatel (Canada)",
  "Boissevain (Canada)",
  "Bolton (Canada)",
  "Bonaventure (Canada)",
  "Bonne Bay (Canada)",
  "Bonnyville (Canada)",
  "Bonsecours (Canada)",
  "Bonshaw (Canada)",
  "Borden (Canada)",
  "Borden-Carleton (Canada)",
  "Boswell (Canada)",
  "Boucherville (Canada)",
  "Bouctouche (Canada)",
  "Bourget (Canada)",
  "Bow Island (Canada)",
  "Bowden (Canada)",
  "Bowmanville (Canada)",
  "Bowser (Canada)",
  "Boyle (Canada)",
  "Bracebridge (Canada)",
  "Brackendale (Canada)",
  "Bradford (Canada)",
  "Bramalea (Canada)",
  "Brampton (Canada)",
  "Brandon (Canada)",
  "Brantford (Canada)",
  "Brechin (Canada)",
  "Bredenbury (Canada)",
  "Brentwood Bay (Canada)",
  "Breslau (Canada)",
  "Breton (Canada)",
  "Bridge Lake (Canada)",
  "Bridgetown (Canada)",
  "Bridgewater (Canada)",
  "Brigham (Canada)",
  "Brighton (Canada)",
  "Brigus (Canada)",
  "Britt (Canada)",
  "Broadview (Canada)",
  "Brochet (Canada)",
  "Brockville (Canada)",
  "Brome (Canada)",
  "Bromhead (Canada)",
  "Bromont (Canada)",
  "Brookfield (Canada)",
  "Brooklyn (Canada)",
  "Brooks (Canada)",
  "Brossard (Canada)",
  "Bruno (Canada)",
  "Bryson (Canada)",
  "Buckhorn (Canada)",
  "Buckingham (Canada)",
  "Buffalo Narrows (Canada)",
  "Burgeo (Canada)",
  "Burgessville (Canada)",
  "Burin (Canada)",
  "Burlington (Canada)",
  "Burnaby (Canada)",
  "Burns Lake (Canada)",
  "Burnside (Canada)",
  "Cabano (Canada)",
  "Cabri (Canada)",
  "Cache Creek (Canada)",
  "Calder (Canada)",
  "Caledon (Canada)",
  "Caledonia (Canada)",
  "Calgary (Canada)",
  "Calixa-Lavallee (Canada)",
  "Callander (Canada)",
  "Calmar (Canada)",
  "Cambridge (Canada)",
  "Cambridge Bay (Canada)",
  "Camlachie (Canada)",
  "Campbell River (Canada)",
  "Campbellford (Canada)",
  "Campbellton (Canada)",
  "Campbellville (Canada)",
  "Camrose (Canada)",
  "Canal Flats (Canada)",
  "Candiac (Canada)",
  "Canfield (Canada)",
  "Canim Lake (Canada)",
  "Canmore (Canada)",
  "Canning (Canada)",
  "Cannington (Canada)",
  "Canoe Narrows (Canada)",
  "Canora (Canada)",
  "Canso (Canada)",
  "Canterbury (Canada)",
  "Canwood (Canada)",
  "Canyon (Canada)",
  "Cap-aux-Meules (Canada)",
  "Cap-Chat (Canada)",
  "Cap-Saint-Ignace (Canada)",
  "Cap-Sante (Canada)",
  "Cape Dorset (Canada)",
  "Caraquet (Canada)",
  "Carberry (Canada)",
  "Carbonear (Canada)",
  "Cardigan (Canada)",
  "Cardinal (Canada)",
  "Cardston (Canada)",
  "Carignan (Canada)",
  "Carleton Place (Canada)",
  "Carleton-sur-Mer (Canada)",
  "Carlisle (Canada)",
  "Carlyle (Canada)",
  "Carmacks (Canada)",
  "Carman (Canada)",
  "Carmel (Canada)",
  "Carnduff (Canada)",
  "Caroline (Canada)",
  "Caronport (Canada)",
  "Carp (Canada)",
  "Carrot River (Canada)",
  "Carrying Place (Canada)",
  "Carseland (Canada)",
  "Carstairs (Canada)",
  "Carters Cove (Canada)",
  "Cartier (Canada)",
  "Cartwright (Canada)",
  "Casimir (Canada)",
  "Casselman (Canada)",
  "Castle Bay (Canada)",
  "Castlegar (Canada)",
  "Cat Lake (Canada)",
  "Causapscal (Canada)",
  "Cavendish (Canada)",
  "Cayley (Canada)",
  "Cayuga (Canada)",
  "Cedar Valley (Canada)",
  "Central Bedeque (Canada)",
  "Centreville (Canada)",
  "Ceylon (Canada)",
  "Chalk River (Canada)",
  "Chambly (Canada)",
  "Champlain (Canada)",
  "Chandler (Canada)",
  "Chapais (Canada)",
  "Chapel Arm (Canada)",
  "Chapleau (Canada)",
  "Charlemagne (Canada)",
  "Charlesbourg (Canada)",
  "Charlie Lake (Canada)",
  "Charlottetown (Canada)",
  "Charny (Canada)",
  "Chase (Canada)",
  "Chateau-Richer (Canada)",
  "Chateauguay (Canada)",
  "Chatham (Canada)",
  "Chatsworth (Canada)",
  "Cheapside (Canada)",
  "Chelmsford (Canada)",
  "Chelsea (Canada)",
  "Chemainus (Canada)",
  "Cheneville (Canada)",
  "Cherry Grove (Canada)",
  "Chertsey (Canada)",
  "Chesley (Canada)",
  "Chester (Canada)",
  "Chestermere (Canada)",
  "Chesterville (Canada)",
  "Cheticamp (Canada)",
  "Chibougamau (Canada)",
  "Chicoutimi (Canada)",
  "Chilliwack (Canada)",
  "Chipman (Canada)",
  "Church Point (Canada)",
  "Churchill (Canada)",
  "Churchville (Canada)",
  "Chute-aux-Outardes (Canada)",
  "Circonscription electorale de Jacques-Cartier (Canada)",
  "Clair (Canada)",
  "Clairmont (Canada)",
  "Claremont (Canada)",
  "Clarence (Canada)",
  "Clarence-Rockland (Canada)",
  "Clarenville (Canada)",
  "Clarenville-Shoal Harbour (Canada)",
  "Claresholm (Canada)",
  "Clarke City (Canada)",
  "Clarkson (Canada)",
  "Clearwater (Canada)",
  "Clementsvale (Canada)",
  "Clermont (Canada)",
  "Cleveland (Canada)",
  "Clifford (Canada)",
  "Clinton (Canada)",
  "Clive (Canada)",
  "Clyde River (Canada)",
  "Coal Harbour (Canada)",
  "Coaldale (Canada)",
  "Coaticook (Canada)",
  "Cobble Hill (Canada)",
  "Cobden (Canada)",
  "Coboconk (Canada)",
  "Cobourg (Canada)",
  "Coburg (Canada)",
  "Cochrane (Canada)",
  "Colborne (Canada)",
  "Cold Lake (Canada)",
  "Coldstream (Canada)",
  "Coldwater (Canada)",
  "Coleman (Canada)",
  "Coleraine (Canada)",
  "Coleville (Canada)",
  "Colinet (Canada)",
  "Colliers (Canada)",
  "Collingwood (Canada)",
  "Colonsay (Canada)",
  "Colville Lake (Canada)",
  "Comber (Canada)",
  "Comox (Canada)",
  "Compton (Canada)",
  "Conception Bay South (Canada)",
  "Concord (Canada)",
  "Conestogo (Canada)",
  "Coniston (Canada)",
  "Conquest (Canada)",
  "Consort (Canada)",
  "Contrecoeur (Canada)",
  "Cookshire-Eaton (Canada)",
  "Copper Cliff (Canada)",
  "Coquitlam (Canada)",
  "Corbyville (Canada)",
  "Corner Brook (Canada)",
  "Cornwall (Canada)",
  "Cornwallis (Canada)",
  "Cote-Saint-Luc (Canada)",
  "Cottam (Canada)",
  "Courtenay (Canada)",
  "Courtland (Canada)",
  "Courtright (Canada)",
  "Coutts (Canada)",
  "Cow Head (Canada)",
  "Cowansville (Canada)",
  "Cowichan Bay (Canada)",
  "Cowley (Canada)",
  "Crabtree (Canada)",
  "Craik (Canada)",
  "Cranberry (Canada)",
  "Cranberry Portage (Canada)",
  "Cranbrook (Canada)",
  "Crapaud (Canada)",
  "Creemore (Canada)",
  "Creston (Canada)",
  "Crofton (Canada)",
  "Cromer (Canada)",
  "Cross Lake (Canada)",
  "Crossfield (Canada)",
  "Cudworth (Canada)",
  "Cumberland (Canada)",
  "Cumberland Bay (Canada)",
  "Cumberland House (Canada)",
  "Cupar (Canada)",
  "Cut Knife (Canada)",
  "Czar (Canada)",
  "Dalhousie (Canada)",
  "Dalmeny (Canada)",
  "Danville (Canada)",
  "Dartmouth (Canada)",
  "Dashwood (Canada)",
  "Dauphin (Canada)",
  "Daveluyville (Canada)",
  "Davidson (Canada)",
  "Dawson City (Canada)",
  "Dawson Creek (Canada)",
  "Dawson Settlement (Canada)",
  "Daysland (Canada)",
  "Debden (Canada)",
  "Debert (Canada)",
  "DeBolt (Canada)",
  "Deep River (Canada)",
  "Deer Lake (Canada)",
  "Delaware (Canada)",
  "Delaware West (Canada)",
  "Delburne (Canada)",
  "Delhi (Canada)",
  "Deline (Canada)",
  "Delisle (Canada)",
  "Delson (Canada)",
  "Delta (Canada)",
  "Denfield (Canada)",
  "Denman Island (Canada)",
  "Deroche (Canada)",
  "Desbiens (Canada)",
  "Deschaillons-sur-Saint-Laurent (Canada)",
  "Deschambault (Canada)",
  "Deseronto (Canada)",
  "Deux-Montagnes (Canada)",
  "Devon (Canada)",
  "Didsbury (Canada)",
  "Dieppe (Canada)",
  "Digby (Canada)",
  "Dinsmore (Canada)",
  "Disraeli (Canada)",
  "Dixville (Canada)",
  "Doaktown (Canada)",
  "Dog Creek (Canada)",
  "Dolbeau-Mistassini (Canada)",
  "Dollard-Des Ormeaux (Canada)",
  "Domain (Canada)",
  "Dominion City (Canada)",
  "Domremy (Canada)",
  "Don Mills (Canada)",
  "Donnacona (Canada)",
  "Donnelly (Canada)",
  "Dorchester (Canada)",
  "Dorion-Gardens (Canada)",
  "Dorset (Canada)",
  "Dorval (Canada)",
  "Dover (Canada)",
  "Downsview (Canada)",
  "Drayton (Canada)",
  "Drayton Valley (Canada)",
  "Dresden (Canada)",
  "Drumbo (Canada)",
  "Drumheller (Canada)",
  "Drummondville (Canada)",
  "Dryden (Canada)",
  "Dublin (Canada)",
  "Duchess (Canada)",
  "Duck Lake (Canada)",
  "Dudswell (Canada)",
  "Dufresne (Canada)",
  "Dugald (Canada)",
  "Duhamel (Canada)",
  "Duncan (Canada)",
  "Dundalk (Canada)",
  "Dundas (Canada)",
  "Dundurn (Canada)",
  "Dunham (Canada)",
  "Dunnville (Canada)",
  "Dunsford (Canada)",
  "Dupuy (Canada)",
  "Durham (Canada)",
  "Eaglesham (Canada)",
  "Earlton (Canada)",
  "East Angus (Canada)",
  "East Broughton (Canada)",
  "East Chezzetcook (Canada)",
  "East Coulee (Canada)",
  "East Margaree (Canada)",
  "Eastern Passage (Canada)",
  "Eastman (Canada)",
  "Eastport (Canada)",
  "Eatonville (Canada)",
  "Echo Bay (Canada)",
  "Eckville (Canada)",
  "Economy (Canada)",
  "Edam (Canada)",
  "Eden (Canada)",
  "Edgerton (Canada)",
  "Edgewood (Canada)",
  "Edmonton (Canada)",
  "Edmundston (Canada)",
  "Edson (Canada)",
  "Eganville (Canada)",
  "Elbow (Canada)",
  "Elgin (Canada)",
  "Elk Point (Canada)",
  "Elliot Lake (Canada)",
  "Elliston (Canada)",
  "Elma (Canada)",
  "Elmira (Canada)",
  "Elmsdale (Canada)",
  "Elmvale (Canada)",
  "Elora (Canada)",
  "Elrose (Canada)",
  "Embrun (Canada)",
  "Emerald (Canada)",
  "Emerald Junction (Canada)",
  "Emo (Canada)",
  "Empress (Canada)",
  "Emsdale (Canada)",
  "Enderby (Canada)",
  "Enfield (Canada)",
  "Englehart (Canada)",
  "Enterprise (Canada)",
  "Entwistle (Canada)",
  "Eriksdale (Canada)",
  "Erin (Canada)",
  "Espanola (Canada)",
  "Esprit-Saint (Canada)",
  "Essex (Canada)",
  "Esterhazy (Canada)",
  "Estevan (Canada)",
  "Eston (Canada)",
  "Etobicoke (Canada)",
  "Eureka (Canada)",
  "Everett (Canada)",
  "Exeter (Canada)",
  "Fabreville (Canada)",
  "Fairmont Hot Springs (Canada)",
  "Fairview, Alberta (Canada)",
  "Farnham (Canada)",
  "Fenelon Falls (Canada)",
  "Fergus (Canada)",
  "Ferintosh (Canada)",
  "Ferme-Neuve (Canada)",
  "Fermeuse (Canada)",
  "Fermont (Canada)",
  "Fernie (Canada)",
  "Field (Canada)",
  "Fillmore (Canada)",
  "Finch (Canada)",
  "Flat River (Canada)",
  "Flesherton (Canada)",
  "Flin Flon (Canada)",
  "Florenceville-Bristol (Canada)",
  "Foam Lake (Canada)",
  "Fogo (Canada)",
  "Fonthill (Canada)",
  "Foremost (Canada)",
  "Forest (Canada)",
  "Forestburg (Canada)",
  "Forestville (Canada)",
  "Fort Albany (Canada)",
  "Fort Erie (Canada)",
  "Fort Frances (Canada)",
  "Fort Good Hope (Canada)",
  "Fort Hope (Canada)",
  "Fort Kent (Canada)",
  "Fort Macleod (Canada)",
  "Fort McMurray (Canada)",
  "Fort McPherson (Canada)",
  "Fort Nelson (Canada)",
  "Fort Saskatchewan (Canada)",
  "Fort Severn (Canada)",
  "Fort Simpson (Canada)",
  "Fort Smith (Canada)",
  "Fort St. James (Canada)",
  "Fort St. John (Canada)",
  "Fort Vermilion (Canada)",
  "Fort-Coulonge (Canada)",
  "Fortierville (Canada)",
  "Fortune (Canada)",
  "Fox Creek (Canada)",
  "Foxtrap (Canada)",
  "Foymount (Canada)",
  "Francis (Canada)",
  "Frankford (Canada)",
  "Fraser Lake (Canada)",
  "Fredericton (Canada)",
  "Fredericton Junction (Canada)",
  "Frelighsburg (Canada)",
  "Frobisher (Canada)",
  "Fruitvale (Canada)",
  "Gabriola (Canada)",
  "Gadsby (Canada)",
  "Galahad (Canada)",
  "Gambo (Canada)",
  "Gananoque (Canada)",
  "Gander (Canada)",
  "Gander Bay (Canada)",
  "Ganges (Canada)",
  "Garden River (Canada)",
  "Garibaldi (Canada)",
  "Garibaldi Highlands (Canada)",
  "Garnish (Canada)",
  "Garrick (Canada)",
  "Garson (Canada)",
  "Gatineau (Canada)",
  "Gem (Canada)",
  "Georgetown (Canada)",
  "Geraldton (Canada)",
  "Gibsons (Canada)",
  "Gilbert Plains (Canada)",
  "Gillam (Canada)",
  "Gimli (Canada)",
  "Girouxville (Canada)",
  "Glace Bay (Canada)",
  "Gladstone (Canada)",
  "Glaslyn (Canada)",
  "Gleichen (Canada)",
  "Glen Robertson (Canada)",
  "Glencoe (Canada)",
  "Glendon (Canada)",
  "Glentworth (Canada)",
  "Glenwood (Canada)",
  "Gloucester (Canada)",
  "Glovertown (Canada)",
  "Goderich (Canada)",
  "Gogama (Canada)",
  "Gold Bridge (Canada)",
  "Golden (Canada)",
  "Golden Lake (Canada)",
  "Gore (Canada)",
  "Gore Bay (Canada)",
  "Granby (Canada)",
  "Grand Bend (Canada)",
  "Grand Falls (Canada)",
  "Grand Falls-Windsor (Canada)",
  "Grand Forks (Canada)",
  "Grand Rapids (Canada)",
  "Grand Valley (Canada)",
  "Grand-Remous (Canada)",
  "Grand-Saint-Esprit (Canada)",
  "Grande Cache (Canada)",
  "Grande Prairie (Canada)",
  "Grande-Anse (Canada)",
  "Grande-Riviere (Canada)",
  "Grandora (Canada)",
  "Granton (Canada)",
  "Granum (Canada)",
  "Granville Ferry (Canada)",
  "Grasmere (Canada)",
  "Gravelbourg (Canada)",
  "Gravenhurst (Canada)",
  "Gray Creek (Canada)",
  "Great Village (Canada)",
  "Greater Sudbury (Canada)",
  "Greely (Canada)",
  "Greenfield Park (Canada)",
  "Greenspond (Canada)",
  "Greenwood (Canada)",
  "Grenfell (Canada)",
  "Grenville (Canada)",
  "Gretna (Canada)",
  "Grimsby (Canada)",
  "Grimshaw (Canada)",
  "Grindrod (Canada)",
  "Griswold (Canada)",
  "Gronlid (Canada)",
  "Grono Road (Canada)",
  "Guelph (Canada)",
  "Guigues (Canada)",
  "Gull Lake (Canada)",
  "Guysborough (Canada)",
  "Hafford (Canada)",
  "Hagensborg (Canada)",
  "Hagersville (Canada)",
  "Haileybury (Canada)",
  "Halfmoon Bay (Canada)",
  "Haliburton (Canada)",
  "Halifax (Canada)",
  "Ham-Nord (Canada)",
  "Hamilton (Canada)",
  "Hamiota (Canada)",
  "Hampden (Canada)",
  "Hampton (Canada)",
  "Hanley (Canada)",
  "Hanna (Canada)",
  "Hanover (Canada)",
  "Hantsport (Canada)",
  "Happy Valley-Goose Bay (Canada)",
  "Harbour Grace (Canada)",
  "Harbour Main (Canada)",
  "Hardisty (Canada)",
  "Hare Bay (Canada)",
  "Harriston (Canada)",
  "Harrow (Canada)",
  "Hartland (Canada)",
  "Hartley Bay (Canada)",
  "Hartney (Canada)",
  "Harvey (Canada)",
  "Hastings (Canada)",
  "Havelock (Canada)",
  "Havre Boucher (Canada)",
  "Havre-Aubert (Canada)",
  "Havre-Saint-Pierre (Canada)",
  "Hawkesbury (Canada)",
  "Hawkestone Beach (Canada)",
  "Hay Lakes (Canada)",
  "Hay River (Canada)",
  "Hazelton (Canada)",
  "Head of Jeddore (Canada)",
  "Hearst (Canada)",
  "Hebertville (Canada)",
  "Hebron (Canada)",
  "Hemmingford (Canada)",
  "Henryville (Canada)",
  "Hensall (Canada)",
  "Herbert (Canada)",
  "Heron Bay (Canada)",
  "Hespeler (Canada)",
  "High Level (Canada)",
  "High Prairie (Canada)",
  "High River (Canada)",
  "Hillsburgh (Canada)",
  "Hinton (Canada)",
  "Hobbema (Canada)",
  "Holden (Canada)",
  "Holman (Canada)",
  "Holyrood (Canada)",
  "Honeymoon Bay (Canada)",
  "Hope (Canada)",
  "Hornepayne (Canada)",
  "Houston (Canada)",
  "Howick (Canada)",
  "Howley (Canada)",
  "Hubbards (Canada)",
  "Hudson (Canada)",
  "Hudson Bay (Canada)",
  "Hull (Canada)",
  "Humboldt (Canada)",
  "Hunter River (Canada)",
  "Huntingdon (Canada)",
  "Huntsville (Canada)",
  "Huron East (Canada)",
  "Hythe (Canada)",
  "Ignace (Canada)",
  "Ilderton (Canada)",
  "Ile-a-la-Crosse (Canada)",
  "Indian Head (Canada)",
  "Ingersoll (Canada)",
  "Ingleside (Canada)",
  "Inglewood (Canada)",
  "Inglis (Canada)",
  "Ingonish (Canada)",
  "Innisfail (Canada)",
  "Innisfil (Canada)",
  "Inukjuak (Canada)",
  "Inuvik (Canada)",
  "Invermere (Canada)",
  "Inverness (Canada)",
  "Inwood (Canada)",
  "Iona (Canada)",
  "Iqaluit (Canada)",
  "Irma (Canada)",
  "Iroquois (Canada)",
  "Iroquois Falls (Canada)",
  "Irricana (Canada)",
  "Islington (Canada)",
  "Italy Cross (Canada)",
  "Ituna (Canada)",
  "Ivujivik (Canada)",
  "Jacksons (Canada)",
  "Jasper (Canada)",
  "Jenner (Canada)",
  "Jerseyville (Canada)",
  "Joliette (Canada)",
  "Jonquiere (Canada)",
  "Jordan (Canada)",
  "Jordan Falls (Canada)",
  "Kagawong (Canada)",
  "Kamloops (Canada)",
  "Kamouraska (Canada)",
  "Kamsack (Canada)",
  "Kanata (Canada)",
  "Kangiqsujuaq (Canada)",
  "Kangirsuk (Canada)",
  "Kapuskasing (Canada)",
  "Kars (Canada)",
  "Kaslo (Canada)",
  "Kazabazua (Canada)",
  "Kedgwick (Canada)",
  "Keene (Canada)",
  "Keewatin (Canada)",
  "Kelowna (Canada)",
  "Kelvington (Canada)",
  "Kemptville (Canada)",
  "Kenora (Canada)",
  "Kensington (Canada)",
  "Kent Bridge (Canada)",
  "Kentville (Canada)",
  "Kerrobert (Canada)",
  "Kerwood (Canada)",
  "Keswick (Canada)",
  "Keswick Beach (Canada)",
  "Kettleby (Canada)",
  "Killarney (Canada)",
  "Kilworthy (Canada)",
  "Kimberley (Canada)",
  "Kimmirut (Canada)",
  "Kincardine (Canada)",
  "Kindersley (Canada)",
  "King City (Canada)",
  "Kingsey Falls (Canada)",
  "Kingston (Canada)",
  "Kingsville (Canada)",
  "Kinistino (Canada)",
  "Kinuso (Canada)",
  "Kippen (Canada)",
  "Kirkland (Canada)",
  "Kirkland Lake (Canada)",
  "Kirkton (Canada)",
  "Kisbey (Canada)",
  "Kispiox (Canada)",
  "Kitchener (Canada)",
  "Kitimat (Canada)",
  "Kitscoty (Canada)",
  "Kitwanga (Canada)",
  "Klemtu (Canada)",
  "Knowlton (Canada)",
  "Kohler (Canada)",
  "Komoka (Canada)",
  "Kugaaruk (Canada)",
  "Kugluktuk (Canada)",
  "Kuujjuaq (Canada)",
  "Kuujjuarapik (Canada)",
  "La Baie (Canada)",
  "La Conception (Canada)",
  "La Corey (Canada)",
  "La Durantaye (Canada)",
  "La Guadeloupe (Canada)",
  "La Loche (Canada)",
  "La Malbaie (Canada)",
  "La Morandiere (Canada)",
  "La Patrie (Canada)",
  "La Pocatiere (Canada)",
  "La Prairie (Canada)",
  "La Presentation (Canada)",
  "La Ronge (Canada)",
  "La Salle (Canada)",
  "La Sarre (Canada)",
  "La Tabatiere (Canada)",
  "La Tuque (Canada)",
  "La Visitation (Canada)",
  "Labelle (Canada)",
  "Labrador City (Canada)",
  "Lac du Bonnet (Canada)",
  "Lac La Biche (Canada)",
  "Lac-aux-Sables (Canada)",
  "Lac-Drolet (Canada)",
  "Lac-Megantic (Canada)",
  "Lachenaie (Canada)",
  "Lachine (Canada)",
  "Lachute (Canada)",
  "Lacolle (Canada)",
  "Lacombe (Canada)",
  "Ladysmith (Canada)",
  "Lafleche (Canada)",
  "LaHave (Canada)",
  "Lake Cowichan (Canada)",
  "Lake Lenore (Canada)",
  "Lake Louise (Canada)",
  "Lakeburn (Canada)",
  "Lakefield (Canada)",
  "Lambton (Canada)",
  "Lamont (Canada)",
  "Lampman (Canada)",
  "Lanark (Canada)",
  "Lancaster (Canada)",
  "Lancaster Park (Canada)",
  "Landrienne (Canada)",
  "Lang (Canada)",
  "Langdon (Canada)",
  "Langenburg (Canada)",
  "Langham (Canada)",
  "Langley (Canada)",
  "Lanigan (Canada)",
  "Lanoraie (Canada)",
  "Lantzville (Canada)",
  "Lasalle (Canada)",
  "Lashburn (Canada)",
  "Laurentia Beach (Canada)",
  "Laurier-Station (Canada)",
  "Laval (Canada)",
  "Lavaltrie (Canada)",
  "Laverlochere (Canada)",
  "Lavillette (Canada)",
  "Lawrencetown (Canada)",
  "Leader (Canada)",
  "Leamington (Canada)",
  "Leduc (Canada)",
  "Lefroy (Canada)",
  "Legal (Canada)",
  "Lemberg (Canada)",
  "Leonard (Canada)",
  "Lery (Canada)",
  "Les Cedres (Canada)",
  "Les Coteaux (Canada)",
  "Les Escoumins (Canada)",
  "Les Mechins (Canada)",
  "Leslieville (Canada)",
  "Lethbridge (Canada)",
  "Levack (Canada)",
  "Levis (Canada)",
  "Lewisporte (Canada)",
  "Lillooet (Canada)",
  "Limoges (Canada)",
  "Lindsay (Canada)",
  "Lipton (Canada)",
  "Listowel (Canada)",
  "Little Current (Canada)",
  "Little Narrows (Canada)",
  "Lively (Canada)",
  "Liverpool (Canada)",
  "Lloydminster (Canada)",
  "Logan Lake (Canada)",
  "London (Canada)",
  "Londonderry (Canada)",
  "Long Sault (Canada)",
  "Longueuil (Canada)",
  "Lorraine (Canada)",
  "Lotbiniere (Canada)",
  "Lougheed (Canada)",
  "Louisbourg (Canada)",
  "Louiseville (Canada)",
  "Lourdes (Canada)",
  "Lower Island Cove (Canada)",
  "Lucan (Canada)",
  "Lucknow (Canada)",
  "Lucky Lake (Canada)",
  "Lumby (Canada)",
  "Lumsden (Canada)",
  "Lunenburg (Canada)",
  "Luseland (Canada)",
  "Luskville (Canada)",
  "Lyn (Canada)",
  "Lyster (Canada)",
  "Lytton (Canada)",
  "Maberly (Canada)",
  "Mabou (Canada)",
  "Macamic (Canada)",
  "MacGregor (Canada)",
  "Mackenzie (Canada)",
  "Macklin (Canada)",
  "MacTier (Canada)",
  "Madoc (Canada)",
  "Magog (Canada)",
  "Magrath (Canada)",
  "Mahone Bay (Canada)",
  "Maidstone (Canada)",
  "Malagawatch (Canada)",
  "Malakwa (Canada)",
  "Malartic (Canada)",
  "Mallaig (Canada)",
  "Malton (Canada)",
  "Mandeville (Canada)",
  "Manitou (Canada)",
  "Manitouwadge (Canada)",
  "Maniwaki (Canada)",
  "Mankota (Canada)",
  "Mannville (Canada)",
  "Manor (Canada)",
  "Manotick Station (Canada)",
  "Manseau (Canada)",
  "Mansonville (Canada)",
  "Maple (Canada)",
  "Maple Creek (Canada)",
  "Maple Ridge (Canada)",
  "Mar (Canada)",
  "Marathon (Canada)",
  "Marbleton (Canada)",
  "Marchand (Canada)",
  "Margaree Valley (Canada)",
  "Margaretsville (Canada)",
  "Maria (Canada)",
  "Marieville (Canada)",
  "Markdale (Canada)",
  "Markham (Canada)",
  "Markstay (Canada)",
  "Marmora (Canada)",
  "Martinville (Canada)",
  "Marwayne (Canada)",
  "Maryfield (Canada)",
  "Marystown (Canada)",
  "Marysville (Canada)",
  "Mascouche (Canada)",
  "Maskinonge (Canada)",
  "Masset (Canada)",
  "Massey (Canada)",
  "Masson-Angers (Canada)",
  "Massueville (Canada)",
  "Matagami (Canada)",
  "Matane (Canada)",
  "Matheson (Canada)",
  "Maxville (Canada)",
  "Mayne (Canada)",
  "McBride (Canada)",
  "McCreary (Canada)",
  "McGregor (Canada)",
  "McLennan (Canada)",
  "McMasterville (Canada)",
  "Meadow Lake (Canada)",
  "Meaford (Canada)",
  "Medicine Hat (Canada)",
  "Melbourne Ridge (Canada)",
  "Melfort (Canada)",
  "Melita (Canada)",
  "Melocheville (Canada)",
  "Melville (Canada)",
  "Memphremagog (Canada)",
  "Memramcook (Canada)",
  "Mercier (Canada)",
  "Merlin (Canada)",
  "Merrickville (Canada)",
  "Merritt (Canada)",
  "Merville (Canada)",
  "Messines (Canada)",
  "Metabetchouan-Lac-a-la-Croix (Canada)",
  "Meteghan (Canada)",
  "Meteghan River (Canada)",
  "Metis-sur-Mer (Canada)",
  "Miami (Canada)",
  "Midale (Canada)",
  "Middle Arm (Canada)",
  "Middle Musquodoboit (Canada)",
  "Middleton (Canada)",
  "Midhurst (Canada)",
  "Midland (Canada)",
  "Milden (Canada)",
  "Mildmay (Canada)",
  "Milford (Canada)",
  "Milford Station (Canada)",
  "Milk River (Canada)",
  "Mill Village (Canada)",
  "Millarville (Canada)",
  "Millbrook (Canada)",
  "Millet (Canada)",
  "Millville (Canada)",
  "Milton (Canada)",
  "Milverton (Canada)",
  "Minden (Canada)",
  "Minnedosa (Canada)",
  "Minto (Canada)",
  "Mira Gut (Canada)",
  "Mirabel (Canada)",
  "Miramichi (Canada)",
  "Miscouche (Canada)",
  "Mission (Canada)",
  "Mississauga (Canada)",
  "Mistatim (Canada)",
  "Mitchell (Canada)",
  "Moberly Lake (Canada)",
  "Mobile (Canada)",
  "Moffat (Canada)",
  "Moncton (Canada)",
  "Mont-Joli (Canada)",
  "Mont-Laurier (Canada)",
  "Mont-Royal (Canada)",
  "Mont-Saint-Gregoire (Canada)",
  "Mont-Saint-Hilaire (Canada)",
  "Mont-Saint-Michel (Canada)",
  "Mont-Tremblant (Canada)",
  "Montague (Canada)",
  "Montebello (Canada)",
  "Montmagny (Canada)",
  "Montmartre (Canada)",
  "Montreal (Canada)",
  "Montreal East (Canada)",
  "Moonbeam (Canada)",
  "Mooretown (Canada)",
  "Moose Creek (Canada)",
  "Moose Jaw (Canada)",
  "Moose Lake (Canada)",
  "Moosehorn (Canada)",
  "Moosomin (Canada)",
  "Moosonee (Canada)",
  "Moraviantown (Canada)",
  "Morden (Canada)",
  "Morell (Canada)",
  "Morewood (Canada)",
  "Morinville (Canada)",
  "Morrin (Canada)",
  "Morris (Canada)",
  "Morrisburg (Canada)",
  "Mortlach (Canada)",
  "Mount Brydges (Canada)",
  "Mount Elgin (Canada)",
  "Mount Forest (Canada)",
  "Mount Pearl (Canada)",
  "Mount Stewart (Canada)",
  "Mount Uniacke (Canada)",
  "Mountain View (Canada)",
  "Muirkirk (Canada)",
  "Mulgrave (Canada)",
  "Mundare (Canada)",
  "Munson (Canada)",
  "Munster (Canada)",
  "Murdochville (Canada)",
  "Murray Harbour (Canada)",
  "Murray River (Canada)",
  "Musquodoboit Harbour (Canada)",
  "Myrnam (Canada)",
  "Nackawic (Canada)",
  "Naicam (Canada)",
  "Nakina (Canada)",
  "Nakusp (Canada)",
  "Nampa (Canada)",
  "Namur (Canada)",
  "Nanaimo (Canada)",
  "Nanoose Bay (Canada)",
  "Nantes (Canada)",
  "Nanton (Canada)",
  "Napanee (Canada)",
  "Napierville (Canada)",
  "Nauwigewauk (Canada)",
  "Neepawa (Canada)",
  "Neguac (Canada)",
  "Neilburg (Canada)",
  "Nelson (Canada)",
  "Nepean (Canada)",
  "Neudorf (Canada)",
  "Neuville (Canada)",
  "New Aiyansh (Canada)",
  "New Carlisle (Canada)",
  "New Denver (Canada)",
  "New Dundee (Canada)",
  "New Glasgow (Canada)",
  "New Hamburg (Canada)",
  "New Harbour (Canada)",
  "New Liskeard (Canada)",
  "New Ross (Canada)",
  "New Waterford (Canada)",
  "New Westminster (Canada)",
  "New-Richmond (Canada)",
  "New-Wes-Valley (Canada)",
  "Newburgh (Canada)",
  "Newcastle Creek (Canada)",
  "Newcastle Village (Canada)",
  "Newdale (Canada)",
  "Newmarket (Canada)",
  "Newport (Canada)",
  "Newton (Canada)",
  "Newtown (Canada)",
  "Niagara (Canada)",
  "Niagara Falls (Canada)",
  "Niagara-on-the-Lake (Canada)",
  "Nicolet (Canada)",
  "Nipawin (Canada)",
  "Nipigon (Canada)",
  "Nisku (Canada)",
  "Niverville (Canada)",
  "Nobleford (Canada)",
  "Nobleton (Canada)",
  "Nokomis (Canada)",
  "Nominingue (Canada)",
  "Norfolk County (Canada)",
  "Norman Wells (Canada)",
  "Normandin (Canada)",
  "Norquay (Canada)",
  "North Battleford (Canada)",
  "North Bay (Canada)",
  "North Delta (Canada)",
  "North Hatley (Canada)",
  "North Lunenburg (Canada)",
  "North Perth (Canada)",
  "North Portal (Canada)",
  "North Russell (Canada)",
  "North Rustico (Canada)",
  "North Saanich (Canada)",
  "North Star (Canada)",
  "North Sydney (Canada)",
  "North Vancouver (Canada)",
  "North York (Canada)",
  "Norval (Canada)",
  "Norway House (Canada)",
  "Norwich (Canada)",
  "Norwood (Canada)",
  "Notre-Dame-de-Lourdes (Canada)",
  "Notre-Dame-du-Bon-Conseil (Canada)",
  "Notre-Dame-du-Mont-Carmel (Canada)",
  "Notre-Dame-du-Nord (Canada)",
  "Notre-Dame-du-Portage (Canada)",
  "Nouvelle (Canada)",
  "Noyan (Canada)",
  "Oak Lake (Canada)",
  "Oak Point (Canada)",
  "Oakville (Canada)",
  "Obedjiwan (Canada)",
  "Odessa (Canada)",
  "Ogoki (Canada)",
  "Ohsweken (Canada)",
  "Oil Springs (Canada)",
  "Oka (Canada)",
  "Okanagan Centre (Canada)",
  "Okotoks (Canada)",
  "Old Perlican (Canada)",
  "Oldcastle (Canada)",
  "Olds (Canada)",
  "Oliver (Canada)",
  "Omemee (Canada)",
  "Omerville (Canada)",
  "Onanole (Canada)",
  "Onion Lake (Canada)",
  "Onoway (Canada)",
  "Orangeville (Canada)",
  "Orillia (Canada)",
  "Orleans (Canada)",
  "Ormstown (Canada)",
  "Oromocto (Canada)",
  "Orono (Canada)",
  "Orton (Canada)",
  "Oshawa (Canada)",
  "Osoyoos (Canada)",
  "Ottawa (Canada)",
  "Otter Lake (Canada)",
  "Otterburn Park (Canada)",
  "Otterburne (Canada)",
  "Outlook (Canada)",
  "Owen Sound (Canada)",
  "Oxbow (Canada)",
  "Oxdrift (Canada)",
  "Oxford (Canada)",
  "Oxford House (Canada)",
  "Oyama (Canada)",
  "Pain Court (Canada)",
  "Paisley (Canada)",
  "Pakenham (Canada)",
  "Palmarolle (Canada)",
  "Palmerston (Canada)",
  "Papineauville (Canada)",
  "Paradise Cove (Canada)",
  "Paradise Valley (Canada)",
  "Parham (Canada)",
  "Paris (Canada)",
  "Parisville (Canada)",
  "Parkhill (Canada)",
  "Parksville (Canada)",
  "Parry Sound (Canada)",
  "Parson (Canada)",
  "Pasadena (Canada)",
  "Pascal (Canada)",
  "Paspebiac (Canada)",
  "Pass Lake (Canada)",
  "Paulatuk (Canada)",
  "Peace River (Canada)",
  "Peachland (Canada)",
  "Pefferlaw (Canada)",
  "Pelly (Canada)",
  "Pemberton (Canada)",
  "Pembroke (Canada)",
  "Penetanguishene (Canada)",
  "Penhold (Canada)",
  "Pennfield (Canada)",
  "Penobsquis (Canada)",
  "Penticton (Canada)",
  "Penzance (Canada)",
  "Perth (Canada)",
  "Perth-Andover (Canada)",
  "Petawawa (Canada)",
  "Peterborough (Canada)",
  "Petherton (Canada)",
  "Petit-de-Grat (Canada)",
  "Petitcodiac (Canada)",
  "Petite-Riviere-Saint-Francois (Canada)",
  "Petrolia (Canada)",
  "Pickering (Canada)",
  "Picton (Canada)",
  "Pictou (Canada)",
  "Pierreville (Canada)",
  "Pierson (Canada)",
  "Pikangikum (Canada)",
  "Pilot Butte (Canada)",
  "Pilot Mound (Canada)",
  "Pinantan Lake (Canada)",
  "Pinawa (Canada)",
  "Pincher Creek (Canada)",
  "Pincourt (Canada)",
  "Pine Falls (Canada)",
  "Piney (Canada)",
  "Pitt Meadows (Canada)",
  "Placentia (Canada)",
  "Plamondon (Canada)",
  "Plantagenet Station (Canada)",
  "Plaster Rock (Canada)",
  "Plessisville (Canada)",
  "Plum Coulee (Canada)",
  "Plumas (Canada)",
  "Plympton (Canada)",
  "Point Edward (Canada)",
  "Pointe-a-la-Croix (Canada)",
  "Pointe-aux-Trembles (Canada)",
  "Pointe-Calumet (Canada)",
  "Pointe-Claire (Canada)",
  "Pointe-Fortune (Canada)",
  "Pointe-Lebel (Canada)",
  "Pointe-Verte (Canada)",
  "Ponoka (Canada)",
  "Pont-Rouge (Canada)",
  "Pont-Viau (Canada)",
  "Pontypool (Canada)",
  "Poplar Hill (Canada)",
  "Port Alberni (Canada)",
  "Port Alice (Canada)",
  "Port au Choix (Canada)",
  "Port au Port (Canada)",
  "Port Burwell (Canada)",
  "Port Clements (Canada)",
  "Port Colborne (Canada)",
  "Port Coquitlam (Canada)",
  "Port Credit (Canada)",
  "Port Dover (Canada)",
  "Port Edward (Canada)",
  "Port Elgin (Canada)",
  "Port Hardy (Canada)",
  "Port Hawkesbury (Canada)",
  "Port Hope (Canada)",
  "Port Lorne (Canada)",
  "Port McNeill (Canada)",
  "Port McNicoll (Canada)",
  "Port Medway (Canada)",
  "Port Moody (Canada)",
  "Port Morien (Canada)",
  "Port Perry (Canada)",
  "Port Renfrew (Canada)",
  "Port Royal (Canada)",
  "Port Saunders (Canada)",
  "Port Stanley (Canada)",
  "Port-Cartier (Canada)",
  "Port-Daniel (Canada)",
  "Portage (Canada)",
  "Portage la Prairie (Canada)",
  "Portage-du-Fort (Canada)",
  "Porters Lake (Canada)",
  "Portneuf (Canada)",
  "Portugal Cove (Canada)",
  "Potter (Canada)",
  "Pouce Coupe (Canada)",
  "Pouch Cove (Canada)",
  "Powassan (Canada)",
  "Powell River (Canada)",
  "Preeceville (Canada)",
  "Prescott (Canada)",
  "Price (Canada)",
  "Priceville (Canada)",
  "Prince (Canada)",
  "Prince Albert (Canada)",
  "Prince George (Canada)",
  "Prince Rupert (Canada)",
  "Princeton (Canada)",
  "Princeville (Canada)",
  "Pritchard (Canada)",
  "Prospect (Canada)",
  "Provost (Canada)",
  "Pugwash (Canada)",
  "Pukatawagan (Canada)",
  "Puslinch (Canada)",
  "Puvirnituq (Canada)",
  "Pynns (Canada)",
  "Qualicum Beach (Canada)",
  "Quaqtaq (Canada)",
  "Quathiaski Cove (Canada)",
  "Quebec (Canada)",
  "Queen Charlotte (Canada)",
  "Quesnel (Canada)",
  "Quill Lake (Canada)",
  "Quispamsis (Canada)",
  "Quyon (Canada)",
  "Racine (Canada)",
  "Radisson (Canada)",
  "Radville (Canada)",
  "Rainbow Lake (Canada)",
  "Raleigh (Canada)",
  "Ramea (Canada)",
  "Rankin Inlet (Canada)",
  "Rapid City (Canada)",
  "Rawdon (Canada)",
  "Raymond (Canada)",
  "Raymore (Canada)",
  "Red Deer (Canada)",
  "Red Lake (Canada)",
  "Red Rock (Canada)",
  "Redcliff (Canada)",
  "Redvers (Canada)",
  "Redwater (Canada)",
  "Regina (Canada)",
  "Rencontre East (Canada)",
  "Renfrew (Canada)",
  "Renous (Canada)",
  "Repentigny (Canada)",
  "Revelstoke (Canada)",
  "Rexdale (Canada)",
  "Rhein (Canada)",
  "Richibucto (Canada)",
  "Richmond (Canada)",
  "Richmond Hill (Canada)",
  "Ridgetown (Canada)",
  "Ridgeway (Canada)",
  "Rigaud (Canada)",
  "Rimbey (Canada)",
  "Rimouski (Canada)",
  "Ripley (Canada)",
  "Ripon (Canada)",
  "Ripples (Canada)",
  "River Hebert (Canada)",
  "River John (Canada)",
  "Rivers (Canada)",
  "Riverside-Albert (Canada)",
  "Riverton (Canada)",
  "Riverview (Canada)",
  "Riviere-Beaudette (Canada)",
  "Riviere-Bleue (Canada)",
  "Riviere-Ouelle (Canada)",
  "Riviere-Rouge (Canada)",
  "Riviere-Saint-Paul (Canada)",
  "Roberts Creek (Canada)",
  "Roberval (Canada)",
  "Roblin (Canada)",
  "Rocanville (Canada)",
  "Rockland (Canada)",
  "Rockville (Canada)",
  "Rockwood (Canada)",
  "Rocky Mountain House (Canada)",
  "Roddickton (Canada)",
  "Rogersville (Canada)",
  "Roland (Canada)",
  "Rosalind (Canada)",
  "Rosedale (Canada)",
  "Rosemary (Canada)",
  "Rosemere (Canada)",
  "Roseneath (Canada)",
  "Rosetown (Canada)",
  "Rossburn (Canada)",
  "Rossland (Canada)",
  "Rossport (Canada)",
  "Rosthern (Canada)",
  "Rothesay (Canada)",
  "Rougemont (Canada)",
  "Rouleau (Canada)",
  "Rouyn-Noranda (Canada)",
  "Roxboro (Canada)",
  "Roxton Falls (Canada)",
  "Roxton Pond (Canada)",
  "Rumsey (Canada)",
  "Russell (Canada)",
  "Rycroft (Canada)",
  "Saanichton (Canada)",
  "Sable River (Canada)",
  "Sabrevois (Canada)",
  "Sackville (Canada)",
  "Saguenay (Canada)",
  "Saint Andrews (Canada)",
  "Saint Etienne-De-Lauzon (Canada)",
  "Saint John (Canada)",
  "Saint Romuald (Canada)",
  "Saint-Adalbert (Canada)",
  "Saint-Adelphe (Canada)",
  "Saint-Agapit (Canada)",
  "Saint-Aime-du-Lac-des-Iles (Canada)",
  "Saint-Alban (Canada)",
  "Saint-Alexis-des-Monts (Canada)",
  "Saint-Ambroise (Canada)",
  "Saint-Anaclet-de-Lessard (Canada)",
  "Saint-Andre (Canada)",
  "Saint-Andre-Avellin (Canada)",
  "Saint-Anicet (Canada)",
  "Saint-Anselme (Canada)",
  "Saint-Antoine-de-Tilly (Canada)",
  "Saint-Antoine-sur-Richelieu (Canada)",
  "Saint-Antonin (Canada)",
  "Saint-Apollinaire (Canada)",
  "Saint-Arsene (Canada)",
  "Saint-Aubert (Canada)",
  "Saint-Augustin (Canada)",
  "Saint-Augustin-de-Desmaures (Canada)",
  "Saint-Barnabe-Sud (Canada)",
  "Saint-Barthelemy (Canada)",
  "Saint-Basile (Canada)",
  "Saint-Basile-le-Grand (Canada)",
  "Saint-Benjamin (Canada)",
  "Saint-Bruno (Canada)",
  "Saint-Bruno-de-Montarville (Canada)",
  "Saint-Camille (Canada)",
  "Saint-Casimir (Canada)",
  "Saint-Cesaire (Canada)",
  "Saint-Charles-de-Bellechasse (Canada)",
  "Saint-Charles-sur-Richelieu (Canada)",
  "Saint-Clement (Canada)",
  "Saint-Colomban (Canada)",
  "Saint-Come (Canada)",
  "Saint-Come--Liniere (Canada)",
  "Saint-Cyprien (Canada)",
  "Saint-Cyrille-de-Wendover (Canada)",
  "Saint-Damase (Canada)",
  "Saint-Denis (Canada)",
  "Saint-Dominique (Canada)",
  "Saint-Donat (Canada)",
  "Saint-Elie (Canada)",
  "Saint-Elzear (Canada)",
  "Saint-Etienne-des-Gres (Canada)",
  "Saint-Eusebe (Canada)",
  "Saint-Eustache (Canada)",
  "Saint-Fabien (Canada)",
  "Saint-Fabien-de-Panet (Canada)",
  "Saint-Faustin--Lac-Carre (Canada)",
  "Saint-Felicien (Canada)",
  "Saint-Felix-de-Kingsey (Canada)",
  "Saint-Felix-de-Valois (Canada)",
  "Saint-Ferdinand (Canada)",
  "Saint-Ferreol-les-Neiges (Canada)",
  "Saint-Flavien (Canada)",
  "Saint-Francois-du-Lac (Canada)",
  "Saint-Frederic (Canada)",
  "Saint-Gabriel (Canada)",
  "Saint-Gabriel-de-Valcartier (Canada)",
  "Saint-Gedeon (Canada)",
  "Saint-Georges (Canada)",
  "Saint-Georges-de-Windsor (Canada)",
  "Saint-Germain-de-Grantham (Canada)",
  "Saint-Gilles (Canada)",
  "Saint-Godefroi (Canada)",
  "Saint-Guillaume (Canada)",
  "Saint-Henri (Canada)",
  "Saint-Hilaire (Canada)",
  "Saint-Honore-de-Temiscouata (Canada)",
  "Saint-Hubert-de-Riviere-du-Loup (Canada)",
  "Saint-Hugues (Canada)",
  "Saint-Hyacinthe (Canada)",
  "Saint-Irenee (Canada)",
  "Saint-Isidore (Canada)",
  "Saint-Jacques (Canada)",
  "Saint-Jean-de-Dieu (Canada)",
  "Saint-Jean-de-Matha (Canada)",
  "Saint-Jean-Port-Joli (Canada)",
  "Saint-Jean-sur-Richelieu (Canada)",
  "Saint-Jerome (Canada)",
  "Saint-Joachim (Canada)",
  "Saint-Joachim-de-Shefford (Canada)",
  "Saint-Joseph-de-Beauce (Canada)",
  "Saint-Jude (Canada)",
  "Saint-Lambert (Canada)",
  "Saint-Lazare-de-Bellechasse (Canada)",
  "Saint-Leon-de-Standon (Canada)",
  "Saint-Leonard (Canada)",
  "Saint-Liboire (Canada)",
  "Saint-Lin (Canada)",
  "Saint-Lin-Laurentides (Canada)",
  "Saint-Louis de Kent (Canada)",
  "Saint-Louis-de-Gonzague (Canada)",
  "Saint-Luc (Canada)",
  "Saint-Ludger (Canada)",
  "Saint-Malachie (Canada)",
  "Saint-Marc-de-Figuery (Canada)",
  "Saint-Marc-des-Carrieres (Canada)",
  "Saint-Marc-sur-Richelieu (Canada)",
  "Saint-Mathieu (Canada)",
  "Saint-Maurice (Canada)",
  "Saint-Michel (Canada)",
  "Saint-Michel-de-Bellechasse (Canada)",
  "Saint-Michel-des-Saints (Canada)",
  "Saint-Nazaire (Canada)",
  "Saint-Odilon-de-Cranbourne (Canada)",
  "Saint-Pacome (Canada)",
  "Saint-Pamphile (Canada)",
  "Saint-Pascal (Canada)",
  "Saint-Paul (Canada)",
  "Saint-Paul-de-la-Croix (Canada)",
  "Saint-Paulin (Canada)",
  "Saint-Philemon (Canada)",
  "Saint-Philippe (Canada)",
  "Saint-Philippe-de-Neri (Canada)",
  "Saint-Pie (Canada)",
  "Saint-Pierre (Canada)",
  "Saint-Pierre-de-Broughton (Canada)",
  "Saint-Pierre-les-Becquets (Canada)",
  "Saint-Placide (Canada)",
  "Saint-Polycarpe (Canada)",
  "Saint-Prosper (Canada)",
  "Saint-Quentin (Canada)",
  "Saint-Raphael (Canada)",
  "Saint-Raymond (Canada)",
  "Saint-Remi (Canada)",
  "Saint-Roch (Canada)",
  "Saint-Roch-de-Richelieu (Canada)",
  "Saint-Roch-des-Aulnaies (Canada)",
  "Saint-Sauveur (Canada)",
  "Saint-Sebastien (Canada)",
  "Saint-Severe (Canada)",
  "Saint-Simon-de-Rimouski (Canada)",
  "Saint-Stanislas (Canada)",
  "Saint-Stanislas-de-Kostka (Canada)",
  "Saint-Sylvere (Canada)",
  "Saint-Sylvestre (Canada)",
  "Saint-Telesphore (Canada)",
  "Saint-Thomas (Canada)",
  "Saint-Tite (Canada)",
  "Saint-Ubalde (Canada)",
  "Saint-Ulric (Canada)",
  "Saint-Urbain (Canada)",
  "Saint-Urbain-Premier (Canada)",
  "Saint-Vallier (Canada)",
  "Saint-Venant-de-Paquette (Canada)",
  "Saint-Victor (Canada)",
  "Saint-Wenceslas (Canada)",
  "Saint-Zacharie (Canada)",
  "Saint-Zenon (Canada)",
  "Saint-Zotique (Canada)",
  "Sainte-Adele (Canada)",
  "Sainte-Agathe-des-Monts (Canada)",
  "Sainte-Angele-de-Premont (Canada)",
  "Sainte-Anne-de-Beaupre (Canada)",
  "Sainte-Anne-de-Bellevue (Canada)",
  "Sainte-Anne-de-la-Perade (Canada)",
  "Sainte-Anne-des-Monts (Canada)",
  "Sainte-Anne-du-Lac (Canada)",
  "Sainte-Brigitte-des-Saults (Canada)",
  "Sainte-Catherine (Canada)",
  "Sainte-Cecile-de-Masham (Canada)",
  "Sainte-Cecile-de-Whitton (Canada)",
  "Sainte-Claire (Canada)",
  "Sainte-Clotilde-de-Horton (Canada)",
  "Sainte-Croix (Canada)",
  "Sainte-Famille (Canada)",
  "Sainte-Foy (Canada)",
  "Sainte-Gertrude (Canada)",
  "Sainte-Helene-de-Bagot (Canada)",
  "Sainte-Helene-de-Breakeyville (Canada)",
  "Sainte-Julie (Canada)",
  "Sainte-Julienne (Canada)",
  "Sainte-Justine (Canada)",
  "Sainte-Marcelline-de-Kildare (Canada)",
  "Sainte-Marguerite (Canada)",
  "Sainte-Marie (Canada)",
  "Sainte-Marthe (Canada)",
  "Sainte-Marthe-sur-le-Lac (Canada)",
  "Sainte-Martine (Canada)",
  "Sainte-Melanie (Canada)",
  "Sainte-Monique (Canada)",
  "Sainte-Perpetue (Canada)",
  "Sainte-Thecle (Canada)",
  "Sainte-Therese (Canada)",
  "Salaberry-de-Valleyfield (Canada)",
  "Salisbury (Canada)",
  "Salmo (Canada)",
  "Salmon Arm (Canada)",
  "Salmon River (Canada)",
  "Sandy Bay (Canada)",
  "Sandy Lake (Canada)",
  "Sanikiluaq (Canada)",
  "Sardis (Canada)",
  "Sarnia (Canada)",
  "Saskatoon (Canada)",
  "Saulnierville (Canada)",
  "Sault Ste. Marie (Canada)",
  "Savona (Canada)",
  "Sawyerville (Canada)",
  "Sayabec (Canada)",
  "Sayward (Canada)",
  "Scarborough (Canada)",
  "Schefferville (Canada)",
  "Schomberg (Canada)",
  "Schreiber (Canada)",
  "Scotland (Canada)",
  "Scotsburn (Canada)",
  "Scotstown (Canada)",
  "Scott (Canada)",
  "Scoudouc (Canada)",
  "Seaforth (Canada)",
  "Searchmont (Canada)",
  "Sechelt (Canada)",
  "Seldom-Little Seldom (Canada)",
  "Selkirk (Canada)",
  "Senneterre (Canada)",
  "Senneville (Canada)",
  "Sept-Iles (Canada)",
  "Seton Portage (Canada)",
  "Severn Bridge (Canada)",
  "Sexsmith (Canada)",
  "Shamattawa (Canada)",
  "Shanklin (Canada)",
  "Shannon (Canada)",
  "Shannonville (Canada)",
  "Sharbot Lake (Canada)",
  "Shaunavon (Canada)",
  "Shawinigan (Canada)",
  "Shawinigan-Sud (Canada)",
  "Shawnigan Lake (Canada)",
  "Shawville (Canada)",
  "Shediac (Canada)",
  "Sheho (Canada)",
  "Shelburne (Canada)",
  "Shellbrook (Canada)",
  "Sherbrooke (Canada)",
  "Sherrington (Canada)",
  "Sherwood Park (Canada)",
  "Shigawake (Canada)",
  "Shoal Lake (Canada)",
  "Shubenacadie (Canada)",
  "Sicamous (Canada)",
  "Sidney (Canada)",
  "Sillery (Canada)",
  "Silton (Canada)",
  "Simcoe (Canada)",
  "Sioux Lookout (Canada)",
  "Skidegate (Canada)",
  "Slave Lake (Canada)",
  "Slocan (Canada)",
  "Smithers (Canada)",
  "Smiths Falls (Canada)",
  "Smithville (Canada)",
  "Smoky Lake (Canada)",
  "Smooth Rock Falls (Canada)",
  "Snow Lake (Canada)",
  "Sointula (Canada)",
  "Sombra (Canada)",
  "Sooke (Canada)",
  "Sorel (Canada)",
  "Sorel-Tracy (Canada)",
  "Sorrento (Canada)",
  "Souris (Canada)",
  "South Augusta (Canada)",
  "South Gower (Canada)",
  "South Indian Lake (Canada)",
  "South Lancaster (Canada)",
  "South Mountain (Canada)",
  "South Porcupine (Canada)",
  "South River (Canada)",
  "South Slocan (Canada)",
  "Southend Reindeer (Canada)",
  "Southey (Canada)",
  "Spanish (Canada)",
  "Sparta (Canada)",
  "Spencerville (Canada)",
  "Sperling (Canada)",
  "Spillimacheen (Canada)",
  "Spirit River (Canada)",
  "Spiritwood (Canada)",
  "Spring Hill (Canada)",
  "Springdale (Canada)",
  "Springfield (Canada)",
  "Springhill (Canada)",
  "Spruce Grove (Canada)",
  "Spy Hill (Canada)",
  "Squamish (Canada)",
  "St-Hubert (Canada)",
  "St-Lazare (Canada)",
  "St-Timothee (Canada)",
  "St. Albert (Canada)",
  "St. Andrews (Canada)",
  "St. Anthony (Canada)",
  "St. Brieux (Canada)",
  "St. Catharines (Canada)",
  "St. Jacobs (Canada)",
  "St. Louis (Canada)",
  "St. Marys (Canada)",
  "St. Paul (Canada)",
  "St. Peters Bay (Canada)",
  "St. Stephen (Canada)",
  "St. Thomas (Canada)",
  "St. Walburg (Canada)",
  "Stanbridge East (Canada)",
  "Standard (Canada)",
  "Stanley Bridge, Hope River, Bayview, Cavendish and North Rustico (Canada)",
  "Star City (Canada)",
  "Stayner (Canada)",
  "Steinbach (Canada)",
  "Stella (Canada)",
  "Stellarton (Canada)",
  "Stephenville (Canada)",
  "Stephenville Crossing (Canada)",
  "Stettler (Canada)",
  "Stevensville (Canada)",
  "Stewiacke (Canada)",
  "Stirling (Canada)",
  "Stittsville (Canada)",
  "Stoneham (Canada)",
  "Stonewall (Canada)",
  "Stoney Creek (Canada)",
  "Stony Mountain (Canada)",
  "Stony Plain (Canada)",
  "Stouffville (Canada)",
  "Stoughton (Canada)",
  "Strasbourg (Canada)",
  "Stratford (Canada)",
  "Strathmore (Canada)",
  "Strathroy (Canada)",
  "Streetsville (Canada)",
  "Sturgeon Falls (Canada)",
  "Sturgis (Canada)",
  "Summerland (Canada)",
  "Summerside (Canada)",
  "Sunderland (Canada)",
  "Sundridge (Canada)",
  "Surrey (Canada)",
  "Sussex (Canada)",
  "Sutton (Canada)",
  "Swan River (Canada)",
  "Swastika (Canada)",
  "Swift Current (Canada)",
  "Sydenham (Canada)",
  "Sydney (Canada)",
  "Sydney Mines (Canada)",
  "Sylvan Lake (Canada)",
  "Taber (Canada)",
  "Tadoussac (Canada)",
  "Tahsis (Canada)",
  "Takla Landing (Canada)",
  "Tappen (Canada)",
  "Taschereau (Canada)",
  "Tasiujaq (Canada)",
  "Tatamagouche (Canada)",
  "Tavistock (Canada)",
  "Taylor (Canada)",
  "Tecumseh (Canada)",
  "Teeswater (Canada)",
  "Temiscaming (Canada)",
  "Tennion (Canada)",
  "Terrace (Canada)",
  "Terrace Bay (Canada)",
  "Terre Noire (Canada)",
  "Terrebonne (Canada)",
  "Teulon (Canada)",
  "Thamesford (Canada)",
  "Thamesville (Canada)",
  "The Pas (Canada)",
  "Thedford (Canada)",
  "Theodore (Canada)",
  "Theresa (Canada)",
  "Thetford-Mines (Canada)",
  "Thompson (Canada)",
  "Thorhild (Canada)",
  "Thornbury (Canada)",
  "Thorndale (Canada)",
  "Thornhill (Canada)",
  "Thorold (Canada)",
  "Three Hills (Canada)",
  "Three Mile Plains (Canada)",
  "Thunder Bay (Canada)",
  "Thurso (Canada)",
  "Tilbury (Canada)",
  "Tilley (Canada)",
  "Tillsonburg (Canada)",
  "Timberlea (Canada)",
  "Timmins (Canada)",
  "Tisdale (Canada)",
  "Tlell (Canada)",
  "Tofield (Canada)",
  "Tofino (Canada)",
  "Tompkins (Canada)",
  "Torbay (Canada)",
  "Toronto (Canada)",
  "Torrington (Canada)",
  "Tottenham (Canada)",
  "Tracadie (Canada)",
  "Trail (Canada)",
  "Treherne (Canada)",
  "Trenton (Canada)",
  "Tring-Jonction (Canada)",
  "Trochu (Canada)",
  "Trois Pistoles (Canada)",
  "Trout Creek (Canada)",
  "Trout River (Canada)",
  "Troy (Canada)",
  "Truro (Canada)",
  "Tsawwassen (Canada)",
  "Tuktoyaktuk (Canada)",
  "Tumbler Ridge (Canada)",
  "Turner Valley (Canada)",
  "Turtleford (Canada)",
  "Tusket (Canada)",
  "Tweed (Canada)",
  "Twillingate (Canada)",
  "Two Hills (Canada)",
  "Tyndall (Canada)",
  "Ucluelet (Canada)",
  "Union Bay (Canada)",
  "Unionville (Canada)",
  "Unity (Canada)",
  "Upper Island Cove (Canada)",
  "Upton (Canada)",
  "Utterson (Canada)",
  "Uxbridge (Canada)",
  "Val-Brillant (Canada)",
  "Val-David (Canada)",
  "Val-des-Monts (Canada)",
  "Val-Morin (Canada)",
  "Valcourt (Canada)",
  "Valemount (Canada)",
  "Vallee-Jonction (Canada)",
  "Valley (Canada)",
  "Valleyview (Canada)",
  "Van Anda (Canada)",
  "Vancouver (Canada)",
  "Vanderhoof (Canada)",
  "Vankleek Hill (Canada)",
  "Varennes (Canada)",
  "Vaudreuil-Dorion (Canada)",
  "Vaughan (Canada)",
  "Vauxhall (Canada)",
  "Vegreville (Canada)",
  "Vercheres (Canada)",
  "Verdun (Canada)",
  "Vermilion (Canada)",
  "Verner (Canada)",
  "Vernon (Canada)",
  "Vernon Bridge (Canada)",
  "Victoria (Canada)",
  "Victoria Cove (Canada)",
  "Victoria Harbour (Canada)",
  "Victoriaville (Canada)",
  "Vieux-Saint-Laurent (Canada)",
  "Viking (Canada)",
  "Villa Marie (Canada)",
  "Ville-Marie (Canada)",
  "Vineland Station (Canada)",
  "Virden (Canada)",
  "Viscount (Canada)",
  "Vonda (Canada)",
  "Vulcan (Canada)",
  "Wabana (Canada)",
  "Wabigoon (Canada)",
  "Wabush (Canada)",
  "Wadena (Canada)",
  "Wainfleet (Canada)",
  "Wainwright (Canada)",
  "Wakaw (Canada)",
  "Wakefield (Canada)",
  "Waldeck (Canada)",
  "Waldheim (Canada)",
  "Walkerton (Canada)",
  "Wallace (Canada)",
  "Wallaceburg (Canada)",
  "Warkworth (Canada)",
  "Warman (Canada)",
  "Warner (Canada)",
  "Warsaw (Canada)",
  "Warwick (Canada)",
  "Wasaga Beach (Canada)",
  "Washago (Canada)",
  "Waterdown (Canada)",
  "Waterford (Canada)",
  "Waterloo (Canada)",
  "Waterville (Canada)",
  "Watford (Canada)",
  "Watrous (Canada)",
  "Watson (Canada)",
  "Watson Lake (Canada)",
  "Waubaushene (Canada)",
  "Wawa (Canada)",
  "Waweig (Canada)",
  "Wawota (Canada)",
  "Weedon (Canada)",
  "Weir (Canada)",
  "Weldon (Canada)",
  "Welland (Canada)",
  "Wellesley (Canada)",
  "Wellington (Canada)",
  "Wembley (Canada)",
  "Wemindji (Canada)",
  "West Bay (Canada)",
  "West Chezzetcook (Canada)",
  "West Hill (Canada)",
  "West Lake (Canada)",
  "West Osgoode (Canada)",
  "Westbank (Canada)",
  "Western Bay (Canada)",
  "Westlock (Canada)",
  "Westmount (Canada)",
  "Weston (Canada)",
  "Westport (Canada)",
  "Westree (Canada)",
  "Westville (Canada)",
  "Wetaskiwin (Canada)",
  "Weyburn (Canada)",
  "Wheatley (Canada)",
  "Whistler Village (Canada)",
  "Whitby (Canada)",
  "White City (Canada)",
  "White Fox (Canada)",
  "White River (Canada)",
  "White Rock (Canada)",
  "Whitecourt (Canada)",
  "Whitehorse (Canada)",
  "Whitemouth (Canada)",
  "Whites Lake (Canada)",
  "Whitney (Canada)",
  "Whycocomagh (Canada)",
  "Wiarton (Canada)",
  "Wickham (Canada)",
  "Wilcox (Canada)",
  "Wildwood (Canada)",
  "Wilkie (Canada)",
  "Williams Lake (Canada)",
  "Williamsburg (Canada)",
  "Willingdon (Canada)",
  "Willow Bunch (Canada)",
  "Willowdale (Canada)",
  "Willowvale (Canada)",
  "Winchester (Canada)",
  "Windermere (Canada)",
  "Windham Centre (Canada)",
  "Windsor (Canada)",
  "Winfield (Canada)",
  "Wingham (Canada)",
  "Winkler (Canada)",
  "Winnipeg (Canada)",
  "Winnipegosis (Canada)",
  "Wiseton (Canada)",
  "Wishart (Canada)",
  "Witless Bay (Canada)",
  "Wittenburg (Canada)",
  "Wolfville (Canada)",
  "Wolseley (Canada)",
  "Wood Mountain (Canada)",
  "Woodbridge (Canada)",
  "Woodlands (Canada)",
  "Woodridge (Canada)",
  "Woodstock (Canada)",
  "Worsley (Canada)",
  "Wrentham (Canada)",
  "Wright-Gracefield-Northfield (Canada)",
  "Wymark (Canada)",
  "Wynyard (Canada)",
  "Wyoming (Canada)",
  "Yahk (Canada)",
  "Yamachiche (Canada)",
  "Yamaska (Canada)",
  "Yarker (Canada)",
  "Yarmouth (Canada)",
  "Yellow Grass (Canada)",
  "Yellowknife (Canada)",
  "York (Canada)",
  "Yorkton (Canada)",
  "Youbou (Canada)",
  "Young (Canada)",
  "Youngstown (Canada)",
  "Cayman Islands",
  "Bodden Town (Cayman Islands)",
  "Coral Gables (Cayman Islands)",
  "George Town (Cayman Islands)",
  "Newlands (Cayman Islands)",
  "Savannah (Cayman Islands)",
  "Spot Bay (Cayman Islands)",
  "West Bay (Cayman Islands)",
  "Chile",
  "Algarrobo (Chile)",
  "Angol (Chile)",
  "Antofagasta (Chile)",
  "Apoquindo (Chile)",
  "Arauco (Chile)",
  "Arica (Chile)",
  "Buin (Chile)",
  "Bulnes (Chile)",
  "Calama (Chile)",
  "Caldera (Chile)",
  "Castro (Chile)",
  "Catemu (Chile)",
  "Centro (Chile)",
  "Cerrillos de Tamaya (Chile)",
  "Chicureo Abajo (Chile)",
  "Chiguayante (Chile)",
  "Chillan (Chile)",
  "Chillan Viejo (Chile)",
  "Chimbarongo (Chile)",
  "Chonchi (Chile)",
  "Chuquicamata (Chile)",
  "Coihaique (Chile)",
  "Colina (Chile)",
  "Collipulli (Chile)",
  "Concon (Chile)",
  "Constitucion (Chile)",
  "Coquimbo (Chile)",
  "Coronel (Chile)",
  "Cunco (Chile)",
  "Curacavi (Chile)",
  "Dalcahue (Chile)",
  "El Arco (Chile)",
  "El Bosque (Chile)",
  "El Monte (Chile)",
  "El Quisco (Chile)",
  "El Salto (Chile)",
  "El Salvador (Chile)",
  "El Valle (Chile)",
  "Fresia (Chile)",
  "Galvarino (Chile)",
  "Graneros (Chile)",
  "Hanga Roa (Chile)",
  "Iquique (Chile)",
  "La Calera (Chile)",
  "La Cisterna (Chile)",
  "La Granja (Chile)",
  "La Laja (Chile)",
  "La Ligua (Chile)",
  "La Reina (Chile)",
  "La Serena (Chile)",
  "La Union (Chile)",
  "Lampa (Chile)",
  "Las Condes (Chile)",
  "Lautaro (Chile)",
  "Llanquihue (Chile)",
  "Lo Barnechea (Chile)",
  "Lolol (Chile)",
  "Los Andes (Chile)",
  "Los Angeles (Chile)",
  "Machali (Chile)",
  "Macul (Chile)",
  "Maipu (Chile)",
  "Maquina Atacama (Chile)",
  "Maule (Chile)",
  "Mejillones (Chile)",
  "Melipilla (Chile)",
  "Miraflores (Chile)",
  "Molina (Chile)",
  "Monte Aguila (Chile)",
  "Nacimiento (Chile)",
  "Nogales (Chile)",
  "Nunoa (Chile)",
  "Olmue (Chile)",
  "Osorno (Chile)",
  "Ovalle (Chile)",
  "Paillaco (Chile)",
  "Penablanca (Chile)",
  "Penaflor (Chile)",
  "Port Montt (Chile)",
  "Providencia (Chile)",
  "Puchuncavi (Chile)",
  "Pucon (Chile)",
  "Puente Alto (Chile)",
  "Puerto Aisen (Chile)",
  "Puerto Natales (Chile)",
  "Puerto Varas (Chile)",
  "Punta Arenas (Chile)",
  "Puren (Chile)",
  "Purranque (Chile)",
  "Quellon (Chile)",
  "Quemchi (Chile)",
  "Quilicura (Chile)",
  "Quillota (Chile)",
  "Rancagua (Chile)",
  "Recoleta (Chile)",
  "Renaca (Chile)",
  "Renca (Chile)",
  "Rengo (Chile)",
  "Rinconada (Chile)",
  "Rio Bueno (Chile)",
  "San Antonio (Chile)",
  "San Bernardo (Chile)",
  "San Carlos (Chile)",
  "San Felipe (Chile)",
  "San Fernando (Chile)",
  "San Javier (Chile)",
  "San Martin (Chile)",
  "San Pedro (Chile)",
  "Santa Barbara (Chile)",
  "Santiago (Chile)",
  "Talagante (Chile)",
  "Talca (Chile)",
  "Talcahuano (Chile)",
  "Tarapaca (Chile)",
  "Temuco (Chile)",
  "Tome (Chile)",
  "Valdivia (Chile)",
  "Vallenar (Chile)",
  "Villa Alemana (Chile)",
  "Villarrica (Chile)",
  "Vitacura (Chile)",
  "China",
  "Aishang (China)",
  "Aizhou (China)",
  "Aksu (China)",
  "Anbang (China)",
  "Anbu (China)",
  "Ancheng (China)",
  "Anda (China)",
  "Andong (China)",
  "Angu (China)",
  "Anguo (China)",
  "Anhu (China)",
  "Anji (China)",
  "Anjiang (China)",
  "Ankang (China)",
  "Anke (China)",
  "Anqing (China)",
  "Anqiu (China)",
  "Anshan (China)",
  "Anshun (China)",
  "Ansu (China)",
  "Anxi (China)",
  "Anyang (China)",
  "Anying (China)",
  "Anzhou (China)",
  "Anzi (China)",
  "Aojiang (China)",
  "Aoma (China)",
  "Bagong (China)",
  "Baicheng (China)",
  "Baidu (China)",
  "Baihua (China)",
  "Baile (China)",
  "Bailicun (China)",
  "Baipo (China)",
  "Baise City (China)",
  "Baisha (China)",
  "Baishan (China)",
  "Baishi (China)",
  "Baitang (China)",
  "Baixiang (China)",
  "Baixin (China)",
  "Baiyashi (China)",
  "Baiyi (China)",
  "Baiyin (China)",
  "Baiyu (China)",
  "Baiyun (China)",
  "Baizhang (China)",
  "Baliqiao (China)",
  "Bamao (China)",
  "Bangke (China)",
  "Bangtou (China)",
  "Bantou (China)",
  "Banxi (China)",
  "Banyou (China)",
  "Baocheng (China)",
  "Baodi (China)",
  "Baodian (China)",
  "Baoding (China)",
  "Baojiang (China)",
  "Baoluo (China)",
  "Baoqing (China)",
  "Baoshan (China)",
  "Baotao (China)",
  "Baotian (China)",
  "Baotou (China)",
  "Baoxing (China)",
  "Baoyu (China)",
  "Base (China)",
  "Baying (China)",
  "Bazhou (China)",
  "Baziqiang (China)",
  "Beichan (China)",
  "Beidou (China)",
  "Beihai (China)",
  "Beijie (China)",
  "Beijing (China)",
  "Beilin (China)",
  "Beilun (China)",
  "Beishan (China)",
  "Beixi (China)",
  "Beixiao (China)",
  "Beiying (China)",
  "Beiyuan (China)",
  "Bengbu (China)",
  "Benxi (China)",
  "Bianzhuang (China)",
  "Bibo (China)",
  "Bijie (China)",
  "Binhe (China)",
  "Binjiang (China)",
  "Binzhou (China)",
  "Biyang (China)",
  "Bobai (China)",
  "Bohai (China)",
  "Boji (China)",
  "Bole (China)",
  "Boli (China)",
  "Boshan (China)",
  "Boxing (China)",
  "Bozhou (China)",
  "Buxin (China)",
  "Caijing (China)",
  "Caishen (China)",
  "Caiyuan (China)",
  "Cangnan (China)",
  "Cangshang (China)",
  "Cangzhou (China)",
  "Caopu (China)",
  "Caoxi (China)",
  "Caozhai (China)",
  "Cengang (China)",
  "Cenyang (China)",
  "Cezi (China)",
  "Chadianzi (China)",
  "Chaicun (China)",
  "Chaigoubu (China)",
  "Chamdo (China)",
  "Changchao (China)",
  "Changchun (China)",
  "Changde (China)",
  "Changfeng (China)",
  "Changhe (China)",
  "Changjian (China)",
  "Changjiang (China)",
  "Changle (China)",
  "Changning (China)",
  "Changping (China)",
  "Changqing (China)",
  "Changsha (China)",
  "Changshan (China)",
  "Changsheng (China)",
  "Changshu City (China)",
  "Changting (China)",
  "Changtu (China)",
  "Changxiacun (China)",
  "Changxin (China)",
  "Changzheng (China)",
  "Changzhi (China)",
  "Changzhong (China)",
  "Changzhou (China)",
  "Chaohu (China)",
  "Chaoyang (China)",
  "Chaozhou (China)",
  "Chen Xian (China)",
  "Chendu (China)",
  "Chengbei (China)",
  "Chengdong (China)",
  "Chengdu (China)",
  "Chengfeng (China)",
  "Chengguan (China)",
  "Chenghua (China)",
  "Chengjian (China)",
  "Chengmai (China)",
  "Chengnan (China)",
  "Chengqiao (China)",
  "Chenguang (China)",
  "Chengxi (China)",
  "Chengxian Chengguanzhen (China)",
  "Chengxiang (China)",
  "Chengyang (China)",
  "Chengzhong (China)",
  "Chenyang (China)",
  "Chenzhou (China)",
  "Chifeng (China)",
  "Chiping (China)",
  "Chizhou (China)",
  "Chongqing (China)",
  "Chongwen (China)",
  "Chongxian (China)",
  "Choucheng (China)",
  "Chuansha (China)",
  "Chum (China)",
  "Chunhua (China)",
  "Chunjiang (China)",
  "Chuxiong (China)",
  "Chuzhou (China)",
  "Cicheng (China)",
  "Cili (China)",
  "Citai (China)",
  "Cixi (China)",
  "Cuibei (China)",
  "Cuiyuan (China)",
  "Cuizhu (China)",
  "Cuntou (China)",
  "Dabaizhuang (China)",
  "Dabao (China)",
  "Dabu (China)",
  "Dachen (China)",
  "Dachuan (China)",
  "Dagang (China)",
  "Dahai (China)",
  "Dahuaishu (China)",
  "Daidong (China)",
  "Daishan (China)",
  "Daixi (China)",
  "Daizhong (China)",
  "Daji (China)",
  "Dajie (China)",
  "Daju (China)",
  "Dali (China)",
  "Dalian (China)",
  "Dampu (China)",
  "Dancheng (China)",
  "Dandong (China)",
  "Danfeng (China)",
  "Dangshan (China)",
  "Dangyang (China)",
  "Danjiang (China)",
  "Danjiangkou (China)",
  "Danyang (China)",
  "Danzao (China)",
  "Danzhou (China)",
  "Daojiang (China)",
  "Daotou (China)",
  "Daoxu (China)",
  "Daqiao (China)",
  "Daqing (China)",
  "Daqiuzhuang (China)",
  "Dasha (China)",
  "Dashiqiao (China)",
  "Dashun (China)",
  "Datang (China)",
  "Datong (China)",
  "Dawa (China)",
  "Dawang (China)",
  "Dawukou (China)",
  "Daxie (China)",
  "Daxing (China)",
  "Dayan (China)",
  "Dayang (China)",
  "Daye (China)",
  "Dayun (China)",
  "Dazhong (China)",
  "Dazhou (China)",
  "Dazu (China)",
  "Defeng (China)",
  "Dehui (China)",
  "Dengbu (China)",
  "Dengzhou (China)",
  "Deqing (China)",
  "Deyang (China)",
  "Deyuan (China)",
  "Deyue (China)",
  "Dezhou (China)",
  "Diankou (China)",
  "Dianzhong (China)",
  "Dianzi (China)",
  "Digang (China)",
  "Dingcheng (China)",
  "Dinghai (China)",
  "Dingqiao (China)",
  "Dingtao (China)",
  "Dingxi (China)",
  "Dingzha (China)",
  "Dingzhou (China)",
  "Dipu (China)",
  "Dongchang (China)",
  "Dongcheng (China)",
  "Dongcun (China)",
  "Dongdu (China)",
  "Dongfang (China)",
  "Dongfeng (China)",
  "Dongguan (China)",
  "Donghai (China)",
  "Donghe (China)",
  "Donghong (China)",
  "Donghuxu (China)",
  "Dongjiaying (China)",
  "Dongli (China)",
  "Donglin (China)",
  "Dongliu (China)",
  "Dongning (China)",
  "Dongshen (China)",
  "Dongsheng (China)",
  "Dongshi (China)",
  "Dongtou (China)",
  "Dongxi (China)",
  "Dongxiao (China)",
  "Dongxin (China)",
  "Dongxing (China)",
  "Dongyang (China)",
  "Dongying (China)",
  "Doujiang (China)",
  "Doumen (China)",
  "Dujiacun (China)",
  "Dungdo (China)",
  "Duoba (China)",
  "Dushu (China)",
  "Duwei (China)",
  "Duyun (China)",
  "Edong (China)",
  "Ejia (China)",
  "Enshi (China)",
  "Erqi (China)",
  "Eshan (China)",
  "Ezhou (China)",
  "Fangqiao (China)",
  "Fangshan (China)",
  "Fangxian Chengguanzhen (China)",
  "Fangzhuang (China)",
  "Fangzi (China)",
  "Fanjing (China)",
  "Fanzhong (China)",
  "Feihong (China)",
  "Feilong (China)",
  "Feixian (China)",
  "Feiyun (China)",
  "Fendou (China)",
  "Fengcheng (China)",
  "Fenggang (China)",
  "Fengjiang (China)",
  "Fenglin (China)",
  "Fengling (China)",
  "Fengming (China)",
  "Fengnan (China)",
  "Fengqi (China)",
  "Fengqiao (China)",
  "Fengqing (China)",
  "Fengshan (China)",
  "Fengshang (China)",
  "Fengshun (China)",
  "Fengtai (China)",
  "Fengting (China)",
  "Fengxian (China)",
  "Fengxiang (China)",
  "Fengzhou (China)",
  "Fenshang (China)",
  "Fenyang (China)",
  "Foshan (China)",
  "Fotang (China)",
  "Fuchun (China)",
  "Fuchunjiang (China)",
  "Fudao (China)",
  "Fuding (China)",
  "Fugu (China)",
  "Fuhao (China)",
  "Fuling (China)",
  "Fumin (China)",
  "Fuqiang (China)",
  "Furong (China)",
  "Fusheng (China)",
  "Fushun (China)",
  "Futian (China)",
  "Fuxin (China)",
  "Fuyang (China)",
  "Fuzhou (China)",
  "Gaiwei (China)",
  "Gaiyu (China)",
  "Gamlung (China)",
  "Gangcun (China)",
  "Ganjiang (China)",
  "Gannan (China)",
  "Ganyao (China)",
  "Ganzhou (China)",
  "Gaobeidian (China)",
  "Gaobu (China)",
  "Gaocheng (China)",
  "Gaoji (China)",
  "Gaoping (China)",
  "Gaoqiao (China)",
  "Gaotang (China)",
  "Gaoting (China)",
  "Gaoxin (China)",
  "Gaozhao (China)",
  "Gaozhou (China)",
  "Gejiu (China)",
  "Gongchang (China)",
  "Gongjiang (China)",
  "Gongjiao (China)",
  "Gongli (China)",
  "Gongyi (China)",
  "Gongyuan (China)",
  "Goushan (China)",
  "Gouzhuang (China)",
  "Guali (China)",
  "Guancheng (China)",
  "Guangchang (China)",
  "Guangdong (China)",
  "Guangming (China)",
  "Guangtai (China)",
  "Guangtong (China)",
  "Guangxi (China)",
  "Guangyuan (China)",
  "Guangzhou (China)",
  "Guanli (China)",
  "Guanqiao (China)",
  "Guantian (China)",
  "Gucheng (China)",
  "Guigang (China)",
  "Guili (China)",
  "Guilin (China)",
  "Guixi (China)",
  "Guiyang (China)",
  "Guiyuan (China)",
  "Guizhoumanzuxiang (China)",
  "Gujiang (China)",
  "Gujiao (China)",
  "Guli (China)",
  "Gunan (China)",
  "Guodian (China)",
  "Guohe (China)",
  "Guoji (China)",
  "Guoshang (China)",
  "Guozhen (China)",
  "Gushan (China)",
  "Gushi (China)",
  "Guxiang (China)",
  "Guyong (China)",
  "Guyuan (China)",
  "Gyari (China)",
  "Haibei (China)",
  "Haicheng (China)",
  "Haidian (China)",
  "Haifu (China)",
  "Haihong (China)",
  "Haijing (China)",
  "Haikou (China)",
  "Hailar (China)",
  "Hailin (China)",
  "Hainan (China)",
  "Haitang (China)",
  "Haixing (China)",
  "Haiyang (China)",
  "Haiyu (China)",
  "Haizhou (China)",
  "Handan (China)",
  "Hangbu (China)",
  "Hangong (China)",
  "Hangu (China)",
  "Hangzhou (China)",
  "Hanjiang (China)",
  "Hanshan (China)",
  "Hanshang (China)",
  "Hanting (China)",
  "Hanzhong (China)",
  "Haocheng (China)",
  "Haozhuang (China)",
  "Harbin (China)",
  "Hebei (China)",
  "Hebi (China)",
  "Hecheng (China)",
  "Hechi (China)",
  "Hechun (China)",
  "Hecun (China)",
  "Hedong (China)",
  "Hefei (China)",
  "Hegang (China)",
  "Heibei (China)",
  "Heihe (China)",
  "Hejiang (China)",
  "Hejin (China)",
  "Hekou (China)",
  "Heliang (China)",
  "Hengdian (China)",
  "Henghe (China)",
  "Hengjie (China)",
  "Hengshui (China)",
  "Hengyang (China)",
  "Hengzhou (China)",
  "Heping (China)",
  "Heshun (China)",
  "Hetao (China)",
  "Hetian (China)",
  "Hexi (China)",
  "Hexun (China)",
  "Heyi (China)",
  "Heyuan (China)",
  "Heze (China)",
  "Hezhou (China)",
  "Hezhu (China)",
  "Hezuo (China)",
  "Hohhot (China)",
  "Hongchang (China)",
  "Honglin (China)",
  "Hongling (China)",
  "Hongpan (China)",
  "Hongqiao (China)",
  "Hongshan (China)",
  "Hongshun (China)",
  "Hongtang (China)",
  "Hongwei (China)",
  "Hongxi (China)",
  "Hongyan (China)",
  "Hongyangshu (China)",
  "Hongyuan (China)",
  "Hongyun (China)",
  "Houxiangcheng (China)",
  "Houzhai (China)",
  "Huabei (China)",
  "Huacheng (China)",
  "Huadian (China)",
  "Huadong (China)",
  "Huafeng (China)",
  "Huagong (China)",
  "Huagongchang (China)",
  "Huaibei (China)",
  "Huaihe (China)",
  "Huaihua (China)",
  "Huainan (China)",
  "Huairou (China)",
  "Huajiashan (China)",
  "Huajie (China)",
  "Huali (China)",
  "Hualin (China)",
  "Hualong (China)",
  "Huamei (China)",
  "Huanan (China)",
  "Huangbao (China)",
  "Huangdao (China)",
  "Huangdu (China)",
  "Huanggang (China)",
  "Huanghou (China)",
  "Huanghu (China)",
  "Huanghua (China)",
  "Huangjia (China)",
  "Huangjue (China)",
  "Huangshan (China)",
  "Huangshi (China)",
  "Huangxuan (China)",
  "Huangyan (China)",
  "Huangyuan Chengguanzhen (China)",
  "Huangze (China)",
  "Huangzhai (China)",
  "Huangzhou (China)",
  "Huanzhen (China)",
  "Huaqiang (China)",
  "Huaqiao (China)",
  "Huashan (China)",
  "Huatan (China)",
  "Huating (China)",
  "Huawei (China)",
  "Huaxia (China)",
  "Huaxin (China)",
  "Huaxing (China)",
  "Huayang (China)",
  "Huaying (China)",
  "Huayuan (China)",
  "Huayue (China)",
  "Huayuji (China)",
  "Huazhong (China)",
  "Huazhou (China)",
  "Huge (China)",
  "Huicheng (China)",
  "Huigang (China)",
  "Huilongba (China)",
  "Huimin (China)",
  "Huixin (China)",
  "Huiyang (China)",
  "Huizhou (China)",
  "Hulan (China)",
  "Huling (China)",
  "Hulu (China)",
  "Huludao (China)",
  "Huoshan (China)",
  "Huozhou (China)",
  "Hupi (China)",
  "Huquan (China)",
  "Hushan (China)",
  "Huzhen (China)",
  "Huzhou (China)",
  "Jiadi (China)",
  "Jiading (China)",
  "Jiafu (China)",
  "Jiahao (China)",
  "Jiahe Chengguanzhen (China)",
  "Jiahu (China)",
  "Jialing (China)",
  "Jiamusi (China)",
  "Jianci (China)",
  "Jiangbei (China)",
  "Jiangchuanlu (China)",
  "Jiangdong (China)",
  "Jiangguantun (China)",
  "Jiangjia (China)",
  "Jiangjin Qu (China)",
  "Jiangkou (China)",
  "Jiangmen (China)",
  "Jiangnan (China)",
  "Jiangshan (China)",
  "Jiangtian (China)",
  "Jiangwan (China)",
  "Jiangwang (China)",
  "Jiangyan (China)",
  "Jiangyin (China)",
  "Jiangyou (China)",
  "Jianning (China)",
  "Jianxing (China)",
  "Jianyang (China)",
  "Jianyuan (China)",
  "Jianzhong (China)",
  "Jiaojiang (China)",
  "Jiaokou (China)",
  "Jiaotong (China)",
  "Jiaotou (China)",
  "Jiaowei (China)",
  "Jiaoyu (China)",
  "Jiaoyuan (China)",
  "Jiaoyun (China)",
  "Jiaozhou (China)",
  "Jiaozuo (China)",
  "Jiaqin (China)",
  "Jiaxin (China)",
  "Jiaxing (China)",
  "Jiaying (China)",
  "Jiayuan (China)",
  "Jiayue (China)",
  "Jiazhuang (China)",
  "Jicheng (China)",
  "Jiedong (China)",
  "Jiefang (China)",
  "Jiehe (China)",
  "Jielong (China)",
  "Jieshou (China)",
  "Jiexi (China)",
  "Jiexiu (China)",
  "Jieyang (China)",
  "Jifeng (China)",
  "Jiguang (China)",
  "Jilin City (China)",
  "Jimo (China)",
  "Jin Jiang (China)",
  "Jinan (China)",
  "Jinbi (China)",
  "Jincheng (China)",
  "Jinchuan (China)",
  "Jinci (China)",
  "Jinda (China)",
  "Jinde (China)",
  "Jindi (China)",
  "Jindian (China)",
  "Jinding (China)",
  "Jindou (China)",
  "Jindu (China)",
  "Jingbei (China)",
  "Jingcheng (China)",
  "Jingdezhen (China)",
  "Jingdong (China)",
  "Jingdu (China)",
  "Jingfeng (China)",
  "Jinghai (China)",
  "Jinghe (China)",
  "Jinghong (China)",
  "Jinghu (China)",
  "Jinghua (China)",
  "Jingjiang (China)",
  "Jinglian (China)",
  "Jinglong (China)",
  "Jingmen (China)",
  "Jingning Chengguanzhen (China)",
  "Jingshan (China)",
  "Jingtian (China)",
  "Jinguang (China)",
  "Jingxi (China)",
  "Jingyuan (China)",
  "Jingzhong (China)",
  "Jingzhou (China)",
  "Jinhai (China)",
  "Jinhe (China)",
  "Jinhong (China)",
  "Jinhu (China)",
  "Jinhua (China)",
  "Jining (China)",
  "Jinjiang (China)",
  "Jinli (China)",
  "Jinling (China)",
  "Jinlong (China)",
  "Jinlu (China)",
  "Jinlun (China)",
  "Jinma (China)",
  "Jinmao (China)",
  "Jinmen (China)",
  "Jinming (China)",
  "Jinnan (China)",
  "Jinpan (China)",
  "Jinpu (China)",
  "Jinqiao (China)",
  "Jinqingzhen (China)",
  "Jinsha (China)",
  "Jinshadi (China)",
  "Jinshan (China)",
  "Jinshi (China)",
  "Jintan (China)",
  "Jintian (China)",
  "Jinwo (China)",
  "Jinxiangzhen (China)",
  "Jinxing (China)",
  "Jinyang (China)",
  "Jinyi (China)",
  "Jinyin (China)",
  "Jinying (China)",
  "Jinyuan (China)",
  "Jinyun (China)",
  "Jinzao (China)",
  "Jinzhong (China)",
  "Jinzhou (China)",
  "Jishou (China)",
  "Jishu (China)",
  "Jiuba (China)",
  "Jiuguan (China)",
  "Jiuzong (China)",
  "Jixi (China)",
  "Jiya (China)",
  "Jiyang (China)",
  "Jizhou (China)",
  "Jondag (China)",
  "Juchuan (China)",
  "Juhua (China)",
  "Julong (China)",
  "Jundu (China)",
  "Junlin (China)",
  "Junping (China)",
  "Junpu (China)",
  "Kaifeng (China)",
  "Kaihua (China)",
  "Kaili (China)",
  "Kaiyuan (China)",
  "Kaiyun (China)",
  "Kandun (China)",
  "Kang Xian (China)",
  "Kangcun (China)",
  "Kangda (China)",
  "Karamay (China)",
  "Kashgar (China)",
  "Kecheng (China)",
  "Keqiao (China)",
  "Keshan (China)",
  "Korla (China)",
  "Kouqian (China)",
  "Kouquan (China)",
  "Kuangyan (China)",
  "Kuicheng (China)",
  "Kuiju (China)",
  "Kuishan (China)",
  "Kumul (China)",
  "Kunlun (China)",
  "Kunming (China)",
  "Kunshan (China)",
  "Kunyang (China)",
  "Kuytun (China)",
  "Laibin (China)",
  "Laiwu (China)",
  "Laiyi (China)",
  "Lake (China)",
  "Langfang (China)",
  "Langli (China)",
  "Langzhong (China)",
  "Lanhai (China)",
  "Lanjiang (China)",
  "Lanping (China)",
  "Lantang (China)",
  "Lanxi (China)",
  "Lanzhou (China)",
  "Laohekou (China)",
  "Laonian (China)",
  "Lecheng (China)",
  "Ledong (China)",
  "Lengshuijiang (China)",
  "Leshan (China)",
  "Lhasa (China)",
  "Lianghu (China)",
  "Liangping (China)",
  "Liangzhu (China)",
  "Lianhua (China)",
  "Lianjiang (China)",
  "Liannan (China)",
  "Lianping (China)",
  "Lianshan (China)",
  "Lianshi (China)",
  "Lianshui (China)",
  "Lianyuan (China)",
  "Lianyungang (China)",
  "Liaocheng (China)",
  "Liaoyang (China)",
  "Liaoyuan (China)",
  "Libang (China)",
  "Licheng (China)",
  "Lida (China)",
  "Lidong (China)",
  "Lidu (China)",
  "Lihou (China)",
  "Lijiang (China)",
  "Lijing (China)",
  "Linbei (China)",
  "Lincang (China)",
  "Lincheng (China)",
  "Lindian (China)",
  "Linfen (China)",
  "Lingang (China)",
  "Lingcheng (China)",
  "Lingdong (China)",
  "Linghu (China)",
  "Lingnga (China)",
  "Lingshi (China)",
  "Lingshui (China)",
  "Lingxi (China)",
  "Lingzhi (China)",
  "Linhai (China)",
  "Linjiang (China)",
  "Linkou (China)",
  "Linnan (China)",
  "Linping (China)",
  "Linqi (China)",
  "Linqing (China)",
  "Linqu (China)",
  "Lintong (China)",
  "Linxia Chengguanzhen (China)",
  "Linyi (China)",
  "Linze (China)",
  "Linzhou (China)",
  "Lishang (China)",
  "Lishu (China)",
  "Lishui (China)",
  "Liu`an (China)",
  "Liucheng (China)",
  "Liudao (China)",
  "Liudong (China)",
  "Liuhe (China)",
  "Liuheng (China)",
  "Liujian (China)",
  "Liujiang (China)",
  "Liulin (China)",
  "Liushi (China)",
  "Liuzhou (China)",
  "Liwu (China)",
  "Liyuan (China)",
  "Lize (China)",
  "Longchuan (China)",
  "Longdong (China)",
  "Longfeng (China)",
  "Longgang (China)",
  "Longgong (China)",
  "Longhu (China)",
  "Longhua (China)",
  "Longjian (China)",
  "Longjiang (China)",
  "Longjin (China)",
  "Longlin (China)",
  "Longmao (China)",
  "Longmen (China)",
  "Longnan (China)",
  "Longquan (China)",
  "Longshan (China)",
  "Longtai (China)",
  "Longteng (China)",
  "Longxiang (China)",
  "Longxin (China)",
  "Longxing (China)",
  "Longxun (China)",
  "Longyan (China)",
  "Longzhou (China)",
  "Loufan (China)",
  "Lucheng (China)",
  "Lugouqiao (China)",
  "Luhua (China)",
  "Lujiazhi (China)",
  "Lung (China)",
  "Luobu (China)",
  "Luocheng (China)",
  "Luofang (China)",
  "Luohe (China)",
  "Luohu (China)",
  "Luoling (China)",
  "Luomen (China)",
  "Luorong (China)",
  "Luoxi (China)",
  "Luoyang (China)",
  "Luoyuan (China)",
  "Luozhuang (China)",
  "Luqiao (China)",
  "Luquan (China)",
  "Lushang (China)",
  "Luwan (China)",
  "Luzhou (China)",
  "Macao (China)",
  "Maji (China)",
  "Majin (China)",
  "Maoba (China)",
  "Maodian (China)",
  "Maodun (China)",
  "Maoming (China)",
  "Maotai (China)",
  "Mapai (China)",
  "Mawei (China)",
  "Mayao (China)",
  "Mayu (China)",
  "Meicheng (China)",
  "Meijiang (China)",
  "Meilian (China)",
  "Meilin (China)",
  "Meishan (China)",
  "Meixi (China)",
  "Meiyu (China)",
  "Meiyuan (China)",
  "Meizhou (China)",
  "Mengshan (China)",
  "Mengyin (China)",
  "Mengzhou (China)",
  "Mengzi (China)",
  "Mianyang (China)",
  "Miaogao (China)",
  "Mingde (China)",
  "Mingdu (China)",
  "Mingjia (China)",
  "Mingjing (China)",
  "Mingshui (China)",
  "Mingxi (China)",
  "Mingxing (China)",
  "Mingzhou (China)",
  "Mingzhu (China)",
  "Minhang (China)",
  "Minjiang (China)",
  "Minjie (China)",
  "Minzhu (China)",
  "Minzu (China)",
  "Mishan (China)",
  "Miyun (China)",
  "Moushan (China)",
  "Mudanjiang (China)",
  "Muling (China)",
  "Nagqu (China)",
  "Name (China)",
  "Nanchang (China)",
  "Nanchong (China)",
  "Nanda (China)",
  "Nandu (China)",
  "Nanfang (China)",
  "Nanguo (China)",
  "Nangxian (China)",
  "Nanhai (China)",
  "Nanhan (China)",
  "Nanhe (China)",
  "Nanhu (China)",
  "Nanjiang (China)",
  "Nanjin (China)",
  "Nanjing (China)",
  "Nankai (China)",
  "Nanlong (China)",
  "Nanma (China)",
  "Nanmen (China)",
  "Nanning (China)",
  "Nanping (China)",
  "Nanshan (China)",
  "Nantang (China)",
  "Nantong (China)",
  "Nanxi (China)",
  "Nanxianglong (China)",
  "Nanxun (China)",
  "Nanyang (China)",
  "Nanying (China)",
  "Nanyuan (China)",
  "Nanzhao (China)",
  "Nanzhuang (China)",
  "Napo (China)",
  "Naxi Yaozuxiang (China)",
  "Neijiang (China)",
  "Nenjiang (China)",
  "Niaojiang (China)",
  "Ningbo (China)",
  "Ningde (China)",
  "Ninghai (China)",
  "Ninghe (China)",
  "Ningyi (China)",
  "Niulanshan (China)",
  "Niunaichang (China)",
  "Nongkeng (China)",
  "Nyingchi County (China)",
  "Oucheng (China)",
  "Oujiang (China)",
  "Panjin Shi (China)",
  "Panxi (China)",
  "Panzhihua (China)",
  "Pengcheng (China)",
  "Penglai (China)",
  "Pengshan (China)",
  "Pengyu (China)",
  "Pingchuan (China)",
  "Pingdingshan (China)",
  "Pinghu (China)",
  "Pingliang (China)",
  "Pinglu (China)",
  "Pingwang (China)",
  "Pingxiang (China)",
  "Pingyang (China)",
  "Pingyao County (China)",
  "Pingyi (China)",
  "Pingyin (China)",
  "Pingyuan (China)",
  "Pizhou (China)",
  "Potang (China)",
  "Pucheng (China)",
  "Pudong (China)",
  "Puning (China)",
  "Puqing (China)",
  "Putian (China)",
  "Putuo (China)",
  "Putuoshan (China)",
  "Puyang (China)",
  "Puyuan (China)",
  "Qiandao (China)",
  "Qiandaohu (China)",
  "Qianhai (China)",
  "Qianjiang (China)",
  "Qianjin (China)",
  "Qianling (China)",
  "Qianqing (China)",
  "Qianshan (China)",
  "Qiantan (China)",
  "Qiantang (China)",
  "Qianyu (China)",
  "Qiaodou (China)",
  "Qiaolian (China)",
  "Qiaoting (China)",
  "Qiaoxia (China)",
  "Qiaoyuan (China)",
  "Qiaozhen (China)",
  "Qidu (China)",
  "Qijiashan (China)",
  "Qike (China)",
  "Qingdao (China)",
  "Qingfeng Chengguanzhen (China)",
  "Qinggang (China)",
  "Qinghai (China)",
  "Qinghu (China)",
  "Qingma (China)",
  "Qingnian (China)",
  "Qingquan (China)",
  "Qingshan (China)",
  "Qingshuiwan (China)",
  "Qingtian (China)",
  "Qingtong (China)",
  "Qingxin (China)",
  "Qingyang (China)",
  "Qingyuan (China)",
  "Qingzhen (China)",
  "Qingzhou (China)",
  "Qinhuangdao (China)",
  "Qinjiang (China)",
  "Qinzhou (China)",
  "Qionghai (China)",
  "Qiongshan (China)",
  "Qiqihar (China)",
  "Qitai (China)",
  "Quanzhou (China)",
  "Qujiang (China)",
  "Qujing (China)",
  "Qushan (China)",
  "Quzhou (China)",
  "Renhe (China)",
  "Renhou (China)",
  "Renmin (China)",
  "Renqiu (China)",
  "Renrong (China)",
  "Rizhao (China)",
  "Rongjiang (China)",
  "Rongjin (China)",
  "Rongle (China)",
  "Rongshan (China)",
  "Rongsheng (China)",
  "Rongxian (China)",
  "Rongxing (China)",
  "Ruicheng (China)",
  "Ruifeng (China)",
  "Ruihu (China)",
  "Ruili (China)",
  "Ruiting (China)",
  "Ruixing (China)",
  "Ruiying (China)",
  "Rujia (China)",
  "Sailing (China)",
  "Sandu (China)",
  "Sanguan (China)",
  "Sanhe (China)",
  "Sanjiang (China)",
  "Sanjiangkou (China)",
  "Sanjiaocheng (China)",
  "Sanjiu (China)",
  "Sanlian (China)",
  "Sanmao (China)",
  "Sanmen (China)",
  "Sanmenxia (China)",
  "Sanming (China)",
  "Sanpailou (China)",
  "Santai (China)",
  "Santiaolou (China)",
  "Sanya (China)",
  "Sanyi (China)",
  "Sanyou (China)",
  "Sanyuanpu (China)",
  "Sanzhang (China)",
  "Shamen (China)",
  "Shancheng (China)",
  "Shangbao (China)",
  "Shangbu (China)",
  "Shangdong (China)",
  "Shangfang (China)",
  "Shanghai (China)",
  "Shanghu (China)",
  "Shangjiang (China)",
  "Shangluo (China)",
  "Shangma (China)",
  "Shangnan (China)",
  "Shangqiu (China)",
  "Shangrao (China)",
  "Shangsha (China)",
  "Shangsi (China)",
  "Shangtang (China)",
  "Shangwang (China)",
  "Shangwu (China)",
  "Shangxi (China)",
  "Shangye (China)",
  "Shangying (China)",
  "Shangzhang (China)",
  "Shangzhi (China)",
  "Shangzhou (China)",
  "Shanhe (China)",
  "Shanhu (China)",
  "Shanrui (China)",
  "Shanshui (China)",
  "Shantou (China)",
  "Shanwei (China)",
  "Shanzhuang (China)",
  "Shaoguan (China)",
  "Shaoguang (China)",
  "Shaowu (China)",
  "Shaoxing (China)",
  "Shashan (China)",
  "Shashi (China)",
  "Shecheng (China)",
  "Shejiazhuang (China)",
  "Shekou (China)",
  "Shengdu (China)",
  "Shengfang (China)",
  "Shengli (China)",
  "Shengping (China)",
  "Shengshan (China)",
  "Shenjiamen (China)",
  "Shenlong (China)",
  "Shennan (China)",
  "Shensha (China)",
  "Shenwan (China)",
  "Shenyan (China)",
  "Shenyang (China)",
  "Shenzhen (China)",
  "Shenzhou (China)",
  "Shibei (China)",
  "Shifan (China)",
  "Shifang (China)",
  "Shihezi (China)",
  "Shiji (China)",
  "Shijiazhuang (China)",
  "Shijing (China)",
  "Shijingxiang (China)",
  "Shiling (China)",
  "Shilou (China)",
  "Shima (China)",
  "Shinian (China)",
  "Shipu (China)",
  "Shiqi (China)",
  "Shiqiaopu (China)",
  "Shishi (China)",
  "Shixia (China)",
  "Shiyan (China)",
  "Shizheng (China)",
  "Shizhong (China)",
  "Shizhu (China)",
  "Shouchang (China)",
  "Shouchun (China)",
  "Shouguang (China)",
  "Shouyang (China)",
  "Shuangcheng (China)",
  "Shuanglin (China)",
  "Shuanglong (China)",
  "Shuangqiao (China)",
  "Shuangshipu (China)",
  "Shuangtang (China)",
  "Shuangxi (China)",
  "Shuangyashan (China)",
  "Shuguang (China)",
  "Shuhong (China)",
  "Shuigang (China)",
  "Shuiku (China)",
  "Shuitian (China)",
  "Shuitou (China)",
  "Shuiwu (China)",
  "Shule (China)",
  "Shuliang (China)",
  "Shunde (China)",
  "Shunshui (China)",
  "Shuozhou (China)",
  "Shuyi (China)",
  "Sichuan (China)",
  "Side (China)",
  "Siduxu (China)",
  "Sijiqing (China)",
  "Sili (China)",
  "Simao (China)",
  "Simcun (China)",
  "Simen (China)",
  "Siting (China)",
  "Siyang (China)",
  "Sizhou (China)",
  "Songling (China)",
  "Songyang (China)",
  "Songyuan (China)",
  "Suihua (China)",
  "Suining (China)",
  "Suixi (China)",
  "Suji (China)",
  "Sundun (China)",
  "Sungang (China)",
  "Suqian (China)",
  "Suxi (China)",
  "Suzhou (China)",
  "Suzhuang (China)",
  "Taicang (China)",
  "Taihe (China)",
  "Taikang (China)",
  "Tailai (China)",
  "Taili (China)",
  "Taimen (China)",
  "Taiping (China)",
  "Taipingyan (China)",
  "Taishan (China)",
  "Taixing (China)",
  "Taiyuan (China)",
  "Taizhou (China)",
  "Tancheng (China)",
  "Tang Xian (China)",
  "Tangbian (China)",
  "Tangchang (China)",
  "Tangcheng (China)",
  "Tangdukou (China)",
  "Tangtou (China)",
  "Tangxi (China)",
  "Tangxia (China)",
  "Tangyuan (China)",
  "Tanshi (China)",
  "Taocheng (China)",
  "Taohua (China)",
  "Taoshan (China)",
  "Taoxi (China)",
  "Taoyuan (China)",
  "Taozhuang (China)",
  "Tara (China)",
  "Tashi (China)",
  "Taxi (China)",
  "Tengjiao (China)",
  "Tengzhou (China)",
  "Tianbao (China)",
  "Tianchang (China)",
  "Tiancheng (China)",
  "Tianchi (China)",
  "Tiandong (China)",
  "Tianfu (China)",
  "Tiangang (China)",
  "Tianhai (China)",
  "Tianhong (China)",
  "Tianjin (China)",
  "Tianle (China)",
  "Tianli (China)",
  "Tianma (China)",
  "Tianning (China)",
  "Tianshan (China)",
  "Tianshi (China)",
  "Tianshui (China)",
  "Tiantai Chengguanzhen (China)",
  "Tianyang (China)",
  "Tianyi (China)",
  "Tianyuan (China)",
  "Tianzhen (China)",
  "Tianzhu (China)",
  "Tieli (China)",
  "Tieling (China)",
  "Tieshan (China)",
  "Tingpang (China)",
  "Tongbai (China)",
  "Tongchuan (China)",
  "Tongcun (China)",
  "Tongji (China)",
  "Tongliao (China)",
  "Tongling (China)",
  "Tonglu (China)",
  "Tongmin (China)",
  "Tongren (China)",
  "Tongwang (China)",
  "Tongxiang (China)",
  "Tongyu (China)",
  "Tongyuan (China)",
  "Tumu (China)",
  "Turpan Diqu (China)",
  "Wanbao (China)",
  "Wangfu (China)",
  "Wangluo (China)",
  "Wangshang (China)",
  "Wangsu (China)",
  "Wanjia (China)",
  "Wanning (China)",
  "Wanquan (China)",
  "Wansong (China)",
  "Wanxi (China)",
  "Weibo (China)",
  "Weidun (China)",
  "Weifang (China)",
  "Weihai (China)",
  "Weinan (China)",
  "Weitang (China)",
  "Weiyang (China)",
  "Wenchang (China)",
  "Wenhou (China)",
  "Wenlan (China)",
  "Wenlin (China)",
  "Wenling (China)",
  "Wenquan (China)",
  "Wenshan (China)",
  "Wenshang (China)",
  "Wenxi (China)",
  "Wenxing (China)",
  "Wenzhou (China)",
  "Wuhai (China)",
  "Wuhan (China)",
  "Wuhou (China)",
  "Wuhu (China)",
  "Wuling (China)",
  "Wuning (China)",
  "Wushan (China)",
  "Wutai (China)",
  "Wutong (China)",
  "Wuwei (China)",
  "Wuxi (China)",
  "Wuxia (China)",
  "Wuxing (China)",
  "Wuyang (China)",
  "Wuyi (China)",
  "Wuyishan (China)",
  "Wuzhen (China)",
  "Wuzhishan (China)",
  "Wuzhong (China)",
  "Wuzhou (China)",
  "Xiabancheng (China)",
  "Xiadianmiao (China)",
  "Xiakou (China)",
  "Xiamen (China)",
  "Xianchang (China)",
  "Xiandu (China)",
  "Xiangcheng (China)",
  "Xiangfan (China)",
  "Xiangfeng (China)",
  "Xiangjiang (China)",
  "Xiangnan (China)",
  "Xiangru (China)",
  "Xiangshan (China)",
  "Xiangsheng (China)",
  "Xiangsi (China)",
  "Xiangtan (China)",
  "Xiangtong (China)",
  "Xiangxi (China)",
  "Xiangyi (China)",
  "Xiangyuan (China)",
  "Xianjiang (China)",
  "Xianlin (China)",
  "Xianning (China)",
  "Xianqiao (China)",
  "Xianren (China)",
  "Xianrenqiao (China)",
  "Xiantao (China)",
  "Xianyang (China)",
  "Xianyou (China)",
  "Xiaogan (China)",
  "Xiaogang (China)",
  "Xiaoheshan (China)",
  "Xiaojiahe (China)",
  "Xiaojiang (China)",
  "Xiaomi (China)",
  "Xiaoqu (China)",
  "Xiaoshan (China)",
  "Xiaoshun (China)",
  "Xiaosi (China)",
  "Xiaoxi (China)",
  "Xiaozheng (China)",
  "Xiapu (China)",
  "Xiasha (China)",
  "Xiashi (China)",
  "Xiatangyunxiang (China)",
  "Xiayuan (China)",
  "Xiazhi (China)",
  "Xiazhuang (China)",
  "Xibei (China)",
  "Xibu (China)",
  "Xichang (China)",
  "Xichong (China)",
  "Xidian (China)",
  "Xiedian (China)",
  "Xieqiao (China)",
  "Xiezhou (China)",
  "Xigu (China)",
  "Xiguan (China)",
  "Xihu (China)",
  "Xijiao (China)",
  "Xijing (China)",
  "Xikou (China)",
  "Xiling (China)",
  "Ximeng (China)",
  "Xinao (China)",
  "Xincao (China)",
  "Xinchang (China)",
  "Xinchen (China)",
  "Xincheng (China)",
  "Xindi (China)",
  "Xindu (China)",
  "Xingang (China)",
  "Xingchang (China)",
  "Xingchen (China)",
  "Xingcheng (China)",
  "Xingfeng (China)",
  "Xingguang (China)",
  "Xinghua (China)",
  "Xingqiao (China)",
  "Xingtai (China)",
  "Xinguang (China)",
  "Xingwang (China)",
  "Xingxian (China)",
  "Xingxiangcun (China)",
  "Xingxing (China)",
  "Xingye (China)",
  "Xingyi (China)",
  "Xingyuan (China)",
  "Xinhua (China)",
  "Xinhuang (China)",
  "Xinhui (China)",
  "Xining (China)",
  "Xinjian (China)",
  "Xinjiang (China)",
  "Xinmin (China)",
  "Xinning (China)",
  "Xinqiao (China)",
  "Xinrong (China)",
  "Xinsha (China)",
  "Xinshijie (China)",
  "Xintai (China)",
  "Xintangbian (China)",
  "Xintian (China)",
  "Xinxiang (China)",
  "Xinxing (China)",
  "Xinyang (China)",
  "Xinyi (China)",
  "Xinyou (China)",
  "Xinzha (China)",
  "Xinzheng (China)",
  "Xinzhou (China)",
  "Xiping (China)",
  "Xiqu (China)",
  "Xishan (China)",
  "Xitang (China)",
  "Xiushan (China)",
  "Xiushui (China)",
  "Xiuying (China)",
  "Xiuyu (China)",
  "Xixi (China)",
  "Xiyang (China)",
  "Xiyue (China)",
  "Xizhang (China)",
  "Xizhou (China)",
  "Xuancheng (China)",
  "Xuanwu (China)",
  "Xuanzhou (China)",
  "Xuchang (China)",
  "Xucheng (China)",
  "Xucun (China)",
  "Xuefeng (China)",
  "Xuexi (China)",
  "Xuexiao (China)",
  "Xuezhuang (China)",
  "Xuhui (China)",
  "Xunxian (China)",
  "Xunyi Chengguanzhen (China)",
  "Xushan (China)",
  "Xuzhou (China)",
  "Yahao (China)",
  "Yajin (China)",
  "Yalan (China)",
  "Yalongwan (China)",
  "Yanchang (China)",
  "Yancheng (China)",
  "Yandang (China)",
  "Yandao (China)",
  "Yangce (China)",
  "Yangcun (China)",
  "Yangda (China)",
  "Yangfen (China)",
  "Yanggu (China)",
  "Yangjiang (China)",
  "Yangling (China)",
  "Yanglou (China)",
  "Yangmiao (China)",
  "Yangpu (China)",
  "Yangqitan (China)",
  "Yangqu (China)",
  "Yangquan (China)",
  "Yangshan (China)",
  "Yanguan (China)",
  "Yangxunqiao (China)",
  "Yangzhong (China)",
  "Yangzhou (China)",
  "Yanhai (China)",
  "Yanji (China)",
  "Yanjiang (China)",
  "Yanjiao (China)",
  "Yanjing (China)",
  "Yanming (China)",
  "Yanshi (China)",
  "Yanta (China)",
  "Yantai (China)",
  "Yantan (China)",
  "Yaocheng (China)",
  "Yaofeng (China)",
  "Yaohua (China)",
  "Yaoluoping (China)",
  "Yaozhuang (China)",
  "Yayu (China)",
  "Yibin (China)",
  "Yichang (China)",
  "Yicheng (China)",
  "Yichun (China)",
  "Yidong (China)",
  "Yifeng (China)",
  "Yifu (China)",
  "Yijin (China)",
  "Yijing (China)",
  "Yili (China)",
  "Yinchuan (China)",
  "Yindian (China)",
  "Yinfang (China)",
  "Yingcai (China)",
  "Yingchuan (China)",
  "Yingdu (China)",
  "Yinggen (China)",
  "Yingjia (China)",
  "Yingjie (China)",
  "Yingqian (China)",
  "Yingtai (China)",
  "Yinhai (China)",
  "Yining (China)",
  "Yinjiang (China)",
  "Yinliang (China)",
  "Yinshan (China)",
  "Yintian (China)",
  "Yinxin (China)",
  "Yinzhou (China)",
  "Yiqiao (China)",
  "Yisa (China)",
  "Yishan (China)",
  "Yishui (China)",
  "Yitian (China)",
  "Yiting (China)",
  "Yiwu (China)",
  "Yiyang (China)",
  "Yizheng (China)",
  "Yizhong (China)",
  "Yizhou (China)",
  "Yongchuan (China)",
  "Yongjia (China)",
  "Yongjiawan (China)",
  "Yongjin (China)",
  "Yongjiu (China)",
  "Yongkang (China)",
  "Yongning (China)",
  "Yongqiang (China)",
  "Yongxing (China)",
  "Yongzhou (China)",
  "Youchegang (China)",
  "Youjia (China)",
  "Youjiang (China)",
  "Youshi (China)",
  "Youyi (China)",
  "Yuanchang (China)",
  "Yuanheng (China)",
  "Yuanli (China)",
  "Yuanling (China)",
  "Yuanping (China)",
  "Yuanpu (China)",
  "Yuanqu (China)",
  "Yuantong (China)",
  "Yuanyang (China)",
  "Yucai (China)",
  "Yucheng (China)",
  "Yuchi (China)",
  "Yuecheng (China)",
  "Yuehu (China)",
  "Yuehua (China)",
  "Yuele (China)",
  "Yueliangwan (China)",
  "Yueqing (China)",
  "Yuetai (China)",
  "Yuhai (China)",
  "Yuhan (China)",
  "Yuhang (China)",
  "Yuhong (China)",
  "Yuhuan (China)",
  "Yuhuayuan (China)",
  "Yuhui (China)",
  "Yujing (China)",
  "Yulin (China)",
  "Yulong (China)",
  "Yuncheng (China)",
  "Yunfeng (China)",
  "Yunfu (China)",
  "Yungang (China)",
  "Yungcun (China)",
  "Yunling (China)",
  "Yunnan (China)",
  "Yunting (China)",
  "Yunyang (China)",
  "Yuting (China)",
  "Yutou (China)",
  "Yuxi (China)",
  "Yuxiang (China)",
  "Yuyao (China)",
  "Yuyuan (China)",
  "Yuzhen (China)",
  "Yuzhong Chengguanzhen (China)",
  "Yuzi (China)",
  "Zaoyang (China)",
  "Zaozhuang (China)",
  "Zhancheng (China)",
  "Zhanghe (China)",
  "Zhanghou (China)",
  "Zhangjiagang (China)",
  "Zhangjiajie (China)",
  "Zhangjiakou (China)",
  "Zhangjiang (China)",
  "Zhangqi (China)",
  "Zhangqiu (China)",
  "Zhangshan (China)",
  "Zhangshu (China)",
  "Zhangye (China)",
  "Zhangzhai (China)",
  "Zhangzhou (China)",
  "Zhangzhuang (China)",
  "Zhanjiang (China)",
  "Zhanmao (China)",
  "Zhaobaoshan (China)",
  "Zhaodong (China)",
  "Zhaoqing (China)",
  "Zhaotong (China)",
  "Zhaoyu (China)",
  "Zhaozhou (China)",
  "Zhapu (China)",
  "Zhedong (China)",
  "Zhengda (China)",
  "Zhengdi (China)",
  "Zhengding (China)",
  "Zhenghai (China)",
  "Zhengjiang (China)",
  "Zhengyuan (China)",
  "Zhengzhou (China)",
  "Zhenhua (China)",
  "Zhenjiang (China)",
  "Zhenxi (China)",
  "Zhenxing (China)",
  "Zhenzhou (China)",
  "Zhenzhuquan (China)",
  "Zheshang (China)",
  "Zhicheng (China)",
  "Zhijiang (China)",
  "Zhili (China)",
  "Zhima (China)",
  "Zhixing (China)",
  "Zhiye (China)",
  "Zhiyuan (China)",
  "Zhizhong (China)",
  "Zhongdian (China)",
  "Zhongfang (China)",
  "Zhongfu (China)",
  "Zhonggongmiao (China)",
  "Zhongji (China)",
  "Zhongjian (China)",
  "Zhonglin (China)",
  "Zhongnan (China)",
  "Zhongqiao (China)",
  "Zhongshan (China)",
  "Zhongtang (China)",
  "Zhongtian (China)",
  "Zhongtu (China)",
  "Zhongwei (China)",
  "Zhongxiang (China)",
  "Zhongxin (China)",
  "Zhongxing (China)",
  "Zhongyang (China)",
  "Zhongyicun (China)",
  "Zhongyin (China)",
  "Zhongyuan (China)",
  "Zhongzhou (China)",
  "Zhoukou (China)",
  "Zhoushan (China)",
  "Zhouwangmiao (China)",
  "Zhouxiang (China)",
  "Zhuhai (China)",
  "Zhuji (China)",
  "Zhujiajian (China)",
  "Zhujiajiao (China)",
  "Zhulin (China)",
  "Zhumadian (China)",
  "Zhuozhou (China)",
  "Zhuxi Chengguanzhen (China)",
  "Zhuxiang (China)",
  "Zhuyuan (China)",
  "Zibo (China)",
  "Zicheng (China)",
  "Zigong (China)",
  "Zijin (China)",
  "Ziyang (China)",
  "Ziyu (China)",
  "Zongxoi (China)",
  "Zoucheng (China)",
  "Zunyi (China)",
  "Zuxi (China)",
  "Colombia",
  "Acacias (Colombia)",
  "Acevedo (Colombia)",
  "Aguachica (Colombia)",
  "Antioquia (Colombia)",
  "Arauca (Colombia)",
  "Armenia (Colombia)",
  "Atlantico (Colombia)",
  "Barrancabermeja (Colombia)",
  "Barranquilla (Colombia)",
  "Bello (Colombia)",
  "Bermudez (Colombia)",
  "Boyaca (Colombia)",
  "Bucaramanga (Colombia)",
  "Buenaventura (Colombia)",
  "Buga (Colombia)",
  "Cajica (Colombia)",
  "Calamar (Colombia)",
  "Caldas (Colombia)",
  "Candelaria (Colombia)",
  "Cartagena (Colombia)",
  "Cartago (Colombia)",
  "Cauca (Colombia)",
  "Chia (Colombia)",
  "Chiquinquira (Colombia)",
  "Chocho (Colombia)",
  "Columbia (Colombia)",
  "Corozal (Colombia)",
  "Cota (Colombia)",
  "Cumaribo (Colombia)",
  "Cundinamarca (Colombia)",
  "Dosquebradas (Colombia)",
  "Duitama (Colombia)",
  "El Colegio (Colombia)",
  "Engativa (Colombia)",
  "Envigado (Colombia)",
  "Espinal (Colombia)",
  "Florencia (Colombia)",
  "Floridablanca (Colombia)",
  "Fonseca (Colombia)",
  "Fundacion (Colombia)",
  "Fusagasuga (Colombia)",
  "Galapa (Colombia)",
  "Ginebra (Colombia)",
  "Giron (Colombia)",
  "Guarne (Colombia)",
  "Ibague (Colombia)",
  "Inirida (Colombia)",
  "Ipiales (Colombia)",
  "Itagui (Colombia)",
  "La Ceja (Colombia)",
  "La Estrella (Colombia)",
  "La Mesa (Colombia)",
  "La Union (Colombia)",
  "Los Patios (Colombia)",
  "Madrid (Colombia)",
  "Magdalena (Colombia)",
  "Manizales (Colombia)",
  "Miami (Colombia)",
  "Mitu (Colombia)",
  "Montenegro (Colombia)",
  "Mosquera (Colombia)",
  "Municipio de Copacabana (Colombia)",
  "Neiva (Colombia)",
  "Obando (Colombia)",
  "Palmira (Colombia)",
  "Pamplona (Colombia)",
  "Pasto (Colombia)",
  "Pereira (Colombia)",
  "Piedecuesta (Colombia)",
  "Pitalito (Colombia)",
  "Planadas (Colombia)",
  "Popayan (Colombia)",
  "Puerta Roja (Colombia)",
  "Puerto Tejada (Colombia)",
  "Purificacion (Colombia)",
  "Riohacha (Colombia)",
  "Rionegro (Colombia)",
  "Risaralda (Colombia)",
  "Sabana de Torres (Colombia)",
  "Sabaneta (Colombia)",
  "Sachica (Colombia)",
  "San Clemente (Colombia)",
  "San Diego (Colombia)",
  "San Gil (Colombia)",
  "San Martin (Colombia)",
  "Santa Marta (Colombia)",
  "Santa Rosa de Cabal (Colombia)",
  "Santa Rosa del Sur (Colombia)",
  "Santiago de Cali (Colombia)",
  "Silvania (Colombia)",
  "Sincelejo (Colombia)",
  "Soacha (Colombia)",
  "Sogamoso (Colombia)",
  "Soledad (Colombia)",
  "Sopo (Colombia)",
  "Tarapaca (Colombia)",
  "Tauramena (Colombia)",
  "Tenjo (Colombia)",
  "Tocancipa (Colombia)",
  "Tunja (Colombia)",
  "Turbaco (Colombia)",
  "Ubaque (Colombia)",
  "Urumita (Colombia)",
  "Valledupar (Colombia)",
  "Velez (Colombia)",
  "Villa del Rosario (Colombia)",
  "Villamaria (Colombia)",
  "Villavicencio (Colombia)",
  "Yopal (Colombia)",
  "Yotoco (Colombia)",
  "Yumbo (Colombia)",
  "Congo",
  "Banana (Congo)",
  "Goma (Congo)",
  "Kinshasa (Congo)",
  "Likasi (Congo)",
  "Lubumbashi (Congo)",
  "Costa Rica",
  "Alajuela (Costa Rica)",
  "Alajuelita (Costa Rica)",
  "Alfaro (Costa Rica)",
  "Aserri (Costa Rica)",
  "Atenas (Costa Rica)",
  "Barva (Costa Rica)",
  "Cartago (Costa Rica)",
  "Colon (Costa Rica)",
  "Corazon de Jesus (Costa Rica)",
  "Coronado (Costa Rica)",
  "Coyol (Costa Rica)",
  "Curridabat (Costa Rica)",
  "Desamparados (Costa Rica)",
  "Escazu (Costa Rica)",
  "Esparza (Costa Rica)",
  "Filadelfia (Costa Rica)",
  "Grecia (Costa Rica)",
  "Guachipelin (Costa Rica)",
  "Guacima (Costa Rica)",
  "Guadalupe (Costa Rica)",
  "Guapiles (Costa Rica)",
  "Heredia (Costa Rica)",
  "Isla de Tibas (Costa Rica)",
  "Jaco (Costa Rica)",
  "La Garita (Costa Rica)",
  "Liberia (Costa Rica)",
  "Llorente (Costa Rica)",
  "Mercedes (Costa Rica)",
  "Mexico (Costa Rica)",
  "Miramar (Costa Rica)",
  "Moravia (Costa Rica)",
  "Palmares (Costa Rica)",
  "Paraiso (Costa Rica)",
  "Pavas (Costa Rica)",
  "Perez (Costa Rica)",
  "Poas (Costa Rica)",
  "Puerto Jimenez (Costa Rica)",
  "Puntarenas (Costa Rica)",
  "Quepos (Costa Rica)",
  "Quesada (Costa Rica)",
  "Sabanilla (Costa Rica)",
  "San Diego (Costa Rica)",
  "San Francisco (Costa Rica)",
  "San Isidro (Costa Rica)",
  "San Joaquin (Costa Rica)",
  "San Jose de la Montana (Costa Rica)",
  "San Josecito (Costa Rica)",
  "San Juan (Costa Rica)",
  "San Marcos (Costa Rica)",
  "San Pablo (Costa Rica)",
  "San Pedro (Costa Rica)",
  "San Rafael (Costa Rica)",
  "San Ramon (Costa Rica)",
  "Santa Ana (Costa Rica)",
  "Santa Cecilia (Costa Rica)",
  "Santa Cruz (Costa Rica)",
  "Santo Domingo (Costa Rica)",
  "Sarchi (Costa Rica)",
  "Siquirres (Costa Rica)",
  "Tres Rios (Costa Rica)",
  "Turrialba (Costa Rica)",
  "Turrucares (Costa Rica)",
  "Zapote (Costa Rica)",
  "Zarcero (Costa Rica)",
  "Croatia",
  "Antunovac (Croatia)",
  "Baska (Croatia)",
  "Baska Voda (Croatia)",
  "Bedekovcina (Croatia)",
  "Bestovje (Croatia)",
  "Betina (Croatia)",
  "Bibinje (Croatia)",
  "Bizovac (Croatia)",
  "Bjelovar (Croatia)",
  "Bracevci (Croatia)",
  "Brdovec (Croatia)",
  "Bregana (Croatia)",
  "Brela (Croatia)",
  "Brsadin (Croatia)",
  "Buje (Croatia)",
  "Cabuna (Croatia)",
  "Cavtat (Croatia)",
  "Cepin (Croatia)",
  "Cestica (Croatia)",
  "Core (Croatia)",
  "Crikvenica (Croatia)",
  "Dalj (Croatia)",
  "Darda (Croatia)",
  "Delnice (Croatia)",
  "Desinic (Croatia)",
  "Donja Pusca (Croatia)",
  "Donja Zelina (Croatia)",
  "Donji Kraljevec (Croatia)",
  "Dubrovnik (Croatia)",
  "Dugo Selo (Croatia)",
  "Fazana (Croatia)",
  "Fuzine (Croatia)",
  "Galgovo (Croatia)",
  "Glina (Croatia)",
  "Gornja Lomnica (Croatia)",
  "Gornji Kriz (Croatia)",
  "Gornji Stupnik (Croatia)",
  "Hreljin (Croatia)",
  "Icici (Croatia)",
  "Ivanec (Croatia)",
  "Ivanic-Grad (Croatia)",
  "Karlovac (Croatia)",
  "Karojba (Croatia)",
  "Kastel Gomilica (Croatia)",
  "Kastel Luksic (Croatia)",
  "Kastel Sucurac (Croatia)",
  "Koprivnica (Croatia)",
  "Kostrena (Croatia)",
  "Kraljevec na Sutli (Croatia)",
  "Krapina (Croatia)",
  "Kriz (Croatia)",
  "Krk (Croatia)",
  "Krsan (Croatia)",
  "Kutjevo (Croatia)",
  "Labin (Croatia)",
  "Lapad (Croatia)",
  "Lekenik (Croatia)",
  "Lepoglava (Croatia)",
  "Lovran (Croatia)",
  "Lukavec (Croatia)",
  "Lupoglav (Croatia)",
  "Makarska (Croatia)",
  "Matulji (Croatia)",
  "Medulin (Croatia)",
  "Mlini (Croatia)",
  "Mursko Sredisce (Croatia)",
  "Nedelisce (Croatia)",
  "Nova Gradiska (Croatia)",
  "Novi Marof (Croatia)",
  "Novi Vinodolski (Croatia)",
  "Novo Cice (Croatia)",
  "Nustar (Croatia)",
  "Okrug Gornji (Croatia)",
  "Opatija (Croatia)",
  "Orahovica (Croatia)",
  "Orebic (Croatia)",
  "Orle (Croatia)",
  "Oroslavje (Croatia)",
  "Osijek (Croatia)",
  "Pakrac (Croatia)",
  "Pazin (Croatia)",
  "Petrcane (Croatia)",
  "Petrinja (Croatia)",
  "Piskorevci (Croatia)",
  "Podastrana (Croatia)",
  "Podgajci Posavski (Croatia)",
  "Popovaca (Croatia)",
  "Postira (Croatia)",
  "Pregrada (Croatia)",
  "Prelog (Croatia)",
  "Primosten (Croatia)",
  "Privlaka (Croatia)",
  "Pula (Croatia)",
  "Rab (Croatia)",
  "Rakitje (Croatia)",
  "Rijeka (Croatia)",
  "Rovinj (Croatia)",
  "Samobor (Croatia)",
  "Sapjane (Croatia)",
  "Senkovec (Croatia)",
  "Sesvete (Croatia)",
  "Sesvetski Kraljevec (Croatia)",
  "Sice (Croatia)",
  "Silas (Croatia)",
  "Simuni (Croatia)",
  "Sinj (Croatia)",
  "Sisak (Croatia)",
  "Slatina (Croatia)",
  "Slavonski Brod (Croatia)",
  "Sokolovac (Croatia)",
  "Solin (Croatia)",
  "Split (Croatia)",
  "Splitska (Croatia)",
  "Strmec (Croatia)",
  "Strmec Samoborski (Croatia)",
  "Sveti Ivan Zelina (Croatia)",
  "Tar (Croatia)",
  "Torcec (Croatia)",
  "Trogir (Croatia)",
  "Tuzno (Croatia)",
  "Umag (Croatia)",
  "Varazdinske Toplice (Croatia)",
  "Vardarac (Croatia)",
  "Velika Gorica (Croatia)",
  "Vetovo (Croatia)",
  "Vinica (Croatia)",
  "Vinkovci (Croatia)",
  "Virje (Croatia)",
  "Virovitica (Croatia)",
  "Viskovci (Croatia)",
  "Vojnic (Croatia)",
  "Vrata (Croatia)",
  "Vratisinec (Croatia)",
  "Vrbnik (Croatia)",
  "Vrbovec (Croatia)",
  "Vukovar (Croatia)",
  "Zabok (Croatia)",
  "Zadar (Croatia)",
  "Zagreb (Croatia)",
  "Zapresic (Croatia)",
  "Zlatar (Croatia)",
  "Cuba",
  "Bayamo (Cuba)",
  "Cienfuegos (Cuba)",
  "Habana (Cuba)",
  "Havana (Cuba)",
  "La Habana (Cuba)",
  "Las Tunas (Cuba)",
  "Matanzas (Cuba)",
  "Santiago de Cuba (Cuba)",
  "Varadero (Cuba)",
  "Villa (Cuba)",
  "Cyprus",
  "Agia Anna (Cyprus)",
  "Aradippou (Cyprus)",
  "Ayia Marina (Cyprus)",
  "Chlorakas (Cyprus)",
  "Deryneia (Cyprus)",
  "Famagusta (Cyprus)",
  "Geroskipou (Cyprus)",
  "Kato Lakatamia (Cyprus)",
  "Kato Polemidia (Cyprus)",
  "Kiti (Cyprus)",
  "Kyrenia (Cyprus)",
  "Larnaca (Cyprus)",
  "Laxia (Cyprus)",
  "Limassol (Cyprus)",
  "Livadia (Cyprus)",
  "Morfou (Cyprus)",
  "Mouttagiaka (Cyprus)",
  "Nicosia (Cyprus)",
  "Paphos (Cyprus)",
  "Paralimni (Cyprus)",
  "Parekklisha (Cyprus)",
  "Prodromi (Cyprus)",
  "Sotira (Cyprus)",
  "Strovolos (Cyprus)",
  "Tera (Cyprus)",
  "Czech Republic",
  "Adamov (Czech Republic)",
  "Albrechtice (Czech Republic)",
  "Arnoltice (Czech Republic)",
  "As (Czech Republic)",
  "Babice (Czech Republic)",
  "Banov (Czech Republic)",
  "Baska (Czech Republic)",
  "Batelov (Czech Republic)",
  "Bavory (Czech Republic)",
  "Bechyne (Czech Republic)",
  "Bela pod Bezdezem (Czech Republic)",
  "Belotin (Czech Republic)",
  "Benatky nad Jizerou (Czech Republic)",
  "Benesov (Czech Republic)",
  "Benesovice (Czech Republic)",
  "Bernartice (Czech Republic)",
  "Bernartice nad Odrou (Czech Republic)",
  "Beroun (Czech Republic)",
  "Bezdekov (Czech Republic)",
  "Bilina (Czech Republic)",
  "Bilovice (Czech Republic)",
  "Bilovice nad Svitavou (Czech Republic)",
  "Bitovany (Czech Republic)",
  "Blansko (Czech Republic)",
  "Blatna (Czech Republic)",
  "Blizevedly (Czech Republic)",
  "Blovice (Czech Republic)",
  "Blucina (Czech Republic)",
  "Bludov (Czech Republic)",
  "Bludovice (Czech Republic)",
  "Bochov (Czech Republic)",
  "Bohumin (Czech Republic)",
  "Bohunovice (Czech Republic)",
  "Bojanov (Czech Republic)",
  "Bojkovice (Czech Republic)",
  "Bolatice (Czech Republic)",
  "Bolebor (Czech Republic)",
  "Bor (Czech Republic)",
  "Boretice (Czech Republic)",
  "Borohradek (Czech Republic)",
  "Borotin (Czech Republic)",
  "Borovany (Czech Republic)",
  "Borsice (Czech Republic)",
  "Bosin (Czech Republic)",
  "Boskovice (Czech Republic)",
  "Bradlec (Czech Republic)",
  "Brandys nad Labem-Stara Boleslav (Czech Republic)",
  "Branky (Czech Republic)",
  "Branov (Czech Republic)",
  "Bratrikov (Czech Republic)",
  "Brezi (Czech Republic)",
  "Brezno (Czech Republic)",
  "Brezolupy (Czech Republic)",
  "Brezova (Czech Republic)",
  "Brno (Czech Republic)",
  "Brod (Czech Republic)",
  "Brodce (Czech Republic)",
  "Brodek (Czech Republic)",
  "Broumov (Czech Republic)",
  "Brtnice (Czech Republic)",
  "Bruntal (Czech Republic)",
  "Brusperk (Czech Republic)",
  "Bucovice (Czech Republic)",
  "Budyne nad Ohri (Czech Republic)",
  "Bukovec (Czech Republic)",
  "Bustehrad (Czech Republic)",
  "Bylnice (Czech Republic)",
  "Bystrice (Czech Republic)",
  "Bystrice nad Pernstejnem (Czech Republic)",
  "Bystrice pod Hostynem (Czech Republic)",
  "Cakovice (Czech Republic)",
  "Caslav (Czech Republic)",
  "Cebin (Czech Republic)",
  "Cechtice (Czech Republic)",
  "Cejc (Czech Republic)",
  "Cejkovice (Czech Republic)",
  "Celadna (Czech Republic)",
  "Celakovice (Czech Republic)",
  "Cepi (Czech Republic)",
  "Cercany (Czech Republic)",
  "Cerekvice nad Loucnou (Czech Republic)",
  "Cerhenice (Czech Republic)",
  "Cerna Hora (Czech Republic)",
  "Cerna za Bory (Czech Republic)",
  "Cerncice (Czech Republic)",
  "Cernosice (Czech Republic)",
  "Cernotin (Czech Republic)",
  "Cervene Pecky (Czech Republic)",
  "Cerveny Kostelec (Czech Republic)",
  "Ceska (Czech Republic)",
  "Ceska Skalice (Czech Republic)",
  "Ceska Trebova (Czech Republic)",
  "Ceska Ves (Czech Republic)",
  "Ceske Mezirici (Czech Republic)",
  "Cesky Brod (Czech Republic)",
  "Cesky Dub (Czech Republic)",
  "Cestice (Czech Republic)",
  "Cheb (Czech Republic)",
  "Cheznovice (Czech Republic)",
  "Chlumcany (Czech Republic)",
  "Chlumec (Czech Republic)",
  "Chlumec nad Cidlinou (Czech Republic)",
  "Chlupice (Czech Republic)",
  "Chocen (Czech Republic)",
  "Chocerady (Czech Republic)",
  "Chodov (Czech Republic)",
  "Chodova Plana (Czech Republic)",
  "Chomutov (Czech Republic)",
  "Chotebor (Czech Republic)",
  "Chotesov (Czech Republic)",
  "Chotetov (Czech Republic)",
  "Chotoviny (Czech Republic)",
  "Choustnik (Czech Republic)",
  "Choustnikovo Hradiste (Czech Republic)",
  "Chrast (Czech Republic)",
  "Chrastna (Czech Republic)",
  "Chribska (Czech Republic)",
  "Chroustovice (Czech Republic)",
  "Chrudim (Czech Republic)",
  "Chvalec (Czech Republic)",
  "Chyne (Czech Republic)",
  "Cista (Czech Republic)",
  "Citoliby (Czech Republic)",
  "Citov (Czech Republic)",
  "Ckyne (Czech Republic)",
  "Ctidruzice (Czech Republic)",
  "Cvikov (Czech Republic)",
  "Dalovice (Czech Republic)",
  "Damnice (Czech Republic)",
  "Darkovicky (Czech Republic)",
  "Davle (Czech Republic)",
  "Deblin (Czech Republic)",
  "Dehylov (Czech Republic)",
  "Dejvice (Czech Republic)",
  "Desna (Czech Republic)",
  "Detmarovice (Czech Republic)",
  "Dily (Czech Republic)",
  "Divisov (Czech Republic)",
  "Dlouha Trebova (Czech Republic)",
  "Dlouhe (Czech Republic)",
  "Dobra (Czech Republic)",
  "Dobra Voda (Czech Republic)",
  "Dobrany (Czech Republic)",
  "Dobre (Czech Republic)",
  "Dobrichovice (Czech Republic)",
  "Dobris (Czech Republic)",
  "Dobriv (Czech Republic)",
  "Dobronin (Czech Republic)",
  "Dobroslavice (Czech Republic)",
  "Dobruska (Czech Republic)",
  "Doksy (Czech Republic)",
  "Dolany (Czech Republic)",
  "Dolni Becva (Czech Republic)",
  "Dolni Benesov (Czech Republic)",
  "Dolni Berkovice (Czech Republic)",
  "Dolni Bojanovice (Czech Republic)",
  "Dolni Brezany (Czech Republic)",
  "Dolni Dunajovice (Czech Republic)",
  "Dolni Jircany (Czech Republic)",
  "Dolni Kounice (Czech Republic)",
  "Dolni Nemci (Czech Republic)",
  "Dolni Poustevna (Czech Republic)",
  "Dolni Radechova (Czech Republic)",
  "Dolni Sucha (Czech Republic)",
  "Dolni Sytova (Czech Republic)",
  "Dolni Terlicko (Czech Republic)",
  "Dolni Zandov (Czech Republic)",
  "Domasov (Czech Republic)",
  "Domazelice (Czech Republic)",
  "Domazlice (Czech Republic)",
  "Doubravnik (Czech Republic)",
  "Doubravy (Czech Republic)",
  "Doudleby nad Orlici (Czech Republic)",
  "Drahotuse (Czech Republic)",
  "Drisy (Czech Republic)",
  "Drnovice (Czech Republic)",
  "Drzkov (Czech Republic)",
  "Drzovice (Czech Republic)",
  "Dubec (Czech Republic)",
  "Dubenec (Czech Republic)",
  "Dubi (Czech Republic)",
  "Dubicko (Czech Republic)",
  "Dubnany (Czech Republic)",
  "Dubne (Czech Republic)",
  "Duchcov (Czech Republic)",
  "Dukovany (Czech Republic)",
  "Dusejov (Czech Republic)",
  "Dvorce (Czech Republic)",
  "Dvur Kralove nad Labem (Czech Republic)",
  "Dymokury (Czech Republic)",
  "Ejpovice (Czech Republic)",
  "Filipova (Czech Republic)",
  "Francova Lhota (Czech Republic)",
  "Frantiskovy Lazne (Czech Republic)",
  "Frenstat pod Radhostem (Czech Republic)",
  "Frydek-Mistek (Czech Republic)",
  "Frydlant (Czech Republic)",
  "Frydlant nad Ostravici (Czech Republic)",
  "Frystak (Czech Republic)",
  "Fulnek (Czech Republic)",
  "Golcuv Jenikov (Czech Republic)",
  "Grucovice (Czech Republic)",
  "Habartov (Czech Republic)",
  "Haj ve Slezsku (Czech Republic)",
  "Haje (Czech Republic)",
  "Hajnice (Czech Republic)",
  "Halenkov (Czech Republic)",
  "Hamr (Czech Republic)",
  "Harrachov (Czech Republic)",
  "Havrice (Czech Republic)",
  "Hermanuv Mestec (Czech Republic)",
  "Hlasna Treban (Czech Republic)",
  "Hlinsko (Czech Republic)",
  "Hlubocepy (Czech Republic)",
  "Hlubocky (Czech Republic)",
  "Hluboka nad Vltavou (Czech Republic)",
  "Hlucin (Czech Republic)",
  "Hodice (Czech Republic)",
  "Hodslavice (Czech Republic)",
  "Holasice (Czech Republic)",
  "Holesov (Czech Republic)",
  "Holice (Czech Republic)",
  "Holoubkov (Czech Republic)",
  "Holubice (Czech Republic)",
  "Holysov (Czech Republic)",
  "Horesedly (Czech Republic)",
  "Horice (Czech Republic)",
  "Horin (Czech Republic)",
  "Horni Becva (Czech Republic)",
  "Horni Benesov (Czech Republic)",
  "Horni Bojanovice (Czech Republic)",
  "Horni Briza (Czech Republic)",
  "Horni Lutyne (Czech Republic)",
  "Horni Nemci (Czech Republic)",
  "Horni Pocernice (Czech Republic)",
  "Horni Podluzi (Czech Republic)",
  "Horni Redice (Czech Republic)",
  "Horni Slavkov (Czech Republic)",
  "Horomerice (Czech Republic)",
  "Horousanky (Czech Republic)",
  "Horovice (Czech Republic)",
  "Horsovsky Tyn (Czech Republic)",
  "Hospozin (Czech Republic)",
  "Hosteradice (Czech Republic)",
  "Hostice (Czech Republic)",
  "Hostin (Czech Republic)",
  "Hostinne (Czech Republic)",
  "Hostivice (Czech Republic)",
  "Hostomice (Czech Republic)",
  "Hovezi (Czech Republic)",
  "Hovorany (Czech Republic)",
  "Hovorcovice (Czech Republic)",
  "Hrabetice (Czech Republic)",
  "Hradcovice (Czech Republic)",
  "Hradec (Czech Republic)",
  "Hradec nad Moravici (Czech Republic)",
  "Hradek (Czech Republic)",
  "Hradek nad Nisou (Czech Republic)",
  "Hradiste (Czech Republic)",
  "Hranice (Czech Republic)",
  "Hrdejovice (Czech Republic)",
  "Hronov (Czech Republic)",
  "Hrusovany nad Jevisovkou (Czech Republic)",
  "Hrusovany u Brna (Czech Republic)",
  "Hudlice (Czech Republic)",
  "Hukvaldy (Czech Republic)",
  "Hulin (Czech Republic)",
  "Humpolec (Czech Republic)",
  "Hustopece (Czech Republic)",
  "Hvozd (Czech Republic)",
  "Hysly (Czech Republic)",
  "Ivancice (Czech Republic)",
  "Ivanovice na Hane (Czech Republic)",
  "Jablonec nad Nisou (Czech Republic)",
  "Jablonne nad Orlici (Czech Republic)",
  "Jablonne v Podjestedi (Czech Republic)",
  "Jablunka (Czech Republic)",
  "Jablunkov (Czech Republic)",
  "Jalubi (Czech Republic)",
  "Janovice (Czech Republic)",
  "Janovice nad Uhlavou (Czech Republic)",
  "Jaromer (Czech Republic)",
  "Jaroslav (Czech Republic)",
  "Jaroslavice (Czech Republic)",
  "Jedovnice (Czech Republic)",
  "Jenec (Czech Republic)",
  "Jesenice (Czech Republic)",
  "Jesenik (Czech Republic)",
  "Jevicko (Czech Republic)",
  "Jevisovice (Czech Republic)",
  "Jezov (Czech Republic)",
  "Jihlava (Czech Republic)",
  "Jilemnice (Czech Republic)",
  "Jilove u Prahy (Czech Republic)",
  "Jilovec (Czech Republic)",
  "Jiloviste (Czech Republic)",
  "Jince (Czech Republic)",
  "Jindrichuv Hradec (Czech Republic)",
  "Jinocany (Czech Republic)",
  "Jirice (Czech Republic)",
  "Jirickov (Czech Republic)",
  "Jirkov (Czech Republic)",
  "Jirny (Czech Republic)",
  "Jistebnik (Czech Republic)",
  "Kacerov (Czech Republic)",
  "Kacice (Czech Republic)",
  "Kadan (Czech Republic)",
  "Kamenice (Czech Republic)",
  "Kamenicky Senov (Czech Republic)",
  "Kamenne Zehrovice (Czech Republic)",
  "Kamenny Privoz (Czech Republic)",
  "Kamyk (Czech Republic)",
  "Kaplice (Czech Republic)",
  "Karlin (Czech Republic)",
  "Karlovy Vary (Czech Republic)",
  "Karvina (Czech Republic)",
  "Kaznejov (Czech Republic)",
  "Kbely (Czech Republic)",
  "Kdyne (Czech Republic)",
  "Kladno (Czech Republic)",
  "Klasterec nad Ohri (Czech Republic)",
  "Klatovy (Czech Republic)",
  "Klecany (Czech Republic)",
  "Klicany (Czech Republic)",
  "Klimkovice (Czech Republic)",
  "Klobouky (Czech Republic)",
  "Knezmost (Czech Republic)",
  "Kochovice (Czech Republic)",
  "Koci (Czech Republic)",
  "Kolodeje (Czech Republic)",
  "Komarice (Czech Republic)",
  "Komorni Lhotka (Czech Republic)",
  "Konice (Czech Republic)",
  "Kopidlno (Czech Republic)",
  "Koprivnice (Czech Republic)",
  "Korycany (Czech Republic)",
  "Kosmonosy (Czech Republic)",
  "Kostelec na Hane (Czech Republic)",
  "Kostelec nad Cernymi Lesy (Czech Republic)",
  "Kostelec nad Labem (Czech Republic)",
  "Kostelec nad Orlici (Czech Republic)",
  "Kostelni Hlavno (Czech Republic)",
  "Kostice (Czech Republic)",
  "Kotencice (Czech Republic)",
  "Kourim (Czech Republic)",
  "Kouty (Czech Republic)",
  "Kozmice (Czech Republic)",
  "Kralice na Hane (Czech Republic)",
  "Kraliky (Czech Republic)",
  "Kralovice (Czech Republic)",
  "Kralupy nad Vltavou (Czech Republic)",
  "Kraluv Dvur (Czech Republic)",
  "Kraslice (Czech Republic)",
  "Krasna Hora nad Vltavou (Czech Republic)",
  "Krasna Lipa (Czech Republic)",
  "Kratonohy (Czech Republic)",
  "Kravare (Czech Republic)",
  "Krchleby (Czech Republic)",
  "Krenovice (Czech Republic)",
  "Krepenice (Czech Republic)",
  "Kresice (Czech Republic)",
  "Kretin (Czech Republic)",
  "Krhova (Czech Republic)",
  "Krivoklat (Czech Republic)",
  "Krizanov (Czech Republic)",
  "Krmelin (Czech Republic)",
  "Krnov (Czech Republic)",
  "Krnsko (Czech Republic)",
  "Krouna (Czech Republic)",
  "Krtiny (Czech Republic)",
  "Krumvir (Czech Republic)",
  "Krupka (Czech Republic)",
  "Ktis (Czech Republic)",
  "Kuncice (Czech Republic)",
  "Kuncina (Czech Republic)",
  "Kunovice (Czech Republic)",
  "Kunratice (Czech Republic)",
  "Kunstat (Czech Republic)",
  "Kurim (Czech Republic)",
  "Kvetnice (Czech Republic)",
  "Kyjov (Czech Republic)",
  "Kylesovice (Czech Republic)",
  "Lachov (Czech Republic)",
  "Ladvi (Czech Republic)",
  "Lampertice (Czech Republic)",
  "Lanskroun (Czech Republic)",
  "Lanzhot (Czech Republic)",
  "Lasenice (Czech Republic)",
  "Lazne Bohdanec (Czech Republic)",
  "Lazne Libverda (Czech Republic)",
  "Ledec nad Sazavou (Czech Republic)",
  "Lednice (Czech Republic)",
  "Leskovec (Czech Republic)",
  "Lesonice (Czech Republic)",
  "Letohrad (Czech Republic)",
  "Letovice (Czech Republic)",
  "Lety (Czech Republic)",
  "Lhota (Czech Republic)",
  "Lhota pod Libcany (Czech Republic)",
  "Lhotka (Czech Republic)",
  "Liban (Czech Republic)",
  "Libcany (Czech Republic)",
  "Liberec (Czech Republic)",
  "Libesice (Czech Republic)",
  "Libeznice (Czech Republic)",
  "Libice nad Cidlinou (Czech Republic)",
  "Libis (Czech Republic)",
  "Libocany (Czech Republic)",
  "Libochovicky (Czech Republic)",
  "Libos (Czech Republic)",
  "Lichkov (Czech Republic)",
  "Lichnov (o. Novy Jicin) (Czech Republic)",
  "Line (Czech Republic)",
  "Lipence (Czech Republic)",
  "Lipnik nad Becvou (Czech Republic)",
  "Lipova (Czech Republic)",
  "Lisov (Czech Republic)",
  "Litava (Czech Republic)",
  "Liten (Czech Republic)",
  "Litovel (Czech Republic)",
  "Litvinov (Czech Republic)",
  "Lodenice (Czech Republic)",
  "Loket (Czech Republic)",
  "Lomnice (Czech Republic)",
  "Lomnice nad Luznici (Czech Republic)",
  "Lomnice nad Popelkou (Czech Republic)",
  "Loucany (Czech Republic)",
  "Loucen (Czech Republic)",
  "Loukov (Czech Republic)",
  "Lounovice pod Blanikem (Czech Republic)",
  "Louny (Czech Republic)",
  "Lovosice (Czech Republic)",
  "Lubenec (Czech Republic)",
  "Luby (Czech Republic)",
  "Luhacovice (Czech Republic)",
  "Luka (Czech Republic)",
  "Lukov (Czech Republic)",
  "Lustenice (Czech Republic)",
  "Lutin (Czech Republic)",
  "Luze (Czech Republic)",
  "Luzna (Czech Republic)",
  "Lysa nad Labem (Czech Republic)",
  "Lysice (Czech Republic)",
  "Mala Skala (Czech Republic)",
  "Malcice (Czech Republic)",
  "Malenice (Czech Republic)",
  "Malenovice (Czech Republic)",
  "Malesov (Czech Republic)",
  "Maly Ujezd (Czech Republic)",
  "Marianska (Czech Republic)",
  "Markvartovice (Czech Republic)",
  "Marov u Upice (Czech Republic)",
  "Mcely (Czech Republic)",
  "Mechenice (Czech Republic)",
  "Menany (Czech Republic)",
  "Merklin (Czech Republic)",
  "Mesice (Czech Republic)",
  "Mestec (Czech Republic)",
  "Mesto Touskov (Czech Republic)",
  "Mezibori (Czech Republic)",
  "Mezilesi (Czech Republic)",
  "Michle (Czech Republic)",
  "Mikulov (Czech Republic)",
  "Mikulovice (Czech Republic)",
  "Milevsko (Czech Republic)",
  "Milikov (Czech Republic)",
  "Milin (Czech Republic)",
  "Milonice (Czech Republic)",
  "Milotice (Czech Republic)",
  "Milovice (Czech Republic)",
  "Mimon (Czech Republic)",
  "Miroslav (Czech Republic)",
  "Mirosov (Czech Republic)",
  "Mirosovice (Czech Republic)",
  "Mlada Vozice (Czech Republic)",
  "Mlade Buky (Czech Republic)",
  "Mladkov (Czech Republic)",
  "Mlazovice (Czech Republic)",
  "Mnichovice (Czech Republic)",
  "Mnisek pod Brdy (Czech Republic)",
  "Modrice (Czech Republic)",
  "Mohelnice (Czech Republic)",
  "Mokra Horakov (Czech Republic)",
  "Mokrany (Czech Republic)",
  "Moran (Czech Republic)",
  "Moravicany (Czech Republic)",
  "Moravska Nova Ves (Czech Republic)",
  "Moravska Trebova (Czech Republic)",
  "Moravske Budejovice (Czech Republic)",
  "Moravsky Beroun (Czech Republic)",
  "Moravsky Krumlov (Czech Republic)",
  "Moravsky Pisek (Czech Republic)",
  "Moravsky Zizkov (Czech Republic)",
  "Most (Czech Republic)",
  "Mostek (Czech Republic)",
  "Mosty u Jablunkova (Czech Republic)",
  "Mratin (Czech Republic)",
  "Mrlinek (Czech Republic)",
  "Msec (Czech Republic)",
  "Mseno (Czech Republic)",
  "Mukarov (Czech Republic)",
  "Mutenice (Czech Republic)",
  "Nachod (Czech Republic)",
  "Naklo (Czech Republic)",
  "Napajedla (Czech Republic)",
  "Nasavrky (Czech Republic)",
  "Navsi u Jablunkova (Czech Republic)",
  "Nechanice (Czech Republic)",
  "Nedakonice (Czech Republic)",
  "Nedomice (Czech Republic)",
  "Nedvedice (Czech Republic)",
  "Nehvizdy (Czech Republic)",
  "Nejdek (Czech Republic)",
  "Nelahozeves (Czech Republic)",
  "Nenkovice (Czech Republic)",
  "Nepomysl (Czech Republic)",
  "Neratovice (Czech Republic)",
  "Nesovice (Czech Republic)",
  "Nezamyslice (Czech Republic)",
  "Nezvestice (Czech Republic)",
  "Nikolcice (Czech Republic)",
  "Nivnice (Czech Republic)",
  "Nova Cerekev (Czech Republic)",
  "Nova Lhota (Czech Republic)",
  "Nova Paka (Czech Republic)",
  "Nova Vcelnice (Czech Republic)",
  "Nova Ves (Czech Republic)",
  "Nove Dvory (Czech Republic)",
  "Nove Mesto na Morave (Czech Republic)",
  "Nove Mesto nad Metuji (Czech Republic)",
  "Nove Straseci (Czech Republic)",
  "Nove Veseli (Czech Republic)",
  "Novosedly (Czech Republic)",
  "Novy Bor (Czech Republic)",
  "Novy Bydzov (Czech Republic)",
  "Novy Hrozenkov (Czech Republic)",
  "Novy Jicin (Czech Republic)",
  "Novy Knin (Czech Republic)",
  "Novy Oldrichov (Czech Republic)",
  "Nucice (Czech Republic)",
  "Nupaky (Czech Republic)",
  "Nydek (Czech Republic)",
  "Nymburk (Czech Republic)",
  "Nyrany (Czech Republic)",
  "Nyrsko (Czech Republic)",
  "Obrany (Czech Republic)",
  "Obristvi (Czech Republic)",
  "Ochoz (Czech Republic)",
  "Odolena Voda (Czech Republic)",
  "Odry (Czech Republic)",
  "Ohrobec (Czech Republic)",
  "Okrisky (Czech Republic)",
  "Olomouc (Czech Republic)",
  "Olomucany (Czech Republic)",
  "Olovi (Czech Republic)",
  "Olsany (Czech Republic)",
  "Ondrejov (Czech Republic)",
  "Oparany (Czech Republic)",
  "Opatovice nad Labem (Czech Republic)",
  "Opava (Czech Republic)",
  "Opocno (Czech Republic)",
  "Orlova (Czech Republic)",
  "Osecna (Czech Republic)",
  "Osek (Czech Republic)",
  "Osik (Czech Republic)",
  "Oslavany (Czech Republic)",
  "Ostopovice (Czech Republic)",
  "Ostrava (Czech Republic)",
  "Ostromer (Czech Republic)",
  "Ostrov (Czech Republic)",
  "Ostrov u Macochy (Czech Republic)",
  "Ostrozska Lhota (Czech Republic)",
  "Ostrozska Nova Ves (Czech Republic)",
  "Otnice (Czech Republic)",
  "Otrokovice (Czech Republic)",
  "Pacov (Czech Republic)",
  "Palkovice (Czech Republic)",
  "Pardubice (Czech Republic)",
  "Pariz (Czech Republic)",
  "Pavlice (Czech Republic)",
  "Pchery (Czech Republic)",
  "Pecin (Czech Republic)",
  "Pecky (Czech Republic)",
  "Pelhrimov (Czech Republic)",
  "Perstejn (Czech Republic)",
  "Petrovice (Czech Republic)",
  "Petrovice u Karvine (Czech Republic)",
  "Petrvald (Czech Republic)",
  "Pilsen (Czech Republic)",
  "Pisecna (Czech Republic)",
  "Pistovice (Czech Republic)",
  "Plana (Czech Republic)",
  "Planany (Czech Republic)",
  "Plouznice (Czech Republic)",
  "Pocatky (Czech Republic)",
  "Pocenice (Czech Republic)",
  "Podborany (Czech Republic)",
  "Podebrady (Czech Republic)",
  "Podivin (Czech Republic)",
  "Polepy (Czech Republic)",
  "Polesovice (Czech Republic)",
  "Police nad Metuji (Czech Republic)",
  "Policka (Czech Republic)",
  "Polna (Czech Republic)",
  "Poricany (Czech Republic)",
  "Poritsch (Czech Republic)",
  "Postoloprty (Czech Republic)",
  "Postrelmov (Czech Republic)",
  "Potstejn (Czech Republic)",
  "Prachatice (Czech Republic)",
  "Prackovice nad Labem (Czech Republic)",
  "Prague (Czech Republic)",
  "Prazmo (Czech Republic)",
  "Preckov (Czech Republic)",
  "Predklasteri (Czech Republic)",
  "Predmerice nad Jizerou (Czech Republic)",
  "Predmerice nad Labem (Czech Republic)",
  "Predmesti (Czech Republic)",
  "Prelouc (Czech Republic)",
  "Prepere (Czech Republic)",
  "Prerov nad Labem (Czech Republic)",
  "Prestice (Czech Republic)",
  "Prezletice (Czech Republic)",
  "Pribor (Czech Republic)",
  "Pribyslav (Czech Republic)",
  "Prikazy (Czech Republic)",
  "Primda (Czech Republic)",
  "Prlov (Czech Republic)",
  "Prosetin (Czech Republic)",
  "Protivin (Czech Republic)",
  "Provodov (Czech Republic)",
  "Pruhonice (Czech Republic)",
  "Prusanky (Czech Republic)",
  "Psary (Czech Republic)",
  "Pustejov (Czech Republic)",
  "Pysely (Czech Republic)",
  "Radim (Czech Republic)",
  "Radnice (Czech Republic)",
  "Radostice (Czech Republic)",
  "Radostin (Czech Republic)",
  "Radun (Czech Republic)",
  "Raj (Czech Republic)",
  "Rajec-Jestrebi (Czech Republic)",
  "Rajecko (Czech Republic)",
  "Rakovnik (Czech Republic)",
  "Rapotice (Czech Republic)",
  "Rapotin (Czech Republic)",
  "Raspenava (Czech Republic)",
  "Rataje (Czech Republic)",
  "Ratibor (Czech Republic)",
  "Ratiskovice (Czech Republic)",
  "Rebesovice (Czech Republic)",
  "Repy (Czech Republic)",
  "Ricany (Czech Republic)",
  "Rikovice (Czech Republic)",
  "Ritka (Czech Republic)",
  "Rokycany (Czech Republic)",
  "Rokytnice (Czech Republic)",
  "Rokytnice nad Jizerou (Czech Republic)",
  "Ronov nad Doubravou (Czech Republic)",
  "Rosice (Czech Republic)",
  "Roudnice nad Labem (Czech Republic)",
  "Roudnicek (Czech Republic)",
  "Rousinov (Czech Republic)",
  "Rovensko pod Troskami (Czech Republic)",
  "Rovina (Czech Republic)",
  "Rovna (Czech Republic)",
  "Rozdrojovice (Czech Republic)",
  "Rozmital pod Tremsinem (Czech Republic)",
  "Roznov pod Radhostem (Czech Republic)",
  "Rozsochatec (Czech Republic)",
  "Roztoky (Czech Republic)",
  "Rtyne (Czech Republic)",
  "Ruda nad Moravou (Czech Republic)",
  "Rudikov (Czech Republic)",
  "Rudna (Czech Republic)",
  "Rudolfov (Czech Republic)",
  "Rudoltice (Czech Republic)",
  "Rybna (Czech Republic)",
  "Rychnov (Czech Republic)",
  "Rychnov nad Kneznou (Czech Republic)",
  "Rychvald (Czech Republic)",
  "Rymarov (Czech Republic)",
  "Sadska (Czech Republic)",
  "Sanov (Czech Republic)",
  "Sany (Czech Republic)",
  "Satov (Czech Republic)",
  "Seberov (Czech Republic)",
  "Sebrov (Czech Republic)",
  "Sedlcany (Czech Republic)",
  "Sedlec (Czech Republic)",
  "Sedliste (Czech Republic)",
  "Seibersdorf (Czech Republic)",
  "Semily (Czech Republic)",
  "Senec (Czech Republic)",
  "Senice na Hane (Czech Republic)",
  "Senohraby (Czech Republic)",
  "Senov (Czech Republic)",
  "Sestajovice (Czech Republic)",
  "Sezemice (Czech Republic)",
  "Sibrina (Czech Republic)",
  "Silherovice (Czech Republic)",
  "Siluvky (Czech Republic)",
  "Sivice (Czech Republic)",
  "Skalice (Czech Republic)",
  "Skalsko (Czech Republic)",
  "Skripov (Czech Republic)",
  "Skuhrov nad Belou (Czech Republic)",
  "Skutec (Czech Republic)",
  "Slany (Czech Republic)",
  "Slapanice (Czech Republic)",
  "Slapy (Czech Republic)",
  "Slavicin (Czech Republic)",
  "Slavkov u Brna (Czech Republic)",
  "Slavonice (Czech Republic)",
  "Sloup (Czech Republic)",
  "Sluknov (Czech Republic)",
  "Slusovice (Czech Republic)",
  "Smirice (Czech Republic)",
  "Smrzovka (Czech Republic)",
  "Snedovice (Czech Republic)",
  "Snezne (Czech Republic)",
  "Sobeslav (Czech Republic)",
  "Sobotka (Czech Republic)",
  "Sokolov (Czech Republic)",
  "Spalene Porici (Czech Republic)",
  "Spytihnev (Czech Republic)",
  "Stalkov (Czech Republic)",
  "Stankov (Czech Republic)",
  "Stara Paka (Czech Republic)",
  "Stara Voda (Czech Republic)",
  "Stare Mesto (Czech Republic)",
  "Stare Sedliste (Czech Republic)",
  "Starec (Czech Republic)",
  "Starovice (Czech Republic)",
  "Starovicky (Czech Republic)",
  "Stary Bydzov (Czech Republic)",
  "Statenice (Czech Republic)",
  "Stenovice (Czech Republic)",
  "Stepanov (Czech Republic)",
  "Sterboholy (Czech Republic)",
  "Sternberk (Czech Republic)",
  "Steti (Czech Republic)",
  "Stezery (Czech Republic)",
  "Stitina (Czech Republic)",
  "Stity (Czech Republic)",
  "Stochov (Czech Republic)",
  "Stod (Czech Republic)",
  "Stoky (Czech Republic)",
  "Strachotice (Czech Republic)",
  "Strachotin (Czech Republic)",
  "Strakonice (Czech Republic)",
  "Strancice (Czech Republic)",
  "Strasice (Czech Republic)",
  "Straz nad Nisou (Czech Republic)",
  "Straz pod Ralskem (Czech Republic)",
  "Strazek (Czech Republic)",
  "Straznice (Czech Republic)",
  "Strelice (Czech Republic)",
  "Stribrec (Czech Republic)",
  "Stribrna Skalice (Czech Republic)",
  "Stribro (Czech Republic)",
  "Stritez nad Ludinou (Czech Republic)",
  "Strunkovice nad Blanici (Czech Republic)",
  "Studenka (Czech Republic)",
  "Sucha Loz (Czech Republic)",
  "Sudkov (Czech Republic)",
  "Sumice (Czech Republic)",
  "Sumperk (Czech Republic)",
  "Susice (Czech Republic)",
  "Svatava (Czech Republic)",
  "Svaty Jan nad Malsi (Czech Republic)",
  "Svetla nad Sazavou (Czech Republic)",
  "Sviadnov (Czech Republic)",
  "Svihov (Czech Republic)",
  "Svitavka (Czech Republic)",
  "Svitavy (Czech Republic)",
  "Svojek (Czech Republic)",
  "Svojkov (Czech Republic)",
  "Syrovice (Czech Republic)",
  "Tachlovice (Czech Republic)",
  "Tachov (Czech Republic)",
  "Tehov (Czech Republic)",
  "Telc (Czech Republic)",
  "Telnice (Czech Republic)",
  "Temice (Czech Republic)",
  "Teplice (Czech Republic)",
  "Tesetice (Czech Republic)",
  "Tetin (Czech Republic)",
  "Tisice (Czech Republic)",
  "Tisnov (Czech Republic)",
  "Tlumacov (Czech Republic)",
  "Tman (Czech Republic)",
  "Tosovice (Czech Republic)",
  "Trebechovice pod Orebem (Czech Republic)",
  "Trebestovice (Czech Republic)",
  "Trebon (Czech Republic)",
  "Tremesna (Czech Republic)",
  "Tremosna (Czech Republic)",
  "Trhovy Stepanov (Czech Republic)",
  "Trinec (Czech Republic)",
  "Trmice (Czech Republic)",
  "Troja (Czech Republic)",
  "Trstenice (Czech Republic)",
  "Trutnov (Czech Republic)",
  "Tucapy (Czech Republic)",
  "Tuchlovice (Czech Republic)",
  "Tuchomerice (Czech Republic)",
  "Tupesy (Czech Republic)",
  "Turnov (Czech Republic)",
  "Tursko (Czech Republic)",
  "Tvarozna (Czech Republic)",
  "Tvrdonice (Czech Republic)",
  "Tynec nad Sazavou (Czech Republic)",
  "Tyniste nad Orlici (Czech Republic)",
  "Uhersky Brod (Czech Republic)",
  "Uhersky Ostroh (Czech Republic)",
  "Uhonice (Czech Republic)",
  "Uhrice (Czech Republic)",
  "Ujezd (Czech Republic)",
  "Ujezd nad Lesy (Czech Republic)",
  "Unetice (Czech Republic)",
  "Unicov (Czech Republic)",
  "Usti nad Orlici (Czech Republic)",
  "Ustin (Czech Republic)",
  "Uvaly (Czech Republic)",
  "Uzice (Czech Republic)",
  "Vacenovice (Czech Republic)",
  "Valasske Klobouky (Czech Republic)",
  "Valasske Mezirici (Czech Republic)",
  "Valtice (Czech Republic)",
  "Vamberk (Czech Republic)",
  "Varnsdorf (Czech Republic)",
  "Vcelna (Czech Republic)",
  "Velehrad (Czech Republic)",
  "Velesin (Czech Republic)",
  "Velesovice (Czech Republic)",
  "Velka Bites (Czech Republic)",
  "Velka Bystrice (Czech Republic)",
  "Velka Dobra (Czech Republic)",
  "Velka Jesenice (Czech Republic)",
  "Velka Polom (Czech Republic)",
  "Velke Albrechtice (Czech Republic)",
  "Velke Brezno (Czech Republic)",
  "Velke Heraltice (Czech Republic)",
  "Velke Hydcice (Czech Republic)",
  "Velke Losiny (Czech Republic)",
  "Velke Mezirici (Czech Republic)",
  "Velke Opatovice (Czech Republic)",
  "Velke Popovice (Czech Republic)",
  "Velke Prilepy (Czech Republic)",
  "Velke Svatonovice (Czech Republic)",
  "Velky (Czech Republic)",
  "Velky Borek (Czech Republic)",
  "Velky Osek (Czech Republic)",
  "Veltrusy (Czech Republic)",
  "Velvary (Czech Republic)",
  "Vendryne (Czech Republic)",
  "Vernovice (Czech Republic)",
  "Veseli nad Luznici (Czech Republic)",
  "Veseli nad Moravou (Czech Republic)",
  "Veverska Bityska (Czech Republic)",
  "Veverske Kninice (Czech Republic)",
  "Vidce (Czech Republic)",
  "Vimperk (Czech Republic)",
  "Vinarice (Czech Republic)",
  "Vinicne Sumice (Czech Republic)",
  "Visnove (Czech Republic)",
  "Vitkov (Czech Republic)",
  "Vizovice (Czech Republic)",
  "Vlachovice (Czech Republic)",
  "Vladislav (Czech Republic)",
  "Vlasim (Czech Republic)",
  "Vlastislav (Czech Republic)",
  "Vlcovice (Czech Republic)",
  "Vochov (Czech Republic)",
  "Vodnany (Czech Republic)",
  "Vojkovice (Czech Republic)",
  "Volyne (Czech Republic)",
  "Votice (Czech Republic)",
  "Vracov (Czech Republic)",
  "Vrane nad Vltavou (Czech Republic)",
  "Vratimov (Czech Republic)",
  "Vratkov (Czech Republic)",
  "Vrbice (Czech Republic)",
  "Vrbno pod Pradedem (Czech Republic)",
  "Vrchlabi (Czech Republic)",
  "Vresina (Czech Republic)",
  "Vsechovice (Czech Republic)",
  "Vselibice (Czech Republic)",
  "Vsenory (Czech Republic)",
  "Vsestary (Czech Republic)",
  "Vsetaty (Czech Republic)",
  "Vsetin (Czech Republic)",
  "Vtelno (Czech Republic)",
  "Vyprachtice (Czech Republic)",
  "Vysker (Czech Republic)",
  "Vysoka (Czech Republic)",
  "Vysoka nad Labem (Czech Republic)",
  "Vysoke Myto (Czech Republic)",
  "Vysoke Veseli (Czech Republic)",
  "Zabcice (Czech Republic)",
  "Zabreh (Czech Republic)",
  "Zadni Treban (Czech Republic)",
  "Zajeci (Czech Republic)",
  "Zamberk (Czech Republic)",
  "Zampach (Czech Republic)",
  "Zandov (Czech Republic)",
  "Zasova (Czech Republic)",
  "Zatcany (Czech Republic)",
  "Zborovice (Czech Republic)",
  "Zbuch (Czech Republic)",
  "Zbysov (Czech Republic)",
  "Zdanice (Czech Republic)",
  "Zdeslav (Czech Republic)",
  "Zdiby (Czech Republic)",
  "Zdice (Czech Republic)",
  "Zdirec (Czech Republic)",
  "Zdounky (Czech Republic)",
  "Zehun (Czech Republic)",
  "Zelenec (Czech Republic)",
  "Zelenka (Czech Republic)",
  "Zelesice (Czech Republic)",
  "Zeletava (Czech Republic)",
  "Zeleznice (Czech Republic)",
  "Zelezny Brod (Czech Republic)",
  "Zeliv (Czech Republic)",
  "Zeravice (Czech Republic)",
  "Zidlochovice (Czech Republic)",
  "Zilina (Czech Republic)",
  "Zipotin (Czech Republic)",
  "Zizkov (Czech Republic)",
  "Zlata (Czech Republic)",
  "Zlate Hory (Czech Republic)",
  "Zlicin (Czech Republic)",
  "Zliv (Czech Republic)",
  "Znojmo (Czech Republic)",
  "Zubri (Czech Republic)",
  "Zulova (Czech Republic)",
  "Zvole (Czech Republic)",
  "Denmark",
  "Aabenraa (Denmark)",
  "Aabybro (Denmark)",
  "Aalborg (Denmark)",
  "Aarhus (Denmark)",
  "Aars (Denmark)",
  "Abyhoj (Denmark)",
  "Agedrup (Denmark)",
  "Agerbaek (Denmark)",
  "Agerskov (Denmark)",
  "Akirkeby (Denmark)",
  "Albaek (Denmark)",
  "Albertslund (Denmark)",
  "Ale (Denmark)",
  "Alestrup (Denmark)",
  "Aller (Denmark)",
  "Allerod (Denmark)",
  "Allingabro (Denmark)",
  "Allinge (Denmark)",
  "Almind (Denmark)",
  "Alsgarde (Denmark)",
  "Alslev (Denmark)",
  "Anholt (Denmark)",
  "Ans (Denmark)",
  "Ansager (Denmark)",
  "Arden (Denmark)",
  "Arre (Denmark)",
  "Arslev (Denmark)",
  "Arup (Denmark)",
  "Asa (Denmark)",
  "Askeby (Denmark)",
  "Asnaes (Denmark)",
  "Asperup (Denmark)",
  "Assens (Denmark)",
  "Astrup (Denmark)",
  "Augustenborg (Denmark)",
  "Auning (Denmark)",
  "Avlum (Denmark)",
  "Baekke (Denmark)",
  "Baekmarksbro (Denmark)",
  "Baelum (Denmark)",
  "Bagsvaerd (Denmark)",
  "Balle (Denmark)",
  "Ballerup (Denmark)",
  "Bandholm (Denmark)",
  "Baring (Denmark)",
  "Barrit (Denmark)",
  "Beder (Denmark)",
  "Bedsted (Denmark)",
  "Bevtoft (Denmark)",
  "Billund (Denmark)",
  "Bjaert (Denmark)",
  "Bjaeverskov (Denmark)",
  "Bjerringbro (Denmark)",
  "Blavand (Denmark)",
  "Blokhus (Denmark)",
  "Blommenslyst (Denmark)",
  "Boeslunde (Denmark)",
  "Bogense (Denmark)",
  "Bogo By (Denmark)",
  "Bolderslev (Denmark)",
  "Bording Stationsby (Denmark)",
  "Borkop (Denmark)",
  "Borre (Denmark)",
  "Borum (Denmark)",
  "Borup (Denmark)",
  "Bovlingbjerg (Denmark)",
  "Bovrup (Denmark)",
  "Brabrand (Denmark)",
  "Braedstrup (Denmark)",
  "Bramming (Denmark)",
  "Brande (Denmark)",
  "Branderup (Denmark)",
  "Bredballe (Denmark)",
  "Bredebro (Denmark)",
  "Bredsten (Denmark)",
  "Brejning (Denmark)",
  "Brenderup (Denmark)",
  "Broager (Denmark)",
  "Brobyvaerk (Denmark)",
  "Brondby Strand (Denmark)",
  "Bronshoj (Denmark)",
  "Brorup (Denmark)",
  "Brovst (Denmark)",
  "Bruunshab (Denmark)",
  "Bryrup (Denmark)",
  "Bylderup-Bov (Denmark)",
  "Charlottenlund (Denmark)",
  "Christiansfeld (Denmark)",
  "Copenhagen (Denmark)",
  "Dalmose (Denmark)",
  "Dannemare (Denmark)",
  "Daugard (Denmark)",
  "Dianalund (Denmark)",
  "Dong (Denmark)",
  "Dronninglund (Denmark)",
  "Dronningmolle (Denmark)",
  "Dybvad (Denmark)",
  "Ebberup (Denmark)",
  "Ebeltoft (Denmark)",
  "Ega (Denmark)",
  "Egernsund (Denmark)",
  "Egtved (Denmark)",
  "Ejby (Denmark)",
  "Ejstrup (Denmark)",
  "Elsinore (Denmark)",
  "Engesvang (Denmark)",
  "Errindlev (Denmark)",
  "Erslev (Denmark)",
  "Esbjerg (Denmark)",
  "Eskebjerg (Denmark)",
  "Eskilstrup (Denmark)",
  "Espergaerde (Denmark)",
  "Faaborg (Denmark)",
  "Fakse (Denmark)",
  "Farevejle (Denmark)",
  "Farum (Denmark)",
  "Farup (Denmark)",
  "Farvang (Denmark)",
  "Fensmark (Denmark)",
  "Ferritslev (Denmark)",
  "Ferslev (Denmark)",
  "Fjenneslev (Denmark)",
  "Fjerritslev (Denmark)",
  "Flauenskjold (Denmark)",
  "Flemming (Denmark)",
  "Follenslev (Denmark)",
  "Fovling (Denmark)",
  "Fredensborg (Denmark)",
  "Fredericia (Denmark)",
  "Frederiksberg (Denmark)",
  "Frederiksdal (Denmark)",
  "Frederikshavn (Denmark)",
  "Frederikssund (Denmark)",
  "Frorup (Denmark)",
  "Frostrup (Denmark)",
  "Fuglebjerg (Denmark)",
  "Gadbjerg (Denmark)",
  "Gadstrup (Denmark)",
  "Galten (Denmark)",
  "Gammel Svebolle (Denmark)",
  "Gandrup (Denmark)",
  "Ganlose (Denmark)",
  "Gedved (Denmark)",
  "Gelsted (Denmark)",
  "Gentofte (Denmark)",
  "Gesten (Denmark)",
  "Gilleleje (Denmark)",
  "Gislev (Denmark)",
  "Gislinge (Denmark)",
  "Gistrup (Denmark)",
  "Give (Denmark)",
  "Gjerlev (Denmark)",
  "Gjern (Denmark)",
  "Gjol (Denmark)",
  "Gladsaxe (Denmark)",
  "Glamsbjerg (Denmark)",
  "Glejbjerg (Denmark)",
  "Glesborg (Denmark)",
  "Glostrup (Denmark)",
  "Glumso (Denmark)",
  "Glyngore (Denmark)",
  "Gording (Denmark)",
  "Gorlose (Denmark)",
  "Graested (Denmark)",
  "Gram (Denmark)",
  "Gredstedbro (Denmark)",
  "Greve (Denmark)",
  "Greve Strand (Denmark)",
  "Grevinge (Denmark)",
  "Grindsted (Denmark)",
  "Gudbjerg (Denmark)",
  "Gudhjem (Denmark)",
  "Gudme (Denmark)",
  "Guldborg (Denmark)",
  "Haderslev (Denmark)",
  "Haderup (Denmark)",
  "Hadsten (Denmark)",
  "Hadsund (Denmark)",
  "Hals (Denmark)",
  "Hammel (Denmark)",
  "Hammershoj (Denmark)",
  "Hampen (Denmark)",
  "Hanstholm (Denmark)",
  "Harboore (Denmark)",
  "Harby (Denmark)",
  "Harlev (Denmark)",
  "Harndrup (Denmark)",
  "Harpelunde (Denmark)",
  "Hasle (Denmark)",
  "Haslev (Denmark)",
  "Hasselager (Denmark)",
  "Havdrup (Denmark)",
  "Havndal (Denmark)",
  "Hedehusene (Denmark)",
  "Hedensted (Denmark)",
  "Hejls (Denmark)",
  "Hejnsvig (Denmark)",
  "Hellebaek (Denmark)",
  "Hellerup (Denmark)",
  "Helsinge (Denmark)",
  "Hemmet (Denmark)",
  "Henne (Denmark)",
  "Henne Strand (Denmark)",
  "Herfolge (Denmark)",
  "Herlev (Denmark)",
  "Herlufmagle (Denmark)",
  "Herning (Denmark)",
  "Hesselager (Denmark)",
  "Himmelev (Denmark)",
  "Hinnerup (Denmark)",
  "Hirtshals (Denmark)",
  "Hjallerup (Denmark)",
  "Hjerm (Denmark)",
  "Hjortshoj (Denmark)",
  "Hobro (Denmark)",
  "Hodsager (Denmark)",
  "Hojbjerg (Denmark)",
  "Hojby (Denmark)",
  "Hoje Tastrup (Denmark)",
  "Hojslev (Denmark)",
  "Holbaek (Denmark)",
  "Holeby (Denmark)",
  "Holme (Denmark)",
  "Holme-Olstrup (Denmark)",
  "Holstebro (Denmark)",
  "Holsted (Denmark)",
  "Holte (Denmark)",
  "Hoptrup (Denmark)",
  "Horbelev (Denmark)",
  "Hornbaek (Denmark)",
  "Horning (Denmark)",
  "Hornslet (Denmark)",
  "Hornsyld (Denmark)",
  "Horsens (Denmark)",
  "Horslunde (Denmark)",
  "Horve (Denmark)",
  "Hosterkob (Denmark)",
  "Hou (Denmark)",
  "Hovedgard (Denmark)",
  "Humble (Denmark)",
  "Humlebaek (Denmark)",
  "Hundested (Denmark)",
  "Hundslund (Denmark)",
  "Hurup (Denmark)",
  "Hvide Sande (Denmark)",
  "Hvidovre (Denmark)",
  "Hyllinge (Denmark)",
  "Idestrup (Denmark)",
  "Ikast (Denmark)",
  "Janderup (Denmark)",
  "Jelling (Denmark)",
  "Jerslev (Denmark)",
  "Jerup (Denmark)",
  "Jordrup (Denmark)",
  "Jorlunde (Denmark)",
  "Juelsminde (Denmark)",
  "Jyderup (Denmark)",
  "Jyllinge (Denmark)",
  "Jystrup (Denmark)",
  "Kaldred (Denmark)",
  "Kalundborg (Denmark)",
  "Kalvehave (Denmark)",
  "Karise (Denmark)",
  "Karlslunde (Denmark)",
  "Karlstrup (Denmark)",
  "Karrebaeksminde (Denmark)",
  "Karup (Denmark)",
  "Kastrup (Denmark)",
  "Kejlstrup (Denmark)",
  "Kerteminde (Denmark)",
  "Kettinge (Denmark)",
  "Kibaek (Denmark)",
  "Kirke Eskilstrup (Denmark)",
  "Kirke Hvalso (Denmark)",
  "Kirke Saby (Denmark)",
  "Kirke-Hyllinge (Denmark)",
  "Kjargaard (Denmark)",
  "Kjellerup (Denmark)",
  "Klampenborg (Denmark)",
  "Klarup (Denmark)",
  "Klemensker (Denmark)",
  "Klippinge (Denmark)",
  "Klokkerholm (Denmark)",
  "Klovborg (Denmark)",
  "Knebel (Denmark)",
  "Kokkedal (Denmark)",
  "Koldby (Denmark)",
  "Kolding (Denmark)",
  "Kolind (Denmark)",
  "Kolsen (Denmark)",
  "Kongens Lyngby (Denmark)",
  "Kongerslev (Denmark)",
  "Krusa (Denmark)",
  "Kvaerndrup (Denmark)",
  "Kvistgard (Denmark)",
  "Kvols (Denmark)",
  "Laeso (Denmark)",
  "Lambjerg (Denmark)",
  "Langa (Denmark)",
  "Langeskov (Denmark)",
  "Lasby (Denmark)",
  "Laurbjerg (Denmark)",
  "Ledoje (Denmark)",
  "Lejre (Denmark)",
  "Lem (Denmark)",
  "Lemming (Denmark)",
  "Lemvig (Denmark)",
  "Lille Skensved (Denmark)",
  "Liseleje (Denmark)",
  "Logstrup (Denmark)",
  "Lokken (Denmark)",
  "Losning (Denmark)",
  "Lundby Stationsby (Denmark)",
  "Lunderskov (Denmark)",
  "Lyngby (Denmark)",
  "Lynge (Denmark)",
  "Lystrup (Denmark)",
  "Malling (Denmark)",
  "Malov (Denmark)",
  "Mariager (Denmark)",
  "Maribo (Denmark)",
  "Marslet (Denmark)",
  "Marslev (Denmark)",
  "Marstal (Denmark)",
  "Martofte (Denmark)",
  "Melby (Denmark)",
  "Mern (Denmark)",
  "Middelfart (Denmark)",
  "Millinge (Denmark)",
  "Moldrup (Denmark)",
  "Morke (Denmark)",
  "Morkov (Denmark)",
  "Morud (Denmark)",
  "Munke Bjergby (Denmark)",
  "Munkebo (Denmark)",
  "Naerum (Denmark)",
  "Naesby (Denmark)",
  "Naestved (Denmark)",
  "Nakskov (Denmark)",
  "Nexo (Denmark)",
  "Nibe (Denmark)",
  "Nim (Denmark)",
  "Nimtofte (Denmark)",
  "Niva (Denmark)",
  "No (Denmark)",
  "Nodebo (Denmark)",
  "Norager (Denmark)",
  "Nordborg (Denmark)",
  "Nordby (Denmark)",
  "Nordenskov (Denmark)",
  "Norre Aby (Denmark)",
  "Norre Asmindrup (Denmark)",
  "Norre Bindslev (Denmark)",
  "Norre Nebel (Denmark)",
  "Norre Snede (Denmark)",
  "Norreballe (Denmark)",
  "Norresundby (Denmark)",
  "Nyborg (Denmark)",
  "Nygard (Denmark)",
  "Nykobing Falster (Denmark)",
  "Nykobing Mors (Denmark)",
  "Nykobing Sjaelland (Denmark)",
  "Nysted (Denmark)",
  "Odder (Denmark)",
  "Oddesund Syd (Denmark)",
  "Odense (Denmark)",
  "Odsted (Denmark)",
  "Oksbol (Denmark)",
  "Olsted (Denmark)",
  "Olstykke (Denmark)",
  "Omme (Denmark)",
  "Orbaek (Denmark)",
  "Ornhoj (Denmark)",
  "Orsted (Denmark)",
  "Orum (Denmark)",
  "Osby (Denmark)",
  "Ostbirk (Denmark)",
  "Oster Assels (Denmark)",
  "Ostermarie (Denmark)",
  "Ostervra (Denmark)",
  "Otterup (Denmark)",
  "Oure (Denmark)",
  "Padborg (Denmark)",
  "Pandrup (Denmark)",
  "Ramlose (Denmark)",
  "Randbol (Denmark)",
  "Randers (Denmark)",
  "Ranum (Denmark)",
  "Rebaek (Denmark)",
  "Refsvindinge (Denmark)",
  "Regstrup (Denmark)",
  "Ribe (Denmark)",
  "Ringe (Denmark)",
  "Ringsted (Denmark)",
  "Risskov (Denmark)",
  "Rodding (Denmark)",
  "Rodkaersbro (Denmark)",
  "Rodvig (Denmark)",
  "Ronde (Denmark)",
  "Roskilde (Denmark)",
  "Roslev (Denmark)",
  "Rude (Denmark)",
  "Ruds-Vedby (Denmark)",
  "Rungsted (Denmark)",
  "Ry (Denmark)",
  "Rynkeby (Denmark)",
  "Ryomgard (Denmark)",
  "Ryslinge (Denmark)",
  "Sabro (Denmark)",
  "Saeby (Denmark)",
  "Salten (Denmark)",
  "Saltum (Denmark)",
  "Sandager (Denmark)",
  "Sanderum (Denmark)",
  "Sandved (Denmark)",
  "Sherrebek (Denmark)",
  "Silkeborg (Denmark)",
  "Sindal (Denmark)",
  "Sjolund (Denmark)",
  "Skaelskor (Denmark)",
  "Skaering (Denmark)",
  "Skagen (Denmark)",
  "Skals (Denmark)",
  "Skamby (Denmark)",
  "Skanderborg (Denmark)",
  "Skarup (Denmark)",
  "Skibby (Denmark)",
  "Skive (Denmark)",
  "Skjern (Denmark)",
  "Skodsborg (Denmark)",
  "Skodstrup (Denmark)",
  "Skovlunde (Denmark)",
  "Slagelse (Denmark)",
  "Slangerup (Denmark)",
  "Slet (Denmark)",
  "Snedsted (Denmark)",
  "Snekkersten (Denmark)",
  "Snertinge (Denmark)",
  "Soborg (Denmark)",
  "Soby (Denmark)",
  "Soesmarke (Denmark)",
  "Solbjerg (Denmark)",
  "Sollested (Denmark)",
  "Solrod (Denmark)",
  "Sommersted (Denmark)",
  "Sonder Omme (Denmark)",
  "Sonder Stenderup (Denmark)",
  "Sonderso (Denmark)",
  "Soro (Denmark)",
  "Sorring (Denmark)",
  "Sorvad (Denmark)",
  "Spentrup (Denmark)",
  "Spjald (Denmark)",
  "Spottrup (Denmark)",
  "Stakroge (Denmark)",
  "Stege (Denmark)",
  "Stenderup (Denmark)",
  "Stenlille (Denmark)",
  "Stenlose (Denmark)",
  "Stenstrup (Denmark)",
  "Stensved (Denmark)",
  "Stoholm (Denmark)",
  "Stokkemarke (Denmark)",
  "Store Fuglede (Denmark)",
  "Store Heddinge (Denmark)",
  "Store Merlose (Denmark)",
  "Storvorde (Denmark)",
  "Stouby (Denmark)",
  "Stovring (Denmark)",
  "Strandby (Denmark)",
  "Strib (Denmark)",
  "Stroby (Denmark)",
  "Struer (Denmark)",
  "Suldrup (Denmark)",
  "Sulsted (Denmark)",
  "Sundby (Denmark)",
  "Sunds (Denmark)",
  "Svaneke (Denmark)",
  "Svanninge (Denmark)",
  "Svendborg (Denmark)",
  "Svenstrup (Denmark)",
  "Svinninge (Denmark)",
  "Taastrup (Denmark)",
  "Tagerup (Denmark)",
  "Tappernoje (Denmark)",
  "Tarbaek (Denmark)",
  "Tarm (Denmark)",
  "Tars (Denmark)",
  "Tejn (Denmark)",
  "Terndrup (Denmark)",
  "Them (Denmark)",
  "Thisted (Denmark)",
  "Thorso (Denmark)",
  "Tikob (Denmark)",
  "Tilst (Denmark)",
  "Tim (Denmark)",
  "Tinglev (Denmark)",
  "Tistrup (Denmark)",
  "Tisvildeleje (Denmark)",
  "Tjaereborg (Denmark)",
  "Tjebberup (Denmark)",
  "Toftlund (Denmark)",
  "Tollose (Denmark)",
  "Tommerup (Denmark)",
  "Toreby (Denmark)",
  "Tornby (Denmark)",
  "Torrig (Denmark)",
  "Torring (Denmark)",
  "Tranbjerg (Denmark)",
  "Tranebjerg (Denmark)",
  "Tranekaer (Denmark)",
  "Trelde (Denmark)",
  "Trige (Denmark)",
  "TRUE (Denmark)",
  "Trustrup (Denmark)",
  "Tullebolle (Denmark)",
  "Tune (Denmark)",
  "Tureby (Denmark)",
  "Tylstrup (Denmark)",
  "Ugerlose (Denmark)",
  "Uldum (Denmark)",
  "Ulfborg (Denmark)",
  "Ullerslev (Denmark)",
  "Ulstrup (Denmark)",
  "Vadum (Denmark)",
  "Vaeggerlose (Denmark)",
  "Vaerlose (Denmark)",
  "Vamdrup (Denmark)",
  "Vandel (Denmark)",
  "Vantinge (Denmark)",
  "Varde (Denmark)",
  "Vasby (Denmark)",
  "Vebbestrup (Denmark)",
  "Vedbaek (Denmark)",
  "Vederso (Denmark)",
  "Veflinge (Denmark)",
  "Vejby (Denmark)",
  "Vejen (Denmark)",
  "Vejle (Denmark)",
  "Vejstrup (Denmark)",
  "Vekso (Denmark)",
  "Vemb (Denmark)",
  "Vemmelev (Denmark)",
  "Verninge (Denmark)",
  "Vestbjerg (Denmark)",
  "Vester Hassing (Denmark)",
  "Vester-Skerninge (Denmark)",
  "Vesterborg (Denmark)",
  "Vestervig (Denmark)",
  "Viborg (Denmark)",
  "Viby (Denmark)",
  "Vig (Denmark)",
  "Vildbjerg (Denmark)",
  "Vils (Denmark)",
  "Vinderup (Denmark)",
  "Vinkel (Denmark)",
  "Vipperod (Denmark)",
  "Virring (Denmark)",
  "Virum (Denmark)",
  "Vissenbjerg (Denmark)",
  "Viuf (Denmark)",
  "Vivild (Denmark)",
  "Vodskov (Denmark)",
  "Vojens (Denmark)",
  "Vorbasse (Denmark)",
  "Vordingborg (Denmark)",
  "Vra (Denmark)",
  "Dominican Republic",
  "Arenazo (Dominican Republic)",
  "Bavaro (Dominican Republic)",
  "Boca Chica (Dominican Republic)",
  "Cabarete (Dominican Republic)",
  "Cotui (Dominican Republic)",
  "Dominica (Dominican Republic)",
  "Guaricano (Dominican Republic)",
  "Hato Mayor del Rey (Dominican Republic)",
  "Jimani (Dominican Republic)",
  "La Romana (Dominican Republic)",
  "Los Alcarrizos (Dominican Republic)",
  "Los Prados (Dominican Republic)",
  "Moca (Dominican Republic)",
  "Pedernales (Dominican Republic)",
  "Puerto Plata (Dominican Republic)",
  "Punta Cana (Dominican Republic)",
  "Sabaneta (Dominican Republic)",
  "San Cristobal (Dominican Republic)",
  "San Fernando de Monte Cristi (Dominican Republic)",
  "San Jose de Ocoa (Dominican Republic)",
  "Santa Cruz de Barahona (Dominican Republic)",
  "Santiago de los Caballeros (Dominican Republic)",
  "Santo Domingo (Dominican Republic)",
  "Ecuador",
  "Ambato (Ecuador)",
  "Atacames (Ecuador)",
  "Atuntaqui (Ecuador)",
  "Bahia de Caraquez (Ecuador)",
  "Banos (Ecuador)",
  "Calderon (Ecuador)",
  "Cayambe (Ecuador)",
  "Cuenca (Ecuador)",
  "Daule (Ecuador)",
  "El Carmen (Ecuador)",
  "El Naranjal (Ecuador)",
  "Esmeraldas (Ecuador)",
  "Florida (Ecuador)",
  "General Leonidas Plaza Gutierrez (Ecuador)",
  "Guabo (Ecuador)",
  "Gualaceo (Ecuador)",
  "Guamote (Ecuador)",
  "Guayaquil (Ecuador)",
  "Guayas (Ecuador)",
  "Guayllabamba (Ecuador)",
  "Hacienda Bolivia (Ecuador)",
  "Hacienda Duran (Ecuador)",
  "Hacienda Ibarra (Ecuador)",
  "Hacienda Santa Catalina (Ecuador)",
  "Huaquillas (Ecuador)",
  "Ibarra (Ecuador)",
  "Jipijapa (Ecuador)",
  "La Esperanza (Ecuador)",
  "La Libertad (Ecuador)",
  "La Troncal (Ecuador)",
  "Latacunga (Ecuador)",
  "Loja (Ecuador)",
  "Loreto (Ecuador)",
  "Macara (Ecuador)",
  "Macas (Ecuador)",
  "Machachi (Ecuador)",
  "Machala (Ecuador)",
  "Manta (Ecuador)",
  "Milagro (Ecuador)",
  "Montecristi (Ecuador)",
  "Nabon (Ecuador)",
  "Olmedo (Ecuador)",
  "Otavalo (Ecuador)",
  "Paccha (Ecuador)",
  "Pasaje (Ecuador)",
  "Pelileo (Ecuador)",
  "Pichincha (Ecuador)",
  "Playas (Ecuador)",
  "Portoviejo (Ecuador)",
  "Puerto Francisco de Orellana (Ecuador)",
  "Puyo (Ecuador)",
  "Quevedo (Ecuador)",
  "Quito (Ecuador)",
  "Riobamba (Ecuador)",
  "Salinas (Ecuador)",
  "Samborondon (Ecuador)",
  "San Miguel (Ecuador)",
  "San Rafael (Ecuador)",
  "Santa Cruz (Ecuador)",
  "Santa Elena (Ecuador)",
  "Santo Domingo de los Colorados (Ecuador)",
  "Sucre (Ecuador)",
  "Tababela (Ecuador)",
  "Tarapoa (Ecuador)",
  "Tena (Ecuador)",
  "Vinces (Ecuador)",
  "Zamora (Ecuador)",
  "Zaruma (Ecuador)",
  "Zumba (Ecuador)",
  "Egypt",
  "Abu Hammad (Egypt)",
  "Al Mahallah al Kubra (Egypt)",
  "Al Mansurah (Egypt)",
  "Al Marj (Egypt)",
  "Alexandria (Egypt)",
  "Almazah (Egypt)",
  "Ar Rawdah (Egypt)",
  "Assiut (Egypt)",
  "Az Zamalik (Egypt)",
  "Badr (Egypt)",
  "Banha (Egypt)",
  "Bani Suwayf (Egypt)",
  "Cairo (Egypt)",
  "Damietta (Egypt)",
  "Faraskur (Egypt)",
  "Flaminj (Egypt)",
  "Giza (Egypt)",
  "Heliopolis (Egypt)",
  "Helwan (Egypt)",
  "Hurghada (Egypt)",
  "Ismailia (Egypt)",
  "Kafr ash Shaykh (Egypt)",
  "Luxor (Egypt)",
  "Madinat an Nasr (Egypt)",
  "Madinat as Sadis min Uktubar (Egypt)",
  "Minya (Egypt)",
  "Nasr (Egypt)",
  "New Cairo (Egypt)",
  "Port Said (Egypt)",
  "Rafah (Egypt)",
  "Ramsis (Egypt)",
  "Sadat (Egypt)",
  "Shirbin (Egypt)",
  "Shubra (Egypt)",
  "Sohag (Egypt)",
  "Suez (Egypt)",
  "Tanta (Egypt)",
  "Toukh (Egypt)",
  "Zagazig (Egypt)",
  "El Salvador",
  "Ahuachapan (El Salvador)",
  "Antiguo Cuscatlan (El Salvador)",
  "Apaneca (El Salvador)",
  "Apopa (El Salvador)",
  "Ayutuxtepeque (El Salvador)",
  "Botoncillal El Botoncillo (El Salvador)",
  "Coatepeque (El Salvador)",
  "Colon (El Salvador)",
  "Colonia Escalon (El Salvador)",
  "Cuscatancingo (El Salvador)",
  "Delgado (El Salvador)",
  "Gigante (El Salvador)",
  "Guazapa (El Salvador)",
  "Ilopango (El Salvador)",
  "La Libertad (El Salvador)",
  "Lourdes (El Salvador)",
  "Mejicanos (El Salvador)",
  "Nuevo Cuscatlan (El Salvador)",
  "Ozatlan (El Salvador)",
  "San Jose Villanueva (El Salvador)",
  "San Marcos (El Salvador)",
  "San Miguel (El Salvador)",
  "San Salvador (El Salvador)",
  "Santa Ana (El Salvador)",
  "Santa Tecla (El Salvador)",
  "Santiago Texacuangos (El Salvador)",
  "Sonsonate (El Salvador)",
  "Soyapango (El Salvador)",
  "Usulutan (El Salvador)",
  "Zaragoza (El Salvador)",
  "Estonia",
  "Aasmae (Estonia)",
  "Aaviku (Estonia)",
  "Aespa (Estonia)",
  "Ahtma (Estonia)",
  "Alliku (Estonia)",
  "Ambla (Estonia)",
  "Antsla (Estonia)",
  "Ardu (Estonia)",
  "Avinurme (Estonia)",
  "Elva (Estonia)",
  "Emmaste (Estonia)",
  "Haabneeme (Estonia)",
  "Haage (Estonia)",
  "Haapsalu (Estonia)",
  "Halinga (Estonia)",
  "Harju (Estonia)",
  "Harku (Estonia)",
  "Kaarma (Estonia)",
  "Kadrina (Estonia)",
  "Kehra (Estonia)",
  "Keila (Estonia)",
  "Kiili (Estonia)",
  "Kohila (Estonia)",
  "Kohtla (Estonia)",
  "Kose (Estonia)",
  "Kulna (Estonia)",
  "Kuressaare (Estonia)",
  "Kuusalu (Estonia)",
  "Laagri (Estonia)",
  "Lagedi (Estonia)",
  "Lahtse (Estonia)",
  "Lepna (Estonia)",
  "Loksa (Estonia)",
  "Loo (Estonia)",
  "Lubja (Estonia)",
  "Maardu (Estonia)",
  "Muraste (Estonia)",
  "Narva (Estonia)",
  "Otepaeae (Estonia)",
  "Paide (Estonia)",
  "Paikuse (Estonia)",
  "Paldiski (Estonia)",
  "Palivere (Estonia)",
  "Peetri (Estonia)",
  "Puhja (Estonia)",
  "Rakke (Estonia)",
  "Rakvere (Estonia)",
  "Rapla (Estonia)",
  "Saku (Estonia)",
  "Saue (Estonia)",
  "Sindi (Estonia)",
  "Somerpalu (Estonia)",
  "Syare (Estonia)",
  "Tabasalu (Estonia)",
  "Tabivere (Estonia)",
  "Tallinn (Estonia)",
  "Tamsalu (Estonia)",
  "Tapa (Estonia)",
  "Tartu (Estonia)",
  "Toila (Estonia)",
  "Vaida (Estonia)",
  "Valga (Estonia)",
  "Vara (Estonia)",
  "Venevere (Estonia)",
  "Viimsi (Estonia)",
  "Viljandi (Estonia)",
  "Voka (Estonia)",
  "Faroe Islands",
  "Argir (Faroe Islands)",
  "Glyvrar (Faroe Islands)",
  "Hvalba (Faroe Islands)",
  "Innan Glyvur (Faroe Islands)",
  "Leirvik (Faroe Islands)",
  "Saltangara (Faroe Islands)",
  "Signabour (Faroe Islands)",
  "Strendur (Faroe Islands)",
  "Finland",
  "Aapajoki (Finland)",
  "Aavasaksa (Finland)",
  "Aitoo (Finland)",
  "Akaa (Finland)",
  "Alastaro (Finland)",
  "Alaveteli (Finland)",
  "Alavieska (Finland)",
  "Alavus (Finland)",
  "Alvettula (Finland)",
  "Angelniemi (Finland)",
  "Anjala (Finland)",
  "Anttila (Finland)",
  "Askola (Finland)",
  "Aura (Finland)",
  "Bergoe (Finland)",
  "Box (Finland)",
  "Broby (Finland)",
  "Degerby (Finland)",
  "Eno (Finland)",
  "Erajarvi (Finland)",
  "Espoo (Finland)",
  "Eura (Finland)",
  "Eurajoki (Finland)",
  "Evijaervi (Finland)",
  "Evitskog (Finland)",
  "Fiskars (Finland)",
  "Forsby (Finland)",
  "Forssa (Finland)",
  "Gammelby (Finland)",
  "Haapavesi (Finland)",
  "Haeme (Finland)",
  "Haermae (Finland)",
  "Haijaa (Finland)",
  "Halikko (Finland)",
  "Halkia (Finland)",
  "Halli (Finland)",
  "Halsua (Finland)",
  "Hamina (Finland)",
  "Hammaslahti (Finland)",
  "Hanko (Finland)",
  "Harjavalta (Finland)",
  "Harviala (Finland)",
  "Hattula (Finland)",
  "Hauho (Finland)",
  "Haukipudas (Finland)",
  "Haukkala (Finland)",
  "Hausjaervi (Finland)",
  "Heimari (Finland)",
  "Heinola (Finland)",
  "Helsingby (Finland)",
  "Helsinki (Finland)",
  "Hervanta (Finland)",
  "Hiltulanlahti (Finland)",
  "Himanka (Finland)",
  "Hinthaara (Finland)",
  "Hirvensalmi (Finland)",
  "Hirvihaara (Finland)",
  "Hoegsara (Finland)",
  "Hoeytiae (Finland)",
  "Hollola (Finland)",
  "Huittinen (Finland)",
  "Humppila (Finland)",
  "Huuvari (Finland)",
  "Hyvinkaeae (Finland)",
  "Ihode (Finland)",
  "Ii (Finland)",
  "Iisalmi (Finland)",
  "Iittala (Finland)",
  "Ilkka (Finland)",
  "Ilmajoki (Finland)",
  "Ilmarinen (Finland)",
  "Ilveskylae (Finland)",
  "Imatra (Finland)",
  "Inkeroinen (Finland)",
  "Isojoki (Finland)",
  "Itaekylae (Finland)",
  "Ivalo (Finland)",
  "Jaervelae (Finland)",
  "Jakobstad (Finland)",
  "Janakkala (Finland)",
  "Joensuu (Finland)",
  "Jokela (Finland)",
  "Jokikunta (Finland)",
  "Jokimaa (Finland)",
  "Jokioinen (Finland)",
  "Jormua (Finland)",
  "Joroinen (Finland)",
  "Jorvas (Finland)",
  "Joutseno (Finland)",
  "Jurva (Finland)",
  "Juuka (Finland)",
  "Juupajoki (Finland)",
  "Jyskae (Finland)",
  "Kaakamo (Finland)",
  "Kaarina (Finland)",
  "Kaemmenniemi (Finland)",
  "Kaerrby (Finland)",
  "Kaipiainen (Finland)",
  "Kaipola (Finland)",
  "Kaivanto (Finland)",
  "Kajaani (Finland)",
  "Kalajoki (Finland)",
  "Kalanti (Finland)",
  "Kangasala (Finland)",
  "Kangasniemi (Finland)",
  "Kankaanpaeae (Finland)",
  "Kannonkoski (Finland)",
  "Kannus (Finland)",
  "Kantomaanpaeae (Finland)",
  "Kantti (Finland)",
  "Kantvik (Finland)",
  "Karhula (Finland)",
  "Karinkanta (Finland)",
  "Karjaa (Finland)",
  "Karjalohja (Finland)",
  "Karkkila (Finland)",
  "Karkku (Finland)",
  "Karstula (Finland)",
  "Karttula (Finland)",
  "Karunki (Finland)",
  "Karvia (Finland)",
  "Kaskinen (Finland)",
  "Kauhajoki (Finland)",
  "Kauhakorpi (Finland)",
  "Kauhava (Finland)",
  "Kauniainen (Finland)",
  "Kausala (Finland)",
  "Kaustinen (Finland)",
  "Kauttua (Finland)",
  "Kauvatsa (Finland)",
  "Kello (Finland)",
  "Kellokoski (Finland)",
  "Kemi (Finland)",
  "Keminmaa (Finland)",
  "Kempele (Finland)",
  "Kerava (Finland)",
  "Kerkkoo (Finland)",
  "Keuruu (Finland)",
  "Kihnioe (Finland)",
  "Kiiminki (Finland)",
  "Kiistala (Finland)",
  "Kiljava (Finland)",
  "Kimito (Finland)",
  "Kinnula (Finland)",
  "Kirjala (Finland)",
  "Kirkkonummi (Finland)",
  "Kisko (Finland)",
  "Kitee (Finland)",
  "Kiuruvesi (Finland)",
  "Kivijaervi (Finland)",
  "Klaukkala (Finland)",
  "Kokkola (Finland)",
  "Kolari (Finland)",
  "Kolinkylae (Finland)",
  "Koljonvirta (Finland)",
  "Kontiolahti (Finland)",
  "Kontiomaeki (Finland)",
  "Kopsa (Finland)",
  "Koria (Finland)",
  "Kormu (Finland)",
  "Korplax (Finland)",
  "Korppoo (Finland)",
  "Korsholm (Finland)",
  "Koskenkorva (Finland)",
  "Koskioinen (Finland)",
  "Kotka (Finland)",
  "Kouvola (Finland)",
  "Kronoby (Finland)",
  "Kruusila (Finland)",
  "Kuhmalahti (Finland)",
  "Kuhmo (Finland)",
  "Kuivakangas (Finland)",
  "Kuivaniemi (Finland)",
  "Kulho (Finland)",
  "Kuni (Finland)",
  "Kuohu (Finland)",
  "Kuopio (Finland)",
  "Kuortane (Finland)",
  "Kurikka (Finland)",
  "Kuusamo (Finland)",
  "Kuusankoski (Finland)",
  "Kuusisto (Finland)",
  "Kvevlax (Finland)",
  "Kylmaekoski (Finland)",
  "Kyroeskoski (Finland)",
  "Kyynaeroe (Finland)",
  "Laeyliaeinen (Finland)",
  "Lahela (Finland)",
  "Lahti (Finland)",
  "Laihia (Finland)",
  "Laikko (Finland)",
  "Laitikkala (Finland)",
  "Laitila (Finland)",
  "Lammi (Finland)",
  "Lampinsaari (Finland)",
  "Lapinlahti (Finland)",
  "Lappajaervi (Finland)",
  "Lappeenranta (Finland)",
  "Lapua (Finland)",
  "Larsmo (Finland)",
  "Latovainio (Finland)",
  "Laukaa (Finland)",
  "Laukkoski (Finland)",
  "Launonen (Finland)",
  "Lautiosaari (Finland)",
  "Lehmo (Finland)",
  "Lehtimaeki (Finland)",
  "Lemi (Finland)",
  "Lepaa (Finland)",
  "Lepplax (Finland)",
  "Levanto (Finland)",
  "Lieksa (Finland)",
  "Lielax (Finland)",
  "Lieto (Finland)",
  "Lievestuore (Finland)",
  "Liljendal (Finland)",
  "Liminka (Finland)",
  "Linna (Finland)",
  "Linnavuori (Finland)",
  "Liperi (Finland)",
  "Littoinen (Finland)",
  "Lohja (Finland)",
  "Lohtaja (Finland)",
  "Loimaa (Finland)",
  "Loppi (Finland)",
  "Louhi (Finland)",
  "Loviisa (Finland)",
  "Lumijoki (Finland)",
  "Luohua (Finland)",
  "Luoma-aho (Finland)",
  "Maenttae (Finland)",
  "Maentyharju (Finland)",
  "Majvik (Finland)",
  "Mankila (Finland)",
  "Mantila (Finland)",
  "Martensby (Finland)",
  "Marttila (Finland)",
  "Masala (Finland)",
  "Masku (Finland)",
  "Mattila (Finland)",
  "Mehtaekylae (Finland)",
  "Merimasku (Finland)",
  "Metsaekansa (Finland)",
  "Mieto (Finland)",
  "Mikkeli (Finland)",
  "Mouhijaervi (Finland)",
  "Muhos (Finland)",
  "Multia (Finland)",
  "Munsala (Finland)",
  "Muonio (Finland)",
  "Muurame (Finland)",
  "Muurla (Finland)",
  "Myllykoski (Finland)",
  "Mynaemaeki (Finland)",
  "Naantali (Finland)",
  "Naervijoki (Finland)",
  "Nahkela (Finland)",
  "Nakkila (Finland)",
  "Narva (Finland)",
  "Nastola (Finland)",
  "Neuvoton (Finland)",
  "Niinijoki (Finland)",
  "Niittylahti (Finland)",
  "Nivala (Finland)",
  "Nokia (Finland)",
  "Noormarkku (Finland)",
  "Nousiainen (Finland)",
  "Nukari (Finland)",
  "Nummela (Finland)",
  "Nummenkylae (Finland)",
  "Nummi (Finland)",
  "Nunnanlahti (Finland)",
  "Nuppulinna (Finland)",
  "Nurmes (Finland)",
  "Nurmo (Finland)",
  "Nuutajaervi (Finland)",
  "Nykarleby (Finland)",
  "OEvermalax (Finland)",
  "Oitti (Finland)",
  "Oja (Finland)",
  "Okskoski (Finland)",
  "Orimattila (Finland)",
  "Orivesi (Finland)",
  "Otalampi (Finland)",
  "Oulainen (Finland)",
  "Oulu (Finland)",
  "Oulunsalo (Finland)",
  "Outokumpu (Finland)",
  "Paalijaervi (Finland)",
  "Paattinen (Finland)",
  "Padasjoki (Finland)",
  "Paelkaene (Finland)",
  "Paimio (Finland)",
  "Paippinen (Finland)",
  "Palojoki (Finland)",
  "Palokka (Finland)",
  "Pankakoski (Finland)",
  "Pargas (Finland)",
  "Parkano (Finland)",
  "Parkkuu (Finland)",
  "Pattijoki (Finland)",
  "Peipohja (Finland)",
  "Pellinki (Finland)",
  "Pello (Finland)",
  "Peraelae (Finland)",
  "Peraseinajoki (Finland)",
  "Pernioe (Finland)",
  "Pertteli (Finland)",
  "Perttula (Finland)",
  "Pihtipudas (Finland)",
  "Piispanristi (Finland)",
  "Pilkanmaa (Finland)",
  "Pirkkala (Finland)",
  "Pjelax (Finland)",
  "Pockar (Finland)",
  "Poeytyae (Finland)",
  "Pohjola (Finland)",
  "Poikelus (Finland)",
  "Poitsila (Finland)",
  "Pori (Finland)",
  "Porlammi (Finland)",
  "Pornainen (Finland)",
  "Porvoo (Finland)",
  "Pukkila (Finland)",
  "Punkaharju (Finland)",
  "Punkalaidun (Finland)",
  "Puolanka (Finland)",
  "Purmo (Finland)",
  "Purola (Finland)",
  "Pusula (Finland)",
  "Puumala (Finland)",
  "Pyhaejoki (Finland)",
  "Pyhaentae (Finland)",
  "Pyhamaa (Finland)",
  "Raahe (Finland)",
  "Raisio (Finland)",
  "Rantasalmi (Finland)",
  "Raseborg (Finland)",
  "Rauma (Finland)",
  "Rautalampi (Finland)",
  "Rautjaervi (Finland)",
  "Ravijoki (Finland)",
  "Revonlahti (Finland)",
  "Riihikoski (Finland)",
  "Riistavesi (Finland)",
  "Ristiina (Finland)",
  "Roeykkae (Finland)",
  "Roismala (Finland)",
  "Rovaniemi (Finland)",
  "Ruokolahti (Finland)",
  "Ruovesi (Finland)",
  "Rusko (Finland)",
  "Ruto (Finland)",
  "Ruutana (Finland)",
  "Ryttylae (Finland)",
  "Saaksmaki (Finland)",
  "Saarijaervi (Finland)",
  "Saekylae (Finland)",
  "Saeviae (Finland)",
  "Sahalahti (Finland)",
  "Sajaniemi (Finland)",
  "Salo (Finland)",
  "Saloinen (Finland)",
  "Salomaa (Finland)",
  "Sammaljoki (Finland)",
  "Sappee (Finland)",
  "Saukkola (Finland)",
  "Saunalahti (Finland)",
  "Sauvo (Finland)",
  "Savitaipale (Finland)",
  "Savonlinna (Finland)",
  "Saynatsalo (Finland)",
  "Seinaejoki (Finland)",
  "Sievi (Finland)",
  "Siikainen (Finland)",
  "Siivikkala (Finland)",
  "Simoniemi (Finland)",
  "Simpele (Finland)",
  "Sipoo (Finland)",
  "Siuntio (Finland)",
  "Siuro (Finland)",
  "Skinnarby (Finland)",
  "Skuru (Finland)",
  "Soederkulla (Finland)",
  "Soeoermarkku (Finland)",
  "Solberg (Finland)",
  "Somerniemi (Finland)",
  "Somero (Finland)",
  "Sotkamo (Finland)",
  "Sotkuma (Finland)",
  "Suhmura (Finland)",
  "Sundby (Finland)",
  "Sundom (Finland)",
  "Suolahti (Finland)",
  "Suomusjaervi (Finland)",
  "Suomussalmi (Finland)",
  "Suonenjoki (Finland)",
  "Svarta (Finland)",
  "Sykaeraeinen (Finland)",
  "Taavetti (Finland)",
  "Taipalsaari (Finland)",
  "Tammela (Finland)",
  "Tampella (Finland)",
  "Tampere (Finland)",
  "Tarttila (Finland)",
  "Tarvasjoki (Finland)",
  "Tenala (Finland)",
  "Terjaerv (Finland)",
  "Tervajoki (Finland)",
  "Tervakoski (Finland)",
  "Tervalampi (Finland)",
  "Teuva (Finland)",
  "Tikkakoski (Finland)",
  "Toelby (Finland)",
  "Toholampi (Finland)",
  "Toijala (Finland)",
  "Toivala (Finland)",
  "Tolkis (Finland)",
  "Tornio (Finland)",
  "Tortinmaeki (Finland)",
  "Tottijarvi (Finland)",
  "Tuomikylae (Finland)",
  "Turenki (Finland)",
  "Turku (Finland)",
  "Tuulos (Finland)",
  "Tuuri (Finland)",
  "Tuusula (Finland)",
  "Tyrnaevae (Finland)",
  "Uimaharju (Finland)",
  "Ulvila (Finland)",
  "Utsjoki (Finland)",
  "Utti (Finland)",
  "Uurainen (Finland)",
  "Uusikaupunki (Finland)",
  "Vaajakoski (Finland)",
  "Vaasa (Finland)",
  "Vaeaeksy (Finland)",
  "Vaesterskog (Finland)",
  "Vahto (Finland)",
  "Vakkola (Finland)",
  "Valkama (Finland)",
  "Valkeakoski (Finland)",
  "Valkeala (Finland)",
  "Valkola (Finland)",
  "Vammala (Finland)",
  "Vanhalinna (Finland)",
  "Vantaa (Finland)",
  "Varkaus (Finland)",
  "Vasarainen (Finland)",
  "Vaskio (Finland)",
  "Vaulammi (Finland)",
  "Vehniae (Finland)",
  "Veikkola (Finland)",
  "Venetheitto (Finland)",
  "Vesanka (Finland)",
  "Veteli (Finland)",
  "Viantie (Finland)",
  "Vihtavuori (Finland)",
  "Vihti (Finland)",
  "Viiala (Finland)",
  "Viitasaari (Finland)",
  "Viljakkala (Finland)",
  "Vilppula (Finland)",
  "Vimpeli (Finland)",
  "Virrat (Finland)",
  "Vuokatti (Finland)",
  "Ylaemylly (Finland)",
  "Ylihaermae (Finland)",
  "Ylitornio (Finland)",
  "Ylivieska (Finland)",
  "Ypaejae (Finland)",
  "France",
  "Aast (France)",
  "Abancourt (France)",
  "Abbans-Dessus (France)",
  "Abbaretz (France)",
  "Abbecourt (France)",
  "Abbeville (France)",
  "Abbeville-les-Conflans (France)",
  "Abeilhan (France)",
  "Abere (France)",
  "Abergement-Saint-Jean (France)",
  "Abilly (France)",
  "Ablain-Saint-Nazaire (France)",
  "Ablaincourt-Pressoir (France)",
  "Ablainzevelle (France)",
  "Ablis (France)",
  "Ablon-sur-Seine (France)",
  "Abondance (France)",
  "Abondant (France)",
  "Abreschviller (France)",
  "Abrest (France)",
  "Abscon (France)",
  "Abzac (France)",
  "Achatel (France)",
  "Achenheim (France)",
  "Acheres (France)",
  "Acheville (France)",
  "Achicourt (France)",
  "Achiet-le-Grand (France)",
  "Achiet-le-Petit (France)",
  "Acigne (France)",
  "Acq (France)",
  "Acquigny (France)",
  "Acy-en-Multien (France)",
  "Adam-les-Vercel (France)",
  "Adamswiller (France)",
  "Adelans-et-le-Val-de-Bithaine (France)",
  "Adissan (France)",
  "Adriers (France)",
  "Afa (France)",
  "Agay (France)",
  "Agde (France)",
  "Agen (France)",
  "Agneaux (France)",
  "Agnetz (France)",
  "Agnin (France)",
  "Agnos (France)",
  "Agny (France)",
  "Aguilcourt (France)",
  "Ahaxe-Alciette-Bascassan (France)",
  "Ahuille (France)",
  "Ahun (France)",
  "Ahuy (France)",
  "Aiffres (France)",
  "Aigaliers (France)",
  "Aiglemont (France)",
  "Aignan (France)",
  "Aigne (France)",
  "Aigny (France)",
  "Aigonnay (France)",
  "Aigre (France)",
  "Aigrefeuille (France)",
  "Aigrefeuille-sur-Maine (France)",
  "Aigremont (France)",
  "Aiguebelette-le-Lac (France)",
  "Aiguebelle (France)",
  "Aigueblanche (France)",
  "Aiguefonde (France)",
  "Aigueperse (France)",
  "Aigues-Mortes (France)",
  "Aigues-Vives (France)",
  "Aiguillon (France)",
  "Ailette (France)",
  "Aillas (France)",
  "Aillevillers-et-Lyaumont (France)",
  "Aillon-le-Vieux (France)",
  "Ailly-le-Haut-Clocher (France)",
  "Ailly-sur-Noye (France)",
  "Ailly-sur-Somme (France)",
  "Aimargues (France)",
  "Aime (France)",
  "Ainay-le-Chateau (France)",
  "Aincourt (France)",
  "Ainhoa (France)",
  "Airaines (France)",
  "Aire-sur-la-Lys (France)",
  "Airel (France)",
  "Airvault (France)",
  "Aiserey (France)",
  "Aissey (France)",
  "Aiton (France)",
  "Aix-en-Ergny (France)",
  "Aix-en-Issart (France)",
  "Aix-en-Othe (France)",
  "Aix-en-Provence (France)",
  "Aix-les-Bains (France)",
  "Aix-Noulette (France)",
  "Aixe-sur-Vienne (France)",
  "Aizenay (France)",
  "Ajaccio (France)",
  "Alairac (France)",
  "Alata (France)",
  "Alban (France)",
  "Albas (France)",
  "Albefeuille-Lagarde (France)",
  "Albens (France)",
  "Albert (France)",
  "Albertville (France)",
  "Albi (France)",
  "Albias (France)",
  "Albigny-sur-Saone (France)",
  "Albiosc (France)",
  "Alboussiere (France)",
  "Albussac (France)",
  "Alby-sur-Cheran (France)",
  "Alenya (France)",
  "Alfortville (France)",
  "Algolsheim (France)",
  "Algrange (France)",
  "Alignan-du-Vent (France)",
  "Alissas (France)",
  "Alix (France)",
  "Alixan (France)",
  "Alizay (France)",
  "Allain (France)",
  "Allaire (France)",
  "Allan (France)",
  "Allassac (France)",
  "Allauch (France)",
  "Allegre (France)",
  "Alleins (France)",
  "Allemans-du-Dropt (France)",
  "Allennes-les-Marais (France)",
  "Alleriot (France)",
  "Allery (France)",
  "Allevard (France)",
  "Alliancelles (France)",
  "Allier (France)",
  "Allieres (France)",
  "Allinges (France)",
  "Allogny (France)",
  "Allondrelle-la-Malmaison (France)",
  "Allonne (France)",
  "Allonnes (France)",
  "Allonville (France)",
  "Allonzier-la-Caille (France)",
  "Allos (France)",
  "Allouagne (France)",
  "Alloue (France)",
  "Allouis (France)",
  "Allouville-Bellefosse (France)",
  "Alluy (France)",
  "Alluyes (France)",
  "Ally (France)",
  "Alos-Sibas-Abense (France)",
  "Alteckendorf (France)",
  "Altkirch (France)",
  "Altorf (France)",
  "Altviller (France)",
  "Alvimare (France)",
  "Alzonne (France)",
  "Amagney (France)",
  "Amailloux (France)",
  "Amancey (France)",
  "Amancy (France)",
  "Amange (France)",
  "Amanlis (France)",
  "Amanvillers (France)",
  "Amathay-Vesigneux (France)",
  "Amaye-sur-Orne (France)",
  "Ambazac (France)",
  "Amberac (France)",
  "Amberieux (France)",
  "Amberieux-en-Dombes (France)",
  "Ambert (France)",
  "Ambes (France)",
  "Ambierle (France)",
  "Ambleny (France)",
  "Ambleteuse (France)",
  "Amboise (France)",
  "Ambon (France)",
  "Ambonnay (France)",
  "Ambres (France)",
  "Ambrieres (France)",
  "Ambronay (France)",
  "Ambrus (France)",
  "Ambutrix (France)",
  "Amelecourt (France)",
  "Amelie-les-Bains-Palalda (France)",
  "Amen (France)",
  "Ames (France)",
  "Amfreville (France)",
  "Amfreville-la-Mi-Voie (France)",
  "Amfreville-sous-les-Monts (France)",
  "Amiens (France)",
  "Amigny (France)",
  "Amigny-Rouy (France)",
  "Amillis (France)",
  "Amilly (France)",
  "Ammerschwihr (France)",
  "Ammerzwiller (France)",
  "Amneville (France)",
  "Amou (France)",
  "Amplepuis (France)",
  "Amponville (France)",
  "Ampuis (France)",
  "Ampus (France)",
  "Amure (France)",
  "Anais (France)",
  "Ance (France)",
  "Anceaumeville (France)",
  "Ancemont (France)",
  "Ancenis (France)",
  "Ancerville (France)",
  "Anche (France)",
  "Anchenoncourt-et-Chazel (France)",
  "Ancinnes (France)",
  "Ancizan (France)",
  "Ancone (France)",
  "Ancy (France)",
  "Ancy-le-Franc (France)",
  "Ancy-sur-Moselle (France)",
  "Andance (France)",
  "Andancette (France)",
  "Andard (France)",
  "Ande (France)",
  "Andechy (France)",
  "Andel (France)",
  "Andelot-Morval (France)",
  "Andernos-les-Bains (France)",
  "Andeville (France)",
  "Andigne (France)",
  "Andilly (France)",
  "Andoins (France)",
  "Andolsheim (France)",
  "Andon (France)",
  "Andonville (France)",
  "Andouille (France)",
  "Andouille-Neuville (France)",
  "Andres (France)",
  "Andrest (France)",
  "Andresy (France)",
  "Andreze (France)",
  "Andrezieux-Boutheon (France)",
  "Anduze (France)",
  "Anet (France)",
  "Anetz (France)",
  "Ange (France)",
  "Angeac-Champagne (France)",
  "Angeac-Charente (France)",
  "Angely (France)",
  "Angers (France)",
  "Angerville-la-Martel (France)",
  "Angervilliers (France)",
  "Angevillers (France)",
  "Angicourt (France)",
  "Angiens (France)",
  "Anglade (France)",
  "Anglefort (France)",
  "Angles (France)",
  "Anglet (France)",
  "Angliers (France)",
  "Anglure (France)",
  "Angoulins (France)",
  "Angoume (France)",
  "Angoville-sur-Ay (France)",
  "Angres (France)",
  "Anguerny (France)",
  "Angy (France)",
  "Anhaux (France)",
  "Anhiers (France)",
  "Aniane (France)",
  "Aniche (France)",
  "Anizy-le-Chateau (France)",
  "Anjou (France)",
  "Anjoutey (France)",
  "Anlhiac (France)",
  "Annay (France)",
  "Annay-sur-Serein (France)",
  "Annebault (France)",
  "Annecy (France)",
  "Annecy-le-Vieux (France)",
  "Annemasse (France)",
  "Annepont (France)",
  "Annequin (France)",
  "Annesse-et-Beaulieu (France)",
  "Annet-sur-Marne (France)",
  "Anneville-Ambourville (France)",
  "Anneyron (France)",
  "Annezay (France)",
  "Annezin (France)",
  "Annoeullin (France)",
  "Annois (France)",
  "Annonay (France)",
  "Annot (France)",
  "Annouville-Vilmesnil (France)",
  "Anor (France)",
  "Anould (France)",
  "Ansac-sur-Vienne (France)",
  "Ansauvillers (France)",
  "Anse (France)",
  "Ansouis (France)",
  "Antagnac (France)",
  "Anteuil (France)",
  "Anthe (France)",
  "Antheuil-Portes (France)",
  "Anthon (France)",
  "Anthy-sur-Leman (France)",
  "Antibes (France)",
  "Antignac (France)",
  "Antigny (France)",
  "Antilly (France)",
  "Antogny le Tillac (France)",
  "Antony (France)",
  "Antrain (France)",
  "Anzat-le-Luguet (France)",
  "Anzin (France)",
  "Anzin-Saint-Aubin (France)",
  "Anzy-le-Duc (France)",
  "Aoste (France)",
  "Aougny (France)",
  "Aouste-sur-Sye (France)",
  "Apach (France)",
  "Apcher (France)",
  "Appenwihr (France)",
  "Appeville (France)",
  "Appeville-Annebault (France)",
  "Appietto (France)",
  "Appoigny (France)",
  "Apprieu (France)",
  "Apremont (France)",
  "Apt (France)",
  "Araches-la-Frasse (France)",
  "Aragon (France)",
  "Aramits (France)",
  "Aramon (France)",
  "Arancou (France)",
  "Arbent (France)",
  "Arberats-Sillegue (France)",
  "Arbin (France)",
  "Arbois (France)",
  "Arbonne (France)",
  "Arboucave (France)",
  "Arbus (France)",
  "Arbusigny (France)",
  "Arc-et-Senans (France)",
  "Arc-sur-Tille (France)",
  "Arcachon (France)",
  "Arcay (France)",
  "Arcens (France)",
  "Arces (France)",
  "Archamps (France)",
  "Arche (France)",
  "Arches (France)",
  "Archettes (France)",
  "Archiac (France)",
  "Archingeay (France)",
  "Arcine (France)",
  "Arcis-sur-Aube (France)",
  "Arcon (France)",
  "Arcueil (France)",
  "Arcy-Sainte-Restitue (France)",
  "Ardenay-sur-Merize (France)",
  "Ardentes (France)",
  "Ardes (France)",
  "Ardiege (France)",
  "Ardillieres (France)",
  "Ardoix (France)",
  "Ardon (France)",
  "Ardres (France)",
  "Areines (France)",
  "Arengosse (France)",
  "Arenthon (France)",
  "Ares (France)",
  "Aressy (France)",
  "Arette (France)",
  "Argancy (France)",
  "Argelers (France)",
  "Argeles-Gazost (France)",
  "Argeliers (France)",
  "Argelliers (France)",
  "Argences (France)",
  "Argens (France)",
  "Argent-sur-Sauldre (France)",
  "Argentan (France)",
  "Argentat (France)",
  "Argenteuil (France)",
  "Argentiere (France)",
  "Argentine (France)",
  "Argenton-les-Vallees (France)",
  "Argenton-sur-Creuse (France)",
  "Argentre (France)",
  "Argentre-du-Plessis (France)",
  "Argeville (France)",
  "Argol (France)",
  "Argueil (France)",
  "Arinthod (France)",
  "Arlanc (France)",
  "Arlay (France)",
  "Arlebosc (France)",
  "Arles (France)",
  "Arleux (France)",
  "Arleux-en-Gohelle (France)",
  "Armaucourt (France)",
  "Armbouts-Cappel (France)",
  "Armenonville-les-Gatineaux (France)",
  "Armes (France)",
  "Armoy (France)",
  "Arnac-Pompadour (France)",
  "Arnage (France)",
  "Arnas (France)",
  "Arnay-le-Duc (France)",
  "Arnieres-sur-Iton (France)",
  "Arnouville (France)",
  "Arnouville-les-Mantes (France)",
  "Aron (France)",
  "Arpaillargues-et-Aureillac (France)",
  "Arpajon (France)",
  "Arpajon-sur-Cere (France)",
  "Arpenans (France)",
  "Arpheuilles-Saint-Priest (France)",
  "Arques (France)",
  "Arques-la-Bataille (France)",
  "Arquettes-en-Val (France)",
  "Arrabloy (France)",
  "Arradon (France)",
  "Arras (France)",
  "Arraute-Charritte (France)",
  "Arreau (France)",
  "Arrenes (France)",
  "Arrigas (France)",
  "Arrigny (France)",
  "Arrou (France)",
  "Ars (France)",
  "Ars-en-Re (France)",
  "Ars-Laquenexy (France)",
  "Ars-sur-Formans (France)",
  "Ars-sur-Moselle (France)",
  "Arsac (France)",
  "Arsac-en-Velay (France)",
  "Arsague (France)",
  "Artannes-sur-Indre (France)",
  "Artas (France)",
  "Artenay (France)",
  "Arthaz-Pont-Notre-Dame (France)",
  "Arthes (France)",
  "Arthez-de-Bearn (France)",
  "Arthon (France)",
  "Arthon-en-Retz (France)",
  "Artigat (France)",
  "Artigueloutan (France)",
  "Artiguelouve (France)",
  "Artigues (France)",
  "Artigues-pres-Bordeaux (France)",
  "Artix (France)",
  "Artolsheim (France)",
  "Arudy (France)",
  "Arvert (France)",
  "Arveyres (France)",
  "Arvillard (France)",
  "Arville (France)",
  "Arvillers (France)",
  "Arx (France)",
  "Arzacq-Arraziguet (France)",
  "Arzal (France)",
  "Arzano (France)",
  "Arzon (France)",
  "Asasp-Arros (France)",
  "Ascain (France)",
  "Ascheres-le-Marche (France)",
  "Ascoux (France)",
  "Ascq (France)",
  "Aslonnes (France)",
  "Asnans-Beauvoisin (France)",
  "Asnieres (France)",
  "Asnieres-sur-Oise (France)",
  "Asnieres-sur-Seine (France)",
  "Aspach-le-Bas (France)",
  "Aspach-le-Haut (France)",
  "Asperes (France)",
  "Aspet (France)",
  "Aspremont (France)",
  "Asque (France)",
  "Assas (France)",
  "Assat (France)",
  "Assay (France)",
  "Assevent (France)",
  "Assier (France)",
  "Assignan (France)",
  "Asson (France)",
  "Astaffort (France)",
  "Astille (France)",
  "Astis (France)",
  "Athee (France)",
  "Athesans-Etroitefontaine (France)",
  "Athies (France)",
  "Athies-sous-Laon (France)",
  "Athis-Mons (France)",
  "Attenschwiller (France)",
  "Attiches (France)",
  "Attichy (France)",
  "Attignat-Oncin (France)",
  "Attigny (France)",
  "Atton (France)",
  "Atur (France)",
  "Aubagne (France)",
  "Aubais (France)",
  "Aubas (France)",
  "Aubenas (France)",
  "Auberchicourt (France)",
  "Aubergenville (France)",
  "Auberives-sur-Vareze (France)",
  "Aubers (France)",
  "Auberville-la-Campagne (France)",
  "Auberville-la-Renault (France)",
  "Aubervilliers (France)",
  "Aubevoye (France)",
  "Aubiac (France)",
  "Aubie-et-Espessas (France)",
  "Aubiere (France)",
  "Aubiet (France)",
  "Aubignan (France)",
  "Aubigne (France)",
  "Aubigne-Racan (France)",
  "Aubigney (France)",
  "Aubigny (France)",
  "Aubigny-au-Bac (France)",
  "Aubigny-en-Artois (France)",
  "Aubigny-en-Laonnois (France)",
  "Aubigny-en-Plaine (France)",
  "Aubin (France)",
  "Aubin-Saint-Vaast (France)",
  "Aubinges (France)",
  "Aubord (France)",
  "Auboue (France)",
  "Aubrac (France)",
  "Aubrives (France)",
  "Aubry-du-Hainaut (France)",
  "Aubusson (France)",
  "Auby (France)",
  "Aucamville (France)",
  "Auch (France)",
  "Auchel (France)",
  "Auchy-les-Hesdin (France)",
  "Auchy-les-Mines (France)",
  "Auchy-lez-Orchies (France)",
  "Audelange (France)",
  "Audenge (France)",
  "Audes (France)",
  "Audierne (France)",
  "Audignies (France)",
  "Audincourt (France)",
  "Audincthun (France)",
  "Audruicq (France)",
  "Audun-le-Roman (France)",
  "Audun-le-Tiche (France)",
  "Auffargis (France)",
  "Auffay (France)",
  "Auffreville-Brasseuil (France)",
  "Auga (France)",
  "Augignac (France)",
  "Augny (France)",
  "Aulhat-Saint-Privat (France)",
  "Aulnat (France)",
  "Aulnay (France)",
  "Aulnay-sous-Bois (France)",
  "Aulnay-sur-Marne (France)",
  "Aulnay-sur-Mauldre (France)",
  "Aulnoye-Aymeries (France)",
  "Ault (France)",
  "Aumagne (France)",
  "Aumale (France)",
  "Aumenancourt (France)",
  "Aumetz (France)",
  "Aumont (France)",
  "Aumont-Aubrac (France)",
  "Aumont-en-Halatte (France)",
  "Aumontzey (France)",
  "Aumur (France)",
  "Aunay-les-Bois (France)",
  "Aunay-sous-Auneau (France)",
  "Aunay-sur-Odon (France)",
  "Auneau (France)",
  "Auneuil (France)",
  "Aups (France)",
  "Auray (France)",
  "Aurec-sur-Loire (France)",
  "Aureil (France)",
  "Aureilhan (France)",
  "Auriac (France)",
  "Auriac-sur-Vendinelle (France)",
  "Auribeau-sur-Siagne (France)",
  "Aurignac (France)",
  "Aurillac (France)",
  "Auriol (France)",
  "Aurons (France)",
  "Aussevielle (France)",
  "Aussillon (France)",
  "Aussonce (France)",
  "Aussonne (France)",
  "Autechaux (France)",
  "Auterive (France)",
  "Auteuil (France)",
  "Autevielle-Saint-Martin-Bideren (France)",
  "Authevernes (France)",
  "Authie (France)",
  "Authon (France)",
  "Authou (France)",
  "Authouillet (France)",
  "Autoreille (France)",
  "Autrans (France)",
  "Autreche (France)",
  "Autremencourt (France)",
  "Autreville (France)",
  "Autry-le-Chatel (France)",
  "Autun (France)",
  "Auvergny (France)",
  "Auvers (France)",
  "Auvers-Saint-Georges (France)",
  "Auvers-sur-Oise (France)",
  "Auvillar (France)",
  "Auvillars (France)",
  "Auvillers-les-Forges (France)",
  "Auvilliers-en-Gatinais (France)",
  "Auxais (France)",
  "Auxances (France)",
  "Auxange (France)",
  "Auxerre (France)",
  "Auxey-Duresses (France)",
  "Auxi-le-Chateau (France)",
  "Auxon (France)",
  "Auxon-Dessous (France)",
  "Auxon-Dessus (France)",
  "Auxonne (France)",
  "Auxy (France)",
  "Auzances (France)",
  "Auzeville-Tolosane (France)",
  "Auzielle (France)",
  "Auzon (France)",
  "Auzouer-en-Touraine (France)",
  "Availles-Limouzine (France)",
  "Avallon (France)",
  "Avancon (France)",
  "Avanne-Aveney (France)",
  "Avanton (France)",
  "Avaray (France)",
  "Aveize (France)",
  "Aveizieux (France)",
  "Avelin (France)",
  "Aveluy (France)",
  "Avenay (France)",
  "Avensan (France)",
  "Aventignan (France)",
  "Averdoingt (France)",
  "Averdon (France)",
  "Averton (France)",
  "Avesnelles (France)",
  "Avesnes-en-Bray (France)",
  "Avesnes-le-Comte (France)",
  "Avesnes-le-Sec (France)",
  "Avesnes-les-Aubert (France)",
  "Avesnes-sur-Helpe (France)",
  "Aveze (France)",
  "Aviernoz (France)",
  "Avignon (France)",
  "Avignonet-Lauragais (France)",
  "Avion (France)",
  "Aviron (France)",
  "Avize (France)",
  "Avoine (France)",
  "Avon (France)",
  "Avon-la-Peze (France)",
  "Avord (France)",
  "Avot (France)",
  "Avrainville (France)",
  "Avranches (France)",
  "Avremesnil (France)",
  "Avressieux (France)",
  "Avril (France)",
  "Avrille (France)",
  "Ax-les-Thermes (France)",
  "Aydat (France)",
  "Ayencourt (France)",
  "Ayette (France)",
  "Ayguemorte-les-Graves (France)",
  "Ayguesvives (France)",
  "Aymeries (France)",
  "Aynac (France)",
  "Ayrens (France)",
  "Ayse (France)",
  "Aytre (France)",
  "Azas (France)",
  "Azay-le-Brule (France)",
  "Azay-le-Rideau (France)",
  "Azay-sur-Cher (France)",
  "Azay-sur-Indre (France)",
  "Aze (France)",
  "Azerables (France)",
  "Azerailles (France)",
  "Azet (France)",
  "Azille (France)",
  "Azur (France)",
  "Azy-sur-Marne (France)",
  "Babeau-Bouldoux (France)",
  "Baccarat (France)",
  "Bach (France)",
  "Bachant (France)",
  "Bachy (France)",
  "Bacilly (France)",
  "Baconnes (France)",
  "Bacqueville (France)",
  "Bacqueville-en-Caux (France)",
  "Baden (France)",
  "Badevel (France)",
  "Bagard (France)",
  "Bagas (France)",
  "Bage-la-Ville (France)",
  "Bages (France)",
  "Bagnac-sur-Cele (France)",
  "Bagneaux (France)",
  "Bagneaux-sur-Loing (France)",
  "Bagneux (France)",
  "Bagnolet (France)",
  "Bagnols (France)",
  "Bagnols-en-Foret (France)",
  "Bagnols-sur-Ceze (France)",
  "Baguer-Morvan (France)",
  "Baguer-Pican (France)",
  "Baho (France)",
  "Baignes-Sainte-Radegonde (France)",
  "Baigneux-les-Juifs (France)",
  "Baillargues (France)",
  "Bailleau-le-Pin (France)",
  "Baillet-en-France (France)",
  "Bailleul (France)",
  "Bailleul-le-Soc (France)",
  "Bailleul-Sir-Berthoult (France)",
  "Bailleul-sur-Therain (France)",
  "Bailly (France)",
  "Bailly-Carrois (France)",
  "Bailly-le-Franc (France)",
  "Bailly-Romainvilliers (France)",
  "Bain-de-Bretagne (France)",
  "Bains (France)",
  "Bains-les-Bains (France)",
  "Bains-sur-Oust (France)",
  "Bainville-sur-Madon (France)",
  "Baisieux (France)",
  "Baixas (France)",
  "Bajus (France)",
  "Balagny-sur-Therain (France)",
  "Balan (France)",
  "Balanod (France)",
  "Balaruc-le-Vieux (France)",
  "Balaruc-les-Bains (France)",
  "Balaze (France)",
  "Balbigny (France)",
  "Baldenheim (France)",
  "Baldersheim (France)",
  "Balgau (France)",
  "Ballainvilliers (France)",
  "Ballaison (France)",
  "Ballan-Mire (France)",
  "Ballancourt-sur-Essonne (France)",
  "Balleroy (France)",
  "Ballon (France)",
  "Balma (France)",
  "Balschwiller (France)",
  "Balzac (France)",
  "Ban-de-Laveline (France)",
  "Banassac (France)",
  "Bandol AOC (France)",
  "Bangor (France)",
  "Banize (France)",
  "Bannalec (France)",
  "Banne (France)",
  "Bannes (France)",
  "Bannieres (France)",
  "Banon (France)",
  "Bantigny (France)",
  "Bantzenheim (France)",
  "Banville (France)",
  "Banvou (France)",
  "Banyuls de la Marenda (France)",
  "Banyuls-dels-Aspres (France)",
  "Bapaume (France)",
  "Bar-le-Duc (France)",
  "Bar-sur-Aube (France)",
  "Bar-sur-Seine (France)",
  "Baraqueville (France)",
  "Barastre (France)",
  "Barbaira (France)",
  "Barbaste (France)",
  "Barbatre (France)",
  "Barbazan-Debat (France)",
  "Barbechat (France)",
  "Barbentane (France)",
  "Barberaz (France)",
  "Barbery (France)",
  "Barbeville (France)",
  "Barbezieux-Saint-Hilaire (France)",
  "Barbieres (France)",
  "Barbizon (France)",
  "Barby (France)",
  "Barc (France)",
  "Barcelonnette (France)",
  "Bardos (France)",
  "Barembach (France)",
  "Barentin (France)",
  "Barenton-Bugny (France)",
  "Barfleur (France)",
  "Barges (France)",
  "Barinque (France)",
  "Barisey-au-Plain (France)",
  "Barisey-la-Cote (France)",
  "Barjac (France)",
  "Barjols (France)",
  "Barjon (France)",
  "Barjouville (France)",
  "Barlin (France)",
  "Barneville-Carteret (France)",
  "Baron (France)",
  "Baronville (France)",
  "Barr (France)",
  "Barraux (France)",
  "Barreme (France)",
  "Barro (France)",
  "Barrou (France)",
  "Bartenheim (France)",
  "Barzy-en-Thierache (France)",
  "Bas-en-Basset (France)",
  "Bascons (France)",
  "Bassan (France)",
  "Basse-Goulaine (France)",
  "Basse-Ham (France)",
  "Basse-Rentgen (France)",
  "Basse-sur-le-Rupt (France)",
  "Bassemberg (France)",
  "Bassens (France)",
  "Bassigney (France)",
  "Bassillac (France)",
  "Bassoles-Aulers (France)",
  "Bassou (France)",
  "Bassu (France)",
  "Bassussarry (France)",
  "Bastennes (France)",
  "Bastia (France)",
  "Batilly (France)",
  "Batilly-en-Gatinais (France)",
  "Battenheim (France)",
  "Battrans (France)",
  "Batz-sur-Mer (France)",
  "Batzendorf (France)",
  "Baud (France)",
  "Baudemont (France)",
  "Baudreix (France)",
  "Baulay (France)",
  "Baule (France)",
  "Baulne (France)",
  "Baulon (France)",
  "Baume-les-Dames (France)",
  "Baume-les-Messieurs (France)",
  "Baune (France)",
  "Baupte (France)",
  "Baurech (France)",
  "Bauvin (France)",
  "Bavans (France)",
  "Bavay (France)",
  "Bavent (France)",
  "Bavilliers (France)",
  "Bavincourt (France)",
  "Baye (France)",
  "Bayet (France)",
  "Bayeux (France)",
  "Bayon (France)",
  "Bayonne (France)",
  "Bazailles (France)",
  "Bazancourt (France)",
  "Bazarnes (France)",
  "Bazas (France)",
  "Bazeilles (France)",
  "Bazemont (France)",
  "Bazens (France)",
  "Bazet (France)",
  "Bazian (France)",
  "Bazicourt (France)",
  "Baziege (France)",
  "Bazien (France)",
  "Bazincourt-sur-Epte (France)",
  "Bazoches (France)",
  "Bazoches-les-Gallerandes (France)",
  "Bazoches-sur-Guyonne (France)",
  "Bazoches-sur-Hoene (France)",
  "Bazoches-sur-le-Betz (France)",
  "Bazoges-en-Paillers (France)",
  "Bazoilles-sur-Meuse (France)",
  "Bazoncourt (France)",
  "Bazouges-la-Perouse (France)",
  "Bazouges-sous-Hede (France)",
  "Bazouges-sur-le-Loir (France)",
  "Bazuel (France)",
  "Beaucaire (France)",
  "Beaucamps-le-Vieux (France)",
  "Beaucamps-Ligny (France)",
  "Beauce (France)",
  "Beauchamp (France)",
  "Beauchamps (France)",
  "Beauchamps-sur-Huillard (France)",
  "Beauchastel (France)",
  "Beauchene (France)",
  "Beaucourt (France)",
  "Beaucouze (France)",
  "Beaucroissant (France)",
  "Beaudricourt (France)",
  "Beaufort (France)",
  "Beaufort-en-Vallee (France)",
  "Beaufou (France)",
  "Beaugency (France)",
  "Beaujeu (France)",
  "Beaulieu-sous-Parthenay (France)",
  "Beaulieu-sur-Dordogne (France)",
  "Beaulieu-sur-Loire (France)",
  "Beaulieu-sur-Mer (France)",
  "Beaumetz-les-Loges (France)",
  "Beaumont (France)",
  "Beaumont-de-Lomagne (France)",
  "Beaumont-de-Pertuis (France)",
  "Beaumont-du-Gatinais (France)",
  "Beaumont-du-Perigord (France)",
  "Beaumont-en-Veron (France)",
  "Beaumont-Hague (France)",
  "Beaumont-le-Roger (France)",
  "Beaumont-les-Autels (France)",
  "Beaumont-les-Nonains (France)",
  "Beaumont-les-Valence (France)",
  "Beaumont-Pied-de-Boeuf (France)",
  "Beaumont-sur-Oise (France)",
  "Beaumont-sur-Sarthe (France)",
  "Beaune (France)",
  "Beaune-la-Rolande (France)",
  "Beaupont (France)",
  "Beaupreau (France)",
  "Beaupuy (France)",
  "Beauquesne (France)",
  "Beaurains (France)",
  "Beaurainville (France)",
  "Beauregard-Baret (France)",
  "Beauregard-Vendon (France)",
  "Beaurepaire (France)",
  "Beaurevoir (France)",
  "Beaurieux (France)",
  "Beausemblant (France)",
  "Beausoleil (France)",
  "Beaussault (France)",
  "Beautheil (France)",
  "Beautiran (France)",
  "Beautor (France)",
  "Beautot (France)",
  "Beauvais (France)",
  "Beauvais-sur-Matha (France)",
  "Beauval (France)",
  "Beauvallon (France)",
  "Beauvene (France)",
  "Beauvernois (France)",
  "Beauvezer (France)",
  "Beauville (France)",
  "Beauvoir (France)",
  "Beauvoir-de-Marc (France)",
  "Beauvoir-sur-Mer (France)",
  "Beauvoir-sur-Niort (France)",
  "Beauvoisin (France)",
  "Beauzac (France)",
  "Beauzelle (France)",
  "Bechy (France)",
  "Becon-les-Granits (France)",
  "Bedarrides (France)",
  "Bedee (France)",
  "Bedous (France)",
  "Beganne (France)",
  "Begard (France)",
  "Begles (France)",
  "Beguey (France)",
  "Behren-les-Forbach (France)",
  "Beignon (France)",
  "Beine-Nauroy (France)",
  "Beinheim (France)",
  "Beire-le-Chatel (France)",
  "Bel Air (France)",
  "Belarga (France)",
  "Belaye (France)",
  "Belbeuf (France)",
  "Belcodene (France)",
  "Belfort (France)",
  "Belfort-du-Quercy (France)",
  "Belgentier (France)",
  "Belgodere (France)",
  "Belhade (France)",
  "Beliet (France)",
  "Beligneux (France)",
  "Bellac (France)",
  "Bellaing (France)",
  "Bellancourt (France)",
  "Belle-Isle-en-Terre (France)",
  "Belleau (France)",
  "Bellebrune (France)",
  "Bellechaume (France)",
  "Bellefond (France)",
  "Bellefontaine (France)",
  "Bellefosse (France)",
  "Bellegarde (France)",
  "Bellegarde-du-Razes (France)",
  "Bellegarde-en-Diois (France)",
  "Bellegarde-Sainte-Marie (France)",
  "Bellegarde-sur-Valserine (France)",
  "Bellemagny (France)",
  "Bellenaves (France)",
  "Bellencombre (France)",
  "Belleneuve (France)",
  "Bellengreville (France)",
  "Bellentre (France)",
  "Belleray (France)",
  "Bellerive-sur-Allier (France)",
  "Bellesserre (France)",
  "Belleu (France)",
  "Bellevaux (France)",
  "Bellevesvre (France)",
  "Belleville (France)",
  "Belleville-en-Caux (France)",
  "Belleville-sur-Loire (France)",
  "Belleville-sur-Mer (France)",
  "Belleville-sur-Meuse (France)",
  "Belleville-sur-Vie (France)",
  "Bellevue-la-Montagne (France)",
  "Belley (France)",
  "Bellignat (France)",
  "Belloy-en-France (France)",
  "Belmont (France)",
  "Belmont-sur-Rance (France)",
  "Belmontet (France)",
  "Belpech (France)",
  "Belrupt-en-Verdunois (France)",
  "Belveze (France)",
  "Belz (France)",
  "Benamenil (France)",
  "Bene (France)",
  "Benerville-sur-Mer (France)",
  "Benesse-Maremne (France)",
  "Benestroff (France)",
  "Benfeld (France)",
  "Benifontaine (France)",
  "Benney (France)",
  "Bennwihr (France)",
  "Benon (France)",
  "Benouville (France)",
  "Beon (France)",
  "Berat (France)",
  "Bercenay-en-Othe (France)",
  "Berck (France)",
  "Berck-Plage (France)",
  "Berdoues (France)",
  "Berenx (France)",
  "Berg-sur-Moselle (France)",
  "Bergerac (France)",
  "Bergheim (France)",
  "Bergholtz (France)",
  "Bergonne (France)",
  "Bergues (France)",
  "Berjou (France)",
  "Berlaimont (France)",
  "Berles-au-Bois (France)",
  "Bermeries (France)",
  "Bermicourt (France)",
  "Bernadets (France)",
  "Bernard (France)",
  "Bernaville (France)",
  "Bernay (France)",
  "Bernay-Saint-Martin (France)",
  "Berne (France)",
  "Bernes-sur-Oise (France)",
  "Berneuil-sur-Aisne (France)",
  "Berneval-le-Grand (France)",
  "Bernex (France)",
  "Bernieres-le-Patry (France)",
  "Bernieres-sur-Mer (France)",
  "Bernin (France)",
  "Bernis (France)",
  "Bernos-Beaulac (France)",
  "Berriac (France)",
  "Berric (France)",
  "Berry-Bouy (France)",
  "Bersaillin (France)",
  "Bersee (France)",
  "Berson (France)",
  "Berthecourt (France)",
  "Berthelange (France)",
  "Berthen (France)",
  "Bertholene (France)",
  "Bertin (France)",
  "Bertincourt (France)",
  "Bertrange (France)",
  "Bertrimoutier (France)",
  "Bertry (France)",
  "Berville-en-Roumois (France)",
  "Berville-la-Campagne (France)",
  "Berville-sur-Seine (France)",
  "Berviller-en-Moselle (France)",
  "Berzeme (France)",
  "Berzet (France)",
  "Besayes (France)",
  "Besne (France)",
  "Bessais-le-Fromental (France)",
  "Bessan (France)",
  "Bessancourt (France)",
  "Bessay (France)",
  "Besse (France)",
  "Besse-sur-Braye (France)",
  "Besse-sur-Issole (France)",
  "Besseges (France)",
  "Bessenay (France)",
  "Bessens (France)",
  "Bessey-les-Citeaux (France)",
  "Bessieres (France)",
  "Bessines (France)",
  "Bessines-sur-Gartempe (France)",
  "Besson (France)",
  "Bessoncourt (France)",
  "Bessonies (France)",
  "Betaille (France)",
  "Bethancourt-en-Valois (France)",
  "Bethencourt-sur-Mer (France)",
  "Betheniville (France)",
  "Betheny (France)",
  "Bethisy-Saint-Pierre (France)",
  "Bethoncourt (France)",
  "Betschdorf (France)",
  "Bettancourt-la-Ferree (France)",
  "Bettignies (France)",
  "Betting (France)",
  "Betton (France)",
  "Bettviller (France)",
  "Bettwiller (France)",
  "Betz (France)",
  "Beugin (France)",
  "Beugneux (France)",
  "Beure (France)",
  "Beurlay (France)",
  "Beuste (France)",
  "Beutal (France)",
  "Beuvillers (France)",
  "Beuvrages (France)",
  "Beuvraignes (France)",
  "Beuvron-en-Auge (France)",
  "Beuvry-la-Foret (France)",
  "Beuzec-Cap-Sizun (France)",
  "Beuzeville (France)",
  "Bevenais (France)",
  "Beychac-et-Caillau (France)",
  "Beynes (France)",
  "Beynost (France)",
  "Beyrede-Jumet (France)",
  "Beyrie-en-Bearn (France)",
  "Beyssenac (France)",
  "Bezac (France)",
  "Bezannes (France)",
  "Bezons (France)",
  "Bezouce (France)",
  "Bezu-Saint-Eloi (France)",
  "Biache-Saint-Vaast (France)",
  "Biard (France)",
  "Biarritz (France)",
  "Biars-sur-Cere (France)",
  "Biaudos (France)",
  "Bichancourt (France)",
  "Bicqueley (France)",
  "Bidache (France)",
  "Bidarray (France)",
  "Bidart (France)",
  "Bief-du-Fourg (France)",
  "Bielle (France)",
  "Bienville (France)",
  "Bienvillers-au-Bois (France)",
  "Biermont (France)",
  "Bierne (France)",
  "Bierry-les-Belles-Fontaines (France)",
  "Biesheim (France)",
  "Biesles (France)",
  "Bietlenheim (France)",
  "Bieville-Beuville (France)",
  "Bievres (France)",
  "Biganos (France)",
  "Bignac (France)",
  "Bignan (France)",
  "Bignoux (France)",
  "Biguglia (France)",
  "Bihorel (France)",
  "Bilieu (France)",
  "Billere (France)",
  "Billey (France)",
  "Billiers (France)",
  "Billom (France)",
  "Billy (France)",
  "Billy-Berclau (France)",
  "Billy-Montigny (France)",
  "Billy-sous-les-Cotes (France)",
  "Billy-sur-Ourcq (France)",
  "Bindernheim (France)",
  "Binges (France)",
  "Binic (France)",
  "Bining (France)",
  "Bionville-sur-Nied (France)",
  "Biot (France)",
  "Bioule (France)",
  "Birac-sur-Trec (France)",
  "Biriatou (France)",
  "Birieux (France)",
  "Biscarrosse (France)",
  "Bischheim (France)",
  "Bischoffsheim (France)",
  "Bischwihr (France)",
  "Bischwiller (France)",
  "Bisel (France)",
  "Bitche (France)",
  "Biviers (France)",
  "Biville-sur-Mer (France)",
  "Bizanos (France)",
  "Blacourt (France)",
  "Blacqueville (France)",
  "Blacy (France)",
  "Blaesheim (France)",
  "Blagnac (France)",
  "Blagny (France)",
  "Blaignan (France)",
  "Blain (France)",
  "Blainville (France)",
  "Blainville-sur-Mer (France)",
  "Blainville-sur-Orne (France)",
  "Blaise (France)",
  "Blaise-sous-Arzillieres (France)",
  "Blaison-Gohier (France)",
  "Blamont (France)",
  "Blan (France)",
  "Blancafort (France)",
  "Blanchefosse-et-Bay (France)",
  "Blangy-sur-Bresle (France)",
  "Blanot (France)",
  "Blanquefort (France)",
  "Blanzat (France)",
  "Blanzay-sur-Boutonne (France)",
  "Blanzy (France)",
  "Blanzy-la-Salonnaise (France)",
  "Blaringhem (France)",
  "Blaudeix (France)",
  "Blausasc (France)",
  "Blauzac (France)",
  "Blavozy (France)",
  "Blaye (France)",
  "Blaye-les-Mines (France)",
  "Bleigny-le-Carreau (France)",
  "Blendecques (France)",
  "Blenod-les-Pont-a-Mousson (France)",
  "Blenod-les-Toul (France)",
  "Blerancourdelle (France)",
  "Blere (France)",
  "Bleriot-Plage (France)",
  "Blesle (France)",
  "Bletterans (France)",
  "Bleury (France)",
  "Bligny-les-Beaune (France)",
  "Blincourt (France)",
  "Blingel (France)",
  "Blodelsheim (France)",
  "Blois (France)",
  "Blomard (France)",
  "Blondefontaine (France)",
  "Blonville-sur-Mer (France)",
  "Blotzheim (France)",
  "Blou (France)",
  "Bloye (France)",
  "Blyes (France)",
  "Bobigny (France)",
  "Bocquegney (France)",
  "Boe (France)",
  "Boege (France)",
  "Boeil-Bezing (France)",
  "Boen-sur-Lignon (France)",
  "Boersch (France)",
  "Boeschepe (France)",
  "Boeseghem (France)",
  "Boesenbiesen (France)",
  "Bohain-en-Vermandois (France)",
  "Bohal (France)",
  "Boigneville (France)",
  "Boigny (France)",
  "Boigny-sur-Bionne (France)",
  "Bois (France)",
  "Bois-Anzeray (France)",
  "Bois-Arnault (France)",
  "Bois-Colombes (France)",
  "Bois-de-Cene (France)",
  "Bois-de-Gand (France)",
  "Bois-de-la-Pierre (France)",
  "Bois-Grenier (France)",
  "Bois-Guillaume (France)",
  "Bois-Jerome-Saint-Ouen (France)",
  "Bois-le-Roi (France)",
  "Boisbergues (France)",
  "Boiscommun (France)",
  "Boisemont (France)",
  "Boisjean (France)",
  "Boisleux-au-Mont (France)",
  "Boismont (France)",
  "Boismorand (France)",
  "Boisse-Penchot (France)",
  "Boisseron (France)",
  "Boisset-et-Gaujac (France)",
  "Boisseuil (France)",
  "Boissey-le-Chatel (France)",
  "Boissezon (France)",
  "Boissieres (France)",
  "Boissise-la-Bertrand (France)",
  "Boissise-le-Roi (France)",
  "Boissy-Fresnoy (France)",
  "Boissy-le-Bois (France)",
  "Boissy-le-Chatel (France)",
  "Boissy-le-Girard (France)",
  "Boissy-Maugis (France)",
  "Boissy-Mauvoisin (France)",
  "Boissy-sous-Saint-Yon (France)",
  "Boisville-la-Saint-Pere (France)",
  "Boisyvon (France)",
  "Bolandoz (France)",
  "Bolbec (France)",
  "Bollene (France)",
  "Bolleville (France)",
  "Bollezeele (France)",
  "Bollwiller (France)",
  "Bolsenheim (France)",
  "Bommes (France)",
  "Bompas (France)",
  "Bon-Encontre (France)",
  "Bonchamp-les-Laval (France)",
  "Boncourt-le-Bois (France)",
  "Boncourt-sur-Meuse (France)",
  "Bondeville (France)",
  "Bondigoux (France)",
  "Bondoufle (France)",
  "Bondues (France)",
  "Bondy (France)",
  "Bongheat (France)",
  "Bonifacio (France)",
  "Bonlier (France)",
  "Bonnard (France)",
  "Bonnat (France)",
  "Bonnebosq (France)",
  "Bonnee (France)",
  "Bonnegarde (France)",
  "Bonneguete (France)",
  "Bonneil (France)",
  "Bonnelles (France)",
  "Bonnemain (France)",
  "Bonnes (France)",
  "Bonnet (France)",
  "Bonnetable (France)",
  "Bonnetage (France)",
  "Bonnetan (France)",
  "Bonneuil-en-France (France)",
  "Bonneuil-en-Valois (France)",
  "Bonneuil-les-Eaux (France)",
  "Bonneuil-sur-Marne (France)",
  "Bonneval (France)",
  "Bonnevaux-le-Prieure (France)",
  "Bonnevent-Velloreille (France)",
  "Bonneville (France)",
  "Bonneville-Aptot (France)",
  "Bonneville-la-Louvet (France)",
  "Bonnieres-sur-Seine (France)",
  "Bonnieux (France)",
  "Bonny-sur-Loire (France)",
  "Bonrepos (France)",
  "Bonrepos-Riquet (France)",
  "Bons-en-Chablais (France)",
  "Bonsecours (France)",
  "Bonson (France)",
  "Bonzac (France)",
  "Boos (France)",
  "Boqueho (France)",
  "Bor-et-Bar (France)",
  "Boran-sur-Oise (France)",
  "Bord (France)",
  "Bordeaux (France)",
  "Borderes (France)",
  "Borderes-et-Lamensans (France)",
  "Bordes (France)",
  "Bords (France)",
  "Borgo (France)",
  "Bormes-les-Mimosas (France)",
  "Bornay (France)",
  "Bornel (France)",
  "Bosc Renoult (France)",
  "Bosc-Guerard-Saint-Adrien (France)",
  "Bosc-le-Hard (France)",
  "Bosc-Mesnil (France)",
  "Bosgouet (France)",
  "Bosguerard-de-Marcouville (France)",
  "Bosjean (France)",
  "Bosmont-sur-Serre (France)",
  "Bosrobert (France)",
  "Bossancourt (France)",
  "Bossay-sur-Claise (France)",
  "Bossendorf (France)",
  "Bossey (France)",
  "Botsorhel (France)",
  "Bouafles (France)",
  "Bouan (France)",
  "Bouaye (France)",
  "Boubers-sur-Canche (France)",
  "Boubiers (France)",
  "Bouc-Bel-Air (France)",
  "Boucau (France)",
  "Bouchain (France)",
  "Bouchemaine (France)",
  "Boucheporn (France)",
  "Bouchet (France)",
  "Boucoiran-et-Nozieres (France)",
  "Bouee (France)",
  "Bouer (France)",
  "Bouere (France)",
  "Bouex (France)",
  "Bouffemont (France)",
  "Bouffere (France)",
  "Bougarber (France)",
  "Bouge-Chambalud (France)",
  "Bougival (France)",
  "Bougnon (France)",
  "Bougue (France)",
  "Bouguenais (France)",
  "Bougy (France)",
  "Bouhet (France)",
  "Bouillargues (France)",
  "Bouilly (France)",
  "Bouilly-en-Gatinais (France)",
  "Bouin (France)",
  "Boujan-sur-Libron (France)",
  "Boulages (France)",
  "Boulancourt (France)",
  "Boulange (France)",
  "Boulay-les-Barres (France)",
  "Boulay-Moselle (France)",
  "Boulazac (France)",
  "Bouleurs (France)",
  "Bouliac (France)",
  "Boulieu-les-Annonay (France)",
  "Bouligney (France)",
  "Bouligny (France)",
  "Boullay-les-Troux (France)",
  "Boulleret (France)",
  "Boulleville (France)",
  "Bouloc (France)",
  "Boulogne-Billancourt (France)",
  "Boulogne-la-Grasse (France)",
  "Boulogne-sur-Gesse (France)",
  "Boulogne-sur-Mer (France)",
  "Bouloire (France)",
  "Boult-sur-Suippe (France)",
  "Boulzicourt (France)",
  "Bouniagues (France)",
  "Bouquet (France)",
  "Bouquetot (France)",
  "Bouray-sur-Juine (France)",
  "Bourbon-Lancy (France)",
  "Bourbonne-les-Bains (France)",
  "Bourbourg (France)",
  "Bourbriac (France)",
  "Bourcefranc-le-Chapus (France)",
  "Bourdenay (France)",
  "Bourdon (France)",
  "Bouresse (France)",
  "Bourg (France)",
  "Bourg-Achard (France)",
  "Bourg-Argental (France)",
  "Bourg-Blanc (France)",
  "Bourg-de-Thizy (France)",
  "Bourg-en-Bresse (France)",
  "Bourg-la-Reine (France)",
  "Bourg-le-Comte (France)",
  "Bourg-le-Roi (France)",
  "Bourg-les-Valence (France)",
  "Bourg-Saint-Andeol (France)",
  "Bourg-Saint-Maurice (France)",
  "Bourg-Sainte-Marie (France)",
  "Bourg-sous-Chatelet (France)",
  "Bourganeuf (France)",
  "Bourgbarre (France)",
  "Bourges (France)",
  "Bourget (France)",
  "Bourghelles (France)",
  "Bourgneuf (France)",
  "Bourgneuf-en-Retz (France)",
  "Bourgogne (France)",
  "Bourgoin (France)",
  "Bourguebus (France)",
  "Bourgueil (France)",
  "Bourguignon-sous-Coucy (France)",
  "Bourlon (France)",
  "Bourneville (France)",
  "Bournezeau (France)",
  "Bourogne (France)",
  "Bourran (France)",
  "Bourron-Marlotte (France)",
  "Bours (France)",
  "Boursault (France)",
  "Bourseville (France)",
  "Boursieres (France)",
  "Bourth (France)",
  "Boury-en-Vexin (France)",
  "Bousbach (France)",
  "Bousbecque (France)",
  "Bousies (France)",
  "Boussac (France)",
  "Boussay (France)",
  "Boussenac (France)",
  "Boussens (France)",
  "Boussieres (France)",
  "Boussois (France)",
  "Boussy-Saint-Antoine (France)",
  "Boust (France)",
  "Boutancourt (France)",
  "Boutiers-Saint-Trojan (France)",
  "Boutigny-Prouais (France)",
  "Boutigny-sur-Essonne (France)",
  "Bouvaincourt-sur-Bresle (France)",
  "Bouvante (France)",
  "Bouvelinghem (France)",
  "Bouvesse-Quirieu (France)",
  "Bouvigny-Boyeffles (France)",
  "Bouville (France)",
  "Bouvincourt-en-Vermandois (France)",
  "Bouvron (France)",
  "Bouxieres-aux-Chenes (France)",
  "Bouxieres-aux-Dames (France)",
  "Bouxwiller (France)",
  "Bouzel (France)",
  "Bouzigues (France)",
  "Bouzincourt (France)",
  "Bouzonville (France)",
  "Bouzonville-en-Beauce (France)",
  "Bouzy (France)",
  "Bouzy-la-Foret (France)",
  "Boves (France)",
  "Boyer (France)",
  "Boynes (France)",
  "Boz (France)",
  "Bozel (France)",
  "Bozouls (France)",
  "Brachy (France)",
  "Bracieux (France)",
  "Brain-sur-Allonnes (France)",
  "Brain-sur-Vilaine (France)",
  "Braine (France)",
  "Brains (France)",
  "Bram (France)",
  "Bramans (France)",
  "Branches (France)",
  "Brancourt-en-Laonnois (France)",
  "Brandivy (France)",
  "Brando (France)",
  "Brandonnet (France)",
  "Branges (France)",
  "Branne (France)",
  "Branoux-les-Taillades (France)",
  "Bransles (France)",
  "Braquis (France)",
  "Bras (France)",
  "Brasles (France)",
  "Brasparts (France)",
  "Brassac (France)",
  "Brassac-les-Mines (France)",
  "Brassempouy (France)",
  "Braucourt (France)",
  "Braud-et-Saint-Louis (France)",
  "Brax (France)",
  "Bray (France)",
  "Bray-Dunes (France)",
  "Bray-en-Val (France)",
  "Bray-et-Lu (France)",
  "Bray-Saint-Christophe (France)",
  "Bray-sur-Seine (France)",
  "Bray-sur-Somme (France)",
  "Braye-sous-Faye (France)",
  "Brazey-en-Plaine (France)",
  "Breal-sous-Montfort (France)",
  "Brebieres (France)",
  "Brece (France)",
  "Brecey (France)",
  "Brech (France)",
  "Breconchaux (France)",
  "Bree (France)",
  "Bregy (France)",
  "Brehal (France)",
  "Brehand (France)",
  "Breil-sur-Roya (France)",
  "Breitenbach-Haut-Rhin (France)",
  "Breles (France)",
  "Bremenil (France)",
  "Bremontier-Merval (France)",
  "Brenouille (France)",
  "Brens (France)",
  "Bresles (France)",
  "Bresse-sur-Grosne (France)",
  "Bressolles (France)",
  "Bressols (France)",
  "Bresson (France)",
  "Bressuire (France)",
  "Brest (France)",
  "Bretagne (France)",
  "Breteil (France)",
  "Breteniere (France)",
  "Bretenoux (France)",
  "Breteuil (France)",
  "Bretignolles (France)",
  "Bretignolles-sur-Mer (France)",
  "Bretigny (France)",
  "Bretteville-sur-Laize (France)",
  "Bretteville-sur-Odon (France)",
  "Breuil (France)",
  "Breuil-le-Sec (France)",
  "Breuil-le-Vert (France)",
  "Breuil-Magne (France)",
  "Breuillet (France)",
  "Breuilpont (France)",
  "Breuschwickersheim (France)",
  "Breuvannes-en-Bassigny (France)",
  "Breux-sur-Avre (France)",
  "Breval (France)",
  "Breviandes (France)",
  "Brexent-Enocq (France)",
  "Breze (France)",
  "Brezins (France)",
  "Brezolles (France)",
  "Brianconnet (France)",
  "Briare (France)",
  "Briarres-sur-Essonne (France)",
  "Briatexte (France)",
  "Bricon (France)",
  "Bricquebec (France)",
  "Bricqueville-la-Blouette (France)",
  "Bricqueville-sur-Mer (France)",
  "Bricy (France)",
  "Brides-les-Bains (France)",
  "Bridore (France)",
  "Brie (France)",
  "Brie-Comte-Robert (France)",
  "Brie-et-Angonnes (France)",
  "Briec (France)",
  "Brienne-la-Vieille (France)",
  "Briennon (France)",
  "Brienon-sur-Armancon (France)",
  "Brieres-les-Scelles (France)",
  "Brieuil-sur-Chize (France)",
  "Brieulles-sur-Meuse (France)",
  "Briey (France)",
  "Brignac-la-Plaine (France)",
  "Brignais (France)",
  "Brigne (France)",
  "Brignoles (France)",
  "Brignon (France)",
  "Brignoud (France)",
  "Brigueuil (France)",
  "Briis-sous-Forges (France)",
  "Brimeux (France)",
  "Brin-sur-Seille (France)",
  "Brinay (France)",
  "Brindas (France)",
  "Bringolo (France)",
  "Brinon-sur-Beuvron (France)",
  "Briollay (France)",
  "Brion (France)",
  "Brionne (France)",
  "Briord (France)",
  "Brioude (France)",
  "Brioux-sur-Boutonne (France)",
  "Briouze (France)",
  "Briscous (France)",
  "Brison-Saint-Innocent (France)",
  "Brissac (France)",
  "Brissac-Quince (France)",
  "Brissy-Hamegicourt (France)",
  "Brive (France)",
  "Brive-la-Gaillarde (France)",
  "Brives-Charensac (France)",
  "Brives-sur-Charente (France)",
  "Brix (France)",
  "Brochon (France)",
  "Broglie (France)",
  "Brognon (France)",
  "Broin (France)",
  "Brombos (France)",
  "Bron (France)",
  "Broons (France)",
  "Brossac (France)",
  "Brosville (France)",
  "Brou (France)",
  "Brou-sur-Chantereine (France)",
  "Brouay (France)",
  "Brouckerque (France)",
  "Brouderdorff (France)",
  "Broue (France)",
  "Brouennes (France)",
  "Brouilla (France)",
  "Brouqueyran (France)",
  "Brousse (France)",
  "Brousseval (France)",
  "Brouviller (France)",
  "Bru (France)",
  "Bruch (France)",
  "Brue-Auriac (France)",
  "Bruebach (France)",
  "Bruges (France)",
  "Brugheas (France)",
  "Brugny-Vaudancourt (France)",
  "Bruguieres (France)",
  "Bruille-Saint-Amand (France)",
  "Bruley (France)",
  "Brullioles (France)",
  "Brulon (France)",
  "Brumath (France)",
  "Brunoy (France)",
  "Brunstatt (France)",
  "Brussey (France)",
  "Brussieu (France)",
  "Brusvily (France)",
  "Brux (France)",
  "Bruyeres-et-Montberault (France)",
  "Bruyeres-le-Chatel (France)",
  "Bruyeres-sur-Oise (France)",
  "Bruz (France)",
  "Bry-sur-Marne (France)",
  "Bu (France)",
  "Buanes (France)",
  "Bubry (France)",
  "Buc (France)",
  "Buceels (France)",
  "Bucey-les-Gy (France)",
  "Bucheres (France)",
  "Buchy (France)",
  "Bucquoy (France)",
  "Bucy-le-Long (France)",
  "Bucy-les-Cerny (France)",
  "Budos (France)",
  "Bueil (France)",
  "Buellas (France)",
  "Buffard (France)",
  "Bugeat (France)",
  "Bugnicourt (France)",
  "Buhl (France)",
  "Buigny-les-Gamaches (France)",
  "Buire-le-Sec (France)",
  "Buironfosse (France)",
  "Buis-les-Baronnies (France)",
  "Bulat-Pestivien (France)",
  "Bulgneville (France)",
  "Bulhon (France)",
  "Bulles (France)",
  "Bully (France)",
  "Bully-les-Mines (France)",
  "Buno-Bonnevaux (France)",
  "Bunzac (France)",
  "Burbure (France)",
  "Bures-sur-Yvette (France)",
  "Burie (France)",
  "Burlats (France)",
  "Burnhaupt-le-Haut (France)",
  "Buros (France)",
  "Bury (France)",
  "Busigny (France)",
  "Busloup (France)",
  "Busque (France)",
  "Bussac-Foret (France)",
  "Bussang (France)",
  "Bussiere-Galant (France)",
  "Bussiere-Poitevine (France)",
  "Bussieres-les-Belmont (France)",
  "Bussunarits-Sarrasquette (France)",
  "Bussus-Bussuel (France)",
  "Bussy-le-Chateau (France)",
  "Bussy-le-Grand (France)",
  "Bussy-les-Daours (France)",
  "Bussy-Lettree (France)",
  "Bussy-Saint-Georges (France)",
  "Busy (France)",
  "Buthiers (France)",
  "Butry-sur-Oise (France)",
  "Buxerolles (France)",
  "Buxeuil (France)",
  "Buxieres-les-Mines (France)",
  "Buxy (France)",
  "Buzan (France)",
  "Buzancais (France)",
  "Buzet-sur-Baise (France)",
  "Buzet-sur-Tarn (France)",
  "Buzy (France)",
  "Cabanac (France)",
  "Cabanac-et-Villagrains (France)",
  "Cabannes (France)",
  "Cabara (France)",
  "Cabariot (France)",
  "Cabasse (France)",
  "Cabestany (France)",
  "Cabourg (France)",
  "Cabries (France)",
  "Cabris (France)",
  "Cachan (France)",
  "Cadalen (France)",
  "Cadaujac (France)",
  "Caden (France)",
  "Cadenet (France)",
  "Cadillac (France)",
  "Cadillac-en-Fronsadais (France)",
  "Cadolive (France)",
  "Cadours (France)",
  "Caen (France)",
  "Caestre (France)",
  "Cagnac-les-Mines (France)",
  "Cagnes-sur-Mer (France)",
  "Cagnicourt (France)",
  "Cagny (France)",
  "Cahagnes (France)",
  "Cahors (France)",
  "Cahuzac (France)",
  "Cahuzac-sur-Vere (France)",
  "Caille (France)",
  "Caillouel-Crepigny (France)",
  "Cailloux-sur-Fontaines (France)",
  "Cairanne (France)",
  "Cairon (France)",
  "Caissargues (France)",
  "Cajarc (France)",
  "Calais (France)",
  "Calas (France)",
  "Calavante (France)",
  "Calcatoggio (France)",
  "Calenzana (France)",
  "Callac (France)",
  "Callas (France)",
  "Calleville (France)",
  "Callian (France)",
  "Calmels-et-le-Viala (France)",
  "Calmont (France)",
  "Calonne-Ricouart (France)",
  "Calonne-sur-la-Lys (France)",
  "Caluire-et-Cuire (France)",
  "Calvi (France)",
  "Calvisson (France)",
  "Camaret-sur-Aigues (France)",
  "Camaret-sur-Mer (France)",
  "Camarsac (France)",
  "Cambes (France)",
  "Cambieure (France)",
  "Camblain-Chatelain (France)",
  "Camblanes-et-Meynac (France)",
  "Cambo-les-Bains (France)",
  "Cambounet-sur-le-Sor (France)",
  "Cambrai (France)",
  "Cambrin (France)",
  "Cambronne-les-Ribecourt (France)",
  "Came (France)",
  "Cameyrac (France)",
  "Camiac-et-Saint-Denis (France)",
  "Camiers (France)",
  "Camlez (France)",
  "Camoel (France)",
  "Camon (France)",
  "Camors (France)",
  "Campagnan (France)",
  "Campagne (France)",
  "Campagne-les-Hesdin (France)",
  "Campagne-les-Wardrecques (France)",
  "Campagnolles (France)",
  "Campeaux (France)",
  "Campeneac (France)",
  "Campet-et-Lamolere (France)",
  "Camphin-en-Carembault (France)",
  "Camphin-en-Pevele (France)",
  "Campigneulles-les-Petites (France)",
  "Campremy (France)",
  "Camprond (France)",
  "Camps-la-Source (France)",
  "Campuac (France)",
  "Campugnan (France)",
  "Canaples (France)",
  "Cancale (France)",
  "Cancon (France)",
  "Cande (France)",
  "Cande-sur-Beuvron (France)",
  "Candillargues (France)",
  "Candresse (France)",
  "Canejan (France)",
  "Canet-de-Salars (France)",
  "Canet-Plage (France)",
  "Cangey (France)",
  "Canisy (France)",
  "Canlers (France)",
  "Canly (France)",
  "Cannes (France)",
  "Cannes-Ecluse (France)",
  "Cannet (France)",
  "Canohes (France)",
  "Canouville (France)",
  "Canteleu (France)",
  "Cantin (France)",
  "Cany-Barville (France)",
  "Capbreton (France)",
  "Capdenac-Gare (France)",
  "Capelle (France)",
  "Capelle-Fermont (France)",
  "Capendu (France)",
  "Capens (France)",
  "Capestang (France)",
  "Capinghem (France)",
  "Capoulet-et-Junac (France)",
  "Cappel (France)",
  "Cappelle-Brouck (France)",
  "Cappelle-en-Pevele (France)",
  "Cappelle-la-Grande (France)",
  "Captieux (France)",
  "Capvern (France)",
  "Caragoudes (France)",
  "Caraman (France)",
  "Carantec (France)",
  "Carantilly (France)",
  "Carbon-Blanc (France)",
  "Carbonne (France)",
  "Carcans (France)",
  "Carcassonne (France)",
  "Carces (France)",
  "Carcopino (France)",
  "Cardaillac (France)",
  "Cardesse (France)",
  "Cardonville (France)",
  "Cardroc (France)",
  "Carency (France)",
  "Carentan (France)",
  "Carentoir (France)",
  "Carhaix-Plouguer (France)",
  "Carignan (France)",
  "Carling (France)",
  "Carlipa (France)",
  "Carlucet (France)",
  "Carlus (France)",
  "Carmaux (France)",
  "Carnac (France)",
  "Carneville (France)",
  "Carnieres (France)",
  "Carnin (France)",
  "Carnon-Plage (France)",
  "Carnoules (France)",
  "Carolles (France)",
  "Caromb (France)",
  "Carpentras (France)",
  "Carpiquet (France)",
  "Carquefou (France)",
  "Carqueiranne (France)",
  "Carresse-Cassaber (France)",
  "Carrieres-sous-Poissy (France)",
  "Carrieres-sur-Seine (France)",
  "Carros (France)",
  "Carrouges (France)",
  "Carry-le-Rouet (France)",
  "Carsac-Aillac (France)",
  "Carspach (France)",
  "Cartelegue (France)",
  "Cartignies (France)",
  "Carville-la-Folletiere (France)",
  "Carvin (France)",
  "Casanova (France)",
  "Cases-de-Pene (France)",
  "Cassagne (France)",
  "Cassagnes (France)",
  "Cassagnes-Begonhes (France)",
  "Cassagnoles (France)",
  "Cassel (France)",
  "Casseneuil (France)",
  "Cassis (France)",
  "Casson (France)",
  "Cast (France)",
  "Castagnede (France)",
  "Castanet-le-Haut (France)",
  "Castanet-Tolosan (France)",
  "Casteide-Cami (France)",
  "Castelginest (France)",
  "Casteljaloux (France)",
  "Castellane (France)",
  "Castellar (France)",
  "Castellare-di-Mercurio (France)",
  "Castello-di-Rostino (France)",
  "Castelmoron-sur-Lot (France)",
  "Castelnau-de-Brassac (France)",
  "Castelnau-de-Levis (France)",
  "Castelnau-de-Montmiral (France)",
  "Castelnau-Durban (France)",
  "Castelnau-le-Lez (France)",
  "Castelnau-Montratier (France)",
  "Castelnau-sur-Gupie (France)",
  "Castelnau-Valence (France)",
  "Castelnaud-de-Gratecambe (France)",
  "Castelnaudary (France)",
  "Castels (France)",
  "Castelsagrat (France)",
  "Castelsarrasin (France)",
  "Castera-Loubix (France)",
  "Castet (France)",
  "Castetner (France)",
  "Castets-en-Dorthe (France)",
  "Castiglione (France)",
  "Castillon-Debats (France)",
  "Castillon-du-Gard (France)",
  "Castillon-la-Bataille (France)",
  "Castillon-Massas (France)",
  "Castillonnes (France)",
  "Castilly (France)",
  "Castres (France)",
  "Castres-Gironde (France)",
  "Castries (France)",
  "Catenay (France)",
  "Catenoy (France)",
  "Cattenom (France)",
  "Catus (France)",
  "Caucalieres (France)",
  "Caudan (France)",
  "Caudebec-en-Caux (France)",
  "Caudecoste (France)",
  "Caudrot (France)",
  "Caudry (France)",
  "Cauge (France)",
  "Caullery (France)",
  "Caulnes (France)",
  "Caumont (France)",
  "Caumont-sur-Durance (France)",
  "Caumont-sur-Garonne (France)",
  "Cauneille (France)",
  "Caunes-Minervois (France)",
  "Caurel (France)",
  "Cauroir (France)",
  "Cauroy-les-Hermonville (France)",
  "Caussade-Riviere (France)",
  "Causse-de-la-Selle (France)",
  "Cauvigny (France)",
  "Cauville-sur-Mer (France)",
  "Caux (France)",
  "Caux-et-Sauzens (France)",
  "Cauzac (France)",
  "Cavaillon (France)",
  "Cavalaire-sur-Mer (France)",
  "Cavan (France)",
  "Caveirac (France)",
  "Cavignac (France)",
  "Cavillargues (France)",
  "Cavillon (France)",
  "Cayeux-sur-Mer (France)",
  "Caylus (France)",
  "Cayrac (France)",
  "Cazals-des-Bayles (France)",
  "Cazaux (France)",
  "Cazaux-Layrisse (France)",
  "Cazavet (France)",
  "Cazeres (France)",
  "Cazes-Mondenard (France)",
  "Cazevieille (France)",
  "Cazilhac (France)",
  "Cazouls-les-Beziers (France)",
  "Ceauce (France)",
  "Ceaux (France)",
  "Cebazat (France)",
  "Ceignes (France)",
  "Ceintrey (France)",
  "Celle-Levescault (France)",
  "Celles-en-Bassigny (France)",
  "Celles-sur-Belle (France)",
  "Celles-sur-Durolle (France)",
  "Cellettes (France)",
  "Cellieu (France)",
  "Celon (France)",
  "Cenac (France)",
  "Cendras (France)",
  "Cenon (France)",
  "Cenon-sur-Vienne (France)",
  "Centres (France)",
  "Cepet (France)",
  "Cepie (France)",
  "Cepoy (France)",
  "Cerans-Foulletourte (France)",
  "Cercier (France)",
  "Cercoux (France)",
  "Cerdon (France)",
  "Cere (France)",
  "Cerelles (France)",
  "Cereste (France)",
  "Ceret (France)",
  "Cergy (France)",
  "Cerilly (France)",
  "Cerise (France)",
  "Cerisiers (France)",
  "Cerisy-la-Foret (France)",
  "Cerisy-la-Salle (France)",
  "Cerizay (France)",
  "Cernay (France)",
  "Cernay-la-Ville (France)",
  "Cerneux (France)",
  "Cerny (France)",
  "Cerons (France)",
  "Cers (France)",
  "Certines (France)",
  "Cervens (France)",
  "Cervera de la Marenda (France)",
  "Cervieres (France)",
  "Cervione (France)",
  "Cesar (France)",
  "Cesarches (France)",
  "Cescau (France)",
  "Cesny-aux-Vignes (France)",
  "Cesny-Bois-Halbout (France)",
  "Cessales (France)",
  "Cessenon-sur-Orb (France)",
  "Cesseras (France)",
  "Cessey (France)",
  "Cessieu (France)",
  "Cesson (France)",
  "Cessy (France)",
  "Cestas (France)",
  "Ceton (France)",
  "Ceyrat (France)",
  "Ceyreste (France)",
  "Ceyssat (France)",
  "Ceyzeriat (France)",
  "Cezay (France)",
  "Chaban (France)",
  "Chabanais (France)",
  "Chabestan (France)",
  "Chabeuil (France)",
  "Chablis (France)",
  "Chabons (France)",
  "Chabottes (France)",
  "Chabrillan (France)",
  "Chabris (France)",
  "Chacenay (France)",
  "Chadeleuf (France)",
  "Chadrac (France)",
  "Chadron (France)",
  "Chaffois (France)",
  "Chagey (France)",
  "Chagny (France)",
  "Chaignay (France)",
  "Chail (France)",
  "Chaillac (France)",
  "Chaillac-sur-Vienne (France)",
  "Chaille-les-Marais (France)",
  "Chailles (France)",
  "Chaillevette (France)",
  "Chailloue (France)",
  "Chailly-en-Biere (France)",
  "Chailly-en-Gatinais (France)",
  "Chainaz-les-Frasses (France)",
  "Chaingy (France)",
  "Chaix (France)",
  "Chalais (France)",
  "Chalamont (France)",
  "Chalampe (France)",
  "Chalandry-Elaire (France)",
  "Chaleins (France)",
  "Chalencon (France)",
  "Chalette-sur-Loing (France)",
  "Chalette-sur-Voire (France)",
  "Chaleze (France)",
  "Chalezeule (France)",
  "Chaligny (France)",
  "Chalindrey (France)",
  "Challain-la-Potherie (France)",
  "Challans (France)",
  "Challes-les-Eaux (France)",
  "Challet (France)",
  "Challex (France)",
  "Chalmaison (France)",
  "Chalo-Saint-Mars (France)",
  "Chalon (France)",
  "Chalonnes-sur-Loire (France)",
  "Chalons-en-Champagne (France)",
  "Chalonvillars (France)",
  "Chalou-Moulineux (France)",
  "Chamagne (France)",
  "Chamagnieu (France)",
  "Chambalud (France)",
  "Chamberet (France)",
  "Chambilly (France)",
  "Chamblac (France)",
  "Chamblanc (France)",
  "Chambles (France)",
  "Chamblet (France)",
  "Chambley-Bussieres (France)",
  "Chambly (France)",
  "Chamboeuf (France)",
  "Chambon (France)",
  "Chambon-sur-Lac (France)",
  "Chambon-sur-Voueize (France)",
  "Chambonas (France)",
  "Chambord (France)",
  "Chamboret (France)",
  "Chamborigaud (France)",
  "Chambors (France)",
  "Chambost-Longessaigne (France)",
  "Chamboulive (France)",
  "Chambourcy (France)",
  "Chambourg-sur-Indre (France)",
  "Chambrecy (France)",
  "Chambretaud (France)",
  "Chambry (France)",
  "Chamelet (France)",
  "Chameyrat (France)",
  "Chamigny (France)",
  "Chamonix (France)",
  "Chamouilley (France)",
  "Chamoux (France)",
  "Chamoux-sur-Gelon (France)",
  "Champ (France)",
  "Champ-le-Duc (France)",
  "Champ-sur-Drac (France)",
  "Champagnac (France)",
  "Champagnac-le-Vieux (France)",
  "Champagnat (France)",
  "Champagne (France)",
  "Champagne-les-Marais (France)",
  "Champagne-Mouton (France)",
  "Champagne-sur-Oise (France)",
  "Champagne-sur-Seine (France)",
  "Champagney (France)",
  "Champagnole (France)",
  "Champanges (France)",
  "Champcenest (France)",
  "Champcerie (France)",
  "Champcevinel (France)",
  "Champcueil (France)",
  "Champdieu (France)",
  "Champdotre (France)",
  "Champdray (France)",
  "Champeaux (France)",
  "Champeix (France)",
  "Champenay (France)",
  "Champenoux (France)",
  "Champeon (France)",
  "Champey-sur-Moselle (France)",
  "Champforgeuil (France)",
  "Champfromier (France)",
  "Champhol (France)",
  "Champien (France)",
  "Champier (France)",
  "Champigne (France)",
  "Champigneulles (France)",
  "Champigny (France)",
  "Champigny-sur-Marne (France)",
  "Champlan (France)",
  "Champlemy (France)",
  "Champlitte (France)",
  "Champniers (France)",
  "Champoly (France)",
  "Champoulet (France)",
  "Champs (France)",
  "Champs-sur-Marne (France)",
  "Champs-sur-Tarentaine-Marchal (France)",
  "Champs-sur-Yonne (France)",
  "Champsac (France)",
  "Champtercier (France)",
  "Champtoce-sur-Loire (France)",
  "Champtoceaux (France)",
  "Champvans (France)",
  "Chanas (France)",
  "Chanceaux-sur-Choisille (France)",
  "Chancelade (France)",
  "Chancenay (France)",
  "Change (France)",
  "Changis-sur-Marne (France)",
  "Chaniers (France)",
  "Chanonat (France)",
  "Chanoz-Chatenay (France)",
  "Chanteau (France)",
  "Chantecorps (France)",
  "Chanteheux (France)",
  "Chanteloup (France)",
  "Chanteloup-en-Brie (France)",
  "Chanteloup-les-Bois (France)",
  "Chanteloup-les-Vignes (France)",
  "Chantemerle-sur-la-Soie (France)",
  "Chantepie (France)",
  "Chanteuges (France)",
  "Chantilly (France)",
  "Chantome (France)",
  "Chantonnay (France)",
  "Chantraine (France)",
  "Chantrans (France)",
  "Chanveaux (France)",
  "Chaon (France)",
  "Chapareillan (France)",
  "Chapdes-Beaufort (France)",
  "Chapeiry (France)",
  "Chapelle-Vallon (France)",
  "Chapelle-Voland (France)",
  "Chaponnay (France)",
  "Chaponost (France)",
  "Chaptelat (France)",
  "Chaptuzat (France)",
  "Charancieu (France)",
  "Charantonnay (France)",
  "Charavines (France)",
  "Charbonnieres-les-Bains (France)",
  "Charbonnieres-les-Varennes (France)",
  "Charbuy (France)",
  "Charentay (France)",
  "Charenton-le-Pont (France)",
  "Charge (France)",
  "Chargey-les-Gray (France)",
  "Chargey-les-Port (France)",
  "Charleval (France)",
  "Charleville (France)",
  "Charlieu (France)",
  "Charly (France)",
  "Charly-sur-Marne (France)",
  "Charmant (France)",
  "Charmes (France)",
  "Charmes-sur-Rhone (France)",
  "Charmont-en-Beauce (France)",
  "Charmont-sous-Barbuise (France)",
  "Charnas (France)",
  "Charnay (France)",
  "Charnay-les-Macon (France)",
  "Charnecles (France)",
  "Charny (France)",
  "Charolles (France)",
  "Charonville (France)",
  "Charost (France)",
  "Charquemont (France)",
  "Charrecey (France)",
  "Charron (France)",
  "Charroux (France)",
  "Charsonville (France)",
  "Charteves (France)",
  "Chartres (France)",
  "Chartres-de-Bretagne (France)",
  "Chartrettes (France)",
  "Charvieu-Chavagneux (France)",
  "Charvonnex (France)",
  "Chas (France)",
  "Chasnais (France)",
  "Chasne-sur-Illet (France)",
  "Chaspinhac (France)",
  "Chassagnes (France)",
  "Chassagny (France)",
  "Chasse-sur-Rhone (France)",
  "Chasselay (France)",
  "Chassemy (France)",
  "Chassenard (France)",
  "Chasseneuil (France)",
  "Chasseneuil-du-Poitou (France)",
  "Chasseneuil-sur-Bonnieure (France)",
  "Chassey-le-Camp (France)",
  "Chassiers (France)",
  "Chassieu (France)",
  "Chataincourt (France)",
  "Chateau (France)",
  "Chateau-Arnoux-Saint-Auban (France)",
  "Chateau-Chervix (France)",
  "Chateau-Chinon(Ville) (France)",
  "Chateau-du-Loir (France)",
  "Chateau-Gontier (France)",
  "Chateau-Landon (France)",
  "Chateau-Porcien (France)",
  "Chateau-Renault (France)",
  "Chateau-Salins (France)",
  "Chateau-sur-Epte (France)",
  "Chateaubleau (France)",
  "Chateaubourg (France)",
  "Chateaubriand (France)",
  "Chateaubriant (France)",
  "Chateaudouble (France)",
  "Chateaufort (France)",
  "Chateaugay (France)",
  "Chateaugiron (France)",
  "Chateaumeillant (France)",
  "Chateauneuf (France)",
  "Chateauneuf-de-Bordette (France)",
  "Chateauneuf-de-Gadagne (France)",
  "Chateauneuf-de-Galaure (France)",
  "Chateauneuf-du-Faou (France)",
  "Chateauneuf-du-Rhone (France)",
  "Chateauneuf-en-Thymerais (France)",
  "Chateauneuf-Grasse (France)",
  "Chateauneuf-la-Foret (France)",
  "Chateauneuf-le-Rouge (France)",
  "Chateauneuf-les-Martigues (France)",
  "Chateauneuf-sur-Charente (France)",
  "Chateauneuf-sur-Loire (France)",
  "Chateauneuf-sur-Sarthe (France)",
  "Chateaurenard (France)",
  "Chateauvillain (France)",
  "Chatel (France)",
  "Chatel-de-Neuvre (France)",
  "Chatel-Guyon (France)",
  "Chatel-Saint-Germain (France)",
  "Chatel-sur-Moselle (France)",
  "Chatelaillon-Plage (France)",
  "Chatelaines (France)",
  "Chatelais (France)",
  "Chatelaudren (France)",
  "Chateldon (France)",
  "Chatelet (France)",
  "Chatelus (France)",
  "Chatelus-le-Marcheix (France)",
  "Chatenay-sur-Seine (France)",
  "Chatenet (France)",
  "Chatenois (France)",
  "Chatenois-les-Forges (France)",
  "Chatenoy (France)",
  "Chatenoy-le-Royal (France)",
  "Chatignonville (France)",
  "Chatillon (France)",
  "Chatillon-Coligny (France)",
  "Chatillon-la-Palud (France)",
  "Chatillon-le-Duc (France)",
  "Chatillon-le-Roi (France)",
  "Chatillon-les-Sons (France)",
  "Chatillon-sur-Chalaronne (France)",
  "Chatillon-sur-Cher (France)",
  "Chatillon-sur-Indre (France)",
  "Chatillon-sur-Loire (France)",
  "Chatillon-sur-Saone (France)",
  "Chatillon-sur-Seiche (France)",
  "Chatillon-sur-Thouet (France)",
  "Chatonnay (France)",
  "Chatonrupt-Sommermont (France)",
  "Chatou (France)",
  "Chatres (France)",
  "Chatres-sur-Cher (France)",
  "Chatte (France)",
  "Chatuzange-le-Goubet (France)",
  "Chauche (France)",
  "Chaudefonds-sur-Layon (France)",
  "Chaudefontaine (France)",
  "Chaudon (France)",
  "Chaudron-en-Mauges (France)",
  "Chauffailles (France)",
  "Chauffry (France)",
  "Chaufour-les-Bonnieres (France)",
  "Chaufour-Notre-Dame (France)",
  "Chaulnes (France)",
  "Chaumes (France)",
  "Chaumes-en-Brie (France)",
  "Chaumont (France)",
  "Chaumont-en-Vexin (France)",
  "Chaumontel (France)",
  "Chaumousey (France)",
  "Chauny (France)",
  "Chauray (France)",
  "Chauriat (France)",
  "Chaussin (France)",
  "Chaussoy-Epagny (France)",
  "Chaussy (France)",
  "Chauve (France)",
  "Chauvigny (France)",
  "Chauvincourt-Provemont (France)",
  "Chauvry (France)",
  "Chaux (France)",
  "Chavagne (France)",
  "Chavagnes-en-Paillers (France)",
  "Chavagnes-les-Redoux (France)",
  "Chavaignes (France)",
  "Chavanay (France)",
  "Chavannes-sur-Reyssouze (France)",
  "Chavanod (France)",
  "Chavanoz (France)",
  "Chavaroux (France)",
  "Chavenay (France)",
  "Chavigny (France)",
  "Chaville (France)",
  "Chazelles (France)",
  "Chazelles-sur-Lyon (France)",
  "Checy (France)",
  "Chedigny (France)",
  "Chef-Boutonne (France)",
  "Cheffes (France)",
  "Chehery (France)",
  "Chelan (France)",
  "Chelles (France)",
  "Chemaze (France)",
  "Chemere (France)",
  "Chemere-le-Roi (France)",
  "Chemery (France)",
  "Chemery-les-Deux (France)",
  "Chemille-Melay (France)",
  "Chemilly-sur-Serein (France)",
  "Cheminot (France)",
  "Chemy (France)",
  "Chenailler-Mascheix (France)",
  "Chenaud (France)",
  "Chenay-le-Chatel (France)",
  "Chenerailles (France)",
  "Chenevelles (France)",
  "Chenex (France)",
  "Chenimenil (France)",
  "Chennevieres-sur-Marne (France)",
  "Chenonceaux (France)",
  "Chenou (France)",
  "Chenove (France)",
  "Chens-sur-Leman (France)",
  "Cheny (France)",
  "Chepoix (France)",
  "Cheptainville (France)",
  "Chepy (France)",
  "Cherac (France)",
  "Cherbourg-Octeville (France)",
  "Chereng (France)",
  "Cheret (France)",
  "Cherier (France)",
  "Cherisey (France)",
  "Cherisy (France)",
  "Cheron (France)",
  "Cheroy (France)",
  "Cherre (France)",
  "Cherreau (France)",
  "Cherves-Chatelars (France)",
  "Cherves-Richemont (France)",
  "Chervettes (France)",
  "Cherveux (France)",
  "Chesley (France)",
  "Chessy (France)",
  "Chessy-les-Pres (France)",
  "Cheux (France)",
  "Chevaigne (France)",
  "Cheval-Blanc (France)",
  "Chevalet-le-Haut (France)",
  "Chevanceaux (France)",
  "Chevannes (France)",
  "Cheverny (France)",
  "Chevigny (France)",
  "Chevigny-Saint-Sauveur (France)",
  "Chevillon (France)",
  "Chevillon-sur-Huillard (France)",
  "Chevilly (France)",
  "Chevilly-Larue (France)",
  "Chevincourt (France)",
  "Chevire-le-Rouge (France)",
  "Chevrainvilliers (France)",
  "Chevremont (France)",
  "Chevreuse (France)",
  "Chevrier (France)",
  "Chevrieres (France)",
  "Chevrotaine (France)",
  "Chevru (France)",
  "Chevry (France)",
  "Chevry-Cossigny (France)",
  "Chevry-en-Sereine (France)",
  "Cheyssieu (France)",
  "Chezery-Forens (France)",
  "Chezy (France)",
  "Chezy-sur-Marne (France)",
  "Chiche (France)",
  "Chichery (France)",
  "Chierry (France)",
  "Chieulles (France)",
  "Chignin (France)",
  "Chigny-les-Roses (France)",
  "Chigy (France)",
  "Chilhac (France)",
  "Chille (France)",
  "Chilly (France)",
  "Chilly-Mazarin (France)",
  "Chimilin (France)",
  "Chinon (France)",
  "Chire-en-Montreuil (France)",
  "Chirens (France)",
  "Chiry-Ourscamp (France)",
  "Chissay-en-Touraine (France)",
  "Chisseaux (France)",
  "Chissey-en-Morvan (France)",
  "Chitenay (France)",
  "Chivres-en-Laonnois (France)",
  "Chize (France)",
  "Chocques (France)",
  "Choisel (France)",
  "Choisey (France)",
  "Choisy (France)",
  "Choisy-au-Bac (France)",
  "Choisy-en-Brie (France)",
  "Choisy-le-Roi (France)",
  "Cholet (France)",
  "Chomerac (France)",
  "Chorges (France)",
  "Chourgnac (France)",
  "Chouy (France)",
  "Choye (France)",
  "Chozeau (France)",
  "Chuelles (France)",
  "Chuzelles (France)",
  "Ciboure (France)",
  "Ciel (France)",
  "Cierrey (France)",
  "Cieurac (France)",
  "Cieux (France)",
  "Cinq-Mars-la-Pile (France)",
  "Cinqueux (France)",
  "Cintegabelle (France)",
  "Cintheaux (France)",
  "Cintre (France)",
  "Cires-les-Mello (France)",
  "Cirey (France)",
  "Cirey-sur-Vezouze (France)",
  "Ciron (France)",
  "Ciry-le-Noble (France)",
  "Cissac-Medoc (France)",
  "Cisse (France)",
  "Cistrieres (France)",
  "Citerne (France)",
  "Citry (France)",
  "Civaux (France)",
  "Civens (France)",
  "Civrac-de-Blaye (France)",
  "Civrac-en-Medoc (France)",
  "Civray (France)",
  "Civray-de-Touraine (France)",
  "Civrieux (France)",
  "Civry-en-Montagne (France)",
  "Cize (France)",
  "Cizely (France)",
  "Claira (France)",
  "Clairac (France)",
  "Clairfontaine (France)",
  "Clairmarais (France)",
  "Clairoix (France)",
  "Clairvaux-dAveyron (France)",
  "Clairvaux-les-Lacs (France)",
  "Claix (France)",
  "Clamart (France)",
  "Clamecy (France)",
  "Claouey (France)",
  "Clapiers (France)",
  "Clarac (France)",
  "Claracq (France)",
  "Clarafond (France)",
  "Clarensac (France)",
  "Clarques (France)",
  "Clary (France)",
  "Classun (France)",
  "Clasville (France)",
  "Clavel (France)",
  "Clavette (France)",
  "Claviers (France)",
  "Claville (France)",
  "Claye-Souilly (France)",
  "Cleder (France)",
  "Cleguer (France)",
  "Cleguerec (France)",
  "Clelles (France)",
  "Clement (France)",
  "Clemery (France)",
  "Clemont (France)",
  "Clenay (France)",
  "Cleon (France)",
  "Clerieux (France)",
  "Clermont (France)",
  "Clermont-Ferrand (France)",
  "Clerval (France)",
  "Clery-Saint-Andre (France)",
  "Clesse (France)",
  "Clety (France)",
  "Clevilliers (France)",
  "Clichy (France)",
  "Clichy-sous-Bois (France)",
  "Clinchamps-sur-Orne (France)",
  "Cliron (France)",
  "Clisson (France)",
  "Clohars-Carnoet (France)",
  "Clohars-Fouesnant (France)",
  "Clouange (France)",
  "Clouzeau (France)",
  "Cloyes-sur-le-Loir (France)",
  "Cluny (France)",
  "Cluses (France)",
  "Coaraze (France)",
  "Coarraze (France)",
  "Cobrieux (France)",
  "Cocheren (France)",
  "Cocumont (France)",
  "Codognan (France)",
  "Codolet (France)",
  "Coesmes (France)",
  "Coeuvres-et-Valsery (France)",
  "Coex (France)",
  "Cogles (France)",
  "Cogna (France)",
  "Cognac (France)",
  "Cognin (France)",
  "Cogolin (France)",
  "Coignet (France)",
  "Coignieres (France)",
  "Coimeres (France)",
  "Coincy (France)",
  "Coirac (France)",
  "Coise-Saint-Jean-Pied-Gauthier (France)",
  "Coivrel (France)",
  "Colayrac-Saint-Cirq (France)",
  "Colembert (France)",
  "Coligny (France)",
  "Collandres-Quincarnon (France)",
  "Collegien (France)",
  "Colleret (France)",
  "Colleville (France)",
  "Colleville-Montgomery (France)",
  "Collias (France)",
  "Collioure (France)",
  "Collobrieres (France)",
  "Collonges-les-Premieres (France)",
  "Collonges-sous-Saleve (France)",
  "Collorgues (France)",
  "Colmar (France)",
  "Colmars (France)",
  "Cologne (France)",
  "Colomars (France)",
  "Colombe (France)",
  "Colombelles (France)",
  "Colombes (France)",
  "Colombier (France)",
  "Colombier-Fontaine (France)",
  "Colombier-le-Cardinal (France)",
  "Colombier-le-Vieux (France)",
  "Colombier-Saugnieu (France)",
  "Colombieres-sur-Orb (France)",
  "Colombiers (France)",
  "Colomby-sur-Thaon (France)",
  "Colomiers (France)",
  "Colomieu (France)",
  "Colondannes (France)",
  "Colonzelle (France)",
  "Colpo (France)",
  "Coltainville (France)",
  "Combas (France)",
  "Comblanchien (France)",
  "Combloux (France)",
  "Combon (France)",
  "Combourg (France)",
  "Combrand (France)",
  "Combree (France)",
  "Combrit (France)",
  "Combronde (France)",
  "Combs-la-Ville (France)",
  "Comines (France)",
  "Commelle-Vernay (France)",
  "Commenchon (France)",
  "Commensacq (France)",
  "Commentry (France)",
  "Commeny (France)",
  "Commequiers (France)",
  "Commer (France)",
  "Commercy (France)",
  "Commes (France)",
  "Commissey (France)",
  "Communay (France)",
  "Compans (France)",
  "Compertrix (France)",
  "Compolibat (France)",
  "Compreignac (France)",
  "Comps (France)",
  "Concarneau (France)",
  "Conceze (France)",
  "Conches-en-Ouche (France)",
  "Conches-sur-Gondoire (France)",
  "Conchy-les-Pots (France)",
  "Condac (France)",
  "Condat (France)",
  "Condat-sur-Vienne (France)",
  "Conde-en-Brie (France)",
  "Conde-Northen (France)",
  "Conde-Sainte-Libiaire (France)",
  "Conde-sur-Iton (France)",
  "Conde-sur-Marne (France)",
  "Conde-sur-Noireau (France)",
  "Conde-sur-Sarthe (France)",
  "Conde-sur-Suippe (France)",
  "Conde-sur-Vesgre (France)",
  "Conde-sur-Vire (France)",
  "Condecourt (France)",
  "Condeon (France)",
  "Condette (France)",
  "Condezaygues (France)",
  "Condillac (France)",
  "Condom (France)",
  "Condrieu (France)",
  "Conflans-en-Jarnisy (France)",
  "Conflans-Sainte-Honorine (France)",
  "Conflans-sur-Loing (France)",
  "Conflans-sur-Seine (France)",
  "Confolens (France)",
  "Congenies (France)",
  "Conliege (France)",
  "Connantray-Vaurefroy (France)",
  "Conne-de-Labarde (France)",
  "Connerre (France)",
  "Conquereuil (France)",
  "Conques-sur-Orbiel (France)",
  "Cons-Sainte-Colombe (France)",
  "Contamine-sur-Arve (France)",
  "Contes (France)",
  "Contigne (France)",
  "Contoire (France)",
  "Contre (France)",
  "Contres (France)",
  "Contrexeville (France)",
  "Contrisson (France)",
  "Contz-les-Bains (France)",
  "Copponex (France)",
  "Coquelles (France)",
  "Corancez (France)",
  "Coray (France)",
  "Corbara (France)",
  "Corbarieu (France)",
  "Corbas (France)",
  "Corbehem (France)",
  "Corbeil-Cerf (France)",
  "Corbeil-Essonnes (France)",
  "Corbeilles (France)",
  "Corbelin (France)",
  "Corbeny (France)",
  "Corbere-Aberes (France)",
  "Corbere-les-Cabanes (France)",
  "Corbie (France)",
  "Corbieres (France)",
  "Corbigny (France)",
  "Corbonod (France)",
  "Corbreuse (France)",
  "Corcelles-les-Citeaux (France)",
  "Corcelles-les-Monts (France)",
  "Corcieux (France)",
  "Corconne (France)",
  "Corcy (France)",
  "Cordelle (France)",
  "Cordemais (France)",
  "Cordes-sur-Ciel (France)",
  "Cordes-Tolosannes (France)",
  "Cordon (France)",
  "Corenc (France)",
  "Corgnac-sur-lIsle (France)",
  "Corgoloin (France)",
  "Corignac (France)",
  "Corlay (France)",
  "Cormainville (France)",
  "Corme-Ecluse (France)",
  "Corme-Royal (France)",
  "Cormeilles (France)",
  "Cormeilles-en-Parisis (France)",
  "Cormeilles-en-Vexin (France)",
  "Cormelles-le-Royal (France)",
  "Cormeray (France)",
  "Cormery (France)",
  "Cormes (France)",
  "Cormicy (France)",
  "Cormolain (France)",
  "Cormontreuil (France)",
  "Cormoranche-sur-Saone (France)",
  "Cornac (France)",
  "Cornas (France)",
  "Corne (France)",
  "Cornebarrieu (France)",
  "Corneilla-de-Conflent (France)",
  "Corneilla-del-Vercol (France)",
  "Corneilla-la-Riviere (France)",
  "Cornier (France)",
  "Cornille (France)",
  "Cornillon-Confoux (France)",
  "Cornillon-en-Trieves (France)",
  "Cornimont (France)",
  "Cornus (France)",
  "Corny-sur-Moselle (France)",
  "Coron (France)",
  "Corpe (France)",
  "Corps-Nuds (France)",
  "Correze (France)",
  "Corroy (France)",
  "Cors (France)",
  "Corsept (France)",
  "Corseul (France)",
  "Corte (France)",
  "Corveissiat (France)",
  "Corze (France)",
  "Cosges (France)",
  "Cosmes (France)",
  "Cosne-Cours-sur-Loire (France)",
  "Cosqueville (France)",
  "Cosse-le-Vivien (France)",
  "Cossesseville (France)",
  "Costaros (France)",
  "Cotdoussan (France)",
  "Cotignac (France)",
  "Cottenchy (France)",
  "Cottevrard (France)",
  "Cottun (France)",
  "Couargues (France)",
  "Coublevie (France)",
  "Coubon (France)",
  "Coubron (France)",
  "Couches (France)",
  "Couchey (France)",
  "Coucy-le-Chateau-Auffrique (France)",
  "Couddes (France)",
  "Coudehard (France)",
  "Coudekerque-Branche (France)",
  "Coudes (France)",
  "Coudoux (France)",
  "Coudray (France)",
  "Coudray-au-Perche (France)",
  "Coudray-Rabut (France)",
  "Coudroy (France)",
  "Coudun (France)",
  "Coueron (France)",
  "Couesmes-Vauce (France)",
  "Coufouleux (France)",
  "Couhe (France)",
  "Couilly-Pont-aux-Dames (France)",
  "Couiza (France)",
  "Coulaines (France)",
  "Coulandon (France)",
  "Coulans-sur-Gee (France)",
  "Coulaures (France)",
  "Coullons (France)",
  "Coulogne (France)",
  "Couloisy (France)",
  "Coulombiers (France)",
  "Coulomby (France)",
  "Coulommes (France)",
  "Coulommiers (France)",
  "Coulommiers-la-Tour (France)",
  "Coulon (France)",
  "Coulonces (France)",
  "Coulonges (France)",
  "Coulonges-Thouarsais (France)",
  "Coulounieix-Chamiers (France)",
  "Coulouvray-Boisbenatre (France)",
  "Coulvain (France)",
  "Coulx (France)",
  "Coume (France)",
  "Coupelle-Vieille (France)",
  "Coupesarte (France)",
  "Coupeville (France)",
  "Coupigny (France)",
  "Coupru (France)",
  "Coupvray (France)",
  "Cour-Cheverny (France)",
  "Cour-et-Buis (France)",
  "Cour-sur-Loire (France)",
  "Courances (France)",
  "Courbeveille (France)",
  "Courbevoie (France)",
  "Courcais (France)",
  "Courcay (France)",
  "Courcelles (France)",
  "Courcelles-Chaussy (France)",
  "Courcelles-en-Bassee (France)",
  "Courcelles-Epayelles (France)",
  "Courcelles-les-Gisors (France)",
  "Courcelles-les-Lens (France)",
  "Courcelles-sur-Nied (France)",
  "Courcelles-sur-Seine (France)",
  "Courcelles-sur-Viosne (France)",
  "Courcemont (France)",
  "Courcerac (France)",
  "Courchaton (France)",
  "Courchelettes (France)",
  "Courchevel (France)",
  "Courcon (France)",
  "Courcouronnes (France)",
  "Courcy (France)",
  "Courcy-aux-Loges (France)",
  "Courdemanche (France)",
  "Courdimanche-sur-Essonne (France)",
  "Courgis (France)",
  "Courlans (France)",
  "Courlaoux (France)",
  "Courlay (France)",
  "Courmelles (France)",
  "Courmemin (France)",
  "Cournon (France)",
  "Cournon-dAuvergne (France)",
  "Cournonsec (France)",
  "Cournonterral (France)",
  "Courpalay (France)",
  "Courpiere (France)",
  "Courquetaine (France)",
  "Cours (France)",
  "Cours-de-Monsegur (France)",
  "Cours-la-Ville (France)",
  "Cours-les-Bains (France)",
  "Cours-les-Barres (France)",
  "Coursac (France)",
  "Coursan (France)",
  "Courseulles-sur-Mer (France)",
  "Courson (France)",
  "Courson-les-Carrieres (France)",
  "Courson-Monteloup (France)",
  "Courtelevant (France)",
  "Courtenay (France)",
  "Courtenot (France)",
  "Courteranges (France)",
  "Courthezon (France)",
  "Courtisols (France)",
  "Courtomer (France)",
  "Courtonne-la-Meurdrac (France)",
  "Courtry (France)",
  "Courville-sur-Eure (France)",
  "Courzieu (France)",
  "Cousance (France)",
  "Cousances-les-Forges (France)",
  "Cousolre (France)",
  "Coussay-les-Bois (France)",
  "Coutances (France)",
  "Coutarnoux (France)",
  "Couterne (France)",
  "Couternon (France)",
  "Couteuges (France)",
  "Coutevroult (France)",
  "Coutiches (France)",
  "Coutras (France)",
  "Couvains (France)",
  "Couville (France)",
  "Couvron-et-Aumencourt (France)",
  "Coux (France)",
  "Couy (France)",
  "Couze-et-Saint-Front (France)",
  "Couzeix (France)",
  "Couzon (France)",
  "Coye-la-Foret (France)",
  "Coyriere (France)",
  "Cozes (France)",
  "Crach (France)",
  "Craincourt (France)",
  "Cramant (France)",
  "Cran-Gevrier (France)",
  "Crancey (France)",
  "Crancot (France)",
  "Cransac (France)",
  "Cranves-Sales (France)",
  "Craon (France)",
  "Craonne (France)",
  "Craponne (France)",
  "Cras-sur-Reyssouze (France)",
  "Cravanche (France)",
  "Cravans (France)",
  "Cravant (France)",
  "Cravant-les-Coteaux (France)",
  "Cravent (France)",
  "Crayssac (France)",
  "Crazannes (France)",
  "Creances (France)",
  "Creches-sur-Saone (France)",
  "Crecy-sur-Serre (France)",
  "Credin (France)",
  "Cregy-les-Meaux (France)",
  "Crehange (France)",
  "Crehen (France)",
  "Creil (France)",
  "Creissan (France)",
  "Creissels (France)",
  "Cremeaux (France)",
  "Cremieu (France)",
  "Crepey (France)",
  "Cresancey (France)",
  "Crespieres (France)",
  "Crespin (France)",
  "Cressat (France)",
  "Cresse (France)",
  "Cresserons (France)",
  "Crest (France)",
  "Creully (France)",
  "Creusot (France)",
  "Creutzwald (France)",
  "Creuzier-le-Neuf (France)",
  "Creuzier-le-Vieux (France)",
  "Crevant-Laveine (France)",
  "Crevechamps (France)",
  "Crevecoeur-le-Grand (France)",
  "Crevin (France)",
  "Crezancy (France)",
  "Criel-sur-Mer (France)",
  "Crillon-le-Brave (France)",
  "Crimolois (France)",
  "Crisenoy (France)",
  "Crisolles (France)",
  "Crissey (France)",
  "Crochte (France)",
  "Crocq (France)",
  "Croisette (France)",
  "Croisilles (France)",
  "Croissanville (France)",
  "Croissy-Beaubourg (France)",
  "Croissy-sur-Celle (France)",
  "Croissy-sur-Seine (France)",
  "Croix (France)",
  "Croix-Chapeau (France)",
  "Croix-de-Vie (France)",
  "Croix-Mare (France)",
  "Croixanvec (France)",
  "Crolles (France)",
  "Cros-de-Ronesque (France)",
  "Crosne (France)",
  "Crottet (France)",
  "Crouay (France)",
  "Croutelle (France)",
  "Crouttes (France)",
  "Crouy-sur-Cosson (France)",
  "Crouy-sur-Ourcq (France)",
  "Croze (France)",
  "Crozes-Hermitage (France)",
  "Crozon (France)",
  "Cruas (France)",
  "Cruet (France)",
  "Crugny (France)",
  "Cruscades (France)",
  "Cruseilles (France)",
  "Crusnes (France)",
  "Cruzilles-les-Mepillat (France)",
  "Cruzy (France)",
  "Cublac (France)",
  "Cubry-les-Soing (France)",
  "Cubzac-les-Ponts (France)",
  "Cucq (France)",
  "Cucuron (France)",
  "Cuers (France)",
  "Cuffies (France)",
  "Cuffy (France)",
  "Cugand (France)",
  "Cuges-les-Pins (France)",
  "Cugnaux (France)",
  "Cuigy-en-Bray (France)",
  "Cuinchy (France)",
  "Cuincy (France)",
  "Cuinzier (France)",
  "Cuise-la-Motte (France)",
  "Cuiseaux (France)",
  "Cuisery (France)",
  "Culhat (France)",
  "Culmont (France)",
  "Culoz (France)",
  "Cumieres (France)",
  "Cunfin (France)",
  "Cunlhat (France)",
  "Cuon (France)",
  "Cuperly (France)",
  "Cuqueron (France)",
  "Curchy (France)",
  "Curdin (France)",
  "Curel (France)",
  "Curgies (France)",
  "Curtafond (France)",
  "Curzay-sur-Vonne (France)",
  "Cussay (France)",
  "Cusset (France)",
  "Cussey-les-Forges (France)",
  "Cussy-les-Forges (France)",
  "Custines (France)",
  "Cusy (France)",
  "Cuttoli-Corticchiato (France)",
  "Cuvergnon (France)",
  "Cuverville (France)",
  "Cuvilly (France)",
  "Cuvry (France)",
  "Cuxac-Cabardes (France)",
  "Cuy (France)",
  "Cuy-Saint-Fiacre (France)",
  "Cuzieu (France)",
  "Cysoing (France)",
  "Dabo (France)",
  "Dachstein (France)",
  "Dadonville (France)",
  "Dagneux (France)",
  "Dainville (France)",
  "Dalhunden (France)",
  "Dallet (France)",
  "Dalmas (France)",
  "Damazan (France)",
  "Dambach-la-Ville (France)",
  "Dambenois (France)",
  "Dame-Marie (France)",
  "Damelevieres (France)",
  "Damerey (France)",
  "Damery (France)",
  "Damgan (France)",
  "Damiatte (France)",
  "Damigny (France)",
  "Dammarie-les-Lys (France)",
  "Dammarie-sur-Saulx (France)",
  "Dammartin-en-Goele (France)",
  "Dammartin-en-Serve (France)",
  "Damparis (France)",
  "Dampierre (France)",
  "Dampierre-au-Temple (France)",
  "Dampierre-en-Burly (France)",
  "Dampierre-en-Crot (France)",
  "Dampierre-les-Bois (France)",
  "Dampierre-les-Conflans (France)",
  "Dampierre-sur-Blevy (France)",
  "Dampierre-sur-Boutonne (France)",
  "Dampmart (France)",
  "Damprichard (France)",
  "Dampsmesnil (France)",
  "Dampvalley-les-Colombe (France)",
  "Damville (France)",
  "Damvix (France)",
  "Dancevoir (France)",
  "Dangeau (France)",
  "Dangeul (France)",
  "Danjoutin (France)",
  "Danne-et-Quatre-Vents (France)",
  "Dannemoine (France)",
  "Daoulas (France)",
  "Dardilly (France)",
  "Dareize (France)",
  "Dargnies (France)",
  "Dargoire (France)",
  "Darmont (France)",
  "Darnetal (France)",
  "Darney (France)",
  "Darnieulles (France)",
  "Darvoy (France)",
  "Dasle (France)",
  "Daubensand (France)",
  "Daubeuf-la-Campagne (France)",
  "Dauendorf (France)",
  "Daumeray (France)",
  "Daux (France)",
  "Dauzat-sur-Vodable (France)",
  "Davenescourt (France)",
  "Davezieux (France)",
  "Dax (France)",
  "Deauville (France)",
  "Decazeville (France)",
  "Dechy (France)",
  "Decines-Charpieu (France)",
  "Decize (France)",
  "Delettes (France)",
  "Delle (France)",
  "Delme (France)",
  "Demouville (France)",
  "Demuin (France)",
  "Denain (France)",
  "Denat (France)",
  "Denee (France)",
  "Denevre (France)",
  "Denice (France)",
  "Denier (France)",
  "Dennebroeucq (France)",
  "Denneville (France)",
  "Denney (France)",
  "Denting (France)",
  "Dercy (France)",
  "Dernancourt (France)",
  "Derval (France)",
  "Desaignes (France)",
  "Desertines (France)",
  "Desvres (France)",
  "Detrier (France)",
  "Dettwiller (France)",
  "Deuil-la-Barre (France)",
  "Deulemont (France)",
  "Devecey (France)",
  "Devillac (France)",
  "Deville-les-Rouen (France)",
  "Devrouze (France)",
  "Deyme (France)",
  "Deyvillers (France)",
  "Dezize-les-Maranges (France)",
  "Dhuisy (France)",
  "Diarville (France)",
  "Didenheim (France)",
  "Die (France)",
  "Diebling (France)",
  "Dieffenbach-au-Val (France)",
  "Diemeringen (France)",
  "Diemoz (France)",
  "Dienville (France)",
  "Dieppe (France)",
  "Dieppe-sous-Douaumont (France)",
  "Dierre (France)",
  "Dietwiller (France)",
  "Dieue-sur-Meuse (France)",
  "Dieulefit (France)",
  "Dieulouard (France)",
  "Dieupentale (France)",
  "Dieuze (France)",
  "Dieval (France)",
  "Diffembach-les-Hellimer (France)",
  "Diges (France)",
  "Digne-les-Bains (France)",
  "Digoin (France)",
  "Dijon (France)",
  "Dimbsthal (France)",
  "Dimechaux (France)",
  "Dinan (France)",
  "Dinard (France)",
  "Dinge (France)",
  "Dingsheim (France)",
  "Dingy-en-Vuache (France)",
  "Dingy-Saint-Clair (France)",
  "Dinoze (France)",
  "Dionay (France)",
  "Dissay (France)",
  "Disse-sous-Ballon (France)",
  "Distre (France)",
  "Distroff (France)",
  "Dive (France)",
  "Dives-sur-Mer (France)",
  "Divion (France)",
  "Divonne-les-Bains (France)",
  "Dixmont (France)",
  "Dizy (France)",
  "Doazit (France)",
  "Docelles (France)",
  "Doeuil-sur-le-Mignon (France)",
  "Dogneville (France)",
  "Doingt (France)",
  "Doissin (France)",
  "Doix (France)",
  "Dol-de-Bretagne (France)",
  "Dole (France)",
  "Dollon (France)",
  "Dolo (France)",
  "Dolomieu (France)",
  "Domagne (France)",
  "Domaize (France)",
  "Domalain (France)",
  "Domancy (France)",
  "Domart-sur-la-Luce (France)",
  "Domats (France)",
  "Dombasle-sur-Meurthe (France)",
  "Domene (France)",
  "Domerat (France)",
  "Domessargues (France)",
  "Domessin (France)",
  "Domevre-sur-Durbion (France)",
  "Domezain-Berraute (France)",
  "Domfront (France)",
  "Domgermain (France)",
  "Domjevin (France)",
  "Dommartin (France)",
  "Dommartin-les-Remiremont (France)",
  "Dommartin-les-Toul (France)",
  "Domont (France)",
  "Dompierre-les-Ormes (France)",
  "Dompierre-sur-Besbre (France)",
  "Dompierre-sur-Mer (France)",
  "Dompierre-sur-Veyle (France)",
  "Dompierre-sur-Yon (France)",
  "Donchery (France)",
  "Doncourt-les-Conflans (France)",
  "Donges (France)",
  "Donjeux (France)",
  "Donnemarie-Dontilly (France)",
  "Donnery (France)",
  "Donneville (France)",
  "Donnezac (France)",
  "Dontrien (France)",
  "Donville-les-Bains (France)",
  "Donzacq (France)",
  "Donzere (France)",
  "Donzy (France)",
  "Donzy-le-Pertuis (France)",
  "Dordives (France)",
  "Dorlisheim (France)",
  "Dormans (France)",
  "Dormelles (France)",
  "Dornes (France)",
  "Dornot (France)",
  "Dortan (France)",
  "Dorval (France)",
  "Douai (France)",
  "Douarnenez (France)",
  "Doubs (France)",
  "Douchy (France)",
  "Douchy-les-Mines (France)",
  "Doudeville (France)",
  "Doue-la-Fontaine (France)",
  "Douilly (France)",
  "Doulezon (France)",
  "Doullens (France)",
  "Doumy (France)",
  "Dounoux (France)",
  "Dourdain (France)",
  "Dourdan (France)",
  "Dourges (France)",
  "Dourgne (France)",
  "Doussard (France)",
  "Douvaine (France)",
  "Douville (France)",
  "Douvres (France)",
  "Douvres-la-Delivrande (France)",
  "Douvrin (France)",
  "Doux (France)",
  "Douy-la-Ramee (France)",
  "Douzy (France)",
  "Doville (France)",
  "Doyet (France)",
  "Dozule (France)",
  "Drace (France)",
  "Drachenbronn-Birlenbach (France)",
  "Dracy-le-Fort (France)",
  "Draguignan (France)",
  "Draillant (France)",
  "Drain (France)",
  "Drancy (France)",
  "Drap (France)",
  "Draveil (France)",
  "Dreffeac (France)",
  "Dremil-Lafage (France)",
  "Dreslincourt (France)",
  "Dreuil-les-Amiens (France)",
  "Dreux (France)",
  "Dreville (France)",
  "Drocourt (France)",
  "Drom (France)",
  "Droupt-Saint-Basle (France)",
  "Drouville (France)",
  "Druillat (France)",
  "Drulingen (France)",
  "Drusenheim (France)",
  "Druye (France)",
  "Druyes-les-Belles-Fontaines (France)",
  "Dry (France)",
  "Ducey (France)",
  "Duclair (France)",
  "Duerne (France)",
  "Dugny (France)",
  "Dugny-sur-Meuse (France)",
  "Duingt (France)",
  "Duisans (France)",
  "Dullin (France)",
  "Dun-le-Poelier (France)",
  "Dun-sur-Auron (France)",
  "Duneau (France)",
  "Dunes (France)",
  "Duniere (France)",
  "Dunkirk (France)",
  "Duppigheim (France)",
  "Durance (France)",
  "Duranus (France)",
  "Durban-Corbieres (France)",
  "Durcet (France)",
  "Durette (France)",
  "Durfort (France)",
  "Durlinsdorf (France)",
  "Durmenach (France)",
  "Durrenbach (France)",
  "Durrenentzen (France)",
  "Durstel (France)",
  "Durtal (France)",
  "Durtol (France)",
  "Dury (France)",
  "Duttlenheim (France)",
  "Eance (France)",
  "Eaubonne (France)",
  "Eaucourt-sur-Somme (France)",
  "Eaunes (France)",
  "Eaux-Bonnes (France)",
  "Eaux-Puiseaux (France)",
  "Eauze (France)",
  "Ebersviller (France)",
  "Eblange (France)",
  "Ecaillon (France)",
  "Ecalles-Alix (France)",
  "Ecaquelon (France)",
  "Ecardenville-la-Campagne (France)",
  "Ecardenville-sur-Eure (France)",
  "Ecault (France)",
  "Eccles (France)",
  "Echalas (France)",
  "Echarcon (France)",
  "Echauffour (France)",
  "Echavanne (France)",
  "Echemire (France)",
  "Echenans (France)",
  "Echenevex (France)",
  "Echenon (France)",
  "Echenoz-la-Meline (France)",
  "Echevannes (France)",
  "Echevis (France)",
  "Echillais (France)",
  "Echinghen (France)",
  "Echire (France)",
  "Eckbolsheim (France)",
  "Eckwersheim (France)",
  "Eclaron-Braucourt-Sainte-Liviere (France)",
  "Ecleux (France)",
  "Ecluzelles (France)",
  "Ecoche (France)",
  "Ecole (France)",
  "Ecole-Valentin (France)",
  "Ecommoy (France)",
  "Ecorcei (France)",
  "Ecos (France)",
  "Ecouen (France)",
  "Ecouflant (France)",
  "Ecourt-Saint-Quentin (France)",
  "Ecouviez (France)",
  "Ecoyeux (France)",
  "Ecques (France)",
  "Ecquevilly (France)",
  "Ecretteville-les-Baons (France)",
  "Ecrosnes (France)",
  "Ecrouves (France)",
  "Ectot-les-Baons (France)",
  "Ecueil (France)",
  "Ecueille (France)",
  "Ecuelles (France)",
  "Ecuille (France)",
  "Ecuires (France)",
  "Ecuisses (France)",
  "Ecully (France)",
  "Ecury-sur-Coole (France)",
  "Edern (France)",
  "Effiat (France)",
  "Effry (France)",
  "Egletons (France)",
  "Egligny (France)",
  "Egly (France)",
  "Egreville (France)",
  "Eguilles (France)",
  "Eichhoffen (France)",
  "Einville-au-Jard (France)",
  "el Volo (France)",
  "Elbeuf (France)",
  "Eletot (France)",
  "Eleu-dit-Leauwette (France)",
  "Elincourt (France)",
  "Elincourt-Sainte-Marguerite (France)",
  "Elliant (France)",
  "Ellon (France)",
  "Elna (France)",
  "Elnes (France)",
  "Eloise (France)",
  "Eloyes (France)",
  "els Banys de la Presta (France)",
  "Elven (France)",
  "Emance (France)",
  "Embrun (France)",
  "Embry (France)",
  "Emerainville (France)",
  "Emieville (France)",
  "Emmerin (France)",
  "Enchenberg (France)",
  "Enencourt-le-Sec (France)",
  "Enghien-les-Bains (France)",
  "Engins (France)",
  "Englefontaine (France)",
  "Englos (France)",
  "Ennery (France)",
  "Ennevelin (France)",
  "Ennezat (France)",
  "Ennordres (France)",
  "Enquin-les-Mines (France)",
  "Ensisheim (France)",
  "Ensues-la-Redonne (France)",
  "Entraigues-sur-la-Sorgue (France)",
  "Entrains-sur-Nohain (France)",
  "Entrammes (France)",
  "Entrange (France)",
  "Entraygues-sur-Truyere (France)",
  "Entre-deux-Guiers (France)",
  "Entrecasteaux (France)",
  "Entremont-le-Vieux (France)",
  "Entressen (France)",
  "Entzheim (France)",
  "Enveig (France)",
  "Envermeu (France)",
  "Epagne-Epagnette (France)",
  "Epagny (France)",
  "Epaignes (France)",
  "Epegard (France)",
  "Epenancourt (France)",
  "Epenede (France)",
  "Epercieux-Saint-Paul (France)",
  "Eperlecques (France)",
  "Epernon (France)",
  "Epertully (France)",
  "Epervans (France)",
  "Epfig (France)",
  "Epiais-Rhus (France)",
  "Epieds-en-Beauce (France)",
  "Epierre (France)",
  "Epinac (France)",
  "Epinay (France)",
  "Epinay-sous-Senart (France)",
  "Epineuil-le-Fleuriel (France)",
  "Epinouze (France)",
  "Epinoy (France)",
  "Episy (France)",
  "Epoisses (France)",
  "Epone (France)",
  "Epouville (France)",
  "Eppe-Sauvage (France)",
  "Eppeville (France)",
  "Epping (France)",
  "Epuisay (France)",
  "Equeurdreville-Hainneville (France)",
  "Equihen-Plage (France)",
  "Er (France)",
  "Eragny (France)",
  "Erbray (France)",
  "Erbree (France)",
  "Erce-en-Lamee (France)",
  "Erce-pres-Liffre (France)",
  "Erceville (France)",
  "Ercheu (France)",
  "Erdeven (France)",
  "Ergersheim (France)",
  "Ergny (France)",
  "Ergue-Gaberic (France)",
  "Erize-Saint-Dizier (France)",
  "Ermenonville (France)",
  "Ermont (France)",
  "Ernee (France)",
  "Ernemont-sur-Buchy (France)",
  "Ernestviller (France)",
  "Ernolsheim-Bruche (France)",
  "Erome (France)",
  "Eroudeville (France)",
  "Erquinghem-Lys (France)",
  "Erquinvillers (France)",
  "Erquy (France)",
  "Erre (France)",
  "Errouville (France)",
  "Erstein (France)",
  "Ervauville (France)",
  "Esbarres (France)",
  "Esbly (France)",
  "Escalquens (France)",
  "Escames (France)",
  "Escassefort (France)",
  "Escaudain (France)",
  "Escaudoeuvres (France)",
  "Escautpont (France)",
  "Escazeaux (France)",
  "Eschau (France)",
  "Eschbach-au-Val (France)",
  "Eschentzwiller (France)",
  "Esches (France)",
  "Esclainvillers (France)",
  "Escolives-Sainte-Camille (France)",
  "Escombres-et-le-Chesnois (France)",
  "Escondeaux (France)",
  "Escorneboeuf (France)",
  "Escou (France)",
  "Escout (France)",
  "Escoutoux (France)",
  "Escurolles (France)",
  "Esery (France)",
  "Eslettes (France)",
  "Esmery-Hallon (France)",
  "Esnandes (France)",
  "Esnouveaux (France)",
  "Espagnac (France)",
  "Espalais (France)",
  "Espalion (France)",
  "Espaly-Saint-Marcel (France)",
  "Esparron-de-Verdon (France)",
  "Espedaillac (France)",
  "Espelette (France)",
  "Espeluche (France)",
  "Espezel (France)",
  "Espiet (France)",
  "Espinasses (France)",
  "Espira-de-Conflent (France)",
  "Espirat (France)",
  "Espondeilhan (France)",
  "Esquay-Notre-Dame (France)",
  "Esquay-sur-Seulles (France)",
  "Esquelbecq (France)",
  "Esquerchin (France)",
  "Esquerdes (France)",
  "Esquibien (France)",
  "Esquieze-Sere (France)",
  "Essegney (France)",
  "Essert (France)",
  "Essertaux (France)",
  "Esserts-Saleve (France)",
  "Essey (France)",
  "Essey-les-Nancy (France)",
  "Essia (France)",
  "Essigny-le-Grand (France)",
  "Esson (France)",
  "Essoyes (France)",
  "Estadens (France)",
  "Estagel (France)",
  "Estaing (France)",
  "Estaires (France)",
  "Esternay (France)",
  "Estevelles (France)",
  "Estillac (France)",
  "Estivareilles (France)",
  "Estouches (France)",
  "Estouteville-Ecalles (France)",
  "Estouy (France)",
  "Estrablin (France)",
  "Estree-Blanche (France)",
  "Estrees (France)",
  "Estrees-les-Crecy (France)",
  "Estrees-Saint-Denis (France)",
  "Estrees-sur-Noye (France)",
  "Esvres (France)",
  "Etable (France)",
  "Etables (France)",
  "Etables-sur-Mer (France)",
  "Etaimpuis (France)",
  "Etaing (France)",
  "Etainhus (France)",
  "Etalondes (France)",
  "Etampes-sur-Marne (France)",
  "Etang-sur-Arroux (France)",
  "Etaules (France)",
  "Etauliers (France)",
  "Etaux (France)",
  "Etaves-et-Bocquiaux (France)",
  "Eteimbes (France)",
  "Etel (France)",
  "Etelfay (France)",
  "Etercy (France)",
  "Eternoz (France)",
  "Eterville (France)",
  "Etevaux (France)",
  "Eth (France)",
  "Etigny (France)",
  "Etiolles (France)",
  "Etival (France)",
  "Etival-Clairefontaine (France)",
  "Etival-les-le-Mans (France)",
  "Etoges (France)",
  "Etoile-sur-Rhone (France)",
  "Etourvy (France)",
  "Etouvans (France)",
  "Etouvy (France)",
  "Etrechy (France)",
  "Etreillers (France)",
  "Etrelles (France)",
  "Etrembieres (France)",
  "Etrepagny (France)",
  "Etrepilly (France)",
  "Etreux (France)",
  "Etrez (France)",
  "Etriac (France)",
  "Etriche (France)",
  "Etricourt-Manancourt (France)",
  "Etroeungt (France)",
  "Etroussat (France)",
  "Ettendorf (France)",
  "Etting (France)",
  "Etupes (France)",
  "Etuz (France)",
  "Etzling (France)",
  "Eu (France)",
  "Eulmont (France)",
  "Euville (France)",
  "Evans (France)",
  "Evenos (France)",
  "Everly (France)",
  "Eveux (France)",
  "Evin-Malmaison (France)",
  "Evran (France)",
  "Evrecy (France)",
  "Evron (France)",
  "Excenevex (France)",
  "Excideuil (France)",
  "Exideuil (France)",
  "Exincourt (France)",
  "Exireuil (France)",
  "Exoudun (France)",
  "Eybens (France)",
  "Eybouleuf (France)",
  "Eygalieres (France)",
  "Eyguieres (France)",
  "Eygurande-et-Gardedeuil (France)",
  "Eyjeaux (France)",
  "Eyliac (France)",
  "Eymet (France)",
  "Eymoutiers (France)",
  "Eyragues (France)",
  "Eyrans (France)",
  "Eysines (France)",
  "Eyzin-Pinet (France)",
  "Ezanville (France)",
  "Eze (France)",
  "Ezy-sur-Eure (France)",
  "Fabregues (France)",
  "Fabrezan (France)",
  "Faches (France)",
  "Faches-Thumesnil (France)",
  "Fagnieres (France)",
  "Faimbe (France)",
  "Fains-Veel (France)",
  "Falaise (France)",
  "Falck (France)",
  "Falicon (France)",
  "Fallerans (France)",
  "Falleron (France)",
  "Falletans (France)",
  "Famars (France)",
  "Fameck (France)",
  "Fampoux (France)",
  "Fanjeaux (France)",
  "Faramans (France)",
  "Farbus (France)",
  "Farebersviller (France)",
  "Fareins (France)",
  "Faremoutiers (France)",
  "Farges-les-Chalon (France)",
  "Fargues (France)",
  "Fargues-Saint-Hilaire (France)",
  "Farincourt (France)",
  "Fatouville-Grestain (France)",
  "Fauch (France)",
  "Faucigny (France)",
  "Faucogney-et-la-Mer (France)",
  "Faucompierre (France)",
  "Faulquemont (France)",
  "Faulx (France)",
  "Faumont (France)",
  "Fauquembergues (France)",
  "Fauville-en-Caux (France)",
  "Favars (France)",
  "Faverges (France)",
  "Faverges-de-la-Tour (France)",
  "Faverolles-la-Campagne (France)",
  "Faverolles-sur-Cher (France)",
  "Favieres (France)",
  "Fay-aux-Loges (France)",
  "Fay-de-Bretagne (France)",
  "Fay-les-Nemours (France)",
  "Fayence (France)",
  "Fayet-le-Chateau (France)",
  "Fayl-Billot (France)",
  "Fays-la-Chapelle (France)",
  "Fayssac (France)",
  "Feas (France)",
  "Fechain (France)",
  "Fegersheim (France)",
  "Fegreac (France)",
  "Feigeres (France)",
  "Feignies (France)",
  "Feillens (France)",
  "Fein (France)",
  "Feissons-sur-Isere (France)",
  "Feldkirch (France)",
  "Felines (France)",
  "Felix (France)",
  "Fellering (France)",
  "Felletin (France)",
  "Fenain (France)",
  "Fenay (France)",
  "Fendeille (France)",
  "Fenetrange (France)",
  "Feneu (France)",
  "Feneyrols (France)",
  "Fenioux (France)",
  "Fenouillet (France)",
  "Fere-Champenoise (France)",
  "Fere-en-Tardenois (France)",
  "Ferel (France)",
  "Ferfay (France)",
  "Fericy (France)",
  "Ferin (France)",
  "Fermanville (France)",
  "Ferney-Voltaire (France)",
  "Ferolles-Attilly (France)",
  "Ferques (France)",
  "Ferrals-les-Corbieres (France)",
  "Ferreol (France)",
  "Ferrette (France)",
  "Ferreux-Quincey (France)",
  "Ferriere-la-Grande (France)",
  "Ferriere-la-Petite (France)",
  "Ferriere-Larcon (France)",
  "Ferrieres (France)",
  "Ferrieres-en-Brie (France)",
  "Ferrieres-en-Gatinais (France)",
  "Ferrieres-Haut-Clocher (France)",
  "Ferrieres-les-Scey (France)",
  "Ferrieres-sur-Ariege (France)",
  "Fesches-le-Chatel (France)",
  "Fesques (France)",
  "Fessenheim (France)",
  "Festieux (France)",
  "Festubert (France)",
  "Feternes (France)",
  "Feucherolles (France)",
  "Feuges (France)",
  "Feuguerolles-sur-Seulles (France)",
  "Feuilla (France)",
  "Feuquieres (France)",
  "Feuquieres-en-Vimeu (France)",
  "Feurs (France)",
  "Feves (France)",
  "Fey (France)",
  "Feytiat (France)",
  "Feyzin (France)",
  "Fiefs (France)",
  "Fiennes (France)",
  "Fierville-Bray (France)",
  "Fierville-les-Mines (France)",
  "Figanieres (France)",
  "Figeac (France)",
  "Fignieres (France)",
  "Filain (France)",
  "Fille (France)",
  "Fillievres (France)",
  "Fillinges (France)",
  "Filstroff (France)",
  "Finhan (France)",
  "Firmi (France)",
  "Firminy (France)",
  "Fislis (France)",
  "Fismes (France)",
  "Fitilieu (France)",
  "Fitz-James (France)",
  "Flagnac (France)",
  "Flamanville (France)",
  "Flamarens (France)",
  "Flancourt-Catelon (France)",
  "Flangebouche (France)",
  "Flaucourt (France)",
  "Flaux (France)",
  "Flavacourt (France)",
  "Flavigny (France)",
  "Flavigny-sur-Moselle (France)",
  "Flavin (France)",
  "Flavy-le-Martel (France)",
  "Flaxlanden (France)",
  "Flayosc (France)",
  "Fleac (France)",
  "Flechin (France)",
  "Flee (France)",
  "Flers (France)",
  "Flers-en-Escrebieux (France)",
  "Flers-sur-Noye (France)",
  "Fletrange (France)",
  "Fletre (France)",
  "Fleurance (France)",
  "Fleurbaix (France)",
  "Fleure (France)",
  "Fleurey-sur-Ouche (France)",
  "Fleurie (France)",
  "Fleuriel (France)",
  "Fleury (France)",
  "Fleury-en-Biere (France)",
  "Fleury-les-Aubrais (France)",
  "Fleury-Merogis (France)",
  "Fleury-sur-Andelle (France)",
  "Fleury-sur-Orne (France)",
  "Fleville-devant-Nancy (France)",
  "Flevy (France)",
  "Flines-les-Mortagne (France)",
  "Flines-lez-Raches (France)",
  "Flins-sur-Seine (France)",
  "Flixecourt (France)",
  "Floing (France)",
  "Floirac (France)",
  "Florac (France)",
  "Florange (France)",
  "Florensac (France)",
  "Florent-en-Argonne (France)",
  "Florentin (France)",
  "Floyon (France)",
  "Flumet (France)",
  "Foameix-Ornel (France)",
  "Foecy (France)",
  "Foissiat (France)",
  "Foix (France)",
  "Folelli (France)",
  "Folembray (France)",
  "Folgensbourg (France)",
  "Follainville-Dennemont (France)",
  "Folles (France)",
  "Folleville (France)",
  "Folschviller (France)",
  "Fonbeauzard (France)",
  "Foncine-le-Haut (France)",
  "Fondettes (France)",
  "Fons (France)",
  "Fonsorbes (France)",
  "Font-Romeu-Odeillo-Via (France)",
  "Fontain (France)",
  "Fontaine (France)",
  "Fontaine-au-Bois (France)",
  "Fontaine-Bonneleau (France)",
  "Fontaine-en-Dormois (France)",
  "Fontaine-Etoupefour (France)",
  "Fontaine-Guerin (France)",
  "Fontaine-Heudebourg (France)",
  "Fontaine-la-Mallet (France)",
  "Fontaine-le-Comte (France)",
  "Fontaine-le-Dun (France)",
  "Fontaine-le-Pin (France)",
  "Fontaine-les-Bassets (France)",
  "Fontaine-les-Dijon (France)",
  "Fontaine-les-Gres (France)",
  "Fontaine-les-Luxeuil (France)",
  "Fontaine-Milon (France)",
  "Fontaine-Notre-Dame (France)",
  "Fontaine-Simon (France)",
  "Fontaine-sous-Jouy (France)",
  "Fontaine-sous-Preaux (France)",
  "Fontaine-sur-Somme (France)",
  "Fontaine-Uterte (France)",
  "Fontainebleau (France)",
  "Fontaines (France)",
  "Fontaines-Saint-Martin (France)",
  "Fontaines-sur-Saone (France)",
  "Fontanes (France)",
  "Fontanil-Cornillon (France)",
  "Fontannes (France)",
  "Fontcouverte (France)",
  "Fontenai-les-Louvets (France)",
  "Fontenay (France)",
  "Fontenay-aux-Roses (France)",
  "Fontenay-en-Parisis (France)",
  "Fontenay-le-Comte (France)",
  "Fontenay-le-Fleury (France)",
  "Fontenay-le-Vicomte (France)",
  "Fontenay-sous-Bois (France)",
  "Fontenay-sur-Loing (France)",
  "Fontenay-sur-Vegre (France)",
  "Fontenay-Tresigny (France)",
  "Fontenelle-en-Brie (France)",
  "Fontenilles (France)",
  "Fontenoy (France)",
  "Fontenoy-la-Joute (France)",
  "Fontenoy-le-Chateau (France)",
  "Fontes (France)",
  "Fontjoncouse (France)",
  "Fontpedrosa (France)",
  "Fontrabiouse (France)",
  "Fontvannes (France)",
  "Fontvieille (France)",
  "Forbach (France)",
  "Forcalqueiret (France)",
  "Forcalquier (France)",
  "Force (France)",
  "Forest-sur-Marque (France)",
  "Foret-la-Folie (France)",
  "Forfry (France)",
  "Forges (France)",
  "Forges-les-Bains (France)",
  "Forges-les-Eaux (France)",
  "Forleans (France)",
  "Formerie (France)",
  "Fors (France)",
  "Fort-Mahon-Plage (France)",
  "Fort-Mardyck (France)",
  "Fortschwihr (France)",
  "Fos-sur-Mer (France)",
  "Fosse (France)",
  "Fossemanant (France)",
  "Fosses (France)",
  "Fosses-et-Baleyssac (France)",
  "Fouchy (France)",
  "Fouday (France)",
  "Fouencamps (France)",
  "Fouesnant (France)",
  "Foug (France)",
  "Fougax-et-Barrineuf (France)",
  "Fougere (France)",
  "Fougeres (France)",
  "Fougeres-sur-Bievre (France)",
  "Fougerolles (France)",
  "Fouillard (France)",
  "Fouilleuse (France)",
  "Foulangues (France)",
  "Foulayronnes (France)",
  "Foulcrey (France)",
  "Fouquebrune (France)",
  "Fouquieres-les-Lens (France)",
  "Four (France)",
  "Fouras (France)",
  "Fourchambault (France)",
  "Fourches (France)",
  "Fourdrinoy (France)",
  "Fourg (France)",
  "Fourmies (France)",
  "Fournes-en-Weppes (France)",
  "Fournier (France)",
  "Fournival (France)",
  "Fournols (France)",
  "Fouronnes (France)",
  "Fourques (France)",
  "Fourques-sur-Garonne (France)",
  "Fourqueux (France)",
  "Fours-Saint-Laurent (France)",
  "Foussemagne (France)",
  "Fragnes (France)",
  "Fraisans (France)",
  "Fraisses (France)",
  "Fraize (France)",
  "Fralignes (France)",
  "Frambouhans (France)",
  "Framerville-Rainecourt (France)",
  "Francaltroff (France)",
  "Francardo (France)",
  "Francheleins (France)",
  "Francheville (France)",
  "Francieres (France)",
  "Franclens (France)",
  "Francois (France)",
  "Franconville (France)",
  "Francourville (France)",
  "Frangy (France)",
  "Franois (France)",
  "Franqueville (France)",
  "Franvillers (France)",
  "Franxault (France)",
  "Frauenberg (France)",
  "Frebuans (France)",
  "Frechede (France)",
  "Frechencourt (France)",
  "Frechou (France)",
  "Fredille (France)",
  "Fregimont (France)",
  "Fregouville (France)",
  "Freistroff (France)",
  "Frejairolles (France)",
  "Freland (France)",
  "Frelinghien (France)",
  "Fremainville (France)",
  "Fremifontaine (France)",
  "Fremonville (France)",
  "Frencq (France)",
  "Frenes (France)",
  "Freneuse (France)",
  "Frepillon (France)",
  "Fresnay-en-Retz (France)",
  "Fresnay-le-Gilmert (France)",
  "Fresnay-sur-Sarthe (France)",
  "Fresne (France)",
  "Fresne-Cauverville (France)",
  "Fresne-la-Mere (France)",
  "Fresne-Saint-Mames (France)",
  "Fresneaux-Montchevreuil (France)",
  "Fresnes (France)",
  "Fresnes-au-Mont (France)",
  "Fresnes-en-Woevre (France)",
  "Fresnes-les-Montauban (France)",
  "Fresnes-sur-Escaut (France)",
  "Fresnes-sur-Marne (France)",
  "Fresnicourt-le-Dolmen (France)",
  "Fresnoy-la-Riviere (France)",
  "Fresnoy-le-Grand (France)",
  "Frespech (France)",
  "Fressac (France)",
  "Fressain (France)",
  "Fresse (France)",
  "Fresse-sur-Moselle (France)",
  "Fressenneville (France)",
  "Fressies (France)",
  "Fressines (France)",
  "Frethun (France)",
  "Fretigney-et-Velloreille (France)",
  "Fretin (France)",
  "Frevent (France)",
  "Frevillers (France)",
  "Frevin-Capelle (France)",
  "Friauville (France)",
  "Frieres-Faillouel (France)",
  "Friesen (France)",
  "Friville-Escarbotin (France)",
  "Frocourt (France)",
  "Froges (France)",
  "Froidestrees (France)",
  "Froidfond (France)",
  "Froissy (France)",
  "Fromelles (France)",
  "Fromont (France)",
  "Fromonville (France)",
  "Froncles (France)",
  "Fronsac (France)",
  "Frontenac (France)",
  "Frontenas (France)",
  "Frontenay-Rohan-Rohan (France)",
  "Frontenay-sur-Dive (France)",
  "Frontenex (France)",
  "Frontignan (France)",
  "Frontignan-Saves (France)",
  "Fronton (France)",
  "Frontonas (France)",
  "Frossay (France)",
  "Frouard (France)",
  "Frouzins (France)",
  "Frozes (France)",
  "Frucourt (France)",
  "Frugeres-les-Mines (France)",
  "Fruges (France)",
  "Fuisse (France)",
  "Fultot (France)",
  "Fumay (France)",
  "Fumel (France)",
  "Furchhausen (France)",
  "Furiani (France)",
  "Fussy (France)",
  "Fuveau (France)",
  "Fye (France)",
  "Gaas (France)",
  "Gabaston (France)",
  "Gabat (France)",
  "Gace (France)",
  "Gagnac-sur-Garonne (France)",
  "Gagnieres (France)",
  "Gagny (France)",
  "Gahard (France)",
  "Gailhan (France)",
  "Gaillac (France)",
  "Gaillac-Toulza (France)",
  "Gaillan-en-Medoc (France)",
  "Gaillard (France)",
  "Gaillefontaine (France)",
  "Gaillon (France)",
  "Galametz (France)",
  "Galapian (France)",
  "Galargues (France)",
  "Galfingue (France)",
  "Galgon (France)",
  "Galie (France)",
  "Gallardon (France)",
  "Gallician (France)",
  "Galluis (France)",
  "Gamaches (France)",
  "Gamarde-les-Bains (France)",
  "Gambais (France)",
  "Gambsheim (France)",
  "Gan (France)",
  "Ganac (France)",
  "Gandrange (France)",
  "Ganges (France)",
  "Gannat (France)",
  "Gap (France)",
  "Garancieres-en-Drouais (France)",
  "Garanou (France)",
  "Garat (France)",
  "Garche (France)",
  "Garches (France)",
  "Garchizy (France)",
  "Gardanne (France)",
  "Gardonne (France)",
  "Garencieres (France)",
  "Garennes-sur-Eure (France)",
  "Gareoult (France)",
  "Gargenville (France)",
  "Garlan (France)",
  "Garlin (France)",
  "Garons (France)",
  "Garrevaques (France)",
  "Garrigues (France)",
  "Garris (France)",
  "Garrosse (France)",
  "Gas (France)",
  "Gasny (France)",
  "Gassin (France)",
  "Gasville-Oiseme (France)",
  "Gatelles (France)",
  "Gatey (France)",
  "Gattieres (France)",
  "Gauchy (France)",
  "Gauciel (France)",
  "Gauriac (France)",
  "Gauriaguet (France)",
  "Gauville-la-Campagne (France)",
  "Gavarnie (France)",
  "Gavray (France)",
  "Gaye (France)",
  "Geanges (France)",
  "Geaune (France)",
  "Geay (France)",
  "Gee (France)",
  "Geispolsheim (France)",
  "Gelannes (France)",
  "Gelles (France)",
  "Gelos (France)",
  "Geloux (France)",
  "Gemeaux (France)",
  "Gemenos (France)",
  "Gemil (France)",
  "Gemozac (France)",
  "Genac (France)",
  "Genas (France)",
  "Genay (France)",
  "Gencay (France)",
  "Gendrey (France)",
  "Gene (France)",
  "Genebrieres (France)",
  "Genech (France)",
  "Genelard (France)",
  "Generac (France)",
  "Generargues (France)",
  "Genet (France)",
  "Geneuille (France)",
  "Geney (France)",
  "Genille (France)",
  "Genis (France)",
  "Genissac (France)",
  "Genissiat (France)",
  "Genissieux (France)",
  "Genlis (France)",
  "Gennes (France)",
  "Gennes-sur-Seiche (France)",
  "Gennetines (France)",
  "Gennevilliers (France)",
  "Gensac (France)",
  "Gensac-la-Pallue (France)",
  "Gente (France)",
  "Gentilly (France)",
  "Georges (France)",
  "Geovreisset (France)",
  "Ger (France)",
  "Gerard (France)",
  "Gerbaix (France)",
  "Gerbecourt-et-Haplemont (France)",
  "Gerbeviller (France)",
  "Gergy (France)",
  "Gerland (France)",
  "Germaine (France)",
  "Germainville (France)",
  "Germenay (France)",
  "Germigny-sur-Loire (France)",
  "Germond-Rouvre (France)",
  "Gerstheim (France)",
  "Gervais (France)",
  "Gervans (France)",
  "Gerzat (France)",
  "Gesnes-le-Gandelin (France)",
  "Geste (France)",
  "Gestel (France)",
  "Gesvres (France)",
  "Getigne (France)",
  "Geudertheim (France)",
  "Geveze (France)",
  "Gevrey-Chambertin (France)",
  "Gevrieux (France)",
  "Gex (France)",
  "Geyssans (France)",
  "Gezaincourt (France)",
  "Gezoncourt (France)",
  "Ghisonaccia (France)",
  "Ghyvelde (France)",
  "Giat (France)",
  "Giberville (France)",
  "Gidy (France)",
  "Gien (France)",
  "Gieres (France)",
  "Gievres (France)",
  "Gif-sur-Yvette (France)",
  "Gigean (France)",
  "Gignac (France)",
  "Gignac-la-Nerthe (France)",
  "Gigny-sur-Saone (France)",
  "Gilette (France)",
  "Gilles (France)",
  "Gilley (France)",
  "Gilly-les-Citeaux (France)",
  "Gilly-sur-Isere (France)",
  "Gimbrett (France)",
  "Gimeux (France)",
  "Gimont (France)",
  "Ginasservis (France)",
  "Ginestas (France)",
  "Ginestet (France)",
  "Girancourt (France)",
  "Giraumont (France)",
  "Girolles (France)",
  "Giromagny (France)",
  "Giron (France)",
  "Gironcourt-sur-Vraine (France)",
  "Gironde-sur-Dropt (France)",
  "Giroussens (France)",
  "Gisors (France)",
  "Givenchy-en-Gohelle (France)",
  "Givet (France)",
  "Givors (France)",
  "Givraines (France)",
  "Givrand (France)",
  "Givrauval (France)",
  "Givron (France)",
  "Givry (France)",
  "Glanon (France)",
  "Glatigny (France)",
  "Gleize (France)",
  "Glenic (France)",
  "Glomel (France)",
  "Glos-sur-Risle (France)",
  "Goderville (France)",
  "Godewaersvelde (France)",
  "Goersdorf (France)",
  "Goes (France)",
  "Goetzenbruck (France)",
  "Goeulzin (France)",
  "Goin (France)",
  "Goincourt (France)",
  "Golbey (France)",
  "Golfe-Juan (France)",
  "Gombergean (France)",
  "Gomelange (France)",
  "Gomene (France)",
  "Gomer (France)",
  "Gometz-la-Ville (France)",
  "Gometz-le-Chatel (France)",
  "Gommegnies (France)",
  "Gommerville (France)",
  "Goncelin (France)",
  "Gondecourt (France)",
  "Gondeville (France)",
  "Gondrecourt-le-Chateau (France)",
  "Gondreville (France)",
  "Gonesse (France)",
  "Gonfaron (France)",
  "Gonfreville-Caillot (France)",
  "Gonfreville-lOrcher (France)",
  "Gonnehem (France)",
  "Gonneville-la-Mallet (France)",
  "Gonneville-sur-Mer (France)",
  "Gontier (France)",
  "Gorbio (France)",
  "Gorcy (France)",
  "Gordes (France)",
  "Gorges (France)",
  "Gornac (France)",
  "Gorre (France)",
  "Gorron (France)",
  "Gorze (France)",
  "Gosnay (France)",
  "Gosselming (France)",
  "Gottenhouse (France)",
  "Gouaix (France)",
  "Gouaux (France)",
  "Goudelin (France)",
  "Goudourville (France)",
  "Gouesnach (France)",
  "Gouesnou (France)",
  "Gouezec (France)",
  "Gouillons (France)",
  "Gouise (France)",
  "Goulet (France)",
  "Goult (France)",
  "Goupillieres (France)",
  "Gourdan-Polignan (France)",
  "Gourdon (France)",
  "Gourge (France)",
  "Gourhel (France)",
  "Gourin (France)",
  "Gourlizon (France)",
  "Gournay-en-Bray (France)",
  "Gournay-sur-Marne (France)",
  "Gourvillette (France)",
  "Goury (France)",
  "Goussainville (France)",
  "Gousse (France)",
  "Goutrens (France)",
  "Gouts (France)",
  "Gouttieres (France)",
  "Gouvernes (France)",
  "Gouves (France)",
  "Gouvieux (France)",
  "Gouville-sur-Mer (France)",
  "Goux-les-Dambelin (France)",
  "Goux-les-Usiers (France)",
  "Gouy-en-Artois (France)",
  "Gouy-en-Ternois (France)",
  "Gouy-Saint-Andre (France)",
  "Gouy-sous-Bellonne (France)",
  "Gouze (France)",
  "Gouzeaucourt (France)",
  "Goven (France)",
  "Goxwiller (France)",
  "Goyrans (France)",
  "Grabels (France)",
  "Gracay (France)",
  "Graces (France)",
  "Gradignan (France)",
  "Gragnague (France)",
  "Graincourt-les-Havrincourt (France)",
  "Grainville (France)",
  "Grainville-la-Teinturiere (France)",
  "Grainville-sur-Odon (France)",
  "Graissessac (France)",
  "Gramat (France)",
  "Grammond (France)",
  "Grand (France)",
  "Grand Champ (France)",
  "Grand-Auverne (France)",
  "Grand-Charmont (France)",
  "Grand-Fort-Philippe (France)",
  "Grand-Fougeray (France)",
  "Grand-Laviers (France)",
  "Grand-Rozoy (France)",
  "Grandchamp (France)",
  "Grandchamps-des-Fontaines (France)",
  "Grande-Synthe (France)",
  "Grandfontaine (France)",
  "Grandfontaine-Fournets (France)",
  "Grandfresnoy (France)",
  "Grandpre (France)",
  "Grandris (France)",
  "Grandsaigne (France)",
  "Grandvaux (France)",
  "Grandvillars (France)",
  "Grandville-Gaudreville (France)",
  "Grandvillers (France)",
  "Grandvilliers (France)",
  "Grane (France)",
  "Granges (France)",
  "Granges-le-Bourg (France)",
  "Granges-sur-Vologne (France)",
  "Grangues (France)",
  "Grans (France)",
  "Granville (France)",
  "Grasse (France)",
  "Grassendorf (France)",
  "Gratentour (France)",
  "Gratot (France)",
  "Graulhet (France)",
  "Grauves (France)",
  "Gravelines (France)",
  "Graveron-Semerville (France)",
  "Graveson (France)",
  "Gravigny (France)",
  "Gray (France)",
  "Grazac (France)",
  "Grazay (France)",
  "Greasque (France)",
  "Gremecey (France)",
  "Gremevillers (France)",
  "Gremonville (France)",
  "Grenade (France)",
  "Grenay (France)",
  "Greneville-en-Beauce (France)",
  "Grenoble (France)",
  "Grentheville (France)",
  "Grentzingen (France)",
  "Greolieres (France)",
  "Greoux-les-Bains (France)",
  "Grepiac (France)",
  "Gresse-en-Vercors (France)",
  "Gresswiller (France)",
  "Gressy (France)",
  "Gresy-sur-Aix (France)",
  "Gresy-sur-Isere (France)",
  "Gretz-Armainvilliers (France)",
  "Grez-en-Bouere (France)",
  "Grez-sur-Loing (France)",
  "Grezieu-la-Varenne (France)",
  "Grezillac (France)",
  "Grezolles (France)",
  "Gries (France)",
  "Griesbach-au-Val (France)",
  "Griesheim-pres-Molsheim (France)",
  "Grignan (France)",
  "Grignols (France)",
  "Grigny (France)",
  "Grillon (France)",
  "Grilly (France)",
  "Grimaud (France)",
  "Gripport (France)",
  "Griselles (France)",
  "Grisolles (France)",
  "Grisy-Suisnes (France)",
  "Grisy-sur-Seine (France)",
  "Grivillers (France)",
  "Groffliers (France)",
  "Groissiat (France)",
  "Groisy (France)",
  "Groix (France)",
  "Gron (France)",
  "Gros-Rederching (France)",
  "Grosbliederstroff (France)",
  "Grosbreuil (France)",
  "Groslay (France)",
  "Grosrouvre (France)",
  "Grosseto-Prugna (France)",
  "Grossoeuvre (France)",
  "Grozon (France)",
  "Gruchet-le-Valasse (France)",
  "Gruey-les-Surance (France)",
  "Gruffy (France)",
  "Grugies (France)",
  "Gruissan (France)",
  "Grun-Bordas (France)",
  "Grundviller (France)",
  "Gruson (France)",
  "Grussenheim (France)",
  "Guebwiller (France)",
  "Guecelard (France)",
  "Guegon (France)",
  "Guemar (France)",
  "Guemene-Penfao (France)",
  "Guemene-sur-Scorff (France)",
  "Guenange (France)",
  "Guengat (France)",
  "Guenin (France)",
  "Guenrouet (France)",
  "Guer (France)",
  "Guerande (France)",
  "Guerard (France)",
  "Guercheville (France)",
  "Gueret (France)",
  "Guerigny (France)",
  "Guerlesquin (France)",
  "Guernes (France)",
  "Guerting (France)",
  "Guerville (France)",
  "Guesnain (France)",
  "Guessling-Hemering (France)",
  "Guethary (France)",
  "Gueugnon (France)",
  "Gueures (France)",
  "Gueux (France)",
  "Guewenheim (France)",
  "Guiche (France)",
  "Guichen (France)",
  "Guiclan (France)",
  "Guidel (France)",
  "Guignen (France)",
  "Guignes (France)",
  "Guigneville (France)",
  "Guignicourt (France)",
  "Guignicourt-sur-Vence (France)",
  "Guiler-sur-Goyen (France)",
  "Guilers (France)",
  "Guilherand-Granges (France)",
  "Guillestre (France)",
  "Guilliers (France)",
  "Guilly (France)",
  "Guilvinec (France)",
  "Guines (France)",
  "Guingamp (France)",
  "Guipavas (France)",
  "Guipel (France)",
  "Guipronvel (France)",
  "Guipry (France)",
  "Guiscard (France)",
  "Guise (France)",
  "Guiseniers (France)",
  "Guitalens (France)",
  "Guivry (France)",
  "Gujan-Mestras (France)",
  "Gumbrechtshoffen (France)",
  "Gundershoffen (France)",
  "Gundolsheim (France)",
  "Guntzviller (France)",
  "Guny (France)",
  "Gurs (France)",
  "Guyancourt (France)",
  "Gy-en-Sologne (France)",
  "Habas (France)",
  "Habere-Lullin (France)",
  "Habsheim (France)",
  "Hadol (France)",
  "Hagenbach (France)",
  "Hagenthal-le-Haut (France)",
  "Hagetmau (France)",
  "Hageville (France)",
  "Hagondange (France)",
  "Haguenau (France)",
  "Haillicourt (France)",
  "Haironville (France)",
  "Haisnes (France)",
  "Hallencourt (France)",
  "Hallennes-lez-Haubourdin (France)",
  "Hallering (France)",
  "Hallines (France)",
  "Halloy-les-Pernois (France)",
  "Halluin (France)",
  "Ham (France)",
  "Ham-en-Artois (France)",
  "Ham-sous-Varsberg (France)",
  "Ham-sur-Meuse (France)",
  "Hamars (France)",
  "Hambach (France)",
  "Hambers (France)",
  "Hamel (France)",
  "Hammeville (France)",
  "Han-sur-Nied (France)",
  "Hanches (France)",
  "Hangenbieten (France)",
  "Hangest-en-Santerre (France)",
  "Hannogne-Saint-Martin (France)",
  "Hantay (France)",
  "Hanvec (France)",
  "Happonvilliers (France)",
  "Haramont (France)",
  "Haraucourt (France)",
  "Harbonnieres (France)",
  "Harcanville (France)",
  "Harcigny (France)",
  "Harcourt (France)",
  "Hardelot-Plage (France)",
  "Hardencourt (France)",
  "Hardifort (France)",
  "Hardinvast (France)",
  "Harfleur (France)",
  "Hargicourt (France)",
  "Hargnies (France)",
  "Harnes (France)",
  "Haroue (France)",
  "Harprich (France)",
  "Harreberg (France)",
  "Harreville-les-Chanteurs (France)",
  "Harskirchen (France)",
  "Hartmannswiller (France)",
  "Hary (France)",
  "Hasnon (France)",
  "Hasparren (France)",
  "Haspres (France)",
  "Hastingues (France)",
  "Hatten (France)",
  "Hattenville (France)",
  "Hattstatt (France)",
  "Haubourdin (France)",
  "Hauconcourt (France)",
  "Haucourt-Moulaine (France)",
  "Haudiomont (France)",
  "Haudivillers (France)",
  "Haulchin (France)",
  "Haussignemont (France)",
  "Haussimont (France)",
  "Haussy (France)",
  "Haut-Clocher (France)",
  "Haute-Goulaine (France)",
  "Haute-Isle (France)",
  "Haute-Rivoire (France)",
  "Haute-Vigneulles (France)",
  "Hautecloque (France)",
  "Hautefage-la-Tour (France)",
  "Hautefort (France)",
  "Hauteluce (France)",
  "Hauterive (France)",
  "Hauterives (France)",
  "Hauteville-les-Dijon (France)",
  "Hauteville-Lompnes (France)",
  "Hauteville-sur-Mer (France)",
  "Hautmont (France)",
  "Hautot-sur-Mer (France)",
  "Hautvillers (France)",
  "Hautvillers-Ouville (France)",
  "Haveluy (France)",
  "Haverskerque (France)",
  "Havrincourt (France)",
  "Hayange (France)",
  "Haybes (France)",
  "Hazebrouck (France)",
  "Hebecourt (France)",
  "Hebecrevon (France)",
  "Hebuterne (France)",
  "Hede-Bazouges (France)",
  "Hegenheim (France)",
  "Heidwiller (France)",
  "Heiligenberg (France)",
  "Heillecourt (France)",
  "Heilly (France)",
  "Heimersdorf (France)",
  "Heimsbrunn (France)",
  "Heiteren (France)",
  "Helesmes (France)",
  "Helette (France)",
  "Helfaut (France)",
  "Hellemmes-Lille (France)",
  "Hellimer (France)",
  "Hem (France)",
  "Hemevillers (France)",
  "Heming (France)",
  "Hemonstoir (France)",
  "Henansal (France)",
  "Hendaye (France)",
  "Henin-Beaumont (France)",
  "Henin-sur-Cojeul (France)",
  "Hennebont (France)",
  "Henon (France)",
  "Henonville (France)",
  "Henouville (France)",
  "Henrichemont (France)",
  "Henridorff (France)",
  "Henvic (France)",
  "Herbeys (France)",
  "Herbignac (France)",
  "Herblay (France)",
  "Herchies (France)",
  "Herepian (France)",
  "Heres (France)",
  "Hergnies (France)",
  "Heric (France)",
  "Hericourt (France)",
  "Hericy (France)",
  "Herimoncourt (France)",
  "Herin (France)",
  "Herisson (France)",
  "Herlies (France)",
  "Hermanville-sur-Mer (France)",
  "Hermelange (France)",
  "Hermeray (France)",
  "Hermes (France)",
  "Hermies (France)",
  "Hermillon (France)",
  "Herny (France)",
  "Herouvillette (France)",
  "Herrlisheim-pres-Colmar (France)",
  "Herserange (France)",
  "Hersin-Coupigny (France)",
  "Hery-sur-Alby (France)",
  "Herzeele (France)",
  "Hesdin (France)",
  "Hesingue (France)",
  "Hestrus (France)",
  "Hettange-Grande (France)",
  "Heuchin (France)",
  "Heudebouville (France)",
  "Heudicourt (France)",
  "Heudicourt-sous-les-Cotes (France)",
  "Heudreville-sur-Eure (France)",
  "Heugas (France)",
  "Heuqueville (France)",
  "Heurtevent (France)",
  "Heutregiville (France)",
  "Heyrieux (France)",
  "Higueres-Souye (France)",
  "Hilbesheim (France)",
  "Hillion (France)",
  "Hindlingen (France)",
  "Hinges (France)",
  "Hipsheim (France)",
  "Hirel (France)",
  "Hirsingue (France)",
  "Hirson (France)",
  "Hochfelden (France)",
  "Hochstatt (France)",
  "Hocquigny (France)",
  "Hodenc-en-Bray (France)",
  "Hodeng-Hodenger (France)",
  "Hoenheim (France)",
  "Hoerdt (France)",
  "Hohfrankenheim (France)",
  "Hohwiller (France)",
  "Holnon (France)",
  "Holtzheim (France)",
  "Holtzwihr (France)",
  "Holving (France)",
  "Hombleux (France)",
  "Homblieres (France)",
  "Hombourg (France)",
  "Hombourg-Budange (France)",
  "Hombourg-Haut (France)",
  "Homecourt (France)",
  "Homps (France)",
  "Hon-Hergies (France)",
  "Hondeghem (France)",
  "Hondouville (France)",
  "Hondschoote (France)",
  "Honfleur (France)",
  "Honnecourt-sur-Escaut (France)",
  "Hopital-Camfrout (France)",
  "Horbourg-Wihr (France)",
  "Hordain (France)",
  "Horgues (France)",
  "Hornaing (France)",
  "Hossegor (France)",
  "Houchin (France)",
  "Houdain-lez-Bavay (France)",
  "Houdan (France)",
  "Houdelmont (France)",
  "Houdemont (France)",
  "Houeilles (France)",
  "Houilles (France)",
  "Houlbec-Cocherel (France)",
  "Houlette (France)",
  "Houlgate (France)",
  "Houlle (France)",
  "Houplin-Ancoisne (France)",
  "Houplines (France)",
  "Houppeville (France)",
  "Hourtin (France)",
  "Houry (France)",
  "Houssen (France)",
  "Housseville (France)",
  "Houville-la-Branche (France)",
  "Houx (France)",
  "Hoymille (France)",
  "Hubersent (France)",
  "Huberville (France)",
  "Huchenneville (France)",
  "Hucqueliers (France)",
  "Hudimesnil (France)",
  "Huelgoat (France)",
  "Huest (France)",
  "Huez (France)",
  "Hugier (France)",
  "Huiron (France)",
  "Huisseau-sur-Cosson (France)",
  "Huisseau-sur-Mauves (France)",
  "Hulluch (France)",
  "Hultehouse (France)",
  "Hundling (France)",
  "Hundsbach (France)",
  "Huningue (France)",
  "Hunspach (France)",
  "Huppy (France)",
  "Hure (France)",
  "Huriel (France)",
  "Hurigny (France)",
  "Husseren-les-Chateaux (France)",
  "Husseren-Wesserling (France)",
  "Hussigny-Godbrange (France)",
  "Huttenheim (France)",
  "Hyemondans (France)",
  "Idrac-Respailles (France)",
  "Idron (France)",
  "Iffendic (France)",
  "Ifs (France)",
  "Igney (France)",
  "Igny (France)",
  "Igon (France)",
  "Igoville (France)",
  "Illange (France)",
  "Ille-sur-Tet (France)",
  "Illfurth (France)",
  "Illhaeusern (France)",
  "Illiat (France)",
  "Illiers-Combray (France)",
  "Illifaut (France)",
  "Illkirch-Graffenstaden (France)",
  "Illois (France)",
  "Illzach (France)",
  "Imling (France)",
  "Imphy (France)",
  "Incheville (France)",
  "Inchy (France)",
  "Indre (France)",
  "Ingersheim (France)",
  "Ingolsheim (France)",
  "Ingrandes (France)",
  "Ingre (France)",
  "Inguiniel (France)",
  "Ingwiller (France)",
  "Innenheim (France)",
  "Inxent (France)",
  "Ippling (France)",
  "Irai (France)",
  "Irancy (France)",
  "Irigny (France)",
  "Irissarry (France)",
  "Irodouer (France)",
  "Irouleguy (France)",
  "Irreville (France)",
  "Is-sur-Tille (France)",
  "Isbergues (France)",
  "Isches (France)",
  "Isigny-le-Buat (France)",
  "Isigny-sur-Mer (France)",
  "Isle (France)",
  "Isle-et-Bardais (France)",
  "Isles-les-Villenoy (France)",
  "Isneauville (France)",
  "Isola (France)",
  "Ispoure (France)",
  "Isques (France)",
  "Issancourt-et-Rumel (France)",
  "Issenhausen (France)",
  "Issenheim (France)",
  "Issoire (France)",
  "Issou (France)",
  "Issoudun (France)",
  "Issus (France)",
  "Issy-les-Moulineaux (France)",
  "Istres (France)",
  "Itancourt (France)",
  "Iteuil (France)",
  "Ittenheim (France)",
  "Itteville (France)",
  "Itxassou (France)",
  "Iville (France)",
  "Ivoy-le-Pre (France)",
  "Ivry-la-Bataille (France)",
  "Ivry-le-Temple (France)",
  "Ivry-sur-Seine (France)",
  "Iwuy (France)",
  "Izeaux (France)",
  "Izernore (France)",
  "Izeron (France)",
  "Izier (France)",
  "Izon (France)",
  "Izotges (France)",
  "Jabreilles-les-Bordes (France)",
  "Jacob-Bellecombette (France)",
  "Jacou (France)",
  "Jainvillotte (France)",
  "Jallais (France)",
  "Jallans (France)",
  "Jallerange (France)",
  "Jalognes (France)",
  "Jalogny (France)",
  "Jalons (France)",
  "Jambles (France)",
  "Jametz (France)",
  "Jandun (France)",
  "Janneyrias (France)",
  "Janville (France)",
  "Janville-sur-Juine (France)",
  "Janvilliers (France)",
  "Janvry (France)",
  "Janze (France)",
  "Jarcieu (France)",
  "Jard-sur-Mer (France)",
  "Jardin (France)",
  "Jardres (France)",
  "Jargeau (France)",
  "Jarmenil (France)",
  "Jarnac-Champagne (France)",
  "Jarnages (France)",
  "Jarny (France)",
  "Jarrie (France)",
  "Jarrier (France)",
  "Jars (France)",
  "Jarville-la-Malgrange (France)",
  "Jarze (France)",
  "Jassans-Riottier (France)",
  "Jasseron (France)",
  "Jaucourt (France)",
  "Jaujac (France)",
  "Jaunay-Clan (France)",
  "Jaure (France)",
  "Jausiers (France)",
  "Jaux (France)",
  "Javene (France)",
  "Javrezac (France)",
  "Jayat (France)",
  "Jazeneuil (France)",
  "Jeandelaincourt (France)",
  "Jeanmenil (France)",
  "Jebsheim (France)",
  "Jegun (France)",
  "Jenlain (France)",
  "Jesonville (France)",
  "Jettingen (France)",
  "Jeu-les-Bois (France)",
  "Jeufosse (France)",
  "Jeumont (France)",
  "Jeune (France)",
  "Jeurre (France)",
  "Jeux-les-Bard (France)",
  "Jeuxey (France)",
  "Jezainville (France)",
  "Job (France)",
  "Jobourg (France)",
  "Joch (France)",
  "Joeuf (France)",
  "Joigny (France)",
  "Joigny-sur-Meuse (France)",
  "Joinville (France)",
  "Joinville-le-Pont (France)",
  "Jolimetz (France)",
  "Jolivet (France)",
  "Jonage (France)",
  "Jonchery-sur-Vesle (France)",
  "Joncourt (France)",
  "Jonquerets-de-Livet (France)",
  "Jonquerettes (France)",
  "Jonquieres (France)",
  "Jons (France)",
  "Jonvelle (France)",
  "Jonvilliers (France)",
  "Jonzac (France)",
  "Jonzier-Epagny (France)",
  "Jonzieux (France)",
  "Josnes (France)",
  "Josse (France)",
  "Josselin (France)",
  "Jossigny (France)",
  "Jouarre (France)",
  "Jouars-Pontchartrain (France)",
  "Jouaville (France)",
  "Joudreville (France)",
  "Joue-sur-Erdre (France)",
  "Jougne (France)",
  "Jouhet (France)",
  "Jouques (France)",
  "Journans (France)",
  "Journiac (France)",
  "Journy (France)",
  "Joux (France)",
  "Jouy-en-Josas (France)",
  "Jouy-le-Chatel (France)",
  "Jouy-le-Moutier (France)",
  "Jouy-le-Potier (France)",
  "Jouy-sous-Thelle (France)",
  "Jouy-sur-Eure (France)",
  "Jouy-sur-Morin (France)",
  "Juan-les-Pins (France)",
  "Jugeals-Nazareth (France)",
  "Jugon-les-Lacs (France)",
  "Juigne-sur-Loire (France)",
  "Juigne-sur-Sarthe (France)",
  "Juillac (France)",
  "Juillan (France)",
  "Juilles (France)",
  "Juilly (France)",
  "Jujurieux (France)",
  "Julienas (France)",
  "Julienne (France)",
  "Jully-sur-Sarce (France)",
  "Jumeauville (France)",
  "Jumelles (France)",
  "Jumilhac-le-Grand (France)",
  "Junay (France)",
  "Jungholtz (France)",
  "Juniville (France)",
  "Jupilles (France)",
  "Jurancon (France)",
  "Jury (France)",
  "Jussey (France)",
  "Jussy (France)",
  "Juvaincourt (France)",
  "Juvignac (France)",
  "Juvigny (France)",
  "Juvigny-en-Perthois (France)",
  "Juville (France)",
  "Juvisy-sur-Orge (France)",
  "Juziers (France)",
  "Kaltenhouse (France)",
  "Kappelen (France)",
  "Katzenthal (France)",
  "Kaysersberg (France)",
  "Kedange-sur-Canner (France)",
  "Kembs (France)",
  "Kerbach (France)",
  "Kerfot (France)",
  "Kerfourn (France)",
  "Kergloff (France)",
  "Kerien (France)",
  "Kerity (France)",
  "Kerlaz (France)",
  "Kerlouan (France)",
  "Kernascleden (France)",
  "Kerpert (France)",
  "Kervignac (France)",
  "Kesseldorf (France)",
  "Kienheim (France)",
  "Kientzheim (France)",
  "Killem (France)",
  "Kilstett (France)",
  "Kingersheim (France)",
  "Kintzheim (France)",
  "Knutange (France)",
  "Koenigsmacker (France)",
  "Koeur-la-Petite (France)",
  "Krafft (France)",
  "Krautergersheim (France)",
  "Kriegsheim (France)",
  "Kruth (France)",
  "Kunheim (France)",
  "Kuntzig (France)",
  "Kurtzenhouse (France)",
  "Kutzenhausen (France)",
  "La Baconniere (France)",
  "La Barben (France)",
  "La Barre-de-Monts (France)",
  "La Barre-en-Ouche (France)",
  "La Barthe (France)",
  "La Bassee (France)",
  "La Bastide (France)",
  "La Bathie (France)",
  "La Batie (France)",
  "La Baule-Escoublac (France)",
  "La Baume-de-Transit (France)",
  "La Baussaine (France)",
  "La Bazoche-Gouet (France)",
  "La Bazoge (France)",
  "La Begue (France)",
  "La Bernerie-en-Retz (France)",
  "La Berthenoux (France)",
  "La Bigne (France)",
  "La Biolle (France)",
  "La Bohalle (France)",
  "La Boissiere-des-Landes (France)",
  "La Bosse (France)",
  "La Bosse-de-Bretagne (France)",
  "La Bouexiere (France)",
  "La Bouilladisse (France)",
  "La Bourboule (France)",
  "La Bourgonce (France)",
  "La Boussac (France)",
  "La Bresse (France)",
  "La Bretonne (France)",
  "La Bridoire (France)",
  "La Brigue (France)",
  "La Brillanne (France)",
  "La Broque (France)",
  "La Brosse (France)",
  "La Bruffiere (France)",
  "La Brulatte (France)",
  "La Buisse (France)",
  "La Buissiere (France)",
  "La Bussiere (France)",
  "La Calmette (France)",
  "La Calotterie (France)",
  "La Canourgue (France)",
  "La Capelle (France)",
  "La Caunette (France)",
  "La Cavalerie (France)",
  "La Celle (France)",
  "La Celle-Saint-Cloud (France)",
  "La Celle-sous-Montmirail (France)",
  "La Celle-sur-Seine (France)",
  "La Cerlangue (France)",
  "La Chaise (France)",
  "La Chaise-Baudouin (France)",
  "La Chaize-le-Vicomte (France)",
  "La Chapelaude (France)",
  "La Chapelle (France)",
  "La Chapelle-aux-Bois (France)",
  "La Chapelle-aux-Lys (France)",
  "La Chapelle-Baton (France)",
  "La Chapelle-Blanche (France)",
  "La Chapelle-de-Brain (France)",
  "La Chapelle-de-Guinchay (France)",
  "La Chapelle-en-Vercors (France)",
  "La Chapelle-Gaudin (France)",
  "La Chapelle-Gauthier (France)",
  "La Chapelle-Heulin (France)",
  "La Chapelle-Laurent (France)",
  "La Chapelle-Montligeon (France)",
  "La Chapelle-Orthemale (France)",
  "La Chapelle-Saint-Luc (France)",
  "La Chapelle-sur-Chezy (France)",
  "La Chapelle-Yvon (France)",
  "La Charme (France)",
  "La Charmee (France)",
  "La Chartre-sur-le-Loir (France)",
  "La Chataigneraie (France)",
  "La Chatre (France)",
  "La Chatre-Langlin (France)",
  "La Chaumusse (France)",
  "La Chaussaire (France)",
  "La Chaussee (France)",
  "La Chaux-du-Dombief (France)",
  "La Cheneviere (France)",
  "La Chevallerais (France)",
  "La Chevroliere (France)",
  "La Ciotat (France)",
  "La Clayette (France)",
  "La Clisse (France)",
  "La Clotte (France)",
  "La Clusaz (France)",
  "La Cluse-et-Mijoux (France)",
  "La Colle-sur-Loup (France)",
  "La Combelle (France)",
  "La Copechagniere (France)",
  "La Coquille (France)",
  "La Cornuaille (France)",
  "La Cote (France)",
  "La Couarde-sur-Mer (France)",
  "La Coucourde (France)",
  "La Coudre (France)",
  "La Coulonche (France)",
  "La Courneuve (France)",
  "La Couronne (France)",
  "La Couture-Boussey (France)",
  "La Crau (France)",
  "La Croix-Valmer (France)",
  "La Cropte (France)",
  "La Dagueniere (France)",
  "La Defense (France)",
  "La Destrousse (France)",
  "La Dominelais (France)",
  "La Fare-en-Champsaur (France)",
  "La Farlede (France)",
  "La Faute-sur-Mer (France)",
  "La Faye (France)",
  "La Fere (France)",
  "La Ferriere (France)",
  "La Ferriere-Bochard (France)",
  "La Ferte (France)",
  "La Ferte-Alais (France)",
  "La Ferte-Mace (France)",
  "La Ferte-Milon (France)",
  "La Ferte-Saint-Cyr (France)",
  "La Ferte-Saint-Samson (France)",
  "La Ferte-sous-Jouarre (France)",
  "La Feuillade (France)",
  "La Feuillie (France)",
  "La Flachere (France)",
  "La Fleche (France)",
  "La Flocelliere (France)",
  "La Flotte (France)",
  "La Force (France)",
  "La Forest-Landerneau (France)",
  "La Foret (France)",
  "La Foret-Fouesnant (France)",
  "La Forie (France)",
  "La Fouillade (France)",
  "La Fouillouse (France)",
  "La Foux (France)",
  "La Foye-Monjault (France)",
  "La Frasse (France)",
  "La Frenaye (France)",
  "La Fresnais (France)",
  "La Frette (France)",
  "La Frette-sur-Seine (France)",
  "La Gacilly (France)",
  "La Gaillarde (France)",
  "La Garde (France)",
  "La Garenne (France)",
  "La Garenne-Colombes (France)",
  "La Garnache (France)",
  "La Gaubretiere (France)",
  "La Gaude (France)",
  "La Gavotte (France)",
  "La Glacerie (France)",
  "La Gorgue (France)",
  "La Gouesniere (France)",
  "La Grand-Croix (France)",
  "La Grande-Motte (France)",
  "La Grandville (France)",
  "La Grave (France)",
  "La Graverie (France)",
  "La Gree-Saint-Laurent (France)",
  "La Greve-sur-Mignon (France)",
  "La Grigonnais (France)",
  "La Groise (France)",
  "La Guerche (France)",
  "La Guerche-de-Bretagne (France)",
  "La Gueroulde (France)",
  "La Guierche (France)",
  "La Guyonniere (France)",
  "La Haie (France)",
  "La Halle (France)",
  "La Hallotiere (France)",
  "La Haye (France)",
  "La Haye-Saint-Sylvestre (France)",
  "La Houblonniere (France)",
  "La Houssaye-en-Brie (France)",
  "La Houssoye (France)",
  "La Jaille-Yvon (France)",
  "La Jarne (France)",
  "La Jarrie (France)",
  "La Jubaudiere (France)",
  "La Jumelliere (France)",
  "La Laigne (France)",
  "La Lande (France)",
  "La Landec (France)",
  "La Laupie (France)",
  "La Lechere (France)",
  "La Limouziniere (France)",
  "La Liviniere (France)",
  "La Londe (France)",
  "La Londe-les-Maures (France)",
  "La Loubiere (France)",
  "La Loupe (France)",
  "La Lucerne-dOutremer (France)",
  "La Machine (France)",
  "La Madeleine (France)",
  "La Madeleine-sur-Loing (France)",
  "La Magdelaine-sur-Tarn (France)",
  "La Mailleraye-sur-Seine (France)",
  "La Malhoure (France)",
  "La Marche (France)",
  "La Marolle-en-Sologne (France)",
  "La Martiniere (France)",
  "La Maxe (France)",
  "La Meauffe (France)",
  "La Meaugon (France)",
  "La Meignanne (France)",
  "La Meilleraie-Tillay (France)",
  "La Meilleraye-de-Bretagne (France)",
  "La Membrolle-sur-Choisille (France)",
  "La Membrolle-sur-Longuenee (France)",
  "La Menitre (France)",
  "La Meziere (France)",
  "La Milesse (France)",
  "La Montagne (France)",
  "La Mothe-Saint-Heray (France)",
  "La Motte (France)",
  "La Motte-Chalancon (France)",
  "La Motte-du-Caire (France)",
  "La Motte-Fouquet (France)",
  "La Motte-Saint-Jean (France)",
  "La Motte-Saint-Martin (France)",
  "La Motte-Servolex (France)",
  "La Mulatiere (France)",
  "La Muraz (France)",
  "La Mure (France)",
  "La Napoule (France)",
  "La Nerthe (France)",
  "La Neuveville-devant-Lepanges (France)",
  "La Neuville-du-Bosc (France)",
  "La Noe (France)",
  "La Norville (France)",
  "La Palud (France)",
  "La Paute (France)",
  "La Penne-sur-Huveaune (France)",
  "La Perche (France)",
  "La Place (France)",
  "La Plagne (France)",
  "La Plaine (France)",
  "La Plaine-Saint-Denis (France)",
  "La Planche (France)",
  "La Plane (France)",
  "La Pointe (France)",
  "La Poiteviniere (France)",
  "La Pommeraye (France)",
  "La Porcherie (France)",
  "La Porte (France)",
  "La Possonniere (France)",
  "La Poste (France)",
  "La Poterie (France)",
  "La Poterie-Mathieu (France)",
  "La Poueze (France)",
  "La Puye (France)",
  "La Queue-en-Brie (France)",
  "La Queue-les-Yvelines (France)",
  "La Ravoire (France)",
  "La Remuee (France)",
  "La Renaudiere (France)",
  "La Reole (France)",
  "La Reorthe (France)",
  "La Ricamarie (France)",
  "La Richardais (France)",
  "La Riche (France)",
  "La Riviere (France)",
  "La Riviere-de-Corps (France)",
  "La Rixouse (France)",
  "La Roche (France)",
  "La Roche-Blanche (France)",
  "La Roche-des-Arnauds (France)",
  "La Roche-sur-le-Buis (France)",
  "La Roche-sur-Yon (France)",
  "La Rochefoucauld (France)",
  "La Rochegiron (France)",
  "La Rochelle (France)",
  "La Rochenard (France)",
  "La Rochette (France)",
  "La Rochette-sur-Crest (France)",
  "La Romagne (France)",
  "La Ronde (France)",
  "La Roque-Baignard (France)",
  "La Roquebrussanne (France)",
  "La Roquette-sur-Siagne (France)",
  "La Rouine (France)",
  "La Rouquette (France)",
  "La Rouviere (France)",
  "La Salette-Fallavaux (France)",
  "La Salle-de-Vihiers (France)",
  "La Salvetat-Lauragais (France)",
  "La Salvetat-Saint-Gilles (France)",
  "La Salvetat-sur-Agout (France)",
  "La Saulce (France)",
  "La Sauve (France)",
  "La Sauvetat-de-Saveres (France)",
  "La Seauve-sur-Semene (France)",
  "La Seguiniere (France)",
  "La Selle-en-Hermoy (France)",
  "La Selve (France)",
  "La Sentinelle (France)",
  "La Seyne-sur-Mer (France)",
  "La Sone (France)",
  "La Souche (France)",
  "La Souterraine (France)",
  "La Suze-sur-Sarthe (France)",
  "La Tagniere (France)",
  "La Taillee (France)",
  "La Talaudiere (France)",
  "La Tardiere (France)",
  "La Terrasse (France)",
  "La Tessoualle (France)",
  "La Teste-de-Buch (France)",
  "La Tour (France)",
  "La Tour-dAuvergne (France)",
  "La Tour-de-Scay (France)",
  "La Tour-du-Crieu (France)",
  "La Tour-Saint-Gelin (France)",
  "La Tour-sur-Orb (France)",
  "La Tourette (France)",
  "La Tranche-sur-Mer (France)",
  "La Treille (France)",
  "La Tremblade (France)",
  "La Trimouille (France)",
  "La Trinite (France)",
  "La Trinite-de-Reville (France)",
  "La Tronche (France)",
  "La Tuiliere (France)",
  "La Turballe (France)",
  "La Turbie (France)",
  "La Vacherie (France)",
  "La Valette-du-Var (France)",
  "La Vallee de Dormelles (France)",
  "La Varenne (France)",
  "La Vaupaliere (France)",
  "La Verpilliere (France)",
  "La Verrie (France)",
  "La Verriere (France)",
  "La Vespiere (France)",
  "La Veze (France)",
  "La Ville (France)",
  "La Ville aux Chiens (France)",
  "La Ville-aux-Bois (France)",
  "La Villedieu (France)",
  "La Villedieu-les-Quenoche (France)",
  "La Villeneuve (France)",
  "La Villetelle (France)",
  "La Voulte-sur-Rhone (France)",
  "La Vraie-Croix (France)",
  "La Walck (France)",
  "La Wantzenau (France)",
  "Laas (France)",
  "Labaroche (France)",
  "Labarthe-sur-Leze (France)",
  "Labastide-Beauvoir (France)",
  "Labastide-Cezeracq (France)",
  "Labastide-dArmagnac (France)",
  "Labastide-de-Virac (France)",
  "Labastide-Gabausse (France)",
  "Labastide-Rouairoux (France)",
  "Labastide-Saint-Pierre (France)",
  "Labastidette (France)",
  "Labatut (France)",
  "Labege (France)",
  "Labenne (France)",
  "Labessiere-Candeil (France)",
  "Labeuvriere (France)",
  "Lablachere (France)",
  "Labouheyre (France)",
  "Labouquerie (France)",
  "Labourse (France)",
  "Labretonie (France)",
  "Labruguiere (France)",
  "Labruyere (France)",
  "Labry (France)",
  "Labuissiere (France)",
  "Lacabarede (France)",
  "Lacadee (France)",
  "Lacanau (France)",
  "Lacapelle-Cabanac (France)",
  "Lacapelle-Marival (France)",
  "Lacapelle-Viescamp (France)",
  "Lacaune (France)",
  "Lacaussade (France)",
  "Lachapelle (France)",
  "Lachapelle-sous-Chaux (France)",
  "Lachapelle-sous-Rougemont (France)",
  "Lachaux (France)",
  "Lacour (France)",
  "Lacq (France)",
  "Lacroix-Falgarde (France)",
  "Lacroix-Saint-Ouen (France)",
  "Lacroix-sur-Meuse (France)",
  "Lacrost (France)",
  "Lacs (France)",
  "Ladoix-Serrigny (France)",
  "Ladon (France)",
  "Laduz (France)",
  "Lafare (France)",
  "Lafeuillade-en-Vezie (France)",
  "Lafitole (France)",
  "Lafox (France)",
  "Lagardelle-sur-Leze (France)",
  "Lagardere (France)",
  "Lagarrigue (France)",
  "Lagnes (France)",
  "Lagnieu (France)",
  "Lagny-le-Sec (France)",
  "Lagor (France)",
  "Lagord (France)",
  "Lagrave (France)",
  "Laguenne (France)",
  "Laguiole (France)",
  "Lagupie (France)",
  "Lahitte-Toupiere (France)",
  "Lahonce (France)",
  "Lahontan (France)",
  "Lahosse (France)",
  "Lahourcade (France)",
  "Laifour (France)",
  "Laigne (France)",
  "Laigne-en-Belin (France)",
  "Laignelet (France)",
  "Laignes (France)",
  "Laigneville (France)",
  "LAiguillon-sur-Mer (France)",
  "Laille (France)",
  "Lailly-en-Val (France)",
  "Lainsecq (France)",
  "Laissac (France)",
  "Laissaud (France)",
  "Laissey (France)",
  "Laiz (France)",
  "Laize (France)",
  "Lalanne-Arque (France)",
  "Lalbenque (France)",
  "Laleu (France)",
  "Lalinde (France)",
  "Lallaing (France)",
  "Lalonquette (France)",
  "Laloubere (France)",
  "Lamagdelaine (France)",
  "Lamagistere (France)",
  "Lamaire (France)",
  "Lamalou-les-Bains (France)",
  "Lamanon (France)",
  "Lamarche (France)",
  "Lamarche-sur-Saone (France)",
  "Lamastre (France)",
  "Lamativie (France)",
  "Lamaziere-Basse (France)",
  "Lamballe (France)",
  "Lambersart (France)",
  "Lambert (France)",
  "Lambesc (France)",
  "Lambres-lez-Douai (France)",
  "Lamnay (France)",
  "Lamorlaye (France)",
  "Lamorville (France)",
  "Lamothe-Landerron (France)",
  "Lamothe-Montravel (France)",
  "Lamotte-Beuvron (France)",
  "Lampaul-Guimiliau (France)",
  "Lampaul-Plouarzel (France)",
  "Lampertheim (France)",
  "Lampertsloch (France)",
  "Lancey (France)",
  "Lancie (France)",
  "Lancieux (France)",
  "Lancon-Provence (France)",
  "Lancrans (France)",
  "Landas (France)",
  "Landebia (France)",
  "Landeda (France)",
  "Landelles (France)",
  "Landelles-et-Coupigny (France)",
  "Landemont (France)",
  "Landepereuse (France)",
  "Landerneau (France)",
  "Landeronde (France)",
  "Landes (France)",
  "Landevant (France)",
  "Landevieille (France)",
  "Landivisiau (France)",
  "Landogne (France)",
  "Landos (France)",
  "Landrecies (France)",
  "Landres (France)",
  "Landrethun-le-Nord (France)",
  "Landrethun-les-Ardres (France)",
  "Landrevarzec (France)",
  "Landry (France)",
  "Landser (France)",
  "Landujan (France)",
  "Landunvez (France)",
  "Lanester (France)",
  "Laneuvelotte (France)",
  "Laneuveville-aux-Bois (France)",
  "Laneuveville-devant-Bayon (France)",
  "Laneuveville-devant-Nancy (France)",
  "Langan (France)",
  "Langeac (France)",
  "Langeais (France)",
  "Langensoultzbach (France)",
  "Langlade (France)",
  "Langoat (France)",
  "Langoelan (France)",
  "Langoiran (France)",
  "Langolen (France)",
  "Langon (France)",
  "Langonnet (France)",
  "Langourla (France)",
  "Langres (France)",
  "Langrune-sur-Mer (France)",
  "Langueux (France)",
  "Languidic (France)",
  "Lanhelin (France)",
  "Laning (France)",
  "Lanleff (France)",
  "Lanmerin (France)",
  "Lanmeur (France)",
  "Lanne (France)",
  "Lanne-en-Baretous (France)",
  "Lannebert (France)",
  "Lannemezan (France)",
  "Lannilis (France)",
  "Lannion (France)",
  "Lannoy (France)",
  "Lannoy-Cuillere (France)",
  "Lanobre (France)",
  "Lanouee (France)",
  "Lanrelas (France)",
  "Lanrodec (France)",
  "Lans (France)",
  "Lans-en-Vercors (France)",
  "Lansac (France)",
  "Lansargues (France)",
  "Lanta (France)",
  "Lantenne-Vertiere (France)",
  "Lanthenans (France)",
  "Lantignie (France)",
  "Lantilly (France)",
  "Lanton (France)",
  "Lantriac (France)",
  "Lanvallay (France)",
  "Lanvaudan (France)",
  "Lanveoc (France)",
  "Lanvollon (France)",
  "Lanzac (France)",
  "Laon (France)",
  "Lapalisse (France)",
  "Lapalme (France)",
  "Lapalud (France)",
  "Laparrouquial (France)",
  "Lapenche (France)",
  "Laperriere-sur-Saone (France)",
  "Lapeyrouse-Fossat (France)",
  "Lapeyrouse-Mornay (France)",
  "Lapoutroie (France)",
  "Lapouyade (France)",
  "Lapte (France)",
  "Lapugnoy (France)",
  "Laquenexy (France)",
  "Laqueuille (France)",
  "Laragne-Monteglin (France)",
  "LArbresle (France)",
  "Larcay (France)",
  "Larchant (France)",
  "Larche (France)",
  "Lardy (France)",
  "Largeasse (France)",
  "Largillay-Marsonnay (France)",
  "Lariviere-Arnoncourt (France)",
  "Larmor-Baden (France)",
  "Larmor-Plage (France)",
  "Laroche-Saint-Cydroine (France)",
  "Laroin (France)",
  "Laroque-de-Fa (France)",
  "Larrazet (France)",
  "Larressore (France)",
  "Larriviere-Saint-Savin (France)",
  "Laruns (France)",
  "Larzicourt (France)",
  "Lasalle (France)",
  "Lasbordes (France)",
  "Lassay-les-Chateaux (France)",
  "Lassay-sur-Croisne (France)",
  "Lasseran (France)",
  "Lasserre (France)",
  "Lasseube (France)",
  "Lassigny (France)",
  "Lasson (France)",
  "Lassy (France)",
  "Lastic (France)",
  "Lathuile (France)",
  "Latille (France)",
  "Latour (France)",
  "Latour-Bas-Elne (France)",
  "Latresne (France)",
  "Latronquiere (France)",
  "Lattainville (France)",
  "Lattes (France)",
  "Lattre-Saint-Quentin (France)",
  "Laudun-lArdoise (France)",
  "Laugnac (France)",
  "Launaguet (France)",
  "Launay (France)",
  "Laurabuc (France)",
  "Laure (France)",
  "Laurede (France)",
  "Laurenan (France)",
  "Laurens (France)",
  "Laurent (France)",
  "Lauret (France)",
  "Lauris (France)",
  "Laussonne (France)",
  "Lautenbach (France)",
  "Lauterbourg (France)",
  "Lautrec (France)",
  "Lauzerte (France)",
  "Lauzerville (France)",
  "Lauzun (France)",
  "Laval (France)",
  "Laval-sur-Doulon (France)",
  "Laval-sur-Vologne (France)",
  "Lavannes (France)",
  "Lavans-les-Saint-Claude (France)",
  "Lavans-Vuillafans (France)",
  "Lavardac (France)",
  "Lavardens (France)",
  "Lavardin (France)",
  "Lavau (France)",
  "Lavaur (France)",
  "Lavaurette (France)",
  "Lavelanet (France)",
  "Laventie (France)",
  "Lavergne (France)",
  "Lavernose-Lacasse (France)",
  "Laversine (France)",
  "Laversines (France)",
  "Laverune (France)",
  "Lavit (France)",
  "Lavours (France)",
  "Lavoute-Chilhac (France)",
  "Laxou (France)",
  "Laye (France)",
  "Layrac (France)",
  "Layrisse (France)",
  "Le Baizil (France)",
  "Le Ban-Saint-Martin (France)",
  "Le Bar-sur-Loup (France)",
  "Le Barboux (France)",
  "Le Barcares (France)",
  "Le Bardon (France)",
  "Le Barp (France)",
  "Le Beausset (France)",
  "Le Bernard (France)",
  "Le Bessat (France)",
  "Le Bez (France)",
  "Le Bignon (France)",
  "Le Blanc (France)",
  "Le Blanc-Mesnil (France)",
  "Le Bleymard (France)",
  "Le Bocasse (France)",
  "Le Bois (France)",
  "Le Bois-Plage-en-Re (France)",
  "Le Bonhomme (France)",
  "Le Bono (France)",
  "Le Bosc (France)",
  "Le Bosc-Roger-en-Roumois (France)",
  "Le Bouchage (France)",
  "Le Boulay-Morin (France)",
  "Le Boupere (France)",
  "Le Bourg (France)",
  "Le Bourget (France)",
  "Le Bourgneuf-la-Foret (France)",
  "Le Bouscat (France)",
  "Le Bousquet (France)",
  "Le Breil (France)",
  "Le Breuil (France)",
  "Le Breuil-sur-Couze (France)",
  "Le Broc (France)",
  "Le Brugeron (France)",
  "Le Buisson-de-Cadouin (France)",
  "Le Cailar (France)",
  "Le Cannet (France)",
  "Le Castellet (France)",
  "Le Castera (France)",
  "Le Cateau-Cambresis (France)",
  "Le Caylar (France)",
  "Le Cellier (France)",
  "Le Cendre (France)",
  "Le Cergne (France)",
  "Le Chambon (France)",
  "Le Champ-pres-Froges (France)",
  "Le Champ-Saint-Pere (France)",
  "Le Chateau (France)",
  "Le Chateau Gaillard (France)",
  "Le Chatel (France)",
  "Le Chatelard (France)",
  "Le Chatelet-en-Brie (France)",
  "Le Chatelet-sur-Retourne (France)",
  "Le Chaylard (France)",
  "Le Chesnay (France)",
  "Le Chesne (France)",
  "Le Cheylas (France)",
  "Le Collet-de-Deze (France)",
  "Le Comte (France)",
  "Le Conquet (France)",
  "Le Coteau (France)",
  "Le Coudray (France)",
  "Le Coudray-Baillet (France)",
  "Le Coudray-Montceaux (France)",
  "Le Cres (France)",
  "Le Crest (France)",
  "Le Creusot (France)",
  "Le Croisic (France)",
  "Le Crotoy (France)",
  "Le Deluge (France)",
  "Le Deschaux (France)",
  "Le Dezert (France)",
  "Le Donjon (France)",
  "Le Dorat (France)",
  "Le Doulieu (France)",
  "Le Falgoux (France)",
  "Le Faou (France)",
  "Le Faouet (France)",
  "Le Fayet (France)",
  "Le Fenouiller (France)",
  "Le Ferre (France)",
  "Le Fidelaire (France)",
  "Le Fief-Sauvin (France)",
  "Le Foeil (France)",
  "Le Folgoet (France)",
  "Le Fossat (France)",
  "Le Fousseret (France)",
  "Le Fraysse (France)",
  "Le Fresne-Camilly (France)",
  "Le Fuilet (France)",
  "Le Garric (France)",
  "Le Gavre (France)",
  "Le Gond (France)",
  "Le Grand Village (France)",
  "Le Grand-Bornand (France)",
  "Le Grand-Pressigny (France)",
  "Le Grand-Quevilly (France)",
  "Le Gratteris (France)",
  "Le Grau-du-Roi (France)",
  "Le Gua (France)",
  "Le Guerno (France)",
  "Le Guildo (France)",
  "Le Haillan (France)",
  "Le Havre (France)",
  "Le Herie-la-Vieville (France)",
  "Le Hohwald (France)",
  "Le Houlme (France)",
  "Le Kremlin-Bicetre (France)",
  "Le Landin (France)",
  "Le Landreau (France)",
  "Le Lardin-Saint-Lazare (France)",
  "Le Lavandou (France)",
  "Le Lez (France)",
  "Le Longeron (France)",
  "Le Loroux (France)",
  "Le Loroux-Bottereau (France)",
  "Le Louroux-Beconnais (France)",
  "Le Luart (France)",
  "Le Luc (France)",
  "Le Lude (France)",
  "Le Manoir (France)",
  "Le Mans (France)",
  "Le Marillais (France)",
  "Le Martinet (France)",
  "Le Mas (France)",
  "Le May-sur-Evre (France)",
  "Le Mayet (France)",
  "Le Mazeau (France)",
  "Le Mee-sur-Seine (France)",
  "Le Meix-Saint-Epoing (France)",
  "Le Mele-sur-Sarthe (France)",
  "Le Menil (France)",
  "Le Meriot (France)",
  "Le Merlerault (France)",
  "Le Mesnil-Amelot (France)",
  "Le Mesnil-en-Thelle (France)",
  "Le Mesnil-en-Vallee (France)",
  "Le Mesnil-Esnard (France)",
  "Le Mesnil-Fuguet (France)",
  "Le Mesnil-le-Roi (France)",
  "Le Mesnil-Ozenne (France)",
  "Le Mesnil-Vigot (France)",
  "Le Mesnil-Villeman (France)",
  "Le Meux (France)",
  "Le Molard (France)",
  "Le Molay (France)",
  "Le Monastier-Pin-Mories (France)",
  "Le Monastier-sur-Gazeille (France)",
  "Le Mont-Dore (France)",
  "Le Monteil (France)",
  "Le Montel (France)",
  "Le Muy (France)",
  "Le Neubourg (France)",
  "Le Noyer (France)",
  "Le Palais (France)",
  "Le Pallet (France)",
  "Le Parc (France)",
  "Le Parcq (France)",
  "Le Passage (France)",
  "Le Pecq (France)",
  "Le Pellerin (France)",
  "Le Perray-en-Yvelines (France)",
  "Le Perreux-sur-Marne (France)",
  "Le Perrier (France)",
  "Le Petit-Quevilly (France)",
  "Le Pian-sur-Garonne (France)",
  "Le Pin (France)",
  "Le Pin-la-Garenne (France)",
  "Le Pizou (France)",
  "Le Plan-de-la-Tour (France)",
  "Le Planois (France)",
  "Le Plessier-sur-Bulles (France)",
  "Le Plessis (France)",
  "Le Plessis-Belleville (France)",
  "Le Plessis-Bouchard (France)",
  "Le Plessis-Grammoire (France)",
  "Le Plessis-Hebert (France)",
  "Le Plessis-Pate (France)",
  "Le Plessis-Robinson (France)",
  "Le Plessis-Trevise (France)",
  "Le Poet (France)",
  "Le Poet-Laval (France)",
  "Le Poinconnet (France)",
  "Le Poire-sur-Velluire (France)",
  "Le Pont (France)",
  "Le Pont-de-Claix (France)",
  "Le Pontet (France)",
  "Le Porge (France)",
  "Le Port (France)",
  "Le Port-Marly (France)",
  "Le Portel (France)",
  "Le Pouget (France)",
  "Le Pouliguen (France)",
  "Le Pout (France)",
  "Le Pouzin (France)",
  "Le Pradet (France)",
  "Le Pre-Saint-Gervais (France)",
  "Le Puy (France)",
  "Le Puy-en-Velay (France)",
  "Le Puy-Sainte-Reparade (France)",
  "Le Quesnel (France)",
  "Le Quesnoy (France)",
  "Le Raincy (France)",
  "Le Relecq-Kerhuon (France)",
  "Le Renouard (France)",
  "Le Revest-les-Eaux (France)",
  "Le Rheu (France)",
  "Le Roncenay-Authenay (France)",
  "Le Rouget (France)",
  "Le Rouret (France)",
  "Le Rove (France)",
  "Le Sacq (France)",
  "Le Sap (France)",
  "Le Segur (France)",
  "Le Soler (France)",
  "Le Sourn (France)",
  "Le Syndicat (France)",
  "Le Taillan-Medoc (France)",
  "Le Tallud (France)",
  "Le Teich (France)",
  "Le Teil (France)",
  "Le Teilleul (France)",
  "Le Temple-de-Bretagne (France)",
  "Le Theil (France)",
  "Le Theil-de-Bretagne (France)",
  "Le Thieulin (France)",
  "Le Thillay (France)",
  "Le Thillot (France)",
  "Le Tholonet (France)",
  "Le Tholy (France)",
  "Le Thor (France)",
  "Le Thou (France)",
  "Le Thoureil (France)",
  "Le Thuit-Signol (France)",
  "Le Thuit-Simer (France)",
  "Le Tignet (France)",
  "Le Torquesne (France)",
  "Le Touquet-Paris-Plage (France)",
  "Le Tourne (France)",
  "Le Tourneur (France)",
  "Le Touvet (France)",
  "Le Trait (France)",
  "Le Treport (France)",
  "Le Trevoux (France)",
  "Le Tronchet (France)",
  "Le Tronquay (France)",
  "Le Val (France)",
  "Le Val-dAjol (France)",
  "Le Vaudioux (France)",
  "Le Vaudreuil (France)",
  "Le Vaumain (France)",
  "Le Verger (France)",
  "Le Vernet (France)",
  "Le Versoud (France)",
  "Le Vesinet (France)",
  "Le Vieux (France)",
  "Le Vieux Bourg (France)",
  "Le Vigan (France)",
  "Le Vigean (France)",
  "Le Vigeant (France)",
  "Le Vigen (France)",
  "Le Vivier-sur-Mer (France)",
  "Le Voide (France)",
  "Lealvillers (France)",
  "Leaz (France)",
  "Lecelles (France)",
  "Lechatelet (France)",
  "Lechelle (France)",
  "Lecousse (France)",
  "Lectoure (France)",
  "Ledenon (France)",
  "Lederzeele (France)",
  "Leers (France)",
  "Leffincourt (France)",
  "Leffrinckoucke (France)",
  "Leforest (France)",
  "Lege (France)",
  "Lege-Cap-Ferret (France)",
  "Leguevin (France)",
  "Lehon (France)",
  "Leigneux (France)",
  "Leintrey (France)",
  "Lelling (France)",
  "Lemainville (France)",
  "Lembach (France)",
  "Lemberg (France)",
  "Leme (France)",
  "Lemmes (France)",
  "Lempdes (France)",
  "Lempdes-sur-Allagnon (France)",
  "Lemps (France)",
  "Lencloitre (France)",
  "Lennon (France)",
  "Lenoncourt (France)",
  "Lens (France)",
  "Lens-Lestang (France)",
  "Lent (France)",
  "Lentigny (France)",
  "Lentilly (France)",
  "Lentiol (France)",
  "Leojac (France)",
  "Leon (France)",
  "Leouville (France)",
  "Lepin-le-Lac (France)",
  "Lepuix (France)",
  "Lepuy (France)",
  "Lere (France)",
  "Lery (France)",
  "Les Abrets (France)",
  "Les Ageux (France)",
  "Les Alleuds (France)",
  "Les Allies (France)",
  "Les Allues (France)",
  "Les Ancizes-Comps (France)",
  "Les Andelys (France)",
  "Les Angles (France)",
  "Les Arcs (France)",
  "Les Ardillats (France)",
  "Les Artigues-de-Lussac (France)",
  "Les Assions (France)",
  "Les Aubiers (France)",
  "Les Avanchers-Valmorel (France)",
  "Les Avenieres (France)",
  "Les Bains (France)",
  "Les Baux de Provence (France)",
  "Les Baux-de-Breteuil (France)",
  "Les Billanges (France)",
  "Les Bordes (France)",
  "Les Bordes-sur-Lez (France)",
  "Les Breviaires (France)",
  "Les Brouzils (France)",
  "Les Cabanes (France)",
  "Les Cabannes (France)",
  "Les Cars (France)",
  "Les Champs (France)",
  "Les Chapelles (France)",
  "Les Charmontois (France)",
  "Les Cheres (France)",
  "Les Clayes-sous-Bois (France)",
  "Les Clouzeaux (France)",
  "Les Combes (France)",
  "Les Deux-Fays (France)",
  "Les Echelles (France)",
  "Les Ecrennes (France)",
  "Les Eglisottes-et-Chalaures (France)",
  "Les Eparres (France)",
  "Les Epesses (France)",
  "Les Essards (France)",
  "Les Essards-Taignevaux (France)",
  "Les Essarts (France)",
  "Les Etangs (France)",
  "Les Eyzies-de-Tayac-Sireuil (France)",
  "Les Fins (France)",
  "Les Fontaines (France)",
  "Les Fonts (France)",
  "Les Forges (France)",
  "Les Fourgs (France)",
  "Les Gets (France)",
  "Les Gours (France)",
  "Les Grands-Chezeaux (France)",
  "Les Granges (France)",
  "Les Gras (France)",
  "Les Halles (France)",
  "Les Hays (France)",
  "Les Herbiers (France)",
  "Les Hogues (France)",
  "Les Hopitaux-Vieux (France)",
  "Les Hotelleries (France)",
  "Les Houches (France)",
  "Les Jumeaux (France)",
  "Les Landes (France)",
  "Les Landes-Genusson (France)",
  "Les Laumes (France)",
  "Les Lilas (France)",
  "Les Loges (France)",
  "Les Loges-en-Josas (France)",
  "Les Lucs-sur-Boulogne (France)",
  "Les Mages (France)",
  "Les Marches (France)",
  "Les Martres (France)",
  "Les Mathes (France)",
  "Les Maures (France)",
  "Les Mees (France)",
  "Les Mesneux (France)",
  "Les Mesnuls (France)",
  "Les Milles (France)",
  "Les Moitiers-en-Bauptois (France)",
  "Les Molieres (France)",
  "Les Montils (France)",
  "Les Mureaux (France)",
  "Les Nouillers (France)",
  "Les Ollieres-sur-Eyrieux (France)",
  "Les Olmes (France)",
  "Les Ormes (France)",
  "Les Ormes-sur-Voulzie (France)",
  "Les Palais (France)",
  "Les Pavillons-sous-Bois (France)",
  "Les Peintures (France)",
  "Les Pennes-Mirabeau (France)",
  "Les Petites Loges (France)",
  "Les Pieux (France)",
  "Les Pineaux (France)",
  "Les Pins (France)",
  "Les Places (France)",
  "Les Rives (France)",
  "Les Rivieres-Henruel (France)",
  "Les Roches (France)",
  "Les Rosiers (France)",
  "Les Rosiers-sur-Loire (France)",
  "Les Rousses (France)",
  "Les Sables (France)",
  "Les Sauvages (France)",
  "Les Sorinieres (France)",
  "Les Taillades (France)",
  "Les Thilliers-en-Vexin (France)",
  "Les Touches (France)",
  "Les Vallees (France)",
  "Les Vans (France)",
  "Les Varennes (France)",
  "Les Vigneaux (France)",
  "Les Villedieu (France)",
  "Lescar (France)",
  "Lescheraines (France)",
  "Lescherolles (France)",
  "Lesches (France)",
  "Lesconil (France)",
  "Lescout (France)",
  "Lescure (France)",
  "Lesdain (France)",
  "Lesigny (France)",
  "Lesneven (France)",
  "Lespesses (France)",
  "Lespielle (France)",
  "Lespignan (France)",
  "Lespinasse (France)",
  "Lesquin (France)",
  "Lessay (France)",
  "Lesse (France)",
  "Lessy (France)",
  "Lestelle-Betharram (France)",
  "Lestiou (France)",
  "Lestrem (France)",
  "Letanne (France)",
  "Letricourt (France)",
  "Leuc (France)",
  "Leucate (France)",
  "Leudeville (France)",
  "Leugny (France)",
  "Leuilly-sous-Coucy (France)",
  "Leuville-sur-Orge (France)",
  "Leuvrigny (France)",
  "Levainville (France)",
  "Leval (France)",
  "Levallois-Perret (France)",
  "Levens (France)",
  "Levernois (France)",
  "Leves (France)",
  "Levet (France)",
  "Levie (France)",
  "Levier (France)",
  "Levignacq (France)",
  "Levis (France)",
  "Levroux (France)",
  "Lewarde (France)",
  "Lexy (France)",
  "Leyme (France)",
  "Leyr (France)",
  "Leyrieu (France)",
  "Leysse (France)",
  "Lezan (France)",
  "Lezardrieux (France)",
  "Lezat-sur-Leze (France)",
  "Lezay (France)",
  "Lezennes (France)",
  "Lezignan-Corbieres (France)",
  "Lezigne (France)",
  "Lezigneux (France)",
  "Lezoux (France)",
  "Lheraule (France)",
  "Lherm (France)",
  "Lhommaize (France)",
  "Lhuis (France)",
  "Liancourt (France)",
  "Liancourt-Saint-Pierre (France)",
  "Liart (France)",
  "Libercourt (France)",
  "Libourne (France)",
  "Licques (France)",
  "Liepvre (France)",
  "Lieramont (France)",
  "Liergues (France)",
  "Liernais (France)",
  "Lieu-Saint-Amand (France)",
  "Lieuran-les-Beziers (France)",
  "Lieurey (France)",
  "Lieuron (France)",
  "Lieusaint (France)",
  "Lieuvillers (France)",
  "Liffol-le-Grand (France)",
  "Liffre (France)",
  "Ligescourt (France)",
  "Lignan-sur-Orb (France)",
  "Ligne (France)",
  "Lignerolles (France)",
  "Lignieres-Chatelain (France)",
  "Lignieres-la-Carelle (France)",
  "Lignol (France)",
  "Ligny-en-Barrois (France)",
  "Ligny-en-Cambresis (France)",
  "Ligny-le-Chatel (France)",
  "Ligny-le-Ribault (France)",
  "Ligsdorf (France)",
  "Ligueil (France)",
  "Liguge (France)",
  "Lihons (France)",
  "Lihus (France)",
  "Lille (France)",
  "Lillebonne (France)",
  "Lillers (France)",
  "Limas (France)",
  "Limay (France)",
  "Limeil-Brevannes (France)",
  "Limeray (France)",
  "Limersheim (France)",
  "Limerzel (France)",
  "Limesy (France)",
  "Limetz-Villez (France)",
  "Limoges (France)",
  "Limoges-Fourches (France)",
  "Limogne-en-Quercy (France)",
  "Limonest (France)",
  "Limony (France)",
  "Limours (France)",
  "Limoux (France)",
  "Linars (France)",
  "Linas (France)",
  "Lincel (France)",
  "Lingolsheim (France)",
  "Lingreville (France)",
  "Linselles (France)",
  "Linxe (France)",
  "Liocourt (France)",
  "Lion-en-Sullias (France)",
  "Lion-sur-Mer (France)",
  "Liouc (France)",
  "Lipsheim (France)",
  "Lire (France)",
  "Lisieux (France)",
  "Lisle (France)",
  "LIsle-dEspagnac (France)",
  "LIsle-sur-le-Doubs (France)",
  "Lisle-sur-Tarn (France)",
  "Lisors (France)",
  "Lisses (France)",
  "Lissieu (France)",
  "Lissy (France)",
  "Lit-et-Mixe (France)",
  "Livarot (France)",
  "Liverdun (France)",
  "Liverdy-en-Brie (France)",
  "Livernon (France)",
  "Livet-et-Gavet (France)",
  "Livet-sur-Authou (France)",
  "Livinhac-le-Haut (France)",
  "Livre-sur-Changeon (France)",
  "Livron (France)",
  "Livron-sur-Drome (France)",
  "Livry-Gargan (France)",
  "Livry-sur-Seine (France)",
  "Lixhausen (France)",
  "Lixheim (France)",
  "Lizac (France)",
  "Lizy-sur-Ourcq (France)",
  "Llupia (France)",
  "Loche (France)",
  "Loche-sur-Indrois (France)",
  "Locmaria-Grand-Champ (France)",
  "Locmaria-Plouzane (France)",
  "Locmariaquer (France)",
  "Locmine (France)",
  "Locmiquelic (France)",
  "Locoal-Mendon (France)",
  "Locon (France)",
  "Locquemeau (France)",
  "Locquirec (France)",
  "Loctudy (France)",
  "Loge-Fougereuse (France)",
  "Logelbach (France)",
  "Logelheim (France)",
  "Lognes (France)",
  "Logonna-Daoulas (France)",
  "Logonna-Quimerch (France)",
  "Logrian-Florian (France)",
  "Lohr (France)",
  "Loire (France)",
  "Loire-les-Marais (France)",
  "Loire-sur-Rhone (France)",
  "Loiron (France)",
  "Loisin (France)",
  "Loison-sous-Lens (France)",
  "Loisy (France)",
  "Loisy-sur-Marne (France)",
  "Loix (France)",
  "Lokmaria (France)",
  "Lombers (France)",
  "Lombez (France)",
  "Lombron (France)",
  "Lomme (France)",
  "Lommerange (France)",
  "Lompret (France)",
  "Londigny (France)",
  "Londinieres (France)",
  "Longages (France)",
  "Longchamp (France)",
  "Longchamp-sous-Chatenois (France)",
  "Longeault (France)",
  "Longecourt-en-Plaine (France)",
  "Longes (France)",
  "Longessaigne (France)",
  "Longevelle-sur-Doubs (France)",
  "Longeves (France)",
  "Longeville-en-Barrois (France)",
  "Longeville-les-Metz (France)",
  "Longeville-les-Saint-Avold (France)",
  "Longeville-sur-Mer (France)",
  "Longfosse (France)",
  "Longjumeau (France)",
  "Longlaville (France)",
  "Longnes (France)",
  "Longpont-sur-Orge (France)",
  "Longpre-les-Corps-Saints (France)",
  "Longue-Jumelles (France)",
  "Longueau (France)",
  "Longueil-Annel (France)",
  "Longueil-Sainte-Marie (France)",
  "Longuenesse (France)",
  "Longueville (France)",
  "Longueville-sur-Scie (France)",
  "Longuyon (France)",
  "Longvic (France)",
  "Longwy (France)",
  "Lons (France)",
  "Lons-le-Saunier (France)",
  "Loon-Plage (France)",
  "Loos (France)",
  "Loos-en-Gohelle (France)",
  "Loperhet (France)",
  "Lorcieres (France)",
  "Lorette (France)",
  "Lorey (France)",
  "Lorgies (France)",
  "Lorgues (France)",
  "Lorient (France)",
  "Lorignac (France)",
  "Loriol-du-Comtat (France)",
  "Loriol-sur-Drome (France)",
  "Lorlanges (France)",
  "Lormaison (France)",
  "Lormaye (France)",
  "Lormont (France)",
  "Lorquin (France)",
  "Lorrez-le-Bocage-Preaux (France)",
  "Lorris (France)",
  "Lorry-Mardigny (France)",
  "Los Masos (France)",
  "Losne (France)",
  "Louailles (France)",
  "Louannec (France)",
  "Louargat (France)",
  "Louatre (France)",
  "Loubaresse (France)",
  "Loubes-Bernac (France)",
  "Loubeyrat (France)",
  "Loubieng (France)",
  "Loubigne (France)",
  "Loubille (France)",
  "Loudeac (France)",
  "Loudes (France)",
  "Loudrefing (France)",
  "Loudun (France)",
  "Loue (France)",
  "Louer (France)",
  "Lougres (France)",
  "Louhans (France)",
  "Loupershouse (France)",
  "Loupes (France)",
  "Loupiac-de-la-Reole (France)",
  "Loupian (France)",
  "Louplande (France)",
  "Lourches (France)",
  "Lourdes (France)",
  "Lourenties (France)",
  "Loures-Barousse (France)",
  "Louresse-Rochemenier (France)",
  "Lourmarin (France)",
  "Lourties-Monbrun (France)",
  "Loury (France)",
  "Louveciennes (France)",
  "Louvemont (France)",
  "Louverne (France)",
  "Louvie-Juzon (France)",
  "Louviers (France)",
  "Louvigne-de-Bais (France)",
  "Louvignies-Quesnoy (France)",
  "Louvigny (France)",
  "Louvil (France)",
  "Louvrechy (France)",
  "Louvres (France)",
  "Louvroil (France)",
  "Louzouer (France)",
  "Louzy (France)",
  "Lovagny (France)",
  "Loyat (France)",
  "Loyes (France)",
  "Loyettes (France)",
  "Lozanne (France)",
  "Lozinghem (France)",
  "Lozon (France)",
  "Luant (France)",
  "Lubersac (France)",
  "Luc (France)",
  "Luc-sur-Mer (France)",
  "Luc-sur-Orbieu (France)",
  "Lucciana (France)",
  "Luce (France)",
  "Luceau (France)",
  "Lucenay (France)",
  "Lucey (France)",
  "Luchy (France)",
  "Lucon (France)",
  "Lucq-de-Bearn (France)",
  "Lucy-sur-Cure (France)",
  "Ludon-Medoc (France)",
  "Ludres (France)",
  "Luemschwiller (France)",
  "Lugny-Champagne (France)",
  "Lugny-les-Charolles (France)",
  "Lugrin (France)",
  "Luisant (France)",
  "Luitre (France)",
  "Lullin (France)",
  "Lully (France)",
  "Lumbin (France)",
  "Lumbres (France)",
  "Lumeau (France)",
  "Lumio (France)",
  "Lunel (France)",
  "Lunel-Viel (France)",
  "Luneray (France)",
  "Lupe (France)",
  "Luppy (France)",
  "Lupstein (France)",
  "Luquet (France)",
  "Lurais (France)",
  "Luray (France)",
  "Lurcy (France)",
  "Lurcy-Levis (France)",
  "Lure (France)",
  "Lury-sur-Arnon (France)",
  "Lus-la-Croix-Haute (France)",
  "Lusanger (France)",
  "Lusignan (France)",
  "Lusignan-Grand (France)",
  "Lusigny (France)",
  "Lusigny-sur-Barse (France)",
  "Lussac (France)",
  "Lussac-les-Chateaux (France)",
  "Lussac-les-Eglises (France)",
  "Lussant (France)",
  "Lussas (France)",
  "Lussat (France)",
  "Luttange (France)",
  "Lutter (France)",
  "Lutterbach (France)",
  "Lutz-en-Dunois (France)",
  "Lux (France)",
  "Luxe (France)",
  "Luxeuil-les-Bains (France)",
  "Luxey (France)",
  "Luynes (France)",
  "Luz-Saint-Sauveur (France)",
  "Luzarches (France)",
  "Luze (France)",
  "Luzille (France)",
  "Luzinay (France)",
  "Luzy (France)",
  "Lyaud (France)",
  "Lynde (France)",
  "Lyon (France)",
  "Mably (France)",
  "Macau (France)",
  "Mache (France)",
  "Machecoul (France)",
  "Machemont (France)",
  "Macheren (France)",
  "Machezal (France)",
  "Maclas (France)",
  "Macot-la-Plagne (France)",
  "Madirac (France)",
  "Madre (France)",
  "Madriat (France)",
  "Mael-Carhaix (France)",
  "Maennolsheim (France)",
  "Maffliers (France)",
  "Magagnosc (France)",
  "Magalas (France)",
  "Magenta (France)",
  "Magescq (France)",
  "Magland (France)",
  "Magnan (France)",
  "Magnanville (France)",
  "Magne (France)",
  "Magneux-Haute-Rive (France)",
  "Magnieres (France)",
  "Magny (France)",
  "Magny-Cours (France)",
  "Magny-en-Vexin (France)",
  "Magny-le-Desert (France)",
  "Magny-le-Hongre (France)",
  "Magny-les-Hameaux (France)",
  "Magny-les-Villers (France)",
  "Magny-sur-Tille (France)",
  "Magrie (France)",
  "Magstatt-le-Haut (France)",
  "Maiche (France)",
  "Maidieres (France)",
  "Maigne (France)",
  "Maignelay-Montigny (France)",
  "Maillane (France)",
  "Maille (France)",
  "Maillebois (France)",
  "Mailly-Champagne (France)",
  "Mailly-la-Ville (France)",
  "Mailly-le-Camp (France)",
  "Mailly-Maillet (France)",
  "Mailly-Raineval (France)",
  "Maincy (France)",
  "Maing (France)",
  "Maintenon (France)",
  "Mainvilliers (France)",
  "Mainxe (France)",
  "Mairieux (France)",
  "Mairy-sur-Marne (France)",
  "Maisdon-sur-Sevre (France)",
  "Maisnil (France)",
  "Maisnil-les-Ruitz (France)",
  "Maison-Ponthieu (France)",
  "Maisons-Alfort (France)",
  "Maisons-Laffitte (France)",
  "Maisonsgoutte (France)",
  "Maisontiers (France)",
  "Maisse (France)",
  "Maizeroy (France)",
  "Maizieres-les-Metz (France)",
  "Malabry (France)",
  "Malafretaz (France)",
  "Malain (France)",
  "Malakoff (France)",
  "Malancourt-la-Montagne (France)",
  "Malange (France)",
  "Malansac (France)",
  "Malataverne (France)",
  "Malaucene (France)",
  "Malaumont (France)",
  "Malaunay (France)",
  "Malause (France)",
  "Malaussanne (France)",
  "Malay-le-Grand (France)",
  "Malegoude (France)",
  "Malemort-du-Comtat (France)",
  "Malemort-sur-Correze (France)",
  "Malesherbes (France)",
  "Malestroit (France)",
  "Maleville (France)",
  "Malguenac (France)",
  "Malicorne (France)",
  "Malicorne-sur-Sarthe (France)",
  "Malijai (France)",
  "Malincourt (France)",
  "Malintrat (France)",
  "Malissard (France)",
  "Malleloy (France)",
  "Mallemoisson (France)",
  "Mallemort (France)",
  "Malling (France)",
  "Malmerspach (France)",
  "Malmont (France)",
  "Malpas (France)",
  "Malras (France)",
  "Malrevers (France)",
  "Malves-en-Minervois (France)",
  "Malville (France)",
  "Malzeville (France)",
  "Mamers (France)",
  "Mametz (France)",
  "Mamey (France)",
  "Mamirolle (France)",
  "Manchecourt (France)",
  "Mancieulles (France)",
  "Mandagout (France)",
  "Mandelieu-la-Napoule (France)",
  "Mandeure (France)",
  "Mandres-la-Cote (France)",
  "Mandres-les-Roses (France)",
  "Manduel (France)",
  "Mane (France)",
  "Maneglise (France)",
  "Mangiennes (France)",
  "Mangonville (France)",
  "Manicamp (France)",
  "Maninghen-Henne (France)",
  "Manneville-es-Plains (France)",
  "Mannevillette (France)",
  "Manom (France)",
  "Manoncourt-sur-Seille (France)",
  "Manonviller (France)",
  "Manosque (France)",
  "Manot (France)",
  "Mansigne (France)",
  "Mansle (France)",
  "Manspach (France)",
  "Mantes-la-Jolie (France)",
  "Mantes-la-Ville (France)",
  "Manthelan (France)",
  "Mantoche (France)",
  "Mantry (France)",
  "Manzat (France)",
  "Manziat (France)",
  "Marange-Silvange (France)",
  "Marange-Zondrange (France)",
  "Marans (France)",
  "Maraussan (France)",
  "Marbache (France)",
  "Marc (France)",
  "Marcamps (France)",
  "Marcay (France)",
  "Marce-sur-Esves (France)",
  "Marcelcave (France)",
  "Marcellaz-Albanais (France)",
  "Marchainville (France)",
  "Marchaux (France)",
  "Marche-Allouarde (France)",
  "Marcheprime (France)",
  "Marchesieux (France)",
  "Marcheville (France)",
  "Marchiennes (France)",
  "Marciac (France)",
  "Marcigny (France)",
  "Marcilhac-sur-Cele (France)",
  "Marcillac (France)",
  "Marcillac-la-Croisille (France)",
  "Marcillat-en-Combraille (France)",
  "Marcilloles (France)",
  "Marcilly-en-Bassigny (France)",
  "Marcilly-en-Beauce (France)",
  "Marcilly-en-Villette (France)",
  "Marcilly-sur-Eure (France)",
  "Marcilly-sur-Seine (France)",
  "Marcilly-sur-Tille (France)",
  "Marcilly-sur-Vienne (France)",
  "Marck (France)",
  "Marckolsheim (France)",
  "Marcoing (France)",
  "Marcollin (France)",
  "Marconnelle (France)",
  "Marcoussis (France)",
  "Marcouville (France)",
  "Marcoux (France)",
  "Marcq (France)",
  "Marcq-en-Baroeul (France)",
  "Marcq-en-Ostrevent (France)",
  "Mardeuil (France)",
  "Mardie (France)",
  "Mardyck (France)",
  "Mareau-aux-Bois (France)",
  "Mareau-aux-Pres (France)",
  "Mareil-en-France (France)",
  "Mareil-Marly (France)",
  "Mareil-sur-Loir (France)",
  "Mareil-sur-Mauldre (France)",
  "Marenla (France)",
  "Marennes (France)",
  "Marest (France)",
  "Marestaing (France)",
  "Mareuil (France)",
  "Mareuil-Caubert (France)",
  "Mareuil-en-Brie (France)",
  "Mareuil-le-Port (France)",
  "Mareuil-les-Meaux (France)",
  "Mareuil-sur-Cher (France)",
  "Mareuil-sur-Lay-Dissais (France)",
  "Mareuil-sur-Ourcq (France)",
  "Margaux (France)",
  "Margencel (France)",
  "Margency (France)",
  "Margerie-Chantagret (France)",
  "Margny (France)",
  "Margny-les-Compiegne (France)",
  "Margon (France)",
  "Marguerittes (France)",
  "Margut (France)",
  "Marie (France)",
  "Marienthal (France)",
  "Marignac (France)",
  "Marignac-Lasclares (France)",
  "Marignane (France)",
  "Marigne (France)",
  "Marignier (France)",
  "Marigny (France)",
  "Marigny-Brizay (France)",
  "Marigny-Chemereau (France)",
  "Marigny-en-Orxois (France)",
  "Marigny-le-Chatel (France)",
  "Marigny-les-Usages (France)",
  "Marigny-Marmande (France)",
  "Marin (France)",
  "Marines (France)",
  "Maringes (France)",
  "Maringues (France)",
  "Marle (France)",
  "Marlenheim (France)",
  "Marles-en-Brie (France)",
  "Marles-les-Mines (France)",
  "Marliens (France)",
  "Marlioz (France)",
  "Marlotte (France)",
  "Marly (France)",
  "Marly-la-Ville (France)",
  "Marly-le-Roi (France)",
  "Marmagne (France)",
  "Marmande (France)",
  "Marmanhac (France)",
  "Marmoutier (France)",
  "Marnand (France)",
  "Marnaz (France)",
  "Marnes (France)",
  "Marnes-la-Coquette (France)",
  "Maroeuil (France)",
  "Marolles (France)",
  "Marolles-en-Brie (France)",
  "Marolles-en-Hurepoix (France)",
  "Marols (France)",
  "Maromme (France)",
  "Maron (France)",
  "Marpent (France)",
  "Marquay (France)",
  "Marquefave (France)",
  "Marqueglise (France)",
  "Marques (France)",
  "Marquillies (France)",
  "Marquion (France)",
  "Marquise (France)",
  "Marquixanes (France)",
  "Mars (France)",
  "Mars-sur-Allier (France)",
  "Marsac (France)",
  "Marsac-en-Livradois (France)",
  "Marsac-sur-lIsle (France)",
  "Marsainvilliers (France)",
  "Marsais (France)",
  "Marsais-Sainte-Radegonde (France)",
  "Marsaneix (France)",
  "Marsangy (France)",
  "Marsannay-le-Bois (France)",
  "Marsanne (France)",
  "Marsat (France)",
  "Marsaz (France)",
  "Marseillan (France)",
  "Marseille (France)",
  "Marseille-en-Beauvaisis (France)",
  "Marseilles-les-Aubigny (France)",
  "Marsillargues (France)",
  "Marsilly (France)",
  "Marssac-sur-Tarn (France)",
  "Martainville-Epreville (France)",
  "Martel (France)",
  "Marthod (France)",
  "Martiel (France)",
  "Martignas-sur-Jalle (France)",
  "Martigne-Briand (France)",
  "Martigne-Ferchaud (France)",
  "Martigny (France)",
  "Martigues (France)",
  "Martillac (France)",
  "Martin-Eglise (France)",
  "Martinet (France)",
  "Martinvast (France)",
  "Martot (France)",
  "Martres-Tolosane (France)",
  "Marval (France)",
  "Marvejols (France)",
  "Marville-les-Bois (France)",
  "Marzan (France)",
  "Marzy (France)",
  "Mas-Grenier (France)",
  "Mas-Saintes-Puelles (France)",
  "Masevaux (France)",
  "Maslacq (France)",
  "Maslives (France)",
  "Masnieres (France)",
  "Masny (France)",
  "Masparraute (France)",
  "Massat (France)",
  "Masserac (France)",
  "Masseret (France)",
  "Massiac (France)",
  "Massieu (France)",
  "Massieux (France)",
  "Massingy-les-Semur (France)",
  "Massugas (France)",
  "Massy (France)",
  "Mastaing (France)",
  "Matha (France)",
  "Mathay (France)",
  "Mathenay (France)",
  "Mathieu (France)",
  "Matignon (France)",
  "Matigny (France)",
  "Matour (France)",
  "Mattaincourt (France)",
  "Mattexey (France)",
  "Matzenheim (France)",
  "Maubeuge (France)",
  "Maubourguet (France)",
  "Mauchamps (France)",
  "Maucor (France)",
  "Mauguio (France)",
  "Maule (France)",
  "Mauleon-Licharre (France)",
  "Maulevrier (France)",
  "Mauperthuis (France)",
  "Mauprevoir (France)",
  "Mauran (France)",
  "Maure (France)",
  "Maure-de-Bretagne (France)",
  "Maurecourt (France)",
  "Maureilhan (France)",
  "Maureillas-las-Illas (France)",
  "Mauremont (France)",
  "Maurens (France)",
  "Maurepas (France)",
  "Mauressargues (France)",
  "Maureville (France)",
  "Mauriac (France)",
  "Maurois (France)",
  "Mauron (France)",
  "Maurrin (France)",
  "Maurs (France)",
  "Maussane-les-Alpilles (France)",
  "Mauves (France)",
  "Mauves-sur-Huisne (France)",
  "Mauves-sur-Loire (France)",
  "Mauvezin-de-Prat (France)",
  "Maux (France)",
  "Mauzac (France)",
  "Mauze-sur-le-Mignon (France)",
  "Mauzens-et-Miremont (France)",
  "Maxent (France)",
  "Maxeville (France)",
  "Maxilly-sur-Saone (France)",
  "May-en-Multien (France)",
  "May-sur-Orne (France)",
  "Mayenne (France)",
  "Mayet (France)",
  "Maysel (France)",
  "Mazamet (France)",
  "Mazan (France)",
  "Mazange (France)",
  "Maze (France)",
  "Mazeray (France)",
  "Mazeres-de-Neste (France)",
  "Mazeres-sur-Salat (France)",
  "Mazerolles (France)",
  "Mazerolles-le-Salin (France)",
  "Mazet-Saint-Voy (France)",
  "Mazeuil (France)",
  "Mazieres-en-Gatine (France)",
  "Mazieres-en-Mauges (France)",
  "Mazingarbe (France)",
  "Mazion (France)",
  "Meaudre (France)",
  "Meaulte (France)",
  "Meaux (France)",
  "Meauzac (France)",
  "Medan (France)",
  "Medis (France)",
  "Medreac (France)",
  "Mees (France)",
  "Megrit (France)",
  "Meharicourt (France)",
  "Mehun-sur-Yevre (France)",
  "Meigneux (France)",
  "Meilhac (France)",
  "Meilhan (France)",
  "Meilhan-sur-Garonne (France)",
  "Meillant (France)",
  "Meillerie (France)",
  "Meillon (France)",
  "Meillonnas (France)",
  "Meisenthal (France)",
  "Mejannes-le-Clap (France)",
  "Mejannes-les-Ales (France)",
  "Melay (France)",
  "Melesse (France)",
  "Melgven (France)",
  "Mellac (France)",
  "Melle (France)",
  "Mellecey (France)",
  "Melleran (France)",
  "Melleray (France)",
  "Mellionnec (France)",
  "Mello (France)",
  "Melrand (France)",
  "Melsheim (France)",
  "Melun (France)",
  "Membrey (France)",
  "Menat (France)",
  "Mende (France)",
  "Mendionde (France)",
  "Meneac (France)",
  "Menerbes (France)",
  "Menesplet (France)",
  "Menestreau-en-Villette (France)",
  "Menetou-Salon (France)",
  "Menetreol-sur-Sauldre (France)",
  "Menetrol (France)",
  "Menil-Erreux (France)",
  "Menil-Hermei (France)",
  "Menil-la-Horgne (France)",
  "Menil-sur-Belvitte (France)",
  "Menilles (France)",
  "Mennecy (France)",
  "Mennevret (France)",
  "Mens (France)",
  "Mensignac (France)",
  "Menthon-Saint-Bernard (France)",
  "Menton (France)",
  "Menucourt (France)",
  "Menville (France)",
  "Meounes-les-Montrieux (France)",
  "Mer (France)",
  "Mercenac (France)",
  "Merckeghem (France)",
  "Mercues (France)",
  "Mercurol (France)",
  "Mercy-le-Bas (France)",
  "Merdrignac (France)",
  "Mere (France)",
  "Mereau (France)",
  "Merenvielle (France)",
  "Mereville (France)",
  "Merey-sous-Montrond (France)",
  "Mergey (France)",
  "Mericourt (France)",
  "Meriel (France)",
  "Merignac (France)",
  "Merignas (France)",
  "Merignat (France)",
  "Merignies (France)",
  "Merigny (France)",
  "Merindol (France)",
  "Merlas (France)",
  "Merlebach (France)",
  "Merlevenez (France)",
  "Merlimont (France)",
  "Merlines (France)",
  "Mernel (France)",
  "Meroux (France)",
  "Merry-la-Vallee (France)",
  "Merry-sur-Yonne (France)",
  "Mers-les-Bains (France)",
  "Mertzen (France)",
  "Mertzwiller (France)",
  "Meru (France)",
  "Mervans (France)",
  "Merville (France)",
  "Merville-Franceville-Plage (France)",
  "Merxheim (France)",
  "Mery (France)",
  "Mery-es-Bois (France)",
  "Mery-sur-Oise (France)",
  "Mery-sur-Seine (France)",
  "Mesanger (France)",
  "Mesangueville (France)",
  "Meschers-sur-Gironde (France)",
  "Mescoules (France)",
  "Mesland (France)",
  "Meslay (France)",
  "Meslay-du-Maine (France)",
  "Meslay-le-Vidame (France)",
  "Meslin (France)",
  "Mesnil-Raoul (France)",
  "Mesnil-Saint-Georges (France)",
  "Mesnil-Saint-Nicaise (France)",
  "Mesnil-Sellieres (France)",
  "Mesnil-sous-Vienne (France)",
  "Mesplede (France)",
  "Messac (France)",
  "Messanges (France)",
  "Messei (France)",
  "Messein (France)",
  "Messia-sur-Sorne (France)",
  "Messigny-et-Vantoux (France)",
  "Messimy (France)",
  "Messon (France)",
  "Messy (France)",
  "Metabief (France)",
  "Meteren (France)",
  "Mettray (France)",
  "Metz (France)",
  "Metz-Tessy (France)",
  "Metzeral (France)",
  "Metzeresche (France)",
  "Metzervisse (France)",
  "Meucon (France)",
  "Meudon (France)",
  "Meulan-en-Yvelines (France)",
  "Meung-sur-Loire (France)",
  "Meurchin (France)",
  "Meursault (France)",
  "Meuse (France)",
  "Meusnes (France)",
  "Meuzac (France)",
  "Mevoisins (France)",
  "Meximieux (France)",
  "Mexy (France)",
  "Meyenheim (France)",
  "Meylan (France)",
  "Meymac (France)",
  "Meynes (France)",
  "Meyrargues (France)",
  "Meyras (France)",
  "Meyreuil (France)",
  "Meyssac (France)",
  "Meythet (France)",
  "Meyzieu (France)",
  "Meze (France)",
  "Mezel (France)",
  "Mezeres (France)",
  "Mezeriat (France)",
  "Mezidon-Canon (France)",
  "Mezieres-en-Drouais (France)",
  "Mezieres-lez-Clery (France)",
  "Mezieres-sous-Lavardin (France)",
  "Mezieres-sur-Couesnon (France)",
  "Mezieres-sur-Seine (France)",
  "Mezzavia (France)",
  "Michelbach-le-Bas (France)",
  "Mielan (France)",
  "Miellin (France)",
  "Mietesheim (France)",
  "Mieussy (France)",
  "Migennes (France)",
  "Miglos (France)",
  "Mignaloux-Beauvoir (France)",
  "Migne-Auxances (France)",
  "Mignieres (France)",
  "Migron (France)",
  "Milhaud (France)",
  "Milizac (France)",
  "Millac (France)",
  "Millas (France)",
  "Millau (France)",
  "Millencourt (France)",
  "Millery (France)",
  "Milly (France)",
  "Milly-la-Foret (France)",
  "Milly-Lamartine (France)",
  "Milly-sur-Therain (France)",
  "Milon-la-Chapelle (France)",
  "Mimbaste (France)",
  "Mimet (France)",
  "Mimizan (France)",
  "Mine de Bert (France)",
  "Mingot (France)",
  "Miniac-Morvan (France)",
  "Miniac-sous-Becherel (France)",
  "Minihy-Treguier (France)",
  "Minversheim (France)",
  "Minzier (France)",
  "Mionnay (France)",
  "Mions (France)",
  "Mios (France)",
  "Mirabel-aux-Baronnies (France)",
  "Miradoux (France)",
  "Miramas (France)",
  "Mirambeau (France)",
  "Miramont-de-Guyenne (France)",
  "Miramont-Sensacq (France)",
  "Mirande (France)",
  "Mirebeau (France)",
  "Mirebeau-sur-Beze (France)",
  "Mirecourt (France)",
  "Miremont (France)",
  "Mirepeix (France)",
  "Mirepoix (France)",
  "Mirepoix-sur-Tarn (France)",
  "Mireval-Lauragais (France)",
  "Miribel (France)",
  "Miserey (France)",
  "Miserey-Salines (France)",
  "Mison (France)",
  "Misse (France)",
  "Missillac (France)",
  "Missiriac (France)",
  "Misson (France)",
  "Missy-sur-Aisne (France)",
  "Mitry-Mory (France)",
  "Mittelbronn (France)",
  "Mittelhausbergen (France)",
  "Mittelwihr (France)",
  "Mitterand (France)",
  "Mittersheim (France)",
  "Modane (France)",
  "Moelan-sur-Mer (France)",
  "Moens (France)",
  "Moernach (France)",
  "Moeslains (France)",
  "Mogneville (France)",
  "Mogues (France)",
  "Mohon (France)",
  "Moineville (France)",
  "Moinville-la-Jeulin (France)",
  "Moirans (France)",
  "Moirans-en-Montagne (France)",
  "Moire (France)",
  "Moisdon-la-Riviere (France)",
  "Moislains (France)",
  "Moissac (France)",
  "Moissat (France)",
  "Moisselles (France)",
  "Moissieu-sur-Dolon (France)",
  "Moissy-Cramayel (France)",
  "Moisville (France)",
  "Molac (France)",
  "Molandier (France)",
  "Molesme (France)",
  "Moliens (France)",
  "Molieres-sur-Ceze (France)",
  "Moliets-et-Maa (France)",
  "Molinet (France)",
  "Molineuf (France)",
  "Molleges (France)",
  "Molliens-au-Bois (France)",
  "Mollkirch (France)",
  "Moloy (France)",
  "Molsheim (France)",
  "Moltifao (France)",
  "Momas (France)",
  "Mombrier (France)",
  "Momeres (France)",
  "Mommenheim (France)",
  "Momy (France)",
  "Monbahus (France)",
  "Monbalen (France)",
  "Monbrun (France)",
  "Moncale (France)",
  "Monce-en-Belin (France)",
  "Monceau-le-Neuf-et-Faucouzy (France)",
  "Monceau-le-Waast (France)",
  "Monceau-Saint-Waast (France)",
  "Monceaux (France)",
  "Moncel-sur-Seille (France)",
  "Moncetz-Longevas (France)",
  "Moncheaux-les-Frevent (France)",
  "Monchecourt (France)",
  "Monchy-Breton (France)",
  "Monchy-Humieres (France)",
  "Monchy-Saint-Eloi (France)",
  "Monclar-de-Quercy (France)",
  "Moncontour (France)",
  "Moncoutant (France)",
  "Mondelange (France)",
  "Mondescourt (France)",
  "Mondetour (France)",
  "Mondeville (France)",
  "Mondon (France)",
  "Mondonville (France)",
  "Mondragon (France)",
  "Mondrainville (France)",
  "Mondrepuis (France)",
  "Monein (France)",
  "Monestier-de-Clermont (France)",
  "Monesties (France)",
  "Monetay-sur-Loire (France)",
  "Moneteau (France)",
  "Monferran-Plaves (France)",
  "Monferran-Saves (France)",
  "Monflanquin (France)",
  "Monistrol-sur-Loire (France)",
  "Monnaie (France)",
  "Monnetier-Mornex (France)",
  "Monneville (France)",
  "Monnieres (France)",
  "Monpazier (France)",
  "Mons (France)",
  "Mons-en-Baroeul (France)",
  "Mons-en-Montois (France)",
  "Mons-en-Pevele (France)",
  "Monsac (France)",
  "Monsegur (France)",
  "Monsempron-Libos (France)",
  "Monsireigne (France)",
  "Monsteroux-Milieu (France)",
  "Mont (France)",
  "Mont-Bonvillers (France)",
  "Mont-Cauvaire (France)",
  "Mont-de-Marsan (France)",
  "Mont-Notre-Dame (France)",
  "Mont-pres-Chambord (France)",
  "Mont-Saint-Aignan (France)",
  "Mont-Saint-Martin (France)",
  "Mont-Saint-Pere (France)",
  "Mont-Saxonnex (France)",
  "Mont-sur-Meurthe (France)",
  "Mont-sur-Monnet (France)",
  "Montabard (France)",
  "Montady (France)",
  "Montagnac (France)",
  "Montagnat (France)",
  "Montagne (France)",
  "Montagney (France)",
  "Montagnieu (France)",
  "Montagnole (France)",
  "Montagny-en-Vexin (France)",
  "Montagny-les-Lanches (France)",
  "Montagny-les-Seurre (France)",
  "Montagny-Sainte-Felicite (France)",
  "Montagny-sur-Grosne (France)",
  "Montagrier (France)",
  "Montagut (France)",
  "Montaigu (France)",
  "Montaigu-de-Quercy (France)",
  "Montaigut-sur-Save (France)",
  "Montainville (France)",
  "Montalet-le-Bois (France)",
  "Montalieu-Vercieu (France)",
  "Montamise (France)",
  "Montanay (France)",
  "Montardon (France)",
  "Montargis (France)",
  "Montarlot-les-Rioz (France)",
  "Montarnaud (France)",
  "Montastruc-de-Salies (France)",
  "Montastruc-la-Conseillere (France)",
  "Montataire (France)",
  "Montauban (France)",
  "Montauban-de-Bretagne (France)",
  "Montaud (France)",
  "Montaudin (France)",
  "Montaulieu (France)",
  "Montaure (France)",
  "Montauroux (France)",
  "Montaut (France)",
  "Montayral (France)",
  "Montbard (France)",
  "Montbartier (France)",
  "Montbazin (France)",
  "Montbazon (France)",
  "Montbel (France)",
  "Montbellet (France)",
  "Montberon (France)",
  "Montbert (France)",
  "Montbeton (France)",
  "Montbeugny (France)",
  "Montbizot (France)",
  "Montblanc (France)",
  "Montboillon (France)",
  "Montbonnot-Saint-Martin (France)",
  "Montboucher-sur-Jabron (France)",
  "Montbouton (France)",
  "Montbrison (France)",
  "Montbron (France)",
  "Montbronn (France)",
  "Montbrun-Bocage (France)",
  "Montcaret (France)",
  "Montcarra (France)",
  "Montceau (France)",
  "Montceau-les-Mines (France)",
  "Montceaux (France)",
  "Montceaux-les-Meaux (France)",
  "Montcel (France)",
  "Montcenis (France)",
  "Montcey (France)",
  "Montchamp (France)",
  "Montchanin (France)",
  "Montchaude (France)",
  "Montclar (France)",
  "Montcornet (France)",
  "Montcourt-Fromonville (France)",
  "Montcoy (France)",
  "Montcuq (France)",
  "Montcy-Notre-Dame (France)",
  "Montdidier (France)",
  "Montdoumerc (France)",
  "Montdragon (France)",
  "Monteaux (France)",
  "Montebourg (France)",
  "Montech (France)",
  "Montecheroux (France)",
  "Monteglin (France)",
  "Monteils (France)",
  "Montel-de-Gelat (France)",
  "Montelier (France)",
  "Montemboeuf (France)",
  "Montendre (France)",
  "Montenescourt (France)",
  "Montenois (France)",
  "Montenoison (France)",
  "Monterblanc (France)",
  "Montereau-Fault-Yonne (France)",
  "Monterfil (France)",
  "Montescot (France)",
  "Montesquieu (France)",
  "Montesquieu-des-Alberes (France)",
  "Montesquieu-Volvestre (France)",
  "Montessaux (France)",
  "Montesson (France)",
  "Montestruc-sur-Gers (France)",
  "Montestrucq (France)",
  "Monteux (France)",
  "Montevrain (France)",
  "Monteynard (France)",
  "Montfarville (France)",
  "Montfaucon (France)",
  "Montfaucon-en-Velay (France)",
  "Montfaucon-Montigne (France)",
  "Montfavet (France)",
  "Montfermeil (France)",
  "Montfermy (France)",
  "Montferrand-du-Perigord (France)",
  "Montferrand-le-Chateau (France)",
  "Montferrat (France)",
  "Montferrier-sur-Lez (France)",
  "Montfleur (France)",
  "Montfort (France)",
  "Montfort-en-Chalosse (France)",
  "Montfort-sur-Meu (France)",
  "Montfrin (France)",
  "Montfroc (France)",
  "Montfuron (France)",
  "Montgaillard (France)",
  "Montgaroult (France)",
  "Montgermont (France)",
  "Montgeron (France)",
  "Montgesty (France)",
  "Montgirod (France)",
  "Montgiscard (France)",
  "Montgivray (France)",
  "Montherme (France)",
  "Monthieux (France)",
  "Monthodon (France)",
  "Monthou-sur-Bievre (France)",
  "Monthou-sur-Cher (France)",
  "Monthureux-sur-Saone (France)",
  "Monthyon (France)",
  "Monticello (France)",
  "Montier-en-Der (France)",
  "Montierchaume (France)",
  "Montiers (France)",
  "Montignac-de-Lauzun (France)",
  "Montignac-le-Coq (France)",
  "Montigne-le-Brillant (France)",
  "Montigne-les-Rairies (France)",
  "Montigny (France)",
  "Montigny-en-Cambresis (France)",
  "Montigny-en-Gohelle (France)",
  "Montigny-le-Bretonneux (France)",
  "Montigny-le-Chartif (France)",
  "Montigny-Lengrain (France)",
  "Montigny-les-Cormeilles (France)",
  "Montigny-sur-Loing (France)",
  "Montilly (France)",
  "Montilly-sur-Noireau (France)",
  "Montiron (France)",
  "Montivilliers (France)",
  "Montjavoult (France)",
  "Montjean-sur-Loire (France)",
  "Montlhery (France)",
  "Montliard (France)",
  "Montlieu-la-Garde (France)",
  "Montlignon (France)",
  "Montlivault (France)",
  "Montlouis (France)",
  "Montlouis-sur-Loire (France)",
  "Montluel (France)",
  "Montmacq (France)",
  "Montmagny (France)",
  "Montmalin (France)",
  "Montmarault (France)",
  "Montmartin (France)",
  "Montmartin-en-Graignes (France)",
  "Montmartin-sur-Mer (France)",
  "Montmelian (France)",
  "Montmerle-sur-Saone (France)",
  "Montmeyran (France)",
  "Montmirail (France)",
  "Montmiral (France)",
  "Montmirat (France)",
  "Montmoreau-Saint-Cybard (France)",
  "Montmorency (France)",
  "Montmorency-Beaufort (France)",
  "Montmorillon (France)",
  "Montmorot (France)",
  "Montoir-de-Bretagne (France)",
  "Montoire-sur-le-Loir (France)",
  "Montois-la-Montagne (France)",
  "Montoison (France)",
  "Montolivet (France)",
  "Montournais (France)",
  "Montpellier (France)",
  "Montpeyroux (France)",
  "Montpezat (France)",
  "Montpezat-de-Quercy (France)",
  "Montpezat-sous-Bauzon (France)",
  "Montpincon (France)",
  "Montpinier (France)",
  "Montpitol (France)",
  "Montpon-Menesterol (France)",
  "Montpothier (France)",
  "Montrabe (France)",
  "Montreal (France)",
  "Montreal-la-Cluse (France)",
  "Montredon-des-Corbieres (France)",
  "Montredon-Labessonnie (France)",
  "Montrejeau (France)",
  "Montrelais (France)",
  "Montrem (France)",
  "Montrequienne (France)",
  "Montresor (France)",
  "Montret (France)",
  "Montreuil (France)",
  "Montreuil-aux-Lions (France)",
  "Montreuil-Bonnin (France)",
  "Montreuil-en-Caux (France)",
  "Montreuil-sous-Perouse (France)",
  "Montreuil-sur-Blaise (France)",
  "Montreuil-sur-Breche (France)",
  "Montreuil-sur-Lozon (France)",
  "Montreuil-sur-Mer (France)",
  "Montreux-Jeune (France)",
  "Montrevel (France)",
  "Montrevel-en-Bresse (France)",
  "Montrichard (France)",
  "Montricoux (France)",
  "Montrodat (France)",
  "Montrond (France)",
  "Montrond-les-Bains (France)",
  "Montrouge (France)",
  "Montrouveau (France)",
  "Montry (France)",
  "Monts (France)",
  "Monts-en-Bessin (France)",
  "Monts-sur-Guesnes (France)",
  "Montsalvy (France)",
  "Montsauche-les-Settons (France)",
  "Montsegur-sur-Lauzon (France)",
  "Montseveroux (France)",
  "Montsoreau (France)",
  "Montsoult (France)",
  "Montussan (France)",
  "Montvalen (France)",
  "Montvalent (France)",
  "Montvendre (France)",
  "Montverdun (France)",
  "Montville (France)",
  "Moon-sur-Elle (France)",
  "Moosch (France)",
  "Moragne (France)",
  "Morainvilliers (France)",
  "Morance (France)",
  "Morancez (France)",
  "Morangis (France)",
  "Morbecque (France)",
  "Morbier (France)",
  "Morcenx (France)",
  "Mordelles (France)",
  "Moreac (France)",
  "Moree (France)",
  "Morelmaison (France)",
  "Morestel (France)",
  "Moret-sur-Loing (France)",
  "Moreuil (France)",
  "Morez (France)",
  "Morgny (France)",
  "Morgny-la-Pommeraye (France)",
  "Morhange (France)",
  "Morienval (France)",
  "Morieres-les-Avignon (France)",
  "Morieux (France)",
  "Morigny-Champigny (France)",
  "Morisel (France)",
  "Moriville (France)",
  "Morlaas (France)",
  "Morlaix (France)",
  "Mormant (France)",
  "Mornac (France)",
  "Mornac-sur-Seudre (France)",
  "Mornant (France)",
  "Mornay (France)",
  "Mornay-sur-Allier (France)",
  "Moroges (France)",
  "Morogues (France)",
  "Morre (France)",
  "Morsain (France)",
  "Morsang-sur-Orge (France)",
  "Morsbach (France)",
  "Morsbronn-les-Bains (France)",
  "Morschwiller-le-Bas (France)",
  "Mortagne (France)",
  "Mortagne-au-Perche (France)",
  "Mortagne-du-Nord (France)",
  "Mortagne-sur-Sevre (France)",
  "Mortain (France)",
  "Mortcerf (France)",
  "Morteau (France)",
  "Morteaux-Couliboeuf (France)",
  "Mortefontaine (France)",
  "Mortefontaine-en-Thelle (France)",
  "Mortree (France)",
  "Mortzwiller (France)",
  "Morvillers-Saint-Saturnin (France)",
  "Morvilliers (France)",
  "Morzine (France)",
  "Moslins (France)",
  "Mosnac (France)",
  "Mosson (France)",
  "Motteville (France)",
  "Mouans-Sartoux (France)",
  "Mouaze (France)",
  "Mouchin (France)",
  "Mouflers (France)",
  "Mougins (France)",
  "Mougon (France)",
  "Mouguerre (France)",
  "Mouilleron-en-Pareds (France)",
  "Mouilleron-le-Captif (France)",
  "Mouledous (France)",
  "Moules (France)",
  "Mouliets-et-Villemartin (France)",
  "Moulin (France)",
  "Moulin-Mage (France)",
  "Moulin-Neuf (France)",
  "Moulin-sous-Touvent (France)",
  "Moulineaux (France)",
  "Moulines (France)",
  "Moulinet (France)",
  "Moulins (France)",
  "Moulins-Engilbert (France)",
  "Moulins-les-Metz (France)",
  "Moulins-sur-Cephons (France)",
  "Moulins-sur-Yevre (France)",
  "Moulle (France)",
  "Moult (France)",
  "Moumour (France)",
  "Mourenx (France)",
  "Moureze (France)",
  "Mouries (France)",
  "Mourioux-Vieilleville (France)",
  "Mourmelon-le-Grand (France)",
  "Mourmelon-le-Petit (France)",
  "Mouroux (France)",
  "Mours (France)",
  "Mours-Saint-Eusebe (France)",
  "Moussan (France)",
  "Mousseaux-Neuville (France)",
  "Moussey (France)",
  "Mousson (France)",
  "Moussoulens (France)",
  "Moussy (France)",
  "Moussy-le-Neuf (France)",
  "Moussy-le-Vieux (France)",
  "Mousteru (France)",
  "Moustier-en-Fagne (France)",
  "Mouthe (France)",
  "Mouthier-en-Bresse (France)",
  "Mouthiers-sur-Boeme (France)",
  "Moutiers (France)",
  "Moutiers-les-Mauxfaits (France)",
  "Moutiers-sur-le-Lay (France)",
  "Mouvaux (France)",
  "Mouxy (France)",
  "Mouy (France)",
  "Mouzeil (France)",
  "Mouzieys-Teulet (France)",
  "Mouzillon (France)",
  "Mouzon (France)",
  "Moyaux (France)",
  "Moyencourt (France)",
  "Moyenmoutier (France)",
  "Moyenneville (France)",
  "Moyeuvre-Grande (France)",
  "Moyon (France)",
  "Moyrazes (France)",
  "Moyvillers (France)",
  "Mozac (France)",
  "Muespach-le-Haut (France)",
  "Muides-sur-Loire (France)",
  "Muidorge (France)",
  "Muirancourt (France)",
  "Muizon (France)",
  "Mulcent (France)",
  "Mulcey (France)",
  "Mulhouse (France)",
  "Mulsanne (France)",
  "Munchhouse (France)",
  "Mundolsheim (France)",
  "Munster (France)",
  "Mur-de-Bretagne (France)",
  "Mur-de-Sologne (France)",
  "Murat (France)",
  "Mures (France)",
  "Muret (France)",
  "Murianette (France)",
  "Muro (France)",
  "Murol (France)",
  "Muron (France)",
  "Murs-Erigne (France)",
  "Murviel-les-Beziers (France)",
  "Murviel-les-Montpellier (France)",
  "Mus (France)",
  "Mussidan (France)",
  "Mussig (France)",
  "Mutzig (France)",
  "Muzillac (France)",
  "Nadillac (France)",
  "Nages-et-Solorgues (France)",
  "Nailloux (France)",
  "Naintre (France)",
  "Naizin (France)",
  "Najac (France)",
  "Nalliers (France)",
  "Nancy (France)",
  "Nandy (France)",
  "Nangis (France)",
  "Nans-les-Pins (France)",
  "Nanterre (France)",
  "Nantes (France)",
  "Nanteuil-en-Vallee (France)",
  "Nanteuil-le-Haudouin (France)",
  "Nanteuil-les-Meaux (France)",
  "Nantiat (France)",
  "Nantoin (France)",
  "Nanton (France)",
  "Nantua (France)",
  "Naours (France)",
  "Narbonne (France)",
  "Narbonne-Plage (France)",
  "Nargis (France)",
  "Narrosse (France)",
  "Nattages (France)",
  "Naucelle (France)",
  "Naujan-et-Postiac (France)",
  "Naux (France)",
  "Navailles-Angos (France)",
  "Navarrenx (France)",
  "Naves (France)",
  "Nay (France)",
  "Nayemont-les-Fosses (France)",
  "Nazelles-Negron (France)",
  "Neauphle-le-Chateau (France)",
  "Neauphlette (France)",
  "Nebian (France)",
  "Nebouzat (France)",
  "Nedonchel (France)",
  "Neewiller-pres-Lauterbourg (France)",
  "Neffes (France)",
  "Neffies (France)",
  "Nefiach (France)",
  "Negrepelisse (France)",
  "Negron (France)",
  "Nehou (France)",
  "Nemours (France)",
  "Neoules (France)",
  "Nercillac (France)",
  "Nere (France)",
  "Neris-les-Bains (France)",
  "Neron (France)",
  "Neronde-sur-Dore (France)",
  "Ners (France)",
  "Nersac (France)",
  "Nerville-la-Foret (France)",
  "Nery (France)",
  "Nesle (France)",
  "Nesles (France)",
  "Nesles-la-Montagne (France)",
  "Nesles-la-Vallee (France)",
  "Nesmy (France)",
  "Nessa (France)",
  "Neuf Eglise (France)",
  "Neuf-Brisach (France)",
  "Neufchateau (France)",
  "Neufchatel-en-Bray (France)",
  "Neufchef (France)",
  "Neufgrange (France)",
  "Neuflize (France)",
  "Neufmanil (France)",
  "Neufmesnil (France)",
  "Neufmoutiers-en-Brie (France)",
  "Neufvy-sur-Aronde (France)",
  "Neuil (France)",
  "Neuille (France)",
  "Neuilly (France)",
  "Neuilly-en-Thelle (France)",
  "Neuilly-le-Bisson (France)",
  "Neuilly-le-Real (France)",
  "Neuilly-les-Dijon (France)",
  "Neuilly-Plaisance (France)",
  "Neuilly-Saint-Front (France)",
  "Neuilly-sous-Clermont (France)",
  "Neuilly-sur-Marne (France)",
  "Neuilly-sur-Seine (France)",
  "Neulliac (France)",
  "Neung-sur-Beuvron (France)",
  "Neure (France)",
  "Neussargues-Moissac (France)",
  "Neuve-Maison (France)",
  "Neuvecelle (France)",
  "Neuves-Maisons (France)",
  "Neuvic (France)",
  "Neuvic-Entier (France)",
  "Neuvicq-le-Chateau (France)",
  "Neuville (France)",
  "Neuville-aux-Bois (France)",
  "Neuville-Bosc (France)",
  "Neuville-de-Poitou (France)",
  "Neuville-en-Ferrain (France)",
  "Neuville-Ferrieres (France)",
  "Neuville-les-Dames (France)",
  "Neuville-les-Dieppe (France)",
  "Neuville-Saint-Remy (France)",
  "Neuville-Saint-Vaast (France)",
  "Neuville-sur-Ain (France)",
  "Neuville-sur-Brenne (France)",
  "Neuville-sur-Escaut (France)",
  "Neuville-sur-Oise (France)",
  "Neuville-sur-Saone (France)",
  "Neuville-sur-Sarthe (France)",
  "Neuville-sur-Seine (France)",
  "Neuviller-sur-Moselle (France)",
  "Neuvireuil (France)",
  "Neuvy (France)",
  "Neuvy-Bouin (France)",
  "Neuvy-en-Beauce (France)",
  "Neuvy-en-Sullias (France)",
  "Neuvy-le-Roi (France)",
  "Neuvy-Saint-Sepulchre (France)",
  "Nevers (France)",
  "Nevez (France)",
  "Nevian (France)",
  "Neville (France)",
  "Nexon (France)",
  "Neydens (France)",
  "Nibas (France)",
  "Nibelle (France)",
  "Nice (France)",
  "Nicole (France)",
  "Nicorps (France)",
  "Niderhoff (France)",
  "Niderviller (France)",
  "Niederbronn-les-Bains (France)",
  "Niederbruck (France)",
  "Niederentzen (France)",
  "Niederhaslach (France)",
  "Niederhausbergen (France)",
  "Niedermodern (France)",
  "Niederschaeffolsheim (France)",
  "Nieppe (France)",
  "Niergnies (France)",
  "Nieuil (France)",
  "Nieul-le-Virouil (France)",
  "Nieul-les-Saintes (France)",
  "Nieul-sur-Mer (France)",
  "Nievroz (France)",
  "Niffer (France)",
  "Niherne (France)",
  "Nilvange (France)",
  "Niort (France)",
  "Nissan-lez-Enserune (France)",
  "Nitting (France)",
  "Nivillac (France)",
  "Nivolas-Vermelle (France)",
  "Nizas (France)",
  "Noailhac (France)",
  "Noaillac (France)",
  "Noaillan (France)",
  "Noailles (France)",
  "Noe (France)",
  "Noeux-les-Auxi (France)",
  "Noeux-les-Mines (France)",
  "Nogent (France)",
  "Nogent-le-Phaye (France)",
  "Nogent-le-Roi (France)",
  "Nogent-le-Rotrou (France)",
  "Nogent-sur-Eure (France)",
  "Nogent-sur-Marne (France)",
  "Nogent-sur-Oise (France)",
  "Nogent-sur-Seine (France)",
  "Nogent-sur-Vernisson (France)",
  "Nohanent (France)",
  "Nohic (France)",
  "Noidans-le-Ferroux (France)",
  "Noidans-les-Vesoul (France)",
  "Noilhan (France)",
  "Nointel (France)",
  "Nointot (France)",
  "Noiron (France)",
  "Noiron-sous-Gevrey (France)",
  "Noirterre (France)",
  "Noiseau (France)",
  "Noisiel (France)",
  "Noisseville (France)",
  "Noisy-le-Grand (France)",
  "Noisy-le-Roi (France)",
  "Noisy-le-Sec (France)",
  "Noisy-Rudignon (France)",
  "Noisy-sur-Ecole (France)",
  "Noisy-sur-Oise (France)",
  "Nolay (France)",
  "Nomain (France)",
  "Nomeny (France)",
  "Nomexy (France)",
  "Nommay (France)",
  "Nonancourt (France)",
  "Nonette (France)",
  "Nonneville (France)",
  "Nontron (France)",
  "Nonville (France)",
  "Nonvilliers-Grandhoux (France)",
  "Noordpeene (France)",
  "Nordausques (France)",
  "Nordhouse (France)",
  "Norges-la-Ville (France)",
  "Normanville (France)",
  "Normier (France)",
  "Norrent-Fontes (France)",
  "Norrey-en-Auge (France)",
  "Norroy-le-Sec (France)",
  "Norroy-le-Veneur (France)",
  "Norroy-les-Pont-a-Mousson (France)",
  "Nort-sur-Erdre (France)",
  "Nostang (France)",
  "Nothalten (France)",
  "Notre-Dame-de-Boisset (France)",
  "Notre-Dame-de-Bondeville (France)",
  "Notre-Dame-de-Cenilly (France)",
  "Notre-Dame-de-Commiers (France)",
  "Notre-Dame-de-Gravenchon (France)",
  "Notre-Dame-de-la-Rouviere (France)",
  "Notre-Dame-de-Livaye (France)",
  "Notre-Dame-de-Livoye (France)",
  "Notre-Dame-de-Mesage (France)",
  "Notre-Dame-de-Riez (France)",
  "Notre-Dame-de-Sanilhac (France)",
  "Notre-Dame-des-Landes (France)",
  "Notre-Dame-du-Pre (France)",
  "Nouaille-Maupertuis (France)",
  "Nouainville (France)",
  "Nouan-le-Fuzelier (France)",
  "Noueilles (France)",
  "Nouilly (France)",
  "Nousseviller-les-Bitche (France)",
  "Nousseviller-Saint-Nabor (France)",
  "Nouvelle-Eglise (France)",
  "Nouvion-le-Comte (France)",
  "Nouvion-le-Vineux (France)",
  "Nouvion-sur-Meuse (France)",
  "Nouvoitou (France)",
  "Nouzonville (France)",
  "Novalaise (France)",
  "Noveant-sur-Moselle (France)",
  "Noves (France)",
  "Novillard (France)",
  "Novillars (France)",
  "Novion-Porcien (France)",
  "Noyal-Muzillac (France)",
  "Noyal-Pontivy (France)",
  "Noyal-sur-Vilaine (France)",
  "Noyant-de-Touraine (France)",
  "Noyant-la-Gravoyere (France)",
  "Noyant-la-Plaine (France)",
  "Noyarey (France)",
  "Noyelles-Godault (France)",
  "Noyelles-sous-Lens (France)",
  "Noyelles-sur-Escaut (France)",
  "Noyen-sur-Sarthe (France)",
  "Noyers (France)",
  "Noyers-Bocage (France)",
  "Noyers-sur-Cher (France)",
  "Noyon (France)",
  "Nozay (France)",
  "Nuaille (France)",
  "Nuaille-sur-Boutonne (France)",
  "Nueil-sur-Layon (France)",
  "Nuelles (France)",
  "Nuits-Saint-Georges (France)",
  "Nyoiseau (France)",
  "Nyons (France)",
  "Obenheim (France)",
  "Oberbronn (France)",
  "Oberentzen (France)",
  "Oberhaslach (France)",
  "Oberhausbergen (France)",
  "Oberhergheim (France)",
  "Oberhoffen-sur-Moder (France)",
  "Oberlauterbach (France)",
  "Obermodern-Zutzendorf (France)",
  "Obermorschwihr (France)",
  "Obermorschwiller (France)",
  "Obernai (France)",
  "Obersaasheim (France)",
  "Oberschaeffolsheim (France)",
  "Objat (France)",
  "Oblinghem (France)",
  "Obtree (France)",
  "Ochey (France)",
  "Octeville (France)",
  "Octeville-sur-Mer (France)",
  "Oderen (France)",
  "Odomez (France)",
  "Odos (France)",
  "Oelleville (France)",
  "OEting (France)",
  "Oeyreluy (France)",
  "Offekerque (France)",
  "Offemont (France)",
  "Offendorf (France)",
  "Offoy (France)",
  "Offranville (France)",
  "Ogenne-Camptort (France)",
  "Oger (France)",
  "Ogeu-les-Bains (France)",
  "Ogeviller (France)",
  "Ogy (France)",
  "Oignies (France)",
  "Oingt (France)",
  "Oinville-sur-Montcient (France)",
  "Oiron (France)",
  "Oiselay-et-Grachaux (France)",
  "Oisemont (France)",
  "Oisly (France)",
  "Oisseau (France)",
  "Oissel (France)",
  "Oisy (France)",
  "Oisy-le-Verger (France)",
  "Oizon (France)",
  "Olargues (France)",
  "Olby (France)",
  "Olemps (France)",
  "Oletta (France)",
  "Olivet (France)",
  "Ollainville (France)",
  "Olle (France)",
  "Ollezy (France)",
  "Ollieres (France)",
  "Olliergues (France)",
  "Ollioules (France)",
  "Olloix (France)",
  "Olmet (France)",
  "Olonne-sur-Mer (France)",
  "Olonzac (France)",
  "Oloron-Sainte-Marie (France)",
  "Olwisheim (France)",
  "Omessa (France)",
  "Omonville-la-Rogue (France)",
  "Ondes (France)",
  "Ondres (France)",
  "Ondreville-sur-Essonne (France)",
  "Onet-le-Chateau (France)",
  "Onnaing (France)",
  "Onnion (France)",
  "Ons-en-Bray (France)",
  "Onzain (France)",
  "Opio (France)",
  "Oppede (France)",
  "Optevoz (France)",
  "Oradour (France)",
  "Oradour-Fanais (France)",
  "Oradour-sur-Glane (France)",
  "Oraison (France)",
  "Orangis (France)",
  "Orban (France)",
  "Orbec (France)",
  "Orbeil (France)",
  "Orbey (France)",
  "Orcet (France)",
  "Orchaise (France)",
  "Orchamps-Vennes (France)",
  "Orches (France)",
  "Orchies (France)",
  "Orcier (France)",
  "Orcines (France)",
  "Ordan-Larroque (France)",
  "Ordiarp (France)",
  "Oregue (France)",
  "Oresmaux (France)",
  "Orgelet (France)",
  "Orgeres (France)",
  "Orgerus (France)",
  "Orgeval (France)",
  "Orgon (France)",
  "Orgueil (France)",
  "Orignolles (France)",
  "Origny-en-Thierache (France)",
  "Origny-le-Sec (France)",
  "Origny-Sainte-Benoite (France)",
  "Oriol-en-Royans (France)",
  "Orion (France)",
  "Orleat (France)",
  "Orlienas (France)",
  "Orlu (France)",
  "Orly (France)",
  "Ormersviller (France)",
  "Ormes (France)",
  "Ormes-et-Ville (France)",
  "Ormesson-sur-Marne (France)",
  "Ormoy (France)",
  "Ormoy-Villers (France)",
  "Ornacieux (France)",
  "Ornans (France)",
  "Ornex (France)",
  "Orny (France)",
  "Orphin (France)",
  "Orrouer (France)",
  "Orrouy (France)",
  "Orry-la-Ville (France)",
  "Orsan (France)",
  "Orsanco (France)",
  "Orsonville (France)",
  "Orthez (France)",
  "Ortoncourt (France)",
  "Orval (France)",
  "Orvault (France)",
  "Orvaux (France)",
  "Orveau-Bellesauve (France)",
  "Orvillers-Sorel (France)",
  "Osne-le-Val (France)",
  "Osny (France)",
  "Ossages (France)",
  "Osse (France)",
  "Osses (France)",
  "Ossun (France)",
  "Ostheim (France)",
  "Ostricourt (France)",
  "Ostwald (France)",
  "Ota (France)",
  "Othis (France)",
  "Ottange (France)",
  "Ottmarsheim (France)",
  "Ottonville (France)",
  "Ottrott (France)",
  "Ouanne (France)",
  "Ouarville (France)",
  "Ouchamps (France)",
  "Oucques (France)",
  "Oudon (France)",
  "Ouerre (France)",
  "Ouezy (France)",
  "Ouffieres (France)",
  "Ougney (France)",
  "Ouhans (France)",
  "Ouilly-le-Tesson (France)",
  "Ouilly-le-Vicomte (France)",
  "Ouistreham (France)",
  "Oulchy-le-Chateau (France)",
  "Oullins (France)",
  "Oupia (France)",
  "Ourches (France)",
  "Ouroux (France)",
  "Ouroux-sur-Saone (France)",
  "Oursbelille (France)",
  "Ousse (France)",
  "Ousson-sur-Loire (France)",
  "Outarville (France)",
  "Outille (France)",
  "Outines (France)",
  "Outreau (France)",
  "Ouveillan (France)",
  "Ouville (France)",
  "Ouzilly (France)",
  "Ouzouer-le-Marche (France)",
  "Ouzouer-sur-Loire (France)",
  "Ouzouer-sur-Trezee (France)",
  "Ovanches (France)",
  "Ovillers-la-Boisselle (France)",
  "Oye-Plage (France)",
  "Oyeu (France)",
  "Oyonnax (France)",
  "Oytier-Saint-Oblas (France)",
  "Ozan (France)",
  "Ozoir-la-Ferriere (France)",
  "Ozolles (France)",
  "Ozouer-le-Voulgis (France)",
  "Pabu (France)",
  "Pace (France)",
  "Pact (France)",
  "Pacy-sur-Eure (France)",
  "Pageas (France)",
  "Pagny-la-Ville (France)",
  "Pagny-sur-Meuse (France)",
  "Pagny-sur-Moselle (France)",
  "Paillart (France)",
  "Paillencourt (France)",
  "Paillet (France)",
  "Paimboeuf (France)",
  "Paimpol (France)",
  "Paimpont (France)",
  "Painblanc (France)",
  "Pair-et-Grandrupt (France)",
  "Paix (France)",
  "Paizay-le-Chapt (France)",
  "Paizay-le-Sec (France)",
  "Paizay-le-Tort (France)",
  "Palaiseau (France)",
  "Palaja (France)",
  "Palalda (France)",
  "Palaminy (France)",
  "Palau-del-Vidre (France)",
  "Palavas-les-Flots (France)",
  "Paleyrac (France)",
  "Palinges (France)",
  "Palleau (France)",
  "Palluau (France)",
  "Palluau-sur-Indre (France)",
  "Pallud (France)",
  "Pamfou (France)",
  "Pamiers (France)",
  "Pamproux (France)",
  "Panassac (France)",
  "Panazol (France)",
  "Panissieres (France)",
  "Pannece (France)",
  "Pannes (France)",
  "Panossas (France)",
  "Pantin (France)",
  "Panzoult (France)",
  "Paradou (France)",
  "Paray-le-Monial (France)",
  "Paray-sous-Briailles (France)",
  "Paray-Vieille-Poste (France)",
  "Paraza (France)",
  "Parcay-Meslay (France)",
  "Parce-sur-Sarthe (France)",
  "Parcieux (France)",
  "Pareds (France)",
  "Parempuyre (France)",
  "Parent (France)",
  "Parentis-en-Born (France)",
  "Pargny-Filain (France)",
  "Pargny-la-Dhuys (France)",
  "Pargny-les-Bois (France)",
  "Pargny-sur-Saulx (France)",
  "Parigne (France)",
  "Parigne-sur-Braye (France)",
  "Parigny (France)",
  "Paris (France)",
  "Paris 17 Batignolles-Monceau (France)",
  "Parisot (France)",
  "Parly (France)",
  "Parmain (France)",
  "Parnans (France)",
  "Parnay (France)",
  "Parne-sur-Roc (France)",
  "Paron (France)",
  "Parsac (France)",
  "Parthenay (France)",
  "Parthenay-de-Bretagne (France)",
  "Parzac (France)",
  "Pas-en-Artois (France)",
  "Paslieres (France)",
  "Pasly (France)",
  "Pasques (France)",
  "Passel (France)",
  "Passy (France)",
  "Patay (France)",
  "Pau (France)",
  "Pauilhac (France)",
  "Pauillac Haut (France)",
  "Paul (France)",
  "Paulhac (France)",
  "Paulhaguet (France)",
  "Paulhan (France)",
  "Paulx (France)",
  "Pavie (France)",
  "Pavilly (France)",
  "Payrac (France)",
  "Payrin-Augmontel (France)",
  "Payroux (France)",
  "Payzac (France)",
  "Peaugres (France)",
  "Peaule (France)",
  "Pebrac (France)",
  "Pechabou (France)",
  "Pechbonnieu (France)",
  "Pecquencourt (France)",
  "Pecqueuse (France)",
  "Pegomas (France)",
  "Peillac (France)",
  "Peille (France)",
  "Peillon (France)",
  "Peillonnex (France)",
  "Peipin (France)",
  "Peisey-Nancroix (France)",
  "Pelissanne (France)",
  "Pellegrue (France)",
  "Pellevoisin (France)",
  "Pellouailles-les-Vignes (France)",
  "Pelousey (France)",
  "Peltre (France)",
  "Pelussin (France)",
  "Penchard (France)",
  "Pencran (France)",
  "Pende (France)",
  "Penestin (France)",
  "Penguily (France)",
  "Penmarch (France)",
  "Pennautier (France)",
  "Penne-dAgenais (France)",
  "Pennedepie (France)",
  "Penol (France)",
  "Penta-di-Casinca (France)",
  "Penvenan (France)",
  "Pepieux (France)",
  "Peray (France)",
  "Percy (France)",
  "Pere (France)",
  "Perenchies (France)",
  "Peret (France)",
  "Peri (France)",
  "Periers (France)",
  "Perignac (France)",
  "Perignat-les-Sarlieve (France)",
  "Perigne (France)",
  "Perigneux (France)",
  "Perigny (France)",
  "Perillos (France)",
  "Pern (France)",
  "Pernay (France)",
  "Pernes (France)",
  "Pernes-les-Boulogne (France)",
  "Pernes-les-Fontaines (France)",
  "Perols (France)",
  "Peron (France)",
  "Peronnas (France)",
  "Peronne (France)",
  "Peronne-en-Melantois (France)",
  "Perouse (France)",
  "Peroy-les-Gombries (France)",
  "Perpezac-le-Noir (France)",
  "Perpignan (France)",
  "Perrecy-les-Forges (France)",
  "Perreux (France)",
  "Perrex (France)",
  "Perrier (France)",
  "Perriers-sur-Andelle (France)",
  "Perrignier (France)",
  "Perrigny (France)",
  "Perrigny-les-Dijon (France)",
  "Perrogney-les-Fontaines (France)",
  "Perros-Guirec (France)",
  "Perrou (France)",
  "Perruel (France)",
  "Pers (France)",
  "Pers-Jussy (France)",
  "Persan (France)",
  "Persquen (France)",
  "Perthes-les-Brienne (France)",
  "Pertheville-Ners (France)",
  "Pertuis (France)",
  "Pessac (France)",
  "Pessac-sur-Dordogne (France)",
  "Pessan (France)",
  "Pessans (France)",
  "Pessat-Villeneuve (France)",
  "Petit Chaumont (France)",
  "Petit-Couronne (France)",
  "Petit-Landau (France)",
  "Petit-Mars (France)",
  "Petit-Palais-et-Cornemps (France)",
  "Petite-Foret (France)",
  "Petite-Rosselle (France)",
  "Petiville (France)",
  "Petreto-Bicchisano (France)",
  "Peumerit (France)",
  "Pexonne (France)",
  "Pey (France)",
  "Peymeinade (France)",
  "Peynier (France)",
  "Peypin (France)",
  "Peyrat-le-Chateau (France)",
  "Peyrehorade (France)",
  "Peyriac-de-Mer (France)",
  "Peyriac-Minervois (France)",
  "Peyrignac (France)",
  "Peyrilhac (France)",
  "Peyrilles (France)",
  "Peyrins (France)",
  "Peyrissas (France)",
  "Peyrolles-en-Provence (France)",
  "Peyruis (France)",
  "Peyrus (France)",
  "Peyrusse-Massas (France)",
  "Pezenas (France)",
  "Pezens (France)",
  "Pezilla-la-Riviere (France)",
  "Pezou (France)",
  "Pfaffenheim (France)",
  "Pfaffenhoffen (France)",
  "Pfastatt (France)",
  "Pfettisheim (France)",
  "Pfulgriesheim (France)",
  "Phaffans (France)",
  "Phalempin (France)",
  "Phalsbourg (France)",
  "Pia (France)",
  "Piazza (France)",
  "Piblange (France)",
  "Pibrac (France)",
  "Picauville (France)",
  "Picquigny (France)",
  "Piegros-la-Clastre (France)",
  "Piegut-Pluviers (France)",
  "Piennes (France)",
  "Pierre (France)",
  "Pierre-Benite (France)",
  "Pierre-de-Bresse (France)",
  "Pierre-la-Treiche (France)",
  "Pierre-Levee (France)",
  "Pierrefeu-du-Var (France)",
  "Pierrefitte-Nestalas (France)",
  "Pierrefitte-sur-Seine (France)",
  "Pierrefonds (France)",
  "Pierrefort (France)",
  "Pierregot (France)",
  "Pierrelatte (France)",
  "Pierrelaye (France)",
  "Pierremande (France)",
  "Pierrepont-sur-Avre (France)",
  "Pierrerue (France)",
  "Pierres (France)",
  "Pierrevert (France)",
  "Pierrevillers (France)",
  "Pierric (France)",
  "Pierry (France)",
  "Pietralba (France)",
  "Pieusse (France)",
  "Pignan (France)",
  "Pignans (France)",
  "Pignols (France)",
  "Pihem (France)",
  "Pimbo (France)",
  "Pimprez (France)",
  "Pin (France)",
  "Pinet (France)",
  "Pineuilh (France)",
  "Piney (France)",
  "Pinon (France)",
  "Pinsaguel (France)",
  "Piolenc (France)",
  "Pionsat (France)",
  "Pipriac (France)",
  "Piquecos (France)",
  "Pire-sur-Seiche (France)",
  "Pirey (France)",
  "Pirou (France)",
  "Piscop (France)",
  "Piseux (France)",
  "Pissos (France)",
  "Pissotte (France)",
  "Pissy-Poville (France)",
  "Pithiviers (France)",
  "Pitres (France)",
  "Pizay (France)",
  "Plabennec (France)",
  "Place (France)",
  "Plailly (France)",
  "Plaimpied-Givaudins (France)",
  "Plaine-de-Walsch (France)",
  "Plaine-Haute (France)",
  "Plaintel (France)",
  "Plaisance (France)",
  "Plaisance-du-Touch (France)",
  "Plaisir (France)",
  "Plan-de-Cuques (France)",
  "Planay (France)",
  "Plancher-Bas (France)",
  "Planches (France)",
  "Planguenoual (France)",
  "Planzolles (France)",
  "Plappeville (France)",
  "Plassay (France)",
  "Plateau (France)",
  "Plaudren (France)",
  "Plauzat (France)",
  "Pleboulle (France)",
  "Plechatel (France)",
  "Pledran (France)",
  "Plehedel (France)",
  "Plelan-le-Grand (France)",
  "Plelan-le-Petit (France)",
  "Plelauff (France)",
  "Plelo (France)",
  "Plemet (France)",
  "Plenee-Jugon (France)",
  "Pleneuf-Val-Andre (France)",
  "Plerguer (France)",
  "Plerin (France)",
  "Plerneuf (France)",
  "Plescop (France)",
  "Plesidy (France)",
  "Plesnois (France)",
  "Plessala (France)",
  "Plesse (France)",
  "Plessis-Barbuise (France)",
  "Plessis-de-Roye (France)",
  "Plestan (France)",
  "Plestin-les-Greves (France)",
  "Pleucadeuc (France)",
  "Pleudihen-sur-Rance (France)",
  "Pleugriffet (France)",
  "Pleugueneuc (France)",
  "Pleumartin (France)",
  "Pleumeleuc (France)",
  "Pleumeur-Bodou (France)",
  "Pleure (France)",
  "Pleurs (France)",
  "Pleurtuit (France)",
  "Pleuven (France)",
  "Pleyben (France)",
  "Pleyber-Christ (France)",
  "Plobannalec-Lesconil (France)",
  "Plobsheim (France)",
  "Ploemel (France)",
  "Ploemeur (France)",
  "Ploerdut (France)",
  "Ploeren (France)",
  "Ploermel (France)",
  "Ploeuc-sur-Lie (France)",
  "Ploeven (France)",
  "Plogonnec (France)",
  "Plomb (France)",
  "Plombieres-les-Bains (France)",
  "Plombieres-les-Dijon (France)",
  "Plomelin (France)",
  "Plomeur (France)",
  "Plonevez-du-Faou (France)",
  "Plorec-sur-Arguenon (France)",
  "Plottes (France)",
  "Plouagat (France)",
  "Plouaret (France)",
  "Plouarzel (France)",
  "Plouasne (France)",
  "Plouay (France)",
  "Ploubalay (France)",
  "Ploubazlanec (France)",
  "Ploubezre (France)",
  "Ploudalmezeau (France)",
  "Ploudaniel (France)",
  "Plouec-du-Trieux (France)",
  "Plouedern (France)",
  "Plouer-sur-Rance (France)",
  "Plouescat (France)",
  "Plouezec (France)",
  "Ploufragan (France)",
  "Plougar (France)",
  "Plougasnou (France)",
  "Plougastel-Daoulas (France)",
  "Plougonvelin (France)",
  "Plougonven (France)",
  "Plougoulm (France)",
  "Plougoumelen (France)",
  "Plougourvest (France)",
  "Plougrescant (France)",
  "Plouguerneau (France)",
  "Plouguin (France)",
  "Plouha (France)",
  "Plouharnel (France)",
  "Plouigneau (France)",
  "Ploumagoar (France)",
  "Ploumilliau (France)",
  "Ploumoguer (France)",
  "Plouneour-Menez (France)",
  "Plouneour-Trez (France)",
  "Plounerin (France)",
  "Plounevez-Lochrist (France)",
  "Plounevezel (France)",
  "Plourin (France)",
  "Plourivo (France)",
  "Plouvain (France)",
  "Plouvara (France)",
  "Plouvien (France)",
  "Plouvorn (France)",
  "Plouzane (France)",
  "Plozevet (France)",
  "Pludual (France)",
  "Pluduno (France)",
  "Pluguffan (France)",
  "Pluherlin (France)",
  "Plumaugat (France)",
  "Plumeliau (France)",
  "Plumelin (France)",
  "Plumergat (France)",
  "Plumoison (France)",
  "Pluneret (France)",
  "Plurien (France)",
  "Plusquellec (France)",
  "Plussulien (France)",
  "Pluvault (France)",
  "Pluvigner (France)",
  "Pluzunet (France)",
  "Poce-sur-Cisse (France)",
  "Podensac (France)",
  "Poeuilly (France)",
  "Poey-de-Lescar (France)",
  "Poggio-dOletta (France)",
  "Pogny (France)",
  "Poigny-la-Foret (France)",
  "Poilly-lez-Gien (France)",
  "Poilly-sur-Tholon (France)",
  "Poinsenot (France)",
  "Pointis-Inard (France)",
  "Poisat (France)",
  "Poiseul-les-Saulx (France)",
  "Poisieux (France)",
  "Poisson (France)",
  "Poissons (France)",
  "Poissy (France)",
  "Poisy (France)",
  "Poitiers (France)",
  "Poix (France)",
  "Poix-de-Picardie (France)",
  "Poix-du-Nord (France)",
  "Poligny (France)",
  "Pollestres (France)",
  "Polliat (France)",
  "Pomacle (France)",
  "Pomarez (France)",
  "Pomerols (France)",
  "Pomeys (France)",
  "Pommard (France)",
  "Pommeret (France)",
  "Pommerit-Jaudy (France)",
  "Pommerit-le-Vicomte (France)",
  "Pommeuse (France)",
  "Pommevic (France)",
  "Pommiers (France)",
  "Pommiers-la-Placette (France)",
  "Pompadour (France)",
  "Pompaire (France)",
  "Pompertuzat (France)",
  "Pompey (France)",
  "Pompidou (France)",
  "Pompignan (France)",
  "Pomponne (France)",
  "Pomport (France)",
  "Poncey-les-Athee (France)",
  "Ponchon (France)",
  "Poncin (France)",
  "Poncins (France)",
  "Pons (France)",
  "Pont (France)",
  "Pont-a-Marcq (France)",
  "Pont-a-Vendin (France)",
  "Pont-Audemer (France)",
  "Pont-Aven (France)",
  "Pont-de-Barret (France)",
  "Pont-de-Beauvoisin (France)",
  "Pont-de-Briques (France)",
  "Pont-de-Buis-les-Quimerch (France)",
  "Pont-de-Cheruy (France)",
  "Pont-de-Labeaume (France)",
  "Pont-de-Larn (France)",
  "Pont-de-Metz (France)",
  "Pont-de-Roide (France)",
  "Pont-de-Salars (France)",
  "Pont-de-Vaux (France)",
  "Pont-de-Veyle (France)",
  "Pont-du-Casse (France)",
  "Pont-du-Chateau (France)",
  "Pont-en-Royans (France)",
  "Pont-Eveque (France)",
  "Pont-Hebert (France)",
  "Pont-Noyelles (France)",
  "Pont-Remy (France)",
  "Pont-Saint-Esprit (France)",
  "Pont-Saint-Mard (France)",
  "Pont-Saint-Martin (France)",
  "Pont-Saint-Pierre (France)",
  "Pont-Saint-Vincent (France)",
  "Pont-Sainte-Marie (France)",
  "Pont-Sainte-Maxence (France)",
  "Pont-Salomon (France)",
  "Pont-Scorff (France)",
  "Pont-sur-Sambre (France)",
  "Pont-sur-Seine (France)",
  "Pont-sur-Yonne (France)",
  "Pontacq (France)",
  "Pontailler-sur-Saone (France)",
  "Pontarlier (France)",
  "Pontarme (France)",
  "Pontaubault (France)",
  "Pontault-Combault (France)",
  "Pontaumur (France)",
  "Pontaut (France)",
  "Pontavert (France)",
  "Pontcarre (France)",
  "Pontcharra (France)",
  "Pontcharra-sur-Turdine (France)",
  "Pontchateau (France)",
  "Pontcirq (France)",
  "Ponte-Leccia (France)",
  "Ponteilla (France)",
  "Pontenx-les-Forges (France)",
  "Pontgibaud (France)",
  "Pontgouin (France)",
  "Ponthevrard (France)",
  "Ponthierry (France)",
  "Pontigne (France)",
  "Pontigny (France)",
  "Pontivy (France)",
  "Pontlevoy (France)",
  "Pontoise (France)",
  "Pontorson (France)",
  "Pontours (France)",
  "Pontpoint (France)",
  "Pontruet (France)",
  "Ponts (France)",
  "Pontvallain (France)",
  "Popian (France)",
  "Porcelette (France)",
  "Porcheres (France)",
  "Porcheresse (France)",
  "Porcheville (France)",
  "Porcieu-Amblagnieu (France)",
  "Pordic (France)",
  "Pornic (France)",
  "Pornichet (France)",
  "Port-Brillet (France)",
  "Port-Cros (France)",
  "Port-de-Bouc (France)",
  "Port-des-Barques (France)",
  "Port-en-Bessin-Huppain (France)",
  "Port-Joinville (France)",
  "Port-la-Nouvelle (France)",
  "Port-Launay (France)",
  "Port-Louis (France)",
  "Port-Mort (France)",
  "Port-Saint-Louis-du-Rhone (France)",
  "Port-Saint-Pere (France)",
  "Port-Sainte-Foy-et-Ponchapt (France)",
  "Port-Sainte-Marie (France)",
  "Port-sur-Saone (France)",
  "Portbail (France)",
  "Portel-des-Corbieres (France)",
  "Portes-les-Valence (France)",
  "Portet-sur-Garonne (France)",
  "Portets (France)",
  "Porticcio (France)",
  "Portieux (France)",
  "Portiragnes (France)",
  "Porto (France)",
  "Porto-Vecchio (France)",
  "Portrieux (France)",
  "Ports (France)",
  "Portvendres (France)",
  "Poses (France)",
  "Potigny (France)",
  "Pouan-les-Vallees (France)",
  "Pouance (France)",
  "Pouffonds (France)",
  "Pougny (France)",
  "Pougues-les-Eaux (France)",
  "Pougy (France)",
  "Pouille (France)",
  "Pouillenay (France)",
  "Pouilley-Francais (France)",
  "Pouilley-les-Vignes (France)",
  "Pouillon (France)",
  "Pouilloux (France)",
  "Pouilly (France)",
  "Pouilly-en-Auxois (France)",
  "Pouilly-le-Monial (France)",
  "Pouilly-les-Nonains (France)",
  "Pouilly-sous-Charlieu (France)",
  "Pouilly-sur-Loire (France)",
  "Pouilly-sur-Serre (France)",
  "Pouilly-sur-Vingeanne (France)",
  "Poulainville (France)",
  "Poulan-Pouzols (France)",
  "Pouldergat (France)",
  "Pouldreuzic (France)",
  "Poule-les-Echarmeaux (France)",
  "Pouligney-Lusans (France)",
  "Poullan-sur-Mer (France)",
  "Poullaouen (France)",
  "Poulx (France)",
  "Pournoy-la-Grasse (France)",
  "Pourrain (France)",
  "Pourrieres (France)",
  "Pouru-Saint-Remy (France)",
  "Poussan (France)",
  "Pouxeux (France)",
  "Pouydesseaux (France)",
  "Pouzauges (France)",
  "Pouzay (France)",
  "Pouzilhac (France)",
  "Pouzolles (France)",
  "Pouzols-Minervois (France)",
  "Poville (France)",
  "Pradelles (France)",
  "Prades (France)",
  "Prades-le-Lez (France)",
  "Pradieres (France)",
  "Prahecq (France)",
  "Pralognan-la-Vanoise (France)",
  "Pranzac (France)",
  "Prasville (France)",
  "Prat (France)",
  "Prats-de-Carlux (France)",
  "Pray (France)",
  "Prayssac (France)",
  "Prayssas (France)",
  "Praz (France)",
  "Praz-sur-Arly (France)",
  "Pre-en-Pail (France)",
  "Preaux (France)",
  "Precey (France)",
  "Prechac (France)",
  "Precieux (France)",
  "Precigne (France)",
  "Precorbin (France)",
  "Precy-sous-Thil (France)",
  "Precy-sur-Marne (France)",
  "Precy-sur-Oise (France)",
  "Precy-sur-Vrin (France)",
  "Prefailles (France)",
  "Prefontaines (France)",
  "Preguillac (France)",
  "Preignac (France)",
  "Preignan (France)",
  "Premanon (France)",
  "Premery (France)",
  "Premian (France)",
  "Premontre (France)",
  "Preseau (France)",
  "Presles-en-Brie (France)",
  "Presles-et-Thierny (France)",
  "Pressagny-le-Val (France)",
  "Pressignac-Vicq (France)",
  "Pressigny (France)",
  "Pressigny-les-Pins (France)",
  "Pretot-Vicquemare (France)",
  "Preuschdorf (France)",
  "Prevessin-Moens (France)",
  "Prey (France)",
  "Prigonrieux (France)",
  "Primarette (France)",
  "Primat (France)",
  "Prin-Deyrancon (France)",
  "Princay (France)",
  "Pringy (France)",
  "Prinquiau (France)",
  "Prisse (France)",
  "Privas (France)",
  "Prix (France)",
  "Prix-les-Mezieres (France)",
  "Proissans (France)",
  "Prompsat (France)",
  "Pronville (France)",
  "Propriano (France)",
  "Prouais (France)",
  "Prouilly (France)",
  "Prouvy (France)",
  "Prouzel (France)",
  "Provencheres-sur-Fave (France)",
  "Provencheres-sur-Meuse (France)",
  "Proveysieux (France)",
  "Proville (France)",
  "Provin (France)",
  "Provins (France)",
  "Proyart (France)",
  "Prudhomat (France)",
  "Prunay-Cassereau (France)",
  "Prunay-le-Gillon (France)",
  "Prunay-sur-Essonne (France)",
  "Prunelli-di-Fiumorbo (France)",
  "Prunieres (France)",
  "Pruniers (France)",
  "Pruzilly (France)",
  "Publier (France)",
  "Puceul (France)",
  "Puchevillers (France)",
  "Puget-sur-Argens (France)",
  "Puget-Ville (France)",
  "Pugey (France)",
  "Pugieu (France)",
  "Pugnac (France)",
  "Pugny-Chatenod (France)",
  "Puicheric (France)",
  "Puihardy (France)",
  "Puilacher (France)",
  "Puilboreau (France)",
  "Puimichel (France)",
  "Puiseaux (France)",
  "Puiseux-en-France (France)",
  "Puiseux-le-Hauberger (France)",
  "Puisieulx (France)",
  "Puisieux (France)",
  "Puissalicon (France)",
  "Puisseguin (France)",
  "Puisserguier (France)",
  "Pujaudran (France)",
  "Pujaut (France)",
  "Pujo-le-Plan (France)",
  "Pujols (France)",
  "Puligny-Montrachet (France)",
  "Pulligny (France)",
  "Pulnoy (France)",
  "Pulversheim (France)",
  "Pusignan (France)",
  "Pussay (France)",
  "Puteaux (France)",
  "Putot-en-Bessin (France)",
  "Puttelange-les-Thionville (France)",
  "Puy-Guillaume (France)",
  "Puy-Saint-Andre (France)",
  "Puy-Saint-Martin (France)",
  "Puybegon (France)",
  "Puybrun (France)",
  "Puycelsi (France)",
  "Puycornet (France)",
  "Puygouzon (France)",
  "Puygros (France)",
  "Puylaurens (France)",
  "Puyloubier (France)",
  "Puymiclan (France)",
  "Puyravault (France)",
  "Puyreaux (France)",
  "Puyricard (France)",
  "Quaedypre (France)",
  "Quarouble (France)",
  "Quarre-les-Tombes (France)",
  "Quatremare (France)",
  "Queaux (France)",
  "Quebriac (France)",
  "Quedillac (France)",
  "Queige (France)",
  "Quelaines-Saint-Gault (France)",
  "Quelneuc (France)",
  "Quenoche (France)",
  "Querenaing (France)",
  "Quernes (France)",
  "Querqueville (France)",
  "Querrien (France)",
  "Querrieu (France)",
  "Quers (France)",
  "Quesnoy-sur-Deule (France)",
  "Quessoy (France)",
  "Questembert (France)",
  "Quetigny (France)",
  "Quettehou (France)",
  "Quettreville-sur-Sienne (France)",
  "Quevauvillers (France)",
  "Queven (France)",
  "Quevert (France)",
  "Quevillon (France)",
  "Queyrac (France)",
  "Queyrieres (France)",
  "Quezac (France)",
  "Quiberon (France)",
  "Quiberville (France)",
  "Quibou (France)",
  "Quiers (France)",
  "Quievrechain (France)",
  "Quievrecourt (France)",
  "Quievy (France)",
  "Quillan (France)",
  "Quillebeuf-sur-Seine (France)",
  "Quilly (France)",
  "Quily (France)",
  "Quimper (France)",
  "Quincampoix (France)",
  "Quincie-en-Beaujolais (France)",
  "Quincieu (France)",
  "Quincy (France)",
  "Quincy-sous-Senart (France)",
  "Quincy-Voisins (France)",
  "Quineville (France)",
  "Quingey (France)",
  "Quinsac (France)",
  "Quint-Fonsegrives (France)",
  "Quintenic (France)",
  "Quintin (France)",
  "Quissac (France)",
  "Rabastens-de-Bigorre (France)",
  "Rabat-les-Trois-Seigneurs (France)",
  "Rablay-sur-Layon (France)",
  "Rabou (France)",
  "Raches (France)",
  "Racquinghem (France)",
  "Racrange (France)",
  "Radinghem (France)",
  "Radinghem-en-Weppes (France)",
  "Radon (France)",
  "Radonvilliers (France)",
  "Raedersheim (France)",
  "Raffetot (France)",
  "Rahon (France)",
  "Rai (France)",
  "Raids (France)",
  "Raimbeaucourt (France)",
  "Rainfreville (France)",
  "Rainneville (France)",
  "Rainville (France)",
  "Rainvillers (France)",
  "Raismes (France)",
  "Raizeux (France)",
  "Ramatuelle (France)",
  "Rambert (France)",
  "Rambervillers (France)",
  "Rambouillet (France)",
  "Rammersmatt (France)",
  "Ramonchamp (France)",
  "Ramonville-Saint-Agne (France)",
  "Rance (France)",
  "Rancogne (France)",
  "Randan (France)",
  "Randens (France)",
  "Ranes (France)",
  "Rang (France)",
  "Rang-du-Fliers (France)",
  "Rans (France)",
  "Ranspach (France)",
  "Rantigny (France)",
  "Ranville (France)",
  "Raon-aux-Bois (France)",
  "Raon-sur-Plaine (France)",
  "Rarecourt (France)",
  "Ratieres (France)",
  "Ratte (France)",
  "Raucourt-et-Flaba (France)",
  "Rauville-la-Place (France)",
  "Rauwiller (France)",
  "Rauzan (France)",
  "Ravenel (France)",
  "Ravieres (France)",
  "Ray-sur-Saone (France)",
  "Rayssac (France)",
  "Razac-sur-lIsle (France)",
  "Real (France)",
  "Realmont (France)",
  "Realville (France)",
  "Reaumont (France)",
  "Reaumur (France)",
  "Rebais (France)",
  "Rebenacq (France)",
  "Rebrechien (France)",
  "Rebreuve-sur-Canche (France)",
  "Rebreuviette (France)",
  "Recey-sur-Ource (France)",
  "Rechesy (France)",
  "Rechicourt-le-Chateau (France)",
  "Recloses (France)",
  "Recologne (France)",
  "Recologne-les-Rioz (France)",
  "Recques-sur-Course (France)",
  "Recquignies (France)",
  "Redene (France)",
  "Redessan (France)",
  "Reding (France)",
  "Redon (France)",
  "Reffuveille (France)",
  "Regniowez (France)",
  "Reguiny (France)",
  "Reguisheim (France)",
  "Regusse (France)",
  "Rehainviller (France)",
  "Rehon (France)",
  "Reichshoffen (France)",
  "Reichstett (France)",
  "Reignac-sur-Indre (France)",
  "Reignier-Esery (France)",
  "Reillanne (France)",
  "Reilly (France)",
  "Reims (France)",
  "Reims-la-Brulee (France)",
  "Reiners (France)",
  "Reiningue (France)",
  "Reipertswiller (France)",
  "Reitwiller (France)",
  "Relanges (France)",
  "Remaucourt (France)",
  "Remecourt (France)",
  "Remelfang (France)",
  "Remelfing (France)",
  "Remenoville (France)",
  "Remerangles (France)",
  "Remereville (France)",
  "Remering-les-Puttelange (France)",
  "Remigny (France)",
  "Remilly (France)",
  "Reminiac (France)",
  "Remiremont (France)",
  "Remoulins (France)",
  "Remungol (France)",
  "Remuzat (France)",
  "Remy (France)",
  "Renage (France)",
  "Renaison (France)",
  "Renault (France)",
  "Renaze (France)",
  "Rene (France)",
  "Renescure (France)",
  "Rennes (France)",
  "Renneville (France)",
  "Repaix (France)",
  "Reparsac (France)",
  "Replonges (France)",
  "Requeil (France)",
  "Requista (France)",
  "Ressons-le-Long (France)",
  "Ressons-sur-Matz (France)",
  "Restinclieres (France)",
  "Rethel (France)",
  "Rethondes (France)",
  "Retiers (France)",
  "Retonfey (France)",
  "Retournac (France)",
  "Retschwiller (France)",
  "Rettel (France)",
  "Rety (France)",
  "Reuil-en-Brie (France)",
  "Reuil-sur-Breche (France)",
  "Reuilly (France)",
  "Reuilly-Sauvigny (France)",
  "Reumont (France)",
  "Reuves (France)",
  "Revel (France)",
  "Revel-Tourdan (France)",
  "Reventin-Vaugris (France)",
  "Revest-des-Brousses (France)",
  "Reviers (France)",
  "Revigny (France)",
  "Revigny-sur-Ornain (France)",
  "Reville (France)",
  "Revin (France)",
  "Reynies (France)",
  "Reyrieux (France)",
  "Reyssouze (France)",
  "Rezonville (France)",
  "Rhinau (France)",
  "Riaille (France)",
  "Rians (France)",
  "Riantec (France)",
  "Ribaute-les-Tavernes (France)",
  "Ribecourt-Dreslincourt (France)",
  "Ribemont (France)",
  "Ribemont-sur-Ancre (France)",
  "Riberac (France)",
  "Ribiers (France)",
  "Ricarville (France)",
  "Richardmenil (France)",
  "Richebourg (France)",
  "Richelieu (France)",
  "Richeling (France)",
  "Richemont (France)",
  "Richwiller (France)",
  "Ricquebourg (France)",
  "Riec-sur-Belon (France)",
  "Riedisheim (France)",
  "Riedseltz (France)",
  "Riencourt-les-Bapaume (France)",
  "Rieulay (France)",
  "Rieumes (France)",
  "Rieupeyroux (France)",
  "Rieux (France)",
  "Rieux-de-Pelleport (France)",
  "Rieux-en-Cambresis (France)",
  "Rieux-Minervois (France)",
  "Riez (France)",
  "Rignac (France)",
  "Rignieux-le-Franc (France)",
  "Rilhac-Rancon (France)",
  "Rillieux-la-Pape (France)",
  "Rimbaud (France)",
  "Rimogne (France)",
  "Ringendorf (France)",
  "Rinxent (France)",
  "Riocaud (France)",
  "Riom (France)",
  "Riom-es-Montagnes (France)",
  "Rion-des-Landes (France)",
  "Rions (France)",
  "Riorges (France)",
  "Riotord (France)",
  "Rioz (France)",
  "Ris-Orangis (France)",
  "Rittershoffen (France)",
  "Rivarennes (France)",
  "Rive-de-Gier (France)",
  "Rivecourt (France)",
  "Rivedoux-Plage (France)",
  "Rivery (France)",
  "Rivesaltes (France)",
  "Riviere (France)",
  "Rivieres (France)",
  "Riville (France)",
  "Rixheim (France)",
  "Roaillan (France)",
  "Roanne (France)",
  "Roannes-Saint-Mary (France)",
  "Robert (France)",
  "Robion (France)",
  "Rocbaron (France)",
  "Roche (France)",
  "Roche-la-Moliere (France)",
  "Roche-le-Peyroux (France)",
  "Roche-lez-Beaupre (France)",
  "Rochechouart (France)",
  "Rochecorbon (France)",
  "Rochefort (France)",
  "Rochefort-du-Gard (France)",
  "Rochefort-en-Terre (France)",
  "Rochefort-sur-Loire (France)",
  "Rochefort-sur-Nenon (France)",
  "Rochegude (France)",
  "Rochemaure (France)",
  "Rocher (France)",
  "Roches (France)",
  "Roches-les-Blamont (France)",
  "Roches-Premarie-Andille (France)",
  "Roches-sur-Marne (France)",
  "Rocheserviere (France)",
  "Rochesson (France)",
  "Rochetoirin (France)",
  "Rochetrejoux (France)",
  "Rochy-Conde (France)",
  "Rocquemont (France)",
  "Rocquencourt (France)",
  "Rocroi (France)",
  "Rodelinghem (France)",
  "Roderen (France)",
  "Rodern (France)",
  "Rodez (France)",
  "Roeschwoog (France)",
  "Roeulx (France)",
  "Roeux (France)",
  "Roeze-sur-Sarthe (France)",
  "Roffey (France)",
  "Roffiac (France)",
  "Rogerville (France)",
  "Rognac (France)",
  "Rognaix (France)",
  "Rognes (France)",
  "Rognonas (France)",
  "Rohan (France)",
  "Rohr (France)",
  "Rohrbach-les-Bitche (France)",
  "Roiffieux (France)",
  "Roisel (France)",
  "Roissy-en-Brie (France)",
  "Roissy-en-France (France)",
  "Rolampont (France)",
  "Rollancourt (France)",
  "Rolleville (France)",
  "Rollot (France)",
  "Romagnat (France)",
  "Romagne (France)",
  "Romagnieu (France)",
  "Romain (France)",
  "Romain-sur-Meuse (France)",
  "Romaine (France)",
  "Romainville (France)",
  "Romaneche-Thorins (France)",
  "Romange (France)",
  "Romans (France)",
  "Rombas (France)",
  "Romegoux (France)",
  "Romeny-sur-Marne (France)",
  "Romeries (France)",
  "Romille (France)",
  "Romilly-sur-Andelle (France)",
  "Romilly-sur-Seine (France)",
  "Romorantin-Lanthenay (France)",
  "Rompon (France)",
  "Roncey (France)",
  "Ronchin (France)",
  "Roncq (France)",
  "Ronnet (France)",
  "Ronno (France)",
  "Ronsenac (France)",
  "Ronthon (France)",
  "Roost-Warendin (France)",
  "Roppe (France)",
  "Roppenheim (France)",
  "Roppentzwiller (France)",
  "Roquebilliere (France)",
  "Roquebrune (France)",
  "Roquebrune-Cap-Martin (France)",
  "Roquebrune-sur-Argens (France)",
  "Roquecor (France)",
  "Roquecourbe (France)",
  "Roquefort (France)",
  "Roquefort-de-Sault (France)",
  "Roquefort-des-Corbieres (France)",
  "Roquefort-la-Bedoule (France)",
  "Roquefort-sur-Garonne (France)",
  "Roquefort-sur-Soulzon (France)",
  "Roquelaure-Saint-Aubin (France)",
  "Roquemaure (France)",
  "Roques (France)",
  "Roqueseriere (France)",
  "Roquesteron (France)",
  "Roquetoire (France)",
  "Roquettes (France)",
  "Roquevaire (France)",
  "Rosay (France)",
  "Rosay-sur-Lieure (France)",
  "Roscoff (France)",
  "Rosel (France)",
  "Rosenwiller (France)",
  "Rosheim (France)",
  "Rosieres (France)",
  "Rosieres-aux-Salines (France)",
  "Rosieres-en-Haye (France)",
  "Rosieres-en-Santerre (France)",
  "Rosieres-pres-Troyes (France)",
  "Rosieres-sur-Mance (France)",
  "Rosny-sous-Bois (France)",
  "Rosny-sur-Seine (France)",
  "Rosoy-en-Multien (France)",
  "Rospez (France)",
  "Rosporden (France)",
  "Rosselange (France)",
  "Rossfeld (France)",
  "Rosteig (France)",
  "Rostrenen (France)",
  "Rosult (France)",
  "Rothau (France)",
  "Rotherens (France)",
  "Rots (France)",
  "Rott (France)",
  "Rouans (France)",
  "Roubaix (France)",
  "Roubia (France)",
  "Roubion (France)",
  "Roucy (France)",
  "Rouen (France)",
  "Rouffach (France)",
  "Rouffiac-Tolosan (France)",
  "Rouffignac (France)",
  "Rouffignac-Saint-Cernin-de-Reilhac (France)",
  "Rouge-Perriers (France)",
  "Rougegoutte (France)",
  "Rougemont (France)",
  "Rougiers (France)",
  "Rouillac (France)",
  "Rouille (France)",
  "Rouillon (France)",
  "Rouilly-Sacey (France)",
  "Roujan (France)",
  "Roulans (France)",
  "Roullens (France)",
  "Roullet-Saint-Estephe (France)",
  "Roumare (France)",
  "Roumazieres (France)",
  "Roumegoux (France)",
  "Roumoules (France)",
  "Rountzenheim (France)",
  "Rouperroux (France)",
  "Rousies (France)",
  "Roussay (France)",
  "Rousseloy (France)",
  "Rousset (France)",
  "Roussillon (France)",
  "Rousson (France)",
  "Roussy (France)",
  "Roussy-le-Village (France)",
  "Routot (France)",
  "Rouville (France)",
  "Rouvillers (France)",
  "Rouvre (France)",
  "Rouvres-la-Chetive (France)",
  "Rouvroy (France)",
  "Rouvroy-sur-Audry (France)",
  "Rouvroy-sur-Marne (France)",
  "Rouxmesnil-Bouteilles (France)",
  "Rouy-le-Grand (France)",
  "Rouziers-de-Touraine (France)",
  "Roville-aux-Chenes (France)",
  "Royan (France)",
  "Royas (France)",
  "Royat (France)",
  "Roybon (France)",
  "Roye (France)",
  "Roye-sur-Matz (France)",
  "Rozay-en-Brie (France)",
  "Rozerieulles (France)",
  "Rozier-en-Donzy (France)",
  "Rozieres-en-Beauce (France)",
  "Rozoy-Bellevalle (France)",
  "Rozoy-sur-Serre (France)",
  "Ruaudin (France)",
  "Rubelles (France)",
  "Rubempre (France)",
  "Ruch (France)",
  "Rucqueville (France)",
  "Rue (France)",
  "Rueil-Malmaison (France)",
  "Ruelisheim (France)",
  "Ruelle (France)",
  "Ruelle-sur-Touvre (France)",
  "Ruesnes (France)",
  "Ruffec (France)",
  "Ruffey-les-Beaune (France)",
  "Ruffey-les-Echirey (France)",
  "Ruffey-sur-Seille (France)",
  "Ruffiac (France)",
  "Rugles (France)",
  "Ruille-Froid-Fonds (France)",
  "Ruille-le-Gravelais (France)",
  "Ruitz (France)",
  "Rully (France)",
  "Rumaucourt (France)",
  "Rumegies (France)",
  "Rumersheim-le-Haut (France)",
  "Rumigny (France)",
  "Rumilly (France)",
  "Rumilly-en-Cambresis (France)",
  "Rungis (France)",
  "Ruoms (France)",
  "Rupt (France)",
  "Rupt-sur-Moselle (France)",
  "Rurange-les-Thionville (France)",
  "Russ (France)",
  "Ruy (France)",
  "Ry (France)",
  "Saacy-sur-Marne (France)",
  "Saales (France)",
  "Sabatier (France)",
  "Sable-sur-Sarthe (France)",
  "Sablonceaux (France)",
  "Sablons (France)",
  "Sabres (France)",
  "Sacey (France)",
  "Sachin (France)",
  "Saclas (France)",
  "Saclay (France)",
  "Sacquenville (France)",
  "Sacy (France)",
  "Sadirac (France)",
  "Saessolsheim (France)",
  "Saffre (France)",
  "Sagy (France)",
  "Sahurs (France)",
  "Saignes (France)",
  "Sail-sous-Couzan (France)",
  "Saillans (France)",
  "Sailly-en-Ostrevent (France)",
  "Sailly-Flibeaucourt (France)",
  "Sailly-Labourse (France)",
  "Sailly-le-Sec (France)",
  "Sailly-lez-Cambrai (France)",
  "Sailly-sur-la-Lys (France)",
  "Sain-Bel (France)",
  "Sainghin-en-Melantois (France)",
  "Sainghin-en-Weppes (France)",
  "Sainneville (France)",
  "Sainpuits (France)",
  "Sains-du-Nord (France)",
  "Sains-en-Amienois (France)",
  "Sains-en-Gohelle (France)",
  "Saint Nicolas Les Arras (France)",
  "Saint-Abit (France)",
  "Saint-Adrien (France)",
  "Saint-Affrique (France)",
  "Saint-Agathon (France)",
  "Saint-Agnant (France)",
  "Saint-Agoulin (France)",
  "Saint-Agreve (France)",
  "Saint-Aignan-des-Noyers (France)",
  "Saint-Aignan-sur-Roe (France)",
  "Saint-Aigulin (France)",
  "Saint-Albain (France)",
  "Saint-Alban-de-Roche (France)",
  "Saint-Alban-du-Rhone (France)",
  "Saint-Alban-Leysse (France)",
  "Saint-Alexandre (France)",
  "Saint-Amancet (France)",
  "Saint-Amand-de-Belves (France)",
  "Saint-Amand-de-Coly (France)",
  "Saint-Amand-en-Puisaye (France)",
  "Saint-Amand-les-Eaux (France)",
  "Saint-Amand-Magnazeix (France)",
  "Saint-Amand-Montrond (France)",
  "Saint-Amand-sur-Fion (France)",
  "Saint-Amans-Soult (France)",
  "Saint-Amans-Valtoret (France)",
  "Saint-Amant-de-Boixe (France)",
  "Saint-Amant-Tallende (France)",
  "Saint-Amarin (France)",
  "Saint-Ambroix (France)",
  "Saint-Amour (France)",
  "Saint-Andeol-de-Vals (France)",
  "Saint-Andeux (France)",
  "Saint-Andiol (France)",
  "Saint-Andre (France)",
  "Saint-Andre-de-Chalencon (France)",
  "Saint-Andre-de-Corcy (France)",
  "Saint-Andre-de-Messei (France)",
  "Saint-Andre-de-Roquelongue (France)",
  "Saint-Andre-de-Rosans (France)",
  "Saint-Andre-de-Sangonis (France)",
  "Saint-Andre-des-Eaux (France)",
  "Saint-Andre-en-Vivarais (France)",
  "Saint-Andre-Farivillers (France)",
  "Saint-Andre-le-Gaz (France)",
  "Saint-Andre-le-Puy (France)",
  "Saint-Andre-les-Alpes (France)",
  "Saint-Andre-les-Vergers (France)",
  "Saint-Andre-lez-Lille (France)",
  "Saint-Andre-sur-Orne (France)",
  "Saint-Andre-Treize-Voies (France)",
  "Saint-Androny (France)",
  "Saint-Angeau (France)",
  "Saint-Angel (France)",
  "Saint-Antoine-Cumond (France)",
  "Saint-Antoine-du-Rocher (France)",
  "Saint-Antoine-la-Foret (France)",
  "Saint-Antonin-sur-Bayon (France)",
  "Saint-Apollinaire (France)",
  "Saint-Appolinard (France)",
  "Saint-Aquilin-de-Pacy (France)",
  "Saint-Armel (France)",
  "Saint-Arnoult (France)",
  "Saint-Arnoult-des-Bois (France)",
  "Saint-Arnoult-en-Yvelines (France)",
  "Saint-Astier (France)",
  "Saint-Auban (France)",
  "Saint-Aubert (France)",
  "Saint-Aubin (France)",
  "Saint-Aubin-de-Baubigne (France)",
  "Saint-Aubin-de-Lanquais (France)",
  "Saint-Aubin-de-Medoc (France)",
  "Saint-Aubin-des-Chateaux (France)",
  "Saint-Aubin-du-Cormier (France)",
  "Saint-Aubin-du-Plain (France)",
  "Saint-Aubin-en-Bray (France)",
  "Saint-Aubin-en-Charollais (France)",
  "Saint-Aubin-les-Elbeuf (France)",
  "Saint-Aubin-les-Forges (France)",
  "Saint-Aubin-sous-Erquery (France)",
  "Saint-Aubin-sur-Gaillon (France)",
  "Saint-Aubin-sur-Mer (France)",
  "Saint-Aubin-sur-Scie (France)",
  "Saint-Augustin (France)",
  "Saint-Aunes (France)",
  "Saint-Avaugourd-des-Landes (France)",
  "Saint-Ave (France)",
  "Saint-Avertin (France)",
  "Saint-Avit (France)",
  "Saint-Avit-les-Guespieres (France)",
  "Saint-Avold (France)",
  "Saint-Avre (France)",
  "Saint-Ay (France)",
  "Saint-Aygulf (France)",
  "Saint-Baldoph (France)",
  "Saint-Bardoux (France)",
  "Saint-Barnabe (France)",
  "Saint-Barthelemy-de-Bussiere (France)",
  "Saint-Barthelemy-de-Vals (France)",
  "Saint-Barthelemy-Lestra (France)",
  "Saint-Baudille-de-la-Tour (France)",
  "Saint-Bauzille-de-Montmel (France)",
  "Saint-Bauzille-de-Putois (France)",
  "Saint-Beat (France)",
  "Saint-Benezet (France)",
  "Saint-Benin-dAzy (France)",
  "Saint-Benoit (France)",
  "Saint-Benoit-des-Ondes (France)",
  "Saint-Benoit-sur-Loire (France)",
  "Saint-Berain-sur-Dheune (France)",
  "Saint-Bernard (France)",
  "Saint-Beron (France)",
  "Saint-Berthevin (France)",
  "Saint-Bihy (France)",
  "Saint-Blaise-la-Roche (France)",
  "Saint-Bohaire (France)",
  "Saint-Boil (France)",
  "Saint-Boingt (France)",
  "Saint-Bonnet-de-Mure (France)",
  "Saint-Bonnet-le-Chateau (France)",
  "Saint-Bonnet-le-Troncy (France)",
  "Saint-Bonnet-les-Allier (France)",
  "Saint-Branchs (France)",
  "Saint-Brandan (France)",
  "Saint-Bres (France)",
  "Saint-Brevin-les-Pins (France)",
  "Saint-Briac-sur-Mer (France)",
  "Saint-Brice (France)",
  "Saint-Brice-de-Landelles (France)",
  "Saint-Brice-sous-Foret (France)",
  "Saint-Brieuc (France)",
  "Saint-Bris-le-Vineux (France)",
  "Saint-Brisson-sur-Loire (France)",
  "Saint-Calais (France)",
  "Saint-Cannat (France)",
  "Saint-Caprais-de-Bordeaux (France)",
  "Saint-Carne (France)",
  "Saint-Carreuc (France)",
  "Saint-Cassin (France)",
  "Saint-Cast-le-Guildo (France)",
  "Saint-Cere (France)",
  "Saint-Cergues (France)",
  "Saint-Cesaire (France)",
  "Saint-Cezaire-sur-Siagne (France)",
  "Saint-Cezert (France)",
  "Saint-Chamassy (France)",
  "Saint-Chamond (France)",
  "Saint-Chaptes (France)",
  "Saint-Chartres (France)",
  "Saint-Chef (France)",
  "Saint-Cheron-du-Chemin (France)",
  "Saint-Chinian (France)",
  "Saint-Christo-en-Jarez (France)",
  "Saint-Christol (France)",
  "Saint-Christol-les-Ales (France)",
  "Saint-Christophe-de-Valains (France)",
  "Saint-Christophe-du-Bois (France)",
  "Saint-Christophe-du-Ligneron (France)",
  "Saint-Christophe-en-Boucherie (France)",
  "Saint-Christophe-en-Brionnais (France)",
  "Saint-Christophe-sur-Avre (France)",
  "Saint-Christophe-sur-Guiers (France)",
  "Saint-Christophe-sur-le-Nais (France)",
  "Saint-Christophe-sur-Roc (France)",
  "Saint-Ciers-sur-Gironde (France)",
  "Saint-Cirgues (France)",
  "Saint-Clair (France)",
  "Saint-Clair-de-la-Tour (France)",
  "Saint-Clair-du-Rhone (France)",
  "Saint-Clair-sur-Galaure (France)",
  "Saint-Clar-de-Riviere (France)",
  "Saint-Claud (France)",
  "Saint-Claude (France)",
  "Saint-Claude-de-Diray (France)",
  "Saint-Clement (France)",
  "Saint-Clement-de-la-Place (France)",
  "Saint-Clement-des-Levees (France)",
  "Saint-Clet (France)",
  "Saint-Cloud (France)",
  "Saint-Colomban (France)",
  "Saint-Congard (France)",
  "Saint-Contest (France)",
  "Saint-Corneille (France)",
  "Saint-Cornier-des-Landes (France)",
  "Saint-Coulitz (France)",
  "Saint-Coulomb (France)",
  "Saint-Creac (France)",
  "Saint-Crepin-Ibouvillers (France)",
  "Saint-Crespin-sur-Moine (France)",
  "Saint-Cyprien (France)",
  "Saint-Cyprien-Plage (France)",
  "Saint-Cyr-du-Gault (France)",
  "Saint-Cyr-en-Pail (France)",
  "Saint-Cyr-en-Val (France)",
  "Saint-Cyr-la-Campagne (France)",
  "Saint-Cyr-le-Chatoux (France)",
  "Saint-Cyr-les-Champagnes (France)",
  "Saint-Cyr-sur-le-Rhone (France)",
  "Saint-Cyr-sur-Loire (France)",
  "Saint-Cyr-sur-Menthon (France)",
  "Saint-Cyr-sur-Mer (France)",
  "Saint-Cyr-sur-Morin (France)",
  "Saint-Denis (France)",
  "Saint-Denis-dAnjou (France)",
  "Saint-Denis-de-Cabanne (France)",
  "Saint-Denis-de-Palin (France)",
  "Saint-Denis-de-Pile (France)",
  "Saint-Denis-de-Vaux (France)",
  "Saint-Denis-des-Murs (France)",
  "Saint-Denis-en-Bugey (France)",
  "Saint-Denis-en-Val (France)",
  "Saint-Denis-le-Gast (France)",
  "Saint-Denis-le-Thiboult (France)",
  "Saint-Denis-les-Rebais (France)",
  "Saint-Denis-sur-Coise (France)",
  "Saint-Denis-sur-Sarthon (France)",
  "Saint-Derrien (France)",
  "Saint-Desir (France)",
  "Saint-Desirat (France)",
  "Saint-Desire (France)",
  "Saint-Dezery (France)",
  "Saint-Didier-de-Formans (France)",
  "Saint-Didier-de-la-Tour (France)",
  "Saint-Didier-en-Donjon (France)",
  "Saint-Didier-en-Velay (France)",
  "Saint-Didier-sous-Riverie (France)",
  "Saint-Didier-sur-Chalaronne (France)",
  "Saint-Didier-sur-Rochefort (France)",
  "Saint-Die-des-Vosges (France)",
  "Saint-Dionisy (France)",
  "Saint-Disdier (France)",
  "Saint-Divy (France)",
  "Saint-Dizier (France)",
  "Saint-Dizier-en-Diois (France)",
  "Saint-Dolay (France)",
  "Saint-Domet (France)",
  "Saint-Domineuc (France)",
  "Saint-Donan (France)",
  "Saint-Doulchard (France)",
  "Saint-Drezery (France)",
  "Saint-Ebremond-de-Bonfosse (France)",
  "Saint-Elier (France)",
  "Saint-Eliph (France)",
  "Saint-Elix-Seglan (France)",
  "Saint-Eloi (France)",
  "Saint-Eloy-de-Gy (France)",
  "Saint-Eloy-la-Glaciere (France)",
  "Saint-Eloy-les-Mines (France)",
  "Saint-Emilion (France)",
  "Saint-Erblon (France)",
  "Saint-Erme-Outre-et-Ramecourt (France)",
  "Saint-Esteve (France)",
  "Saint-Esteve-Janson (France)",
  "Saint-Etienne (France)",
  "Saint-Etienne-au-Mont (France)",
  "Saint-Etienne-au-Temple (France)",
  "Saint-Etienne-de-Baigorry (France)",
  "Saint-Etienne-de-Brillouet (France)",
  "Saint-Etienne-de-Crossey (France)",
  "Saint-Etienne-de-Cuines (France)",
  "Saint-Etienne-de-Fontbellon (France)",
  "Saint-Etienne-de-Montluc (France)",
  "Saint-Etienne-de-Saint-Geoirs (France)",
  "Saint-Etienne-de-Tinee (France)",
  "Saint-Etienne-de-Tulmont (France)",
  "Saint-Etienne-de-Valoux (France)",
  "Saint-Etienne-des-Oullieres (France)",
  "Saint-Etienne-du-Bois (France)",
  "Saint-Etienne-du-Gres (France)",
  "Saint-Etienne-du-Valdonnez (France)",
  "Saint-Etienne-du-Vauvray (France)",
  "Saint-Etienne-en-Cogles (France)",
  "Saint-Etienne-la-Varenne (France)",
  "Saint-Etienne-le-Molard (France)",
  "Saint-Etienne-les-Orgues (France)",
  "Saint-Etienne-sous-Bailleul (France)",
  "Saint-Etienne-sur-Chalaronne (France)",
  "Saint-Etienne-sur-Suippe (France)",
  "Saint-Eulien (France)",
  "Saint-Eustache-la-Foret (France)",
  "Saint-Evarzec (France)",
  "Saint-Faust (France)",
  "Saint-Felicien (France)",
  "Saint-Felix-de-Rieutord (France)",
  "Saint-Felix-de-Villadeix (France)",
  "Saint-Fiacre (France)",
  "Saint-Fiacre-sur-Maine (France)",
  "Saint-Firmin (France)",
  "Saint-Firmin-des-Pres (France)",
  "Saint-Florent (France)",
  "Saint-Florent-le-Vieil (France)",
  "Saint-Florent-sur-Auzonnet (France)",
  "Saint-Florent-sur-Cher (France)",
  "Saint-Florentin (France)",
  "Saint-Floret (France)",
  "Saint-Floris (France)",
  "Saint-Flour (France)",
  "Saint-Flovier (France)",
  "Saint-Folquin (France)",
  "Saint-Fons (France)",
  "Saint-Forgeux (France)",
  "Saint-Fort (France)",
  "Saint-Fregant (France)",
  "Saint-Fromond (France)",
  "Saint-Front (France)",
  "Saint-Fulgent (France)",
  "Saint-Fuscien (France)",
  "Saint-Galmier (France)",
  "Saint-Gatien-des-Bois (France)",
  "Saint-Gaudens (France)",
  "Saint-Gaultier (France)",
  "Saint-Gein (France)",
  "Saint-Gelais (France)",
  "Saint-Gely-du-Fesc (France)",
  "Saint-Gence (France)",
  "Saint-Generoux (France)",
  "Saint-Genes-du-Retz (France)",
  "Saint-Genest-Lerpt (France)",
  "Saint-Genest-Malifaux (France)",
  "Saint-Gengoux-de-Scisse (France)",
  "Saint-Genies-Bellevue (France)",
  "Saint-Genies-de-Comolas (France)",
  "Saint-Genies-de-Malgoires (France)",
  "Saint-Genies-des-Mourgues (France)",
  "Saint-Genis-Laval (France)",
  "Saint-Genis-les-Ollieres (France)",
  "Saint-Genis-Pouilly (France)",
  "Saint-Genix-sur-Guiers (France)",
  "Saint-Genou (France)",
  "Saint-Geoire-en-Valdaine (France)",
  "Saint-Geoirs (France)",
  "Saint-Georges-de-Baroille (France)",
  "Saint-Georges-de-Didonne (France)",
  "Saint-Georges-de-Luzencon (France)",
  "Saint-Georges-de-Montaigu (France)",
  "Saint-Georges-de-Pointindoux (France)",
  "Saint-Georges-de-Poisieux (France)",
  "Saint-Georges-de-Reneins (France)",
  "Saint-Georges-des-Coteaux (France)",
  "Saint-Georges-du-Bois (France)",
  "Saint-Georges-du-Rosay (France)",
  "Saint-Georges-du-Vievre (France)",
  "Saint-Georges-Haute-Ville (France)",
  "Saint-Georges-le-Flechard (France)",
  "Saint-Georges-les-Baillargeaux (France)",
  "Saint-Georges-Montcocq (France)",
  "Saint-Georges-sur-Allier (France)",
  "Saint-Georges-sur-Arnon (France)",
  "Saint-Georges-sur-Baulche (France)",
  "Saint-Georges-sur-Cher (France)",
  "Saint-Georges-sur-Eure (France)",
  "Saint-Georges-sur-Fontaine (France)",
  "Saint-Georges-sur-Loire (France)",
  "Saint-Georges-sur-Renon (France)",
  "Saint-Gerand (France)",
  "Saint-Gerand-de-Vaux (France)",
  "Saint-Gereon (France)",
  "Saint-Germain (France)",
  "Saint-Germain-de-Joux (France)",
  "Saint-Germain-de-la-Grange (France)",
  "Saint-Germain-de-Princay (France)",
  "Saint-Germain-de-Tournebut (France)",
  "Saint-Germain-des-Fosses (France)",
  "Saint-Germain-des-Pres (France)",
  "Saint-Germain-du-Corbeis (France)",
  "Saint-Germain-du-Plain (France)",
  "Saint-Germain-du-Puch (France)",
  "Saint-Germain-du-Puy (France)",
  "Saint-Germain-en-Laye (France)",
  "Saint-Germain-la-Blanche-Herbe (France)",
  "Saint-Germain-la-Ville (France)",
  "Saint-Germain-Langot (France)",
  "Saint-Germain-Laprade (France)",
  "Saint-Germain-Laxis (France)",
  "Saint-Germain-Lembron (France)",
  "Saint-Germain-les-Arpajon (France)",
  "Saint-Germain-les-Belles (France)",
  "Saint-Germain-les-Corbeil (France)",
  "Saint-Germain-les-Paroisses (France)",
  "Saint-Germain-les-Vergnes (France)",
  "Saint-Germain-Nuelles (France)",
  "Saint-Germain-sous-Doue (France)",
  "Saint-Germain-sur-Avre (France)",
  "Saint-Germain-sur-Moine (France)",
  "Saint-Germain-sur-Morin (France)",
  "Saint-Germain-sur-Rhone (France)",
  "Saint-Germain-Village (France)",
  "Saint-Germainmont (France)",
  "Saint-Germer-de-Fly (France)",
  "Saint-Gervais-en-Belin (France)",
  "Saint-Gervais-la-Foret (France)",
  "Saint-Gervais-les-Bains (France)",
  "Saint-Gervais-sous-Meymont (France)",
  "Saint-Gervais-sur-Roubion (France)",
  "Saint-Gervazy (France)",
  "Saint-Geyrac (France)",
  "Saint-Gildas-des-Bois (France)",
  "Saint-Gilles (France)",
  "Saint-Gilles-des-Marais (France)",
  "Saint-Gilles-les-Bois (France)",
  "Saint-Gingolph (France)",
  "Saint-Girons (France)",
  "Saint-Goazec (France)",
  "Saint-Gobain (France)",
  "Saint-Gondon (France)",
  "Saint-Gondran (France)",
  "Saint-Gonnery (France)",
  "Saint-Gratien (France)",
  "Saint-Grave (France)",
  "Saint-Gregoire (France)",
  "Saint-Guilhem-le-Desert (France)",
  "Saint-Guinoux (France)",
  "Saint-Heand (France)",
  "Saint-Helen (France)",
  "Saint-Herblain (France)",
  "Saint-Herve (France)",
  "Saint-Hilaire-Cusson-la-Valmitte (France)",
  "Saint-Hilaire-de-Brethmas (France)",
  "Saint-Hilaire-de-Chaleons (France)",
  "Saint-Hilaire-de-Court (France)",
  "Saint-Hilaire-de-la-Cote (France)",
  "Saint-Hilaire-de-Loulay (France)",
  "Saint-Hilaire-de-Riez (France)",
  "Saint-Hilaire-de-Talmont (France)",
  "Saint-Hilaire-de-Voust (France)",
  "Saint-Hilaire-du-Harcouet (France)",
  "Saint-Hilaire-le-Vouhis (France)",
  "Saint-Hilaire-les-Andresis (France)",
  "Saint-Hilaire-les-Courbes (France)",
  "Saint-Hilaire-lez-Cambrai (France)",
  "Saint-Hilaire-Peyroux (France)",
  "Saint-Hilaire-Saint-Mesmin (France)",
  "Saint-Hilarion (France)",
  "Saint-Hilliers (France)",
  "Saint-Hippolyte (France)",
  "Saint-Hippolyte-de-Montaigu (France)",
  "Saint-Hippolyte-du-Fort (France)",
  "Saint-Honore-les-Bains (France)",
  "Saint-Hostien (France)",
  "Saint-Igneuc (France)",
  "Saint-Illide (France)",
  "Saint-Imoges (France)",
  "Saint-Ismier (France)",
  "Saint-Jacques-de-la-Lande (France)",
  "Saint-Jean-aux-Amognes (France)",
  "Saint-Jean-Bonnefonds (France)",
  "Saint-Jean-Brevelay (France)",
  "Saint-Jean-de-Boiseau (France)",
  "Saint-Jean-de-Bournay (France)",
  "Saint-Jean-de-Braye (France)",
  "Saint-Jean-de-Chevelu (France)",
  "Saint-Jean-de-Cornies (France)",
  "Saint-Jean-de-Gonville (France)",
  "Saint-Jean-de-la-Ruelle (France)",
  "Saint-Jean-de-Laur (France)",
  "Saint-Jean-de-Lier (France)",
  "Saint-Jean-de-Liversay (France)",
  "Saint-Jean-de-Losne (France)",
  "Saint-Jean-de-Luz (France)",
  "Saint-Jean-de-Marcel (France)",
  "Saint-Jean-de-Marsacq (France)",
  "Saint-Jean-de-Maurienne (France)",
  "Saint-Jean-de-Moirans (France)",
  "Saint-Jean-de-Monts (France)",
  "Saint-Jean-de-Muzols (France)",
  "Saint-Jean-de-Sauves (France)",
  "Saint-Jean-de-Serres (France)",
  "Saint-Jean-de-Sixt (France)",
  "Saint-Jean-de-Soudain (France)",
  "Saint-Jean-de-Vedas (France)",
  "Saint-Jean-des-Baisants (France)",
  "Saint-Jean-des-Champs (France)",
  "Saint-Jean-des-Mauvrets (France)",
  "Saint-Jean-des-Ollieres (France)",
  "Saint-Jean-des-Vignes (France)",
  "Saint-Jean-du-Bruel (France)",
  "Saint-Jean-du-Cardonnay (France)",
  "Saint-Jean-du-Falga (France)",
  "Saint-Jean-en-Royans (France)",
  "Saint-Jean-Kerdaniel (France)",
  "Saint-Jean-Kourtzerode (France)",
  "Saint-Jean-la-Poterie (France)",
  "Saint-Jean-Lasseille (France)",
  "Saint-Jean-le-Blanc (France)",
  "Saint-Jean-le-Centenier (France)",
  "Saint-Jean-le-Thomas (France)",
  "Saint-Jean-les-Deux-Jumeaux (France)",
  "Saint-Jean-Ligoure (France)",
  "Saint-Jean-Pied-de-Port (France)",
  "Saint-Jean-Rohrbach (France)",
  "Saint-Jean-Saint-Germain (France)",
  "Saint-Jean-sur-Erve (France)",
  "Saint-Jean-sur-Vilaine (France)",
  "Saint-Jean-Trolimon (France)",
  "Saint-Jeannet (France)",
  "Saint-Jeoire (France)",
  "Saint-Jeoire-Prieure (France)",
  "Saint-Jeures (France)",
  "Saint-Joachim (France)",
  "Saint-Jorioz (France)",
  "Saint-Jory (France)",
  "Saint-Joseph-de-Riviere (France)",
  "Saint-Jouan-des-Guerets (France)",
  "Saint-Jouvent (France)",
  "Saint-Judoce (France)",
  "Saint-Julien (France)",
  "Saint-Julien-de-Cassagnas (France)",
  "Saint-Julien-de-Chedon (France)",
  "Saint-Julien-de-Concelles (France)",
  "Saint-Julien-de-Coppel (France)",
  "Saint-Julien-de-Lampon (France)",
  "Saint-Julien-de-Vouvantes (France)",
  "Saint-Julien-des-Landes (France)",
  "Saint-Julien-du-Puy (France)",
  "Saint-Julien-du-Sault (France)",
  "Saint-Julien-en-Born (France)",
  "Saint-Julien-en-Champsaur (France)",
  "Saint-Julien-en-Genevois (France)",
  "Saint-Julien-le-Roux (France)",
  "Saint-Julien-le-Vendomois (France)",
  "Saint-Julien-les-Metz (France)",
  "Saint-Julien-les-Villas (France)",
  "Saint-Julien-sur-Bibost (France)",
  "Saint-Julien-sur-Sarthe (France)",
  "Saint-Junien (France)",
  "Saint-Just-Chaleyssin (France)",
  "Saint-Just-de-Claix (France)",
  "Saint-Just-en-Bas (France)",
  "Saint-Just-en-Chaussee (France)",
  "Saint-Just-en-Chevalet (France)",
  "Saint-Just-le-Martel (France)",
  "Saint-Just-Malmont (France)",
  "Saint-Just-pres-Brioude (France)",
  "Saint-Lactencin (France)",
  "Saint-Lager (France)",
  "Saint-Lager-Bressac (France)",
  "Saint-Lambert-du-Lattay (France)",
  "Saint-Lambert-la-Potherie (France)",
  "Saint-Lambert-sur-Dive (France)",
  "Saint-Lanne (France)",
  "Saint-Lary-Boujean (France)",
  "Saint-Lary-Soulan (France)",
  "Saint-Launeuc (France)",
  "Saint-Laurent-Blangy (France)",
  "Saint-Laurent-Bretagne (France)",
  "Saint-Laurent-de-la-Plaine (France)",
  "Saint-Laurent-de-la-Pree (France)",
  "Saint-Laurent-de-la-Salanque (France)",
  "Saint-Laurent-de-Levezou (France)",
  "Saint-Laurent-de-Mure (France)",
  "Saint-Laurent-de-Neste (France)",
  "Saint-Laurent-des-Autels (France)",
  "Saint-Laurent-des-Combes (France)",
  "Saint-Laurent-du-Bois (France)",
  "Saint-Laurent-du-Mottay (France)",
  "Saint-Laurent-du-Pont (France)",
  "Saint-Laurent-du-Var (France)",
  "Saint-Laurent-en-Beaumont (France)",
  "Saint-Laurent-en-Gatines (France)",
  "Saint-Laurent-la-Roche (France)",
  "Saint-Laurent-Rochefort (France)",
  "Saint-Laurent-sur-Gorre (France)",
  "Saint-Laurent-sur-Sevre (France)",
  "Saint-Laurs (France)",
  "Saint-Leger-aux-Bois (France)",
  "Saint-Leger-de-Rotes (France)",
  "Saint-Leger-des-Vignes (France)",
  "Saint-Leger-du-Bourg-Denis (France)",
  "Saint-Leger-en-Bray (France)",
  "Saint-Leger-les-Domart (France)",
  "Saint-Leger-sous-Brienne (France)",
  "Saint-Leger-sous-Cholet (France)",
  "Saint-Leger-sur-Dheune (France)",
  "Saint-Leonard (France)",
  "Saint-Leonard-de-Noblat (France)",
  "Saint-Leu-la-Foret (France)",
  "Saint-Lezin (France)",
  "Saint-Lieux-les-Lavaur (France)",
  "Saint-Lormel (France)",
  "Saint-Loubes (France)",
  "Saint-Louis (France)",
  "Saint-Louis-de-Montferrand (France)",
  "Saint-Loup (France)",
  "Saint-Loup-Cammas (France)",
  "Saint-Loup-Hors (France)",
  "Saint-Lubin-des-Joncherets (France)",
  "Saint-Lumier-en-Champagne (France)",
  "Saint-Lumine-de-Clisson (France)",
  "Saint-Luperce (France)",
  "Saint-Lupicin (France)",
  "Saint-Lye (France)",
  "Saint-Lye-la-Foret (France)",
  "Saint-Lyphard (France)",
  "Saint-Lys (France)",
  "Saint-Macaire (France)",
  "Saint-Macaire-du-Bois (France)",
  "Saint-Macaire-en-Mauges (France)",
  "Saint-Magne-de-Castillon (France)",
  "Saint-Maigner (France)",
  "Saint-Maigrin (France)",
  "Saint-Maime (France)",
  "Saint-Maixant (France)",
  "Saint-Malo-de-Guersac (France)",
  "Saint-Malo-de-la-Lande (France)",
  "Saint-Malo-de-Phily (France)",
  "Saint-Malo-du-Bois (France)",
  "Saint-Malo-en-Donziois (France)",
  "Saint-Mamert-du-Gard (France)",
  "Saint-Mamet-la-Salvetat (France)",
  "Saint-Mammes (France)",
  "Saint-Mande (France)",
  "Saint-Mandrier-sur-Mer (France)",
  "Saint-Marc-Jaumegarde (France)",
  "Saint-Marceau (France)",
  "Saint-Marcel-Bel-Accueil (France)",
  "Saint-Marcel-les-Valence (France)",
  "Saint-Marcel-sur-Aude (France)",
  "Saint-Marcellin (France)",
  "Saint-Marcellin-en-Forez (France)",
  "Saint-Mard (France)",
  "Saint-Mard-de-Vaux (France)",
  "Saint-Mariens (France)",
  "Saint-Mars-de-Coutais (France)",
  "Saint-Mars-de-Locquenay (France)",
  "Saint-Mars-du-Desert (France)",
  "Saint-Mars-la-Jaille (France)",
  "Saint-Martial (France)",
  "Saint-Martial-de-Vitaterne (France)",
  "Saint-Martin-au-Laert (France)",
  "Saint-Martin-Belle-Roche (France)",
  "Saint-Martin-Bellevue (France)",
  "Saint-Martin-Boulogne (France)",
  "Saint-Martin-Choquel (France)",
  "Saint-Martin-de-Belleville (France)",
  "Saint-Martin-de-Bernegoue (France)",
  "Saint-Martin-de-Blagny (France)",
  "Saint-Martin-de-Crau (France)",
  "Saint-Martin-de-Fontenay (France)",
  "Saint-Martin-de-la-Brasque (France)",
  "Saint-Martin-de-Lenne (France)",
  "Saint-Martin-de-Londres (France)",
  "Saint-Martin-de-Nigelles (France)",
  "Saint-Martin-de-Re (France)",
  "Saint-Martin-de-Riberac (France)",
  "Saint-Martin-de-Seignanx (France)",
  "Saint-Martin-de-Valamas (France)",
  "Saint-Martin-de-Valgalgues (France)",
  "Saint-Martin-des-Bois (France)",
  "Saint-Martin-des-Champs (France)",
  "Saint-Martin-des-Noyers (France)",
  "Saint-Martin-des-Olmes (France)",
  "Saint-Martin-du-Bois (France)",
  "Saint-Martin-du-Bosc (France)",
  "Saint-Martin-du-Manoir (France)",
  "Saint-Martin-du-Mont (France)",
  "Saint-Martin-du-Puy (France)",
  "Saint-Martin-du-Tertre (France)",
  "Saint-Martin-du-Tilleul (France)",
  "Saint-Martin-du-Var (France)",
  "Saint-Martin-en-Biere (France)",
  "Saint-Martin-en-Bresse (France)",
  "Saint-Martin-en-Campagne (France)",
  "Saint-Martin-en-Gatinois (France)",
  "Saint-Martin-en-Haut (France)",
  "Saint-Martin-la-Campagne (France)",
  "Saint-Martin-la-Plaine (France)",
  "Saint-Martin-Lacaussade (France)",
  "Saint-Martin-Lalande (France)",
  "Saint-Martin-le-Beau (France)",
  "Saint-Martin-le-Noeud (France)",
  "Saint-Martin-le-Pin (France)",
  "Saint-Martin-le-Vieil (France)",
  "Saint-Martin-le-Vinoux (France)",
  "Saint-Martin-les-Melle (France)",
  "Saint-Martin-Longueau (France)",
  "Saint-Martin-Osmonville (France)",
  "Saint-Martin-Sainte-Catherine (France)",
  "Saint-Martin-sur-Arve (France)",
  "Saint-Martin-sur-le-Pre (France)",
  "Saint-Martin-sur-Nohain (France)",
  "Saint-Martin-sur-Ocre (France)",
  "Saint-Martin-Terressus (France)",
  "Saint-Martin-Valmeroux (France)",
  "Saint-Mary (France)",
  "Saint-Mathieu-de-Treviers (France)",
  "Saint-Mathurin (France)",
  "Saint-Maudan (France)",
  "Saint-Maulvis (France)",
  "Saint-Maurice (France)",
  "Saint-Maurice-de-Cazevieille (France)",
  "Saint-Maurice-de-Gourdans (France)",
  "Saint-Maurice-de-Lignon (France)",
  "Saint-Maurice-de-Remens (France)",
  "Saint-Maurice-la-Clouere (France)",
  "Saint-Maurice-la-Souterraine (France)",
  "Saint-Maurice-les-Brousses (France)",
  "Saint-Maurice-Montcouronne (France)",
  "Saint-Maurice-sur-Aveyron (France)",
  "Saint-Maurice-sur-Dargoire (France)",
  "Saint-Maurice-sur-Eygues (France)",
  "Saint-Maurice-sur-Fessard (France)",
  "Saint-Maurice-sur-Moselle (France)",
  "Saint-Max (France)",
  "Saint-Maxent (France)",
  "Saint-Maximin (France)",
  "Saint-Maximin-la-Sainte-Baume (France)",
  "Saint-Maxire (France)",
  "Saint-Meard-de-Gurcon (France)",
  "Saint-Medard-de-Guizieres (France)",
  "Saint-Medard-en-Forez (France)",
  "Saint-Meen-le-Grand (France)",
  "Saint-Melaine-sur-Aubance (France)",
  "Saint-Meloir-des-Ondes (France)",
  "Saint-Meme-les-Carrieres (France)",
  "Saint-Memmie (France)",
  "Saint-Michel (France)",
  "Saint-Michel-Chef-Chef (France)",
  "Saint-Michel-de-Chabrillanoux (France)",
  "Saint-Michel-de-Maurienne (France)",
  "Saint-Michel-de-Rieufret (France)",
  "Saint-Michel-de-Volangis (France)",
  "Saint-Michel-des-Andaines (France)",
  "Saint-Michel-le-Cloucq (France)",
  "Saint-Michel-lObservatoire (France)",
  "Saint-Michel-Mont-Mercure (France)",
  "Saint-Michel-sous-Bois (France)",
  "Saint-Michel-sur-Meurthe (France)",
  "Saint-Michel-sur-Orge (France)",
  "Saint-Michel-sur-Savasse (France)",
  "Saint-Michel-sur-Ternoise (France)",
  "Saint-Michel-Treve (France)",
  "Saint-Mihiel (France)",
  "Saint-Mitre-les-Remparts (France)",
  "Saint-Morillon (France)",
  "Saint-Nabord (France)",
  "Saint-Nauphary (France)",
  "Saint-Nazaire (France)",
  "Saint-Nazaire-de-Valentane (France)",
  "Saint-Nectaire (France)",
  "Saint-Nicolas-de-Bliquetuit (France)",
  "Saint-Nicolas-de-la-Grave (France)",
  "Saint-Nicolas-de-Port (France)",
  "Saint-Nicolas-de-Redon (France)",
  "Saint-Nicolas-du-Pelem (France)",
  "Saint-Nolff (France)",
  "Saint-Nom-la-Breteche (France)",
  "Saint-Offenge-Dessous (France)",
  "Saint-Offenge-Dessus (France)",
  "Saint-Omer (France)",
  "Saint-Omer-en-Chaussee (France)",
  "Saint-Orens-de-Gameville (France)",
  "Saint-Orens-Pouy-Petit (France)",
  "Saint-Ouen (France)",
  "Saint-Ouen-de-Thouberville (France)",
  "Saint-Ouen-des-Besaces (France)",
  "Saint-Ouen-des-Toits (France)",
  "Saint-Ouen-du-Breuil (France)",
  "Saint-Ouen-du-Mesnil-Oger (France)",
  "Saint-Ouen-du-Tilleul (France)",
  "Saint-Ouen-en-Brie (France)",
  "Saint-Ouen-les-Parey (France)",
  "Saint-Ouen-sur-Iton (France)",
  "Saint-Ouen-sur-Morin (France)",
  "Saint-Oulph (France)",
  "Saint-Ours (France)",
  "Saint-Pabu (France)",
  "Saint-Pair-du-Mont (France)",
  "Saint-Pair-sur-Mer (France)",
  "Saint-Pal-de-Mons (France)",
  "Saint-Palais (France)",
  "Saint-Pancre (France)",
  "Saint-Pandelon (France)",
  "Saint-Pantaleon-de-Larche (France)",
  "Saint-Papoul (France)",
  "Saint-Pardoux-du-Breuil (France)",
  "Saint-Pargoire (France)",
  "Saint-Parize-le-Chatel (France)",
  "Saint-Parres-aux-Tertres (France)",
  "Saint-Parres-les-Vaudes (France)",
  "Saint-Paterne (France)",
  "Saint-Paterne-Racan (France)",
  "Saint-Pathus (France)",
  "Saint-Patrice-de-Claids (France)",
  "Saint-Paul (France)",
  "Saint-Paul-de-Jarrat (France)",
  "Saint-Paul-de-Varces (France)",
  "Saint-Paul-de-Vence (France)",
  "Saint-Paul-de-Vezelin (France)",
  "Saint-Paul-du-Vernay (France)",
  "Saint-Paul-en-Chablais (France)",
  "Saint-Paul-en-Cornillon (France)",
  "Saint-Paul-en-Foret (France)",
  "Saint-Paul-en-Jarez (France)",
  "Saint-Paul-en-Pareds (France)",
  "Saint-Paul-les-Dax (France)",
  "Saint-Paul-les-Durance (France)",
  "Saint-Paul-les-Romans (France)",
  "Saint-Paul-Trois-Chateaux (France)",
  "Saint-Paulet-de-Caisson (France)",
  "Saint-Pee-sur-Nivelle (France)",
  "Saint-Pellerin (France)",
  "Saint-Peray (France)",
  "Saint-Perdon (France)",
  "Saint-Pere (France)",
  "Saint-Pere-en-Retz (France)",
  "Saint-Pern (France)",
  "Saint-Philbert-de-Bouaine (France)",
  "Saint-Philbert-de-Grand-Lieu (France)",
  "Saint-Philbert-du-Pont-Charrault (France)",
  "Saint-Philibert (France)",
  "Saint-Piat (France)",
  "Saint-Pierre-Bois (France)",
  "Saint-Pierre-Brouck (France)",
  "Saint-Pierre-dAllevard (France)",
  "Saint-Pierre-de-Boeuf (France)",
  "Saint-Pierre-de-Bressieux (France)",
  "Saint-Pierre-de-Chandieu (France)",
  "Saint-Pierre-de-Jards (France)",
  "Saint-Pierre-de-Lages (France)",
  "Saint-Pierre-de-Maille (France)",
  "Saint-Pierre-de-Mons (France)",
  "Saint-Pierre-de-Plesguen (France)",
  "Saint-Pierre-de-Trivisy (France)",
  "Saint-Pierre-de-Varengeville (France)",
  "Saint-Pierre-de-Varennes (France)",
  "Saint-Pierre-des-Corps (France)",
  "Saint-Pierre-des-Echaubrognes (France)",
  "Saint-Pierre-des-Fleurs (France)",
  "Saint-Pierre-du-Chemin (France)",
  "Saint-Pierre-du-Mont (France)",
  "Saint-Pierre-du-Palais (France)",
  "Saint-Pierre-du-Perray (France)",
  "Saint-Pierre-du-Regard (France)",
  "Saint-Pierre-du-Vauvray (France)",
  "Saint-Pierre-en-Port (France)",
  "Saint-Pierre-en-Val (France)",
  "Saint-Pierre-la-Bourlhonne (France)",
  "Saint-Pierre-la-Cour (France)",
  "Saint-Pierre-la-Garenne (France)",
  "Saint-Pierre-la-Palud (France)",
  "Saint-Pierre-Lavis (France)",
  "Saint-Pierre-le-Chastel (France)",
  "Saint-Pierre-le-Moutier (France)",
  "Saint-Pierre-le-Vieux (France)",
  "Saint-Pierre-les-Elbeuf (France)",
  "Saint-Pierre-Montlimart (France)",
  "Saint-Pierre-sur-Dives (France)",
  "Saint-Pierre-Tarentaine (France)",
  "Saint-Pierreville (France)",
  "Saint-Pol-sur-Mer (France)",
  "Saint-Pol-sur-Ternoise (France)",
  "Saint-Pompont (France)",
  "Saint-Pont (France)",
  "Saint-Porchaire (France)",
  "Saint-Porquier (France)",
  "Saint-Pouange (France)",
  "Saint-Pourcain-sur-Sioule (France)",
  "Saint-Prest (France)",
  "Saint-Priest-des-Champs (France)",
  "Saint-Priest-en-Jarez (France)",
  "Saint-Priest-la-Roche (France)",
  "Saint-Priest-la-Vetre (France)",
  "Saint-Priest-Taurion (France)",
  "Saint-Prim (France)",
  "Saint-Privat (France)",
  "Saint-Privat-de-Vallongue (France)",
  "Saint-Privat-la-Montagne (France)",
  "Saint-Prix (France)",
  "Saint-Projet (France)",
  "Saint-Prouant (France)",
  "Saint-Pryve-Saint-Mesmin (France)",
  "Saint-Python (France)",
  "Saint-Quay-Perros (France)",
  "Saint-Quay-Portrieux (France)",
  "Saint-Quentin (France)",
  "Saint-Quentin-de-Baron (France)",
  "Saint-Quentin-du-Dropt (France)",
  "Saint-Quentin-en-Mauges (France)",
  "Saint-Quentin-Fallavier (France)",
  "Saint-Quentin-la-Poterie (France)",
  "Saint-Quentin-le-Verger (France)",
  "Saint-Quentin-les-Chardonnets (France)",
  "Saint-Quentin-sur-Indrois (France)",
  "Saint-Quentin-sur-le-Homme (France)",
  "Saint-Rambert-en-Bugey (France)",
  "Saint-Regle (France)",
  "Saint-Remeze (France)",
  "Saint-Remimont (France)",
  "Saint-Remy (France)",
  "Saint-Remy-du-Nord (France)",
  "Saint-Remy-en-Mauges (France)",
  "Saint-Remy-en-Rollat (France)",
  "Saint-Remy-la-Vanne (France)",
  "Saint-Remy-la-Varenne (France)",
  "Saint-Remy-les-Chevreuse (France)",
  "Saint-Remy-sur-Avre (France)",
  "Saint-Remy-sur-Durolle (France)",
  "Saint-Renan (France)",
  "Saint-Reverien (France)",
  "Saint-Riquier (France)",
  "Saint-Rirand (France)",
  "Saint-Robert (France)",
  "Saint-Roch (France)",
  "Saint-Rogatien (France)",
  "Saint-Romain-de-Colbosc (France)",
  "Saint-Romain-de-Lerps (France)",
  "Saint-Romain-en-Gal (France)",
  "Saint-Romain-en-Gier (France)",
  "Saint-Romain-la-Motte (France)",
  "Saint-Romain-la-Virvee (France)",
  "Saint-Romain-Lachalm (France)",
  "Saint-Romain-le-Preux (France)",
  "Saint-Romain-le-Puy (France)",
  "Saint-Romain-les-Atheux (France)",
  "Saint-Rome-de-Tarn (France)",
  "Saint-Saens (France)",
  "Saint-Saire (France)",
  "Saint-Samson-sur-Rance (France)",
  "Saint-Sandoux (France)",
  "Saint-Sardos (France)",
  "Saint-Saturnin-du-Limet (France)",
  "Saint-Saturnin-les-Apt (France)",
  "Saint-Saturnin-les-Avignon (France)",
  "Saint-Saturnin-sur-Loire (France)",
  "Saint-Saulge (France)",
  "Saint-Saulve (France)",
  "Saint-Sauvant (France)",
  "Saint-Sauveur-dAunis (France)",
  "Saint-Sauveur-de-Montagut (France)",
  "Saint-Sauveur-Gouvernet (France)",
  "Saint-Sauveur-la-Sagne (France)",
  "Saint-Sauveur-le-Vicomte (France)",
  "Saint-Sauveur-Lendelin (France)",
  "Saint-Savin (France)",
  "Saint-Savinien (France)",
  "Saint-Saviol (France)",
  "Saint-Savournin (France)",
  "Saint-Sebastien (France)",
  "Saint-Sebastien-de-Morsent (France)",
  "Saint-Secondin (France)",
  "Saint-Segal (France)",
  "Saint-Seglin (France)",
  "Saint-Seine-sur-Vingeanne (France)",
  "Saint-Selve (France)",
  "Saint-Senoch (France)",
  "Saint-Senoux (France)",
  "Saint-Series (France)",
  "Saint-Sernin (France)",
  "Saint-Sernin-du-Bois (France)",
  "Saint-Servant (France)",
  "Saint-Seurin-de-Cadourne (France)",
  "Saint-Seurin-de-Cursac (France)",
  "Saint-Seurin-de-Prats (France)",
  "Saint-Sever (France)",
  "Saint-Sever-Calvados (France)",
  "Saint-Siffret (France)",
  "Saint-Sigismond (France)",
  "Saint-Simeon-de-Bressieux (France)",
  "Saint-Simon (France)",
  "Saint-Simon-de-Pellouaille (France)",
  "Saint-Sixt (France)",
  "Saint-Sixte (France)",
  "Saint-Sorlin-en-Valloire (France)",
  "Saint-Souplet (France)",
  "Saint-Soupplets (France)",
  "Saint-Suliac (France)",
  "Saint-Sulpice-de-Faleyrens (France)",
  "Saint-Sulpice-de-Royan (France)",
  "Saint-Sulpice-et-Cameyrac (France)",
  "Saint-Sulpice-la-Foret (France)",
  "Saint-Sulpice-le-Vieux (France)",
  "Saint-Sulpice-les-Feuilles (France)",
  "Saint-Sulpice-sur-Leze (France)",
  "Saint-Sulpice-sur-Risle (France)",
  "Saint-Sylvain (France)",
  "Saint-Sylvestre-sur-Lot (France)",
  "Saint-Symphorien-de-Lay (France)",
  "Saint-Symphorien-dOzon (France)",
  "Saint-Symphorien-sur-Coise (France)",
  "Saint-Symphorien-sur-Saone (France)",
  "Saint-Theodorit (France)",
  "Saint-Thibaud-de-Couz (France)",
  "Saint-Thibault (France)",
  "Saint-Thibault-des-Vignes (France)",
  "Saint-Thibery (France)",
  "Saint-Thierry (France)",
  "Saint-Thurial (France)",
  "Saint-Trivier-de-Courtes (France)",
  "Saint-Trivier-sur-Moignans (France)",
  "Saint-Trojan-les-Bains (France)",
  "Saint-Tropez (France)",
  "Saint-Tugdual (France)",
  "Saint-Urbain (France)",
  "Saint-Urbain-Maconcourt (France)",
  "Saint-Usage (France)",
  "Saint-Uze (France)",
  "Saint-Vaast-du-Val (France)",
  "Saint-Vaast-en-Auge (France)",
  "Saint-Vaast-la-Hougue (France)",
  "Saint-Vaast-les-Mello (France)",
  "Saint-Valery-en-Caux (France)",
  "Saint-Valery-sur-Somme (France)",
  "Saint-Vallier (France)",
  "Saint-Vallier-de-Thiey (France)",
  "Saint-Varent (France)",
  "Saint-Vaury (France)",
  "Saint-Venant (France)",
  "Saint-Verand (France)",
  "Saint-Viance (France)",
  "Saint-Viatre (France)",
  "Saint-Viaud (France)",
  "Saint-Victor-de-Cessieu (France)",
  "Saint-Victor-la-Coste (France)",
  "Saint-Victor-sur-Arlanc (France)",
  "Saint-Victor-sur-Loire (France)",
  "Saint-Victor-sur-Rhins (France)",
  "Saint-Victoret (France)",
  "Saint-Victurnien (France)",
  "Saint-Vigor (France)",
  "Saint-Vincent-de-Connezac (France)",
  "Saint-Vincent-de-Durfort (France)",
  "Saint-Vincent-de-Paul (France)",
  "Saint-Vincent-de-Tyrosse (France)",
  "Saint-Vincent-des-Landes (France)",
  "Saint-Vincent-sur-Graon (France)",
  "Saint-Vit (France)",
  "Saint-Vital (France)",
  "Saint-Vite (France)",
  "Saint-Vivien (France)",
  "Saint-Vivien-de-Medoc (France)",
  "Saint-Vrain (France)",
  "Saint-Vulbas (France)",
  "Saint-Witz (France)",
  "Saint-Xandre (France)",
  "Saint-Ybars (France)",
  "Saint-Yorre (France)",
  "Saint-Yrieix-la-Perche (France)",
  "Saint-Yrieix-sur-Charente (France)",
  "Saint-Yvi (France)",
  "Saint-Yzan-de-Soudiac (France)",
  "Saint-Zacharie (France)",
  "Sainte-Adresse (France)",
  "Sainte-Anastasie-sur-Issole (France)",
  "Sainte-Anne-Saint-Priest (France)",
  "Sainte-Anne-sur-Vilaine (France)",
  "Sainte-Austreberthe (France)",
  "Sainte-Bazeille (France)",
  "Sainte-Blandine (France)",
  "Sainte-Catherine (France)",
  "Sainte-Cecile (France)",
  "Sainte-Colombe-de-Duras (France)",
  "Sainte-Colombe-sur-Gand (France)",
  "Sainte-Colombe-sur-Guette (France)",
  "Sainte-Consorce (France)",
  "Sainte-Croix (France)",
  "Sainte-Croix-aux-Mines (France)",
  "Sainte-Croix-en-Jarez (France)",
  "Sainte-Croix-en-Plaine (France)",
  "Sainte-Croix-Grand-Tonne (France)",
  "Sainte-Croix-Hague (France)",
  "Sainte-Croix-Volvestre (France)",
  "Sainte-Eanne (France)",
  "Sainte-Enimie (France)",
  "Sainte-Eulalie-en-Born (France)",
  "Sainte-Eusoye (France)",
  "Sainte-Fauste (France)",
  "Sainte-Fereole (France)",
  "Sainte-Flaive-des-Loups (France)",
  "Sainte-Florence (France)",
  "Sainte-Florine (France)",
  "Sainte-Fortunade (France)",
  "Sainte-Foy-de-Longas (France)",
  "Sainte-Foy-de-Peyrolieres (France)",
  "Sainte-Foy-la-Grande (France)",
  "Sainte-Foy-les-Lyon (France)",
  "Sainte-Gemme-la-Plaine (France)",
  "Sainte-Gemme-Martaillac (France)",
  "Sainte-Genevieve (France)",
  "Sainte-Helene (France)",
  "Sainte-Hermine (France)",
  "Sainte-Honorine-du-Fay (France)",
  "Sainte-Livrade-sur-Lot (France)",
  "Sainte-Luce-sur-Loire (France)",
  "Sainte-Lucie de Porto-Vecchio (France)",
  "Sainte-Lucie-de-Tallano (France)",
  "Sainte-Marguerite (France)",
  "Sainte-Marguerite-des-Loges (France)",
  "Sainte-Marie (France)",
  "Sainte-Marie-aux-Chenes (France)",
  "Sainte-Marie-aux-Mines (France)",
  "Sainte-Marie-Cappel (France)",
  "Sainte-Marie-de-Chignac (France)",
  "Sainte-Marie-de-Cuines (France)",
  "Sainte-Marie-de-Re (France)",
  "Sainte-Marie-du-Mont (France)",
  "Sainte-Marie-Kerque (France)",
  "Sainte-Marie-Laumont (France)",
  "Sainte-Maure-de-Touraine (France)",
  "Sainte-Maxime (France)",
  "Sainte-Menehould (France)",
  "Sainte-Mere-Eglise (France)",
  "Sainte-Neomaye (France)",
  "Sainte-Opportune-du-Bosc (France)",
  "Sainte-Ouenne (France)",
  "Sainte-Pazanne (France)",
  "Sainte-Preuve (France)",
  "Sainte-Radegonde (France)",
  "Sainte-Reine-de-Bretagne (France)",
  "Sainte-Sabine-sur-Longeve (France)",
  "Sainte-Savine (France)",
  "Sainte-Seve (France)",
  "Sainte-Sigolene (France)",
  "Sainte-Solange (France)",
  "Sainte-Soulle (France)",
  "Sainte-Suzanne (France)",
  "Sainte-Terre (France)",
  "Sainte-Tulle (France)",
  "Sainte-Valiere (France)",
  "Sainte-Verge (France)",
  "Saintes (France)",
  "Saintes-Maries-de-la-Mer (France)",
  "Saintry-sur-Seine (France)",
  "Saints (France)",
  "Saints-Geosmes (France)",
  "Sainville (France)",
  "Saissac (France)",
  "Saisseval (France)",
  "Saivres (France)",
  "Saix (France)",
  "Salagnon (France)",
  "Salaise-sur-Sanne (France)",
  "Salans (France)",
  "Salbert (France)",
  "Salbris (France)",
  "Saleich (France)",
  "Saleilles (France)",
  "Salernes (France)",
  "Salers (France)",
  "Sales (France)",
  "Saleux (France)",
  "Salies-de-Bearn (France)",
  "Salies-du-Salat (France)",
  "Saligny (France)",
  "Saligny-le-Vif (France)",
  "Saligny-sur-Roudon (France)",
  "Salin-de-Giraud (France)",
  "Salindres (France)",
  "Salins (France)",
  "Salins-les-Bains (France)",
  "Salins-les-Thermes (France)",
  "Sallanches (France)",
  "Sallaumines (France)",
  "Salleboeuf (France)",
  "Sallertaine (France)",
  "Salles-de-Barbezieux (France)",
  "Salles-la-Source (France)",
  "Salles-Mongiscard (France)",
  "Salles-sur-Mer (France)",
  "Salome (France)",
  "Salon-de-Provence (France)",
  "Salouel (France)",
  "Salses-le-Chateau (France)",
  "Salvagnac (France)",
  "Salviac (France)",
  "Salvizinet (France)",
  "Salzuit (France)",
  "Samadet (France)",
  "Samatan (France)",
  "Samazan (France)",
  "Sambin (France)",
  "Sameon (France)",
  "Samer (France)",
  "Samognat (France)",
  "Samois-sur-Seine (France)",
  "Samoreau (France)",
  "Sampigny-les-Maranges (France)",
  "Samson (France)",
  "San-Giuliano (France)",
  "San-Nicolao (France)",
  "Sanary-sur-Mer (France)",
  "Sancerre (France)",
  "Sanchey (France)",
  "Sancourt (France)",
  "Sand (France)",
  "Sandillon (France)",
  "Sandouville (France)",
  "Sandrans (France)",
  "Sangatte (France)",
  "Sanguinet (France)",
  "Sannerville (France)",
  "Sannois (France)",
  "Sansais (France)",
  "Sant Andreu de Sureda (France)",
  "Sant Genis de Fontanes (France)",
  "Sant Joan de Pladecorts (France)",
  "Santa-Maria-Poggio (France)",
  "Santeny (France)",
  "Santes (France)",
  "Santeuil (France)",
  "Santranges (France)",
  "Sanvignes-les-Mines (France)",
  "Sanxay (France)",
  "Saone (France)",
  "Sapogne-et-Feucheres (France)",
  "Sapogne-sur-Marche (France)",
  "Saramon (France)",
  "Saran (France)",
  "Sarcelles (France)",
  "Sarcey (France)",
  "Sarcicourt (France)",
  "Sardan (France)",
  "Sardieu (France)",
  "Sardon (France)",
  "Sare (France)",
  "Sarge-sur-Braye (France)",
  "Sarliac-sur-lIsle (France)",
  "Sarralbe (France)",
  "Sarraltroff (France)",
  "Sarrant (France)",
  "Sarras (France)",
  "Sarrazac (France)",
  "Sarre (France)",
  "Sarre-Union (France)",
  "Sarrebourg (France)",
  "Sarreguemines (France)",
  "Sarrey (France)",
  "Sarrians (France)",
  "Sarrola-Carcopino (France)",
  "Sarry (France)",
  "Sars-Poteries (France)",
  "Sartrouville (France)",
  "Sarzeau (France)",
  "Sassay (France)",
  "Sassenage (France)",
  "Sassenay (France)",
  "Sassey-sur-Meuse (France)",
  "Sathonay-Camp (France)",
  "Sathonay-Village (France)",
  "Satillieu (France)",
  "Satolas-et-Bonce (France)",
  "Saubens (France)",
  "Saubion (France)",
  "Saubrigues (France)",
  "Saubusse (France)",
  "Saucats (France)",
  "Saudoy (France)",
  "Saugon (France)",
  "Saugues (France)",
  "Saujon (France)",
  "Saulces-Monclin (France)",
  "Saulcy-sur-Meurthe (France)",
  "Saules (France)",
  "Saulge (France)",
  "Saulieu (France)",
  "Saulnes (France)",
  "Saulnieres (France)",
  "Saulnot (France)",
  "Saulny (France)",
  "Saulon-la-Chapelle (France)",
  "Saulon-la-Rue (France)",
  "Sault (France)",
  "Sault-Brenaz (France)",
  "Sault-les-Rethel (France)",
  "Sault-Saint-Remy (France)",
  "Saultain (France)",
  "Saulx-les-Chartreux (France)",
  "Saulx-Marchais (France)",
  "Saulxures (France)",
  "Saulxures-les-Nancy (France)",
  "Saulxures-sur-Moselotte (France)",
  "Saulzoir (France)",
  "Saumane-de-Vaucluse (France)",
  "Saumeray (France)",
  "Saumur (France)",
  "Saurat (France)",
  "Sauret-Besserve (France)",
  "Sausheim (France)",
  "Saussan (France)",
  "Saussay-la-Campagne (France)",
  "Saussemesnil (France)",
  "Saussenac (France)",
  "Sausset-les-Pins (France)",
  "Saussey (France)",
  "Saussines (France)",
  "Sautel (France)",
  "Sauteyrargues (France)",
  "Sautron (France)",
  "Sauvage (France)",
  "Sauvagney (France)",
  "Sauvagnon (France)",
  "Sauvat (France)",
  "Sauve (France)",
  "Sauverny (France)",
  "Sauvessanges (France)",
  "Sauveterre (France)",
  "Sauveterre-de-Bearn (France)",
  "Sauveterre-de-Comminges (France)",
  "Sauveterre-de-Guyenne (France)",
  "Sauveterre-la-Lemance (France)",
  "Sauvian (France)",
  "Sauvigney-les-Pesmes (France)",
  "Sauvigny-les-Bois (France)",
  "Sauxillanges (France)",
  "Sauze (France)",
  "Sauze-Vaussais (France)",
  "Sauzelles (France)",
  "Sauzet (France)",
  "Sauzon (France)",
  "Savasse (France)",
  "Savenay (France)",
  "Savennieres (France)",
  "Saverdun (France)",
  "Saverne (France)",
  "Savieres (France)",
  "Savignac-les-Eglises (France)",
  "Savignac-sur-Leyze (France)",
  "Savigne (France)",
  "Savigne-sous-le-Lude (France)",
  "Savigneux (France)",
  "Savigny (France)",
  "Savigny-en-Revermont (France)",
  "Savigny-en-Septaine (France)",
  "Savigny-le-Sec (France)",
  "Savigny-le-Temple (France)",
  "Savigny-les-Beaune (France)",
  "Savigny-sur-Braye (France)",
  "Savigny-sur-Clairis (France)",
  "Savigny-sur-Orge (France)",
  "Savoie (France)",
  "Savonnieres (France)",
  "Savouges (France)",
  "Savoyeux (France)",
  "Savy (France)",
  "Savy-Berlette (France)",
  "Sayat (France)",
  "Scaer (France)",
  "Sceaux (France)",
  "Sceaux-du-Gatinais (France)",
  "Sceaux-sur-Huisne (France)",
  "Scharrachbergheim-Irmstett (France)",
  "Scherwiller (France)",
  "Schillersdorf (France)",
  "Schiltigheim (France)",
  "Schirmeck (France)",
  "Schirrhein (France)",
  "Schmittviller (France)",
  "Schnersheim (France)",
  "Schoeneck (France)",
  "Schopperten (France)",
  "Schorbach (France)",
  "Schweighouse-Thann (France)",
  "Schwenheim (France)",
  "Scientrier (France)",
  "Sciez (France)",
  "Scionzier (France)",
  "Scorbe-Clairvaux (France)",
  "Scrignac (France)",
  "Scy-Chazelles (France)",
  "Sebazac-Concoures (France)",
  "Sebecourt (France)",
  "Sebourg (France)",
  "Secheval (France)",
  "Seclin (France)",
  "Secondigny (France)",
  "Sedan (France)",
  "Sedzere (France)",
  "Sees (France)",
  "Seez (France)",
  "Segalas (France)",
  "Segny (France)",
  "Segonzac (France)",
  "Segonzat (France)",
  "Segre (France)",
  "Segrie (France)",
  "Segry (France)",
  "Seichamps (France)",
  "Seiches-sur-le-Loir (France)",
  "Seignalens (France)",
  "Seignelay (France)",
  "Seignosse (France)",
  "Seilh (France)",
  "Seilhac (France)",
  "Seine-Port (France)",
  "Seingbouse (France)",
  "Seissan (France)",
  "Selle (France)",
  "Selles (France)",
  "Selles-Saint-Denis (France)",
  "Selles-sur-Cher (France)",
  "Selles-sur-Nahon (France)",
  "Sellieres (France)",
  "Selommes (France)",
  "Seloncourt (France)",
  "Selongey (France)",
  "Seltz (France)",
  "Semalens (France)",
  "Semblancay (France)",
  "Semeac (France)",
  "Semeacq-Blachon (France)",
  "Semecourt (France)",
  "Semoine (France)",
  "Semoutiers-Montsaon (France)",
  "Semoy (France)",
  "Sempigny (France)",
  "Semur-en-Brionnais (France)",
  "Senan (France)",
  "Senas (France)",
  "Sendets (France)",
  "Sene (France)",
  "Senlecques (France)",
  "Senlis (France)",
  "Sennece-les-Macon (France)",
  "Sennecey-le-Grand (France)",
  "Sennely (France)",
  "Senneville-sur-Fecamp (France)",
  "Senonches (France)",
  "Senones (France)",
  "Senonges (France)",
  "Senozan (France)",
  "Sens (France)",
  "Sens-de-Bretagne (France)",
  "Sentheim (France)",
  "Senuc (France)",
  "Sepmeries (France)",
  "Seppois-le-Bas (France)",
  "Seppois-le-Haut (France)",
  "Sept-Forges (France)",
  "Sept-Saulx (France)",
  "Septeme (France)",
  "Septemes-les-Vallons (France)",
  "Septeuil (France)",
  "Septfonds (France)",
  "Septvaux (France)",
  "Sequedin (France)",
  "Seraincourt (France)",
  "Serans (France)",
  "Seraucourt-le-Grand (France)",
  "Serbannes (France)",
  "Serdinya (France)",
  "Sereilhac (France)",
  "Serein (France)",
  "Seremange-Erzange (France)",
  "Serent (France)",
  "Serezin-de-la-Tour (France)",
  "Serezin-du-Rhone (France)",
  "Sergeac (France)",
  "Sergy (France)",
  "Serifontaine (France)",
  "Serignan (France)",
  "Serignan-du-Comtat (France)",
  "Serigne (France)",
  "Sermaises (France)",
  "Sermamagny (France)",
  "Sermerieu (France)",
  "Sermoise (France)",
  "Sermoyer (France)",
  "Sernhac (France)",
  "Serocourt (France)",
  "Seronville (France)",
  "Serques (France)",
  "Serquigny (France)",
  "Serre-les-Sapins (France)",
  "Serres (France)",
  "Serres-Castet (France)",
  "Serres-Gaston (France)",
  "Serres-Morlaas (France)",
  "Serres-Sainte-Marie (France)",
  "Serres-sur-Arget (France)",
  "Serrieres (France)",
  "Serrieres-de-Briord (France)",
  "Serrieres-en-Chautagne (France)",
  "Serris (France)",
  "Serrouville (France)",
  "Sers (France)",
  "Servais (France)",
  "Servance (France)",
  "Servant (France)",
  "Servas (France)",
  "Servaville-Salmonville (France)",
  "Servian (France)",
  "Servon (France)",
  "Servon-sur-Vilaine (France)",
  "Sery (France)",
  "Sery-les-Mezieres (France)",
  "Sessenheim (France)",
  "Seugy (France)",
  "Seuil (France)",
  "Seuillet (France)",
  "Seurre (France)",
  "Sevelinges (France)",
  "Severac (France)",
  "Severac-le-Chateau (France)",
  "Seveux (France)",
  "Sevran (France)",
  "Sevres-Anxaumont (France)",
  "Sevrey (France)",
  "Sevrier (France)",
  "Sewen (France)",
  "Sexey-aux-Forges (France)",
  "Seyches (France)",
  "Seyne-les-Alpes (France)",
  "Seynod (France)",
  "Seyre (France)",
  "Seyssel (France)",
  "Seysses (France)",
  "Seyssinet (France)",
  "Seyssinet-Pariset (France)",
  "Seyssins (France)",
  "Seyssuel (France)",
  "Sezanne (France)",
  "Sibiril (France)",
  "Siccieu-Saint-Julien-et-Carisieu (France)",
  "Siegen (France)",
  "Sierck-les-Bains (France)",
  "Sierentz (France)",
  "Sierville (France)",
  "Sigean (France)",
  "Sigloy (France)",
  "Signes (France)",
  "Signy (France)",
  "Signy-le-Petit (France)",
  "Signy-Signets (France)",
  "Sigogne (France)",
  "Sigoules (France)",
  "Sigournais (France)",
  "Sigy-en-Bray (France)",
  "Silfiac (France)",
  "Sillans (France)",
  "Sille-le-Guillaume (France)",
  "Sillery (France)",
  "Sillingy (France)",
  "Silly-la-Poterie (France)",
  "Silly-le-Long (France)",
  "Silly-sur-Nied (France)",
  "Silly-Tillard (France)",
  "Simandre (France)",
  "Simandres (France)",
  "Simiane-Collongue (France)",
  "Simorre (France)",
  "Sin-le-Noble (France)",
  "Sinard (France)",
  "Sinceny (France)",
  "Sion-les-Mines (France)",
  "Siran (France)",
  "Sireuil (France)",
  "Siros (France)",
  "Sisco (France)",
  "Sissonne (France)",
  "Sissy (France)",
  "Sisteron (France)",
  "Sivry-Courtry (France)",
  "Sivry-sur-Meuse (France)",
  "Six-Fours-les-Plages (France)",
  "Sizun (France)",
  "Smarves (France)",
  "Sochaux (France)",
  "Soignolles-en-Brie (France)",
  "Soing-Cubry-Charentenay (France)",
  "Soissons (France)",
  "Soissons-sur-Nacey (France)",
  "Soisy-sous-Montmorency (France)",
  "Soisy-sur-Ecole (France)",
  "Soisy-sur-Seine (France)",
  "Soize (France)",
  "Solaize (France)",
  "Solaro (France)",
  "Solers (France)",
  "Solesmes (France)",
  "Soleymieux (France)",
  "Solferino (France)",
  "Solgne (France)",
  "Soliers (France)",
  "Solignac (France)",
  "Solignac-sous-Roche (France)",
  "Solignac-sur-Loire (France)",
  "Soligny-la-Trappe (France)",
  "Sollies-Pont (France)",
  "Sollies-Toucas (France)",
  "Sollies-Ville (France)",
  "Sologny (France)",
  "Somain (France)",
  "Sombacour (France)",
  "Sombernon (France)",
  "Somloire (France)",
  "Sommaing (France)",
  "Somme (France)",
  "Sommecaise (France)",
  "Sommedieue (France)",
  "Sommelonne (France)",
  "Sommerance (France)",
  "Sommervieu (France)",
  "Sommerviller (France)",
  "Sommieres (France)",
  "Sommieres-du-Clain (France)",
  "Sonchamp (France)",
  "Sondernach (France)",
  "Songeons (France)",
  "Sonnay (France)",
  "Sonnaz (France)",
  "Sonzay (France)",
  "Soorts-Hossegor (France)",
  "Sorbey (France)",
  "Sorbiers (France)",
  "Sorcy-Saint-Martin (France)",
  "Sore (France)",
  "Sorede (France)",
  "Sorel-en-Vimeu (France)",
  "Sorel-Moussel (France)",
  "Soreze (France)",
  "Sorges (France)",
  "Sorgues (France)",
  "Sorigny (France)",
  "Sormonne (France)",
  "Sornay (France)",
  "Sorquainville (France)",
  "Sorrus (France)",
  "Sort-en-Chalosse (France)",
  "Sospel (France)",
  "Sottevast (France)",
  "Sotteville (France)",
  "Sotteville-sur-Mer (France)",
  "Souastre (France)",
  "Soubise (France)",
  "Soublecause (France)",
  "Souchez (France)",
  "Soucht (France)",
  "Soucieu-en-Jarrest (France)",
  "Soucy (France)",
  "Soudan (France)",
  "Soues (France)",
  "Souesmes (France)",
  "Souffelweyersheim (France)",
  "Soufflenheim (France)",
  "Sougy (France)",
  "Sougy-sur-Loire (France)",
  "Souilhanels (France)",
  "Souillac (France)",
  "Soulac-sur-Mer (France)",
  "Soulaincourt (France)",
  "Soulaines-Dhuys (France)",
  "Soulaines-sur-Aubance (France)",
  "Soulaire-et-Bourg (France)",
  "Soulanges (France)",
  "Soulangis (France)",
  "Soulge-sur-Ouette (France)",
  "Soulieres (France)",
  "Souligne-sous-Ballon (France)",
  "Soulitre (France)",
  "Soullans (France)",
  "Soultz-Haut-Rhin (France)",
  "Soultz-sous-Forets (France)",
  "Soultzeren (France)",
  "Soultzmatt (France)",
  "Soulvache (France)",
  "Soumeras (France)",
  "Soumoulou (France)",
  "Souppes-sur-Loing (France)",
  "Souprosse (France)",
  "Souraide (France)",
  "Sourcieux-les-Mines (France)",
  "Sourdeval (France)",
  "Sourdon (France)",
  "Sourdun (France)",
  "Sourribes (France)",
  "Sours (France)",
  "Souspierre (France)",
  "Soustons (France)",
  "Souvans (France)",
  "Souvigne (France)",
  "Souvigne-sur-Sarthe (France)",
  "Souvigny (France)",
  "Souvigny-en-Sologne (France)",
  "Souzay-Champigny (France)",
  "Souzy (France)",
  "Souzy-la-Briche (France)",
  "Soyaux (France)",
  "Soyers (France)",
  "Soyons (France)",
  "Spay (France)",
  "Spechbach-le-Bas (France)",
  "Spechbach-le-Haut (France)",
  "Speracedes (France)",
  "Spezet (France)",
  "Spicheren (France)",
  "Spincourt (France)",
  "Sponville (France)",
  "Spycker (France)",
  "Squiffiec (France)",
  "St Chamas (France)",
  "St Laurent des Arbres (France)",
  "St-Malo (France)",
  "Staffelfelden (France)",
  "Stains (France)",
  "Steenbecque (France)",
  "Steenvoorde (France)",
  "Steenwerck (France)",
  "Steige (France)",
  "Steinbach (France)",
  "Steinbourg (France)",
  "Stella-Plage (France)",
  "Stenay (France)",
  "Still (France)",
  "Stiring-Wendel (France)",
  "Stosswihr (France)",
  "Strasbourg (France)",
  "Strazeele (France)",
  "Strueth (France)",
  "Succieu (France)",
  "Suce-sur-Erdre (France)",
  "Sucy-en-Brie (France)",
  "Suhescun (France)",
  "Suin (France)",
  "Suippes (France)",
  "Sully-la-Chapelle (France)",
  "Sully-sur-Loire (France)",
  "Sulniac (France)",
  "Sundhoffen (France)",
  "Supt (France)",
  "Surat (France)",
  "Surba (France)",
  "Suresnes (France)",
  "Surgeres (France)",
  "Surgy (France)",
  "Surin (France)",
  "Surques (France)",
  "Surtainville (France)",
  "Surville (France)",
  "Survilliers (France)",
  "Sury-en-Vaux (France)",
  "Sury-le-Comtal (France)",
  "Surzur (France)",
  "Sus (France)",
  "Sussargues (France)",
  "Suzanne (France)",
  "Suze-la-Rousse (France)",
  "Tabanac (France)",
  "Tacoignieres (France)",
  "Taden (France)",
  "Taglio-Isolaccio (France)",
  "Tagnon (France)",
  "Taillades (France)",
  "Taillebourg (France)",
  "Taillis (France)",
  "Tailly (France)",
  "Taintrux (France)",
  "Taissy (France)",
  "Taize-Aizie (France)",
  "Tajan (France)",
  "Talange (France)",
  "Talant (France)",
  "Talence (France)",
  "Talensac (France)",
  "Tallard (France)",
  "Tallende (France)",
  "Taller (France)",
  "Talloires (France)",
  "Tallud-Sainte-Gemme (France)",
  "Talon (France)",
  "Taluyers (France)",
  "Tamnay-en-Bazois (France)",
  "Tancrou (France)",
  "Taninges (France)",
  "Tanneron (France)",
  "Taradeau (France)",
  "Tarare (France)",
  "Tarascon (France)",
  "Tarascon-sur-Ariege (France)",
  "Tarbes (France)",
  "Tarcenay (France)",
  "Tarentaise (France)",
  "Targassonne (France)",
  "Targon (France)",
  "Tarnes (France)",
  "Tarnos (France)",
  "Tartaras (France)",
  "Tartas (France)",
  "Tartigny (France)",
  "Tassille (France)",
  "Tassin-la-Demi-Lune (France)",
  "Tatinghem (France)",
  "Taule (France)",
  "Taulignan (France)",
  "Taupont (France)",
  "Tauriac (France)",
  "Tauriac-de-Camares (France)",
  "Tautavel (France)",
  "Tauxieres-Mutry (France)",
  "Tauxigny (France)",
  "Tavaux (France)",
  "Tavel (France)",
  "Tavera (France)",
  "Tavernes (France)",
  "Taverny (France)",
  "Tavers (France)",
  "Tavey (France)",
  "Tayrac (France)",
  "Tecou (France)",
  "Teillet-Argenty (France)",
  "Teissieres-de-Cornet (France)",
  "Telgruc-sur-Mer (France)",
  "Teloche (France)",
  "Templemars (France)",
  "Templeuve (France)",
  "Tence (France)",
  "Tende (France)",
  "Tendon (France)",
  "Terce (France)",
  "Tercis-les-Bains (France)",
  "Terdeghem (France)",
  "Tergnier (France)",
  "Ternand (France)",
  "Ternay (France)",
  "Terrasson-Lavilledieu (France)",
  "Terraube (France)",
  "Terrebasse (France)",
  "Terssac (France)",
  "Terves (France)",
  "Terville (France)",
  "Tessy-sur-Vire (France)",
  "Teteghem (France)",
  "Teuillac (France)",
  "Teurtheville-Hague (France)",
  "Teyjat (France)",
  "Teyran (France)",
  "Thaims (France)",
  "Thaire (France)",
  "Thal-Drulingen (France)",
  "Thann (France)",
  "Thaon (France)",
  "Thaon-les-Vosges (France)",
  "Thauron (France)",
  "Theding (France)",
  "Thegra (France)",
  "Theil-sur-Vanne (France)",
  "Theillay (France)",
  "Theix (France)",
  "Theize (France)",
  "Thelus (France)",
  "Themericourt (France)",
  "Thenay (France)",
  "Thenelles (France)",
  "Thenezay (France)",
  "Thenissey (France)",
  "Thennelieres (France)",
  "Thennes (France)",
  "Thenon (France)",
  "Theoule-sur-Mer (France)",
  "Therdonne (France)",
  "Therouanne (France)",
  "Theuville (France)",
  "Theuville-aux-Maillots (France)",
  "Theys (France)",
  "Thezan-les-Beziers (France)",
  "Theze (France)",
  "Theziers (France)",
  "Thezy-Glimont (France)",
  "Thiais (France)",
  "Thiant (France)",
  "Thiberville (France)",
  "Thiefosse (France)",
  "Thiergeville (France)",
  "Thiers (France)",
  "Thiers-sur-Theve (France)",
  "Thierville (France)",
  "Thierville-sur-Meuse (France)",
  "Thietreville (France)",
  "Thieuloy-Saint-Antoine (France)",
  "Thieville (France)",
  "Thil (France)",
  "Thilay (France)",
  "Thilouze (France)",
  "Thimert-Gatelles (France)",
  "Thimory (France)",
  "Thin-le-Moutier (France)",
  "Thionville (France)",
  "Thise (France)",
  "Thivars (France)",
  "Thivencelle (France)",
  "Thiverval-Grignon (France)",
  "Thiviers (France)",
  "Thizy-les-Bourgs (France)",
  "Thodure (France)",
  "Thoirette (France)",
  "Thoiry (France)",
  "Thoissey (France)",
  "Thomery (France)",
  "Thones (France)",
  "Thonnance-les-Joinville (France)",
  "Thonon-les-Bains (France)",
  "Thoraise (France)",
  "Thorens-Glieres (France)",
  "Thorigny (France)",
  "Thorigny-sur-Marne (France)",
  "Thou (France)",
  "Thouare-sur-Loire (France)",
  "Thouars (France)",
  "Thourotte (France)",
  "Thoux (France)",
  "Thuir (France)",
  "Thuit-Hebert (France)",
  "Thulay (France)",
  "Thumeries (France)",
  "Thun-Saint-Amand (France)",
  "Thurageau (France)",
  "Thure (France)",
  "Thuret (France)",
  "Thurins (France)",
  "Thury-Harcourt (France)",
  "Thusy (France)",
  "Thyez (France)",
  "Tierce (France)",
  "Tiercelet (France)",
  "Tigery (France)",
  "Tignes (France)",
  "Tignieu-Jameyzieu (France)",
  "Tigy (France)",
  "Til-Chatel (France)",
  "Tillay-le-Peneux (France)",
  "Tille (France)",
  "Tillenay (France)",
  "Tillieres (France)",
  "Tillieres-sur-Avre (France)",
  "Tilloy-et-Bellay (France)",
  "Tilloy-lez-Cambrai (France)",
  "Tilloy-lez-Marchiennes (France)",
  "Tilly-sur-Meuse (France)",
  "Tilques (France)",
  "Tinchebray (France)",
  "Tincques (France)",
  "Tincry (France)",
  "Tinqueux (France)",
  "Tinteniac (France)",
  "Tiranges (France)",
  "Tirepied (France)",
  "Tivernon (France)",
  "Tocqueville (France)",
  "Tocqueville-en-Caux (France)",
  "Tollevast (France)",
  "Tombeboeuf (France)",
  "Tomblaine (France)",
  "Tonnay-Boutonne (France)",
  "Tonnay-Charente (France)",
  "Tonneins (France)",
  "Tonnerre (France)",
  "Tonnoy (France)",
  "Torce-en-Vallee (France)",
  "Torcieu (France)",
  "Torcy (France)",
  "Torcy-le-Grand (France)",
  "Torderes (France)",
  "Torfou (France)",
  "Torigni-sur-Vire (France)",
  "Torpes (France)",
  "Torreilles (France)",
  "Torsac (France)",
  "Torvilliers (France)",
  "Torxe (France)",
  "Tosse (France)",
  "Tossiat (France)",
  "Totes (France)",
  "Touchay (France)",
  "Toucy (France)",
  "Toudon (France)",
  "Toufflers (France)",
  "Toul (France)",
  "Toulaud (France)",
  "Toulenne (France)",
  "Toulon (France)",
  "Toulon-sur-Arroux (France)",
  "Toulouges (France)",
  "Toulouse (France)",
  "Toulouzette (France)",
  "Toulx-Sainte-Croix (France)",
  "Touques (France)",
  "Touquin (France)",
  "Tour-en-Sologne (France)",
  "Tourbes (France)",
  "Tourcoing (France)",
  "Tourgeville (France)",
  "Tourlaville (France)",
  "Tourly (France)",
  "Tournan-en-Brie (France)",
  "Tournay-sur-Odon (France)",
  "Tournecoupe (France)",
  "Tournedos-Bois-Hubert (France)",
  "Tournefeuille (France)",
  "Tournehem-sur-la-Hem (France)",
  "Tournieres (France)",
  "Tournoisis (France)",
  "Tournon-Saint-Martin (France)",
  "Tournus (France)",
  "Tourouvre (France)",
  "Tourrette-Levens (France)",
  "Tourrettes-sur-Loup (France)",
  "Tourriers (France)",
  "Tours (France)",
  "Tours-en-Savoie (France)",
  "Tours-sur-Marne (France)",
  "Tours-sur-Meymont (France)",
  "Tourtrol (France)",
  "Tourves (France)",
  "Tourville-en-Auge (France)",
  "Tourville-la-Campagne (France)",
  "Tourville-la-Chapelle (France)",
  "Tourville-la-Riviere (France)",
  "Tourville-les-Ifs (France)",
  "Tourville-sur-Odon (France)",
  "Tourville-sur-Sienne (France)",
  "Toury (France)",
  "Toussaint (France)",
  "Toussieu (France)",
  "Toussieux (France)",
  "Toussus-le-Noble (France)",
  "Toutlemonde (France)",
  "Touville (France)",
  "Touvois (France)",
  "Touvre (France)",
  "Touzac (France)",
  "Tracy-le-Mont (France)",
  "Tracy-le-Val (France)",
  "Tracy-sur-Loire (France)",
  "Traenheim (France)",
  "Trainel (France)",
  "Trainou (France)",
  "Tramole (France)",
  "Tramoyes (France)",
  "Trange (France)",
  "Trans-en-Provence (France)",
  "Trappes (France)",
  "Traubach-le-Bas (France)",
  "Trausse (France)",
  "Travecy (France)",
  "Treauville (France)",
  "Trebes (France)",
  "Trebeurden (France)",
  "Trebry (France)",
  "Treclun (France)",
  "Tredarzec (France)",
  "Treduder (France)",
  "Treffendel (France)",
  "Treffieux (France)",
  "Trefflean (France)",
  "Treffort (France)",
  "Treffrin (France)",
  "Treflez (France)",
  "Tregarantec (France)",
  "Tregastel (France)",
  "Treglamus (France)",
  "Tregourez (France)",
  "Treguidel (France)",
  "Tregunc (France)",
  "Treignac (France)",
  "Treillieres (France)",
  "Treize-Septiers (France)",
  "Trelaze (France)",
  "Trelevern (France)",
  "Trelissac (France)",
  "Trelivan (France)",
  "Trelly (France)",
  "Trelon (France)",
  "Tremblay (France)",
  "Tremel (France)",
  "Trementines (France)",
  "Tremery (France)",
  "Tremeven (France)",
  "Tremilly (France)",
  "Tremons (France)",
  "Tremont-sur-Saulx (France)",
  "Tremuson (France)",
  "Treon (France)",
  "Trepied (France)",
  "Trept (France)",
  "Tresilley (France)",
  "Tresques (France)",
  "Tressan (France)",
  "Tressange (France)",
  "Tresserve (France)",
  "Tresses (France)",
  "Trets (France)",
  "Treuzy-Levelay (France)",
  "Treve (France)",
  "Treveneuc (France)",
  "Treveray (France)",
  "Treverien (France)",
  "Treves (France)",
  "Trevien (France)",
  "Trevignin (France)",
  "Trevoux (France)",
  "Trezioux (France)",
  "Triaize (France)",
  "Tricot (France)",
  "Trie-Chateau (France)",
  "Trie-sur-Baise (France)",
  "Triel-sur-Seine (France)",
  "Trieux (France)",
  "Trigavou (France)",
  "Trignac (France)",
  "Trigueres (France)",
  "Trilbardou (France)",
  "Trilport (France)",
  "Triors (France)",
  "Trith-Saint-Leger (France)",
  "Trizac (France)",
  "Trizay (France)",
  "Troarn (France)",
  "Troche (France)",
  "Trogues (France)",
  "Troissereux (France)",
  "Troissy (France)",
  "Troisvilles (France)",
  "Tronville-en-Barrois (France)",
  "Trosly-Breuil (France)",
  "Trouhans (France)",
  "Trouillas (France)",
  "Trouley-Labarthe (France)",
  "Troussencourt (France)",
  "Troussey (France)",
  "Trouville (France)",
  "Trouville-la-Haule (France)",
  "Trouville-sur-Mer (France)",
  "Trouy (France)",
  "Troyes (France)",
  "Troyon (France)",
  "Truchtersheim (France)",
  "Trumilly (France)",
  "Truyes (France)",
  "Tubersent (France)",
  "Tuchan (France)",
  "Tucquegnieux (France)",
  "Tuffe (France)",
  "Tulle (France)",
  "Tullins (France)",
  "Tupigny (France)",
  "Turckheim (France)",
  "Turquant (France)",
  "Tursac (France)",
  "Tuzaguet (France)",
  "Uberach (France)",
  "Uchacq-et-Parentis (France)",
  "Uchaud (France)",
  "Uchaux (France)",
  "Uckange (France)",
  "Ueberstrass (France)",
  "Ugine (France)",
  "Ugny-sur-Meuse (France)",
  "Uhrwiller (France)",
  "Ully-Saint-Georges (France)",
  "Umpeau (France)",
  "Undurein (France)",
  "Ungersheim (France)",
  "Unieux (France)",
  "Ur (France)",
  "Urbeis (France)",
  "Urcay (France)",
  "Urcel (France)",
  "Urcuit (France)",
  "Urdos (France)",
  "Uriage-les-Bains (France)",
  "Urmatt (France)",
  "Urou-et-Crennes (France)",
  "Urrugne (France)",
  "Urt (France)",
  "Uruffe (France)",
  "Urval (France)",
  "Urville-Nacqueville (France)",
  "Ury (France)",
  "Urzy (France)",
  "Us (France)",
  "Ussac (France)",
  "Usseau (France)",
  "Ussel (France)",
  "Usson-du-Poitou (France)",
  "Ussy (France)",
  "Ustaritz (France)",
  "Utelle (France)",
  "Uvernet-Fours (France)",
  "Uxegney (France)",
  "Uxem (France)",
  "Uzein (France)",
  "Uzerche (France)",
  "Uzos (France)",
  "Vaas (France)",
  "Vacheresse (France)",
  "Vacognes-Neuilly (France)",
  "Vacon (France)",
  "Vacquieres (France)",
  "Vacquiers (France)",
  "Vadenay (France)",
  "Vadencourt (France)",
  "Vagney (France)",
  "Vahl-les-Benestroff (France)",
  "Vailhauques (France)",
  "Vaillant (France)",
  "Vailly (France)",
  "Vailly-sur-Aisne (France)",
  "Vailly-sur-Sauldre (France)",
  "Vaire (France)",
  "Vaire-sous-Corbie (France)",
  "Vaires-sur-Marne (France)",
  "Vaison-la-Romaine (France)",
  "Vaissac (France)",
  "Vaivre-et-Montoille (France)",
  "Val-de-Fier (France)",
  "Val-de-la-Haye (France)",
  "Val-et-Chatillon (France)",
  "Valaire (France)",
  "Valay (France)",
  "Valberg (France)",
  "Valbonne (France)",
  "Valcourt (France)",
  "Valdahon (France)",
  "Valdampierre (France)",
  "Valdoie (France)",
  "Valdurenque (France)",
  "Valencay (France)",
  "Valence (France)",
  "Valence-sur-Baise (France)",
  "Valenciennes (France)",
  "Valencin (France)",
  "Valencogne (France)",
  "Valensole (France)",
  "Valentigney (France)",
  "Valenton (France)",
  "Valescourt (France)",
  "Valff (France)",
  "Valflaunes (France)",
  "Valines (France)",
  "Vallangoujard (France)",
  "Vallans (France)",
  "Vallant-Saint-Georges (France)",
  "Vallauris (France)",
  "Valle-di-Mezzana (France)",
  "Vallegue (France)",
  "Valleiry (France)",
  "Valleraugue (France)",
  "Valleres (France)",
  "Vallerois-Lorioz (France)",
  "Valleroy-le-Sec (France)",
  "Vallesvilles (France)",
  "Vallet (France)",
  "Vallier (France)",
  "Valliguieres (France)",
  "Valliquerville (France)",
  "Vallon-en-Sully (France)",
  "Vallouise (France)",
  "Valmestroff (France)",
  "Valmondois (France)",
  "Valmont (France)",
  "Valmy (France)",
  "Valognes (France)",
  "Valpuiseaux (France)",
  "Valras-Plage (France)",
  "Valreas (France)",
  "Valros (France)",
  "Vals-les-Bains (France)",
  "Vals-pres-le-Puy (France)",
  "Vanclans (France)",
  "Vandieres (France)",
  "Vandoeuvre-les-Nancy (France)",
  "Vandoncourt (France)",
  "Vandre (France)",
  "Vandrimare (France)",
  "Vandy (France)",
  "Vannes (France)",
  "Vanosc (France)",
  "Vantoux (France)",
  "Vanves (France)",
  "Vanville (France)",
  "Vanzac (France)",
  "Varades (France)",
  "Varages (France)",
  "Varaignes (France)",
  "Varaize (France)",
  "Varanges (France)",
  "Varangeville (France)",
  "Varaville (France)",
  "Varces-Allieres-et-Risset (France)",
  "Varen (France)",
  "Varennes (France)",
  "Varennes-en-Argonne (France)",
  "Varennes-Jarcy (France)",
  "Varennes-le-Grand (France)",
  "Varennes-les-Macon (France)",
  "Varennes-sous-Dun (France)",
  "Varennes-sur-Allier (France)",
  "Varennes-sur-Loire (France)",
  "Varennes-sur-Seine (France)",
  "Varennes-Vauzelles (France)",
  "Varesnes (France)",
  "Varetz (France)",
  "Varilhes (France)",
  "Varinfroy (France)",
  "Varois-et-Chaignot (France)",
  "Varrains (France)",
  "Varreddes (France)",
  "Vars (France)",
  "Varsberg (France)",
  "Varzay (France)",
  "Varzy (France)",
  "Vasles (France)",
  "Vassel (France)",
  "Vasselay (France)",
  "Vassy (France)",
  "Vasteville (France)",
  "Vatan (France)",
  "Vathimenil (France)",
  "Vatimesnil (France)",
  "Vauban (France)",
  "Vaubecourt (France)",
  "Vauchamps (France)",
  "Vauchelles (France)",
  "Vauchelles-les-Domart (France)",
  "Vauchelles-les-Quesnoy (France)",
  "Vauchonvilliers (France)",
  "Vaucouleurs (France)",
  "Vaucresson (France)",
  "Vaudebarrier (France)",
  "Vaudemange (France)",
  "Vaudesson (France)",
  "Vaudeville (France)",
  "Vaudoy-en-Brie (France)",
  "Vaudreching (France)",
  "Vaudrey (France)",
  "Vaugneray (France)",
  "Vaugrigneuse (France)",
  "Vauhallan (France)",
  "Vaujours (France)",
  "Vaulry (France)",
  "Vault-de-Lugny (France)",
  "Vaulx-en-Velin (France)",
  "Vaulx-Milieu (France)",
  "Vaumas (France)",
  "Vaumoise (France)",
  "Vaumort (France)",
  "Vaureal (France)",
  "Vaureilles (France)",
  "Vauvert (France)",
  "Vauville (France)",
  "Vauvillers (France)",
  "Vaux-en-Bugey (France)",
  "Vaux-la-Petite (France)",
  "Vaux-le-Penil (France)",
  "Vaux-les-Pres (France)",
  "Vaux-les-Saint-Claude (France)",
  "Vaux-sur-Aure (France)",
  "Vaux-sur-Blaise (France)",
  "Vaux-sur-Eure (France)",
  "Vaux-sur-Mer (France)",
  "Vaux-sur-Poligny (France)",
  "Vaux-sur-Seine (France)",
  "Vauxbuin (France)",
  "Vauxtin (France)",
  "Vaxoncourt (France)",
  "Vay (France)",
  "Vayrac (France)",
  "Vayres (France)",
  "Veauche (France)",
  "Veaugues (France)",
  "Vecoux (France)",
  "Vecquemont (France)",
  "Vecqueville (France)",
  "Vedene (France)",
  "Veho (France)",
  "Veigne (France)",
  "Veigy-Foncenex (France)",
  "Velaine-en-Haye (France)",
  "Velaines (France)",
  "Velars-sur-Ouche (France)",
  "Velaux (France)",
  "Velines (France)",
  "Velle-le-Chatel (France)",
  "Velleches (France)",
  "Velleminfroy (France)",
  "Velleron (France)",
  "Velotte-et-Tatignecourt (France)",
  "Velye (France)",
  "Velzic (France)",
  "Vemars (France)",
  "Venables (France)",
  "Venansault (France)",
  "Venant (France)",
  "Venarey-les-Laumes (France)",
  "Venasque (France)",
  "Vence (France)",
  "Vendargues (France)",
  "Vendat (France)",
  "Vendays-Montalivet (France)",
  "Vendegies-sur-Ecaillon (France)",
  "Vendemian (France)",
  "Vendenheim (France)",
  "Vendeuil-Caply (France)",
  "Vendeuvre-du-Poitou (France)",
  "Vendeville (France)",
  "Vendin-le-Vieil (France)",
  "Vendin-les-Bethune (France)",
  "Vendome (France)",
  "Vendrennes (France)",
  "Vendres (France)",
  "Venejan (France)",
  "Venelles (France)",
  "Venerand (France)",
  "Venerque (France)",
  "Venette (France)",
  "Veneux-les-Sablons (France)",
  "Venizel (France)",
  "Venizy (France)",
  "Vennecy (France)",
  "Venon (France)",
  "Venoy (France)",
  "Ventabren (France)",
  "Ventes-Saint-Remy (France)",
  "Venteuil (France)",
  "Venthon (France)",
  "Ventiseri (France)",
  "Ver-sur-Launette (France)",
  "Verac (France)",
  "Verargues (France)",
  "Verberie (France)",
  "Verchaix (France)",
  "Verchamp (France)",
  "Verchin (France)",
  "Verdelais (France)",
  "Verderonne (France)",
  "Verdon (France)",
  "Verdun (France)",
  "Verdun-en-Lauragais (France)",
  "Verdun-sur-Garonne (France)",
  "Verdun-sur-le-Doubs (France)",
  "Veretz (France)",
  "Verfeil (France)",
  "Vergeze (France)",
  "Vergongheon (France)",
  "Vergt (France)",
  "Veria (France)",
  "Verigny (France)",
  "Verines (France)",
  "Verjux (France)",
  "Verlinghem (France)",
  "Vermand (France)",
  "Vermelles (France)",
  "Vermenton (France)",
  "Vern-sur-Seiche (France)",
  "Vernaison (France)",
  "Verne (France)",
  "Vernegues (France)",
  "Verneil-le-Chetif (France)",
  "Vernet-les-Bains (France)",
  "Verneugheol (France)",
  "Verneuil (France)",
  "Verneuil-en-Bourbonnais (France)",
  "Verneuil-en-Halatte (France)",
  "Verneuil-sous-Coucy (France)",
  "Verneuil-sur-Avre (France)",
  "Verneuil-sur-Seine (France)",
  "Verneuil-sur-Vienne (France)",
  "Vernierfontaine (France)",
  "Vernines (France)",
  "Verniolle (France)",
  "Vernioz (France)",
  "Vernon (France)",
  "Vernosc-les-Annonay (France)",
  "Vernou-sur-Brenne (France)",
  "Vernouillet (France)",
  "Vernoux-en-Vivarais (France)",
  "Verny (France)",
  "Veron (France)",
  "Verpillieres (France)",
  "Verquieres (France)",
  "Verquigneul (France)",
  "Verquin (France)",
  "Verrey-sous-Salmaise (France)",
  "Verrieres (France)",
  "Verrieres-le-Buisson (France)",
  "Vers (France)",
  "Vers-en-Montagne (France)",
  "Vers-Pont-du-Gard (France)",
  "Vers-sur-Selles (France)",
  "Versailles (France)",
  "Versailleux (France)",
  "Versigny (France)",
  "Verson (France)",
  "Versonnex (France)",
  "Vert (France)",
  "Vert-en-Drouais (France)",
  "Vert-le-Grand (France)",
  "Vert-le-Petit (France)",
  "Vert-Saint-Denis (France)",
  "Vertaizon (France)",
  "Verthemex (France)",
  "Vertolaye (France)",
  "Verton (France)",
  "Vertou (France)",
  "Vertrieu (France)",
  "Vertus (France)",
  "Vervezelle (France)",
  "Vervins (France)",
  "Very (France)",
  "Verze (France)",
  "Verzeille (France)",
  "Verzenay (France)",
  "Vescovato (France)",
  "Vesigneul-sur-Coole (France)",
  "Vesly (France)",
  "Vesoul (France)",
  "Vestric-et-Candiac (France)",
  "Vetheuil (France)",
  "Vetraz-Monthoux (France)",
  "Veuil (France)",
  "Veurey-Voroize (France)",
  "Veynes (France)",
  "Veyrac (France)",
  "Veyras (France)",
  "Veyre-Monton (France)",
  "Veyrier-du-Lac (France)",
  "Veyrignac (France)",
  "Vezac (France)",
  "Vezelise (France)",
  "Vezenobres (France)",
  "Vezezoux (France)",
  "Vezin-le-Coquet (France)",
  "Vezins (France)",
  "Viabon (France)",
  "Vialas (France)",
  "Vianne (France)",
  "Viarmes (France)",
  "Vias (France)",
  "Vibersviller (France)",
  "Vibraye (France)",
  "Vic-en-Bigorre (France)",
  "Vic-Fezensac (France)",
  "Vic-la-Gardiole (France)",
  "Vic-le-Comte (France)",
  "Vic-le-Fesq (France)",
  "Vic-sur-Aisne (France)",
  "Vic-sur-Cere (France)",
  "Vic-sur-Seille (France)",
  "Vichy (France)",
  "Vico (France)",
  "Vicq (France)",
  "Vidauban (France)",
  "Videix (France)",
  "Vieille-Brioude (France)",
  "Vieille-Eglise (France)",
  "Vieille-Eglise-en-Yvelines (France)",
  "Vieillevigne (France)",
  "Vieilley (France)",
  "Vielle-Saint-Girons (France)",
  "Vielle-Tursan (France)",
  "Viellespeze (France)",
  "Vielmur-sur-Agout (France)",
  "Viels-Maisons (France)",
  "Vienne (France)",
  "Vienville (France)",
  "Vierville (France)",
  "Vierzon (France)",
  "Viesly (France)",
  "Viessoix (France)",
  "Vieux (France)",
  "Vieux Conde (France)",
  "Vieux-Berquin (France)",
  "Vieux-Boucau-les-Bains (France)",
  "Vieux-Charmont (France)",
  "Vieux-Conde (France)",
  "Vieux-Ferrette (France)",
  "Vieux-Fume (France)",
  "Vieux-Manoir (France)",
  "Vieux-Moulin (France)",
  "Vieux-Rouen-sur-Bresle (France)",
  "Vieux-Thann (France)",
  "Vif (France)",
  "Vigeois (France)",
  "Vignacourt (France)",
  "Vignes (France)",
  "Vigneulles (France)",
  "Vigneulles-les-Hattonchatel (France)",
  "Vigneux-de-Bretagne (France)",
  "Vigneux-sur-Seine (France)",
  "Vignoc (France)",
  "Vignolles (France)",
  "Vignols (France)",
  "Vignonet (France)",
  "Vignot (France)",
  "Vigny (France)",
  "Vigoulet-Auzil (France)",
  "Vigouroux (France)",
  "Vigueron (France)",
  "Vigy (France)",
  "Vihiers (France)",
  "Vilallonga dels Monts (France)",
  "Villabe (France)",
  "Villabon (France)",
  "Villacourt (France)",
  "Village-Neuf (France)",
  "Villaines-la-Juhel (France)",
  "Villaines-les-Rochers (France)",
  "Villaines-sous-Bois (France)",
  "Villaines-sous-Luce (France)",
  "Villaines-sous-Malicorne (France)",
  "Villamblard (France)",
  "Villamee (France)",
  "Villard (France)",
  "Villard-Bonnot (France)",
  "Villard-de-Lans (France)",
  "Villard-Saint-Christophe (France)",
  "Villard-sur-Doron (France)",
  "Villards (France)",
  "Villargondran (France)",
  "Villaries (France)",
  "Villarlurin (France)",
  "Villars (France)",
  "Villars-Brandis (France)",
  "Villars-Colmars (France)",
  "Villars-en-Pons (France)",
  "Villars-et-Villenotte (France)",
  "Villars-les-Dombes (France)",
  "Villars-sur-Var (France)",
  "Villarzel-Cabardes (France)",
  "Villasavary (France)",
  "Villaz (France)",
  "Ville (France)",
  "Ville-du-Pont (France)",
  "Ville-en-Tardenois (France)",
  "Ville-la-Grand (France)",
  "Ville-sous-Anjou (France)",
  "Ville-sur-Cousances (France)",
  "Ville-sur-Jarnioux (France)",
  "Ville-sur-Lumes (France)",
  "Ville-sur-Tourbe (France)",
  "Villebarou (France)",
  "Villebernier (France)",
  "Villeblevin (France)",
  "Villebois-Lavalette (France)",
  "Villebon-sur-Yvette (France)",
  "Villebret (France)",
  "Villebrumier (France)",
  "Villecerf (France)",
  "Villeconin (France)",
  "Villecresnes (France)",
  "Villecroze (France)",
  "Villedieu (France)",
  "Villedieu-les-Poeles (France)",
  "Villedomer (France)",
  "Villedoux (France)",
  "Villefagnan (France)",
  "Villefontaine (France)",
  "Villefranche-de-Lauragais (France)",
  "Villefranche-de-Rouergue (France)",
  "Villefranche-du-Queyran (France)",
  "Villefranche-le-Chateau (France)",
  "Villefranche-sur-Cher (France)",
  "Villefranche-sur-Mer (France)",
  "Villefranque (France)",
  "Villegailhenc (France)",
  "Villegats (France)",
  "Villegouge (France)",
  "Villejesus (France)",
  "Villejoubert (France)",
  "Villejuif (France)",
  "Villejust (France)",
  "Villelaure (France)",
  "Villelongue-de-la-Salanque (France)",
  "Villemandeur (France)",
  "Villemarechal (France)",
  "Villembray (France)",
  "Villemeux-sur-Eure (France)",
  "Villemoirieu (France)",
  "Villemoisson-sur-Orge (France)",
  "Villemolaque (France)",
  "Villemomble (France)",
  "Villemort (France)",
  "Villemotier (France)",
  "Villemoustaussou (France)",
  "Villemoyenne (France)",
  "Villemur (France)",
  "Villemur-sur-Tarn (France)",
  "Villemurlin (France)",
  "Villenauxe-la-Grande (France)",
  "Villenave (France)",
  "Villeneuve (France)",
  "Villeneuve les beziers (France)",
  "Villeneuve-au-Chemin (France)",
  "Villeneuve-de-Berg (France)",
  "Villeneuve-de-Duras (France)",
  "Villeneuve-de-la-Raho (France)",
  "Villeneuve-de-Marc (France)",
  "Villeneuve-de-Marsan (France)",
  "Villeneuve-des-Escaldes (France)",
  "Villeneuve-Frouville (France)",
  "Villeneuve-la-Garenne (France)",
  "Villeneuve-la-Guyard (France)",
  "Villeneuve-la-Riviere (France)",
  "Villeneuve-le-Comte (France)",
  "Villeneuve-le-Roi (France)",
  "Villeneuve-Lecussan (France)",
  "Villeneuve-les-Avignon (France)",
  "Villeneuve-les-Bouloc (France)",
  "Villeneuve-les-Genets (France)",
  "Villeneuve-les-Maguelone (France)",
  "Villeneuve-les-Sablons (France)",
  "Villeneuve-Loubet (France)",
  "Villeneuve-Minervois (France)",
  "Villeneuve-Saint-Georges (France)",
  "Villeneuve-Saint-Germain (France)",
  "Villeneuve-sur-Allier (France)",
  "Villeneuve-sur-Fere (France)",
  "Villeneuve-sur-Lot (France)",
  "Villeneuve-sur-Yonne (France)",
  "Villeneuve-Tolosane (France)",
  "Villennes-sur-Seine (France)",
  "Villenouvelle (France)",
  "Villenoy (France)",
  "Villeny (France)",
  "Villeparisis (France)",
  "Villeperdue (France)",
  "Villepinte (France)",
  "Villepreux (France)",
  "Villequier-Aumont (France)",
  "Villerable (France)",
  "Villerest (France)",
  "Villermain (France)",
  "Villeron (France)",
  "Villers (France)",
  "Villers-Allerand (France)",
  "Villers-au-Bois (France)",
  "Villers-au-Tertre (France)",
  "Villers-aux-Vents (France)",
  "Villers-Bocage (France)",
  "Villers-Bretonneux (France)",
  "Villers-Canivet (France)",
  "Villers-Farlay (France)",
  "Villers-Helon (France)",
  "Villers-la-Montagne (France)",
  "Villers-le-Lac (France)",
  "Villers-le-Sec (France)",
  "Villers-les-Nancy (France)",
  "Villers-les-Ormes (France)",
  "Villers-les-Pots (France)",
  "Villers-les-Roye (France)",
  "Villers-Outreaux (France)",
  "Villers-Plouich (France)",
  "Villers-Pol (France)",
  "Villers-Saint-Frambourg (France)",
  "Villers-Saint-Genest (France)",
  "Villers-Saint-Paul (France)",
  "Villers-Saint-Sepulcre (France)",
  "Villers-Semeuse (France)",
  "Villers-Sire-Nicole (France)",
  "Villers-sous-Preny (France)",
  "Villers-sous-Saint-Leu (France)",
  "Villers-sur-Fere (France)",
  "Villers-sur-le-Mont (France)",
  "Villers-sur-Mer (France)",
  "Villers-sur-Meuse (France)",
  "Villers-sur-Port (France)",
  "Villers-sur-Saulnot (France)",
  "Villersexel (France)",
  "Villerupt (France)",
  "Villerville (France)",
  "Villes-sur-Auzon (France)",
  "Villesequelande (France)",
  "Villesiscle (France)",
  "Villetaneuse (France)",
  "Villetelle (France)",
  "Villeton (France)",
  "Villetrun (France)",
  "Villette (France)",
  "Villettes (France)",
  "Villeurbanne (France)",
  "Villevallier (France)",
  "Villevaude (France)",
  "Villeve (France)",
  "Villeveque (France)",
  "Villeveyrac (France)",
  "Villevieux (France)",
  "Villevillon (France)",
  "Villevocance (France)",
  "Villexavier (France)",
  "Villey-Saint-Etienne (France)",
  "Villey-sur-Tille (France)",
  "Villez-sous-Bailleul (France)",
  "Villie-Morgon (France)",
  "Villiers-Adam (France)",
  "Villiers-au-Bouin (France)",
  "Villiers-Couture (France)",
  "Villiers-en-Desoeuvre (France)",
  "Villiers-en-Plaine (France)",
  "Villiers-le-Bacle (France)",
  "Villiers-le-Bel (France)",
  "Villiers-le-Mahieu (France)",
  "Villiers-Saint-Georges (France)",
  "Villiers-sous-Grez (France)",
  "Villiers-sur-Chize (France)",
  "Villiers-sur-Marne (France)",
  "Villiers-sur-Morin (France)",
  "Villiers-sur-Orge (France)",
  "Villiers-sur-Seine (France)",
  "Villiers-sur-Tholon (France)",
  "Villieu-Loyes-Mollon (France)",
  "Villing (France)",
  "Villons-les-Buissons (France)",
  "Villorceau (France)",
  "Villotte-sur-Aire (France)",
  "Villy-Bocage (France)",
  "Villy-le-Bouveret (France)",
  "Villy-le-Pelloux (France)",
  "Vimines (France)",
  "Vimory (France)",
  "Vimoutiers (France)",
  "Vimy (France)",
  "Vinassan (France)",
  "Vinay (France)",
  "Vinca (France)",
  "Vincelles (France)",
  "Vincennes (France)",
  "Vincent (France)",
  "Vincey (France)",
  "Vincly (France)",
  "Vindelle (France)",
  "Vineuil (France)",
  "Vineuil-Saint-Firmin (France)",
  "Vinneuf (France)",
  "Vinon-sur-Verdon (France)",
  "Vinzelles (France)",
  "Vinzier (France)",
  "Viodos-Abense-de-Bas (France)",
  "Violaines (France)",
  "Violay (France)",
  "Viomenil (France)",
  "Vion (France)",
  "Viplaix (France)",
  "Vire (France)",
  "Virelade (France)",
  "Vireux-Molhain (France)",
  "Vireux-Wallerand (France)",
  "Virey-le-Grand (France)",
  "Viriat (France)",
  "Virieu-le-Grand (France)",
  "Virignin (France)",
  "Viriville (France)",
  "Viroflay (France)",
  "Virville (France)",
  "Viry (France)",
  "Viry-Noureuil (France)",
  "Vitrac (France)",
  "Vitrac-Saint-Vincent (France)",
  "Vitreux (France)",
  "Vitrimont (France)",
  "Vitrolles (France)",
  "Vitry-aux-Loges (France)",
  "Vitry-en-Artois (France)",
  "Vitry-en-Perthois (France)",
  "Vitry-la-Ville (France)",
  "Vitry-sur-Orne (France)",
  "Vitry-sur-Seine (France)",
  "Vitteaux (France)",
  "Vittel (France)",
  "Vittersbourg (France)",
  "Viuz-en-Sallaz (France)",
  "Viven (France)",
  "Vivier (France)",
  "Vivier-au-Court (France)",
  "Vivieres (France)",
  "Viviers (France)",
  "Viviers-du-Lac (France)",
  "Viviers-les-Montagnes (France)",
  "Viviez (France)",
  "Vivonne (France)",
  "Vivy (France)",
  "Vix (France)",
  "Vizille (France)",
  "Vocance (France)",
  "Voglans (France)",
  "Voillecomte (France)",
  "Voiron (France)",
  "Voisin (France)",
  "Voisins-le-Bretonneux (France)",
  "Voissant (France)",
  "Vollore-Ville (France)",
  "Volmerange-les-Boulay (France)",
  "Volmerange-les-Mines (France)",
  "Volonne (France)",
  "Volvic (France)",
  "Volx (France)",
  "Vonnas (France)",
  "Voreppe (France)",
  "Vorey (France)",
  "Vorges (France)",
  "Vouel (France)",
  "Vouhe (France)",
  "Vouille (France)",
  "Vouille-les-Marais (France)",
  "Vouillers (France)",
  "Voujeaucourt (France)",
  "Voulangis (France)",
  "Voultegon (France)",
  "Voulton (France)",
  "Voulx (France)",
  "Vouneuil-sous-Biard (France)",
  "Vouneuil-sur-Vienne (France)",
  "Vourey (France)",
  "Vourles (France)",
  "Voutezac (France)",
  "Voutre (France)",
  "Vouvant (France)",
  "Vouvray (France)",
  "Vouzan (France)",
  "Vouzeron (France)",
  "Vouziers (France)",
  "Vouzon (France)",
  "Vouzy (France)",
  "Voves (France)",
  "Vraiville (France)",
  "Vraux (France)",
  "Vred (France)",
  "Vriange (France)",
  "Vrigne-aux-Bois (France)",
  "Vrigny (France)",
  "Vritz (France)",
  "Vue (France)",
  "Vulaines-sur-Seine (France)",
  "Vulbens (France)",
  "Wahagnies (France)",
  "Wahlenheim (France)",
  "Wail (France)",
  "Wailly (France)",
  "Wailly-Beaucamp (France)",
  "Waldhouse (France)",
  "Waldighofen (France)",
  "Waldweistroff (France)",
  "Waldwisse (France)",
  "Walheim (France)",
  "Wallers (France)",
  "Walscheid (France)",
  "Wambaix (France)",
  "Wambercourt (France)",
  "Wambrechies (France)",
  "Wandignies-Hamage (France)",
  "Wangen (France)",
  "Wangenbourg-Engenthal (France)",
  "Wanquetin (France)",
  "Warcq (France)",
  "Warendin (France)",
  "Wargnies-le-Petit (France)",
  "Warhem (France)",
  "Warlaing (France)",
  "Warlus (France)",
  "Warluzel (France)",
  "Warmeriville (France)",
  "Warnecourt (France)",
  "Wasnes-au-Bac (France)",
  "Wasquehal (France)",
  "Wasselonne (France)",
  "Wassy (France)",
  "Watten (France)",
  "Wattignies (France)",
  "Wattrelos (France)",
  "Wattwiller (France)",
  "Wavignies (France)",
  "Waville (France)",
  "Wavrechain-sous-Denain (France)",
  "Wavrechain-sous-Faulx (France)",
  "Wavrin (France)",
  "Waziers (France)",
  "Weislingen (France)",
  "Weitbruch (France)",
  "Wentzwiller (France)",
  "Werentzhouse (France)",
  "Wervicq-Sud (France)",
  "Westbecourt (France)",
  "Westhoffen (France)",
  "Westhouse (France)",
  "Weyersheim (France)",
  "Wickerschwihr (France)",
  "Widensolen (France)",
  "Wierre-Effroy (France)",
  "Wignehies (France)",
  "Wihr-au-Val (France)",
  "Wildersbach (France)",
  "Willems (France)",
  "Willencourt (France)",
  "Willer-sur-Thur (France)",
  "Willerwald (France)",
  "Willgottheim (France)",
  "Wimereux (France)",
  "Wimille (France)",
  "Wingen-sur-Moder (France)",
  "Wingersheim (France)",
  "Wingles (France)",
  "Winnezeele (France)",
  "Wintersbourg (France)",
  "Wintzenbach (France)",
  "Wintzenheim (France)",
  "Wintzfelden (France)",
  "Wirwignes (France)",
  "Wissant (France)",
  "Wissembourg (France)",
  "Wissignicourt (France)",
  "Wissous (France)",
  "Witry-les-Reims (France)",
  "Wittelsheim (France)",
  "Wittenheim (France)",
  "Witternheim (France)",
  "Wittersdorf (France)",
  "Wittersheim (France)",
  "Wittes (France)",
  "Wittisheim (France)",
  "Wiwersheim (France)",
  "Wizernes (France)",
  "Woerth (France)",
  "Woincourt (France)",
  "Woippy (France)",
  "Wolfgantzen (France)",
  "Wolfisheim (France)",
  "Wolschheim (France)",
  "Wolxheim (France)",
  "Wormhout (France)",
  "Woustviller (France)",
  "Wuenheim (France)",
  "Xaintray (France)",
  "Xanton-Chassenon (France)",
  "Xertigny (France)",
  "Xures (France)",
  "Yaucourt-Bussus (France)",
  "Ychoux (France)",
  "Ydes (France)",
  "Yebleron (France)",
  "Yebles (France)",
  "Yenne (France)",
  "Yermenonville (France)",
  "Yerres (France)",
  "Yerville (France)",
  "Yevre-la-Ville (France)",
  "Yffiniac (France)",
  "Ygos-Saint-Saturnin (France)",
  "Ygrande (France)",
  "Ymare (France)",
  "Youx (France)",
  "Yport (France)",
  "Ypreville-Biville (France)",
  "Yronde-et-Buron (France)",
  "Yssac-la-Tourette (France)",
  "Yssandon (France)",
  "Yssingeaux (France)",
  "Ytrac (France)",
  "Yversay (France)",
  "Yves (France)",
  "Yvetot (France)",
  "Yvette (France)",
  "Yvias (France)",
  "Yvoire (France)",
  "Yvrac (France)",
  "Yvrac-et-Malleyrand (France)",
  "Yzernay (France)",
  "Yzeure (France)",
  "Yzeures-sur-Creuse (France)",
  "Yzosse (France)",
  "Zellwiller (France)",
  "Zillisheim (France)",
  "Zimmersheim (France)",
  "Zimming (France)",
  "Zinswiller (France)",
  "Zittersheim (France)",
  "Zouafques (France)",
  "Zutzendorf (France)",
  "French Polynesia",
  "Arue (French Polynesia)",
  "Faaa (French Polynesia)",
  "Mahina (French Polynesia)",
  "Paeau (French Polynesia)",
  "Papeete (French Polynesia)",
  "Pirae (French Polynesia)",
  "Punaauia (French Polynesia)",
  "Gabon",
  "Gamba (Gabon)",
  "Libreville (Gabon)",
  "Mamagnia (Gabon)",
  "Moanda (Gabon)",
  "Port-Gentil (Gabon)",
  "Georgia",
  "Gogolesubani (Georgia)",
  "Kutaisi (Georgia)",
  "Lentekhi (Georgia)",
  "Qazbegi (Georgia)",
  "Samtredia (Georgia)",
  "Sukhumi (Georgia)",
  "Tbilisi (Georgia)",
  "Zemo-Avchala (Georgia)",
  "Zugdidi (Georgia)",
  "Germany",
  "Aach (Germany)",
  "Aachen (Germany)",
  "Aalen (Germany)",
  "Abbensen (Germany)",
  "Abberode (Germany)",
  "Abenberg (Germany)",
  "Abensberg (Germany)",
  "Abstatt (Germany)",
  "Abtsbessingen (Germany)",
  "Abtsgmuend (Germany)",
  "Abtswind (Germany)",
  "Abtweiler (Germany)",
  "Achern (Germany)",
  "Achim (Germany)",
  "Achstetten (Germany)",
  "Adelebsen (Germany)",
  "Adelheidsdorf (Germany)",
  "Adelmannsfelden (Germany)",
  "Adelschlag (Germany)",
  "Adelsdorf (Germany)",
  "Adelsheim (Germany)",
  "Adelshofen (Germany)",
  "Adelsried (Germany)",
  "Adenau (Germany)",
  "Adenbuettel (Germany)",
  "Adendorf (Germany)",
  "Adlkofen (Germany)",
  "Adlum (Germany)",
  "Adorf (Germany)",
  "Aerzen (Germany)",
  "Affalterbach (Germany)",
  "Affaltern (Germany)",
  "Affing (Germany)",
  "Affinghausen (Germany)",
  "Aftholderberg (Germany)",
  "Aglasterhausen (Germany)",
  "Ahaus (Germany)",
  "Ahausen (Germany)",
  "Ahlen (Germany)",
  "Ahlhorn (Germany)",
  "Aholfing (Germany)",
  "Aholming (Germany)",
  "Ahorn (Germany)",
  "Ahrbruck (Germany)",
  "Ahrensboek (Germany)",
  "Ahrensbok (Germany)",
  "Ahrensburg (Germany)",
  "Ahrensfelde (Germany)",
  "Ahrenshagen (Germany)",
  "Ahrensmoor (Germany)",
  "Ahstedt (Germany)",
  "Aicha vorm Wald (Germany)",
  "Aichach (Germany)",
  "Aichelberg (Germany)",
  "Aichhalden (Germany)",
  "Aichstetten (Germany)",
  "Aichtal (Germany)",
  "Aichwald (Germany)",
  "Aidenbach (Germany)",
  "Aidlingen (Germany)",
  "Aindling (Germany)",
  "Aindorf (Germany)",
  "Ainring (Germany)",
  "Aislingen (Germany)",
  "Aitrach (Germany)",
  "Aitrang (Germany)",
  "Aken (Germany)",
  "Albaching (Germany)",
  "Albbruck (Germany)",
  "Albeck (Germany)",
  "Albersdorf (Germany)",
  "Albershausen (Germany)",
  "Albersweiler (Germany)",
  "Albertshausen (Germany)",
  "Albig (Germany)",
  "Albisheim (Germany)",
  "Albrechts (Germany)",
  "Albstadt (Germany)",
  "Aldekerk (Germany)",
  "Aldenhoven (Germany)",
  "Aldersbach (Germany)",
  "Aldingen (Germany)",
  "Alesheim (Germany)",
  "Aletshausen (Germany)",
  "Alexander (Germany)",
  "Alfdorf (Germany)",
  "Alfeld (Germany)",
  "Alfhausen (Germany)",
  "Alfstedt (Germany)",
  "Alfter (Germany)",
  "Algermissen (Germany)",
  "Alheim (Germany)",
  "Allee (Germany)",
  "Allendorf (Germany)",
  "Allensbach (Germany)",
  "Allersberg (Germany)",
  "Allershausen (Germany)",
  "Alleshausen (Germany)",
  "Alling (Germany)",
  "Allmannshofen (Germany)",
  "Allmendingen (Germany)",
  "Allmersbach im Tal (Germany)",
  "Allstedt (Germany)",
  "Almstedt (Germany)",
  "Alpen (Germany)",
  "Alpenrod (Germany)",
  "Alperstedt (Germany)",
  "Alpirsbach (Germany)",
  "Alsbach (Germany)",
  "Alsbach-Hahnlein (Germany)",
  "Alsdorf (Germany)",
  "Alsfeld (Germany)",
  "Alsheim (Germany)",
  "Alt Buchhorst (Germany)",
  "Alt Bukow (Germany)",
  "Alt Golm (Germany)",
  "Alt Ruppin (Germany)",
  "Alt Schwerin (Germany)",
  "Altbach (Germany)",
  "Altbelgern (Germany)",
  "Altdobern (Germany)",
  "Altdorf (Germany)",
  "Alteglofsheim (Germany)",
  "Alten (Germany)",
  "Altena (Germany)",
  "Altenahr (Germany)",
  "Altenau (Germany)",
  "Altenbeken (Germany)",
  "Altenberg (Germany)",
  "Altenberge (Germany)",
  "Altenburg (Germany)",
  "Altendiez (Germany)",
  "Altenglan (Germany)",
  "Altengottern (Germany)",
  "Altengronau (Germany)",
  "Altenhasslau (Germany)",
  "Altenholz (Germany)",
  "Altenkirchen (Germany)",
  "Altenkrempe (Germany)",
  "Altenkunstadt (Germany)",
  "Altenmarkt (Germany)",
  "Altenmedingen (Germany)",
  "Altenmunster (Germany)",
  "Altenriet (Germany)",
  "Altenstadt (Germany)",
  "Altensteig (Germany)",
  "Altenthann (Germany)",
  "Altentreptow (Germany)",
  "Alterhofen (Germany)",
  "Altersbach (Germany)",
  "Altfraunhofen (Germany)",
  "Althegnenberg (Germany)",
  "Altheim (Germany)",
  "Althengstett (Germany)",
  "Althutte (Germany)",
  "Altingen (Germany)",
  "Altkirchen (Germany)",
  "Altlandsberg (Germany)",
  "Altleiningen (Germany)",
  "Altlussheim (Germany)",
  "Altmannstein (Germany)",
  "Altoetting (Germany)",
  "Altomunster (Germany)",
  "Altrip (Germany)",
  "Altshausen (Germany)",
  "Altstadt (Germany)",
  "Alttechau (Germany)",
  "Altusried (Germany)",
  "Altwittenbek (Germany)",
  "Alveslohe (Germany)",
  "Alzenau in Unterfranken (Germany)",
  "Alzey (Germany)",
  "Am Mellensee (Germany)",
  "Am See (Germany)",
  "Amberg (Germany)",
  "Amelinghausen (Germany)",
  "Amerang (Germany)",
  "Ammern (Germany)",
  "Ammerndorf (Germany)",
  "Amoneburg (Germany)",
  "Amorbach (Germany)",
  "Ampermoching (Germany)",
  "Ampfing (Germany)",
  "Amstetten (Germany)",
  "Amtzell (Germany)",
  "Andechs (Germany)",
  "Anderlingen (Germany)",
  "Andernach (Germany)",
  "Andreasberg (Germany)",
  "Anger (Germany)",
  "Angermunde (Germany)",
  "Angern (Germany)",
  "Anker (Germany)",
  "Ankershagen (Germany)",
  "Anklam (Germany)",
  "Ankum (Germany)",
  "Annaberg-Buchholz (Germany)",
  "Annaburg (Germany)",
  "Annerod (Germany)",
  "Annweiler am Trifels (Germany)",
  "Anrath (Germany)",
  "Anrochte (Germany)",
  "Ansbach (Germany)",
  "Antdorf (Germany)",
  "Anzing (Germany)",
  "Apen (Germany)",
  "Apenburg (Germany)",
  "Apensen (Germany)",
  "Apfeldorf (Germany)",
  "Apfelstadt (Germany)",
  "Apolda (Germany)",
  "Appel (Germany)",
  "Appen (Germany)",
  "Appenweier (Germany)",
  "Arberg (Germany)",
  "Arenrath (Germany)",
  "Arensdorf (Germany)",
  "Arenshausen (Germany)",
  "Aresing (Germany)",
  "Arft (Germany)",
  "Argenthal (Germany)",
  "Arlaching (Germany)",
  "Arlewatt (Germany)",
  "Armsheim (Germany)",
  "Armstorf (Germany)",
  "Arnbach (Germany)",
  "Arnbruck (Germany)",
  "Arnsberg (Germany)",
  "Arnschwang (Germany)",
  "Arnsdorf (Germany)",
  "Arnstadt (Germany)",
  "Arnstein (Germany)",
  "Arnstorf (Germany)",
  "Arrach (Germany)",
  "Artern (Germany)",
  "Artlenburg (Germany)",
  "Arzbach (Germany)",
  "Arzberg (Germany)",
  "Asbach (Germany)",
  "Asbach-Baumenheim (Germany)",
  "Ascha (Germany)",
  "Aschaffenburg (Germany)",
  "Aschau (Germany)",
  "Ascheberg (Germany)",
  "Ascheffel (Germany)",
  "Aschendorf (Germany)",
  "Aschersleben (Germany)",
  "Aschheim (Germany)",
  "Asendorf (Germany)",
  "Aspach (Germany)",
  "Asperg (Germany)",
  "Aspisheim (Germany)",
  "Assel (Germany)",
  "Assenheim (Germany)",
  "Asslar (Germany)",
  "Assling (Germany)",
  "Astfeld (Germany)",
  "Atteln (Germany)",
  "Attendorn (Germany)",
  "Attenkirchen (Germany)",
  "Attenweiler (Germany)",
  "Atting (Germany)",
  "Atzendorf (Germany)",
  "Au (Germany)",
  "Au am Rhein (Germany)",
  "Au in der Hallertau (Germany)",
  "Aub (Germany)",
  "Aue (Germany)",
  "Auen (Germany)",
  "Auenstein (Germany)",
  "Auerbach (Germany)",
  "Aufhausen (Germany)",
  "Auggen (Germany)",
  "Augsburg (Germany)",
  "Augustdorf (Germany)",
  "Augustfehn (Germany)",
  "Augustusburg (Germany)",
  "Aukrug (Germany)",
  "Aulendorf (Germany)",
  "Aull (Germany)",
  "Auma (Germany)",
  "Aumuhle (Germany)",
  "Aurach (Germany)",
  "Aurachtal (Germany)",
  "Aurich (Germany)",
  "Autenzell (Germany)",
  "Auw (Germany)",
  "Axstedt (Germany)",
  "Aying (Germany)",
  "Ayl (Germany)",
  "Baar-Ebenhausen (Germany)",
  "Baasdorf (Germany)",
  "Baasem (Germany)",
  "Babenhausen (Germany)",
  "Babensham (Germany)",
  "Babow (Germany)",
  "Bach (Germany)",
  "Bach an der Donau (Germany)",
  "Bacharach (Germany)",
  "Bachhagel (Germany)",
  "Bachingen an der Brenz (Germany)",
  "Backnang (Germany)",
  "Bad Abbach (Germany)",
  "Bad Aibling (Germany)",
  "Bad Alexandersbad (Germany)",
  "Bad Arolsen (Germany)",
  "Bad Bellingen (Germany)",
  "Bad Bentheim (Germany)",
  "Bad Bergzabern (Germany)",
  "Bad Berka (Germany)",
  "Bad Berleburg (Germany)",
  "Bad Berneck im Fichtelgebirge (Germany)",
  "Bad Bevensen (Germany)",
  "Bad Bibra (Germany)",
  "Bad Blankenburg (Germany)",
  "Bad Bocklet (Germany)",
  "Bad Boll (Germany)",
  "Bad Bramstedt (Germany)",
  "Bad Breisig (Germany)",
  "Bad Buchau (Germany)",
  "Bad Camberg (Germany)",
  "Bad Ditzenbach (Germany)",
  "Bad Doberan (Germany)",
  "Bad Driburg (Germany)",
  "Bad Duben (Germany)",
  "Bad Duerkheim (Germany)",
  "Bad Durrenberg (Germany)",
  "Bad Durrheim (Germany)",
  "Bad Eilsen (Germany)",
  "Bad Elster (Germany)",
  "Bad Ems (Germany)",
  "Bad Endbach (Germany)",
  "Bad Endorf (Germany)",
  "Bad Essen (Germany)",
  "Bad Fallingbostel (Germany)",
  "Bad Feilnbach (Germany)",
  "Bad Frankenhausen (Germany)",
  "Bad Freienwalde (Germany)",
  "Bad Fussing (Germany)",
  "Bad Gandersheim (Germany)",
  "Bad Godesberg (Germany)",
  "Bad Gogging (Germany)",
  "Bad Gottleuba (Germany)",
  "Bad Griesbach (Germany)",
  "Bad Grund (Germany)",
  "Bad Harzburg (Germany)",
  "Bad Heilbrunn (Germany)",
  "Bad Herrenalb (Germany)",
  "Bad Hersfeld (Germany)",
  "Bad Hindelang (Germany)",
  "Bad Homburg (Germany)",
  "Bad Honnef (Germany)",
  "Bad Honningen (Germany)",
  "Bad Iburg (Germany)",
  "Bad Karlshafen (Germany)",
  "Bad Kissingen (Germany)",
  "Bad Kleinen (Germany)",
  "Bad Klosterlausnitz (Germany)",
  "Bad Kohlgrub (Germany)",
  "Bad Konig (Germany)",
  "Bad Konigshofen im Grabfeld (Germany)",
  "Bad Kosen (Germany)",
  "Bad Kostritz (Germany)",
  "Bad Kreuznach (Germany)",
  "Bad Krozingen (Germany)",
  "Bad Laasphe (Germany)",
  "Bad Laer (Germany)",
  "Bad Langensalza (Germany)",
  "Bad Lauchstadt (Germany)",
  "Bad Lausick (Germany)",
  "Bad Lauterberg im Harz (Germany)",
  "Bad Liebenstein (Germany)",
  "Bad Liebenwerda (Germany)",
  "Bad Liebenzell (Germany)",
  "Bad Lippspringe (Germany)",
  "Bad Lobenstein (Germany)",
  "Bad Marienberg (Germany)",
  "Bad Mergentheim (Germany)",
  "Bad Munder am Deister (Germany)",
  "Bad Munster am Stein-Ebernburg (Germany)",
  "Bad Munstereifel (Germany)",
  "Bad Muskau (Germany)",
  "Bad Nauheim (Germany)",
  "Bad Nenndorf (Germany)",
  "Bad Neuenahr-Ahrweiler (Germany)",
  "Bad Neustadt an der Saale (Germany)",
  "Bad Oeynhausen (Germany)",
  "Bad Oldesloe (Germany)",
  "Bad Orb (Germany)",
  "Bad Peterstal-Griesbach (Germany)",
  "Bad Pyrmont (Germany)",
  "Bad Rappenau (Germany)",
  "Bad Reichenhall (Germany)",
  "Bad Rippoldsau-Schapbach (Germany)",
  "Bad Rothenfelde (Germany)",
  "Bad Saarow (Germany)",
  "Bad Sachsa (Germany)",
  "Bad Sackingen (Germany)",
  "Bad Salzdetfurth (Germany)",
  "Bad Salzschlirf (Germany)",
  "Bad Salzuflen (Germany)",
  "Bad Salzungen (Germany)",
  "Bad Sassendorf (Germany)",
  "Bad Schachen (Germany)",
  "Bad Schandau (Germany)",
  "Bad Schmiedeberg (Germany)",
  "Bad Schussenried (Germany)",
  "Bad Schwalbach (Germany)",
  "Bad Schwartau (Germany)",
  "Bad Segeberg (Germany)",
  "Bad Soden am Taunus (Germany)",
  "Bad Soden-Salmunster (Germany)",
  "Bad Sooden-Allendorf (Germany)",
  "Bad Staffelstein (Germany)",
  "Bad Steben (Germany)",
  "Bad Sulza (Germany)",
  "Bad Teinach-Zavelstein (Germany)",
  "Bad Tennstedt (Germany)",
  "Bad Toelz (Germany)",
  "Bad Uberkingen (Germany)",
  "Bad Urach (Germany)",
  "Bad Vilbel (Germany)",
  "Bad Waldsee (Germany)",
  "Bad Wiessee (Germany)",
  "Bad Wildbad im Schwarzwald (Germany)",
  "Bad Wildungen (Germany)",
  "Bad Wilsnack (Germany)",
  "Bad Wimpfen (Germany)",
  "Bad Windsheim (Germany)",
  "Bad Worishofen (Germany)",
  "Bad Wurzach (Germany)",
  "Bad Zwischenahn (Germany)",
  "Badbergen (Germany)",
  "Baddeckenstedt (Germany)",
  "Badem (Germany)",
  "Baden-Baden (Germany)",
  "Badenhausen (Germany)",
  "Badenweiler (Germany)",
  "Badow (Germany)",
  "Badra (Germany)",
  "Baesweiler (Germany)",
  "Bahlingen (Germany)",
  "Bahratal (Germany)",
  "Bahrdorf (Germany)",
  "Bahrenfleth (Germany)",
  "Baienfurt (Germany)",
  "Baierbrunn (Germany)",
  "Baiern (Germany)",
  "Baiersbronn (Germany)",
  "Baiersdorf (Germany)",
  "Baindt (Germany)",
  "Bakum (Germany)",
  "Baldham (Germany)",
  "Balgheim (Germany)",
  "Balingen (Germany)",
  "Ballendorf (Germany)",
  "Ballenstedt (Germany)",
  "Ballersbach (Germany)",
  "Ballrechten (Germany)",
  "Ballstadt (Germany)",
  "Baltmannsweiler (Germany)",
  "Baltringen (Germany)",
  "Baltrum (Germany)",
  "Balve (Germany)",
  "Bamberg (Germany)",
  "Bamlach (Germany)",
  "Bammental (Germany)",
  "Bank (Germany)",
  "Bankewitz (Germany)",
  "Bann (Germany)",
  "Bannberscheid (Germany)",
  "Bannemin (Germany)",
  "Bannewitz (Germany)",
  "Banteln (Germany)",
  "Banzkow (Germany)",
  "Barbelroth (Germany)",
  "Barbing (Germany)",
  "Barby (Germany)",
  "Barchfeld (Germany)",
  "Bardenberg (Germany)",
  "Bardowick (Germany)",
  "Barendorf (Germany)",
  "Barenstein (Germany)",
  "Barenthal (Germany)",
  "Bargenstedt (Germany)",
  "Bargfeld-Stegen (Germany)",
  "Barghorst (Germany)",
  "Bargstedt (Germany)",
  "Bargteheide (Germany)",
  "Bargum (Germany)",
  "Barkelsby (Germany)",
  "Barkenholm (Germany)",
  "Barkow (Germany)",
  "Barleben (Germany)",
  "Barmstedt (Germany)",
  "Barnau (Germany)",
  "Barnstorf (Germany)",
  "Barntrup (Germany)",
  "Barsbuettel (Germany)",
  "Barsinghausen (Germany)",
  "Barssel (Germany)",
  "Barstadt (Germany)",
  "Barth (Germany)",
  "Bartholoma (Germany)",
  "Bartow (Germany)",
  "Barum (Germany)",
  "Baruth (Germany)",
  "Barwedel (Germany)",
  "Basel (Germany)",
  "Bassenheim (Germany)",
  "Bassum (Germany)",
  "Bastheim (Germany)",
  "Bastorf (Germany)",
  "Battenberg (Germany)",
  "Batzhausen (Germany)",
  "Baudenbach (Germany)",
  "Baumholder (Germany)",
  "Baunach (Germany)",
  "Baunatal (Germany)",
  "Bausendorf (Germany)",
  "Bautzen (Germany)",
  "Bawinkel (Germany)",
  "Bayenthal (Germany)",
  "Bayerbach (Germany)",
  "Bayerisch Eisenstein (Germany)",
  "Bayerisch Gmain (Germany)",
  "Bayern (Germany)",
  "Bayersoien (Germany)",
  "Bayreuth (Germany)",
  "Bayrischzell (Germany)",
  "Beaumarais (Germany)",
  "Bebra (Germany)",
  "Becheln (Germany)",
  "Becherbach (Germany)",
  "Bechhofen (Germany)",
  "Bechtolsheim (Germany)",
  "Beckdorf (Germany)",
  "Beckedorf (Germany)",
  "Beckeln (Germany)",
  "Beckingen (Germany)",
  "Beckstedt (Germany)",
  "Beckum (Germany)",
  "Bedburdyck (Germany)",
  "Bedburg (Germany)",
  "Bederkesa (Germany)",
  "Beedenbostel (Germany)",
  "Beelen (Germany)",
  "Beelitz (Germany)",
  "Beerfelden (Germany)",
  "Beeskow (Germany)",
  "Beesten (Germany)",
  "Beetzendorf (Germany)",
  "Beetzsee (Germany)",
  "Behrendorf (Germany)",
  "Behrenhoff (Germany)",
  "Behringen (Germany)",
  "Beierfeld (Germany)",
  "Beiersdorf (Germany)",
  "Beierstedt (Germany)",
  "Beilngries (Germany)",
  "Beilrode (Germany)",
  "Beilstein (Germany)",
  "Beim Kronsberg (Germany)",
  "Beimerstetten (Germany)",
  "Beindersheim (Germany)",
  "Beinerstadt (Germany)",
  "Bekond (Germany)",
  "Belecke (Germany)",
  "Belgern (Germany)",
  "Belgershain (Germany)",
  "Bell (Germany)",
  "Bellamont (Germany)",
  "Bellenberg (Germany)",
  "Bellershausen (Germany)",
  "Bellheim (Germany)",
  "Belm (Germany)",
  "Beltheim (Germany)",
  "Belum (Germany)",
  "Belzig (Germany)",
  "Bemerode (Germany)",
  "Bempflingen (Germany)",
  "Bendeleben (Germany)",
  "Bendestorf (Germany)",
  "Bendfeld (Germany)",
  "Bendorf (Germany)",
  "Benediktbeuern (Germany)",
  "Benneckenstein (Germany)",
  "Benningen (Germany)",
  "Benningen am Neckar (Germany)",
  "Bennungen (Germany)",
  "Bensberg (Germany)",
  "Benshausen (Germany)",
  "Bensheim (Germany)",
  "Bentfeld (Germany)",
  "Bentwisch (Germany)",
  "Benz (Germany)",
  "Benzingerode (Germany)",
  "Beratzhausen (Germany)",
  "Berching (Germany)",
  "Berchtesgaden (Germany)",
  "Berg (Germany)",
  "Berga (Germany)",
  "Bergbau (Germany)",
  "Berge (Germany)",
  "Bergen (Germany)",
  "Bergen auf Ruegen (Germany)",
  "Bergfelde (Germany)",
  "Berggiesshubel (Germany)",
  "Berghaupten (Germany)",
  "Berghausen (Germany)",
  "Bergheim (Germany)",
  "Berghulen (Germany)",
  "Bergisch Gladbach (Germany)",
  "Bergkamen (Germany)",
  "Bergkirchen (Germany)",
  "Berglern (Germany)",
  "Berglicht (Germany)",
  "Bergneustadt (Germany)",
  "Bergrheinfeld (Germany)",
  "Bergtheim (Germany)",
  "Bergweiler (Germany)",
  "Berka (Germany)",
  "Berkenthin (Germany)",
  "Berkheim (Germany)",
  "Berlin (Germany)",
  "Berlingerode (Germany)",
  "Bermatingen (Germany)",
  "Bermbach (Germany)",
  "Bermersheim (Germany)",
  "Bermuthshain (Germany)",
  "Bernau (Germany)",
  "Bernau am Chiemsee (Germany)",
  "Bernau bei Berlin (Germany)",
  "Bernburg (Germany)",
  "Berne (Germany)",
  "Berngau (Germany)",
  "Bernhardswald (Germany)",
  "Bernkastel-Kues (Germany)",
  "Bernried (Germany)",
  "Bernsbach (Germany)",
  "Bernsdorf (Germany)",
  "Bernstadt (Germany)",
  "Bernterode (Germany)",
  "Berod bei Wallmerod (Germany)",
  "Bersenbruck (Germany)",
  "Berumbur (Germany)",
  "Berzdorf (Germany)",
  "Bescheid (Germany)",
  "Besigheim (Germany)",
  "Besitz (Germany)",
  "Bestensee (Germany)",
  "Bestwig (Germany)",
  "Betheln (Germany)",
  "Bettingen (Germany)",
  "Betzdorf (Germany)",
  "Betzendorf (Germany)",
  "Betzenstein (Germany)",
  "Betzigau (Germany)",
  "Beucha (Germany)",
  "Beuern (Germany)",
  "Beuren (Germany)",
  "Bevern (Germany)",
  "Beverstedt (Germany)",
  "Beverungen (Germany)",
  "Bexbach (Germany)",
  "Bexhovede (Germany)",
  "Biberach an der Riss (Germany)",
  "Biberbach (Germany)",
  "Biblis (Germany)",
  "Biburg (Germany)",
  "Bichl (Germany)",
  "Bickenbach (Germany)",
  "Biebelsheim (Germany)",
  "Bieber (Germany)",
  "Biebesheim (Germany)",
  "Biedenkopf (Germany)",
  "Biederitz (Germany)",
  "Bielefeld (Germany)",
  "Bienenbuttel (Germany)",
  "Bienstadt (Germany)",
  "Biere (Germany)",
  "Bierlingen (Germany)",
  "Biesenthal (Germany)",
  "Biesingen (Germany)",
  "Biessenhofen (Germany)",
  "Bietigheim (Germany)",
  "Bietigheim-Bissingen (Germany)",
  "Bietingen (Germany)",
  "Bilk (Germany)",
  "Billerbeck (Germany)",
  "Billigheim (Germany)",
  "Billigheim-Ingenheim (Germany)",
  "Billings (Germany)",
  "Billingshausen (Germany)",
  "Bimohlen (Germany)",
  "Binau (Germany)",
  "Bindlach (Germany)",
  "Bingen (Germany)",
  "Bingen am Rhein (Germany)",
  "Binsfeld (Germany)",
  "Binzen (Germany)",
  "Bippen (Germany)",
  "Birenbach (Germany)",
  "Birkenau (Germany)",
  "Birkenfeld (Germany)",
  "Birkenheide (Germany)",
  "Birkenhof (Germany)",
  "Birkenlohe (Germany)",
  "Birkenwerder (Germany)",
  "Birkheim (Germany)",
  "Birlenbach (Germany)",
  "Birlinghoven (Germany)",
  "Birnbach (Germany)",
  "Birstein (Germany)",
  "Bischberg (Germany)",
  "Bischheim (Germany)",
  "Bischoffen (Germany)",
  "Bischofferode (Germany)",
  "Bischofsgrun (Germany)",
  "Bischofsheim (Germany)",
  "Bischofsheim an der Rhon (Germany)",
  "Bischofsmais (Germany)",
  "Bischofswerda (Germany)",
  "Bischofswiesen (Germany)",
  "Bischweier (Germany)",
  "Bisingen (Germany)",
  "Bismark (Germany)",
  "Bispingen (Germany)",
  "Bissendorf (Germany)",
  "Bissingen (Germany)",
  "Bissingen an der Teck (Germany)",
  "Bistensee (Germany)",
  "Bitburg (Germany)",
  "Bitterfeld-Wolfen (Germany)",
  "Bitz (Germany)",
  "Blaibach (Germany)",
  "Blaichach (Germany)",
  "Blankenburg (Germany)",
  "Blankenfelde (Germany)",
  "Blankenhagen (Germany)",
  "Blankenhain (Germany)",
  "Blankenheim (Germany)",
  "Blankenloch (Germany)",
  "Blankenrath (Germany)",
  "Blankensee (Germany)",
  "Blankenstein (Germany)",
  "Blatzheim (Germany)",
  "Blaubeuren Abbey (Germany)",
  "Blechhammer (Germany)",
  "Bleckede (Germany)",
  "Bleckenrode (Germany)",
  "Bleialf (Germany)",
  "Bleicherode (Germany)",
  "Blender (Germany)",
  "Bliesdorf (Germany)",
  "Blieskastel (Germany)",
  "Bliesmengen-Bolchen (Germany)",
  "Blievenstorf (Germany)",
  "Blitzenreute (Germany)",
  "Blomberg (Germany)",
  "Blomesche Wildnis (Germany)",
  "Blumberg (Germany)",
  "Blume (Germany)",
  "Blumenthal (Germany)",
  "Bobbau (Germany)",
  "Bobenheim am Berg (Germany)",
  "Bobenheim-Roxheim (Germany)",
  "Bobingen (Germany)",
  "Bobrach (Germany)",
  "Bocholt (Germany)",
  "Bochum (Germany)",
  "Bocka (Germany)",
  "Bockau (Germany)",
  "Bockelwitz (Germany)",
  "Bockenau (Germany)",
  "Bockenem (Germany)",
  "Bockenheim (Germany)",
  "Bockhorn (Germany)",
  "Bockhorst (Germany)",
  "Boddenstedt (Germany)",
  "Boddin (Germany)",
  "Bodelshausen (Germany)",
  "Boden (Germany)",
  "Bodenfelde (Germany)",
  "Bodenheim (Germany)",
  "Bodenkirchen (Germany)",
  "Bodenmais (Germany)",
  "Bodensee (Germany)",
  "Bodenteich (Germany)",
  "Bodenwerder (Germany)",
  "Bodenwohr (Germany)",
  "Bodman-Ludwigshafen (Germany)",
  "Bodolz (Germany)",
  "Boel (Germany)",
  "Boffzen (Germany)",
  "Bofingen (Germany)",
  "Bogen (Germany)",
  "Bogenhausen (Germany)",
  "Bohl-Iggelheim (Germany)",
  "Bohlen (Germany)",
  "Bohmenkirch (Germany)",
  "Bohmstedt (Germany)",
  "Bohmte (Germany)",
  "Bohringen (Germany)",
  "Boich (Germany)",
  "Boizenburg (Germany)",
  "Boklund (Germany)",
  "Bolanden (Germany)",
  "Boldebuck (Germany)",
  "Boldela (Germany)",
  "Bollendorf (Germany)",
  "Bollingstedt (Germany)",
  "Bollschweil (Germany)",
  "Bollstein (Germany)",
  "Bolsterlang (Germany)",
  "Bomlitz (Germany)",
  "Bondorf (Germany)",
  "Bonebuttel (Germany)",
  "Bonefeld (Germany)",
  "Bonen (Germany)",
  "Bonn (Germany)",
  "Bonndorf im Schwarzwald (Germany)",
  "Bonnigheim (Germany)",
  "Bonningstedt (Germany)",
  "Bonstetten (Germany)",
  "Boos (Germany)",
  "Boostedt (Germany)",
  "Bopfingen (Germany)",
  "Boppard (Germany)",
  "Bordelum (Germany)",
  "Bordesholm (Germany)",
  "Borgdorf-Seedorf (Germany)",
  "Borgentreich (Germany)",
  "Borger (Germany)",
  "Borgholzhausen (Germany)",
  "Borgsdorf (Germany)",
  "Borgstedt (Germany)",
  "Borgsum (Germany)",
  "Borken (Germany)",
  "Borkheide (Germany)",
  "Borkum (Germany)",
  "Borkwalde (Germany)",
  "Born (Germany)",
  "Borna (Germany)",
  "Borne (Germany)",
  "Bornheim (Germany)",
  "Bornhoved (Germany)",
  "Bornich (Germany)",
  "Bornitz (Germany)",
  "Bornsen (Germany)",
  "Bornstein (Germany)",
  "Borsdorf (Germany)",
  "Borsfleth (Germany)",
  "Borstel (Germany)",
  "Borstel-Hohenraden (Germany)",
  "Bortlingen (Germany)",
  "Bosau (Germany)",
  "Bosel (Germany)",
  "Bosingen (Germany)",
  "Bosleben-Wullersleben (Germany)",
  "Botersen (Germany)",
  "Bothkamp (Germany)",
  "Bottrop (Germany)",
  "Botzingen (Germany)",
  "Bous (Germany)",
  "Bovenden (Germany)",
  "Boxberg (Germany)",
  "Brachbach (Germany)",
  "Brackel (Germany)",
  "Brackenheim (Germany)",
  "Brake (Unterweser) (Germany)",
  "Brakel (Germany)",
  "Bramsche (Germany)",
  "Bramstedt (Germany)",
  "Brand (Germany)",
  "Brand-Erbisdorf (Germany)",
  "Brande-Hornerkirchen (Germany)",
  "Brandenburg (Germany)",
  "Brandis (Germany)",
  "Brandl (Germany)",
  "Brandshagen (Germany)",
  "Brannenburg (Germany)",
  "Braubach (Germany)",
  "Braunfels (Germany)",
  "Braunlage (Germany)",
  "Braunlingen (Germany)",
  "Braunsbach (Germany)",
  "Braunsbedra (Germany)",
  "Braunschweig (Germany)",
  "Brauweiler (Germany)",
  "Brebel (Germany)",
  "Breckerfeld (Germany)",
  "Breddenberg (Germany)",
  "Breddorf (Germany)",
  "Bredenbek (Germany)",
  "Bredeney (Germany)",
  "Bredstedt (Germany)",
  "Breesen (Germany)",
  "Breest (Germany)",
  "Bregenstedt (Germany)",
  "Breidenbach (Germany)",
  "Breiholz (Germany)",
  "Breisach (Germany)",
  "Breit (Germany)",
  "Breitbrunn am Chiemsee (Germany)",
  "Breitenau (Germany)",
  "Breitenbach (Germany)",
  "Breitenbach am Herzberg (Germany)",
  "Breitenberg (Germany)",
  "Breitenbrunn (Germany)",
  "Breitenburg (Germany)",
  "Breitenfelde (Germany)",
  "Breitengussbach (Germany)",
  "Breitenworbis (Germany)",
  "Breitnau (Germany)",
  "Breitscheid (Germany)",
  "Breitscheidt (Germany)",
  "Breitungen (Germany)",
  "Brekendorf (Germany)",
  "Bremen (Germany)",
  "Bremerhaven (Germany)",
  "Bremervorde (Germany)",
  "Brennberg (Germany)",
  "Brensbach (Germany)",
  "Brenz (Germany)",
  "Bretten (Germany)",
  "Bretthausen (Germany)",
  "Bretzfeld (Germany)",
  "Breuna (Germany)",
  "Brey (Germany)",
  "Brickeln (Germany)",
  "Brieselang (Germany)",
  "Brieske (Germany)",
  "Brieskow-Finkenheerd (Germany)",
  "Brietlingen (Germany)",
  "Brilon (Germany)",
  "Britz (Germany)",
  "Brockum (Germany)",
  "Brodersby (Germany)",
  "Broderstorf (Germany)",
  "Brodhagen (Germany)",
  "Broeckel (Germany)",
  "Brokdorf (Germany)",
  "Brokstedt (Germany)",
  "Brome (Germany)",
  "Bromskirchen (Germany)",
  "Brotterode (Germany)",
  "Bruch (Germany)",
  "Bruchhausen (Germany)",
  "Bruchhausen-Vilsen (Germany)",
  "Bruchkobel (Germany)",
  "Bruchmuhlbach-Miesau (Germany)",
  "Bruchsal (Germany)",
  "Bruchweiler (Germany)",
  "Bruck (Germany)",
  "Bruck in der Oberpfalz (Germany)",
  "Bruckberg (Germany)",
  "Brucken (Germany)",
  "Bruckmuhl (Germany)",
  "Brucktal (Germany)",
  "Bruel (Germany)",
  "Bruggen (Germany)",
  "Bruhl (Germany)",
  "Brunn (Germany)",
  "Brunnthal (Germany)",
  "Brunsbuttel (Germany)",
  "Brunstorf (Germany)",
  "Brusewitz (Germany)",
  "Brussow (Germany)",
  "Bubenheim (Germany)",
  "Bubenreuth (Germany)",
  "Bubesheim (Germany)",
  "Buborn (Germany)",
  "Buch (Germany)",
  "Buch am Buchrain (Germany)",
  "Buch am Erlbach (Germany)",
  "Bucha (Germany)",
  "Buchbach (Germany)",
  "Buchberg (Germany)",
  "Buchel (Germany)",
  "Buchen (Germany)",
  "Buchenbach (Germany)",
  "Buchenberg (Germany)",
  "Buchfart (Germany)",
  "Buchholz (Germany)",
  "Buchholz in der Nordheide (Germany)",
  "Buchlberg (Germany)",
  "Buchloe (Germany)",
  "Buchwaldchen (Germany)",
  "Buchwalde (Germany)",
  "Buckeburg (Germany)",
  "Buckenhof (Germany)",
  "Buckow (Germany)",
  "Buden (Germany)",
  "Budenheim (Germany)",
  "Budingen (Germany)",
  "Bueddenstedt (Germany)",
  "Buedelsdorf (Germany)",
  "Buende (Germany)",
  "Buggingen (Germany)",
  "Buhl (Germany)",
  "Buhlen (Germany)",
  "Buhler (Germany)",
  "Buhlerhohe (Germany)",
  "Buhlertal (Germany)",
  "Buhlertann (Germany)",
  "Buhlerzell (Germany)",
  "Bulkau (Germany)",
  "Bullay (Germany)",
  "Bulow (Germany)",
  "Bulowerburg (Germany)",
  "Bunde (Germany)",
  "Bundenthal (Germany)",
  "Bunsoh (Germany)",
  "Burbach (Germany)",
  "Buren (Germany)",
  "Burg (Germany)",
  "Burg auf Fehmarn (Germany)",
  "Burg bei Magdeburg (Germany)",
  "Burg Stargard (Germany)",
  "Burg-Grambke (Germany)",
  "Burgau (Germany)",
  "Burgberg (Germany)",
  "Burgbernheim (Germany)",
  "Burgbrohl (Germany)",
  "Burgdorf, Hanover (Germany)",
  "Burgebrach (Germany)",
  "Burgen (Germany)",
  "Burgerhof (Germany)",
  "Burghaslach (Germany)",
  "Burghaun (Germany)",
  "Burghausen (Germany)",
  "Burgheim (Germany)",
  "Burgkirchen an der Alz (Germany)",
  "Burgkunstadt (Germany)",
  "Burglauer (Germany)",
  "Burglengenfeld (Germany)",
  "Burgoberbach (Germany)",
  "Burgrieden (Germany)",
  "Burgschwalbach (Germany)",
  "Burgsinn (Germany)",
  "Burgsponheim (Germany)",
  "Burgstadt (Germany)",
  "Burgstall (Germany)",
  "Burgstetten (Germany)",
  "Burgthann (Germany)",
  "Burgwedel (Germany)",
  "Burgwindheim (Germany)",
  "Burk (Germany)",
  "Burkardroth (Germany)",
  "Burkau (Germany)",
  "Burkhardtsdorf (Germany)",
  "Burladingen (Germany)",
  "Burscheid (Germany)",
  "Burstadt (Germany)",
  "Burtenbach (Germany)",
  "Burweg (Germany)",
  "Buschbell (Germany)",
  "Buschhausen (Germany)",
  "Busdorf (Germany)",
  "Busenberg (Germany)",
  "Butjadingen (Germany)",
  "Buttelborn (Germany)",
  "Buttelstedt (Germany)",
  "Buttenheim (Germany)",
  "Buttenwiesen (Germany)",
  "Butthard (Germany)",
  "Buttstedt (Germany)",
  "Butzbach (Germany)",
  "Butzow (Germany)",
  "Buxheim (Germany)",
  "Buxtehude (Germany)",
  "Cadenberge (Germany)",
  "Cadolzburg (Germany)",
  "Calau (Germany)",
  "Calbe (Germany)",
  "Calberlah (Germany)",
  "Calden (Germany)",
  "Callbach (Germany)",
  "Callenberg (Germany)",
  "Calvorde (Germany)",
  "Calw (Germany)",
  "Cambs (Germany)",
  "Camin (Germany)",
  "Cannewitz (Germany)",
  "Cappel (Germany)",
  "Cappeln (Germany)",
  "Caputh (Germany)",
  "Carlow (Germany)",
  "Carlsberg (Germany)",
  "Carmzow (Germany)",
  "Carolinensiel (Germany)",
  "Castrop-Rauxel (Germany)",
  "Catterfeld (Germany)",
  "Celle (Germany)",
  "Cham (Germany)",
  "Chamerau (Germany)",
  "Chemnitz (Germany)",
  "Chieming (Germany)",
  "Chiemsee (Germany)",
  "Chostlarn (Germany)",
  "Clausen (Germany)",
  "Claussnitz (Germany)",
  "Clausthal (Germany)",
  "Clausthal-Zellerfeld (Germany)",
  "Cleebronn (Germany)",
  "Cloppenburg (Germany)",
  "Coburg (Germany)",
  "Cochem (Germany)",
  "Coesfeld (Germany)",
  "Colbe (Germany)",
  "Colbitz (Germany)",
  "Colditz (Germany)",
  "Colmnitz (Germany)",
  "Colnrade (Germany)",
  "Cologne (Germany)",
  "Contwig (Germany)",
  "Coppenbrugge (Germany)",
  "Coppengrave (Germany)",
  "Cornberg (Germany)",
  "Coswig (Germany)",
  "Cottbus (Germany)",
  "Crailsheim (Germany)",
  "Cramonshagen (Germany)",
  "Cranz (Germany)",
  "Cremlingen (Germany)",
  "Creussen (Germany)",
  "Creuzburg (Germany)",
  "Crimmitschau (Germany)",
  "Crinitz (Germany)",
  "Crivitz (Germany)",
  "Crossen (Germany)",
  "Crostau (Germany)",
  "Crottendorf (Germany)",
  "Cunewalde (Germany)",
  "Cuxhaven (Germany)",
  "Daaden (Germany)",
  "Dabel (Germany)",
  "Dabelow (Germany)",
  "Dabendorf (Germany)",
  "Dabergotz (Germany)",
  "Dachau (Germany)",
  "Dachwig (Germany)",
  "Dackscheid (Germany)",
  "Dagersheim (Germany)",
  "Dahlem (Germany)",
  "Dahlen (Germany)",
  "Dahlenburg (Germany)",
  "Dahlenwarsleben (Germany)",
  "Dahlewitz (Germany)",
  "Dahme (Germany)",
  "Dahn (Germany)",
  "Dahnsdorf (Germany)",
  "Dallgow-Doeberitz (Germany)",
  "Dalum (Germany)",
  "Dambeck (Germany)",
  "Damlos (Germany)",
  "Damme (Germany)",
  "Damp (Germany)",
  "Danischenhagen (Germany)",
  "Dankenfeld (Germany)",
  "Dankmarshausen (Germany)",
  "Danndorf (Germany)",
  "Dannenberg (Germany)",
  "Dannstadt-Schauernheim (Germany)",
  "Dardesheim (Germany)",
  "Darmstadt (Germany)",
  "Darstein (Germany)",
  "Dasing (Germany)",
  "Dassel (Germany)",
  "Dassendorf (Germany)",
  "Dassow (Germany)",
  "Datteln (Germany)",
  "Dattenberg (Germany)",
  "Dauborn (Germany)",
  "Dauchingen (Germany)",
  "Daun (Germany)",
  "Dausenau (Germany)",
  "Deckenpfronn (Germany)",
  "Dedeleben (Germany)",
  "Dedelstorf (Germany)",
  "Dederstedt (Germany)",
  "Degerloch (Germany)",
  "Deggendorf (Germany)",
  "Deggenhausen (Germany)",
  "Deggingen (Germany)",
  "Deidesheim (Germany)",
  "Deilingen (Germany)",
  "Deining (Germany)",
  "Deiningen (Germany)",
  "Deinste (Germany)",
  "Deisenhofen (Germany)",
  "Deisslingen (Germany)",
  "Deizisau (Germany)",
  "Delbruck (Germany)",
  "Delingsdorf (Germany)",
  "Delitzsch (Germany)",
  "Dellfeld (Germany)",
  "Delligsen (Germany)",
  "Dellmensingen (Germany)",
  "Delmenhorst (Germany)",
  "Delve (Germany)",
  "Demitz-Thumitz (Germany)",
  "Demmin (Germany)",
  "Denkendorf (Germany)",
  "Denkingen (Germany)",
  "Denklingen (Germany)",
  "Densborn (Germany)",
  "Dentlein am Forst (Germany)",
  "Denzlingen (Germany)",
  "Derben (Germany)",
  "Derenburg (Germany)",
  "Dermbach (Germany)",
  "Dernau (Germany)",
  "Dernbach (Germany)",
  "Derschen (Germany)",
  "Dersum (Germany)",
  "Dessau (Germany)",
  "Dessow (Germany)",
  "Destedt (Germany)",
  "Detern (Germany)",
  "Detmold (Germany)",
  "Dettelbach (Germany)",
  "Dettenhausen (Germany)",
  "Dettighofen (Germany)",
  "Dettingen an der Erms (Germany)",
  "Dettingen an der Iller (Germany)",
  "Dettingen unter Teck (Germany)",
  "Dettmannsdorf (Germany)",
  "Deutsch (Germany)",
  "Deutsch Evern (Germany)",
  "Deutsches Haus (Germany)",
  "Deutz (Germany)",
  "Deutzen (Germany)",
  "Dexheim (Germany)",
  "Diana (Germany)",
  "Dibbersen (Germany)",
  "Diebach (Germany)",
  "Dieblich (Germany)",
  "Dieburg (Germany)",
  "Diedersdorf (Germany)",
  "Diedorf (Germany)",
  "Diefflen (Germany)",
  "Diekholzen (Germany)",
  "Dielheim (Germany)",
  "Dielingen (Germany)",
  "Dienheim (Germany)",
  "Dienstweiler (Germany)",
  "Diepenau (Germany)",
  "Diepersdorf (Germany)",
  "Diepholz (Germany)",
  "Dierdorf (Germany)",
  "Dierkow (Germany)",
  "Dieskau (Germany)",
  "Diessen am Ammersee (Germany)",
  "Dietenheim (Germany)",
  "Dietenhofen (Germany)",
  "Dietersburg (Germany)",
  "Dietersheim (Germany)",
  "Dietfurt (Germany)",
  "Dietingen (Germany)",
  "Dietlingen (Germany)",
  "Dietmannsried (Germany)",
  "Dietramszell (Germany)",
  "Dietrichingen (Germany)",
  "Dietzenbach (Germany)",
  "Diez (Germany)",
  "Dillenburg (Germany)",
  "Dillingen (Germany)",
  "Dillingen an der Donau (Germany)",
  "Dingelstadt (Germany)",
  "Dingelstedt (Germany)",
  "Dingolfing (Germany)",
  "Dingolshausen (Germany)",
  "Dingstede (Germany)",
  "Dinkelscherben (Germany)",
  "Dinklage (Germany)",
  "Dinslaken (Germany)",
  "Dintesheim (Germany)",
  "Dipbach (Germany)",
  "Dippach (Germany)",
  "Dipperz (Germany)",
  "Dippoldiswalde (Germany)",
  "Dirlewang (Germany)",
  "Dirmingen (Germany)",
  "Dirmstein (Germany)",
  "Dischingen (Germany)",
  "Dissen (Germany)",
  "Ditfurt (Germany)",
  "Dittelbrunn (Germany)",
  "Dittelsdorf (Germany)",
  "Dittelsheim-Hessloch (Germany)",
  "Dittenheim (Germany)",
  "Dittweiler (Germany)",
  "Ditzingen (Germany)",
  "Dobel (Germany)",
  "Dobeln (Germany)",
  "Doberlug-Kirchhain (Germany)",
  "Doberschau (Germany)",
  "Doberschutz (Germany)",
  "Dobitschen (Germany)",
  "Dobler (Germany)",
  "Dobra (Germany)",
  "Dockingen (Germany)",
  "Dogern (Germany)",
  "Dohlau (Germany)",
  "Dohle (Germany)",
  "Dohna (Germany)",
  "Dohren (Germany)",
  "Dolgesheim (Germany)",
  "Dollern (Germany)",
  "Dollnstein (Germany)",
  "Dombuhl (Germany)",
  "Domitz (Germany)",
  "Dommitzsch (Germany)",
  "Domsdorf (Germany)",
  "Domsuhl (Germany)",
  "Donaueschingen (Germany)",
  "Donaustauf (Germany)",
  "Donndorf (Germany)",
  "Donnersdorf (Germany)",
  "Donsieders (Germany)",
  "Donzdorf (Germany)",
  "Dorentrup (Germany)",
  "Dorf (Germany)",
  "Dorf Doberitz (Germany)",
  "Dorfen (Germany)",
  "Dorfles (Germany)",
  "Dorfprozelten (Germany)",
  "Dormagen (Germany)",
  "Dormettingen (Germany)",
  "Dornburg (Germany)",
  "Dorndorf (Germany)",
  "Dornhan (Germany)",
  "Dornheim (Germany)",
  "Dornstadt (Germany)",
  "Dornstetten (Germany)",
  "Dornum (Germany)",
  "Dorpen (Germany)",
  "Dorpstedt (Germany)",
  "Dorstadt (Germany)",
  "Dorsten (Germany)",
  "Dorth (Germany)",
  "Dortmund (Germany)",
  "Dorum (Germany)",
  "Dorverden (Germany)",
  "Dorzbach (Germany)",
  "Dossenheim (Germany)",
  "Dotlingen (Germany)",
  "Dotternhausen (Germany)",
  "Drachselsried (Germany)",
  "Drage (Germany)",
  "Dransfeld (Germany)",
  "Dranske (Germany)",
  "Drebach (Germany)",
  "Drebkau (Germany)",
  "Dreetz (Germany)",
  "Dreieich (Germany)",
  "Dreikirchen (Germany)",
  "Dreis (Germany)",
  "Dreisbach (Germany)",
  "Drelsdorf (Germany)",
  "Drensteinfurt (Germany)",
  "Dresden (Germany)",
  "Drestedt (Germany)",
  "Dreveskirchen (Germany)",
  "Driedorf (Germany)",
  "Driftsethe (Germany)",
  "Drochtersen (Germany)",
  "Drolshagen (Germany)",
  "Droyssig (Germany)",
  "Drubeck (Germany)",
  "Duchelsdorf (Germany)",
  "Ducherow (Germany)",
  "Duchroth (Germany)",
  "Dudenhofen (Germany)",
  "Duderode (Germany)",
  "Duderstadt (Germany)",
  "Duggendorf (Germany)",
  "Duingen (Germany)",
  "Duisburg (Germany)",
  "Dulmen (Germany)",
  "Dummerstorf (Germany)",
  "Dungenheim (Germany)",
  "Dunningen (Germany)",
  "Dunnwald (Germany)",
  "Dunzweiler (Germany)",
  "Durach (Germany)",
  "Durbach (Germany)",
  "Durbheim (Germany)",
  "Durchhausen (Germany)",
  "Durlangen (Germany)",
  "Durmentingen (Germany)",
  "Durmersheim (Germany)",
  "Durnau (Germany)",
  "Durrbrunn (Germany)",
  "Dusseldorf (Germany)",
  "Dusslingen (Germany)",
  "Ebeleben (Germany)",
  "Ebelsbach (Germany)",
  "Ebendorf (Germany)",
  "Ebensfeld (Germany)",
  "Ebenweiler (Germany)",
  "Eberbach (Germany)",
  "Eberdingen (Germany)",
  "Eberhardzell (Germany)",
  "Ebermannsdorf (Germany)",
  "Ebermannstadt (Germany)",
  "Ebern (Germany)",
  "Ebernhahn (Germany)",
  "Ebersbach (Germany)",
  "Ebersbach an der Fils (Germany)",
  "Ebersberg (Germany)",
  "Ebersdorf (Germany)",
  "Ebershausen (Germany)",
  "Eberstadt (Germany)",
  "Eberswalde (Germany)",
  "Ebertsheim (Germany)",
  "Ebhausen (Germany)",
  "Ebnat (Germany)",
  "Ebrach (Germany)",
  "Ebringen (Germany)",
  "Ebstorf (Germany)",
  "Eching (Germany)",
  "Echterdingen (Germany)",
  "Echzell (Germany)",
  "Eckartsberg (Germany)",
  "Eckartsberga (Germany)",
  "Eckernforde (Germany)",
  "Eckersdorf (Germany)",
  "Eckfeld (Germany)",
  "Ecklingerode (Germany)",
  "Eddelak (Germany)",
  "Edelsfeld (Germany)",
  "Edemissen (Germany)",
  "Eden (Germany)",
  "Edenkoben (Germany)",
  "Ederheim (Germany)",
  "Edesheim (Germany)",
  "Edewecht (Germany)",
  "Edingen-Neckarhausen (Germany)",
  "Edling (Germany)",
  "Effelder (Germany)",
  "Effeltrich (Germany)",
  "Efringen-Kirchen (Germany)",
  "Egeln (Germany)",
  "Egelsbach (Germany)",
  "Egenhausen (Germany)",
  "Egenhofen (Germany)",
  "Egesheim (Germany)",
  "Egestorf (Germany)",
  "Egg an der Gunz (Germany)",
  "Eggebek (Germany)",
  "Eggenfelden (Germany)",
  "Eggenstein-Leopoldshafen (Germany)",
  "Eggenthal (Germany)",
  "Eggersdorf (Germany)",
  "Eggesin (Germany)",
  "Eggingen (Germany)",
  "Egglham (Germany)",
  "Eggolsheim (Germany)",
  "Eggstatt (Germany)",
  "Eging (Germany)",
  "Egling (Germany)",
  "Egloffstein (Germany)",
  "Egmating (Germany)",
  "Ehekirchen (Germany)",
  "Ehingen (Germany)",
  "Ehlscheid (Germany)",
  "Ehndorf (Germany)",
  "Ehningen (Germany)",
  "Ehrenberg (Germany)",
  "Ehrenburg (Germany)",
  "Ehrenfeld (Germany)",
  "Ehrenfriedersdorf (Germany)",
  "Ehringshausen (Germany)",
  "Eibau (Germany)",
  "Eibelshausen (Germany)",
  "Eibelstadt (Germany)",
  "Eibenstock (Germany)",
  "Eibsee (Germany)",
  "Eich (Germany)",
  "Eichelgarten (Germany)",
  "Eichen (Germany)",
  "Eichenau (Germany)",
  "Eichenbarleben (Germany)",
  "Eichenberg (Germany)",
  "Eichenbuhl (Germany)",
  "Eichendorf (Germany)",
  "Eichenzell (Germany)",
  "Eicherloh (Germany)",
  "Eichstaett (Germany)",
  "Eichstedt (Germany)",
  "Eichstetten (Germany)",
  "Eichtersheim (Germany)",
  "Eichwalde (Germany)",
  "Eicklingen (Germany)",
  "Eigeltingen (Germany)",
  "Eilenburg (Germany)",
  "Eilenburg, Berg (Germany)",
  "Eilsleben (Germany)",
  "Eime (Germany)",
  "Eimeldingen (Germany)",
  "Eimsheim (Germany)",
  "Einbeck (Germany)",
  "Einhaus (Germany)",
  "Einhausen (Germany)",
  "Einsbach (Germany)",
  "Einselthum (Germany)",
  "Eiselfing (Germany)",
  "Eisenach (Germany)",
  "Eisenbach (Germany)",
  "Eisenberg (Germany)",
  "Eisfeld (Germany)",
  "Eisingen (Germany)",
  "Eislingen (Germany)",
  "Eitelborn (Germany)",
  "Eitensheim (Germany)",
  "Eiterfeld (Germany)",
  "Eitorf (Germany)",
  "Eitting (Germany)",
  "Elbe (Germany)",
  "Elbingerode (Germany)",
  "Eldena (Germany)",
  "Eldingen (Germany)",
  "Elend (Germany)",
  "Elfershausen (Germany)",
  "Elgersburg (Germany)",
  "Elisabeth-Sophien-Koog (Germany)",
  "Elkenroth (Germany)",
  "Ellefeld (Germany)",
  "Ellenberg (Germany)",
  "Ellerau (Germany)",
  "Ellerbek (Germany)",
  "Ellerdorf (Germany)",
  "Ellerhoop (Germany)",
  "Ellerstadt (Germany)",
  "Ellgau (Germany)",
  "Ellhofen (Germany)",
  "Ellingen (Germany)",
  "Ellrich (Germany)",
  "Ellwangen (Germany)",
  "Ellzee (Germany)",
  "Elm (Germany)",
  "Elmenhorst (Germany)",
  "Elmlohe (Germany)",
  "Elmpt (Germany)",
  "Elmshorn (Germany)",
  "Elmstein (Germany)",
  "Elsdorf (Germany)",
  "Elsdorf-Westermuhlen (Germany)",
  "Elsendorf (Germany)",
  "Elsenfeld (Germany)",
  "Elsfleth (Germany)",
  "Elster (Germany)",
  "Elsterberg (Germany)",
  "Elsterwerda (Germany)",
  "Elstorf (Germany)",
  "Elstra (Germany)",
  "Elterlein (Germany)",
  "Eltmann (Germany)",
  "Eltville (Germany)",
  "Elxleben (Germany)",
  "Elz (Germany)",
  "Elzach (Germany)",
  "Elze (Germany)",
  "Embsen (Germany)",
  "Emden (Germany)",
  "Emerkingen (Germany)",
  "Emlichheim (Germany)",
  "Emmelshausen (Germany)",
  "Emmendorf (Germany)",
  "Emmerich (Germany)",
  "Emmering (Germany)",
  "Emmerzhausen (Germany)",
  "Emmingen-Liptingen (Germany)",
  "Empfertshausen (Germany)",
  "Empfingen (Germany)",
  "Emsburen (Germany)",
  "Emsdetten (Germany)",
  "Emskirchen (Germany)",
  "Emstal (Germany)",
  "Emstek (Germany)",
  "Emtinghausen (Germany)",
  "Emtmannsberg (Germany)",
  "Endingen (Germany)",
  "Engden (Germany)",
  "Engelsberg (Germany)",
  "Engelsbrand (Germany)",
  "Engelsburg (Germany)",
  "Engelshof (Germany)",
  "Engelskirchen (Germany)",
  "Engelstadt (Germany)",
  "Engelthal (Germany)",
  "Engen (Germany)",
  "Enger (Germany)",
  "Eningen unter Achalm (Germany)",
  "Enkenbach-Alsenborn (Germany)",
  "Enkirch (Germany)",
  "Ennepetal (Germany)",
  "Ennigerloh (Germany)",
  "Ensdorf (Germany)",
  "Ensen (Germany)",
  "Entringen (Germany)",
  "Epfenbach (Germany)",
  "Epfendorf (Germany)",
  "Eppelborn (Germany)",
  "Eppelheim (Germany)",
  "Eppelsheim (Germany)",
  "Eppenbrunn (Germany)",
  "Eppendorf (Germany)",
  "Eppenrod (Germany)",
  "Eppertshausen (Germany)",
  "Eppingen (Germany)",
  "Eppishausen (Germany)",
  "Eppstein (Germany)",
  "Erbach (Germany)",
  "Erbach im Odenwald (Germany)",
  "Erbendorf (Germany)",
  "Erbes-Budesheim (Germany)",
  "Erda (Germany)",
  "Erdeborn (Germany)",
  "Erding (Germany)",
  "Erdmannhausen (Germany)",
  "Erdweg (Germany)",
  "Eresing (Germany)",
  "Erfde (Germany)",
  "Erfelden (Germany)",
  "Erftstadt (Germany)",
  "Erfurt (Germany)",
  "Ergersheim (Germany)",
  "Ergolding (Germany)",
  "Ergoldsbach (Germany)",
  "Erika (Germany)",
  "Ering (Germany)",
  "Eriskirch (Germany)",
  "Erkelenz (Germany)",
  "Erkheim (Germany)",
  "Erkner (Germany)",
  "Erkrath (Germany)",
  "Erlabrunn (Germany)",
  "Erlach (Germany)",
  "Erlangen (Germany)",
  "Erlau (Germany)",
  "Erlbach (Germany)",
  "Erlenbach (Germany)",
  "Erlenbach am Main (Germany)",
  "Erlenbach bei Marktheidenfeld (Germany)",
  "Erlenmoos (Germany)",
  "Erlensee (Germany)",
  "Erligheim (Germany)",
  "Ermershausen (Germany)",
  "Ermke (Germany)",
  "Erndtebruck (Germany)",
  "Ernsgaden (Germany)",
  "Ernst (Germany)",
  "Ernsthof (Germany)",
  "Ernstroda (Germany)",
  "Ernstthal (Germany)",
  "Erolzheim (Germany)",
  "Erpel (Germany)",
  "Erpolzheim (Germany)",
  "Ersingen (Germany)",
  "Ertingen (Germany)",
  "Erwitte (Germany)",
  "Erzenhausen (Germany)",
  "Erzhausen (Germany)",
  "Erzingen (Germany)",
  "Eschach (Germany)",
  "Eschau (Germany)",
  "Eschbach (Germany)",
  "Eschborn (Germany)",
  "Escheburg (Germany)",
  "Eschede (Germany)",
  "Eschelbronn (Germany)",
  "Eschenbach (Germany)",
  "Eschenlohe (Germany)",
  "Eschershausen (Germany)",
  "Eschfeld (Germany)",
  "Eschlkam (Germany)",
  "Eschwege (Germany)",
  "Eschweiler (Germany)",
  "Esens (Germany)",
  "Esgrus (Germany)",
  "Eslarn (Germany)",
  "Eslohe (Germany)",
  "Espelkamp (Germany)",
  "Espelkamp-Mittwald (Germany)",
  "Espenau (Germany)",
  "Espenhain (Germany)",
  "Essel (Germany)",
  "Esselborn (Germany)",
  "Essen (Germany)",
  "Essenbach (Germany)",
  "Essenheim (Germany)",
  "Essingen (Germany)",
  "Esslingen am Neckar (Germany)",
  "Estenfeld (Germany)",
  "Esterwegen (Germany)",
  "Esthal (Germany)",
  "Estorf (Germany)",
  "Etschberg (Germany)",
  "Etteln (Germany)",
  "Ettenheim (Germany)",
  "Ettenstatt (Germany)",
  "Ettersburg (Germany)",
  "Etterschlag (Germany)",
  "Ettleben (Germany)",
  "Ettlingen (Germany)",
  "Ettringen (Germany)",
  "Etzelwang (Germany)",
  "Etzenricht (Germany)",
  "Euerbach (Germany)",
  "Euerdorf (Germany)",
  "Eurasburg (Germany)",
  "Euskirchen (Germany)",
  "Eussenheim (Germany)",
  "Eutin (Germany)",
  "Eutingen (Germany)",
  "Everode (Germany)",
  "Eversmeer (Germany)",
  "Everswinkel (Germany)",
  "Evessen (Germany)",
  "Ewersbach (Germany)",
  "Eydelstedt (Germany)",
  "Eyendorf (Germany)",
  "Eystrup (Germany)",
  "Fachbach (Germany)",
  "Fahrdorf (Germany)",
  "Fahrenbach (Germany)",
  "Fahrendorf (Germany)",
  "Fahrenkrug (Germany)",
  "Fahrenzhausen (Germany)",
  "Fahrland (Germany)",
  "Faid (Germany)",
  "Falkenberg (Germany)",
  "Falkendorf (Germany)",
  "Falkenfels (Germany)",
  "Falkensee (Germany)",
  "Falkenstein (Germany)",
  "Fallersleben (Germany)",
  "Falls (Germany)",
  "Fambach (Germany)",
  "Farchant (Germany)",
  "Fargau-Pratjau (Germany)",
  "Farnroda (Germany)",
  "Farnstadt (Germany)",
  "Fassberg (Germany)",
  "Fatschenbrunn (Germany)",
  "Faulbach (Germany)",
  "Fehl-Ritzhausen (Germany)",
  "Fehmarn (Germany)",
  "Fehrbellin (Germany)",
  "Feilbingert (Germany)",
  "Feilitzsch (Germany)",
  "Feldafing (Germany)",
  "Feldberg (Germany)",
  "Feldberg-Ort (Germany)",
  "Felde (Germany)",
  "Feldkirchen (Germany)",
  "Feldkirchen-Westerham (Germany)",
  "Feldrennach (Germany)",
  "Fell (Germany)",
  "Fellbach (Germany)",
  "Felm (Germany)",
  "Felsberg (Germany)",
  "Ferchesar (Germany)",
  "Fernthal (Germany)",
  "Festenburg (Germany)",
  "Feucht (Germany)",
  "Feuchtwangen (Germany)",
  "Feusdorf (Germany)",
  "Fichtelberg (Germany)",
  "Fichtenberg (Germany)",
  "Fichtenwalde (Germany)",
  "Filderstadt (Germany)",
  "Filsen (Germany)",
  "Filsum (Germany)",
  "Filzen (Germany)",
  "Fincken (Germany)",
  "Finkenbach (Germany)",
  "Finnentrop (Germany)",
  "Finningen (Germany)",
  "Finowfurt (Germany)",
  "Finsing (Germany)",
  "Finsterwalde (Germany)",
  "Fintel (Germany)",
  "Firrel (Germany)",
  "Fischach (Germany)",
  "Fischbach (Germany)",
  "Fischbachau (Germany)",
  "Fischen (Germany)",
  "Fischerbach (Germany)",
  "Fischerhude (Germany)",
  "Fischingen (Germany)",
  "Flachslanden (Germany)",
  "Flacht (Germany)",
  "Flammersfeld (Germany)",
  "Flechtingen (Germany)",
  "Fleckeby (Germany)",
  "Flecken Zechlin (Germany)",
  "Flein (Germany)",
  "Fleisbach (Germany)",
  "Flensburg (Germany)",
  "Flieden (Germany)",
  "Flinsbach (Germany)",
  "Flintbek (Germany)",
  "Flintsbach (Germany)",
  "Floha (Germany)",
  "Flomborn (Germany)",
  "Flonheim (Germany)",
  "Florsheim (Germany)",
  "Florstadt (Germany)",
  "Floss (Germany)",
  "Flothe (Germany)",
  "Flurstedt (Germany)",
  "Fockbek (Germany)",
  "Fohren (Germany)",
  "Fohren-Linden (Germany)",
  "Forbach (Germany)",
  "Forchheim (Germany)",
  "Forchtenberg (Germany)",
  "Forheim (Germany)",
  "Forst (Germany)",
  "Forstern (Germany)",
  "Forsthaus (Germany)",
  "Forstinning (Germany)",
  "Frammersbach (Germany)",
  "Frankenau (Germany)",
  "Frankenberg (Germany)",
  "Frankenheim (Germany)",
  "Frankenstein (Germany)",
  "Frankenthal (Germany)",
  "Frankenwinheim (Germany)",
  "Frankfurt (Oder) (Germany)",
  "Frankfurt am Main (Germany)",
  "Frankisch-Crumbach (Germany)",
  "Frankweiler (Germany)",
  "Franzenheim (Germany)",
  "Frasdorf (Germany)",
  "Frauenau (Germany)",
  "Frauendorf (Germany)",
  "Frauenstein (Germany)",
  "Fraunberg (Germany)",
  "Fraureuth (Germany)",
  "Frechen (Germany)",
  "Freckenfeld (Germany)",
  "Freckenhorst (Germany)",
  "Fredelsloh (Germany)",
  "Freden (Germany)",
  "Fredersdorf (Germany)",
  "Freiberg (Germany)",
  "Freiberg am Neckar (Germany)",
  "Freiburg (Germany)",
  "Freihung (Germany)",
  "Freilassing (Germany)",
  "Freimersheim (Germany)",
  "Freinsheim (Germany)",
  "Freirachdorf (Germany)",
  "Freisbach (Germany)",
  "Freisen (Germany)",
  "Freising (Germany)",
  "Freital (Germany)",
  "Freiwalde (Germany)",
  "Fremdingen (Germany)",
  "Frensdorf (Germany)",
  "Freren (Germany)",
  "Fresenburg (Germany)",
  "Freudenberg (Germany)",
  "Freudenburg (Germany)",
  "Freudenstadt (Germany)",
  "Freudental (Germany)",
  "Freudenweiler (Germany)",
  "Freund (Germany)",
  "Freyburg (Germany)",
  "Freystadt (Germany)",
  "Freyung (Germany)",
  "Frickenhausen (Germany)",
  "Frickingen (Germany)",
  "Fridingen an der Donau (Germany)",
  "Fridolfing (Germany)",
  "Friedberg (Germany)",
  "Friedeburg (Germany)",
  "Friedelsheim (Germany)",
  "Friedensau (Germany)",
  "Friedensdorf (Germany)",
  "Friedenweiler (Germany)",
  "Friedersdorf (Germany)",
  "Friedewald (Germany)",
  "Friedland (Germany)",
  "Friedrichroda (Germany)",
  "Friedrichsdorf (Germany)",
  "Friedrichshafen (Germany)",
  "Friedrichsholm (Germany)",
  "Friedrichskoog (Germany)",
  "Friedrichsort (Germany)",
  "Friedrichstadt (Germany)",
  "Friedrichsthal (Germany)",
  "Friedrichswerth (Germany)",
  "Frielendorf (Germany)",
  "Friemar (Germany)",
  "Friesack (Germany)",
  "Friesenhagen (Germany)",
  "Friesenheim (Germany)",
  "Friesenried (Germany)",
  "Friesoythe (Germany)",
  "Friolzheim (Germany)",
  "Frittlingen (Germany)",
  "Fritzlar (Germany)",
  "Frohburg (Germany)",
  "Frommern (Germany)",
  "Frondenberg (Germany)",
  "Fronhausen (Germany)",
  "Fronhofen (Germany)",
  "Frontenhausen (Germany)",
  "Frose (Germany)",
  "Fuchshain (Germany)",
  "Fuchsstadt (Germany)",
  "Fulda (Germany)",
  "Fuldabruck (Germany)",
  "Fuldatal (Germany)",
  "Funfeichen (Germany)",
  "Funfstetten (Germany)",
  "Furholzen (Germany)",
  "Furstenau (Germany)",
  "Furstenberg (Germany)",
  "Fursteneck (Germany)",
  "Furstenstein (Germany)",
  "Furstenwald (Germany)",
  "Furstenwalde (Germany)",
  "Furstenzell (Germany)",
  "Furth (Germany)",
  "Furth im Wald (Germany)",
  "Furthen (Germany)",
  "Furtwangen im Schwarzwald (Germany)",
  "Fusternberg (Germany)",
  "Gablenz (Germany)",
  "Gablingen (Germany)",
  "Gachenbach (Germany)",
  "Gadebusch (Germany)",
  "Gadegast (Germany)",
  "Gagern (Germany)",
  "Gaggenau (Germany)",
  "Gahlkow (Germany)",
  "Gaiberg (Germany)",
  "Gaienhofen (Germany)",
  "Gaildorf (Germany)",
  "Gailingen (Germany)",
  "Gaimersheim (Germany)",
  "Gaishofen (Germany)",
  "Gaissach (Germany)",
  "Galenbeck (Germany)",
  "Gammellund (Germany)",
  "Gammelsdorf (Germany)",
  "Gammelshausen (Germany)",
  "Gammertingen (Germany)",
  "Ganderkesee (Germany)",
  "Gangelt (Germany)",
  "Gangkofen (Germany)",
  "Gangloff (Germany)",
  "Garbek (Germany)",
  "Garbsen (Germany)",
  "Garching an der Alz (Germany)",
  "Garching bei Munchen (Germany)",
  "Gardelegen (Germany)",
  "Garden (Germany)",
  "Garlstorf (Germany)",
  "Garmisch-Partenkirchen (Germany)",
  "Garrel (Germany)",
  "Gars (Germany)",
  "Gartow (Germany)",
  "Gartringen (Germany)",
  "Garz (Germany)",
  "Gatersleben (Germany)",
  "Gattendorf (Germany)",
  "Gau-Algesheim (Germany)",
  "Gau-Bickelheim (Germany)",
  "Gau-Bischofsheim (Germany)",
  "Gau-Odernheim (Germany)",
  "Gaukonigshofen (Germany)",
  "Gauting (Germany)",
  "Gebenbach (Germany)",
  "Gebesee (Germany)",
  "Gebhardshain (Germany)",
  "Gechingen (Germany)",
  "Gedern (Germany)",
  "Geeste (Germany)",
  "Geesthacht (Germany)",
  "Gefell (Germany)",
  "Gefrees (Germany)",
  "Gehaus (Germany)",
  "Gehlenbeck (Germany)",
  "Gehofen (Germany)",
  "Gehrde (Germany)",
  "Gehrden (Germany)",
  "Gehrweiler (Germany)",
  "Geiersthal (Germany)",
  "Geilenkirchen (Germany)",
  "Geinsheim (Germany)",
  "Geiselbach (Germany)",
  "Geiselhoring (Germany)",
  "Geiselwind (Germany)",
  "Geisenfeld (Germany)",
  "Geisenhain (Germany)",
  "Geisenhausen (Germany)",
  "Geisenheim (Germany)",
  "Geisfeld (Germany)",
  "Geisig (Germany)",
  "Geising (Germany)",
  "Geisingen (Germany)",
  "Geisling (Germany)",
  "Geislingen (Germany)",
  "Geislingen an der Steige (Germany)",
  "Geithain (Germany)",
  "Gelbensande (Germany)",
  "Geldern (Germany)",
  "Geldersheim (Germany)",
  "Gelenau (Germany)",
  "Gelnhausen (Germany)",
  "Gelsenkirchen (Germany)",
  "Geltendorf (Germany)",
  "Gelting (Germany)",
  "Gemmingen (Germany)",
  "Gemmrigheim (Germany)",
  "Gemunden (Germany)",
  "Gemunden an der Wohra (Germany)",
  "Genderkingen (Germany)",
  "Gendorf (Germany)",
  "Gengenbach (Germany)",
  "Genkingen (Germany)",
  "Gensingen (Germany)",
  "Genthin (Germany)",
  "Georgensgmund (Germany)",
  "Georgenthal (Germany)",
  "Georgsdorf (Germany)",
  "Georgsmarienhutte (Germany)",
  "Gera (Germany)",
  "Geraberg (Germany)",
  "Gerabronn (Germany)",
  "Gerach (Germany)",
  "Geratskirchen (Germany)",
  "Gerau (Germany)",
  "Gerbershausen (Germany)",
  "Gerbrunn (Germany)",
  "Gerbstedt (Germany)",
  "Gerdau (Germany)",
  "Geretsried (Germany)",
  "Gerhardshofen (Germany)",
  "Gerichshain (Germany)",
  "Geringswalde (Germany)",
  "Gerlachsheim (Germany)",
  "Gerlfangen (Germany)",
  "Gerlingen (Germany)",
  "Germering (Germany)",
  "Germersheim (Germany)",
  "Gernrode (Germany)",
  "Gernsbach (Germany)",
  "Gernsheim (Germany)",
  "Gerolfingen (Germany)",
  "Gerolsbach (Germany)",
  "Gerolsheim (Germany)",
  "Gerolstein (Germany)",
  "Gerolzhofen (Germany)",
  "Gersdorf (Germany)",
  "Gersfeld (Germany)",
  "Gershasen (Germany)",
  "Gersheim (Germany)",
  "Gerstetten (Germany)",
  "Gersthofen (Germany)",
  "Gerstungen (Germany)",
  "Gerwisch (Germany)",
  "Gerzen (Germany)",
  "Gescher (Germany)",
  "Geschwenda (Germany)",
  "Geseke (Germany)",
  "Gessertshausen (Germany)",
  "Gestungshausen (Germany)",
  "Getelo (Germany)",
  "Gettorf (Germany)",
  "Gevelsberg (Germany)",
  "Gevensleben (Germany)",
  "Geversdorf (Germany)",
  "Geyer (Germany)",
  "Giebelstadt (Germany)",
  "Gieboldehausen (Germany)",
  "Gielde (Germany)",
  "Gieleroth (Germany)",
  "Giengen an der Brenz (Germany)",
  "Gierstadt (Germany)",
  "Giesen (Germany)",
  "Giesenkirchen (Germany)",
  "Giessen (Germany)",
  "Gifhorn (Germany)",
  "Giggenhausen (Germany)",
  "Gilching (Germany)",
  "Gillenfeld (Germany)",
  "Gillersdorf (Germany)",
  "Gilserberg (Germany)",
  "Gimbsheim (Germany)",
  "Gingen an der Fils (Germany)",
  "Gingst (Germany)",
  "Ginsheim-Gustavsburg (Germany)",
  "Girod (Germany)",
  "Gittelde (Germany)",
  "Gladbach (Germany)",
  "Gladbeck (Germany)",
  "Gladenbach (Germany)",
  "Glan-Munchweiler (Germany)",
  "Glandorf (Germany)",
  "Glasewitz (Germany)",
  "Glashagen (Germany)",
  "Glashutte (Germany)",
  "Glashutten (Germany)",
  "Glattbach (Germany)",
  "Glatten (Germany)",
  "Glauburg (Germany)",
  "Glauchau (Germany)",
  "Gleichen (Germany)",
  "Gleiritsch (Germany)",
  "Gleissenberg (Germany)",
  "Gleisweiler (Germany)",
  "Gleschendorf (Germany)",
  "Glien (Germany)",
  "Glienicke (Germany)",
  "Glinde (Germany)",
  "Glindenberg (Germany)",
  "Glonn (Germany)",
  "Gluckstadt (Germany)",
  "Gmain bei Weidach (Germany)",
  "Gmund am Tegernsee (Germany)",
  "Gnadau (Germany)",
  "Gnarrenburg (Germany)",
  "Gnaschwitz (Germany)",
  "Gnoien (Germany)",
  "Gnotzheim (Germany)",
  "Gnutz (Germany)",
  "Goch (Germany)",
  "Gochsheim (Germany)",
  "Goda (Germany)",
  "Goggingen (Germany)",
  "Gohrde (Germany)",
  "Gohren (Germany)",
  "Goldbach (Germany)",
  "Goldberg (Germany)",
  "Goldelund (Germany)",
  "Goldenstedt (Germany)",
  "Goldewin (Germany)",
  "Goldkronach (Germany)",
  "Gollheim (Germany)",
  "Gollhofen (Germany)",
  "Golm (Germany)",
  "Golmbach (Germany)",
  "Golssen (Germany)",
  "Golzow (Germany)",
  "Gomadingen (Germany)",
  "Gomaringen (Germany)",
  "Gommern (Germany)",
  "Gommersheim (Germany)",
  "Gondelsheim (Germany)",
  "Gondenbrett (Germany)",
  "Gonnheim (Germany)",
  "Gorenzen (Germany)",
  "Goritz (Germany)",
  "Gorkwitz (Germany)",
  "Gornsdorf (Germany)",
  "Gorschendorf (Germany)",
  "Gorwihl (Germany)",
  "Goseck (Germany)",
  "Gosheim (Germany)",
  "Goslar (Germany)",
  "Gossel (Germany)",
  "Gossen (Germany)",
  "Gossersweiler-Stein (Germany)",
  "Gossnitz (Germany)",
  "Gossweinstein (Germany)",
  "Gotha (Germany)",
  "Gotteszell (Germany)",
  "Gottfrieding (Germany)",
  "Gottmadingen (Germany)",
  "Graach (Germany)",
  "Grabau (Germany)",
  "Graben (Germany)",
  "Graben-Neudorf (Germany)",
  "Grabenstatt (Germany)",
  "Grabenstetten (Germany)",
  "Grabow (Germany)",
  "Grafelfing (Germany)",
  "Grafenau (Germany)",
  "Grafenberg (Germany)",
  "Grafengehaig (Germany)",
  "Grafenhainichen (Germany)",
  "Grafenhausen (Germany)",
  "Grafenrheinfeld (Germany)",
  "Grafenroda (Germany)",
  "Grafenthal (Germany)",
  "Grafenwohr (Germany)",
  "Grafhorst (Germany)",
  "Grafing bei Munchen (Germany)",
  "Grafrath (Germany)",
  "Grafschaft (Germany)",
  "Grainau (Germany)",
  "Graitschen (Germany)",
  "Grambin (Germany)",
  "Grambow (Germany)",
  "Grammow (Germany)",
  "Gramzow (Germany)",
  "Gransee (Germany)",
  "Grasberg (Germany)",
  "Grasbrunn (Germany)",
  "Grassau (Germany)",
  "Gravenbruch (Germany)",
  "Graveneck (Germany)",
  "Gravenwiesbach (Germany)",
  "Grebbin (Germany)",
  "Grebenau (Germany)",
  "Grebenhain (Germany)",
  "Grebenstein (Germany)",
  "Greding (Germany)",
  "Green (Germany)",
  "Grefrath (Germany)",
  "Greifenberg (Germany)",
  "Greifenstein (Germany)",
  "Greifswald (Germany)",
  "Greiling (Germany)",
  "Greiz (Germany)",
  "Gremersdorf (Germany)",
  "Gremsdorf (Germany)",
  "Grenzach-Wyhlen (Germany)",
  "Grenzau (Germany)",
  "Greppin (Germany)",
  "Grethem (Germany)",
  "Grethen (Germany)",
  "Grettstadt (Germany)",
  "Greussen (Germany)",
  "Greussenheim (Germany)",
  "Greven (Germany)",
  "Grevenbroich (Germany)",
  "Grevesmuhlen (Germany)",
  "Gribow (Germany)",
  "Gries (Germany)",
  "Griesheim (Germany)",
  "Griesingen (Germany)",
  "Griesstatt (Germany)",
  "Grimma (Germany)",
  "Grimmen (Germany)",
  "Grobenzell (Germany)",
  "Grobzig (Germany)",
  "Groditz (Germany)",
  "Groeningen (Germany)",
  "Groitzsch (Germany)",
  "Grolsheim (Germany)",
  "Grombach (Germany)",
  "Gromitz (Germany)",
  "Gronau (Germany)",
  "Gronenbach (Germany)",
  "Gronwohld (Germany)",
  "Gross Bengerstorf (Germany)",
  "Gross Gronau (Germany)",
  "Gross Kiesow (Germany)",
  "Gross Koris (Germany)",
  "Gross Kreutz (Germany)",
  "Gross Kummerfeld (Germany)",
  "Gross Lindow (Germany)",
  "Gross Miltzow (Germany)",
  "Gross Oesingen (Germany)",
  "Gross Rheide (Germany)",
  "Gross Santersleben (Germany)",
  "Gross Twulpstedt (Germany)",
  "Gross Wittensee (Germany)",
  "Gross-Bieberau (Germany)",
  "Gross-Gerau (Germany)",
  "Gross-Rohrheim (Germany)",
  "Gross-Umstadt (Germany)",
  "Gross-Zimmern (Germany)",
  "Grossaitingen (Germany)",
  "Grossalmerode (Germany)",
  "Grossbardorf (Germany)",
  "Grossbeeren (Germany)",
  "Grossbettlingen (Germany)",
  "Grossbottwar (Germany)",
  "Grossbreitenbach (Germany)",
  "Grosselfingen (Germany)",
  "Grossenaspe (Germany)",
  "Grossengottern (Germany)",
  "Grossenhain (Germany)",
  "Grossenkneten (Germany)",
  "Grossenluder (Germany)",
  "Grossensee (Germany)",
  "Grossenseebach (Germany)",
  "Grossenwiehe (Germany)",
  "Grosserlach (Germany)",
  "Grosshabersdorf (Germany)",
  "Grosshansdorf (Germany)",
  "Grossharthau (Germany)",
  "Grossheide (Germany)",
  "Grossheirath (Germany)",
  "Grossheubach (Germany)",
  "Grosskarolinenfeld (Germany)",
  "Grosskorbetha (Germany)",
  "Grosskrotzenburg (Germany)",
  "Grosskugel (Germany)",
  "Grosslittgen (Germany)",
  "Grosslobichau (Germany)",
  "Grossmaischeid (Germany)",
  "Grossmehring (Germany)",
  "Grossmonra (Germany)",
  "Grossostheim (Germany)",
  "Grossposna (Germany)",
  "Grossraschen (Germany)",
  "Grossrinderfeld (Germany)",
  "Grossrohrsdorf (Germany)",
  "Grossrosseln (Germany)",
  "Grossruckerswalde (Germany)",
  "Grossschirma (Germany)",
  "Grossschonau (Germany)",
  "Grosssolt (Germany)",
  "Grosswallstadt (Germany)",
  "Grosswechsungen (Germany)",
  "Grove (Germany)",
  "Gruibingen (Germany)",
  "Gruna (Germany)",
  "Grunberg (Germany)",
  "Grundau (Germany)",
  "Grundhof (Germany)",
  "Grunenberg (Germany)",
  "Grunenplan (Germany)",
  "Grunenthal (Germany)",
  "Grunhainichen (Germany)",
  "Grunheide (Germany)",
  "Grunhof (Germany)",
  "Grunkraut (Germany)",
  "Grunstadt (Germany)",
  "Grunwald (Germany)",
  "Gschwend (Germany)",
  "Guben (Germany)",
  "Guckheim (Germany)",
  "Gudensberg (Germany)",
  "Guenzburg (Germany)",
  "Guestrow (Germany)",
  "Guglingen (Germany)",
  "Gulzow (Germany)",
  "Gulzowshof (Germany)",
  "Gummersbach (Germany)",
  "Gundelfingen (Germany)",
  "Gundelsheim (Germany)",
  "Gundernhausen (Germany)",
  "Gundersheim (Germany)",
  "Gundremmingen (Germany)",
  "Gunningen (Germany)",
  "Guntersblum (Germany)",
  "Guntersleben (Germany)",
  "Gunthersleben (Germany)",
  "Gunzenhausen (Germany)",
  "Gurtweil (Germany)",
  "Gustavel (Germany)",
  "Gusten (Germany)",
  "Gusterath (Germany)",
  "Gutach (Schwarzwaldbahn) (Germany)",
  "Gutach im Breisgau (Germany)",
  "Gutenacker (Germany)",
  "Gutenberg (Germany)",
  "Gutenstetten (Germany)",
  "Gutenzell-Hurbel (Germany)",
  "Gutmadingen (Germany)",
  "Gutow (Germany)",
  "Gutzkow (Germany)",
  "Guxhagen (Germany)",
  "Gyhum (Germany)",
  "Haag (Germany)",
  "Haag an der Amper (Germany)",
  "Haan (Germany)",
  "Haar (Germany)",
  "Haarbach (Germany)",
  "Haaren (Germany)",
  "Habach (Germany)",
  "Habenhausen (Germany)",
  "Habersdorf (Germany)",
  "Hachenburg (Germany)",
  "Hackenheim (Germany)",
  "Hadamar (Germany)",
  "Hademstorf (Germany)",
  "Haenigsen (Germany)",
  "Hafenlohr (Germany)",
  "Haffkrug (Germany)",
  "Hage (Germany)",
  "Hagelstadt (Germany)",
  "Hagen (Germany)",
  "Hagenbach (Germany)",
  "Hagenburg (Germany)",
  "Hagenow (Germany)",
  "Hagnau (Germany)",
  "Hahn (Germany)",
  "Hahnbach (Germany)",
  "Hahnenbach (Germany)",
  "Hahnheim (Germany)",
  "Hahnstatten (Germany)",
  "Haibach (Germany)",
  "Haide (Germany)",
  "Haidhof (Germany)",
  "Haidmuhle (Germany)",
  "Haiger (Germany)",
  "Haigerloch (Germany)",
  "Haimhausen (Germany)",
  "Haiming (Germany)",
  "Haina (Germany)",
  "Hainewalde (Germany)",
  "Hainichen (Germany)",
  "Hainsfarth (Germany)",
  "Haiterbach (Germany)",
  "Halbe (Germany)",
  "Halberg (Germany)",
  "Halberstadt (Germany)",
  "Halblech (Germany)",
  "Halbs (Germany)",
  "Haldensleben I (Germany)",
  "Haldenwang (Germany)",
  "Halfing (Germany)",
  "Hallbergmoos (Germany)",
  "Halle (Germany)",
  "Halle Neustadt (Germany)",
  "Hallenberg (Germany)",
  "Hallerndorf (Germany)",
  "Hallnberg (Germany)",
  "Hallschlag (Germany)",
  "Hallstadt (Germany)",
  "Halsbach (Germany)",
  "Halsbrucke (Germany)",
  "Halsenbach (Germany)",
  "Halstenbek (Germany)",
  "Haltern (Germany)",
  "Halver (Germany)",
  "Halvesbostel (Germany)",
  "Hamb (Germany)",
  "Hamberge (Germany)",
  "Hambergen (Germany)",
  "Hambrucken (Germany)",
  "Hambuhren (Germany)",
  "Hamburg (Germany)",
  "Hamdorf (Germany)",
  "Hamelin (Germany)",
  "Hamm (Germany)",
  "Hammah (Germany)",
  "Hammelburg (Germany)",
  "Hammersbach (Germany)",
  "Hamminkeln (Germany)",
  "Hamwarde (Germany)",
  "Hanau (Germany)",
  "Handeloh (Germany)",
  "Handewitt (Germany)",
  "Hanerau-Hademarschen (Germany)",
  "Hanhofen (Germany)",
  "Hankensbuttel (Germany)",
  "Hannover (Germany)",
  "Hanover (Germany)",
  "Hanstedt (Germany)",
  "Happurg (Germany)",
  "Harburg (Germany)",
  "Hard (Germany)",
  "Hardegsen (Germany)",
  "Hardenberg (Germany)",
  "Hardheim (Germany)",
  "Hardt (Germany)",
  "Haren (Germany)",
  "Hargesheim (Germany)",
  "Harlachen (Germany)",
  "Harmsdorf (Germany)",
  "Harmstorf (Germany)",
  "Harpstedt (Germany)",
  "Harra (Germany)",
  "Harrislee (Germany)",
  "Harschbach (Germany)",
  "Harsefeld (Germany)",
  "Harsewinkel (Germany)",
  "Harsleben (Germany)",
  "Harste (Germany)",
  "Harsum (Germany)",
  "Hartenfels (Germany)",
  "Hartenholm (Germany)",
  "Hartenstein (Germany)",
  "Hartha (Germany)",
  "Harthausen (Germany)",
  "Hartheim (Germany)",
  "Hartmannsdorf (Germany)",
  "Harxheim (Germany)",
  "Harzgerode (Germany)",
  "Hasbergen (Germany)",
  "Haschbach an der Glan (Germany)",
  "Hasel (Germany)",
  "Haselbach (Germany)",
  "Haseldorf (Germany)",
  "Haselunne (Germany)",
  "Hasenmoor (Germany)",
  "Haslach im Kinzigtal (Germany)",
  "Hasloch (Germany)",
  "Hasloh (Germany)",
  "Hassel (Germany)",
  "Hasselbach (Germany)",
  "Hasselberg (Germany)",
  "Hasselfelde (Germany)",
  "Hassfurt (Germany)",
  "Hassloch (Germany)",
  "Hassmersheim (Germany)",
  "Haste (Germany)",
  "Hastenrath (Germany)",
  "Haswede (Germany)",
  "Hatten (Germany)",
  "Hattenhofen (Germany)",
  "Hattersheim (Germany)",
  "Hattert (Germany)",
  "Hattingen (Germany)",
  "Hattorf (Germany)",
  "Hattstedt (Germany)",
  "Hatzenbuhl (Germany)",
  "Hatzfeld (Germany)",
  "Haus (Germany)",
  "Hausach (Germany)",
  "Hausen (Germany)",
  "Hausen am Tann (Germany)",
  "Hausen ob Verena (Germany)",
  "Hausen uber Aar (Germany)",
  "Hauser (Germany)",
  "Hausern (Germany)",
  "Hausham (Germany)",
  "Hausten (Germany)",
  "Hauteroda (Germany)",
  "Hauzenberg (Germany)",
  "Havelberg (Germany)",
  "Haverkamp (Germany)",
  "Havixbeck (Germany)",
  "Hawangen (Germany)",
  "Hayingen (Germany)",
  "Hebertsfelden (Germany)",
  "Hebertshausen (Germany)",
  "Hechingen (Germany)",
  "Hechthausen (Germany)",
  "Hecklingen (Germany)",
  "Heddesheim (Germany)",
  "Heede (Germany)",
  "Heeg (Germany)",
  "Heek (Germany)",
  "Heeslingen (Germany)",
  "Hefersweiler (Germany)",
  "Hegge (Germany)",
  "Hehlen (Germany)",
  "Heide (Germany)",
  "Heideck (Germany)",
  "Heidehof (Germany)",
  "Heidekamp (Germany)",
  "Heidelberg (Germany)",
  "Heiden (Germany)",
  "Heidenau (Germany)",
  "Heidenburg (Germany)",
  "Heidenheim (Germany)",
  "Heidesheim (Germany)",
  "Heigenbrucken (Germany)",
  "Heikendorf (Germany)",
  "Heilbad Heiligenstadt (Germany)",
  "Heilbronn (Germany)",
  "Heiligengrabe (Germany)",
  "Heiligenhafen (Germany)",
  "Heiligenhagen (Germany)",
  "Heiligenhaus (Germany)",
  "Heiligenroth (Germany)",
  "Heiligenstadt (Germany)",
  "Heiligenstedtenerkamp (Germany)",
  "Heiligenthal (Germany)",
  "Heiligkreuzsteinach (Germany)",
  "Heilingen (Germany)",
  "Heilsbronn (Germany)",
  "Heimbach (Germany)",
  "Heimbuchenthal (Germany)",
  "Heimenkirch (Germany)",
  "Heimertingen (Germany)",
  "Heimerzheim (Germany)",
  "Heimsheim (Germany)",
  "Heimstetten (Germany)",
  "Heinade (Germany)",
  "Heinbockel (Germany)",
  "Heinersreuth (Germany)",
  "Heiningen (Germany)",
  "Heinkenborstel (Germany)",
  "Heinrichsthal (Germany)",
  "Heinsberg (Germany)",
  "Heinsdorf (Germany)",
  "Heinz (Germany)",
  "Heinzenberg (Germany)",
  "Heinzenhausen (Germany)",
  "Heisede (Germany)",
  "Heist (Germany)",
  "Heitersheim (Germany)",
  "Helbra (Germany)",
  "Heldrungen (Germany)",
  "Helfant (Germany)",
  "Helferskirchen (Germany)",
  "Hellenhahn-Schellenberg (Germany)",
  "Hellenthal (Germany)",
  "Hellstein (Germany)",
  "Hellweg (Germany)",
  "Helmbrechts (Germany)",
  "Helmenzen (Germany)",
  "Helmern (Germany)",
  "Helmershausen (Germany)",
  "Helmstadt (Germany)",
  "Helmste (Germany)",
  "Helmstedt (Germany)",
  "Helpsen (Germany)",
  "Helsa (Germany)",
  "Heltersberg (Germany)",
  "Helvesiek (Germany)",
  "Hemau (Germany)",
  "Hemdingen (Germany)",
  "Hemelingen (Germany)",
  "Hemer (Germany)",
  "Hemhofen (Germany)",
  "Hemmelzen (Germany)",
  "Hemmersdorf (Germany)",
  "Hemmingen (Germany)",
  "Hemmingstedt (Germany)",
  "Hemmoor (Germany)",
  "Hemsbach (Germany)",
  "Hemsbunde (Germany)",
  "Hemslingen (Germany)",
  "Henfenfeld (Germany)",
  "Hengersberg (Germany)",
  "Hennef (Germany)",
  "Hennickendorf (Germany)",
  "Hennigsdorf (Germany)",
  "Hennstedt (Germany)",
  "Hennweiler (Germany)",
  "Henstedt-Ulzburg (Germany)",
  "Hepberg (Germany)",
  "Heppdiel (Germany)",
  "Heppenheim an der Bergstrasse (Germany)",
  "Hepstedt (Germany)",
  "Herbertingen (Germany)",
  "Herbolzheim (Germany)",
  "Herborn (Germany)",
  "Herbrechtingen (Germany)",
  "Herbsleben (Germany)",
  "Herbstein (Germany)",
  "Herdecke (Germany)",
  "Herdorf (Germany)",
  "Herdwangen-Schonach (Germany)",
  "Herfatz (Germany)",
  "Herford (Germany)",
  "Herforst (Germany)",
  "Hergenfeld (Germany)",
  "Hergisdorf (Germany)",
  "Hergolding (Germany)",
  "Hergolshausen (Germany)",
  "Heringen (Germany)",
  "Heringsdorf (Germany)",
  "Herleshausen (Germany)",
  "Hermannsburg (Germany)",
  "Hermaringen (Germany)",
  "Hermersberg (Germany)",
  "Hermeskeil (Germany)",
  "Hermsdorf (Germany)",
  "Herne (Germany)",
  "Heroldsbach (Germany)",
  "Heroldsberg (Germany)",
  "Herren Steinfeld (Germany)",
  "Herren-Sulzbach (Germany)",
  "Herrenberg (Germany)",
  "Herresbach (Germany)",
  "Herrieden (Germany)",
  "Herringen (Germany)",
  "Herrischried (Germany)",
  "Herrnburg (Germany)",
  "Herrnhut (Germany)",
  "Herrsching am Ammersee (Germany)",
  "Herrstein (Germany)",
  "Hersbruck (Germany)",
  "Herschbach (Germany)",
  "Herschberg (Germany)",
  "Herscheid (Germany)",
  "Herschweiler-Pettersheim (Germany)",
  "Herten (Germany)",
  "Herxheim (Germany)",
  "Herzberg (Germany)",
  "Herzberg am Harz (Germany)",
  "Herzebrock (Germany)",
  "Herzfeld (Germany)",
  "Herzlake (Germany)",
  "Herzogenaurach (Germany)",
  "Herzogenrath (Germany)",
  "Herzogsweiler (Germany)",
  "Hesedorf (Germany)",
  "Hesel (Germany)",
  "Hespe (Germany)",
  "Hessdorf (Germany)",
  "Hesselbach (Germany)",
  "Hessen (Germany)",
  "Hessheim (Germany)",
  "Hessigheim (Germany)",
  "Hessisch Lichtenau (Germany)",
  "Hessisch Oldendorf (Germany)",
  "Hetschburg (Germany)",
  "Hettenleidelheim (Germany)",
  "Hettenrodt (Germany)",
  "Hettstadt (Germany)",
  "Hettstedt (Germany)",
  "Hetzenhausen (Germany)",
  "Hetzerath (Germany)",
  "Hetzlinshofen (Germany)",
  "Hetzwege (Germany)",
  "Heubach (Germany)",
  "Heuchelheim (Germany)",
  "Heuchlingen (Germany)",
  "Heudeber (Germany)",
  "Heudorf (Germany)",
  "Heuerssen (Germany)",
  "Heusenstamm (Germany)",
  "Heustreu (Germany)",
  "Heusweiler (Germany)",
  "Heuweiler (Germany)",
  "Heyerode (Germany)",
  "Hiddenhausen (Germany)",
  "Hiddensee (Germany)",
  "Hilbersdorf (Germany)",
  "Hilchenbach (Germany)",
  "Hildburghausen (Germany)",
  "Hildebrandshausen (Germany)",
  "Hilden (Germany)",
  "Hilders (Germany)",
  "Hildesheim (Germany)",
  "Hildrizhausen (Germany)",
  "Hilgermissen (Germany)",
  "Hilgert (Germany)",
  "Hilgertshausen-Tandern (Germany)",
  "Hille (Germany)",
  "Hillersleben (Germany)",
  "Hillesheim (Germany)",
  "Hillscheid (Germany)",
  "Hilpoltstein (Germany)",
  "Hilscheid (Germany)",
  "Hilter (Germany)",
  "Hiltpoltstein (Germany)",
  "Hiltrup (Germany)",
  "Hilwartshausen (Germany)",
  "Hilzingen (Germany)",
  "Himbergen (Germany)",
  "Himmelkron (Germany)",
  "Himmelpforten (Germany)",
  "Himmelstadt (Germany)",
  "Hinrichshagen (Germany)",
  "Hinte (Germany)",
  "Hinterhermsdorf (Germany)",
  "Hinterm Horn (Germany)",
  "Hinterschmiding (Germany)",
  "Hinterzarten (Germany)",
  "Hirrlingen (Germany)",
  "Hirschaid (Germany)",
  "Hirschau (Germany)",
  "Hirschberg (Germany)",
  "Hirschfelde (Germany)",
  "Hirschhorn (Germany)",
  "Hirschstein (Germany)",
  "Hirstein (Germany)",
  "Hirzenhain (Germany)",
  "Hirzlei (Germany)",
  "Hittbergen (Germany)",
  "Hitzacker (Germany)",
  "Hitzhofen (Germany)",
  "Hobbersdorf (Germany)",
  "Hobeck (Germany)",
  "Hochberg (Germany)",
  "Hochborn (Germany)",
  "Hochdonn (Germany)",
  "Hochdorf (Germany)",
  "Hochdorf-Assenheim (Germany)",
  "Hochenschwand (Germany)",
  "Hochheim am Main (Germany)",
  "Hochkirch (Germany)",
  "Hochscheid (Germany)",
  "Hochspeyer (Germany)",
  "Hochst im Odenwald (Germany)",
  "Hochstadt (Germany)",
  "Hochstadt an der Aisch (Germany)",
  "Hochstadten (Germany)",
  "Hochstenbach (Germany)",
  "Hochstetten-Dhaun (Germany)",
  "Hochstrasse (Germany)",
  "Hochwang (Germany)",
  "Hockendorf (Germany)",
  "Hockenheim (Germany)",
  "Hodenhagen (Germany)",
  "Hof (Germany)",
  "Hofbieber (Germany)",
  "Hofen (Germany)",
  "Hofen an der Enz (Germany)",
  "Hofer (Germany)",
  "Hofgarten (Germany)",
  "Hofgeismar (Germany)",
  "Hofheim (Germany)",
  "Hofheim am Taunus (Germany)",
  "Hofkirchen (Germany)",
  "Hofstetten (Germany)",
  "Hohberg (Germany)",
  "Hohe (Germany)",
  "Hoheinod (Germany)",
  "Hohen (Germany)",
  "Hohen Neuendorf (Germany)",
  "Hohen Schwarfs (Germany)",
  "Hohen Wangelin (Germany)",
  "Hohenaspe (Germany)",
  "Hohenau (Germany)",
  "Hohenberg (Germany)",
  "Hohenbergham (Germany)",
  "Hohenbocka (Germany)",
  "Hohenbrunn (Germany)",
  "Hohendodeleben (Germany)",
  "Hoheneggelsen (Germany)",
  "Hohenerxleben (Germany)",
  "Hohenfelde (Germany)",
  "Hohenfels (Germany)",
  "Hohenfinow (Germany)",
  "Hohenfurch (Germany)",
  "Hohenhameln (Germany)",
  "Hohenhaus (Germany)",
  "Hohenhorn (Germany)",
  "Hohenkammer (Germany)",
  "Hohenkirchen-Siegertsbrunn (Germany)",
  "Hohenleimbach (Germany)",
  "Hohenleipisch (Germany)",
  "Hohenleuben (Germany)",
  "Hohenlimburg (Germany)",
  "Hohenlinden (Germany)",
  "Hohenlockstedt (Germany)",
  "Hohenmolsen (Germany)",
  "Hohenollen (Germany)",
  "Hohenpeissenberg (Germany)",
  "Hohenroda (Germany)",
  "Hohenroth (Germany)",
  "Hohenschaftlarn (Germany)",
  "Hohenstein (Germany)",
  "Hohenstein-Ernstthal (Germany)",
  "Hohentengen am Hochrhein (Germany)",
  "Hohenthann (Germany)",
  "Hohenwart (Germany)",
  "Hohenwarthe (Germany)",
  "Hohenwestedt (Germany)",
  "Hohn (Germany)",
  "Hohndorf (Germany)",
  "Hohne (Germany)",
  "Hohnhorst (Germany)",
  "Hohnstein (Germany)",
  "Hohnstorf (Germany)",
  "Hohr-Grenzhausen (Germany)",
  "Hoisdorf (Germany)",
  "Hoitlingen (Germany)",
  "Holdorf (Germany)",
  "Holle (Germany)",
  "Hollenbach (Germany)",
  "Hollenstedt (Germany)",
  "Holler (Germany)",
  "Hollern (Germany)",
  "Hollfeld (Germany)",
  "Hollingstedt (Germany)",
  "Hollstadt (Germany)",
  "Holm (Germany)",
  "Holte (Germany)",
  "Holthausen (Germany)",
  "Holtinghausen (Germany)",
  "Holtland (Germany)",
  "Holtsee (Germany)",
  "Holzbach (Germany)",
  "Holzengel (Germany)",
  "Holzgerlingen (Germany)",
  "Holzgunz (Germany)",
  "Holzhausen (Germany)",
  "Holzhausen an der Haide (Germany)",
  "Holzheim (Germany)",
  "Holzkirchen (Germany)",
  "Holzmaden (Germany)",
  "Holzminden (Germany)",
  "Holzweissig (Germany)",
  "Holzwickede (Germany)",
  "Homberg (Germany)",
  "Homburg (Germany)",
  "Honebach (Germany)",
  "Hoogstede (Germany)",
  "Hopferau (Germany)",
  "Hopfingen (Germany)",
  "Hoppegarten (Germany)",
  "Hoppstadten-Weiersbach (Germany)",
  "Hopsten (Germany)",
  "Horath (Germany)",
  "Horb am Neckar (Germany)",
  "Horbach (Germany)",
  "Horbruch (Germany)",
  "Hordt (Germany)",
  "Horgau (Germany)",
  "Horgertshausen (Germany)",
  "Horhausen (Germany)",
  "Hormersdorf (Germany)",
  "Horn-Bad Meinberg (Germany)",
  "Hornbach (Germany)",
  "Hornberg (Germany)",
  "Hornburg (Germany)",
  "Horneburg (Germany)",
  "Horperath (Germany)",
  "Horressen (Germany)",
  "Horrweiler (Germany)",
  "Horselgau (Germany)",
  "Horst (Germany)",
  "Horstdorf (Germany)",
  "Horstedt (Germany)",
  "Horstel (Germany)",
  "Horstmar (Germany)",
  "Hosbach (Germany)",
  "Hosenfeld (Germany)",
  "Hoslwang (Germany)",
  "Hosskirch (Germany)",
  "Host (Germany)",
  "Hotensleben (Germany)",
  "Hovelhof (Germany)",
  "Hoya (Germany)",
  "Hoyerswerda (Germany)",
  "Hoym (Germany)",
  "Huckelhoven (Germany)",
  "Huckeswagen (Germany)",
  "Hude (Germany)",
  "Huellhorst (Germany)",
  "Huffelsheim (Germany)",
  "Huffenhardt (Germany)",
  "Hufingen (Germany)",
  "Hugelsheim (Germany)",
  "Huglfing (Germany)",
  "Huhnerhof (Germany)",
  "Huje (Germany)",
  "Hulben (Germany)",
  "Humptrup (Germany)",
  "Hunderdorf (Germany)",
  "Hundhaupten (Germany)",
  "Hundisburg (Germany)",
  "Hundsangen (Germany)",
  "Hundsdorf (Germany)",
  "Hunfeld (Germany)",
  "Hungen (Germany)",
  "Hungenroth (Germany)",
  "Hunxe (Germany)",
  "Hunzel (Germany)",
  "Hurlach (Germany)",
  "Hurtgenwald (Germany)",
  "Husby (Germany)",
  "Husum (Germany)",
  "Hutschenhausen (Germany)",
  "Huttblek (Germany)",
  "Huttenberg (Germany)",
  "Hutthurm (Germany)",
  "Huttisheim (Germany)",
  "Huttlingen (Germany)",
  "Ibach (Germany)",
  "Ibbenbueren (Germany)",
  "Ichenhausen (Germany)",
  "Ichtershausen (Germany)",
  "Icker (Germany)",
  "Icking (Germany)",
  "Idar-Oberstein (Germany)",
  "Idenheim (Germany)",
  "Idstein (Germany)",
  "Iffeldorf (Germany)",
  "Iffezheim (Germany)",
  "Igel (Germany)",
  "Igensdorf (Germany)",
  "Igersheim (Germany)",
  "Iggensbach (Germany)",
  "Iggingen (Germany)",
  "Ihlow (Germany)",
  "Ihringen (Germany)",
  "Ihrlerstein (Germany)",
  "Ilbesheim (Germany)",
  "Ilfeld (Germany)",
  "Illerrieden (Germany)",
  "Illertissen (Germany)",
  "Illingen (Germany)",
  "Illschwang (Germany)",
  "Ilmenau (Germany)",
  "Ilmmunster (Germany)",
  "Ilsede (Germany)",
  "Ilsenburg (Germany)",
  "Ilsfeld (Germany)",
  "Ilshofen (Germany)",
  "Ilvesheim (Germany)",
  "Im Loh (Germany)",
  "Immendingen (Germany)",
  "Immenhausen (Germany)",
  "Immenreuth (Germany)",
  "Immenstaad am Bodensee (Germany)",
  "Immenstadt im Allgau (Germany)",
  "Imsbach (Germany)",
  "In der Meer (Germany)",
  "Inchenhofen (Germany)",
  "Inden (Germany)",
  "Ingelfingen (Germany)",
  "Ingelheim am Rhein (Germany)",
  "Ingersleben (Germany)",
  "Ingoldingen (Germany)",
  "Ingolstadt (Germany)",
  "Innernzell (Germany)",
  "Inning am Ammersee (Germany)",
  "Inning am Holz (Germany)",
  "Insel (Germany)",
  "Insheim (Germany)",
  "Insingen (Germany)",
  "Insul (Germany)",
  "Inzell (Germany)",
  "Inzigkofen (Germany)",
  "Inzlingen (Germany)",
  "Inzmuhlen (Germany)",
  "Iphofen (Germany)",
  "Ippesheim (Germany)",
  "Ipsheim (Germany)",
  "Irlbach (Germany)",
  "Irmenach (Germany)",
  "Irrel (Germany)",
  "Irschenberg (Germany)",
  "Irsee (Germany)",
  "Irxleben (Germany)",
  "Isen (Germany)",
  "Isenburg (Germany)",
  "Isenbuttel (Germany)",
  "Iserlohn (Germany)",
  "Ismaning (Germany)",
  "Isny im Allgau (Germany)",
  "Ispringen (Germany)",
  "Isselburg (Germany)",
  "Isseroda (Germany)",
  "Isserstedt (Germany)",
  "Issigau (Germany)",
  "Issum (Germany)",
  "Istein (Germany)",
  "Ittersbach (Germany)",
  "Ittlingen (Germany)",
  "Itzehoe (Germany)",
  "Itzstedt (Germany)",
  "Ivenack (Germany)",
  "Jacobsdorf (Germany)",
  "Jaderberg (Germany)",
  "Jagel (Germany)",
  "Jagerhof (Germany)",
  "Jagsthausen (Germany)",
  "Jagstzell (Germany)",
  "Jahnsdorf (Germany)",
  "Jahnshof (Germany)",
  "Jameln (Germany)",
  "Jarmen (Germany)",
  "Jebenhausen (Germany)",
  "Jeddeloh Eins (Germany)",
  "Jelmstorf (Germany)",
  "Jembke (Germany)",
  "Jemgum (Germany)",
  "Jena (Germany)",
  "Jengen (Germany)",
  "Jerichow (Germany)",
  "Jersbek (Germany)",
  "Jersleben (Germany)",
  "Jesberg (Germany)",
  "Jesendorf (Germany)",
  "Jessen (Germany)",
  "Jessnitz (Germany)",
  "Jestadt (Germany)",
  "Jesteburg (Germany)",
  "Jestetten (Germany)",
  "Jettenbach (Germany)",
  "Jettingen-Scheppach (Germany)",
  "Jetzendorf (Germany)",
  "Jevenstedt (Germany)",
  "Jever (Germany)",
  "Jheringsfehn (Germany)",
  "Joachimsthal (Germany)",
  "Jockgrim (Germany)",
  "Joehlingen (Germany)",
  "Johannesberg (Germany)",
  "Johanngeorgenstadt (Germany)",
  "Johstadt (Germany)",
  "Jork (Germany)",
  "Jorl (Germany)",
  "Jubek (Germany)",
  "Juchen (Germany)",
  "Jugenheim (Germany)",
  "Juist (Germany)",
  "Julbach (Germany)",
  "Julich (Germany)",
  "Juliusburg (Germany)",
  "Jungingen (Germany)",
  "Junkerath (Germany)",
  "Juterbog (Germany)",
  "Kaaks (Germany)",
  "Kaarst (Germany)",
  "Kadelburg (Germany)",
  "Kaden (Germany)",
  "Kagsdorf (Germany)",
  "Kahl am Main (Germany)",
  "Kahla (Germany)",
  "Kaisersbach (Germany)",
  "Kaisersesch (Germany)",
  "Kaiserslautern (Germany)",
  "Kaisheim (Germany)",
  "Kakenstorf (Germany)",
  "Kalbach (Germany)",
  "Kaldenkirchen (Germany)",
  "Kalefeld (Germany)",
  "Kalenborn-Scheuern (Germany)",
  "Kalkar (Germany)",
  "Kalkreuth (Germany)",
  "Kall (Germany)",
  "Kallmunz (Germany)",
  "Kallstadt (Germany)",
  "Kaltenengers (Germany)",
  "Kaltenkirchen (Germany)",
  "Kaltennordheim (Germany)",
  "Kaltenwestheim (Germany)",
  "Kamen (Germany)",
  "Kamenz (Germany)",
  "Kammerforst (Germany)",
  "Kammerstein (Germany)",
  "Kamp-Bornhofen (Germany)",
  "Kamp-Lintfort (Germany)",
  "Kampen (Germany)",
  "Kamsdorf (Germany)",
  "Kandel (Germany)",
  "Kandern (Germany)",
  "Kanzlei (Germany)",
  "Kapellen-Drusweiler (Germany)",
  "Kapellenberg (Germany)",
  "Kappel-Grafenhausen (Germany)",
  "Kappeln (Germany)",
  "Kappelrodeck (Germany)",
  "Kapsweyer (Germany)",
  "Karbach (Germany)",
  "Karben (Germany)",
  "Karin (Germany)",
  "Karl (Germany)",
  "Karlsbad (Germany)",
  "Karlsdorf-Neuthard (Germany)",
  "Karlsfeld (Germany)",
  "Karlshagen (Germany)",
  "Karlshausen (Germany)",
  "Karlshuld (Germany)",
  "Karlskron (Germany)",
  "Karlsruhe (Germany)",
  "Karlstadt am Main (Germany)",
  "Karlstein (Germany)",
  "Karlum (Germany)",
  "Karsbach (Germany)",
  "Karsdorf (Germany)",
  "Karstadt (Germany)",
  "Karweiler (Germany)",
  "Kasbach-Ohlenberg (Germany)",
  "Kasdorf (Germany)",
  "Kasel-Golzig (Germany)",
  "Kasendorf (Germany)",
  "Kasseedorf (Germany)",
  "Kassel (Germany)",
  "Kassow (Germany)",
  "Kastel (Germany)",
  "Kastellaun (Germany)",
  "Kastl (Germany)",
  "Kastorf (Germany)",
  "Katlenburg-Lindau (Germany)",
  "Katzenelnbogen (Germany)",
  "Katzenmoos (Germany)",
  "Katzenstein (Germany)",
  "Katzow (Germany)",
  "Katzwang (Germany)",
  "Katzwinkel (Germany)",
  "Kaub (Germany)",
  "Kaufbeuren (Germany)",
  "Kaufering (Germany)",
  "Kaufungen (Germany)",
  "Kaulsdorf (Germany)",
  "Kayhude (Germany)",
  "Kayna (Germany)",
  "Kefenrod (Germany)",
  "Kefferhausen (Germany)",
  "Kehl (Germany)",
  "Keidelheim (Germany)",
  "Keitum (Germany)",
  "Kelberg (Germany)",
  "Kelbra (Germany)",
  "Kelheim (Germany)",
  "Kelkheim (Taunus) (Germany)",
  "Kell (Germany)",
  "Kellenhusen (Germany)",
  "Keller (Germany)",
  "Kellinghusen (Germany)",
  "Kelsterbach (Germany)",
  "Kemberg (Germany)",
  "Kemerting (Germany)",
  "Kemmern (Germany)",
  "Kemnath (Germany)",
  "Kempen (Germany)",
  "Kempenich (Germany)",
  "Kempfeld (Germany)",
  "Kempten (Allgaeu) (Germany)",
  "Kendenich (Germany)",
  "Kenn (Germany)",
  "Kennenburg (Germany)",
  "Kenzingen (Germany)",
  "Kerlingen (Germany)",
  "Kerpen (Germany)",
  "Kervenheim (Germany)",
  "Kerzenheim (Germany)",
  "Kesselbach (Germany)",
  "Kesselsdorf (Germany)",
  "Kestert (Germany)",
  "Ketsch (Germany)",
  "Kettenkamp (Germany)",
  "Kettershausen (Germany)",
  "Kettig (Germany)",
  "Ketzin (Germany)",
  "Kevelaer (Germany)",
  "Kiedrich (Germany)",
  "Kiefersfelden (Germany)",
  "Kiel (Germany)",
  "Kienberg (Germany)",
  "Kierspe (Germany)",
  "Kieselbach (Germany)",
  "Kieselbronn (Germany)",
  "Kiez (Germany)",
  "Kindelbruck (Germany)",
  "Kinding (Germany)",
  "Kindsbach (Germany)",
  "Kinheim (Germany)",
  "Kinsau (Germany)",
  "Kipfenberg (Germany)",
  "Kippenheim (Germany)",
  "Kirberg (Germany)",
  "Kirburg (Germany)",
  "Kirchardt (Germany)",
  "Kirchberg (Germany)",
  "Kirchdorf (Germany)",
  "Kirchdorf am Inn (Germany)",
  "Kirchdorf im Wald (Germany)",
  "Kirchehrenbach (Germany)",
  "Kirchen (Germany)",
  "Kirchendemenreuth (Germany)",
  "Kirchenlamitz (Germany)",
  "Kirchentellinsfurt (Germany)",
  "Kirchenthumbach (Germany)",
  "Kirchgellersen (Germany)",
  "Kirchhain (Germany)",
  "Kirchham (Germany)",
  "Kirchhasel (Germany)",
  "Kirchhaslach (Germany)",
  "Kirchheilingen (Germany)",
  "Kirchheim (Germany)",
  "Kirchheim am Ries (Germany)",
  "Kirchheim an der Weinstrasse (Germany)",
  "Kirchheim unter Teck (Germany)",
  "Kirchheimbolanden (Germany)",
  "Kirchhellen (Germany)",
  "Kirchhundem (Germany)",
  "Kirchlauter (Germany)",
  "Kirchlengern (Germany)",
  "Kirchlinteln (Germany)",
  "Kirchreit (Germany)",
  "Kirchroth (Germany)",
  "Kirchsahr (Germany)",
  "Kirchseelte (Germany)",
  "Kirchseeon (Germany)",
  "Kirchtimke (Germany)",
  "Kirchweg (Germany)",
  "Kirchweidach (Germany)",
  "Kirchweyhe (Germany)",
  "Kirchworbis (Germany)",
  "Kirchzarten (Germany)",
  "Kirchzell (Germany)",
  "Kirkel (Germany)",
  "Kirn (Germany)",
  "Kirrweiler (Germany)",
  "Kirschau (Germany)",
  "Kirschfurt (Germany)",
  "Kirschhofen (Germany)",
  "Kirspenich (Germany)",
  "Kirtorf (Germany)",
  "Kisdorf (Germany)",
  "Kisselbach (Germany)",
  "Kissenbruck (Germany)",
  "Kissing (Germany)",
  "Kisslegg (Germany)",
  "Kist (Germany)",
  "Kitzingen (Germany)",
  "Kitzscher (Germany)",
  "Kladen (Germany)",
  "Klais (Germany)",
  "Klanxbull (Germany)",
  "Klaus (Germany)",
  "Klausdorf (Germany)",
  "Klausen (Germany)",
  "Klausheide (Germany)",
  "Klein Ammensleben (Germany)",
  "Klein Berssen (Germany)",
  "Klein Kedingshagen (Germany)",
  "Klein Lichtenhagen (Germany)",
  "Klein Meckelsen (Germany)",
  "Klein Nordende (Germany)",
  "Klein Offenseth-Sparrieshoop (Germany)",
  "Klein Rogahn (Germany)",
  "Klein Ronnau (Germany)",
  "Klein Wesenberg (Germany)",
  "Klein Wittensee (Germany)",
  "Klein-Gerau (Germany)",
  "Klein-Schlamin (Germany)",
  "Klein-Winternheim (Germany)",
  "Klein-Zimmern (Germany)",
  "Kleinbeeren (Germany)",
  "Kleinblittersdorf (Germany)",
  "Kleinbundenbach (Germany)",
  "Kleinfahner (Germany)",
  "Kleinfurra (Germany)",
  "Kleinheubach (Germany)",
  "Kleinkahl (Germany)",
  "Kleinmachnow (Germany)",
  "Kleinmaischeid (Germany)",
  "Kleinostheim (Germany)",
  "Kleinrinderfeld (Germany)",
  "Kleinschwabhausen (Germany)",
  "Kleinsendelbach (Germany)",
  "Kleinsteinhausen (Germany)",
  "Kleinwallstadt (Germany)",
  "Kleinwolmsdorf (Germany)",
  "Klettwitz (Germany)",
  "Kleve (Germany)",
  "Klinge (Germany)",
  "Klingenberg (Germany)",
  "Klingenberg am Main (Germany)",
  "Klingenmunster (Germany)",
  "Klingenthal (Germany)",
  "Klink (Germany)",
  "Klipphausen (Germany)",
  "Kloster (Germany)",
  "Klosterhauser (Germany)",
  "Klosterlechfeld (Germany)",
  "Klostermansfeld (Germany)",
  "Klostersee (Germany)",
  "Klotten (Germany)",
  "Klotze (Germany)",
  "Kluis (Germany)",
  "Klutz (Germany)",
  "Knesebeck (Germany)",
  "Knetzgau (Germany)",
  "Knittelsheim (Germany)",
  "Knittlingen (Germany)",
  "Knobelsdorf (Germany)",
  "Knoringen (Germany)",
  "Kobern-Gondorf (Germany)",
  "Koblenz (Germany)",
  "Kochel (Germany)",
  "Kodersdorf (Germany)",
  "Koditz (Germany)",
  "Kodnitz (Germany)",
  "Kofering (Germany)",
  "Kohlberg (Germany)",
  "Kohra (Germany)",
  "Kohren-Sahlis (Germany)",
  "Kolbermoor (Germany)",
  "Kolbingen (Germany)",
  "Kolitzheim (Germany)",
  "Kolkwitz (Germany)",
  "Kolleda (Germany)",
  "Kollerbach (Germany)",
  "Kollmar (Germany)",
  "Kolln-Reisiek (Germany)",
  "Kollnburg (Germany)",
  "Kolpien (Germany)",
  "Komp (Germany)",
  "Kongen (Germany)",
  "Konigheim (Germany)",
  "Konigs Wusterhausen (Germany)",
  "Konigsbach-Stein (Germany)",
  "Konigsberg in Bayern (Germany)",
  "Konigsbronn (Germany)",
  "Konigsbruck (Germany)",
  "Konigsbrunn (Germany)",
  "Konigsdorf (Germany)",
  "Konigsee (Germany)",
  "Konigseggwald (Germany)",
  "Konigsfeld (Germany)",
  "Konigsfeld im Schwarzwald (Germany)",
  "Konigsheim (Germany)",
  "Konigslutter am Elm (Germany)",
  "Konigstein (Germany)",
  "Konigstein im Taunus (Germany)",
  "Konigswartha (Germany)",
  "Konigswinter (Germany)",
  "Konken (Germany)",
  "Konnern (Germany)",
  "Konradsreuth (Germany)",
  "Konstanz (Germany)",
  "Konz (Germany)",
  "Konzell (Germany)",
  "Korb (Germany)",
  "Korbach (Germany)",
  "Korchow (Germany)",
  "Kordel (Germany)",
  "Korle (Germany)",
  "Korntal (Germany)",
  "Kornwestheim (Germany)",
  "Korperich (Germany)",
  "Korschenbroich (Germany)",
  "Kosching (Germany)",
  "Kossa (Germany)",
  "Kothel (Germany)",
  "Kottenheim (Germany)",
  "Kotterichen (Germany)",
  "Kottgeisering (Germany)",
  "Kottweiler-Schwanden (Germany)",
  "Kotzting (Germany)",
  "Koxhausen (Germany)",
  "Kraft (Germany)",
  "Kraftisried (Germany)",
  "Kraiburg am Inn (Germany)",
  "Krailling (Germany)",
  "Krakow am See (Germany)",
  "Kramerhof (Germany)",
  "Kranenburg (Germany)",
  "Kranichfeld (Germany)",
  "Kranzberg (Germany)",
  "Krauchenwies (Germany)",
  "Kraupa (Germany)",
  "Krauschwitz (Germany)",
  "Krautheim (Germany)",
  "Krefeld (Germany)",
  "Kreiensen (Germany)",
  "Kreischa (Germany)",
  "Kremmen (Germany)",
  "Krempe (Germany)",
  "Kremperheide (Germany)",
  "Kressbronn am Bodensee (Germany)",
  "Krettnach (Germany)",
  "Kretzschau (Germany)",
  "Kreuz (Germany)",
  "Kreuzau (Germany)",
  "Kreuztal (Germany)",
  "Kreuzwertheim (Germany)",
  "Krickenbach (Germany)",
  "Kriebitzsch (Germany)",
  "Kriebstein (Germany)",
  "Kriftel (Germany)",
  "Kritzmow (Germany)",
  "Kritzow (Germany)",
  "Krolpa (Germany)",
  "Krombach (Germany)",
  "Kronach (Germany)",
  "Kronau (Germany)",
  "Kronberg (Germany)",
  "Kronshagen (Germany)",
  "Kropelin (Germany)",
  "Kropp (Germany)",
  "Kroppelshagen-Fahrendorf (Germany)",
  "Kroppenstedt (Germany)",
  "Kroslin (Germany)",
  "Krostitz (Germany)",
  "Krov (Germany)",
  "Kruden (Germany)",
  "Kruft (Germany)",
  "Krugsdorf (Germany)",
  "Krugzell (Germany)",
  "Krumbach (Germany)",
  "Krumhermersdorf (Germany)",
  "Krummensee (Germany)",
  "Krummesse (Germany)",
  "Krumpa (Germany)",
  "Krumstedt (Germany)",
  "Krun (Germany)",
  "Kubschutz (Germany)",
  "Kuchen (Germany)",
  "Kuddeworde (Germany)",
  "Kuenzelsau (Germany)",
  "Kueps Oberfranken (Germany)",
  "Kuhardt (Germany)",
  "Kuhbach (Germany)",
  "Kuhn (Germany)",
  "Kuhndorf (Germany)",
  "Kuhstedt (Germany)",
  "Kukels (Germany)",
  "Kulmbach (Germany)",
  "Kulsheim (Germany)",
  "Kulz (Germany)",
  "Kumhausen (Germany)",
  "Kummerfeld (Germany)",
  "Kummersbruck (Germany)",
  "Kunreuth (Germany)",
  "Kunzell (Germany)",
  "Kunzing (Germany)",
  "Kupferberg (Germany)",
  "Kupferzell (Germany)",
  "Kuppenheim (Germany)",
  "Kurnach (Germany)",
  "Kurnbach (Germany)",
  "Kurort Hartha (Germany)",
  "Kurort Steinbach-Hallenberg (Germany)",
  "Kurten (Germany)",
  "Kurtscheid (Germany)",
  "Kusel (Germany)",
  "Kusterdingen (Germany)",
  "Kutenholz (Germany)",
  "Kutzenhausen (Germany)",
  "Kyritz (Germany)",
  "Laaber (Germany)",
  "Laage (Germany)",
  "Laasdorf (Germany)",
  "Laatzen (Germany)",
  "Labejum (Germany)",
  "Labenz (Germany)",
  "Laboe (Germany)",
  "Lachen (Germany)",
  "Lachendorf (Germany)",
  "Ladbergen (Germany)",
  "Ladenburg (Germany)",
  "Laer (Germany)",
  "Lage (Germany)",
  "Lagerdorf (Germany)",
  "Lagesbuttel (Germany)",
  "Lahden (Germany)",
  "Lahm (Germany)",
  "Lahnstein (Germany)",
  "Lahr (Germany)",
  "Lahrbach (Germany)",
  "Laichingen (Germany)",
  "Lain (Germany)",
  "Lalendorf (Germany)",
  "Lam (Germany)",
  "Lambertsberg (Germany)",
  "Lambrecht (Germany)",
  "Lambsheim (Germany)",
  "Lamerdingen (Germany)",
  "Lampertheim (Germany)",
  "Lampertswalde (Germany)",
  "Lamspringe (Germany)",
  "Lamstedt (Germany)",
  "Landau (Germany)",
  "Landau an der Isar (Germany)",
  "Landensberg (Germany)",
  "Landesbergen (Germany)",
  "Landrecht (Germany)",
  "Landsberg (Germany)",
  "Landsberg am Lech (Germany)",
  "Landscheid (Germany)",
  "Landscheide (Germany)",
  "Landshut (Germany)",
  "Landstuhl (Germany)",
  "Langballig (Germany)",
  "Langebruck (Germany)",
  "Langeln (Germany)",
  "Langelsheim (Germany)",
  "Langen (Germany)",
  "Langenargen (Germany)",
  "Langenau (Germany)",
  "Langenbach (Germany)",
  "Langenberg (Germany)",
  "Langenbernsdorf (Germany)",
  "Langendorf (Germany)",
  "Langenenslingen (Germany)",
  "Langenfeld (Germany)",
  "Langenhagen (Germany)",
  "Langenhorn (Germany)",
  "Langenlonsheim (Germany)",
  "Langenmosen (Germany)",
  "Langenneufnach (Germany)",
  "Langenpreising (Germany)",
  "Langenselbold (Germany)",
  "Langensendelbach (Germany)",
  "Langenweddingen (Germany)",
  "Langenwetzendorf (Germany)",
  "Langenwolmsdorf (Germany)",
  "Langenzenn (Germany)",
  "Langeoog (Germany)",
  "Langerringen (Germany)",
  "Langerwehe (Germany)",
  "Langewiesen (Germany)",
  "Langfurth (Germany)",
  "Langgons (Germany)",
  "Langlingen (Germany)",
  "Langquaid (Germany)",
  "Langstedt (Germany)",
  "Langsur (Germany)",
  "Langwasser (Germany)",
  "Langwedel (Germany)",
  "Langweid (Germany)",
  "Langweiler (Germany)",
  "Langwieden (Germany)",
  "Langwitz (Germany)",
  "Lappersdorf (Germany)",
  "Lasbek (Germany)",
  "Lassan (Germany)",
  "Lassentin (Germany)",
  "Lastrup (Germany)",
  "Lathen (Germany)",
  "Laubach (Germany)",
  "Lauben (Germany)",
  "Laubenheim (Germany)",
  "Laubusch (Germany)",
  "Laucha (Germany)",
  "Lauchhammer (Germany)",
  "Lauchheim (Germany)",
  "Lauchringen (Germany)",
  "Lauda-Konigshofen (Germany)",
  "Laudenbach (Germany)",
  "Laudert (Germany)",
  "Lauenau (Germany)",
  "Lauenbruck (Germany)",
  "Lauenburg (Germany)",
  "Lauenhagen (Germany)",
  "Lauf (Germany)",
  "Lauf an der Pegnitz (Germany)",
  "Laufach (Germany)",
  "Laufen (Germany)",
  "Laufenburg (Germany)",
  "Lauffen am Neckar (Germany)",
  "Laugna (Germany)",
  "Lauingen (Germany)",
  "Laumersheim (Germany)",
  "Laupheim (Germany)",
  "Laurenburg (Germany)",
  "Lauscha (Germany)",
  "Lauschied (Germany)",
  "Lausitz (Germany)",
  "Laussnitz (Germany)",
  "Lauta (Germany)",
  "Lautenbach (Germany)",
  "Lautenthal (Germany)",
  "Lauter (Germany)",
  "Lauterach (Germany)",
  "Lauterbach (Germany)",
  "Lauterbach/Schwarzwald (Germany)",
  "Lauterburg (Germany)",
  "Lauterecken (Germany)",
  "Lauterhofen (Germany)",
  "Lautern (Germany)",
  "Lautersheim (Germany)",
  "Lautertal (Germany)",
  "Lautrach (Germany)",
  "Lautzenhausen (Germany)",
  "Lawalde (Germany)",
  "Lebach (Germany)",
  "Lebrade (Germany)",
  "Lebus (Germany)",
  "Lebusa (Germany)",
  "Lechbruck (Germany)",
  "Leck (Germany)",
  "Lederhose (Germany)",
  "Leegebruch (Germany)",
  "Leeheim (Germany)",
  "Leer (Germany)",
  "Leezdorf (Germany)",
  "Leezen (Germany)",
  "Legau (Germany)",
  "Legden (Germany)",
  "Lehen (Germany)",
  "Lehesten (Germany)",
  "Lehmen (Germany)",
  "Lehmke (Germany)",
  "Lehndorf (Germany)",
  "Lehrberg (Germany)",
  "Lehre (Germany)",
  "Lehrensteinsfeld (Germany)",
  "Lehrte (Germany)",
  "Leibelbach (Germany)",
  "Leibertingen (Germany)",
  "Leiblfing (Germany)",
  "Leichlingen (Germany)",
  "Leidersbach (Germany)",
  "Leiferde (Germany)",
  "Leimbach (Germany)",
  "Leimen (Germany)",
  "Leimersheim (Germany)",
  "Leinach (Germany)",
  "Leinburg (Germany)",
  "Leinefelde (Germany)",
  "Leinfelden-Echterdingen (Germany)",
  "Leingarten (Germany)",
  "Leinsweiler (Germany)",
  "Leipheim (Germany)",
  "Leipzig (Germany)",
  "Leisnig (Germany)",
  "Leitershofen (Germany)",
  "Leitzkau (Germany)",
  "Leiwen (Germany)",
  "Leizen (Germany)",
  "Lemberg (Germany)",
  "Lembruch (Germany)",
  "Lemforde (Germany)",
  "Lemgo (Germany)",
  "Lemwerder (Germany)",
  "Lengdorf (Germany)",
  "Lengede (Germany)",
  "Lengenfeld (Germany)",
  "Lengerich (Germany)",
  "Lenggries (Germany)",
  "Lengthal (Germany)",
  "Lennestadt (Germany)",
  "Lensahn (Germany)",
  "Lentfohrden (Germany)",
  "Lenting (Germany)",
  "Lenzkirch (Germany)",
  "Leonberg (Germany)",
  "Leopoldshagen (Germany)",
  "Leopoldshohe (Germany)",
  "Lerbach (Germany)",
  "Lessien (Germany)",
  "Lettenreuth (Germany)",
  "Leubsdorf (Germany)",
  "Leukersdorf (Germany)",
  "Leun (Germany)",
  "Leuna (Germany)",
  "Leutenbach (Germany)",
  "Leutenberg (Germany)",
  "Leutershausen (Germany)",
  "Leuthen (Germany)",
  "Leutkirch im Allgau (Germany)",
  "Leverkusen (Germany)",
  "Lich (Germany)",
  "Lichte (Germany)",
  "Lichtenau (Germany)",
  "Lichtenberg (Germany)",
  "Lichtenbroich (Germany)",
  "Lichtenfels (Germany)",
  "Lichtenstein (Germany)",
  "Lichtentanne (Germany)",
  "Lichtenwald (Germany)",
  "Liebenau (Germany)",
  "Liebenburg (Germany)",
  "Liebenscheid (Germany)",
  "Liebenstein (Germany)",
  "Liebenwalde (Germany)",
  "Lieberose (Germany)",
  "Liederbach (Germany)",
  "Lienen (Germany)",
  "Liepgarten (Germany)",
  "Lieser (Germany)",
  "Lieskau (Germany)",
  "Lietzen (Germany)",
  "Lilienthal (Germany)",
  "Limbach (Germany)",
  "Limbach-Oberfrohna (Germany)",
  "Limburg an der Lahn (Germany)",
  "Limburgerhof (Germany)",
  "Lindau (Germany)",
  "Linde (Germany)",
  "Linden (Germany)",
  "Lindenau (Germany)",
  "Lindenberg im Allgau (Germany)",
  "Lindenfels (Germany)",
  "Lindenthal (Germany)",
  "Lindern (Germany)",
  "Lindewitt (Germany)",
  "Lindhorst (Germany)",
  "Lindigshof (Germany)",
  "Lindlar (Germany)",
  "Lindwedel (Germany)",
  "Lingen (Germany)",
  "Lingenfeld (Germany)",
  "Lingerhahn (Germany)",
  "Linkenbach (Germany)",
  "Linkenheim-Hochstetten (Germany)",
  "Linnich (Germany)",
  "Linow (Germany)",
  "Linsburg (Germany)",
  "Lintig (Germany)",
  "Linz am Rhein (Germany)",
  "Lippe (Germany)",
  "Lipporn (Germany)",
  "Lippstadt (Germany)",
  "Lissendorf (Germany)",
  "Listerfehrda (Germany)",
  "Litzendorf (Germany)",
  "Lobau (Germany)",
  "Loch (Germany)",
  "Lochau (Germany)",
  "Lochgau (Germany)",
  "Lochhausen (Germany)",
  "Lochum (Germany)",
  "Loddin (Germany)",
  "Loderburg (Germany)",
  "Loffenau (Germany)",
  "Loffingen (Germany)",
  "Lohberg (Germany)",
  "Lohe-Rickelshof (Germany)",
  "Loheide (Germany)",
  "Lohfelden (Germany)",
  "Lohmar (Germany)",
  "Lohnberg (Germany)",
  "Lohne (Germany)",
  "Lohnsfeld (Germany)",
  "Lohr a. Main (Germany)",
  "Lohra (Germany)",
  "Lohsa (Germany)",
  "Loiching (Germany)",
  "Loitz (Germany)",
  "Lollar (Germany)",
  "Lollbach (Germany)",
  "Lommatzsch (Germany)",
  "Longkamp (Germany)",
  "Longuich (Germany)",
  "Loningen (Germany)",
  "Lonnerstadt (Germany)",
  "Lonnig (Germany)",
  "Lonsee (Germany)",
  "Lonsingen (Germany)",
  "Loose (Germany)",
  "Lorch (Germany)",
  "Lorsch (Germany)",
  "Lorscheid (Germany)",
  "Lorup (Germany)",
  "Losheim (Germany)",
  "Lossburg (Germany)",
  "Lossnitz (Germany)",
  "Lostau (Germany)",
  "Lotte (Germany)",
  "Lottstetten (Germany)",
  "Lotzbeuren (Germany)",
  "Lowenstein (Germany)",
  "Loxstedt (Germany)",
  "Lubbecke (Germany)",
  "Lubbenau (Germany)",
  "Lubesse (Germany)",
  "Lubmin (Germany)",
  "Lubstorf (Germany)",
  "Lubtheen (Germany)",
  "Lubz (Germany)",
  "Luchem (Germany)",
  "Lucka (Germany)",
  "Luckau (Germany)",
  "Luckenbach (Germany)",
  "Luckenwalde (Germany)",
  "Ludersdorf (Germany)",
  "Ludersfeld (Germany)",
  "Ludinghausen (Germany)",
  "Ludwigsburg (Germany)",
  "Ludwigsfelde (Germany)",
  "Ludwigshafen (Germany)",
  "Ludwigshafen am Rhein (Germany)",
  "Ludwigshohe (Germany)",
  "Ludwigslust (Germany)",
  "Ludwigsstadt (Germany)",
  "Luebben (Germany)",
  "Luechow (Germany)",
  "Luerdissen (Germany)",
  "Luft (Germany)",
  "Luftkurort Arendsee (Germany)",
  "Lugau (Germany)",
  "Lugde (Germany)",
  "Luhden (Germany)",
  "Luhe-Wildenau (Germany)",
  "Lunne (Germany)",
  "Lunzenau (Germany)",
  "Lupburg (Germany)",
  "Lurschau (Germany)",
  "Lutau (Germany)",
  "Lutjenburg (Germany)",
  "Lutjensee (Germany)",
  "Lutter am Barenberge (Germany)",
  "Luttgenrode (Germany)",
  "Luttum (Germany)",
  "Lutz (Germany)",
  "Lutzelbach (Germany)",
  "Lutzen (Germany)",
  "Lutzerath (Germany)",
  "Lutzingen (Germany)",
  "Lychen (Germany)",
  "Lynow (Germany)",
  "Maasholm (Germany)",
  "Machern (Germany)",
  "Machtolsheim (Germany)",
  "Macken (Germany)",
  "Mackenbach (Germany)",
  "Magdala (Germany)",
  "Magdeburg (Germany)",
  "Magstadt (Germany)",
  "Mahlberg (Germany)",
  "Mahlow (Germany)",
  "Mahndorf (Germany)",
  "Maierhofen (Germany)",
  "Maikammer (Germany)",
  "Mailing (Germany)",
  "Mainaschaff (Germany)",
  "Mainbernheim (Germany)",
  "Mainburg (Germany)",
  "Mainhardt (Germany)",
  "Mainleus (Germany)",
  "Mainstockheim (Germany)",
  "Maintal (Germany)",
  "Mainz (Germany)",
  "Maisach (Germany)",
  "Maitenbeth (Germany)",
  "Malberg (Germany)",
  "Malborn (Germany)",
  "Malchin (Germany)",
  "Malchow (Germany)",
  "Malente (Germany)",
  "Malgersdorf (Germany)",
  "Mallersdorf-Pfaffenberg (Germany)",
  "Mallin (Germany)",
  "Malsburg (Germany)",
  "Malsch (Germany)",
  "Malschwitz (Germany)",
  "Malsfeld (Germany)",
  "Malterdingen (Germany)",
  "Mammendorf (Germany)",
  "Mamming (Germany)",
  "Manching (Germany)",
  "Mandel (Germany)",
  "Mandern (Germany)",
  "Mannebach (Germany)",
  "Mannheim (Germany)",
  "Mannichswalde (Germany)",
  "Mansfeld (Germany)",
  "Mantel (Germany)",
  "Marbach am Neckar (Germany)",
  "Marburg (Germany)",
  "March (Germany)",
  "Margarethen (Germany)",
  "Margertshausen (Germany)",
  "Margetshochheim (Germany)",
  "Mariaposching (Germany)",
  "Marienberg (Germany)",
  "Marienburg (Germany)",
  "Marienfeld (Germany)",
  "Marienhafe (Germany)",
  "Marienhagen (Germany)",
  "Marienhausen (Germany)",
  "Marienheide (Germany)",
  "Marienrachdorf (Germany)",
  "Marienstein (Germany)",
  "Mariental (Germany)",
  "Maring-Noviand (Germany)",
  "Mark (Germany)",
  "Markdorf (Germany)",
  "Markersbach (Germany)",
  "Markgroningen (Germany)",
  "Markkleeberg (Germany)",
  "Marklkofen (Germany)",
  "Marklohe (Germany)",
  "Markneukirchen (Germany)",
  "Markranstadt (Germany)",
  "Marksuhl (Germany)",
  "Markt (Germany)",
  "Markt Einersheim (Germany)",
  "Markt Erlbach (Germany)",
  "Markt Indersdorf (Germany)",
  "Markt Rettenbach (Germany)",
  "Markt Schwaben (Germany)",
  "Markt Wald (Germany)",
  "Marktbreit (Germany)",
  "Marktgraitz (Germany)",
  "Marktheidenfeld (Germany)",
  "Marktl (Germany)",
  "Marktleugast (Germany)",
  "Marktleuthen (Germany)",
  "Marktoberdorf (Germany)",
  "Marktredwitz (Germany)",
  "Marktrodach (Germany)",
  "Marktschellenberg (Germany)",
  "Marktschorgast (Germany)",
  "Marl (Germany)",
  "Marlow (Germany)",
  "Marne (Germany)",
  "Marnheim (Germany)",
  "Marpingen (Germany)",
  "Marquartstein (Germany)",
  "Marsberg (Germany)",
  "Marschacht (Germany)",
  "Marschall (Germany)",
  "Marsdorf (Germany)",
  "Martensrade (Germany)",
  "Martfeld (Germany)",
  "Martinsried (Germany)",
  "Marwitz (Germany)",
  "Marxen (Germany)",
  "Marxgrun (Germany)",
  "Marxheim (Germany)",
  "Marzling (Germany)",
  "Maschen (Germany)",
  "Maselheim (Germany)",
  "Massbach (Germany)",
  "Massenbachhausen (Germany)",
  "Massenhausen (Germany)",
  "Massenheim (Germany)",
  "Massing (Germany)",
  "Mastershausen (Germany)",
  "Mastholte (Germany)",
  "Mattstedt (Germany)",
  "Mauer (Germany)",
  "Mauerstetten (Germany)",
  "Maulbronn (Germany)",
  "Maulburg (Germany)",
  "Mauth (Germany)",
  "Maxdorf (Germany)",
  "Maxhutte-Haidhof (Germany)",
  "Maximilian (Germany)",
  "Mayen (Germany)",
  "Mechelgrun (Germany)",
  "Mechelroda (Germany)",
  "Mechenried (Germany)",
  "Mechernich (Germany)",
  "Meckenbach (Germany)",
  "Meckenbeuren (Germany)",
  "Meckenheim (Germany)",
  "Meckesheim (Germany)",
  "Mecklenburg (Germany)",
  "Medard (Germany)",
  "Medebach (Germany)",
  "Medelby (Germany)",
  "Medow (Germany)",
  "Meeder (Germany)",
  "Meerane (Germany)",
  "Meerbeck (Germany)",
  "Meerbusch (Germany)",
  "Meersburg (Germany)",
  "Meesiger (Germany)",
  "Megesheim (Germany)",
  "Mehlbach (Germany)",
  "Mehlingen (Germany)",
  "Mehlmeisel (Germany)",
  "Mehmels (Germany)",
  "Mehr (Germany)",
  "Mehring (Germany)",
  "Meine (Germany)",
  "Meinersen (Germany)",
  "Meinerzhagen (Germany)",
  "Meiningen (Germany)",
  "Meinsdorf (Germany)",
  "Meisenheim (Germany)",
  "Meissen (Germany)",
  "Meissenheim (Germany)",
  "Meitingen (Germany)",
  "Melbeck (Germany)",
  "Melchow (Germany)",
  "Meldorf (Germany)",
  "Melle (Germany)",
  "Mellenbach-Glasbach (Germany)",
  "Mellingen (Germany)",
  "Mellrichstadt (Germany)",
  "Melsbach (Germany)",
  "Melsdorf (Germany)",
  "Melsungen (Germany)",
  "Memmelsdorf (Germany)",
  "Memmingen (Germany)",
  "Memmingerberg (Germany)",
  "Menden (Germany)",
  "Mendig (Germany)",
  "Mengen (Germany)",
  "Mengerschied (Germany)",
  "Mengersgereuth-Hammern (Germany)",
  "Mengerskirchen (Germany)",
  "Mengkofen (Germany)",
  "Menslage (Germany)",
  "Meppen (Germany)",
  "Merching (Germany)",
  "Merchweiler (Germany)",
  "Merdingen (Germany)",
  "Merenberg (Germany)",
  "Mering (Germany)",
  "Merkelbach (Germany)",
  "Merkendorf (Germany)",
  "Merkers (Germany)",
  "Merklingen (Germany)",
  "Merschwitz (Germany)",
  "Merseburg (Germany)",
  "Mersheim (Germany)",
  "Mertendorf (Germany)",
  "Mertingen (Germany)",
  "Mertloch (Germany)",
  "Merxheim (Germany)",
  "Merzalben (Germany)",
  "Merzen (Germany)",
  "Merzenich (Germany)",
  "Merzhausen (Germany)",
  "Merzig (Germany)",
  "Meschede (Germany)",
  "Messel (Germany)",
  "Messkirch (Germany)",
  "Messstetten (Germany)",
  "Metelen (Germany)",
  "Metjendorf (Germany)",
  "Metten (Germany)",
  "Mettenheim (Germany)",
  "Metterich (Germany)",
  "Mettingen (Germany)",
  "Mettlach (Germany)",
  "Mettmann (Germany)",
  "Metzingen (Germany)",
  "Metzlos-Gehag (Germany)",
  "Meudt (Germany)",
  "Meuro (Germany)",
  "Meuselwitz (Germany)",
  "Meyn (Germany)",
  "Michelau (Germany)",
  "Michelbach an der Bilz (Germany)",
  "Michelfeld (Germany)",
  "Michelstadt (Germany)",
  "Michendorf (Germany)",
  "Mickhausen (Germany)",
  "Midlum (Germany)",
  "Miehlen (Germany)",
  "Mielenhausen (Germany)",
  "Mielkendorf (Germany)",
  "Miellen (Germany)",
  "Miesbach (Germany)",
  "Mieste (Germany)",
  "Mietingen (Germany)",
  "Mihla (Germany)",
  "Milbertshofen (Germany)",
  "Mildenau (Germany)",
  "Mildstedt (Germany)",
  "Milow (Germany)",
  "Miltenberg (Germany)",
  "Milzau (Germany)",
  "Mindelheim (Germany)",
  "Mindelstetten (Germany)",
  "Minden (Germany)",
  "Minfeld (Germany)",
  "Minsen (Germany)",
  "Mintraching (Germany)",
  "Mirow (Germany)",
  "Missen-Wilhams (Germany)",
  "Mistelbach (Germany)",
  "Mistelgau (Germany)",
  "Mittbach (Germany)",
  "Mittegrossefehn (Germany)",
  "Mittelbach (Germany)",
  "Mittelberg (Germany)",
  "Mittelbiberach (Germany)",
  "Mitteleschenbach (Germany)",
  "Mittelherwigsdorf (Germany)",
  "Mittelneufnach (Germany)",
  "Mittelnkirchen (Germany)",
  "Mittelrot (Germany)",
  "Mittelsaida (Germany)",
  "Mittelstenweiler (Germany)",
  "Mittelstetten (Germany)",
  "Mittelstreu (Germany)",
  "Mittenaar (Germany)",
  "Mittenwald (Germany)",
  "Mittenwalde (Germany)",
  "Mitterfelden (Germany)",
  "Mitterfels (Germany)",
  "Mitterstetten (Germany)",
  "Mitterteich (Germany)",
  "Mittweida (Germany)",
  "Mitwitz (Germany)",
  "Mixdorf (Germany)",
  "Mochau (Germany)",
  "Mochenwangen (Germany)",
  "Mockern (Germany)",
  "Mockmuhl (Germany)",
  "Mockrehna (Germany)",
  "Moers (Germany)",
  "Mogendorf (Germany)",
  "Mogglingen (Germany)",
  "Moglingen (Germany)",
  "Mohlsdorf (Germany)",
  "Mohnesee (Germany)",
  "Mohren (Germany)",
  "Mohrendorf (Germany)",
  "Mohrkirch (Germany)",
  "Moisburg (Germany)",
  "Molbergen (Germany)",
  "Molfsee (Germany)",
  "Mollenhagen (Germany)",
  "Molln (Germany)",
  "Molschow (Germany)",
  "Molsheim (Germany)",
  "Mombris (Germany)",
  "Momlingen (Germany)",
  "Mommenheim (Germany)",
  "Monchberg (Germany)",
  "Monchsdeggingen (Germany)",
  "Monchsroth (Germany)",
  "Monchweiler (Germany)",
  "Monheim (Germany)",
  "Monheim am Rhein (Germany)",
  "Monkeberg (Germany)",
  "Monreal (Germany)",
  "Monschau (Germany)",
  "Monsheim (Germany)",
  "Montabaur (Germany)",
  "Monzelfeld (Germany)",
  "Monzingen (Germany)",
  "Moor (Germany)",
  "Moorenweis (Germany)",
  "Moos (Germany)",
  "Moosach (Germany)",
  "Moosburg (Germany)",
  "Moosinning (Germany)",
  "Moosthenning (Germany)",
  "Morbach (Germany)",
  "Morenhoven (Germany)",
  "Morfelden-Walldorf (Germany)",
  "Moringen (Germany)",
  "Moritz (Germany)",
  "Moritzburg (Germany)",
  "Morlenbach (Germany)",
  "Morsbach (Germany)",
  "Morscheid (Germany)",
  "Morschheim (Germany)",
  "Morschied (Germany)",
  "Morsfeld (Germany)",
  "Morshausen (Germany)",
  "Morsleben (Germany)",
  "Morslingen (Germany)",
  "Mosbach (Germany)",
  "Moschenfeld (Germany)",
  "Moser (Germany)",
  "Mossingen (Germany)",
  "Mottgers (Germany)",
  "Mottingen (Germany)",
  "Motzingen (Germany)",
  "Moyland (Germany)",
  "Much (Germany)",
  "Mucheln (Germany)",
  "Mudau (Germany)",
  "Muden (Germany)",
  "Mudersbach (Germany)",
  "Mudershausen (Germany)",
  "Muehldorf (Germany)",
  "Muehlheim am Main (Germany)",
  "Mugeln (Germany)",
  "Muggendorf (Germany)",
  "Muggensturm (Germany)",
  "Muhl Rosin (Germany)",
  "Muhlacker (Germany)",
  "Muhlau (Germany)",
  "Muhle (Germany)",
  "Muhlen (Germany)",
  "Muhlhausen (Germany)",
  "Muhlhausen-Ehingen (Germany)",
  "Muhlheim an der Donau (Germany)",
  "Muhltal (Germany)",
  "Muhltroff (Germany)",
  "Muhr am See (Germany)",
  "Mulda (Germany)",
  "Muldenhammer (Germany)",
  "Muldenstein (Germany)",
  "Mulfingen (Germany)",
  "Mulheim-Karlich (Germany)",
  "Mullrose (Germany)",
  "Mulsen (Germany)",
  "Mulsum (Germany)",
  "Munchaurach (Germany)",
  "Munchberg (Germany)",
  "Muncheberg (Germany)",
  "Munchenbernsdorf (Germany)",
  "Munchhausen (Germany)",
  "Munchsmunster (Germany)",
  "Munchsteinach (Germany)",
  "Munchweiler am Klingbach (Germany)",
  "Munchweiler an der Alsenz (Germany)",
  "Munderkingen (Germany)",
  "Mundersbach (Germany)",
  "Munich (Germany)",
  "Munnerstadt (Germany)",
  "Munsing (Germany)",
  "Munsingen (Germany)",
  "Munster (Germany)",
  "Munster-Sarmsheim (Germany)",
  "Munsterdorf (Germany)",
  "Munstermaifeld (Germany)",
  "Munzenberg (Germany)",
  "Munzingen (Germany)",
  "Murchin (Germany)",
  "Murg (Germany)",
  "Murlenbach (Germany)",
  "Murnau am Staffelsee (Germany)",
  "Murr (Germany)",
  "Murrhardt (Germany)",
  "Muschenbach (Germany)",
  "Mutlangen (Germany)",
  "Mutterschied (Germany)",
  "Mutterstadt (Germany)",
  "Mutzenich (Germany)",
  "Mutzschen (Germany)",
  "Mylau (Germany)",
  "Nabburg (Germany)",
  "Nachrodt-Wiblingwerde (Germany)",
  "Nachterstedt (Germany)",
  "Nachtsheim (Germany)",
  "Nackenheim (Germany)",
  "Nagel (Germany)",
  "Nagold (Germany)",
  "Nahe (Germany)",
  "Nahrendorf (Germany)",
  "Naila (Germany)",
  "Nakensdorf (Germany)",
  "Nalbach (Germany)",
  "Namborn (Germany)",
  "Nandlstadt (Germany)",
  "Nanzweiler (Germany)",
  "Nassau (Germany)",
  "Nassenfels (Germany)",
  "Nastatten (Germany)",
  "Nattenheim (Germany)",
  "Nattheim (Germany)",
  "Nauen (Germany)",
  "Nauendorf (Germany)",
  "Nauheim (Germany)",
  "Naumburg (Germany)",
  "Naunheim (Germany)",
  "Naunhof (Germany)",
  "Nauort (Germany)",
  "Naurath (Germany)",
  "Nebra (Germany)",
  "Nebringen (Germany)",
  "Neckarbischofsheim (Germany)",
  "Neckargemund (Germany)",
  "Neckargerach (Germany)",
  "Neckarrems (Germany)",
  "Neckarsteinach (Germany)",
  "Neckarsulm (Germany)",
  "Neckartailfingen (Germany)",
  "Neckartenzlingen (Germany)",
  "Neckarweihingen (Germany)",
  "Neckarwestheim (Germany)",
  "Neckarzimmern (Germany)",
  "Neehausen (Germany)",
  "Neenstetten (Germany)",
  "Neermoor (Germany)",
  "Neetze (Germany)",
  "Negast (Germany)",
  "Nehren (Germany)",
  "Neidenfels (Germany)",
  "Neidenstein (Germany)",
  "Neidlingen (Germany)",
  "Neitersen (Germany)",
  "Nellingen (Germany)",
  "Nennhausen (Germany)",
  "Nennig (Germany)",
  "Nennslingen (Germany)",
  "Nentershausen (Germany)",
  "Nerchau (Germany)",
  "Neresheim (Germany)",
  "Neroth (Germany)",
  "Nersingen (Germany)",
  "Nesselwang (Germany)",
  "Netphen (Germany)",
  "Nettersheim (Germany)",
  "Nettetal (Germany)",
  "Netze (Germany)",
  "Netzschkau (Germany)",
  "Neu Darchau (Germany)",
  "Neu Duvenstedt (Germany)",
  "Neu Isenburg (Germany)",
  "Neu Kaliss (Germany)",
  "Neu Sanitz (Germany)",
  "Neu Vitense (Germany)",
  "Neu Wulmstorf (Germany)",
  "Neu Zauche (Germany)",
  "Neu-Anspach (Germany)",
  "Neu-Ulm (Germany)",
  "Neubeckum (Germany)",
  "Neuberg (Germany)",
  "Neubeuern (Germany)",
  "Neubiberg (Germany)",
  "Neuborger (Germany)",
  "Neubrandenburg (Germany)",
  "Neubrunn (Germany)",
  "Neubukow (Germany)",
  "Neubulach (Germany)",
  "Neuburg (Germany)",
  "Neuburg an der Donau (Germany)",
  "Neudietendorf (Germany)",
  "Neudorf (Germany)",
  "Neudrossenfeld (Germany)",
  "Neuenbrook (Germany)",
  "Neuenburg am Rhein (Germany)",
  "Neuendettelsau (Germany)",
  "Neuendorf (Germany)",
  "Neuengors (Germany)",
  "Neuenhagen (Germany)",
  "Neuenhaus (Germany)",
  "Neuenkirchen (Germany)",
  "Neuenmarkt (Germany)",
  "Neuenrade (Germany)",
  "Neuenstadt am Kocher (Germany)",
  "Neuenstein (Germany)",
  "Neuental (Germany)",
  "Neuerburg (Germany)",
  "Neufahrn bei Freising (Germany)",
  "Neufarn (Germany)",
  "Neuffen (Germany)",
  "Neufra (Germany)",
  "Neufrach (Germany)",
  "Neufraunhofen (Germany)",
  "Neugersdorf (Germany)",
  "Neuhardenberg (Germany)",
  "Neuhaus (Germany)",
  "Neuhaus am Inn (Germany)",
  "Neuhaus am Rennweg (Germany)",
  "Neuhaus-Schierschnitz (Germany)",
  "Neuhausel (Germany)",
  "Neuhausen (Germany)",
  "Neuhausen auf den Fildern (Germany)",
  "Neuhausen ob Eck (Germany)",
  "Neuhemsbach (Germany)",
  "Neuhof (Germany)",
  "Neuhofen (Germany)",
  "Neuhutten (Germany)",
  "Neukamperfehn (Germany)",
  "Neukeferloh (Germany)",
  "Neukieritzsch (Germany)",
  "Neukirch (Germany)",
  "Neukirch/Lausitz (Germany)",
  "Neukirchen (Germany)",
  "Neukirchen am Teisenberg (Germany)",
  "Neukirchen-Vluyn (Germany)",
  "Neukloster (Germany)",
  "Neuleiningen (Germany)",
  "Neulussheim (Germany)",
  "Neumagen-Dhron (Germany)",
  "Neumark (Germany)",
  "Neumarkt in der Oberpfalz (Germany)",
  "Neumarkt-Sankt Veit (Germany)",
  "Neunburg vorm Wald (Germany)",
  "Neundorf (Germany)",
  "Neunheilingen (Germany)",
  "Neunkirchen (Germany)",
  "Neunkirchen am Sand (Germany)",
  "Neuotting (Germany)",
  "Neupetershain (Germany)",
  "Neupotz (Germany)",
  "Neuravensburg (Germany)",
  "Neureichenau (Germany)",
  "Neuried (Germany)",
  "Neuruppin (Germany)",
  "Neusalza-Spremberg (Germany)",
  "Neusass (Germany)",
  "Neuschonau (Germany)",
  "Neusitz (Germany)",
  "Neusorg (Germany)",
  "Neuss (Germany)",
  "Neustadt (Germany)",
  "Neustadt (Orla) (Germany)",
  "Neustadt am Rubenberge (Germany)",
  "Neustadt an der Aisch (Germany)",
  "Neustadt an der Orla (Germany)",
  "Neustadt an der Waldnaab (Germany)",
  "Neustadt bei Coburg (Germany)",
  "Neustadt in Holstein (Germany)",
  "Neustadt in Sachsen (Germany)",
  "Neustadt-Glewe (Germany)",
  "Neustadt/Westerwald (Germany)",
  "Neustrelitz (Germany)",
  "Neutraubling (Germany)",
  "Neutrebbin (Germany)",
  "Neutz-Lettewitz (Germany)",
  "Neuweiler (Germany)",
  "Neuwerk (Germany)",
  "Neuwied (Germany)",
  "Neuwittenbek (Germany)",
  "Neuzelle (Germany)",
  "Neverin (Germany)",
  "Neversdorf (Germany)",
  "Newel (Germany)",
  "Nickenich (Germany)",
  "Nidda (Germany)",
  "Nidderau (Germany)",
  "Nideggen (Germany)",
  "Niebull (Germany)",
  "Niedenstein (Germany)",
  "Nieder Ohmen (Germany)",
  "Nieder-Beerbach (Germany)",
  "Nieder-Olm (Germany)",
  "Niederaichbach (Germany)",
  "Niederalben (Germany)",
  "Niederalsen (Germany)",
  "Niederalteich (Germany)",
  "Niederau (Germany)",
  "Niederaula (Germany)",
  "Niederbrechen (Germany)",
  "Niederbreitbach (Germany)",
  "Niederburg (Germany)",
  "Niederding (Germany)",
  "Niederdorf (Germany)",
  "Niederdorfelden (Germany)",
  "Niederdorla (Germany)",
  "Niedereisenhausen (Germany)",
  "Niedereschach (Germany)",
  "Niederfell (Germany)",
  "Niederfischbach (Germany)",
  "Niederfrohna (Germany)",
  "Niederheimbach (Germany)",
  "Niederkassel (Germany)",
  "Niederkirchen (Germany)",
  "Niederkirchen bei Deidesheim (Germany)",
  "Niederkruchten (Germany)",
  "Niederlangen (Germany)",
  "Niederlindhart (Germany)",
  "Niedermurach (Germany)",
  "Niedernberg (Germany)",
  "Niederndodeleben (Germany)",
  "Niederneisen (Germany)",
  "Niederneuching (Germany)",
  "Niedernhall (Germany)",
  "Niedernhausen (Germany)",
  "Niedernwohren (Germany)",
  "Niederorschel (Germany)",
  "Niederpollnitz (Germany)",
  "Niederquembach (Germany)",
  "Niederrieden (Germany)",
  "Niedersachswerfen (Germany)",
  "Niederschonenfeld (Germany)",
  "Niederselters (Germany)",
  "Niederspier (Germany)",
  "Niederstadtfeld (Germany)",
  "Niederstaufenbach (Germany)",
  "Niederstetten (Germany)",
  "Niederstotzingen (Germany)",
  "Niederviehbach (Germany)",
  "Niederwambach (Germany)",
  "Niederweidbach (Germany)",
  "Niederwerrn (Germany)",
  "Niederwiesa (Germany)",
  "Niederwinkling (Germany)",
  "Niederwuerschnitz (Germany)",
  "Niederzeuzheim (Germany)",
  "Niederzier (Germany)",
  "Niederzissen (Germany)",
  "Niefern-Oschelbronn (Germany)",
  "Nieheim (Germany)",
  "Niemberg (Germany)",
  "Niemegk (Germany)",
  "Nienborstel (Germany)",
  "Nienburg (Germany)",
  "Nienburg/Saale (Germany)",
  "Niendorf (Germany)",
  "Nienhagen (Germany)",
  "Nienstadt (Germany)",
  "Niepars (Germany)",
  "Nierstein (Germany)",
  "Niesgrau (Germany)",
  "Niesky (Germany)",
  "Nieste (Germany)",
  "Nindorf (Germany)",
  "Nittel (Germany)",
  "Nittenau (Germany)",
  "Nittendorf (Germany)",
  "Nitz (Germany)",
  "Nobitz (Germany)",
  "Noda (Germany)",
  "Nohfelden (Germany)",
  "Nohra (Germany)",
  "Nonnenhorn (Germany)",
  "Nonnweiler (Germany)",
  "Norddeich (Germany)",
  "Norden (Germany)",
  "Nordendorf (Germany)",
  "Nordenham (Germany)",
  "Norderney (Germany)",
  "Norderstapel (Germany)",
  "Norderstedt (Germany)",
  "Nordhalben (Germany)",
  "Nordhastedt (Germany)",
  "Nordhausen (Germany)",
  "Nordheim (Germany)",
  "Nordhofen (Germany)",
  "Nordholz (Germany)",
  "Nordhorn (Germany)",
  "Nordkirchen (Germany)",
  "Nordrach (Germany)",
  "Nordstemmen (Germany)",
  "Nordwalde (Germany)",
  "Norheim (Germany)",
  "Norken (Germany)",
  "Norsingen (Germany)",
  "Norten-Hardenberg (Germany)",
  "Northeim (Germany)",
  "Nortmoor (Germany)",
  "Nortorf (Germany)",
  "Nortrup (Germany)",
  "Norvenich (Germany)",
  "Nossen (Germany)",
  "Nottuln (Germany)",
  "Notzingen (Germany)",
  "Nubbel (Germany)",
  "Nudlingen (Germany)",
  "Nufringen (Germany)",
  "Numbrecht (Germany)",
  "Nunchritz (Germany)",
  "Nuremberg (Germany)",
  "Nurtingen (Germany)",
  "Nusplingen (Germany)",
  "Nussdorf am Inn (Germany)",
  "Nusse (Germany)",
  "Nussloch (Germany)",
  "Ober-Erlenbach (Germany)",
  "Ober-Hilbersheim (Germany)",
  "Ober-Morlen (Germany)",
  "Ober-Olm (Germany)",
  "Ober-Ramstadt (Germany)",
  "Ober-Roden (Germany)",
  "Oberammergau (Germany)",
  "Oberasbach (Germany)",
  "Oberau (Germany)",
  "Oberaudorf (Germany)",
  "Oberaula (Germany)",
  "Oberberg (Germany)",
  "Oberbillig (Germany)",
  "Oberbodnitz (Germany)",
  "Oberboihingen (Germany)",
  "Oberderdingen (Germany)",
  "Oberdiebach (Germany)",
  "Oberding (Germany)",
  "Oberdischingen (Germany)",
  "Oberdolling (Germany)",
  "Oberdorla (Germany)",
  "Oberelbert (Germany)",
  "Oberelsbach (Germany)",
  "Oberfell (Germany)",
  "Obergebra (Germany)",
  "Obergriesbach (Germany)",
  "Obergunzburg (Germany)",
  "Obergurig (Germany)",
  "Oberhaching (Germany)",
  "Oberhaid (Germany)",
  "Oberharmersbach (Germany)",
  "Oberhausen (Germany)",
  "Oberhausen-Rheinhausen (Germany)",
  "Oberhermsdorf (Germany)",
  "Oberhof (Germany)",
  "Oberhonnefeld-Gierend (Germany)",
  "Oberhundem (Germany)",
  "Oberickelsheim (Germany)",
  "Oberirsen (Germany)",
  "Oberkail (Germany)",
  "Oberkammlach (Germany)",
  "Oberkirch (Germany)",
  "Oberkirchen (Germany)",
  "Oberkochen (Germany)",
  "Oberkotzau (Germany)",
  "Oberlahr (Germany)",
  "Oberlaindern (Germany)",
  "Oberleichtersbach (Germany)",
  "Oberlungwitz (Germany)",
  "Obermarchtal (Germany)",
  "Obermeilingen (Germany)",
  "Obermeitingen (Germany)",
  "Obermichelbach (Germany)",
  "Obermohr (Germany)",
  "Obermoschel (Germany)",
  "Obernbreit (Germany)",
  "Obernburg am Main (Germany)",
  "Oberndorf (Germany)",
  "Oberneukirchen (Germany)",
  "Oberneuses (Germany)",
  "Obernfeld (Germany)",
  "Obernheim-Kirchenarnbach (Germany)",
  "Obernkirchen (Germany)",
  "Obernzell (Germany)",
  "Obernzenn (Germany)",
  "Oberopfingen (Germany)",
  "Oberornau (Germany)",
  "Oberotterbach (Germany)",
  "Oberottmarshausen (Germany)",
  "Oberpframmern (Germany)",
  "Oberpierscheid (Germany)",
  "Oberpleichfeld (Germany)",
  "Oberpleis (Germany)",
  "Oberporing (Germany)",
  "Oberporlitz (Germany)",
  "Oberraden (Germany)",
  "Oberreichenbach (Germany)",
  "Oberried (Germany)",
  "Oberrieden (Germany)",
  "Oberriexingen (Germany)",
  "Oberrod (Germany)",
  "Oberrot (Germany)",
  "Oberschefflenz (Germany)",
  "Oberscheinfeld (Germany)",
  "Oberschleissheim (Germany)",
  "Oberschneiding (Germany)",
  "Oberschona (Germany)",
  "Oberschweinbach (Germany)",
  "Oberseifersdorf (Germany)",
  "Obersontheim (Germany)",
  "Oberstadion (Germany)",
  "Oberstadtfeld (Germany)",
  "Oberstaufen (Germany)",
  "Oberstdorf (Germany)",
  "Oberstenfeld (Germany)",
  "Oberstreu (Germany)",
  "Obersuhl (Germany)",
  "Obertaufkirchen (Germany)",
  "Oberteuringen (Germany)",
  "Oberthal (Germany)",
  "Oberthulba (Germany)",
  "Obertraubling (Germany)",
  "Obertrubach (Germany)",
  "Obertshausen (Germany)",
  "Oberursel (Germany)",
  "Oberviechtach (Germany)",
  "Oberweimar (Germany)",
  "Oberweis (Germany)",
  "Oberweissbach (Germany)",
  "Oberwesel (Germany)",
  "Oberwiesenthal (Germany)",
  "Oberwinter (Germany)",
  "Oberwolfach (Germany)",
  "Oberzissen (Germany)",
  "Obing (Germany)",
  "Obrigheim (Germany)",
  "Obristfeld (Germany)",
  "Ochsenfurt (Germany)",
  "Ochsenhausen (Germany)",
  "Ochtendung (Germany)",
  "Ochtmersleben (Germany)",
  "Ochtrup (Germany)",
  "Ockenfels (Germany)",
  "Odelzhausen (Germany)",
  "Odendorf (Germany)",
  "Odenheim (Germany)",
  "Odenthal (Germany)",
  "Oderberg (Germany)",
  "Odernheim (Germany)",
  "Oebisfelde (Germany)",
  "Oechsen (Germany)",
  "Oederan (Germany)",
  "Oedheim (Germany)",
  "Oelde (Germany)",
  "Oelerse (Germany)",
  "Oelixdorf (Germany)",
  "Oelsberg (Germany)",
  "Oelsen (Germany)",
  "Oelsnitz (Germany)",
  "Oelzschau (Germany)",
  "Oer-Erkenschwick (Germany)",
  "Oerlenbach (Germany)",
  "Oerlinghausen (Germany)",
  "Oersdorf (Germany)",
  "Oesterdeichstrich (Germany)",
  "Oestrich-Winkel (Germany)",
  "Oettersdorf (Germany)",
  "Oettingen in Bayern (Germany)",
  "Oeversee (Germany)",
  "Offenau (Germany)",
  "Offenbach (Germany)",
  "Offenbach an der Queich (Germany)",
  "Offenbach-Hundheim (Germany)",
  "Offenberg (Germany)",
  "Offenburg (Germany)",
  "Offenhausen (Germany)",
  "Offingen (Germany)",
  "Offstein (Germany)",
  "Ofterdingen (Germany)",
  "Oftersheim (Germany)",
  "Oggelshausen (Germany)",
  "Ohlsbach (Germany)",
  "Ohlsdorf (Germany)",
  "Ohlstadt (Germany)",
  "Ohmden (Germany)",
  "Ohningen (Germany)",
  "Ohorn (Germany)",
  "Ohrdruf (Germany)",
  "Ohren (Germany)",
  "Ohrensen (Germany)",
  "Ohringen (Germany)",
  "Olbernhau (Germany)",
  "Olbersdorf (Germany)",
  "Olbersleben (Germany)",
  "Olbronn-Durrn (Germany)",
  "Olching (Germany)",
  "Oldenburg (Germany)",
  "Oldenburg in Holstein (Germany)",
  "Oldendorf (Germany)",
  "Oldenswort (Germany)",
  "Oldersum (Germany)",
  "Olderup (Germany)",
  "Oldisleben (Germany)",
  "Olfen (Germany)",
  "Olpe (Germany)",
  "Olsberg (Germany)",
  "Olsbrucken (Germany)",
  "Olzheim (Germany)",
  "Opfenbach (Germany)",
  "Opfingen (Germany)",
  "Oppach (Germany)",
  "Oppenau (Germany)",
  "Oppendorf (Germany)",
  "Oppenheim (Germany)",
  "Oppenweiler (Germany)",
  "Oppurg (Germany)",
  "Oranienbaum (Germany)",
  "Oranienburg (Germany)",
  "Orbis (Germany)",
  "Oring (Germany)",
  "Ornbau (Germany)",
  "Orsingen-Nenzingen (Germany)",
  "Ortenberg (Germany)",
  "Ortenburg (Germany)",
  "Ortrand (Germany)",
  "Osann-Monzel (Germany)",
  "Osburg (Germany)",
  "Osch (Germany)",
  "Oschatz (Germany)",
  "Oschersleben (Germany)",
  "Oschingen (Germany)",
  "Osdorf (Germany)",
  "Osede (Germany)",
  "Osingen (Germany)",
  "Osloss (Germany)",
  "Ostbevern (Germany)",
  "Ostelsheim (Germany)",
  "Osten (Germany)",
  "Ostenfeld (Germany)",
  "Osterberg (Germany)",
  "Osterbrock (Germany)",
  "Osterburg (Germany)",
  "Osterburken (Germany)",
  "Ostercappeln (Germany)",
  "Osterfeld (Germany)",
  "Osterhausen (Germany)",
  "Osterhofen (Germany)",
  "Osterholz-Scharmbeck (Germany)",
  "Osternienburg (Germany)",
  "Osterode (Germany)",
  "Osterode am Harz (Germany)",
  "Osterrade (Germany)",
  "Osterronfeld (Germany)",
  "Osterspai (Germany)",
  "Osterstedt (Germany)",
  "Osterwald (Germany)",
  "Osterwieck (Germany)",
  "Ostfildern (Germany)",
  "Ostheim (Germany)",
  "Osthofen (Germany)",
  "Ostrach (Germany)",
  "Ostramondra (Germany)",
  "Ostrhauderfehn (Germany)",
  "Ostringen (Germany)",
  "Ostritz (Germany)",
  "Ostseebad Ahrenshoop (Germany)",
  "Ostseebad Baabe (Germany)",
  "Ostseebad Binz (Germany)",
  "Ostseebad Boltenhagen (Germany)",
  "Ostseebad Dierhagen (Germany)",
  "Ostseebad Gohren (Germany)",
  "Ostseebad Kuhlungsborn (Germany)",
  "Ostseebad Nienhagen (Germany)",
  "Ostseebad Prerow (Germany)",
  "Ostseebad Sellin (Germany)",
  "Ostseebad Zinnowitz (Germany)",
  "Oststeinbek (Germany)",
  "Othenstorf (Germany)",
  "Otigheim (Germany)",
  "Otisheim (Germany)",
  "Ottenbach (Germany)",
  "Ottendorf-Okrilla (Germany)",
  "Ottenhofen (Germany)",
  "Ottensoos (Germany)",
  "Otter (Germany)",
  "Otterbach (Germany)",
  "Otterberg (Germany)",
  "Otterfing (Germany)",
  "Otterloh (Germany)",
  "Otterndorf (Germany)",
  "Ottersberg (Germany)",
  "Ottersheim (Germany)",
  "Otterstadt (Germany)",
  "Ottersweier (Germany)",
  "Otterwisch (Germany)",
  "Ottobeuren (Germany)",
  "Ottobrunn (Germany)",
  "Ottrau (Germany)",
  "Ottweiler (Germany)",
  "Otze (Germany)",
  "Otzing (Germany)",
  "Ovelgoenne (Germany)",
  "Ovelgonne (Germany)",
  "Overath (Germany)",
  "Owen (Germany)",
  "Owingen (Germany)",
  "Owschlag (Germany)",
  "Oy-Mittelberg (Germany)",
  "Oyten (Germany)",
  "Padelugge (Germany)",
  "Padenstedt (Germany)",
  "Paderborn (Germany)",
  "Pahl (Germany)",
  "Painten (Germany)",
  "Palling (Germany)",
  "Panschwitz-Kuckau (Germany)",
  "Pansdorf (Germany)",
  "Pantelitz (Germany)",
  "Papenburg (Germany)",
  "Papendorf (Germany)",
  "Pappenheim (Germany)",
  "Paradies (Germany)",
  "Parchim (Germany)",
  "Parey (Germany)",
  "Parkstein (Germany)",
  "Parkstetten (Germany)",
  "Parnsberg (Germany)",
  "Parsau (Germany)",
  "Parsdorf (Germany)",
  "Partenheim (Germany)",
  "Partenstein (Germany)",
  "Parum (Germany)",
  "Pasewalk (Germany)",
  "Passau (Germany)",
  "Passow (Germany)",
  "Pastetten (Germany)",
  "Pattensen (Germany)",
  "Patzig (Germany)",
  "Paulinenaue (Germany)",
  "Paunzhausen (Germany)",
  "Pausa (Germany)",
  "Pauscha (Germany)",
  "Pechbrunn (Germany)",
  "Peckelsheim (Germany)",
  "Peetsch (Germany)",
  "Pegau (Germany)",
  "Pegnitz (Germany)",
  "Peine (Germany)",
  "Peissenberg (Germany)",
  "Peiting (Germany)",
  "Peitz (Germany)",
  "Pellingen (Germany)",
  "Pellworm (Germany)",
  "Pelm (Germany)",
  "Pemfling (Germany)",
  "Penig (Germany)",
  "Pentenried (Germany)",
  "Pentling (Germany)",
  "Penzberg (Germany)",
  "Penzing (Germany)",
  "Perach (Germany)",
  "Perkam (Germany)",
  "Perl (Germany)",
  "Perleberg (Germany)",
  "Perlesreut (Germany)",
  "Pesch (Germany)",
  "Pessenburgheim (Germany)",
  "Pesterwitz (Germany)",
  "Petersaurach (Germany)",
  "Petersberg (Germany)",
  "Petersdorf (Germany)",
  "Petershagen (Germany)",
  "Petershausen (Germany)",
  "Petriroda (Germany)",
  "Petting (Germany)",
  "Pettstadt (Germany)",
  "Pewsum (Germany)",
  "Pfaffen-Schwabenheim (Germany)",
  "Pfaffenhausen (Germany)",
  "Pfaffenhofen (Germany)",
  "Pfaffenhofen an der Ilm (Germany)",
  "Pfaffenhofen an der Roth (Germany)",
  "Pfaffenweiler (Germany)",
  "Pfaffing (Germany)",
  "Pfaffroda (Germany)",
  "Pfalzfeld (Germany)",
  "Pfalzgrafenweiler (Germany)",
  "Pfarrkirchen (Germany)",
  "Pfarrweisach (Germany)",
  "Pfatter (Germany)",
  "Pfedelbach (Germany)",
  "Pfeffelbach (Germany)",
  "Pfeffenhausen (Germany)",
  "Pflummern (Germany)",
  "Pforring (Germany)",
  "Pforzen (Germany)",
  "Pforzheim (Germany)",
  "Pfreimd (Germany)",
  "Pfronten (Germany)",
  "Pfullendorf (Germany)",
  "Pfullingen (Germany)",
  "Pfungstadt (Germany)",
  "Philippsburg (Germany)",
  "Philippsthal (Germany)",
  "Pickliessem (Germany)",
  "Piding (Germany)",
  "Piesport (Germany)",
  "Pilsach (Germany)",
  "Pilsting (Germany)",
  "Pinneberg (Germany)",
  "Pinnow (Germany)",
  "Pirk (Germany)",
  "Pirmasens (Germany)",
  "Pirna (Germany)",
  "Pittenhart (Germany)",
  "Pixel (Germany)",
  "Plaidt (Germany)",
  "Plan (Germany)",
  "Planegg (Germany)",
  "Plankstadt (Germany)",
  "Plate (Germany)",
  "Platkow (Germany)",
  "Platten (Germany)",
  "Plattenburg (Germany)",
  "Plattling (Germany)",
  "Plau am See (Germany)",
  "Plaue (Germany)",
  "Plauen (Germany)",
  "Plech (Germany)",
  "Pleckhausen (Germany)",
  "Pleidelsheim (Germany)",
  "Plein (Germany)",
  "Pleinfeld (Germany)",
  "Pleiskirchen (Germany)",
  "Pleisweiler-Oberhofen (Germany)",
  "Pleizenhausen (Germany)",
  "Plessa (Germany)",
  "Plettenberg (Germany)",
  "Pleystein (Germany)",
  "Pliening (Germany)",
  "Pliezhausen (Germany)",
  "Plochingen (Germany)",
  "Plodda (Germany)",
  "Ploen (Germany)",
  "Plossberg (Germany)",
  "Pluderhausen (Germany)",
  "Pluwig (Germany)",
  "Pockau (Germany)",
  "Pocking (Germany)",
  "Podeldorf (Germany)",
  "Poggensee (Germany)",
  "Pohl (Germany)",
  "Pohnstorf (Germany)",
  "Poing (Germany)",
  "Point (Germany)",
  "Polch (Germany)",
  "Politz (Germany)",
  "Pollenfeld (Germany)",
  "Pollhagen (Germany)",
  "Polling (Germany)",
  "Pommelsbrunn (Germany)",
  "Pommerby (Germany)",
  "Pomster (Germany)",
  "Poppenhausen (Germany)",
  "Poppenlauer (Germany)",
  "Poppenricht (Germany)",
  "Pornbach (Germany)",
  "Porta Westfalica (Germany)",
  "Posing (Germany)",
  "Possendorf (Germany)",
  "Possenheim (Germany)",
  "Possneck (Germany)",
  "Postbauer-Heng (Germany)",
  "Postmunster (Germany)",
  "Potsdam (Germany)",
  "Pottenstein (Germany)",
  "Pottmes (Germany)",
  "Pouch (Germany)",
  "Poxdorf (Germany)",
  "Pracht (Germany)",
  "Prackenbach (Germany)",
  "Prangendorf (Germany)",
  "Prebberede (Germany)",
  "Prebitz (Germany)",
  "Preetz (Germany)",
  "Preith (Germany)",
  "Premnitz (Germany)",
  "Prenzlau (Germany)",
  "Pressath (Germany)",
  "Pressen (Germany)",
  "Pressig (Germany)",
  "Prestewitz (Germany)",
  "Pretzfeld (Germany)",
  "Preussisch Oldendorf (Germany)",
  "Prichsenstadt (Germany)",
  "Prien am Chiemsee (Germany)",
  "Priesendorf (Germany)",
  "Priestewitz (Germany)",
  "Prietitz (Germany)",
  "Prisdorf (Germany)",
  "Prittriching (Germany)",
  "Pritzwalk (Germany)",
  "Probsteierhagen (Germany)",
  "Prohn (Germany)",
  "Proseken (Germany)",
  "Protzen (Germany)",
  "Pruchten (Germany)",
  "Prum (Germany)",
  "Prumzurlay (Germany)",
  "Prutting (Germany)",
  "Puchersreuth (Germany)",
  "Puchheim (Germany)",
  "Puderbach (Germany)",
  "Pulheim (Germany)",
  "Pullach im Isartal (Germany)",
  "Pullenreuth (Germany)",
  "Pullhausen (Germany)",
  "Puls (Germany)",
  "Pulsnitz (Germany)",
  "Purgen (Germany)",
  "Puschendorf (Germany)",
  "Putbus (Germany)",
  "Putlitz (Germany)",
  "Puttlingen (Germany)",
  "Putzbrunn (Germany)",
  "Pyrbaum (Germany)",
  "Quaal (Germany)",
  "Quakenbruck (Germany)",
  "Quarnbek (Germany)",
  "Quarnstedt (Germany)",
  "Quedlinburg (Germany)",
  "Queidersbach (Germany)",
  "Querfurt (Germany)",
  "Querum (Germany)",
  "Quickborn (Germany)",
  "Quierschied (Germany)",
  "Quitzow (Germany)",
  "Raben (Germany)",
  "Rabenau (Germany)",
  "Rabenholz (Germany)",
  "Rabenkirchen-Fauluck (Germany)",
  "Rabke (Germany)",
  "Rachelsbach (Germany)",
  "Rackith (Germany)",
  "Rackwitz (Germany)",
  "Radbruch (Germany)",
  "Radeberg (Germany)",
  "Radebeul (Germany)",
  "Radeburg (Germany)",
  "Radefeld (Germany)",
  "Radegast (Germany)",
  "Radevormwald (Germany)",
  "Radibor (Germany)",
  "Radolfzell (Germany)",
  "Raesfeld (Germany)",
  "Raguhn (Germany)",
  "Rahden (Germany)",
  "Raibach (Germany)",
  "Rain (Germany)",
  "Raisdorf (Germany)",
  "Raisting (Germany)",
  "Ralingen (Germany)",
  "Rambin (Germany)",
  "Ramerberg (Germany)",
  "Rammelsbach (Germany)",
  "Rammenau (Germany)",
  "Rammingen (Germany)",
  "Ramsau (Germany)",
  "Ramsen (Germany)",
  "Ramsla (Germany)",
  "Ramsloh (Germany)",
  "Ramstein-Miesenbach (Germany)",
  "Ramsthal (Germany)",
  "Randersacker (Germany)",
  "Rangendingen (Germany)",
  "Rangsdorf (Germany)",
  "Ranis (Germany)",
  "Ransbach-Baumbach (Germany)",
  "Ranstadt (Germany)",
  "Ransweiler (Germany)",
  "Rantum (Germany)",
  "Rantzau (Germany)",
  "Rappenau (Germany)",
  "Raschau (Germany)",
  "Rasdorf (Germany)",
  "Rasselstein (Germany)",
  "Rastatt (Germany)",
  "Rastdorf (Germany)",
  "Rastede (Germany)",
  "Rastenberg (Germany)",
  "Rastow (Germany)",
  "Ratekau (Germany)",
  "Rath (Germany)",
  "Rathenow (Germany)",
  "Ratingen (Germany)",
  "Rattelsdorf (Germany)",
  "Rattenberg (Germany)",
  "Rattenkirchen (Germany)",
  "Ratzeburg (Germany)",
  "Raubach (Germany)",
  "Raubling (Germany)",
  "Rauda (Germany)",
  "Rauen (Germany)",
  "Rauenberg (Germany)",
  "Rauenstein (Germany)",
  "Raunheim (Germany)",
  "Rauschenberg (Germany)",
  "Rausdorf (Germany)",
  "Ravensburg (Germany)",
  "Ravenstein (Germany)",
  "Rechberg (Germany)",
  "Rechberghausen (Germany)",
  "Rechenberg-Bienenmuhle (Germany)",
  "Rechtenbach (Germany)",
  "Rechtenfleth (Germany)",
  "Rechtenstein (Germany)",
  "Rechtmehring (Germany)",
  "Rechtsupweg (Germany)",
  "Recke (Germany)",
  "Recklinghausen (Germany)",
  "Reddelich (Germany)",
  "Redefin (Germany)",
  "Redekin (Germany)",
  "Rednitzhembach (Germany)",
  "Redwitz an der Rodach (Germany)",
  "Rees (Germany)",
  "Regen (Germany)",
  "Regensburg (Germany)",
  "Regenstauf (Germany)",
  "Regis-Breitingen (Germany)",
  "Regnitzlosau (Germany)",
  "Rehau (Germany)",
  "Rehburg-Loccum (Germany)",
  "Rehden (Germany)",
  "Reher (Germany)",
  "Rehfelde (Germany)",
  "Rehhorst (Germany)",
  "Rehling (Germany)",
  "Rehlingen-Siersburg (Germany)",
  "Rehna (Germany)",
  "Reich (Germany)",
  "Reichardtswerben (Germany)",
  "Reichartshausen (Germany)",
  "Reichelsheim (Germany)",
  "Reichenbach (Germany)",
  "Reichenbach im Tale (Germany)",
  "Reichenbach-Steegen (Germany)",
  "Reichenbach/Vogtland (Germany)",
  "Reichenschwand (Germany)",
  "Reichersbeuern (Germany)",
  "Reichertshausen (Germany)",
  "Reichertshofen (Germany)",
  "Reilingen (Germany)",
  "Reimlingen (Germany)",
  "Reimsbach (Germany)",
  "Reinbek (Germany)",
  "Reinfeld (Germany)",
  "Reinhartshausen (Germany)",
  "Reinheim (Germany)",
  "Reinsberg (Germany)",
  "Reinsdorf (Germany)",
  "Reinstadt (Germany)",
  "Reipoltskirchen (Germany)",
  "Reisbach (Germany)",
  "Reischach (Germany)",
  "Reiskirchen (Germany)",
  "Reit im Winkl (Germany)",
  "Reithofen (Germany)",
  "Reitmehring (Germany)",
  "Reitsch (Germany)",
  "Rellingen (Germany)",
  "Remagen (Germany)",
  "Remels (Germany)",
  "Remlingen (Germany)",
  "Remmesweiler (Germany)",
  "Remptendorf (Germany)",
  "Remscheid (Germany)",
  "Renchen (Germany)",
  "Rendsburg (Germany)",
  "Rengsdorf (Germany)",
  "Rennerod (Germany)",
  "Rennersdorf (Germany)",
  "Rennertshofen (Germany)",
  "Renningen (Germany)",
  "Renquishausen (Germany)",
  "Rentrisch (Germany)",
  "Rentweinsdorf (Germany)",
  "Reppenstedt (Germany)",
  "Retgendorf (Germany)",
  "Rethwisch (Germany)",
  "Rettenberg (Germany)",
  "Rettersen (Germany)",
  "Rettert (Germany)",
  "Rettigheim (Germany)",
  "Rettin (Germany)",
  "Retzstadt (Germany)",
  "Reute (Germany)",
  "Reuth (Germany)",
  "Reutlingen (Germany)",
  "Reutlingendorf (Germany)",
  "Rhade (Germany)",
  "Rhadereistedt (Germany)",
  "Rhaunen (Germany)",
  "Rheda-Wiedenbruck (Germany)",
  "Rhede (Germany)",
  "Rheden (Germany)",
  "Rhein (Germany)",
  "Rheinau (Germany)",
  "Rheinbach (Germany)",
  "Rheinberg (Germany)",
  "Rheinbollen (Germany)",
  "Rheinbreitbach (Germany)",
  "Rheinbrohl (Germany)",
  "Rheine (Germany)",
  "Rheinfelden (Germany)",
  "Rheinsberg (Germany)",
  "Rheinzabern (Germany)",
  "Rheurdt (Germany)",
  "Rhinow (Germany)",
  "Rhumspringe (Germany)",
  "Ribbeck (Germany)",
  "Ribbesbuttel (Germany)",
  "Ribnitz (Germany)",
  "Ribnitz-Damgarten (Germany)",
  "Richen (Germany)",
  "Rickenbach (Germany)",
  "Rickert (Germany)",
  "Riding (Germany)",
  "Riedbach (Germany)",
  "Riede (Germany)",
  "Rieden (Germany)",
  "Riedenberg (Germany)",
  "Riedenburg (Germany)",
  "Riedenheim (Germany)",
  "Rieder (Germany)",
  "Riederich (Germany)",
  "Riedering (Germany)",
  "Riedhausen (Germany)",
  "Riedlingen (Germany)",
  "Riedmoos (Germany)",
  "Riegel (Germany)",
  "Riegelsberg (Germany)",
  "Riegsee (Germany)",
  "Rielasingen-Worblingen (Germany)",
  "Riemerling (Germany)",
  "Rieneck (Germany)",
  "Riepsdorf (Germany)",
  "Riesa (Germany)",
  "Rieseby (Germany)",
  "Rieste (Germany)",
  "Rietberg (Germany)",
  "Rietheim (Germany)",
  "Riethnordhausen (Germany)",
  "Rietschen (Germany)",
  "Rimbach (Germany)",
  "Rimhorn (Germany)",
  "Rimpar (Germany)",
  "Rimschweiler (Germany)",
  "Rimsting (Germany)",
  "Rinchnach (Germany)",
  "Ringenwalde (Germany)",
  "Ringsberg (Germany)",
  "Ringsheim (Germany)",
  "Rinteln (Germany)",
  "Rinzenberg (Germany)",
  "Riol (Germany)",
  "Ritschenhausen (Germany)",
  "Ritterhude (Germany)",
  "Rittersdorf (Germany)",
  "Robel (Germany)",
  "Rochlitz (Germany)",
  "Rockenberg (Germany)",
  "Rockenhausen (Germany)",
  "Rodach (Germany)",
  "Rodalben (Germany)",
  "Roden (Germany)",
  "Rodenbach (Germany)",
  "Rodenberg (Germany)",
  "Rodental (Germany)",
  "Rodersheim-Gronau (Germany)",
  "Rodewisch (Germany)",
  "Rodgau (Germany)",
  "Rodheim (Germany)",
  "Roding (Germany)",
  "Rodinghausen (Germany)",
  "Roehrnbach (Germany)",
  "Roes (Germany)",
  "Roetgen (Germany)",
  "Roetz (Germany)",
  "Rofingen (Germany)",
  "Roggenburg (Germany)",
  "Roggentin (Germany)",
  "Rogling (Germany)",
  "Rohr (Germany)",
  "Rohrbach (Germany)",
  "Rohrdorf (Germany)",
  "Rohrenfels (Germany)",
  "Rohrmoos (Germany)",
  "Rohrsdorf (Germany)",
  "Roigheim (Germany)",
  "Roklum (Germany)",
  "Rollbach (Germany)",
  "Rollshausen (Germany)",
  "Romhild (Germany)",
  "Rommerskirchen (Germany)",
  "Romrod (Germany)",
  "Romsthal (Germany)",
  "Rondeshagen (Germany)",
  "Ronneburg (Germany)",
  "Ronnenberg (Germany)",
  "Ronsberg (Germany)",
  "Rorichum (Germany)",
  "Rosbach vor der Hohe (Germany)",
  "Roschbach (Germany)",
  "Rosdorf (Germany)",
  "Roseburg (Germany)",
  "Rosenburg (Germany)",
  "Rosendahl (Germany)",
  "Rosenfeld (Germany)",
  "Rosengarten (Germany)",
  "Rosenheim (Germany)",
  "Rosenkopf (Germany)",
  "Rosenthal (Germany)",
  "Rositz (Germany)",
  "Roslau (Germany)",
  "Rosrath (Germany)",
  "Rossau (Germany)",
  "Rossdorf (Germany)",
  "Rosshaupten (Germany)",
  "Rosslau (Germany)",
  "Rosstal (Germany)",
  "Rosswein (Germany)",
  "Rostock (Germany)",
  "Rot am See (Germany)",
  "Rotenburg (Germany)",
  "Rotenburg an der Fulda (Germany)",
  "Rotenhain (Germany)",
  "Roth (Germany)",
  "Rotha (Germany)",
  "Rothenbach (Germany)",
  "Rothenbach an der Pegnitz (Germany)",
  "Rothenbuch (Germany)",
  "Rothenburg (Germany)",
  "Rothenfels (Germany)",
  "Rothenstein (Germany)",
  "Rothhausen (Germany)",
  "Rothlein (Germany)",
  "Rothof (Germany)",
  "Rott am Inn (Germany)",
  "Rottach (Germany)",
  "Rottach-Egern (Germany)",
  "Rottenacker (Germany)",
  "Rottenbach (Germany)",
  "Rottenbuch (Germany)",
  "Rottenburg (Germany)",
  "Rottenburg an der Laaber (Germany)",
  "Rottendorf (Germany)",
  "Rottenstuben (Germany)",
  "Rotterode (Germany)",
  "Rotthalmunster (Germany)",
  "Rottingen (Germany)",
  "Rottleberode (Germany)",
  "Rottweil (Germany)",
  "Rovershagen (Germany)",
  "Roxel (Germany)",
  "Rubke (Germany)",
  "Ruchheim (Germany)",
  "Ruchsen (Germany)",
  "Ruckeroth (Germany)",
  "Ruckersdorf (Germany)",
  "Ruckholz (Germany)",
  "Rudelzhausen (Germany)",
  "Ruderatshofen (Germany)",
  "Rudersberg (Germany)",
  "Rudersdorf (Germany)",
  "Ruderting (Germany)",
  "Rudesheim (Germany)",
  "Rudesheim am Rhein (Germany)",
  "Rudolstadt (Germany)",
  "Rugendorf (Germany)",
  "Rugheim (Germany)",
  "Rugland (Germany)",
  "Ruhen (Germany)",
  "Ruhla (Germany)",
  "Ruhland (Germany)",
  "Ruhpolding (Germany)",
  "Ruhstorf (Germany)",
  "Ruit (Germany)",
  "Rullstorf (Germany)",
  "Rulzheim (Germany)",
  "Rumeltshausen (Germany)",
  "Rummer (Germany)",
  "Rummingen (Germany)",
  "Rumohr (Germany)",
  "Runding (Germany)",
  "Runkel (Germany)",
  "Runthe (Germany)",
  "Ruppach-Goldhausen (Germany)",
  "Ruppertsberg (Germany)",
  "Ruppertshofen (Germany)",
  "Ruppertsweiler (Germany)",
  "Ruppichteroth (Germany)",
  "Ruschberg (Germany)",
  "Ruscheid (Germany)",
  "Ruspel (Germany)",
  "Rust (Germany)",
  "Rutesheim (Germany)",
  "Ruthen (Germany)",
  "Ruthnick (Germany)",
  "Rutsweiler an der Lauter (Germany)",
  "Saal (Germany)",
  "Saaldorf (Germany)",
  "Saalfeld (Germany)",
  "Saalstadt (Germany)",
  "Saara (Germany)",
  "Saarburg (Germany)",
  "Saarlouis (Germany)",
  "Saarwellingen (Germany)",
  "Sachsen (Germany)",
  "Sachsenhagen (Germany)",
  "Sachsenheim (Germany)",
  "Sachsenkam (Germany)",
  "Sack (Germany)",
  "Saerbeck (Germany)",
  "Saffig (Germany)",
  "Sagard (Germany)",
  "Sailauf (Germany)",
  "Saint Egidien (Germany)",
  "Saint Michaelisdonn (Germany)",
  "Salach (Germany)",
  "Salching (Germany)",
  "Saldenburg (Germany)",
  "Salem (Germany)",
  "Salgen (Germany)",
  "Salm (Germany)",
  "Salmtal (Germany)",
  "Salz (Germany)",
  "Salzbergen (Germany)",
  "Salzburg (Germany)",
  "Salzgitter (Germany)",
  "Salzgitter-Bad (Germany)",
  "Salzhausen (Germany)",
  "Salzhemmendorf (Germany)",
  "Salzkotten (Germany)",
  "Salzstetten (Germany)",
  "Salzwedel (Germany)",
  "Salzweg (Germany)",
  "Samerberg (Germany)",
  "Samern (Germany)",
  "Samswegen (Germany)",
  "Samtens (Germany)",
  "Sand (Germany)",
  "Sandberg (Germany)",
  "Sandbostel (Germany)",
  "Sande (Germany)",
  "Sandersdorf (Germany)",
  "Sandersleben (Germany)",
  "Sandesneben (Germany)",
  "Sandhausen (Germany)",
  "Sandkrug (Germany)",
  "Sangerhausen (Germany)",
  "Sankt Andreasberg (Germany)",
  "Sankt Augustin (Germany)",
  "Sankt Englmar (Germany)",
  "Sankt Georgen im Schwarzwald (Germany)",
  "Sankt Ingbert (Germany)",
  "Sankt Katharinen (Germany)",
  "Sankt Leon-Rot (Germany)",
  "Sankt Peter-Ording (Germany)",
  "Sankt Wendel (Germany)",
  "Sankt Wolfgang (Germany)",
  "Sanssouci (Germany)",
  "Sargenroth (Germany)",
  "Sarkwitz (Germany)",
  "Sarlhusen (Germany)",
  "Sarstedt (Germany)",
  "Sasbach (Germany)",
  "Sasbachwalden (Germany)",
  "Sassenberg (Germany)",
  "Sassenburg (Germany)",
  "Sassnitz (Germany)",
  "Satjendorf (Germany)",
  "Satrup (Germany)",
  "Satteldorf (Germany)",
  "Sattelstadt (Germany)",
  "Satzvey (Germany)",
  "Sauensiek (Germany)",
  "Sauerlach (Germany)",
  "Sauldorf (Germany)",
  "Saulgau (Germany)",
  "Schaafheim (Germany)",
  "Schaalby (Germany)",
  "Schacht-Audorf (Germany)",
  "Schackendorf (Germany)",
  "Schacksdorf (Germany)",
  "Schadeleben (Germany)",
  "Schafflund (Germany)",
  "Schafstedt (Germany)",
  "Schalkau (Germany)",
  "Schalksmuhle (Germany)",
  "Schallstadt (Germany)",
  "Schammelsdorf (Germany)",
  "Scharbeutz (Germany)",
  "Scharnebeck (Germany)",
  "Scharnhorst (Germany)",
  "Scharrel (Germany)",
  "Schauenstein (Germany)",
  "Schauerberg (Germany)",
  "Schaulings (Germany)",
  "Schaumburg (Germany)",
  "Schechen (Germany)",
  "Schechingen (Germany)",
  "Scheden (Germany)",
  "Scheer (Germany)",
  "Scheessel (Germany)",
  "Scheidegg (Germany)",
  "Scheinfeld (Germany)",
  "Schelklingen (Germany)",
  "Schellerten (Germany)",
  "Schellhorn (Germany)",
  "Schellweiler (Germany)",
  "Schenefeld (Germany)",
  "Schenkenhorst (Germany)",
  "Schenkenzell (Germany)",
  "Schenklengsfeld (Germany)",
  "Scherenbostel (Germany)",
  "Schermbeck (Germany)",
  "Schermen (Germany)",
  "Scherstetten (Germany)",
  "Schesslitz (Germany)",
  "Scheuerfeld (Germany)",
  "Scheuerhalden (Germany)",
  "Scheuring (Germany)",
  "Scheyern (Germany)",
  "Schieder-Schwalenberg (Germany)",
  "Schierling (Germany)",
  "Schiffdorf (Germany)",
  "Schifferstadt (Germany)",
  "Schiffweiler (Germany)",
  "Schildau (Germany)",
  "Schildow (Germany)",
  "Schillingsfurst (Germany)",
  "Schillingshof (Germany)",
  "Schiltach (Germany)",
  "Schiltberg (Germany)",
  "Schindhard (Germany)",
  "Schiphorst (Germany)",
  "Schirgiswalde (Germany)",
  "Schirmitz (Germany)",
  "Schirnding (Germany)",
  "Schkeuditz (Germany)",
  "Schkolen (Germany)",
  "Schkopau (Germany)",
  "Schladen (Germany)",
  "Schlaitdorf (Germany)",
  "Schlammersdorf (Germany)",
  "Schlangen (Germany)",
  "Schlangenbad (Germany)",
  "Schlanstedt (Germany)",
  "Schlat (Germany)",
  "Schleching (Germany)",
  "Schlegel (Germany)",
  "Schlehdorf (Germany)",
  "Schleid (Germany)",
  "Schleiden (Germany)",
  "Schleife (Germany)",
  "Schleiz (Germany)",
  "Schlema (Germany)",
  "Schlepzig (Germany)",
  "Schleswig (Germany)",
  "Schlettau (Germany)",
  "Schleusingen (Germany)",
  "Schlieben (Germany)",
  "Schliengen (Germany)",
  "Schlier (Germany)",
  "Schlierbach (Germany)",
  "Schliersee (Germany)",
  "Schlitz (Germany)",
  "Schloss (Germany)",
  "Schloss Holte-Stukenbrock (Germany)",
  "Schlossberg (Germany)",
  "Schlotfeld (Germany)",
  "Schlotheim (Germany)",
  "Schluchsee (Germany)",
  "Schluchtern (Germany)",
  "Schlusselfeld (Germany)",
  "Schmalenberg (Germany)",
  "Schmalensee (Germany)",
  "Schmalkalden (Germany)",
  "Schmallenberg (Germany)",
  "Schmelz (Germany)",
  "Schmerz (Germany)",
  "Schmidgaden (Germany)",
  "Schmidmuhlen (Germany)",
  "Schmidt (Germany)",
  "Schmidthachenbach (Germany)",
  "Schmiechen (Germany)",
  "Schmiedefeld am Rennsteig (Germany)",
  "Schmilau (Germany)",
  "Schmitten (Germany)",
  "Schmittweiler (Germany)",
  "Schmolln (Germany)",
  "Schnabelwaid (Germany)",
  "Schnackenburg (Germany)",
  "Schnaitsee (Germany)",
  "Schnaittach (Germany)",
  "Schnaittenbach (Germany)",
  "Schneckenhausen (Germany)",
  "Schnee (Germany)",
  "Schneeberg (Germany)",
  "Schnega (Germany)",
  "Schneizlreuth (Germany)",
  "Schnellbach (Germany)",
  "Schnelldorf (Germany)",
  "Schnellmannshausen (Germany)",
  "Schneppenbach (Germany)",
  "Schneverdingen (Germany)",
  "Schney (Germany)",
  "Schnurpflingen (Germany)",
  "Schoeffengrund (Germany)",
  "Schoenberg (Germany)",
  "Schoenheide (Germany)",
  "Schollbrunn (Germany)",
  "Schollene (Germany)",
  "Schollkrippen (Germany)",
  "Schollnach (Germany)",
  "Schomberg (Germany)",
  "Schonach im Schwarzwald (Germany)",
  "Schonaich (Germany)",
  "Schonau (Germany)",
  "Schonau im Schwarzwald (Germany)",
  "Schonberg (Germany)",
  "Schonborn (Germany)",
  "Schonbrunn (Germany)",
  "Schondorf am Ammersee (Germany)",
  "Schondra (Germany)",
  "Schonebeck (Germany)",
  "Schoneberg (Germany)",
  "Schoneck (Germany)",
  "Schonecken (Germany)",
  "Schonefeld (Germany)",
  "Schoneiche (Germany)",
  "Schonenberg (Germany)",
  "Schonenberg-Kubelberg (Germany)",
  "Schonewalde (Germany)",
  "Schoneworde (Germany)",
  "Schonfeld (Germany)",
  "Schonfliess (Germany)",
  "Schongau (Germany)",
  "Schongeising (Germany)",
  "Schoningen (Germany)",
  "Schonkirchen (Germany)",
  "Schonsee (Germany)",
  "Schonstett (Germany)",
  "Schonthal (Germany)",
  "Schonungen (Germany)",
  "Schonwald (Germany)",
  "Schonwald im Schwarzwald (Germany)",
  "Schonwalde (Germany)",
  "Schopfheim (Germany)",
  "Schopfloch (Germany)",
  "Schoppenstedt (Germany)",
  "Schoppingen (Germany)",
  "Schopsdorf (Germany)",
  "Schorndorf (Germany)",
  "Schornsheim (Germany)",
  "Schortens (Germany)",
  "Schossin (Germany)",
  "Schotten (Germany)",
  "Schramberg (Germany)",
  "Schriesheim (Germany)",
  "Schrobenhausen (Germany)",
  "Schrozberg (Germany)",
  "Schuby (Germany)",
  "Schuld (Germany)",
  "Schulendorf (Germany)",
  "Schulldorf (Germany)",
  "Schuller (Germany)",
  "Schulzendorf (Germany)",
  "Schuttertal (Germany)",
  "Schutterwald (Germany)",
  "Schuttorf (Germany)",
  "Schutz (Germany)",
  "Schutzbach (Germany)",
  "Schutzenhof (Germany)",
  "Schwaan (Germany)",
  "Schwabach (Germany)",
  "Schwaben (Germany)",
  "Schwabenheim (Germany)",
  "Schwabhausen (Germany)",
  "Schwabmunchen (Germany)",
  "Schwabniederhofen (Germany)",
  "Schwabstedt (Germany)",
  "Schwaibach (Germany)",
  "Schwaig (Germany)",
  "Schwaigern (Germany)",
  "Schwaikheim (Germany)",
  "Schwalbach (Germany)",
  "Schwalbach am Taunus (Germany)",
  "Schwalingen (Germany)",
  "Schwallungen (Germany)",
  "Schwalmstadt (Germany)",
  "Schwalmtal (Germany)",
  "Schwandorf in Bayern (Germany)",
  "Schwanebeck (Germany)",
  "Schwaneberg (Germany)",
  "Schwanewede (Germany)",
  "Schwanfeld (Germany)",
  "Schwangau (Germany)",
  "Schwansee (Germany)",
  "Schwarme (Germany)",
  "Schwarmstedt (Germany)",
  "Schwarz (Germany)",
  "Schwarzach (Germany)",
  "Schwarzbach (Germany)",
  "Schwarzenbach (Germany)",
  "Schwarzenbach am Wald (Germany)",
  "Schwarzenbach an der Saale (Germany)",
  "Schwarzenbek (Germany)",
  "Schwarzenberg (Germany)",
  "Schwarzenborn (Germany)",
  "Schwarzenbruck (Germany)",
  "Schwarzenfeld (Germany)",
  "Schwarzhausen (Germany)",
  "Schwarzheide (Germany)",
  "Schwarzholz (Germany)",
  "Schwarzwald (Germany)",
  "Schwebheim (Germany)",
  "Schwedelbach (Germany)",
  "Schwedt (Oder) (Germany)",
  "Schwegenheim (Germany)",
  "Schweiburg (Germany)",
  "Schweich (Germany)",
  "Schweigen-Rechtenbach (Germany)",
  "Schweinfurt (Germany)",
  "Schweitenkirchen (Germany)",
  "Schweizerhof (Germany)",
  "Schwelm (Germany)",
  "Schwendi (Germany)",
  "Schwenningen (Germany)",
  "Schwerin (Germany)",
  "Schweringen (Germany)",
  "Schwerstedt (Germany)",
  "Schwerte (Germany)",
  "Schwesing (Germany)",
  "Schwetzingen (Germany)",
  "Schwieberdingen (Germany)",
  "Schwienau (Germany)",
  "Schwifting (Germany)",
  "Schwindegg (Germany)",
  "Schwirzheim (Germany)",
  "Schwollen (Germany)",
  "Schworstadt (Germany)",
  "Sebnitz (Germany)",
  "Seck (Germany)",
  "Seckach (Germany)",
  "Seebach (Germany)",
  "Seebad Ahlbeck (Germany)",
  "Seebad Bansin (Germany)",
  "Seebad Heringsdorf (Germany)",
  "Seebergen (Germany)",
  "Seebruck (Germany)",
  "Seeburg (Germany)",
  "Seedorf (Germany)",
  "Seefeld (Germany)",
  "Seeg (Germany)",
  "Seega (Germany)",
  "Seehaus (Germany)",
  "Seehausen (Germany)",
  "Seeheilbad Graal-Muritz (Germany)",
  "Seeheim-Jugenheim (Germany)",
  "Seehof (Germany)",
  "Seeland (Germany)",
  "Seelbach (Germany)",
  "Seelitz (Germany)",
  "Seelow (Germany)",
  "Seelscheid (Germany)",
  "Seelze (Germany)",
  "Seeon-Seebruck (Germany)",
  "Seesbach (Germany)",
  "Seesen (Germany)",
  "Seeshaupt (Germany)",
  "Seester (Germany)",
  "Seevetal (Germany)",
  "Seffern (Germany)",
  "Sefferweich (Germany)",
  "Sehlde (Germany)",
  "Sehlem (Germany)",
  "Sehlen (Germany)",
  "Sehma (Germany)",
  "Sehnde (Germany)",
  "Seifhennersdorf (Germany)",
  "Selb (Germany)",
  "Selbach (Germany)",
  "Selbitz (Germany)",
  "Selent (Germany)",
  "Seligenstadt (Germany)",
  "Seligenthal (Germany)",
  "Sellerich (Germany)",
  "Sellin (Germany)",
  "Selm (Germany)",
  "Selmsdorf (Germany)",
  "Selsingen (Germany)",
  "Selters (Germany)",
  "Selzen (Germany)",
  "Sembach (Germany)",
  "Senden (Germany)",
  "Sendenhorst (Germany)",
  "Senftenberg (Germany)",
  "Sengenthal (Germany)",
  "Sennfeld (Germany)",
  "Sensweiler (Germany)",
  "Senzig (Germany)",
  "Sereetz (Germany)",
  "Serrig (Germany)",
  "Sersheim (Germany)",
  "Sesslach (Germany)",
  "Seth (Germany)",
  "Seubersdorf (Germany)",
  "Seukendorf (Germany)",
  "Sexau (Germany)",
  "Sibbesse (Germany)",
  "Sickte (Germany)",
  "Siebenbaumen (Germany)",
  "Siedenbrunzow (Germany)",
  "Siedenburg (Germany)",
  "Siegburg (Germany)",
  "Siegelsbach (Germany)",
  "Siegen (Germany)",
  "Siegenburg (Germany)",
  "Siegsdorf (Germany)",
  "Sielenbach (Germany)",
  "Sierksdorf (Germany)",
  "Siershahn (Germany)",
  "Siesbach (Germany)",
  "Sievershutten (Germany)",
  "Sieverstedt (Germany)",
  "Sigmaringen (Germany)",
  "Sigmaringendorf (Germany)",
  "Sigmarszell (Germany)",
  "Silling (Germany)",
  "Simbach (Germany)",
  "Simbach am Inn (Germany)",
  "Simmelsdorf (Germany)",
  "Simmerath (Germany)",
  "Simmern (Germany)",
  "Simmersfeld (Germany)",
  "Simmertal (Germany)",
  "Simonsberg (Germany)",
  "Sindelfingen (Germany)",
  "Sindorf (Germany)",
  "Sindringen (Germany)",
  "Singen (Germany)",
  "Singenbach (Germany)",
  "Singhofen (Germany)",
  "Sinn (Germany)",
  "Sinsheim (Germany)",
  "Sinzheim (Germany)",
  "Sinzig (Germany)",
  "Sinzing (Germany)",
  "Sippersfeld (Germany)",
  "Sipplingen (Germany)",
  "Sittensen (Germany)",
  "Sochtenau (Germany)",
  "Soemmerda (Germany)",
  "Soest (Germany)",
  "Sogel (Germany)",
  "Sohland (Germany)",
  "Sohlde (Germany)",
  "Sohren (Germany)",
  "Solden (Germany)",
  "Solingen (Germany)",
  "Solkwitz (Germany)",
  "Soller (Germany)",
  "Sollerup (Germany)",
  "Sollstedt (Germany)",
  "Solms (Germany)",
  "Soltau (Germany)",
  "Soltendieck (Germany)",
  "Somborn (Germany)",
  "Sommerland (Germany)",
  "Sommerloch (Germany)",
  "Sondershausen (Germany)",
  "Sondheim (Germany)",
  "Sonneberg (Germany)",
  "Sonnefeld (Germany)",
  "Sonnschied (Germany)",
  "Sonsbeck (Germany)",
  "Sontheim (Germany)",
  "Sontheim an der Brenz (Germany)",
  "Sonthofen (Germany)",
  "Sontra (Germany)",
  "Sorth (Germany)",
  "Sorup (Germany)",
  "Sosa (Germany)",
  "Sotterhausen (Germany)",
  "Sottrum (Germany)",
  "Soyen (Germany)",
  "Spaichingen (Germany)",
  "Spalt (Germany)",
  "Spangdahlem (Germany)",
  "Spangenberg (Germany)",
  "Spardorf (Germany)",
  "Spatzenhausen (Germany)",
  "Spay (Germany)",
  "Spechtsbrunn (Germany)",
  "Spechtshausen (Germany)",
  "Speicher (Germany)",
  "Speichersdorf (Germany)",
  "Speinshart (Germany)",
  "Spelle (Germany)",
  "Spenge (Germany)",
  "Spessart (Germany)",
  "Spetzerfehn (Germany)",
  "Speyer (Germany)",
  "Spiegelau (Germany)",
  "Spiegelberg (Germany)",
  "Spiegelsberge (Germany)",
  "Spielberg (Germany)",
  "Spiesen-Elversberg (Germany)",
  "Spitzkunnersdorf (Germany)",
  "Splietsdorf (Germany)",
  "Sponheim (Germany)",
  "Spora (Germany)",
  "Spornitz (Germany)",
  "Spraitbach (Germany)",
  "Spree (Germany)",
  "Spreenhagen (Germany)",
  "Spremberg (Germany)",
  "Sprendlingen (Germany)",
  "Sprengel (Germany)",
  "Springe (Germany)",
  "Springstille (Germany)",
  "Sprockhovel (Germany)",
  "Stabelow (Germany)",
  "Stade (Germany)",
  "Stadecken-Elsheim (Germany)",
  "Stadelhofen (Germany)",
  "Stadt (Germany)",
  "Stadt Wehlen (Germany)",
  "Stadtallendorf (Germany)",
  "Stadtbergen (Germany)",
  "Stadthagen (Germany)",
  "Stadtilm (Germany)",
  "Stadtkyll (Germany)",
  "Stadtlauringen (Germany)",
  "Stadtlohn (Germany)",
  "Stadtoldendorf (Germany)",
  "Stadtprozelten (Germany)",
  "Stadtroda (Germany)",
  "Stadtsteinach (Germany)",
  "Stahl (Germany)",
  "Stahlhofen (Germany)",
  "Stahnsdorf (Germany)",
  "Staig (Germany)",
  "Stammbach (Germany)",
  "Stammheim (Germany)",
  "Stamsried (Germany)",
  "Stangerode (Germany)",
  "Stapelburg (Germany)",
  "Stapelfeld (Germany)",
  "Starnberg (Germany)",
  "Stassfurt (Germany)",
  "Stauchitz (Germany)",
  "Staudt (Germany)",
  "Staufen im Breisgau (Germany)",
  "Staufenberg (Germany)",
  "Stegaurach (Germany)",
  "Stegen (Germany)",
  "Steglitz Bezirk (Germany)",
  "Steigra (Germany)",
  "Steimbke (Germany)",
  "Steimel (Germany)",
  "Stein (Germany)",
  "Stein an der Traun (Germany)",
  "Stein-Neukirch (Germany)",
  "Steinach (Germany)",
  "Steinau an der Strasse (Germany)",
  "Steinbach am Taunus (Germany)",
  "Steinburg (Germany)",
  "Steineberg (Germany)",
  "Steinegg (Germany)",
  "Steinen (Germany)",
  "Steinenbronn (Germany)",
  "Steineroth (Germany)",
  "Steinfeld (Germany)",
  "Steinfurt (Germany)",
  "Steingaden (Germany)",
  "Steinhagen (Germany)",
  "Steinhausen (Germany)",
  "Steinheim (Germany)",
  "Steinheim am Albuch (Germany)",
  "Steinheim am der Murr (Germany)",
  "Steinhoring (Germany)",
  "Steinhorst (Germany)",
  "Steinigtwolmsdorf (Germany)",
  "Steinlah (Germany)",
  "Steinmauern (Germany)",
  "Steintoch (Germany)",
  "Steinweiler (Germany)",
  "Steinwenden (Germany)",
  "Steinwiesen (Germany)",
  "Steisslingen (Germany)",
  "Stelle (Germany)",
  "Stellshagen (Germany)",
  "Stelzenberg (Germany)",
  "Stendal (Germany)",
  "Stepfershausen (Germany)",
  "Stephanskirchen (Germany)",
  "Sterbfritz (Germany)",
  "Sternberg (Germany)",
  "Sternenfels (Germany)",
  "Sterup (Germany)",
  "Sterzhausen (Germany)",
  "Stetten (Germany)",
  "Stettfeld (Germany)",
  "Steuden (Germany)",
  "Steudnitz (Germany)",
  "Steyerberg (Germany)",
  "Stiege (Germany)",
  "Stimpfach (Germany)",
  "Stinstedt (Germany)",
  "Stockach (Germany)",
  "Stockelsdorf (Germany)",
  "Stockhausen (Germany)",
  "Stockhausen-Illfurth (Germany)",
  "Stockheim (Germany)",
  "Stockse (Germany)",
  "Stocksee (Germany)",
  "Stockstadt am Main (Germany)",
  "Stockstadt am Rhein (Germany)",
  "Stoetze (Germany)",
  "Stolberg (Germany)",
  "Stolk (Germany)",
  "Stollberg (Germany)",
  "Stolpen (Germany)",
  "Stolzenau (Germany)",
  "Stolzenhagen (Germany)",
  "Storkow (Germany)",
  "Stornstein (Germany)",
  "Stotten (Germany)",
  "Stotternheim (Germany)",
  "Stottwang (Germany)",
  "Straelen (Germany)",
  "Strahlungen (Germany)",
  "Stralsund (Germany)",
  "Strande (Germany)",
  "Strassberg (Germany)",
  "Strasskirchen (Germany)",
  "Strasslach-Dingharting (Germany)",
  "Straubing (Germany)",
  "Straupitz (Germany)",
  "Strausberg (Germany)",
  "Straussfurt (Germany)",
  "Streich (Germany)",
  "Streithausen (Germany)",
  "Strickscheid (Germany)",
  "Strohkirchen (Germany)",
  "Strohn (Germany)",
  "Stromberg (Germany)",
  "Strucklingen (Germany)",
  "Strullendorf (Germany)",
  "Struxdorf (Germany)",
  "Stubenberg (Germany)",
  "Stuhlingen (Germany)",
  "Stuhr (Germany)",
  "Stukenbrock (Germany)",
  "Stulln (Germany)",
  "Stuttgart (Germany)",
  "Stutzengrun (Germany)",
  "Stutzerbach (Germany)",
  "Styrum (Germany)",
  "Suckow (Germany)",
  "Suddendorf (Germany)",
  "Suderau (Germany)",
  "Suderbrarup (Germany)",
  "Suderburg (Germany)",
  "Suderholz (Germany)",
  "Suderlugum (Germany)",
  "Suderstapel (Germany)",
  "Sudheide (Germany)",
  "Sudlohn (Germany)",
  "Sugenheim (Germany)",
  "Suhl (Germany)",
  "Suhlendorf (Germany)",
  "Sulfeld (Germany)",
  "Sulingen (Germany)",
  "Sulz am Neckar (Germany)",
  "Sulzbach (Germany)",
  "Sulzbach am Main (Germany)",
  "Sulzbach-Rosenberg (Germany)",
  "Sulzberg (Germany)",
  "Sulzemoos (Germany)",
  "Sulzfeld (Germany)",
  "Sulzgries (Germany)",
  "Sulzheim (Germany)",
  "Sulzthal (Germany)",
  "Sunching (Germany)",
  "Sundern (Germany)",
  "Sundhausen (Germany)",
  "Supplingen (Germany)",
  "Surheim (Germany)",
  "Susel (Germany)",
  "Sussen (Germany)",
  "Syke (Germany)",
  "Syrau (Germany)",
  "Taarstedt (Germany)",
  "Tabarz (Germany)",
  "Tacherting (Germany)",
  "Taching am See (Germany)",
  "Tailfingen (Germany)",
  "Talheim (Germany)",
  "Talkau (Germany)",
  "Tamm (Germany)",
  "Tangendorf (Germany)",
  "Tangerhutte (Germany)",
  "Tangermunde (Germany)",
  "Tangstedt (Germany)",
  "Tann (Germany)",
  "Tanna (Germany)",
  "Tannesberg (Germany)",
  "Tannhausen (Germany)",
  "Tantow (Germany)",
  "Tapfheim (Germany)",
  "Tappenbeck (Germany)",
  "Tarbek (Germany)",
  "Tarmow (Germany)",
  "Tarmstedt (Germany)",
  "Tarp (Germany)",
  "Tating (Germany)",
  "Tauberbischofsheim (Germany)",
  "Tauberfeld (Germany)",
  "Tauberrettersheim (Germany)",
  "Taucha (Germany)",
  "Tauche (Germany)",
  "Taufkirchen (Germany)",
  "Taunusstein (Germany)",
  "Taura (Germany)",
  "Tawern (Germany)",
  "Tecklenburg (Germany)",
  "Tegernheim (Germany)",
  "Tegernsee (Germany)",
  "Teichwolframsdorf (Germany)",
  "Teisendorf (Germany)",
  "Teising (Germany)",
  "Teisnach (Germany)",
  "Teistungenburg (Germany)",
  "Telgte (Germany)",
  "Tellingstedt (Germany)",
  "Teltow (Germany)",
  "Temmels (Germany)",
  "Templin (Germany)",
  "Tengen (Germany)",
  "Teningen (Germany)",
  "Tennenbronn (Germany)",
  "Terborg (Germany)",
  "Teschow (Germany)",
  "Tespe (Germany)",
  "Tessin (Germany)",
  "Tetenbull (Germany)",
  "Tetenhusen (Germany)",
  "Teterow (Germany)",
  "Tettau (Germany)",
  "Tettenwang (Germany)",
  "Tettnang Castle (Germany)",
  "Teublitz (Germany)",
  "Teuchern (Germany)",
  "Teugn (Germany)",
  "Teuschnitz (Germany)",
  "Teutschenthal (Germany)",
  "Thaden (Germany)",
  "Thale (Germany)",
  "Thaleischweiler-Froschen (Germany)",
  "Thalfang (Germany)",
  "Thalhausen (Germany)",
  "Thalheim (Germany)",
  "Thallichtenberg (Germany)",
  "Thallwitz (Germany)",
  "Thalmassing (Germany)",
  "Thalwenden (Germany)",
  "Thannberg (Germany)",
  "Thannhausen (Germany)",
  "Tharandt (Germany)",
  "Thedinghausen (Germany)",
  "Theilenhofen (Germany)",
  "Theilheim (Germany)",
  "Theissen (Germany)",
  "Theley (Germany)",
  "Themar (Germany)",
  "Thermalbad Wiesenbad (Germany)",
  "Thiendorf (Germany)",
  "Thierhaupten (Germany)",
  "Thierstein (Germany)",
  "Tholey (Germany)",
  "Thoma (Germany)",
  "Thomasburg (Germany)",
  "Thomm (Germany)",
  "Thuine (Germany)",
  "Thulba (Germany)",
  "Thum (Germany)",
  "Thungen (Germany)",
  "Thungersheim (Germany)",
  "Thur (Germany)",
  "Thurkow (Germany)",
  "Thurnau (Germany)",
  "Thyrnau (Germany)",
  "Thyrow (Germany)",
  "Tiddische (Germany)",
  "Tiefenbach (Germany)",
  "Tiefenbronn (Germany)",
  "Tiefenort (Germany)",
  "Tiefenthal (Germany)",
  "Tieplitz (Germany)",
  "Tieringen (Germany)",
  "Tilleda (Germany)",
  "Timmaspe (Germany)",
  "Timmendorf (Germany)",
  "Timmendorf Beach (Germany)",
  "Timmenrode (Germany)",
  "Tinnum (Germany)",
  "Tirschenreuth (Germany)",
  "Titisee-Neustadt (Germany)",
  "Titting (Germany)",
  "Tittling (Germany)",
  "Tittmoning (Germany)",
  "Titz (Germany)",
  "Tobertitz (Germany)",
  "Todenbuttel (Germany)",
  "Todendorf (Germany)",
  "Todesfelde (Germany)",
  "Todtenried (Germany)",
  "Todtenweis (Germany)",
  "Todtmoos (Germany)",
  "Todtnau (Germany)",
  "Todtnauberg (Germany)",
  "Toging am Inn (Germany)",
  "Tomerdingen (Germany)",
  "Tonbach (Germany)",
  "Tonisvorst (Germany)",
  "Tonndorf (Germany)",
  "Tonning (Germany)",
  "Topen (Germany)",
  "Toppenstedt (Germany)",
  "Torgau (Germany)",
  "Torgelow (Germany)",
  "Tornesch (Germany)",
  "Tostedt (Germany)",
  "Traben-Trarbach (Germany)",
  "Trabitz (Germany)",
  "Traisen (Germany)",
  "Traitsching (Germany)",
  "Trappenkamp (Germany)",
  "Trassem (Germany)",
  "Traubing (Germany)",
  "Traunreut (Germany)",
  "Traunstein (Germany)",
  "Traunwalchen (Germany)",
  "Trautskirchen (Germany)",
  "Trebbin (Germany)",
  "Treben (Germany)",
  "Trebendorf (Germany)",
  "Trebsen (Germany)",
  "Trebur (Germany)",
  "Treffelhausen (Germany)",
  "Treffelstein (Germany)",
  "Treia (Germany)",
  "Treis-Karden (Germany)",
  "Tremsbuttel (Germany)",
  "Trendelburg (Germany)",
  "Trent (Germany)",
  "Treuchtlingen (Germany)",
  "Treudelberg (Germany)",
  "Treuen (Germany)",
  "Treuenbrietzen (Germany)",
  "Triberg im Schwarzwald (Germany)",
  "Trier (Germany)",
  "Trierweiler (Germany)",
  "Triftern (Germany)",
  "Trimbs (Germany)",
  "Trippstadt (Germany)",
  "Triptis (Germany)",
  "Trittau (Germany)",
  "Trochtelfingen (Germany)",
  "Trogen (Germany)",
  "Troisdorf (Germany)",
  "Trollenhagen (Germany)",
  "Trossingen (Germany)",
  "Trostau (Germany)",
  "Trostberg an der Alz (Germany)",
  "Truchtlaching (Germany)",
  "Trulben (Germany)",
  "Trunkelsberg (Germany)",
  "Trusetal (Germany)",
  "Tschernitz (Germany)",
  "Tuerkenfeld (Germany)",
  "Tulau (Germany)",
  "Tuningen (Germany)",
  "Tuntenhausen (Germany)",
  "Turkheim (Germany)",
  "Tussenhausen (Germany)",
  "Tuszshing (Germany)",
  "Tutow (Germany)",
  "Tuttlingen (Germany)",
  "Tutzing (Germany)",
  "Tutzpatz (Germany)",
  "Twielenfleth (Germany)",
  "Twist (Germany)",
  "Twiste (Germany)",
  "Twistringen (Germany)",
  "Ubach-Palenberg (Germany)",
  "Uberfeld (Germany)",
  "Uberherrn (Germany)",
  "Uberlingen (Germany)",
  "Ubersee (Germany)",
  "Ubstadt-Weiher (Germany)",
  "Uchte (Germany)",
  "Uchtelhausen (Germany)",
  "Uckendorf (Germany)",
  "Udenheim (Germany)",
  "Uder (Germany)",
  "Uebigau (Germany)",
  "Ueckermunde (Germany)",
  "Uedem (Germany)",
  "Uehlfeld (Germany)",
  "Uelitz (Germany)",
  "Uelsen (Germany)",
  "Uelvesbull (Germany)",
  "Uelzen (Germany)",
  "Uersfeld (Germany)",
  "Uess (Germany)",
  "Ueterlande (Germany)",
  "Uetersen (Germany)",
  "Uettingen (Germany)",
  "Uetze (Germany)",
  "Uffenheim (Germany)",
  "Uffing (Germany)",
  "Uhingen (Germany)",
  "Uhldingen-Muhlhofen (Germany)",
  "Uhler (Germany)",
  "Uhlingen-Birkendorf (Germany)",
  "Ulbersdorf (Germany)",
  "Ulm (Germany)",
  "Ulmen (Germany)",
  "Ulmet (Germany)",
  "Ulrichstein (Germany)",
  "Ulsnis (Germany)",
  "Umkirch (Germany)",
  "Ummendorf (Germany)",
  "Umpfenbach (Germany)",
  "Unkel (Germany)",
  "Unlingen (Germany)",
  "Unna (Germany)",
  "Unnau (Germany)",
  "Unterammergau (Germany)",
  "Unterbaldingen (Germany)",
  "Unterdiessen (Germany)",
  "Unterdietfurt (Germany)",
  "Untereglfing (Germany)",
  "Untereisesheim (Germany)",
  "Unterensingen (Germany)",
  "Untereuerheim (Germany)",
  "Unterfoehring (Germany)",
  "Untergimpern (Germany)",
  "Untergriesbach (Germany)",
  "Untergruppenbach (Germany)",
  "Unterhaching (Germany)",
  "Unterhausen (Germany)",
  "Unterkirnach (Germany)",
  "Unterliezheim (Germany)",
  "Unterluss (Germany)",
  "Untermeitingen (Germany)",
  "Untermunkheim (Germany)",
  "Unterneukirchen (Germany)",
  "Unterpleichfeld (Germany)",
  "Unterreichenbach (Germany)",
  "Unterreit (Germany)",
  "Unterroth (Germany)",
  "Unterschleichach (Germany)",
  "Unterschleissheim (Germany)",
  "Unterschneidheim (Germany)",
  "Untersiemau (Germany)",
  "Untersteinach (Germany)",
  "Unterthingau (Germany)",
  "Unteruhldingen (Germany)",
  "Unterwellenborn (Germany)",
  "Unterwossen (Germany)",
  "Untrasried (Germany)",
  "Upgant-Schott (Germany)",
  "Urbach (Germany)",
  "Urbar (Germany)",
  "Urexweiler (Germany)",
  "Urmitz (Germany)",
  "Ursensollen (Germany)",
  "Urspringen (Germany)",
  "Urweiler (Germany)",
  "Urzig (Germany)",
  "Usedom (Germany)",
  "Usingen (Germany)",
  "Uslar (Germany)",
  "Ustersbach (Germany)",
  "Uttenreuth (Germany)",
  "Uttenweiler (Germany)",
  "Utting (Germany)",
  "Utzenfeld (Germany)",
  "Uxheim (Germany)",
  "Vacha (Germany)",
  "Vachendorf (Germany)",
  "Vahlbruch (Germany)",
  "Vahldorf (Germany)",
  "Vaihingen an der Enz (Germany)",
  "Vallendar (Germany)",
  "Valley (Germany)",
  "Varel (Germany)",
  "Vastorf (Germany)",
  "Vaterstetten (Germany)",
  "Vechelde (Germany)",
  "Vechta (Germany)",
  "Veckenstedt (Germany)",
  "Vegesack (Germany)",
  "Vehlow (Germany)",
  "Veilsdorf (Germany)",
  "Veitsbronn (Germany)",
  "Veitshochheim (Germany)",
  "Velbert (Germany)",
  "Velburg (Germany)",
  "Velden (Germany)",
  "Veldenz (Germany)",
  "Velen (Germany)",
  "Vellahn (Germany)",
  "Vellberg (Germany)",
  "Vellmar (Germany)",
  "Velpke (Germany)",
  "Velten (Germany)",
  "Veltheim (Germany)",
  "Vendersheim (Germany)",
  "Venne (Germany)",
  "Verchen (Germany)",
  "Verden an der Aller (Germany)",
  "Veringendorf (Germany)",
  "Veringenstadt (Germany)",
  "Verl (Germany)",
  "Versmold (Germany)",
  "Vesser (Germany)",
  "Vestenberg (Germany)",
  "Vestenbergsgreuth (Germany)",
  "Vetschau (Germany)",
  "Vettelschoss (Germany)",
  "Vettweiss (Germany)",
  "Viecheln (Germany)",
  "Viechtach (Germany)",
  "Vielbach (Germany)",
  "Vienenburg (Germany)",
  "Vier (Germany)",
  "Vierden (Germany)",
  "Viereth-Trunstadt (Germany)",
  "Vierhofen (Germany)",
  "Vierkirchen (Germany)",
  "Viernau (Germany)",
  "Viernheim (Germany)",
  "Viersen (Germany)",
  "Vieselbach (Germany)",
  "Vietgest (Germany)",
  "Villenbach (Germany)",
  "Villingen (Germany)",
  "Villingen-Schwenningen (Germany)",
  "Villingendorf (Germany)",
  "Villmar (Germany)",
  "Vilsbiburg (Germany)",
  "Vilseck (Germany)",
  "Vilsheim (Germany)",
  "Vilshofen (Germany)",
  "Vilshoven (Germany)",
  "Visbek (Germany)",
  "Visselhovede (Germany)",
  "Vitte (Germany)",
  "Vlotho (Germany)",
  "Vluyn (Germany)",
  "Voerde (Germany)",
  "Vogelsdorf (Germany)",
  "Vogelsen (Germany)",
  "Vogt (Germany)",
  "Vogtareuth (Germany)",
  "Vogtsburg (Germany)",
  "Vohburg an der Donau (Germany)",
  "Vohenstrauss (Germany)",
  "Vohl (Germany)",
  "Vohrenbach (Germany)",
  "Vohringen (Germany)",
  "Volkach (Germany)",
  "Volkenschwand (Germany)",
  "Volkenshagen (Germany)",
  "Volkersweiler (Germany)",
  "Volkertshausen (Germany)",
  "Volklingen (Germany)",
  "Volkmarsen (Germany)",
  "Vollersode (Germany)",
  "Vollersroda (Germany)",
  "Vollkofen (Germany)",
  "Vollrathsruhe (Germany)",
  "Voltlage (Germany)",
  "Volxheim (Germany)",
  "Vorbach (Germany)",
  "Vorden (Germany)",
  "Vordorf (Germany)",
  "Vorland (Germany)",
  "Vorra (Germany)",
  "Vorstetten (Germany)",
  "Vorwerk (Germany)",
  "Voslapp (Germany)",
  "Vreden (Germany)",
  "Waakirchen (Germany)",
  "Waal (Germany)",
  "Wabern (Germany)",
  "Wachau (Germany)",
  "Wachenheim an der Weinstrasse (Germany)",
  "Wachenroth (Germany)",
  "Wachsenburg (Germany)",
  "Wachtberg (Germany)",
  "Wachtendonk (Germany)",
  "Wachtersbach (Germany)",
  "Wachtum (Germany)",
  "Wacken (Germany)",
  "Wackernheim (Germany)",
  "Wackerow (Germany)",
  "Wackersdorf (Germany)",
  "Wadelsdorf (Germany)",
  "Wadern (Germany)",
  "Wadersloh (Germany)",
  "Wadgassen (Germany)",
  "Waffenbrunn (Germany)",
  "Wagenfeld (Germany)",
  "Wagenhoff (Germany)",
  "Wagersrott (Germany)",
  "Waghausel (Germany)",
  "Waging am See (Germany)",
  "Wahlstedt (Germany)",
  "Wahnwegen (Germany)",
  "Wahrenholz (Germany)",
  "Waiblingen (Germany)",
  "Waibstadt (Germany)",
  "Waidhaus (Germany)",
  "Wain (Germany)",
  "Waischenfeld (Germany)",
  "Wakendorf (Germany)",
  "Walchum (Germany)",
  "Wald (Germany)",
  "Wald-Michelbach (Germany)",
  "Waldalgesheim (Germany)",
  "Waldaschaff (Germany)",
  "Waldau (Germany)",
  "Waldbockelheim (Germany)",
  "Waldbreitbach (Germany)",
  "Waldbrol (Germany)",
  "Waldbrunn (Germany)",
  "Waldburg (Germany)",
  "Waldbuttelbrunn (Germany)",
  "Waldeck (Germany)",
  "Waldems (Germany)",
  "Waldenbuch (Germany)",
  "Waldenburg (Germany)",
  "Walderbach (Germany)",
  "Waldershof (Germany)",
  "Waldesch (Germany)",
  "Waldfeucht (Germany)",
  "Waldfischbach-Burgalben (Germany)",
  "Waldhambach (Germany)",
  "Waldheim (Germany)",
  "Waldkappel (Germany)",
  "Waldkirch (Germany)",
  "Waldkirchen (Germany)",
  "Waldkraiburg (Germany)",
  "Waldlaubersheim (Germany)",
  "Waldmohr (Germany)",
  "Waldmunchen (Germany)",
  "Waldorf (Germany)",
  "Waldsassen (Germany)",
  "Waldschlosschen (Germany)",
  "Waldsee (Germany)",
  "Waldshut (Germany)",
  "Waldshut-Tiengen (Germany)",
  "Waldsieversdorf (Germany)",
  "Waldstetten (Germany)",
  "Walhausen (Germany)",
  "Walheim (Germany)",
  "Walkenried (Germany)",
  "Wall (Germany)",
  "Walldorf (Germany)",
  "Walldurn (Germany)",
  "Walle (Germany)",
  "Wallendorf (Germany)",
  "Wallenfels (Germany)",
  "Wallenhorst (Germany)",
  "Wallerfangen (Germany)",
  "Wallersdorf (Germany)",
  "Wallerstein (Germany)",
  "Wallertheim (Germany)",
  "Walleshausen (Germany)",
  "Wallhausen (Germany)",
  "Wallmerod (Germany)",
  "Walluf (Germany)",
  "Walpertshofen (Germany)",
  "Walpertskirchen (Germany)",
  "Walsdorf (Germany)",
  "Walshausen (Germany)",
  "Walsleben (Germany)",
  "Walsrode (Germany)",
  "Waltenhofen (Germany)",
  "Waltersdorf (Germany)",
  "Waltershausen (Germany)",
  "Walting (Germany)",
  "Waltrop (Germany)",
  "Wandlitz (Germany)",
  "Wanfried (Germany)",
  "Wang (Germany)",
  "Wangen (Germany)",
  "Wangen im Allgau (Germany)",
  "Wangerooge (Germany)",
  "Wankendorf (Germany)",
  "Wanna (Germany)",
  "Wanne-Eickel (Germany)",
  "Wannweil (Germany)",
  "Wansleben (Germany)",
  "Wanzleben (Germany)",
  "Warberg (Germany)",
  "Warburg (Germany)",
  "Wardenburg (Germany)",
  "Wardow (Germany)",
  "Waren (Germany)",
  "Warendorf (Germany)",
  "Warin (Germany)",
  "Warmensteinach (Germany)",
  "Warmsen (Germany)",
  "Warnau (Germany)",
  "Warngau (Germany)",
  "Warnow (Germany)",
  "Warrenzin (Germany)",
  "Warstein (Germany)",
  "Wartenberg (Germany)",
  "Wartenberg-Rohrbach (Germany)",
  "Warthausen (Germany)",
  "Warza (Germany)",
  "Wasbek (Germany)",
  "Wasbuttel (Germany)",
  "Waschenbach (Germany)",
  "Waschenbeuren (Germany)",
  "Wasenweiler (Germany)",
  "Wassenach (Germany)",
  "Wassenberg (Germany)",
  "Wasser (Germany)",
  "Wasserburg am Inn (Germany)",
  "Wasserleben (Germany)",
  "Wasserliesch (Germany)",
  "Wasserlosen (Germany)",
  "Wassermungenau (Germany)",
  "Wassersleben (Germany)",
  "Wassertrudingen (Germany)",
  "Wasungen (Germany)",
  "Wathlingen (Germany)",
  "Wattenbek (Germany)",
  "Wattenheim (Germany)",
  "Wattmannshagen (Germany)",
  "Watzenborn (Germany)",
  "Waxweiler (Germany)",
  "Wechingen (Germany)",
  "Wechmar (Germany)",
  "Wechselburg (Germany)",
  "Weddel (Germany)",
  "Weddelbrook (Germany)",
  "Weddingstedt (Germany)",
  "Wedel (Germany)",
  "Wedringen (Germany)",
  "Weede (Germany)",
  "Weener (Germany)",
  "Weenzen (Germany)",
  "Wees (Germany)",
  "Weeze (Germany)",
  "Wefensleben (Germany)",
  "Weferlingen (Germany)",
  "Wegberg (Germany)",
  "Wegeleben (Germany)",
  "Wegscheid (Germany)",
  "Wehingen (Germany)",
  "Wehlheiden (Germany)",
  "Wehnrath (Germany)",
  "Wehr (Germany)",
  "Wehrbleck (Germany)",
  "Wehrheim (Germany)",
  "Weiberg (Germany)",
  "Weibern (Germany)",
  "Weibersbrunn (Germany)",
  "Weichering (Germany)",
  "Weichs (Germany)",
  "Weida (Germany)",
  "Weiden (Germany)",
  "Weidenbach (Germany)",
  "Weidenberg (Germany)",
  "Weidenhahn (Germany)",
  "Weidenhof (Germany)",
  "Weidenthal (Germany)",
  "Weidhausen (Germany)",
  "Weiding (Germany)",
  "Weigendorf (Germany)",
  "Weihenzell (Germany)",
  "Weiherhammer (Germany)",
  "Weihmichl (Germany)",
  "Weikersheim (Germany)",
  "Weil (Germany)",
  "Weil am Rhein (Germany)",
  "Weil der Stadt (Germany)",
  "Weil im Schonbuch (Germany)",
  "Weilar (Germany)",
  "Weilbach (Germany)",
  "Weilburg (Germany)",
  "Weiler (Germany)",
  "Weiler-Simmerberg (Germany)",
  "Weilerbach (Germany)",
  "Weilersbach (Germany)",
  "Weilerswist (Germany)",
  "Weilheim (Germany)",
  "Weilheim an der Teck (Germany)",
  "Weilmunster (Germany)",
  "Weiltingen (Germany)",
  "Weimar (Germany)",
  "Weinahr (Germany)",
  "Weinbach (Germany)",
  "Weinbohla (Germany)",
  "Weingarten (Germany)",
  "Weinheim (Germany)",
  "Weinsberg (Germany)",
  "Weinsfeld (Germany)",
  "Weinsheim (Germany)",
  "Weinstadt-Endersbach (Germany)",
  "Weischlitz (Germany)",
  "Weisel (Germany)",
  "Weisen (Germany)",
  "Weisenbach (Germany)",
  "Weisendorf (Germany)",
  "Weisenheim am Berg (Germany)",
  "Weisenheim am Sand (Germany)",
  "Weiskirchen (Germany)",
  "Weismain (Germany)",
  "Weissach (Germany)",
  "Weissandt-Golzau (Germany)",
  "Weissbach (Germany)",
  "Weissdorf (Germany)",
  "Weissen (Germany)",
  "Weissenborn (Germany)",
  "Weissenbrunn (Germany)",
  "Weissenburg (Germany)",
  "Weissenfeld (Germany)",
  "Weissenfels (Germany)",
  "Weissenhorn (Germany)",
  "Weissenohe (Germany)",
  "Weissensee (Germany)",
  "Weissenstadt (Germany)",
  "Weissenthurm (Germany)",
  "Weisswasser (Germany)",
  "Weisweil (Germany)",
  "Weitefeld (Germany)",
  "Weitenhagen (Germany)",
  "Weitersborn (Germany)",
  "Weitersburg (Germany)",
  "Weiterstadt (Germany)",
  "Weitersweiler (Germany)",
  "Weitnau (Germany)",
  "Weitramsdorf (Germany)",
  "Weitzschen (Germany)",
  "Weixerau (Germany)",
  "Welbsleben (Germany)",
  "Welden (Germany)",
  "Welgesheim (Germany)",
  "Welkenbach (Germany)",
  "Welle (Germany)",
  "Wellheim (Germany)",
  "Welschbillig (Germany)",
  "Welshofen (Germany)",
  "Welsleben (Germany)",
  "Welterod (Germany)",
  "Welver (Germany)",
  "Welzheim (Germany)",
  "Welzow (Germany)",
  "Wemding (Germany)",
  "Wenddorf (Germany)",
  "Wendeburg (Germany)",
  "Wendelstein (Germany)",
  "Wenden (Germany)",
  "Wendhausen (Germany)",
  "Wendisch Evern (Germany)",
  "Wendisch Rietz (Germany)",
  "Wendlingen am Neckar (Germany)",
  "Wendtorf (Germany)",
  "Wengelsdorf (Germany)",
  "Wennigsen (Germany)",
  "Wentorf (Germany)",
  "Wentorf bei Hamburg (Germany)",
  "Wenzenbach (Germany)",
  "Wenzendorf (Germany)",
  "Wenzingen (Germany)",
  "Wenzlow (Germany)",
  "Werben (Germany)",
  "Werda (Germany)",
  "Werdau (Germany)",
  "Werder (Germany)",
  "Werdohl (Germany)",
  "Werl (Germany)",
  "Werlaburgdorf (Germany)",
  "Werlau (Germany)",
  "Werlte (Germany)",
  "Wermelskirchen (Germany)",
  "Wermsdorf (Germany)",
  "Wernau (Germany)",
  "Wernberg-Koblitz (Germany)",
  "Werne (Germany)",
  "Werneck (Germany)",
  "Wernersberg (Germany)",
  "Werneuchen (Germany)",
  "Wernigerode (Germany)",
  "Werningshausen (Germany)",
  "Wernrode (Germany)",
  "Werpeloh (Germany)",
  "Werste (Germany)",
  "Wertach (Germany)",
  "Wertheim am Main (Germany)",
  "Werther (Germany)",
  "Wertingen (Germany)",
  "Wesel (Germany)",
  "Weselberg (Germany)",
  "Wesenberg (Germany)",
  "Wesendorf (Germany)",
  "Wesselburen (Germany)",
  "Wesseling (Germany)",
  "Wesseln (Germany)",
  "Wessiszell (Germany)",
  "Wessling (Germany)",
  "Wessobrunn Abbey (Germany)",
  "Weste (Germany)",
  "Westendorf (Germany)",
  "Westensee (Germany)",
  "Wester-Ohrstedt (Germany)",
  "Westerburg (Germany)",
  "Westerdeichstrich (Germany)",
  "Westeregeln (Germany)",
  "Westergellersen (Germany)",
  "Westerheim (Germany)",
  "Westerholt (Germany)",
  "Westerhorn (Germany)",
  "Westerkappeln (Germany)",
  "Westerland (Germany)",
  "Westerronfeld (Germany)",
  "Westerstede (Germany)",
  "Westerstetten (Germany)",
  "Westfeld (Germany)",
  "Westhausen (Germany)",
  "Westheim (Germany)",
  "Westhofen (Germany)",
  "Westkirchen (Germany)",
  "Westrhauderfehn (Germany)",
  "Wetschen (Germany)",
  "Wettelsheim (Germany)",
  "Wettenberg (Germany)",
  "Wetter (Germany)",
  "Wetter (Ruhr) (Germany)",
  "Wetterzeube (Germany)",
  "Wettin (Germany)",
  "Wettringen (Germany)",
  "Wettstetten (Germany)",
  "Wetzlar (Germany)",
  "Weyarn (Germany)",
  "Weyerbusch (Germany)",
  "Weyhausen (Germany)",
  "Weyhe (Germany)",
  "Wichte (Germany)",
  "Wickede (Germany)",
  "Wicklesgreuth (Germany)",
  "Wiebelsheim (Germany)",
  "Wiedemar (Germany)",
  "Wiederau (Germany)",
  "Wiedergeltingen (Germany)",
  "Wiefelstede (Germany)",
  "Wiehe (Germany)",
  "Wiehl (Germany)",
  "Wiek (Germany)",
  "Wielenbach (Germany)",
  "Wienhausen (Germany)",
  "Wienrode (Germany)",
  "Wiernsheim (Germany)",
  "Wiesau (Germany)",
  "Wiesbach (Germany)",
  "Wiesbaden (Germany)",
  "Wieseck (Germany)",
  "Wieselbach (Germany)",
  "Wiesenbach (Germany)",
  "Wiesenburg (Germany)",
  "Wiesenfeld (Germany)",
  "Wiesenfelden (Germany)",
  "Wiesensteig (Germany)",
  "Wiesent (Germany)",
  "Wiesentheid (Germany)",
  "Wieske (Germany)",
  "Wiesloch (Germany)",
  "Wiesmoor (Germany)",
  "Wiesthal (Germany)",
  "Wietmarschen (Germany)",
  "Wietze (Germany)",
  "Wietzen (Germany)",
  "Wietzendorf (Germany)",
  "Wiggensbach (Germany)",
  "Wilburgstetten (Germany)",
  "Wildau (Germany)",
  "Wildberg (Germany)",
  "Wildbergerhutte (Germany)",
  "Wildenberg (Germany)",
  "Wildenfels (Germany)",
  "Wildeshausen (Germany)",
  "Wildpoldsried (Germany)",
  "Wilferdingen (Germany)",
  "Wilgartswiesen (Germany)",
  "Wilhelm (Germany)",
  "Wilhelmsburg (Germany)",
  "Wilhelmsdorf (Germany)",
  "Wilhelmsfeld (Germany)",
  "Wilhelmshaven (Germany)",
  "Wilhelmsheim (Germany)",
  "Wilhelmsthal (Germany)",
  "Wilhermsdorf (Germany)",
  "Wilkau-Hasslau (Germany)",
  "Willebadessen (Germany)",
  "Willensen (Germany)",
  "Willich (Germany)",
  "Willingen (Germany)",
  "Willmars (Germany)",
  "Willmenrod (Germany)",
  "Willmersdorf (Germany)",
  "Willroth (Germany)",
  "Willstatt (Germany)",
  "Wilnsdorf (Germany)",
  "Wilsdruff (Germany)",
  "Wilstedt (Germany)",
  "Wilster (Germany)",
  "Wilsum (Germany)",
  "Wilthen (Germany)",
  "Wiltingen (Germany)",
  "Wimmelburg (Germany)",
  "Wimsheim (Germany)",
  "Wincheringen (Germany)",
  "Windach (Germany)",
  "Windberg (Germany)",
  "Windeby (Germany)",
  "Windehausen (Germany)",
  "Winden (Germany)",
  "Windesheim (Germany)",
  "Windhagen (Germany)",
  "Windischeschenbach (Germany)",
  "Windischleuba (Germany)",
  "Windorf (Germany)",
  "Windsbach (Germany)",
  "Wingst (Germany)",
  "Winhoring (Germany)",
  "Winkelhaid (Germany)",
  "Winkwitz (Germany)",
  "Winnen (Germany)",
  "Winnenden (Germany)",
  "Winnigstedt (Germany)",
  "Winningen (Germany)",
  "Winnweiler (Germany)",
  "Winsen (Germany)",
  "Winterbach (Germany)",
  "Winterberg (Germany)",
  "Winterhausen (Germany)",
  "Winterlingen (Germany)",
  "Winterrieden (Germany)",
  "Wintersdorf (Germany)",
  "Wintrich (Germany)",
  "Winzeln (Germany)",
  "Winzer (Germany)",
  "Wipfeld (Germany)",
  "Wippershain (Germany)",
  "Wirdum (Germany)",
  "Wirges (Germany)",
  "Wirsberg (Germany)",
  "Wisch (Germany)",
  "Wischhafen (Germany)",
  "Wismar (Germany)",
  "Wisper (Germany)",
  "Wissen (Germany)",
  "Wistedt (Germany)",
  "Wittdun (Germany)",
  "Wittekindshof (Germany)",
  "Wittelshofen (Germany)",
  "Witten (Germany)",
  "Wittenbeck (Germany)",
  "Wittenberg (Germany)",
  "Wittenberge (Germany)",
  "Wittenborn (Germany)",
  "Wittenburg (Germany)",
  "Wittenforden (Germany)",
  "Wittgendorf (Germany)",
  "Wittgensdorf (Germany)",
  "Wittgert (Germany)",
  "Wittichenau (Germany)",
  "Wittighausen (Germany)",
  "Wittingen (Germany)",
  "Wittislingen (Germany)",
  "Wittlich (Germany)",
  "Wittmund (Germany)",
  "Wittnau (Germany)",
  "Wittorf (Germany)",
  "Wittstock (Germany)",
  "Witzenhausen (Germany)",
  "Witzhave (Germany)",
  "Witzin (Germany)",
  "Witzwort (Germany)",
  "Woffleben (Germany)",
  "Wohlsborn (Germany)",
  "Wohltorf (Germany)",
  "Wohnbach (Germany)",
  "Wohnrod (Germany)",
  "Wohra (Germany)",
  "Wohrden (Germany)",
  "Woldegk (Germany)",
  "Woldert (Germany)",
  "Wolfach (Germany)",
  "Wolfegg (Germany)",
  "Wolfen (Germany)",
  "Wolferlingen (Germany)",
  "Wolferode (Germany)",
  "Wolfersdorf (Germany)",
  "Wolfersheim (Germany)",
  "Wolfgang (Germany)",
  "Wolfhagen (Germany)",
  "Wolfis (Germany)",
  "Wolfmannshausen (Germany)",
  "Wolframs-Eschenbach (Germany)",
  "Wolfratshausen (Germany)",
  "Wolfsburg (Germany)",
  "Wolfschlugen (Germany)",
  "Wolfsegg (Germany)",
  "Wolfsheim (Germany)",
  "Wolgast (Germany)",
  "Wolkenstein (Germany)",
  "Wollbrandshausen (Germany)",
  "Wollstein (Germany)",
  "Wolmirstedt (Germany)",
  "Wolnzach (Germany)",
  "Wolpertshausen (Germany)",
  "Wolpertswende (Germany)",
  "Wolpinghausen (Germany)",
  "Wolsdorf (Germany)",
  "Wolsendorf (Germany)",
  "Wolsfeld (Germany)",
  "Woltersdorf (Germany)",
  "Woltershausen (Germany)",
  "Woltingerode (Germany)",
  "Womrath (Germany)",
  "Wonfurt (Germany)",
  "Wonsees (Germany)",
  "Worbis (Germany)",
  "Worfelden (Germany)",
  "Worms (Germany)",
  "Wormstedt (Germany)",
  "Worpswede (Germany)",
  "Worrstadt (Germany)",
  "Worth (Germany)",
  "Worth am Main (Germany)",
  "Worth am Rhein (Germany)",
  "Worthsee (Germany)",
  "Wotersen (Germany)",
  "Wredenhagen (Germany)",
  "Wremen (Germany)",
  "Wrestedt (Germany)",
  "Wriedel (Germany)",
  "Wriezen (Germany)",
  "Wrist (Germany)",
  "Wrixum (Germany)",
  "Wuelfrath (Germany)",
  "Wulfen (Germany)",
  "Wulften (Germany)",
  "Wumbach (Germany)",
  "Wundersleben (Germany)",
  "Wunnenberg (Germany)",
  "Wunsiedel (Germany)",
  "Wunstorf (Germany)",
  "Wuppertal (Germany)",
  "Wurmannsquick (Germany)",
  "Wurmberg (Germany)",
  "Wurmlingen (Germany)",
  "Wurmsham (Germany)",
  "Wurselen (Germany)",
  "Wurzbach (Germany)",
  "Wurzburg (Germany)",
  "Wurzen (Germany)",
  "Wustenriet (Germany)",
  "Wustenrot (Germany)",
  "Wusterhausen (Germany)",
  "Wusterhusen (Germany)",
  "Wustermark (Germany)",
  "Wusterwitz (Germany)",
  "Wustheuterode (Germany)",
  "Wustweiler (Germany)",
  "Wutoschingen (Germany)",
  "Wyhl (Germany)",
  "Wyk auf Fohr (Germany)",
  "Xanten (Germany)",
  "Zaberfeld (Germany)",
  "Zahna (Germany)",
  "Zainingen (Germany)",
  "Zaisenhausen (Germany)",
  "Zaisertshofen (Germany)",
  "Zapel (Germany)",
  "Zapfendorf (Germany)",
  "Zarpen (Germany)",
  "Zarrendorf (Germany)",
  "Zarrentin (Germany)",
  "Zaulsdorf (Germany)",
  "Zechlin Dorf (Germany)",
  "Zehdenick (Germany)",
  "Zeil (Germany)",
  "Zeiskam (Germany)",
  "Zeithain (Germany)",
  "Zeitlofs (Germany)",
  "Zeitz (Germany)",
  "Zell (Germany)",
  "Zell am Harmersbach (Germany)",
  "Zell am Main (Germany)",
  "Zell im Wiesental (Germany)",
  "Zell unter Aichelberg (Germany)",
  "Zella-Mehlis (Germany)",
  "Zellingen (Germany)",
  "Zeltingen-Rachtig (Germany)",
  "Zemmer (Germany)",
  "Zempin (Germany)",
  "Zeppernick (Germany)",
  "Zerben (Germany)",
  "Zerbst (Germany)",
  "Zerf (Germany)",
  "Zernien (Germany)",
  "Zerre (Germany)",
  "Zerrenthin (Germany)",
  "Zetel (Germany)",
  "Zeulenroda (Germany)",
  "Zeuthen (Germany)",
  "Zeven (Germany)",
  "Ziegendorf (Germany)",
  "Zielitz (Germany)",
  "Ziemetshausen (Germany)",
  "Zierenberg (Germany)",
  "Ziesar (Germany)",
  "Ziesendorf (Germany)",
  "Ziltendorf (Germany)",
  "Zimmern (Germany)",
  "Zimmern ob Rottweil (Germany)",
  "Zingst (Germany)",
  "Zipplingen (Germany)",
  "Zirkow (Germany)",
  "Zirndorf (Germany)",
  "Zittau (Germany)",
  "Zolling (Germany)",
  "Zollstock (Germany)",
  "Zorbig (Germany)",
  "Zorge (Germany)",
  "Zorneding (Germany)",
  "Zornheim (Germany)",
  "Zoschingen (Germany)",
  "Zossen (Germany)",
  "Zottelstedt (Germany)",
  "Zschopau (Germany)",
  "Zschorlau (Germany)",
  "Zschornewitz (Germany)",
  "Zschortau (Germany)",
  "Zuchering (Germany)",
  "Zuffenhausen (Germany)",
  "Zuhlsdorf (Germany)",
  "Zulpich (Germany)",
  "Zusamaltheim (Germany)",
  "Zusmarshausen (Germany)",
  "Zussow (Germany)",
  "Zuzenhausen (Germany)",
  "Zweiflingen (Germany)",
  "Zwenkau (Germany)",
  "Zwesten (Germany)",
  "Zwickau (Germany)",
  "Zwiefalten (Germany)",
  "Zwiesel (Germany)",
  "Zwingenberg (Germany)",
  "Zwonitz (Germany)",
  "Ghana",
  "Accra (Ghana)",
  "Bawku (Ghana)",
  "Berekum (Ghana)",
  "Bolgatanga (Ghana)",
  "Cape Coast (Ghana)",
  "Home (Ghana)",
  "Koforidua (Ghana)",
  "Kumasi (Ghana)",
  "Legon (Ghana)",
  "Mampong (Ghana)",
  "Navrongo (Ghana)",
  "Sunyani (Ghana)",
  "Takoradi (Ghana)",
  "Tema (Ghana)",
  "Wa (Ghana)",
  "Winneba (Ghana)",
  "Greece",
  "Aegina (Greece)",
  "Agioi Anargyroi (Greece)",
  "Agios Nikolaos (Greece)",
  "Agrinio (Greece)",
  "Aigaleo (Greece)",
  "Aigio (Greece)",
  "Alexandreia (Greece)",
  "Alexandroupoli (Greece)",
  "Aliartos (Greece)",
  "Alimos (Greece)",
  "Amaliada (Greece)",
  "Anavyssos (Greece)",
  "Andravida (Greece)",
  "Ano Liosia (Greece)",
  "Archaia Nemea (Greece)",
  "Argos (Greece)",
  "Argostoli (Greece)",
  "Argyroupoli (Greece)",
  "Arta (Greece)",
  "Aspropyrgos (Greece)",
  "Athens (Greece)",
  "Attica (Greece)",
  "Ayios Stefanos (Greece)",
  "Chalcis (Greece)",
  "Chania (Greece)",
  "Chios (Greece)",
  "Cholargos (Greece)",
  "Corfu (Greece)",
  "Corinth (Greece)",
  "Dafni (Greece)",
  "Dionysos (Greece)",
  "Drama (Greece)",
  "Ekali (Greece)",
  "Elassona (Greece)",
  "Elefsina (Greece)",
  "Elliniko (Greece)",
  "Eretria (Greece)",
  "Farsala (Greece)",
  "Filippiada (Greece)",
  "Filothei (Greece)",
  "Florina (Greece)",
  "Galatsi (Greece)",
  "Giannitsa (Greece)",
  "Glyfada (Greece)",
  "Gonnoi (Greece)",
  "Goumenissa (Greece)",
  "Heraklion (Greece)",
  "Hydra (Greece)",
  "Ialysos (Greece)",
  "Ierapetra (Greece)",
  "Igoumenitsa (Greece)",
  "Ioannina (Greece)",
  "Kaisariani (Greece)",
  "Kalamaria (Greece)",
  "Kalamata (Greece)",
  "Kalamos (Greece)",
  "Kallithea (Greece)",
  "Kalymnos (Greece)",
  "Kamatero (Greece)",
  "Karditsa (Greece)",
  "Kassiopi (Greece)",
  "Kastoria (Greece)",
  "Katerini (Greece)",
  "Kavala (Greece)",
  "Keratea (Greece)",
  "Keratsini (Greece)",
  "Kifissia (Greece)",
  "Kilkis (Greece)",
  "Komotini (Greece)",
  "Koropi (Greece)",
  "Kos (Greece)",
  "Kouvaras (Greece)",
  "Kozani (Greece)",
  "Kranidi (Greece)",
  "Kryonerion (Greece)",
  "Kymi (Greece)",
  "Lamia (Greece)",
  "Larisa (Greece)",
  "Lefkada (Greece)",
  "Lefkimmi (Greece)",
  "Leontario (Greece)",
  "Litochoro (Greece)",
  "Lixouri (Greece)",
  "Loutraki (Greece)",
  "Lykovrysi (Greece)",
  "Magoula (Greece)",
  "Makrygialos (Greece)",
  "Mandra (Greece)",
  "Marathon (Greece)",
  "Markopoulo Mesogaias (Greece)",
  "Megalochari (Greece)",
  "Megara (Greece)",
  "Melissia (Greece)",
  "Mesagros (Greece)",
  "Messolonghi (Greece)",
  "Metaxades (Greece)",
  "Moires (Greece)",
  "Mytilene (Greece)",
  "Nafpaktos (Greece)",
  "Nafplion (Greece)",
  "Naousa (Greece)",
  "Naxos (Greece)",
  "Nea Alikarnassos (Greece)",
  "Nea Filadelfeia (Greece)",
  "Nea Ionia (Greece)",
  "Nea Kios (Greece)",
  "Nea Makri (Greece)",
  "Nea Peramos (Greece)",
  "Nea Smyrni (Greece)",
  "Nikaia (Greece)",
  "Orestiada (Greece)",
  "Ormylia (Greece)",
  "Oropos (Greece)",
  "Paiania (Greece)",
  "Pallini (Greece)",
  "Paloukia (Greece)",
  "Panorama (Greece)",
  "Parga (Greece)",
  "Patmos (Greece)",
  "Pefki (Greece)",
  "Pella (Greece)",
  "Perama (Greece)",
  "Peristeri (Greece)",
  "Pikermi (Greece)",
  "Piraeus (Greece)",
  "Platy (Greece)",
  "Polichni (Greece)",
  "Porto Rafti (Greece)",
  "Preveza (Greece)",
  "Psychiko (Greece)",
  "Ptolemaida (Greece)",
  "Pylaia (Greece)",
  "Pyrgos (Greece)",
  "Rafina (Greece)",
  "Rethymno (Greece)",
  "Rhodes (Greece)",
  "Rio (Greece)",
  "Salamina (Greece)",
  "Samothraki (Greece)",
  "Serres (Greece)",
  "Servia (Greece)",
  "Sindos (Greece)",
  "Skala (Greece)",
  "Skala Oropou (Greece)",
  "Skiathos (Greece)",
  "Sparta (Greece)",
  "Spata (Greece)",
  "Symi (Greece)",
  "Tavros (Greece)",
  "Thebes (Greece)",
  "Thermi (Greece)",
  "Thessaloniki (Greece)",
  "Tinos (Greece)",
  "Trikala (Greece)",
  "Tripoli (Greece)",
  "Vari (Greece)",
  "Varkiza (Greece)",
  "Vergina (Greece)",
  "Veroia (Greece)",
  "Volos (Greece)",
  "Voula (Greece)",
  "Vouliagmeni (Greece)",
  "Xanthi (Greece)",
  "Xylokastro (Greece)",
  "Zakynthos (Greece)",
  "Greenland",
  "Aasiaat (Greenland)",
  "Ilulissat (Greenland)",
  "Kapisillit (Greenland)",
  "Maniitsoq (Greenland)",
  "Narsaq (Greenland)",
  "Narsarsuaq (Greenland)",
  "Nuuk (Greenland)",
  "Nuussuaq (Greenland)",
  "Paamiut (Greenland)",
  "Qaqortoq (Greenland)",
  "Qasigiannguit (Greenland)",
  "Qeqertarsuaq (Greenland)",
  "Qeqertat (Greenland)",
  "Sisimiut (Greenland)",
  "Tasiilaq (Greenland)",
  "Upernavik (Greenland)",
  "Uummannaq Kommune (Greenland)",
  "Guadeloupe",
  "Anse-Bertrand (Guadeloupe)",
  "Baie Mahault (Guadeloupe)",
  "Baie-Mahault (Guadeloupe)",
  "Baillif (Guadeloupe)",
  "Basse-Terre (Guadeloupe)",
  "Capesterre-Belle-Eau (Guadeloupe)",
  "Capesterre-de-Marie-Galante (Guadeloupe)",
  "Deshaies (Guadeloupe)",
  "Gourbeyre (Guadeloupe)",
  "Goyave (Guadeloupe)",
  "Grand-Bourg (Guadeloupe)",
  "Lamentin (Guadeloupe)",
  "Le Gosier (Guadeloupe)",
  "Le Moule (Guadeloupe)",
  "Les Abymes (Guadeloupe)",
  "Petit-Bourg (Guadeloupe)",
  "Petit-Canal (Guadeloupe)",
  "Pointe-Noire (Guadeloupe)",
  "Port-Louis (Guadeloupe)",
  "Saint-Claude (Guadeloupe)",
  "Saint-Francois (Guadeloupe)",
  "Saint-Louis (Guadeloupe)",
  "Sainte-Anne (Guadeloupe)",
  "Sainte-Rose (Guadeloupe)",
  "Terre-de-Bas (Guadeloupe)",
  "Terre-de-Haut (Guadeloupe)",
  "Trois-Rivieres (Guadeloupe)",
  "Village (Guadeloupe)",
  "Guam",
  "Barrigada Village (Guam)",
  "Dededo Village (Guam)",
  "Inarajan Village (Guam)",
  "Santa Rita (Guam)",
  "Tamuning-Tumon-Harmon Village (Guam)",
  "Yigo Village (Guam)",
  "Guatemala",
  "Antigua Guatemala (Guatemala)",
  "Cambote (Guatemala)",
  "Catarina (Guatemala)",
  "Central (Guatemala)",
  "Chimaltenango (Guatemala)",
  "Chiquimula (Guatemala)",
  "Ciudad Vieja (Guatemala)",
  "Coban (Guatemala)",
  "El Limon (Guatemala)",
  "El Naranjo (Guatemala)",
  "El Salvador (Guatemala)",
  "Escuintla (Guatemala)",
  "Esquipulas (Guatemala)",
  "Flores (Guatemala)",
  "Guatemala City (Guatemala)",
  "Huehuetenango (Guatemala)",
  "Jocotenango (Guatemala)",
  "La Reforma (Guatemala)",
  "Mazatenango (Guatemala)",
  "Melchor de Mencos (Guatemala)",
  "Mixco (Guatemala)",
  "Palin (Guatemala)",
  "Panajachel (Guatemala)",
  "Petapa (Guatemala)",
  "Puerto Barrios (Guatemala)",
  "Quesada (Guatemala)",
  "Quetzaltenango (Guatemala)",
  "Retalhuleu (Guatemala)",
  "San Antonio Miramar (Guatemala)",
  "San Jose Pinula (Guatemala)",
  "San Juan (Guatemala)",
  "San Marcos (Guatemala)",
  "San Pedro Sacatepequez (Guatemala)",
  "Santa Catarina Pinula (Guatemala)",
  "Santa Cruz La Laguna (Guatemala)",
  "Santa Cruz Naranjo (Guatemala)",
  "Santa Lucia Cotzumalguapa (Guatemala)",
  "Santa Rosa (Guatemala)",
  "Solola (Guatemala)",
  "Villa Nueva (Guatemala)",
  "Vista Hermosa (Guatemala)",
  "Zacapa (Guatemala)",
  "Guinea",
  "Conakry (Guinea)",
  "Dabola (Guinea)",
  "Kalia (Guinea)",
  "Kankan (Guinea)",
  "Lola (Guinea)",
  "Mamou (Guinea)",
  "Port Kamsar (Guinea)",
  "Sangaredi (Guinea)",
  "Haiti",
  "Carrefour (Haiti)",
  "Delmar (Haiti)",
  "Duverger (Haiti)",
  "Jacmel (Haiti)",
  "Masseau (Haiti)",
  "Moise (Haiti)",
  "Petionville (Haiti)",
  "Port-au-Prince (Haiti)",
  "Prince (Haiti)",
  "Turgeau (Haiti)",
  "Hashemite Kingdom of Jordan",
  "Amman (Hashemite Kingdom of Jordan)",
  "Ar Ramtha (Hashemite Kingdom of Jordan)",
  "Farah (Hashemite Kingdom of Jordan)",
  "Irbid (Hashemite Kingdom of Jordan)",
  "Madaba (Hashemite Kingdom of Jordan)",
  "Sahab (Hashemite Kingdom of Jordan)",
  "Salt (Hashemite Kingdom of Jordan)",
  "Honduras",
  "Choloma (Honduras)",
  "Comayagua (Honduras)",
  "Comayaguela (Honduras)",
  "Coxen Hole (Honduras)",
  "El Barro (Honduras)",
  "El Paraiso (Honduras)",
  "El Progreso (Honduras)",
  "La Ceiba (Honduras)",
  "La Hacienda (Honduras)",
  "Morazan (Honduras)",
  "Nacaome (Honduras)",
  "Pinalejo (Honduras)",
  "Piraera (Honduras)",
  "Puerto Lempira (Honduras)",
  "San Antonio de Flores (Honduras)",
  "San Pedro Sula (Honduras)",
  "Santa Barbara (Honduras)",
  "Sula (Honduras)",
  "Tegucigalpa (Honduras)",
  "Hong Kong",
  "Aberdeen (Hong Kong)",
  "Causeway Bay (Hong Kong)",
  "Central District (Hong Kong)",
  "Cha Kwo Ling (Hong Kong)",
  "Chai Wan Kok (Hong Kong)",
  "Chek Chue (Hong Kong)",
  "Cheung Kong (Hong Kong)",
  "Cheung Sha Wan (Hong Kong)",
  "Chuen Lung (Hong Kong)",
  "Chung Hau (Hong Kong)",
  "Fa Yuen (Hong Kong)",
  "Fanling (Hong Kong)",
  "Fo Tan (Hong Kong)",
  "Happy Valley (Hong Kong)",
  "Ho Man Tin (Hong Kong)",
  "Hong Kong (Hong Kong)",
  "Hung Hom (Hong Kong)",
  "Kat O Sheung Wai (Hong Kong)",
  "Kennedy Town (Hong Kong)",
  "Kowloon (Hong Kong)",
  "Kowloon Tong (Hong Kong)",
  "Kwai Chung (Hong Kong)",
  "Kwun Hang (Hong Kong)",
  "Lai Chi Wo (Hong Kong)",
  "Lam Tei (Hong Kong)",
  "Lam Tin (Hong Kong)",
  "Lin Fa Tei (Hong Kong)",
  "Lo So Shing (Hong Kong)",
  "Ma On Shan Tsuen (Hong Kong)",
  "Ma Wan (Hong Kong)",
  "Ma Yau Tong (Hong Kong)",
  "Mau Ping (Hong Kong)",
  "Mid Levels (Hong Kong)",
  "Mong Kok (Hong Kong)",
  "Nam A (Hong Kong)",
  "Ngau Chi Wan (Hong Kong)",
  "Ngau Tau Kok (Hong Kong)",
  "North Point (Hong Kong)",
  "Pak Ngan Heung (Hong Kong)",
  "Peng Chau (Hong Kong)",
  "Ping Yeung (Hong Kong)",
  "Quarry Bay (Hong Kong)",
  "Repulse Bay (Hong Kong)",
  "Sai Keng (Hong Kong)",
  "San Tsuen (Hong Kong)",
  "San Tung Chung Hang (Hong Kong)",
  "Sha Po Kong (Hong Kong)",
  "Sha Tau Kok (Hong Kong)",
  "Sha Tin Wai (Hong Kong)",
  "Sham Shui Po (Hong Kong)",
  "Sham Tseng (Hong Kong)",
  "Shatin (Hong Kong)",
  "Shau Kei Wan (Hong Kong)",
  "Shek Kip Mei (Hong Kong)",
  "Shek Tong Tsui (Hong Kong)",
  "Sheung Shui (Hong Kong)",
  "Sheung Tsuen (Hong Kong)",
  "Shuen Wan (Hong Kong)",
  "Tai Chau To (Hong Kong)",
  "Tai Hang (Hong Kong)",
  "Tai Kok Tsui (Hong Kong)",
  "Tai Lin Pai (Hong Kong)",
  "Tai Po (Hong Kong)",
  "Tai Tan (Hong Kong)",
  "Tai Wai (Hong Kong)",
  "Tai Wan To (Hong Kong)",
  "Tin Shui Wai (Hong Kong)",
  "Tin Wan Resettlement Estate (Hong Kong)",
  "Ting Kau (Hong Kong)",
  "To Kwa Wan (Hong Kong)",
  "Tseung Kwan O (Hong Kong)",
  "Tsimshatsui (Hong Kong)",
  "Tsing Lung Tau (Hong Kong)",
  "Tsz Wan Shan (Hong Kong)",
  "Tuen Mun San Hui (Hong Kong)",
  "Wan Tsai (Hong Kong)",
  "Wo Che (Hong Kong)",
  "Wong Chuk Hang (Hong Kong)",
  "Wong Tai Sin (Hong Kong)",
  "Yau Ma Tei (Hong Kong)",
  "Ying Pun (Hong Kong)",
  "Yuen Long San Hui (Hong Kong)",
  "Hungary",
  "Abaujszanto (Hungary)",
  "Abda (Hungary)",
  "Abony (Hungary)",
  "Acs (Hungary)",
  "Acsa (Hungary)",
  "Adacs (Hungary)",
  "Adony (Hungary)",
  "Agard (Hungary)",
  "Ajak (Hungary)",
  "Ajka (Hungary)",
  "Alap (Hungary)",
  "Albertirsa (Hungary)",
  "Almasfuzito (Hungary)",
  "Almaskamaras (Hungary)",
  "Almosd (Hungary)",
  "Alsogalla (Hungary)",
  "Alsonemedi (Hungary)",
  "Alsopahok (Hungary)",
  "Alsoszentivan (Hungary)",
  "Alsotold (Hungary)",
  "Alsozsolca (Hungary)",
  "Aparhant (Hungary)",
  "Apatfalva (Hungary)",
  "Apc (Hungary)",
  "Apostag (Hungary)",
  "Arnot (Hungary)",
  "Asotthalom (Hungary)",
  "Aszalo (Hungary)",
  "Aszar (Hungary)",
  "Aszod (Hungary)",
  "Atkar (Hungary)",
  "Attala (Hungary)",
  "Babolna (Hungary)",
  "Bacsalmas (Hungary)",
  "Badacsonytomaj (Hungary)",
  "Badacsonytordemic (Hungary)",
  "Bag (Hungary)",
  "Bagyogszovat (Hungary)",
  "Baja (Hungary)",
  "Bajna (Hungary)",
  "Bakonybel (Hungary)",
  "Bakonycsernye (Hungary)",
  "Bakonyszentlaszlo (Hungary)",
  "Bakonyszombathely (Hungary)",
  "Balassagyarmat (Hungary)",
  "Balaton (Hungary)",
  "Balatonalmadi (Hungary)",
  "Balatonboglar (Hungary)",
  "Balatonfokajar (Hungary)",
  "Balatonfured (Hungary)",
  "Balatonfuzfo (Hungary)",
  "Balatonkenese (Hungary)",
  "Balatonlelle (Hungary)",
  "Balatonszabadi (Hungary)",
  "Balatonszarszo (Hungary)",
  "Balkany (Hungary)",
  "Balloszog (Hungary)",
  "Balmazujvaros (Hungary)",
  "Banhorvati (Hungary)",
  "Baracs (Hungary)",
  "Baracska (Hungary)",
  "Barand (Hungary)",
  "Barcs (Hungary)",
  "Bataszek (Hungary)",
  "Batonyterenye (Hungary)",
  "Bazsi (Hungary)",
  "Becsehely (Hungary)",
  "Bekasmegyer (Hungary)",
  "Bekesszentandras (Hungary)",
  "Belapatfalva (Hungary)",
  "Beled (Hungary)",
  "Beloiannisz (Hungary)",
  "Benye (Hungary)",
  "Berekfurdo (Hungary)",
  "Berettyoujfalu (Hungary)",
  "Berhida (Hungary)",
  "Berkesd (Hungary)",
  "Besnyo (Hungary)",
  "Biatorbagy (Hungary)",
  "Bicske (Hungary)",
  "Biharkeresztes (Hungary)",
  "Bikal (Hungary)",
  "Bocs (Hungary)",
  "Bodajk (Hungary)",
  "Bodaszolo (Hungary)",
  "Bodrogkisfalud (Hungary)",
  "Bogyiszlo (Hungary)",
  "Bohonye (Hungary)",
  "Bokod (Hungary)",
  "Boldog (Hungary)",
  "Boldva (Hungary)",
  "Boly (Hungary)",
  "Boncodfolde (Hungary)",
  "Bonyhad (Hungary)",
  "Borcs (Hungary)",
  "Borsodszirak (Hungary)",
  "Budajeno (Hungary)",
  "Budakalasz (Hungary)",
  "Budakeszi (Hungary)",
  "Budaors (Hungary)",
  "Budapest (Hungary)",
  "Bugac (Hungary)",
  "Bugyi (Hungary)",
  "Buk (Hungary)",
  "Bukkaranyos (Hungary)",
  "Buzsak (Hungary)",
  "Cegled (Hungary)",
  "Cegledbercel (Hungary)",
  "Celldomolk (Hungary)",
  "Center (Hungary)",
  "Cered (Hungary)",
  "Ciko (Hungary)",
  "Cirak (Hungary)",
  "Csabacsud (Hungary)",
  "Csajag (Hungary)",
  "Csakvar (Hungary)",
  "Csanadpalota (Hungary)",
  "Csanytelek (Hungary)",
  "Csecse (Hungary)",
  "Csemo (Hungary)",
  "Csepa (Hungary)",
  "Cserenfa (Hungary)",
  "Cserepfalu (Hungary)",
  "Cserszegtomaj (Hungary)",
  "Csobad (Hungary)",
  "Csobanka (Hungary)",
  "Csoeroeg (Hungary)",
  "Csogle (Hungary)",
  "Csolyospalos (Hungary)",
  "Csomad (Hungary)",
  "Csomor (Hungary)",
  "Csongrad (Hungary)",
  "Csopak (Hungary)",
  "Csorna (Hungary)",
  "Csorvas (Hungary)",
  "Csovar (Hungary)",
  "Dabas (Hungary)",
  "Dad (Hungary)",
  "Danszentmiklos (Hungary)",
  "Dany (Hungary)",
  "Debrecen (Hungary)",
  "Decs (Hungary)",
  "Dedestapolcsany (Hungary)",
  "Deg (Hungary)",
  "Delegyhaza (Hungary)",
  "Demjen (Hungary)",
  "Derecske (Hungary)",
  "Deszk (Hungary)",
  "Devavanya (Hungary)",
  "Devecser (Hungary)",
  "Diosd (Hungary)",
  "Dobrokoz (Hungary)",
  "Domaszek (Hungary)",
  "Dombovar (Hungary)",
  "Dombrad (Hungary)",
  "Domoszlo (Hungary)",
  "Domsod (Hungary)",
  "Donat (Hungary)",
  "Dorog (Hungary)",
  "Doroghaza (Hungary)",
  "Dudar (Hungary)",
  "Dunabogdany (Hungary)",
  "Dunafoldvar (Hungary)",
  "Dunaharaszti (Hungary)",
  "Dunakeszi (Hungary)",
  "Dunaszeg (Hungary)",
  "Dunaszentgyorgy (Hungary)",
  "Dunavarsany (Hungary)",
  "Dunavecse (Hungary)",
  "Dusnok (Hungary)",
  "Ebes (Hungary)",
  "Ecs (Hungary)",
  "Ecser (Hungary)",
  "Edeleny (Hungary)",
  "Eger (Hungary)",
  "Egerszolat (Hungary)",
  "Egyek (Hungary)",
  "Egyhazasdengeleg (Hungary)",
  "Egyhazashetye (Hungary)",
  "Emod (Hungary)",
  "Encs (Hungary)",
  "Eperjeske (Hungary)",
  "Ercsi (Hungary)",
  "Erdokertes (Hungary)",
  "Esztergom (Hungary)",
  "Farmos (Hungary)",
  "Fegyvernek (Hungary)",
  "Fehergyarmat (Hungary)",
  "Felsopakony (Hungary)",
  "Felsoszentivan (Hungary)",
  "Felsotarkany (Hungary)",
  "Felsozsolca (Hungary)",
  "Fertod (Hungary)",
  "Fertoszentmiklos (Hungary)",
  "Fot (Hungary)",
  "Fuzesabony (Hungary)",
  "Fuzesgyarmat (Hungary)",
  "Galambok (Hungary)",
  "Galgamacsa (Hungary)",
  "Garab (Hungary)",
  "Gardony (Hungary)",
  "Gasztony (Hungary)",
  "Gavavencsello (Hungary)",
  "Gelse (Hungary)",
  "Gemzse (Hungary)",
  "Gencsapati (Hungary)",
  "Gerjen (Hungary)",
  "God (Hungary)",
  "Golle (Hungary)",
  "Gonyu (Hungary)",
  "Gorbehaza (Hungary)",
  "Gorgeteg (Hungary)",
  "Gyal (Hungary)",
  "Gyar (Hungary)",
  "Gyenesdias (Hungary)",
  "Gyomaendrod (Hungary)",
  "Gyomro (Hungary)",
  "Gyongyos (Hungary)",
  "Gyongyossolymos (Hungary)",
  "Gyongyostarjan (Hungary)",
  "Gyorkony (Hungary)",
  "Gyorladamer (Hungary)",
  "Gyorsag (Hungary)",
  "Gyorujbarat (Hungary)",
  "Gyorzamoly (Hungary)",
  "Gyula (Hungary)",
  "Gyulahaza (Hungary)",
  "Hajduboszormeny (Hungary)",
  "Hajdudorog (Hungary)",
  "Hajduhadhaz (Hungary)",
  "Hajdunanas (Hungary)",
  "Hajdusamson (Hungary)",
  "Hajduszoboszlo (Hungary)",
  "Hajmasker (Hungary)",
  "Halaszi (Hungary)",
  "Halasztelek (Hungary)",
  "Halimba (Hungary)",
  "Harkany (Hungary)",
  "Harta (Hungary)",
  "Hatvan (Hungary)",
  "Hegyeshalom (Hungary)",
  "Hegyfalu (Hungary)",
  "Hegyhatmaroc (Hungary)",
  "Hegyhatszentmarton (Hungary)",
  "Hehalom (Hungary)",
  "Helvecia (Hungary)",
  "Herceghalom (Hungary)",
  "Hercegkut (Hungary)",
  "Hercegszanto (Hungary)",
  "Hered (Hungary)",
  "Herend (Hungary)",
  "Hernad (Hungary)",
  "Hernadkak (Hungary)",
  "Hernadnemeti (Hungary)",
  "Heves (Hungary)",
  "Hevesaranyos (Hungary)",
  "Hevizgyoerk (Hungary)",
  "Hidas (Hungary)",
  "Hodmezovasarhely (Hungary)",
  "Homrogd (Hungary)",
  "Hont (Hungary)",
  "Hort (Hungary)",
  "Hosszuheteny (Hungary)",
  "Ibrany (Hungary)",
  "Ikreny (Hungary)",
  "Inancs (Hungary)",
  "Inarcs (Hungary)",
  "Iregszemcse (Hungary)",
  "Isaszeg (Hungary)",
  "Ivancsa (Hungary)",
  "Izmeny (Hungary)",
  "Janoshalma (Hungary)",
  "Janoshida (Hungary)",
  "Janossomorja (Hungary)",
  "Jaszapati (Hungary)",
  "Jaszbereny (Hungary)",
  "Jaszfelsoszentgyorgy (Hungary)",
  "Jaszfenyszaru (Hungary)",
  "Jaszkiser (Hungary)",
  "Jaszladany (Hungary)",
  "Juta (Hungary)",
  "Kacsota (Hungary)",
  "Kadarkut (Hungary)",
  "Kajaszo (Hungary)",
  "Kallo (Hungary)",
  "Kalocsa (Hungary)",
  "Kaloz (Hungary)",
  "Kapolnasnyek (Hungary)",
  "Kaposmero (Hungary)",
  "Kapuvar (Hungary)",
  "Karancskeszi (Hungary)",
  "Karancslapujto (Hungary)",
  "Karancssag (Hungary)",
  "Karcag (Hungary)",
  "Kartal (Hungary)",
  "Kazincbarcika (Hungary)",
  "Kecel (Hungary)",
  "Kemecse (Hungary)",
  "Kemence (Hungary)",
  "Kerecsend (Hungary)",
  "Kerekegyhaza (Hungary)",
  "Kerekharaszt (Hungary)",
  "Kerepes (Hungary)",
  "Kesznyeten (Hungary)",
  "Keszthely (Hungary)",
  "Keszu (Hungary)",
  "Ketegyhaza (Hungary)",
  "Kevermes (Hungary)",
  "Kincsesbanya (Hungary)",
  "Kisar (Hungary)",
  "Kisber (Hungary)",
  "Kisdorog (Hungary)",
  "Kisgyor (Hungary)",
  "Kiskinizs (Hungary)",
  "Kiskoros (Hungary)",
  "Kiskunfelegyhaza (Hungary)",
  "Kiskunhalas (Hungary)",
  "Kiskunlachaza (Hungary)",
  "Kislang (Hungary)",
  "Kismanyok (Hungary)",
  "Kismaros (Hungary)",
  "Kisnemedi (Hungary)",
  "Kispest (Hungary)",
  "Kistapolca (Hungary)",
  "Kistarcsa (Hungary)",
  "Kistelek (Hungary)",
  "Kistokaj (Hungary)",
  "Kistormas (Hungary)",
  "Kisujszallas (Hungary)",
  "Kisvarda (Hungary)",
  "Kisvaszar (Hungary)",
  "Klarafalva (Hungary)",
  "Koka (Hungary)",
  "Kolontar (Hungary)",
  "Komlo (Hungary)",
  "Komlod (Hungary)",
  "Kompolt (Hungary)",
  "Kony (Hungary)",
  "Kophaza (Hungary)",
  "Kormend (Hungary)",
  "Kornye (Hungary)",
  "Korosladany (Hungary)",
  "Koszarhegy (Hungary)",
  "Koszeg (Hungary)",
  "Kotaj (Hungary)",
  "Kotelek (Hungary)",
  "Kubekhaza (Hungary)",
  "Kulcs (Hungary)",
  "Kulsobarand (Hungary)",
  "Kunagota (Hungary)",
  "Kunbaja (Hungary)",
  "Kunhegyes (Hungary)",
  "Kunszentmarton (Hungary)",
  "Kunszentmiklos (Hungary)",
  "Kunsziget (Hungary)",
  "Kurd (Hungary)",
  "Kurityan (Hungary)",
  "Labatlan (Hungary)",
  "Labod (Hungary)",
  "Ladbesenyo (Hungary)",
  "Lajoskomarom (Hungary)",
  "Lajosmizse (Hungary)",
  "Lazi (Hungary)",
  "Leanyfalu (Hungary)",
  "Lebeny (Hungary)",
  "Lenti (Hungary)",
  "Letenye (Hungary)",
  "Levelek (Hungary)",
  "Lovas (Hungary)",
  "Lovaszi (Hungary)",
  "Madocsa (Hungary)",
  "Maglod (Hungary)",
  "Magy (Hungary)",
  "Magyaregres (Hungary)",
  "Magyarhertelend (Hungary)",
  "Magyarnandor (Hungary)",
  "Majoshaza (Hungary)",
  "Makad (Hungary)",
  "Makkoshotyka (Hungary)",
  "Maklar (Hungary)",
  "Mako (Hungary)",
  "Malyi (Hungary)",
  "Mandok (Hungary)",
  "Many (Hungary)",
  "Marcali (Hungary)",
  "Mariapocs (Hungary)",
  "Markaz (Hungary)",
  "Marokpapi (Hungary)",
  "Martely (Hungary)",
  "Martfu (Hungary)",
  "Martonvasar (Hungary)",
  "Mateszalka (Hungary)",
  "Matraballa (Hungary)",
  "Matraszolos (Hungary)",
  "Mecseknadasd (Hungary)",
  "Medgyesegyhaza (Hungary)",
  "Melykut (Hungary)",
  "Mende (Hungary)",
  "Mernye (Hungary)",
  "Mesztegnyo (Hungary)",
  "Mezobereny (Hungary)",
  "Mezofalva (Hungary)",
  "Mezohegyes (Hungary)",
  "Mezokovesd (Hungary)",
  "Mezonyarad (Hungary)",
  "Mezoszilas (Hungary)",
  "Mezotur (Hungary)",
  "Mindszent (Hungary)",
  "Misefa (Hungary)",
  "Miskolc (Hungary)",
  "Mogyorod (Hungary)",
  "Monor (Hungary)",
  "Monorierdo (Hungary)",
  "Mor (Hungary)",
  "Morahalom (Hungary)",
  "Mosonszentmiklos (Hungary)",
  "Mosonszolnok (Hungary)",
  "Mucsony (Hungary)",
  "Nagyatad (Hungary)",
  "Nagybarca (Hungary)",
  "Nagyberki (Hungary)",
  "Nagycenk (Hungary)",
  "Nagycserkesz (Hungary)",
  "Nagydobsza (Hungary)",
  "Nagyhalasz (Hungary)",
  "Nagyhegyes (Hungary)",
  "Nagyigmand (Hungary)",
  "Nagykallo (Hungary)",
  "Nagykanizsa (Hungary)",
  "Nagykapornak (Hungary)",
  "Nagykata (Hungary)",
  "Nagykereki (Hungary)",
  "Nagykeresztur (Hungary)",
  "Nagykoros (Hungary)",
  "Nagykoru (Hungary)",
  "Nagykovacsi (Hungary)",
  "Nagykozar (Hungary)",
  "Nagymagocs (Hungary)",
  "Nagymanyok (Hungary)",
  "Nagynyarad (Hungary)",
  "Nagypali (Hungary)",
  "Nagysap (Hungary)",
  "Nagyszekeres (Hungary)",
  "Nagyszenas (Hungary)",
  "Nagyszokoly (Hungary)",
  "Nagytarcsa (Hungary)",
  "Nagytotfalu (Hungary)",
  "Nagyvazsony (Hungary)",
  "Nagyvenyim (Hungary)",
  "Naszaly (Hungary)",
  "Nemesbod (Hungary)",
  "Nemesbuek (Hungary)",
  "Nemesvid (Hungary)",
  "Nemesvita (Hungary)",
  "Nemetker (Hungary)",
  "Neszmely (Hungary)",
  "Nezsa (Hungary)",
  "Nogradsap (Hungary)",
  "Nogradsipek (Hungary)",
  "Nyekladhaza (Hungary)",
  "Nyergesujfalu (Hungary)",
  "Nyiradony (Hungary)",
  "Nyirbator (Hungary)",
  "Nyiregyhaza (Hungary)",
  "Nyirmartonfalva (Hungary)",
  "Nyirtass (Hungary)",
  "Nyul (Hungary)",
  "Ocsa (Hungary)",
  "Ocseny (Hungary)",
  "Ocsod (Hungary)",
  "Ofeherto (Hungary)",
  "Onga (Hungary)",
  "Orbottyan (Hungary)",
  "Oroshaza (Hungary)",
  "Oroszlany (Hungary)",
  "Ostoros (Hungary)",
  "Otteveny (Hungary)",
  "Ozd (Hungary)",
  "Paka (Hungary)",
  "Pakod (Hungary)",
  "Pakozd (Hungary)",
  "Paks (Hungary)",
  "Palotabozsok (Hungary)",
  "Papa (Hungary)",
  "Part (Hungary)",
  "Paszto (Hungary)",
  "Patroha (Hungary)",
  "Patvarc (Hungary)",
  "Paty (Hungary)",
  "Pazmand (Hungary)",
  "Pecel (Hungary)",
  "Pecol (Hungary)",
  "Pellerd (Hungary)",
  "Per (Hungary)",
  "Perbal (Hungary)",
  "Perenye (Hungary)",
  "Peteri (Hungary)",
  "Petfuerdo (Hungary)",
  "Petofibanya (Hungary)",
  "Petohaza (Hungary)",
  "Pilis (Hungary)",
  "Piliscsaba (Hungary)",
  "Pilismarot (Hungary)",
  "Pilisszanto (Hungary)",
  "Pilisszentivan (Hungary)",
  "Pilisszentkereszt (Hungary)",
  "Pilisvorosvar (Hungary)",
  "Polgar (Hungary)",
  "Polgardi (Hungary)",
  "Pomaz (Hungary)",
  "Posfa (Hungary)",
  "Puspokladany (Hungary)",
  "Pusztaszabolcs (Hungary)",
  "Pusztaszemes (Hungary)",
  "Pusztavacs (Hungary)",
  "Pusztavam (Hungary)",
  "Pusztazamor (Hungary)",
  "Rabapatona (Hungary)",
  "Rabapaty (Hungary)",
  "Racalmas (Hungary)",
  "Rackeresztur (Hungary)",
  "Rackeve (Hungary)",
  "Rad (Hungary)",
  "Rajka (Hungary)",
  "Rakoczifalva (Hungary)",
  "Rakocziujfalu (Hungary)",
  "Repcelak (Hungary)",
  "Repceszemere (Hungary)",
  "Retsag (Hungary)",
  "Revfueloep (Hungary)",
  "Rimoc (Hungary)",
  "Roszke (Hungary)",
  "Rudabanya (Hungary)",
  "Sajokapolna (Hungary)",
  "Sajokeresztur (Hungary)",
  "Sajooeroes (Hungary)",
  "Sajopalfala (Hungary)",
  "Sajoszentpeter (Hungary)",
  "Sajovamos (Hungary)",
  "Salfold (Hungary)",
  "Salgotarjan (Hungary)",
  "Sandorfalva (Hungary)",
  "Sarbogard (Hungary)",
  "Sarisap (Hungary)",
  "Sarkad (Hungary)",
  "Sarmellek (Hungary)",
  "Sarosd (Hungary)",
  "Sarospatak (Hungary)",
  "Sarpentele (Hungary)",
  "Sarpilis (Hungary)",
  "Sarszentagota (Hungary)",
  "Sarszentlorinc (Hungary)",
  "Sarszentmihaly (Hungary)",
  "Sarvar (Hungary)",
  "Sasd (Hungary)",
  "Saska (Hungary)",
  "Se (Hungary)",
  "Selyeb (Hungary)",
  "Senyo (Hungary)",
  "Seregelyes (Hungary)",
  "Siklos (Hungary)",
  "Simontornya (Hungary)",
  "Sirok (Hungary)",
  "Solt (Hungary)",
  "Soltszentimre (Hungary)",
  "Solymar (Hungary)",
  "Somogysard (Hungary)",
  "Somogyudvarhely (Hungary)",
  "Sopron (Hungary)",
  "Sopronkovesd (Hungary)",
  "Sored (Hungary)",
  "Soskut (Hungary)",
  "Suelysap (Hungary)",
  "Sukoro (Hungary)",
  "Sumeg (Hungary)",
  "Szabadbattyan (Hungary)",
  "Szabadszallas (Hungary)",
  "Szabadszentkiraly (Hungary)",
  "Szabolcs (Hungary)",
  "Szabolcsveresmart (Hungary)",
  "Szajk (Hungary)",
  "Szajol (Hungary)",
  "Szalaszend (Hungary)",
  "Szany (Hungary)",
  "Szar (Hungary)",
  "Szarvas (Hungary)",
  "Szazhalombatta (Hungary)",
  "Szecseny (Hungary)",
  "Szecsenyfelfalu (Hungary)",
  "Szeged (Hungary)",
  "Szeghalom (Hungary)",
  "Szegvar (Hungary)",
  "Szemely (Hungary)",
  "Szendehely (Hungary)",
  "Szentendre (Hungary)",
  "Szentes (Hungary)",
  "Szentgotthard (Hungary)",
  "Szentistvan (Hungary)",
  "Szentkatalin (Hungary)",
  "Szentkiralyszabadja (Hungary)",
  "Szentlorinc (Hungary)",
  "Szentmartonkata (Hungary)",
  "Szepetnek (Hungary)",
  "Szeremle (Hungary)",
  "Szerencs (Hungary)",
  "Szigetcsep (Hungary)",
  "Szigethalom (Hungary)",
  "Szigetszentmarton (Hungary)",
  "Szigetszentmiklos (Hungary)",
  "Szigetvar (Hungary)",
  "Szigliget (Hungary)",
  "Szikszo (Hungary)",
  "Szilsarkany (Hungary)",
  "Szirmabesenyo (Hungary)",
  "Szod (Hungary)",
  "Szodliget (Hungary)",
  "Szogliget (Hungary)",
  "Szokolya (Hungary)",
  "Szolnok (Hungary)",
  "Szombathely (Hungary)",
  "Szomod (Hungary)",
  "Szomor (Hungary)",
  "Szuhakallo (Hungary)",
  "Tab (Hungary)",
  "Tabajd (Hungary)",
  "Taborfalva (Hungary)",
  "Taksony (Hungary)",
  "Taktaszada (Hungary)",
  "Tamasi (Hungary)",
  "Tapiobicske (Hungary)",
  "Tapiogyorgye (Hungary)",
  "Tapiosag (Hungary)",
  "Tapioszecso (Hungary)",
  "Tapioszele (Hungary)",
  "Taplanszentkereszt (Hungary)",
  "Tapolca (Hungary)",
  "Tar (Hungary)",
  "Tarcal (Hungary)",
  "Tarjan (Hungary)",
  "Tarnok (Hungary)",
  "Tarpa (Hungary)",
  "Tass (Hungary)",
  "Taszar (Hungary)",
  "Tat (Hungary)",
  "Tata (Hungary)",
  "Teglas (Hungary)",
  "Telekes (Hungary)",
  "Telep (Hungary)",
  "Telki (Hungary)",
  "Tepe (Hungary)",
  "Terem (Hungary)",
  "Tet (Hungary)",
  "Tetetlen (Hungary)",
  "Tevel (Hungary)",
  "Tinnye (Hungary)",
  "Tiszaadony (Hungary)",
  "Tiszabo (Hungary)",
  "Tiszacsege (Hungary)",
  "Tiszafoldvar (Hungary)",
  "Tiszafured (Hungary)",
  "Tiszalok (Hungary)",
  "Tiszaluc (Hungary)",
  "Tiszapalkonya (Hungary)",
  "Tiszaszalka (Hungary)",
  "Tiszaujvaros (Hungary)",
  "Tiszavalk (Hungary)",
  "Tiszavasvari (Hungary)",
  "Toekoel (Hungary)",
  "Toeroekbalint (Hungary)",
  "Tokodaltaro (Hungary)",
  "Tolna (Hungary)",
  "Tomorkeny (Hungary)",
  "Tompa (Hungary)",
  "Tordas (Hungary)",
  "Torokszentmiklos (Hungary)",
  "Torony (Hungary)",
  "Toszeg (Hungary)",
  "Totkomlos (Hungary)",
  "Totszerdahely (Hungary)",
  "Tura (Hungary)",
  "Turkeve (Hungary)",
  "Ujfeherto (Hungary)",
  "Ujkenez (Hungary)",
  "Ujkigyos (Hungary)",
  "Ujlengyel (Hungary)",
  "Ujszasz (Hungary)",
  "Ujszentmargita (Hungary)",
  "Ujszilvas (Hungary)",
  "Ujudvar (Hungary)",
  "Ullo (Hungary)",
  "Uraiujfalu (Hungary)",
  "Uri (Hungary)",
  "Urom (Hungary)",
  "Vacduka (Hungary)",
  "Vacratot (Hungary)",
  "Vacszentlaszlo (Hungary)",
  "Vadna (Hungary)",
  "Vaja (Hungary)",
  "Vajszlo (Hungary)",
  "Valko (Hungary)",
  "Valkonya (Hungary)",
  "Vamospercs (Hungary)",
  "Vamosszabadi (Hungary)",
  "Varalja (Hungary)",
  "Varbalog (Hungary)",
  "Varbo (Hungary)",
  "Vardomb (Hungary)",
  "Varosfoeld (Hungary)",
  "Varpalota (Hungary)",
  "Vasarosdombo (Hungary)",
  "Vasvar (Hungary)",
  "Vecses (Hungary)",
  "Vegegyhaza (Hungary)",
  "Velence (Hungary)",
  "Vep (Hungary)",
  "Veresegyhaz (Hungary)",
  "Veroce (Hungary)",
  "Verseg (Hungary)",
  "Vertessomlo (Hungary)",
  "Vertesszolos (Hungary)",
  "Vertestolna (Hungary)",
  "Vezseny (Hungary)",
  "Visznek (Hungary)",
  "Vitnyed (Hungary)",
  "Vizslas (Hungary)",
  "Zagyvaszanto (Hungary)",
  "Zahony (Hungary)",
  "Zalaegerszeg (Hungary)",
  "Zalakomar (Hungary)",
  "Zalalovo (Hungary)",
  "Zalaszentgrot (Hungary)",
  "Zamoly (Hungary)",
  "Zebecke (Hungary)",
  "Zebegeny (Hungary)",
  "Zichyujfalu (Hungary)",
  "Zirc (Hungary)",
  "Zsambek (Hungary)",
  "Zsambok (Hungary)",
  "Zsombo (Hungary)",
  "Iceland",
  "Akranes (Iceland)",
  "Akureyri (Iceland)",
  "Borgarnes (Iceland)",
  "Dalvik (Iceland)",
  "Grindavik (Iceland)",
  "Hella (Iceland)",
  "Holmavik (Iceland)",
  "Husavik (Iceland)",
  "Hvammstangi (Iceland)",
  "Hveragerdi (Iceland)",
  "Hvolsvollur (Iceland)",
  "Kopavogur (Iceland)",
  "Reykjavik (Iceland)",
  "Selfoss (Iceland)",
  "Skagastrond (Iceland)",
  "Stokkseyri (Iceland)",
  "Vestmannaeyjar (Iceland)",
  "Vogar (Iceland)",
  "India",
  "Abdul (India)",
  "Adilabad (India)",
  "Adwani (India)",
  "Agartala (India)",
  "Agra (India)",
  "Ahmedabad (India)",
  "Ahmednagar (India)",
  "Aizawl (India)",
  "Ajabpur (India)",
  "Ajmer (India)",
  "Akividu (India)",
  "Akola (India)",
  "Alanallur (India)",
  "Alangulam (India)",
  "Alappuzha (India)",
  "Aldona (India)",
  "Alibag (India)",
  "Aligarh (India)",
  "Alipur (India)",
  "Allahabad (India)",
  "Almora (India)",
  "Aluva (India)",
  "Alwar (India)",
  "Amal (India)",
  "Amalapuram (India)",
  "Ambad (India)",
  "Ambah (India)",
  "Ambala (India)",
  "Ambarnath (India)",
  "Ambejogai (India)",
  "Ambikapur (India)",
  "Ambur (India)",
  "Amer (India)",
  "Amet (India)",
  "Amravati (India)",
  "Amreli (India)",
  "Amritsar (India)",
  "Anand (India)",
  "Anantapur (India)",
  "Anantnag (India)",
  "Anantpur (India)",
  "Anchal (India)",
  "Andheri (India)",
  "Andra (India)",
  "Angadipuram (India)",
  "Angul (India)",
  "Ankleshwar (India)",
  "Annamalainagar (India)",
  "Antapur (India)",
  "Arakkonam (India)",
  "Arani (India)",
  "Aranmula (India)",
  "Arch (India)",
  "Ariyalur (India)",
  "Arora (India)",
  "Arpora (India)",
  "Arunachal (India)",
  "Arvi (India)",
  "Asansol (India)",
  "Assagao (India)",
  "Attingal (India)",
  "Attur (India)",
  "Aundh (India)",
  "Aurangabad (India)",
  "Avanigadda (India)",
  "Azamgarh (India)",
  "Baddi (India)",
  "Badlapur (India)",
  "Bagalkot (India)",
  "Bagh (India)",
  "Bagpat (India)",
  "Bahadurgarh (India)",
  "Baharampur (India)",
  "Baidyabati (India)",
  "Bala (India)",
  "Balaghat (India)",
  "Balana (India)",
  "Balanagar (India)",
  "Balangir (India)",
  "Balasore (India)",
  "Bali (India)",
  "Ballabgarh (India)",
  "Balu (India)",
  "Balurghat (India)",
  "Bambolim (India)",
  "Banda (India)",
  "Bandra (India)",
  "Banga (India)",
  "Bangalore (India)",
  "Bangaon (India)",
  "Bank (India)",
  "Banka (India)",
  "Bankura (India)",
  "Banswara (India)",
  "Bapatla (India)",
  "Barakpur (India)",
  "Baramati (India)",
  "Barddhaman (India)",
  "Bardoli (India)",
  "Bareilly (India)",
  "Bargarh (India)",
  "Barmer (India)",
  "Barnala (India)",
  "Baroda (India)",
  "Barpali (India)",
  "Barpeta (India)",
  "Basirhat (India)",
  "Basti (India)",
  "Basu (India)",
  "Batala (India)",
  "Bawan (India)",
  "Bawana (India)",
  "Beawar (India)",
  "Begusarai (India)",
  "Behala (India)",
  "Bela (India)",
  "Belapur (India)",
  "Belgaum (India)",
  "Belgharia (India)",
  "Bellare (India)",
  "Bellary (India)",
  "Bemetara (India)",
  "Berasia (India)",
  "Betalbatim (India)",
  "Betim (India)",
  "Betul (India)",
  "Bhadath (India)",
  "Bhadohi (India)",
  "Bhadravati (India)",
  "Bhagalpur (India)",
  "Bhagwan (India)",
  "Bhandari (India)",
  "Bhandup (India)",
  "Bharatpur (India)",
  "Bharuch (India)",
  "Bhatapara (India)",
  "Bhatinda (India)",
  "Bhatkal (India)",
  "Bhavnagar (India)",
  "Bhawan (India)",
  "Bhilai (India)",
  "Bhilwara (India)",
  "Bhimavaram (India)",
  "Bhiwandi (India)",
  "Bhiwani (India)",
  "Bhoj (India)",
  "Bhongir (India)",
  "Bhopal (India)",
  "Bhubaneswar (India)",
  "Bhuj (India)",
  "Bhusawal (India)",
  "Bichpuri (India)",
  "Bidar (India)",
  "Bihar Sharif (India)",
  "Bijapur (India)",
  "Bikaner (India)",
  "Bilaspur (India)",
  "Bilimora (India)",
  "Binavas (India)",
  "Binnaguri (India)",
  "Bishnupur (India)",
  "Bobbili (India)",
  "Bodhan (India)",
  "Bodinayakkanur (India)",
  "Boisar (India)",
  "Bokaro (India)",
  "Bolpur (India)",
  "Botad (India)",
  "Brahmapur (India)",
  "Budaun (India)",
  "Budbud (India)",
  "Budha (India)",
  "Bulandshahr (India)",
  "Bundi (India)",
  "Calangute (India)",
  "Candolim (India)",
  "Canning (India)",
  "Caranzalem (India)",
  "Chakan (India)",
  "Chakra (India)",
  "Chalisgaon (India)",
  "Chamba (India)",
  "Champa (India)",
  "Chand (India)",
  "Chandan (India)",
  "Chandannagar (India)",
  "Chandauli (India)",
  "Chandausi (India)",
  "Chandigarh (India)",
  "Chandrapur (India)",
  "Changanacheri (India)",
  "Channapatna (India)",
  "Charan (India)",
  "Charu (India)",
  "Chen (India)",
  "Chengannur (India)",
  "Chennai (India)",
  "Chetan (India)",
  "Cheyyar (India)",
  "Chhabra (India)",
  "Chhachhrauli (India)",
  "Chhota Udepur (India)",
  "Chicalim (India)",
  "Chidambaram (India)",
  "Chikodi (India)",
  "Chinchvad (India)",
  "Chintamani (India)",
  "Chiplun (India)",
  "Chirala (India)",
  "Chitra (India)",
  "Chitradurga (India)",
  "Chittoor (India)",
  "Chittur (India)",
  "Choolai (India)",
  "Chopda (India)",
  "Chopra (India)",
  "Churachandpur (India)",
  "Coimbatore (India)",
  "Colaba (India)",
  "Connaught Place (India)",
  "Coonoor (India)",
  "Cuddalore (India)",
  "Cumbum (India)",
  "Cuncolim (India)",
  "Curchorem (India)",
  "Cuttack (India)",
  "Dadri (India)",
  "Dahanu (India)",
  "Dahod (India)",
  "Dam Dam (India)",
  "Daman (India)",
  "Damoh (India)",
  "Dang (India)",
  "Dangi (India)",
  "Darbhanga (India)",
  "Darjeeling (India)",
  "Darsi (India)",
  "Dasna (India)",
  "Dasua (India)",
  "Davangere (India)",
  "Dehradun (India)",
  "Delhi (India)",
  "Deolali (India)",
  "Deoria (India)",
  "Devgarh (India)",
  "Devipattinam (India)",
  "Dewas (India)",
  "Dhaka (India)",
  "Dhamtari (India)",
  "Dhanbad (India)",
  "Dhansura (India)",
  "Dhar (India)",
  "Dharamsala (India)",
  "Dharapuram (India)",
  "Dharavi (India)",
  "Dhariwal (India)",
  "Dharmapuri (India)",
  "Dharwad (India)",
  "Dhenkanal (India)",
  "Dhone (India)",
  "Dhrol (India)",
  "Dhubri (India)",
  "Dhule (India)",
  "Dhuri (India)",
  "Dibrugarh (India)",
  "Dicholi (India)",
  "Dimapur (India)",
  "Dinanagar (India)",
  "Dindigul (India)",
  "Dindori (India)",
  "Dipas (India)",
  "Dogadda (India)",
  "Dona Paula (India)",
  "Dumka (India)",
  "Durg (India)",
  "Durgapur (India)",
  "Dwarahat (India)",
  "Dwarka (India)",
  "Edavanna (India)",
  "Ekkattuthangal (India)",
  "Ellora Caves (India)",
  "Eluru (India)",
  "Eral (India)",
  "Ernakulam (India)",
  "Erode (India)",
  "Etawah (India)",
  "Faizabad (India)",
  "Farakka (India)",
  "Faridabad (India)",
  "Faridkot (India)",
  "Fatehabad (India)",
  "Fatehgarh (India)",
  "Fatehpur (India)",
  "Firozabad (India)",
  "Firozpur (India)",
  "Fort (India)",
  "Gadag (India)",
  "Gampalagudem (India)",
  "Gandhidham (India)",
  "Gandhigram (India)",
  "Gandhinagar (India)",
  "Ganga (India)",
  "Ganganagar (India)",
  "Gangapur (India)",
  "Gangrar (India)",
  "Gangtok (India)",
  "Gannavaram (India)",
  "Ganpat (India)",
  "Gargoti (India)",
  "Garhshankar (India)",
  "Gaya (India)",
  "Ghana (India)",
  "Ghatal (India)",
  "Ghatkopar (India)",
  "Ghaziabad (India)",
  "Goa (India)",
  "Gobichettipalayam (India)",
  "Godhra (India)",
  "Gohana (India)",
  "Golaghat (India)",
  "Gold (India)",
  "Gonda (India)",
  "Gorakhpur (India)",
  "Goregaon (India)",
  "Goshaingaon (India)",
  "Gudivada (India)",
  "Gudur (India)",
  "Guindy (India)",
  "Gujrat (India)",
  "Gulbarga (India)",
  "Guna (India)",
  "Guntur (India)",
  "Gurdaspur (India)",
  "Gurgaon (India)",
  "Guruvayur (India)",
  "Guwahati (India)",
  "Gwalior (India)",
  "Habra (India)",
  "Hadadi (India)",
  "Haldia (India)",
  "Haldwani (India)",
  "Hamirpur (India)",
  "Hansi (India)",
  "Hapur (India)",
  "Hari (India)",
  "Haridwar (India)",
  "Haripad (India)",
  "Haripur (India)",
  "Haryana (India)",
  "Hassan (India)",
  "Haveri (India)",
  "Hazaribagh (India)",
  "Himatnagar (India)",
  "Hinganghat (India)",
  "Hingoli (India)",
  "Hira (India)",
  "Hiriyur (India)",
  "Hisar (India)",
  "Honavar (India)",
  "Hong (India)",
  "Hoshangabad (India)",
  "Hoshiarpur (India)",
  "Hosur (India)",
  "Howrah (India)",
  "Hubli (India)",
  "Hugli (India)",
  "Hyderabad (India)",
  "Ichalkaranji (India)",
  "Idukki (India)",
  "Igatpuri (India)",
  "Iglas (India)",
  "Imphal (India)",
  "Indore (India)",
  "Indraprast (India)",
  "Irinjalakuda (India)",
  "Itanagar (India)",
  "Jabalpur (India)",
  "Jadabpur (India)",
  "Jagdalpur (India)",
  "Jagraon (India)",
  "Jaipur (India)",
  "Jaisalmer (India)",
  "Jajpur (India)",
  "Jalalabad (India)",
  "Jalalpur (India)",
  "Jalandhar (India)",
  "Jalesar (India)",
  "Jalgaon Jamod (India)",
  "Jalna (India)",
  "Jalpaiguri (India)",
  "Jamal (India)",
  "Jammu (India)",
  "Jamnagar (India)",
  "Jamshedpur (India)",
  "Janjgir (India)",
  "Jaspur (India)",
  "Jatani (India)",
  "Jaunpur (India)",
  "Jayanti (India)",
  "Jaynagar (India)",
  "Jaypur (India)",
  "Jha Jha (India)",
  "Jhajjar (India)",
  "Jhalawar (India)",
  "Jhansi (India)",
  "Jhargram (India)",
  "Jharsuguda (India)",
  "Jhunjhunun (India)",
  "Jind (India)",
  "Jodhpur (India)",
  "Jorhat (India)",
  "Junagadh (India)",
  "Kadapa (India)",
  "Kagal (India)",
  "Kailaras (India)",
  "Kaimganj (India)",
  "Kaithal (India)",
  "Kakdwip (India)",
  "Kakinada (India)",
  "Kaladi (India)",
  "Kalam (India)",
  "Kalamboli (India)",
  "Kalan (India)",
  "Kalinga (India)",
  "Kalka (India)",
  "Kalkaji Devi (India)",
  "Kalol (India)",
  "Kalpakkam (India)",
  "Kalpetta (India)",
  "Kalra (India)",
  "Kalyan (India)",
  "Kalyani (India)",
  "Kamalpur (India)",
  "Kamalpura (India)",
  "Kamat (India)",
  "Kanakpura (India)",
  "Kanchipuram (India)",
  "Kanchrapara (India)",
  "Kandi (India)",
  "Kangayam (India)",
  "Kangra (India)",
  "Kanhangad (India)",
  "Kanigiri (India)",
  "Kaniyambadi (India)",
  "Kankauli (India)",
  "Kanniyakumari (India)",
  "Kannur (India)",
  "Kanpur (India)",
  "Kapurthala Town (India)",
  "Karad (India)",
  "Karaikal (India)",
  "Karaikudi (India)",
  "Karamadai (India)",
  "Karamsad (India)",
  "Karanja (India)",
  "Karari (India)",
  "Kargil (India)",
  "Karimganj (India)",
  "Karimnagar (India)",
  "Karjat (India)",
  "Karnal (India)",
  "Karsiyang (India)",
  "Karur (India)",
  "Karwar (India)",
  "Kasal (India)",
  "Kasaragod (India)",
  "Kasganj (India)",
  "Kashipur (India)",
  "Kasia (India)",
  "Kataria (India)",
  "Kathua (India)",
  "Katni (India)",
  "Katoya (India)",
  "Katra (India)",
  "Kaul (India)",
  "Kavali (India)",
  "Kavaratti (India)",
  "Kayamkulam (India)",
  "Keshod (India)",
  "Khajuraho Group of Monuments (India)",
  "Khalapur (India)",
  "Khambhat (India)",
  "Khammam (India)",
  "Khan (India)",
  "Khanna (India)",
  "Kharagpur (India)",
  "Kharar (India)",
  "Khargone (India)",
  "Khatauli (India)",
  "Kheda (India)",
  "Khergam (India)",
  "Kheri (India)",
  "Khinwara (India)",
  "Khopoli (India)",
  "Khurda (India)",
  "Khurja (India)",
  "Kishangarh (India)",
  "Koch Bihar (India)",
  "Kochi (India)",
  "Kodaikanal (India)",
  "Kodungallur (India)",
  "Kohima (India)",
  "Kokrajhar (India)",
  "Kolar (India)",
  "Kolayat (India)",
  "Kolhapur (India)",
  "Kolkata (India)",
  "Kollam (India)",
  "Kollegal (India)",
  "Koni (India)",
  "Konnagar (India)",
  "Koothanallur (India)",
  "Koppal (India)",
  "Koraput (India)",
  "Korba (India)",
  "Kosamba (India)",
  "Kot Isa Khan (India)",
  "Kota (India)",
  "Kotian (India)",
  "Kottagudem (India)",
  "Kottakkal (India)",
  "Kottarakara (India)",
  "Kottayam (India)",
  "Kovilpatti (India)",
  "Kovvur (India)",
  "Kozhikode (India)",
  "Krishnagiri (India)",
  "Kulti (India)",
  "Kumar (India)",
  "Kumbakonam (India)",
  "Kumhari (India)",
  "Kundan (India)",
  "Kunwar (India)",
  "Kuppam (India)",
  "Kurali (India)",
  "Kurnool (India)",
  "Kushalnagar (India)",
  "Kuzhithurai (India)",
  "Ladwa (India)",
  "Lakhimpur (India)",
  "Lala (India)",
  "Lalgudi (India)",
  "Lamba Harisingh (India)",
  "Lanka (India)",
  "Latur (India)",
  "Liluah (India)",
  "Lohaghat (India)",
  "Lucknow (India)",
  "Ludhiana (India)",
  "Machhiwara (India)",
  "Machilipatnam (India)",
  "Madanapalle (India)",
  "Madgaon (India)",
  "Madhoganj (India)",
  "Madikeri (India)",
  "Madurai (India)",
  "Madurantakam (India)",
  "Mahabalipuram (India)",
  "Mahad (India)",
  "Mahajan (India)",
  "Mahal (India)",
  "Maharaj (India)",
  "Mahatma (India)",
  "Mahesana (India)",
  "Mahesh (India)",
  "Mahim (India)",
  "Mahulia (India)",
  "Malappuram (India)",
  "Maldah (India)",
  "Malpur (India)",
  "Manali (India)",
  "Mancherial (India)",
  "Mandal (India)",
  "Mandapeta (India)",
  "Mandi (India)",
  "Mandla (India)",
  "Mandsaur (India)",
  "Mandvi (India)",
  "Mandya (India)",
  "Mangalagiri (India)",
  "Mangalore (India)",
  "Mangaon (India)",
  "Manipala (India)",
  "Manipur (India)",
  "Manjeri (India)",
  "Manna (India)",
  "Mannargudi (India)",
  "Manor (India)",
  "Mansa (India)",
  "Manu (India)",
  "Markal (India)",
  "Markapur (India)",
  "Marmagao (India)",
  "Maru (India)",
  "Mashobra (India)",
  "Matar (India)",
  "Mathan (India)",
  "Mathura (India)",
  "Mattanur (India)",
  "Mavelikara (India)",
  "Mawana (India)",
  "Mayapur (India)",
  "Medak (India)",
  "Medarametla (India)",
  "Medchal (India)",
  "Medinipur (India)",
  "Meerut (India)",
  "Mehra (India)",
  "Mettur (India)",
  "Mhow (India)",
  "Mill (India)",
  "Miraj (India)",
  "Mirza Murad (India)",
  "Mirzapur (India)",
  "Mithapur (India)",
  "Modasa (India)",
  "Moga (India)",
  "Mohala (India)",
  "Mohali (India)",
  "Mohan (India)",
  "Moradabad (India)",
  "Morena (India)",
  "Morinda (India)",
  "Morvi (India)",
  "Motihari (India)",
  "Mount Abu (India)",
  "Muddanuru (India)",
  "Mukerian (India)",
  "Muktsar (India)",
  "Multi (India)",
  "Mumbai (India)",
  "Mundgod (India)",
  "Mundra (India)",
  "Munger (India)",
  "Murshidabad (India)",
  "Mussoorie (India)",
  "Muzaffarnagar (India)",
  "Muzaffarpur (India)",
  "Mylapore (India)",
  "Mysore (India)",
  "Nabadwip (India)",
  "Nabha (India)",
  "Nadgaon (India)",
  "Nadia (India)",
  "Nadiad (India)",
  "Nagal (India)",
  "Nagapattinam (India)",
  "Nagar (India)",
  "Nagara (India)",
  "Nagari (India)",
  "Nagaur (India)",
  "Nagercoil (India)",
  "Nagpur (India)",
  "Nagwa (India)",
  "Naini (India)",
  "Nalagarh (India)",
  "Nalbari (India)",
  "Nalgonda (India)",
  "Namakkal (India)",
  "Namrup (India)",
  "Nanda (India)",
  "Nanded (India)",
  "Nandi (India)",
  "Nandigama (India)",
  "Nandurbar (India)",
  "Nandyal (India)",
  "Naraina (India)",
  "Narasaraopet (India)",
  "Narayangaon (India)",
  "Narela (India)",
  "Narnaul (India)",
  "Narsapur (India)",
  "Nashik (India)",
  "Nathdwara (India)",
  "Navelim (India)",
  "Navsari (India)",
  "Nayagarh (India)",
  "Nazira (India)",
  "Nehra (India)",
  "Nellore (India)",
  "Neral (India)",
  "Neri (India)",
  "New Delhi (India)",
  "Neyveli (India)",
  "Nila (India)",
  "Nilambur (India)",
  "Nilokheri (India)",
  "Nizamabad (India)",
  "Noida (India)",
  "Nongpoh (India)",
  "Nongstoin (India)",
  "North Lakhimpur (India)",
  "Nurpur (India)",
  "Nuzvid (India)",
  "Odhan (India)",
  "Omalur (India)",
  "Ongole (India)",
  "Ooty (India)",
  "Orai (India)",
  "Osmanabad (India)",
  "Ottappalam (India)",
  "Pachmarhi (India)",
  "Padrauna (India)",
  "Pahalgam (India)",
  "Pakala (India)",
  "Pala (India)",
  "Palakkad (India)",
  "Palampur (India)",
  "Palani (India)",
  "Palayam (India)",
  "Palghar (India)",
  "Pali (India)",
  "Palladam (India)",
  "Paloncha (India)",
  "Palus (India)",
  "Palwal (India)",
  "Panchal (India)",
  "Panchgani (India)",
  "Pandharpur (India)",
  "Panipat (India)",
  "Panjim (India)",
  "Panruti (India)",
  "Pantnagar (India)",
  "Panvel (India)",
  "Paonta Sahib (India)",
  "Parappanangadi (India)",
  "Paravur (India)",
  "Parbhani (India)",
  "Parel (India)",
  "Parra (India)",
  "Patan (India)",
  "Patancheru (India)",
  "Patel (India)",
  "Patelguda (India)",
  "Pathanamthitta (India)",
  "Pathankot (India)",
  "Patiala (India)",
  "Patna (India)",
  "Pattambi (India)",
  "Pattukkottai (India)",
  "Pauri (India)",
  "Payyanur (India)",
  "Peddapuram (India)",
  "Pehowa (India)",
  "Perambalur (India)",
  "Peranampattu (India)",
  "Perundurai (India)",
  "Petlad (India)",
  "Phagwara (India)",
  "Phaphamau (India)",
  "Piduguralla (India)",
  "Pilani (India)",
  "Pileru (India)",
  "Pilkhuwa (India)",
  "Pimpri (India)",
  "Pitampura (India)",
  "Pithapuram (India)",
  "Pithoragarh (India)",
  "Pochampalli (India)",
  "Pollachi (India)",
  "Ponda (India)",
  "Ponnani (India)",
  "Ponneri (India)",
  "Porbandar (India)",
  "Port Blair (India)",
  "Potti (India)",
  "Powai (India)",
  "Proddatur (India)",
  "Puducherry (India)",
  "Pudukkottai (India)",
  "Puliyur (India)",
  "Punalur (India)",
  "Pune (India)",
  "Puras (India)",
  "Puri (India)",
  "Purnea (India)",
  "Puruliya (India)",
  "Pusa (India)",
  "Pushkar (India)",
  "Puttur (India)",
  "Quepem (India)",
  "Raichur (India)",
  "Raigarh (India)",
  "Raipur (India)",
  "Rajahmundry (India)",
  "Rajapalaiyam (India)",
  "Rajapur (India)",
  "Rajkot (India)",
  "Rajpur (India)",
  "Rajpura (India)",
  "Raju (India)",
  "Rama (India)",
  "Ramanagaram (India)",
  "Ramanathapuram (India)",
  "Ramapuram (India)",
  "Ramavaram (India)",
  "Ramgarh (India)",
  "Ramnagar (India)",
  "Rampur (India)",
  "Rana (India)",
  "Ranaghat (India)",
  "Ranchi (India)",
  "Rander (India)",
  "Raniganj (India)",
  "Ranippettai (India)",
  "Ranjan (India)",
  "Ratlam (India)",
  "Ratnagiri (India)",
  "Raurkela (India)",
  "Rawal (India)",
  "Raxaul (India)",
  "Rayagada (India)",
  "Rewa (India)",
  "Rewari (India)",
  "Ring (India)",
  "Rishikesh (India)",
  "Rohtak (India)",
  "Roorkee (India)",
  "Roshan (India)",
  "Rudrapur (India)",
  "Rupnagar (India)",
  "Rupnarayanpur (India)",
  "Sachin (India)",
  "Sagar (India)",
  "Saha (India)",
  "Saharanpur (India)",
  "Sahibabad (India)",
  "Sakri (India)",
  "Salem (India)",
  "Saligao (India)",
  "Salt Lake City (India)",
  "Samastipur (India)",
  "Sambalpur (India)",
  "Sanand (India)",
  "Sandur (India)",
  "Sangam (India)",
  "Sangamner (India)",
  "Sangli (India)",
  "Sangola (India)",
  "Sangrur (India)",
  "Sanquelim (India)",
  "Saranga (India)",
  "Sarangi (India)",
  "Sarwar (India)",
  "Satara (India)",
  "Satna (India)",
  "Sattur (India)",
  "Sawi (India)",
  "Secunderabad (India)",
  "Sehore (India)",
  "Sendhwa (India)",
  "Serampore (India)",
  "Shadnagar (India)",
  "Shahabad (India)",
  "Shahapur (India)",
  "Shahdara (India)",
  "Shahdol (India)",
  "Shahjahanpur (India)",
  "Shahkot (India)",
  "Shamsabad (India)",
  "Shanti Grama (India)",
  "Shillong (India)",
  "Shimla (India)",
  "Shimoga (India)",
  "Shirgaon (India)",
  "Shiv (India)",
  "Sholavandan (India)",
  "Shoranur (India)",
  "Shrigonda (India)",
  "Shyamnagar (India)",
  "Sibsagar (India)",
  "Sidhi (India)",
  "Sidhpur (India)",
  "Sikar (India)",
  "Sikka (India)",
  "Silchar (India)",
  "Siliguri (India)",
  "Silvassa (India)",
  "Singarayakonda (India)",
  "Singtam (India)",
  "Sinnar (India)",
  "Sion (India)",
  "Sirhind (India)",
  "Sirkazhi (India)",
  "Sirohi (India)",
  "Sirsa (India)",
  "Sirsi (India)",
  "Siruguppa (India)",
  "Siruseri (India)",
  "Sirwani (India)",
  "Sitapur (India)",
  "Siuri (India)",
  "Sivaganga (India)",
  "Sivakasi (India)",
  "Sodhi (India)",
  "Sojat (India)",
  "Solan (India)",
  "Solapur (India)",
  "Solim (India)",
  "Somnath (India)",
  "Soni (India)",
  "Sonipat (India)",
  "Sopara (India)",
  "Srikakulam (India)",
  "Srikalahasti (India)",
  "Srinagar (India)",
  "Sriperumbudur (India)",
  "Srirangam (India)",
  "Srivilliputhur (India)",
  "Sukma (India)",
  "Sultan (India)",
  "Sultanpur (India)",
  "Sultans Battery (India)",
  "Suman (India)",
  "Sunam (India)",
  "Sundargarh (India)",
  "Surana (India)",
  "Suratgarh (India)",
  "Surendranagar (India)",
  "Suriapet (India)",
  "Tadepallegudem (India)",
  "Tala (India)",
  "Talcher (India)",
  "Talegaon Dabhade (India)",
  "Talwandi Sabo (India)",
  "Tambaram (India)",
  "Tanda (India)",
  "Tanuku (India)",
  "Tarn Taran (India)",
  "Teri (India)",
  "Tezpur (India)",
  "Thalassery (India)",
  "Thane (India)",
  "Thanjavur (India)",
  "Thasra (India)",
  "Thenali (India)",
  "Thenkasi (India)",
  "Thirumangalam (India)",
  "Thiruthani (India)",
  "Thiruvananthapuram (India)",
  "Thiruvarur (India)",
  "Thoothukudi (India)",
  "Thrissur (India)",
  "Tikamgarh (India)",
  "Tindivanam (India)",
  "Tinsukia (India)",
  "Tiptur (India)",
  "Tiruchchendur (India)",
  "Tiruchi (India)",
  "Tirumala (India)",
  "Tirumala - Tirupati (India)",
  "Tirunelveli (India)",
  "Tiruppur (India)",
  "Tirur (India)",
  "Tiruvalla (India)",
  "Tiruvallur (India)",
  "Tiruvannamalai (India)",
  "Tohana (India)",
  "Tonk (India)",
  "Trimbak (India)",
  "Tuljapur (India)",
  "Turaiyur (India)",
  "Udaigiri (India)",
  "Udaipur (India)",
  "Udupi (India)",
  "Ujjain (India)",
  "Ulhasnagar (India)",
  "Ulubari (India)",
  "Umred (India)",
  "Unnao (India)",
  "Uppal (India)",
  "Uttarkashi (India)",
  "Vadamadurai (India)",
  "Vadner (India)",
  "Vadodara (India)",
  "Vaikam (India)",
  "Vainguinim (India)",
  "Valsad (India)",
  "Vandalur (India)",
  "Vandavasi (India)",
  "Vaniyambadi (India)",
  "Vapi (India)",
  "Varanasi (India)",
  "Vasai (India)",
  "Vasco (India)",
  "Vashi (India)",
  "Vazhakulam (India)",
  "Vellore (India)",
  "Verna (India)",
  "Vidisha (India)",
  "Vijapur (India)",
  "Vijayawada (India)",
  "Vikarabad (India)",
  "Vikasnagar (India)",
  "Villupuram (India)",
  "Vinukonda (India)",
  "Virar (India)",
  "Visakhapatnam (India)",
  "Visnagar (India)",
  "Vizianagaram (India)",
  "Wai (India)",
  "Warangal (India)",
  "Wardha (India)",
  "Wellington (India)",
  "Yadgir (India)",
  "Yamunanagar (India)",
  "Yanam (India)",
  "Yavatmal (India)",
  "Yeola (India)",
  "Yercaud (India)",
  "Indonesia",
  "Abadi (Indonesia)",
  "Adiantorop (Indonesia)",
  "Airmadidi (Indonesia)",
  "Ambarawa (Indonesia)",
  "Ambon City (Indonesia)",
  "Amlapura (Indonesia)",
  "Anggrek (Indonesia)",
  "Angkasa (Indonesia)",
  "Area (Indonesia)",
  "Badung (Indonesia)",
  "Bali (Indonesia)",
  "Balige (Indonesia)",
  "Balikpapan (Indonesia)",
  "Banda Aceh (Indonesia)",
  "Bandar (Indonesia)",
  "Bandar Lampung (Indonesia)",
  "Bandung (Indonesia)",
  "Bangkalan (Indonesia)",
  "Bangkinang (Indonesia)",
  "Bangli (Indonesia)",
  "Banjar (Indonesia)",
  "Banjarbaru (Indonesia)",
  "Banjarmasin (Indonesia)",
  "Banjarnegara (Indonesia)",
  "Bantaeng (Indonesia)",
  "Bantan (Indonesia)",
  "Bantul (Indonesia)",
  "Banyumas (Indonesia)",
  "Banyuwangi (Indonesia)",
  "Baratjaya (Indonesia)",
  "Batang (Indonesia)",
  "Batu (Indonesia)",
  "Baturaja (Indonesia)",
  "Baubau (Indonesia)",
  "Bekasi (Indonesia)",
  "Bengkalis (Indonesia)",
  "Bengkulu (Indonesia)",
  "Bima (Indonesia)",
  "Binjai (Indonesia)",
  "Bitung (Indonesia)",
  "Blitar (Indonesia)",
  "Blora (Indonesia)",
  "Bogor (Indonesia)",
  "Bojonegoro (Indonesia)",
  "Bondowoso (Indonesia)",
  "Bontang (Indonesia)",
  "Boyolali (Indonesia)",
  "Bukit Tinggi (Indonesia)",
  "Bulukumba (Indonesia)",
  "Buntok (Indonesia)",
  "Cakrawala (Indonesia)",
  "Cempaka (Indonesia)",
  "Cengkareng (Indonesia)",
  "Ciamis (Indonesia)",
  "Cianjur (Indonesia)",
  "Cibitung (Indonesia)",
  "Cibubur (Indonesia)",
  "Cihampelas (Indonesia)",
  "Cikarang (Indonesia)",
  "Cikini (Indonesia)",
  "Cilacap (Indonesia)",
  "Cilegon (Indonesia)",
  "Cilincing (Indonesia)",
  "Cimahi (Indonesia)",
  "Cimanggis (Indonesia)",
  "Cipinanglatihan (Indonesia)",
  "Ciputat (Indonesia)",
  "Cirebon (Indonesia)",
  "Citeureup (Indonesia)",
  "Darmaga (Indonesia)",
  "Darussalam (Indonesia)",
  "Demak (Indonesia)",
  "Denpasar (Indonesia)",
  "Depok (Indonesia)",
  "Depok Jaya (Indonesia)",
  "Dumai (Indonesia)",
  "Duren (Indonesia)",
  "Duri (Indonesia)",
  "Gandul (Indonesia)",
  "Garut (Indonesia)",
  "Gedung (Indonesia)",
  "Gianyar (Indonesia)",
  "Gorontalo (Indonesia)",
  "Gresik (Indonesia)",
  "Guntung (Indonesia)",
  "Gunungsitoli (Indonesia)",
  "Holis (Indonesia)",
  "Indo (Indonesia)",
  "Indramayu (Indonesia)",
  "Jagakarsa (Indonesia)",
  "Jakarta (Indonesia)",
  "Jambi City (Indonesia)",
  "Jayapura (Indonesia)",
  "Jember (Indonesia)",
  "Jepara (Indonesia)",
  "Jimbaran (Indonesia)",
  "Jombang (Indonesia)",
  "Kabanjahe (Indonesia)",
  "Kalideres (Indonesia)",
  "Kalimantan (Indonesia)",
  "Kandangan (Indonesia)",
  "Karanganyar (Indonesia)",
  "Karawang (Indonesia)",
  "Kartasura (Indonesia)",
  "Kebayoran Baru (Indonesia)",
  "Kebayoran Lama Selatan (Indonesia)",
  "Kebumen (Indonesia)",
  "Kediri (Indonesia)",
  "Kelapa Dua (Indonesia)",
  "Kemang (Indonesia)",
  "Kendal (Indonesia)",
  "Kepanjen (Indonesia)",
  "Kerinci (Indonesia)",
  "Kerobokan (Indonesia)",
  "Klaten (Indonesia)",
  "Kopeng (Indonesia)",
  "Kota (Indonesia)",
  "Kudus (Indonesia)",
  "Kulon (Indonesia)",
  "Kuningan (Indonesia)",
  "Kupang (Indonesia)",
  "Kuta (Indonesia)",
  "Lamongan (Indonesia)",
  "Lampeong (Indonesia)",
  "Langsa (Indonesia)",
  "Lapan (Indonesia)",
  "Lawang (Indonesia)",
  "Lestari (Indonesia)",
  "Lhokseumawe (Indonesia)",
  "Lubuk Pakam (Indonesia)",
  "Lumajang (Indonesia)",
  "Madiun (Indonesia)",
  "Magelang (Indonesia)",
  "Magetan (Indonesia)",
  "Mail (Indonesia)",
  "Majalengka (Indonesia)",
  "Makassar (Indonesia)",
  "Malang (Indonesia)",
  "Mamuju (Indonesia)",
  "Manado (Indonesia)",
  "Maros (Indonesia)",
  "Mataram (Indonesia)",
  "Medan (Indonesia)",
  "Mega (Indonesia)",
  "Menara (Indonesia)",
  "Menteng (Indonesia)",
  "Mojoagung (Indonesia)",
  "Mojokerto (Indonesia)",
  "Muntilan (Indonesia)",
  "Negara (Indonesia)",
  "Negeribesar (Indonesia)",
  "Nganjuk (Indonesia)",
  "Ngawi (Indonesia)",
  "Nusa (Indonesia)",
  "Nusa Dua (Indonesia)",
  "Nusantara (Indonesia)",
  "Pacitan (Indonesia)",
  "Padang (Indonesia)",
  "Palaihari (Indonesia)",
  "Palangka (Indonesia)",
  "Palangkaraya (Indonesia)",
  "Palembang (Indonesia)",
  "Palu (Indonesia)",
  "Pamekasan (Indonesia)",
  "Pamulang (Indonesia)",
  "Panasuan (Indonesia)",
  "Pandeglang (Indonesia)",
  "Pangaturan (Indonesia)",
  "Parakan (Indonesia)",
  "Pare (Indonesia)",
  "Parman (Indonesia)",
  "Pasuruan (Indonesia)",
  "Patam (Indonesia)",
  "Pati (Indonesia)",
  "Payakumbuh (Indonesia)",
  "Pekalongan (Indonesia)",
  "Pekan (Indonesia)",
  "Pekanbaru (Indonesia)",
  "Pemalang (Indonesia)",
  "Pematangsiantar (Indonesia)",
  "Polerejo (Indonesia)",
  "Pondok (Indonesia)",
  "Ponorogo (Indonesia)",
  "Pontianak (Indonesia)",
  "Porsea (Indonesia)",
  "Poso (Indonesia)",
  "Probolinggo (Indonesia)",
  "Purbalingga (Indonesia)",
  "Purwakarta (Indonesia)",
  "Purwodadi Grobogan (Indonesia)",
  "Purwokerto (Indonesia)",
  "Purworejo (Indonesia)",
  "Pusat (Indonesia)",
  "Riau (Indonesia)",
  "Salatiga (Indonesia)",
  "Samarinda (Indonesia)",
  "Sampang (Indonesia)",
  "Sampit (Indonesia)",
  "Sangereng (Indonesia)",
  "Sanur (Indonesia)",
  "Sejahtera (Indonesia)",
  "Sekupang (Indonesia)",
  "Selatan (Indonesia)",
  "Selong (Indonesia)",
  "Semarang (Indonesia)",
  "Sengkang (Indonesia)",
  "Sentul (Indonesia)",
  "Serang (Indonesia)",
  "Serdang (Indonesia)",
  "Serpong (Indonesia)",
  "Sidoarjo (Indonesia)",
  "Sigli (Indonesia)",
  "Sijunjung (Indonesia)",
  "Simpang (Indonesia)",
  "Singaraja (Indonesia)",
  "Singkawang (Indonesia)",
  "Situbondo (Indonesia)",
  "Sleman (Indonesia)",
  "Soasio (Indonesia)",
  "Soe (Indonesia)",
  "Soho (Indonesia)",
  "Solo (Indonesia)",
  "Sragen (Indonesia)",
  "Stabat (Indonesia)",
  "Subang (Indonesia)",
  "Sukabumi (Indonesia)",
  "Sukoharjo (Indonesia)",
  "Sumedang (Indonesia)",
  "Sungailiat (Indonesia)",
  "Sunggal (Indonesia)",
  "Sungguminasa (Indonesia)",
  "Surabaya (Indonesia)",
  "Surabayan (Indonesia)",
  "Surakarta (Indonesia)",
  "Tabanan (Indonesia)",
  "Tangsel (Indonesia)",
  "Tanjung (Indonesia)",
  "Tanjung Balai (Indonesia)",
  "Tanjungpinang (Indonesia)",
  "Tarakan (Indonesia)",
  "Tasikmalaya (Indonesia)",
  "Tebingtinggi (Indonesia)",
  "Tegal (Indonesia)",
  "Temanggung (Indonesia)",
  "Tembagapura (Indonesia)",
  "Tengah (Indonesia)",
  "Tenggara (Indonesia)",
  "Tenggarong (Indonesia)",
  "Tigaraksa (Indonesia)",
  "Tigarasa (Indonesia)",
  "Timur (Indonesia)",
  "Tipar Timur (Indonesia)",
  "Tirtagangga (Indonesia)",
  "Tomohon (Indonesia)",
  "Tondano (Indonesia)",
  "Trenggalek (Indonesia)",
  "Tuban (Indonesia)",
  "Tulungagung (Indonesia)",
  "Ubud (Indonesia)",
  "Udayana (Indonesia)",
  "Ungaran (Indonesia)",
  "Utama (Indonesia)",
  "Utara (Indonesia)",
  "Veteran (Indonesia)",
  "Wilayah (Indonesia)",
  "Wonogiri (Indonesia)",
  "Wonosari (Indonesia)",
  "Wonosobo (Indonesia)",
  "Yogyakarta (Indonesia)",
  "Iran",
  "`Aliabad (Iran)",
  "`Aliabad-e Aq Hesar (Iran)",
  "`Oryan (Iran)",
  "Abadan (Iran)",
  "Abol (Iran)",
  "Ahvaz (Iran)",
  "Amlash (Iran)",
  "Amol (Iran)",
  "Arak (Iran)",
  "Ardabil (Iran)",
  "Ardakan (Iran)",
  "Arnan (Iran)",
  "Arsanjan (Iran)",
  "Asadabad (Iran)",
  "Ashan (Iran)",
  "Ashna (Iran)",
  "Ashtian (Iran)",
  "Astara (Iran)",
  "Azad (Iran)",
  "Azadi (Iran)",
  "Azaran (Iran)",
  "Babol (Iran)",
  "Badr (Iran)",
  "Baft (Iran)",
  "Baghin (Iran)",
  "Baharan (Iran)",
  "Bakhtiari (Iran)",
  "Bakhtiaruyeh (Iran)",
  "Baladeh (Iran)",
  "Banak (Iran)",
  "Band (Iran)",
  "Bandar-e Khamir (Iran)",
  "Bandar-e Mahshahr (Iran)",
  "Barbun (Iran)",
  "Bardsir (Iran)",
  "Baseri Hadi (Iran)",
  "Bastak (Iran)",
  "Behbahan (Iran)",
  "Behdasht (Iran)",
  "Behjan (Iran)",
  "Behshahr (Iran)",
  "Bidak (Iran)",
  "Birizg (Iran)",
  "Birjand (Iran)",
  "Bonab (Iran)",
  "Borran-e Bala (Iran)",
  "Bostan (Iran)",
  "Bukan (Iran)",
  "Chabahar (Iran)",
  "Chah Deraz (Iran)",
  "Chapar (Iran)",
  "Dadeh (Iran)",
  "Damavand (Iran)",
  "Damghan (Iran)",
  "Darmian (Iran)",
  "Dezful (Iran)",
  "Dorud (Iran)",
  "Emam (Iran)",
  "Esfarayen (Iran)",
  "Eslamshahr (Iran)",
  "Estahban (Iran)",
  "Evin (Iran)",
  "Falavarjan (Iran)",
  "Fardis (Iran)",
  "Farsi (Iran)",
  "Fasa (Iran)",
  "Fordu (Iran)",
  "Garmsar (Iran)",
  "Gazan-e Bala (Iran)",
  "Gerash (Iran)",
  "Germi (Iran)",
  "Gharbi (Iran)",
  "Gilan-e Gharb (Iran)",
  "Gilas (Iran)",
  "Golestan (Iran)",
  "Golpayegan (Iran)",
  "Golriz (Iran)",
  "Gorgan (Iran)",
  "Gostar (Iran)",
  "Hadishahr (Iran)",
  "Hamadan (Iran)",
  "Hasanabad-e Qadamgah (Iran)",
  "Hashtgerd (Iran)",
  "Hashtpar (Iran)",
  "Hirad (Iran)",
  "Homa (Iran)",
  "Hormozabad (Iran)",
  "Hush (Iran)",
  "Idah (Iran)",
  "Ilam (Iran)",
  "Iranshahr (Iran)",
  "Isfahan (Iran)",
  "Jadir (Iran)",
  "Jahan (Iran)",
  "Jahan Nama (Iran)",
  "Jahrom (Iran)",
  "Jiroft (Iran)",
  "Julfa (Iran)",
  "Kabir (Iran)",
  "Kaman (Iran)",
  "Karaj (Iran)",
  "Karimkhan (Iran)",
  "Kashan (Iran)",
  "Kelarabad (Iran)",
  "Kerman (Iran)",
  "Kermanshah (Iran)",
  "Khalkhal (Iran)",
  "Khameneh (Iran)",
  "Khash (Iran)",
  "Khomeyn (Iran)",
  "Khomeyni Shahr (Iran)",
  "Khong (Iran)",
  "Khorasan (Iran)",
  "Khowrasgan (Iran)",
  "Khowrshid (Iran)",
  "Khvajeh (Iran)",
  "Khvoy (Iran)",
  "Kian (Iran)",
  "Kish (Iran)",
  "Kord Kandi (Iran)",
  "Kordestan-e `Olya (Iran)",
  "Kordovan-e Sofla (Iran)",
  "Lahijan (Iran)",
  "Lamerd (Iran)",
  "Mahabad (Iran)",
  "Mahallat (Iran)",
  "Mahestan (Iran)",
  "Malayer (Iran)",
  "Malek Talesh (Iran)",
  "Malekan (Iran)",
  "Mamasani (Iran)",
  "Manjil (Iran)",
  "Maragheh (Iran)",
  "Marand (Iran)",
  "Mashhad (Iran)",
  "Mehin (Iran)",
  "Mehregan (Iran)",
  "Meshgin Shahr (Iran)",
  "Meybod (Iran)",
  "Miandoab (Iran)",
  "Mianej (Iran)",
  "Mobarakeh (Iran)",
  "Moghan (Iran)",
  "Mohr (Iran)",
  "Nahavand (Iran)",
  "Najafabad (Iran)",
  "Naji (Iran)",
  "Nasir (Iran)",
  "Negar (Iran)",
  "Nehbandan (Iran)",
  "Neka (Iran)",
  "Neyriz (Iran)",
  "Neyshabur (Iran)",
  "Novin (Iran)",
  "Onar (Iran)",
  "Parpis (Iran)",
  "Parsian (Iran)",
  "Pasargad (Iran)",
  "Payam (Iran)",
  "Peshgoman (Iran)",
  "Peyk (Iran)",
  "Piruz (Iran)",
  "Post (Iran)",
  "Qasr od Dasht (Iran)",
  "Qazvin (Iran)",
  "Qeshm (Iran)",
  "Quchan (Iran)",
  "Rabor (Iran)",
  "Rafsanjan (Iran)",
  "Rasak (Iran)",
  "Rasht (Iran)",
  "Ravand (Iran)",
  "Ravansar (Iran)",
  "Ravar (Iran)",
  "Rayen (Iran)",
  "Razan (Iran)",
  "Razaviyeh (Iran)",
  "Razi (Iran)",
  "Rigan (Iran)",
  "Sabz (Iran)",
  "Sabzevar (Iran)",
  "Sahand (Iran)",
  "Salmas (Iran)",
  "Saman (Iran)",
  "Samen (Iran)",
  "Sanandaj (Iran)",
  "Saravan (Iran)",
  "Sari (Iran)",
  "Saveh (Iran)",
  "Semirom (Iran)",
  "Semnan (Iran)",
  "Sena (Iran)",
  "Servan (Iran)",
  "Shadman (Iran)",
  "Shahid (Iran)",
  "Shahid Chamran (Iran)",
  "Shahid Madani (Iran)",
  "Shahr (Iran)",
  "Shahrak (Iran)",
  "Shahrak-e Direh (Iran)",
  "Shahrak-e Milad (Iran)",
  "Shahrak-e Pars (Iran)",
  "Shahreza (Iran)",
  "Shahriar (Iran)",
  "Shahrud (Iran)",
  "Sharif (Iran)",
  "Shazand (Iran)",
  "Shiraz (Iran)",
  "Shirvan (Iran)",
  "Siahkal (Iran)",
  "Sirjan (Iran)",
  "Sistan-e `Olya (Iran)",
  "Soltanabad (Iran)",
  "Tabriz (Iran)",
  "Taha (Iran)",
  "Takestan (Iran)",
  "Tiran (Iran)",
  "Tonekabon (Iran)",
  "Torkan (Iran)",
  "Urmia (Iran)",
  "Vaj (Iran)",
  "Valilu (Iran)",
  "Vandar (Iran)",
  "Varamin (Iran)",
  "Wahna (Iran)",
  "Yasuj (Iran)",
  "Yazd (Iran)",
  "Zabol (Iran)",
  "Zahedan (Iran)",
  "Zahra (Iran)",
  "Zangiabad (Iran)",
  "Zanjan (Iran)",
  "Zarand (Iran)",
  "Iraq",
  "Al `Amarah (Iraq)",
  "Al Hillah (Iraq)",
  "Baghdad (Iraq)",
  "Bahr (Iraq)",
  "Basere (Iraq)",
  "Basra (Iraq)",
  "Erbil (Iraq)",
  "Haji Hasan (Iraq)",
  "Hayat (Iraq)",
  "Karkh (Iraq)",
  "Kirkuk (Iraq)",
  "Manawi (Iraq)",
  "Mosul (Iraq)",
  "Najaf (Iraq)",
  "Sulaymaniyah (Iraq)",
  "Tikrit (Iraq)",
  "Ireland",
  "Abbeyfeale (Ireland)",
  "Abbeyleix (Ireland)",
  "Ardee (Ireland)",
  "Arklow (Ireland)",
  "Artane (Ireland)",
  "Ashbourne (Ireland)",
  "Athboy (Ireland)",
  "Athenry (Ireland)",
  "Athlone (Ireland)",
  "Athy (Ireland)",
  "Bagenalstown (Ireland)",
  "Bailieborough (Ireland)",
  "Balbriggan (Ireland)",
  "Baldoyle (Ireland)",
  "Balgriffin (Ireland)",
  "Ballina (Ireland)",
  "Ballinadee (Ireland)",
  "Ballinasloe (Ireland)",
  "Ballincollig (Ireland)",
  "Ballineen (Ireland)",
  "Ballinrobe (Ireland)",
  "Ballintober (Ireland)",
  "Ballivor (Ireland)",
  "Ballon (Ireland)",
  "Ballsbridge (Ireland)",
  "Ballybrack (Ireland)",
  "Ballybrit (Ireland)",
  "Ballycullen (Ireland)",
  "Ballyfermot (Ireland)",
  "Ballygarvan (Ireland)",
  "Ballyhooly (Ireland)",
  "Ballymahon (Ireland)",
  "Ballymote (Ireland)",
  "Ballymount (Ireland)",
  "Ballymun (Ireland)",
  "Ballyphilip (Ireland)",
  "Ballyragget (Ireland)",
  "Ballyroe (Ireland)",
  "Ballyvaghan (Ireland)",
  "Bandon (Ireland)",
  "Bantry (Ireland)",
  "Berrings (Ireland)",
  "Bettystown (Ireland)",
  "Birr (Ireland)",
  "Blackrock (Ireland)",
  "Blanchardstown (Ireland)",
  "Blessington (Ireland)",
  "Bodyke (Ireland)",
  "Boherbue (Ireland)",
  "Bray (Ireland)",
  "Broadford (Ireland)",
  "Buncrana (Ireland)",
  "Bundoran (Ireland)",
  "Cabinteely (Ireland)",
  "Cabra (Ireland)",
  "Caher (Ireland)",
  "Cahersiveen (Ireland)",
  "Callan (Ireland)",
  "Carbury (Ireland)",
  "Carlow (Ireland)",
  "Carnew (Ireland)",
  "Carraroe (Ireland)",
  "Carrick (Ireland)",
  "Carrick on Shannon (Ireland)",
  "Carrick-on-Suir (Ireland)",
  "Carrickmacross (Ireland)",
  "Carrickmines (Ireland)",
  "Carrigaline (Ireland)",
  "Carrigtohill (Ireland)",
  "Cashel (Ireland)",
  "Castlebar (Ireland)",
  "Castleblayney (Ireland)",
  "Castlebridge (Ireland)",
  "Castleknock (Ireland)",
  "Castlemaine (Ireland)",
  "Castlerea (Ireland)",
  "Cavan (Ireland)",
  "Celbridge (Ireland)",
  "Clane (Ireland)",
  "Clarecastle (Ireland)",
  "Claregalway (Ireland)",
  "Claremorris (Ireland)",
  "Clogherhead (Ireland)",
  "Clonakilty (Ireland)",
  "Clondalkin (Ireland)",
  "Clonee (Ireland)",
  "Clones (Ireland)",
  "Clonmel (Ireland)",
  "Clonsilla (Ireland)",
  "Clontarf (Ireland)",
  "Coachford (Ireland)",
  "Cobh (Ireland)",
  "Coolock (Ireland)",
  "Cork (Ireland)",
  "County Galway (Ireland)",
  "County Wexford (Ireland)",
  "Courtown (Ireland)",
  "Crookhaven (Ireland)",
  "Cross (Ireland)",
  "Crosshaven (Ireland)",
  "Crumlin (Ireland)",
  "Crusheen (Ireland)",
  "Dalkey (Ireland)",
  "Delgany (Ireland)",
  "Donabate (Ireland)",
  "Donegal (Ireland)",
  "Donnybrook (Ireland)",
  "Doolin (Ireland)",
  "Doughiska (Ireland)",
  "Douglas (Ireland)",
  "Drogheda (Ireland)",
  "Droichead Nua (Ireland)",
  "Dublin (Ireland)",
  "Duleek (Ireland)",
  "Dunboyne (Ireland)",
  "Dundalk (Ireland)",
  "Dundrum (Ireland)",
  "Dungarvan (Ireland)",
  "Dunlavin (Ireland)",
  "Dunleer (Ireland)",
  "Dunshaughlin (Ireland)",
  "Edenderry (Ireland)",
  "Enfield (Ireland)",
  "Ennis (Ireland)",
  "Enniscorthy (Ireland)",
  "Enniskerry (Ireland)",
  "Feakle (Ireland)",
  "Ferbane (Ireland)",
  "Fermoy (Ireland)",
  "Finglas (Ireland)",
  "Firhouse (Ireland)",
  "Foxford (Ireland)",
  "Foxrock (Ireland)",
  "Foynes (Ireland)",
  "Galway (Ireland)",
  "Garristown (Ireland)",
  "Geevagh (Ireland)",
  "Glanmire (Ireland)",
  "Glasnevin (Ireland)",
  "Glen (Ireland)",
  "Glenealy (Ireland)",
  "Glengarriff (Ireland)",
  "Glenties (Ireland)",
  "Gorey (Ireland)",
  "Gort (Ireland)",
  "Grange (Ireland)",
  "Greystones (Ireland)",
  "Headford (Ireland)",
  "Hospital (Ireland)",
  "Inchicore (Ireland)",
  "Irishtown (Ireland)",
  "Island (Ireland)",
  "Jamestown (Ireland)",
  "Kanturk (Ireland)",
  "Kells (Ireland)",
  "Kenmare (Ireland)",
  "Kilbride Cross Roads (Ireland)",
  "Kilcock (Ireland)",
  "Kilcoole (Ireland)",
  "Kilcullen (Ireland)",
  "Kildalkey (Ireland)",
  "Kildare (Ireland)",
  "Kilfinane (Ireland)",
  "Kilkenny (Ireland)",
  "Killala (Ireland)",
  "Killaloe (Ireland)",
  "Killarney (Ireland)",
  "Killiney (Ireland)",
  "Killinick (Ireland)",
  "Killorglin (Ireland)",
  "Killurin (Ireland)",
  "Killybegs (Ireland)",
  "Killygordon (Ireland)",
  "Kilmainham (Ireland)",
  "Kilmichael (Ireland)",
  "Kilmore (Ireland)",
  "Kilrush (Ireland)",
  "Kiltamagh (Ireland)",
  "Kingscourt (Ireland)",
  "Kingswood (Ireland)",
  "Kinsale (Ireland)",
  "Kinvarra (Ireland)",
  "Leamlara (Ireland)",
  "Leixlip (Ireland)",
  "Leopardstown (Ireland)",
  "Letterkenny (Ireland)",
  "Limerick (Ireland)",
  "Lisselton (Ireland)",
  "Listowel (Ireland)",
  "Longford (Ireland)",
  "Longwood (Ireland)",
  "Loughrea (Ireland)",
  "Louth (Ireland)",
  "Lucan (Ireland)",
  "Lusk (Ireland)",
  "Macroom (Ireland)",
  "Malahide (Ireland)",
  "Mallow (Ireland)",
  "Manorhamilton (Ireland)",
  "Marino (Ireland)",
  "Maynooth (Ireland)",
  "Mayo (Ireland)",
  "Midleton (Ireland)",
  "Milltown (Ireland)",
  "Mitchelstown (Ireland)",
  "Monaghan (Ireland)",
  "Monasterevin (Ireland)",
  "Monkstown (Ireland)",
  "Mornington (Ireland)",
  "Mount Merrion (Ireland)",
  "Mountrath (Ireland)",
  "Moycullen (Ireland)",
  "Mullinavat (Ireland)",
  "Mullingar (Ireland)",
  "Naas (Ireland)",
  "Naul (Ireland)",
  "Navan (Ireland)",
  "Nenagh (Ireland)",
  "New Ross (Ireland)",
  "Newcastle West (Ireland)",
  "Newmarket (Ireland)",
  "Newport (Ireland)",
  "Oranmore (Ireland)",
  "Oughterard (Ireland)",
  "Oysterhaven (Ireland)",
  "Passage West (Ireland)",
  "Patrickswell (Ireland)",
  "Portarlington (Ireland)",
  "Porterstown (Ireland)",
  "Portmarnock (Ireland)",
  "Portumna (Ireland)",
  "Prosperous (Ireland)",
  "Raheny (Ireland)",
  "Rathcoole (Ireland)",
  "Rathfarnham (Ireland)",
  "Rathgar (Ireland)",
  "Rathmines (Ireland)",
  "Rathmolyon (Ireland)",
  "Rathowen (Ireland)",
  "Ratoath (Ireland)",
  "Ringaskiddy (Ireland)",
  "River (Ireland)",
  "Roscommon (Ireland)",
  "Roscrea (Ireland)",
  "Rush (Ireland)",
  "Saggart (Ireland)",
  "Saint Mullins (Ireland)",
  "Sallins (Ireland)",
  "Sallynoggin (Ireland)",
  "Sandyford (Ireland)",
  "Sandymount (Ireland)",
  "Santry (Ireland)",
  "Seafield (Ireland)",
  "Shankill (Ireland)",
  "Shannon (Ireland)",
  "Shrule (Ireland)",
  "Sixmilebridge (Ireland)",
  "Skerries (Ireland)",
  "Skibbereen (Ireland)",
  "Slieve (Ireland)",
  "Sligo (Ireland)",
  "Spiddal (Ireland)",
  "Stepaside (Ireland)",
  "Stillorgan (Ireland)",
  "Stradbally (Ireland)",
  "Straffan (Ireland)",
  "Summerhill (Ireland)",
  "Sutton (Ireland)",
  "Swinford (Ireland)",
  "Swords (Ireland)",
  "Tallaght (Ireland)",
  "Templeogue (Ireland)",
  "Terenure (Ireland)",
  "Thomastown (Ireland)",
  "Thurles (Ireland)",
  "Tipperary (Ireland)",
  "Tralee (Ireland)",
  "Trim (Ireland)",
  "Tuam (Ireland)",
  "Tullamore (Ireland)",
  "Tullow (Ireland)",
  "Tyrrellspass (Ireland)",
  "Virginia (Ireland)",
  "Walkinstown (Ireland)",
  "Waterford (Ireland)",
  "Westport (Ireland)",
  "Wexford (Ireland)",
  "Wicklow (Ireland)",
  "Williamstown (Ireland)",
  "Windy Arbour (Ireland)",
  "Youghal (Ireland)",
  "Isle of Man",
  "Ballasalla (Isle of Man)",
  "Castletown (Isle of Man)",
  "Crosby (Isle of Man)",
  "Dalby (Isle of Man)",
  "Douglas (Isle of Man)",
  "Foxdale (Isle of Man)",
  "Laxey (Isle of Man)",
  "Onchan (Isle of Man)",
  "Peel (Isle of Man)",
  "Port Erin (Isle of Man)",
  "Port Saint Mary (Isle of Man)",
  "Ramsey (Isle of Man)",
  "Saint Johns (Isle of Man)",
  "Israel",
  "`Alma (Israel)",
  "`Amir (Israel)",
  "`Arugot (Israel)",
  "`Aseret (Israel)",
  "`En Ayyala (Israel)",
  "`En HaShelosha (Israel)",
  "`Evron (Israel)",
  "Acre (Israel)",
  "Afiqim (Israel)",
  "Ahituv (Israel)",
  "Allonim (Israel)",
  "Ashdod (Israel)",
  "Ashqelon (Israel)",
  "Azor (Israel)",
  "Bahan (Israel)",
  "Baraq (Israel)",
  "Bareqet (Israel)",
  "Bat Hadar (Israel)",
  "Bat Hefer (Israel)",
  "Bat Yam (Israel)",
  "Beersheba (Israel)",
  "Ben Shemen-Kefar Hano`ar (Israel)",
  "Bene Ziyyon (Israel)",
  "Bet Alfa (Israel)",
  "Bet Dagan (Israel)",
  "Bet Oren (Israel)",
  "Bet Shemesh (Israel)",
  "Binyamina (Israel)",
  "Biriyya (Israel)",
  "Dabburiya (Israel)",
  "Dimona (Israel)",
  "Eilat (Israel)",
  "Elyakhin (Israel)",
  "Elyaqim (Israel)",
  "Emunim (Israel)",
  "Et Taiyiba (Israel)",
  "Even Yehuda (Israel)",
  "Gan Hayyim (Israel)",
  "Gan Yavne (Israel)",
  "Ganne Tiqwa (Israel)",
  "Gedera (Israel)",
  "Gibbeton (Israel)",
  "Gimzo (Israel)",
  "Ginnosar (Israel)",
  "Giv`at Hayyim (Israel)",
  "Hadar `Am (Israel)",
  "Hadar Ramatayim (Israel)",
  "Hadera (Israel)",
  "Hadid (Israel)",
  "Haifa (Israel)",
  "HaKarmel (Israel)",
  "Haluz (Israel)",
  "Hazav (Israel)",
  "Hazor Ashdod (Israel)",
  "Hazor HaGelilit (Israel)",
  "Herut (Israel)",
  "Herzliya (Israel)",
  "Hever (Israel)",
  "Hod HaSharon (Israel)",
  "Holon (Israel)",
  "Hurfeish (Israel)",
  "Jaffa (Israel)",
  "Jerusalem (Israel)",
  "Kadima (Israel)",
  "Karkur (Israel)",
  "Kefar Daniyyel (Israel)",
  "Kefar Netter (Israel)",
  "Kefar Witqin (Israel)",
  "Kefar Yona (Israel)",
  "Kfar Saba (Israel)",
  "Liman (Israel)",
  "Lod (Israel)",
  "maalot Tarshiha (Israel)",
  "Magen (Israel)",
  "Maghar (Israel)",
  "Mazkeret Batya (Israel)",
  "Mazliah (Israel)",
  "Mazor (Israel)",
  "Mesillat Ziyyon (Israel)",
  "Migdal (Israel)",
  "Mikhmoret (Israel)",
  "Misgav Regional Council (Israel)",
  "Mizpe Netofa (Israel)",
  "Modiin (Israel)",
  "Moran (Israel)",
  "Naham (Israel)",
  "Nahariya (Israel)",
  "Nazareth (Israel)",
  "Nazerat `Illit (Israel)",
  "Nesher (Israel)",
  "Ness Ziona (Israel)",
  "Netanya (Israel)",
  "Netivot (Israel)",
  "Newe Efrayim (Israel)",
  "Newe Yamin (Israel)",
  "Nir Zevi (Israel)",
  "Nirim (Israel)",
  "Nordiyya (Israel)",
  "Ofaqim (Israel)",
  "Or `Aqiva (Israel)",
  "Or Yehuda (Israel)",
  "Pardes Hanna Karkur (Israel)",
  "Pardesiyya (Israel)",
  "Pasuta (Israel)",
  "Petah Tikwah (Israel)",
  "Qiryat Ata (Israel)",
  "Qiryat Bialik (Israel)",
  "Qiryat Gat (Israel)",
  "Qiryat Hayyim (Israel)",
  "Qiryat Motzkin (Israel)",
  "Qiryat Ono (Israel)",
  "Qiryat Tiv`on (Israel)",
  "Qiryat Yam (Israel)",
  "Ramat Aviv (Israel)",
  "Ramat Dawid (Israel)",
  "Ramat Ef`al (Israel)",
  "Ramat Gan (Israel)",
  "Ramat HaSharon (Israel)",
  "Ramat Poleg (Israel)",
  "Ramat Yishay (Israel)",
  "Ramla (Israel)",
  "Ramot Naftali (Israel)",
  "Rehovot (Israel)",
  "Rinnatya (Israel)",
  "Rishon LeZion (Israel)",
  "Rishpon (Israel)",
  "Safed (Israel)",
  "Sarid (Israel)",
  "Savyon (Israel)",
  "Sde Boker (Israel)",
  "Sde Warburg (Israel)",
  "Sderot (Israel)",
  "Sedot Yam (Israel)",
  "Shamir (Israel)",
  "Shave Ziyyon (Israel)",
  "Shefayim (Israel)",
  "Shelomi (Israel)",
  "Shetulim (Israel)",
  "Shoval (Israel)",
  "Talme Menashe (Israel)",
  "Tel Aviv (Israel)",
  "Tel Mond (Israel)",
  "Tiberias (Israel)",
  "Timrat (Israel)",
  "Tirat Karmel (Israel)",
  "Tirat Yehuda (Israel)",
  "Urim (Israel)",
  "Yaqum (Israel)",
  "Yavne (Israel)",
  "Yehud (Israel)",
  "Zoran (Israel)",
  "Italy",
  "Abano Terme (Italy)",
  "Abbadia Lariana (Italy)",
  "Abbadia San Salvatore (Italy)",
  "Abbasanta (Italy)",
  "Abbiategrasso (Italy)",
  "Abetone (Italy)",
  "Acate (Italy)",
  "Acerno (Italy)",
  "Acerra (Italy)",
  "Aci Castello (Italy)",
  "Aci Catena (Italy)",
  "Acireale (Italy)",
  "Acquafredda (Italy)",
  "Acquafredda Inferiore (Italy)",
  "Acquapendente (Italy)",
  "Acquappesa (Italy)",
  "Acquaro (Italy)",
  "Acquasparta (Italy)",
  "Acquaviva (Italy)",
  "Acquaviva delle Fonti (Italy)",
  "Acquaviva Picena (Italy)",
  "Acqui Terme (Italy)",
  "Acri (Italy)",
  "Adelfia (Italy)",
  "Adrano (Italy)",
  "Adria (Italy)",
  "Adro (Italy)",
  "Affi (Italy)",
  "Afragola (Italy)",
  "Africo Nuovo (Italy)",
  "Agazzano (Italy)",
  "Agerola (Italy)",
  "Agira (Italy)",
  "Agliana (Italy)",
  "Agliano (Italy)",
  "Aglientu (Italy)",
  "Agna (Italy)",
  "Agnadello (Italy)",
  "Agnone (Italy)",
  "Agordo (Italy)",
  "Agrano (Italy)",
  "Agrate Brianza (Italy)",
  "Agrigento (Italy)",
  "Agro (Italy)",
  "Agropoli (Italy)",
  "Agugliano (Italy)",
  "Agugliaro (Italy)",
  "Ai Palazzi (Italy)",
  "Aiello (Italy)",
  "Aiello del Sabato (Italy)",
  "Ailano (Italy)",
  "Ailoche (Italy)",
  "Airasca (Italy)",
  "Airola (Italy)",
  "Airole (Italy)",
  "Airuno (Italy)",
  "Ala (Italy)",
  "Ala di Stura (Italy)",
  "Alanno (Italy)",
  "Alassio (Italy)",
  "Alatri (Italy)",
  "Alba (Italy)",
  "Albanella (Italy)",
  "Albano di Lucania (Italy)",
  "Albano Laziale (Italy)",
  "Albano Vercellese (Italy)",
  "Albaredo (Italy)",
  "Albareto (Italy)",
  "Albenga (Italy)",
  "Albergo (Italy)",
  "Alberobello (Italy)",
  "Alberoni (Italy)",
  "Albettone (Italy)",
  "Albiate (Italy)",
  "Albignasego (Italy)",
  "Albina (Italy)",
  "Albinea (Italy)",
  "Albino (Italy)",
  "Albissola Marina (Italy)",
  "Albizzate (Italy)",
  "Albuzzano (Italy)",
  "Alcamo (Italy)",
  "Alcara li Fusi (Italy)",
  "Aldeno (Italy)",
  "Ales (Italy)",
  "Alessandria (Italy)",
  "Alessandria della Rocca (Italy)",
  "Alessano (Italy)",
  "Alezio (Italy)",
  "Alfonsine (Italy)",
  "Alghero (Italy)",
  "Algund (Italy)",
  "Alice Castello (Italy)",
  "Alife (Italy)",
  "Alimena (Italy)",
  "Alleghe (Italy)",
  "Allerona (Italy)",
  "Alliste (Italy)",
  "Allumiere (Italy)",
  "Almenno San Bartolomeo (Italy)",
  "Almese (Italy)",
  "Alpette (Italy)",
  "Alpignano (Italy)",
  "Altamura (Italy)",
  "Altare (Italy)",
  "Altavilla (Italy)",
  "Altavilla Irpina (Italy)",
  "Altavilla Milicia (Italy)",
  "Altavilla Silentina (Italy)",
  "Altavilla Vicentina (Italy)",
  "Altedo (Italy)",
  "Altessano (Italy)",
  "Altidona (Italy)",
  "Altino (Italy)",
  "Altofonte (Italy)",
  "Altomonte (Italy)",
  "Altopascio (Italy)",
  "Alviano (Italy)",
  "Alvignanello (Italy)",
  "Alvignano (Italy)",
  "Alvito (Italy)",
  "Alzano Lombardo (Italy)",
  "Amalfi (Italy)",
  "Amandola (Italy)",
  "Amantea (Italy)",
  "Amaro (Italy)",
  "Amato (Italy)",
  "Ambra (Italy)",
  "Ambrogio (Italy)",
  "Ameglia (Italy)",
  "Amelia (Italy)",
  "Ameno (Italy)",
  "Amorosi (Italy)",
  "Ampezzo (Italy)",
  "Anacapri (Italy)",
  "Anagni (Italy)",
  "Ancarano (Italy)",
  "Ancona (Italy)",
  "Andezeno (Italy)",
  "Andorno Micca (Italy)",
  "Andrano (Italy)",
  "Andria (Italy)",
  "Anfo (Italy)",
  "Angera (Italy)",
  "Anghiari (Italy)",
  "Angolo Terme (Italy)",
  "Angri (Italy)",
  "Anguillara (Italy)",
  "Anguillara Veneta (Italy)",
  "Anna (Italy)",
  "Annicco (Italy)",
  "Annone Veneto (Italy)",
  "Antea (Italy)",
  "Antella (Italy)",
  "Anterselva di Mezzo (Italy)",
  "Antico di Maiolo (Italy)",
  "Antignano (Italy)",
  "Antonimina (Italy)",
  "Antrodoco (Italy)",
  "Anzano del Parco (Italy)",
  "Anzio (Italy)",
  "Aosta (Italy)",
  "Apice (Italy)",
  "Apiro (Italy)",
  "Appiano Gentile (Italy)",
  "Appiano sulla strada del vino (Italy)",
  "Appignano (Italy)",
  "Appignano del Tronto (Italy)",
  "Apricena (Italy)",
  "Aprilia (Italy)",
  "Aquileia (Italy)",
  "Aquilonia (Italy)",
  "Aquino (Italy)",
  "Arabba (Italy)",
  "Aradeo (Italy)",
  "Aragona (Italy)",
  "Arborea (Italy)",
  "Arborio (Italy)",
  "Arbus (Italy)",
  "Arcade (Italy)",
  "Arce (Italy)",
  "Arcene (Italy)",
  "Arcevia (Italy)",
  "Arcidosso (Italy)",
  "Arco (Italy)",
  "Arcola (Italy)",
  "Arcole (Italy)",
  "Arconate (Italy)",
  "Arcore (Italy)",
  "Arcugnano (Italy)",
  "Ardea (Italy)",
  "Ardesio (Italy)",
  "Arenzano (Italy)",
  "Arese (Italy)",
  "Arezzo (Italy)",
  "Argelato (Italy)",
  "Argenta (Italy)",
  "Ariano Ferrarese (Italy)",
  "Ariano Irpino (Italy)",
  "Ariano nel Polesine (Italy)",
  "Ariccia (Italy)",
  "Arielli (Italy)",
  "Arienzo (Italy)",
  "Arignano (Italy)",
  "Arizzano (Italy)",
  "Arluno (Italy)",
  "Armeno (Italy)",
  "Armento (Italy)",
  "Arnesano (Italy)",
  "Arona (Italy)",
  "Arosio (Italy)",
  "Arpaia (Italy)",
  "Arpino (Italy)",
  "Arqua Polesine (Italy)",
  "Arquata Scrivia (Italy)",
  "Arre (Italy)",
  "Arrone (Italy)",
  "Arsago Seprio (Italy)",
  "Arsita (Italy)",
  "Arta Terme (Italy)",
  "Artena (Italy)",
  "Artogne (Italy)",
  "Arzachena (Italy)",
  "Arzano (Italy)",
  "Arzene (Italy)",
  "Arzignano (Italy)",
  "Asciano (Italy)",
  "Ascoli Piceno (Italy)",
  "Ascoli Satriano (Italy)",
  "Asiago (Italy)",
  "Asola (Italy)",
  "Asolo (Italy)",
  "Assemini (Italy)",
  "Assisi (Italy)",
  "Asso (Italy)",
  "Assoro (Italy)",
  "Asti (Italy)",
  "Atella (Italy)",
  "Atena Lucana (Italy)",
  "Atessa (Italy)",
  "Atina (Italy)",
  "Atri (Italy)",
  "Atripalda (Italy)",
  "Attigliano (Italy)",
  "Attimis (Italy)",
  "Atzara (Italy)",
  "Augusta (Italy)",
  "Auletta (Italy)",
  "Aulla (Italy)",
  "Aurelia (Italy)",
  "Auronzo di Cadore (Italy)",
  "Avella (Italy)",
  "Avellino (Italy)",
  "Aversa (Italy)",
  "Avezzano (Italy)",
  "Aviano (Italy)",
  "Avigliana (Italy)",
  "Avigliano (Italy)",
  "Avigliano Umbro (Italy)",
  "Avio (Italy)",
  "Avola (Italy)",
  "Azeglio (Italy)",
  "Azzano (Italy)",
  "Azzano Decimo (Italy)",
  "Azzano San Paolo (Italy)",
  "Azzate (Italy)",
  "Bacoli (Italy)",
  "Badia (Italy)",
  "Badia Calavena (Italy)",
  "Badia Polesine (Italy)",
  "Badolato (Italy)",
  "Bagheria (Italy)",
  "Baglio Rizzo (Italy)",
  "Bagnacavallo (Italy)",
  "Bagnara (Italy)",
  "Bagnara Calabra (Italy)",
  "Bagnara di Romagna (Italy)",
  "Bagnasco (Italy)",
  "Bagni (Italy)",
  "Bagni di Lucca (Italy)",
  "Bagno a Ripoli (Italy)",
  "Bagno di Romagna (Italy)",
  "Bagnoli del Trigno (Italy)",
  "Bagnoli di Sopra (Italy)",
  "Bagnoli Irpino (Italy)",
  "Bagnolo (Italy)",
  "Bagnolo Cremasco (Italy)",
  "Bagnolo in Piano (Italy)",
  "Bagnolo Mella (Italy)",
  "Bagnolo Piemonte (Italy)",
  "Bagnolo San Vito (Italy)",
  "Baia (Italy)",
  "Baiano (Italy)",
  "Baiso (Italy)",
  "Balangero (Italy)",
  "Baldissero Torinese (Italy)",
  "Balestrate-Foce (Italy)",
  "Ballabio (Italy)",
  "Baluello (Italy)",
  "Balvano (Italy)",
  "Balzola (Italy)",
  "Baragiano (Italy)",
  "Baranzate (Italy)",
  "Baratte (Italy)",
  "Barbarano Vicentino (Italy)",
  "Barbarasco (Italy)",
  "Barberino di Mugello (Italy)",
  "Barbiano (Italy)",
  "Bardolino (Italy)",
  "Bardonecchia (Italy)",
  "Bareggio (Italy)",
  "Barga (Italy)",
  "Bargagli (Italy)",
  "Barge (Italy)",
  "Bari (Italy)",
  "Bari Sardo (Italy)",
  "Baricella (Italy)",
  "Barile (Italy)",
  "Barisciano (Italy)",
  "Barlassina (Italy)",
  "Barletta (Italy)",
  "Barni (Italy)",
  "Baronissi (Italy)",
  "Barra (Italy)",
  "Barrafranca (Italy)",
  "Barrali (Italy)",
  "Baruchella (Italy)",
  "Barumini (Italy)",
  "Barzana (Italy)",
  "Basaldella (Italy)",
  "Baschi (Italy)",
  "Basciano (Italy)",
  "Baselice (Italy)",
  "Basiliano (Italy)",
  "Basilicanova (Italy)",
  "Bassano Bresciano (Italy)",
  "Bassano del Grappa (Italy)",
  "Bassano in Teverina (Italy)",
  "Bassano Romano (Italy)",
  "Bastia umbra (Italy)",
  "Bastiglia (Italy)",
  "Battaglia Terme (Italy)",
  "Battipaglia (Italy)",
  "Baunei (Italy)",
  "Baveno (Italy)",
  "Bazzano (Italy)",
  "Bedollo (Italy)",
  "Begliano (Italy)",
  "Beinasco (Italy)",
  "Beinette (Italy)",
  "Belfiore (Italy)",
  "Belforte (Italy)",
  "Belgioioso (Italy)",
  "Bella (Italy)",
  "Bellagio (Italy)",
  "Bellano (Italy)",
  "Bellante (Italy)",
  "Bellaria-Igea Marina (Italy)",
  "Bellariva (Italy)",
  "Bellinzago Novarese (Italy)",
  "Bellocchi (Italy)",
  "Bellona (Italy)",
  "Belluno (Italy)",
  "Belmonte Mezzagno (Italy)",
  "Belmonte Piceno (Italy)",
  "Belpasso (Italy)",
  "Belsito (Italy)",
  "Belvedere (Italy)",
  "Belvedere di Spinello (Italy)",
  "Belvedere Marittimo (Italy)",
  "Belvedere Ostrense (Italy)",
  "Benestare (Italy)",
  "Benetutti (Italy)",
  "Benevello (Italy)",
  "Benevento (Italy)",
  "Benna (Italy)",
  "Bentivoglio (Italy)",
  "Berbenno di Valtellina (Italy)",
  "Berceto (Italy)",
  "Bergamo (Italy)",
  "Berlingo (Italy)",
  "Bernalda (Italy)",
  "Bernate Ticino (Italy)",
  "Bertinoro (Italy)",
  "Bertonico (Italy)",
  "Berzo Demo (Italy)",
  "Besana in Brianza (Italy)",
  "Besano (Italy)",
  "Besnate (Italy)",
  "Besozzo (Italy)",
  "Bettola (Italy)",
  "Bettolle (Italy)",
  "Bettona (Italy)",
  "Beura-Cardezza (Italy)",
  "Bevilacqua (Italy)",
  "Biancade (Italy)",
  "Biancano (Italy)",
  "Biancavilla (Italy)",
  "Bianco (Italy)",
  "Bianconese (Italy)",
  "Biandronno (Italy)",
  "Bianzone (Italy)",
  "Biassono (Italy)",
  "Bibbiano (Italy)",
  "Bibbiena (Italy)",
  "Bibbona (Italy)",
  "Bibione (Italy)",
  "Biella (Italy)",
  "Bienno (Italy)",
  "Bientina (Italy)",
  "Binanuova (Italy)",
  "Binasco (Italy)",
  "Bisaccia (Italy)",
  "Bisacquino (Italy)",
  "Bisceglie (Italy)",
  "Bitetto (Italy)",
  "Bitonto (Italy)",
  "Bitritto (Italy)",
  "Bitti (Italy)",
  "Bivongi (Italy)",
  "Blera (Italy)",
  "Boara (Italy)",
  "Bobbio (Italy)",
  "Boccheggiano (Italy)",
  "Bocchignano (Italy)",
  "Boffalora sopra Ticino (Italy)",
  "Bogliasco (Italy)",
  "Bojano (Italy)",
  "Bolano (Italy)",
  "Bollate (Italy)",
  "Bollengo (Italy)",
  "Bologna (Italy)",
  "Bolzano (Italy)",
  "Bolzano Vicentino (Italy)",
  "Bompietro (Italy)",
  "Bomporto (Italy)",
  "Bonarcado (Italy)",
  "Bonassola (Italy)",
  "Bonavicina (Italy)",
  "Bondeno (Italy)",
  "Bonea (Italy)",
  "Bonorva (Italy)",
  "Bordighera (Italy)",
  "Boretto (Italy)",
  "Borgaro Torinese (Italy)",
  "Borgetto (Italy)",
  "Borghetto (Italy)",
  "Borghetto di Vara (Italy)",
  "Borghetto Santo Spirito (Italy)",
  "Borgia (Italy)",
  "Borgo (Italy)",
  "Borgo a Mozzano (Italy)",
  "Borgo di Terzo (Italy)",
  "Borgo Priolo (Italy)",
  "Borgo Sabotino-Foce Verde (Italy)",
  "Borgo San Dalmazzo (Italy)",
  "Borgo San Giacomo (Italy)",
  "Borgo San Lorenzo (Italy)",
  "Borgo Ticino (Italy)",
  "Borgo Tossignano (Italy)",
  "Borgo Val di Taro (Italy)",
  "Borgo Vercelli (Italy)",
  "Borgomanero (Italy)",
  "Borgone (Italy)",
  "Borgonovo (Italy)",
  "Borgonovo Val Tidone (Italy)",
  "Borgoricco (Italy)",
  "Borgorose (Italy)",
  "Borgosatollo (Italy)",
  "Borgosesia (Italy)",
  "Bornasco (Italy)",
  "Borno (Italy)",
  "Borrello (Italy)",
  "Borzonasca (Italy)",
  "Bosa (Italy)",
  "Boschetto (Italy)",
  "Bosco (Italy)",
  "Bosco Chiesanuova (Italy)",
  "Bosconero (Italy)",
  "Boscoreale (Italy)",
  "Boscotrecase (Italy)",
  "Bosisio Parini (Italy)",
  "Bossolasco (Italy)",
  "Bottanuco (Italy)",
  "Bova Marina (Italy)",
  "Bovalino (Italy)",
  "Bovegno (Italy)",
  "Boves (Italy)",
  "Bovezzo (Italy)",
  "Boville Ernica (Italy)",
  "Bovino (Italy)",
  "Bovisio-Masciago (Italy)",
  "Bovolenta (Italy)",
  "Bovolone (Italy)",
  "Bozzolo (Italy)",
  "Bra (Italy)",
  "Braccagni (Italy)",
  "Bracciano (Italy)",
  "Bracigliano (Italy)",
  "Brancaleone (Italy)",
  "Brandizzo (Italy)",
  "Braone (Italy)",
  "Brebbia (Italy)",
  "Breda di Piave (Italy)",
  "Breganze (Italy)",
  "Breguzzo (Italy)",
  "Brembate (Italy)",
  "Brembilla (Italy)",
  "Brendola (Italy)",
  "Brenner (Italy)",
  "Breno (Italy)",
  "Brescello (Italy)",
  "Brescia (Italy)",
  "Bressana Bottarone (Italy)",
  "Bressanvido (Italy)",
  "Bresso (Italy)",
  "Briatico (Italy)",
  "Bricherasio (Italy)",
  "Briga Novarese (Italy)",
  "Brignano (Italy)",
  "Brindisi (Italy)",
  "Brissago-Valtravaglia (Italy)",
  "Brivio (Italy)",
  "Brixen (Italy)",
  "Brolo (Italy)",
  "Broni (Italy)",
  "Bronte (Italy)",
  "Bronzolo (Italy)",
  "Brossasco (Italy)",
  "Brosso (Italy)",
  "Brugherio (Italy)",
  "Brugine (Italy)",
  "Brugnera (Italy)",
  "Bruino (Italy)",
  "Brunate (Italy)",
  "Bruneck (Italy)",
  "Brunella (Italy)",
  "Bruno (Italy)",
  "Brusciano (Italy)",
  "Brusnengo (Italy)",
  "Bubbio (Italy)",
  "Bucchianico (Italy)",
  "Bucciano (Italy)",
  "Buccino (Italy)",
  "Bucine (Italy)",
  "Budrio (Italy)",
  "Buggiano (Italy)",
  "Buia (Italy)",
  "Buonabitacolo (Italy)",
  "Burano (Italy)",
  "Buriasco (Italy)",
  "Burolo (Italy)",
  "Buronzo (Italy)",
  "Busalla (Italy)",
  "Busca (Italy)",
  "Buscate (Italy)",
  "Buscoldo (Italy)",
  "Busseto (Italy)",
  "Bussi sul Tirino (Italy)",
  "Bussolengo (Italy)",
  "Bussoleno (Italy)",
  "Busto Arsizio (Italy)",
  "Busto Garolfo (Italy)",
  "Butera (Italy)",
  "Buti (Italy)",
  "Buttapietra (Italy)",
  "Buttigliera Alta (Italy)",
  "Buttrio (Italy)",
  "Cabras (Italy)",
  "Caccamo (Italy)",
  "Caccuri (Italy)",
  "Cadelbosco di Sopra (Italy)",
  "Cadeo (Italy)",
  "Cadoneghe (Italy)",
  "Caerano di San Marco (Italy)",
  "Caggiano (Italy)",
  "Cagli (Italy)",
  "Cagliari (Italy)",
  "Cagnano Varano (Italy)",
  "Caianello (Italy)",
  "Caiatia (Italy)",
  "Cairate (Italy)",
  "Cairo Montenotte (Italy)",
  "Caivano (Italy)",
  "Calalzo di Cadore (Italy)",
  "Calamandrana (Italy)",
  "Calangianus (Italy)",
  "Calascibetta (Italy)",
  "Calatabiano (Italy)",
  "Calatafimi (Italy)",
  "Calcara (Italy)",
  "Calcata (Italy)",
  "Calci (Italy)",
  "Calcinaia (Italy)",
  "Calcinate (Italy)",
  "Calcinato (Italy)",
  "Calcinelli (Italy)",
  "Calcio (Italy)",
  "Caldaro sulla Strada del Vino (Italy)",
  "Caldarola (Italy)",
  "Calderara di Reno (Italy)",
  "Caldogno (Italy)",
  "Caldonazzo (Italy)",
  "Calendasco (Italy)",
  "Calenzano (Italy)",
  "Calice al Cornoviglio (Italy)",
  "Calimera (Italy)",
  "Calitri (Italy)",
  "Calizzano (Italy)",
  "Calliano (Italy)",
  "Calolziocorte (Italy)",
  "Caltagirone (Italy)",
  "Caltanissetta (Italy)",
  "Caltavuturo (Italy)",
  "Caluso (Italy)",
  "Calvanico (Italy)",
  "Calvatone (Italy)",
  "Calvenzano (Italy)",
  "Calvisano (Italy)",
  "Calvizzano (Italy)",
  "Camaiore (Italy)",
  "Cambiago (Italy)",
  "Cambiano (Italy)",
  "Camburzano (Italy)",
  "Camerano (Italy)",
  "Camerata Picena (Italy)",
  "Cameri (Italy)",
  "Cameriano (Italy)",
  "Camerino (Italy)",
  "Camerlona (Italy)",
  "Caminetto (Italy)",
  "Camino (Italy)",
  "Camisa (Italy)",
  "Camisano (Italy)",
  "Camisano Vicentino (Italy)",
  "Camogli (Italy)",
  "Campagna (Italy)",
  "Campagnano di Roma (Italy)",
  "Campagnola (Italy)",
  "Campagnola Emilia (Italy)",
  "Camparada (Italy)",
  "Campegine (Italy)",
  "Campertogno (Italy)",
  "Campi (Italy)",
  "Campi Bisenzio (Italy)",
  "Campi Salentina (Italy)",
  "Campiglia Marittima (Italy)",
  "Campiglio (Italy)",
  "Campione (Italy)",
  "Campli (Italy)",
  "Campo (Italy)",
  "Campo Ligure (Italy)",
  "Campo San Martino (Italy)",
  "Campo Tizzoro (Italy)",
  "Campobasso (Italy)",
  "Campobello di Licata (Italy)",
  "Campobello di Mazara (Italy)",
  "Campodarsego (Italy)",
  "Campodenno (Italy)",
  "Campodimele (Italy)",
  "Campodipietra (Italy)",
  "Campodoro (Italy)",
  "Campofelice di Roccella (Italy)",
  "Campofilone (Italy)",
  "Campoformido (Italy)",
  "Campofranco (Italy)",
  "Campogalliano (Italy)",
  "Campoleone (Italy)",
  "Campoli Appennino (Italy)",
  "Campolieto (Italy)",
  "Campolongo Maggiore (Italy)",
  "Campomarino (Italy)",
  "Campomorone (Italy)",
  "Camponogara (Italy)",
  "Campora (Italy)",
  "Camporeale (Italy)",
  "Camporosso (Italy)",
  "Camposampiero (Italy)",
  "Camposano (Italy)",
  "Canale (Italy)",
  "Canaro (Italy)",
  "Canazei (Italy)",
  "Cancello (Italy)",
  "Cancello ed Arnone (Italy)",
  "Candelo (Italy)",
  "Candia Canavese (Italy)",
  "Candia Lomellina (Italy)",
  "Candiana (Italy)",
  "Candiolo (Italy)",
  "Canegrate (Italy)",
  "Canelli (Italy)",
  "Canicattini Bagni (Italy)",
  "Canino (Italy)",
  "Canistro (Italy)",
  "Cannara (Italy)",
  "Cannella (Italy)",
  "Cannero Riviera (Italy)",
  "Canneto Pavese (Italy)",
  "Cannobio (Italy)",
  "Canonica (Italy)",
  "Canosa di Puglia (Italy)",
  "Canosa Sannita (Italy)",
  "Canossa (Italy)",
  "Cantagallo (Italy)",
  "Cantalice (Italy)",
  "Cantalupo in Sabina (Italy)",
  "Cantalupo Ligure (Italy)",
  "Cantarana (Italy)",
  "Cantu (Italy)",
  "Canzo (Italy)",
  "Caorle (Italy)",
  "Caorso (Italy)",
  "Capaccio (Italy)",
  "Capaci (Italy)",
  "Capalbio (Italy)",
  "Capannelle (Italy)",
  "Capannoli (Italy)",
  "Capannori (Italy)",
  "Capena (Italy)",
  "Capergnanica (Italy)",
  "Capistrano (Italy)",
  "Capistrello (Italy)",
  "Capitignano (Italy)",
  "Capo Rizzuto (Italy)",
  "Capodimonte (Italy)",
  "Capodrise (Italy)",
  "Capoliveri (Italy)",
  "Capoterra (Italy)",
  "Cappella Maggiore (Italy)",
  "Cappelle sul Tavo (Italy)",
  "Capralba (Italy)",
  "Capranica (Italy)",
  "Caprese Michelangelo (Italy)",
  "Capri (Italy)",
  "Capriate San Gervasio (Italy)",
  "Caprie (Italy)",
  "Caprino Bergamasco (Italy)",
  "Caprino Veronese (Italy)",
  "Capriolo (Italy)",
  "Capriva del Friuli (Italy)",
  "Capua (Italy)",
  "Capurso (Italy)",
  "Caraglio (Italy)",
  "Carapelle (Italy)",
  "Carasco (Italy)",
  "Carate Brianza (Italy)",
  "Caravaggio (Italy)",
  "Caravate (Italy)",
  "Caravino (Italy)",
  "Caravonica (Italy)",
  "Carbonara al Ticino (Italy)",
  "Carbonara di Bari (Italy)",
  "Carbonara di Po (Italy)",
  "Carbonera (Italy)",
  "Carbonia (Italy)",
  "Carcare (Italy)",
  "Carcereri (Italy)",
  "Carceri (Italy)",
  "Cardano (Italy)",
  "Cardito (Italy)",
  "Carema (Italy)",
  "Carenno (Italy)",
  "Carentino (Italy)",
  "Caresana (Italy)",
  "Cariati (Italy)",
  "Carignano (Italy)",
  "Carini (Italy)",
  "Carinola (Italy)",
  "Carlazzo (Italy)",
  "Carlentini (Italy)",
  "Carlino (Italy)",
  "Carloforte (Italy)",
  "Carmagnola (Italy)",
  "Carmiano (Italy)",
  "Carmignano (Italy)",
  "Carmignano di Brenta (Italy)",
  "Carnago (Italy)",
  "Carnate (Italy)",
  "Carobbio (Italy)",
  "Carolei (Italy)",
  "Caronia (Italy)",
  "Carosino (Italy)",
  "Carovigno (Italy)",
  "Carpaneto Piacentino (Italy)",
  "Carpegna (Italy)",
  "Carpenedolo (Italy)",
  "Carpeneto (Italy)",
  "Carpi (Italy)",
  "Carpiano (Italy)",
  "Carpignano Sesia (Italy)",
  "Carpinello (Italy)",
  "Carpineti (Italy)",
  "Carpineto Romano (Italy)",
  "Carrara (Italy)",
  "Carsoli (Italy)",
  "Cartoceto (Italy)",
  "Cartura (Italy)",
  "Carugate (Italy)",
  "Carvico (Italy)",
  "Casa Bartolacelli-Stella (Italy)",
  "Casa Gherardi (Italy)",
  "Casa Pace (Italy)",
  "Casa Paradiso (Italy)",
  "Casa Zola (Italy)",
  "Casacalenda (Italy)",
  "Casacanditella (Italy)",
  "Casaccia II (Italy)",
  "Casaglia (Italy)",
  "Casal di Principe (Italy)",
  "Casal Velino (Italy)",
  "Casalbordino (Italy)",
  "Casalborgone (Italy)",
  "Casalbuttano ed Uniti (Italy)",
  "Casale (Italy)",
  "Casale Corte Cerro (Italy)",
  "Casale di Mezzani (Italy)",
  "Casale di Scodosia (Italy)",
  "Casale Monferrato (Italy)",
  "Casale sul Sile (Italy)",
  "Casalecchio di Reno (Italy)",
  "Casaleggio Novara (Italy)",
  "Casaletto Spartano (Italy)",
  "Casalgrande (Italy)",
  "Casalgrasso (Italy)",
  "Casali (Italy)",
  "Casalmaggiore (Italy)",
  "Casalmaiocco (Italy)",
  "Casalmorano (Italy)",
  "Casalnuovo di Napoli (Italy)",
  "Casaloldo (Italy)",
  "Casalpusterlengo (Italy)",
  "Casalromano (Italy)",
  "Casalserugo (Italy)",
  "Casaluce (Italy)",
  "Casalvecchio Siculo (Italy)",
  "Casalvieri (Italy)",
  "Casalvolone (Italy)",
  "Casalzuigno (Italy)",
  "Casamarciano (Italy)",
  "Casamassima (Italy)",
  "Casandrino (Italy)",
  "Casanova (Italy)",
  "Casapulla (Italy)",
  "Casarano (Italy)",
  "Casaratta (Italy)",
  "Casargo (Italy)",
  "Casarile (Italy)",
  "Casarsa della Delizia (Italy)",
  "Casate Raffa (Italy)",
  "Casateia (Italy)",
  "Casatenovo (Italy)",
  "Casavecchia (Italy)",
  "Casazza (Italy)",
  "Cascia (Italy)",
  "Casciana Terme (Italy)",
  "Cascina (Italy)",
  "Cascine Maggio (Italy)",
  "Case (Italy)",
  "Case Catania (Italy)",
  "Case Franchini (Italy)",
  "Casei Gerola (Italy)",
  "Caselette (Italy)",
  "Caselle (Italy)",
  "Caselle Lurani (Italy)",
  "Caselle Torinese (Italy)",
  "Caserta (Italy)",
  "Casier (Italy)",
  "Casignana (Italy)",
  "Casina (Italy)",
  "Casinalbo (Italy)",
  "Casnigo (Italy)",
  "Casola di Napoli (Italy)",
  "Casoli (Italy)",
  "Casorate Primo (Italy)",
  "Casorate Sempione (Italy)",
  "Casorezzo (Italy)",
  "Casoria (Italy)",
  "Casperia (Italy)",
  "Caspoggio (Italy)",
  "Cassacco (Italy)",
  "Cassano (Italy)",
  "Cassano delle Murge (Italy)",
  "Cassano Spinola (Italy)",
  "Cassine Superiore (Italy)",
  "Cassino (Italy)",
  "Cassolnovo (Italy)",
  "Castagnaro (Italy)",
  "Castagneto Carducci (Italy)",
  "Castagneto Po (Italy)",
  "Castagnole (Italy)",
  "Castano Primo (Italy)",
  "Casteggio (Italy)",
  "Castegnato (Italy)",
  "Castel (Italy)",
  "Castel Bolognese (Italy)",
  "Castel Colonna (Italy)",
  "Castel del Piano (Italy)",
  "Castel di Casio (Italy)",
  "Castel di Judica (Italy)",
  "Castel Focognano (Italy)",
  "Castel Frentano (Italy)",
  "Castel Gabbiano (Italy)",
  "Castel Gandolfo (Italy)",
  "Castel Giorgio (Italy)",
  "Castel Goffredo (Italy)",
  "Castel Guelfo di Bologna (Italy)",
  "Castel Madama (Italy)",
  "Castel Maggiore (Italy)",
  "Castel Mella (Italy)",
  "Castel San Giovanni (Italy)",
  "Castel San Lorenzo (Italy)",
  "Castel San Pietro Romano (Italy)",
  "Castel San Pietro Terme (Italy)",
  "Castel Viscardo (Italy)",
  "Castel Volturno (Italy)",
  "Castelbellino (Italy)",
  "Castelbuono (Italy)",
  "Castelcucco (Italy)",
  "Casteldaccia (Italy)",
  "Castelfidardo (Italy)",
  "Castelfiorentino (Italy)",
  "Castelfranci (Italy)",
  "Castelfranco di Sopra (Italy)",
  "Castelfranco di Sotto (Italy)",
  "Castelfranco Emilia (Italy)",
  "Castelfranco Veneto (Italy)",
  "Castelgomberto (Italy)",
  "Castelgrande (Italy)",
  "Castellabate (Italy)",
  "Castellalto (Italy)",
  "Castellammare del Golfo (Italy)",
  "Castellammare di Stabia (Italy)",
  "Castellamonte (Italy)",
  "Castellana Grotte (Italy)",
  "Castellana Sicula (Italy)",
  "Castellaneta (Italy)",
  "Castellanza (Italy)",
  "Castellar Guidobono (Italy)",
  "Castellarano (Italy)",
  "Castellaro (Italy)",
  "Castellazzo Bormida (Italy)",
  "Castellengo (Italy)",
  "Castelleone (Italy)",
  "Castelletto (Italy)",
  "Castelletto sopra Ticino (Italy)",
  "Castelli (Italy)",
  "Castelli Calepio (Italy)",
  "Castellina in Chianti (Italy)",
  "Castellina Marittima (Italy)",
  "Castelliri (Italy)",
  "Castello (Italy)",
  "Castello di Annone (Italy)",
  "Castello di Godego (Italy)",
  "Castello di Serravalle (Italy)",
  "Castellucchio (Italy)",
  "Castelluccio Inferiore (Italy)",
  "Castelmassa (Italy)",
  "Castelnovo di Sotto (Italy)",
  "Castelnuovo (Italy)",
  "Castelnuovo Berardenga (Italy)",
  "Castelnuovo dei Sabbioni (Italy)",
  "Castelnuovo della Daunia (Italy)",
  "Castelnuovo di Garfagnana (Italy)",
  "Castelnuovo di Porto (Italy)",
  "Castelnuovo di Val di Cecina (Italy)",
  "Castelnuovo di Verona (Italy)",
  "Castelnuovo Don Bosco (Italy)",
  "Castelnuovo Magra (Italy)",
  "Castelnuovo Nigra (Italy)",
  "Castelnuovo Rangone (Italy)",
  "Castelnuovo Scrivia (Italy)",
  "Castelpagano (Italy)",
  "Castelplanio (Italy)",
  "Castelpoto (Italy)",
  "Castelraimondo (Italy)",
  "Castelseprio (Italy)",
  "Casteltermini (Italy)",
  "Castelvecchio (Italy)",
  "Castelvecchio Pascoli (Italy)",
  "Castelvenere (Italy)",
  "Castelverde (Italy)",
  "Castelvetrano (Italy)",
  "Castelvetro di Modena (Italy)",
  "Castenaso (Italy)",
  "Castenedolo (Italy)",
  "Castiadas (Italy)",
  "Castiglion Fibocchi (Italy)",
  "Castiglion Fiorentino (Italy)",
  "Castiglione (Italy)",
  "Castiglione Chiavarese (Italy)",
  "Castiglione Cosentino (Italy)",
  "Castiglione dei Pepoli (Italy)",
  "Castiglione del Lago (Italy)",
  "Castiglione della Pescaia (Italy)",
  "Castiglione delle Stiviere (Italy)",
  "Castiglione di Cervia (Italy)",
  "Castiglione di Garfagnana (Italy)",
  "Castiglione di Sicilia (Italy)",
  "Castiglione Messer Raimondo (Italy)",
  "Castiglione Olona (Italy)",
  "Castignano (Italy)",
  "Castilenti (Italy)",
  "Castione della Presolana (Italy)",
  "Castions di Strada (Italy)",
  "Casto (Italy)",
  "Castorano (Italy)",
  "Castri di Lecce (Italy)",
  "Castrignano del Capo (Italy)",
  "Castro dei Volsci (Italy)",
  "Castrocaro Terme (Italy)",
  "Castrofilippo (Italy)",
  "Castrolibero (Italy)",
  "Castronno (Italy)",
  "Castroreale (Italy)",
  "Castrovillari (Italy)",
  "Catania (Italy)",
  "Catanzaro (Italy)",
  "Catenanuova (Italy)",
  "Catignano (Italy)",
  "Cattolica (Italy)",
  "Cattolica Eraclea (Italy)",
  "Caulonia (Italy)",
  "Cava Manara (Italy)",
  "Cavacurta (Italy)",
  "Cavagnolo (Italy)",
  "Cavaion Veronese (Italy)",
  "Cavalese (Italy)",
  "Cavallermaggiore (Italy)",
  "Cavallino (Italy)",
  "Cavallirio (Italy)",
  "Cavareno (Italy)",
  "Cavaria con Premezzo (Italy)",
  "Cavarzere (Italy)",
  "Cave (Italy)",
  "Cavenago di Brianza (Italy)",
  "Cavezzo (Italy)",
  "Cavour (Italy)",
  "Cavriago (Italy)",
  "Cavriana (Italy)",
  "Cavriglia (Italy)",
  "Cazzago San Martino (Italy)",
  "Ceccano (Italy)",
  "Cecchini (Italy)",
  "Cecina (Italy)",
  "Cedessano (Italy)",
  "Cedrasco (Italy)",
  "Ceggia (Italy)",
  "Ceglie Messapica (Italy)",
  "Celano (Italy)",
  "Celenza Valfortore (Italy)",
  "Celico (Italy)",
  "Cellamare (Italy)",
  "Celle Ligure (Italy)",
  "Cellino Attanasio (Italy)",
  "Cellino San Marco (Italy)",
  "Cellio (Italy)",
  "Cellole (Italy)",
  "Cembra (Italy)",
  "Cenaia (Italy)",
  "Cencenighe Agordino (Italy)",
  "Cene (Italy)",
  "Cengio (Italy)",
  "Centallo (Italy)",
  "Cento (Italy)",
  "Centola (Italy)",
  "Centrale (Italy)",
  "Centuripe (Italy)",
  "Cepagatti (Italy)",
  "Ceppaloni (Italy)",
  "Ceprano (Italy)",
  "Cerano (Italy)",
  "Cerasolo (Italy)",
  "Cercepiccola (Italy)",
  "Cercola (Italy)",
  "Cerea (Italy)",
  "Ceregnano (Italy)",
  "Ceres (Italy)",
  "Ceresara (Italy)",
  "Cerete (Italy)",
  "Ceriale (Italy)",
  "Ceriano Laghetto (Italy)",
  "Cerignola (Italy)",
  "Cermenate (Italy)",
  "Cernobbio (Italy)",
  "Cernusco sul Naviglio (Italy)",
  "Cerredolo (Italy)",
  "Cerreto Guidi (Italy)",
  "Cerreto Laziale (Italy)",
  "Cerreto Sannita (Italy)",
  "Cerrione (Italy)",
  "Cerro Maggiore (Italy)",
  "Certaldo (Italy)",
  "Certosa (Italy)",
  "Certosa di Pavia (Italy)",
  "Cervaro (Italy)",
  "Cerveno (Italy)",
  "Cervere (Italy)",
  "Cerveteri (Italy)",
  "Cervia (Italy)",
  "Cervignano del Friuli (Italy)",
  "Cervino (Italy)",
  "Cervo (Italy)",
  "Cesa (Italy)",
  "Cesana Torinese (Italy)",
  "Cesano Maderno (Italy)",
  "Cesara (Italy)",
  "Cese (Italy)",
  "Cesena (Italy)",
  "Cesenatico (Italy)",
  "Cesiomaggiore (Italy)",
  "Ceto (Italy)",
  "Cetraro (Italy)",
  "Ceva (Italy)",
  "Chambave (Italy)",
  "Champdepraz (Italy)",
  "Charvensod (Italy)",
  "Cherasco (Italy)",
  "Chiampo (Italy)",
  "Chianciano Terme (Italy)",
  "Chiara (Italy)",
  "Chiaramonte Gulfi (Italy)",
  "Chiarano (Italy)",
  "Chiaravalle (Italy)",
  "Chiaravalle Centrale (Italy)",
  "Chiari (Italy)",
  "Chiavari (Italy)",
  "Chiavazza (Italy)",
  "Chiavenna (Italy)",
  "Chieri (Italy)",
  "Chiesina Uzzanese (Italy)",
  "Chieti (Italy)",
  "Chioggia (Italy)",
  "Chiomonte (Italy)",
  "Chions (Italy)",
  "Chitignano (Italy)",
  "Chiusanico (Italy)",
  "Chiusano di San Domenico (Italy)",
  "Chiusavecchia (Italy)",
  "Chiusi (Italy)",
  "Chiusi della Verna (Italy)",
  "Chivasso (Italy)",
  "Cicagna (Italy)",
  "Cicciano (Italy)",
  "Ciciliano (Italy)",
  "Cicognolo (Italy)",
  "Cigliano (Italy)",
  "Cignone (Italy)",
  "Cilavegna (Italy)",
  "Cimadolmo (Italy)",
  "Cimego (Italy)",
  "Ciminna (Italy)",
  "Cimitile (Italy)",
  "Cinecitta (Italy)",
  "Cingoli (Italy)",
  "Cinisello Balsamo (Italy)",
  "Cinisi (Italy)",
  "Cinquefrondi (Italy)",
  "Cintano (Italy)",
  "Cinto Caomaggiore (Italy)",
  "Circello (Italy)",
  "Cirimido (Italy)",
  "Cisano Bergamasco (Italy)",
  "Cisano sul Neva (Italy)",
  "Ciserano (Italy)",
  "Cislago (Italy)",
  "Cisliano (Italy)",
  "Cison di Valmarino (Italy)",
  "Cisterna di Latina (Italy)",
  "Cisternino (Italy)",
  "Cittadella (Italy)",
  "Cittaducale (Italy)",
  "Cittanova (Italy)",
  "Cittiglio (Italy)",
  "Civezzano (Italy)",
  "Cividale del Friuli (Italy)",
  "Cividate Camuno (Italy)",
  "Civita Castellana (Italy)",
  "Civitanova Alta (Italy)",
  "Civitanova Marche (Italy)",
  "Civitavecchia (Italy)",
  "Civitella del Tronto (Italy)",
  "Civitella di Romagna (Italy)",
  "Civitella in Val di Chiana (Italy)",
  "Civitella Marittima (Italy)",
  "Civitella Roveto (Italy)",
  "Civitella San Paolo (Italy)",
  "Cizzago-Comezzano (Italy)",
  "Cles (Italy)",
  "Clusone (Italy)",
  "Coccaglio (Italy)",
  "Cocconato (Italy)",
  "Cocquio-Trevisago (Italy)",
  "Codaruina (Italy)",
  "Codevigo (Italy)",
  "Codigoro (Italy)",
  "Codogno (Italy)",
  "Codroipo (Italy)",
  "Codrongianos (Italy)",
  "Coggiola (Italy)",
  "Cogne (Italy)",
  "Cogoleto (Italy)",
  "Cogorno (Italy)",
  "Cola (Italy)",
  "Colbordolo (Italy)",
  "Colere (Italy)",
  "Colico (Italy)",
  "Collalto (Italy)",
  "Collazzone (Italy)",
  "Colle (Italy)",
  "Colle Umberto (Italy)",
  "Collecchio (Italy)",
  "Collecorvino (Italy)",
  "Colledara (Italy)",
  "Colledimezzo (Italy)",
  "Colleferro (Italy)",
  "Collefontana-Fontana Liri Inferiore (Italy)",
  "Collegno (Italy)",
  "Collelungo (Italy)",
  "Collepasso (Italy)",
  "Collesano (Italy)",
  "Colli del Tronto (Italy)",
  "Colliano (Italy)",
  "Collinas (Italy)",
  "Colloredo di Prato (Italy)",
  "Colmurano (Italy)",
  "Cologna (Italy)",
  "Cologna Veneta (Italy)",
  "Cologne (Italy)",
  "Cologno al Serio (Italy)",
  "Cologno Monzese (Italy)",
  "Colombaro (Italy)",
  "Colonna (Italy)",
  "Colonnella (Italy)",
  "Colorno (Italy)",
  "Coltura (Italy)",
  "Comacchio (Italy)",
  "Comano (Italy)",
  "Comazzo (Italy)",
  "Comerio (Italy)",
  "Comiso (Italy)",
  "Como (Italy)",
  "Compiano (Italy)",
  "Comun Nuovo (Italy)",
  "Cona (Italy)",
  "Conco (Italy)",
  "Concordia Sagittaria (Italy)",
  "Concordia sulla Secchia (Italy)",
  "Concorezzo (Italy)",
  "Condofuri (Italy)",
  "Condove (Italy)",
  "Conegliano (Italy)",
  "Confienza (Italy)",
  "Conselice (Italy)",
  "Conselve (Italy)",
  "Contigliano (Italy)",
  "Contrada (Italy)",
  "Controguerra (Italy)",
  "Contursi Terme (Italy)",
  "Conversano (Italy)",
  "Copertino (Italy)",
  "Copiano (Italy)",
  "Copparo (Italy)",
  "Coppe (Italy)",
  "Coppito (Italy)",
  "Corato (Italy)",
  "Corbetta (Italy)",
  "Corbola (Italy)",
  "Corchiano (Italy)",
  "Corciano (Italy)",
  "Cordenons (Italy)",
  "Cordignano (Italy)",
  "Cordovado (Italy)",
  "Corea Castelluccia San Paolo (Italy)",
  "Coredo (Italy)",
  "Corfinio (Italy)",
  "Cori (Italy)",
  "Coriano (Italy)",
  "Corigliano Calabro (Italy)",
  "Corinaldo (Italy)",
  "Corio (Italy)",
  "Corleone (Italy)",
  "Corleto Perticara (Italy)",
  "Cormons (Italy)",
  "Cornaiano (Italy)",
  "Cornaredo (Italy)",
  "Cornedo Vicentino (Italy)",
  "Corno (Italy)",
  "Corno di Rosazzo (Italy)",
  "Corno Giovine (Italy)",
  "Cornuda (Italy)",
  "Corona (Italy)",
  "Correggio (Italy)",
  "Correzzola (Italy)",
  "Corridonia (Italy)",
  "Corropoli (Italy)",
  "Corsano (Italy)",
  "Corsico (Italy)",
  "Cortandone (Italy)",
  "Corte (Italy)",
  "Corte Franca (Italy)",
  "Cortemaggiore (Italy)",
  "Cortenova (Italy)",
  "Corteolona (Italy)",
  "Corti (Italy)",
  "Cortina (Italy)",
  "Cortona (Italy)",
  "Corvara (Italy)",
  "Corzano (Italy)",
  "Coseano (Italy)",
  "Cosenza (Italy)",
  "Cossato (Italy)",
  "Costa (Italy)",
  "Costa di Rovigo (Italy)",
  "Costa Volpino (Italy)",
  "Costabissara (Italy)",
  "Costarainera (Italy)",
  "Costermano (Italy)",
  "Cotignola (Italy)",
  "Cotronei (Italy)",
  "Courmayeur (Italy)",
  "Covo (Italy)",
  "Cozzo (Italy)",
  "Creazzo (Italy)",
  "Crema (Italy)",
  "Cremolino (Italy)",
  "Cremona (Italy)",
  "Crescentino (Italy)",
  "Crespano del Grappa (Italy)",
  "Crespellano (Italy)",
  "Crespiatica (Italy)",
  "Crespina (Italy)",
  "Crespino (Italy)",
  "Cressa (Italy)",
  "Crevacuore (Italy)",
  "Crevalcore (Italy)",
  "Crispiano (Italy)",
  "Croce (Italy)",
  "Crocetta del Montello (Italy)",
  "Crodo (Italy)",
  "Cropani (Italy)",
  "Crosia (Italy)",
  "Crotone (Italy)",
  "Cuceglio (Italy)",
  "Cuggiono (Italy)",
  "Cuglieri (Italy)",
  "Cugnoli (Italy)",
  "Cumiana (Italy)",
  "Cumignano sul Naviglio (Italy)",
  "Cuneo (Italy)",
  "Cunico (Italy)",
  "Cupello (Italy)",
  "Cupra Marittima (Italy)",
  "Cupramontana (Italy)",
  "Curcuris (Italy)",
  "Curinga (Italy)",
  "Curno (Italy)",
  "Curtarolo (Italy)",
  "Cusano Milanino (Italy)",
  "Cusano Mutri (Italy)",
  "Cusino (Italy)",
  "Custonaci (Italy)",
  "Cutrofiano (Italy)",
  "Dairago (Italy)",
  "Dalmine (Italy)",
  "Darfo (Italy)",
  "Davoli (Italy)",
  "Decima (Italy)",
  "Decollatura (Italy)",
  "Deggiano (Italy)",
  "Dego (Italy)",
  "Deiva Marina (Italy)",
  "Delia (Italy)",
  "Delianuova (Italy)",
  "Dello (Italy)",
  "Demonte (Italy)",
  "Deruta (Italy)",
  "Dervio (Italy)",
  "Desenzano del Garda (Italy)",
  "Desio (Italy)",
  "Deutschnofen (Italy)",
  "Diamante (Italy)",
  "Diano Marina (Italy)",
  "Diano San Pietro (Italy)",
  "Dicomano (Italy)",
  "Diecimo (Italy)",
  "Dignano (Italy)",
  "Dimaro (Italy)",
  "Dipignano (Italy)",
  "Dogliani (Italy)",
  "Dolceacqua (Italy)",
  "Dolianova (Italy)",
  "Dolo (Italy)",
  "Domanico (Italy)",
  "Domaso (Italy)",
  "Domegliara (Italy)",
  "Domodossola (Italy)",
  "Domusnovas (Italy)",
  "Dongo (Italy)",
  "Doria (Italy)",
  "Dorno (Italy)",
  "Dosolo (Italy)",
  "Dossobuono (Italy)",
  "Dosson (Italy)",
  "Dovadola (Italy)",
  "Dozza (Italy)",
  "Drapia (Italy)",
  "Dro (Italy)",
  "Dronero (Italy)",
  "Druento (Italy)",
  "Druogno (Italy)",
  "Dubino (Italy)",
  "Dueville (Italy)",
  "Dugenta (Italy)",
  "Duino-Aurisina (Italy)",
  "Eboli (Italy)",
  "Edolo (Italy)",
  "Elmas (Italy)",
  "Empoli (Italy)",
  "Endine Gaiano (Italy)",
  "Enemonzo-Quinis (Italy)",
  "Enna (Italy)",
  "Envie (Italy)",
  "Eraclea (Italy)",
  "Erba (Italy)",
  "Erchie (Italy)",
  "Ercolano (Italy)",
  "Erice (Italy)",
  "Erve (Italy)",
  "Escalaplano (Italy)",
  "Escolca (Italy)",
  "Esine (Italy)",
  "Esino Lario (Italy)",
  "Esperia (Italy)",
  "Este (Italy)",
  "Etroubles (Italy)",
  "Fabbri (Italy)",
  "Fabbrica (Italy)",
  "Fabbrico (Italy)",
  "Fabriano (Italy)",
  "Fabrica di Roma (Italy)",
  "Faedis (Italy)",
  "Faenza (Italy)",
  "Fagagna (Italy)",
  "Faggiano (Italy)",
  "Fagnano Olona (Italy)",
  "Faiano (Italy)",
  "Falciano (Italy)",
  "Falconara (Italy)",
  "Falconara Albanese (Italy)",
  "Falconara Marittima (Italy)",
  "Falcone (Italy)",
  "Falerna (Italy)",
  "Falerone (Italy)",
  "Falze-Signoressa (Italy)",
  "Fanano (Italy)",
  "Fanna (Italy)",
  "Fano (Italy)",
  "Fantina (Italy)",
  "Fara Filiorum Petri (Italy)",
  "Fara in Sabina (Italy)",
  "Fara Novarese (Italy)",
  "Farnese (Italy)",
  "Farra di Soligo (Italy)",
  "Fasano (Italy)",
  "Fastello (Italy)",
  "Fauglia (Italy)",
  "Favara (Italy)",
  "Favaro Veneto (Italy)",
  "Favria (Italy)",
  "Feldthurns (Italy)",
  "Felegara (Italy)",
  "Feletto (Italy)",
  "Feletto Umberto (Italy)",
  "Felino (Italy)",
  "Felitto (Italy)",
  "Feltre (Italy)",
  "Fenile (Italy)",
  "Ferentino (Italy)",
  "Fermignano (Italy)",
  "Fermo (Italy)",
  "Ferno (Italy)",
  "Feroleto della Chiesa (Italy)",
  "Ferrandina (Italy)",
  "Ferrara (Italy)",
  "Ferrari (Italy)",
  "Ferrazzano (Italy)",
  "Ferrero (Italy)",
  "Ferriere (Italy)",
  "Fiano (Italy)",
  "Fiano Romano (Italy)",
  "Ficarazzi (Italy)",
  "Ficarolo (Italy)",
  "Ficarra (Italy)",
  "Ficulle (Italy)",
  "Fidenza (Italy)",
  "Field (Italy)",
  "Fiera (Italy)",
  "Fiesole (Italy)",
  "Fiesso Umbertiano (Italy)",
  "Figino (Italy)",
  "Figline Valdarno (Italy)",
  "Filadelfia (Italy)",
  "Filettino (Italy)",
  "Filetto (Italy)",
  "Filiano (Italy)",
  "Filighera (Italy)",
  "Filo (Italy)",
  "Filottrano (Italy)",
  "Finale Emilia (Italy)",
  "Finale Ligure (Italy)",
  "Fino Mornasco (Italy)",
  "Fiorano Modenese (Italy)",
  "Fiore (Italy)",
  "Fisciano (Italy)",
  "Fiuggi (Italy)",
  "Fiume Veneto (Italy)",
  "Fiumefreddo di Sicilia (Italy)",
  "Fiumicello (Italy)",
  "Fiumicino (Italy)",
  "Flaibano (Italy)",
  "Flero (Italy)",
  "Florence (Italy)",
  "Floridia (Italy)",
  "Fluminimaggiore (Italy)",
  "Flussio (Italy)",
  "Foggia (Italy)",
  "Foglianise (Italy)",
  "Fogliano Redipuglia (Italy)",
  "Foglizzo (Italy)",
  "Foiano della Chiana (Italy)",
  "Foiano di Val Fortore (Italy)",
  "Folgaria (Italy)",
  "Folignano (Italy)",
  "Foligno (Italy)",
  "Follina (Italy)",
  "Follonica (Italy)",
  "Fombio (Italy)",
  "Fondi (Italy)",
  "Fonni (Italy)",
  "Fontana (Italy)",
  "Fontana Liri (Italy)",
  "Fontanafredda (Italy)",
  "Fontanarosa (Italy)",
  "Fontanelice (Italy)",
  "Fontanella (Italy)",
  "Fontanellato (Italy)",
  "Fontanelle (Italy)",
  "Fontaniva (Italy)",
  "Fontevivo (Italy)",
  "Fonzaso (Italy)",
  "Forano (Italy)",
  "Forenza (Italy)",
  "Foresto (Italy)",
  "Forino (Italy)",
  "Forio (Italy)",
  "Forlimpopoli (Italy)",
  "Formello (Italy)",
  "Formia (Italy)",
  "Formicola (Italy)",
  "Formigine (Italy)",
  "Fornace di Miramare (Italy)",
  "Fornaci (Italy)",
  "Fornaci di Barga (Italy)",
  "Forno (Italy)",
  "Forno Alpi Graie (Italy)",
  "Forno Canavese (Italy)",
  "Fornovo di Taro (Italy)",
  "Forte dei Marmi (Italy)",
  "Fortunago (Italy)",
  "Fosco (Italy)",
  "Fosdinovo (Italy)",
  "Fossa (Italy)",
  "Fossacesia (Italy)",
  "Fossalta di Piave (Italy)",
  "Fossalta di Portogruaro (Italy)",
  "Fossano (Italy)",
  "Fossato di Vico (Italy)",
  "Fossombrone (Italy)",
  "Frabosa Sottana (Italy)",
  "Fragagnano (Italy)",
  "Francavilla al Mare (Italy)",
  "Francavilla di Sicilia (Italy)",
  "Francavilla Fontana (Italy)",
  "Francavilla in Sinni (Italy)",
  "Francenigo (Italy)",
  "Francofonte (Italy)",
  "Francolise (Italy)",
  "Frascaro (Italy)",
  "Frascati (Italy)",
  "Frascineto (Italy)",
  "Frassineto Po (Italy)",
  "Frasso Telesino (Italy)",
  "Frattamaggiore (Italy)",
  "Frattaminore (Italy)",
  "Frattocchie (Italy)",
  "Fregene (Italy)",
  "Frigento (Italy)",
  "Frisa (Italy)",
  "Front (Italy)",
  "Frontone (Italy)",
  "Frosinone (Italy)",
  "Frugarolo (Italy)",
  "Fucecchio (Italy)",
  "Fumane (Italy)",
  "Fumone (Italy)",
  "Furci Siculo (Italy)",
  "Furore (Italy)",
  "Fuscaldo (Italy)",
  "Fusignano (Italy)",
  "Futani (Italy)",
  "Gabbiano (Italy)",
  "Gabbro (Italy)",
  "Gabicce Mare (Italy)",
  "Gadoni (Italy)",
  "Gaeta (Italy)",
  "Gaggi (Italy)",
  "Gaggiano (Italy)",
  "Gaggio Montano (Italy)",
  "Gagliano del Capo (Italy)",
  "Gaiarine (Italy)",
  "Gaiole in Chianti (Italy)",
  "Gais (Italy)",
  "Galatina (Italy)",
  "Galatone (Italy)",
  "Galbiate (Italy)",
  "Galdo (Italy)",
  "Galeata (Italy)",
  "Gallarate (Italy)",
  "Gallese (Italy)",
  "Galliate (Italy)",
  "Gallicano (Italy)",
  "Gallicano nel Lazio (Italy)",
  "Galliera (Italy)",
  "Galliera Veneta (Italy)",
  "Gallignano (Italy)",
  "Gallio (Italy)",
  "Gallipoli (Italy)",
  "Gallo (Italy)",
  "Gambara (Italy)",
  "Gambellara (Italy)",
  "Gambettola (Italy)",
  "Gandino (Italy)",
  "Gangi (Italy)",
  "Garbagnate Milanese (Italy)",
  "Garda (Italy)",
  "Gardone Riviera (Italy)",
  "Gardone Val Trompia (Italy)",
  "Garessio (Italy)",
  "Gargnano (Italy)",
  "Garibaldi (Italy)",
  "Garlasco (Italy)",
  "Garlate (Italy)",
  "Garlenda (Italy)",
  "Garrufo (Italy)",
  "Gassino Torinese (Italy)",
  "Gattatico (Italy)",
  "Gatteo (Italy)",
  "Gatteo a Mare (Italy)",
  "Gattinara (Italy)",
  "Gavardo (Italy)",
  "Gavi (Italy)",
  "Gavinana (Italy)",
  "Gavirate (Italy)",
  "Gavoi (Italy)",
  "Gazoldo degli Ippoliti (Italy)",
  "Gazzada Schianno (Italy)",
  "Gazzaniga (Italy)",
  "Gazzo (Italy)",
  "Gazzo Veronese (Italy)",
  "Gazzola (Italy)",
  "Gazzuolo (Italy)",
  "Gela (Italy)",
  "Gemona (Italy)",
  "Genazzano (Italy)",
  "Genivolta (Italy)",
  "Genoa (Italy)",
  "Genzano di Roma (Italy)",
  "Gera Lario (Italy)",
  "Gerano (Italy)",
  "Gerenzago (Italy)",
  "Gergei (Italy)",
  "Germagnano (Italy)",
  "Gessate (Italy)",
  "Gessopalena (Italy)",
  "Gesualdo (Italy)",
  "Ghedi (Italy)",
  "Ghemme (Italy)",
  "Ghiffa (Italy)",
  "Ghilarza (Italy)",
  "Ghisalba (Italy)",
  "Giano Vetusto (Italy)",
  "Giardinello (Italy)",
  "Giardinetto Vecchio (Italy)",
  "Giardini (Italy)",
  "Giardini-Naxos (Italy)",
  "Giarratana (Italy)",
  "Giarre (Italy)",
  "Giaveno (Italy)",
  "Giavera del Montello (Italy)",
  "Gibellina (Italy)",
  "Giffoni Valle Piana (Italy)",
  "Gignese (Italy)",
  "Gimigliano (Italy)",
  "Ginosa (Italy)",
  "Gioia (Italy)",
  "Gioia del Colle (Italy)",
  "Gioia Tauro (Italy)",
  "Gioiosa Ionica (Italy)",
  "Gioiosa Marea (Italy)",
  "Giove (Italy)",
  "Giovinazzo (Italy)",
  "Girifalco (Italy)",
  "Gissi (Italy)",
  "Giugliano in Campania (Italy)",
  "Giuliana (Italy)",
  "Giuliano (Italy)",
  "Giuliano Teatino (Italy)",
  "Giulianova (Italy)",
  "Giungano (Italy)",
  "Giussago (Italy)",
  "Giussano (Italy)",
  "Godiasco (Italy)",
  "Godo (Italy)",
  "Goito (Italy)",
  "Golasecca (Italy)",
  "Golfo Aranci (Italy)",
  "Gonars (Italy)",
  "Gonnesa (Italy)",
  "Gonnosfanadiga (Italy)",
  "Gonnostramatza (Italy)",
  "Gonzaga (Italy)",
  "Gordona (Italy)",
  "Gorgo al Monticano (Italy)",
  "Gorgonzola (Italy)",
  "Gorizia (Italy)",
  "Gorla Maggiore (Italy)",
  "Gorle (Italy)",
  "Gorno (Italy)",
  "Gossolengo (Italy)",
  "Gottolengo (Italy)",
  "Gozzano (Italy)",
  "Gradara (Italy)",
  "Grado (Italy)",
  "Gradoli (Italy)",
  "Graglia (Italy)",
  "Gragnano (Italy)",
  "Gragnano Trebbiense (Italy)",
  "Grammichele (Italy)",
  "Grandate (Italy)",
  "Granitola Torretta (Italy)",
  "Grantorto (Italy)",
  "Grassano (Italy)",
  "Gravedona (Italy)",
  "Gravellona Toce (Italy)",
  "Gravina in Puglia (Italy)",
  "Grazzanise (Italy)",
  "Grazzano Badoglio (Italy)",
  "Greci (Italy)",
  "Gressoney-Saint-Jean (Italy)",
  "Greve in Chianti (Italy)",
  "Grezzana (Italy)",
  "Grignasco (Italy)",
  "Grigno (Italy)",
  "Grimaldi (Italy)",
  "Grisignano di Zocco (Italy)",
  "Gropello Cairoli (Italy)",
  "Grosio (Italy)",
  "Grosseto (Italy)",
  "Grotta (Italy)",
  "Grottaferrata (Italy)",
  "Grottaglie (Italy)",
  "Grottaminarda (Italy)",
  "Grottammare (Italy)",
  "Grottazzolina (Italy)",
  "Grotte (Italy)",
  "Grotte di Castro (Italy)",
  "Grottolella (Italy)",
  "Gruaro (Italy)",
  "Grugliasco (Italy)",
  "Grumello Cremonese (Italy)",
  "Grumello del Monte (Italy)",
  "Grumento Nova (Italy)",
  "Grumo Appula (Italy)",
  "Grumo Nevano (Italy)",
  "Grumolo delle Abbadesse (Italy)",
  "Guagnano (Italy)",
  "Gualdo Cattaneo (Italy)",
  "Gualdo Tadino (Italy)",
  "Gualtieri (Italy)",
  "Guamaggiore (Italy)",
  "Guarda Veneta (Italy)",
  "Guardamiglio (Italy)",
  "Guardavalle (Italy)",
  "Guardia Piemontese (Italy)",
  "Guardia Sanframondi (Italy)",
  "Guardiagrele (Italy)",
  "Guardialfiera (Italy)",
  "Guarrato (Italy)",
  "Guasila (Italy)",
  "Guastalla (Italy)",
  "Gubbio (Italy)",
  "Guidizzolo (Italy)",
  "Guido (Italy)",
  "Guidonia (Italy)",
  "Guiglia (Italy)",
  "Gurro (Italy)",
  "Guspini (Italy)",
  "Gussago (Italy)",
  "Gusti (Italy)",
  "Iesi (Italy)",
  "Iglesias (Italy)",
  "Il Ciocco (Italy)",
  "Illasi (Italy)",
  "Imola (Italy)",
  "Imperia (Italy)",
  "Impruneta (Italy)",
  "Induno Olona (Italy)",
  "Innichen (Italy)",
  "Inverigo (Italy)",
  "Inveruno (Italy)",
  "Inzago (Italy)",
  "Ischia (Italy)",
  "Ischia di Castro (Italy)",
  "Ischitella (Italy)",
  "Iseo (Italy)",
  "Isernia (Italy)",
  "Isili (Italy)",
  "Isola (Italy)",
  "Isola del Cantone (Italy)",
  "Isola del Giglio (Italy)",
  "Isola del Gran Sasso (Italy)",
  "Isola del Liri (Italy)",
  "Isola della Scala (Italy)",
  "Isola delle Femmine (Italy)",
  "Isola Dovarese (Italy)",
  "Isola Vicentina (Italy)",
  "Isorella (Italy)",
  "Ispica (Italy)",
  "Ispra (Italy)",
  "Issime (Italy)",
  "Istrana (Italy)",
  "Itala (Italy)",
  "Itri (Italy)",
  "Ittiri (Italy)",
  "Ivrea (Italy)",
  "Izano (Italy)",
  "Jesolo (Italy)",
  "Jolanda di Savoia (Italy)",
  "Klausen (Italy)",
  "La Gala (Italy)",
  "La Loggia (Italy)",
  "La Maddalena (Italy)",
  "La Morra (Italy)",
  "La Salle (Italy)",
  "La Salute di Livenza (Italy)",
  "La Spezia (Italy)",
  "La Valle Agordina (Italy)",
  "La Via (Italy)",
  "La Villa (Italy)",
  "Labico (Italy)",
  "Lacchiarella (Italy)",
  "Lacco Ameno (Italy)",
  "Ladispoli (Italy)",
  "Lago (Italy)",
  "Lagosanto (Italy)",
  "Laigueglia (Italy)",
  "Lainate (Italy)",
  "Laino Borgo (Italy)",
  "Laives (Italy)",
  "Lajatico (Italy)",
  "Lajen (Italy)",
  "Lake Bolsena (Italy)",
  "Lallio (Italy)",
  "Lama Mocogno (Italy)",
  "Lammari (Italy)",
  "Lamon (Italy)",
  "Lampedusa (Italy)",
  "Lamporecchio (Italy)",
  "Lana (Italy)",
  "Lancenigo (Italy)",
  "Lancenigo-Villorba (Italy)",
  "Lanciano (Italy)",
  "Landriano (Italy)",
  "Langhirano (Italy)",
  "Lanusei (Italy)",
  "Lanuvio (Italy)",
  "Lanzo Torinese (Italy)",
  "Lapio (Italy)",
  "Lappato (Italy)",
  "Larciano (Italy)",
  "Larderello (Italy)",
  "Lardirago (Italy)",
  "Lari (Italy)",
  "Lariano (Italy)",
  "Larino (Italy)",
  "Lasino (Italy)",
  "Lastra (Italy)",
  "Lastra a Signa (Italy)",
  "Laterina (Italy)",
  "Laterza (Italy)",
  "Latiano (Italy)",
  "Latina (Italy)",
  "Latisana (Italy)",
  "Latronico (Italy)",
  "Laureana di Borrello (Italy)",
  "Lauria (Italy)",
  "Lauro (Italy)",
  "Lavagna (Italy)",
  "Lavello (Italy)",
  "Lavena Ponte Tresa (Italy)",
  "Laveno-Mombello (Italy)",
  "Lavis (Italy)",
  "Lazise (Italy)",
  "Lazzaro (Italy)",
  "Le Castella (Italy)",
  "Lecce (Italy)",
  "Lecco (Italy)",
  "Leffe (Italy)",
  "Legnago (Italy)",
  "Legnano (Italy)",
  "Legnaro (Italy)",
  "Lendinara (Italy)",
  "Lenna (Italy)",
  "Lenno (Italy)",
  "Leno (Italy)",
  "Lenola (Italy)",
  "Lentate sul Seveso (Italy)",
  "Lentiai (Italy)",
  "Lentini (Italy)",
  "Leonessa (Italy)",
  "Leonforte (Italy)",
  "Leporano (Italy)",
  "Lercara Friddi (Italy)",
  "Lerici (Italy)",
  "Lerma (Italy)",
  "Lesa (Italy)",
  "Lesegno (Italy)",
  "Lesina (Italy)",
  "Lessona (Italy)",
  "Lestizza (Italy)",
  "Lettomanoppello (Italy)",
  "Lettopalena (Italy)",
  "Levane (Italy)",
  "Levanto (Italy)",
  "Levata (Italy)",
  "Leverano (Italy)",
  "Levico Terme (Italy)",
  "Lezzeno (Italy)",
  "Librizzi (Italy)",
  "Licata (Italy)",
  "Licciana Nardi (Italy)",
  "Lido (Italy)",
  "Lido di Camaiore (Italy)",
  "Lido di Ostia (Italy)",
  "Lierna (Italy)",
  "Lignano Sabbiadoro (Italy)",
  "Limana (Italy)",
  "Limatola (Italy)",
  "Limbiate (Italy)",
  "Limena (Italy)",
  "Limite (Italy)",
  "Limiti di Greccio (Italy)",
  "Limone Piemonte (Italy)",
  "Lingotto (Italy)",
  "Lioni (Italy)",
  "Lipari (Italy)",
  "Liscate (Italy)",
  "Lissone (Italy)",
  "Liveri (Italy)",
  "Livigno (Italy)",
  "Livinallongo del Col di Lana (Italy)",
  "Livorno (Italy)",
  "Livorno Ferraris (Italy)",
  "Lizzanello (Italy)",
  "Lizzano (Italy)",
  "Lizzola (Italy)",
  "Loano (Italy)",
  "Localita della Chiesa (Italy)",
  "Locate di Triulzi (Italy)",
  "Locati (Italy)",
  "Locorotondo (Italy)",
  "Locri (Italy)",
  "Lodi (Italy)",
  "Lodi Vecchio (Italy)",
  "Lograto (Italy)",
  "Loiano (Italy)",
  "Lomagna (Italy)",
  "Lomazzo (Italy)",
  "Lombardore (Italy)",
  "Lomello (Italy)",
  "Lonate Ceppino (Italy)",
  "Lonate Pozzolo (Italy)",
  "Lonato (Italy)",
  "Longare (Italy)",
  "Longarone (Italy)",
  "Longiano (Italy)",
  "Longobardi (Italy)",
  "Lonigo (Italy)",
  "Loreggia (Italy)",
  "Loreo (Italy)",
  "Loreto (Italy)",
  "Loreto Aprutino (Italy)",
  "Loria (Italy)",
  "Loro Ciuffenna (Italy)",
  "Loro Piceno (Italy)",
  "Lovere (Italy)",
  "Lozzo Atestino (Italy)",
  "Lozzo di Cadore (Italy)",
  "Luca (Italy)",
  "Lucca (Italy)",
  "Lucera (Italy)",
  "Luciana (Italy)",
  "Luciano (Italy)",
  "Lucino (Italy)",
  "Lucito (Italy)",
  "Luco dei Marsi (Italy)",
  "Lucugnano (Italy)",
  "Lugagnano (Italy)",
  "Lugnano (Italy)",
  "Lugnano in Teverina (Italy)",
  "Lugo (Italy)",
  "Luino (Italy)",
  "Lunamatrona (Italy)",
  "Lungavilla (Italy)",
  "Lungro (Italy)",
  "Lurago Marinone (Italy)",
  "Lurate Caccivio (Italy)",
  "Lustra (Italy)",
  "Lutago (Italy)",
  "Luzzano (Italy)",
  "Luzzi (Italy)",
  "Maccagno (Italy)",
  "Maccarese (Italy)",
  "Macello (Italy)",
  "Macerata (Italy)",
  "Macerata Campania (Italy)",
  "Macomer (Italy)",
  "Maddalena di Cazzano (Italy)",
  "Maddaloni (Italy)",
  "Maderno (Italy)",
  "Madignano (Italy)",
  "Madonna (Italy)",
  "Madonna di Campiglio (Italy)",
  "Maerne (Italy)",
  "Mafalda (Italy)",
  "Magenta (Italy)",
  "Maggio (Italy)",
  "Magione (Italy)",
  "Magliano (Italy)",
  "Magliano Alpi (Italy)",
  "Magliano di Tenna (Italy)",
  "Magliano in Toscana (Italy)",
  "Magliano Sabina (Italy)",
  "Maglie (Italy)",
  "Magnago (Italy)",
  "Magno (Italy)",
  "Magreta (Italy)",
  "Maierato (Italy)",
  "Maiolati Spontini (Italy)",
  "Mairano (Italy)",
  "Malalbergo (Italy)",
  "Malcesine (Italy)",
  "Malcontenta (Italy)",
  "Maletto (Italy)",
  "Malgrate (Italy)",
  "Malnate (Italy)",
  "Malo (Italy)",
  "Malonno (Italy)",
  "Mals (Italy)",
  "Maltignano (Italy)",
  "Mammola (Italy)",
  "Mandas (Italy)",
  "Mandello del Lario (Italy)",
  "Manduria (Italy)",
  "Manerbio (Italy)",
  "Manfredonia (Italy)",
  "Maniago (Italy)",
  "Manocalzati (Italy)",
  "Manoppello (Italy)",
  "Manta (Italy)",
  "Mantova (Italy)",
  "Mantovana (Italy)",
  "Manzano (Italy)",
  "Manziana (Italy)",
  "Manzoni (Italy)",
  "Mapello (Italy)",
  "Maranello (Italy)",
  "Marano di Napoli (Italy)",
  "Marano Ticino (Italy)",
  "Marano Vicentino (Italy)",
  "Maratea (Italy)",
  "Marcallo con Casone (Italy)",
  "Marcaria (Italy)",
  "Marcellina (Italy)",
  "Marche (Italy)",
  "Marciana Marina (Italy)",
  "Marcianise (Italy)",
  "Marco (Italy)",
  "Marcon (Italy)",
  "Mareno di Piave (Italy)",
  "Mareo (Italy)",
  "Maresca (Italy)",
  "Margarita (Italy)",
  "Marghera (Italy)",
  "Margherita di Savoia (Italy)",
  "Mariae (Italy)",
  "Mariano (Italy)",
  "Mariano Comense (Italy)",
  "Mariglianella (Italy)",
  "Marigliano (Italy)",
  "Marignano (Italy)",
  "Marina (Italy)",
  "Marina di Andora (Italy)",
  "Marina di Campo (Italy)",
  "Marina di Carrara (Italy)",
  "Marina di Castagneto Carducci (Italy)",
  "Marina di Cerveteri (Italy)",
  "Marina di Ginosa (Italy)",
  "Marina di Gioiosa Ionica (Italy)",
  "Marina di Ragusa (Italy)",
  "Marineo (Italy)",
  "Marini (Italy)",
  "Marino (Italy)",
  "Marlia (Italy)",
  "Marmirolo (Italy)",
  "Marnate (Italy)",
  "Marola (Italy)",
  "Marone (Italy)",
  "Marostica (Italy)",
  "Marotta (Italy)",
  "Marrubiu (Italy)",
  "Marsala (Italy)",
  "Marsciano (Italy)",
  "Marsico Nuovo (Italy)",
  "Marta (Italy)",
  "Martano (Italy)",
  "Martellago (Italy)",
  "Martignacco (Italy)",
  "Martignano (Italy)",
  "Martina Franca (Italy)",
  "Martinengo (Italy)",
  "Martiniana Po (Italy)",
  "Martinsicuro (Italy)",
  "Martirano (Italy)",
  "Maruggio (Italy)",
  "Marzabotto (Italy)",
  "Marzano (Italy)",
  "Marzano di Nola (Italy)",
  "Mas (Italy)",
  "Masainas (Italy)",
  "Mascali (Italy)",
  "Mascalucia (Italy)",
  "Maser (Italy)",
  "Masera (Italy)",
  "Maserada sul Piave (Italy)",
  "Masi Torello (Italy)",
  "Maslianico (Italy)",
  "Mason (Italy)",
  "Massa (Italy)",
  "Massa e Cozzile (Italy)",
  "Massa Fiscaglia (Italy)",
  "Massa Lombarda (Italy)",
  "Massa Marittima (Italy)",
  "Massa Martana (Italy)",
  "Massafra (Italy)",
  "Massarosa (Italy)",
  "Masserano (Italy)",
  "Massignano (Italy)",
  "Masullas (Italy)",
  "Matelica (Italy)",
  "Matera (Italy)",
  "Mathi (Italy)",
  "Matino (Italy)",
  "Mauro (Italy)",
  "Mazara del Vallo (Italy)",
  "Mazzano Romano (Italy)",
  "Mazzarino (Italy)",
  "Mazzo di Valtellina (Italy)",
  "Meana Sardo (Italy)",
  "Meano (Italy)",
  "Meda (Italy)",
  "Mede (Italy)",
  "Medea (Italy)",
  "Medesano (Italy)",
  "Medicina (Italy)",
  "Medolago (Italy)",
  "Medole (Italy)",
  "Medolla (Italy)",
  "Meduna di Livenza (Italy)",
  "Mel (Italy)",
  "Melara (Italy)",
  "Meldola (Italy)",
  "Melegnano (Italy)",
  "Melendugno (Italy)",
  "Melfi (Italy)",
  "Melicucco (Italy)",
  "Melilli (Italy)",
  "Melissano (Italy)",
  "Melito di Porto Salvo (Italy)",
  "Melizzano (Italy)",
  "Melzo (Italy)",
  "Menaggio (Italy)",
  "Mendicino (Italy)",
  "Menfi (Italy)",
  "Mentana (Italy)",
  "Meolo (Italy)",
  "Meran (Italy)",
  "Merano (Italy)",
  "Merate (Italy)",
  "Mercatino Conca (Italy)",
  "Mercato (Italy)",
  "Mercato San Severino (Italy)",
  "Mercato Saraceno (Italy)",
  "Mercenasco (Italy)",
  "Mercogliano (Italy)",
  "Mereto di Tomba (Italy)",
  "Mergo (Italy)",
  "Mergozzo (Italy)",
  "Mesagne (Italy)",
  "Mesola (Italy)",
  "Mesoraca (Italy)",
  "Messina (Italy)",
  "Mestre (Italy)",
  "Mestrino (Italy)",
  "Meta (Italy)",
  "Metaponto (Italy)",
  "Mezzanego (Italy)",
  "Mezzano (Italy)",
  "Mezzocorona (Italy)",
  "Mezzolara (Italy)",
  "Mezzolombardo (Italy)",
  "Mezzomerico (Italy)",
  "Miglianico (Italy)",
  "Migliarino (Italy)",
  "Migliaro (Italy)",
  "Miglionico (Italy)",
  "Mignanego (Italy)",
  "Mignano Monte Lungo (Italy)",
  "Milan (Italy)",
  "Milanesi (Italy)",
  "Milano (Italy)",
  "Milano Marittima (Italy)",
  "Milazzo (Italy)",
  "Milena (Italy)",
  "Militello in Val di Catania (Italy)",
  "Militello Rosmarino (Italy)",
  "Millesimo (Italy)",
  "Milo (Italy)",
  "Mineo (Italy)",
  "Minerbe (Italy)",
  "Minerbio (Italy)",
  "Minervino di Lecce (Italy)",
  "Minervino Murge (Italy)",
  "Minori (Italy)",
  "Minturno (Italy)",
  "Mira (Italy)",
  "Mirabella (Italy)",
  "Mirabella Eclano (Italy)",
  "Mirabella Imbaccari (Italy)",
  "Mirabello (Italy)",
  "Mirabello Monferrato (Italy)",
  "Miradolo Terme (Italy)",
  "Mirandola (Italy)",
  "Mirano (Italy)",
  "Miravalle (Italy)",
  "Misano Adriatico (Italy)",
  "Misilmeri (Italy)",
  "Misinto (Italy)",
  "Missaglia (Italy)",
  "Misterbianco (Italy)",
  "Mistretta (Italy)",
  "Modena (Italy)",
  "Modica (Italy)",
  "Modigliana (Italy)",
  "Modugno (Italy)",
  "Moena (Italy)",
  "Moglia (Italy)",
  "Mogliano (Italy)",
  "Mogliano Veneto (Italy)",
  "Mogoro (Italy)",
  "Moiano (Italy)",
  "Moimacco (Italy)",
  "Moio della Civitella (Italy)",
  "Mojo Alcantara (Italy)",
  "Mola di Bari (Italy)",
  "Molazzana (Italy)",
  "Molfetta (Italy)",
  "Molinari (Italy)",
  "Molinella (Italy)",
  "Molino (Italy)",
  "Molise (Italy)",
  "Moliterno (Italy)",
  "Mombercelli (Italy)",
  "Momo (Italy)",
  "Monaco (Italy)",
  "Monasterace (Italy)",
  "Monastero (Italy)",
  "Monasterolo del Castello (Italy)",
  "Monastir (Italy)",
  "Moncalieri (Italy)",
  "Moncalvo (Italy)",
  "Mondavio (Italy)",
  "Mondolfo (Italy)",
  "Mondragone (Italy)",
  "Moneglia (Italy)",
  "Monfalcone (Italy)",
  "Monforte San Giorgio (Italy)",
  "Monghidoro (Italy)",
  "Mongrando (Italy)",
  "Moniga del Garda (Italy)",
  "Monopoli (Italy)",
  "Monreale (Italy)",
  "Monsampietro Morico (Italy)",
  "Monsampolo del Tronto (Italy)",
  "Monsano (Italy)",
  "Monselice (Italy)",
  "Monserrato (Italy)",
  "Monsummano Terme (Italy)",
  "Montafia (Italy)",
  "Montagnana (Italy)",
  "Montalbano (Italy)",
  "Montalbano Jonico (Italy)",
  "Montalcino (Italy)",
  "Montaldo (Italy)",
  "Montale (Italy)",
  "Montalenghe (Italy)",
  "Montalto delle Marche (Italy)",
  "Montalto di Castro (Italy)",
  "Montalto Dora (Italy)",
  "Montalto Uffugo (Italy)",
  "Montanara (Italy)",
  "Montanaro (Italy)",
  "Montanaso Lombardo (Italy)",
  "Montappone (Italy)",
  "Monte (Italy)",
  "Monte Castello di Vibio (Italy)",
  "Monte Compatri (Italy)",
  "Monte di Malo (Italy)",
  "Monte di Procida (Italy)",
  "Monte Giberto (Italy)",
  "Monte Porzio Catone (Italy)",
  "Monte Roberto (Italy)",
  "Monte San Giovanni Campano (Italy)",
  "Monte San Giusto (Italy)",
  "Monte San Pietro (Italy)",
  "Monte San Savino (Italy)",
  "Monte San Vito (Italy)",
  "Monte Urano (Italy)",
  "Monte Vidon Corrado (Italy)",
  "Montebello (Italy)",
  "Montebello Vicentino (Italy)",
  "Montebelluna (Italy)",
  "Montecalvoli (Italy)",
  "Montecarlo (Italy)",
  "Montecarotto (Italy)",
  "Montecassiano (Italy)",
  "Montecastrilli (Italy)",
  "Montecatini Terme (Italy)",
  "Montecchia di Crosara (Italy)",
  "Montecchio (Italy)",
  "Montecchio Emilia (Italy)",
  "Montecchio Maggiore (Italy)",
  "Montechiarugolo (Italy)",
  "Montecorice (Italy)",
  "Montecorvino Rovella (Italy)",
  "Montecosaro (Italy)",
  "Montefalcione (Italy)",
  "Montefano (Italy)",
  "Montefiascone (Italy)",
  "Montefiore Conca (Italy)",
  "Montefiorino (Italy)",
  "Monteforte Irpino (Italy)",
  "Montefortino (Italy)",
  "Montefranco (Italy)",
  "Montefredane (Italy)",
  "Montegaldella (Italy)",
  "Montegiorgio (Italy)",
  "Montegranaro (Italy)",
  "Montegrotto Terme (Italy)",
  "Monteiasi (Italy)",
  "Montelabbate (Italy)",
  "Montelanico (Italy)",
  "Montelepre (Italy)",
  "Montelparo (Italy)",
  "Montelupo Fiorentino (Italy)",
  "Montemaggiore al Metauro (Italy)",
  "Montemarano (Italy)",
  "Montemarciano (Italy)",
  "Montemassi (Italy)",
  "Montemiletto (Italy)",
  "Montemurlo (Italy)",
  "Montemurro (Italy)",
  "Montenero di Bisaccia (Italy)",
  "Montenerodomo (Italy)",
  "Monteodorisio (Italy)",
  "Montepaone (Italy)",
  "Montepiano (Italy)",
  "Monteprandone (Italy)",
  "Montepulciano (Italy)",
  "Montepulciano Stazione (Italy)",
  "Monterchi (Italy)",
  "Montereale (Italy)",
  "Montereale Valcellina (Italy)",
  "Monterenzio (Italy)",
  "Monteriggioni (Italy)",
  "Monteroduni (Italy)",
  "Monteroni di Lecce (Italy)",
  "Monterosi (Italy)",
  "Monterosso al Mare (Italy)",
  "Monterosso Calabro (Italy)",
  "Monterotondo (Italy)",
  "Monterubbiano (Italy)",
  "Montesano Salentino (Italy)",
  "Montesano sulla Marcellana (Italy)",
  "Montesarchio (Italy)",
  "Montescaglioso (Italy)",
  "Montescudo (Italy)",
  "Montese (Italy)",
  "Montesilvano Colle (Italy)",
  "Montespertoli (Italy)",
  "Montevago (Italy)",
  "Montevarchi (Italy)",
  "Monteveglio (Italy)",
  "Monteverde (Italy)",
  "Monteviale (Italy)",
  "Monticelli (Italy)",
  "Monticello (Italy)",
  "Monticello Conte Otto (Italy)",
  "Montichiari (Italy)",
  "Monticiano (Italy)",
  "Montignoso (Italy)",
  "Montodine (Italy)",
  "Montoggio (Italy)",
  "Montorio al Vomano (Italy)",
  "Montoro (Italy)",
  "Montorso Vicentino (Italy)",
  "Monza (Italy)",
  "Monzambano (Italy)",
  "Monzuno (Italy)",
  "Mora (Italy)",
  "Morano Calabro (Italy)",
  "Morbegno (Italy)",
  "Morciano di Leuca (Italy)",
  "Morciano di Romagna (Italy)",
  "Morcone (Italy)",
  "Mordano (Italy)",
  "Moretta (Italy)",
  "Morgano (Italy)",
  "Morgex (Italy)",
  "Mori (Italy)",
  "Moricone (Italy)",
  "Morlupo (Italy)",
  "Mornago (Italy)",
  "Morolo (Italy)",
  "Morozzo (Italy)",
  "Morrone (Italy)",
  "Morrovalle (Italy)",
  "Mortara (Italy)",
  "Mortegliano (Italy)",
  "Morter (Italy)",
  "Moscufo (Italy)",
  "Moso (Italy)",
  "Mossa (Italy)",
  "Motta di Livenza (Italy)",
  "Motta San Giovanni (Italy)",
  "Motta San Guglielmo (Italy)",
  "Motta Visconti (Italy)",
  "Mottafollone (Italy)",
  "Mottalciata (Italy)",
  "Mottola (Italy)",
  "Mozzagrogna (Italy)",
  "Mozzanica (Italy)",
  "Mozzate (Italy)",
  "Mozzecane (Italy)",
  "Muggia (Italy)",
  "Mugnano (Italy)",
  "Mugnano del Cardinale (Italy)",
  "Mulazzano (Italy)",
  "Murano (Italy)",
  "Muravera (Italy)",
  "Murlo (Italy)",
  "Muro Leccese (Italy)",
  "Muro Lucano (Italy)",
  "Muros (Italy)",
  "Musei (Italy)",
  "Musile di Piave (Italy)",
  "Mussomeli (Italy)",
  "Nanto (Italy)",
  "Naples (Italy)",
  "Narbolia (Italy)",
  "Narcao (Italy)",
  "Narni (Italy)",
  "Narni Scalo (Italy)",
  "Naro (Italy)",
  "Naturns (Italy)",
  "Natz-Schabs (Italy)",
  "Navacchio (Italy)",
  "Nave (Italy)",
  "Nazzano (Italy)",
  "Negrar (Italy)",
  "Neirone (Italy)",
  "Neive (Italy)",
  "Nembro (Italy)",
  "Nepi (Italy)",
  "Nereto (Italy)",
  "Nervesa della Battaglia (Italy)",
  "Nerviano (Italy)",
  "Nettuno (Italy)",
  "Neumarkt (Italy)",
  "Neviano (Italy)",
  "Neviano degli Arduini (Italy)",
  "Nichelino (Italy)",
  "Nicola (Italy)",
  "Nicolosi (Italy)",
  "Nicosia (Italy)",
  "Nicotera (Italy)",
  "Niederdorf (Italy)",
  "Nimis (Italy)",
  "Niscemi (Italy)",
  "Nizza di Sicilia (Italy)",
  "Nizza Monferrato (Italy)",
  "Noale (Italy)",
  "Nocciano (Italy)",
  "Nocelleto (Italy)",
  "Nocera Inferiore (Italy)",
  "Nocera Superiore (Italy)",
  "Nocera Terinese (Italy)",
  "Noceto (Italy)",
  "Noci (Italy)",
  "Nociglia (Italy)",
  "Nogara (Italy)",
  "Nogaredo al Torre (Italy)",
  "Nogarole Rocca (Italy)",
  "Noicattaro (Italy)",
  "Nola (Italy)",
  "Noli (Italy)",
  "Nonantola (Italy)",
  "None (Italy)",
  "Norcia (Italy)",
  "Norma (Italy)",
  "Notaresco (Italy)",
  "Noto (Italy)",
  "Nova Milanese (Italy)",
  "Nova Siri (Italy)",
  "Novafeltria (Italy)",
  "Novalesa (Italy)",
  "Novara (Italy)",
  "Novate Mezzola (Italy)",
  "Novate Milanese (Italy)",
  "Nove (Italy)",
  "Novellara (Italy)",
  "Novello (Italy)",
  "Noventa di Piave (Italy)",
  "Noventa Vicentina (Italy)",
  "Novi di Modena (Italy)",
  "Novi Ligure (Italy)",
  "Novi Velia (Italy)",
  "Novoli (Italy)",
  "Numana (Italy)",
  "Nuoro (Italy)",
  "Nurachi (Italy)",
  "Nurallao (Italy)",
  "Nuraminis (Italy)",
  "Nurri (Italy)",
  "Nusco (Italy)",
  "Nuvolento (Italy)",
  "Occhieppo Inferiore (Italy)",
  "Occhieppo Superiore (Italy)",
  "Occhiobello (Italy)",
  "Ocre (Italy)",
  "Oderzo (Italy)",
  "Offagna (Italy)",
  "Offanengo (Italy)",
  "Offida (Italy)",
  "Offlaga (Italy)",
  "Oggiono (Italy)",
  "Oglianico (Italy)",
  "Olbia (Italy)",
  "Oleggio (Italy)",
  "Olevano Romano (Italy)",
  "Olevano sul Tusciano (Italy)",
  "Olgiate Comasco (Italy)",
  "Olgiate Molgora (Italy)",
  "Olgiate Olona (Italy)",
  "Olginate (Italy)",
  "Oliena (Italy)",
  "Oliva (Italy)",
  "Oliveri (Italy)",
  "Oliveto (Italy)",
  "Oliveto Citra (Italy)",
  "Oliveto Lario (Italy)",
  "Olivetta (Italy)",
  "Olmedo (Italy)",
  "Omegna (Italy)",
  "Omignano (Italy)",
  "Opera (Italy)",
  "Oppeano (Italy)",
  "Oppido Lucano (Italy)",
  "Orani (Italy)",
  "Oratino (Italy)",
  "Orbassano (Italy)",
  "Orbetello (Italy)",
  "Orciano di Pesaro (Italy)",
  "Orgiano (Italy)",
  "Orgosolo (Italy)",
  "Oria (Italy)",
  "Origgio (Italy)",
  "Orio al Serio (Italy)",
  "Orio Litta (Italy)",
  "Oriolo Romano (Italy)",
  "Oristano (Italy)",
  "Ormea (Italy)",
  "Ormelle (Italy)",
  "Ornago (Italy)",
  "Ornavasso (Italy)",
  "Orosei (Italy)",
  "Orotelli (Italy)",
  "Orsenigo (Italy)",
  "Orsogna (Italy)",
  "Orta Nova (Italy)",
  "Orta San Giulio (Italy)",
  "Orte (Italy)",
  "Ortelle (Italy)",
  "Ortona (Italy)",
  "Ortonovo (Italy)",
  "Ortovero (Italy)",
  "Orvieto (Italy)",
  "Orzinuovi (Italy)",
  "Orzivecchi (Italy)",
  "Osiglia (Italy)",
  "Osimo (Italy)",
  "Osnago (Italy)",
  "Osoppo (Italy)",
  "Ospedaletti (Italy)",
  "Ospedaletto (Italy)",
  "Ospedaletto Euganeo (Italy)",
  "Ospedaletto-Expopisa (Italy)",
  "Ospitaletto (Italy)",
  "Ossago Lodigiano (Italy)",
  "Ossi (Italy)",
  "Ossona (Italy)",
  "Ostiano (Italy)",
  "Ostiglia (Italy)",
  "Ostigliano (Italy)",
  "Ostra (Italy)",
  "Ostra Vetere (Italy)",
  "Ostuni (Italy)",
  "Otranto (Italy)",
  "Otricoli (Italy)",
  "Ottaviano (Italy)",
  "Ottiglio (Italy)",
  "Oulx (Italy)",
  "Ovada (Italy)",
  "Ovaro (Italy)",
  "Ozegna (Italy)",
  "Ozieri (Italy)",
  "Ozzano Monferrato (Italy)",
  "Ozzero (Italy)",
  "Pace (Italy)",
  "Paceco (Italy)",
  "Pacentro (Italy)",
  "Pachino (Italy)",
  "Paciano (Italy)",
  "Paderno (Italy)",
  "Paderno Dugnano (Italy)",
  "Paderno Franciacorta (Italy)",
  "Paderno Ponchielli (Italy)",
  "Padua (Italy)",
  "Padula (Italy)",
  "Paesana (Italy)",
  "Paese (Italy)",
  "Pagani (Italy)",
  "Paganica (Italy)",
  "Pagazzano (Italy)",
  "Paglieta (Italy)",
  "Pago Veiano (Italy)",
  "Palagianello (Italy)",
  "Palagiano (Italy)",
  "Palagonia (Italy)",
  "Palaia (Italy)",
  "Palau (Italy)",
  "Palazzina (Italy)",
  "Palazzo (Italy)",
  "Palazzo Canavese (Italy)",
  "Palazzolo (Italy)",
  "Palazzolo Acreide (Italy)",
  "Palazzolo dello Stella (Italy)",
  "Palermo (Italy)",
  "Palestrina (Italy)",
  "Paliano (Italy)",
  "Palizzi Marina (Italy)",
  "Palladio (Italy)",
  "Pallanza-Intra-Suna (Italy)",
  "Pallanzeno (Italy)",
  "Pallerone (Italy)",
  "Palma Campania (Italy)",
  "Palma di Montechiaro (Italy)",
  "Palmanova (Italy)",
  "Palmariggi (Italy)",
  "Palmas Arborea (Italy)",
  "Palmi (Italy)",
  "Palo del Colle (Italy)",
  "Palombara Sabina (Italy)",
  "Palosco (Italy)",
  "Paluzza (Italy)",
  "Panaro (Italy)",
  "Pancalieri (Italy)",
  "Pandino (Italy)",
  "Panicale (Italy)",
  "Pannarano (Italy)",
  "Pantano (Italy)",
  "Pantigliate (Italy)",
  "Panzano (Italy)",
  "Paola (Italy)",
  "Parabiago (Italy)",
  "Parabita (Italy)",
  "Paratico (Italy)",
  "Parghelia (Italy)",
  "Parigi (Italy)",
  "Parma (Italy)",
  "Parre (Italy)",
  "Partanna (Italy)",
  "Partinico (Italy)",
  "Paruzzaro (Italy)",
  "Pasian di Prato (Italy)",
  "Passarella (Italy)",
  "Passetto (Italy)",
  "Passignano sul Trasimeno (Italy)",
  "Passirano (Italy)",
  "Passo (Italy)",
  "Passo Corese (Italy)",
  "Passo di Treia (Italy)",
  "Pastene (Italy)",
  "Pastrengo (Italy)",
  "Pasturo (Italy)",
  "Paterno (Italy)",
  "Paternopoli (Italy)",
  "Patrica (Italy)",
  "Patti (Italy)",
  "Pauli Arbarei (Italy)",
  "Paulilatino (Italy)",
  "Paullo (Italy)",
  "Pavia (Italy)",
  "Pavia di Udine (Italy)",
  "Pavona (Italy)",
  "Pavone Canavese (Italy)",
  "Pavullo nel Frignano (Italy)",
  "Peccioli (Italy)",
  "Pecetto (Italy)",
  "Pedara (Italy)",
  "Pedaso (Italy)",
  "Pedavena (Italy)",
  "Pedemonte (Italy)",
  "Pederobba (Italy)",
  "Peglio (Italy)",
  "Pegognaga (Italy)",
  "Pelago (Italy)",
  "Pellegrini (Italy)",
  "Pellegrino (Italy)",
  "Pellegrino Parmense (Italy)",
  "Pellestrina (Italy)",
  "Pellezzano (Italy)",
  "Pennabilli (Italy)",
  "Penne (Italy)",
  "Perdasdefogu (Italy)",
  "Perdaxius (Italy)",
  "Perdifumo (Italy)",
  "Perfugas (Italy)",
  "Pergine Valsugana (Italy)",
  "Pergola (Italy)",
  "Perignano (Italy)",
  "Perinaldo (Italy)",
  "Pernumia (Italy)",
  "Pero (Italy)",
  "Perosa Argentina (Italy)",
  "Pertengo (Italy)",
  "Perteole (Italy)",
  "Pertosa (Italy)",
  "Perugia (Italy)",
  "Pesaro (Italy)",
  "Pescantina (Italy)",
  "Pescara (Italy)",
  "Pescarolo ed Uniti (Italy)",
  "Peschici (Italy)",
  "Peschiera Borromeo (Italy)",
  "Peschiera del Garda (Italy)",
  "Pescia (Italy)",
  "Pescina (Italy)",
  "Pescocostanzo (Italy)",
  "Pessinetto (Italy)",
  "Pessione (Italy)",
  "Petacciato (Italy)",
  "Petilia Policastro (Italy)",
  "Petriano (Italy)",
  "Petritoli (Italy)",
  "Petrosino (Italy)",
  "Petruro (Italy)",
  "Pettinengo (Italy)",
  "Pettoranello del Molise (Italy)",
  "Pettorazza Grimani (Italy)",
  "Peveragno (Italy)",
  "Pezzana (Italy)",
  "Pezze di Greco (Italy)",
  "Piacenza (Italy)",
  "Piadena (Italy)",
  "Piaggine (Italy)",
  "Pian Camuno (Italy)",
  "Pian-Fossal (Italy)",
  "Piana (Italy)",
  "Piana degli Albanesi (Italy)",
  "Piancastagnaio (Italy)",
  "Piancogno (Italy)",
  "Piandimeleto (Italy)",
  "Piane Crati (Italy)",
  "Pianella (Italy)",
  "Pianello Val Tidone (Italy)",
  "Pianezza (Italy)",
  "Pianezze (Italy)",
  "Piangipane (Italy)",
  "Pianiga (Italy)",
  "Piano (Italy)",
  "Piano di Coreglia-Ghivizzano (Italy)",
  "Piano di Sorrento (Italy)",
  "Piano-Vetrale (Italy)",
  "Pianoro (Italy)",
  "Piasco (Italy)",
  "Piateda (Italy)",
  "Piavon (Italy)",
  "Piazza (Italy)",
  "Piazza al Serchio (Italy)",
  "Piazza Armerina (Italy)",
  "Piazza Brembana (Italy)",
  "Piazza Roma (Italy)",
  "Piazzola sul Brenta (Italy)",
  "Picerno (Italy)",
  "Pico (Italy)",
  "Piedimonte Etneo (Italy)",
  "Piedimonte Matese (Italy)",
  "Piedimonte San Germano (Italy)",
  "Piegaro (Italy)",
  "Piemonte (Italy)",
  "Pieris (Italy)",
  "Pietra (Italy)",
  "Pietra Ligure (Italy)",
  "Pietrabbondante (Italy)",
  "Pietracuta (Italy)",
  "Pietrapaola (Italy)",
  "Pietraperzia (Italy)",
  "Pietrasanta (Italy)",
  "Pieve (Italy)",
  "Pieve Albignola (Italy)",
  "Pieve del Cairo (Italy)",
  "Pieve di Cadore (Italy)",
  "Pieve di Cento (Italy)",
  "Pieve di Soligo (Italy)",
  "Pieve Fosciana (Italy)",
  "Pieve Ligure (Italy)",
  "Pieve San Giacomo (Italy)",
  "Pieve Vecchia (Italy)",
  "Pievebovigliana (Italy)",
  "Pievepelago (Italy)",
  "Piglio (Italy)",
  "Pignataro Interamna (Italy)",
  "Pignataro Maggiore (Italy)",
  "Pignola (Italy)",
  "Pignone (Italy)",
  "Pilcante (Italy)",
  "Pimonte (Italy)",
  "Pinarella (Italy)",
  "Pinerolo (Italy)",
  "Pineta (Italy)",
  "Pineto (Italy)",
  "Pinzano al Tagliamento (Italy)",
  "Pinzolo (Italy)",
  "Piobbico (Italy)",
  "Piobesi Torinese (Italy)",
  "Pioltello (Italy)",
  "Piombino (Italy)",
  "Piombino Dese (Italy)",
  "Pioraco (Italy)",
  "Piossasco (Italy)",
  "Piove di Sacco (Italy)",
  "Piovene Rocchette (Italy)",
  "Piraino (Italy)",
  "Pirri (Italy)",
  "Pisa (Italy)",
  "Pisciotta (Italy)",
  "Pisogne (Italy)",
  "Pisticci (Italy)",
  "Pistoia (Italy)",
  "Pistrino (Italy)",
  "Pitigliano (Italy)",
  "Piumazzo (Italy)",
  "Pizzighettone (Italy)",
  "Pizzo (Italy)",
  "Plan da Tieja (Italy)",
  "Ploaghe (Italy)",
  "Pocenia (Italy)",
  "Podenzana (Italy)",
  "Podenzano (Italy)",
  "Pofi (Italy)",
  "Poggiardo (Italy)",
  "Poggibonsi (Italy)",
  "Poggio (Italy)",
  "Poggio a Caiano (Italy)",
  "Poggio Berni (Italy)",
  "Poggio di Roio (Italy)",
  "Poggio Mirteto (Italy)",
  "Poggio Moiano (Italy)",
  "Poggio Nativo (Italy)",
  "Poggio Renatico (Italy)",
  "Poggio Rusco (Italy)",
  "Poggio San Lorenzo (Italy)",
  "Poggiomarino (Italy)",
  "Pogliano (Italy)",
  "Pogliano Milanese (Italy)",
  "Pogno (Italy)",
  "Poiana Maggiore (Italy)",
  "Poirino (Italy)",
  "Polesella (Italy)",
  "Polesine Parmense (Italy)",
  "Poli (Italy)",
  "Policoro (Italy)",
  "Polignano a Mare (Italy)",
  "Polinago (Italy)",
  "Polistena (Italy)",
  "Polla (Italy)",
  "Pollein (Italy)",
  "Pollenza (Italy)",
  "Pollica (Italy)",
  "Pollina (Italy)",
  "Polverara (Italy)",
  "Polverigi (Italy)",
  "Pomarance (Italy)",
  "Pombia (Italy)",
  "Pomezia (Italy)",
  "Pompei (Italy)",
  "Pompiano (Italy)",
  "Pomponesco (Italy)",
  "Poncarale (Italy)",
  "Ponsacco (Italy)",
  "Ponso (Italy)",
  "Pont (Italy)",
  "Pont-Canavese (Italy)",
  "Pont-Saint-Martin (Italy)",
  "Pontassieve (Italy)",
  "Ponte (Italy)",
  "Ponte a Evola (Italy)",
  "Ponte a Moriano (Italy)",
  "Ponte Basso (Italy)",
  "Ponte Buggianese (Italy)",
  "Ponte di Barbarano (Italy)",
  "Ponte di Piave (Italy)",
  "Ponte Galeria-La Pisana (Italy)",
  "Ponte Lambro (Italy)",
  "Ponte nelle Alpi (Italy)",
  "Ponte Nossa (Italy)",
  "Ponte San Giovanni (Italy)",
  "Ponte San Nicolo (Italy)",
  "Ponte San Pietro (Italy)",
  "Pontecagnano (Italy)",
  "Pontecchio (Italy)",
  "Pontecchio Polesine (Italy)",
  "Pontecorvo (Italy)",
  "Pontecurone (Italy)",
  "Pontedassio (Italy)",
  "Pontedera (Italy)",
  "Pontelongo (Italy)",
  "Pontenure (Italy)",
  "Pontestura (Italy)",
  "Pontevico (Italy)",
  "Ponti (Italy)",
  "Ponticelli (Italy)",
  "Pontida (Italy)",
  "Pontinia (Italy)",
  "Pontremoli (Italy)",
  "Ponza (Italy)",
  "Ponzano (Italy)",
  "Ponzano Veneto (Italy)",
  "Ponzone (Italy)",
  "Popoli (Italy)",
  "Poppi (Italy)",
  "Porano (Italy)",
  "Porcari (Italy)",
  "Porcia (Italy)",
  "Pordenone (Italy)",
  "Porlezza (Italy)",
  "Porpetto (Italy)",
  "Porretta Terme (Italy)",
  "Porta (Italy)",
  "Portici (Italy)",
  "Portico di Caserta (Italy)",
  "Porto (Italy)",
  "Porto Azzurro (Italy)",
  "Porto Empedocle (Italy)",
  "Porto Ercole (Italy)",
  "Porto Garibaldi (Italy)",
  "Porto Potenza Picena (Italy)",
  "Porto Recanati (Italy)",
  "Porto San Giorgio (Italy)",
  "Porto Tolle (Italy)",
  "Porto Torres (Italy)",
  "Porto Viro (Italy)",
  "Portoferraio (Italy)",
  "Portofino (Italy)",
  "Portogruaro (Italy)",
  "Portomaggiore (Italy)",
  "Portoscuso (Italy)",
  "Portovenere (Italy)",
  "Positano (Italy)",
  "Posta Fibreno (Italy)",
  "Posta Vecchia (Italy)",
  "Postiglione (Italy)",
  "Postioma (Italy)",
  "Potenza (Italy)",
  "Potenza Picena (Italy)",
  "Povegliano (Italy)",
  "Povegliano Veronese (Italy)",
  "Poviglio (Italy)",
  "Pozza di Fassa (Italy)",
  "Pozza-San Marco (Italy)",
  "Pozzallo (Italy)",
  "Pozzilli (Italy)",
  "Pozzo (Italy)",
  "Pozzo di Gotto (Italy)",
  "Pozzolengo (Italy)",
  "Pozzoleone (Italy)",
  "Pozzolo (Italy)",
  "Pozzolo Formigaro (Italy)",
  "Pozzonovo (Italy)",
  "Pozzuoli (Italy)",
  "Pozzuolo del Friuli (Italy)",
  "Prad am Stilfser Joch (Italy)",
  "Pradamano (Italy)",
  "Praia a Mare (Italy)",
  "Praiano (Italy)",
  "Pralormo (Italy)",
  "Pralungo (Italy)",
  "Pramaggiore (Italy)",
  "Prarolo (Italy)",
  "Prata (Italy)",
  "Prata Camportaccio (Italy)",
  "Prata di Pordenone (Italy)",
  "Prata Sannita (Italy)",
  "Prato (Italy)",
  "Prato Sesia (Italy)",
  "Pratola Peligna (Italy)",
  "Pratola Serra (Italy)",
  "Pratovecchio (Italy)",
  "Pravisdomini (Italy)",
  "Pray (Italy)",
  "Predazzo (Italy)",
  "Predore (Italy)",
  "Predosa (Italy)",
  "Preganziol (Italy)",
  "Premana (Italy)",
  "Premariacco (Italy)",
  "Premosello-Chiovenda (Italy)",
  "Presicce (Italy)",
  "Pressana (Italy)",
  "Pretoro (Italy)",
  "Prevalle (Italy)",
  "Prezza (Italy)",
  "Priolo Gargallo (Italy)",
  "Priverno (Italy)",
  "Prizzi (Italy)",
  "Procida (Italy)",
  "Provesano (Italy)",
  "Province of Messina (Italy)",
  "Puglianello (Italy)",
  "Pugliano (Italy)",
  "Puianello (Italy)",
  "Pula (Italy)",
  "Pulsano (Italy)",
  "Punta Marina (Italy)",
  "Putignano (Italy)",
  "Quadri (Italy)",
  "Quagliuzzo (Italy)",
  "Qualiano (Italy)",
  "Qualso (Italy)",
  "Quaregna (Italy)",
  "Quargnento (Italy)",
  "Quarona (Italy)",
  "Quarrata (Italy)",
  "Quart (Italy)",
  "Quarto (Italy)",
  "Quarto Inferiore (Italy)",
  "Quartucciu (Italy)",
  "Quattordio (Italy)",
  "Quattro Castella (Italy)",
  "Quero (Italy)",
  "Quiliano (Italy)",
  "Quindici (Italy)",
  "Quinto di Treviso (Italy)",
  "Quinto Vercellese (Italy)",
  "Quinto Vicentino (Italy)",
  "Quistello (Italy)",
  "Racale (Italy)",
  "Racalmuto (Italy)",
  "Racconigi (Italy)",
  "Radda in Chianti (Italy)",
  "Raffadali (Italy)",
  "Ragogna (Italy)",
  "Ragusa (Italy)",
  "Raiano (Italy)",
  "Raldon (Italy)",
  "Ramacca (Italy)",
  "Ramiseto (Italy)",
  "Ramon (Italy)",
  "Ramponio (Italy)",
  "Rancio Valcuvia (Italy)",
  "Randazzo (Italy)",
  "Ranieri (Italy)",
  "Rapagnano (Italy)",
  "Rapallo (Italy)",
  "Rapolla (Italy)",
  "Rassina (Italy)",
  "Ratschings (Italy)",
  "Ravanusa (Italy)",
  "Ravarino (Italy)",
  "Ravello (Italy)",
  "Ravenna (Italy)",
  "Ravi (Italy)",
  "Recale (Italy)",
  "Recanati (Italy)",
  "Recco (Italy)",
  "Recoaro Terme (Italy)",
  "Regalbuto (Italy)",
  "Reggello (Italy)",
  "Reggio Calabria (Italy)",
  "Reggiolo (Italy)",
  "Regina (Italy)",
  "Regina Margherita (Italy)",
  "Remanzacco (Italy)",
  "Renazzo (Italy)",
  "Rende (Italy)",
  "Reno (Italy)",
  "Resana (Italy)",
  "Rescaldina (Italy)",
  "Resia (Italy)",
  "Revello (Italy)",
  "Revere (Italy)",
  "Revigliasco (Italy)",
  "Revine (Italy)",
  "Rezzato (Italy)",
  "Rho (Italy)",
  "Riale (Italy)",
  "Riano (Italy)",
  "Ribera (Italy)",
  "Ribolla (Italy)",
  "Ricadi (Italy)",
  "Riccia (Italy)",
  "Riccione (Italy)",
  "Riccovolto (Italy)",
  "Riese Pio X (Italy)",
  "Riesi (Italy)",
  "Rieti (Italy)",
  "Rignano Flaminio (Italy)",
  "Rignano Garganico (Italy)",
  "Rimini (Italy)",
  "Rino (Italy)",
  "Rio Marina (Italy)",
  "Rio Saliceto (Italy)",
  "Riola Sardo (Italy)",
  "Riolo Terme (Italy)",
  "Riomaggiore (Italy)",
  "Rionero in Vulture (Italy)",
  "Ripa (Italy)",
  "Ripa Teatina (Italy)",
  "Ripa-Pozzi-Querceta-Ponterosso (Italy)",
  "Ripafratta-Farneta (Italy)",
  "Ripalta Cremasca (Italy)",
  "Riparbella (Italy)",
  "Ripatransone (Italy)",
  "Ripe (Italy)",
  "Ripe San Ginesio (Italy)",
  "Ripi (Italy)",
  "Ripoli (Italy)",
  "Riposto (Italy)",
  "Riva (Italy)",
  "Riva del Garda (Italy)",
  "Riva Ligure (Italy)",
  "Riva presso Chieri (Italy)",
  "Rivalba (Italy)",
  "Rivalta (Italy)",
  "Rivalta di Torino (Italy)",
  "Rivanazzano (Italy)",
  "Rivara (Italy)",
  "Rivarolo del Re ed Uniti (Italy)",
  "Rivarolo Ligure (Italy)",
  "Rivarolo Mantovano (Italy)",
  "Rivello (Italy)",
  "Rivergaro (Italy)",
  "Riviera (Italy)",
  "Rivignano (Italy)",
  "Rivodutri (Italy)",
  "Rivoli (Italy)",
  "Rizzi (Italy)",
  "Rizziconi (Italy)",
  "Roasio (Italy)",
  "Robassomero (Italy)",
  "Robbiate (Italy)",
  "Robbio (Italy)",
  "Robecchetto con Induno (Italy)",
  "Robecco sul Naviglio (Italy)",
  "Rocca (Italy)",
  "Rocca Canavese (Italy)",
  "Rocca di Papa (Italy)",
  "Rocca Massima (Italy)",
  "Rocca Priora (Italy)",
  "Rocca San Casciano (Italy)",
  "Roccabernarda (Italy)",
  "Roccabianca (Italy)",
  "Roccadaspide (Italy)",
  "Roccafranca (Italy)",
  "Roccalumera (Italy)",
  "Roccamonfina (Italy)",
  "Roccapiemonte (Italy)",
  "Roccarainola (Italy)",
  "Roccaromana (Italy)",
  "Roccasecca (Italy)",
  "Roccastrada (Italy)",
  "Roccavione (Italy)",
  "Roccavivara (Italy)",
  "Roccavivi (Italy)",
  "Roccella (Italy)",
  "Roccella Ionica (Italy)",
  "Rocchetta di Vara (Italy)",
  "Rocco (Italy)",
  "Rodano (Italy)",
  "Rodeneck-Rodengo (Italy)",
  "Rodi Garganico (Italy)",
  "Rodigo (Italy)",
  "Roggiano Gravina (Italy)",
  "Rogliano (Italy)",
  "Rognano (Italy)",
  "Roiano (Italy)",
  "Rolo (Italy)",
  "Romagnano Sesia (Italy)",
  "Romana (Italy)",
  "Romanengo (Italy)",
  "Romano Canavese (Italy)",
  "Romano di Lombardia (Italy)",
  "Rome (Italy)",
  "Romentino (Italy)",
  "Rometta (Italy)",
  "Roncade (Italy)",
  "Roncadelle (Italy)",
  "Roncalceci (Italy)",
  "Roncegno (Italy)",
  "Ronchi (Italy)",
  "Ronchi dei Legionari (Italy)",
  "Ronchis (Italy)",
  "Ronciglione (Italy)",
  "Ronco (Italy)",
  "Ronco Scrivia (Italy)",
  "Ronco-Chiesa (Italy)",
  "Roncoferraro (Italy)",
  "Roncone (Italy)",
  "Rondissone (Italy)",
  "Rosa (Italy)",
  "Rosate (Italy)",
  "Rosciano (Italy)",
  "Rose (Italy)",
  "Roseto Capo Spulico (Italy)",
  "Roseto degli Abruzzi (Italy)",
  "Roseto Valfortore (Italy)",
  "Rosignano Marittimo (Italy)",
  "Rosignano Solvay-Castiglioncello (Italy)",
  "Rosolina (Italy)",
  "Rosolini (Italy)",
  "Rossano (Italy)",
  "Rossano Veneto (Italy)",
  "Rossi (Italy)",
  "Rossiglione (Italy)",
  "Rota Greca (Italy)",
  "Rotella (Italy)",
  "Rotello (Italy)",
  "Rotondi (Italy)",
  "Rottofreno (Italy)",
  "Rovagnate (Italy)",
  "Rovato (Italy)",
  "Rovellasca (Italy)",
  "Rovello Porro (Italy)",
  "Roverbella (Italy)",
  "Rovere (Italy)",
  "Roveredo (Italy)",
  "Roveredo in Piano (Italy)",
  "Rovereto (Italy)",
  "Rovetta (Italy)",
  "Roviano (Italy)",
  "Rovigo (Italy)",
  "Rovito (Italy)",
  "Rozzano (Italy)",
  "Rubano (Italy)",
  "Rubbiano (Italy)",
  "Rubiana (Italy)",
  "Rubiera (Italy)",
  "Ruda (Italy)",
  "Rudiano (Italy)",
  "Ruffano (Italy)",
  "Rufina (Italy)",
  "Ruoti (Italy)",
  "Russi (Italy)",
  "Russo (Italy)",
  "Rutigliano (Italy)",
  "Ruviano (Italy)",
  "Ruvo di Puglia (Italy)",
  "Sabatino (Italy)",
  "Sabaudia (Italy)",
  "Sabbio Chiese (Italy)",
  "Sabbioneta (Italy)",
  "Sacco (Italy)",
  "Saccolongo (Italy)",
  "Sacile (Italy)",
  "Sacrofano (Italy)",
  "Sagrado (Italy)",
  "Saint-Pierre (Italy)",
  "Saint-Vincent (Italy)",
  "Sala Baganza (Italy)",
  "Sala Bolognese (Italy)",
  "Sala Consilina (Italy)",
  "Salara (Italy)",
  "Salassa (Italy)",
  "Sale (Italy)",
  "Sale Marasino (Italy)",
  "Salemi (Italy)",
  "Salento (Italy)",
  "Salerno (Italy)",
  "Saletto (Italy)",
  "Salice Salentino (Italy)",
  "Saliceto (Italy)",
  "Saline (Italy)",
  "Salmour (Italy)",
  "Salorno (Italy)",
  "Salsomaggiore Terme (Italy)",
  "Saltocchio (Italy)",
  "Saludecio (Italy)",
  "Saluggia (Italy)",
  "Saluzzo (Italy)",
  "Salvaterra (Italy)",
  "Salve (Italy)",
  "Salvirola (Italy)",
  "Salzano (Italy)",
  "Samarate (Italy)",
  "Samassi (Italy)",
  "Sambruson (Italy)",
  "Sambuceto (Italy)",
  "Sammichele di Bari (Italy)",
  "Samone (Italy)",
  "San Bartolomeo (Italy)",
  "San Bassano (Italy)",
  "San Benedetto dei Marsi (Italy)",
  "San Benedetto del Tronto (Italy)",
  "San Benedetto Po (Italy)",
  "San Benedetto Val di Sambro (Italy)",
  "San Benigno Canavese (Italy)",
  "San Bernardino (Italy)",
  "San Biagio (Italy)",
  "San Biagio di Callalta (Italy)",
  "San Bonifacio (Italy)",
  "San Carlo (Italy)",
  "San Casciano dei Bagni (Italy)",
  "San Casciano in Val di Pesa (Italy)",
  "San Cassiano (Italy)",
  "San Cataldo (Italy)",
  "San Cesareo (Italy)",
  "San Cesario di Lecce (Italy)",
  "San Cesario sul Panaro (Italy)",
  "San Chirico Raparo (Italy)",
  "San Cipirello (Italy)",
  "San Cipriano Picentino (Italy)",
  "San Colombano al Lambro (Italy)",
  "San Costanzo (Italy)",
  "San Daniele del Friuli (Italy)",
  "San Demetrio Corone (Italy)",
  "San Dona (Italy)",
  "San Donaci (Italy)",
  "San Donato (Italy)",
  "San Donato di Lecce (Italy)",
  "San Donato Milanese (Italy)",
  "San Dorligo della Valle (Italy)",
  "San Fedele (Italy)",
  "San Felice Circeo (Italy)",
  "San Felice sul Panaro (Italy)",
  "San Ferdinando (Italy)",
  "San Ferdinando di Puglia (Italy)",
  "San Fili (Italy)",
  "San Filippo (Italy)",
  "San Fior (Italy)",
  "San Fiorano (Italy)",
  "San Francesco (Italy)",
  "San Francesco al Campo (Italy)",
  "San Fratello (Italy)",
  "San Gavino Monreale (Italy)",
  "San Gemini (Italy)",
  "San Genesio ed Uniti (Italy)",
  "San Gennaro Vesuviano (Italy)",
  "San Germano Vercellese (Italy)",
  "San Giacomo (Italy)",
  "San Giacomo degli Schiavoni (Italy)",
  "San Gillio (Italy)",
  "San Gimignano (Italy)",
  "San Ginesio (Italy)",
  "San Giorgio (Italy)",
  "San Giorgio a Cremano (Italy)",
  "San Giorgio a Liri (Italy)",
  "San Giorgio Canavese (Italy)",
  "San Giorgio del Sannio (Italy)",
  "San Giorgio della Richinvelda (Italy)",
  "San Giorgio di Lomellina (Italy)",
  "San Giorgio di Nogaro (Italy)",
  "San Giorgio di Piano (Italy)",
  "San Giorgio in Bosco (Italy)",
  "San Giorgio Ionico (Italy)",
  "San Giorgio la Molara (Italy)",
  "San Giorgio Piacentino (Italy)",
  "San Giovanni (Italy)",
  "San Giovanni a Piro (Italy)",
  "San Giovanni al Natisone (Italy)",
  "San Giovanni Bianco (Italy)",
  "San Giovanni Gemini (Italy)",
  "San Giovanni in Croce (Italy)",
  "San Giovanni in Fiore (Italy)",
  "San Giovanni in Marignano (Italy)",
  "San Giovanni in Persiceto (Italy)",
  "San Giovanni la Punta (Italy)",
  "San Giovanni Lupatoto (Italy)",
  "San Giovanni Rotondo (Italy)",
  "San Giovanni Suergiu (Italy)",
  "San Giovanni Valdarno (Italy)",
  "San Giuliano Milanese (Italy)",
  "San Giuliano Terme (Italy)",
  "San Giuseppe (Italy)",
  "San Giuseppe Jato (Italy)",
  "San Giuseppe Vesuviano (Italy)",
  "San Giustino (Italy)",
  "San Giusto Canavese (Italy)",
  "San Gregorio (Italy)",
  "San Gregorio da Sassola (Italy)",
  "San Gregorio di Catania (Italy)",
  "San Lazzaro degli Armeni (Italy)",
  "San Lazzaro di Savena (Italy)",
  "San Leo (Italy)",
  "San Lorenzello (Italy)",
  "San Lorenzo (Italy)",
  "San Lorenzo al Mare (Italy)",
  "San Lorenzo del Vallo (Italy)",
  "San Lorenzo in Campo (Italy)",
  "San Lorenzo Isontino (Italy)",
  "San Luca (Italy)",
  "San Lucido (Italy)",
  "San Mango (Italy)",
  "San Mango Piemonte (Italy)",
  "San Marcello (Italy)",
  "San Marcello Pistoiese (Italy)",
  "San Marco (Italy)",
  "San Marco Argentano (Italy)",
  "San Marco dei Cavoti (Italy)",
  "San Marco Evangelista (Italy)",
  "San Marco in Lamis (Italy)",
  "San Mariano (Italy)",
  "San Martino (Italy)",
  "San Martino al Tagliamento (Italy)",
  "San Martino Buon Albergo (Italy)",
  "San Martino Canavese (Italy)",
  "San Martino di Lupari (Italy)",
  "San Martino in Pensilis (Italy)",
  "San Martino in Rio (Italy)",
  "San Martino in Strada (Italy)",
  "San Martino Sannita (Italy)",
  "San Martino Siccomario (Italy)",
  "San Marzano di San Giuseppe (Italy)",
  "San Marzano sul Sarno (Italy)",
  "San Maurizio (Italy)",
  "San Maurizio Canavese (Italy)",
  "San Mauro (Italy)",
  "San Mauro Pascoli (Italy)",
  "San Mauro Torinese (Italy)",
  "San Michele al Tagliamento (Italy)",
  "San Michele Prazzo (Italy)",
  "San Michele Salentino (Italy)",
  "San Miniato (Italy)",
  "San Miniato Basso (Italy)",
  "San Nazario (Italy)",
  "San Niccolo (Italy)",
  "San Nicola (Italy)",
  "San Nicola Arcella (Italy)",
  "San Nicolo (Italy)",
  "San Pancrazio Salentino (Italy)",
  "San Paolo (Italy)",
  "San Pellegrino Terme (Italy)",
  "San Piero a Sieve (Italy)",
  "San Piero in Bagno (Italy)",
  "San Piero Patti (Italy)",
  "San Pietro (Italy)",
  "San Pietro a Maida (Italy)",
  "San Pietro di Cadore (Italy)",
  "San Pietro di Feletto (Italy)",
  "San Pietro in Casale (Italy)",
  "San Pietro in Gu (Italy)",
  "San Pietro in Lama (Italy)",
  "San Pietro Mosezzo (Italy)",
  "San Pietro Vernotico (Italy)",
  "San Pietro Viminario (Italy)",
  "San Polo (Italy)",
  "San Polo di Piave (Italy)",
  "San Prisco (Italy)",
  "San Prospero (Italy)",
  "San Quirino (Italy)",
  "San Raineri (Italy)",
  "San Rocco (Italy)",
  "San Rufo (Italy)",
  "San Salvatore (Italy)",
  "San Salvatore Monferrato (Italy)",
  "San Salvatore Telesino (Italy)",
  "San Salvo (Italy)",
  "San Sebastiano al Vesuvio (Italy)",
  "San Secondo (Italy)",
  "San Secondo Parmense (Italy)",
  "San Severino (Italy)",
  "San Severino Marche (Italy)",
  "San Severo (Italy)",
  "San Sisto (Italy)",
  "San Sossio Baronia (Italy)",
  "San Sosti (Italy)",
  "San Sperate (Italy)",
  "San Tammaro (Italy)",
  "San Teodoro (Italy)",
  "San Valentino Torio (Italy)",
  "San Venanzio (Italy)",
  "San Venanzo (Italy)",
  "San Vendemiano (Italy)",
  "San Vero Milis (Italy)",
  "San Vincenzo (Italy)",
  "San Vito (Italy)",
  "San Vito al Tagliamento (Italy)",
  "San Vito Chietino (Italy)",
  "San Vito dei Normanni (Italy)",
  "San Vito di Cadore (Italy)",
  "San Vito Lo Capo (Italy)",
  "San Vito Romano (Italy)",
  "San Vittore (Italy)",
  "San Zaccaria (Italy)",
  "San Zeno (Italy)",
  "San Zenone al Lambro (Italy)",
  "San Zenone al Po (Italy)",
  "San Zenone degli Ezzelini (Italy)",
  "Sand in Taufers (Italy)",
  "Sandigliano (Italy)",
  "Sandon (Italy)",
  "Sandrigo (Italy)",
  "Sangano (Italy)",
  "Sanguinetto (Italy)",
  "Sanluri (Italy)",
  "Sannicandro di Bari (Italy)",
  "Sannicandro Garganico (Italy)",
  "Sannicola (Italy)",
  "Sanremo (Italy)",
  "Sansepolcro (Italy)",
  "Sant Angelo (Italy)",
  "Santa Barbara (Italy)",
  "Santa Caterina dello Ionio (Italy)",
  "Santa Caterina Villarmosa (Italy)",
  "Santa Cristina (Italy)",
  "Santa Croce (Italy)",
  "Santa Croce Camerina (Italy)",
  "Santa Elisabetta (Italy)",
  "Santa Eufemia Lamezia (Italy)",
  "Santa Fiora (Italy)",
  "Santa Flavia (Italy)",
  "Santa Giuletta (Italy)",
  "Santa Giusta (Italy)",
  "Santa Giustina (Italy)",
  "Santa Giustina in Colle (Italy)",
  "Santa Lucia (Italy)",
  "Santa Lucia di Piave (Italy)",
  "Santa Margherita di Belice (Italy)",
  "Santa Margherita Ligure (Italy)",
  "Santa Maria (Italy)",
  "Santa Maria a Monte (Italy)",
  "Santa Maria a Vico (Italy)",
  "Santa Maria Capua Vetere (Italy)",
  "Santa Maria Codifiume (Italy)",
  "Santa Maria degli Angeli (Italy)",
  "Santa Maria di Licodia (Italy)",
  "Santa Maria di Sala (Italy)",
  "Santa Maria Imbaro (Italy)",
  "Santa Maria Nuova (Italy)",
  "Santa Marinella (Italy)",
  "Santa Ninfa (Italy)",
  "Santa Severa (Italy)",
  "Santa Sofia (Italy)",
  "Santa Teresa di Riva (Italy)",
  "Santa Teresa Gallura (Italy)",
  "Santa Venerina (Italy)",
  "Santa Vittoria di Libiola (Italy)",
  "Santa Vittoria in Matenano (Italy)",
  "Santadi (Italy)",
  "Santarcangelo di Romagna (Italy)",
  "Santeramo in Colle (Italy)",
  "Santi (Italy)",
  "Santi Cosma e Damiano (Italy)",
  "Santo Pietro (Italy)",
  "Santo Spirito (Italy)",
  "Santo Stefano (Italy)",
  "Santo Stefano al Mare (Italy)",
  "Santo Stefano Belbo (Italy)",
  "Santo Stefano di Briga (Italy)",
  "Santo Stefano di Cadore (Italy)",
  "Santo Stefano di Magra (Italy)",
  "Santo Stefano di Rogliano (Italy)",
  "Santo Stefano Quisquina (Italy)",
  "Santo Stefano Ticino (Italy)",
  "Santo Stino di Livenza (Italy)",
  "Santorso (Italy)",
  "Santu Lussurgiu (Italy)",
  "Saponara (Italy)",
  "Sapri (Italy)",
  "Sarcedo (Italy)",
  "Sarezzo (Italy)",
  "Sarmede (Italy)",
  "Sarnano (Italy)",
  "Sarnico (Italy)",
  "Sarno (Italy)",
  "Sarnthein (Italy)",
  "Saronno (Italy)",
  "Sarroch (Italy)",
  "Sarsina (Italy)",
  "Sarteano (Italy)",
  "Sartirana Lomellina (Italy)",
  "Sarzana (Italy)",
  "Sassa (Italy)",
  "Sassari (Italy)",
  "Sassello (Italy)",
  "Sassetta (Italy)",
  "Sassinoro (Italy)",
  "Sasso (Italy)",
  "Sasso Marconi (Italy)",
  "Sassoferrato (Italy)",
  "Sassuolo (Italy)",
  "Saturnia (Italy)",
  "Sava (Italy)",
  "Saviano (Italy)",
  "Savigliano (Italy)",
  "Savignano sul Panaro (Italy)",
  "Savignano sul Rubicone (Italy)",
  "Savio (Italy)",
  "Savona (Italy)",
  "Scafa (Italy)",
  "Scafati (Italy)",
  "Scala (Italy)",
  "Scaldasole (Italy)",
  "Scalea (Italy)",
  "Scalenghe (Italy)",
  "Scaletta Zanclea (Italy)",
  "Scandiano (Italy)",
  "Scandicci (Italy)",
  "Scanno (Italy)",
  "Scansano (Italy)",
  "Scanzano (Italy)",
  "Scanzano Jonico (Italy)",
  "Scarlino (Italy)",
  "Scarmagno (Italy)",
  "Scarperia (Italy)",
  "Scauri (Italy)",
  "Scerni (Italy)",
  "Schiavon (Italy)",
  "Schio (Italy)",
  "Schlanders (Italy)",
  "Sciacca (Italy)",
  "Scicli (Italy)",
  "Scilla (Italy)",
  "Scoppito (Italy)",
  "Scordia (Italy)",
  "Scorrano (Italy)",
  "Scuola (Italy)",
  "Seano (Italy)",
  "Seborga (Italy)",
  "Secondigliano (Italy)",
  "Sedegliano (Italy)",
  "Sedico (Italy)",
  "Sedilo (Italy)",
  "Sedriano (Italy)",
  "Segariu (Italy)",
  "Seglia San Bernardo (Italy)",
  "Segni (Italy)",
  "Segrate (Italy)",
  "Selargius (Italy)",
  "Selci (Italy)",
  "Selegas (Italy)",
  "Sella (Italy)",
  "Selva (Italy)",
  "Selvazzano Dentro (Italy)",
  "Selvino (Italy)",
  "Seminara (Italy)",
  "Senago (Italy)",
  "Senerchia (Italy)",
  "Seniga (Italy)",
  "Senigallia (Italy)",
  "Senise (Italy)",
  "Sennori (Italy)",
  "Seravezza (Italy)",
  "Seregno (Italy)",
  "Sergnano (Italy)",
  "Seriate (Italy)",
  "Serino (Italy)",
  "Serle (Italy)",
  "Sermide (Italy)",
  "Sermoneta (Italy)",
  "Sernaglia della Battaglia (Italy)",
  "Serra (Italy)",
  "Serra Pedace (Italy)",
  "Serra San Bruno (Italy)",
  "Serra San Quirico (Italy)",
  "Serracapriola (Italy)",
  "Serradifalco (Italy)",
  "Serraglio (Italy)",
  "Serramanna (Italy)",
  "Serramazzoni (Italy)",
  "Serramezzana (Italy)",
  "Serrano (Italy)",
  "Serrara Fontana (Italy)",
  "Serravalle Pistoiese (Italy)",
  "Serravalle Scrivia (Italy)",
  "Serravalle Sesia (Italy)",
  "Serre (Italy)",
  "Serrenti (Italy)",
  "Sersale (Italy)",
  "Servigliano (Italy)",
  "Sessa Aurunca (Italy)",
  "Sesto al Reghena (Italy)",
  "Sesto Calende (Italy)",
  "Sesto Fiorentino (Italy)",
  "Sesto San Giovanni (Italy)",
  "Sestri Levante (Italy)",
  "Sestriere (Italy)",
  "Sestu (Italy)",
  "Settimo (Italy)",
  "Settimo San Pietro (Italy)",
  "Settimo Torinese (Italy)",
  "Settimo Vittone (Italy)",
  "Seveso (Italy)",
  "Sexten (Italy)",
  "Sezze (Italy)",
  "Sgonico (Italy)",
  "Sgurgola (Italy)",
  "Siamaggiore (Italy)",
  "Siamanna (Italy)",
  "Siano (Italy)",
  "Siapiccia (Italy)",
  "Sibari (Italy)",
  "Sicignano degli Alburni (Italy)",
  "Siculiana (Italy)",
  "Siderno (Italy)",
  "Siena (Italy)",
  "Sigillo (Italy)",
  "Signa (Italy)",
  "Silanus (Italy)",
  "Silea (Italy)",
  "Siliqua (Italy)",
  "Sillavengo (Italy)",
  "Silvi (Italy)",
  "Silvi Paese (Italy)",
  "Simaxis (Italy)",
  "Simone (Italy)",
  "Sinalunga (Italy)",
  "Sindia (Italy)",
  "Siniscola (Italy)",
  "Sinnai (Italy)",
  "Sinopoli (Italy)",
  "Sirignano (Italy)",
  "Sirmione (Italy)",
  "Siziano (Italy)",
  "Sizzano (Italy)",
  "Soave (Italy)",
  "Soci (Italy)",
  "Sogliano Cavour (Italy)",
  "Solagna (Italy)",
  "Solarino (Italy)",
  "Solaro (Italy)",
  "Solarolo (Italy)",
  "Solato (Italy)",
  "Solbiate Arno (Italy)",
  "Solbiate Olona (Italy)",
  "Solda (Italy)",
  "Soldano (Italy)",
  "Soleminis (Italy)",
  "Solesino (Italy)",
  "Soleto (Italy)",
  "Soliera (Italy)",
  "Solignano Nuovo (Italy)",
  "Solofra (Italy)",
  "Solopaca (Italy)",
  "Solto Collina (Italy)",
  "Somma Lombardo (Italy)",
  "Somma Vesuviana (Italy)",
  "Sommacampagna (Italy)",
  "Sommariva del Bosco (Italy)",
  "Sommariva Perno (Italy)",
  "Sommatino (Italy)",
  "Sona (Italy)",
  "Soncino (Italy)",
  "Sondalo (Italy)",
  "Sondrio (Italy)",
  "Sonnino (Italy)",
  "Sora (Italy)",
  "Soragna (Italy)",
  "Sorbolo (Italy)",
  "Sordevolo (Italy)",
  "Soresina (Italy)",
  "Sorgono (Italy)",
  "Sori (Italy)",
  "Soriano nel Cimino (Italy)",
  "Sorisole (Italy)",
  "Sorrento (Italy)",
  "Sorso (Italy)",
  "Sortino (Italy)",
  "Sospiro (Italy)",
  "Sospirolo (Italy)",
  "Sossano (Italy)",
  "Sotto Castello (Italy)",
  "Sotto il Monte Giovanni XXIII (Italy)",
  "Sottomarina (Italy)",
  "Soverato Marina (Italy)",
  "Sovere (Italy)",
  "Soveria Mannelli (Italy)",
  "Sovicille (Italy)",
  "Spadafora (Italy)",
  "Sparanise (Italy)",
  "Sparone (Italy)",
  "Specchia (Italy)",
  "Spello (Italy)",
  "Sperlonga (Italy)",
  "Sperone (Italy)",
  "Spezzano Albanese (Italy)",
  "Spezzano della Sila (Italy)",
  "Spigno Saturnia (Italy)",
  "Spilamberto (Italy)",
  "Spilimbergo (Italy)",
  "Spin (Italy)",
  "Spina (Italy)",
  "Spinazzola (Italy)",
  "Spinea (Italy)",
  "Spineda (Italy)",
  "Spinete (Italy)",
  "Spineto (Italy)",
  "Spinetoli (Italy)",
  "Spinetta Marengo (Italy)",
  "Spirano (Italy)",
  "Spoleto (Italy)",
  "Spoltore (Italy)",
  "Spotorno (Italy)",
  "Spresiano (Italy)",
  "Squillace (Italy)",
  "Squinzano (Italy)",
  "Staffolo (Italy)",
  "Stagno (Italy)",
  "Stanghella (Italy)",
  "Staranzano (Italy)",
  "Statte (Italy)",
  "Stazione Castelguelfo (Italy)",
  "Stazione Ponte Rio (Italy)",
  "Stazzano (Italy)",
  "Stefania (Italy)",
  "Stella Cilento (Italy)",
  "Sterzing (Italy)",
  "Stezzano (Italy)",
  "Stia (Italy)",
  "Stienta (Italy)",
  "Stigliano (Italy)",
  "Stimigliano (Italy)",
  "Stornarella (Italy)",
  "Storo (Italy)",
  "Stra (Italy)",
  "Strada San Zeno (Italy)",
  "Stradella (Italy)",
  "Strambino (Italy)",
  "Stresa (Italy)",
  "Stretti (Italy)",
  "Strevi (Italy)",
  "Striano (Italy)",
  "Strigno (Italy)",
  "Strona (Italy)",
  "Stroncone (Italy)",
  "Strongoli (Italy)",
  "Stroppiana (Italy)",
  "Sturno (Italy)",
  "Suardi (Italy)",
  "Subbiano (Italy)",
  "Subiaco (Italy)",
  "Sulmona (Italy)",
  "Sulzano (Italy)",
  "Sumirago (Italy)",
  "Suno (Italy)",
  "Supino (Italy)",
  "Surbo (Italy)",
  "Susa (Italy)",
  "Susegana (Italy)",
  "Sutri (Italy)",
  "Sutrio (Italy)",
  "Suzzara (Italy)",
  "Syracuse (Italy)",
  "Taggia (Italy)",
  "Tagliacozzo (Italy)",
  "Taglio di Po (Italy)",
  "Taibon Agordino (Italy)",
  "Taino (Italy)",
  "Taio (Italy)",
  "Talsano (Italy)",
  "Taormina (Italy)",
  "Taranta Peligna (Italy)",
  "Tarantasca (Italy)",
  "Taranto (Italy)",
  "Tarcento (Italy)",
  "Tarquinia (Italy)",
  "Tarvisio (Italy)",
  "Tarzo (Italy)",
  "Tassullo (Italy)",
  "Taurano (Italy)",
  "Taurianova (Italy)",
  "Tavagnacco (Italy)",
  "Tavarnelle Val di Pesa (Italy)",
  "Tavarnuzze (Italy)",
  "Taverna (Italy)",
  "Tavernola Bergamasca (Italy)",
  "Taviano (Italy)",
  "Tavullia (Italy)",
  "Teggiano (Italy)",
  "Teglio (Italy)",
  "Teglio Veneto (Italy)",
  "Telese (Italy)",
  "Telgate (Italy)",
  "Tempio Pausania (Italy)",
  "Tenno (Italy)",
  "Teolo (Italy)",
  "Teramo (Italy)",
  "Terenten (Italy)",
  "Terenzo (Italy)",
  "Terlan (Italy)",
  "Terlizzi (Italy)",
  "Termeno (Italy)",
  "Termini Imerese (Italy)",
  "Termoli (Italy)",
  "Ternate (Italy)",
  "Terni (Italy)",
  "Terracina (Italy)",
  "Terralba (Italy)",
  "Terranova da Sibari (Italy)",
  "Terranuova Bracciolini (Italy)",
  "Terrarossa (Italy)",
  "Terrasini (Italy)",
  "Terricciola (Italy)",
  "Terruggia (Italy)",
  "Tertenia (Italy)",
  "Terzigno (Italy)",
  "Terzo (Italy)",
  "Terzorio (Italy)",
  "Tessera (Italy)",
  "Tezze sul Brenta (Italy)",
  "Thiene (Italy)",
  "Thiesi (Italy)",
  "Ticengo (Italy)",
  "Tina (Italy)",
  "Tione di Trento (Italy)",
  "Tirano (Italy)",
  "Tiriolo (Italy)",
  "Tirrenia (Italy)",
  "Tito (Italy)",
  "Tivoli (Italy)",
  "Toblach (Italy)",
  "Todi (Italy)",
  "Toffia (Italy)",
  "Toirano (Italy)",
  "Tolentino (Italy)",
  "Tolfa (Italy)",
  "Tollegno (Italy)",
  "Tolmezzo (Italy)",
  "Tolve (Italy)",
  "Tomba (Italy)",
  "Tombolo (Italy)",
  "Tonezza del Cimone (Italy)",
  "Torano Castello (Italy)",
  "Torchiarolo (Italy)",
  "Torella dei Lombardi (Italy)",
  "Torgiano (Italy)",
  "Torgnon (Italy)",
  "Toritto (Italy)",
  "Tornaco (Italy)",
  "Tornareccio (Italy)",
  "Toro (Italy)",
  "Torrazza (Italy)",
  "Torre (Italy)",
  "Torre a Mare (Italy)",
  "Torre Annunziata (Italy)",
  "Torre Boldone (Italy)",
  "Torre del Greco (Italy)",
  "Torre del Lago Puccini (Italy)",
  "Torre di Ruggiero (Italy)",
  "Torre Orsaia (Italy)",
  "Torre Pellice (Italy)",
  "Torre San Patrizio (Italy)",
  "Torre Santa Susanna (Italy)",
  "Torrebelvicino (Italy)",
  "Torrecuso (Italy)",
  "Torreglia (Italy)",
  "Torregrotta (Italy)",
  "Torrenova (Italy)",
  "Torretta (Italy)",
  "Torrevecchia Teatina (Italy)",
  "Torri (Italy)",
  "Torri del Benaco (Italy)",
  "Torri di Quartesolo (Italy)",
  "Torri in Sabina (Italy)",
  "Torriana (Italy)",
  "Torrice (Italy)",
  "Torricella (Italy)",
  "Torrile (Italy)",
  "Torrita di Siena (Italy)",
  "Torrita Tiberina (Italy)",
  "Tortona (Italy)",
  "Tortora (Italy)",
  "Tortoreto (Italy)",
  "Torviscosa (Italy)",
  "Toscolano-Maderno (Italy)",
  "Tossicia (Italy)",
  "Trabia (Italy)",
  "Tradate (Italy)",
  "Tramonti (Italy)",
  "Tramutola (Italy)",
  "Trana (Italy)",
  "Trani (Italy)",
  "Transacqua (Italy)",
  "Traona (Italy)",
  "Trapani (Italy)",
  "Trappeto (Italy)",
  "Trasacco (Italy)",
  "Tratalias (Italy)",
  "Travagliato (Italy)",
  "Travedona Monate (Italy)",
  "Traversetolo (Italy)",
  "Travo (Italy)",
  "Trebaseleghe (Italy)",
  "Trebisacce (Italy)",
  "Trecastagni (Italy)",
  "Trecate (Italy)",
  "Trecenta (Italy)",
  "Tredozio (Italy)",
  "Treglio (Italy)",
  "Tregnago (Italy)",
  "Treia (Italy)",
  "Trento (Italy)",
  "Trentola-Ducenta (Italy)",
  "Trepuzzi (Italy)",
  "Trequanda (Italy)",
  "Tres (Italy)",
  "Trescore Balneario (Italy)",
  "Trescore Cremasco (Italy)",
  "Tresenda (Italy)",
  "Trevenzuolo (Italy)",
  "Trevi (Italy)",
  "Trevico (Italy)",
  "Treviglio (Italy)",
  "Trevignano (Italy)",
  "Trevignano Romano (Italy)",
  "Treviso (Italy)",
  "Trezzano Rosa (Italy)",
  "Trezzano sul Naviglio (Italy)",
  "Tribiano (Italy)",
  "Tricarico (Italy)",
  "Tricase (Italy)",
  "Tricesimo (Italy)",
  "Trichiana (Italy)",
  "Trieste (Italy)",
  "Triggianello (Italy)",
  "Triggiano (Italy)",
  "Trigolo (Italy)",
  "Trinitapoli (Italy)",
  "Trino (Italy)",
  "Trissino (Italy)",
  "Triuggio (Italy)",
  "Trivento (Italy)",
  "Trivero (Italy)",
  "Trivignano (Italy)",
  "Trivignano Udinese (Italy)",
  "Trofarello (Italy)",
  "Troia (Italy)",
  "Troina (Italy)",
  "Tromello (Italy)",
  "Tronzano Vercellese (Italy)",
  "Tropea (Italy)",
  "Trovo (Italy)",
  "Truccazzano (Italy)",
  "Tufino (Italy)",
  "Tuglie (Italy)",
  "Tuili (Italy)",
  "Tuoro sul Trasimeno (Italy)",
  "Turbigo (Italy)",
  "Turi (Italy)",
  "Turin (Italy)",
  "Turriaco (Italy)",
  "Tursi (Italy)",
  "Tusa (Italy)",
  "Tuscania (Italy)",
  "Udine (Italy)",
  "Ugento (Italy)",
  "Uggiate Trevano (Italy)",
  "Umbertide (Italy)",
  "Urbania (Italy)",
  "Urbino (Italy)",
  "Urbisaglia (Italy)",
  "Urgnano (Italy)",
  "Ururi (Italy)",
  "Uscio (Italy)",
  "Usellus (Italy)",
  "Usini (Italy)",
  "Usmate Velate (Italy)",
  "Ussana (Italy)",
  "Uta (Italy)",
  "Uzzano (Italy)",
  "Vacri (Italy)",
  "Vadena (Italy)",
  "Vado (Italy)",
  "Vado Ligure (Italy)",
  "Vaglia (Italy)",
  "Vaglio Basilicata (Italy)",
  "Vahrn (Italy)",
  "Vaiano (Italy)",
  "Vaiano Cremasco (Italy)",
  "Vailate (Italy)",
  "Vairano Patenora (Italy)",
  "Vajont (Italy)",
  "Valdagno (Italy)",
  "Valdengo (Italy)",
  "Valdieri (Italy)",
  "Valdobbiadene (Italy)",
  "Valduggia (Italy)",
  "Valeggio sul Mincio (Italy)",
  "Valentano (Italy)",
  "Valentina (Italy)",
  "Valenza (Italy)",
  "Valenzano (Italy)",
  "Valfabbrica (Italy)",
  "Valfenera (Italy)",
  "Valganna (Italy)",
  "Valguarnera Caropepe (Italy)",
  "Vallata (Italy)",
  "Valle (Italy)",
  "Valle Agricola (Italy)",
  "Valle di Cadore (Italy)",
  "Valle Lomellina (Italy)",
  "Valle Mosso (Italy)",
  "Vallecrosia (Italy)",
  "Vallefiorita (Italy)",
  "Vallerano (Italy)",
  "Valli del Pasubio (Italy)",
  "Vallo (Italy)",
  "Vallo della Lucania (Italy)",
  "Vallombrosa (Italy)",
  "Valmadrera (Italy)",
  "Valmontone (Italy)",
  "Valperga (Italy)",
  "Valverde (Italy)",
  "Vanzaghello (Italy)",
  "Vanzago (Italy)",
  "Varallo Pombia (Italy)",
  "Varallo Sesia (Italy)",
  "Varano (Italy)",
  "Varapodio (Italy)",
  "Varazze (Italy)",
  "Varedo (Italy)",
  "Varese (Italy)",
  "Varese Ligure (Italy)",
  "Varzo (Italy)",
  "Vas (Italy)",
  "Vasanello (Italy)",
  "Vascon (Italy)",
  "Vasto (Italy)",
  "Vattaro (Italy)",
  "Vecchiano-Nodica (Italy)",
  "Vedano Olona (Italy)",
  "Vedelago (Italy)",
  "Veglie (Italy)",
  "Velletri (Italy)",
  "Vellezzo Bellini (Italy)",
  "Vena di Maida (Italy)",
  "Venafro (Italy)",
  "Venaria Reale (Italy)",
  "Venarotta (Italy)",
  "Venaus (Italy)",
  "Venegono Inferiore (Italy)",
  "Venegono Superiore (Italy)",
  "Venetico (Italy)",
  "Veneto (Italy)",
  "Venice (Italy)",
  "Venosa (Italy)",
  "Ventimiglia (Italy)",
  "Ventimiglia di Sicilia (Italy)",
  "Venturina (Italy)",
  "Verbicaro (Italy)",
  "Vercelli (Italy)",
  "Vercurago (Italy)",
  "Verdellino (Italy)",
  "Verdello (Italy)",
  "Vergano-Villa (Italy)",
  "Vergato (Italy)",
  "Vergiate (Italy)",
  "Vermezzo (Italy)",
  "Vernate (Italy)",
  "Vernio (Italy)",
  "Vernole (Italy)",
  "Verolanuova (Italy)",
  "Verolavecchia (Italy)",
  "Verolengo (Italy)",
  "Veroli (Italy)",
  "Verona (Italy)",
  "Veronella (Italy)",
  "Verrone (Italy)",
  "Vertova (Italy)",
  "Verucchio (Italy)",
  "Verzuolo (Italy)",
  "Vescovana (Italy)",
  "Vescovato (Italy)",
  "Vespolate (Italy)",
  "Vestone (Italy)",
  "Vetralla (Italy)",
  "Vezzano Ligure (Italy)",
  "Vezzano sul Crostolo (Italy)",
  "Viadana (Italy)",
  "Viagrande (Italy)",
  "Viano (Italy)",
  "Viareggio (Italy)",
  "Vibo Valentia (Italy)",
  "Vicarello (Italy)",
  "Vicari (Italy)",
  "Vicchio (Italy)",
  "Vicenza (Italy)",
  "Vico Equense (Italy)",
  "Vicoforte (Italy)",
  "Vicolungo (Italy)",
  "Vicopisano (Italy)",
  "Vicovaro (Italy)",
  "Vidardo (Italy)",
  "Vidiciatico (Italy)",
  "Vidigulfo (Italy)",
  "Vieste (Italy)",
  "Vietri sul Mare (Italy)",
  "Vigarano Mainarda (Italy)",
  "Vigarano Pieve (Italy)",
  "Vigasio (Italy)",
  "Vigevano (Italy)",
  "Vigliano Biellese (Italy)",
  "Vignacastrisi (Italy)",
  "Vignale Monferrato (Italy)",
  "Vignanello (Italy)",
  "Vignate (Italy)",
  "Vignola (Italy)",
  "Vigo di Cadore (Italy)",
  "Vigo di Fassa (Italy)",
  "Vigo Rendena (Italy)",
  "Vigodarzere (Italy)",
  "Vigolo Vattaro (Italy)",
  "Vigolzone (Italy)",
  "Vigone (Italy)",
  "Vigonovo (Italy)",
  "Vigonza (Italy)",
  "Viguzzolo (Italy)",
  "Villa (Italy)",
  "Villa Bartolomea (Italy)",
  "Villa Basilica (Italy)",
  "Villa Castelli (Italy)",
  "Villa Cortese (Italy)",
  "Villa del Conte (Italy)",
  "Villa di Serio (Italy)",
  "Villa di Tirano (Italy)",
  "Villa Estense (Italy)",
  "Villa Faraldi (Italy)",
  "Villa Guardia (Italy)",
  "Villa Lagarina (Italy)",
  "Villa Literno (Italy)",
  "Villa Marina (Italy)",
  "Villa Minozzo (Italy)",
  "Villa Morelli (Italy)",
  "Villa Ottone (Italy)",
  "Villa San Giovanni (Italy)",
  "Villa San Pietro (Italy)",
  "Villa San Sebastiano (Italy)",
  "Villa Santa Lucia (Italy)",
  "Villa Verucchio (Italy)",
  "Villa Vicentina (Italy)",
  "Villabate (Italy)",
  "Villacidro (Italy)",
  "Villadose (Italy)",
  "Villafranca di Forli (Italy)",
  "Villafranca di Verona (Italy)",
  "Villafranca in Lunigiana (Italy)",
  "Villafranca Piemonte (Italy)",
  "Villafranca Tirrena (Italy)",
  "Villafrati (Italy)",
  "Villaggio (Italy)",
  "Villaggio Montegrappa (Italy)",
  "Villagrazia (Italy)",
  "Villalago (Italy)",
  "Villamagna (Italy)",
  "Villamar (Italy)",
  "Villamassargia (Italy)",
  "Villanova (Italy)",
  "Villanova del Ghebbo-Valdentro (Italy)",
  "Villanova Monferrato (Italy)",
  "Villanovafranca (Italy)",
  "Villanterio (Italy)",
  "Villanuova sul Clisi (Italy)",
  "Villapiana (Italy)",
  "Villaputzu (Italy)",
  "Villar (Italy)",
  "Villaricca (Italy)",
  "Villarosa (Italy)",
  "Villasalto (Italy)",
  "Villasanta (Italy)",
  "Villasmundo (Italy)",
  "Villasor (Italy)",
  "Villaspeciosa (Italy)",
  "Villastellone (Italy)",
  "Villastrada (Italy)",
  "Villaurbana (Italy)",
  "Villaverla (Italy)",
  "Villavesco (Italy)",
  "Ville (Italy)",
  "Villetta (Italy)",
  "Vimercate (Italy)",
  "Vimodrone (Italy)",
  "Vinci (Italy)",
  "Viola (Italy)",
  "Virgilio (Italy)",
  "Viscone (Italy)",
  "Viserba (Italy)",
  "Visinale (Italy)",
  "Vistrorio (Italy)",
  "Viterbo (Italy)",
  "Vitigliano (Italy)",
  "Vitorchiano (Italy)",
  "Vittoria (Italy)",
  "Vittorio Veneto (Italy)",
  "Vittuone (Italy)",
  "Vitulano (Italy)",
  "Vitulazio (Italy)",
  "Vivaro (Italy)",
  "Vizzini (Italy)",
  "Vo (Italy)",
  "Vobarno (Italy)",
  "Voghenza (Italy)",
  "Voghera (Italy)",
  "Vogogna (Italy)",
  "Volano (Italy)",
  "Volla (Italy)",
  "Volpago del Montello (Italy)",
  "Volpedo (Italy)",
  "Volpiano (Italy)",
  "Volta Mantovana (Italy)",
  "Voltago (Italy)",
  "Volterra (Italy)",
  "Volturara Irpina (Italy)",
  "Volturino (Italy)",
  "Volvera (Italy)",
  "Welsberg-Taisten (Italy)",
  "Welschnofen (Italy)",
  "Zafferana Etnea (Italy)",
  "Zagarolo (Italy)",
  "Zambana (Italy)",
  "Zambrone (Italy)",
  "Zandobbio (Italy)",
  "Zanetti (Italy)",
  "Zangarona (Italy)",
  "Zanica (Italy)",
  "Zelarino (Italy)",
  "Zelo (Italy)",
  "Zelo Buon Persico (Italy)",
  "Zeme (Italy)",
  "Zerbo (Italy)",
  "Zerfaliu (Italy)",
  "Zermeghedo (Italy)",
  "Zero Branco (Italy)",
  "Zevio (Italy)",
  "Zibido San Giacomo (Italy)",
  "Zimella (Italy)",
  "Zimone (Italy)",
  "Zinasco (Italy)",
  "Zoagli (Italy)",
  "Zocca (Italy)",
  "Zogno (Italy)",
  "Zola Predosa (Italy)",
  "Zoldo Alto (Italy)",
  "Zollino (Italy)",
  "Zoppola (Italy)",
  "Zumaglia (Italy)",
  "Jamaica",
  "Black River (Jamaica)",
  "Browns Town (Jamaica)",
  "Gordon Town (Jamaica)",
  "Gregory Park (Jamaica)",
  "Kingston (Jamaica)",
  "Mandeville (Jamaica)",
  "May Pen (Jamaica)",
  "Moneague (Jamaica)",
  "Montego Bay (Jamaica)",
  "Negril (Jamaica)",
  "Ocho Rios (Jamaica)",
  "Old Harbour (Jamaica)",
  "Port Maria (Jamaica)",
  "Portland Cottage (Jamaica)",
  "Portmore (Jamaica)",
  "Runaway Bay (Jamaica)",
  "Spanish Town (Jamaica)",
  "Japan",
  "Abashiri (Japan)",
  "Abiko (Japan)",
  "Abira (Japan)",
  "Aboshiku-okinohama (Japan)",
  "Agano (Japan)",
  "Agena (Japan)",
  "Ageo (Japan)",
  "Aikawa (Japan)",
  "Aioi (Japan)",
  "Aisai (Japan)",
  "Aitsu (Japan)",
  "Aizu-Wakamatsu (Japan)",
  "Akao (Japan)",
  "Akasaka (Japan)",
  "Akasaki (Japan)",
  "Akashi (Japan)",
  "Akeno (Japan)",
  "Aki (Japan)",
  "Akiruno-shi (Japan)",
  "Akishima (Japan)",
  "Akita (Japan)",
  "Ama-higashimachi (Japan)",
  "Amagasaki (Japan)",
  "Ami (Japan)",
  "Anabuki (Japan)",
  "Anan (Japan)",
  "Anesaki (Japan)",
  "Anjo (Japan)",
  "Annaka (Japan)",
  "Aoicho (Japan)",
  "Aomori (Japan)",
  "Arai (Japan)",
  "Arakawa (Japan)",
  "Ariake (Japan)",
  "Arida (Japan)",
  "Arita (Japan)",
  "Asahi (Japan)",
  "Asahikawa (Japan)",
  "Asahimachi (Japan)",
  "Asaka (Japan)",
  "Ashihara (Japan)",
  "Ashikaga (Japan)",
  "Ashiya (Japan)",
  "Aso (Japan)",
  "Atami (Japan)",
  "Atsugi (Japan)",
  "Awara (Japan)",
  "Ayase (Japan)",
  "Azuchi (Japan)",
  "Azumino (Japan)",
  "Bakurocho (Japan)",
  "Bandaicho (Japan)",
  "Bando (Japan)",
  "Beppu (Japan)",
  "Bibai (Japan)",
  "Budo (Japan)",
  "Chatan (Japan)",
  "Chiba (Japan)",
  "Chichibu (Japan)",
  "Chigasaki (Japan)",
  "Chiji (Japan)",
  "Chikusei (Japan)",
  "Chino (Japan)",
  "Chiran (Japan)",
  "Chiryu (Japan)",
  "Chita (Japan)",
  "Chitose (Japan)",
  "Choshi (Japan)",
  "Chuo (Japan)",
  "Daido (Japan)",
  "Daigo (Japan)",
  "Daimon (Japan)",
  "Daisen (Japan)",
  "Daitocho (Japan)",
  "Date (Japan)",
  "Ebetsu (Japan)",
  "Ebina (Japan)",
  "Ebisu (Japan)",
  "Echizen (Japan)",
  "Edagawa (Japan)",
  "Edogawa-ku (Japan)",
  "Ena (Japan)",
  "Eniwa-shi (Japan)",
  "Esashi (Japan)",
  "Fuchu-machi (Japan)",
  "Fuchucho (Japan)",
  "Fuefuki-shi (Japan)",
  "Fuji (Japan)",
  "Fujieda (Japan)",
  "Fujiidera (Japan)",
  "Fujimi (Japan)",
  "Fujimino (Japan)",
  "Fujinomiya (Japan)",
  "Fujioka (Japan)",
  "Fujisato (Japan)",
  "Fujisawa (Japan)",
  "Fujita (Japan)",
  "Fukai (Japan)",
  "Fukayacho (Japan)",
  "Fukiage-fujimi (Japan)",
  "Fukuchi (Japan)",
  "Fukuchiyama (Japan)",
  "Fukuda (Japan)",
  "Fukui (Japan)",
  "Fukui-shi (Japan)",
  "Fukuoka (Japan)",
  "Fukuroi (Japan)",
  "Fukushima-shi (Japan)",
  "Fukuyama (Japan)",
  "Fumoto (Japan)",
  "Funabashi (Japan)",
  "Fushiki-minatomachi (Japan)",
  "Fushimi (Japan)",
  "Fussa (Japan)",
  "Futoo (Japan)",
  "Futtsu (Japan)",
  "Gamagori (Japan)",
  "Gannosu (Japan)",
  "Geinocho-mukumoto (Japan)",
  "Gifu (Japan)",
  "Ginowan (Japan)",
  "Ginza (Japan)",
  "Gobo (Japan)",
  "Godo (Japan)",
  "Gojo (Japan)",
  "Gosen (Japan)",
  "Goshogawara (Japan)",
  "Gotanda (Japan)",
  "Gotemba (Japan)",
  "Gotsucho (Japan)",
  "Goya (Japan)",
  "Gujo (Japan)",
  "Gundo (Japan)",
  "Gushikawa (Japan)",
  "Gyoda (Japan)",
  "Habikino (Japan)",
  "Hachiman-cho (Japan)",
  "Hachimantai (Japan)",
  "Hachimori (Japan)",
  "Hachinohe (Japan)",
  "Hadano (Japan)",
  "Haebaru (Japan)",
  "Hagi (Japan)",
  "Haibara-akanedai (Japan)",
  "Haijima (Japan)",
  "Hakata (Japan)",
  "Hakodate (Japan)",
  "Hakone (Japan)",
  "Hakozaki (Japan)",
  "Hakui (Japan)",
  "Hama (Japan)",
  "Hamada (Japan)",
  "Hamamatsu (Japan)",
  "Hamana (Japan)",
  "Hamura (Japan)",
  "Hanabatacho (Japan)",
  "Hanamaki (Japan)",
  "Hanawadamachi (Japan)",
  "Handa (Japan)",
  "Hannan (Japan)",
  "Hanno (Japan)",
  "Hanyu (Japan)",
  "Harigaya (Japan)",
  "Hashima (Japan)",
  "Hashimoto (Japan)",
  "Hasuda (Japan)",
  "Hatogaya-honcho (Japan)",
  "Hatoyama (Japan)",
  "Hatsukaichi (Japan)",
  "Hayama (Japan)",
  "Hayashi (Japan)",
  "Hayashima (Japan)",
  "Hayashino (Japan)",
  "Heiwajima (Japan)",
  "Hekinan (Japan)",
  "Hidaka (Japan)",
  "Hidakacho-ebara (Japan)",
  "Higashi-iwashiro (Japan)",
  "Higashi-takadama (Japan)",
  "Higashiasahimachi (Japan)",
  "Higashiboridori (Japan)",
  "Higashicho (Japan)",
  "Higashiiya-takano (Japan)",
  "Higashiiya-wakabayashi (Japan)",
  "Higashikawa (Japan)",
  "Higashimurayama-shi (Japan)",
  "Higashine (Japan)",
  "Higashiomi-shi (Japan)",
  "Higashiosaka (Japan)",
  "Higashiura (Japan)",
  "Higashiyama (Japan)",
  "Higashiyamato (Japan)",
  "Hiji (Japan)",
  "Hikari (Japan)",
  "Hikawa (Japan)",
  "Hiki (Japan)",
  "Hikone (Japan)",
  "Himeji (Japan)",
  "Himi (Japan)",
  "Hinata (Japan)",
  "Hino (Japan)",
  "Hinode (Japan)",
  "Hirakata (Japan)",
  "Hirakawa (Japan)",
  "Hiranuma (Japan)",
  "Hiratsuka (Japan)",
  "Hirokawa (Japan)",
  "Hirosaki (Japan)",
  "Hiroshima (Japan)",
  "Hita (Japan)",
  "Hitachi (Japan)",
  "Hitoyoshi (Japan)",
  "Hiyoshi (Japan)",
  "Hodogayacho (Japan)",
  "Hofu (Japan)",
  "Hojo (Japan)",
  "Hokota (Japan)",
  "Hokuto-shi (Japan)",
  "Hommachi (Japan)",
  "Honcho (Japan)",
  "Honden (Japan)",
  "Hongo (Japan)",
  "Honjo (Japan)",
  "Honjocho (Japan)",
  "Horinouchi (Japan)",
  "Horyuji (Japan)",
  "Hosobara (Japan)",
  "Hotei (Japan)",
  "Hyogo (Japan)",
  "Ibara (Japan)",
  "Ibaraki (Japan)",
  "Ichi (Japan)",
  "Ichibanhoridoricho (Japan)",
  "Ichihara (Japan)",
  "Ichikawa (Japan)",
  "Ichimura (Japan)",
  "Ichinogo (Japan)",
  "Ichinomiya (Japan)",
  "Ichinomiyacho (Japan)",
  "Ichinomotocho (Japan)",
  "Ichinoseki (Japan)",
  "Ida (Japan)",
  "Iida (Japan)",
  "Iiyama (Japan)",
  "Iizuka (Japan)",
  "Iju (Japan)",
  "Ikaruga (Japan)",
  "Ikeda (Japan)",
  "Ikedacho-itano (Japan)",
  "Ikejiri (Japan)",
  "Ikoma (Japan)",
  "Ikuno (Japan)",
  "Imabari-shi (Japan)",
  "Imabuku (Japan)",
  "Imagami (Japan)",
  "Imaicho (Japan)",
  "Imari (Japan)",
  "Imizucho (Japan)",
  "Ina (Japan)",
  "Inabe (Japan)",
  "Inage (Japan)",
  "Inagi-shi (Japan)",
  "Inami (Japan)",
  "Inashiki (Japan)",
  "Inazawa (Japan)",
  "Innoshima Hara-cho (Japan)",
  "Ino (Japan)",
  "Inuyama (Japan)",
  "Inzai (Japan)",
  "Iruma (Japan)",
  "Isahaya (Japan)",
  "Isawa (Japan)",
  "Ise (Japan)",
  "Isehara (Japan)",
  "Isesaki (Japan)",
  "Ishida (Japan)",
  "Ishigaki (Japan)",
  "Ishige (Japan)",
  "Ishii (Japan)",
  "Ishikari (Japan)",
  "Ishikawa (Japan)",
  "Ishiki (Japan)",
  "Ishinomaki (Japan)",
  "Ishioka (Japan)",
  "Isomura (Japan)",
  "Isoya (Japan)",
  "Isumi (Japan)",
  "Itabashi (Japan)",
  "Itako (Japan)",
  "Itami (Japan)",
  "Itoi (Japan)",
  "Itoigawa (Japan)",
  "Itoman (Japan)",
  "Iwade (Japan)",
  "Iwaki (Japan)",
  "Iwakiri (Japan)",
  "Iwakuni (Japan)",
  "Iwakura (Japan)",
  "Iwamizawa (Japan)",
  "Iwanai (Japan)",
  "Iwanuma (Japan)",
  "Iwata (Japan)",
  "Iwatsuki (Japan)",
  "Izu (Japan)",
  "Izumi (Japan)",
  "Izumiotsu (Japan)",
  "Izumo (Japan)",
  "Izunokuni (Japan)",
  "Jissen (Japan)",
  "Joetsu (Japan)",
  "Jogo (Japan)",
  "Joshi (Japan)",
  "Joso (Japan)",
  "Jumonji (Japan)",
  "Kabe (Japan)",
  "Kadoma (Japan)",
  "Kaga (Japan)",
  "Kagawa (Japan)",
  "Kagoshima (Japan)",
  "Kagoya (Japan)",
  "Kahoku (Japan)",
  "Kaigandori (Japan)",
  "Kainan (Japan)",
  "Kaisei (Japan)",
  "Kaita (Japan)",
  "Kaizu (Japan)",
  "Kaizuka (Japan)",
  "Kakamigahara (Japan)",
  "Kakegawa (Japan)",
  "Kakogawa (Japan)",
  "Kakuda (Japan)",
  "Kamagaya-shi (Japan)",
  "Kamaishi (Japan)",
  "Kamakura (Japan)",
  "Kameda-honcho (Japan)",
  "Kameoka (Japan)",
  "Kameyama (Japan)",
  "Kami-kawakami (Japan)",
  "Kami-nagarekawacho (Japan)",
  "Kamibun (Japan)",
  "Kamigori (Japan)",
  "Kamikawa (Japan)",
  "Kamikita-kita (Japan)",
  "Kamimura (Japan)",
  "Kaminokawa (Japan)",
  "Kaminoyama (Japan)",
  "Kamirenjaku (Japan)",
  "Kamisu (Japan)",
  "Kamitosa (Japan)",
  "Kamo (Japan)",
  "Kamogatacho-kamogata (Japan)",
  "Kamoi (Japan)",
  "Kanada (Japan)",
  "Kanagawa (Japan)",
  "Kanaya (Japan)",
  "Kanayama (Japan)",
  "Kanazawa (Japan)",
  "Kanda (Japan)",
  "Kanegasaki (Japan)",
  "Kani (Japan)",
  "Kanie (Japan)",
  "Kano (Japan)",
  "Kanomata (Japan)",
  "Kanuma (Japan)",
  "Kanzaki (Japan)",
  "Karatsu (Japan)",
  "Kariya (Japan)",
  "Kasai (Japan)",
  "Kasama (Japan)",
  "Kasamatsucho (Japan)",
  "Kasaoka (Japan)",
  "Kaseda-shirakame (Japan)",
  "Kashiba (Japan)",
  "Kashihara (Japan)",
  "Kashima (Japan)",
  "Kashima-shi (Japan)",
  "Kashiwa (Japan)",
  "Kashiwagimachi (Japan)",
  "Kashiwara (Japan)",
  "Kashiwazaki (Japan)",
  "Kasuga (Japan)",
  "Kasugai (Japan)",
  "Kasukabe (Japan)",
  "Kasumicho (Japan)",
  "Kasumigaseki (Japan)",
  "Kasumigaura (Japan)",
  "Kataoka (Japan)",
  "Katori-shi (Japan)",
  "Katsumada (Japan)",
  "Katsura (Japan)",
  "Katsuura (Japan)",
  "Katsuyama (Japan)",
  "Katta (Japan)",
  "Kawagoe (Japan)",
  "Kawaguchi (Japan)",
  "Kawahara (Japan)",
  "Kawanishi (Japan)",
  "Kawasaki (Japan)",
  "Kayano (Japan)",
  "Kazo (Japan)",
  "Kazuka (Japan)",
  "Kazuno (Japan)",
  "Kesennuma (Japan)",
  "Keta (Japan)",
  "Kichijoji-honcho (Japan)",
  "Kii-nagashimaku-nagashima (Japan)",
  "Kikuchi (Japan)",
  "Kikugawa (Japan)",
  "Kikyo (Japan)",
  "Kimitsu (Japan)",
  "Kinokawa (Japan)",
  "Kinoshita (Japan)",
  "Kiryu (Japan)",
  "Kisarazu (Japan)",
  "Kishiwada (Japan)",
  "Kita (Japan)",
  "Kita-sannomaru (Japan)",
  "Kitagata (Japan)",
  "Kitahiroshima (Japan)",
  "Kitaibaraki (Japan)",
  "Kitakami (Japan)",
  "Kitakata (Japan)",
  "Kitakyushu (Japan)",
  "Kitami (Japan)",
  "Kitamoto (Japan)",
  "Kitanagoya (Japan)",
  "Kitaozuma (Japan)",
  "Kitsuki (Japan)",
  "Kitsuregawa (Japan)",
  "Kiyama (Japan)",
  "Kiyokawa (Japan)",
  "Kiyose (Japan)",
  "Kiyosu (Japan)",
  "Kobe (Japan)",
  "Kochi-shi (Japan)",
  "Kochino (Japan)",
  "Kocho (Japan)",
  "Kodaira (Japan)",
  "Kodamacho-kodamaminami (Japan)",
  "Kofu (Japan)",
  "Koga (Japan)",
  "Koganei (Japan)",
  "Kojima (Japan)",
  "Koka (Japan)",
  "Kokawa (Japan)",
  "Kokubu-matsuki (Japan)",
  "Kokubunji (Japan)",
  "Kokufu (Japan)",
  "Kokura (Japan)",
  "Kokuryo (Japan)",
  "Komaba (Japan)",
  "Komae-shi (Japan)",
  "Komagane (Japan)",
  "Komagawa (Japan)",
  "Komagome (Japan)",
  "Komaki (Japan)",
  "Komatsu (Japan)",
  "Komatsubara (Japan)",
  "Komatsushimacho (Japan)",
  "Komono (Japan)",
  "Komoro (Japan)",
  "Konan (Japan)",
  "Konancho-fukawa (Japan)",
  "Konosu (Japan)",
  "Koori (Japan)",
  "Koriyama (Japan)",
  "Koryocho-itazu (Japan)",
  "Kosai (Japan)",
  "Kose (Japan)",
  "Koshigaya (Japan)",
  "Koto (Japan)",
  "Kotohira (Japan)",
  "Kotoku (Japan)",
  "Koyama (Japan)",
  "Koyasan (Japan)",
  "Koyo (Japan)",
  "Koza (Japan)",
  "Kubo (Japan)",
  "Kudamatsu (Japan)",
  "Kugayama (Japan)",
  "Kukichuo (Japan)",
  "Kumagaya (Japan)",
  "Kumamoto (Japan)",
  "Kumano (Japan)",
  "Kunitachi (Japan)",
  "Kurami (Japan)",
  "Kurashiki (Japan)",
  "Kurayoshi (Japan)",
  "Kure (Japan)",
  "Kurihara (Japan)",
  "Kurihashi (Japan)",
  "Kurobeshin (Japan)",
  "Kurogi (Japan)",
  "Kuroishi (Japan)",
  "Kuroiwa (Japan)",
  "Kurume (Japan)",
  "Kusai (Japan)",
  "Kusatsu (Japan)",
  "Kuse (Japan)",
  "Kushimoto (Japan)",
  "Kushiro (Japan)",
  "Kusunoki (Japan)",
  "Kutchan (Japan)",
  "Kuwana-shi (Japan)",
  "Kyodo (Japan)",
  "Kyoritsu (Japan)",
  "Kyoto (Japan)",
  "Kyowa (Japan)",
  "Machi (Japan)",
  "Machida (Japan)",
  "Machiya (Japan)",
  "Maebaru (Japan)",
  "Maebashi (Japan)",
  "Maibara (Japan)",
  "Maizuru (Japan)",
  "Maki (Japan)",
  "Makinohara (Japan)",
  "Makuhari (Japan)",
  "Marugame (Japan)",
  "Marunouchi (Japan)",
  "Maruyama (Japan)",
  "Masaki (Japan)",
  "Mashiko (Japan)",
  "Masuda (Japan)",
  "Matsubara (Japan)",
  "Matsudo (Japan)",
  "Matsue-shi (Japan)",
  "Matsukawamachi (Japan)",
  "Matsumoto (Japan)",
  "Matsusaka (Japan)",
  "Matsuto (Japan)",
  "Matsuyama (Japan)",
  "Meguro (Japan)",
  "Meiji (Japan)",
  "Mibu (Japan)",
  "Midori (Japan)",
  "Miemachi (Japan)",
  "Mihama (Japan)",
  "Mihara (Japan)",
  "Miki (Japan)",
  "Mima (Japan)",
  "Mimasaka (Japan)",
  "Minabe (Japan)",
  "Minamata (Japan)",
  "Minami (Japan)",
  "Minamiaoyama (Japan)",
  "Minamiashigara (Japan)",
  "Minamiuonuma (Japan)",
  "Minato (Japan)",
  "Minatogawa (Japan)",
  "Minatomachi (Japan)",
  "Mine (Japan)",
  "Mineyama (Japan)",
  "Mino (Japan)",
  "Minobu (Japan)",
  "Minokamo (Japan)",
  "Minoshima (Japan)",
  "Minowa (Japan)",
  "Misakicho (Japan)",
  "Misakimachi-konami (Japan)",
  "Misato (Japan)",
  "Misawa (Japan)",
  "Mishima (Japan)",
  "Mitai (Japan)",
  "Mitake (Japan)",
  "Mito-shi (Japan)",
  "Mitsucho-iwami (Japan)",
  "Mitsui (Japan)",
  "Mitsukaido (Japan)",
  "Mitsuke (Japan)",
  "Mitsushimacho-osaki (Japan)",
  "Miura (Japan)",
  "Miwa (Japan)",
  "Miyagi (Japan)",
  "Miyako (Japan)",
  "Miyakojima (Japan)",
  "Miyakonojo (Japan)",
  "Miyama (Japan)",
  "Miyata (Japan)",
  "Miyazaki-shi (Japan)",
  "Miyazu (Japan)",
  "Miyoshi (Japan)",
  "Mizugama (Japan)",
  "Mizuko (Japan)",
  "Mizunami (Japan)",
  "Mizusawa (Japan)",
  "Mizushima-nishichidoricho (Japan)",
  "Mobara (Japan)",
  "Mochida (Japan)",
  "Moji (Japan)",
  "Mombetsu (Japan)",
  "Momoishi (Japan)",
  "Monzenmachi-igisu (Japan)",
  "Mooka (Japan)",
  "Mori (Japan)",
  "Moriguchi (Japan)",
  "Morioka (Japan)",
  "Moriya (Japan)",
  "Moriyama (Japan)",
  "Morodomitsu (Japan)",
  "Morohongo (Japan)",
  "Morowa (Japan)",
  "Motegi (Japan)",
  "Motomiya (Japan)",
  "Muikamachi (Japan)",
  "Muko (Japan)",
  "Murai (Japan)",
  "Murakami (Japan)",
  "Murata (Japan)",
  "Murayama (Japan)",
  "Muroran (Japan)",
  "Muroto-misakicho (Japan)",
  "Musa (Japan)",
  "Musashimurayama (Japan)",
  "Musashino (Japan)",
  "Mutsu (Japan)",
  "Mutsumi (Japan)",
  "Nabari (Japan)",
  "Nada (Japan)",
  "Naebocho (Japan)",
  "Nagahama (Japan)",
  "Nagano-shi (Japan)",
  "Naganohara (Japan)",
  "Nagao (Japan)",
  "Nagaoka (Japan)",
  "Nagareyama (Japan)",
  "Nagasaki (Japan)",
  "Nagase (Japan)",
  "Nagashima (Japan)",
  "Nagato (Japan)",
  "Nagawado (Japan)",
  "Nago (Japan)",
  "Nagoya-shi (Japan)",
  "Naha (Japan)",
  "Naitobokujo (Japan)",
  "Naka (Japan)",
  "Nakada (Japan)",
  "Nakagawa (Japan)",
  "Nakagomi (Japan)",
  "Nakagusuku (Japan)",
  "Nakahai (Japan)",
  "Nakahata (Japan)",
  "Nakaichi (Japan)",
  "Nakama (Japan)",
  "Nakamura (Japan)",
  "Nakanishi (Japan)",
  "Nakano (Japan)",
  "Nakano-kami (Japan)",
  "Nakanoshima (Japan)",
  "Nakatsu (Japan)",
  "Nakatsugawa (Japan)",
  "Nakayama (Japan)",
  "Nakazawa (Japan)",
  "Nakijin (Japan)",
  "Namerikawa (Japan)",
  "Nanae (Japan)",
  "Nanao (Japan)",
  "Nanto (Japan)",
  "Nara (Japan)",
  "Narashino-shi (Japan)",
  "Narita (Japan)",
  "Narutocho-mitsuishi (Japan)",
  "Nasa (Japan)",
  "Nasukarasuyama (Japan)",
  "Natori-shi (Japan)",
  "Natsumi (Japan)",
  "Nayoro (Japan)",
  "Nemuro (Japan)",
  "Nerima-ku (Japan)",
  "Neyagawa (Japan)",
  "Nichinan (Japan)",
  "Nihommatsu (Japan)",
  "Niigata (Japan)",
  "Niihama (Japan)",
  "Niimi (Japan)",
  "Niitsu (Japan)",
  "Niiza (Japan)",
  "Nikaho (Japan)",
  "Ninohe (Japan)",
  "Ninomiya (Japan)",
  "Nirasaki (Japan)",
  "Nishi (Japan)",
  "Nishi-Tokyo-shi (Japan)",
  "Nishihara (Japan)",
  "Nishikawa (Japan)",
  "Nishikicho (Japan)",
  "Nishiko (Japan)",
  "Nishimachi (Japan)",
  "Nishime (Japan)",
  "Nishinomiya (Japan)",
  "Nishio (Japan)",
  "Nishiwaki (Japan)",
  "Nishiyama (Japan)",
  "Nisshin (Japan)",
  "Nita (Japan)",
  "Nobeoka (Japan)",
  "Nobitome (Japan)",
  "Noboribetsu (Japan)",
  "Noda (Japan)",
  "Nodani (Japan)",
  "Nogata (Japan)",
  "Noguchi (Japan)",
  "Nomimachi (Japan)",
  "Nomura (Japan)",
  "None-mura (Japan)",
  "Nonoichi (Japan)",
  "Nose (Japan)",
  "Noshiro (Japan)",
  "Numazu (Japan)",
  "Nyuzen (Japan)",
  "Oarai (Japan)",
  "Obama (Japan)",
  "Obanazawa (Japan)",
  "Obari (Japan)",
  "Obayashi (Japan)",
  "Obihiro (Japan)",
  "Obu (Japan)",
  "Obuse (Japan)",
  "Ochiai (Japan)",
  "Ochikawa (Japan)",
  "Odaira (Japan)",
  "Odawara (Japan)",
  "Odori (Japan)",
  "Ogaki (Japan)",
  "Ogawa (Japan)",
  "Ogi (Japan)",
  "Ogori-shimogo (Japan)",
  "Oguni (Japan)",
  "Ogura (Japan)",
  "Ohara (Japan)",
  "Oiso (Japan)",
  "Oiwake (Japan)",
  "Oizu (Japan)",
  "Ojiya (Japan)",
  "Okawa (Japan)",
  "Okawachi (Japan)",
  "Okaya (Japan)",
  "Okayama (Japan)",
  "Okazaki (Japan)",
  "Okegawa (Japan)",
  "Okinawa (Japan)",
  "Okubo (Japan)",
  "Okuchi (Japan)",
  "Okuma (Japan)",
  "Okuura (Japan)",
  "Omachi (Japan)",
  "Omaezaki (Japan)",
  "Omagari (Japan)",
  "Ome (Japan)",
  "Omihachiman (Japan)",
  "Omiya (Japan)",
  "Omiya-ku (Japan)",
  "Omori (Japan)",
  "Omura (Japan)",
  "Omuta (Japan)",
  "Onna (Japan)",
  "Ono (Japan)",
  "Onoda (Japan)",
  "Onogawa (Japan)",
  "Onojo (Japan)",
  "Onomichi (Japan)",
  "Onuma (Japan)",
  "Osaka (Japan)",
  "Osaki (Japan)",
  "Osawa (Japan)",
  "Oshima (Japan)",
  "Oshu (Japan)",
  "Ota (Japan)",
  "Otake (Japan)",
  "Otani (Japan)",
  "Otaru (Japan)",
  "Otawara (Japan)",
  "Otemachi (Japan)",
  "Otsukacho (Japan)",
  "Otsuki (Japan)",
  "Otsuku-kibi (Japan)",
  "owariasahi (Japan)",
  "Oyabe (Japan)",
  "Oyama (Japan)",
  "Oyamato (Japan)",
  "Ozu (Japan)",
  "Raiba (Japan)",
  "Rankoshi-cho (Japan)",
  "Rifu (Japan)",
  "Ritto (Japan)",
  "Roppongi (Japan)",
  "Ryugasaki (Japan)",
  "Ryuo (Japan)",
  "Sabae (Japan)",
  "Sado (Japan)",
  "Saga-shi (Japan)",
  "Sagae (Japan)",
  "Sagamihara-shi (Japan)",
  "Saidaiji-naka (Japan)",
  "Saijo (Japan)",
  "Saikai (Japan)",
  "Saiki (Japan)",
  "Saitama (Japan)",
  "Sakado (Japan)",
  "Sakae (Japan)",
  "Sakai (Japan)",
  "Sakaidecho (Japan)",
  "Sakaiminato (Japan)",
  "Sakata (Japan)",
  "Saku (Japan)",
  "Sakura (Japan)",
  "Sakuracho (Japan)",
  "Sakurai (Japan)",
  "Sakurajima-koikecho (Japan)",
  "Sakurakabu (Japan)",
  "Sandacho (Japan)",
  "Sango (Japan)",
  "Sanjo (Japan)",
  "Sanmu (Japan)",
  "Sanno (Japan)",
  "Sannohe (Japan)",
  "Sano (Japan)",
  "Sanuki (Japan)",
  "Sapporo (Japan)",
  "Sarugakucho (Japan)",
  "Sasaguri (Japan)",
  "Sasayama (Japan)",
  "Sasebo (Japan)",
  "Satocho-sato (Japan)",
  "Satte (Japan)",
  "Sawara (Japan)",
  "Sayama (Japan)",
  "Sayo (Japan)",
  "Seiro (Japan)",
  "Sekicho-furumaya (Japan)",
  "Senboku (Japan)",
  "Sendai (Japan)",
  "Senju (Japan)",
  "Sennan (Japan)",
  "Senriyama (Japan)",
  "Senzoku (Japan)",
  "Seta (Japan)",
  "Setagaya-ku (Japan)",
  "Seto (Japan)",
  "Settsu (Japan)",
  "Shiba-Kaigandori (Japan)",
  "Shibadaimon (Japan)",
  "Shibata (Japan)",
  "Shibaura (Japan)",
  "Shibukawa (Japan)",
  "Shichibancho (Japan)",
  "Shijonawate (Japan)",
  "Shikamaku-miya (Japan)",
  "Shiki (Japan)",
  "Shima (Japan)",
  "Shimabara (Japan)",
  "Shimada (Japan)",
  "Shimizukoji (Japan)",
  "Shimizumachi (Japan)",
  "Shimo-iino (Japan)",
  "Shimoda (Japan)",
  "Shimogo (Japan)",
  "Shimomeguro (Japan)",
  "Shimonoseki (Japan)",
  "Shimotoda (Japan)",
  "Shimotsucho-kominami (Japan)",
  "Shimotsuke (Japan)",
  "Shin (Japan)",
  "Shin-shigai (Japan)",
  "Shinagawa (Japan)",
  "Shingu (Japan)",
  "Shinjo (Japan)",
  "Shinjuku (Japan)",
  "Shinkawa (Japan)",
  "Shinmachi (Japan)",
  "Shinshiro (Japan)",
  "Shinshuku (Japan)",
  "Shinsuka (Japan)",
  "Shiobaru (Japan)",
  "Shiogama (Japan)",
  "Shiojiri (Japan)",
  "Shioya (Japan)",
  "Shirakawa-machi (Japan)",
  "Shiraoka (Japan)",
  "Shirayamamachi (Japan)",
  "Shiretoko (Japan)",
  "Shiroi (Japan)",
  "Shiroishi (Japan)",
  "Shirokane (Japan)",
  "Shisui (Japan)",
  "Shitama (Japan)",
  "Shizukuishi (Japan)",
  "Shizunai-misono (Japan)",
  "Shizuoka (Japan)",
  "Shobara (Japan)",
  "Shobusawa (Japan)",
  "Shoinmachi-koji (Japan)",
  "Shonaicho (Japan)",
  "Showa (Japan)",
  "Shuchi (Japan)",
  "Sodegaura (Japan)",
  "Sogabe-minamijo (Japan)",
  "Soja (Japan)",
  "Soka (Japan)",
  "Sone (Japan)",
  "Sonobe (Japan)",
  "Subashiri (Japan)",
  "Suda (Japan)",
  "Suginomecho (Japan)",
  "Sugito (Japan)",
  "Sugiyama (Japan)",
  "Suigetsu (Japan)",
  "Suijo (Japan)",
  "Suita (Japan)",
  "Sukagawa (Japan)",
  "Sukumo (Japan)",
  "Sumidacho (Japan)",
  "Sumiyocho-kawauchi (Japan)",
  "Sumiyoshi (Japan)",
  "Sumoto (Japan)",
  "Susaki (Japan)",
  "Susami (Japan)",
  "Susono (Japan)",
  "Suwa (Japan)",
  "Suzaka (Japan)",
  "Suzuka (Japan)",
  "Tachibana (Japan)",
  "Tadotsu (Japan)",
  "Taga (Japan)",
  "Tagajo-shi (Japan)",
  "Tagawa (Japan)",
  "Tahara (Japan)",
  "Taiji (Japan)",
  "Taiki (Japan)",
  "Tainoura (Japan)",
  "Tajimi (Japan)",
  "Tajiri (Japan)",
  "Takahama (Japan)",
  "Takahashi (Japan)",
  "Takaishi (Japan)",
  "Takaiwa (Japan)",
  "Takajo (Japan)",
  "Takajomachi (Japan)",
  "Takamatsu (Japan)",
  "Takamori (Japan)",
  "Takanawa (Japan)",
  "Takaoka (Japan)",
  "Takara (Japan)",
  "Takarazuka (Japan)",
  "Takasagocho-takasemachi (Japan)",
  "Takasaki (Japan)",
  "Takase (Japan)",
  "Takashima (Japan)",
  "Takasu (Japan)",
  "Takata (Japan)",
  "Takatsuki (Japan)",
  "Takayama (Japan)",
  "Takedamachi (Japan)",
  "Takemiya (Japan)",
  "Takeo (Japan)",
  "Taketoyo (Japan)",
  "Takicho-oda (Japan)",
  "Takikawa (Japan)",
  "Takinogawa (Japan)",
  "Tama (Japan)",
  "Tamachi (Japan)",
  "Tamagawa (Japan)",
  "Tamana (Japan)",
  "Tamano (Japan)",
  "Tamashima (Japan)",
  "Tamatsukuri (Japan)",
  "Tamura (Japan)",
  "Tanabe (Japan)",
  "Tanaka (Japan)",
  "Taniyama-chuo (Japan)",
  "Tanushimarumachi-sugawara (Japan)",
  "Tashima (Japan)",
  "Tashiro (Japan)",
  "Tatebayashi (Japan)",
  "Tateyama (Japan)",
  "Tatsumi (Japan)",
  "Tatsuno (Japan)",
  "Tatsunocho-tominaga (Japan)",
  "Tawaramoto (Japan)",
  "Teine-ku (Japan)",
  "Temma (Japan)",
  "Tendo (Japan)",
  "Tennojicho-kita (Japan)",
  "Tenri (Japan)",
  "Toba (Japan)",
  "Tobata (Japan)",
  "Tobe (Japan)",
  "Tobo (Japan)",
  "Tochigi (Japan)",
  "Togane (Japan)",
  "Toge (Japan)",
  "Togitsu (Japan)",
  "Tokai (Japan)",
  "Tokamachi (Japan)",
  "Toki (Japan)",
  "Tokiwa (Japan)",
  "Tokiwamachi-tokiwa (Japan)",
  "Tokoname (Japan)",
  "Tokorozawa (Japan)",
  "Tokushima (Japan)",
  "Tokuyama (Japan)",
  "Tokyo (Japan)",
  "Tomakomai (Japan)",
  "Tomioka (Japan)",
  "Tomisato (Japan)",
  "Tomiya (Japan)",
  "Tomo (Japan)",
  "Tomobe (Japan)",
  "Tonami (Japan)",
  "Tondabayashi (Japan)",
  "Tone (Japan)",
  "Tono (Japan)",
  "Toranomon (Japan)",
  "Toride (Japan)",
  "Toshima Mura (Japan)",
  "Tosu (Japan)",
  "Totsukacho (Japan)",
  "Tottori-shi (Japan)",
  "Towada (Japan)",
  "Toyama-shi (Japan)",
  "Toyo (Japan)",
  "Toyoake (Japan)",
  "Toyoda (Japan)",
  "Toyohashi (Japan)",
  "Toyohira-ku (Japan)",
  "Toyokawa (Japan)",
  "Toyonaka (Japan)",
  "Toyonomachi-toyono (Japan)",
  "Toyooka (Japan)",
  "Toyota (Japan)",
  "Tsu (Japan)",
  "Tsubame (Japan)",
  "Tsubata (Japan)",
  "Tsuchiara (Japan)",
  "Tsuchiura (Japan)",
  "Tsudanuma (Japan)",
  "Tsuhako (Japan)",
  "Tsukawaki (Japan)",
  "Tsukayama (Japan)",
  "Tsukuba (Japan)",
  "Tsukubamirai (Japan)",
  "Tsukumiura (Japan)",
  "Tsuru (Japan)",
  "Tsuruga (Japan)",
  "Tsurugashima (Japan)",
  "Tsurumi (Japan)",
  "Tsuruoka (Japan)",
  "Tsushima (Japan)",
  "Tsuyama (Japan)",
  "Ube (Japan)",
  "Uchimaru (Japan)",
  "Ueda, Nagano (Japan)",
  "Ueki (Japan)",
  "Ueno (Japan)",
  "Ueno-ebisumachi (Japan)",
  "Uenohara (Japan)",
  "Uji (Japan)",
  "Ujiie (Japan)",
  "Uki (Japan)",
  "Umeda (Japan)",
  "Uonuma (Japan)",
  "Uozu (Japan)",
  "Urasoe (Japan)",
  "Urayama (Japan)",
  "Urayasu (Japan)",
  "Ureshino (Japan)",
  "Uruma (Japan)",
  "Usa (Japan)",
  "Ushiku (Japan)",
  "Usuki (Japan)",
  "Utazu (Japan)",
  "Utsunomiya (Japan)",
  "Uwajima (Japan)",
  "Uwayama (Japan)",
  "Wadayama (Japan)",
  "Wajima (Japan)",
  "Wakamatsucho (Japan)",
  "Wakasa (Japan)",
  "Wakayama (Japan)",
  "Wakicho (Japan)",
  "Wakimachi (Japan)",
  "Wakkanai (Japan)",
  "Wako (Japan)",
  "Warabi-shi (Japan)",
  "Waseda (Japan)",
  "Watari (Japan)",
  "Yabase (Japan)",
  "Yabasehoncho (Japan)",
  "Yabu (Japan)",
  "Yabuki (Japan)",
  "Yachimata (Japan)",
  "Yachiyo (Japan)",
  "Yaese (Japan)",
  "Yahagi (Japan)",
  "Yahata (Japan)",
  "Yaita (Japan)",
  "Yaizu (Japan)",
  "Yakage (Japan)",
  "Yakuin-Horibata (Japan)",
  "Yakumo (Japan)",
  "Yamada (Japan)",
  "Yamadayu (Japan)",
  "Yamaga (Japan)",
  "Yamagata-shi (Japan)",
  "Yamaguchi (Japan)",
  "Yamakita (Japan)",
  "Yamanashi (Japan)",
  "Yamano (Japan)",
  "Yamashitacho (Japan)",
  "Yamato-Takada (Japan)",
  "Yamatomachi (Japan)",
  "Yamatomachi-mitsuai (Japan)",
  "Yamauchi (Japan)",
  "Yamawaki (Japan)",
  "Yamazaki (Japan)",
  "Yamazakicho-nakabirose (Japan)",
  "Yanagawa (Japan)",
  "Yanagi (Japan)",
  "Yanai (Japan)",
  "Yano (Japan)",
  "Yanocho-futatsugi (Japan)",
  "Yao (Japan)",
  "Yashima (Japan)",
  "Yashio-shi (Japan)",
  "Yasu-shi (Japan)",
  "Yasuda (Japan)",
  "Yasugicho (Japan)",
  "Yatsushiro (Japan)",
  "Yawata (Japan)",
  "Yodogawa (Japan)",
  "Yoichi (Japan)",
  "Yokaichiba (Japan)",
  "Yokkaichi (Japan)",
  "Yokogawa (Japan)",
  "Yokohama (Japan)",
  "Yokoo (Japan)",
  "Yokosuka (Japan)",
  "Yokotemachi (Japan)",
  "Yomitan Son (Japan)",
  "Yomogita (Japan)",
  "Yonago (Japan)",
  "Yoneyamacho-nishino (Japan)",
  "Yonezawa (Japan)",
  "Yorii (Japan)",
  "Yorishimacho (Japan)",
  "Yoshida (Japan)",
  "Yoshida-kasugacho (Japan)",
  "Yoshii (Japan)",
  "Yoshikawa (Japan)",
  "Yoshimi (Japan)",
  "Yoshino (Japan)",
  "Yotsukaido (Japan)",
  "Youkaichi (Japan)",
  "Yubari (Japan)",
  "Yufutsu (Japan)",
  "Yugawara (Japan)",
  "Yuge (Japan)",
  "Yuki (Japan)",
  "Yukuhashi (Japan)",
  "Yunoshima (Japan)",
  "Yurihonjo (Japan)",
  "Yutaka (Japan)",
  "Yuzawa (Japan)",
  "Zama (Japan)",
  "Zushi (Japan)",
  "Kazakhstan",
  "Aksay (Kazakhstan)",
  "Aksoran (Kazakhstan)",
  "Almaty (Kazakhstan)",
  "Aqtas (Kazakhstan)",
  "Aqtau (Kazakhstan)",
  "Astana (Kazakhstan)",
  "Atyrau (Kazakhstan)",
  "Baikonur (Kazakhstan)",
  "Dostyk (Kazakhstan)",
  "Dzhezkazgan (Kazakhstan)",
  "Ekibastuz (Kazakhstan)",
  "Esil (Kazakhstan)",
  "Karagandy (Kazakhstan)",
  "Kazakh (Kazakhstan)",
  "Kentau (Kazakhstan)",
  "Koktem (Kazakhstan)",
  "Kostanay (Kazakhstan)",
  "Kyzyl (Kazakhstan)",
  "Kyzylorda (Kazakhstan)",
  "Lomonosovka (Kazakhstan)",
  "Oral (Kazakhstan)",
  "Pavlodar (Kazakhstan)",
  "Petropavl (Kazakhstan)",
  "Qaraghandy (Kazakhstan)",
  "Qaskeleng (Kazakhstan)",
  "Ridder (Kazakhstan)",
  "Rudnyy (Kazakhstan)",
  "Semey (Kazakhstan)",
  "Serebryansk (Kazakhstan)",
  "Shymkent (Kazakhstan)",
  "Sonaly (Kazakhstan)",
  "Stepnogorsk (Kazakhstan)",
  "Taldyk (Kazakhstan)",
  "Taldykorgan (Kazakhstan)",
  "Talghar (Kazakhstan)",
  "Taraz (Kazakhstan)",
  "Temirtau (Kazakhstan)",
  "Turkestan (Kazakhstan)",
  "Uritskiy (Kazakhstan)",
  "Ust-Kamenogorsk (Kazakhstan)",
  "Vostok (Kazakhstan)",
  "Zyryanovsk (Kazakhstan)",
  "Kenya",
  "Bondo (Kenya)",
  "Chuka (Kenya)",
  "Eldoret (Kenya)",
  "Kabete (Kenya)",
  "Kaiboi (Kenya)",
  "Karatina (Kenya)",
  "Kiambu (Kenya)",
  "Kikuyu (Kenya)",
  "Kisii (Kenya)",
  "Kisumu (Kenya)",
  "Kitale (Kenya)",
  "Kitui (Kenya)",
  "Machakos (Kenya)",
  "Maseno (Kenya)",
  "Mbita (Kenya)",
  "Mombasa (Kenya)",
  "Nairobi (Kenya)",
  "Nakuru (Kenya)",
  "Sawa Sawa (Kenya)",
  "Siaya (Kenya)",
  "Thika (Kenya)",
  "Turbo (Kenya)",
  "Wote (Kenya)",
  "Kosovo",
  "Dunavo (Kosovo)",
  "Ferizaj (Kosovo)",
  "Mitrovica (Kosovo)",
  "Pristina (Kosovo)",
  "Prizren (Kosovo)",
  "Kuwait",
  "Abraq Khaytan (Kuwait)",
  "Ad Dasmah (Kuwait)",
  "Ad Dawhah (Kuwait)",
  "Al Ahmadi (Kuwait)",
  "Al Farwaniyah (Kuwait)",
  "Al Shamiya (Kuwait)",
  "Ar Rawdah (Kuwait)",
  "As Salimiyah (Kuwait)",
  "Ash Shu`aybah (Kuwait)",
  "Ash Shuwaykh (Kuwait)",
  "Bayan (Kuwait)",
  "Hawalli (Kuwait)",
  "Janub as Surrah (Kuwait)",
  "Kayfan (Kuwait)",
  "Kuwait City (Kuwait)",
  "Salwa (Kuwait)",
  "Latvia",
  "Adazi (Latvia)",
  "Agenskalns (Latvia)",
  "Aizkraukle (Latvia)",
  "Aizpute (Latvia)",
  "Baldone (Latvia)",
  "Balvi (Latvia)",
  "Bauska (Latvia)",
  "Brankas (Latvia)",
  "Carnikava (Latvia)",
  "Centrs (Latvia)",
  "Daugavpils (Latvia)",
  "Dobele (Latvia)",
  "Durbe (Latvia)",
  "Gulbene (Latvia)",
  "Ilguciems (Latvia)",
  "Izvalta (Latvia)",
  "Jaunolaine (Latvia)",
  "Jelgava (Latvia)",
  "Kandava (Latvia)",
  "Kolka (Latvia)",
  "Lapmezciems (Latvia)",
  "Liepa (Latvia)",
  "Ludza (Latvia)",
  "Madona (Latvia)",
  "Malpils (Latvia)",
  "Malta (Latvia)",
  "Marupe (Latvia)",
  "Mazsalaca (Latvia)",
  "Ogre (Latvia)",
  "Olaine (Latvia)",
  "Ozolnieki (Latvia)",
  "Pilsrundale (Latvia)",
  "Priedkalne (Latvia)",
  "Pure (Latvia)",
  "Riga (Latvia)",
  "Salaspils (Latvia)",
  "Saldus (Latvia)",
  "Sigulda (Latvia)",
  "Smiltene (Latvia)",
  "Stalgene (Latvia)",
  "Talsi (Latvia)",
  "Tukums (Latvia)",
  "Valka (Latvia)",
  "Valmiera (Latvia)",
  "Varsava (Latvia)",
  "Ventspils (Latvia)",
  "Zasa (Latvia)",
  "Zilupe (Latvia)",
  "Lebanon",
  "Aaley (Lebanon)",
  "Adma (Lebanon)",
  "Ashrafiye (Lebanon)",
  "Baabda (Lebanon)",
  "Baalbek (Lebanon)",
  "Beirut (Lebanon)",
  "Broummana (Lebanon)",
  "Bsalim (Lebanon)",
  "Chekka (Lebanon)",
  "Dbaiye (Lebanon)",
  "Dik el Mehdi (Lebanon)",
  "Halba (Lebanon)",
  "Hboub (Lebanon)",
  "Sarba (Lebanon)",
  "Sidon (Lebanon)",
  "Tripoli (Lebanon)",
  "Yanar (Lebanon)",
  "Zgharta (Lebanon)",
  "Libya",
  "Benghazi (Libya)",
  "Misratah (Libya)",
  "Sabha (Libya)",
  "Tripoli (Libya)",
  "Zliten (Libya)",
  "Liechtenstein",
  "Balzers (Liechtenstein)",
  "Bendern (Liechtenstein)",
  "Eschen (Liechtenstein)",
  "Gamprin (Liechtenstein)",
  "Mauren (Liechtenstein)",
  "Nendeln (Liechtenstein)",
  "Planken (Liechtenstein)",
  "Ruggell (Liechtenstein)",
  "Schaan (Liechtenstein)",
  "Schaanwald (Liechtenstein)",
  "Schellenberg (Liechtenstein)",
  "Triesen (Liechtenstein)",
  "Triesenberg (Liechtenstein)",
  "Vaduz (Liechtenstein)",
  "Luxembourg",
  "Ahn (Luxembourg)",
  "Alzingen (Luxembourg)",
  "Bascharage (Luxembourg)",
  "Beaufort (Luxembourg)",
  "Beckerich (Luxembourg)",
  "Beggen (Luxembourg)",
  "Beidweiler (Luxembourg)",
  "Belvaux (Luxembourg)",
  "Berchem (Luxembourg)",
  "Bereldange (Luxembourg)",
  "Bergem (Luxembourg)",
  "Bertrange (Luxembourg)",
  "Bettembourg (Luxembourg)",
  "Betzdorf (Luxembourg)",
  "Bigonville (Luxembourg)",
  "Bissen (Luxembourg)",
  "Bivange (Luxembourg)",
  "Bofferdange (Luxembourg)",
  "Bonnevoie (Luxembourg)",
  "Bourglinster (Luxembourg)",
  "Bridel (Luxembourg)",
  "Burmerange (Luxembourg)",
  "Canach (Luxembourg)",
  "Cap (Luxembourg)",
  "Capellen (Luxembourg)",
  "Colmar (Luxembourg)",
  "Contern (Luxembourg)",
  "Crauthem (Luxembourg)",
  "Dalheim (Luxembourg)",
  "Differdange (Luxembourg)",
  "Dippach (Luxembourg)",
  "Dudelange (Luxembourg)",
  "Echternach (Luxembourg)",
  "Ehnen (Luxembourg)",
  "Ernster (Luxembourg)",
  "Erpeldange-lez-Bous (Luxembourg)",
  "Esch-sur-Alzette (Luxembourg)",
  "Esch-sur-Sure (Luxembourg)",
  "Ettelbruck (Luxembourg)",
  "Foetz (Luxembourg)",
  "Frisange (Luxembourg)",
  "Godbrange (Luxembourg)",
  "Goetzingen (Luxembourg)",
  "Gonderange (Luxembourg)",
  "Gosseldange (Luxembourg)",
  "Gostingen (Luxembourg)",
  "Grevenknapp (Luxembourg)",
  "Grevenmacher (Luxembourg)",
  "Grosbous (Luxembourg)",
  "Hagen (Luxembourg)",
  "Hautcharage (Luxembourg)",
  "Heffingen (Luxembourg)",
  "Heisdorf-sur-Alzette (Luxembourg)",
  "Helmdange (Luxembourg)",
  "Helmsange (Luxembourg)",
  "Hersberg (Luxembourg)",
  "Hesperange (Luxembourg)",
  "Hobscheid (Luxembourg)",
  "Hollerich (Luxembourg)",
  "Holzem (Luxembourg)",
  "Huncherange (Luxembourg)",
  "Hunsdorf (Luxembourg)",
  "Itzig (Luxembourg)",
  "Junglinster (Luxembourg)",
  "Kayl (Luxembourg)",
  "Kehlen (Luxembourg)",
  "Kleinbettingen (Luxembourg)",
  "Koetschette (Luxembourg)",
  "Lamadelaine (Luxembourg)",
  "Larochette (Luxembourg)",
  "Leudelange (Luxembourg)",
  "Limpertsberg (Luxembourg)",
  "Lintgen (Luxembourg)",
  "Lorentzweiler (Luxembourg)",
  "Luxembourg (Luxembourg)",
  "Luxembourg-Kirchberg (Luxembourg)",
  "Mamer (Luxembourg)",
  "Medernach (Luxembourg)",
  "Merl (Luxembourg)",
  "Mersch (Luxembourg)",
  "Mertert (Luxembourg)",
  "Mondercange (Luxembourg)",
  "Mondorf-les-Bains (Luxembourg)",
  "Moutfort (Luxembourg)",
  "Niedercorn (Luxembourg)",
  "Noerdange (Luxembourg)",
  "Nospelt (Luxembourg)",
  "Oberanven (Luxembourg)",
  "Oetrange (Luxembourg)",
  "Olm (Luxembourg)",
  "Pontpierre (Luxembourg)",
  "Remerschen (Luxembourg)",
  "Remich (Luxembourg)",
  "Rodange (Luxembourg)",
  "Roeser (Luxembourg)",
  "Roodt-sur-Syre (Luxembourg)",
  "Rumelange (Luxembourg)",
  "Sandweiler (Luxembourg)",
  "Sanem (Luxembourg)",
  "Schieren (Luxembourg)",
  "Schifflange (Luxembourg)",
  "Schoos (Luxembourg)",
  "Schouweiler (Luxembourg)",
  "Schuttrange (Luxembourg)",
  "Soleuvre (Luxembourg)",
  "Steinfort (Luxembourg)",
  "Steinsel (Luxembourg)",
  "Strassen (Luxembourg)",
  "Syren (Luxembourg)",
  "Tetange (Luxembourg)",
  "Tuntange (Luxembourg)",
  "Uebersyren (Luxembourg)",
  "Walferdange (Luxembourg)",
  "Wasserbillig (Luxembourg)",
  "Wolpert (Luxembourg)",
  "Wormeldange (Luxembourg)",
  "Macedonia",
  "Berovo (Macedonia)",
  "Bitola (Macedonia)",
  "Bogdanci (Macedonia)",
  "Cair (Macedonia)",
  "Debar (Macedonia)",
  "Delcevo (Macedonia)",
  "Demir Hisar (Macedonia)",
  "Gevgelija (Macedonia)",
  "Gostivar (Macedonia)",
  "Ilinden (Macedonia)",
  "Kadino (Macedonia)",
  "Kavadarci (Macedonia)",
  "Kicevo (Macedonia)",
  "Kumanovo (Macedonia)",
  "Labunista (Macedonia)",
  "Lisice (Macedonia)",
  "Makedonska Kamenica (Macedonia)",
  "Negotino (Macedonia)",
  "Novo Selo (Macedonia)",
  "Ohrid (Macedonia)",
  "Prilep (Macedonia)",
  "Pripor (Macedonia)",
  "Probistip (Macedonia)",
  "Radovis (Macedonia)",
  "Shtip (Macedonia)",
  "Skopje (Macedonia)",
  "Struga (Macedonia)",
  "Strumica (Macedonia)",
  "Tetovo (Macedonia)",
  "Veles (Macedonia)",
  "Madagascar",
  "Ambanja (Madagascar)",
  "Antananarivo (Madagascar)",
  "Antsirabe (Madagascar)",
  "Antsiranana (Madagascar)",
  "Fianarantsoa (Madagascar)",
  "Toamasina (Madagascar)",
  "Toliara (Madagascar)",
  "Malaysia",
  "Alor Gajah (Malaysia)",
  "Alor Star (Malaysia)",
  "Ampang (Malaysia)",
  "Ayer Itam (Malaysia)",
  "Ayer Tawar (Malaysia)",
  "Bachok (Malaysia)",
  "Bagan Serai (Malaysia)",
  "Bahau (Malaysia)",
  "Balik Pulau (Malaysia)",
  "Bandar (Malaysia)",
  "Bandar Baru Bangi (Malaysia)",
  "Bandar Tenggara (Malaysia)",
  "Bandar Tun Razak (Malaysia)",
  "Bangi (Malaysia)",
  "Banting (Malaysia)",
  "Baru (Malaysia)",
  "Batang Berjuntai (Malaysia)",
  "Batang Kali (Malaysia)",
  "Batu (Malaysia)",
  "Batu Arang (Malaysia)",
  "Batu Berendam (Malaysia)",
  "Batu Caves (Malaysia)",
  "Batu Feringgi (Malaysia)",
  "Batu Gajah (Malaysia)",
  "Batu Pahat (Malaysia)",
  "Bau (Malaysia)",
  "Bayan Lepas (Malaysia)",
  "Beaufort (Malaysia)",
  "Bedong (Malaysia)",
  "Bentung (Malaysia)",
  "Benut (Malaysia)",
  "Bidur (Malaysia)",
  "Bintulu (Malaysia)",
  "Bongawan (Malaysia)",
  "Broga (Malaysia)",
  "Bukit Asahan (Malaysia)",
  "Bukit Gambir (Malaysia)",
  "Bukit Kayu Hitam (Malaysia)",
  "Bukit Mertajam (Malaysia)",
  "Bukit Pasir (Malaysia)",
  "Bukit Rotan (Malaysia)",
  "Butterworth (Malaysia)",
  "Cameron Highlands (Malaysia)",
  "Changloon (Malaysia)",
  "Chemor (Malaysia)",
  "Cheras (Malaysia)",
  "Cukai (Malaysia)",
  "Cyberjaya (Malaysia)",
  "Damansara (Malaysia)",
  "Dengkil (Malaysia)",
  "Dungun (Malaysia)",
  "Durian Tunggal (Malaysia)",
  "Gebeng (Malaysia)",
  "Gelang Patah (Malaysia)",
  "Gemas (Malaysia)",
  "Genting (Malaysia)",
  "George Town (Malaysia)",
  "Gerik (Malaysia)",
  "Gopeng (Malaysia)",
  "Gua Musang (Malaysia)",
  "Gurun (Malaysia)",
  "Hulu Langat (Malaysia)",
  "Hutan Melintang (Malaysia)",
  "Inanam (Malaysia)",
  "Ipoh (Malaysia)",
  "Itam (Malaysia)",
  "Jasin (Malaysia)",
  "Jawi (Malaysia)",
  "Jelutong (Malaysia)",
  "Jenjarum (Malaysia)",
  "Jeram (Malaysia)",
  "Jerantut (Malaysia)",
  "Jertih (Malaysia)",
  "Jinjang (Malaysia)",
  "Jitra (Malaysia)",
  "Johor Bahru (Malaysia)",
  "Juasseh (Malaysia)",
  "Kajang (Malaysia)",
  "Kampar (Malaysia)",
  "Kampong Changkat (Malaysia)",
  "Kampong Kahang Batu Twenty-eight (Malaysia)",
  "Kampong Kemaman (Malaysia)",
  "Kampong Pasir Segambut (Malaysia)",
  "Kampung Ayer Keroh (Malaysia)",
  "Kampung Baharu Nilai (Malaysia)",
  "Kampung Batu Kikir (Malaysia)",
  "Kampung Batu Uban (Malaysia)",
  "Kampung Bota Kiri (Malaysia)",
  "Kampung Likas (Malaysia)",
  "Kampung Paya Simpang Empat Genting (Malaysia)",
  "Kampung Pekan Kechil Beranang (Malaysia)",
  "Kampung Tanjung Aru (Malaysia)",
  "Kampung Tasek (Malaysia)",
  "Kampung Teluk Baru (Malaysia)",
  "Kampung Teriang (Malaysia)",
  "Kamunting (Malaysia)",
  "Kangar (Malaysia)",
  "Kapar (Malaysia)",
  "Karak (Malaysia)",
  "Keningau (Malaysia)",
  "Kepala Batas (Malaysia)",
  "Kepong (Malaysia)",
  "Keramat Pulai (Malaysia)",
  "Kerling (Malaysia)",
  "Kertih (Malaysia)",
  "Ketereh (Malaysia)",
  "Klang (Malaysia)",
  "Kluang (Malaysia)",
  "Kompleks Sukan Tenom (Malaysia)",
  "Kota (Malaysia)",
  "Kota Baharu (Malaysia)",
  "Kota Belud (Malaysia)",
  "Kota Bharu (Malaysia)",
  "Kota Kinabalu (Malaysia)",
  "Kota Tinggi (Malaysia)",
  "Kuah (Malaysia)",
  "Kuala (Malaysia)",
  "Kuala Berang (Malaysia)",
  "Kuala Kangsar (Malaysia)",
  "Kuala Ketil (Malaysia)",
  "Kuala Klawang (Malaysia)",
  "Kuala Kubu Baharu (Malaysia)",
  "Kuala Lipis (Malaysia)",
  "Kuala Lumpur (Malaysia)",
  "Kuala Nerang (Malaysia)",
  "Kuala Pahang (Malaysia)",
  "Kuala Penyu (Malaysia)",
  "Kuala Pilah (Malaysia)",
  "Kuala Selangor (Malaysia)",
  "Kuala Terengganu (Malaysia)",
  "Kuang (Malaysia)",
  "Kuantan (Malaysia)",
  "Kubang Kerian (Malaysia)",
  "Kuching (Malaysia)",
  "Kulai (Malaysia)",
  "Kulim (Malaysia)",
  "Labis (Malaysia)",
  "Labu (Malaysia)",
  "Lahad Datu (Malaysia)",
  "Lahat (Malaysia)",
  "Langat (Malaysia)",
  "Layang Layang (Malaysia)",
  "Lenggeng (Malaysia)",
  "Lintang (Malaysia)",
  "Lukut (Malaysia)",
  "Lumut (Malaysia)",
  "Lunas (Malaysia)",
  "Machang (Malaysia)",
  "Malacca (Malaysia)",
  "Mantin (Malaysia)",
  "Marang (Malaysia)",
  "Masai (Malaysia)",
  "Masjid Tanah (Malaysia)",
  "Melaka Tengah (Malaysia)",
  "Melor (Malaysia)",
  "Membakut (Malaysia)",
  "Menggatal (Malaysia)",
  "Menglembu (Malaysia)",
  "Mentekab (Malaysia)",
  "Merbok (Malaysia)",
  "Merlimau (Malaysia)",
  "Miri (Malaysia)",
  "Muadzam Shah (Malaysia)",
  "Muar town (Malaysia)",
  "Mukah (Malaysia)",
  "Nibong Tebal (Malaysia)",
  "Padang Rengas (Malaysia)",
  "Padang Serai (Malaysia)",
  "Pandan (Malaysia)",
  "Pantai (Malaysia)",
  "Pantai Remis (Malaysia)",
  "Papar (Malaysia)",
  "Parit (Malaysia)",
  "Parit Buntar (Malaysia)",
  "Parit Raja (Malaysia)",
  "Pasir Gudang (Malaysia)",
  "Pasir Mas (Malaysia)",
  "Pasir Puteh (Malaysia)",
  "Pedas (Malaysia)",
  "Pekan (Malaysia)",
  "Penaga (Malaysia)",
  "Penampang (Malaysia)",
  "Pendang (Malaysia)",
  "Pengerang (Malaysia)",
  "Pengkalan Chepa (Malaysia)",
  "Pengkalan Kempas (Malaysia)",
  "Perai (Malaysia)",
  "Permatang Pauh (Malaysia)",
  "Petaling Jaya (Malaysia)",
  "Pontian Kechil (Malaysia)",
  "Port Dickson (Malaysia)",
  "Port Klang (Malaysia)",
  "Puchong Batu Dua Belas (Malaysia)",
  "Pudu (Malaysia)",
  "Pudu Ulu (Malaysia)",
  "Pulau Pinang (Malaysia)",
  "Pusing (Malaysia)",
  "Putrajaya (Malaysia)",
  "Ranau (Malaysia)",
  "Raub (Malaysia)",
  "Rawang (Malaysia)",
  "Rembau (Malaysia)",
  "Rengam (Malaysia)",
  "Rengit (Malaysia)",
  "Sabak Bernam (Malaysia)",
  "Sandakan (Malaysia)",
  "Sarikei (Malaysia)",
  "Seberang Jaya (Malaysia)",
  "Segamat (Malaysia)",
  "Selama (Malaysia)",
  "Semenyih (Malaysia)",
  "Semerah (Malaysia)",
  "Senggarang (Malaysia)",
  "Sentul (Malaysia)",
  "Sepang (Malaysia)",
  "Serdang (Malaysia)",
  "Seremban (Malaysia)",
  "Serendah (Malaysia)",
  "Seri Kembangan (Malaysia)",
  "Seri Manjung (Malaysia)",
  "Setapak (Malaysia)",
  "Shah Alam (Malaysia)",
  "Sibu (Malaysia)",
  "Simpang Empat (Malaysia)",
  "Simpang Pertang (Malaysia)",
  "Simpang Renggam (Malaysia)",
  "Simpang Tiga (Malaysia)",
  "Simunjan (Malaysia)",
  "Sitiawan (Malaysia)",
  "Slim River (Malaysia)",
  "Sungai Bakap (Malaysia)",
  "Sungai Besar (Malaysia)",
  "Sungai Besi (Malaysia)",
  "Sungai Buluh (Malaysia)",
  "Sungai Pelek (Malaysia)",
  "Sungai Petani (Malaysia)",
  "Sungai Puyu (Malaysia)",
  "Sungai Udang (Malaysia)",
  "Taiping (Malaysia)",
  "Taman Bukit Skudai (Malaysia)",
  "Taman Desa (Malaysia)",
  "Taman Kota Jaya (Malaysia)",
  "Taman Megah Ria (Malaysia)",
  "Taman Prai (Malaysia)",
  "Taman Senai (Malaysia)",
  "Taman Senawang Indah (Malaysia)",
  "Taman Seremban Jaya (Malaysia)",
  "Taman Sri Ampang (Malaysia)",
  "Tambunan (Malaysia)",
  "Tamparuli (Malaysia)",
  "Tampin (Malaysia)",
  "Tampoi (Malaysia)",
  "Tanah Merah (Malaysia)",
  "Tanah Merah New Village Site C (Malaysia)",
  "Tangkak (Malaysia)",
  "Tanjong (Malaysia)",
  "Tanjong Karang (Malaysia)",
  "Tanjong Malim (Malaysia)",
  "Tanjung (Malaysia)",
  "Tanjung Bunga (Malaysia)",
  "Tanjung Sepat (Malaysia)",
  "Tapah Road (Malaysia)",
  "Tasek Glugor (Malaysia)",
  "Tatau (Malaysia)",
  "Tawau (Malaysia)",
  "Teluk Intan (Malaysia)",
  "Teluk Panglima Garang (Malaysia)",
  "Temerluh (Malaysia)",
  "Temoh (Malaysia)",
  "Teriang (Malaysia)",
  "Teronoh (Malaysia)",
  "Tumpat (Malaysia)",
  "Ulu Tiram (Malaysia)",
  "Victoria (Malaysia)",
  "Malta",
  "Attard (Malta)",
  "Balzan (Malta)",
  "Bingemma (Malta)",
  "Birgu (Malta)",
  "Birkirkara (Malta)",
  "Bugibba (Malta)",
  "Cospicua (Malta)",
  "Dingli (Malta)",
  "Fgura (Malta)",
  "Floriana (Malta)",
  "Fontana (Malta)",
  "Ghajnsielem (Malta)",
  "Gharb (Malta)",
  "Ghasri (Malta)",
  "Ghaxaq (Malta)",
  "Gudja (Malta)",
  "Gzira (Malta)",
  "Hal Gharghur (Malta)",
  "Hamrun (Malta)",
  "Haz-Zebbug (Malta)",
  "Il-Madliena (Malta)",
  "Il-Pergla (Malta)",
  "Imsida (Malta)",
  "Imtarfa (Malta)",
  "Iz-Zebbiegh (Malta)",
  "Kirkop (Malta)",
  "L-Iklin (Malta)",
  "L-Iskorvit (Malta)",
  "Lija (Malta)",
  "Luqa (Malta)",
  "Manikata (Malta)",
  "Marsa (Malta)",
  "Marsalforn (Malta)",
  "Marsaskala (Malta)",
  "Marsaxlokk (Malta)",
  "Mdina (Malta)",
  "Mellieha (Malta)",
  "Mosta (Malta)",
  "Mqabba (Malta)",
  "Munxar (Malta)",
  "Nadur (Malta)",
  "Naxxar (Malta)",
  "Paola (Malta)",
  "Pembroke (Malta)",
  "Qormi (Malta)",
  "Qrendi (Malta)",
  "Rabat (Malta)",
  "Safi (Malta)",
  "Saint John (Malta)",
  "Saint Lawrence (Malta)",
  "Saint Lucia (Malta)",
  "Saint Venera (Malta)",
  "San Pawl il-Bahar (Malta)",
  "Sannat (Malta)",
  "Senglea (Malta)",
  "Siggiewi (Malta)",
  "Sliema (Malta)",
  "Swieqi (Malta)",
  "Tarxien (Malta)",
  "Valletta (Malta)",
  "Victoria (Malta)",
  "Xaghra (Malta)",
  "Xewkija (Malta)",
  "Zabbar (Malta)",
  "Zejtun (Malta)",
  "Zurrieq (Malta)",
  "Martinique",
  "Case-Pilote (Martinique)",
  "Ducos (Martinique)",
  "Fort-de-France (Martinique)",
  "Le Carbet (Martinique)",
  "Le Diamant (Martinique)",
  "Le Francois (Martinique)",
  "Le Gros-Morne (Martinique)",
  "Le Lamentin (Martinique)",
  "Le Morne-Rouge (Martinique)",
  "Le Robert (Martinique)",
  "Les Trois-Ilets (Martinique)",
  "Riviere-Salee (Martinique)",
  "Saint-Esprit (Martinique)",
  "Saint-Joseph (Martinique)",
  "Sainte-Anne (Martinique)",
  "Sainte-Luce (Martinique)",
  "Sainte-Marie (Martinique)",
  "Schoelcher (Martinique)",
  "Mauritius",
  "Beau Bassin (Mauritius)",
  "Chemin Grenier (Mauritius)",
  "Ebene CyberCity (Mauritius)",
  "Floreal (Mauritius)",
  "Goodlands (Mauritius)",
  "Le Reduit (Mauritius)",
  "Port Louis (Mauritius)",
  "Port Mathurin (Mauritius)",
  "Quatre Bornes (Mauritius)",
  "Rose Hill (Mauritius)",
  "Saint Jean (Mauritius)",
  "Tamarin (Mauritius)",
  "Vacoas (Mauritius)",
  "Mayotte",
  "Bandaboa (Mayotte)",
  "Chiconi (Mayotte)",
  "Combani (Mayotte)",
  "Dzaoudzi (Mayotte)",
  "Dzoumonye (Mayotte)",
  "Koungou (Mayotte)",
  "Mamoudzou (Mayotte)",
  "Ouangani (Mayotte)",
  "Pamandzi (Mayotte)",
  "Sada (Mayotte)",
  "Tsingoni (Mayotte)",
  "Tsoundzou 1 (Mayotte)",
  "Mexico",
  "Abasolo (Mexico)",
  "Acambaro (Mexico)",
  "Acambay (Mexico)",
  "Acapulco (Mexico)",
  "Acatic (Mexico)",
  "Acatlan de Perez Figueroa (Mexico)",
  "Acatzingo de Hidalgo (Mexico)",
  "Acolman (Mexico)",
  "Actopan (Mexico)",
  "Acuna (Mexico)",
  "Adolfo Lopez Mateos (Mexico)",
  "Adolfo Ruiz Cortines (Mexico)",
  "Agua Amarga (Mexico)",
  "Agua Azul (Mexico)",
  "Agua Dulce (Mexico)",
  "Agua Prieta (Mexico)",
  "Aguascalientes (Mexico)",
  "Ahome (Mexico)",
  "Ahualulco de Mercado (Mexico)",
  "Ajijic (Mexico)",
  "Alamo (Mexico)",
  "Alamos (Mexico)",
  "Aldama (Mexico)",
  "Allende (Mexico)",
  "Almada (Mexico)",
  "Almoloya (Mexico)",
  "Altamira (Mexico)",
  "Altotonga (Mexico)",
  "Alvaro Obregon Borough (Mexico)",
  "Amado Nervo (Mexico)",
  "Ameca (Mexico)",
  "Amecameca de Juarez (Mexico)",
  "Anahuac (Mexico)",
  "Angamacutiro de la Union (Mexico)",
  "Apan (Mexico)",
  "Apaseo el Alto (Mexico)",
  "Apaseo el Grande (Mexico)",
  "Apatzingan (Mexico)",
  "Apaxco de Ocampo (Mexico)",
  "Apizaco (Mexico)",
  "Apodaca (Mexico)",
  "Arandas (Mexico)",
  "Arcelia (Mexico)",
  "Arizpe (Mexico)",
  "Arriaga (Mexico)",
  "Asuncion Ixtaltepec (Mexico)",
  "Asuncion Nochixtlan (Mexico)",
  "Atequiza (Mexico)",
  "Atizapan (Mexico)",
  "Atlacholoaya (Mexico)",
  "Atlacomulco (Mexico)",
  "Atlatlahucan (Mexico)",
  "Atlixco (Mexico)",
  "Atlzayanca (Mexico)",
  "Atotonilco el Alto (Mexico)",
  "Atoyac de Alvarez (Mexico)",
  "Autlan de Navarro (Mexico)",
  "Autopista Puebla Orizaba 185 (La Condusa) (Mexico)",
  "Axochiapan (Mexico)",
  "Azcapotzalco (Mexico)",
  "Azteca (Mexico)",
  "Badiraguato (Mexico)",
  "Bahia de Kino (Mexico)",
  "Baja California (Mexico)",
  "Balancan de Dominguez (Mexico)",
  "Bamoa (Mexico)",
  "Banderas del Aguila (Mexico)",
  "Banderilla (Mexico)",
  "Barra de Navidad (Mexico)",
  "Barranca de Santa Clara (Mexico)",
  "Barrio de Mexico (Mexico)",
  "Benito Juarez (Mexico)",
  "Benito Juarez (Santa Elena) (Mexico)",
  "Berriozabal (Mexico)",
  "Boca del Rio (Mexico)",
  "Brisas Barra de Suchiate (Mexico)",
  "Bucerias (Mexico)",
  "Cabo San Lucas (Mexico)",
  "Cabos (Mexico)",
  "Cadereyta (Mexico)",
  "Cadereyta de Montes (Mexico)",
  "Cadereyta Jimenez (Mexico)",
  "Cajeme (Mexico)",
  "Cajititlan (Mexico)",
  "Camargo (Mexico)",
  "Campeche (Mexico)",
  "Campestre Tarimbaro (Mexico)",
  "Cananea (Mexico)",
  "Canatlan (Mexico)",
  "Candelaria (Mexico)",
  "Cardenas (Mexico)",
  "Cardonal (Mexico)",
  "Catemaco (Mexico)",
  "Cazones de Herrera (Mexico)",
  "Celaya (Mexico)",
  "Centro (Mexico)",
  "Cerritos (Mexico)",
  "Chalco de Diaz Covarrubias (Mexico)",
  "Chapala (Mexico)",
  "Chetumal (Mexico)",
  "Chiapa de Corzo (Mexico)",
  "Chiapas (Mexico)",
  "Chiautempan (Mexico)",
  "Chiautla (Mexico)",
  "Chicago (Mexico)",
  "Chiconcuac (Mexico)",
  "Chignahuapan (Mexico)",
  "Chihuahua (Mexico)",
  "Chilpancingo (Mexico)",
  "Cholula (Mexico)",
  "Ciudad Ayala (Mexico)",
  "Ciudad Constitucion (Mexico)",
  "Ciudad de Huajuapam de Leon (Mexico)",
  "Ciudad de Villa de Alvarez (Mexico)",
  "Ciudad del Carmen (Mexico)",
  "Ciudad Hidalgo (Mexico)",
  "Ciudad Lopez Mateos (Mexico)",
  "Ciudad Madero (Mexico)",
  "Ciudad Mendoza (Mexico)",
  "Ciudad Nezahualcoyotl (Mexico)",
  "Ciudad Sabinas (Mexico)",
  "Ciudad Sahagun (Mexico)",
  "Ciudad Valles (Mexico)",
  "Ciudad Victoria (Mexico)",
  "Coacalco (Mexico)",
  "Coahuila (Mexico)",
  "Coatepec (Mexico)",
  "Coatzacoalcos (Mexico)",
  "Coatzintla (Mexico)",
  "Cocoyoc (Mexico)",
  "Cocula (Mexico)",
  "Colima (Mexico)",
  "Colimas (Mexico)",
  "Colon (Mexico)",
  "Colonia (Mexico)",
  "Colonia Benito Juarez (Mexico)",
  "Colonia Cuauhtemoc (Mexico)",
  "Colonia de Venado (Mexico)",
  "Colonia Emiliano Zapata (Ejido Santiago Temoaya) (Mexico)",
  "Colonia Guerrero (Los Guajes) (Mexico)",
  "Colonia Hidalgo (Mexico)",
  "Colonia la Presa (Ejido de Chapultepec) (Mexico)",
  "Colonia Lopez Nelio (Las Palmas) (Mexico)",
  "Colonia Madero (Madero) (Mexico)",
  "Colonia Mexico (Mexico)",
  "Colonia Miguel Aleman Valdez (Mexico)",
  "Colonia Veracruz (Mexico)",
  "Comalcalco (Mexico)",
  "Compostela (Mexico)",
  "Corregidora (Mexico)",
  "Cortazar (Mexico)",
  "Cosamaloapan de Carpio (Mexico)",
  "Cosoleacaque (Mexico)",
  "Coyoacan (Mexico)",
  "Coyuca de Catalan (Mexico)",
  "Cuajimalpa (Mexico)",
  "Cuarta Brigada (Mexico)",
  "Cuauhtemoc (Mexico)",
  "Cuautepec (Mexico)",
  "Cuautepec de Hinojosa (Mexico)",
  "Cuautitlan (Mexico)",
  "Cuautla (Mexico)",
  "Cuautlancingo (Mexico)",
  "Cuernavaca (Mexico)",
  "Cuitlahuac (Mexico)",
  "Cunduacan (Mexico)",
  "Cutzamala de Pinzon (Mexico)",
  "Cuyoaco (Mexico)",
  "Dolores Hidalgo Cuna de la Independencia Nacional (Mexico)",
  "Durango (Mexico)",
  "Ecatepec de Morelos (Mexico)",
  "Ejido de Otumba (Loma Bonita) (Mexico)",
  "Ejido Distrito Federal (Mexico)",
  "Ejido Santa Maria Tianguistenco (Ejido el Rosario) (Mexico)",
  "El Alto Culiacan (Mexico)",
  "El Carmen (Mexico)",
  "El Colli (Mexico)",
  "El Fuerte de Montes Claros (Mexico)",
  "El Grullo (Mexico)",
  "El Leon (Mexico)",
  "El Mirador (Mexico)",
  "El Ocotito (Mexico)",
  "El Parque (Mexico)",
  "El Potrero (Mexico)",
  "El Rio (Mexico)",
  "El Sabinal (San Pedro) (Mexico)",
  "El Salto (Mexico)",
  "El Sauz (Mexico)",
  "El Tecuan (Mexico)",
  "El Torreon (Mexico)",
  "Emiliano Zapata (Mexico)",
  "Empalme (Mexico)",
  "Ensenada (Mexico)",
  "Epazoyucan (Mexico)",
  "Erongaricuaro (Mexico)",
  "Escobedo (Mexico)",
  "Escondida de lo Verde (Mexico)",
  "Escuinapa de Hidalgo (Mexico)",
  "Escuintla (Mexico)",
  "Estado de Mexico (Mexico)",
  "Estados Unidos (Mexico)",
  "Ex-Hacienda Pantitlan (Mexico)",
  "Ezequiel Montes (Mexico)",
  "Florida (Mexico)",
  "Fortin de las Flores (Mexico)",
  "Fraccionamiento Iberica Calimaya (Mexico)",
  "Francisco Castellanos (Mexico)",
  "Francisco I. Madero (Mexico)",
  "Fresnillo (Mexico)",
  "Frontera (Mexico)",
  "Garcia (Mexico)",
  "Garza Garcia (Mexico)",
  "General Escobedo (Mexico)",
  "General Teran (Mexico)",
  "Gomez Palacio (Mexico)",
  "Graciano Sanchez (Mexico)",
  "Granjas (Mexico)",
  "Guadalajara (Mexico)",
  "Guadalupe (Mexico)",
  "Guadalupe Victoria (Mexico)",
  "Guadalupito (Mexico)",
  "Guamuchil (Mexico)",
  "Guanajuato (Mexico)",
  "Guasave (Mexico)",
  "Guaymas (Mexico)",
  "Gueramaro (Mexico)",
  "Gustavo A. Madero Borough (Mexico)",
  "Gutierrez Zamora (Mexico)",
  "Hermosillo (Mexico)",
  "Heroica Alvarado (Mexico)",
  "Heroica Caborca (Mexico)",
  "Heroica Coscomatepec de Bravo (Mexico)",
  "Heroica Matamoros (Mexico)",
  "Heroica Zitacuaro (Mexico)",
  "Huandacareo (Mexico)",
  "Huanimaro (Mexico)",
  "Huatabampo (Mexico)",
  "Huauchinango (Mexico)",
  "Huehuetoca (Mexico)",
  "Huejotzingo (Mexico)",
  "Huejucar (Mexico)",
  "Huichapan (Mexico)",
  "Huimanguillo (Mexico)",
  "Huimilpan (Mexico)",
  "Huixquilucan (Mexico)",
  "Huixtla (Mexico)",
  "Hunucma (Mexico)",
  "Iglesia Vieja (Mexico)",
  "Ignacio Allende (Mexico)",
  "Iguala de la Independencia (Mexico)",
  "Insurgentes (Mexico)",
  "Irapuato (Mexico)",
  "Isla Holbox (Mexico)",
  "Isla Mujeres (Mexico)",
  "Ixmiquilpan (Mexico)",
  "Ixtapa (Mexico)",
  "Ixtapa-Zihuatanejo (Mexico)",
  "Ixtapaluca (Mexico)",
  "Ixtapan de la Sal (Mexico)",
  "Ixtlahuaca de Villada (Mexico)",
  "Ixtlahuacan del Rio (Mexico)",
  "Ixtlan del Rio (Mexico)",
  "Izamal (Mexico)",
  "Iztacalco (Mexico)",
  "Iztapalapa (Mexico)",
  "Izucar de Matamoros (Mexico)",
  "Jacobo (Mexico)",
  "Jacona de Plancarte (Mexico)",
  "Jala (Mexico)",
  "Jalapa (Mexico)",
  "Jalisco (Mexico)",
  "Jalostotitlan (Mexico)",
  "Jalpa (Mexico)",
  "Jaltipan de Morelos (Mexico)",
  "Jalupa (Mexico)",
  "Jaumave (Mexico)",
  "Jerez de Garcia Salinas (Mexico)",
  "Jesus Carranza (Mexico)",
  "Jesus del Monte (Mexico)",
  "Jesus Maria (Mexico)",
  "Jilotepec (Mexico)",
  "Jimenez (Mexico)",
  "Jiquilpan de Juarez (Mexico)",
  "Jiquipilco (Mexico)",
  "Jiutepec (Mexico)",
  "Jocotepec (Mexico)",
  "Jocotitlan (Mexico)",
  "Jojutla (Mexico)",
  "Jonacatepec de Leandro Valle (Mexico)",
  "Jose Cardel (Mexico)",
  "Juan (Mexico)",
  "Juarez (Mexico)",
  "Kanasin (Mexico)",
  "Kilometro 32 Texcoco Lecheria (Mexico)",
  "La Barca (Mexico)",
  "La Canada (Mexico)",
  "La Ciudad (Mexico)",
  "La Cruz (Mexico)",
  "La Florida (Mexico)",
  "La Habana (Mexico)",
  "La Herradura (Mexico)",
  "La Isla (Mexico)",
  "La Joya (Mexico)",
  "La Moncada (Mexico)",
  "La Paletena (Mexico)",
  "La Paz (Mexico)",
  "La Piedad (Mexico)",
  "La Pista de Tizayuca (Mexico)",
  "La Reforma (Mexico)",
  "La Tapachula (Mexico)",
  "La Union (Mexico)",
  "La Victoria (Mexico)",
  "Lagos de Moreno (Mexico)",
  "Laredo (Mexico)",
  "Las Americas (Mexico)",
  "Las Cabanas (Colinas de los Angeles) (Mexico)",
  "Las Canoras (Altiva) (Mexico)",
  "Las Choapas (Mexico)",
  "Las Delicias (Mexico)",
  "Leon (Mexico)",
  "Lerdo (Mexico)",
  "Lerma (Mexico)",
  "Leyva Solano (Mexico)",
  "Libramiento Leon-Salamanca (Mexico)",
  "Libres (Mexico)",
  "Linares (Mexico)",
  "Loma de los Cavazos (Maria de la Luz) (Mexico)",
  "Loreto (Mexico)",
  "Los Garzas (Mexico)",
  "Los Mochis (Mexico)",
  "Los Reyes (Mexico)",
  "Los Reyes de Salgado (Mexico)",
  "Los Sabinos (Mexico)",
  "Luvianos (Mexico)",
  "Macuspana (Mexico)",
  "Madero (Mexico)",
  "Magdalena de Kino (Mexico)",
  "Magdalena Petlacalco (Mexico)",
  "Magiscatzin (Mexico)",
  "Malinalco (Mexico)",
  "Manuel Avila Camacho (Mexico)",
  "Manzanillo (Mexico)",
  "Mariano Matamoros (Mexico)",
  "Marquelia (Mexico)",
  "Martinez de la Torre (Mexico)",
  "Mazatecochco (Mexico)",
  "Mazatepec (Mexico)",
  "Mazatlan (Mexico)",
  "Medellin de Bravo (Mexico)",
  "Melchor (Mexico)",
  "Melchor Muzquiz (Mexico)",
  "Mendoza (Mexico)",
  "Mexicali (Mexico)",
  "Mexico City (Mexico)",
  "Mextepec (Mexico)",
  "Mier (Mexico)",
  "Miguel Aleman (Mexico)",
  "Miguel Hidalgo (Mexico)",
  "Mina Mexico (Mexico)",
  "Mineral (Mexico)",
  "Mineral de la Reforma (Mexico)",
  "Mineral del Chico (Mexico)",
  "Mineral del Monte (Mexico)",
  "Mixquiahuala de Juarez (Mexico)",
  "Moctezuma (Mexico)",
  "Monclova (Mexico)",
  "Monte Leon (Mexico)",
  "Montemorelos (Mexico)",
  "Monterrey (Mexico)",
  "Morelia (Mexico)",
  "Morelos (Mexico)",
  "Moroleon (Mexico)",
  "Motozintla de Mendoza (Mexico)",
  "Muna (Mexico)",
  "Nacozari Viejo (Mexico)",
  "Naucalpan (Mexico)",
  "Navojoa (Mexico)",
  "Navolato (Mexico)",
  "Netzahualcoyotl (Mexico)",
  "Nextlalpan (Mexico)",
  "Nicolas Romero (Mexico)",
  "Nogales (Mexico)",
  "Noxtepec de Zaragoza (El Aguacate) (Mexico)",
  "Nueva Italia de Ruiz (Mexico)",
  "Nuevo Casas Grandes (Mexico)",
  "Nuevo Coahuila (Mexico)",
  "Nuevo Ideal (Mexico)",
  "Nuevo Laredo (Mexico)",
  "Nuevo Leon (Mexico)",
  "Oaxaca (Mexico)",
  "Oaxtepec (Mexico)",
  "Ocotlan (Mexico)",
  "Ocoyoacac (Mexico)",
  "Ocozocoautla de Espinosa (Mexico)",
  "Ojinaga (Mexico)",
  "Ojo de Agua (Mexico)",
  "Ojuelos de Jalisco (Mexico)",
  "Orizaba (Mexico)",
  "Oxkutzkab (Mexico)",
  "Pabellon (Mexico)",
  "Pabellon de Arteaga (Mexico)",
  "Pachuca (Mexico)",
  "Pachuca de Soto (Mexico)",
  "Panotla (Mexico)",
  "Pantanal (Mexico)",
  "Paraiso (Mexico)",
  "Paraje Nuevo (Mexico)",
  "Parral (Mexico)",
  "Paseos de Tecamac (Mexico)",
  "Paso Real (Mexico)",
  "Pastor Ortiz (Mexico)",
  "Patzcuaro (Mexico)",
  "Pedregal de Jerico (Mexico)",
  "Pedro Escobedo (Mexico)",
  "Pedro Meoqui (Mexico)",
  "Pericos (Mexico)",
  "Perote (Mexico)",
  "Pesqueria (Mexico)",
  "Petaquillas (Mexico)",
  "Petatlan (Mexico)",
  "Pichucalco (Mexico)",
  "Piedras Negras (Mexico)",
  "Playa del Carmen (Mexico)",
  "Polanco (Mexico)",
  "Poncitlan (Mexico)",
  "Poza Rica Chacas (Mexico)",
  "Poza Rica de Hidalgo (Mexico)",
  "Primero de Mayo (Mexico)",
  "Progreso (Mexico)",
  "Prolongacion Avenida Mexico Norte (Mexico)",
  "Puebla (Mexico)",
  "Pueblo Hidalgo (Mexico)",
  "Pueblo Juarez (La Magdalena) (Mexico)",
  "Pueblo Viejo (Mexico)",
  "Pueblo Yaqui (Mexico)",
  "Puente de Ixtla (Mexico)",
  "Puente Grande (Mexico)",
  "Puerto Escondido (Mexico)",
  "Puerto Mexico (Mexico)",
  "Puerto Morelos (Mexico)",
  "Puerto Vallarta (Mexico)",
  "Purisima de Bustos (Mexico)",
  "Purisima del Zapote (Mexico)",
  "Puruandiro (Mexico)",
  "Queseria (Mexico)",
  "Quintana Roo (Mexico)",
  "Quiroga (Mexico)",
  "Ramos Arizpe (Mexico)",
  "Rancho Xalnene (Mexico)",
  "Rayon (Mexico)",
  "Reforma (Madrigal 2da. Seccion) (Mexico)",
  "Refractarios Guadalajara (Mexico)",
  "Reyes de Vallarta (Mexico)",
  "Reynosa (Mexico)",
  "Rincon de Tamayo (Mexico)",
  "Rio Blanco (Mexico)",
  "Rio Bravo (Mexico)",
  "Roma (Mexico)",
  "Rosarito (Mexico)",
  "Sahuayo de Morelos (Mexico)",
  "Salamanca (Mexico)",
  "Salina Cruz (Mexico)",
  "Salinas de Hidalgo (Mexico)",
  "Saltillo (Mexico)",
  "Salvatierra (Mexico)",
  "San Agustin (Mexico)",
  "San Agustin (Salvador Alvarado) (Mexico)",
  "San Agustin Altamirano (Mexico)",
  "San Agustin Tlaxiaca (Mexico)",
  "San Andres Cholula (Mexico)",
  "San Andres Tuxtla (Mexico)",
  "San Angel Inn (Mexico)",
  "San Antonio (Mexico)",
  "San Antonio la Isla (Mexico)",
  "San Antonio Virreyes (Mexico)",
  "San Bernardo (Mexico)",
  "San Cristobal (Mexico)",
  "San Felipe (Mexico)",
  "San Felipe Torres Mochas [Ganaderia] (Mexico)",
  "San Fernando (Mexico)",
  "San Francisco (Mexico)",
  "San Francisco Javier (Mexico)",
  "San Gabriel (Mexico)",
  "San Jeronimo de Juarez (Mexico)",
  "San Jose del Valle (Mexico)",
  "San Jose Iturbide (Mexico)",
  "San Juan (Mexico)",
  "San Juan Bautista Tuxtla (Mexico)",
  "San Juan de Aragon (Mexico)",
  "San Juan del Rio (Mexico)",
  "San Luis (Mexico)",
  "San Luis de la Paz (Mexico)",
  "San Luis Soyatlan (Mexico)",
  "San Martin de Camargo (Mexico)",
  "San Martin Duraznos (Mexico)",
  "San Martin Hidalgo (Mexico)",
  "San Martin Notario (Mexico)",
  "San Martin Texmelucan de Labastida (Mexico)",
  "San Mateo (Mexico)",
  "San Mateo Atenco (Mexico)",
  "San Mateo Mexicaltzingo (Mexico)",
  "San Miguel (Mexico)",
  "San Miguel Balderas (Mexico)",
  "San Miguel de Allende (Mexico)",
  "San Miguel de Atotonilco (Mexico)",
  "San Miguel de Cozumel (Mexico)",
  "San Miguel de Papasquiaro (Mexico)",
  "San Miguel el Alto (Mexico)",
  "San Miguel el Alto (El Puerto) (Mexico)",
  "San Miguel Zinacantepec (Mexico)",
  "San Nicolas (Mexico)",
  "San Nicolas Totolapan (Mexico)",
  "San Pablo Hidalgo (Mexico)",
  "San Patricio (Mexico)",
  "San Pedro (Mexico)",
  "San Pedro Cholula (Mexico)",
  "San Pedro de Las Colonias (Mexico)",
  "San Pedro de Nolasco (Mexico)",
  "San Pedro Piedra Gorda (Mexico)",
  "San Pedro Pochutla (Mexico)",
  "San Rafael (Mexico)",
  "San Salvador Atenco (Mexico)",
  "San Salvador Huixcolotla (Mexico)",
  "San Salvador Tzompantepec (Mexico)",
  "San Sebastian el Grande (Mexico)",
  "San Sebastian Tutla (Mexico)",
  "San Sebastian Xhala (Mexico)",
  "San Simon de Guerrero (Mexico)",
  "San Vicente (Mexico)",
  "San Vicente Chicoloapan (Mexico)",
  "Santa Anita (Mexico)",
  "Santa Apolonia (Mexico)",
  "Santa Catalina (Mexico)",
  "Santa Catarina (Mexico)",
  "Santa Catarina Juquila (Mexico)",
  "Santa Fe (Mexico)",
  "Santa Isabel Tlanepantla (Mexico)",
  "Santa Lucia (Mexico)",
  "Santa Maria Chimalhuacan (Mexico)",
  "Santiago (Mexico)",
  "Santiago de Queretaro (Mexico)",
  "Santiago Ixcuintla (Mexico)",
  "Santiago Tianguistenco (Mexico)",
  "Satelite (Mexico)",
  "Seccion Primera Santiago Tetla (Mexico)",
  "Selene (Mexico)",
  "Sierra Madre (Mexico)",
  "Silao (Mexico)",
  "Sinaloa de Leyva (Mexico)",
  "Sombrerete (Mexico)",
  "Sonora (Mexico)",
  "Soto la Marina (Mexico)",
  "Suchiapa (Mexico)",
  "Tabasco (Mexico)",
  "Tablon Grande (Bombas de Apaxco) (Mexico)",
  "Tacambaro de Codallos (Mexico)",
  "Tacotalpa (Mexico)",
  "Tala (Mexico)",
  "Tamaulipas (Mexico)",
  "Tamazula de Gordiano (Mexico)",
  "Tampico (Mexico)",
  "Tampico Alto (Mexico)",
  "Tangancicuaro de Arista (Mexico)",
  "Tapachula (Mexico)",
  "Tapalpa (Mexico)",
  "Taxco (Mexico)",
  "Teapa (Mexico)",
  "Tecalitlan (Mexico)",
  "Tecamachalco (Mexico)",
  "Tecate (Mexico)",
  "Tecax (Mexico)",
  "Tecoman (Mexico)",
  "Tecuala (Mexico)",
  "Tejupilco de Hidalgo (Mexico)",
  "Tekoh (Mexico)",
  "Teloloapan (Mexico)",
  "Temascalcingo (Mexico)",
  "Temascaltepec de Gonzalez (Mexico)",
  "Temixco (Mexico)",
  "Tenamaxtlan (Mexico)",
  "Tenancingo de Degollado (Mexico)",
  "Tenosique de Pino Suarez (Mexico)",
  "Teoloyucan (Mexico)",
  "Teotihuacan de Arista (Mexico)",
  "Tepalcatepec (Mexico)",
  "Tepeaca (Mexico)",
  "Tepeapulco (Mexico)",
  "Tepeji de Ocampo (Mexico)",
  "Tepetlacolco (Mexico)",
  "Tepetlapa (Mexico)",
  "Tepic (Mexico)",
  "Tepotzotlan (Mexico)",
  "Tepoztlan (Mexico)",
  "Tequila (Mexico)",
  "Tequisistlan (Mexico)",
  "Tequisquiapan (Mexico)",
  "Tequixquiac (Mexico)",
  "Texcoco (Mexico)",
  "Tezonapa (Mexico)",
  "Tezontepec (Mexico)",
  "Tezontepec de Aldama (Mexico)",
  "Tezoyuca (Mexico)",
  "Tierra Blanca (Mexico)",
  "Tierra Colorada (Mexico)",
  "Tijuana (Mexico)",
  "Tilzapotla (Mexico)",
  "Tirindaro (Mexico)",
  "Tizapan el Alto (Mexico)",
  "Tlacolula de Matamoros (Mexico)",
  "Tlacotalpan (Mexico)",
  "Tlahuac (Mexico)",
  "Tlahuelilpan (Mexico)",
  "Tlajomulco de Zuniga (Mexico)",
  "Tlalmanalco de Velazquez (Mexico)",
  "Tlalnepantla (Mexico)",
  "Tlalpan (Mexico)",
  "Tlaltizapan (Mexico)",
  "Tlanalapa (Mexico)",
  "Tlapa de Comonfort (Mexico)",
  "Tlapacoyan (Mexico)",
  "Tlaquepaque (Mexico)",
  "Tlaquiltenango (Mexico)",
  "Tlatlauquitepec (Mexico)",
  "Tlatlaya (Mexico)",
  "Tlaxcala (Mexico)",
  "Tlaxcalancingo (Mexico)",
  "Tocatlan (Mexico)",
  "Tocumbo (Mexico)",
  "Todos Santos (Mexico)",
  "Tolcayuca (Mexico)",
  "Toluca (Mexico)",
  "Tonala (Mexico)",
  "Tonatico (Mexico)",
  "Topolobampo (Mexico)",
  "Tula de Allende (Mexico)",
  "Tulancingo (Mexico)",
  "Tultepec (Mexico)",
  "Tultitlan (Mexico)",
  "Tulum (Mexico)",
  "Tuxpan (Mexico)",
  "Tuxpan de Rodriguez Cano (Mexico)",
  "Tuxtla (Mexico)",
  "Uman (Mexico)",
  "Union de Tula (Mexico)",
  "Ures (Mexico)",
  "Uriangato (Mexico)",
  "Ursulo Galvan (Mexico)",
  "Uruapan (Mexico)",
  "Valladolid (Mexico)",
  "Valle de Bravo (Mexico)",
  "Valle de Santiago (Mexico)",
  "Valle Hermoso (Mexico)",
  "Valles (Mexico)",
  "Venustiano Carranza (Mexico)",
  "Veracruz (Mexico)",
  "Vicente Guerrero (Mexico)",
  "Victoria (Mexico)",
  "Villa Cuauhtemoc Otzolotepec (Mexico)",
  "Villa de Almoloya de Juarez (Mexico)",
  "Villa de Costa Rica (Mexico)",
  "Villa Garcia (Mexico)",
  "Villa Hidalgo (Mexico)",
  "Villa Nanchital (Mexico)",
  "Villadiego (Mexico)",
  "Villagran (Mexico)",
  "Villahermosa (Mexico)",
  "Visitacion (Mexico)",
  "Vistahermosa de Negrete (Mexico)",
  "Xalapa (Mexico)",
  "Xalisco (Mexico)",
  "Xochimilco (Mexico)",
  "Xochitepec (Mexico)",
  "Xonacatlan (Mexico)",
  "Yanga (Mexico)",
  "Yautepec (Mexico)",
  "Yecapixtla (Mexico)",
  "Yucatan (Mexico)",
  "Yurecuaro (Mexico)",
  "Yuriria (Mexico)",
  "Zacapoaxtla (Mexico)",
  "Zacapu (Mexico)",
  "Zacatecas (Mexico)",
  "Zacatelco (Mexico)",
  "Zacatepec (Mexico)",
  "Zacatlan (Mexico)",
  "Zacualpan (Mexico)",
  "Zacualtipan (Mexico)",
  "Zamora (Mexico)",
  "Zapopan (Mexico)",
  "Zapotitlan de Vadillo (Mexico)",
  "Zapotlan de Juarez (Mexico)",
  "Zapotlanejo (Mexico)",
  "Zaragoza (Mexico)",
  "Zinapecuaro (Mexico)",
  "Zitlala (Mexico)",
  "Zongolica (Mexico)",
  "Zumpango (Mexico)",
  "Mongolia",
  "Altai (Mongolia)",
  "Arvayheer (Mongolia)",
  "Baruun-Urt (Mongolia)",
  "Bayangol (Mongolia)",
  "Bayanhongor (Mongolia)",
  "Cecerleg (Mongolia)",
  "Chihertey (Mongolia)",
  "Choyr (Mongolia)",
  "Dalandzadgad (Mongolia)",
  "Darhan (Mongolia)",
  "Han-Uul (Mongolia)",
  "Javhlant (Mongolia)",
  "Khovd (Mongolia)",
  "Mandalgovi (Mongolia)",
  "Ovoot (Mongolia)",
  "Saynshand (Mongolia)",
  "Toyrim (Mongolia)",
  "Ulaan-Uul (Mongolia)",
  "Ulan Bator (Mongolia)",
  "Uliastay (Mongolia)",
  "Undurkhaan (Mongolia)",
  "Montenegro",
  "Budva (Montenegro)",
  "Crna Gora (Montenegro)",
  "Herceg Novi (Montenegro)",
  "Igalo (Montenegro)",
  "Kotor (Montenegro)",
  "Niksic (Montenegro)",
  "Pljevlja (Montenegro)",
  "Podgorica (Montenegro)",
  "Stari Bar (Montenegro)",
  "Ulcinj (Montenegro)",
  "Morocco",
  "Afourer (Morocco)",
  "Agadir (Morocco)",
  "Ait Melloul (Morocco)",
  "Al Hoceima (Morocco)",
  "Assa (Morocco)",
  "Benguerir (Morocco)",
  "Beni Mellal (Morocco)",
  "Berrechid (Morocco)",
  "Casablanca (Morocco)",
  "Deroua (Morocco)",
  "El Gara (Morocco)",
  "El Hajeb (Morocco)",
  "El Jadida (Morocco)",
  "Erfoud (Morocco)",
  "Fes (Morocco)",
  "Fkih Ben Salah (Morocco)",
  "Kenitra (Morocco)",
  "Khemisset (Morocco)",
  "Khouribga (Morocco)",
  "Ksar el Kebir (Morocco)",
  "Larache (Morocco)",
  "Mansour (Morocco)",
  "Marrakesh (Morocco)",
  "Mehediyah (Morocco)",
  "Meknes (Morocco)",
  "Mohammedia (Morocco)",
  "Nador (Morocco)",
  "Ouazzane (Morocco)",
  "Oued Zem (Morocco)",
  "Oujda (Morocco)",
  "Oulad Teima (Morocco)",
  "Rabat (Morocco)",
  "Safi (Morocco)",
  "Sefrou (Morocco)",
  "Settat (Morocco)",
  "Sidi Bennour (Morocco)",
  "Sidi Slimane (Morocco)",
  "Skhirat (Morocco)",
  "Tahala (Morocco)",
  "Tan-Tan (Morocco)",
  "Tangier (Morocco)",
  "Tarfaya (Morocco)",
  "Taza (Morocco)",
  "Temara (Morocco)",
  "Tiflet (Morocco)",
  "Tiznit (Morocco)",
  "Touissite (Morocco)",
  "Mozambique",
  "Beira (Mozambique)",
  "Maputo (Mozambique)",
  "Matola (Mozambique)",
  "Mozambique (Mozambique)",
  "Nampula (Mozambique)",
  "Pemba (Mozambique)",
  "Quelimane (Mozambique)",
  "Tete (Mozambique)",
  "Myanmar [Burma]",
  "Hlaing (Myanmar [Burma])",
  "Inya (Myanmar [Burma])",
  "Kyauktada (Myanmar [Burma])",
  "Mandalay (Myanmar [Burma])",
  "Wagan (Myanmar [Burma])",
  "Yangon (Myanmar [Burma])",
  "Namibia",
  "Etunda (Namibia)",
  "Grootfontein (Namibia)",
  "Katima Mulilo (Namibia)",
  "Keetmanshoop (Namibia)",
  "Mpapuka (Namibia)",
  "Olympia (Namibia)",
  "Ondangwa (Namibia)",
  "Ongwediva (Namibia)",
  "Oranjemund (Namibia)",
  "Oshakati (Namibia)",
  "Otjiwarongo (Namibia)",
  "Outapi (Namibia)",
  "Swakopmund (Namibia)",
  "Tsumeb (Namibia)",
  "Walvis Bay (Namibia)",
  "Windhoek (Namibia)",
  "Nepal",
  "Bharatpur (Nepal)",
  "Jawlakhel (Nepal)",
  "Kathmandu (Nepal)",
  "Lumbini (Nepal)",
  "Palpa (Nepal)",
  "Patan (Nepal)",
  "Netherlands",
  "Aagtekerke (Netherlands)",
  "Aalburg (Netherlands)",
  "Aalden (Netherlands)",
  "Aalsmeer (Netherlands)",
  "Aalsmeerderbrug (Netherlands)",
  "Aalst (Netherlands)",
  "Aalten (Netherlands)",
  "Aardenburg (Netherlands)",
  "Abbekerk (Netherlands)",
  "Abbenes (Netherlands)",
  "Abcoude (Netherlands)",
  "Achterberg (Netherlands)",
  "Achterveld (Netherlands)",
  "Achthuizen (Netherlands)",
  "Achtmaal (Netherlands)",
  "Aduard (Netherlands)",
  "Aerdenhout (Netherlands)",
  "Aerdt (Netherlands)",
  "Afferden (Netherlands)",
  "Akersloot (Netherlands)",
  "Akkrum (Netherlands)",
  "Albergen (Netherlands)",
  "Alblasserdam (Netherlands)",
  "Alde Leie (Netherlands)",
  "Alem (Netherlands)",
  "Alkmaar (Netherlands)",
  "Almelo (Netherlands)",
  "Almere (Netherlands)",
  "Almere Haven (Netherlands)",
  "Almkerk (Netherlands)",
  "Alphen (Netherlands)",
  "Alphen aan den Rijn (Netherlands)",
  "Ameide (Netherlands)",
  "America (Netherlands)",
  "Amerongen (Netherlands)",
  "Amersfoort (Netherlands)",
  "Ammerstol (Netherlands)",
  "Ammerzoden (Netherlands)",
  "Amstelhoek (Netherlands)",
  "Amstelveen (Netherlands)",
  "Amstenrade (Netherlands)",
  "Amsterdam (Netherlands)",
  "Anderen (Netherlands)",
  "Andijk (Netherlands)",
  "Ane (Netherlands)",
  "Angeren (Netherlands)",
  "Anjum (Netherlands)",
  "Ankeveen (Netherlands)",
  "Anloo (Netherlands)",
  "Anna Paulowna (Netherlands)",
  "Annen (Netherlands)",
  "Apeldoorn (Netherlands)",
  "Appelscha (Netherlands)",
  "Appeltern (Netherlands)",
  "Appingedam (Netherlands)",
  "Arcen (Netherlands)",
  "Arkel (Netherlands)",
  "Arnemuiden (Netherlands)",
  "Arnhem (Netherlands)",
  "Asperen (Netherlands)",
  "Assen (Netherlands)",
  "Assendelft (Netherlands)",
  "Asten (Netherlands)",
  "Augustinusga (Netherlands)",
  "Avenhorn (Netherlands)",
  "Axel (Netherlands)",
  "Baambrugge (Netherlands)",
  "Baarland (Netherlands)",
  "Baarle-Nassau (Netherlands)",
  "Baarlo (Netherlands)",
  "Baarn (Netherlands)",
  "Baars (Netherlands)",
  "Babberich (Netherlands)",
  "Badhoevedorp (Netherlands)",
  "Baexem (Netherlands)",
  "Bakel (Netherlands)",
  "Baken (Netherlands)",
  "Bakhuizen (Netherlands)",
  "Balgoij (Netherlands)",
  "Balk (Netherlands)",
  "Ballum (Netherlands)",
  "Banholt (Netherlands)",
  "Barchem (Netherlands)",
  "Barendrecht (Netherlands)",
  "Barneveld (Netherlands)",
  "Batenburg (Netherlands)",
  "Bathmen (Netherlands)",
  "Bavel (Netherlands)",
  "Bedum (Netherlands)",
  "Beegden (Netherlands)",
  "Beek (Netherlands)",
  "Beekbergen (Netherlands)",
  "Beerta (Netherlands)",
  "Beerzerveld (Netherlands)",
  "Beesd (Netherlands)",
  "Beesel (Netherlands)",
  "Beets (Netherlands)",
  "Beetsterzwaag (Netherlands)",
  "Beilen (Netherlands)",
  "Beinsdorp (Netherlands)",
  "Belfeld (Netherlands)",
  "Bellingwolde (Netherlands)",
  "Bemelen (Netherlands)",
  "Bemmel (Netherlands)",
  "Beneden-Leeuwen (Netherlands)",
  "Bennebroek (Netherlands)",
  "Bennekom (Netherlands)",
  "Benningbroek (Netherlands)",
  "Benthuizen (Netherlands)",
  "Berg (Netherlands)",
  "Berg en Dal (Netherlands)",
  "Bergambacht (Netherlands)",
  "Bergeijk (Netherlands)",
  "Bergen (Netherlands)",
  "Bergen op Zoom (Netherlands)",
  "Bergentheim (Netherlands)",
  "Bergharen (Netherlands)",
  "Berghem (Netherlands)",
  "Bergschenhoek (Netherlands)",
  "Beringe (Netherlands)",
  "Berkenwoude (Netherlands)",
  "Berkhout (Netherlands)",
  "Berlicum (Netherlands)",
  "Best (Netherlands)",
  "Beugen (Netherlands)",
  "Beuningen (Netherlands)",
  "Beusichem (Netherlands)",
  "Beverwijk (Netherlands)",
  "Biddinghuizen (Netherlands)",
  "Bierum (Netherlands)",
  "Biervliet (Netherlands)",
  "Biest (Netherlands)",
  "Bilthoven (Netherlands)",
  "Bitgum (Netherlands)",
  "Bladel (Netherlands)",
  "Blaricum (Netherlands)",
  "Bleiswijk (Netherlands)",
  "Blerick (Netherlands)",
  "Bleskensgraaf (Netherlands)",
  "Blitterswijck (Netherlands)",
  "Bloemendaal (Netherlands)",
  "Blokker (Netherlands)",
  "Blokzijl (Netherlands)",
  "Bocholtz (Netherlands)",
  "Bodegraven (Netherlands)",
  "Boeicop (Netherlands)",
  "Boekel (Netherlands)",
  "Boelenslaan (Netherlands)",
  "Boerakker (Netherlands)",
  "Boesingheliede (Netherlands)",
  "Bolsward (Netherlands)",
  "Borculo (Netherlands)",
  "Borger (Netherlands)",
  "Born (Netherlands)",
  "Borne (Netherlands)",
  "Borsele (Netherlands)",
  "Bosch en Duin (Netherlands)",
  "Boskoop (Netherlands)",
  "Bosschenhoofd (Netherlands)",
  "Bourtange (Netherlands)",
  "Boven-Hardinxveld (Netherlands)",
  "Boven-Leeuwen (Netherlands)",
  "Bovenkarspel (Netherlands)",
  "Bovensmilde (Netherlands)",
  "Boxmeer (Netherlands)",
  "Boxtel (Netherlands)",
  "Brakel (Netherlands)",
  "Breda (Netherlands)",
  "Bredevoort (Netherlands)",
  "Breezand (Netherlands)",
  "Breskens (Netherlands)",
  "Breugel (Netherlands)",
  "Breukelen (Netherlands)",
  "Breukeleveen (Netherlands)",
  "Brielle (Netherlands)",
  "Briltil (Netherlands)",
  "Britsum (Netherlands)",
  "Broek in Waterland (Netherlands)",
  "Broek op Langedijk (Netherlands)",
  "Broekhin (Netherlands)",
  "Broekhuizen (Netherlands)",
  "Brouwershaven (Netherlands)",
  "Bruchem (Netherlands)",
  "Brucht (Netherlands)",
  "Bruinisse (Netherlands)",
  "Brummen (Netherlands)",
  "Brunssum (Netherlands)",
  "Buchten (Netherlands)",
  "Budel (Netherlands)",
  "Budel-Dorplein (Netherlands)",
  "Budel-Schoot (Netherlands)",
  "Buinerveen (Netherlands)",
  "Buitenkaag (Netherlands)",
  "Buitenpost (Netherlands)",
  "Bunde (Netherlands)",
  "Bunnik (Netherlands)",
  "Bunschoten (Netherlands)",
  "Buren (Netherlands)",
  "Burgerveen (Netherlands)",
  "Burgum (Netherlands)",
  "Burgwerd (Netherlands)",
  "Burum (Netherlands)",
  "Bussum (Netherlands)",
  "Buurse (Netherlands)",
  "Cadier en Keer (Netherlands)",
  "Cadzand (Netherlands)",
  "Callantsoog (Netherlands)",
  "Capelle aan den IJssel (Netherlands)",
  "Castricum (Netherlands)",
  "Centrum (Netherlands)",
  "Chaam (Netherlands)",
  "Clinge (Netherlands)",
  "Coevorden (Netherlands)",
  "Colijnsplaat (Netherlands)",
  "Colmschate (Netherlands)",
  "Craailo (Netherlands)",
  "Cromvoirt (Netherlands)",
  "Cruquius (Netherlands)",
  "Cuijk (Netherlands)",
  "Culemborg (Netherlands)",
  "Daarle (Netherlands)",
  "Dalen (Netherlands)",
  "Dalfsen (Netherlands)",
  "Damwald (Netherlands)",
  "De Bilt (Netherlands)",
  "De Blesse (Netherlands)",
  "De Cocksdorp (Netherlands)",
  "De Falom (Netherlands)",
  "De Glind (Netherlands)",
  "De Goorn (Netherlands)",
  "De Hoef (Netherlands)",
  "De Horst (Netherlands)",
  "De Klomp (Netherlands)",
  "De Koog (Netherlands)",
  "De Kwakel (Netherlands)",
  "De Lier (Netherlands)",
  "De Lutte (Netherlands)",
  "De Meern (Netherlands)",
  "De Moer (Netherlands)",
  "De Punt (Netherlands)",
  "De Rijp (Netherlands)",
  "De Steeg (Netherlands)",
  "De Waal (Netherlands)",
  "De Westereen (Netherlands)",
  "De Wijk (Netherlands)",
  "De Wilp (Netherlands)",
  "De Zilk (Netherlands)",
  "Dedemsvaart (Netherlands)",
  "Deest (Netherlands)",
  "Delden (Netherlands)",
  "Delfgauw (Netherlands)",
  "Delft (Netherlands)",
  "Delfzijl (Netherlands)",
  "Den Bommel (Netherlands)",
  "Den Burg (Netherlands)",
  "Den Deijl (Netherlands)",
  "Den Dolder (Netherlands)",
  "Den Dungen (Netherlands)",
  "Den Helder (Netherlands)",
  "Den Hoorn (Netherlands)",
  "Den Hout (Netherlands)",
  "Den Ilp (Netherlands)",
  "Den Oever (Netherlands)",
  "Denekamp (Netherlands)",
  "Deurne (Netherlands)",
  "Deventer (Netherlands)",
  "Didam (Netherlands)",
  "Diemen (Netherlands)",
  "Diepenheim (Netherlands)",
  "Diepenveen (Netherlands)",
  "Dieren (Netherlands)",
  "Diessen (Netherlands)",
  "Dieteren (Netherlands)",
  "Diever (Netherlands)",
  "Dijk (Netherlands)",
  "Dinteloord (Netherlands)",
  "Dinxperlo (Netherlands)",
  "Dirkshorn (Netherlands)",
  "Dirksland (Netherlands)",
  "Dodewaard (Netherlands)",
  "Doenrade (Netherlands)",
  "Doesburg (Netherlands)",
  "Doetinchem (Netherlands)",
  "Dokkum (Netherlands)",
  "Domburg (Netherlands)",
  "Dongen (Netherlands)",
  "Doorn (Netherlands)",
  "Doornenburg (Netherlands)",
  "Doornspijk (Netherlands)",
  "Doorwerth (Netherlands)",
  "Dordrecht (Netherlands)",
  "Dorp (Netherlands)",
  "Dorst (Netherlands)",
  "Drachten (Netherlands)",
  "Drempt (Netherlands)",
  "Dreumel (Netherlands)",
  "Driebergen (Netherlands)",
  "Driebruggen (Netherlands)",
  "Driehuis (Netherlands)",
  "Driel (Netherlands)",
  "Driemond (Netherlands)",
  "Drogeham (Netherlands)",
  "Dronryp (Netherlands)",
  "Dronten (Netherlands)",
  "Drunen (Netherlands)",
  "Druten (Netherlands)",
  "Duin (Netherlands)",
  "Duiven (Netherlands)",
  "Duivendrecht (Netherlands)",
  "Dwingeloo (Netherlands)",
  "Earnewald (Netherlands)",
  "Echt (Netherlands)",
  "Echten (Netherlands)",
  "Echtenerbrug (Netherlands)",
  "Eckart (Netherlands)",
  "Edam (Netherlands)",
  "Ede (Netherlands)",
  "Ederveen (Netherlands)",
  "Eede (Netherlands)",
  "Eefde (Netherlands)",
  "Eelde-Paterswolde (Netherlands)",
  "Eelderwolde (Netherlands)",
  "Eemnes (Netherlands)",
  "Eenrum (Netherlands)",
  "Eerbeek (Netherlands)",
  "Eersel (Netherlands)",
  "Eethen (Netherlands)",
  "Eext (Netherlands)",
  "Eexterzandvoort (Netherlands)",
  "Egchel (Netherlands)",
  "Egmond aan Zee (Netherlands)",
  "Egmond-Binnen (Netherlands)",
  "Eibergen (Netherlands)",
  "Eijsden (Netherlands)",
  "Eindhoven (Netherlands)",
  "Einighausen (Netherlands)",
  "Elburg (Netherlands)",
  "Ell (Netherlands)",
  "Ellecom (Netherlands)",
  "Elsendorp (Netherlands)",
  "Elsloo (Netherlands)",
  "Elst (Netherlands)",
  "Emmeloord (Netherlands)",
  "Emmen (Netherlands)",
  "Emmer-Compascuum (Netherlands)",
  "Emst (Netherlands)",
  "Engelen (Netherlands)",
  "Enkhuizen (Netherlands)",
  "Ens (Netherlands)",
  "Enschede (Netherlands)",
  "Enschot (Netherlands)",
  "Enspijk (Netherlands)",
  "Enter (Netherlands)",
  "Enumatil (Netherlands)",
  "Epe (Netherlands)",
  "Epen (Netherlands)",
  "Epse (Netherlands)",
  "Erica (Netherlands)",
  "Erichem (Netherlands)",
  "Erm (Netherlands)",
  "Ermelo (Netherlands)",
  "Erp (Netherlands)",
  "Escharen (Netherlands)",
  "Espel (Netherlands)",
  "Etten (Netherlands)",
  "Everdingen (Netherlands)",
  "Ewijk (Netherlands)",
  "Exloo (Netherlands)",
  "Eygelshoven (Netherlands)",
  "Eys (Netherlands)",
  "Ezinge (Netherlands)",
  "Farmsum (Netherlands)",
  "Feanwalden (Netherlands)",
  "Ferwert (Netherlands)",
  "Fijnaart (Netherlands)",
  "Finsterwolde (Netherlands)",
  "Fleringen (Netherlands)",
  "Fluitenberg (Netherlands)",
  "Flushing (Netherlands)",
  "Foxhol (Netherlands)",
  "Franeker (Netherlands)",
  "Frederiksoord (Netherlands)",
  "Gaanderen (Netherlands)",
  "Galder (Netherlands)",
  "Gameren (Netherlands)",
  "Gapinge (Netherlands)",
  "Garderen (Netherlands)",
  "Garmerwolde (Netherlands)",
  "Garsthuizen (Netherlands)",
  "Garyp (Netherlands)",
  "Gasselte (Netherlands)",
  "Gasselternijveen (Netherlands)",
  "Geertruidenberg (Netherlands)",
  "Geervliet (Netherlands)",
  "Gees (Netherlands)",
  "Geesteren (Netherlands)",
  "Geldermalsen (Netherlands)",
  "Geldrop (Netherlands)",
  "Geleen (Netherlands)",
  "Gelselaar (Netherlands)",
  "Gemeente Rotterdam (Netherlands)",
  "Gemert (Netherlands)",
  "Gemonde (Netherlands)",
  "Genderen (Netherlands)",
  "Gendringen (Netherlands)",
  "Gendt (Netherlands)",
  "Genemuiden (Netherlands)",
  "Gennep (Netherlands)",
  "Giesbeek (Netherlands)",
  "Giessenburg (Netherlands)",
  "Giessendam (Netherlands)",
  "Gieten (Netherlands)",
  "Gieterveen (Netherlands)",
  "Giethoorn (Netherlands)",
  "Gilze (Netherlands)",
  "Glimmen (Netherlands)",
  "Goes (Netherlands)",
  "Goirle (Netherlands)",
  "Goor (Netherlands)",
  "Gorinchem (Netherlands)",
  "Gorredijk (Netherlands)",
  "Gorssel (Netherlands)",
  "Gouda (Netherlands)",
  "Gouderak (Netherlands)",
  "Goudswaard (Netherlands)",
  "Goutum (Netherlands)",
  "Gramsbergen (Netherlands)",
  "Grashoek (Netherlands)",
  "Grathem (Netherlands)",
  "Grave (Netherlands)",
  "Greup (Netherlands)",
  "Grijpskerk (Netherlands)",
  "Groeningen (Netherlands)",
  "Groenlo (Netherlands)",
  "Groenveld (Netherlands)",
  "Groesbeek (Netherlands)",
  "Groessen (Netherlands)",
  "Groet (Netherlands)",
  "Groningen (Netherlands)",
  "Gronsveld (Netherlands)",
  "Groot-Agelo (Netherlands)",
  "Groot-Ammers (Netherlands)",
  "Grootebroek (Netherlands)",
  "Grootegast (Netherlands)",
  "Grou (Netherlands)",
  "Grubbenvorst (Netherlands)",
  "Gulpen (Netherlands)",
  "Guttecoven (Netherlands)",
  "Gytsjerk (Netherlands)",
  "Haaften (Netherlands)",
  "Haaksbergen (Netherlands)",
  "Haalderen (Netherlands)",
  "Haamstede (Netherlands)",
  "Haaren (Netherlands)",
  "Haarle (Netherlands)",
  "Haarlem (Netherlands)",
  "Haarlemmerliede (Netherlands)",
  "Haelen (Netherlands)",
  "Halfweg (Netherlands)",
  "Halle (Netherlands)",
  "Hallum (Netherlands)",
  "Halsteren (Netherlands)",
  "Hank (Netherlands)",
  "Hantum (Netherlands)",
  "Hantumhuizen (Netherlands)",
  "Hapert (Netherlands)",
  "Haps (Netherlands)",
  "Hardenberg (Netherlands)",
  "Harderwijk (Netherlands)",
  "Haren (Netherlands)",
  "Harich (Netherlands)",
  "Harkema (Netherlands)",
  "Harkstede (Netherlands)",
  "Harlingen (Netherlands)",
  "Harmelen (Netherlands)",
  "Hartwerd (Netherlands)",
  "Hasselt (Netherlands)",
  "Hattem (Netherlands)",
  "Haule (Netherlands)",
  "Haulerwijk (Netherlands)",
  "Hauwert (Netherlands)",
  "Havelte (Netherlands)",
  "Hazerswoude-Dorp (Netherlands)",
  "Hazerswoude-Rijndijk (Netherlands)",
  "Hedel (Netherlands)",
  "Heeg (Netherlands)",
  "Heelsum (Netherlands)",
  "Heemskerk (Netherlands)",
  "Heemstede (Netherlands)",
  "Heenvliet (Netherlands)",
  "Heerde (Netherlands)",
  "Heerenveen (Netherlands)",
  "Heerhugowaard (Netherlands)",
  "Heerjansdam (Netherlands)",
  "Heerle (Netherlands)",
  "Heerlen (Netherlands)",
  "Heesch (Netherlands)",
  "Heeswijk (Netherlands)",
  "Heeswijk-Dinther (Netherlands)",
  "Heeten (Netherlands)",
  "Heeze (Netherlands)",
  "Heijen (Netherlands)",
  "Heijningen (Netherlands)",
  "Heikant (Netherlands)",
  "Heilig Landstichting (Netherlands)",
  "Heiloo (Netherlands)",
  "Heinenoord (Netherlands)",
  "Heino (Netherlands)",
  "Hekelingen (Netherlands)",
  "Helden (Netherlands)",
  "Helenaveen (Netherlands)",
  "Hellendoorn (Netherlands)",
  "Hellevoetsluis (Netherlands)",
  "Hellouw (Netherlands)",
  "Helmond (Netherlands)",
  "Helvoirt (Netherlands)",
  "Hem (Netherlands)",
  "Hemelum (Netherlands)",
  "Hendrik-Ido-Ambacht (Netherlands)",
  "Hengelo (Netherlands)",
  "Hengevelde (Netherlands)",
  "Hennaard (Netherlands)",
  "Hensbroek (Netherlands)",
  "Herbaijum (Netherlands)",
  "Herkenbosch (Netherlands)",
  "Herkingen (Netherlands)",
  "Hernen (Netherlands)",
  "Herpen (Netherlands)",
  "Herten (Netherlands)",
  "Herveld (Netherlands)",
  "Herwijnen (Netherlands)",
  "Heteren (Netherlands)",
  "Heukelum (Netherlands)",
  "Heusden (Netherlands)",
  "Heveadorp (Netherlands)",
  "Heythuysen (Netherlands)",
  "Hierden (Netherlands)",
  "Hijken (Netherlands)",
  "Hillegom (Netherlands)",
  "Hilvarenbeek (Netherlands)",
  "Hilversum (Netherlands)",
  "Hindeloopen (Netherlands)",
  "Hippolytushoef (Netherlands)",
  "Hoedekenskerke (Netherlands)",
  "Hoek (Netherlands)",
  "Hoek van Holland (Netherlands)",
  "Hoenderloo (Netherlands)",
  "Hoensbroek (Netherlands)",
  "Hoevelaken (Netherlands)",
  "Hoge Donk (Netherlands)",
  "Hollandsche Rading (Netherlands)",
  "Hollandscheveld (Netherlands)",
  "Hollum (Netherlands)",
  "Holten (Netherlands)",
  "Holthees (Netherlands)",
  "Holthone (Netherlands)",
  "Holtum (Netherlands)",
  "Holwierde (Netherlands)",
  "Homoet (Netherlands)",
  "Honselersdijk (Netherlands)",
  "Hoofddorp (Netherlands)",
  "Hoofdplaat (Netherlands)",
  "Hoogblokland (Netherlands)",
  "Hooge Zwaluwe (Netherlands)",
  "Hoogeloon (Netherlands)",
  "Hoogerheide (Netherlands)",
  "Hoogersmilde (Netherlands)",
  "Hoogeveen (Netherlands)",
  "Hoogezand (Netherlands)",
  "Hooghalen (Netherlands)",
  "Hoogkarspel (Netherlands)",
  "Hoogkerk (Netherlands)",
  "Hoogland (Netherlands)",
  "Hooglanderveen (Netherlands)",
  "Hoogmade (Netherlands)",
  "Hoogvliet (Netherlands)",
  "Hoogwoud (Netherlands)",
  "Hoorn (Netherlands)",
  "Hoornaar (Netherlands)",
  "Horn (Netherlands)",
  "Horst (Netherlands)",
  "Hout (Netherlands)",
  "Houtakker (Netherlands)",
  "Houten (Netherlands)",
  "Huijbergen (Netherlands)",
  "Huis ter Heide (Netherlands)",
  "Huissen (Netherlands)",
  "Huizen (Netherlands)",
  "Hulsberg (Netherlands)",
  "Hulshorst (Netherlands)",
  "Hulst (Netherlands)",
  "Hummelo (Netherlands)",
  "Hurdegaryp (Netherlands)",
  "IJhorst (Netherlands)",
  "IJlst (Netherlands)",
  "IJmuiden (Netherlands)",
  "IJsselmuiden (Netherlands)",
  "IJsselstein (Netherlands)",
  "IJzendijke (Netherlands)",
  "IJzendoorn (Netherlands)",
  "Ilpendam (Netherlands)",
  "Ingelum (Netherlands)",
  "Ingen (Netherlands)",
  "Ittervoort (Netherlands)",
  "Jabeek (Netherlands)",
  "Jisp (Netherlands)",
  "Joppe (Netherlands)",
  "Joure (Netherlands)",
  "Jubbega (Netherlands)",
  "Julianadorp (Netherlands)",
  "Jutrijp (Netherlands)",
  "Kaag (Netherlands)",
  "Kaatsheuvel (Netherlands)",
  "Kalenberg (Netherlands)",
  "Kamerik (Netherlands)",
  "Kampen (Netherlands)",
  "Kamperland (Netherlands)",
  "Kantens (Netherlands)",
  "Kapelle (Netherlands)",
  "Kats (Netherlands)",
  "Katwijk (Netherlands)",
  "Katwijk aan Zee (Netherlands)",
  "Katwoude (Netherlands)",
  "Keijenborg (Netherlands)",
  "Kekerdom (Netherlands)",
  "Keldonk (Netherlands)",
  "Kerk-Avezaath (Netherlands)",
  "Kerkdriel (Netherlands)",
  "Kerkenveld (Netherlands)",
  "Kerkrade (Netherlands)",
  "Kessel (Netherlands)",
  "Kesteren (Netherlands)",
  "Kimswerd (Netherlands)",
  "Kinderdijk (Netherlands)",
  "Klaaswaal (Netherlands)",
  "Klazienaveen (Netherlands)",
  "Klimmen (Netherlands)",
  "Kloetinge (Netherlands)",
  "Kloosterhaar (Netherlands)",
  "Klundert (Netherlands)",
  "Kockengen (Netherlands)",
  "Koedijk (Netherlands)",
  "Koekange (Netherlands)",
  "Koewacht (Netherlands)",
  "Kolham (Netherlands)",
  "Kolhorn (Netherlands)",
  "Kollum (Netherlands)",
  "Kommerzijl (Netherlands)",
  "Koog aan de Zaan (Netherlands)",
  "Kootstertille (Netherlands)",
  "Kootwijkerbroek (Netherlands)",
  "Kortenhoef (Netherlands)",
  "Kortgene (Netherlands)",
  "Koudekerk aan den Rijn (Netherlands)",
  "Koudekerke (Netherlands)",
  "Koudum (Netherlands)",
  "Kraggenburg (Netherlands)",
  "Krimpen aan de Lek (Netherlands)",
  "Krimpen aan den IJssel (Netherlands)",
  "Krommenie (Netherlands)",
  "Kropswolde (Netherlands)",
  "Kruiningen (Netherlands)",
  "Kruisland (Netherlands)",
  "Kudelstaart (Netherlands)",
  "Kuitaart (Netherlands)",
  "Kwintsheul (Netherlands)",
  "Laag-Soeren (Netherlands)",
  "Lage Mierde (Netherlands)",
  "Lage Zwaluwe (Netherlands)",
  "Landsmeer (Netherlands)",
  "Langedijk (Netherlands)",
  "Langezwaag (Netherlands)",
  "Laren (Netherlands)",
  "Lathum (Netherlands)",
  "Leek (Netherlands)",
  "Leerdam (Netherlands)",
  "Leersum (Netherlands)",
  "Leeuwarden (Netherlands)",
  "Legemeer (Netherlands)",
  "Leiden (Netherlands)",
  "Leiderdorp (Netherlands)",
  "Leidschendam (Netherlands)",
  "Leimuiden (Netherlands)",
  "Lekkerkerk (Netherlands)",
  "Lelystad (Netherlands)",
  "Lemele (Netherlands)",
  "Lemelerveld (Netherlands)",
  "Lemiers (Netherlands)",
  "Lemmer (Netherlands)",
  "Lent (Netherlands)",
  "Lettele (Netherlands)",
  "Leusden (Netherlands)",
  "Leuth (Netherlands)",
  "Lewenborg (Netherlands)",
  "Lexmond (Netherlands)",
  "Lichtenvoorde (Netherlands)",
  "Liempde (Netherlands)",
  "Lienden (Netherlands)",
  "Lies (Netherlands)",
  "Lieshout (Netherlands)",
  "Liessel (Netherlands)",
  "Lievelde (Netherlands)",
  "Lijnden (Netherlands)",
  "Limbricht (Netherlands)",
  "Limmel (Netherlands)",
  "Limmen (Netherlands)",
  "Linne (Netherlands)",
  "Linschoten (Netherlands)",
  "Lippenhuizen (Netherlands)",
  "Lisse (Netherlands)",
  "Lithoijen (Netherlands)",
  "Lobith (Netherlands)",
  "Lochem (Netherlands)",
  "Loenen (Netherlands)",
  "Loon op Zand (Netherlands)",
  "Loosdrecht (Netherlands)",
  "Loozen (Netherlands)",
  "Lopik (Netherlands)",
  "Loppersum (Netherlands)",
  "Losser (Netherlands)",
  "Lottum (Netherlands)",
  "Lunteren (Netherlands)",
  "Lutjebroek (Netherlands)",
  "Lutjewinkel (Netherlands)",
  "Lutten (Netherlands)",
  "Luyksgestel (Netherlands)",
  "Maarheeze (Netherlands)",
  "Maarn (Netherlands)",
  "Maarssen (Netherlands)",
  "Maarssenbroek (Netherlands)",
  "Maartensdijk (Netherlands)",
  "Maasbommel (Netherlands)",
  "Maasbracht (Netherlands)",
  "Maasbree (Netherlands)",
  "Maasdam (Netherlands)",
  "Maasdijk (Netherlands)",
  "Maashees (Netherlands)",
  "Maaskantje (Netherlands)",
  "Maasland (Netherlands)",
  "Maassluis (Netherlands)",
  "Maastricht (Netherlands)",
  "Made (Netherlands)",
  "Makkum (Netherlands)",
  "Malden (Netherlands)",
  "Manderveen (Netherlands)",
  "Margraten (Netherlands)",
  "Marienberg (Netherlands)",
  "Markelo (Netherlands)",
  "Marknesse (Netherlands)",
  "Marrum (Netherlands)",
  "Marum (Netherlands)",
  "Mastenbroek (Netherlands)",
  "Maurik (Netherlands)",
  "Mechelen (Netherlands)",
  "Medemblik (Netherlands)",
  "Meeden (Netherlands)",
  "Meer (Netherlands)",
  "Meerkerk (Netherlands)",
  "Meerlo (Netherlands)",
  "Meerssen (Netherlands)",
  "Meeuwen (Netherlands)",
  "Megchelen (Netherlands)",
  "Meijel (Netherlands)",
  "Melick (Netherlands)",
  "Meppel (Netherlands)",
  "Merkelbeek (Netherlands)",
  "Merselo (Netherlands)",
  "Middelbeers (Netherlands)",
  "Middelburg (Netherlands)",
  "Middelharnis (Netherlands)",
  "Middenbeemster (Netherlands)",
  "Middenmeer (Netherlands)",
  "Midlum (Netherlands)",
  "Mierlo (Netherlands)",
  "Mijdrecht (Netherlands)",
  "Mijnsheerenland (Netherlands)",
  "Mildam (Netherlands)",
  "Milheeze (Netherlands)",
  "Mill (Netherlands)",
  "Millingen (Netherlands)",
  "Millingen aan de Rijn (Netherlands)",
  "Milsbeek (Netherlands)",
  "Moerdijk (Netherlands)",
  "Moergestel (Netherlands)",
  "Moerkapelle (Netherlands)",
  "Molenaarsgraaf (Netherlands)",
  "Molenhoek (Netherlands)",
  "Molenschot (Netherlands)",
  "Monnickendam (Netherlands)",
  "Monster (Netherlands)",
  "Montfoort (Netherlands)",
  "Montfort (Netherlands)",
  "Mook (Netherlands)",
  "Mookhoek (Netherlands)",
  "Moordrecht (Netherlands)",
  "Mortel (Netherlands)",
  "Muiden (Netherlands)",
  "Muiderberg (Netherlands)",
  "Munstergeleen (Netherlands)",
  "Muntendam (Netherlands)",
  "Mussel (Netherlands)",
  "Naaldwijk (Netherlands)",
  "Naarden (Netherlands)",
  "Nederasselt (Netherlands)",
  "Nederhemert (Netherlands)",
  "Nederhorst den Berg (Netherlands)",
  "Nederweert (Netherlands)",
  "Neede (Netherlands)",
  "Neer (Netherlands)",
  "Neer-Andel (Netherlands)",
  "Nes (Netherlands)",
  "Netterden (Netherlands)",
  "Nibbixwoud (Netherlands)",
  "Nieuw-Amsterdam (Netherlands)",
  "Nieuw-Balinge (Netherlands)",
  "Nieuw-Beijerland (Netherlands)",
  "Nieuw-Bergen (Netherlands)",
  "Nieuw-Buinen (Netherlands)",
  "Nieuw-Dordrecht (Netherlands)",
  "Nieuw-Lekkerland (Netherlands)",
  "Nieuw-Namen (Netherlands)",
  "Nieuw-Schoonebeek (Netherlands)",
  "Nieuw-Vennep (Netherlands)",
  "Nieuw-Vossemeer (Netherlands)",
  "Nieuwdorp (Netherlands)",
  "Nieuwe Pekela (Netherlands)",
  "Nieuwe Wetering (Netherlands)",
  "Nieuwe-Niedorp (Netherlands)",
  "Nieuwe-Tonge (Netherlands)",
  "Nieuwegein (Netherlands)",
  "Nieuwehorne (Netherlands)",
  "Nieuwendijk (Netherlands)",
  "Nieuwer-Ter-Aa (Netherlands)",
  "Nieuwerbrug (Netherlands)",
  "Nieuwerkerk aan den IJssel (Netherlands)",
  "Nieuweschoot (Netherlands)",
  "Nieuwkoop (Netherlands)",
  "Nieuwkuijk (Netherlands)",
  "Nieuwlande (Netherlands)",
  "Nieuwleusen (Netherlands)",
  "Nieuwolda (Netherlands)",
  "Nieuwpoort (Netherlands)",
  "Nieuwstadt (Netherlands)",
  "Nieuwveen (Netherlands)",
  "Nigtevecht (Netherlands)",
  "Nijbroek (Netherlands)",
  "Nijhuizum (Netherlands)",
  "Nijkerk (Netherlands)",
  "Nijkerkerveen (Netherlands)",
  "Nijland (Netherlands)",
  "Nijmegen (Netherlands)",
  "Nijnsel (Netherlands)",
  "Nijverdal (Netherlands)",
  "Nistelrode (Netherlands)",
  "Noardburgum (Netherlands)",
  "Noorbeek (Netherlands)",
  "Noord-Scharwoude (Netherlands)",
  "Noord-Sleen (Netherlands)",
  "Noordbroek (Netherlands)",
  "Noordeloos (Netherlands)",
  "Noorden (Netherlands)",
  "Noordgouwe (Netherlands)",
  "Noordhorn (Netherlands)",
  "Noordlaren (Netherlands)",
  "Noordscheschut (Netherlands)",
  "Noordwelle (Netherlands)",
  "Noordwijk aan Zee (Netherlands)",
  "Noordwijk-Binnen (Netherlands)",
  "Noordwijkerhout (Netherlands)",
  "Noordwolde (Netherlands)",
  "Nootdorp (Netherlands)",
  "Norg (Netherlands)",
  "Nuenen (Netherlands)",
  "Nuis (Netherlands)",
  "Nuland (Netherlands)",
  "Numansdorp (Netherlands)",
  "Nunhem (Netherlands)",
  "Nunspeet (Netherlands)",
  "Nuth (Netherlands)",
  "Obbicht (Netherlands)",
  "Obdam (Netherlands)",
  "Ochten (Netherlands)",
  "Odijk (Netherlands)",
  "Oeffelt (Netherlands)",
  "Oegstgeest (Netherlands)",
  "Oene (Netherlands)",
  "Oijen (Netherlands)",
  "Oirsbeek (Netherlands)",
  "Oirschot (Netherlands)",
  "Oisterwijk (Netherlands)",
  "Oldeberkoop (Netherlands)",
  "Oldebroek (Netherlands)",
  "Oldeholtpade (Netherlands)",
  "Oldehove (Netherlands)",
  "Oldekerk (Netherlands)",
  "Oldemarkt (Netherlands)",
  "Oldenzaal (Netherlands)",
  "Olst (Netherlands)",
  "Ommen (Netherlands)",
  "Onnen (Netherlands)",
  "Ooij (Netherlands)",
  "Ooltgensplaat (Netherlands)",
  "Oost-Souburg (Netherlands)",
  "Oostburg (Netherlands)",
  "Oostendam (Netherlands)",
  "Oosterbeek (Netherlands)",
  "Oosterbierum (Netherlands)",
  "Oosterend (Netherlands)",
  "Oosterhesselen (Netherlands)",
  "Oosterhout (Netherlands)",
  "Oosterland (Netherlands)",
  "Oosternijkerk (Netherlands)",
  "Oosterwolde (Netherlands)",
  "Oosterzee (Netherlands)",
  "Oosthuizen (Netherlands)",
  "Oostkapelle (Netherlands)",
  "Oostvoorne (Netherlands)",
  "Oostwold (Netherlands)",
  "Oostwoud (Netherlands)",
  "Oostzaan (Netherlands)",
  "Ootmarsum (Netherlands)",
  "Op den Bosch (Netherlands)",
  "Opeinde (Netherlands)",
  "Ophemert (Netherlands)",
  "Opheusden (Netherlands)",
  "Opmeer (Netherlands)",
  "Oranje (Netherlands)",
  "Oranjewoud (Netherlands)",
  "Ospel (Netherlands)",
  "Oss (Netherlands)",
  "Ossendrecht (Netherlands)",
  "Oterleek (Netherlands)",
  "Otterlo (Netherlands)",
  "Ottersum (Netherlands)",
  "Oud-Ade (Netherlands)",
  "Oud-Alblas (Netherlands)",
  "Oud-Beijerland (Netherlands)",
  "Oud-Gastel (Netherlands)",
  "Oud-Zuilen (Netherlands)",
  "Ouddorp (Netherlands)",
  "Oude Pekela (Netherlands)",
  "Oude Wetering (Netherlands)",
  "Oude-Tonge (Netherlands)",
  "Oudega (Netherlands)",
  "Oudehaske (Netherlands)",
  "Oudehorne (Netherlands)",
  "Oudelande (Netherlands)",
  "Oudemirdum (Netherlands)",
  "Oudemolen (Netherlands)",
  "Oudenbosch (Netherlands)",
  "Oudendijk (Netherlands)",
  "Ouderkerk aan de Amstel (Netherlands)",
  "Oudeschild (Netherlands)",
  "Oudesluis (Netherlands)",
  "Oudewater (Netherlands)",
  "Oudkarspel (Netherlands)",
  "Oudorp (Netherlands)",
  "Oudwoude (Netherlands)",
  "Overasselt (Netherlands)",
  "Overberg (Netherlands)",
  "Overdinkel (Netherlands)",
  "Overloon (Netherlands)",
  "Overveen (Netherlands)",
  "Ovezande (Netherlands)",
  "Palemig (Netherlands)",
  "Pannerden (Netherlands)",
  "Panningen (Netherlands)",
  "Papekop (Netherlands)",
  "Papendrecht (Netherlands)",
  "Partij (Netherlands)",
  "Paterswolde (Netherlands)",
  "Peize (Netherlands)",
  "Pernis (Netherlands)",
  "Petten (Netherlands)",
  "Philippine (Netherlands)",
  "Piershil (Netherlands)",
  "Pieterburen (Netherlands)",
  "Pieterzijl (Netherlands)",
  "Pijnacker (Netherlands)",
  "Pingjum (Netherlands)",
  "Plasmolen (Netherlands)",
  "Poeldijk (Netherlands)",
  "Poortugaal (Netherlands)",
  "Poortvliet (Netherlands)",
  "Posterholt (Netherlands)",
  "Princenhof (Netherlands)",
  "Prinsenbeek (Netherlands)",
  "Puiflijk (Netherlands)",
  "Purmerend (Netherlands)",
  "Purmerland (Netherlands)",
  "Puth (Netherlands)",
  "Putte (Netherlands)",
  "Putten (Netherlands)",
  "Puttershoek (Netherlands)",
  "Raalte (Netherlands)",
  "Raamsdonksveer (Netherlands)",
  "Randwijk (Netherlands)",
  "Ravenstein (Netherlands)",
  "Ravenswoud (Netherlands)",
  "Reeuwijk (Netherlands)",
  "Rekken (Netherlands)",
  "Renesse (Netherlands)",
  "Renkum (Netherlands)",
  "Renswoude (Netherlands)",
  "Ressen (Netherlands)",
  "Retranchement (Netherlands)",
  "Reusel (Netherlands)",
  "Reuver (Netherlands)",
  "Rheden (Netherlands)",
  "Rhenen (Netherlands)",
  "Rhenoy (Netherlands)",
  "Rhoon (Netherlands)",
  "Ridderkerk (Netherlands)",
  "Riethoven (Netherlands)",
  "Rietveld (Netherlands)",
  "Rijckholt (Netherlands)",
  "Rijen (Netherlands)",
  "Rijkevoort (Netherlands)",
  "Rijnsburg (Netherlands)",
  "Rijpwetering (Netherlands)",
  "Rijsbergen (Netherlands)",
  "Rijsenhout (Netherlands)",
  "Rijssen (Netherlands)",
  "Rijswijk (Netherlands)",
  "Rilland (Netherlands)",
  "Rinsumageast (Netherlands)",
  "Rips (Netherlands)",
  "Rivierenwijk (Netherlands)",
  "Rixtel (Netherlands)",
  "Rockanje (Netherlands)",
  "Roden (Netherlands)",
  "Rodenrijs (Netherlands)",
  "Roelofarendsveen (Netherlands)",
  "Roermond (Netherlands)",
  "Roggel (Netherlands)",
  "Rolde (Netherlands)",
  "Roodeschool (Netherlands)",
  "Roosendaal (Netherlands)",
  "Roosteren (Netherlands)",
  "Rooth (Netherlands)",
  "Rosmalen (Netherlands)",
  "Rossum (Netherlands)",
  "Rotterdam (Netherlands)",
  "Rottum (Netherlands)",
  "Rozenburg (Netherlands)",
  "Rozendaal (Netherlands)",
  "Rucphen (Netherlands)",
  "Ruinen (Netherlands)",
  "Ruinerwold (Netherlands)",
  "Rutten (Netherlands)",
  "Ruurlo (Netherlands)",
  "s-Heerenberg (Netherlands)",
  "Saasveld (Netherlands)",
  "Sambeek (Netherlands)",
  "Santpoort-Noord (Netherlands)",
  "Sappemeer (Netherlands)",
  "Sas van Gent (Netherlands)",
  "Sassenheim (Netherlands)",
  "Schagen (Netherlands)",
  "Schagerbrug (Netherlands)",
  "Schaijk (Netherlands)",
  "Schalkhaar (Netherlands)",
  "Scharmer (Netherlands)",
  "Scheemda (Netherlands)",
  "Schellinkhout (Netherlands)",
  "Schermerhorn (Netherlands)",
  "Scherpenisse (Netherlands)",
  "Scherpenzeel (Netherlands)",
  "Schiedam (Netherlands)",
  "Schiermonnikoog (Netherlands)",
  "Schijndel (Netherlands)",
  "Schildwolde (Netherlands)",
  "Schimmert (Netherlands)",
  "Schin op Geul (Netherlands)",
  "Schinnen (Netherlands)",
  "Schinveld (Netherlands)",
  "Schipborg (Netherlands)",
  "Schipluiden (Netherlands)",
  "Schoondijke (Netherlands)",
  "Schoonebeek (Netherlands)",
  "Schoonhoven (Netherlands)",
  "Schoorl (Netherlands)",
  "Schore (Netherlands)",
  "Sellingen (Netherlands)",
  "Serooskerke (Netherlands)",
  "Sevenum (Netherlands)",
  "Sibculo (Netherlands)",
  "Siddeburen (Netherlands)",
  "Siebengewald (Netherlands)",
  "Silvolde (Netherlands)",
  "Simpelveld (Netherlands)",
  "Sint Agatha (Netherlands)",
  "Sint Annaland (Netherlands)",
  "Sint Annaparochie (Netherlands)",
  "Sint Anthonis (Netherlands)",
  "Sint Hubert (Netherlands)",
  "Sint Jansteen (Netherlands)",
  "Sint Joost (Netherlands)",
  "Sint Maarten (Netherlands)",
  "Sint Maartensdijk (Netherlands)",
  "Sint Nicolaasga (Netherlands)",
  "Sint Odilienberg (Netherlands)",
  "Sint Pancras (Netherlands)",
  "Sint Philipsland (Netherlands)",
  "Sint Willebrord (Netherlands)",
  "Sint-Michielsgestel (Netherlands)",
  "Sint-Oedenrode (Netherlands)",
  "Sintjohannesga (Netherlands)",
  "Sittard (Netherlands)",
  "Slagharen (Netherlands)",
  "Sleen (Netherlands)",
  "Sleeuwijk (Netherlands)",
  "Slenaken (Netherlands)",
  "Sliedrecht (Netherlands)",
  "Slijk-Ewijk (Netherlands)",
  "Slochteren (Netherlands)",
  "Slootdorp (Netherlands)",
  "Sloterdijk (Netherlands)",
  "Sluis (Netherlands)",
  "Sluiskil (Netherlands)",
  "Smilde (Netherlands)",
  "Sneek (Netherlands)",
  "Soerendonk (Netherlands)",
  "Soest (Netherlands)",
  "Soesterberg (Netherlands)",
  "Someren (Netherlands)",
  "Sommelsdijk (Netherlands)",
  "Son en Breugel (Netherlands)",
  "Spaarndam (Netherlands)",
  "Spakenburg (Netherlands)",
  "Spanbroek (Netherlands)",
  "Spaubeek (Netherlands)",
  "Spierdijk (Netherlands)",
  "Spijk (Netherlands)",
  "Spijkenisse (Netherlands)",
  "Sprang (Netherlands)",
  "Sprundel (Netherlands)",
  "Stadskanaal (Netherlands)",
  "Stampersgat (Netherlands)",
  "Staphorst (Netherlands)",
  "Stationsbuurt (Netherlands)",
  "Stedum (Netherlands)",
  "Steenbergen (Netherlands)",
  "Steensel (Netherlands)",
  "Steenwijk (Netherlands)",
  "Steggerda (Netherlands)",
  "Steijl (Netherlands)",
  "Stein (Netherlands)",
  "Stellendam (Netherlands)",
  "Sterksel (Netherlands)",
  "Stevensbeek (Netherlands)",
  "Stevensweert (Netherlands)",
  "Stiens (Netherlands)",
  "Stolwijk (Netherlands)",
  "Stompetoren (Netherlands)",
  "Stompwijk (Netherlands)",
  "Stoutenburg (Netherlands)",
  "Strijen (Netherlands)",
  "Strijensas (Netherlands)",
  "Stroe (Netherlands)",
  "Stuifzand (Netherlands)",
  "Sumar (Netherlands)",
  "Surhuisterveen (Netherlands)",
  "Surhuizum (Netherlands)",
  "Susteren (Netherlands)",
  "Suwald (Netherlands)",
  "Swalmen (Netherlands)",
  "Sweikhuizen (Netherlands)",
  "Swifterbant (Netherlands)",
  "Taarlo (Netherlands)",
  "Tegelen (Netherlands)",
  "Ten Boer (Netherlands)",
  "Ten Post (Netherlands)",
  "Ter Aar (Netherlands)",
  "Ter Apel (Netherlands)",
  "Ter Apelkanaal (Netherlands)",
  "Terblijt (Netherlands)",
  "Terborg (Netherlands)",
  "Terheijden (Netherlands)",
  "Terneuzen (Netherlands)",
  "Terschuur (Netherlands)",
  "Teteringen (Netherlands)",
  "The Hague (Netherlands)",
  "Tholen (Netherlands)",
  "Tiel (Netherlands)",
  "Tienhoven (Netherlands)",
  "Tienraij (Netherlands)",
  "Tijnje (Netherlands)",
  "Tilburg (Netherlands)",
  "Tilligte (Netherlands)",
  "Tinte (Netherlands)",
  "Tjerkwerd (Netherlands)",
  "Tolbert (Netherlands)",
  "Toldijk (Netherlands)",
  "Tolkamer (Netherlands)",
  "Tricht (Netherlands)",
  "Tubbergen (Netherlands)",
  "Tuitjenhorn (Netherlands)",
  "Tull (Netherlands)",
  "Tweede Exloermond (Netherlands)",
  "Tweede Valthermond (Netherlands)",
  "Twello (Netherlands)",
  "Twijzelerheide (Netherlands)",
  "Twisk (Netherlands)",
  "Tynaarlo (Netherlands)",
  "Tytsjerk (Netherlands)",
  "Tzummarum (Netherlands)",
  "Uddel (Netherlands)",
  "Uden (Netherlands)",
  "Udenhout (Netherlands)",
  "Ugchelen (Netherlands)",
  "Uitdam (Netherlands)",
  "Uitgeest (Netherlands)",
  "Uithoorn (Netherlands)",
  "Uithuizen (Netherlands)",
  "Uithuizermeeden (Netherlands)",
  "Uitwellingerga (Netherlands)",
  "Ulestraten (Netherlands)",
  "Ulft (Netherlands)",
  "Ulicoten (Netherlands)",
  "Ulrum (Netherlands)",
  "Ulvenhout (Netherlands)",
  "Ureterp (Netherlands)",
  "Urk (Netherlands)",
  "Urmond (Netherlands)",
  "Ursem (Netherlands)",
  "Utrecht (Netherlands)",
  "Vaals (Netherlands)",
  "Vaassen (Netherlands)",
  "Valburg (Netherlands)",
  "Valkenburg (Netherlands)",
  "Valkenswaard (Netherlands)",
  "Valthe (Netherlands)",
  "Valthermond (Netherlands)",
  "Varik (Netherlands)",
  "Varsseveld (Netherlands)",
  "Vasse (Netherlands)",
  "Veen (Netherlands)",
  "Veendam (Netherlands)",
  "Veenendaal (Netherlands)",
  "Veenhuizen (Netherlands)",
  "Veenoord (Netherlands)",
  "Veghel (Netherlands)",
  "Velddriel (Netherlands)",
  "Velden (Netherlands)",
  "Veldhoven (Netherlands)",
  "Velp (Netherlands)",
  "Velsen (Netherlands)",
  "Velsen-Noord (Netherlands)",
  "Ven-Zelderheide (Netherlands)",
  "Venhorst (Netherlands)",
  "Venhuizen (Netherlands)",
  "Venlo (Netherlands)",
  "Venray (Netherlands)",
  "Vianen (Netherlands)",
  "Vierhouten (Netherlands)",
  "Vierlingsbeek (Netherlands)",
  "Vierpolders (Netherlands)",
  "Vijfhuizen (Netherlands)",
  "Vilt (Netherlands)",
  "Vinkeveen (Netherlands)",
  "Vlaardingen (Netherlands)",
  "Vlagtwedde (Netherlands)",
  "Vledder (Netherlands)",
  "Vleuten (Netherlands)",
  "Vlieland (Netherlands)",
  "Vlijmen (Netherlands)",
  "Vlodrop (Netherlands)",
  "Voerendaal (Netherlands)",
  "Vogelenzang (Netherlands)",
  "Vogelwaarde (Netherlands)",
  "Volendam (Netherlands)",
  "Volkel (Netherlands)",
  "Voorburg (Netherlands)",
  "Voorhout (Netherlands)",
  "Voorschoten (Netherlands)",
  "Voorst (Netherlands)",
  "Voorthuizen (Netherlands)",
  "Vorden (Netherlands)",
  "Vorstenbosch (Netherlands)",
  "Vortum-Mullem (Netherlands)",
  "Vragender (Netherlands)",
  "Vreeland (Netherlands)",
  "Vries (Netherlands)",
  "Vriezenveen (Netherlands)",
  "Vroomshoop (Netherlands)",
  "Vrouwenpolder (Netherlands)",
  "Vught (Netherlands)",
  "Vuren (Netherlands)",
  "Waalre (Netherlands)",
  "Waalwijk (Netherlands)",
  "Waardenburg (Netherlands)",
  "Waarder (Netherlands)",
  "Waarland (Netherlands)",
  "Waddinxveen (Netherlands)",
  "Wagenberg (Netherlands)",
  "Wagenborgen (Netherlands)",
  "Wageningen (Netherlands)",
  "Walterswald (Netherlands)",
  "Wamel (Netherlands)",
  "Wanroij (Netherlands)",
  "Wanssum (Netherlands)",
  "Wapenveld (Netherlands)",
  "Warder (Netherlands)",
  "Warffum (Netherlands)",
  "Warmenhuizen (Netherlands)",
  "Warmond (Netherlands)",
  "Warnsveld (Netherlands)",
  "Waspik (Netherlands)",
  "Wassenaar (Netherlands)",
  "Wateringen (Netherlands)",
  "Waterland (Netherlands)",
  "Weerselo (Netherlands)",
  "Weert (Netherlands)",
  "Weesp (Netherlands)",
  "Wehl (Netherlands)",
  "Weidum (Netherlands)",
  "Well (Netherlands)",
  "Wellerlooi (Netherlands)",
  "Wemeldinge (Netherlands)",
  "Werkendam (Netherlands)",
  "Werkhoven (Netherlands)",
  "Wervershoof (Netherlands)",
  "Wessem (Netherlands)",
  "West-Knollendam (Netherlands)",
  "West-Terschelling (Netherlands)",
  "Westbeemster (Netherlands)",
  "Westbroek (Netherlands)",
  "Westdorpe (Netherlands)",
  "Westerbork (Netherlands)",
  "Westerhaar-Vriezenveensewijk (Netherlands)",
  "Westerhoven (Netherlands)",
  "Westervoort (Netherlands)",
  "Westerwijtwerd (Netherlands)",
  "Westkapelle (Netherlands)",
  "Westmaas (Netherlands)",
  "Westzaan (Netherlands)",
  "Weurt (Netherlands)",
  "Wezep (Netherlands)",
  "Wierden (Netherlands)",
  "Wieringerwaard (Netherlands)",
  "Wieringerwerf (Netherlands)",
  "Wierum (Netherlands)",
  "Wijchen (Netherlands)",
  "Wijckel (Netherlands)",
  "Wijdenes (Netherlands)",
  "Wijdewormer (Netherlands)",
  "Wijhe (Netherlands)",
  "Wijk aan Zee (Netherlands)",
  "Wijk bij Duurstede (Netherlands)",
  "Wijlre (Netherlands)",
  "Wijnaldum (Netherlands)",
  "Wijnandsrade (Netherlands)",
  "Wijngaarden (Netherlands)",
  "Wijster (Netherlands)",
  "Wildervank (Netherlands)",
  "Willemstad (Netherlands)",
  "Wilnis (Netherlands)",
  "Wilp (Netherlands)",
  "Winkel (Netherlands)",
  "Winschoten (Netherlands)",
  "Winssen (Netherlands)",
  "Winsum (Netherlands)",
  "Wintelre (Netherlands)",
  "Winterswijk (Netherlands)",
  "Wissenkerke (Netherlands)",
  "Witharen (Netherlands)",
  "Wittem (Netherlands)",
  "Witteveen (Netherlands)",
  "Woensdrecht (Netherlands)",
  "Woerden (Netherlands)",
  "Woerdense Verlaat (Netherlands)",
  "Wognum (Netherlands)",
  "Wolfheze (Netherlands)",
  "Wolphaartsdijk (Netherlands)",
  "Woltersum (Netherlands)",
  "Wolvega (Netherlands)",
  "Wommels (Netherlands)",
  "Wons (Netherlands)",
  "Workum (Netherlands)",
  "Wormer (Netherlands)",
  "Wormerveer (Netherlands)",
  "Woubrugge (Netherlands)",
  "Woudenberg (Netherlands)",
  "Woudrichem (Netherlands)",
  "Woudsend (Netherlands)",
  "Wouwsche Plantage (Netherlands)",
  "Yde (Netherlands)",
  "Yerseke (Netherlands)",
  "Ysbrechtum (Netherlands)",
  "Zaamslag (Netherlands)",
  "Zaandam (Netherlands)",
  "Zaandijk (Netherlands)",
  "Zaanstad (Netherlands)",
  "Zalk (Netherlands)",
  "Zaltbommel (Netherlands)",
  "Zandeweer (Netherlands)",
  "Zandvoort (Netherlands)",
  "Zeddam (Netherlands)",
  "Zeeland (Netherlands)",
  "Zeerijp (Netherlands)",
  "Zeewolde (Netherlands)",
  "Zeist (Netherlands)",
  "Zelhem (Netherlands)",
  "Zetten (Netherlands)",
  "Zevenaar (Netherlands)",
  "Zevenbergen (Netherlands)",
  "Zevenbergschen Hoek (Netherlands)",
  "Zevenhoven (Netherlands)",
  "Zevenhuizen (Netherlands)",
  "Zierikzee (Netherlands)",
  "Zieuwent (Netherlands)",
  "Zijderveld (Netherlands)",
  "Zoetermeer (Netherlands)",
  "Zoeterwoude (Netherlands)",
  "Zonnemaire (Netherlands)",
  "Zorgvlied (Netherlands)",
  "Zoutelande (Netherlands)",
  "Zoutkamp (Netherlands)",
  "Zuid-Beijerland (Netherlands)",
  "Zuid-Scharwoude (Netherlands)",
  "Zuidbroek (Netherlands)",
  "Zuidermeer (Netherlands)",
  "Zuiderpark (Netherlands)",
  "Zuidhorn (Netherlands)",
  "Zuidland (Netherlands)",
  "Zuidlaren (Netherlands)",
  "Zuidoostbeemster (Netherlands)",
  "Zuidwolde (Netherlands)",
  "Zuidzande (Netherlands)",
  "Zuilichem (Netherlands)",
  "Zundert (Netherlands)",
  "Zutphen (Netherlands)",
  "Zwaag (Netherlands)",
  "Zwaagdijk-Oost (Netherlands)",
  "Zwaanshoek (Netherlands)",
  "Zwanenburg (Netherlands)",
  "Zwartebroek (Netherlands)",
  "Zwartemeer (Netherlands)",
  "Zwartewaal (Netherlands)",
  "Zwartsluis (Netherlands)",
  "Zwijndrecht (Netherlands)",
  "Zwinderen (Netherlands)",
  "Zwolle (Netherlands)",
  "New Caledonia",
  "Dumbea (New Caledonia)",
  "Mont-Dore (New Caledonia)",
  "Noumea (New Caledonia)",
  "Nouville (New Caledonia)",
  "Paita (New Caledonia)",
  "New Zealand",
  "Ahaura (New Zealand)",
  "Albany (New Zealand)",
  "Amberley (New Zealand)",
  "Ashhurst (New Zealand)",
  "Auckland (New Zealand)",
  "Avondale (New Zealand)",
  "Awanui (New Zealand)",
  "Balclutha (New Zealand)",
  "Balfour (New Zealand)",
  "Beachlands (New Zealand)",
  "Belmont (New Zealand)",
  "Bethlehem (New Zealand)",
  "Blackburn (New Zealand)",
  "Bombay (New Zealand)",
  "Brightwater (New Zealand)",
  "Browns Bay (New Zealand)",
  "Bulls (New Zealand)",
  "Campbells Bay (New Zealand)",
  "Cashmere Hills (New Zealand)",
  "Cave (New Zealand)",
  "Cheviot (New Zealand)",
  "Christchurch (New Zealand)",
  "Clarks (New Zealand)",
  "Clevedon (New Zealand)",
  "Clinton (New Zealand)",
  "Clive (New Zealand)",
  "Clyde (New Zealand)",
  "Coalgate (New Zealand)",
  "Coatesville (New Zealand)",
  "Collingwood (New Zealand)",
  "Colville (New Zealand)",
  "Coromandel (New Zealand)",
  "Darfield (New Zealand)",
  "Dargaville (New Zealand)",
  "Douglas (New Zealand)",
  "Doyleston (New Zealand)",
  "Drury (New Zealand)",
  "Dunedin (New Zealand)",
  "Duvauchelle (New Zealand)",
  "East Tamaki (New Zealand)",
  "Eastbourne (New Zealand)",
  "Edendale (New Zealand)",
  "Eltham (New Zealand)",
  "Fairlie (New Zealand)",
  "Favona (New Zealand)",
  "Foxton (New Zealand)",
  "Foxton Beach (New Zealand)",
  "Franz Josef (New Zealand)",
  "Gisborne (New Zealand)",
  "Glen Eden (New Zealand)",
  "Glenbrook (New Zealand)",
  "Greenhithe (New Zealand)",
  "Greerton (New Zealand)",
  "Greymouth (New Zealand)",
  "Haast (New Zealand)",
  "Halswell (New Zealand)",
  "Hamilton (New Zealand)",
  "Hastings (New Zealand)",
  "Havelock North (New Zealand)",
  "Hawarden (New Zealand)",
  "Hawera (New Zealand)",
  "Herne Bay (New Zealand)",
  "Hikuai (New Zealand)",
  "Hokitika (New Zealand)",
  "Howick (New Zealand)",
  "Hunterville (New Zealand)",
  "Invercargill (New Zealand)",
  "Johnsonville (New Zealand)",
  "Kaeo (New Zealand)",
  "Kaiata (New Zealand)",
  "Kaikoura (New Zealand)",
  "Kaitangata (New Zealand)",
  "Kaiwaka (New Zealand)",
  "Kaiwharawhara (New Zealand)",
  "Kamo (New Zealand)",
  "Karamea (New Zealand)",
  "Katikati (New Zealand)",
  "Kawakawa (New Zealand)",
  "Kawerau (New Zealand)",
  "Kawhia (New Zealand)",
  "Kerikeri (New Zealand)",
  "Khandallah (New Zealand)",
  "Kimbolton (New Zealand)",
  "Kirwee (New Zealand)",
  "Kohukohu (New Zealand)",
  "Kumeu (New Zealand)",
  "Kurow (New Zealand)",
  "Lake Tekapo (New Zealand)",
  "Leamington (New Zealand)",
  "Leeston (New Zealand)",
  "Levels (New Zealand)",
  "Levin (New Zealand)",
  "Lincoln (New Zealand)",
  "Linwood (New Zealand)",
  "Longburn (New Zealand)",
  "Lower Hutt (New Zealand)",
  "Lumsden (New Zealand)",
  "Mangaroa (New Zealand)",
  "Mangawhai Heads (New Zealand)",
  "Mangere (New Zealand)",
  "Mangonui (New Zealand)",
  "Manukau (New Zealand)",
  "Manunui (New Zealand)",
  "Marua (New Zealand)",
  "Masterton (New Zealand)",
  "Matakana (New Zealand)",
  "Maungatapere (New Zealand)",
  "Maungaturoto (New Zealand)",
  "Menzies Ferry (New Zealand)",
  "Methven (New Zealand)",
  "Milton (New Zealand)",
  "Mission Bay (New Zealand)",
  "Moerewa (New Zealand)",
  "Mosgiel (New Zealand)",
  "Mossburn (New Zealand)",
  "Mount Maunganui (New Zealand)",
  "Murupara (New Zealand)",
  "Myross Bush (New Zealand)",
  "Napier City (New Zealand)",
  "Nelson (New Zealand)",
  "New Plymouth (New Zealand)",
  "Ngaio (New Zealand)",
  "Ngatea (New Zealand)",
  "North Shore (New Zealand)",
  "Northcote Point (New Zealand)",
  "Northland (New Zealand)",
  "Oakura (New Zealand)",
  "Oamaru (New Zealand)",
  "Oban (New Zealand)",
  "Ohaupo (New Zealand)",
  "Ohura (New Zealand)",
  "Okaiawa (New Zealand)",
  "Okaihau (New Zealand)",
  "Okato (New Zealand)",
  "Onga Onga (New Zealand)",
  "Opaheke (New Zealand)",
  "Opotiki (New Zealand)",
  "Opunake (New Zealand)",
  "Orakei (New Zealand)",
  "Oratia (New Zealand)",
  "Orewa (New Zealand)",
  "Otaki (New Zealand)",
  "Otaki Beach (New Zealand)",
  "Otane (New Zealand)",
  "Otautau (New Zealand)",
  "Otematata (New Zealand)",
  "Otorohanga (New Zealand)",
  "Oturehua (New Zealand)",
  "Outram (New Zealand)",
  "Owaka (New Zealand)",
  "Oxford (New Zealand)",
  "Paekakariki (New Zealand)",
  "Paeroa (New Zealand)",
  "Paihia (New Zealand)",
  "Pakuranga (New Zealand)",
  "Palmerston North (New Zealand)",
  "Panmure (New Zealand)",
  "Papakura (New Zealand)",
  "Papamoa (New Zealand)",
  "Paradise (New Zealand)",
  "Paraparaumu (New Zealand)",
  "Paraparaumu Beach (New Zealand)",
  "Paremata (New Zealand)",
  "Parnell (New Zealand)",
  "Penrose (New Zealand)",
  "Petone (New Zealand)",
  "Piopio (New Zealand)",
  "Pirongia (New Zealand)",
  "Pleasant Point (New Zealand)",
  "Pokeno (New Zealand)",
  "Ponsonby (New Zealand)",
  "Porangahau (New Zealand)",
  "Porirua (New Zealand)",
  "Port Chalmers (New Zealand)",
  "Portland (New Zealand)",
  "Puhoi (New Zealand)",
  "Pukeatua (New Zealand)",
  "Punakaiki (New Zealand)",
  "Queenstown (New Zealand)",
  "Raglan (New Zealand)",
  "Rakaia (New Zealand)",
  "Rakaia Gorge (New Zealand)",
  "Ranfurly (New Zealand)",
  "Red Beach (New Zealand)",
  "Renwick (New Zealand)",
  "Reporoa (New Zealand)",
  "Riverhead (New Zealand)",
  "Riversdale (New Zealand)",
  "Riverton (New Zealand)",
  "Rolleston (New Zealand)",
  "Rotherham (New Zealand)",
  "Rotorua (New Zealand)",
  "Ruawai (New Zealand)",
  "Russell (New Zealand)",
  "Saint Heliers (New Zealand)",
  "Sawyers Bay (New Zealand)",
  "Sefton (New Zealand)",
  "Silverdale (New Zealand)",
  "Springs Junction (New Zealand)",
  "Stanmore Bay (New Zealand)",
  "Swanson (New Zealand)",
  "Tahoraiti (New Zealand)",
  "Takaka (New Zealand)",
  "Takanini (New Zealand)",
  "Takapau (New Zealand)",
  "Takapuna (New Zealand)",
  "Tamahere (New Zealand)",
  "Taradale (New Zealand)",
  "Tasman (New Zealand)",
  "Taupiri (New Zealand)",
  "Taupo (New Zealand)",
  "Tauranga (New Zealand)",
  "Te Anau (New Zealand)",
  "Te Atatu (New Zealand)",
  "Te Kauwhata (New Zealand)",
  "Te Roti (New Zealand)",
  "Templeton (New Zealand)",
  "Thames (New Zealand)",
  "Timaru (New Zealand)",
  "Tinwald (New Zealand)",
  "Tirau (New Zealand)",
  "Titirangi North (New Zealand)",
  "Tokomaru (New Zealand)",
  "Tokoroa (New Zealand)",
  "Torbay (New Zealand)",
  "Tuakau (New Zealand)",
  "Tuatapere (New Zealand)",
  "Turangi (New Zealand)",
  "Upper Moutere (New Zealand)",
  "Urenui (New Zealand)",
  "Waiau (New Zealand)",
  "Waiau Pa (New Zealand)",
  "Waihi (New Zealand)",
  "Waihi Beach (New Zealand)",
  "Waikanae (New Zealand)",
  "Waikari (New Zealand)",
  "Waikawa (New Zealand)",
  "Waikuku (New Zealand)",
  "Waikuku Beach (New Zealand)",
  "Waimana (New Zealand)",
  "Waimauku (New Zealand)",
  "Wainuiomata (New Zealand)",
  "Waiouru (New Zealand)",
  "Waipara (New Zealand)",
  "Waipawa (New Zealand)",
  "Waipu (New Zealand)",
  "Wairoa (New Zealand)",
  "Waitakere City (New Zealand)",
  "Waitangi (New Zealand)",
  "Waitara (New Zealand)",
  "Waitati (New Zealand)",
  "Waitoa (New Zealand)",
  "Waiuku (New Zealand)",
  "Wakefield (New Zealand)",
  "Walton (New Zealand)",
  "Wanaka (New Zealand)",
  "Wanganui (New Zealand)",
  "Warkworth (New Zealand)",
  "Waverley (New Zealand)",
  "Wellington (New Zealand)",
  "Wellsford (New Zealand)",
  "Wendon Valley (New Zealand)",
  "West Melton (New Zealand)",
  "Weston (New Zealand)",
  "Westport (New Zealand)",
  "Weymouth (New Zealand)",
  "Whakatane (New Zealand)",
  "Whangamata (New Zealand)",
  "Whangaparaoa (New Zealand)",
  "Whangarei (New Zealand)",
  "Whitianga (New Zealand)",
  "Winchester (New Zealand)",
  "Windermere (New Zealand)",
  "Winton (New Zealand)",
  "Woodend (New Zealand)",
  "Woolston (New Zealand)",
  "Wyndham (New Zealand)",
  "Yaldhurst (New Zealand)",
  "Nicaragua",
  "Bluefields (Nicaragua)",
  "Chinandega (Nicaragua)",
  "El Panama (Nicaragua)",
  "Esteli (Nicaragua)",
  "Granada (Nicaragua)",
  "Jinotega (Nicaragua)",
  "Los Arados (Nicaragua)",
  "Managua (Nicaragua)",
  "Masaya (Nicaragua)",
  "Matagalpa (Nicaragua)",
  "Ocotal (Nicaragua)",
  "Rivas (Nicaragua)",
  "San Juan del Sur (Nicaragua)",
  "Nigeria",
  "Aba (Nigeria)",
  "Abakaliki (Nigeria)",
  "Abeokuta (Nigeria)",
  "Abraka (Nigeria)",
  "Abuja (Nigeria)",
  "Ado-Ekiti (Nigeria)",
  "Adodo (Nigeria)",
  "Aganga (Nigeria)",
  "Agege (Nigeria)",
  "Agidingbi (Nigeria)",
  "Ajegunle (Nigeria)",
  "Ajuwon (Nigeria)",
  "Akure (Nigeria)",
  "Alimosho (Nigeria)",
  "Anambra (Nigeria)",
  "Apapa (Nigeria)",
  "Ayobo (Nigeria)",
  "Benin City (Nigeria)",
  "Birnin Kebbi (Nigeria)",
  "Bonny (Nigeria)",
  "Burutu (Nigeria)",
  "Bwari (Nigeria)",
  "Calabar (Nigeria)",
  "Chafe (Nigeria)",
  "Damaturu (Nigeria)",
  "Egbeda (Nigeria)",
  "Ekpoma (Nigeria)",
  "Enugu (Nigeria)",
  "Forum (Nigeria)",
  "Funtua (Nigeria)",
  "Ibadan (Nigeria)",
  "Ido (Nigeria)",
  "Ifako (Nigeria)",
  "Igando (Nigeria)",
  "Igueben (Nigeria)",
  "Ikeja (Nigeria)",
  "Ikorodu (Nigeria)",
  "Ikotun (Nigeria)",
  "Ile-Ife (Nigeria)",
  "Ilesa (Nigeria)",
  "Ilorin (Nigeria)",
  "Ipaja (Nigeria)",
  "Iseri-Oke (Nigeria)",
  "Isolo (Nigeria)",
  "Jalingo (Nigeria)",
  "Jos (Nigeria)",
  "Kaduna (Nigeria)",
  "Kano (Nigeria)",
  "Kebbi (Nigeria)",
  "Lagos (Nigeria)",
  "Lekki (Nigeria)",
  "Lokoja (Nigeria)",
  "Magodo (Nigeria)",
  "Makurdi (Nigeria)",
  "Maryland (Nigeria)",
  "Minna (Nigeria)",
  "Mogho (Nigeria)",
  "Mowe (Nigeria)",
  "Mushin (Nigeria)",
  "Nsukka (Nigeria)",
  "Obafemi (Nigeria)",
  "Obudu (Nigeria)",
  "Odau (Nigeria)",
  "Ojo (Nigeria)",
  "Ojota (Nigeria)",
  "Ondo (Nigeria)",
  "Onigbongbo (Nigeria)",
  "Orile Oshodi (Nigeria)",
  "Oshodi (Nigeria)",
  "Osogbo (Nigeria)",
  "Ota (Nigeria)",
  "Owerri (Nigeria)",
  "Oworonsoki (Nigeria)",
  "Port Harcourt (Nigeria)",
  "Shomolu (Nigeria)",
  "Suleja (Nigeria)",
  "Suru-Lere (Nigeria)",
  "Tara (Nigeria)",
  "Ughelli (Nigeria)",
  "Ungwan Madaki (Nigeria)",
  "Uyo (Nigeria)",
  "Warri (Nigeria)",
  "Yaba (Nigeria)",
  "Yola (Nigeria)",
  "Zaria (Nigeria)",
  "Norway",
  "Abelvaer (Norway)",
  "Adalsbruk (Norway)",
  "Adland (Norway)",
  "Agotnes (Norway)",
  "Agskardet (Norway)",
  "Aker (Norway)",
  "Akkarfjord (Norway)",
  "Akrehamn (Norway)",
  "Al (Norway)",
  "Alen (Norway)",
  "Algard (Norway)",
  "Almas (Norway)",
  "Alta (Norway)",
  "Alvdal (Norway)",
  "Amli (Norway)",
  "Amot (Norway)",
  "Ana-Sira (Norway)",
  "Andalsnes (Norway)",
  "Andenes (Norway)",
  "Angvika (Norway)",
  "Ankenes (Norway)",
  "Annstad (Norway)",
  "Ardal (Norway)",
  "Ardalstangen (Norway)",
  "Arendal (Norway)",
  "Arland (Norway)",
  "Arneberg (Norway)",
  "Arnes (Norway)",
  "Aros (Norway)",
  "As (Norway)",
  "Asen (Norway)",
  "Aseral (Norway)",
  "Asgardstrand (Norway)",
  "Ask (Norway)",
  "Asker (Norway)",
  "Askim (Norway)",
  "Aukra (Norway)",
  "Auli (Norway)",
  "Aurdal (Norway)",
  "Aure (Norway)",
  "Aursmoen (Norway)",
  "Austbo (Norway)",
  "Austbygdi (Norway)",
  "Austevoll (Norway)",
  "Austmarka (Norway)",
  "Baerums verk (Norway)",
  "Bagn (Norway)",
  "Balestrand (Norway)",
  "Ballangen (Norway)",
  "Ballstad (Norway)",
  "Bangsund (Norway)",
  "Barkaker (Norway)",
  "Barstadvik (Norway)",
  "Batnfjordsora (Norway)",
  "Batsto (Norway)",
  "Beisfjord (Norway)",
  "Beitostolen (Norway)",
  "Bekkjarvik (Norway)",
  "Berge (Norway)",
  "Bergen (Norway)",
  "Berger (Norway)",
  "Berkak (Norway)",
  "Birkeland (Norway)",
  "Birtavarre (Norway)",
  "Bjaland (Norway)",
  "Bjerka (Norway)",
  "Bjerkvik (Norway)",
  "Bjoneroa (Norway)",
  "Bjordal (Norway)",
  "Bjorke (Norway)",
  "Bjorkelangen (Norway)",
  "Bjornevatn (Norway)",
  "Blaker (Norway)",
  "Blakset (Norway)",
  "Bleikvasslia (Norway)",
  "Bo (Norway)",
  "Bomlo (Norway)",
  "Bones (Norway)",
  "Borge (Norway)",
  "Borgen (Norway)",
  "Borhaug (Norway)",
  "Borkenes (Norway)",
  "Borregard (Norway)",
  "Bostad (Norway)",
  "Bovagen (Norway)",
  "Boverfjorden (Norway)",
  "Brandbu (Norway)",
  "Brandval (Norway)",
  "Brattholmen (Norway)",
  "Brattvag (Norway)",
  "Brekke (Norway)",
  "Brekstad (Norway)",
  "Brennasen (Norway)",
  "Brevik (Norway)",
  "Bronnoysund (Norway)",
  "Bru (Norway)",
  "Bruflat (Norway)",
  "Brumunddal (Norway)",
  "Brusand (Norway)",
  "Bruvik (Norway)",
  "Bryne (Norway)",
  "Bud (Norway)",
  "Burfjord (Norway)",
  "Buskerud (Norway)",
  "Buvika (Norway)",
  "Byglandsfjord (Norway)",
  "Bygstad (Norway)",
  "Bykle (Norway)",
  "Byrknes Nordre (Norway)",
  "Cavkkus (Norway)",
  "Dal (Norway)",
  "Dale (Norway)",
  "Dalen (Norway)",
  "Davik (Norway)",
  "Deknepollen (Norway)",
  "Digermulen (Norway)",
  "Dilling (Norway)",
  "Dimmelsvik (Norway)",
  "Dirdal (Norway)",
  "Disena (Norway)",
  "Dokka (Norway)",
  "Dolemo (Norway)",
  "Dovre (Norway)",
  "Drag (Norway)",
  "Drammen (Norway)",
  "Drangedal (Norway)",
  "Drobak (Norway)",
  "Dverberg (Norway)",
  "Dyrvika (Norway)",
  "Ebru (Norway)",
  "Egersund (Norway)",
  "Eggedal (Norway)",
  "Eggkleiva (Norway)",
  "Eide (Norway)",
  "Eidfjord (Norway)",
  "Eidsa (Norway)",
  "Eidsberg (Norway)",
  "Eidsdal (Norway)",
  "Eidsfoss (Norway)",
  "Eidsnes (Norway)",
  "Eidsvag (Norway)",
  "Eidsvoll (Norway)",
  "Eidsvoll verk (Norway)",
  "Eikanger (Norway)",
  "Eikelandsosen (Norway)",
  "Eiken (Norway)",
  "Eina (Norway)",
  "Eivindvik (Norway)",
  "Elverum (Norway)",
  "Enebakkneset (Norway)",
  "Enga (Norway)",
  "Engalsvik (Norway)",
  "Erdal (Norway)",
  "Erfjord (Norway)",
  "Ervik (Norway)",
  "Espeland (Norway)",
  "Etne (Norway)",
  "Evanger (Norway)",
  "Evenskjer (Norway)",
  "Evje (Norway)",
  "Eydehavn (Norway)",
  "Faberg (Norway)",
  "Faervik (Norway)",
  "Fagernes (Norway)",
  "Fagerstrand (Norway)",
  "Fall (Norway)",
  "Fardal (Norway)",
  "Farsund (Norway)",
  "Fauske (Norway)",
  "Feda (Norway)",
  "Fedje (Norway)",
  "Feiring (Norway)",
  "Felle (Norway)",
  "Fenstad (Norway)",
  "Fetsund (Norway)",
  "Fevik (Norway)",
  "Figgjo (Norway)",
  "Finnoy (Norway)",
  "Finnsnes (Norway)",
  "Finsand (Norway)",
  "Fiska (Norway)",
  "Fiskum (Norway)",
  "Fister (Norway)",
  "Fitjar (Norway)",
  "Fjellstrand (Norway)",
  "Fla (Norway)",
  "Flam (Norway)",
  "Flateby (Norway)",
  "Flekke (Norway)",
  "Flekkefjord (Norway)",
  "Flemma (Norway)",
  "Flesberg (Norway)",
  "Flesnes (Norway)",
  "Floro (Norway)",
  "Florvag (Norway)",
  "Foldereid (Norway)",
  "Folderoy (Norway)",
  "Folkestad (Norway)",
  "Follafoss (Norway)",
  "Follebu (Norway)",
  "Follese (Norway)",
  "Fonnes (Norway)",
  "Forde (Norway)",
  "Fornebu (Norway)",
  "Fosnavag (Norway)",
  "Fossdalen (Norway)",
  "Fosser (Norway)",
  "Fotlandsvag (Norway)",
  "Fredrikstad (Norway)",
  "Frekhaug (Norway)",
  "Fresvik (Norway)",
  "Frogner (Norway)",
  "Froland (Norway)",
  "From (Norway)",
  "Furnes (Norway)",
  "Fyrde (Norway)",
  "Fyresdal (Norway)",
  "Gan (Norway)",
  "Gardermoen (Norway)",
  "Gargan (Norway)",
  "Garnes (Norway)",
  "Gasbakken (Norway)",
  "Gaupen (Norway)",
  "Geilo (Norway)",
  "Geithus (Norway)",
  "Gjerdrum (Norway)",
  "Gjerstad (Norway)",
  "Gjolme (Norway)",
  "Glesvaer (Norway)",
  "Glomfjord (Norway)",
  "Godoy (Norway)",
  "Godvik (Norway)",
  "Gol (Norway)",
  "Gran (Norway)",
  "Gransherad (Norway)",
  "Granvin (Norway)",
  "Gratangen (Norway)",
  "Gravdal (Norway)",
  "Greaker (Norway)",
  "Grendi (Norway)",
  "Gressvik (Norway)",
  "Grimstad (Norway)",
  "Groa (Norway)",
  "Grong (Norway)",
  "Grua (Norway)",
  "Gullaug (Norway)",
  "Gvarv (Norway)",
  "Haddal (Norway)",
  "Haegeland (Norway)",
  "Haerland (Norway)",
  "Hagan (Norway)",
  "Hagavik (Norway)",
  "Hakadal (Norway)",
  "Halden (Norway)",
  "Hallingby (Norway)",
  "Halsa (Norway)",
  "Haltdalen (Norway)",
  "Hamar (Norway)",
  "Hamarvik (Norway)",
  "Hammerfest (Norway)",
  "Hansnes (Norway)",
  "Haram (Norway)",
  "Hareid (Norway)",
  "Harstad (Norway)",
  "Haslum (Norway)",
  "Hasvik (Norway)",
  "Hatlestranda (Norway)",
  "Hauge (Norway)",
  "Haugesund (Norway)",
  "Haukeland (Norway)",
  "Havik (Norway)",
  "Hebnes (Norway)",
  "Hedal (Norway)",
  "Heggedal (Norway)",
  "Heggenes (Norway)",
  "Hegra (Norway)",
  "Heimdal (Norway)",
  "Helgeland (Norway)",
  "Helgeroa (Norway)",
  "Hell (Norway)",
  "Hellandsjoen (Norway)",
  "Helleland (Norway)",
  "Hellesylt (Norway)",
  "Hellvik (Norway)",
  "Hemnes (Norway)",
  "Hemnesberget (Norway)",
  "Hemnskjela (Norway)",
  "Hemsedal (Norway)",
  "Henningsvaer (Norway)",
  "Herand (Norway)",
  "Heroysund (Norway)",
  "Herre (Norway)",
  "Hersaeter (Norway)",
  "Hestvika (Norway)",
  "Hetlevik (Norway)",
  "Hildre (Norway)",
  "Hitra (Norway)",
  "Hjellestad (Norway)",
  "Hjelmas (Norway)",
  "Hjelset (Norway)",
  "Hjorungavag (Norway)",
  "Hof (Norway)",
  "Hokkasen (Norway)",
  "Hokksund (Norway)",
  "Hol (Norway)",
  "Hole (Norway)",
  "Holen (Norway)",
  "Holmefjord (Norway)",
  "Holmen (Norway)",
  "Holmenkollen (Norway)",
  "Holmestrand (Norway)",
  "Holsen (Norway)",
  "Holter (Norway)",
  "Hommelvik (Norway)",
  "Hommersak (Norway)",
  "Honefoss (Norway)",
  "Hordvik (Norway)",
  "Hornnes (Norway)",
  "Horte (Norway)",
  "Horten (Norway)",
  "Hov (Norway)",
  "Hovag (Norway)",
  "Hovden (Norway)",
  "Hovet (Norway)",
  "Hovik verk (Norway)",
  "Hovin (Norway)",
  "Hoyanger (Norway)",
  "Hundven (Norway)",
  "Hunndalen (Norway)",
  "Husoy (Norway)",
  "Hustad (Norway)",
  "Hvalstad (Norway)",
  "Hvam (Norway)",
  "Hvitsten (Norway)",
  "Hvittingfoss (Norway)",
  "Hyggen (Norway)",
  "Hylkje (Norway)",
  "Hyllestad (Norway)",
  "Ikornnes (Norway)",
  "Indre Arna (Norway)",
  "Indre Billefjord (Norway)",
  "Indre Klubben (Norway)",
  "Indre Ulvsvag (Norway)",
  "Indreby (Norway)",
  "Innbygda (Norway)",
  "Inndyr (Norway)",
  "Innvik (Norway)",
  "Isdalsto (Norway)",
  "Ise (Norway)",
  "Ivgobahta (Norway)",
  "Jakobselv (Norway)",
  "Jar (Norway)",
  "Jaren (Norway)",
  "Jessheim (Norway)",
  "Jevnaker (Norway)",
  "Jomna (Norway)",
  "Jorpeland (Norway)",
  "Kabelvag (Norway)",
  "Kaldfarnes (Norway)",
  "Kalvag (Norway)",
  "Kamben (Norway)",
  "Karasjok (Norway)",
  "Karlshus (Norway)",
  "Kaupanger (Norway)",
  "Kautokeino (Norway)",
  "Kirkenaer (Norway)",
  "Kirkenes (Norway)",
  "Kjeller (Norway)",
  "Kjellmyra (Norway)",
  "Kjerstad (Norway)",
  "Kjollefjord (Norway)",
  "Kjopsvik (Norway)",
  "Kleive (Norway)",
  "Klepp (Norway)",
  "Kleppe (Norway)",
  "Kleppesto (Norway)",
  "Kleppstad (Norway)",
  "Klofta (Norway)",
  "Klokkarvik (Norway)",
  "Knapper (Norway)",
  "Knappstad (Norway)",
  "Knarrevik (Norway)",
  "Knarrlaget (Norway)",
  "Kolbjornsvik (Norway)",
  "Kolbotn (Norway)",
  "Kolbu (Norway)",
  "Kolltveit (Norway)",
  "Kolnes (Norway)",
  "Kolsas (Norway)",
  "Kolvereid (Norway)",
  "Kongsberg (Norway)",
  "Kongshamn (Norway)",
  "Kongsvika (Norway)",
  "Kongsvinger (Norway)",
  "Konsmo (Norway)",
  "Konsvikosen (Norway)",
  "Kopervik (Norway)",
  "Koppang (Norway)",
  "Korgen (Norway)",
  "Kornsjo (Norway)",
  "Korsvegen (Norway)",
  "Kragero (Norway)",
  "Krakeroy (Norway)",
  "Krakstad (Norway)",
  "Kristiansand (Norway)",
  "Kristiansund (Norway)",
  "Kroderen (Norway)",
  "Krokstadelva (Norway)",
  "Kval (Norway)",
  "Kvalsund (Norway)",
  "Kvam (Norway)",
  "Kvammen (Norway)",
  "Kvanne (Norway)",
  "Kvelde (Norway)",
  "Kvinesdal (Norway)",
  "Kvinlog (Norway)",
  "Kvisvik (Norway)",
  "Kviteseid (Norway)",
  "Kyrkjebo (Norway)",
  "Kyrksaeterora (Norway)",
  "Lakselv (Norway)",
  "Laksevag (Norway)",
  "Laksvatn (Norway)",
  "Lalm (Norway)",
  "Land (Norway)",
  "Langangen (Norway)",
  "Langesund (Norway)",
  "Langevag (Norway)",
  "Langfjordbotn (Norway)",
  "Langhus (Norway)",
  "Larkollen (Norway)",
  "Larvik (Norway)",
  "Laukvik (Norway)",
  "Lauvsnes (Norway)",
  "Lauvstad (Norway)",
  "Leikang (Norway)",
  "Leines (Norway)",
  "Leira (Norway)",
  "Leirfjord (Norway)",
  "Leirsund (Norway)",
  "Leirvik (Norway)",
  "Leknes (Norway)",
  "Lena (Norway)",
  "Lensvik (Norway)",
  "Lenvik (Norway)",
  "Lepsoy (Norway)",
  "Levanger (Norway)",
  "Lidaladdi (Norway)",
  "Lier (Norway)",
  "Lillehammer (Norway)",
  "Lillesand (Norway)",
  "Lindas (Norway)",
  "Loddefjord (Norway)",
  "Lodingen (Norway)",
  "Loen (Norway)",
  "Lofthus (Norway)",
  "Loken (Norway)",
  "Lokken Verk (Norway)",
  "Lom (Norway)",
  "Lonevag (Norway)",
  "Longva (Norway)",
  "Lorenfallet (Norway)",
  "Loten (Norway)",
  "Lovund (Norway)",
  "Lundamo (Norway)",
  "Lunde (Norway)",
  "Lunner (Norway)",
  "Lyngdal (Norway)",
  "Lyngseidet (Norway)",
  "Lyngstad (Norway)",
  "Lysaker (Norway)",
  "Lysoysundet (Norway)",
  "Magnor (Norway)",
  "Malm (Norway)",
  "Maloy (Norway)",
  "Malvik (Norway)",
  "Mandal (Norway)",
  "Manger (Norway)",
  "Manndalen (Norway)",
  "Marheim (Norway)",
  "Masfjorden (Norway)",
  "Mathopen (Norway)",
  "Maura (Norway)",
  "Mehamn (Norway)",
  "Meisingset (Norway)",
  "Melbu (Norway)",
  "Meldal (Norway)",
  "Melhus (Norway)",
  "Melsomvik (Norway)",
  "Meraker (Norway)",
  "Mestervik (Norway)",
  "Midsund (Norway)",
  "Miland (Norway)",
  "Minnesund (Norway)",
  "Mirza Rafi Sauda (Norway)",
  "Misje (Norway)",
  "Misvaer (Norway)",
  "Mjolkeraen (Norway)",
  "Mjondalen (Norway)",
  "Mo (Norway)",
  "Mo i Rana (Norway)",
  "Modalen (Norway)",
  "Moelv (Norway)",
  "Moen (Norway)",
  "Moi (Norway)",
  "Molde (Norway)",
  "Moldjord (Norway)",
  "Morgedal (Norway)",
  "Mosby (Norway)",
  "Mosjoen (Norway)",
  "Moss (Norway)",
  "Movik (Norway)",
  "Myking (Norway)",
  "Myre (Norway)",
  "Mysen (Norway)",
  "Na (Norway)",
  "Naerbo (Norway)",
  "Naersnes (Norway)",
  "Namsos (Norway)",
  "Namsskogan (Norway)",
  "Narvik (Norway)",
  "Naustdal (Norway)",
  "Nedenes (Norway)",
  "Nedre Frei (Norway)",
  "Nesbru (Norway)",
  "Nesbyen (Norway)",
  "Nesgrenda (Norway)",
  "Nesna (Norway)",
  "Nesoddtangen (Norway)",
  "Nesttun (Norway)",
  "Neverdal (Norway)",
  "Nevlunghamn (Norway)",
  "Nodeland (Norway)",
  "Nordby Bruk (Norway)",
  "Nordfjordeid (Norway)",
  "Nordkisa (Norway)",
  "Nordland (Norway)",
  "Nordstrono (Norway)",
  "Noresund (Norway)",
  "Norheimsund (Norway)",
  "Notodden (Norway)",
  "Nybergsund (Norway)",
  "Nyborg (Norway)",
  "Nydalen (Norway)",
  "Nygardsjoen (Norway)",
  "Nyhus (Norway)",
  "Nykirke (Norway)",
  "Odda (Norway)",
  "Odnes (Norway)",
  "Oksfjord (Norway)",
  "Oksvoll (Norway)",
  "Olden (Norway)",
  "Olderdalen (Norway)",
  "Olen (Norway)",
  "Oltedal (Norway)",
  "Oma (Norway)",
  "Onarheim (Norway)",
  "Oppdal (Norway)",
  "Oppegard (Norway)",
  "Opphaug (Norway)",
  "Oresvika (Norway)",
  "Orje (Norway)",
  "Orkanger (Norway)",
  "Ornes (Norway)",
  "Orre (Norway)",
  "Os (Norway)",
  "Oslo (Norway)",
  "Otta (Norway)",
  "Otteroy (Norway)",
  "Ottestad (Norway)",
  "Oveland (Norway)",
  "Ovre Ardal (Norway)",
  "Ovrebo (Norway)",
  "Oyeren (Norway)",
  "Oystese (Norway)",
  "Porsgrunn (Norway)",
  "Prestfoss (Norway)",
  "Raholt (Norway)",
  "Rakkestad (Norway)",
  "Ramberg (Norway)",
  "Ramfjordbotn (Norway)",
  "Ramnes (Norway)",
  "Rana (Norway)",
  "Ranasfoss (Norway)",
  "Randaberg (Norway)",
  "Ranheim (Norway)",
  "Raudeberg (Norway)",
  "Raudsand (Norway)",
  "Raufoss (Norway)",
  "Rauland (Norway)",
  "Re (Norway)",
  "Reine (Norway)",
  "Reinsvoll (Norway)",
  "Reipa (Norway)",
  "Reistad (Norway)",
  "Reitan (Norway)",
  "Rena (Norway)",
  "Rennebu (Norway)",
  "Rindal (Norway)",
  "Ringebu (Norway)",
  "Ringsaker (Norway)",
  "Ringstad (Norway)",
  "Risoyhamn (Norway)",
  "Rjukan (Norway)",
  "Roa (Norway)",
  "Rodberg (Norway)",
  "Rodoy (Norway)",
  "Rognan (Norway)",
  "Rogne (Norway)",
  "Rokland (Norway)",
  "Roldal (Norway)",
  "Rollag (Norway)",
  "Rolvsoy (Norway)",
  "Romedal (Norway)",
  "Rong (Norway)",
  "Roros (Norway)",
  "Rorvik (Norway)",
  "Rosendal (Norway)",
  "Rossland (Norway)",
  "Rost (Norway)",
  "Rovde (Norway)",
  "Roverud (Norway)",
  "Royken (Norway)",
  "Royneberg (Norway)",
  "Rubbestadneset (Norway)",
  "Rud (Norway)",
  "Rygge (Norway)",
  "Rykene (Norway)",
  "Rypefjord (Norway)",
  "Saebo (Norway)",
  "Saebovik (Norway)",
  "Saetre (Norway)",
  "Saevareid (Norway)",
  "Saeveland (Norway)",
  "Sagvag (Norway)",
  "Salhus (Norway)",
  "Salsbruket (Norway)",
  "Salsnes (Norway)",
  "Saltnes (Norway)",
  "Samuelsberg (Norway)",
  "Sand (Norway)",
  "Sandane (Norway)",
  "Sande (Norway)",
  "Sandefjord (Norway)",
  "Sandeid (Norway)",
  "Sander (Norway)",
  "Sandnes (Norway)",
  "Sandnessjoen (Norway)",
  "Sandshamn (Norway)",
  "Sandstad (Norway)",
  "Sandtorg (Norway)",
  "Sandvika (Norway)",
  "Sandvoll (Norway)",
  "Sannidal (Norway)",
  "Sarpsborg (Norway)",
  "Saupstad (Norway)",
  "Selasvatn (Norway)",
  "Selje (Norway)",
  "Seljord (Norway)",
  "Sellebakk (Norway)",
  "Selva (Norway)",
  "Selvaer (Norway)",
  "Sem (Norway)",
  "Setermoen (Norway)",
  "Siggerud (Norway)",
  "Siljan (Norway)",
  "Silsand (Norway)",
  "Singsas (Norway)",
  "Sira (Norway)",
  "Sirevag (Norway)",
  "Sistranda (Norway)",
  "Sjovegan (Norway)",
  "Skabu (Norway)",
  "Skage (Norway)",
  "Skanevik (Norway)",
  "Skarer (Norway)",
  "Skarnes (Norway)",
  "Skatoy (Norway)",
  "Skaun (Norway)",
  "Skedsmokorset (Norway)",
  "Skeie (Norway)",
  "Ski (Norway)",
  "Skien (Norway)",
  "Skjeberg (Norway)",
  "Skjerstad (Norway)",
  "Skjervoy (Norway)",
  "Skjold (Norway)",
  "Skjoldastraumen (Norway)",
  "Skjolden (Norway)",
  "Skodje (Norway)",
  "Skogn (Norway)",
  "Skoppum (Norway)",
  "Skotbu (Norway)",
  "Skotterud (Norway)",
  "Skreia (Norway)",
  "Skudeneshavn (Norway)",
  "Skulsfjord (Norway)",
  "Skutvika (Norway)",
  "Slastad (Norway)",
  "Slattum (Norway)",
  "Slemdal (Norway)",
  "Slemmestad (Norway)",
  "Sletta (Norway)",
  "Snaase (Norway)",
  "Snillfjord (Norway)",
  "Sogn (Norway)",
  "Sokna (Norway)",
  "Sokndal (Norway)",
  "Soknedal (Norway)",
  "Sola (Norway)",
  "Solbergelva (Norway)",
  "Solvorn (Norway)",
  "Sommaroy (Norway)",
  "Somna (Norway)",
  "Son (Norway)",
  "Sondeled (Norway)",
  "Sor-Fron (Norway)",
  "Sorbo (Norway)",
  "Soreidgrenda (Norway)",
  "Sorli (Norway)",
  "Sortland (Norway)",
  "Sorum (Norway)",
  "Sorumsand (Norway)",
  "Sorvaer (Norway)",
  "Sorvagen (Norway)",
  "Sorvik (Norway)",
  "Spangereid (Norway)",
  "Sparbu (Norway)",
  "Sperrebotn (Norway)",
  "Spillum (Norway)",
  "Spydeberg (Norway)",
  "Stabbestad (Norway)",
  "Stabekk (Norway)",
  "Stamnes (Norway)",
  "Stamsund (Norway)",
  "Stange (Norway)",
  "Stathelle (Norway)",
  "Staubo (Norway)",
  "Stavanger (Norway)",
  "Stavern (Norway)",
  "Steigen (Norway)",
  "Steinberg (Norway)",
  "Steinkjer (Norway)",
  "Steinsdalen (Norway)",
  "Sto (Norway)",
  "Stokke (Norway)",
  "Stokmarknes (Norway)",
  "Stol (Norway)",
  "Storas (Norway)",
  "Stordal (Norway)",
  "Storebo (Norway)",
  "Storforshei (Norway)",
  "Storslett (Norway)",
  "Storsteinnes (Norway)",
  "Stranda (Norway)",
  "Straume (Norway)",
  "Straumen (Norway)",
  "Strommen (Norway)",
  "Stronstad (Norway)",
  "Strusshamn (Norway)",
  "Stryn (Norway)",
  "Suldalsosen (Norway)",
  "Sulisjielmma (Norway)",
  "Sund (Norway)",
  "Sundal (Norway)",
  "Sunde (Norway)",
  "Sunndalsora (Norway)",
  "Surnadalsora (Norway)",
  "Svarstad (Norway)",
  "Svartskog (Norway)",
  "Sveio (Norway)",
  "Svelgen (Norway)",
  "Svelvik (Norway)",
  "Svene (Norway)",
  "Svortland (Norway)",
  "Sylling (Norway)",
  "Syvik (Norway)",
  "Tafjord (Norway)",
  "Talvik (Norway)",
  "Tananger (Norway)",
  "Tanem (Norway)",
  "Tangen (Norway)",
  "Tau (Norway)",
  "Tennevoll (Norway)",
  "Tennfjord (Norway)",
  "Tertnes (Norway)",
  "Tiller (Norway)",
  "Tingvoll (Norway)",
  "Tistedal (Norway)",
  "Tjeldsto (Norway)",
  "Tjelta (Norway)",
  "Tjong (Norway)",
  "Tjorvag (Norway)",
  "Tjotta (Norway)",
  "Tofte (Norway)",
  "Tolga (Norway)",
  "Tomasjorda (Norway)",
  "Tomter (Norway)",
  "Tonstad (Norway)",
  "Tornes (Norway)",
  "Torod (Norway)",
  "Torp (Norway)",
  "Torpo (Norway)",
  "Tovik (Norway)",
  "Trana (Norway)",
  "Tranby (Norway)",
  "Trengereid (Norway)",
  "Tretten (Norway)",
  "Treungen (Norway)",
  "Trofors (Norway)",
  "Trollfjorden (Norway)",
  "Tromsdalen (Norway)",
  "Trondheim (Norway)",
  "Trones (Norway)",
  "Turoy (Norway)",
  "Tvedestrand (Norway)",
  "Tveit (Norway)",
  "Tynset (Norway)",
  "Tyristrand (Norway)",
  "Tysnes (Norway)",
  "Tysse (Norway)",
  "Tyssedal (Norway)",
  "Uggdal (Norway)",
  "Ulefoss (Norway)",
  "Ulstein (Norway)",
  "Ulsteinvik (Norway)",
  "Ulvagen (Norway)",
  "Ulvik (Norway)",
  "Undeim (Norway)",
  "Uskedalen (Norway)",
  "Utsira (Norway)",
  "Utskarpen (Norway)",
  "Uvdal (Norway)",
  "Vadheim (Norway)",
  "Vage (Norway)",
  "Vagland (Norway)",
  "Vaksdal (Norway)",
  "Vale (Norway)",
  "Valen (Norway)",
  "Valer (Norway)",
  "Valestrand (Norway)",
  "Valestrandfossen (Norway)",
  "Valldal (Norway)",
  "Valle (Norway)",
  "Valsoyfjord (Norway)",
  "Vangsvika (Norway)",
  "Vannvag (Norway)",
  "Vanse (Norway)",
  "Varangerbotn (Norway)",
  "Varhaug (Norway)",
  "Vassenden (Norway)",
  "Vatne (Norway)",
  "Vedavagen (Norway)",
  "Vegarshei (Norway)",
  "Veggli (Norway)",
  "Venabygd (Norway)",
  "Vennesla (Norway)",
  "Verdal (Norway)",
  "Vestby (Norway)",
  "Vestfossen (Norway)",
  "Vestnes (Norway)",
  "Vestra Mosterhamn (Norway)",
  "Vestre Gausdal (Norway)",
  "Vevang (Norway)",
  "Vevelstad (Norway)",
  "Vigrestad (Norway)",
  "Vikebygd (Norway)",
  "Vikedal (Norway)",
  "Vikersund (Norway)",
  "Vikesa (Norway)",
  "Vikran (Norway)",
  "Vingelen (Norway)",
  "Vinje (Norway)",
  "Vinstra (Norway)",
  "Voksa (Norway)",
  "Volda (Norway)",
  "Vollen (Norway)",
  "Vormedal (Norway)",
  "Vormsund (Norway)",
  "Voss (Norway)",
  "Vossestrand (Norway)",
  "Vraliosen (Norway)",
  "Ytre Alvik (Norway)",
  "Oman",
  "Al Sohar (Oman)",
  "Muscat (Oman)",
  "Nizwa (Oman)",
  "Ruwi (Oman)",
  "Saham (Oman)",
  "Salalah (Oman)",
  "Samad (Oman)",
  "Pakistan",
  "Abbottabad (Pakistan)",
  "Attock (Pakistan)",
  "Batgram (Pakistan)",
  "Bhimbar (Pakistan)",
  "Burewala (Pakistan)",
  "Cantt (Pakistan)",
  "Chakwal (Pakistan)",
  "Clifton (Pakistan)",
  "Daska (Pakistan)",
  "Daud Khel (Pakistan)",
  "Dera Ghazi Khan (Pakistan)",
  "Faisalabad (Pakistan)",
  "Fazal (Pakistan)",
  "Gilgit (Pakistan)",
  "Goth Abad Magsi (Pakistan)",
  "Gujar Khan (Pakistan)",
  "Gujranwala (Pakistan)",
  "Gujrat (Pakistan)",
  "Gulberg (Pakistan)",
  "Gulshan-e-Iqbal (Pakistan)",
  "Habib Baihk (Pakistan)",
  "Haripur (Pakistan)",
  "Havelian (Pakistan)",
  "Hyderabad (Pakistan)",
  "Islamabad (Pakistan)",
  "Jhang City (Pakistan)",
  "Jhang Sadr (Pakistan)",
  "Jhelum (Pakistan)",
  "Jhumra (Pakistan)",
  "Kabirwala (Pakistan)",
  "Karachi (Pakistan)",
  "Kasur (Pakistan)",
  "Khan (Pakistan)",
  "Khanewal (Pakistan)",
  "Khanpur (Pakistan)",
  "Kohat (Pakistan)",
  "Lahore (Pakistan)",
  "Mandi (Pakistan)",
  "Mandi Bahauddin (Pakistan)",
  "Mansehra (Pakistan)",
  "Mardan (Pakistan)",
  "Mian Channu (Pakistan)",
  "Mianwali (Pakistan)",
  "Miran Shah (Pakistan)",
  "Multan (Pakistan)",
  "Muzaffarabad (Pakistan)",
  "Nangar (Pakistan)",
  "Nankana Sahib (Pakistan)",
  "Narowal (Pakistan)",
  "New Mirpur (Pakistan)",
  "Nowshera (Pakistan)",
  "Okara (Pakistan)",
  "Peshawar (Pakistan)",
  "Pindi (Pakistan)",
  "Plot (Pakistan)",
  "Quetta (Pakistan)",
  "Rawalpindi (Pakistan)",
  "Rawlakot (Pakistan)",
  "Saddar (Pakistan)",
  "Sahiwal (Pakistan)",
  "Sarai Sidhu (Pakistan)",
  "Sargodha (Pakistan)",
  "Sarwar (Pakistan)",
  "Sheikhupura (Pakistan)",
  "Sialkot (Pakistan)",
  "Sukkur (Pakistan)",
  "Toba Tek Singh (Pakistan)",
  "Usman (Pakistan)",
  "Wazirabad (Pakistan)",
  "Ziauddin (Pakistan)",
  "Palestine",
  "Al Mawasi (Palestine)",
  "Bethlehem (Palestine)",
  "Gaza (Palestine)",
  "Hebron (Palestine)",
  "Jenin (Palestine)",
  "Jericho (Palestine)",
  "Nablus (Palestine)",
  "Ramallah (Palestine)",
  "Panama",
  "Aguadulce (Panama)",
  "Albrook (Panama)",
  "Ancon (Panama)",
  "Arosemena (Panama)",
  "Arraijan (Panama)",
  "Balboa (Panama)",
  "Bella Vista (Panama)",
  "Bocas del Toro (Panama)",
  "Boquete (Panama)",
  "Bugaba (Panama)",
  "Calidonia (Panama)",
  "Campo Alegre (Panama)",
  "Cerro Viento (Panama)",
  "Chigore (Panama)",
  "Chiriqui (Panama)",
  "Cocle (Panama)",
  "Corozal (Panama)",
  "Cristobal (Panama)",
  "Curundame (Panama)",
  "Curundu (Panama)",
  "David (Panama)",
  "El Arado (Panama)",
  "El Cangrejo (Panama)",
  "El Lago (Panama)",
  "Elena (Panama)",
  "Entre Rios (Panama)",
  "Finca Blanco Numero Uno (Panama)",
  "Fuerte Amador (Panama)",
  "Guadalupe (Panama)",
  "Jimenez (Panama)",
  "Juan Diaz (Panama)",
  "Juan Franco (Panama)",
  "Kuba (Panama)",
  "La Chorrera (Panama)",
  "La Exposicion (Panama)",
  "La Loma (Panama)",
  "Las Mercedes (Panama)",
  "Las Sabanas (Panama)",
  "Las Tablas (Panama)",
  "Llano Tugri (Panama)",
  "Los Angeles (Panama)",
  "Muerto (Panama)",
  "Mulatupo (Panama)",
  "Paitilla (Panama)",
  "Panama City (Panama)",
  "Parque Lefevre (Panama)",
  "Peru (Panama)",
  "Playa Blanca (Panama)",
  "Plaza (Panama)",
  "Portobelo (Panama)",
  "Pueblo Nuevo (Panama)",
  "Quebrada de Camilo (Panama)",
  "Rio Abajo (Panama)",
  "Sabalo (Panama)",
  "Sacramento (Panama)",
  "San Cristobal (Panama)",
  "San Felipe (Panama)",
  "San Francisco (Panama)",
  "San Miguelito (Panama)",
  "Santa Ana (Panama)",
  "Santa Catalina (Panama)",
  "Santa Clara (Panama)",
  "Santa Isabel (Panama)",
  "Santiago (Panama)",
  "Santo Domingo (Panama)",
  "Tocumen (Panama)",
  "Torre (Panama)",
  "Torres Bluff (Panama)",
  "Veraguas (Panama)",
  "Victoria (Panama)",
  "Vista Alegre (Panama)",
  "Vista Hermosa (Panama)",
  "Papua New Guinea",
  "Aitape (Papua New Guinea)",
  "Arawa (Papua New Guinea)",
  "Daru (Papua New Guinea)",
  "Goroka (Papua New Guinea)",
  "Kavieng (Papua New Guinea)",
  "Kerema (Papua New Guinea)",
  "Kikori (Papua New Guinea)",
  "Kimbe (Papua New Guinea)",
  "Kiunga (Papua New Guinea)",
  "Kokopo (Papua New Guinea)",
  "Kundiawa (Papua New Guinea)",
  "Kupano (Papua New Guinea)",
  "Lae (Papua New Guinea)",
  "Lorengau (Papua New Guinea)",
  "Madang (Papua New Guinea)",
  "Mendi (Papua New Guinea)",
  "Mount Hagen (Papua New Guinea)",
  "Namatanai (Papua New Guinea)",
  "Nambaga (Papua New Guinea)",
  "Paivara (Papua New Guinea)",
  "Pongani (Papua New Guinea)",
  "Popondetta (Papua New Guinea)",
  "Port Moresby (Papua New Guinea)",
  "Vanimo (Papua New Guinea)",
  "Wabag (Papua New Guinea)",
  "Waigani (Papua New Guinea)",
  "Wewak (Papua New Guinea)",
  "Paraguay",
  "Ayolas (Paraguay)",
  "Boqueron (Paraguay)",
  "Chore (Paraguay)",
  "Ciudad del Este (Paraguay)",
  "Colonia Mariano Roque Alonso (Paraguay)",
  "Coronel Oviedo (Paraguay)",
  "Fernando de la Mora (Paraguay)",
  "Fuerte Olimpo (Paraguay)",
  "Hernandarias (Paraguay)",
  "Hohenau (Paraguay)",
  "Independencia (Paraguay)",
  "La Paz (Paraguay)",
  "Limpio (Paraguay)",
  "Loma Plata (Paraguay)",
  "Luque (Paraguay)",
  "Nemby (Paraguay)",
  "Presidente Franco (Paraguay)",
  "Salto del Guaira (Paraguay)",
  "San Alberto (Paraguay)",
  "San Juan Bautista (Paraguay)",
  "San Lorenzo (Paraguay)",
  "Santa Rita (Paraguay)",
  "Santa Rosa (Paraguay)",
  "Villa Elisa (Paraguay)",
  "Villa Hayes (Paraguay)",
  "Villarrica (Paraguay)",
  "Yataity (Paraguay)",
  "Peru",
  "Abancay (Peru)",
  "Arequipa (Peru)",
  "Ate (Peru)",
  "Ayacucho (Peru)",
  "Bagua (Peru)",
  "Barranca (Peru)",
  "Barranco (Peru)",
  "Bellavista (Peru)",
  "Bolivar (Peru)",
  "Cajamarca (Peru)",
  "Callao (Peru)",
  "Calle (Peru)",
  "Caras (Peru)",
  "Cerro de Pasco (Peru)",
  "Chachapoyas (Peru)",
  "Chiclayo (Peru)",
  "Chimbote (Peru)",
  "Chincha (Peru)",
  "Cusco (Peru)",
  "Cuzco (Peru)",
  "El Agustino (Peru)",
  "Hacienda La Palma (Peru)",
  "Huacho (Peru)",
  "Huancavelica (Peru)",
  "Huancayo (Peru)",
  "Huaral (Peru)",
  "Huaraz (Peru)",
  "Huaytara (Peru)",
  "Ica (Peru)",
  "Ilo (Peru)",
  "Imperial (Peru)",
  "Iquitos (Peru)",
  "Jaen (Peru)",
  "Jesus Maria (Peru)",
  "Jose Olaya (Peru)",
  "Juliaca (Peru)",
  "Junin (Peru)",
  "La Molina (Peru)",
  "La Perla (Peru)",
  "La Punta (Peru)",
  "Lambayeque (Peru)",
  "Lima (Peru)",
  "Lince (Peru)",
  "Loreto (Peru)",
  "Lurigancho (Peru)",
  "Machu Picchu (Peru)",
  "Maldonado (Peru)",
  "Minas de Marcona (Peru)",
  "Miraflores (Peru)",
  "Moquegua (Peru)",
  "Moyobamba (Peru)",
  "Pasco (Peru)",
  "Paucarpata (Peru)",
  "Pimentel (Peru)",
  "Pisco (Peru)",
  "Piura (Peru)",
  "Progreso (Peru)",
  "Pucallpa (Peru)",
  "Puerto Inca (Peru)",
  "Puerto Maldonado (Peru)",
  "Puno (Peru)",
  "Rimac (Peru)",
  "Rioja (Peru)",
  "San Borja (Peru)",
  "San Isidro (Peru)",
  "San Juan Bautista (Peru)",
  "San Martin (Peru)",
  "San Miguel (Peru)",
  "Santa (Peru)",
  "Santiago (Peru)",
  "Santiago De Surco (Peru)",
  "Sullana (Peru)",
  "Surco (Peru)",
  "Surquillo (Peru)",
  "Tacna (Peru)",
  "Talara (Peru)",
  "Tarapoto (Peru)",
  "Tingo Maria (Peru)",
  "Trujillo (Peru)",
  "Tumbes (Peru)",
  "Ventanilla (Peru)",
  "Victoria (Peru)",
  "Philippines",
  "Abucay (Philippines)",
  "Acacia (Philippines)",
  "Aguilar (Philippines)",
  "Agusan Pequeno (Philippines)",
  "Alabang (Philippines)",
  "Alaminos (Philippines)",
  "Alcala (Philippines)",
  "Alfonso (Philippines)",
  "Alitagtag (Philippines)",
  "Amadeo (Philippines)",
  "Angat (Philippines)",
  "Angeles City (Philippines)",
  "Angono (Philippines)",
  "Antipolo (Philippines)",
  "Apalit (Philippines)",
  "Aquino (Philippines)",
  "Arayat (Philippines)",
  "Asia (Philippines)",
  "Aurora (Philippines)",
  "Ayala (Philippines)",
  "Baao (Philippines)",
  "Baclaran (Philippines)",
  "Bacolod City (Philippines)",
  "Bacoor (Philippines)",
  "Bagac (Philippines)",
  "Bago City (Philippines)",
  "Bagong Nayon (Philippines)",
  "Baguio City (Philippines)",
  "Bagumbayan (Philippines)",
  "Balabag (Philippines)",
  "Balagtas (Philippines)",
  "Balamban (Philippines)",
  "Balanga (Philippines)",
  "Balayan (Philippines)",
  "Baliuag (Philippines)",
  "Balungao (Philippines)",
  "Barbar (Philippines)",
  "Bataan (Philippines)",
  "Batangas (Philippines)",
  "Bates (Philippines)",
  "Batis (Philippines)",
  "Bauan (Philippines)",
  "Bautista (Philippines)",
  "Bay (Philippines)",
  "Bayan (Philippines)",
  "Bayawan (Philippines)",
  "Binan (Philippines)",
  "Binangonan (Philippines)",
  "Binondo (Philippines)",
  "Blumentritt (Philippines)",
  "Boac (Philippines)",
  "Bocaue (Philippines)",
  "Bonifacio (Philippines)",
  "Bool (Philippines)",
  "Bukidnon (Philippines)",
  "Bulacan (Philippines)",
  "Burgos (Philippines)",
  "Bustos (Philippines)",
  "Buting (Philippines)",
  "Butuan (Philippines)",
  "Cabanatuan City (Philippines)",
  "Cabatuan (Philippines)",
  "Cabugao (Philippines)",
  "Cabuyao (Philippines)",
  "Cafe (Philippines)",
  "Cagayan (Philippines)",
  "Cagayan de Oro (Philippines)",
  "Cainta (Philippines)",
  "Calaca (Philippines)",
  "Calamba (Philippines)",
  "Calamba City (Philippines)",
  "Calapan (Philippines)",
  "Calasiao (Philippines)",
  "Calbayog City (Philippines)",
  "Calinan (Philippines)",
  "Caloocan (Philippines)",
  "Caloocan City (Philippines)",
  "Campus (Philippines)",
  "Candelaria (Philippines)",
  "Candon (Philippines)",
  "Canlubang (Philippines)",
  "Capitol (Philippines)",
  "Cardona (Philippines)",
  "Carlatan (Philippines)",
  "Carlos (Philippines)",
  "Carmona (Philippines)",
  "Carolinas (Philippines)",
  "Cauayan (Philippines)",
  "Cavinti (Philippines)",
  "Cavite City (Philippines)",
  "Cebu City (Philippines)",
  "Central (Philippines)",
  "Centre (Philippines)",
  "Centro (Philippines)",
  "China (Philippines)",
  "Cinco (Philippines)",
  "City of Batac (Philippines)",
  "Cogan (Philippines)",
  "Concepcion (Philippines)",
  "Cotabato City (Philippines)",
  "Cruz (Philippines)",
  "Cubao (Philippines)",
  "Dagupan (Philippines)",
  "Danao (Philippines)",
  "Dapitan (Philippines)",
  "Daraga (Philippines)",
  "Dasmarinas (Philippines)",
  "Davao City (Philippines)",
  "Del Monte (Philippines)",
  "Del Pilar (Philippines)",
  "Digos (Philippines)",
  "Diliman Primero (Philippines)",
  "Dinas (Philippines)",
  "Dingras (Philippines)",
  "Dipolog City (Philippines)",
  "Dolores (Philippines)",
  "Domingo (Philippines)",
  "Don Bosco Executive Village (Philippines)",
  "Don Jose (Philippines)",
  "Dumaguete (Philippines)",
  "Fairview (Philippines)",
  "Feliciano (Philippines)",
  "Fernandez (Philippines)",
  "Fernando (Philippines)",
  "Fortuna (Philippines)",
  "Gallego (Philippines)",
  "General (Philippines)",
  "General Santos (Philippines)",
  "General Trias (Philippines)",
  "Gitagum (Philippines)",
  "Gloria (Philippines)",
  "Guagua (Philippines)",
  "Hagonoy (Philippines)",
  "Hilongos (Philippines)",
  "Himamaylan (Philippines)",
  "Ilagan (Philippines)",
  "Iligan (Philippines)",
  "Ilog (Philippines)",
  "Iloilo City (Philippines)",
  "Imus (Philippines)",
  "Interior (Philippines)",
  "Iriga City (Philippines)",
  "Isabela (Philippines)",
  "Island Park (Philippines)",
  "Jagna (Philippines)",
  "Kalibo (Philippines)",
  "Kapatagan (Philippines)",
  "Kawit (Philippines)",
  "Kidapawan (Philippines)",
  "Koronadal (Philippines)",
  "La Salette (Philippines)",
  "La Trinidad (Philippines)",
  "La Union (Philippines)",
  "Laguerta (Philippines)",
  "Laguna (Philippines)",
  "Lahug (Philippines)",
  "Lamitan (Philippines)",
  "Laoag (Philippines)",
  "Lapu-Lapu City (Philippines)",
  "Las Pinas (Philippines)",
  "Lawis (Philippines)",
  "Legaspi (Philippines)",
  "Leon (Philippines)",
  "Leyte (Philippines)",
  "Liberty (Philippines)",
  "Libis (Philippines)",
  "Lilio (Philippines)",
  "Limay (Philippines)",
  "Lipa City (Philippines)",
  "Llanera (Philippines)",
  "Looc (Philippines)",
  "Los Banos (Philippines)",
  "Los Martires (Philippines)",
  "Lourdes (Philippines)",
  "Lucena City (Philippines)",
  "Luna (Philippines)",
  "Luzon (Philippines)",
  "Mabalacat (Philippines)",
  "Mabini (Philippines)",
  "Macabebe (Philippines)",
  "Magsaysay (Philippines)",
  "Makati City (Philippines)",
  "Malabon (Philippines)",
  "Malasiqui (Philippines)",
  "Malate (Philippines)",
  "Malolos (Philippines)",
  "Malvar (Philippines)",
  "Mamungan (Philippines)",
  "Manaoag (Philippines)",
  "Mandaluyong City (Philippines)",
  "Mandaue City (Philippines)",
  "Mangaldan (Philippines)",
  "Mangrove (Philippines)",
  "Manila (Philippines)",
  "Mapua (Philippines)",
  "Maquiling (Philippines)",
  "Marcos (Philippines)",
  "Maria (Philippines)",
  "Marikina City (Philippines)",
  "Marilag (Philippines)",
  "Marilao (Philippines)",
  "Marina (Philippines)",
  "Mariveles (Philippines)",
  "Masbate (Philippines)",
  "Matalam (Philippines)",
  "Matandang Balara (Philippines)",
  "Mauban (Philippines)",
  "Mawab (Philippines)",
  "Merville Subdivision (Philippines)",
  "Meycauayan (Philippines)",
  "Miagao (Philippines)",
  "Minalin (Philippines)",
  "Mindanaw (Philippines)",
  "Minglanilla (Philippines)",
  "Misamis (Philippines)",
  "Molave (Philippines)",
  "Munoz East (Philippines)",
  "Muntinlupa (Philippines)",
  "Naga (Philippines)",
  "Naga City (Philippines)",
  "Naguilian (Philippines)",
  "Navotas (Philippines)",
  "New Manila (Philippines)",
  "Norte (Philippines)",
  "Novaliches (Philippines)",
  "Nueva (Philippines)",
  "Oara (Philippines)",
  "Obando (Philippines)",
  "Occidental (Philippines)",
  "Olongapo City (Philippines)",
  "Orani (Philippines)",
  "Orion (Philippines)",
  "Osmena (Philippines)",
  "Ozamiz City (Philippines)",
  "Pacita (Philippines)",
  "Padre Garcia (Philippines)",
  "Paete (Philippines)",
  "Pagadian (Philippines)",
  "Pagasinan (Philippines)",
  "Pagsanjan (Philippines)",
  "Palma Gil (Philippines)",
  "Palo (Philippines)",
  "Pampanga (Philippines)",
  "Panabo (Philippines)",
  "Pandi (Philippines)",
  "Pangil (Philippines)",
  "Paniqui (Philippines)",
  "Paranaque City (Philippines)",
  "Pardo (Philippines)",
  "Pasay (Philippines)",
  "Pasig (Philippines)",
  "Pasong Tamo (Philippines)",
  "Pateros (Philippines)",
  "Paul (Philippines)",
  "Philippine (Philippines)",
  "Pias (Philippines)",
  "Pikit (Philippines)",
  "Pinaglabanan (Philippines)",
  "Plaridel (Philippines)",
  "Plaza (Philippines)",
  "Poblacion (Philippines)",
  "Poblacion, San Felipe (Philippines)",
  "Princesa (Philippines)",
  "Province of Cebu (Philippines)",
  "Province of Laguna (Philippines)",
  "Province of Pampanga (Philippines)",
  "Province of Pangasinan (Philippines)",
  "Puerto Princesa City (Philippines)",
  "Pulilan (Philippines)",
  "Putatan (Philippines)",
  "Quezon (Philippines)",
  "Quezon City (Philippines)",
  "Ramon (Philippines)",
  "Real (Philippines)",
  "Rizal (Philippines)",
  "Rodriguez (Philippines)",
  "Roosevelt (Philippines)",
  "Roque (Philippines)",
  "Rosales (Philippines)",
  "Rosario West (Philippines)",
  "Roxas City (Philippines)",
  "Salcedo (Philippines)",
  "Salinas (Philippines)",
  "Salle (Philippines)",
  "Samal (Philippines)",
  "Sampaloc (Philippines)",
  "San Agustin (Philippines)",
  "San Andres (Philippines)",
  "San Antonio (Philippines)",
  "San Carlos City (Philippines)",
  "San Fabian (Philippines)",
  "San Fernando (Philippines)",
  "San Francisco (Philippines)",
  "San Jose (Philippines)",
  "San Jose del Monte (Philippines)",
  "San Juan (Philippines)",
  "San Mateo (Philippines)",
  "San Miguel (Philippines)",
  "San Pablo City (Philippines)",
  "San Pedro (Philippines)",
  "San Vicente (Philippines)",
  "Santa Cruz (Philippines)",
  "Santa Rosa (Philippines)",
  "Santiago (Philippines)",
  "Santo (Philippines)",
  "Santo Tomas (Philippines)",
  "Santos (Philippines)",
  "Sariaya (Philippines)",
  "Silang (Philippines)",
  "Silay (Philippines)",
  "Siniloan (Philippines)",
  "Smart (Philippines)",
  "Sorsogon (Philippines)",
  "Sta Cruz (Philippines)",
  "Sto Nino (Philippines)",
  "Subic (Philippines)",
  "Sucat (Philippines)",
  "Sulo (Philippines)",
  "Sultan Kudarat (Philippines)",
  "Summit (Philippines)",
  "Suyo (Philippines)",
  "Taal (Philippines)",
  "Tacloban City (Philippines)",
  "Tacurong (Philippines)",
  "Taft (Philippines)",
  "Tagaytay (Philippines)",
  "Tagbilaran City (Philippines)",
  "Taguig (Philippines)",
  "Tagum (Philippines)",
  "Talisay City (Philippines)",
  "Talon (Philippines)",
  "Tamag (Philippines)",
  "Tambler (Philippines)",
  "Tambo (Philippines)",
  "Tanauan (Philippines)",
  "Tanay (Philippines)",
  "Tandang Sora (Philippines)",
  "Tanza (Philippines)",
  "Tarlac City (Philippines)",
  "Tayabas (Philippines)",
  "Taytay (Philippines)",
  "Tayug (Philippines)",
  "Tejeros Convention (Philippines)",
  "Tigbauan (Philippines)",
  "Toledo City (Philippines)",
  "Trece Martires City (Philippines)",
  "Trinidad (Philippines)",
  "Tuguegarao City (Philippines)",
  "Tuktukan (Philippines)",
  "Ugong Norte (Philippines)",
  "Upper Bicutan (Philippines)",
  "Urdaneta (Philippines)",
  "Valencia (Philippines)",
  "Valenzuela (Philippines)",
  "Vargas (Philippines)",
  "Ventura (Philippines)",
  "Veronica (Philippines)",
  "Victorias City (Philippines)",
  "Victory (Philippines)",
  "Vigan (Philippines)",
  "Villa (Philippines)",
  "Villanueva (Philippines)",
  "Vito (Philippines)",
  "West (Philippines)",
  "West Triangle (Philippines)",
  "Wines (Philippines)",
  "Zamboanga City (Philippines)",
  "Poland",
  "Adama (Poland)",
  "Alwernia (Poland)",
  "Andrespol (Poland)",
  "Andrychow (Poland)",
  "Anin (Poland)",
  "Annopol (Poland)",
  "Arkadia (Poland)",
  "Babienica (Poland)",
  "Babimost (Poland)",
  "Baborow (Poland)",
  "Baboszewo (Poland)",
  "Balice (Poland)",
  "Banino (Poland)",
  "Baniocha (Poland)",
  "Baran (Poland)",
  "Baranow (Poland)",
  "Baranowko (Poland)",
  "Barciany (Poland)",
  "Barcice (Poland)",
  "Barcin (Poland)",
  "Barczewo (Poland)",
  "Barglowka (Poland)",
  "Barlinek (Poland)",
  "Bartoszyce (Poland)",
  "Baruchowo (Poland)",
  "Barwice (Poland)",
  "Bazanowka (Poland)",
  "Beblo (Poland)",
  "Bedkow (Poland)",
  "Bejsce (Poland)",
  "Belk (Poland)",
  "Belsk Duzy (Poland)",
  "Belsznica (Poland)",
  "Bestwina (Poland)",
  "Bestwinka (Poland)",
  "Biala (Poland)",
  "Bialobrzegi (Poland)",
  "Bialy Dunajec (Poland)",
  "Bibice (Poland)",
  "Biecz (Poland)",
  "Biedrusko (Poland)",
  "Bielany (Poland)",
  "Bielany Wroclawskie (Poland)",
  "Bielawa (Poland)",
  "Bielawy (Poland)",
  "Bielcza (Poland)",
  "Bieliny (Poland)",
  "Bielkowo (Poland)",
  "Bielsk (Poland)",
  "Bielsk Podlaski (Poland)",
  "Bielsko-Biala (Poland)",
  "Bierun (Poland)",
  "Bierun Nowy (Poland)",
  "Bierutow (Poland)",
  "Biesiekierz (Poland)",
  "Biezanow-Prokocim (Poland)",
  "Bircza (Poland)",
  "Biskupice (Poland)",
  "Biskupice Oloboczne (Poland)",
  "Biskupiec (Poland)",
  "Biskupin (Poland)",
  "Bisztynek (Poland)",
  "Blachownia (Poland)",
  "Blazowa (Poland)",
  "Bledzew (Poland)",
  "Blizne (Poland)",
  "Blizyn (Poland)",
  "Bobrowniki (Poland)",
  "Bobrza (Poland)",
  "Bochnia (Poland)",
  "Bochotnica (Poland)",
  "Bochowo (Poland)",
  "Bodzentyn (Poland)",
  "Bogatynia (Poland)",
  "Bogdaszowice (Poland)",
  "Bogumilowice (Poland)",
  "Bogunice (Poland)",
  "Boguslaw (Poland)",
  "Bohdan (Poland)",
  "Bojadla (Poland)",
  "Bojano (Poland)",
  "Bojanowo (Poland)",
  "Bojszow (Poland)",
  "Bojszowy (Poland)",
  "Bolechowice (Poland)",
  "Boleslaw (Poland)",
  "Bolewice (Poland)",
  "Bolkow (Poland)",
  "Bolszewo (Poland)",
  "Borek Strzelinski (Poland)",
  "Borki (Poland)",
  "Borkowice (Poland)",
  "Borkowo (Poland)",
  "Borne Sulinowo (Poland)",
  "Borowa (Poland)",
  "Borowe (Poland)",
  "Borowiec (Poland)",
  "Bory (Poland)",
  "Borzecin (Poland)",
  "Borzykowo (Poland)",
  "Bralin (Poland)",
  "Bramki (Poland)",
  "Braniewo (Poland)",
  "Braszewice (Poland)",
  "Bratkowice (Poland)",
  "Brenna (Poland)",
  "Brochocin (Poland)",
  "Brodnica (Poland)",
  "Brok (Poland)",
  "Brudzice (Poland)",
  "Brudzowice (Poland)",
  "Brynica (Poland)",
  "Brzeg (Poland)",
  "Brzeg Dolny (Poland)",
  "Brzesko (Poland)",
  "Brzeszcze (Poland)",
  "Brzezinka (Poland)",
  "Brzeziny (Poland)",
  "Brzeznica (Poland)",
  "Brzostek (Poland)",
  "Brzostowka (Poland)",
  "Brzoza Krolewska (Poland)",
  "Brzozow (Poland)",
  "Brzyska Wola (Poland)",
  "Buczkowice (Poland)",
  "Budziska (Poland)",
  "Budzyn (Poland)",
  "Buk (Poland)",
  "Bukowice (Poland)",
  "Bukowiec Opoczynski (Poland)",
  "Bukowina Tatrzanska (Poland)",
  "Bukowno (Poland)",
  "Bukowsko (Poland)",
  "Burzenin (Poland)",
  "Bychawa (Poland)",
  "Byczyna (Poland)",
  "Bydgoszcz (Poland)",
  "Bydlin (Poland)",
  "Byslaw (Poland)",
  "Bystra (Poland)",
  "Bystrzyca Klodzka (Poland)",
  "Bytom (Poland)",
  "Cekcyn (Poland)",
  "Cekow (Poland)",
  "Chalupki (Poland)",
  "Charzykowy (Poland)",
  "Checiny (Poland)",
  "Chelm (Poland)",
  "Chelmek (Poland)",
  "Chelmsko Slaskie (Poland)",
  "Chmielnik (Poland)",
  "Chmielow (Poland)",
  "Chocianow (Poland)",
  "Chociwel (Poland)",
  "Choczewo (Poland)",
  "Chocznia (Poland)",
  "Chojna (Poland)",
  "Chojnice (Poland)",
  "Choroszcz (Poland)",
  "Chorzele (Poland)",
  "Chorzelow (Poland)",
  "Choszczno (Poland)",
  "Chotel (Poland)",
  "Chotomow (Poland)",
  "Chrzan (Poland)",
  "Chrzastowka (Poland)",
  "Chrzesne (Poland)",
  "Chrzowice (Poland)",
  "Chwaszczyno (Poland)",
  "Chybie (Poland)",
  "Chylice (Poland)",
  "Chyliczki (Poland)",
  "Cianowice Duze (Poland)",
  "Ciasna (Poland)",
  "Ciechocin (Poland)",
  "Ciechocinek (Poland)",
  "Cierpice (Poland)",
  "Cieszkow (Poland)",
  "Cieszyn (Poland)",
  "Ciezkowice (Poland)",
  "Cigacice (Poland)",
  "Cisiec (Poland)",
  "Cmielow (Poland)",
  "Cmolas (Poland)",
  "Cwiklice (Poland)",
  "Cybinka (Poland)",
  "Czaniec (Poland)",
  "Czaplinek (Poland)",
  "Czapury (Poland)",
  "Czarna (Poland)",
  "Czarne (Poland)",
  "Czarnkow (Poland)",
  "Czarnozyly (Poland)",
  "Czarny Bor (Poland)",
  "Czarny Dunajec (Poland)",
  "Czarny Las (Poland)",
  "Czarze (Poland)",
  "Czastary (Poland)",
  "Czechowice-Dziedzice (Poland)",
  "Czekanow (Poland)",
  "Czeladz (Poland)",
  "Czempin (Poland)",
  "Czernica (Poland)",
  "Czernichow (Poland)",
  "Czerniewice (Poland)",
  "Czersk (Poland)",
  "Czerwiensk (Poland)",
  "Czerwionka-Leszczyny (Poland)",
  "Czerwonak (Poland)",
  "Czluchow (Poland)",
  "Czosnow (Poland)",
  "Czudec (Poland)",
  "Czyzyny (Poland)",
  "Dabki (Poland)",
  "Dabrowa (Poland)",
  "Dabrowa Bialostocka (Poland)",
  "Dabrowa Biskupia (Poland)",
  "Dabrowa Chelminska (Poland)",
  "Dabrowa Chotomowska (Poland)",
  "Dabrowka Wielka (Poland)",
  "Dabrowno (Poland)",
  "Dabrowskie (Poland)",
  "Daleszyce (Poland)",
  "Damnica (Poland)",
  "Dankowice (Poland)",
  "Dargoslaw (Poland)",
  "Darnowo (Poland)",
  "Debe Wielkie (Poland)",
  "Debica (Poland)",
  "Debina (Poland)",
  "Deblin (Poland)",
  "Debno (Poland)",
  "Debowiec (Poland)",
  "Debrzno Wies (Poland)",
  "Deszczno (Poland)",
  "Dlugopole-Zdroj (Poland)",
  "Dobiesz (Poland)",
  "Dobieszowice (Poland)",
  "Dobra (Poland)",
  "Dobre Miasto (Poland)",
  "Dobrodzien (Poland)",
  "Dobromierz (Poland)",
  "Dobron (Poland)",
  "Dobroszyce (Poland)",
  "Dobrzany (Poland)",
  "Dobrzen Wielki (Poland)",
  "Dobrzyca (Poland)",
  "Dolaszewo (Poland)",
  "Dolice (Poland)",
  "Domaniewice (Poland)",
  "Domaradz (Poland)",
  "Domaslaw (Poland)",
  "Dopiewiec (Poland)",
  "Drawno (Poland)",
  "Drawsko Pomorskie (Poland)",
  "Drewnica (Poland)",
  "Drezdenko (Poland)",
  "Drohiczyn (Poland)",
  "Drozdowo (Poland)",
  "Druzbice (Poland)",
  "Drzewica (Poland)",
  "Duczki (Poland)",
  "Dukla (Poland)",
  "Dulcza Wielka (Poland)",
  "Dunaj (Poland)",
  "Duszniki-Zdroj (Poland)",
  "Dygowo (Poland)",
  "Dylewo (Poland)",
  "Dynow (Poland)",
  "Dywity (Poland)",
  "Dzialoszyn (Poland)",
  "Dziechciniec (Poland)",
  "Dziegielow (Poland)",
  "Dziekanow Lesny (Poland)",
  "Dzielna (Poland)",
  "Dzierzazno (Poland)",
  "Dzierzgon (Poland)",
  "Dzierzoniow (Poland)",
  "Dziewin (Poland)",
  "Dzikowiec (Poland)",
  "Dziwnow (Poland)",
  "Dzwierzuty (Poland)",
  "Elblag (Poland)",
  "Elzbieta (Poland)",
  "Fabianki (Poland)",
  "Falkow (Poland)",
  "Falkowo (Poland)",
  "Frank (Poland)",
  "Frombork (Poland)",
  "Frydrychowice (Poland)",
  "Frysztak (Poland)",
  "Gadka Stara (Poland)",
  "Garbow (Poland)",
  "Garby (Poland)",
  "Garki (Poland)",
  "Garwolin (Poland)",
  "Gaszowice (Poland)",
  "Gaworzyce (Poland)",
  "Gdow (Poland)",
  "Gdynia (Poland)",
  "Giby (Poland)",
  "Gieraltowice (Poland)",
  "Gizalki (Poland)",
  "Gizyce (Poland)",
  "Gliwice (Poland)",
  "Glogow Malopolski (Poland)",
  "Glogowek (Poland)",
  "Gloskow (Poland)",
  "Glowienka (Poland)",
  "Glowna (Poland)",
  "Glowno (Poland)",
  "Glubczyce (Poland)",
  "Glucholazy (Poland)",
  "Gluchow (Poland)",
  "Gluszyca (Poland)",
  "Gmina Babiak (Poland)",
  "Gmina Bobrowo (Poland)",
  "Gmina Chmielno (Poland)",
  "Gmina Ciechanowiec (Poland)",
  "Gmina Gnojno (Poland)",
  "Gmina Kiszkowo (Poland)",
  "Gmina Kolno (Poland)",
  "Gmina Konarzyny (Poland)",
  "Gmina Lipno (Poland)",
  "Gmina Ludwin (Poland)",
  "Gmina Lutomiersk (Poland)",
  "Gmina Morawica (Poland)",
  "Gmina Moszczenica (Poland)",
  "Gmina Opole Lubelskie (Poland)",
  "Gmina Przywidz (Poland)",
  "Gmina Sadowie (Poland)",
  "Gmina Sierakowice (Poland)",
  "Gmina Strzelin (Poland)",
  "Gmina Szubin (Poland)",
  "Gmina Widawa (Poland)",
  "Gmina Wyszki (Poland)",
  "Gmina Zakliczyn (Poland)",
  "Gniew (Poland)",
  "Gniewino (Poland)",
  "Gniewkowo (Poland)",
  "Gniezno (Poland)",
  "Gnuszyn (Poland)",
  "Godziszewo (Poland)",
  "Gogolin (Poland)",
  "Golanice (Poland)",
  "Golasowice (Poland)",
  "Golczewo (Poland)",
  "Goleszow (Poland)",
  "Golkowice (Poland)",
  "Golotczyzna (Poland)",
  "Golub-Dobrzyn (Poland)",
  "Gomunice (Poland)",
  "Gora (Poland)",
  "Gora Kalwaria (Poland)",
  "Gora Pulawska (Poland)",
  "Gora Siewierska (Poland)",
  "Gorazdze (Poland)",
  "Gorki Wielkie (Poland)",
  "Gorlice (Poland)",
  "Gorno (Poland)",
  "Gorz (Poland)",
  "Gorzkow (Poland)",
  "Gorzkowice (Poland)",
  "Gorzow (Poland)",
  "Gorzow Slaski (Poland)",
  "Gorzyce (Poland)",
  "Gorzyczki (Poland)",
  "Gostyn (Poland)",
  "Gostynin (Poland)",
  "Goszczyn (Poland)",
  "Gowarzewo (Poland)",
  "Gowino (Poland)",
  "Gozdnica (Poland)",
  "Gozdowo (Poland)",
  "Grabiec (Poland)",
  "Grabki Duze (Poland)",
  "Grabow nad Prosna (Poland)",
  "Grabowka (Poland)",
  "Gracze (Poland)",
  "Grajewo (Poland)",
  "Grebocice (Poland)",
  "Grebocin (Poland)",
  "Grodki (Poland)",
  "Grodkow (Poland)",
  "Grodzisk (Poland)",
  "Grodzisk Mazowiecki (Poland)",
  "Grodzisk Wielkopolski (Poland)",
  "Grodzisko Dolne (Poland)",
  "Grojec (Poland)",
  "Gromiec (Poland)",
  "Gronowo Elblaskie (Poland)",
  "Gruczno (Poland)",
  "Grunwald (Poland)",
  "Grupa (Poland)",
  "Gruszczyn (Poland)",
  "Grybow (Poland)",
  "Gryfice (Poland)",
  "Gryfino (Poland)",
  "Gryfow Slaski (Poland)",
  "Grzebien (Poland)",
  "Grzegorz (Poland)",
  "Grzmiaca (Poland)",
  "Grzmucin (Poland)",
  "Gubin (Poland)",
  "Gzin (Poland)",
  "Haczow (Poland)",
  "Harasiuki (Poland)",
  "Hecznarowice (Poland)",
  "Henrykow (Poland)",
  "Hornowek (Poland)",
  "Hucisko Jawornickie (Poland)",
  "Humniska (Poland)",
  "Huta Dabrowa (Poland)",
  "Huta Dlutowska (Poland)",
  "Huta Stara (Poland)",
  "Ilowa (Poland)",
  "Ilowo (Poland)",
  "Ilza (Poland)",
  "Imielin (Poland)",
  "Iwaniska (Poland)",
  "Iwanowice (Poland)",
  "Iwiczna (Poland)",
  "Iwierzyce (Poland)",
  "Iwla (Poland)",
  "Iwonicz-Zdroj (Poland)",
  "Izabela (Poland)",
  "Izabelin (Poland)",
  "Jablonica (Poland)",
  "Jablonka (Poland)",
  "Jablonna (Poland)",
  "Janin (Poland)",
  "Jankowice (Poland)",
  "Janow (Poland)",
  "Janow Lubelski (Poland)",
  "Jarkowice (Poland)",
  "Jarocin (Poland)",
  "Jaroszow (Poland)",
  "Jaroszowiec (Poland)",
  "Jasienica (Poland)",
  "Jasienica Dolna (Poland)",
  "Jasieniec (Poland)",
  "Jaslo (Poland)",
  "Jastkow (Poland)",
  "Jastrowie (Poland)",
  "Jastrzebie (Poland)",
  "Jawiszowice (Poland)",
  "Jawor (Poland)",
  "Jaworzno (Poland)",
  "Jaworzyna Slaska (Poland)",
  "Jedlicze (Poland)",
  "Jedlnia-Letnisko (Poland)",
  "Jelesnia (Poland)",
  "Jemielnica (Poland)",
  "Jerzmanowice (Poland)",
  "Jeziora Wielkie (Poland)",
  "Jeziorany (Poland)",
  "Jezowe (Poland)",
  "Jordanow (Poland)",
  "Jozefatow (Poland)",
  "Jozefoslaw (Poland)",
  "Jozefow (Poland)",
  "Juchnowiec Koscielny (Poland)",
  "Jugow (Poland)",
  "Juszkowo (Poland)",
  "Jutrosin (Poland)",
  "Kaczkowo (Poland)",
  "Kaczor (Poland)",
  "Kalety (Poland)",
  "Kalisz (Poland)",
  "Kalwaria Zebrzydowska (Poland)",
  "Kamien (Poland)",
  "Kamien Krajenski (Poland)",
  "Kamien Pomorski (Poland)",
  "Kamien Slaski (Poland)",
  "Kamienica Polska (Poland)",
  "Kamieniec (Poland)",
  "Kamienna Gora (Poland)",
  "Kamionki (Poland)",
  "Kampinos (Poland)",
  "Kanczuga (Poland)",
  "Karchowice (Poland)",
  "Karczew (Poland)",
  "Kargowa (Poland)",
  "Karlikowo (Poland)",
  "Karlino (Poland)",
  "Karnice (Poland)",
  "Karniowice (Poland)",
  "Karpacz (Poland)",
  "Karpiska (Poland)",
  "Karsko (Poland)",
  "Kartuzy (Poland)",
  "Kasinka (Poland)",
  "Katarzyna (Poland)",
  "Katowice (Poland)",
  "Katy (Poland)",
  "Katy Wroclawskie (Poland)",
  "Kazimierz Biskupi (Poland)",
  "Kazimierz Dolny (Poland)",
  "Kazimierza Wielka (Poland)",
  "Kazmierz (Poland)",
  "Kcynia (Poland)",
  "Keblowo (Poland)",
  "Kedzierzyn (Poland)",
  "Kedzierzyn-Kozle (Poland)",
  "Kety (Poland)",
  "Kicin (Poland)",
  "Kielce (Poland)",
  "Kielcz (Poland)",
  "Kielczow (Poland)",
  "Kielno (Poland)",
  "Kielpin (Poland)",
  "Kijewo Krolewskie (Poland)",
  "Klaj (Poland)",
  "Klecko (Poland)",
  "Klecza Dolna (Poland)",
  "Kleczany (Poland)",
  "Klenica (Poland)",
  "Kleszczewo (Poland)",
  "Kleszczow (Poland)",
  "Klikawa (Poland)",
  "Klikuszowa (Poland)",
  "Klimontow (Poland)",
  "Kliniska (Poland)",
  "Klobuck (Poland)",
  "Klodawa (Poland)",
  "Klomnice (Poland)",
  "Kluczbork (Poland)",
  "Klucze (Poland)",
  "Knurow (Poland)",
  "Knyszyn (Poland)",
  "Kobiernice (Poland)",
  "Kobierzyce (Poland)",
  "Kobior (Poland)",
  "Kobylanka (Poland)",
  "Kobylka (Poland)",
  "Kobylnica (Poland)",
  "Kochcice (Poland)",
  "Kocierzew Poludniowy (Poland)",
  "Kocmyrzow (Poland)",
  "Kojszowka (Poland)",
  "Kokoszkowy (Poland)",
  "Kolbudy (Poland)",
  "Kolbuszowa (Poland)",
  "Koleczkowo (Poland)",
  "Kolno (Poland)",
  "Kolodziejewo (Poland)",
  "Kolonia Zawada (Poland)",
  "Kolonowskie (Poland)",
  "Koluszki (Poland)",
  "Komorniki (Poland)",
  "Komorow (Poland)",
  "Komorsk (Poland)",
  "Konarzewo (Poland)",
  "Konarzyce (Poland)",
  "Konczyce Male (Poland)",
  "Koniakow (Poland)",
  "Koniecpol (Poland)",
  "Konin (Poland)",
  "Koniusza (Poland)",
  "Konopiska (Poland)",
  "Konradowka (Poland)",
  "Konstancin-Jeziorna (Poland)",
  "Konstantyn (Poland)",
  "Konstantynow (Poland)",
  "Konstantynow Lodzki (Poland)",
  "Kopki (Poland)",
  "Korczyna (Poland)",
  "Korfantow (Poland)",
  "Kornik (Poland)",
  "Koronowo (Poland)",
  "Korsze (Poland)",
  "Korytow (Poland)",
  "Korzenna (Poland)",
  "Kosakowo (Poland)",
  "Koscielisko (Poland)",
  "Koscierzyna (Poland)",
  "Kosina (Poland)",
  "Kostrzyn (Poland)",
  "Kostrzyn nad Odra (Poland)",
  "Koszalin (Poland)",
  "Koszecin (Poland)",
  "Koszyce (Poland)",
  "Koteze (Poland)",
  "Kotlin (Poland)",
  "Kowal (Poland)",
  "Kowalew (Poland)",
  "Kowalewo (Poland)",
  "Kowalewo Pomorskie (Poland)",
  "Kowalkow (Poland)",
  "Kowalowa (Poland)",
  "Kowary (Poland)",
  "Kowiesy (Poland)",
  "Kozieglowy (Poland)",
  "Kozienice (Poland)",
  "Kozmin Wielkopolski (Poland)",
  "Kozminek (Poland)",
  "Kozuchow (Poland)",
  "Kozy (Poland)",
  "Krakow (Poland)",
  "Krapkowice (Poland)",
  "Krasiejow (Poland)",
  "Krasne (Poland)",
  "Krasnik (Poland)",
  "Krasnystaw (Poland)",
  "Krasocin (Poland)",
  "Kraszew (Poland)",
  "Krepa Kaszubska (Poland)",
  "Krokowa (Poland)",
  "Kroscienko Wyzne (Poland)",
  "Krosno (Poland)",
  "Krosno Odrzanskie (Poland)",
  "Krosnowice (Poland)",
  "Krotoszyn (Poland)",
  "Kruszyn (Poland)",
  "Kruszyna (Poland)",
  "Krynica (Poland)",
  "Krynica-Zdroj (Poland)",
  "Krypno (Poland)",
  "Krzepice (Poland)",
  "Krzeszow (Poland)",
  "Krzeszowice (Poland)",
  "Krzyki-Partynice (Poland)",
  "Krzyszkowice (Poland)",
  "Krzywcza (Poland)",
  "Krzywin (Poland)",
  "Krzyz Wielkopolski (Poland)",
  "Krzyzanowice (Poland)",
  "Ksiazenice (Poland)",
  "Ksieginice (Poland)",
  "Kukow (Poland)",
  "Kuligow (Poland)",
  "Kunice (Poland)",
  "Kunow (Poland)",
  "Kurdwanow (Poland)",
  "Kurek (Poland)",
  "Kurylowka (Poland)",
  "Kurzetnik (Poland)",
  "Kusnierz (Poland)",
  "Kutno (Poland)",
  "Kuznia (Poland)",
  "Kuznica Czarnkowska (Poland)",
  "Kuzniki (Poland)",
  "Kwaczala (Poland)",
  "Kwidzyn (Poland)",
  "Kwilcz (Poland)",
  "Labiszyn (Poland)",
  "Labowa (Poland)",
  "Labunie (Poland)",
  "Lachowice (Poland)",
  "Lack (Poland)",
  "Lacko (Poland)",
  "Ladek (Poland)",
  "Lajsk (Poland)",
  "Laka (Poland)",
  "Lancut (Poland)",
  "Lany (Poland)",
  "Lask (Poland)",
  "Laska (Poland)",
  "Laskarzew (Poland)",
  "Latowicz (Poland)",
  "Laziska (Poland)",
  "Laziska Gorne (Poland)",
  "Leba (Poland)",
  "Lebork (Poland)",
  "Leczna (Poland)",
  "Ledziny (Poland)",
  "Legionowo (Poland)",
  "Legnica (Poland)",
  "Legowo (Poland)",
  "Lekawica (Poland)",
  "Lelow (Poland)",
  "Lesko (Poland)",
  "Lesna (Poland)",
  "Lesnica (Poland)",
  "Leszkowice (Poland)",
  "Leszno (Poland)",
  "Lewin Brzeski (Poland)",
  "Lezajsk (Poland)",
  "Libiaz (Poland)",
  "Lidzbark (Poland)",
  "Ligota (Poland)",
  "Limanowa (Poland)",
  "Liniewo (Poland)",
  "Linowko (Poland)",
  "Lipinki Luzyckie (Poland)",
  "Lipiny (Poland)",
  "Lipnica Murowana (Poland)",
  "Lipnica Wielka (Poland)",
  "Lipnik (Poland)",
  "Lipno (Poland)",
  "Lipowa (Poland)",
  "Lipsko (Poland)",
  "Lipusz (Poland)",
  "Lisewo (Poland)",
  "Liskow (Poland)",
  "Liszki (Poland)",
  "Liw (Poland)",
  "Lobez (Poland)",
  "Lobzenica (Poland)",
  "Lochow (Poland)",
  "Lochowo (Poland)",
  "Lodygowice (Poland)",
  "Lomianki (Poland)",
  "Lomianki Dolne (Poland)",
  "Lomnica (Poland)",
  "Lotyn (Poland)",
  "Lowicz (Poland)",
  "Lubaczow (Poland)",
  "Luban (Poland)",
  "Lubanie (Poland)",
  "Lubartow (Poland)",
  "Lubaszowa (Poland)",
  "Lubawa (Poland)",
  "Lubawka (Poland)",
  "Lubenia (Poland)",
  "Lubichowo (Poland)",
  "Lubicz (Poland)",
  "Lubien (Poland)",
  "Lubin (Poland)",
  "Lublewo (Poland)",
  "Lublin (Poland)",
  "Lubliniec (Poland)",
  "Lubnice (Poland)",
  "Lubochnia (Poland)",
  "Lubomia (Poland)",
  "Lubomierz (Poland)",
  "Lubon (Poland)",
  "Luborzyca (Poland)",
  "Lubraniec (Poland)",
  "Lubsko (Poland)",
  "Lubsza (Poland)",
  "Lubycza Krolewska (Poland)",
  "Lukow (Poland)",
  "Lulin (Poland)",
  "Lusowko (Poland)",
  "Lutynia (Poland)",
  "Luzino (Poland)",
  "Lysomice (Poland)",
  "Mackowice (Poland)",
  "Magnuszew (Poland)",
  "Majdan Krolewski (Poland)",
  "Majewo (Poland)",
  "Makow (Poland)",
  "Makow Mazowiecki (Poland)",
  "Makow Podhalanski (Poland)",
  "Makowiec (Poland)",
  "Maksymilianowo (Poland)",
  "Malbork (Poland)",
  "Malczyce (Poland)",
  "Malogoszcz (Poland)",
  "Manowo (Poland)",
  "Marcinowice (Poland)",
  "Marek (Poland)",
  "Margonin (Poland)",
  "Maria (Poland)",
  "Marki (Poland)",
  "Marklowice (Poland)",
  "Marta (Poland)",
  "Mary (Poland)",
  "MaryLka (Poland)",
  "Maslice (Poland)",
  "Maslow (Poland)",
  "Maszewo (Poland)",
  "Mazancowice (Poland)",
  "Mechelinki (Poland)",
  "Medyka (Poland)",
  "Medynia Glogowska (Poland)",
  "Melno (Poland)",
  "Meszna (Poland)",
  "Mialy (Poland)",
  "Miasteczko Slaskie (Poland)",
  "Miastko (Poland)",
  "Michalowice (Poland)",
  "Miechow (Poland)",
  "Mieczewo (Poland)",
  "Miedzna (Poland)",
  "Miedzyborow (Poland)",
  "Miedzyborz (Poland)",
  "Miedzybrodzie Zywieckie (Poland)",
  "Miedzylesie (Poland)",
  "Miedzyzdroje (Poland)",
  "Miejska Gorka (Poland)",
  "Mielec (Poland)",
  "Mielno (Poland)",
  "Mieroszow (Poland)",
  "Mierzecice (Poland)",
  "Mierzeszyn (Poland)",
  "Mikolajki (Poland)",
  "Mikoszewo (Poland)",
  "Mikstat (Poland)",
  "Milanow (Poland)",
  "Milcza (Poland)",
  "Milejow (Poland)",
  "Milicz (Poland)",
  "Milkowice (Poland)",
  "Milobadz (Poland)",
  "Miloradz (Poland)",
  "Milowka (Poland)",
  "Minoga (Poland)",
  "Mirkow (Poland)",
  "Miroslaw (Poland)",
  "Miroslawiec (Poland)",
  "Mirsk (Poland)",
  "Miszkowice (Poland)",
  "Mniow (Poland)",
  "Modlnica (Poland)",
  "Modlniczka (Poland)",
  "Modrze (Poland)",
  "Mogilany (Poland)",
  "Mogilno (Poland)",
  "Mokrsko (Poland)",
  "Morawica (Poland)",
  "Moryn (Poland)",
  "Mosina (Poland)",
  "Mosty (Poland)",
  "Moszczanka (Poland)",
  "Mragowo (Poland)",
  "Mrocza (Poland)",
  "Mrowino (Poland)",
  "Mscice (Poland)",
  "Msciwojow (Poland)",
  "Mszana (Poland)",
  "Mszana Dolna (Poland)",
  "Mucharz (Poland)",
  "Murowana Goslina (Poland)",
  "Muszyna (Poland)",
  "Myslachowice (Poland)",
  "Myslenice (Poland)",
  "Mysliborz (Poland)",
  "Myszkow (Poland)",
  "Myszyniec (Poland)",
  "Nacpolsk (Poland)",
  "Nadarzyn (Poland)",
  "Naklo (Poland)",
  "Naleczow (Poland)",
  "Namyslow (Poland)",
  "Naprawa (Poland)",
  "Narew (Poland)",
  "Narzym (Poland)",
  "Nasielsk (Poland)",
  "Nawodna (Poland)",
  "Nebrowo Wielkie (Poland)",
  "Nidzica (Poland)",
  "Nieborowice (Poland)",
  "Niechanowo (Poland)",
  "Niedomice (Poland)",
  "Niedrzwica Duza (Poland)",
  "Niegoslawice (Poland)",
  "Nielisz (Poland)",
  "Niemcz (Poland)",
  "Niemcza (Poland)",
  "Niemodlin (Poland)",
  "Nienadowka (Poland)",
  "Niepolomice (Poland)",
  "Niewierz (Poland)",
  "Nisko (Poland)",
  "Niwica (Poland)",
  "Nowa (Poland)",
  "Nowa Biala (Poland)",
  "Nowa Deba (Poland)",
  "Nowa Huta (Poland)",
  "Nowa Ruda (Poland)",
  "Nowa Slupia (Poland)",
  "Nowa Sol (Poland)",
  "Nowa Wies Elcka (Poland)",
  "Nowe Chechlo (Poland)",
  "Nowe Lignowy (Poland)",
  "Nowe Miasto Lubawskie (Poland)",
  "Nowe Miasto nad Pilica (Poland)",
  "Nowe Miasto nad Warta (Poland)",
  "Nowe Skalmierzyce (Poland)",
  "Nowo-Aleksandrowo (Poland)",
  "Nowogard (Poland)",
  "Nowogrodziec (Poland)",
  "Nowy Dwor (Poland)",
  "Nowy Dwor Gdanski (Poland)",
  "Nowy Dwor Mazowiecki (Poland)",
  "Nowy Korczyn (Poland)",
  "Nowy Staw (Poland)",
  "Nowy Swietow (Poland)",
  "Nowy Targ (Poland)",
  "Nowy Tomysl (Poland)",
  "Nowy Wisnicz (Poland)",
  "Nysa (Poland)",
  "Oblaczkowo (Poland)",
  "Oborniki (Poland)",
  "Obrzycko (Poland)",
  "Obsza (Poland)",
  "Odolanow (Poland)",
  "Odolin (Poland)",
  "Odrzykon (Poland)",
  "Ogrody (Poland)",
  "Ogrodzieniec (Poland)",
  "Ojrzen (Poland)",
  "Oksywie (Poland)",
  "Olawa (Poland)",
  "Olecko (Poland)",
  "Olejnica (Poland)",
  "Olesnica (Poland)",
  "Olesno (Poland)",
  "Oleszno (Poland)",
  "Olimpia (Poland)",
  "Olkusz (Poland)",
  "Olszany (Poland)",
  "Olszowice (Poland)",
  "Olsztyn (Poland)",
  "Olsztynek (Poland)",
  "Olszyna (Poland)",
  "Oltarzew (Poland)",
  "Opalenica (Poland)",
  "Opatow (Poland)",
  "Opatowek (Poland)",
  "Opoczno (Poland)",
  "Opole (Poland)",
  "Orchowo (Poland)",
  "Orneta (Poland)",
  "Ornontowice (Poland)",
  "Orzel (Poland)",
  "Orzesze (Poland)",
  "Orzysz (Poland)",
  "Osieck (Poland)",
  "Osieczna (Poland)",
  "Osiek (Poland)",
  "Osiek Jasielski (Poland)",
  "Osielsko (Poland)",
  "Osno (Poland)",
  "Osno Lubuskie (Poland)",
  "Ostaszewo (Poland)",
  "Ostrog (Poland)",
  "Ostroszowice (Poland)",
  "Ostrow (Poland)",
  "Ostrow Lubelski (Poland)",
  "Ostrow Mazowiecka (Poland)",
  "Ostrowek (Poland)",
  "Ostrowite (Poland)",
  "Ostrowy (Poland)",
  "Ostrzeszow (Poland)",
  "Otoki (Poland)",
  "Otomin (Poland)",
  "Otrebusy (Poland)",
  "Otwock (Poland)",
  "Otyn (Poland)",
  "Owinska (Poland)",
  "Ozarow Mazowiecki (Poland)",
  "Ozimek (Poland)",
  "Ozorkow (Poland)",
  "Pabianice (Poland)",
  "Pacanow (Poland)",
  "Pajeczno (Poland)",
  "Paledzie (Poland)",
  "Paliszewo (Poland)",
  "Paniowki (Poland)",
  "Papowo (Poland)",
  "Parczew (Poland)",
  "Pastuchow (Poland)",
  "Paulina (Poland)",
  "Pawlowice (Poland)",
  "Pcim (Poland)",
  "Peczniew (Poland)",
  "Pedziwiatry (Poland)",
  "Pegow (Poland)",
  "Pelplin (Poland)",
  "Pepowo (Poland)",
  "Pewel Mala (Poland)",
  "Piaseczno (Poland)",
  "Piasek (Poland)",
  "Piaski (Poland)",
  "Piastow (Poland)",
  "Piechowice (Poland)",
  "Piekary (Poland)",
  "Piekary Slaskie (Poland)",
  "Piekielnik (Poland)",
  "Piekoszow (Poland)",
  "Pielgrzymka (Poland)",
  "Pielice (Poland)",
  "Piensk (Poland)",
  "Pierwoszyno (Poland)",
  "Pieszkow (Poland)",
  "Pieszyce (Poland)",
  "Pietrowice Wielkie (Poland)",
  "Pietrzykowice (Poland)",
  "Pila (Poland)",
  "Pila Koscielecka (Poland)",
  "Pilawa (Poland)",
  "Pilawa Gorna (Poland)",
  "Pilchowice (Poland)",
  "Pilica (Poland)",
  "Pinczow (Poland)",
  "Pionki (Poland)",
  "Pisarzowice (Poland)",
  "Pisz (Poland)",
  "Plesna (Poland)",
  "Pleszew (Poland)",
  "Plewiska (Poland)",
  "Plochocin (Poland)",
  "Pniewy (Poland)",
  "Pobiedna (Poland)",
  "Pobiedziska (Poland)",
  "Poczesna (Poland)",
  "Podegrodzie (Poland)",
  "Podgorne (Poland)",
  "Podgorze (Poland)",
  "Podlasie (Poland)",
  "Podleze (Poland)",
  "Pogodki (Poland)",
  "Pogorzela (Poland)",
  "Pogwizdow (Poland)",
  "Pokrzywnica (Poland)",
  "Polajewo (Poland)",
  "Polanka Wielka (Poland)",
  "Polczyn-Zdroj (Poland)",
  "Police (Poland)",
  "Polkowice (Poland)",
  "Polomia (Poland)",
  "Polskie (Poland)",
  "Pomiechowek (Poland)",
  "Pomorskie (Poland)",
  "Popow (Poland)",
  "Popowice (Poland)",
  "Poraj (Poland)",
  "Poreba (Poland)",
  "Poswietne (Poland)",
  "Poznan (Poland)",
  "Prabuty (Poland)",
  "Praca (Poland)",
  "Praszka (Poland)",
  "Prawiedniki (Poland)",
  "Prochowice (Poland)",
  "Prokocim (Poland)",
  "Prosna (Poland)",
  "Proszowice (Poland)",
  "Pruchna (Poland)",
  "Prudnik (Poland)",
  "Prusice (Poland)",
  "Pruszcz Gdanski (Poland)",
  "Pruszcz Pomorski (Poland)",
  "Przasnysz (Poland)",
  "Przechlewo (Poland)",
  "Przeclaw (Poland)",
  "Przemet (Poland)",
  "Przemysl (Poland)",
  "Przemyslaw (Poland)",
  "Przeworsk (Poland)",
  "Przezmierowo (Poland)",
  "Przygodzice (Poland)",
  "Przylep (Poland)",
  "Przyrow (Poland)",
  "Przysiek (Poland)",
  "Przystajn (Poland)",
  "Przyszowice (Poland)",
  "Pszczew (Poland)",
  "Pszczyna (Poland)",
  "Pszow (Poland)",
  "Puck (Poland)",
  "Pustkow (Poland)",
  "Puszczew (Poland)",
  "Puszczykowo (Poland)",
  "Pyrzyce (Poland)",
  "Pyskowice (Poland)",
  "Pysznica (Poland)",
  "Rabien (Poland)",
  "Rabka-Zdroj (Poland)",
  "Raciaz (Poland)",
  "Raciazek (Poland)",
  "Racula (Poland)",
  "Raczka (Poland)",
  "Raczki (Poland)",
  "Radgoszcz (Poland)",
  "Radlin (Poland)",
  "Radlow (Poland)",
  "Radom (Poland)",
  "Radomin (Poland)",
  "Radomsko (Poland)",
  "Radomysl Wielki (Poland)",
  "Radoslaw (Poland)",
  "Radostowice (Poland)",
  "Radoszyce (Poland)",
  "Radymno (Poland)",
  "Radzanow (Poland)",
  "Radzanowo (Poland)",
  "Radziechowy (Poland)",
  "Radziejowice (Poland)",
  "Radzionkow (Poland)",
  "Radzymin (Poland)",
  "Radzyn Podlaski (Poland)",
  "Rajszew (Poland)",
  "Rakow (Poland)",
  "Rakowiec (Poland)",
  "Rakszawa (Poland)",
  "Ranizow (Poland)",
  "Raszowa (Poland)",
  "Raszowka (Poland)",
  "Raszyn (Poland)",
  "Rawa Mazowiecka (Poland)",
  "Rawicz (Poland)",
  "Rebkow (Poland)",
  "Rebowo (Poland)",
  "Reczno (Poland)",
  "Reda (Poland)",
  "Regimin (Poland)",
  "Rejowiec (Poland)",
  "Reszel (Poland)",
  "Rewa (Poland)",
  "Rewal (Poland)",
  "Roczyny (Poland)",
  "Rogalinek (Poland)",
  "Rogow (Poland)",
  "Rogowo (Poland)",
  "Rogoznik (Poland)",
  "Rogozno (Poland)",
  "Rokietnica (Poland)",
  "Rokitki (Poland)",
  "Rokitnica (Poland)",
  "Ropczyce (Poland)",
  "Rosnowko (Poland)",
  "Rostarzewo (Poland)",
  "Rozanka (Poland)",
  "Rozgarty (Poland)",
  "Rozprza (Poland)",
  "Roztoka (Poland)",
  "Ruda (Poland)",
  "Rudka (Poland)",
  "Rudna Mala (Poland)",
  "Rudnik nad Sanem (Poland)",
  "Rudy (Poland)",
  "Rudzica (Poland)",
  "Rudziniec (Poland)",
  "Rumia (Poland)",
  "Rumianek (Poland)",
  "Rusiec (Poland)",
  "Rybna (Poland)",
  "Rybnik (Poland)",
  "Rybno (Poland)",
  "Rychwal (Poland)",
  "Rydzyna (Poland)",
  "Ryglice (Poland)",
  "Ryki (Poland)",
  "Ryman (Poland)",
  "Rymanow (Poland)",
  "Ryn (Poland)",
  "Rypin (Poland)",
  "Rzasnia (Poland)",
  "Rzeczyce (Poland)",
  "Rzemien (Poland)",
  "Rzepin (Poland)",
  "Rzeszotary (Poland)",
  "Rzewnie (Poland)",
  "Rzgow Pierwszy (Poland)",
  "Rzuchowa (Poland)",
  "Sadlinki (Poland)",
  "Sadlno (Poland)",
  "Sady (Poland)",
  "Samin (Poland)",
  "Sandomierz (Poland)",
  "Sanniki (Poland)",
  "Sanok (Poland)",
  "Santok (Poland)",
  "Sarbinowo (Poland)",
  "Scinawa Mala (Poland)",
  "Scinawa Nyska (Poland)",
  "Sedziszow (Poland)",
  "Sedziszow Malopolski (Poland)",
  "Sepolno Krajenskie (Poland)",
  "Serock (Poland)",
  "Serwis (Poland)",
  "Sianow (Poland)",
  "Sidra (Poland)",
  "Sidzina (Poland)",
  "Siechnice (Poland)",
  "Siedlce (Poland)",
  "Siedlec (Poland)",
  "Siekierczyn (Poland)",
  "Siemiatycze (Poland)",
  "Siemirowice (Poland)",
  "Siemkowice (Poland)",
  "Sieniawa (Poland)",
  "Sieniawa Zarska (Poland)",
  "Sieniawka (Poland)",
  "Siennica (Poland)",
  "Siennica Nadolna (Poland)",
  "Sieradz (Poland)",
  "Sieroszowice (Poland)",
  "Sierpc (Poland)",
  "Siewierz (Poland)",
  "Siwek (Poland)",
  "Skala (Poland)",
  "Skarszewy (Poland)",
  "Skarzysko-Kamienna (Poland)",
  "Skawina (Poland)",
  "Skierdy (Poland)",
  "Skierniewice (Poland)",
  "Skoczow (Poland)",
  "Skomlin (Poland)",
  "Skopanie (Poland)",
  "Skorcz (Poland)",
  "Skorzec (Poland)",
  "Skorzewo (Poland)",
  "Skrbensko (Poland)",
  "Skrzetusz (Poland)",
  "Skrzyszow (Poland)",
  "Skwierzyna (Poland)",
  "Slawkow (Poland)",
  "Slawno B (Poland)",
  "Slawoszyno (Poland)",
  "Slemien (Poland)",
  "Slomniki (Poland)",
  "Slone (Poland)",
  "Slupca (Poland)",
  "Slupno (Poland)",
  "Smardzew (Poland)",
  "Smardzowice (Poland)",
  "Smigiel (Poland)",
  "Smolec (Poland)",
  "Smolnica (Poland)",
  "Smolnik (Poland)",
  "Sobienie Jeziory (Poland)",
  "Sobotka (Poland)",
  "Sobowidz (Poland)",
  "Sochaczew (Poland)",
  "Sochocin (Poland)",
  "Soczewka (Poland)",
  "Sokolow Malopolski (Poland)",
  "Sokolowsko (Poland)",
  "Solec Kujawski (Poland)",
  "Sompolno (Poland)",
  "Sopot (Poland)",
  "Sosnie (Poland)",
  "Sosnowiec (Poland)",
  "Sroda Wielkopolska (Poland)",
  "Stadla (Poland)",
  "Stalowa Wola (Poland)",
  "Staniatki (Poland)",
  "Staniszow (Poland)",
  "Stankowo (Poland)",
  "Stanowice (Poland)",
  "Stara (Poland)",
  "Stara Kamienica (Poland)",
  "Stara Lubianka (Poland)",
  "Starachowice (Poland)",
  "Stare Babice (Poland)",
  "Stare Bogaczowice (Poland)",
  "Stare Czarnowo (Poland)",
  "Stare Juchy (Poland)",
  "Stare Kurowo (Poland)",
  "Stare Miasto (Poland)",
  "Stary Broniszew (Poland)",
  "Stary Garbow (Poland)",
  "Stary Sacz (Poland)",
  "Stary Zamosc (Poland)",
  "Staszow (Poland)",
  "Staw (Poland)",
  "Stawiany (Poland)",
  "Stawiguda (Poland)",
  "Stegna (Poland)",
  "Steszew (Poland)",
  "Stoczek (Poland)",
  "Stolno (Poland)",
  "Stopnica (Poland)",
  "Straszyn (Poland)",
  "Strawczyn (Poland)",
  "Strazow (Poland)",
  "Stronie Slaskie (Poland)",
  "Stroze (Poland)",
  "Strumien (Poland)",
  "Strykow (Poland)",
  "Stryszawa (Poland)",
  "Stryszow (Poland)",
  "Strzalkowo (Poland)",
  "Strzebielino (Poland)",
  "Strzebin (Poland)",
  "Strzegom (Poland)",
  "Strzelce Krajenskie (Poland)",
  "Strzelce Opolskie (Poland)",
  "Strzeleczki (Poland)",
  "Strzelno (Poland)",
  "Strzeszow (Poland)",
  "Strzyze (Poland)",
  "Strzyzow (Poland)",
  "Studzieniec (Poland)",
  "Subkowy (Poland)",
  "Sucha Beskidzka (Poland)",
  "Suchedniow (Poland)",
  "Suchowola (Poland)",
  "Suchy Dab (Poland)",
  "Suchy Las (Poland)",
  "Sulechow (Poland)",
  "Sulejow (Poland)",
  "Sulejowek (Poland)",
  "Sulistrowice (Poland)",
  "Sulmierzyce (Poland)",
  "Sulmin (Poland)",
  "Suloszowa (Poland)",
  "Supienie (Poland)",
  "Susiec (Poland)",
  "Susz (Poland)",
  "Suszec (Poland)",
  "Suwaki (Poland)",
  "Swarorzyn (Poland)",
  "Swarzedz (Poland)",
  "Swarzewo (Poland)",
  "Swiatki (Poland)",
  "Swidnica (Poland)",
  "Swidnik (Poland)",
  "Swidwin (Poland)",
  "Swiebodzice (Poland)",
  "Swiebodzin (Poland)",
  "Swiecie nad Osa (Poland)",
  "Swiekatowo (Poland)",
  "Swierk (Poland)",
  "Swierklany (Poland)",
  "Swieta Katarzyna (Poland)",
  "Swietno (Poland)",
  "Swietoniowa (Poland)",
  "Swiniary (Poland)",
  "Swoboda (Poland)",
  "Sycewice (Poland)",
  "Sycow (Poland)",
  "Syrynia (Poland)",
  "Szadek (Poland)",
  "Szadlowice (Poland)",
  "Szczaniec (Poland)",
  "Szczawno (Poland)",
  "Szczawno-Zdroj (Poland)",
  "Szczecin (Poland)",
  "Szczecinek (Poland)",
  "Szczejkowice (Poland)",
  "Szczepanow (Poland)",
  "Szczercow (Poland)",
  "Szczucin (Poland)",
  "Szczuczyn (Poland)",
  "Szczurowa (Poland)",
  "Szczyrk (Poland)",
  "Szczytniki (Poland)",
  "Szczytno (Poland)",
  "Szebnie (Poland)",
  "Szemud (Poland)",
  "Szepietowo (Poland)",
  "Szewce (Poland)",
  "Szlachta (Poland)",
  "Szostka (Poland)",
  "Szowsko (Poland)",
  "Szprotawa (Poland)",
  "Szreniawa (Poland)",
  "Sztum (Poland)",
  "Sztutowo (Poland)",
  "Szyce (Poland)",
  "Szydlowiec (Poland)",
  "Szydlowo (Poland)",
  "Szyldak (Poland)",
  "Szymanow (Poland)",
  "Szypliszki (Poland)",
  "Tanowo (Poland)",
  "Tarchaly Wielkie (Poland)",
  "Tarczyn (Poland)",
  "Targanice (Poland)",
  "Tarnobrzeg (Poland)",
  "Tarnogrod (Poland)",
  "Tarnow Opolski (Poland)",
  "Tarnowiec (Poland)",
  "Tarnowo Podgorne (Poland)",
  "Tarnowskie Gory (Poland)",
  "Tczew (Poland)",
  "Tecza (Poland)",
  "Tegoborze (Poland)",
  "Tenczyn (Poland)",
  "Teofilow (Poland)",
  "Teresin (Poland)",
  "Terespol (Poland)",
  "Tluczan (Poland)",
  "Tolkmicko (Poland)",
  "Tomaszow (Poland)",
  "Tomaszow Lubelski (Poland)",
  "Tomaszowice (Poland)",
  "Topola Mala (Poland)",
  "Torzym (Poland)",
  "Toszek (Poland)",
  "Towarzystwo (Poland)",
  "Trabki (Poland)",
  "Trawniki (Poland)",
  "Trojanow (Poland)",
  "Trzciana (Poland)",
  "Trzcianka (Poland)",
  "Trzciel (Poland)",
  "Trzcinsko (Poland)",
  "Trzebinia (Poland)",
  "Trzebnica (Poland)",
  "Trzebownisko (Poland)",
  "Trzebunia (Poland)",
  "Trzemesnia (Poland)",
  "Trzemeszno (Poland)",
  "Trzemeszno Lubuskie (Poland)",
  "Trzesniow (Poland)",
  "Trzeszczyn (Poland)",
  "Trzyciaz (Poland)",
  "Trzydnik Duzy (Poland)",
  "Tuchola (Poland)",
  "Tuchow (Poland)",
  "Tuczno (Poland)",
  "Tulce (Poland)",
  "Turbia (Poland)",
  "Turek (Poland)",
  "Turobin (Poland)",
  "Turowiec (Poland)",
  "Tuszyn (Poland)",
  "Twardawa (Poland)",
  "Twardogora (Poland)",
  "Tworog (Poland)",
  "Tychy (Poland)",
  "Tyczyn (Poland)",
  "Tykocin (Poland)",
  "Tylicz (Poland)",
  "Tylmanowa (Poland)",
  "Tymbark (Poland)",
  "Tymowa (Poland)",
  "Tyszowce (Poland)",
  "Uciechow (Poland)",
  "Ujazd (Poland)",
  "Ujscie (Poland)",
  "Ulez (Poland)",
  "Unieszewo (Poland)",
  "Ustanow (Poland)",
  "Ustka (Poland)",
  "Ustron (Poland)",
  "Ustrzyki Dolne (Poland)",
  "Wabrzezno (Poland)",
  "Wachock (Poland)",
  "Wadowice (Poland)",
  "Wadowice Gorne (Poland)",
  "Waganiec (Poland)",
  "Wagrowiec (Poland)",
  "Walcz (Poland)",
  "Walim (Poland)",
  "Wambierzyce (Poland)",
  "Wapielsk (Poland)",
  "Warka (Poland)",
  "Warsaw (Poland)",
  "Warzachewka Polska (Poland)",
  "Warzno (Poland)",
  "Warzymice (Poland)",
  "Wasilkow (Poland)",
  "Wasniow (Poland)",
  "Wawel (Poland)",
  "Wawolnica (Poland)",
  "Wegierska Gorka (Poland)",
  "Wegliniec (Poland)",
  "Weglowice (Poland)",
  "Wegrzce Wielkie (Poland)",
  "Wejherowo (Poland)",
  "Wiazow (Poland)",
  "Wicko (Poland)",
  "Wieckowice (Poland)",
  "Wielbark (Poland)",
  "Wielen Zaobrzanski (Poland)",
  "Wielgie (Poland)",
  "Wielichowo (Poland)",
  "Wieliczka (Poland)",
  "Wieliszew (Poland)",
  "Wielki Klincz (Poland)",
  "Wieloglowy (Poland)",
  "Wielopole Skrzynskie (Poland)",
  "Wielowies (Poland)",
  "Wieruszow (Poland)",
  "Wierzchowisko (Poland)",
  "Wieszowa (Poland)",
  "Wijewo (Poland)",
  "Wilczeta (Poland)",
  "Wilczyce (Poland)",
  "Wilczyn (Poland)",
  "Wilga (Poland)",
  "Wilkanowo (Poland)",
  "Wilkow (Poland)",
  "Wilkowice (Poland)",
  "Wilkowisko (Poland)",
  "Winnica (Poland)",
  "Winsko (Poland)",
  "Wiorek (Poland)",
  "Wisla (Poland)",
  "Wisla Wielka (Poland)",
  "Wisniew (Poland)",
  "Wisniowa (Poland)",
  "Wisznice (Poland)",
  "Witaszyce (Poland)",
  "Witkowo (Poland)",
  "Witnica (Poland)",
  "Wlodawa (Poland)",
  "Wlodzimierz (Poland)",
  "Wloszczowa (Poland)",
  "Wojciech (Poland)",
  "Wojkowice (Poland)",
  "Wojkowice Koscielne (Poland)",
  "Wola Baranowska (Poland)",
  "Wola Filipowska (Poland)",
  "Wola Kopcowa (Poland)",
  "Wola Krzysztoporska (Poland)",
  "Wola Radlowska (Poland)",
  "Wola Rasztowska (Poland)",
  "Wola Rebkowska (Poland)",
  "Wola Zaradzynska (Poland)",
  "Wolborz (Poland)",
  "Wolbrom (Poland)",
  "Wolin (Poland)",
  "Wolka (Poland)",
  "Wolow (Poland)",
  "Wolsztyn (Poland)",
  "Wozniki (Poland)",
  "Wreczyca Wielka (Poland)",
  "Wronki (Poland)",
  "Wrzesnia (Poland)",
  "Wrzosowa (Poland)",
  "Wrzosowka (Poland)",
  "Wschowa (Poland)",
  "Wylatowo (Poland)",
  "Wymiarki (Poland)",
  "Wyrzysk (Poland)",
  "Wysogotowo (Poland)",
  "Wysoka (Poland)",
  "Wysoka Strzyzowska (Poland)",
  "Wysokie Mazowieckie (Poland)",
  "Wystep (Poland)",
  "Wyszogrod (Poland)",
  "Zabia Wola (Poland)",
  "Zabierzow Bochenski (Poland)",
  "Zablocie (Poland)",
  "Zabno (Poland)",
  "Zabor (Poland)",
  "Zabrowo (Poland)",
  "Zabrze (Poland)",
  "Zabrzeg (Poland)",
  "Zaczernie (Poland)",
  "Zagnansk (Poland)",
  "Zagorow (Poland)",
  "Zagorzyce (Poland)",
  "Zagosciniec (Poland)",
  "Zagrodno (Poland)",
  "Zakopane (Poland)",
  "Zalakowo (Poland)",
  "Zalasewo (Poland)",
  "Zalesie (Poland)",
  "Zaleszany (Poland)",
  "Zalewo (Poland)",
  "Zalubice Nowe (Poland)",
  "Zalubice Stare (Poland)",
  "Zaluski (Poland)",
  "Zaniemysl (Poland)",
  "Zaparcin (Poland)",
  "Zarow (Poland)",
  "Zarowka (Poland)",
  "Zarszyn (Poland)",
  "Zary (Poland)",
  "Zator (Poland)",
  "Zawada (Poland)",
  "Zawady (Poland)",
  "Zawadzkie (Poland)",
  "Zawidow (Poland)",
  "Zawiercie (Poland)",
  "Zawoja (Poland)",
  "Zawonia (Poland)",
  "Zbaszyn (Poland)",
  "Zbaszynek (Poland)",
  "Zbiczno (Poland)",
  "Zblewo (Poland)",
  "Zbroslawice (Poland)",
  "Zdunska Wola (Poland)",
  "Zduny (Poland)",
  "Zdzieszowice (Poland)",
  "Zebowice (Poland)",
  "Zebrzydowice (Poland)",
  "Zegrze Pomorskie (Poland)",
  "Zelazkow (Poland)",
  "Zelow (Poland)",
  "Zembrzyce (Poland)",
  "Zerkow (Poland)",
  "Zernica (Poland)",
  "Zerniki (Poland)",
  "Zgierz (Poland)",
  "Zglobice (Poland)",
  "Zglobien (Poland)",
  "Zgorsko (Poland)",
  "Zgorzala (Poland)",
  "Zgorzelec (Poland)",
  "Ziebice (Poland)",
  "Zielona (Poland)",
  "Zielone (Poland)",
  "Zielonka (Poland)",
  "Zielonki (Poland)",
  "Zielonki-Wies (Poland)",
  "Zlocieniec (Poland)",
  "Zloczew (Poland)",
  "Zlotow (Poland)",
  "Zloty Stok (Poland)",
  "Zmigrod (Poland)",
  "Znin (Poland)",
  "Zofia (Poland)",
  "Zorawia (Poland)",
  "Zorawina (Poland)",
  "Zosin (Poland)",
  "Zrecin (Poland)",
  "Zukowo (Poland)",
  "Zurawica (Poland)",
  "Zwolen (Poland)",
  "Zyrakow (Poland)",
  "Portugal",
  "Abobada (Portugal)",
  "Abrantes (Portugal)",
  "Acores (Portugal)",
  "Aguada de Cima (Portugal)",
  "Agualva (Portugal)",
  "Agucadoura (Portugal)",
  "Aguiar da Beira Municipality (Portugal)",
  "Alandroal (Portugal)",
  "Albergaria-a-Velha (Portugal)",
  "Albufeira (Portugal)",
  "Alcabideche (Portugal)",
  "Alcacer do Sal (Portugal)",
  "Alcains (Portugal)",
  "Alcanede (Portugal)",
  "Alcanena (Portugal)",
  "Alcobaca (Portugal)",
  "Alcochete (Portugal)",
  "Alcoentre (Portugal)",
  "Aldeia de Paio Pires (Portugal)",
  "Aldeia do Meio (Portugal)",
  "Aldeia dos Fernandes (Portugal)",
  "Alenquer (Portugal)",
  "Alfarelos (Portugal)",
  "Alfena (Portugal)",
  "Alfornelos (Portugal)",
  "Alfragide (Portugal)",
  "Alges (Portugal)",
  "Algoz (Portugal)",
  "Algueirao (Portugal)",
  "Alhandra (Portugal)",
  "Alhos Vedros (Portugal)",
  "Aljezur (Portugal)",
  "Aljustrel (Portugal)",
  "Almada (Portugal)",
  "Almancil (Portugal)",
  "Almargem (Portugal)",
  "Almeirim (Portugal)",
  "Almodovar (Portugal)",
  "Alpiarca (Portugal)",
  "Alportel (Portugal)",
  "Alqueidao da Serra (Portugal)",
  "Alter do Chao (Portugal)",
  "Altura (Portugal)",
  "Alvaiazere (Portugal)",
  "Alvarinhos (Portugal)",
  "Alvelos (Portugal)",
  "Alverca (Portugal)",
  "Amadora (Portugal)",
  "Amarante (Portugal)",
  "Amares (Portugal)",
  "Amiaes de Cima (Portugal)",
  "Amor (Portugal)",
  "Amora (Portugal)",
  "Amoreira (Portugal)",
  "Amorim (Portugal)",
  "Anadia (Portugal)",
  "Anta (Portugal)",
  "Apulia (Portugal)",
  "Arazede (Portugal)",
  "Arco da Calheta (Portugal)",
  "Arcos de Valdevez (Portugal)",
  "Arcozelo (Portugal)",
  "Arganil (Portugal)",
  "Argoncilhe (Portugal)",
  "Armacao de Pera (Portugal)",
  "Arouca (Portugal)",
  "Arraiolos (Portugal)",
  "Arrifana (Portugal)",
  "Arronches (Portugal)",
  "Arruda dos Vinhos (Portugal)",
  "Arvore (Portugal)",
  "Assumar (Portugal)",
  "Atalaia (Portugal)",
  "Atouguia da Baleia (Portugal)",
  "Avanca (Portugal)",
  "Aveiras de Cima (Portugal)",
  "Aveiro (Portugal)",
  "Avelar (Portugal)",
  "Avidos (Portugal)",
  "Avintes (Portugal)",
  "Azoia (Portugal)",
  "Azueira (Portugal)",
  "Azurem (Portugal)",
  "Baguim (Portugal)",
  "Baguim do Monte (Portugal)",
  "Baleia (Portugal)",
  "Baltar (Portugal)",
  "Barcarena (Portugal)",
  "Barcelos (Portugal)",
  "Barreiro (Portugal)",
  "Barrosas (Portugal)",
  "Basto (Portugal)",
  "Batalha (Portugal)",
  "Beira (Portugal)",
  "Beja (Portugal)",
  "Belas (Portugal)",
  "Belmonte (Portugal)",
  "Bemfica (Portugal)",
  "Benafim (Portugal)",
  "Benavente (Portugal)",
  "Benedita (Portugal)",
  "Bicesse (Portugal)",
  "Bico (Portugal)",
  "Boavista (Portugal)",
  "Bobadela (Portugal)",
  "Boliqueime (Portugal)",
  "Bombarral (Portugal)",
  "Borba (Portugal)",
  "Boticas (Portugal)",
  "Bouca (Portugal)",
  "Braga (Portugal)",
  "Braganca (Portugal)",
  "Brito (Portugal)",
  "Bucelas (Portugal)",
  "Buraca (Portugal)",
  "Cabanelas (Portugal)",
  "Cabeceiras de Basto (Portugal)",
  "Cabeco de Vide (Portugal)",
  "Cabecudos (Portugal)",
  "Cabrela (Portugal)",
  "Cacela (Portugal)",
  "Cacem (Portugal)",
  "Cacilhas (Portugal)",
  "Cadaval (Portugal)",
  "Caldas (Portugal)",
  "Caldas da Rainha (Portugal)",
  "Caldas das Taipas (Portugal)",
  "Caldas de Sao Jorge (Portugal)",
  "Caldas de Vizela (Portugal)",
  "Calvao (Portugal)",
  "Calvaria de Cima (Portugal)",
  "Camarate (Portugal)",
  "Caminha (Portugal)",
  "Campanario (Portugal)",
  "Campia (Portugal)",
  "Campinho (Portugal)",
  "Campo (Portugal)",
  "Campo Maior (Portugal)",
  "Canecas (Portugal)",
  "Canedo (Portugal)",
  "Canelas (Portugal)",
  "Canhas (Portugal)",
  "Canical (Portugal)",
  "Canico (Portugal)",
  "Canidelo (Portugal)",
  "Cano (Portugal)",
  "Cantanhede (Portugal)",
  "Caparica (Portugal)",
  "Caramulo (Portugal)",
  "Carapinheira (Portugal)",
  "Carcavelos (Portugal)",
  "Carnaxide (Portugal)",
  "Carrazedo (Portugal)",
  "Carregado (Portugal)",
  "Carrico (Portugal)",
  "Cartaxo (Portugal)",
  "Carvalhal (Portugal)",
  "Carvalho (Portugal)",
  "Carvalhosa (Portugal)",
  "Carvoeiro (Portugal)",
  "Casal (Portugal)",
  "Casal da Areia (Portugal)",
  "Cascais (Portugal)",
  "Castanheira do Ribatejo (Portugal)",
  "Castelejo (Portugal)",
  "Castelo (Portugal)",
  "Castelo Branco (Portugal)",
  "Castelo de Paiva (Portugal)",
  "Castro Daire (Portugal)",
  "Castro Marim (Portugal)",
  "Castro Verde Municipality (Portugal)",
  "Catraia de Sao Paio (Portugal)",
  "Cavaleiros (Portugal)",
  "Cavaloes (Portugal)",
  "Caxias (Portugal)",
  "Ceira (Portugal)",
  "Celorico de Basto (Portugal)",
  "Cercal (Portugal)",
  "Cernache (Portugal)",
  "Cesar (Portugal)",
  "Chao de Couce (Portugal)",
  "Charneca (Portugal)",
  "Chaves (Portugal)",
  "Cidade (Portugal)",
  "Cinfaes (Portugal)",
  "Coimbra (Portugal)",
  "Colares (Portugal)",
  "Colmeias (Portugal)",
  "Condeixa-a-Nova (Portugal)",
  "Constance (Portugal)",
  "Constancia (Portugal)",
  "Corroios (Portugal)",
  "Cortegaca (Portugal)",
  "Costa de Caparica (Portugal)",
  "Costa de Lavos (Portugal)",
  "Covilha (Portugal)",
  "Creixomil (Portugal)",
  "Cruz Quebrada (Portugal)",
  "Cuba (Portugal)",
  "Cucujaes (Portugal)",
  "Dalvares (Portugal)",
  "Damaia (Portugal)",
  "Darque (Portugal)",
  "Dois Portos (Portugal)",
  "Dona Maria (Portugal)",
  "Dossaos (Portugal)",
  "Eixo (Portugal)",
  "Elvas (Portugal)",
  "Encarnacao (Portugal)",
  "Entroncamento (Portugal)",
  "Ericeira (Portugal)",
  "Ermidas (Portugal)",
  "Escapaes (Portugal)",
  "Escoural (Portugal)",
  "Esmoriz (Portugal)",
  "Espinho (Portugal)",
  "Esposende (Portugal)",
  "Estarreja (Portugal)",
  "Estoi (Portugal)",
  "Estombar (Portugal)",
  "Estoril (Portugal)",
  "Estremoz (Portugal)",
  "Evora (Portugal)",
  "Fafe (Portugal)",
  "Famoes (Portugal)",
  "Fanzeres (Portugal)",
  "Fao (Portugal)",
  "Faro (Portugal)",
  "Feira (Portugal)",
  "Felgueiras (Portugal)",
  "Ferradosa (Portugal)",
  "Ferreira (Portugal)",
  "Ferreira do Alentejo (Portugal)",
  "Ferrel (Portugal)",
  "Ferro (Portugal)",
  "Fiaes (Portugal)",
  "Figueira da Foz Municipality (Portugal)",
  "Figueira de Lorvao (Portugal)",
  "Figueiro dos Vinhos (Portugal)",
  "Fogueteiro (Portugal)",
  "Folgados (Portugal)",
  "Forte (Portugal)",
  "Foz do Sousa (Portugal)",
  "Fradelos (Portugal)",
  "Freamunde (Portugal)",
  "Frielas (Portugal)",
  "Funchal (Portugal)",
  "Fundao (Portugal)",
  "Gafanha da Nazare (Portugal)",
  "Galiza (Portugal)",
  "Gandara dos Olivais (Portugal)",
  "Gandra (Portugal)",
  "Garruchas (Portugal)",
  "Gemunde (Portugal)",
  "Godim (Portugal)",
  "Golega (Portugal)",
  "Gondomar (Portugal)",
  "Gouveia (Portugal)",
  "Graca (Portugal)",
  "Gradil (Portugal)",
  "Grandola (Portugal)",
  "Granja (Portugal)",
  "Granja do Ulmeiro (Portugal)",
  "Grijo (Portugal)",
  "Guarda (Portugal)",
  "Guardizela (Portugal)",
  "Guia (Portugal)",
  "Guimaraes (Portugal)",
  "Gulpilhares (Portugal)",
  "Igreja Nova (Portugal)",
  "Ilhavo (Portugal)",
  "Infesta (Portugal)",
  "Joane (Portugal)",
  "Jovim (Portugal)",
  "Juncal (Portugal)",
  "Lagarinhos (Portugal)",
  "Lago (Portugal)",
  "Lagoa (Portugal)",
  "Lagoas (Portugal)",
  "Lagos (Portugal)",
  "Lajes (Portugal)",
  "Lamego (Portugal)",
  "Landim (Portugal)",
  "Lavos (Portugal)",
  "Lavra (Portugal)",
  "Lavradio (Portugal)",
  "Leca da Palmeira (Portugal)",
  "Leca do Bailio (Portugal)",
  "Leiria (Portugal)",
  "Linda a Velha (Portugal)",
  "Linhaceira (Portugal)",
  "Linho (Portugal)",
  "Lisbon (Portugal)",
  "Lordelo (Portugal)",
  "Loule (Portugal)",
  "Lourel de Baixo (Portugal)",
  "Loures (Portugal)",
  "Lourical (Portugal)",
  "Lourosa (Portugal)",
  "Lousa (Portugal)",
  "Lousada (Portugal)",
  "Lousado (Portugal)",
  "Macas de Caminho (Portugal)",
  "Maceda (Portugal)",
  "Macedo de Cavaleiros (Portugal)",
  "Maceira (Portugal)",
  "Machico (Portugal)",
  "Macieira da Lixa (Portugal)",
  "Madeira (Portugal)",
  "Mafra (Portugal)",
  "Maia (Portugal)",
  "Malveira (Portugal)",
  "Malveira da Serra (Portugal)",
  "Mamouros (Portugal)",
  "Mangualde (Portugal)",
  "Manique de Baixo (Portugal)",
  "Manique do Intendente (Portugal)",
  "Marco (Portugal)",
  "Marco de Canaveses (Portugal)",
  "Margaride (Portugal)",
  "Marinha (Portugal)",
  "Marinha Grande (Portugal)",
  "Marinhais (Portugal)",
  "Marteleira (Portugal)",
  "Martinganca (Portugal)",
  "Massama (Portugal)",
  "Massarelos (Portugal)",
  "Matosinhos Municipality (Portugal)",
  "Mealhada (Portugal)",
  "Mem Martins (Portugal)",
  "Mesao Frio (Portugal)",
  "Mexilhoeira Grande (Portugal)",
  "Milharado (Portugal)",
  "Milhazes (Portugal)",
  "Minde (Portugal)",
  "Mira (Portugal)",
  "Miranda do Corvo (Portugal)",
  "Miranda do Douro (Portugal)",
  "Mirandela (Portugal)",
  "Mocarria (Portugal)",
  "Modivas (Portugal)",
  "Moita (Portugal)",
  "Moncao (Portugal)",
  "Monchique (Portugal)",
  "Monforte (Portugal)",
  "Monsanto (Portugal)",
  "Monsaraz (Portugal)",
  "Montalvo (Portugal)",
  "Monte Estoril (Portugal)",
  "Montelavar (Portugal)",
  "Montemor-o-Novo (Portugal)",
  "Montemor-o-Velho (Portugal)",
  "Montes (Portugal)",
  "Montijo (Portugal)",
  "Moreira de Conegos (Portugal)",
  "Mortagua (Portugal)",
  "Moscavide (Portugal)",
  "Moura (Portugal)",
  "Moure (Portugal)",
  "Mourisca do Vouga (Portugal)",
  "Mozelos (Portugal)",
  "Muro (Portugal)",
  "Murtede (Portugal)",
  "Murteira (Portugal)",
  "Nadadouro (Portugal)",
  "Negrelos (Portugal)",
  "Neiva (Portugal)",
  "Nelas (Portugal)",
  "Nespereira (Portugal)",
  "Nine (Portugal)",
  "Nisa (Portugal)",
  "Nogueira (Portugal)",
  "Nogueira da Regedoura (Portugal)",
  "Nogueira do Cravo (Portugal)",
  "Obidos (Portugal)",
  "Odemira (Portugal)",
  "Odivelas (Portugal)",
  "Oeiras (Portugal)",
  "Oia (Portugal)",
  "Olhao (Portugal)",
  "Olhos de Agua (Portugal)",
  "Olivais (Portugal)",
  "Olival (Portugal)",
  "Oliveira (Portugal)",
  "Oliveira do Bairro (Portugal)",
  "Oliveira do Douro (Portugal)",
  "Oliveira do Hospital (Portugal)",
  "Ortiga (Portugal)",
  "Ota (Portugal)",
  "Ourem (Portugal)",
  "Ourique (Portugal)",
  "Outeiro (Portugal)",
  "Ovar (Portugal)",
  "Paco de Arcos (Portugal)",
  "Paderne (Portugal)",
  "Palhaca (Portugal)",
  "Palhais (Portugal)",
  "Palmeira (Portugal)",
  "Palmela (Portugal)",
  "Parada de Gonta (Portugal)",
  "Parada do Bispo (Portugal)",
  "Paraiso (Portugal)",
  "Parchal (Portugal)",
  "Pardilho (Portugal)",
  "Parede (Portugal)",
  "Paredes (Portugal)",
  "Paredes da Beira (Portugal)",
  "Paredes de Coura (Portugal)",
  "Pataias (Portugal)",
  "Paul (Portugal)",
  "Pechao (Portugal)",
  "Pedreiras (Portugal)",
  "Pedroso (Portugal)",
  "Pedroucos (Portugal)",
  "Pegoes Velhos (Portugal)",
  "Penacova (Portugal)",
  "Penafiel (Portugal)",
  "Penedono (Portugal)",
  "Penela (Portugal)",
  "Penha Garcia (Portugal)",
  "Peniche (Portugal)",
  "Penteado (Portugal)",
  "Pereira (Portugal)",
  "Pero Pinheiro (Portugal)",
  "Pevidem (Portugal)",
  "Piedade (Portugal)",
  "Pinhal (Portugal)",
  "Pinhal Novo (Portugal)",
  "Pinheiro (Portugal)",
  "Pinheiro da Bemposta (Portugal)",
  "Pinheiro de Azere (Portugal)",
  "Poceirao (Portugal)",
  "Poco Barreto (Portugal)",
  "Poiares (Portugal)",
  "Polima (Portugal)",
  "Pombal (Portugal)",
  "Ponta (Portugal)",
  "Ponta Delgada (Portugal)",
  "Ponta do Sol (Portugal)",
  "Ponte (Portugal)",
  "Ponte da Barca (Portugal)",
  "Ponte de Lima (Portugal)",
  "Ponte de Sor (Portugal)",
  "Ponte de Vagos (Portugal)",
  "Pontevel (Portugal)",
  "Pontinha (Portugal)",
  "Portalegre (Portugal)",
  "Portel (Portugal)",
  "Portela (Portugal)",
  "Portimao (Portugal)",
  "Porto (Portugal)",
  "Porto Alto (Portugal)",
  "Porto da Cruz (Portugal)",
  "Porto de Mos (Portugal)",
  "Porto Moniz (Portugal)",
  "Porto Salvo (Portugal)",
  "Povoa (Portugal)",
  "Povoa da Galega (Portugal)",
  "Povoa de Lanhoso (Portugal)",
  "Povoa de Santa Iria (Portugal)",
  "Povoa de Santo Adriao (Portugal)",
  "Povoa do Forno (Portugal)",
  "Prado (Portugal)",
  "Pragal (Portugal)",
  "Praia da Vitoria (Portugal)",
  "Praia de Mira (Portugal)",
  "Praia do Almoxarife (Portugal)",
  "Praia do Carvoeiro (Portugal)",
  "Praia do Ribatejo (Portugal)",
  "Prazins (Portugal)",
  "Prime (Portugal)",
  "Proenca-a-Nova (Portugal)",
  "Quarteira (Portugal)",
  "Quejas (Portugal)",
  "Queluz (Portugal)",
  "Queluz de Baixo (Portugal)",
  "Quinchaes (Portugal)",
  "Quinta (Portugal)",
  "Quinta do Anjo (Portugal)",
  "Ramada (Portugal)",
  "Ramo (Portugal)",
  "Rana (Portugal)",
  "Real (Portugal)",
  "Rebordosa (Portugal)",
  "Recarei (Portugal)",
  "Redondo (Portugal)",
  "Regueira de Pontes (Portugal)",
  "Reguengo Grande (Portugal)",
  "Reguengos de Monsaraz (Portugal)",
  "Riachos (Portugal)",
  "Riba de Ave (Portugal)",
  "Ribeira Brava (Portugal)",
  "Ribeira Grande (Portugal)",
  "Ribeirao (Portugal)",
  "Rinchoa (Portugal)",
  "Rio de Moinhos (Portugal)",
  "Rio de Mouro (Portugal)",
  "Rio Maior (Portugal)",
  "Rio Tinto (Portugal)",
  "Romariz (Portugal)",
  "Ronfe (Portugal)",
  "Roque (Portugal)",
  "Roriz (Portugal)",
  "Runa (Portugal)",
  "Sabugal (Portugal)",
  "Sacavem (Portugal)",
  "Salreu (Portugal)",
  "Salto (Portugal)",
  "Salvaterra de Magos (Portugal)",
  "Samil (Portugal)",
  "Samora Correia (Portugal)",
  "Samouco (Portugal)",
  "Sandim (Portugal)",
  "Sanfins do Douro (Portugal)",
  "Sangalhos (Portugal)",
  "Sanguedo (Portugal)",
  "Santa Barbara de Nexe (Portugal)",
  "Santa Catarina (Portugal)",
  "Santa Catarina da Serra (Portugal)",
  "Santa Clara-a-Velha (Portugal)",
  "Santa Comba Dao (Portugal)",
  "Santa Cruz (Portugal)",
  "Santa Iria da Azoia (Portugal)",
  "Santa Marta (Portugal)",
  "Santa Marta de Penaguiao (Portugal)",
  "Santana (Portugal)",
  "Santiago (Portugal)",
  "Santiago da Guarda (Portugal)",
  "Santiago de Riba-Ul (Portugal)",
  "Santiago do Cacem (Portugal)",
  "Santo Andre (Portugal)",
  "Santo Antao do Tojal (Portugal)",
  "Santo Antonio (Portugal)",
  "Santo Antonio da Charneca (Portugal)",
  "Santo Tirso (Portugal)",
  "Sao Bras (Portugal)",
  "Sao Bras de Alportel (Portugal)",
  "Sao Domingos de Rana (Portugal)",
  "Sao Felix da Marinha (Portugal)",
  "Sao Francisco (Portugal)",
  "Sao Joao (Portugal)",
  "Sao Joao da Madeira (Portugal)",
  "Sao Joao da Talha (Portugal)",
  "Sao Joao das Lampas (Portugal)",
  "Sao Joao de Ver (Portugal)",
  "Sao Joao do Campo (Portugal)",
  "Sao Joao dos Montes (Portugal)",
  "Sao Juliao do Tojal (Portugal)",
  "Sao Mamede de Infesta (Portugal)",
  "Sao Marcos (Portugal)",
  "Sao Pedro da Cadeira (Portugal)",
  "Sao Pedro do Estoril (Portugal)",
  "Sao Romao do Coronado (Portugal)",
  "Sao Roque (Portugal)",
  "Sao Vicente da Beira (Portugal)",
  "Sao Vicente de Lafoes (Portugal)",
  "Sardoal (Portugal)",
  "Sarilhos Grandes (Portugal)",
  "Satao (Portugal)",
  "Seia (Portugal)",
  "Seixal (Portugal)",
  "Sendim (Portugal)",
  "Senhora da Hora (Portugal)",
  "Seroa (Portugal)",
  "Serpa (Portugal)",
  "Serpins (Portugal)",
  "Serta (Portugal)",
  "Sesimbra (Portugal)",
  "Sete Rios (Portugal)",
  "Sever do Vouga (Portugal)",
  "Silva (Portugal)",
  "Silvares (Portugal)",
  "Silveira (Portugal)",
  "Silves (Portugal)",
  "Sines Municipality (Portugal)",
  "Sintra (Portugal)",
  "Soalheira (Portugal)",
  "Sobrado (Portugal)",
  "Sobrado de Paiva (Portugal)",
  "Sobral de Monte Agraco (Portugal)",
  "Sobralinho (Portugal)",
  "Sobreda (Portugal)",
  "Sobreiro (Portugal)",
  "Sobrosa (Portugal)",
  "Soito (Portugal)",
  "Soure Municipality (Portugal)",
  "Souto da Carpalhosa (Portugal)",
  "Souzel (Portugal)",
  "Sul (Portugal)",
  "Tabuaco (Portugal)",
  "Talaide (Portugal)",
  "Tamel (Portugal)",
  "Tarouca (Portugal)",
  "Taveiro (Portugal)",
  "Tavira Municipality (Portugal)",
  "Teixoso (Portugal)",
  "Telhado (Portugal)",
  "Tercena (Portugal)",
  "Terena (Portugal)",
  "Tires (Portugal)",
  "Tocha (Portugal)",
  "Tomar (Portugal)",
  "Tondela (Portugal)",
  "Torre da Marinha (Portugal)",
  "Torre de Moncorvo (Portugal)",
  "Torres Novas (Portugal)",
  "Torres Vedras (Portugal)",
  "Touguinha (Portugal)",
  "Tourais (Portugal)",
  "Trafaria (Portugal)",
  "Trancoso (Portugal)",
  "Trancoso de Baixo (Portugal)",
  "Travanca (Portugal)",
  "Treixedo (Portugal)",
  "Trofa (Portugal)",
  "Tunes (Portugal)",
  "Turcifal (Portugal)",
  "Vagos (Portugal)",
  "Valado de Frades (Portugal)",
  "Valbom (Portugal)",
  "Vale (Portugal)",
  "Vale da Pinta (Portugal)",
  "Vale de Acor (Portugal)",
  "Vale de Cambra (Portugal)",
  "Valega (Portugal)",
  "Valenca (Portugal)",
  "Valongo (Portugal)",
  "Valverde (Portugal)",
  "Varzea do Douro (Portugal)",
  "Venda do Pinheiro (Portugal)",
  "Vendas Novas (Portugal)",
  "Vermelha (Portugal)",
  "Vermoil (Portugal)",
  "Vialonga (Portugal)",
  "Viana do Alentejo (Portugal)",
  "Viana do Castelo (Portugal)",
  "Vidago (Portugal)",
  "Vidigueira (Portugal)",
  "Vieira de Leiria (Portugal)",
  "Vieira do Minho (Portugal)",
  "Vila Alva (Portugal)",
  "Vila Boa de Quires (Portugal)",
  "Vila Cha (Portugal)",
  "Vila Cha de Ourique (Portugal)",
  "Vila de Porto Santo (Portugal)",
  "Vila do Bispo (Portugal)",
  "Vila do Conde (Portugal)",
  "Vila Franca de Xira (Portugal)",
  "Vila Franca do Rosario (Portugal)",
  "Vila Nogueira de Azeitao (Portugal)",
  "Vila Nova (Portugal)",
  "Vila Nova da Barquinha (Portugal)",
  "Vila Nova de Cerveira (Portugal)",
  "Vila Nova de Famalicao (Portugal)",
  "Vila Nova de Gaia (Portugal)",
  "Vila Nova de Paiva (Portugal)",
  "Vila Praia de Ancora (Portugal)",
  "Vila Real (Portugal)",
  "Vila Verde (Portugal)",
  "Vilar de Perdizes (Portugal)",
  "Vilar do Paraiso (Portugal)",
  "Vilar do Pinheiro (Portugal)",
  "Vilar Formoso (Portugal)",
  "Vilarinho das Paranheiras (Portugal)",
  "Vilarinho de Arcos (Portugal)",
  "Vilarinho do Bairro (Portugal)",
  "Vilela (Portugal)",
  "Vimieiro (Portugal)",
  "Vinhais (Portugal)",
  "Viseu (Portugal)",
  "Vizela (Portugal)",
  "Zambujal (Portugal)",
  "Zibreira (Portugal)",
  "Puerto Rico",
  "Adjuntas (Puerto Rico)",
  "Aguada (Puerto Rico)",
  "Aguadilla (Puerto Rico)",
  "Aguas Buenas (Puerto Rico)",
  "Aibonito (Puerto Rico)",
  "Anasco (Puerto Rico)",
  "Arecibo (Puerto Rico)",
  "Arroyo (Puerto Rico)",
  "Bajadero (Puerto Rico)",
  "Barceloneta (Puerto Rico)",
  "Barranquitas (Puerto Rico)",
  "Boqueron (Puerto Rico)",
  "Cabo Rojo (Puerto Rico)",
  "Caguas (Puerto Rico)",
  "Camuy (Puerto Rico)",
  "Canovanas (Puerto Rico)",
  "Carolina (Puerto Rico)",
  "Catano (Puerto Rico)",
  "Cayey (Puerto Rico)",
  "Ceiba (Puerto Rico)",
  "Ciales (Puerto Rico)",
  "Cidra (Puerto Rico)",
  "Coamo (Puerto Rico)",
  "Corozal (Puerto Rico)",
  "Coto Laurel (Puerto Rico)",
  "Culebra (Puerto Rico)",
  "Dorado (Puerto Rico)",
  "Ensenada (Puerto Rico)",
  "Fajardo (Puerto Rico)",
  "Florida (Puerto Rico)",
  "Garrochales (Puerto Rico)",
  "Guanica (Puerto Rico)",
  "Guayama (Puerto Rico)",
  "Guayanilla (Puerto Rico)",
  "Guaynabo (Puerto Rico)",
  "Gurabo (Puerto Rico)",
  "Hatillo (Puerto Rico)",
  "Hormigueros (Puerto Rico)",
  "Humacao (Puerto Rico)",
  "Isabela (Puerto Rico)",
  "Jayuya (Puerto Rico)",
  "Juana Diaz (Puerto Rico)",
  "Juncos (Puerto Rico)",
  "Lajas (Puerto Rico)",
  "Lares (Puerto Rico)",
  "Las Piedras (Puerto Rico)",
  "Loiza (Puerto Rico)",
  "Luquillo (Puerto Rico)",
  "Manati (Puerto Rico)",
  "Maricao (Puerto Rico)",
  "Maunabo (Puerto Rico)",
  "Mayaguez (Puerto Rico)",
  "Mercedita (Puerto Rico)",
  "Moca (Puerto Rico)",
  "Morovis (Puerto Rico)",
  "Naguabo (Puerto Rico)",
  "Naranjito (Puerto Rico)",
  "Orocovis (Puerto Rico)",
  "Palmer (Puerto Rico)",
  "Patillas (Puerto Rico)",
  "Penuelas (Puerto Rico)",
  "Ponce (Puerto Rico)",
  "Punta Santiago (Puerto Rico)",
  "Quebradillas (Puerto Rico)",
  "Residencial Puerto Real (Puerto Rico)",
  "Rincon (Puerto Rico)",
  "Rio Grande (Puerto Rico)",
  "Sabana Grande (Puerto Rico)",
  "Sabana Seca (Puerto Rico)",
  "Saint Just (Puerto Rico)",
  "Salinas (Puerto Rico)",
  "San Antonio (Puerto Rico)",
  "San German (Puerto Rico)",
  "San Juan (Puerto Rico)",
  "San Lorenzo (Puerto Rico)",
  "San Sebastian (Puerto Rico)",
  "Santa Isabel (Puerto Rico)",
  "Toa Alta (Puerto Rico)",
  "Toa Baja (Puerto Rico)",
  "Trujillo Alto (Puerto Rico)",
  "Utuado (Puerto Rico)",
  "Vega Alta (Puerto Rico)",
  "Vega Baja (Puerto Rico)",
  "Vieques (Puerto Rico)",
  "Villalba (Puerto Rico)",
  "Yabucoa (Puerto Rico)",
  "Yauco (Puerto Rico)",
  "Republic of Korea",
  "Andong (Republic of Korea)",
  "Ansan-si (Republic of Korea)",
  "Anseong (Republic of Korea)",
  "Anyang-si (Republic of Korea)",
  "Asan (Republic of Korea)",
  "Bucheon-si (Republic of Korea)",
  "Busan (Republic of Korea)",
  "Changwon (Republic of Korea)",
  "Cheonan (Republic of Korea)",
  "Cheongju-si (Republic of Korea)",
  "Chilgok (Republic of Korea)",
  "Chuncheon (Republic of Korea)",
  "Chungju (Republic of Korea)",
  "Chungnim (Republic of Korea)",
  "Daegu (Republic of Korea)",
  "Daejeon (Republic of Korea)",
  "Deokjin (Republic of Korea)",
  "Duchon (Republic of Korea)",
  "Gangneung (Republic of Korea)",
  "Gimhae (Republic of Korea)",
  "Gongju (Republic of Korea)",
  "Gumi (Republic of Korea)",
  "Gunnae (Republic of Korea)",
  "Gwangju (Republic of Korea)",
  "Gwangmyeong (Republic of Korea)",
  "Gyeongju (Republic of Korea)",
  "Gyeongsan-si (Republic of Korea)",
  "Hadong (Republic of Korea)",
  "Haesan (Republic of Korea)",
  "Haeundae (Republic of Korea)",
  "Hanam (Republic of Korea)",
  "Hansol (Republic of Korea)",
  "Hyangyang (Republic of Korea)",
  "Hyoja-dong (Republic of Korea)",
  "Icheon-si (Republic of Korea)",
  "Iksan (Republic of Korea)",
  "Ilsan-dong (Republic of Korea)",
  "Incheon (Republic of Korea)",
  "Janghowon (Republic of Korea)",
  "Jecheon (Republic of Korea)",
  "Jeju-si (Republic of Korea)",
  "Jeonju (Republic of Korea)",
  "Jinju (Republic of Korea)",
  "Ka-ri (Republic of Korea)",
  "Kimso (Republic of Korea)",
  "Kisa (Republic of Korea)",
  "Koyang-dong (Republic of Korea)",
  "Kwanghui-dong (Republic of Korea)",
  "Mapo-dong (Republic of Korea)",
  "Miryang (Republic of Korea)",
  "Moppo (Republic of Korea)",
  "Nae-ri (Republic of Korea)",
  "Naju (Republic of Korea)",
  "Namhyang-dong (Republic of Korea)",
  "Namyang (Republic of Korea)",
  "Namyangju (Republic of Korea)",
  "Nonsan (Republic of Korea)",
  "Okpo-dong (Republic of Korea)",
  "Osan (Republic of Korea)",
  "Paju (Republic of Korea)",
  "Pohang (Republic of Korea)",
  "Pon-dong (Republic of Korea)",
  "Pyong-gol (Republic of Korea)",
  "Samjung-ni (Republic of Korea)",
  "Samsung (Republic of Korea)",
  "Sangdo-dong (Republic of Korea)",
  "Sasang (Republic of Korea)",
  "Se-ri (Republic of Korea)",
  "Seocho (Republic of Korea)",
  "Seong-dong (Republic of Korea)",
  "Seongnam-si (Republic of Korea)",
  "Seosan City (Republic of Korea)",
  "Seoul (Republic of Korea)",
  "Songam (Republic of Korea)",
  "Songang (Republic of Korea)",
  "Songjeong (Republic of Korea)",
  "Songnim (Republic of Korea)",
  "Suncheon (Republic of Korea)",
  "Suwon-si (Republic of Korea)",
  "Taebuk (Republic of Korea)",
  "Tang-ni (Republic of Korea)",
  "Tongan (Republic of Korea)",
  "Uiwang (Republic of Korea)",
  "Ulchin (Republic of Korea)",
  "Ulsan (Republic of Korea)",
  "Unpo (Republic of Korea)",
  "Wonju (Republic of Korea)",
  "Wonmi-gu (Republic of Korea)",
  "Yangsan (Republic of Korea)",
  "Yeoju (Republic of Korea)",
  "Yeosu (Republic of Korea)",
  "Yongin (Republic of Korea)",
  "Yongsan-dong (Republic of Korea)",
  "Republic of Lithuania",
  "Alytus (Republic of Lithuania)",
  "Anciskiai (Republic of Lithuania)",
  "Antakalnis (Republic of Lithuania)",
  "Garliava (Republic of Lithuania)",
  "Ignalina (Republic of Lithuania)",
  "Jonava (Republic of Lithuania)",
  "Jurbarkas (Republic of Lithuania)",
  "Juskonys (Republic of Lithuania)",
  "Kaunas (Republic of Lithuania)",
  "Kretinga (Republic of Lithuania)",
  "Mastaiciai (Republic of Lithuania)",
  "Palanga (Republic of Lithuania)",
  "Panevezys (Republic of Lithuania)",
  "Sakiai (Republic of Lithuania)",
  "Salcininkai (Republic of Lithuania)",
  "Trakai (Republic of Lithuania)",
  "Ukmerge (Republic of Lithuania)",
  "Uzliedziai (Republic of Lithuania)",
  "Venta (Republic of Lithuania)",
  "Vievis (Republic of Lithuania)",
  "Vilniaus Apskritis (Republic of Lithuania)",
  "Vilnius (Republic of Lithuania)",
  "Visaginas (Republic of Lithuania)",
  "Republic of Moldova",
  "Anenii Noi (Republic of Moldova)",
  "Briceni (Republic of Moldova)",
  "Cahul (Republic of Moldova)",
  "Calarasi (Republic of Moldova)",
  "Ciorescu (Republic of Moldova)",
  "Cojusna (Republic of Moldova)",
  "Comrat (Republic of Moldova)",
  "Drochia (Republic of Moldova)",
  "Durlesti (Republic of Moldova)",
  "Ialoveni (Republic of Moldova)",
  "Magdacesti (Republic of Moldova)",
  "Nisporeni (Republic of Moldova)",
  "Orhei (Republic of Moldova)",
  "Soroca (Republic of Moldova)",
  "Straseni (Republic of Moldova)",
  "Tighina (Republic of Moldova)",
  "Tiraspol (Republic of Moldova)",
  "Voinescu (Republic of Moldova)",
  "Vulcanesti (Republic of Moldova)",
  "Romania",
  "Adjud (Romania)",
  "Afumati (Romania)",
  "Agnita (Romania)",
  "Aiud (Romania)",
  "Alba (Romania)",
  "Alba Iulia (Romania)",
  "Albesti-Paleologu (Romania)",
  "Alesd (Romania)",
  "Alexandria (Romania)",
  "Alunu (Romania)",
  "Apahida (Romania)",
  "Apata (Romania)",
  "Arad (Romania)",
  "Avrig (Romania)",
  "Baba Novac (Romania)",
  "Baia Mare (Romania)",
  "Baia Sprie (Romania)",
  "Baicoi (Romania)",
  "Baile Olanesti (Romania)",
  "Baiut (Romania)",
  "Balomiru de Camp (Romania)",
  "Balotesti (Romania)",
  "Banesti (Romania)",
  "Baraolt (Romania)",
  "Barbuletu (Romania)",
  "Bascov (Romania)",
  "Becicherecu Mic (Romania)",
  "Berceni (Romania)",
  "Berzasca (Romania)",
  "Bethausen (Romania)",
  "Bic (Romania)",
  "Bilciuresti (Romania)",
  "Birda (Romania)",
  "Blaj (Romania)",
  "Bobolia (Romania)",
  "Bocsa (Romania)",
  "Bogdanita (Romania)",
  "Bolintin Deal (Romania)",
  "Borca (Romania)",
  "Borsa (Romania)",
  "Bozieni (Romania)",
  "Bragadiru (Romania)",
  "Branistea (Romania)",
  "Brazii de Sus (Romania)",
  "Breaza (Romania)",
  "Bucecea (Romania)",
  "Bucharest (Romania)",
  "Bucsani (Romania)",
  "Bucu (Romania)",
  "Bucurestii Noi (Romania)",
  "Buftea (Romania)",
  "Buhusi (Romania)",
  "Bujor (Romania)",
  "Bumbesti-Jiu (Romania)",
  "Busteni (Romania)",
  "Buzias (Romania)",
  "Calafat (Romania)",
  "Calan (Romania)",
  "Calarasi (Romania)",
  "Calinesti (Romania)",
  "Campeni (Romania)",
  "Campia (Romania)",
  "Campia Turzii (Romania)",
  "Campina (Romania)",
  "Campulung Moldovenesc (Romania)",
  "Campulung Muscel (Romania)",
  "Cara (Romania)",
  "Caracal (Romania)",
  "Caransebes (Romania)",
  "Carbunesti (Romania)",
  "Carei (Romania)",
  "Catcau (Romania)",
  "Catunele (Romania)",
  "Cernisoara (Romania)",
  "Cetatea de Balta (Romania)",
  "Cetatuia (Romania)",
  "Chiajna (Romania)",
  "Chiselet (Romania)",
  "Chisineu-Cris (Romania)",
  "Chisoda (Romania)",
  "Chitila (Romania)",
  "Cisnadie (Romania)",
  "Ciumani (Romania)",
  "Cluj-Napoca (Romania)",
  "Cobadin (Romania)",
  "Codlea (Romania)",
  "Cojasca (Romania)",
  "Comana (Romania)",
  "Comanesti (Romania)",
  "Corabia (Romania)",
  "Corbeanca (Romania)",
  "Corbita (Romania)",
  "Corlateni (Romania)",
  "Cosbuc (Romania)",
  "Costesti (Romania)",
  "Cotnari (Romania)",
  "Covasna (Romania)",
  "Craiova (Romania)",
  "Crangasi (Romania)",
  "Crasna (Romania)",
  "Cris (Romania)",
  "Cristian (Romania)",
  "Cristuru Secuiesc (Romania)",
  "Cugir (Romania)",
  "Curtici (Romania)",
  "Cuza Voda (Romania)",
  "Daia Romana (Romania)",
  "Decebal (Romania)",
  "Dej (Romania)",
  "Deta (Romania)",
  "Deva (Romania)",
  "Dimitrie Cantemir (Romania)",
  "Ditrau (Romania)",
  "Doamna (Romania)",
  "Dobroesti (Romania)",
  "Domnesti (Romania)",
  "Dor Marunt (Romania)",
  "Dorohoi (Romania)",
  "Dragotesti (Romania)",
  "Dridu (Romania)",
  "Dudestii Vechi (Romania)",
  "Dumbravita (Romania)",
  "Eforie (Romania)",
  "Fabrica (Romania)",
  "Faget (Romania)",
  "Fagetu (Romania)",
  "Falcau (Romania)",
  "Falticeni (Romania)",
  "Fetesti (Romania)",
  "Filias (Romania)",
  "Filiasi (Romania)",
  "Filipestii de Padure (Romania)",
  "Floresti (Romania)",
  "Fogarasch (Romania)",
  "Fundulea (Romania)",
  "Gaesti (Romania)",
  "Garla-Mare (Romania)",
  "Gataia (Romania)",
  "Geoagiu (Romania)",
  "Gheorgheni (Romania)",
  "Gherea (Romania)",
  "Gherla (Romania)",
  "Giarmata (Romania)",
  "Gilau (Romania)",
  "Giroc (Romania)",
  "Giulesti (Romania)",
  "Giurgiu (Romania)",
  "Glina (Romania)",
  "Gramesti (Romania)",
  "Grojdibodu (Romania)",
  "Gura Humorului (Romania)",
  "Hangulesti (Romania)",
  "Harsova (Romania)",
  "Hateg (Romania)",
  "Horia (Romania)",
  "Huedin (Romania)",
  "Humulesti (Romania)",
  "Hunedoara (Romania)",
  "Husi (Romania)",
  "Iadara (Romania)",
  "Iernut (Romania)",
  "Ion Ghica (Romania)",
  "Iorga (Romania)",
  "Izvoru Crisului (Romania)",
  "Jibou (Romania)",
  "Jilava (Romania)",
  "Lacu (Romania)",
  "Lacu Sinaia (Romania)",
  "Lancram (Romania)",
  "Lazuri (Romania)",
  "Lehliu-Gara (Romania)",
  "Lenauheim (Romania)",
  "Livezi (Romania)",
  "Ludesti (Romania)",
  "Lugoj (Romania)",
  "Lunguletu (Romania)",
  "Macin (Romania)",
  "Madaras (Romania)",
  "Magura (Romania)",
  "Magurele (Romania)",
  "Mandruloc (Romania)",
  "Mangalia (Romania)",
  "Manta (Romania)",
  "Marasheshty (Romania)",
  "Marasti (Romania)",
  "Marginea (Romania)",
  "Masloc (Romania)",
  "Matasari (Romania)",
  "Matei (Romania)",
  "Medgidia (Romania)",
  "Miercurea Sibiului (Romania)",
  "Miercurea-Ciuc (Romania)",
  "Mihai Bravu (Romania)",
  "Mihail Kogalniceanu (Romania)",
  "Mioveni (Romania)",
  "Mircea (Romania)",
  "Mizil (Romania)",
  "Moara Vlasiei (Romania)",
  "Mocira (Romania)",
  "Mogosoaia (Romania)",
  "Moinesti (Romania)",
  "Moisei (Romania)",
  "Moldova Noua (Romania)",
  "Moreni (Romania)",
  "Moroda (Romania)",
  "Motru (Romania)",
  "Murfatlar (Romania)",
  "Musenita (Romania)",
  "Nana (Romania)",
  "Nasaud (Romania)",
  "Navodari (Romania)",
  "Negresti (Romania)",
  "Negru Voda (Romania)",
  "Nenciulesti (Romania)",
  "Nicolae Balcescu (Romania)",
  "Nucsoara (Romania)",
  "Obreja (Romania)",
  "Ocna Mures (Romania)",
  "Ocnita (Romania)",
  "Odobesti (Romania)",
  "Odorheiu Secuiesc (Romania)",
  "Oltenita (Romania)",
  "Oradea (Romania)",
  "Oravita (Romania)",
  "Orsova (Romania)",
  "Otelu Rosu (Romania)",
  "Otopeni (Romania)",
  "Padureni (Romania)",
  "Panciu (Romania)",
  "Panduri (Romania)",
  "Pantelimon (Romania)",
  "Pascani (Romania)",
  "Patarlagele (Romania)",
  "Pecica (Romania)",
  "Petresti (Romania)",
  "Plopeni (Romania)",
  "Podu Dambovitei (Romania)",
  "Poiana Ilvei (Romania)",
  "Popesti (Romania)",
  "Popesti-Leordeni (Romania)",
  "Potlogi (Romania)",
  "Prejmer (Romania)",
  "Prim Decembrie (Romania)",
  "Prod (Romania)",
  "Pucheni (Romania)",
  "Rabagani (Romania)",
  "Racari (Romania)",
  "Racoasa (Romania)",
  "Racovita (Romania)",
  "Radauti (Romania)",
  "Radovanu (Romania)",
  "Ramnicu Sarat (Romania)",
  "Reghin (Romania)",
  "Roata de Jos (Romania)",
  "Rohia (Romania)",
  "Roman (Romania)",
  "Rosia (Romania)",
  "Rosiori de Vede (Romania)",
  "Sacele (Romania)",
  "Salatrucel (Romania)",
  "Salcea (Romania)",
  "Salcioara (Romania)",
  "Salonta (Romania)",
  "Sancraieni (Romania)",
  "Sangeorge (Romania)",
  "Sangeorz-Bai (Romania)",
  "Santimbru (Romania)",
  "Satu Mare (Romania)",
  "Saveni (Romania)",
  "Schela (Romania)",
  "Sebes (Romania)",
  "Seini (Romania)",
  "Sfantu Gheorghe (Romania)",
  "Sfantu-Gheorghe (Romania)",
  "Sibiu (Romania)",
  "Siculeni (Romania)",
  "Sighisoara (Romania)",
  "Simeria (Romania)",
  "Slatina (Romania)",
  "Slobozia (Romania)",
  "Soimus (Romania)",
  "Somcuta Mare (Romania)",
  "Sovata (Romania)",
  "Spiru Haret (Romania)",
  "Stalpeni (Romania)",
  "Stanilesti (Romania)",
  "Stefanesti (Romania)",
  "Stefanestii de Jos (Romania)",
  "Stroesti (Romania)",
  "Suceava (Romania)",
  "Susenii Bargaului (Romania)",
  "Sutesti (Romania)",
  "Talisoara (Romania)",
  "Tandarei (Romania)",
  "Targu Gangulesti (Romania)",
  "Targu Neamt (Romania)",
  "Tartasesti (Romania)",
  "Tatarani (Romania)",
  "Tatarusi (Romania)",
  "Tecuci (Romania)",
  "Teiu (Romania)",
  "Teius (Romania)",
  "Telega (Romania)",
  "Tibeni (Romania)",
  "Tileagd (Romania)",
  "Timus (Romania)",
  "Tina (Romania)",
  "Tipari (Romania)",
  "Titesti (Romania)",
  "Titu (Romania)",
  "Tomesti (Romania)",
  "Topoloveni (Romania)",
  "Traian (Romania)",
  "Trestiana (Romania)",
  "Tulcea (Romania)",
  "Tulnici (Romania)",
  "Tunari (Romania)",
  "Turda (Romania)",
  "Turnu Magurele (Romania)",
  "Turnu Ruieni (Romania)",
  "Tuzla (Romania)",
  "Uioara de Jos (Romania)",
  "Ungheni (Romania)",
  "Unirea (Romania)",
  "Urlati (Romania)",
  "Urziceni (Romania)",
  "Utvin (Romania)",
  "Vacaresti (Romania)",
  "Valcea (Romania)",
  "Valea Lupului (Romania)",
  "Valea Seaca (Romania)",
  "Valenii de Munte (Romania)",
  "Vama (Romania)",
  "Vaslui (Romania)",
  "Vatra Dornei (Romania)",
  "Vedea (Romania)",
  "Vetresti-Herastrau (Romania)",
  "Victoria (Romania)",
  "Videle (Romania)",
  "Vidin (Romania)",
  "Vidra (Romania)",
  "Viseu de Jos (Romania)",
  "Viseu de Sus (Romania)",
  "Voinesti (Romania)",
  "Voiteg (Romania)",
  "Voluntari (Romania)",
  "Voronet (Romania)",
  "Zetea (Romania)",
  "Zimnicea (Romania)",
  "Russia",
  "Abakan (Russia)",
  "Abinsk (Russia)",
  "Achinsk (Russia)",
  "Adygeysk (Russia)",
  "Agapovka (Russia)",
  "Agidel (Russia)",
  "Akhtubinsk (Russia)",
  "Aksay (Russia)",
  "Aksenovo (Russia)",
  "Alapayevsk (Russia)",
  "Aldan (Russia)",
  "Aleksandrov (Russia)",
  "Aleksandrovka (Russia)",
  "Alekseyevka (Russia)",
  "Aleksin (Russia)",
  "Aleksino (Russia)",
  "Aleysk (Russia)",
  "Alikovo (Russia)",
  "Altay (Russia)",
  "Altayskoye (Russia)",
  "Amur (Russia)",
  "Amursk (Russia)",
  "Anapa (Russia)",
  "Angarsk (Russia)",
  "Anuchino (Russia)",
  "Anzhero-Sudzhensk (Russia)",
  "Apatity (Russia)",
  "Apazovo (Russia)",
  "Aprelevka (Russia)",
  "Aramil (Russia)",
  "Ariadnoye (Russia)",
  "Arkhangelsk (Russia)",
  "Arkhara (Russia)",
  "Armavir (Russia)",
  "Aro (Russia)",
  "Artem (Russia)",
  "Arti (Russia)",
  "Arzamas (Russia)",
  "Asbest (Russia)",
  "Asha (Russia)",
  "Ashchibutak (Russia)",
  "Asino (Russia)",
  "Astrakhan (Russia)",
  "Atkarsk (Russia)",
  "Atlas (Russia)",
  "Avangard (Russia)",
  "Avri (Russia)",
  "Avtozavodskiy Rayon (Russia)",
  "Aykino (Russia)",
  "Azov (Russia)",
  "Bagan (Russia)",
  "Baksan (Russia)",
  "Balabanovo (Russia)",
  "Balakovo (Russia)",
  "Balashikha (Russia)",
  "Balashov (Russia)",
  "Baltiysk (Russia)",
  "Barabash (Russia)",
  "Baranchinskiy (Russia)",
  "Barda (Russia)",
  "Barnaul (Russia)",
  "Bashkortostan (Russia)",
  "Bataysk (Russia)",
  "Baykal (Russia)",
  "Baza (Russia)",
  "Begunitsy (Russia)",
  "Belaya Kalitva (Russia)",
  "Belebey (Russia)",
  "Belgorod (Russia)",
  "Belogorka (Russia)",
  "Belogorsk (Russia)",
  "Belorechensk (Russia)",
  "Beloretsk (Russia)",
  "Belovo (Russia)",
  "Belyy (Russia)",
  "Berdsk (Russia)",
  "Berezayka (Russia)",
  "Berezniki (Russia)",
  "Berezovka (Russia)",
  "Beryozovsky (Russia)",
  "Betlitsa (Russia)",
  "Beya (Russia)",
  "Bezhetsk (Russia)",
  "Bibayevo (Russia)",
  "Bilibino (Russia)",
  "Birobidzhan (Russia)",
  "Birsk (Russia)",
  "Birzha (Russia)",
  "Biysk (Russia)",
  "Blagovar (Russia)",
  "Blagoveshchensk (Russia)",
  "Bogdanovich (Russia)",
  "Bogdashino (Russia)",
  "Bogoroditsk (Russia)",
  "Bogorodsk (Russia)",
  "Bogorodskoye (Russia)",
  "Boguchany (Russia)",
  "Boksitogorsk (Russia)",
  "Bologoye (Russia)",
  "Bor (Russia)",
  "Borisoglebsk (Russia)",
  "Borkovka (Russia)",
  "Borodino (Russia)",
  "Borodynovka (Russia)",
  "Borovichi (Russia)",
  "Borovskiy (Russia)",
  "Borzya (Russia)",
  "Bovykiny (Russia)",
  "Bras (Russia)",
  "Bratsk (Russia)",
  "Brod (Russia)",
  "Bronnitsy (Russia)",
  "Bryansk (Russia)",
  "Bugry (Russia)",
  "Bulgakov (Russia)",
  "Buyantsevo (Russia)",
  "Buzuluk (Russia)",
  "Chapayevsk (Russia)",
  "Chaplygin (Russia)",
  "Chastyye (Russia)",
  "Chaykovskiy (Russia)",
  "Cheboksary (Russia)",
  "Chekhov (Russia)",
  "Chelbasskaya (Russia)",
  "Chelyabinsk (Russia)",
  "Chemashur (Russia)",
  "Cheremkhovo (Russia)",
  "Cherepanovo (Russia)",
  "Cherepovets (Russia)",
  "Cherkessk (Russia)",
  "Cherkizovo (Russia)",
  "Chernigovka (Russia)",
  "Chernogolovka (Russia)",
  "Chernogorsk (Russia)",
  "Chernushka (Russia)",
  "Chernyakhovsk (Russia)",
  "Chesnokovka (Russia)",
  "Chik (Russia)",
  "China (Russia)",
  "Chita (Russia)",
  "Chiverevo (Russia)",
  "Chkalov (Russia)",
  "Chudovo (Russia)",
  "Chyorny Yar (Russia)",
  "Dagomys (Russia)",
  "Dalmatovo (Russia)",
  "Damkhurts (Russia)",
  "Danilov (Russia)",
  "Dedenevo (Russia)",
  "Dedovsk (Russia)",
  "Denisovo (Russia)",
  "Derbent (Russia)",
  "Desnogorsk (Russia)",
  "Detchino (Russia)",
  "Dimitrovgrad (Russia)",
  "Dinskaya (Russia)",
  "Divnogorsk (Russia)",
  "Divo (Russia)",
  "Dmitriyevka (Russia)",
  "Dmitrov (Russia)",
  "Dobryanka (Russia)",
  "Dolgoprudnyy (Russia)",
  "Domodedovo (Russia)",
  "Donetsk (Russia)",
  "Donskoy (Russia)",
  "Drezna (Russia)",
  "Druzhba (Russia)",
  "Dubenka (Russia)",
  "Dubna (Russia)",
  "Dubovka (Russia)",
  "Dubrovka (Russia)",
  "Dudinka (Russia)",
  "Dyurtyuli (Russia)",
  "Dzerzhinsk (Russia)",
  "Dzerzhinskiy (Russia)",
  "Elektrogorsk (Russia)",
  "Elektrostal (Russia)",
  "Elektrougli (Russia)",
  "Elista (Russia)",
  "Enkheluk (Russia)",
  "Fatezh (Russia)",
  "Fialka (Russia)",
  "Filatovskoye (Russia)",
  "Filial (Russia)",
  "Frolovo (Russia)",
  "Frunzenskiy (Russia)",
  "Fryazino (Russia)",
  "Furmanov (Russia)",
  "Gagarin (Russia)",
  "Gagino (Russia)",
  "Gatchina (Russia)",
  "Gaurilov (Russia)",
  "Gelendzhik (Russia)",
  "Georgiyevsk (Russia)",
  "Georgiyevskoye (Russia)",
  "Glazov (Russia)",
  "Glazunovka (Russia)",
  "Glebychevo (Russia)",
  "Gogolya (Russia)",
  "Golitsyno (Russia)",
  "Golovchino (Russia)",
  "Gorbatov (Russia)",
  "Gorin (Russia)",
  "Gorno-Altaysk (Russia)",
  "Gornozavodsk (Russia)",
  "Gorodna (Russia)",
  "Granit (Russia)",
  "Grozny (Russia)",
  "Gryazovets (Russia)",
  "Gubakha (Russia)",
  "Gubkin (Russia)",
  "Gubkinskiy (Russia)",
  "Gudermes (Russia)",
  "Gukovo (Russia)",
  "Gusev (Russia)",
  "Gusinoozyorsk (Russia)",
  "Gvardeysk (Russia)",
  "Ibresi (Russia)",
  "Ibrya (Russia)",
  "Iglino (Russia)",
  "Igra (Russia)",
  "Ilim (Russia)",
  "Ilovlya (Russia)",
  "Ilya (Russia)",
  "Insar (Russia)",
  "Inta (Russia)",
  "Irbit (Russia)",
  "Irkutsk (Russia)",
  "Isheyevka (Russia)",
  "Ishim (Russia)",
  "Ishimbay (Russia)",
  "Ishimskiy (Russia)",
  "Ishkulovo (Russia)",
  "Ishley (Russia)",
  "Iskitim (Russia)",
  "Istra (Russia)",
  "Ivangorod (Russia)",
  "Ivanovo (Russia)",
  "Izberbash (Russia)",
  "Izhevsk (Russia)",
  "Izmaylovo (Russia)",
  "Kabanovo (Russia)",
  "Kabardino (Russia)",
  "Kachkanar (Russia)",
  "Kadrovik (Russia)",
  "Kalashnikovo (Russia)",
  "Kalinina (Russia)",
  "Kaliningrad (Russia)",
  "Kalininskaya (Russia)",
  "Kalino (Russia)",
  "Kaltan (Russia)",
  "Kaluga (Russia)",
  "Kamchatka (Russia)",
  "Kamenka (Russia)",
  "Kamennogorsk (Russia)",
  "Kamennomostskiy (Russia)",
  "Kamenolomni (Russia)",
  "Kamensk (Russia)",
  "Kamensk-Shakhtinskiy (Russia)",
  "Kamyshin (Russia)",
  "Kamyshovka (Russia)",
  "Kanash (Russia)",
  "Kanavka (Russia)",
  "Kandalaksha (Russia)",
  "Kanevskaya (Russia)",
  "Kansk (Russia)",
  "Karabash (Russia)",
  "Karabulak (Russia)",
  "Karachev (Russia)",
  "Karin Perevoz (Russia)",
  "Karla Marksa (Russia)",
  "Kartaly (Russia)",
  "Kashira (Russia)",
  "Kasimov (Russia)",
  "Kasli (Russia)",
  "Kastornoye (Russia)",
  "Katav-Ivanovsk (Russia)",
  "Kavkaz (Russia)",
  "Kemerovo (Russia)",
  "Khabarovsk (Russia)",
  "Khabarskoye (Russia)",
  "Khangash-Yurt (Russia)",
  "Khanty-Mansiysk (Russia)",
  "Kharp (Russia)",
  "Khasavyurt (Russia)",
  "Khimki (Russia)",
  "Kholmistyy (Russia)",
  "Kholmsk (Russia)",
  "Khrenovoye (Russia)",
  "Kichmengskiy Gorodok (Russia)",
  "Kimovsk (Russia)",
  "Kimry (Russia)",
  "Kineshma (Russia)",
  "Kingisepp (Russia)",
  "Kirgiz-Miyaki (Russia)",
  "Kirilly (Russia)",
  "Kirishi (Russia)",
  "Kirov (Russia)",
  "Kirova (Russia)",
  "Kirovgrad (Russia)",
  "Kirovo-Chepetsk (Russia)",
  "Kirovsk (Russia)",
  "Kirovskaya (Russia)",
  "Kirsanov (Russia)",
  "Kiryabinskoye (Russia)",
  "Kirzhach (Russia)",
  "Kislovodsk (Russia)",
  "Kizlyar (Russia)",
  "Kizner (Russia)",
  "Klimovsk (Russia)",
  "Klin (Russia)",
  "Klintsy (Russia)",
  "Klyuchi (Russia)",
  "Klyuchi-Bulak (Russia)",
  "Kochkurovo (Russia)",
  "Kodinsk (Russia)",
  "Kola (Russia)",
  "Kolomino (Russia)",
  "Kolomna (Russia)",
  "Kolpashevo (Russia)",
  "Kolpino (Russia)",
  "Komarov (Russia)",
  "Komintern (Russia)",
  "Komsomolsk-on-Amur (Russia)",
  "Konakovo (Russia)",
  "Kondopoga (Russia)",
  "Konosha (Russia)",
  "Kopeysk (Russia)",
  "Koptyuga (Russia)",
  "Korkino (Russia)",
  "Korolev (Russia)",
  "Korsakov (Russia)",
  "Koryazhma (Russia)",
  "Kosteniki (Russia)",
  "Kostomuksha (Russia)",
  "Kostroma (Russia)",
  "Kotlas (Russia)",
  "Kovdor (Russia)",
  "Kovrov (Russia)",
  "Kraskovo (Russia)",
  "Krasnoarmeysk (Russia)",
  "Krasnoarmeyskaya (Russia)",
  "Krasnoarmeyskoye (Russia)",
  "Krasnodar (Russia)",
  "Krasnodarskiy (Russia)",
  "Krasnogorsk (Russia)",
  "Krasnogorskiy (Russia)",
  "Krasnogvardeyskiy (Russia)",
  "Krasnokamensk (Russia)",
  "Krasnokamsk (Russia)",
  "Krasnoobsk (Russia)",
  "Krasnoufimsk (Russia)",
  "Krasnoyarsk (Russia)",
  "Krasnoye-na-Volge (Russia)",
  "Krasnoznamensk (Russia)",
  "Krasnyy Kut (Russia)",
  "Krasnyy Sulin (Russia)",
  "Krasnyy Yar (Russia)",
  "Kristall (Russia)",
  "Kromy (Russia)",
  "Kronshtadt (Russia)",
  "Kropotkin (Russia)",
  "Krugloye (Russia)",
  "Krymsk (Russia)",
  "Kstovo (Russia)",
  "Kubinka (Russia)",
  "Kugesi (Russia)",
  "Kulikov (Russia)",
  "Kumertau (Russia)",
  "Kungur (Russia)",
  "Kupavna (Russia)",
  "Kurchatov (Russia)",
  "Kurgan (Russia)",
  "Kurganinsk (Russia)",
  "Kursk (Russia)",
  "Kurtamysh (Russia)",
  "Kusa (Russia)",
  "Kusakovka (Russia)",
  "Kushnarenkovo (Russia)",
  "Kushva (Russia)",
  "Kuybyshev (Russia)",
  "Kuznetsk (Russia)",
  "Kuznetskiy Rayon (Russia)",
  "Kuzovatovo (Russia)",
  "Kvatchi (Russia)",
  "Kyshtym (Russia)",
  "Kyzyl (Russia)",
  "Labinsk (Russia)",
  "Labytnangi (Russia)",
  "Lada (Russia)",
  "Lakinsk (Russia)",
  "Lampovo (Russia)",
  "Lazo (Russia)",
  "Lebedev (Russia)",
  "Lenina (Russia)",
  "Leningradskaya (Russia)",
  "Leningradskiy (Russia)",
  "Leninogorsk (Russia)",
  "Leninsk-Kuznetsky (Russia)",
  "Leninskiy (Russia)",
  "Leninskoye (Russia)",
  "Lenkino (Russia)",
  "Lermontov (Russia)",
  "Lesnoy (Russia)",
  "Lesnoy Gorodok (Russia)",
  "Lesodacha (Russia)",
  "Lesosibirsk (Russia)",
  "Lesozavodsk (Russia)",
  "Likino-Dulevo (Russia)",
  "Linda (Russia)",
  "Linevo (Russia)",
  "Liniya (Russia)",
  "Lipetsk (Russia)",
  "Lisikha (Russia)",
  "Liski (Russia)",
  "Livny (Russia)",
  "Lobnya (Russia)",
  "Lodeynoye Pole (Russia)",
  "Lomonosov (Russia)",
  "Losino-Petrovskiy (Russia)",
  "Luchegorsk (Russia)",
  "Lukhovitsy (Russia)",
  "Luzino (Russia)",
  "Lyantor (Russia)",
  "Lytkarino (Russia)",
  "Lyubertsy (Russia)",
  "Magadan (Russia)",
  "Magdagachi (Russia)",
  "Magnitnyy (Russia)",
  "Magnitogorsk (Russia)",
  "Makhachkala (Russia)",
  "Maksimovka (Russia)",
  "Malaya Vishera (Russia)",
  "Malino (Russia)",
  "Maloyaroslavets (Russia)",
  "Malysheva (Russia)",
  "Mamontovka (Russia)",
  "Mamontovo (Russia)",
  "Manturovo (Russia)",
  "Mariinskiy Posad (Russia)",
  "Markovo (Russia)",
  "Marks (Russia)",
  "Maslyanino (Russia)",
  "Mayakovskogo (Russia)",
  "Maykop (Russia)",
  "Maykor (Russia)",
  "Mednogorsk (Russia)",
  "Medvedevo (Russia)",
  "Medvenka (Russia)",
  "Mega (Russia)",
  "Megion (Russia)",
  "Melenki (Russia)",
  "Meleuz (Russia)",
  "Metallostroy (Russia)",
  "Metallurg (Russia)",
  "Mezhdurechensk (Russia)",
  "Mga (Russia)",
  "Miass (Russia)",
  "Miasskoye (Russia)",
  "Michurinsk (Russia)",
  "Mikhaylovskoye (Russia)",
  "Millerovo (Russia)",
  "Mineralnye Vody (Russia)",
  "Minusinsk (Russia)",
  "Mira (Russia)",
  "Moldavanskoye (Russia)",
  "Monchegorsk (Russia)",
  "Monino (Russia)",
  "Morozov (Russia)",
  "Morozovo (Russia)",
  "Morozovsk (Russia)",
  "Morshansk (Russia)",
  "Moscow (Russia)",
  "Moskovskaya (Russia)",
  "Moskovskiy (Russia)",
  "Mostovskoy (Russia)",
  "Mozdok (Russia)",
  "Mozhaysk (Russia)",
  "Mozhga (Russia)",
  "Mtsensk (Russia)",
  "Muravlenko (Russia)",
  "Murmansk (Russia)",
  "Murmashi (Russia)",
  "Murom (Russia)",
  "Myski (Russia)",
  "Mytishchi (Russia)",
  "Naberezhnyye Chelny (Russia)",
  "Nadezhdinka (Russia)",
  "Nadvoitsy (Russia)",
  "Nadym (Russia)",
  "Nakhabino (Russia)",
  "Nakhodka (Russia)",
  "Naro-Fominsk (Russia)",
  "Nartkala (Russia)",
  "Naryan-Mar (Russia)",
  "Nauka (Russia)",
  "Naumkino (Russia)",
  "Nazarovo (Russia)",
  "Nazran (Russia)",
  "Neftekamsk (Russia)",
  "Neftekumsk (Russia)",
  "Nefteyugansk (Russia)",
  "Nelazskoye (Russia)",
  "Nelidovo (Russia)",
  "Neman (Russia)",
  "Neryungri (Russia)",
  "Neva (Russia)",
  "Nevinnomyssk (Russia)",
  "Nevskiy (Russia)",
  "Neya (Russia)",
  "Nikel (Russia)",
  "Nikolayevsk (Russia)",
  "Nikolayevsk-on-Amur (Russia)",
  "Nikulin (Russia)",
  "Nizhegorodskaya (Russia)",
  "Nizhnekamsk (Russia)",
  "Nizhneudinsk (Russia)",
  "Nizhnevartovsk (Russia)",
  "Nizhneyashkino (Russia)",
  "Nizhniy Novgorod (Russia)",
  "Nizhniy Odes (Russia)",
  "Nizhniy Tagil (Russia)",
  "Noginsk (Russia)",
  "Noginskaya (Russia)",
  "Nova (Russia)",
  "Novator (Russia)",
  "Novaya Igirma (Russia)",
  "Novaya Silava (Russia)",
  "Novoaltaysk (Russia)",
  "Novobelokatay (Russia)",
  "Novocheboksarsk (Russia)",
  "Novocherkassk (Russia)",
  "Novodmitriyevka (Russia)",
  "Novodvinsk (Russia)",
  "Novokorsunskaya (Russia)",
  "Novokruchininskiy (Russia)",
  "Novokuybyshevsk (Russia)",
  "Novokuznetsk (Russia)",
  "Novomichurinsk (Russia)",
  "Novomoskovsk (Russia)",
  "Novorossiysk (Russia)",
  "Novoselovo (Russia)",
  "Novosemeykino (Russia)",
  "Novoshakhtinsk (Russia)",
  "Novoshakhtinskiy (Russia)",
  "Novosibirsk (Russia)",
  "Novosokolovogorskiy (Russia)",
  "Novotroitsk (Russia)",
  "Novovarshavka (Russia)",
  "Novovoronezh (Russia)",
  "Novoye Devyatkino (Russia)",
  "Novozavedennoye (Russia)",
  "Novozolotovskaya (Russia)",
  "Novozybkov (Russia)",
  "Novy Urengoy (Russia)",
  "Novyy (Russia)",
  "Noyabrsk (Russia)",
  "Nyagan (Russia)",
  "Nytva (Russia)",
  "Obninsk (Russia)",
  "Obyedineniye (Russia)",
  "Ocher (Russia)",
  "Odintsovo (Russia)",
  "Ogarev (Russia)",
  "Okha (Russia)",
  "Okhansk (Russia)",
  "Okulovka (Russia)",
  "Olenegorsk (Russia)",
  "Olimpiyskiy (Russia)",
  "Omchak (Russia)",
  "Omsk (Russia)",
  "Orekhovo-Zuyevo (Russia)",
  "Orenburg (Russia)",
  "Orlovskiy (Russia)",
  "Orshanka (Russia)",
  "Orsk (Russia)",
  "Oshib (Russia)",
  "Osnova (Russia)",
  "Ostankino (Russia)",
  "Ostrov (Russia)",
  "Otradnoye (Russia)",
  "Otradnyy (Russia)",
  "Ovchinnikov (Russia)",
  "Panayevsk (Russia)",
  "Pangody (Russia)",
  "Parapino (Russia)",
  "Parfino (Russia)",
  "Pargolovo (Russia)",
  "Parkovyy (Russia)",
  "Partizansk (Russia)",
  "Patrushi (Russia)",
  "Pavlovo (Russia)",
  "Pavlovsk (Russia)",
  "Pavlovskaya (Russia)",
  "Pavlovskaya Sloboda (Russia)",
  "Pavlovskiy Posad (Russia)",
  "Pechora (Russia)",
  "Pechory (Russia)",
  "Penza (Russia)",
  "Perepravnaya (Russia)",
  "Perm (Russia)",
  "Perovka (Russia)",
  "Persianovka (Russia)",
  "Pervo (Russia)",
  "Pervomayskaya (Russia)",
  "Pervouralsk (Russia)",
  "Peschanka (Russia)",
  "Pestovo (Russia)",
  "Petergof (Russia)",
  "Petropavlovka (Russia)",
  "Petropavlovsk-Kamchatsky (Russia)",
  "Petrozavodsk (Russia)",
  "Pikalyovo (Russia)",
  "Pioner (Russia)",
  "Piter (Russia)",
  "Pitkyaranta (Russia)",
  "Pivovar (Russia)",
  "Plavsk (Russia)",
  "Pleloye (Russia)",
  "Podgortsy (Russia)",
  "Podolsk (Russia)",
  "Podstepki (Russia)",
  "Pokrov (Russia)",
  "Polevskoy (Russia)",
  "Polyarnyy (Russia)",
  "Poronaysk (Russia)",
  "Posad (Russia)",
  "Poselok (Russia)",
  "Postnikova (Russia)",
  "Potok (Russia)",
  "Povarovo (Russia)",
  "Povedniki (Russia)",
  "Poztykeres (Russia)",
  "Pregradnaya (Russia)",
  "Priozersk (Russia)",
  "Prirechensk (Russia)",
  "Progress (Russia)",
  "Prokhladnyy (Russia)",
  "Protvino (Russia)",
  "Pryazha (Russia)",
  "Pskov (Russia)",
  "Pskovskoye (Russia)",
  "Pugachev (Russia)",
  "Pulkovo (Russia)",
  "Purovsk (Russia)",
  "Pushchino (Russia)",
  "Pushkin (Russia)",
  "Pushkina (Russia)",
  "Pushkino (Russia)",
  "Putilkovo (Russia)",
  "Pyatigorsk (Russia)",
  "Pychas (Russia)",
  "Pyshma (Russia)",
  "Radist (Russia)",
  "Raduga (Russia)",
  "Rakita (Russia)",
  "Ramenskoye (Russia)",
  "Rassvet (Russia)",
  "Ratmirovo (Russia)",
  "Raychikhinsk (Russia)",
  "Redkino (Russia)",
  "Reftinskiy (Russia)",
  "Reutov (Russia)",
  "Revda (Russia)",
  "Rezh (Russia)",
  "Rikasikha (Russia)",
  "Rodionovka (Russia)",
  "Rodionovo-Nesvetayskaya (Russia)",
  "Rodniki (Russia)",
  "Romanovka (Russia)",
  "Rosa (Russia)",
  "Roshchino (Russia)",
  "Rossiyskiy (Russia)",
  "Rostov (Russia)",
  "Rostov-on-Don (Russia)",
  "Rostovskaya (Russia)",
  "Rovnoye (Russia)",
  "Rozhdestvenka (Russia)",
  "Rubin (Russia)",
  "Rubtsovsk (Russia)",
  "Russa (Russia)",
  "Ruza (Russia)",
  "Ruzayevka (Russia)",
  "Ryasnoye (Russia)",
  "Ryazan (Russia)",
  "Rybinsk (Russia)",
  "Rybnoye (Russia)",
  "Rzhev (Russia)",
  "Safonovo (Russia)",
  "Saint Petersburg (Russia)",
  "Salavat (Russia)",
  "Salekhard (Russia)",
  "Salym (Russia)",
  "Samara (Russia)",
  "Samsonovka (Russia)",
  "Saransk (Russia)",
  "Sarapul (Russia)",
  "Saratov (Russia)",
  "Sarov (Russia)",
  "Sartykov (Russia)",
  "Sary (Russia)",
  "Satis (Russia)",
  "Satka (Russia)",
  "Sayanogorsk (Russia)",
  "Sayansk (Russia)",
  "Sebezh (Russia)",
  "Segezha (Russia)",
  "Selo (Russia)",
  "Selyatino (Russia)",
  "Semibratovo (Russia)",
  "Semyonov (Russia)",
  "Serafimovskiy (Russia)",
  "Serdobsk (Russia)",
  "Sergeyevka (Russia)",
  "Sergiyev Posad (Russia)",
  "Sergiyevskoye (Russia)",
  "Serov (Russia)",
  "Serpukhov (Russia)",
  "Sertolovo (Russia)",
  "Seryshevo (Russia)",
  "Sestroretsk (Russia)",
  "Severo (Russia)",
  "Severo-Zapad (Russia)",
  "Severodvinsk (Russia)",
  "Severomorsk (Russia)",
  "Seversk (Russia)",
  "Severskaya (Russia)",
  "Sevsk (Russia)",
  "Shadrinsk (Russia)",
  "Shakhty (Russia)",
  "Shalinskoye (Russia)",
  "Shalushka (Russia)",
  "Sharapova Okhota (Russia)",
  "Sharnutovskiy (Russia)",
  "Shatura (Russia)",
  "Shaturtorf (Russia)",
  "Shchekino (Russia)",
  "Shchelkovo (Russia)",
  "Shebalino (Russia)",
  "Shebekino (Russia)",
  "Sheksna (Russia)",
  "Shelekhov (Russia)",
  "Sheregesh (Russia)",
  "Shoshma (Russia)",
  "Shumerlya (Russia)",
  "Sibay (Russia)",
  "Sistema (Russia)",
  "Skala (Russia)",
  "Skat (Russia)",
  "Skovorodino (Russia)",
  "Slantsy (Russia)",
  "Slavgorod (Russia)",
  "Slavyanka (Russia)",
  "Slavyansk-na-Kubani (Russia)",
  "Sloboda (Russia)",
  "Slyudyanka (Russia)",
  "Smolensk (Russia)",
  "Smolenskaya (Russia)",
  "Snegiri (Russia)",
  "Snezhinsk (Russia)",
  "Snezhinskiy (Russia)",
  "Snezhnogorsk (Russia)",
  "Sobinka (Russia)",
  "Sochi (Russia)",
  "Sodyshka (Russia)",
  "Sofrino (Russia)",
  "Sokol (Russia)",
  "Sokolovo (Russia)",
  "Soldatskoye (Russia)",
  "Solikamsk (Russia)",
  "Solnechnogorsk (Russia)",
  "Sorochinsk (Russia)",
  "Sortavala (Russia)",
  "Sosnogorsk (Russia)",
  "Sosnovo (Russia)",
  "Sosnovoborsk (Russia)",
  "Sosnovyy Bor (Russia)",
  "Sosny (Russia)",
  "Sovetsk (Russia)",
  "Sovetskiy (Russia)",
  "Spas-Demensk (Russia)",
  "Spassk (Russia)",
  "Sputnik (Russia)",
  "Sredneuralsk (Russia)",
  "Srostki (Russia)",
  "Staraya Kupavna (Russia)",
  "Staraya Russa (Russia)",
  "Starobachaty (Russia)",
  "Starodub (Russia)",
  "Staronizhestebliyevskaya (Russia)",
  "Staropesterevo (Russia)",
  "Starovelichkovskaya (Russia)",
  "Start (Russia)",
  "Stary Oskol (Russia)",
  "Stavropol (Russia)",
  "Sterlitamak (Russia)",
  "Strezhevoy (Russia)",
  "Stroiteley (Russia)",
  "Studenets (Russia)",
  "Stupino (Russia)",
  "Sukhanovo (Russia)",
  "Surgut (Russia)",
  "Surkhakhi (Russia)",
  "Sursk (Russia)",
  "Sverdlova (Russia)",
  "Sverdlovskaya (Russia)",
  "Sverdlovskiy (Russia)",
  "Svetlaya (Russia)",
  "Svetlogorsk (Russia)",
  "Svetlograd (Russia)",
  "Svetlyy (Russia)",
  "Svetogorsk (Russia)",
  "Sviblovo (Russia)",
  "Svirsk (Russia)",
  "Svobodnaya (Russia)",
  "Svobodnyy (Russia)",
  "Syamzha (Russia)",
  "Syktyvkar (Russia)",
  "Taganrog (Russia)",
  "Tagil (Russia)",
  "Taksimo (Russia)",
  "Taldom (Russia)",
  "Tambov (Russia)",
  "Tapkhar (Russia)",
  "Tarko-Sale (Russia)",
  "Tarusa (Russia)",
  "Tatlybayevo (Russia)",
  "Taushkasy (Russia)",
  "Tayga (Russia)",
  "Tayshet (Russia)",
  "Tazovskiy (Russia)",
  "Techa (Russia)",
  "Temryuk (Russia)",
  "Teykovo (Russia)",
  "Tikhoretsk (Russia)",
  "Tikhvin (Russia)",
  "Tim (Russia)",
  "Timiryazev (Russia)",
  "Tochka (Russia)",
  "Tolstoy (Russia)",
  "Tolyatti (Russia)",
  "Tomilino (Russia)",
  "Tomsk (Russia)",
  "Topki (Russia)",
  "Torzhok (Russia)",
  "Tosno (Russia)",
  "Trekhgornyy (Russia)",
  "Troitsk (Russia)",
  "Troitsko-Pechorsk (Russia)",
  "Trubchevsk (Russia)",
  "Tsna (Russia)",
  "Tsudakhar (Russia)",
  "Tuapse (Russia)",
  "Tuchkovo (Russia)",
  "Tula (Russia)",
  "Tulun (Russia)",
  "Turinsk (Russia)",
  "Tuymazy (Russia)",
  "Tver (Russia)",
  "Tynda (Russia)",
  "Tyrma (Russia)",
  "Tyrnyauz (Russia)",
  "Tyumen (Russia)",
  "Uchaly (Russia)",
  "Udomlya (Russia)",
  "Ufa (Russia)",
  "Uglegorsk (Russia)",
  "Uglich (Russia)",
  "Ugra (Russia)",
  "Ugresha (Russia)",
  "Ukhta (Russia)",
  "Uktuz (Russia)",
  "Ulan-Ude (Russia)",
  "Ulyanovsk (Russia)",
  "Unecha (Russia)",
  "Ural (Russia)",
  "Uray (Russia)",
  "Urengoy (Russia)",
  "Urgal (Russia)",
  "Urus-Martan (Russia)",
  "Uryupinsk (Russia)",
  "Urzhum (Russia)",
  "Usinsk (Russia)",
  "Usinskoye (Russia)",
  "Uspenskoye (Russia)",
  "Ussuriysk (Russia)",
  "Uvarovo (Russia)",
  "Uyar (Russia)",
  "Uzlovaya (Russia)",
  "Vagay (Russia)",
  "Valday (Russia)",
  "Valentin (Russia)",
  "Vanino (Russia)",
  "Varvarovka (Russia)",
  "Vega (Russia)",
  "Velikiy Ustyug (Russia)",
  "Velikiye Luki (Russia)",
  "Veliky Novgorod (Russia)",
  "Verkhnekazymskiy (Russia)",
  "Verkhniy Ufaley (Russia)",
  "Verkhnyaya Pyshma (Russia)",
  "Verkhnyaya Salda (Russia)",
  "Veshki (Russia)",
  "Vichuga (Russia)",
  "Vidnoye (Russia)",
  "Vikhorevka (Russia)",
  "Vinsady (Russia)",
  "Vitebskaya (Russia)",
  "Vladikavkaz (Russia)",
  "Vladimir (Russia)",
  "Vladivostok (Russia)",
  "Vladykino (Russia)",
  "Vnukovo (Russia)",
  "Volga (Russia)",
  "Volgodonsk (Russia)",
  "Volgograd (Russia)",
  "Volgorechensk (Russia)",
  "Volkhonshchino (Russia)",
  "Volkhov (Russia)",
  "Volna (Russia)",
  "Volochek (Russia)",
  "Volodarskiy (Russia)",
  "Vologda (Russia)",
  "Volovo (Russia)",
  "Volzhsk (Russia)",
  "Volzhskiy (Russia)",
  "Vorkuta (Russia)",
  "Voronezh (Russia)",
  "Voronezhskiy (Russia)",
  "Vorovskogo (Russia)",
  "Voskresensk (Russia)",
  "Voskresenskoye (Russia)",
  "Vostochnaya (Russia)",
  "Vostochnyy (Russia)",
  "Votkinsk (Russia)",
  "Vsevolozhsk (Russia)",
  "Vurnary (Russia)",
  "Vyatka (Russia)",
  "Vyatskiye Polyany (Russia)",
  "Vyazemskiy (Russia)",
  "Vyazniki (Russia)",
  "Vyborg (Russia)",
  "Vyksa (Russia)",
  "Vysokogornyy (Russia)",
  "Vysokovsk (Russia)",
  "Vytegra (Russia)",
  "Yakhroma (Russia)",
  "Yakovlevo (Russia)",
  "Yakutsk (Russia)",
  "Yalta (Russia)",
  "Yalutorovsk (Russia)",
  "Yanaul (Russia)",
  "Yaratovo (Russia)",
  "Yaroslavl (Russia)",
  "Yashkino (Russia)",
  "Yaya (Russia)",
  "Yekaterinburg (Russia)",
  "Yekaterinoslavka (Russia)",
  "Yekaterinovka (Russia)",
  "Yelabuga (Russia)",
  "Yelanskiy (Russia)",
  "Yelets (Russia)",
  "Yelizovo (Russia)",
  "Yermolkino (Russia)",
  "Yessentuki (Russia)",
  "Yeysk (Russia)",
  "Yoshkar-Ola (Russia)",
  "Yugorsk (Russia)",
  "Yugra (Russia)",
  "Yurga (Russia)",
  "Yuzhno-Sakhalinsk (Russia)",
  "Zainsk (Russia)",
  "Zaokskiy (Russia)",
  "Zaozersk (Russia)",
  "Zaprudnya (Russia)",
  "Zaraysk (Russia)",
  "Zarechenskiy (Russia)",
  "Zarechnyy (Russia)",
  "Zarinsk (Russia)",
  "Zavodoukovsk (Russia)",
  "Zavodouspenskoye (Russia)",
  "Zelenodolsk (Russia)",
  "Zelenogorsk (Russia)",
  "Zelenograd (Russia)",
  "Zelenogradsk (Russia)",
  "Zernograd (Russia)",
  "Zeya (Russia)",
  "Zheleznodorozhnyy (Russia)",
  "Zheleznogorsk (Russia)",
  "Zheleznogorsk-Ilimskiy (Russia)",
  "Zheleznovodsk (Russia)",
  "Zhigulevsk (Russia)",
  "Zhirnovsk (Russia)",
  "Zhukovskiy (Russia)",
  "Zhukovskogo (Russia)",
  "Zhuravna (Russia)",
  "Zima (Russia)",
  "Zlatoust (Russia)",
  "Zolotarevka (Russia)",
  "Zori (Russia)",
  "Zubovo (Russia)",
  "Zvenigorod (Russia)",
  "Zvenigovo (Russia)",
  "Zvezda (Russia)",
  "Saint Lucia",
  "Anse La Raye (Saint Lucia)",
  "Castries (Saint Lucia)",
  "Choiseul (Saint Lucia)",
  "Dauphin (Saint Lucia)",
  "Gros Islet (Saint Lucia)",
  "Vieux Fort (Saint Lucia)",
  "San Marino",
  "Acquaviva (San Marino)",
  "Falciano (San Marino)",
  "Fiorentino (San Marino)",
  "San Marino (San Marino)",
  "Serravalle (San Marino)",
  "Saudi Arabia",
  "Abha (Saudi Arabia)",
  "Abqaiq (Saudi Arabia)",
  "Al Bahah (Saudi Arabia)",
  "Al Faruq (Saudi Arabia)",
  "Al Hufuf (Saudi Arabia)",
  "Al Qatif (Saudi Arabia)",
  "Al Yamamah (Saudi Arabia)",
  "At Tuwal (Saudi Arabia)",
  "Buraidah (Saudi Arabia)",
  "Dammam (Saudi Arabia)",
  "Dhahran (Saudi Arabia)",
  "Hayil (Saudi Arabia)",
  "Jazirah (Saudi Arabia)",
  "Jeddah (Saudi Arabia)",
  "Jizan (Saudi Arabia)",
  "Jubail (Saudi Arabia)",
  "Khamis Mushait (Saudi Arabia)",
  "Khobar (Saudi Arabia)",
  "Khulays (Saudi Arabia)",
  "Linah (Saudi Arabia)",
  "Madinat Yanbu` as Sina`iyah (Saudi Arabia)",
  "Mecca (Saudi Arabia)",
  "Medina (Saudi Arabia)",
  "Mina (Saudi Arabia)",
  "Najran (Saudi Arabia)",
  "Rabigh (Saudi Arabia)",
  "Rahimah (Saudi Arabia)",
  "Rahman (Saudi Arabia)",
  "Ramdah (Saudi Arabia)",
  "Ras Tanura (Saudi Arabia)",
  "Riyadh (Saudi Arabia)",
  "Sabya (Saudi Arabia)",
  "Safwa (Saudi Arabia)",
  "Sakaka (Saudi Arabia)",
  "Sambah (Saudi Arabia)",
  "Sayhat (Saudi Arabia)",
  "Tabuk (Saudi Arabia)",
  "Yanbu` al Bahr (Saudi Arabia)",
  "Senegal",
  "Boussinki (Senegal)",
  "Camberene (Senegal)",
  "Dakar (Senegal)",
  "Dodji (Senegal)",
  "Guediawaye (Senegal)",
  "Kaolack (Senegal)",
  "Kedougou (Senegal)",
  "Louga (Senegal)",
  "Madina Kokoun (Senegal)",
  "Saint-Louis (Senegal)",
  "Sama (Senegal)",
  "Tanaf (Senegal)",
  "Serbia",
  "Ada (Serbia)",
  "Aleksinac (Serbia)",
  "Apatin (Serbia)",
  "Arilje (Serbia)",
  "Avala (Serbia)",
  "Backa Topola (Serbia)",
  "Backi Jarak (Serbia)",
  "Backi Petrovac (Serbia)",
  "Backo Gradiste (Serbia)",
  "Banatsko Novo Selo (Serbia)",
  "Barajevo (Serbia)",
  "Basaid (Serbia)",
  "Batajnica (Serbia)",
  "Becej (Serbia)",
  "Belgrade (Serbia)",
  "Bocar (Serbia)",
  "Bor (Serbia)",
  "Cantavir (Serbia)",
  "Coka (Serbia)",
  "Cukarica (Serbia)",
  "Cuprija (Serbia)",
  "Debeljaca (Serbia)",
  "Despotovac (Serbia)",
  "Dolovo (Serbia)",
  "Drenovac (Serbia)",
  "Futog (Serbia)",
  "Glozan (Serbia)",
  "Golubinci (Serbia)",
  "Gornji Milanovac (Serbia)",
  "Grocka (Serbia)",
  "Ingija (Serbia)",
  "Izvor (Serbia)",
  "Jagodina (Serbia)",
  "Kacarevo (Serbia)",
  "Kanjiza (Serbia)",
  "Kovin (Serbia)",
  "Kragujevac (Serbia)",
  "Kraljevo (Serbia)",
  "Leskovac (Serbia)",
  "Loznica (Serbia)",
  "Melenci (Serbia)",
  "Nikinci (Serbia)",
  "Nova Pazova (Serbia)",
  "Novi Banovci (Serbia)",
  "Novi Becej (Serbia)",
  "Novi Belgrade (Serbia)",
  "Novi Pazar (Serbia)",
  "Novi Sad (Serbia)",
  "Odzaci (Serbia)",
  "Palic (Serbia)",
  "Paracin (Serbia)",
  "Petrovac (Serbia)",
  "Petrovaradin (Serbia)",
  "Pirot (Serbia)",
  "Popovac (Serbia)",
  "Priboj (Serbia)",
  "Prokuplje (Serbia)",
  "Ratkovo (Serbia)",
  "Ruma (Serbia)",
  "Rumenka (Serbia)",
  "Savski Venac (Serbia)",
  "Selo Mladenovac (Serbia)",
  "Senta (Serbia)",
  "Sibac (Serbia)",
  "Simanovci (Serbia)",
  "Sirig (Serbia)",
  "Smederevo (Serbia)",
  "Sombor (Serbia)",
  "Srbobran (Serbia)",
  "Sremcica (Serbia)",
  "Sremska Kamenica (Serbia)",
  "Sremska Mitrovica (Serbia)",
  "Sremski Karlovci (Serbia)",
  "Stara Pazova (Serbia)",
  "Stari Banovci (Serbia)",
  "Subotica (Serbia)",
  "Surcin (Serbia)",
  "Svilajnac (Serbia)",
  "Svrljig (Serbia)",
  "Temerin (Serbia)",
  "Titel (Serbia)",
  "Tornjos (Serbia)",
  "Ugrinovci (Serbia)",
  "Umcari (Serbia)",
  "Umka (Serbia)",
  "Vajska (Serbia)",
  "Valjevo (Serbia)",
  "Veternik (Serbia)",
  "Vrbas (Serbia)",
  "Zajecar (Serbia)",
  "Zemun Polje (Serbia)",
  "Zlatibor (Serbia)",
  "Zrenjanin (Serbia)",
  "Singapore",
  "Ang Mo Kio New Town (Singapore)",
  "Ayer Raja New Town (Singapore)",
  "Bedok New Town (Singapore)",
  "Boon Lay (Singapore)",
  "Bukit Batok New Town (Singapore)",
  "Bukit Panjang New Town (Singapore)",
  "Bukit Timah (Singapore)",
  "Bukit Timah Estate (Singapore)",
  "Changi Village (Singapore)",
  "Choa Chu Kang New Town (Singapore)",
  "Clementi New Town (Singapore)",
  "Holland Village (Singapore)",
  "Hougang (Singapore)",
  "Jurong East New Town (Singapore)",
  "Jurong Town (Singapore)",
  "Jurong West New Town (Singapore)",
  "Kalang (Singapore)",
  "Kampong Pasir Ris (Singapore)",
  "Kembangan (Singapore)",
  "Pandan Valley (Singapore)",
  "Pasir Panjang (Singapore)",
  "Punggol (Singapore)",
  "Queenstown Estate (Singapore)",
  "Serangoon (Singapore)",
  "Simei New Town (Singapore)",
  "Singapore (Singapore)",
  "Tai Seng (Singapore)",
  "Tampines New Town (Singapore)",
  "Tanglin Halt (Singapore)",
  "Tanjong Pagar (Singapore)",
  "Toa Payoh New Town (Singapore)",
  "Woodlands New Town (Singapore)",
  "Yew Tee (Singapore)",
  "Yishun New Town (Singapore)",
  "Slovakia",
  "Bahon (Slovakia)",
  "Baka (Slovakia)",
  "Banovce nad Bebravou (Slovakia)",
  "Bardejov (Slovakia)",
  "Bela (Slovakia)",
  "Beladice (Slovakia)",
  "Bernolakovo (Slovakia)",
  "Besenov (Slovakia)",
  "Blatnica (Slovakia)",
  "Bobrov (Slovakia)",
  "Bohdanovce (Slovakia)",
  "Boleraz (Slovakia)",
  "Borovce (Slovakia)",
  "Branc (Slovakia)",
  "Bratislava (Slovakia)",
  "Brezno (Slovakia)",
  "Brezova pod Bradlom (Slovakia)",
  "Bystricka (Slovakia)",
  "Cecejovce (Slovakia)",
  "Cechynce (Slovakia)",
  "Cerova (Slovakia)",
  "Cerveny Hradok (Slovakia)",
  "Cerveny Kamen (Slovakia)",
  "Chlebnice (Slovakia)",
  "Chorvatsky Grob (Slovakia)",
  "Chtelnica (Slovakia)",
  "Cifer (Slovakia)",
  "Detva (Slovakia)",
  "Diakovce (Slovakia)",
  "Diviaky nad Nitricou (Slovakia)",
  "Dlha nad Oravou (Slovakia)",
  "Dlhe Klcovo (Slovakia)",
  "Dlhe nad Cirochou (Slovakia)",
  "Dolna Marikova (Slovakia)",
  "Dolna Streda (Slovakia)",
  "Dolne Oresany (Slovakia)",
  "Dolne Vestenice (Slovakia)",
  "Dolny Hricov (Slovakia)",
  "Dolny Stal (Slovakia)",
  "Druzstevna pri Hornade (Slovakia)",
  "Eliasovce (Slovakia)",
  "Fintice (Slovakia)",
  "Galanta (Slovakia)",
  "Gbely (Slovakia)",
  "Gelnica (Slovakia)",
  "Giraltovce (Slovakia)",
  "Golianovo (Slovakia)",
  "Handlova (Slovakia)",
  "Hencovce (Slovakia)",
  "Hlinik (Slovakia)",
  "Hlinik nad Hronom (Slovakia)",
  "Hlohovec (Slovakia)",
  "Holic (Slovakia)",
  "Holice (Slovakia)",
  "Horna Lehota (Slovakia)",
  "Horna Stubna (Slovakia)",
  "Horne Oresany (Slovakia)",
  "Horne Saliby (Slovakia)",
  "Horny Smokovec (Slovakia)",
  "Hradiste (Slovakia)",
  "Hrinova (Slovakia)",
  "Hrochot,Slovakia (Slovakia)",
  "Hruby Sur (Slovakia)",
  "Ilava (Slovakia)",
  "Istebne (Slovakia)",
  "Ivanka pri Dunaji (Slovakia)",
  "Jablonove (Slovakia)",
  "Jacovce (Slovakia)",
  "Jasenica (Slovakia)",
  "Jaslovske Bohunice (Slovakia)",
  "Jelenec (Slovakia)",
  "Jelka (Slovakia)",
  "Kajal (Slovakia)",
  "Kanianka (Slovakia)",
  "Klatova Nova Ves (Slovakia)",
  "Klokoc (Slovakia)",
  "Klokocov (Slovakia)",
  "Kolarovo (Slovakia)",
  "Komjatice (Slovakia)",
  "Kopcany (Slovakia)",
  "Kosicka Nova Ves (Slovakia)",
  "Kremnica (Slovakia)",
  "Krizovany nad Dudvahom (Slovakia)",
  "Krompachy (Slovakia)",
  "Krupina (Slovakia)",
  "Krusovce (Slovakia)",
  "Kukova (Slovakia)",
  "Kvetoslavov (Slovakia)",
  "Kysucky Lieskovec (Slovakia)",
  "Ladce (Slovakia)",
  "Lednicke Rovne (Slovakia)",
  "Lehota (Slovakia)",
  "Lemesany (Slovakia)",
  "Levice (Slovakia)",
  "Likavka (Slovakia)",
  "Liptovske Revuce (Slovakia)",
  "Liptovsky Hradok (Slovakia)",
  "Lozorno (Slovakia)",
  "Lubina (Slovakia)",
  "Ludanice (Slovakia)",
  "Lukacovce (Slovakia)",
  "Madunice (Slovakia)",
  "Malacky (Slovakia)",
  "Maly Lapas (Slovakia)",
  "Marianka (Slovakia)",
  "Martin (Slovakia)",
  "Mesto (Slovakia)",
  "Michalovce (Slovakia)",
  "Miloslavov (Slovakia)",
  "Mojmirovce (Slovakia)",
  "Moldava nad Bodvou (Slovakia)",
  "Moravany nad Vahom (Slovakia)",
  "Mutne (Slovakia)",
  "Myjava (Slovakia)",
  "Nana (Slovakia)",
  "Nededza (Slovakia)",
  "Nitra (Slovakia)",
  "Nitrianske Rudno (Slovakia)",
  "Nitrianske Sucany (Slovakia)",
  "Nizna (Slovakia)",
  "Nizna Kamenica (Slovakia)",
  "Nova Bana (Slovakia)",
  "Novaky (Slovakia)",
  "Nove Sady (Slovakia)",
  "Novy Salas (Slovakia)",
  "Nyrovce (Slovakia)",
  "Okoc (Slovakia)",
  "Olesna (Slovakia)",
  "Opatovce nad Nitrou (Slovakia)",
  "Opoj (Slovakia)",
  "Oravske Vesele (Slovakia)",
  "Pastuchov (Slovakia)",
  "Pata (Slovakia)",
  "Pernek (Slovakia)",
  "Pezinok (Slovakia)",
  "Plave Vozokany (Slovakia)",
  "Plavecke Podhradie (Slovakia)",
  "Pliesovce (Slovakia)",
  "Pobedim (Slovakia)",
  "Podbrezova (Slovakia)",
  "Podolie (Slovakia)",
  "Polomka (Slovakia)",
  "Poprad (Slovakia)",
  "Potvorice (Slovakia)",
  "Praha (Slovakia)",
  "Prakovce (Slovakia)",
  "Praznovce (Slovakia)",
  "Prievidza (Slovakia)",
  "Proc (Slovakia)",
  "Reca (Slovakia)",
  "Rosina (Slovakia)",
  "Rovinka (Slovakia)",
  "Ruzindol (Slovakia)",
  "Sabinov (Slovakia)",
  "Samorin (Slovakia)",
  "Sarisske Bohdanovce (Slovakia)",
  "Secovce (Slovakia)",
  "Secovska Polianka (Slovakia)",
  "Selice (Slovakia)",
  "Selpice (Slovakia)",
  "Sena (Slovakia)",
  "Senec (Slovakia)",
  "Senica (Slovakia)",
  "Sipkove (Slovakia)",
  "Skalica (Slovakia)",
  "Sladkovicovo (Slovakia)",
  "Slovenska Ves (Slovakia)",
  "Slovensky Grob (Slovakia)",
  "Smizany (Slovakia)",
  "Snina (Slovakia)",
  "Sobrance (Slovakia)",
  "Solosnica (Slovakia)",
  "Spacince (Slovakia)",
  "Spissky Hrusov (Slovakia)",
  "Spissky Stiavnik (Slovakia)",
  "Spissky Stvrtok (Slovakia)",
  "Stara Lesna (Slovakia)",
  "Stara Tura (Slovakia)",
  "Stary Smokovec (Slovakia)",
  "Staskov (Slovakia)",
  "Stefanov (Slovakia)",
  "Stropkov (Slovakia)",
  "Studienka (Slovakia)",
  "Stupava (Slovakia)",
  "Sucany (Slovakia)",
  "Sucha nad Parnou (Slovakia)",
  "Svaty Kriz (Slovakia)",
  "Svaty Peter (Slovakia)",
  "Svit (Slovakia)",
  "Svodin (Slovakia)",
  "Tajov (Slovakia)",
  "Terchova (Slovakia)",
  "Tesedikovo (Slovakia)",
  "Tlmace (Slovakia)",
  "Tomasov (Slovakia)",
  "Torysky (Slovakia)",
  "Trnava (Slovakia)",
  "Trnovec nad Vahom (Slovakia)",
  "Trstena (Slovakia)",
  "Turany (Slovakia)",
  "Tvrdosin (Slovakia)",
  "Tvrdosovce (Slovakia)",
  "Udavske (Slovakia)",
  "Urmince (Slovakia)",
  "Vahovce (Slovakia)",
  "Varhanovce (Slovakia)",
  "Varin (Slovakia)",
  "Vecelkov (Slovakia)",
  "Velcice (Slovakia)",
  "Velicna (Slovakia)",
  "Velky Meder (Slovakia)",
  "Vinne (Slovakia)",
  "Vitanova (Slovakia)",
  "Vlckovce (Slovakia)",
  "Vlkanova (Slovakia)",
  "Vojcice (Slovakia)",
  "Vrable (Slovakia)",
  "Vrutky (Slovakia)",
  "Vysne Raslavice (Slovakia)",
  "Vysny Orlik (Slovakia)",
  "Zakamenne (Slovakia)",
  "Zavod (Slovakia)",
  "Zbince (Slovakia)",
  "Zelenec (Slovakia)",
  "Zeliezovce (Slovakia)",
  "Ziar nad Hronom (Slovakia)",
  "Zikava (Slovakia)",
  "Zlate (Slovakia)",
  "Zuberec (Slovakia)",
  "Zvolen (Slovakia)",
  "Slovenia",
  "Ankaran (Slovenia)",
  "Begunje na Gorenjskem (Slovenia)",
  "Beltinci (Slovenia)",
  "Besnica (Slovenia)",
  "Bevke (Slovenia)",
  "Bistrica pri Rusah (Slovenia)",
  "Bled (Slovenia)",
  "Bohinjska Bela (Slovenia)",
  "Borovnica (Slovenia)",
  "Breginj (Slovenia)",
  "Brestanica (Slovenia)",
  "Breznica (Slovenia)",
  "Cemsenik (Slovenia)",
  "Cerklje ob Krki (Slovenia)",
  "Cerknica (Slovenia)",
  "Cersak (Slovenia)",
  "Cirkovce (Slovenia)",
  "Crensovci (Slovenia)",
  "Dekani (Slovenia)",
  "Dob (Slovenia)",
  "Dobravlje (Slovenia)",
  "Dobrova (Slovenia)",
  "Dobrunje (Slovenia)",
  "Dol pri Ljubljani (Slovenia)",
  "Dolenjske Toplice (Slovenia)",
  "Dornava (Slovenia)",
  "Dravograd (Slovenia)",
  "Globoko (Slovenia)",
  "Gmajnica (Slovenia)",
  "Gorenja Vas (Slovenia)",
  "Gorisnica (Slovenia)",
  "Gornja Radgona (Slovenia)",
  "Grize (Slovenia)",
  "Grosuplje (Slovenia)",
  "Horjul (Slovenia)",
  "Hotedrsica (Slovenia)",
  "Hrastnik (Slovenia)",
  "Hrusica (Slovenia)",
  "Idrija (Slovenia)",
  "Ig (Slovenia)",
  "Ilirska Bistrica (Slovenia)",
  "Izola (Slovenia)",
  "Jesenice (Slovenia)",
  "Kamnik (Slovenia)",
  "Kidricevo (Slovenia)",
  "Knezak (Slovenia)",
  "Kobarid (Slovenia)",
  "Kojsko (Slovenia)",
  "Komenda (Slovenia)",
  "Koper (Slovenia)",
  "Krajna (Slovenia)",
  "Kranj (Slovenia)",
  "Kranjska Gora (Slovenia)",
  "Lenart v Slov. Goricah (Slovenia)",
  "Lendava (Slovenia)",
  "Lesce (Slovenia)",
  "Limbus (Slovenia)",
  "Litija (Slovenia)",
  "Ljubecna (Slovenia)",
  "Ljubljana (Slovenia)",
  "Log pri Brezovici (Slovenia)",
  "Logatec (Slovenia)",
  "Lokev (Slovenia)",
  "Lovrenc na Dravskem Polju (Slovenia)",
  "Lovrenc na Pohorju (Slovenia)",
  "Maribor (Slovenia)",
  "Markovci (Slovenia)",
  "Medvode (Slovenia)",
  "Menges (Slovenia)",
  "Mezica (Slovenia)",
  "Miklavz na Dravskem Polju (Slovenia)",
  "Miren (Slovenia)",
  "Mirna (Slovenia)",
  "Mojstrana (Slovenia)",
  "Moravce (Slovenia)",
  "Mozirje (Slovenia)",
  "Murska Sobota (Slovenia)",
  "Naklo (Slovenia)",
  "Notranje Gorice (Slovenia)",
  "Nova Cerkev (Slovenia)",
  "Nova Gorica (Slovenia)",
  "Novo Mesto (Slovenia)",
  "Pernica (Slovenia)",
  "Pesnica pri Mariboru (Slovenia)",
  "Petrovce (Slovenia)",
  "Piran (Slovenia)",
  "Pobegi (Slovenia)",
  "Podbocje (Slovenia)",
  "Polhov Gradec (Slovenia)",
  "Poljcane (Slovenia)",
  "Polzela (Slovenia)",
  "Postojna (Slovenia)",
  "Prebold (Slovenia)",
  "Preserje (Slovenia)",
  "Prestranek (Slovenia)",
  "Prevalje (Slovenia)",
  "Ptuj (Slovenia)",
  "Puconci (Slovenia)",
  "Radlje ob Dravi (Slovenia)",
  "Radomlje (Slovenia)",
  "Radovljica (Slovenia)",
  "Rakek (Slovenia)",
  "Ravne (Slovenia)",
  "Ravne na Koroskem (Slovenia)",
  "Rocinj (Slovenia)",
  "Secovlje (Slovenia)",
  "Selnica ob Dravi (Slovenia)",
  "Sempeter pri Gorici (Slovenia)",
  "Sencur (Slovenia)",
  "Sentjanz (Slovenia)",
  "Sentvid pri Sticni (Slovenia)",
  "Sevnica (Slovenia)",
  "Skofljica (Slovenia)",
  "Slovenj Gradec (Slovenia)",
  "Slovenska Bistrica (Slovenia)",
  "Slovenske Konjice (Slovenia)",
  "Smarje (Slovenia)",
  "Smarje pri Jelsah (Slovenia)",
  "Smarje-Sap (Slovenia)",
  "Smartno ob Paki (Slovenia)",
  "Smlednik (Slovenia)",
  "Sostanj (Slovenia)",
  "Spodnje Hoce (Slovenia)",
  "Spodnji Brnik (Slovenia)",
  "Spodnji Duplek (Slovenia)",
  "Spodnji Ivanjci (Slovenia)",
  "Sredisce ob Dravi (Slovenia)",
  "Stahovica (Slovenia)",
  "Stara Cerkev (Slovenia)",
  "Stari Trg pri Lozu (Slovenia)",
  "Starse (Slovenia)",
  "Tisina (Slovenia)",
  "Tolmin (Slovenia)",
  "Trbovlje (Slovenia)",
  "Trzic (Slovenia)",
  "Trzin (Slovenia)",
  "Velenje (Slovenia)",
  "Velika Loka (Slovenia)",
  "Verzej (Slovenia)",
  "Videm (Slovenia)",
  "Vipava (Slovenia)",
  "Vodice (Slovenia)",
  "Vojnik (Slovenia)",
  "Vrhnika (Slovenia)",
  "Zagorje ob Savi (Slovenia)",
  "Zelezniki (Slovenia)",
  "Zgornja Polskava (Slovenia)",
  "Zgornje Gorje (Slovenia)",
  "Zgornje Hoce (Slovenia)",
  "Zgornje Skofije (Slovenia)",
  "Zidani Most (Slovenia)",
  "Ziri (Slovenia)",
  "South Africa",
  "Alberton (South Africa)",
  "Alice (South Africa)",
  "Alrode (South Africa)",
  "Amanzimtoti (South Africa)",
  "Ashton (South Africa)",
  "Atlantis (South Africa)",
  "Balfour (South Africa)",
  "Bathurst (South Africa)",
  "Beaufort West (South Africa)",
  "Bedfordview (South Africa)",
  "Belhar (South Africa)",
  "Bellville (South Africa)",
  "Benoni (South Africa)",
  "Bergvliet (South Africa)",
  "Bethal (South Africa)",
  "Bethlehem (South Africa)",
  "Bloemfontein (South Africa)",
  "Boksburg (South Africa)",
  "Bothasig (South Africa)",
  "Botshabelo (South Africa)",
  "Braamfontein (South Africa)",
  "Brackenfell (South Africa)",
  "Brakpan (South Africa)",
  "Bredasdorp (South Africa)",
  "Brits (South Africa)",
  "Bronkhorstspruit (South Africa)",
  "Brooklyn (South Africa)",
  "Bryanston (South Africa)",
  "Buffalo (South Africa)",
  "Butterworth (South Africa)",
  "Cape Town (South Africa)",
  "Capital Park (South Africa)",
  "Carletonville (South Africa)",
  "Carolina (South Africa)",
  "Centurion (South Africa)",
  "City of Cape Town (South Africa)",
  "Clanwilliam (South Africa)",
  "Claremont (South Africa)",
  "Coega (South Africa)",
  "Coligny (South Africa)",
  "Constantia (South Africa)",
  "Craighall (South Africa)",
  "Cullinan (South Africa)",
  "De Aar (South Africa)",
  "Delmas (South Africa)",
  "Deneysville (South Africa)",
  "Despatch (South Africa)",
  "Doornfontein (South Africa)",
  "Dube Location (South Africa)",
  "Duiwelskloof (South Africa)",
  "Dullstroom (South Africa)",
  "Dundee (South Africa)",
  "Durban (South Africa)",
  "Durbanville (South Africa)",
  "East London (South Africa)",
  "Edenburg (South Africa)",
  "Edenvale (South Africa)",
  "Eersterivier (South Africa)",
  "Elandsfontein (South Africa)",
  "Elarduspark (South Africa)",
  "Eloff (South Africa)",
  "Elsburg (South Africa)",
  "Elsenburg (South Africa)",
  "Emerald Hill (South Africa)",
  "Emnambithi-Ladysmith (South Africa)",
  "Empangeni (South Africa)",
  "Erasmia (South Africa)",
  "Ermelo (South Africa)",
  "Eshowe (South Africa)",
  "Evander (South Africa)",
  "Faure (South Africa)",
  "Florida Glen (South Africa)",
  "Fochville (South Africa)",
  "Fordsburg (South Africa)",
  "Fort Cox (South Africa)",
  "Franschhoek (South Africa)",
  "Gansbaai (South Africa)",
  "Ganspan (South Africa)",
  "George (South Africa)",
  "Germiston (South Africa)",
  "Gezina (South Africa)",
  "Gillitts (South Africa)",
  "Gingindlovu (South Africa)",
  "Glen Austin (South Africa)",
  "Goodwood (South Africa)",
  "Graaff Reinet (South Africa)",
  "Grabouw (South Africa)",
  "Grahamstown (South Africa)",
  "Greytown (South Africa)",
  "Groblersdal (South Africa)",
  "Haenertsburg (South Africa)",
  "Harrismith (South Africa)",
  "Hartbeespoort (South Africa)",
  "Hartenbos (South Africa)",
  "Hartswater (South Africa)",
  "Hatfield (South Africa)",
  "Hawston (South Africa)",
  "Hazyview (South Africa)",
  "Heidelberg (South Africa)",
  "Hekpoort (South Africa)",
  "Henley on Klip (South Africa)",
  "Hennenman (South Africa)",
  "Hermanus (South Africa)",
  "Hillcrest (South Africa)",
  "Hilton (South Africa)",
  "Himeville (South Africa)",
  "Hluhluwe (South Africa)",
  "Hoedspruit (South Africa)",
  "Hogsback (South Africa)",
  "Houghton Estate (South Africa)",
  "Hout Bay (South Africa)",
  "Howick (South Africa)",
  "Humansdorp (South Africa)",
  "Hyde Park (South Africa)",
  "Irene (South Africa)",
  "Isando (South Africa)",
  "Ixopo (South Africa)",
  "Johannesburg (South Africa)",
  "Kalkbaai (South Africa)",
  "Katlehong (South Africa)",
  "Kempton Park (South Africa)",
  "Kimberley (South Africa)",
  "Kingsborough (South Africa)",
  "Kingsley (South Africa)",
  "Kingsmead (South Africa)",
  "Kinross (South Africa)",
  "Kleinmond (South Africa)",
  "Klerksdorp (South Africa)",
  "Kloof (South Africa)",
  "Knysna (South Africa)",
  "Kokstad (South Africa)",
  "Kraaifontein (South Africa)",
  "Kranskop (South Africa)",
  "Kriel (South Africa)",
  "Kroonstad (South Africa)",
  "Krugersdorp (South Africa)",
  "Kuils River (South Africa)",
  "KwaDukuza (South Africa)",
  "La Lucia (South Africa)",
  "La Motte (South Africa)",
  "Ladybrand (South Africa)",
  "Lansdowne (South Africa)",
  "Laudium (South Africa)",
  "Lephalale (South Africa)",
  "Lichtenburg (South Africa)",
  "Lombardy East (South Africa)",
  "London (South Africa)",
  "Louis Trichardt (South Africa)",
  "Lutzville (South Africa)",
  "Lydenburg (South Africa)",
  "Lyndhurst (South Africa)",
  "Lynnwood (South Africa)",
  "Lyttelton (South Africa)",
  "Machadodorp (South Africa)",
  "Mafikeng (South Africa)",
  "Maidstone (South Africa)",
  "Majuba (South Africa)",
  "Malmesbury (South Africa)",
  "Malvern (South Africa)",
  "Maraisburg (South Africa)",
  "Margate (South Africa)",
  "Marshalltown (South Africa)",
  "Meadowridge (South Africa)",
  "Medunsa (South Africa)",
  "Melkbosstrand (South Africa)",
  "Merrivale (South Africa)",
  "Messina (South Africa)",
  "Meyerton (South Africa)",
  "Middelburg (South Africa)",
  "Midrand (South Africa)",
  "Milnerton (South Africa)",
  "Mmabatho (South Africa)",
  "Mobeni (South Africa)",
  "Modderfontein (South Africa)",
  "Mokopane (South Africa)",
  "Montagu (South Africa)",
  "Monument Park (South Africa)",
  "Mooi River (South Africa)",
  "Mookgophong (South Africa)",
  "Moorreesburg (South Africa)",
  "Morningside Manor (South Africa)",
  "Mossel Bay (South Africa)",
  "Mount Edgecombe (South Africa)",
  "Mthatha (South Africa)",
  "Muizenberg (South Africa)",
  "Napier (South Africa)",
  "Nelspruit (South Africa)",
  "New Germany (South Africa)",
  "Newcastle (South Africa)",
  "Newlands (South Africa)",
  "Nigel (South Africa)",
  "Northcliff (South Africa)",
  "Odendaalsrus (South Africa)",
  "Ogies (South Africa)",
  "Olifantsfontein (South Africa)",
  "Onderstepoort (South Africa)",
  "Onverwacht (South Africa)",
  "Oranjeville (South Africa)",
  "Oranjezicht (South Africa)",
  "Ottosdal (South Africa)",
  "Oudtshoorn (South Africa)",
  "Overport (South Africa)",
  "Paarl (South Africa)",
  "Panorama (South Africa)",
  "Parktown (South Africa)",
  "Parkview (South Africa)",
  "Parow (South Africa)",
  "Peninsula (South Africa)",
  "Phalaborwa (South Africa)",
  "Philadelphia (South Africa)",
  "Philipstown (South Africa)",
  "Phoenix (South Africa)",
  "Phokeng (South Africa)",
  "Pietermaritzburg (South Africa)",
  "Pinelands (South Africa)",
  "Pinetown (South Africa)",
  "Plettenberg Bay (South Africa)",
  "Plumstead (South Africa)",
  "Polokwane (South Africa)",
  "Pongola (South Africa)",
  "Port Alfred (South Africa)",
  "Port Elizabeth (South Africa)",
  "Port Shepstone (South Africa)",
  "Potchefstroom (South Africa)",
  "Pretoria (South Africa)",
  "Prieska (South Africa)",
  "Primrose (South Africa)",
  "Pringle Bay (South Africa)",
  "Queensburgh (South Africa)",
  "Queenstown (South Africa)",
  "Queenswood (South Africa)",
  "Randburg (South Africa)",
  "Randfontein (South Africa)",
  "Rawsonville (South Africa)",
  "Rhodes (South Africa)",
  "Richards Bay (South Africa)",
  "Richmond (South Africa)",
  "Riebeek-Kasteel (South Africa)",
  "Rivonia (South Africa)",
  "Roggebaai (South Africa)",
  "Roodepoort (South Africa)",
  "Rooigrond (South Africa)",
  "Rooihuiskraal (South Africa)",
  "Rosebank (South Africa)",
  "Rosettenville (South Africa)",
  "Rosslyn (South Africa)",
  "Rustenburg (South Africa)",
  "Sabie (South Africa)",
  "Saint Helena Bay (South Africa)",
  "Saint James (South Africa)",
  "Saldanha (South Africa)",
  "Salt Rock (South Africa)",
  "Sandown (South Africa)",
  "Sandton (South Africa)",
  "Sasolburg (South Africa)",
  "Schweizer-Reneke (South Africa)",
  "Scottburgh (South Africa)",
  "Sebokeng (South Africa)",
  "Secunda (South Africa)",
  "Sedgefield (South Africa)",
  "Senekal (South Africa)",
  "Shelly Beach (South Africa)",
  "Silverton (South Africa)",
  "Sinoville (South Africa)",
  "Somerset West (South Africa)",
  "Soshanguve (South Africa)",
  "Soweto (South Africa)",
  "Springs (South Africa)",
  "Standerton (South Africa)",
  "Stanford (South Africa)",
  "Stellenbosch (South Africa)",
  "Stilfontein (South Africa)",
  "Strand (South Africa)",
  "Sun Valley (South Africa)",
  "Swartkops (South Africa)",
  "Temba (South Africa)",
  "Tembisa (South Africa)",
  "Thabazimbi (South Africa)",
  "The Rest (South Africa)",
  "Thohoyandou (South Africa)",
  "Three Anchor Bay (South Africa)",
  "Three Rivers (South Africa)",
  "Tokai (South Africa)",
  "Tongaat (South Africa)",
  "Tzaneen (South Africa)",
  "Uitenhage (South Africa)",
  "Umbogintwini (South Africa)",
  "Umdloti (South Africa)",
  "Umhlanga (South Africa)",
  "Umkomaas (South Africa)",
  "Umtentweni (South Africa)",
  "Upington (South Africa)",
  "Vaalbank (South Africa)",
  "Vaalpark (South Africa)",
  "Vanderbijlpark (South Africa)",
  "Vereeniging (South Africa)",
  "Verulam (South Africa)",
  "Villiersdorp (South Africa)",
  "Virginia (South Africa)",
  "Vosloorus (South Africa)",
  "Vrededorp (South Africa)",
  "Vredenburg (South Africa)",
  "Vryheid (South Africa)",
  "Walkerville (South Africa)",
  "Walmer Heights (South Africa)",
  "Warner Beach (South Africa)",
  "Wartburg (South Africa)",
  "Waverley (South Africa)",
  "Welgelegen (South Africa)",
  "Welgemoed (South Africa)",
  "Welkom (South Africa)",
  "Wellington (South Africa)",
  "Weltevreden (South Africa)",
  "Weltevreedenpark (South Africa)",
  "Westonaria (South Africa)",
  "Westville (South Africa)",
  "White River (South Africa)",
  "Wilderness (South Africa)",
  "Winterton (South Africa)",
  "Witbank (South Africa)",
  "Witpoortjie (South Africa)",
  "Wonderboom (South Africa)",
  "Worcester (South Africa)",
  "Wynberg (South Africa)",
  "Zeerust (South Africa)",
  "Spain",
  "A Cidade (Spain)",
  "A Estrada (Spain)",
  "A Pobra do Caraminal (Spain)",
  "Abadino (Spain)",
  "Abanilla (Spain)",
  "Abanto (Spain)",
  "Abaran (Spain)",
  "Abegondo (Spain)",
  "Abrera (Spain)",
  "Acala del Rio (Spain)",
  "Aceuchal (Spain)",
  "Adeje (Spain)",
  "Ador (Spain)",
  "Adra (Spain)",
  "Adrall (Spain)",
  "Aduna (Spain)",
  "Agaete (Spain)",
  "Agoncillo (Spain)",
  "Agost (Spain)",
  "Agramunt (Spain)",
  "Agreda (Spain)",
  "Aguadulce (Spain)",
  "Aguilafuente (Spain)",
  "Aguilar (Spain)",
  "Aguilar de Campoo (Spain)",
  "Aguilar del Rio Alhama (Spain)",
  "Agullana (Spain)",
  "Agullent (Spain)",
  "Ahigal (Spain)",
  "Aielo de Malferit (Spain)",
  "Aiguafreda (Spain)",
  "Aizarnazabal (Spain)",
  "Aizoain (Spain)",
  "Ajalvir (Spain)",
  "Ajo (Spain)",
  "Ajofrin (Spain)",
  "Alaejos (Spain)",
  "Alagon (Spain)",
  "Alameda (Spain)",
  "Alanis (Spain)",
  "Alava (Spain)",
  "Alba de Tormes (Spain)",
  "Albacete (Spain)",
  "Albaida (Spain)",
  "Albal (Spain)",
  "Albalat dels Sorells (Spain)",
  "Albalat dels Tarongers (Spain)",
  "Albalate de Cinca (Spain)",
  "Albarreal de Tajo (Spain)",
  "Albatera (Spain)",
  "Albelda de Iregua (Spain)",
  "Albendin (Spain)",
  "Alberic (Spain)",
  "Alberite (Spain)",
  "Albolote (Spain)",
  "Alboraya (Spain)",
  "Albox (Spain)",
  "Albuixech (Spain)",
  "Albujon (Spain)",
  "Alburquerque (Spain)",
  "Alcala de Xivert (Spain)",
  "Alcala del Obispo (Spain)",
  "Alcala la Real (Spain)",
  "Alcanadre (Spain)",
  "Alcanar (Spain)",
  "Alcantara (Spain)",
  "Alcantarilla (Spain)",
  "Alcarras (Spain)",
  "Alcazar de San Juan (Spain)",
  "Alcobendas (Spain)",
  "Alcoletge (Spain)",
  "Alcorisa (Spain)",
  "Alcossebre (Spain)",
  "Alcover (Spain)",
  "Alcoy (Spain)",
  "Aldaia (Spain)",
  "Aldealsenor (Spain)",
  "Aldeamayor de San Martin (Spain)",
  "Aldeanueva (Spain)",
  "Aldeanueva de Ebro (Spain)",
  "Aldeaseca de Armuna (Spain)",
  "Aldeatejada (Spain)",
  "Alegria (Spain)",
  "Alella (Spain)",
  "Alfafar (Spain)",
  "Alfajarin (Spain)",
  "Alfambra (Spain)",
  "Alfara de Algimia (Spain)",
  "Alfara del Patriarca (Spain)",
  "Alfaro (Spain)",
  "Alfarrasi (Spain)",
  "Alfondeguilla (Spain)",
  "Alforja (Spain)",
  "Algadefe (Spain)",
  "Algaida (Spain)",
  "Algar (Spain)",
  "Algarrobo (Spain)",
  "Algeciras (Spain)",
  "Algemesi (Spain)",
  "Algete (Spain)",
  "Algezares (Spain)",
  "Alginet (Spain)",
  "Algodonales (Spain)",
  "Algorta (Spain)",
  "Alguazas (Spain)",
  "Alhama de Aragon (Spain)",
  "Alhama de Granada (Spain)",
  "Alhama de Murcia (Spain)",
  "Alhambra (Spain)",
  "Alhaurin de la Torre (Spain)",
  "Alhaurin el Grande (Spain)",
  "Alhendin (Spain)",
  "Alicante (Spain)",
  "Aliseda (Spain)",
  "Aljaraque (Spain)",
  "Allariz (Spain)",
  "Almagro (Spain)",
  "Almansa (Spain)",
  "Almargen (Spain)",
  "Almassera (Spain)",
  "Almassora (Spain)",
  "Almazan (Spain)",
  "Almazcara (Spain)",
  "Almenar (Spain)",
  "Almenara (Spain)",
  "Almendralejo (Spain)",
  "Almensilla (Spain)",
  "Almodovar del Campo (Spain)",
  "Almodovar del Rio (Spain)",
  "Almonacid de Zorita (Spain)",
  "Almonte (Spain)",
  "Almoradi (Spain)",
  "Almoster (Spain)",
  "Almudebar (Spain)",
  "Almuna (Spain)",
  "Alonsotegi (Spain)",
  "Alora (Spain)",
  "Alosno (Spain)",
  "Alovera (Spain)",
  "Alozaina (Spain)",
  "Alpedrete (Spain)",
  "Alpicat (Spain)",
  "Alquerias (Spain)",
  "Alta (Spain)",
  "Altafulla (Spain)",
  "Altea (Spain)",
  "Altet (Spain)",
  "Altura (Spain)",
  "Alumbres (Spain)",
  "Alza (Spain)",
  "Alzira (Spain)",
  "Amavida (Spain)",
  "Amer (Spain)",
  "Ames (Spain)",
  "Ametlla (Spain)",
  "Amorebieta (Spain)",
  "Amposta (Spain)",
  "Ampuero (Spain)",
  "Amurrio (Spain)",
  "Anchuelo (Spain)",
  "Andeiro (Spain)",
  "Andoain (Spain)",
  "Andorra (Spain)",
  "Andosilla (Spain)",
  "Andujar (Spain)",
  "Anglesola (Spain)",
  "Anguciana (Spain)",
  "Anoeta (Spain)",
  "Anora (Spain)",
  "Anorbe (Spain)",
  "Antas (Spain)",
  "Antequera (Spain)",
  "Antigua (Spain)",
  "Aracena (Spain)",
  "Arafo (Spain)",
  "Arama (Spain)",
  "Aranda de Duero (Spain)",
  "Aranguren (Spain)",
  "Aranjuez (Spain)",
  "Aravaca (Spain)",
  "Arbizu (Spain)",
  "Arbo (Spain)",
  "Arboleas (Spain)",
  "Arca (Spain)",
  "Arcade (Spain)",
  "Arce (Spain)",
  "Archena (Spain)",
  "Archidona (Spain)",
  "Arcicollar (Spain)",
  "Arcos de la Frontera (Spain)",
  "Arenas de San Pedro (Spain)",
  "Arenys de Mar (Spain)",
  "Arenys de Munt (Spain)",
  "Ares (Spain)",
  "Arevalo (Spain)",
  "Argamasilla de Alba (Spain)",
  "Argamasilla de Calatrava (Spain)",
  "Arganda (Spain)",
  "Argelaguer (Spain)",
  "Argentona (Spain)",
  "Arges (Spain)",
  "Argonos (Spain)",
  "Arico (Spain)",
  "Arino (Spain)",
  "Arjona (Spain)",
  "Armilla (Spain)",
  "Armunia (Spain)",
  "Arnedillo (Spain)",
  "Arnedo (Spain)",
  "Arnuero (Spain)",
  "Aroche (Spain)",
  "Arona (Spain)",
  "Arrecife (Spain)",
  "Arriate (Spain)",
  "Arrigorriaga (Spain)",
  "Arroyal (Spain)",
  "Arroyo (Spain)",
  "Arroyo de la Luz (Spain)",
  "Arroyo de la Miel (Spain)",
  "Arroyo del Ojanco (Spain)",
  "Arroyomolinos (Spain)",
  "Arrubal (Spain)",
  "Artajo (Spain)",
  "Artajona (Spain)",
  "Artana (Spain)",
  "Arteixo (Spain)",
  "Artenara (Spain)",
  "Artes (Spain)",
  "Artesa de Segre (Spain)",
  "Arucas (Spain)",
  "Arzua (Spain)",
  "As Pontes de Garcia Rodriguez (Spain)",
  "Aspe (Spain)",
  "Asteasu (Spain)",
  "Astigarraga (Spain)",
  "Astorga (Spain)",
  "Astrabudua (Spain)",
  "Astrain (Spain)",
  "Astudillo (Spain)",
  "Atarfe (Spain)",
  "Atarrabia (Spain)",
  "Ataun (Spain)",
  "Ateca (Spain)",
  "Autol (Spain)",
  "Avia (Spain)",
  "Avinyonet del Penedes (Spain)",
  "Axpe (Spain)",
  "Ayamonte (Spain)",
  "Ayegui (Spain)",
  "Azagra (Spain)",
  "Azkoitia (Spain)",
  "Aznalcazar (Spain)",
  "Aznalcollar (Spain)",
  "Azpeitia (Spain)",
  "Azuaga (Spain)",
  "Azuqueca de Henares (Spain)",
  "Babilafuente (Spain)",
  "Badajoz (Spain)",
  "Badalona (Spain)",
  "Baena (Spain)",
  "Baeza (Spain)",
  "Baga (Spain)",
  "Bailen (Spain)",
  "Baina (Spain)",
  "Bajamar (Spain)",
  "Balaguer (Spain)",
  "Balazote (Spain)",
  "Balenya (Spain)",
  "Balerma (Spain)",
  "Balsareny (Spain)",
  "Balsicas (Spain)",
  "Banugues (Spain)",
  "Bara (Spain)",
  "Barakaldo (Spain)",
  "Baranain (Spain)",
  "Barbarroja (Spain)",
  "Barbastro (Spain)",
  "Barbate (Spain)",
  "Barcarrota (Spain)",
  "Barcelona (Spain)",
  "Barcena de Cicero (Spain)",
  "Bargas (Spain)",
  "Barranda (Spain)",
  "Barros (Spain)",
  "Bas (Spain)",
  "Basauri (Spain)",
  "Batres (Spain)",
  "Baza (Spain)",
  "Beas (Spain)",
  "Beas de Segura (Spain)",
  "Becerril de la Sierra (Spain)",
  "Bedmar (Spain)",
  "Begijar (Spain)",
  "Begis (Spain)",
  "Begues (Spain)",
  "Begur (Spain)",
  "Bejar (Spain)",
  "Belgida (Spain)",
  "Belicena (Spain)",
  "Bellpuig (Spain)",
  "Bellvei (Spain)",
  "Bellver de Cerdanya (Spain)",
  "Bellvis (Spain)",
  "Belvis de Jarama (Spain)",
  "Belvis de la Jara (Spain)",
  "Bembibre (Spain)",
  "Benacazon (Spain)",
  "Benagalbon (Spain)",
  "Benahadux (Spain)",
  "Benahavis (Spain)",
  "Benamargosa (Spain)",
  "Benameji (Spain)",
  "Benatae (Spain)",
  "Benavarri / Benabarre (Spain)",
  "Benavente (Spain)",
  "Benavides (Spain)",
  "Benejuzar (Spain)",
  "Beniajan (Spain)",
  "Beniarbeig (Spain)",
  "Beniarjo (Spain)",
  "Beniarres (Spain)",
  "Benicassim (Spain)",
  "Benicolet (Spain)",
  "Benidoleig (Spain)",
  "Benidorm (Spain)",
  "Beniel (Spain)",
  "Benifairo de les Valls (Spain)",
  "Benifato (Spain)",
  "Beniganim (Spain)",
  "Benijofar (Spain)",
  "Benimodo (Spain)",
  "Beniparrell (Spain)",
  "Benisano (Spain)",
  "Benissa (Spain)",
  "Benisuera (Spain)",
  "Benitachell (Spain)",
  "Beranga (Spain)",
  "Berango (Spain)",
  "Berantevilla (Spain)",
  "Berastegui (Spain)",
  "Berga (Spain)",
  "Bergondo (Spain)",
  "Berguenda (Spain)",
  "Beriain (Spain)",
  "Berja (Spain)",
  "Bermeo (Spain)",
  "Bernuy (Spain)",
  "Berriatua (Spain)",
  "Berriobeiti (Spain)",
  "Berriozar (Spain)",
  "Berriz (Spain)",
  "Berrobi (Spain)",
  "Berron (Spain)",
  "Bescano (Spain)",
  "Betanzos (Spain)",
  "Betelu (Spain)",
  "Betera (Spain)",
  "Beznar (Spain)",
  "Biar (Spain)",
  "Bicorp (Spain)",
  "Bigastro (Spain)",
  "Bigues i Riells (Spain)",
  "Bilbao (Spain)",
  "Binefar (Spain)",
  "Bitem (Spain)",
  "Blanca (Spain)",
  "Blancos (Spain)",
  "Blanes (Spain)",
  "Boadilla del Monte (Spain)",
  "Bocairent (Spain)",
  "Boceguillas (Spain)",
  "Boecillo (Spain)",
  "Bogajo (Spain)",
  "Boimorto (Spain)",
  "Boiro (Spain)",
  "Bolanos de Calatrava (Spain)",
  "Bollullos de la Mitacion (Spain)",
  "Bollullos par del Condado (Spain)",
  "Boltana (Spain)",
  "Bolvir (Spain)",
  "Bonares (Spain)",
  "Borben (Spain)",
  "Bordils (Spain)",
  "Borja (Spain)",
  "Bormujos (Spain)",
  "Bornos (Spain)",
  "Borox (Spain)",
  "Borriol (Spain)",
  "Botarell (Spain)",
  "Botorrita (Spain)",
  "Brafim (Spain)",
  "Brazatortas (Spain)",
  "Brea de Aragon (Spain)",
  "Breda (Spain)",
  "Brenes (Spain)",
  "Brihuega (Spain)",
  "Brion (Spain)",
  "Briviesca (Spain)",
  "Broto (Spain)",
  "Brunete (Spain)",
  "Buenache de Alarcon (Spain)",
  "Bueu (Spain)",
  "Bujaraloz (Spain)",
  "Bullas (Spain)",
  "Burela de Cabo (Spain)",
  "Burgos (Spain)",
  "Burguillos (Spain)",
  "Burguillos del Cerro (Spain)",
  "Burjassot (Spain)",
  "Burlata (Spain)",
  "Burriana (Spain)",
  "Busot (Spain)",
  "Bustarviejo (Spain)",
  "Busturi-Axpe (Spain)",
  "Cabana (Spain)",
  "Cabanaquinta (Spain)",
  "Cabanas (Spain)",
  "Cabanas de la Sagra (Spain)",
  "Cabanes (Spain)",
  "Cabanillas del Campo (Spain)",
  "Cabeza del Buey (Spain)",
  "Cabezarrubias del Puerto (Spain)",
  "Cabezo de Torres (Spain)",
  "Cabezon de la Sal (Spain)",
  "Caborana (Spain)",
  "Cabra (Spain)",
  "Cabrera de Almanza (Spain)",
  "Cabrerizos (Spain)",
  "Cabrils (Spain)",
  "Cacheiras (Spain)",
  "Cadiar (Spain)",
  "Cadiz (Spain)",
  "Cadreita (Spain)",
  "Caion (Spain)",
  "Cajar (Spain)",
  "Cala (Spain)",
  "Cala del Moral (Spain)",
  "Cala Millor (Spain)",
  "Cala Ratjada (Spain)",
  "Calaf (Spain)",
  "Calafell (Spain)",
  "Calahonda (Spain)",
  "Calahorra (Spain)",
  "Calamonte (Spain)",
  "Calasparra (Spain)",
  "Calatayud (Spain)",
  "Calatorao (Spain)",
  "Caldelas (Spain)",
  "Calders (Spain)",
  "Caldes de Malavella (Spain)",
  "Calella (Spain)",
  "Calig (Spain)",
  "Callosa de Segura (Spain)",
  "Calo (Spain)",
  "Calonge (Spain)",
  "Calpe (Spain)",
  "Camarassa (Spain)",
  "Camarenilla (Spain)",
  "Camargo (Spain)",
  "Camarinas (Spain)",
  "Camarma de Esteruelas (Spain)",
  "Camas (Spain)",
  "Cambados (Spain)",
  "Cambre (Spain)",
  "Cambrils (Spain)",
  "Camos (Spain)",
  "Campanario (Spain)",
  "Campillo (Spain)",
  "Campillos (Spain)",
  "Campina (Spain)",
  "Campins (Spain)",
  "Campo (Spain)",
  "Campo de Criptana (Spain)",
  "Campo Real (Spain)",
  "Campohermoso (Spain)",
  "Camponaraya (Spain)",
  "Campos (Spain)",
  "Camprodon (Spain)",
  "Campuzano (Spain)",
  "Can Picafort (Spain)",
  "Canal (Spain)",
  "Canals (Spain)",
  "Canar (Spain)",
  "Candas (Spain)",
  "Candelaria (Spain)",
  "Candeleda (Spain)",
  "Caneja (Spain)",
  "Canet de Mar (Spain)",
  "Canete de las Torres (Spain)",
  "Cangas de Onis (Spain)",
  "Cangas del Narcea (Spain)",
  "Cangas do Morrazo (Spain)",
  "Canillas de Albaida (Spain)",
  "Canovelles (Spain)",
  "Cantillana (Spain)",
  "Cantimpalos (Spain)",
  "Capdepera (Spain)",
  "Capela (Spain)",
  "Capella (Spain)",
  "Capellades (Spain)",
  "Capileira (Spain)",
  "Capmany (Spain)",
  "Capsec (Spain)",
  "Carabana (Spain)",
  "Caravaca (Spain)",
  "Carbajal de la Legua (Spain)",
  "Carbajosa de la Sagrada (Spain)",
  "Carballo (Spain)",
  "Carcabuey (Spain)",
  "Carcar (Spain)",
  "Carcastillo (Spain)",
  "Cardedeu (Spain)",
  "Cardenal (Spain)",
  "Cardenosa (Spain)",
  "Cardona (Spain)",
  "Carinena (Spain)",
  "Carlet (Spain)",
  "Carmona (Spain)",
  "Carpesa (Spain)",
  "Carpio (Spain)",
  "Carral (Spain)",
  "Carranque (Spain)",
  "Carrascal de Barregas (Spain)",
  "Carrion de los Cespedes (Spain)",
  "Carrion de los Condes (Spain)",
  "Carrizo de la Ribera (Spain)",
  "Cartagena (Spain)",
  "Cartama (Spain)",
  "Cartaya (Spain)",
  "Cartelle (Spain)",
  "Casabermeja (Spain)",
  "Casar de Caceres (Spain)",
  "Casares (Spain)",
  "Casarrubios del Monte (Spain)",
  "Casas del Castanar (Spain)",
  "Casas del Monte (Spain)",
  "Casas Viejas (Spain)",
  "Caserio El Campello (Spain)",
  "Casetas (Spain)",
  "Casillas de Coria (Spain)",
  "Casillas del Angel (Spain)",
  "Casla (Spain)",
  "Caso (Spain)",
  "Caspe (Spain)",
  "Castalla (Spain)",
  "Castandiello (Spain)",
  "Castejon (Spain)",
  "Castejon del Puente (Spain)",
  "Castell de Ferro (Spain)",
  "Castellar de la Frontera (Spain)",
  "Castellar de Santiago (Spain)",
  "Castellar del Valles (Spain)",
  "Castellbell i el Vilar (Spain)",
  "Castellbisbal (Spain)",
  "Castelldefels (Spain)",
  "Castellet (Spain)",
  "Castello (Spain)",
  "Castellvell del Camp (Spain)",
  "Castellvi de Rosanes (Spain)",
  "Castelseras (Spain)",
  "Castilblanco de los Arroyos (Spain)",
  "Castilleja de Guzman (Spain)",
  "Castilleja de la Cuesta (Spain)",
  "Castillo de Locubin (Spain)",
  "Castillo del Romeral (Spain)",
  "Castrillon (Spain)",
  "Castro (Spain)",
  "Castro Urdiales (Spain)",
  "Castrogonzalo (Spain)",
  "Castropol (Spain)",
  "Castuera (Spain)",
  "Catadau (Spain)",
  "Catarroja (Spain)",
  "Cati (Spain)",
  "Catoira (Spain)",
  "Catral (Spain)",
  "Caudete (Spain)",
  "Cazalegas (Spain)",
  "Cazorla (Spain)",
  "Cea (Spain)",
  "Cebolla (Spain)",
  "Cebreros (Spain)",
  "Cecenas (Spain)",
  "Cedillo del Condado (Spain)",
  "Celanova (Spain)",
  "Celeiro (Spain)",
  "Celeiros (Spain)",
  "Celra (Spain)",
  "Cenes de la Vega (Spain)",
  "Cenicero (Spain)",
  "Cenicientos (Spain)",
  "Central (Spain)",
  "Cerceda (Spain)",
  "Cercedilla (Spain)",
  "Cerdeda (Spain)",
  "Cerro Muriano (Spain)",
  "Cervello (Spain)",
  "Cervera (Spain)",
  "Cervera del Rio Alhama (Spain)",
  "Cervo (Spain)",
  "Cetina (Spain)",
  "Ceuta (Spain)",
  "Ceuti (Spain)",
  "Chantada (Spain)",
  "Chapela (Spain)",
  "Chauchina (Spain)",
  "Checa (Spain)",
  "Chelva (Spain)",
  "Cheste (Spain)",
  "Chiclana de la Frontera (Spain)",
  "Chiclana de Segura (Spain)",
  "Chilches (Spain)",
  "Chiloeches (Spain)",
  "Chinchilla de Monte Aragon (Spain)",
  "Chipiona (Spain)",
  "Chiva (Spain)",
  "Chozas de Canales (Spain)",
  "Chucena (Spain)",
  "Churra (Spain)",
  "Churriana (Spain)",
  "Churriana de la Vega (Spain)",
  "Cicero (Spain)",
  "Cidones (Spain)",
  "Ciempozuelos (Spain)",
  "Cieza (Spain)",
  "Cifuentes (Spain)",
  "Cigales (Spain)",
  "Cijuela (Spain)",
  "Cilleros (Spain)",
  "Ciriza (Spain)",
  "Cisterniga (Spain)",
  "Cistierna (Spain)",
  "Ciudad Real (Spain)",
  "Ciudad Rodrigo (Spain)",
  "Ciutadella (Spain)",
  "Cizur Menor (Spain)",
  "Cobatillas (Spain)",
  "Cobena (Spain)",
  "Cobisa (Spain)",
  "Coca (Spain)",
  "Cocentaina (Spain)",
  "Cogollos (Spain)",
  "Coin (Spain)",
  "Coiros (Spain)",
  "Colera (Spain)",
  "Coles (Spain)",
  "Colinas de Trasmonte (Spain)",
  "Colindres (Spain)",
  "Collado (Spain)",
  "Collado Mediano (Spain)",
  "Collado Villalba (Spain)",
  "Collbato (Spain)",
  "Colmenar de Oreja (Spain)",
  "Colmenar del Arroyo (Spain)",
  "Colmenar Viejo (Spain)",
  "Colmenarejo (Spain)",
  "Colonia de San Pedro (Spain)",
  "Comares (Spain)",
  "Comillas (Spain)",
  "Competa (Spain)",
  "Compostela (Spain)",
  "Conil de la Frontera (Spain)",
  "Consell (Spain)",
  "Constanti (Spain)",
  "Constantina (Spain)",
  "Consuegra (Spain)",
  "Corbera (Spain)",
  "Corbera de Llobregat (Spain)",
  "Corchuela (Spain)",
  "Cordova (Spain)",
  "Corella (Spain)",
  "Corgo (Spain)",
  "Coria (Spain)",
  "Coristanco (Spain)",
  "Corme-Porto (Spain)",
  "Cornellana (Spain)",
  "Corral de Almaguer (Spain)",
  "Corralejo (Spain)",
  "Corrales (Spain)",
  "Cortegana (Spain)",
  "Cortes de la Frontera (Spain)",
  "Cortiguera (Spain)",
  "Corvera (Spain)",
  "Coslada (Spain)",
  "Costa (Spain)",
  "Costur (Spain)",
  "Couso de Salas (Spain)",
  "Covaleda (Spain)",
  "Cox (Spain)",
  "Creixell (Spain)",
  "Crevillent (Spain)",
  "Cruce de Arinaga (Spain)",
  "Cruces (Spain)",
  "Cruilles (Spain)",
  "Cruz (Spain)",
  "Cuadros (Spain)",
  "Cuarte de Huerva (Spain)",
  "Cubas (Spain)",
  "Cubelles (Spain)",
  "Cubillos del Sil (Spain)",
  "Cudillero (Spain)",
  "Cuellar (Spain)",
  "Cuenca (Spain)",
  "Cueto (Spain)",
  "Cuevas de San Marcos (Spain)",
  "Cuevas de Vinroma (Spain)",
  "Cuevas del Almanzora (Spain)",
  "Cullera (Spain)",
  "Culleredo (Spain)",
  "Cunit (Spain)",
  "Cuntis (Spain)",
  "Curro (Spain)",
  "Curtis (Spain)",
  "Daganzo de Arriba (Spain)",
  "Daimiel (Spain)",
  "Dalias (Spain)",
  "Daroca (Spain)",
  "Daya Vieja (Spain)",
  "Deba (Spain)",
  "Denia (Spain)",
  "Derio (Spain)",
  "Deustu (Spain)",
  "Diezma (Spain)",
  "Dilar (Spain)",
  "Dodro (Spain)",
  "Dolores (Spain)",
  "Domingo Perez (Spain)",
  "Don Benito (Spain)",
  "Dos Hermanas (Spain)",
  "Dosbarrios (Spain)",
  "Dosrius (Spain)",
  "Duenas (Spain)",
  "Dumbria (Spain)",
  "Durango (Spain)",
  "Durcal (Spain)",
  "Echarri-Aranaz (Spain)",
  "Egues-Uharte (Spain)",
  "Eibar (Spain)",
  "Ejea de los Caballeros (Spain)",
  "El Abrigo (Spain)",
  "El Alamo (Spain)",
  "El Arahal (Spain)",
  "El Astillero (Spain)",
  "El Ballestero (Spain)",
  "El Barco de Avila (Spain)",
  "El Burgo de Ebro (Spain)",
  "El Burgo de Osma (Spain)",
  "el Camp de Mirra (Spain)",
  "el Campello (Spain)",
  "El Casar (Spain)",
  "el Catllar (Spain)",
  "El Coronil (Spain)",
  "El Corrillo (Spain)",
  "El Cuervo (Spain)",
  "El Ejido (Spain)",
  "El Escorial (Spain)",
  "El Espinar (Spain)",
  "El Garrobo (Spain)",
  "El Grado (Spain)",
  "El Grao (Spain)",
  "El Hoyo de Pinares (Spain)",
  "el Masnou (Spain)",
  "El Medano (Spain)",
  "El Molar (Spain)",
  "El Moral (Spain)",
  "el Morche (Spain)",
  "el Morell (Spain)",
  "El Palmar (Spain)",
  "El Palmar de Troya (Spain)",
  "El Papiol (Spain)",
  "El Pedernoso (Spain)",
  "El Perello (Spain)",
  "el Poal (Spain)",
  "El Pozo de los Frailes (Spain)",
  "el Prat de Llobregat (Spain)",
  "El Provencio (Spain)",
  "El Puerto (Spain)",
  "El Raal (Spain)",
  "El Real de la Jara (Spain)",
  "El Saler (Spain)",
  "El Sauzal (Spain)",
  "El Sotillo (Spain)",
  "El Tablero (Spain)",
  "El Torno (Spain)",
  "El Toro (Spain)",
  "El Vellon (Spain)",
  "El Vendrell (Spain)",
  "El Viso de San Juan (Spain)",
  "El Viso del Alcor (Spain)",
  "Elburgo (Spain)",
  "Elche (Spain)",
  "Elda (Spain)",
  "Elechas (Spain)",
  "Elexalde (Spain)",
  "Elgoibar (Spain)",
  "Elgorriaga (Spain)",
  "Elizondo (Spain)",
  "Elorrio (Spain)",
  "els Hostalets de Pierola (Spain)",
  "Els Monjos (Spain)",
  "els Pallaresos (Spain)",
  "Eltzaburu (Spain)",
  "Encinas Reales (Spain)",
  "Encinasola (Spain)",
  "Eneriz (Spain)",
  "Enguera (Spain)",
  "Entrambasaguas (Spain)",
  "Entrevias (Spain)",
  "Epila (Spain)",
  "Erandio (Spain)",
  "Ermua (Spain)",
  "Errenteria (Spain)",
  "Escalona (Spain)",
  "Escalonilla (Spain)",
  "Escatron (Spain)",
  "Escurial (Spain)",
  "Esparreguera (Spain)",
  "Espartinas (Spain)",
  "Espejo (Spain)",
  "Espera (Spain)",
  "Espiel (Spain)",
  "Espinardo (Spain)",
  "Espirdo (Spain)",
  "Esplugues de Llobregat (Spain)",
  "Esplus (Spain)",
  "Espolla (Spain)",
  "Esquivias (Spain)",
  "Esteiro (Spain)",
  "Estella-Lizarra (Spain)",
  "Estellencs (Spain)",
  "Estepa (Spain)",
  "Estepona (Spain)",
  "Estrada (Spain)",
  "Ezcaray (Spain)",
  "Fabero (Spain)",
  "Falces (Spain)",
  "Falset (Spain)",
  "Fasnia (Spain)",
  "Faura (Spain)",
  "Felanitx (Spain)",
  "Fene (Spain)",
  "Ferreira (Spain)",
  "Ferreries (Spain)",
  "Ferrol (Spain)",
  "Figaro (Spain)",
  "Figueras (Spain)",
  "Finestrat (Spain)",
  "Firgas (Spain)",
  "Fitero (Spain)",
  "Flix (Spain)",
  "Formentera de Segura (Spain)",
  "Fornells de la Selva (Spain)",
  "Fortia (Spain)",
  "Fortuna (Spain)",
  "Forua (Spain)",
  "Foz (Spain)",
  "Fraga (Spain)",
  "Frailes (Spain)",
  "Fregenal de la Sierra (Spain)",
  "Fresno de la Vega (Spain)",
  "Frigiliana (Spain)",
  "Friol (Spain)",
  "Fruiz (Spain)",
  "Fuengirola (Spain)",
  "Fuenlabrada (Spain)",
  "Fuenmayor (Spain)",
  "Fuensalida (Spain)",
  "Fuensanta (Spain)",
  "Fuensanta de Martos (Spain)",
  "Fuente del Maestre (Spain)",
  "Fuente el Fresno (Spain)",
  "Fuente el Saz (Spain)",
  "Fuente Vaqueros (Spain)",
  "Fuente-Alamo (Spain)",
  "Fuentealbilla (Spain)",
  "Fuentelapena (Spain)",
  "Fuenterrabia (Spain)",
  "Fuentes (Spain)",
  "Fuentes de Andalucia (Spain)",
  "Fuentes de Ebro (Spain)",
  "Fuentes de Jiloca (Spain)",
  "Fuentes de Leon (Spain)",
  "Fuentes de Valdepero (Spain)",
  "Gaceta (Spain)",
  "Galapagar (Spain)",
  "Galapagos (Spain)",
  "Galaroza (Spain)",
  "Galdakao (Spain)",
  "Galdames Beitia (Spain)",
  "Galera (Spain)",
  "Galizano (Spain)",
  "Gallarta (Spain)",
  "Gallur (Spain)",
  "Galvez (Spain)",
  "Gamiz (Spain)",
  "Gamonal (Spain)",
  "Gandesa (Spain)",
  "Gandia (Spain)",
  "Garachico (Spain)",
  "Garcia (Spain)",
  "Garciaz (Spain)",
  "Gargallo (Spain)",
  "Garraf (Spain)",
  "Garrigas (Spain)",
  "Garriguella (Spain)",
  "Garrovillas (Spain)",
  "Garrucha (Spain)",
  "Gata (Spain)",
  "Gata de Gorgos (Spain)",
  "Gaucin (Spain)",
  "Gautegiz Arteaga (Spain)",
  "Gava (Spain)",
  "Gelida (Spain)",
  "Gelsa (Spain)",
  "Gelves (Spain)",
  "Genoves (Spain)",
  "Gerena (Spain)",
  "Gernika-Lumo (Spain)",
  "Getafe (Spain)",
  "Getaria (Spain)",
  "Gilet (Spain)",
  "Gines (Spain)",
  "Girona (Spain)",
  "Gironella (Spain)",
  "Gizaburuaga (Spain)",
  "Godella (Spain)",
  "Godelleta (Spain)",
  "Gojar (Spain)",
  "Golmayo (Spain)",
  "Golmes (Spain)",
  "Gondomar (Spain)",
  "Gordon (Spain)",
  "Gorriti (Spain)",
  "Gozon de Ucieza (Spain)",
  "Grado (Spain)",
  "Granada (Spain)",
  "Granadilla de Abona (Spain)",
  "Granja de Rocamora (Spain)",
  "Granollers (Spain)",
  "Graus (Spain)",
  "Grazalema (Spain)",
  "Grijota (Spain)",
  "Grinon (Spain)",
  "Grisen (Spain)",
  "Grove, O (Spain)",
  "Guadalajara (Spain)",
  "Guadalcazar (Spain)",
  "Guadalix de la Sierra (Spain)",
  "Guadalupe (Spain)",
  "Guadamur (Spain)",
  "Guadarrama (Spain)",
  "Guadiana del Caudillo (Spain)",
  "Guadiaro (Spain)",
  "Guadix (Spain)",
  "Guamasa (Spain)",
  "Guardamar del Segura (Spain)",
  "Guardo (Spain)",
  "Guarena (Spain)",
  "Guarnizo (Spain)",
  "Guejar-Sierra (Spain)",
  "Guenes (Spain)",
  "Guevejar (Spain)",
  "Guijuelo (Spain)",
  "Guillena (Spain)",
  "Guimar (Spain)",
  "Guitiriz (Spain)",
  "Gurb (Spain)",
  "Guzman (Spain)",
  "Haro (Spain)",
  "Hellin (Spain)",
  "Henares (Spain)",
  "Herencia (Spain)",
  "Hernani (Spain)",
  "Hernansancho (Spain)",
  "Hernialde (Spain)",
  "Herrera (Spain)",
  "Herrera de Pisuerga (Spain)",
  "Herrera del Duque (Spain)",
  "Higuera la Real (Spain)",
  "Hijar (Spain)",
  "Hinojedo (Spain)",
  "Hinojos (Spain)",
  "Hinojosa del Duque (Spain)",
  "Hinojosa del Valle (Spain)",
  "Hondon de las Nieves (Spain)",
  "Hondon de los Frailes (Spain)",
  "Hontanares de Eresma (Spain)",
  "Hontoria del Pinar (Spain)",
  "Horcajo de las Torres (Spain)",
  "Horche (Spain)",
  "Hornachos (Spain)",
  "Hornachuelos (Spain)",
  "Horta de Sant Joan (Spain)",
  "Hortichuela (Spain)",
  "Hospital (Spain)",
  "Hostalric (Spain)",
  "Hoya-Gonzalo (Spain)",
  "Hoyo de Manzanares (Spain)",
  "Huarte-Araquil (Spain)",
  "Huecas (Spain)",
  "Huelma (Spain)",
  "Huelva (Spain)",
  "Huelves (Spain)",
  "Huercal de Almeria (Spain)",
  "Huercal Overa (Spain)",
  "Huercanos (Spain)",
  "Huertas (Spain)",
  "Huerto (Spain)",
  "Huesca (Spain)",
  "Huete (Spain)",
  "Huetor Santillan (Spain)",
  "Huetor Vega (Spain)",
  "Huetor-Tajar (Spain)",
  "Huevar (Spain)",
  "Humanes (Spain)",
  "Humanes de Madrid (Spain)",
  "Hurchillo (Spain)",
  "Ibarra (Spain)",
  "Ibarruri (Spain)",
  "Ibi (Spain)",
  "Ibiza (Spain)",
  "Ibros (Spain)",
  "Icod de los Vinos (Spain)",
  "Idiazabal (Spain)",
  "Igualada (Spain)",
  "Igualeja (Spain)",
  "Igueste (Spain)",
  "Illa (Spain)",
  "Illana (Spain)",
  "Illas (Spain)",
  "Illescas (Spain)",
  "Illora (Spain)",
  "Illueca (Spain)",
  "Inca (Spain)",
  "Incio (Spain)",
  "Infantes (Spain)",
  "Ingenio (Spain)",
  "Iniesta (Spain)",
  "Ipazter (Spain)",
  "Irun (Spain)",
  "Irura (Spain)",
  "Isla Becoa (Spain)",
  "Isla Cristina (Spain)",
  "Isora (Spain)",
  "Itza (Spain)",
  "Iznajar (Spain)",
  "Iznate (Spain)",
  "Jabaga (Spain)",
  "Jabali Nuevo (Spain)",
  "Jaca (Spain)",
  "Jacarilla (Spain)",
  "Jaraiz de la Vera (Spain)",
  "Javea (Spain)",
  "Jerez de la Frontera (Spain)",
  "Jerez de los Caballeros (Spain)",
  "Jerte (Spain)",
  "Jesus (Spain)",
  "Jijona (Spain)",
  "Jimena de la Frontera (Spain)",
  "Joanetes (Spain)",
  "Jodar (Spain)",
  "Jorba (Spain)",
  "Jumilla (Spain)",
  "Juneda (Spain)",
  "La Abadilla (Spain)",
  "La Adrada (Spain)",
  "La Alameda de la Sagra (Spain)",
  "La Alberca (Spain)",
  "La Algaba (Spain)",
  "La Aljorra (Spain)",
  "La Almunia de Dona Godina (Spain)",
  "La Antilla (Spain)",
  "La Arena (Spain)",
  "La Baneza (Spain)",
  "la Baronia de Rialb (Spain)",
  "La Batlloria (Spain)",
  "La Bisbal (Spain)",
  "la Bisbal del Penedes (Spain)",
  "La Cabrera (Spain)",
  "La Calzada (Spain)",
  "La Calzada de Calatrava (Spain)",
  "La Calzada de Oropesa (Spain)",
  "La Campana (Spain)",
  "La Canada (Spain)",
  "La Canada de San Urbano (Spain)",
  "La Canalosa (Spain)",
  "La Canonja (Spain)",
  "La Caridad (Spain)",
  "La Carlota (Spain)",
  "La Carolina (Spain)",
  "La Cavada (Spain)",
  "La Codosera (Spain)",
  "La Espina (Spain)",
  "la Febro (Spain)",
  "La Felguera (Spain)",
  "La Figuera (Spain)",
  "La Flecha (Spain)",
  "La Floresta Pearson (Spain)",
  "La Florida (Spain)",
  "La Fresneda (Spain)",
  "La Frontera (Spain)",
  "La Fuente de San Esteban (Spain)",
  "La Galera (Spain)",
  "La Garganta (Spain)",
  "la Garriga (Spain)",
  "La Gineta (Spain)",
  "La Granada (Spain)",
  "la Granadella (Spain)",
  "La Granja (Spain)",
  "La Granja de San Vicente (Spain)",
  "La Guancha (Spain)",
  "La Guardia (Spain)",
  "La Guardia de Jaen (Spain)",
  "La Herradura (Spain)",
  "La Higuerita (Spain)",
  "La Lantejuela (Spain)",
  "La Llacuna (Spain)",
  "La Llagosta (Spain)",
  "La Llosa (Spain)",
  "La Luisiana (Spain)",
  "La Mamola (Spain)",
  "La Mancha Blanca (Spain)",
  "La Matanza de Acentejo (Spain)",
  "La Mojonera (Spain)",
  "La Muela (Spain)",
  "La Nora (Spain)",
  "la Nou de Bergueda (Spain)",
  "la Nucia (Spain)",
  "La Oliva (Spain)",
  "La Orden (Spain)",
  "La Orotava (Spain)",
  "La Palma del Condado (Spain)",
  "La Paz (Spain)",
  "La Pedraja de Portillo (Spain)",
  "La Pedrera (Spain)",
  "La Penilla (Spain)",
  "La Perdoma (Spain)",
  "La Plana (Spain)",
  "La Playa de Arguineguin (Spain)",
  "La Pobla de Claramunt (Spain)",
  "La Pobla de Lillet (Spain)",
  "la Pobla de Mafumet (Spain)",
  "la Pobla de Montornes (Spain)",
  "La Pola de Gordon (Spain)",
  "La Puebla de Almoradiel (Spain)",
  "La Puebla de Arganzon (Spain)",
  "La Puebla de Cazalla (Spain)",
  "La Puebla de Hijar (Spain)",
  "La Puebla de los Infantes (Spain)",
  "La Puebla de Montalban (Spain)",
  "La Puebla del Rio (Spain)",
  "La Pueblanueva (Spain)",
  "La Puerta de Segura (Spain)",
  "La Rambla (Spain)",
  "La Rapita (Spain)",
  "La Raya (Spain)",
  "La Red de Valdetuejar (Spain)",
  "La Riba (Spain)",
  "la Riera de Gaia (Spain)",
  "La Rinconada (Spain)",
  "La Robla (Spain)",
  "La Roca del Valles (Spain)",
  "La Roda (Spain)",
  "La Roda de Andalucia (Spain)",
  "La Seca (Spain)",
  "La Secuita (Spain)",
  "La Selva (Spain)",
  "La Serna (Spain)",
  "La Solana (Spain)",
  "La Torre (Spain)",
  "la Vall (Spain)",
  "la Vall del Bac (Spain)",
  "La Vera (Spain)",
  "La Victoria (Spain)",
  "La Victoria de Acentejo (Spain)",
  "La Vinuela (Spain)",
  "La Virgen del Camino (Spain)",
  "La Virgen del Pilar (Spain)",
  "La Yedra (Spain)",
  "Labajos (Spain)",
  "Lagartera (Spain)",
  "Lago (Spain)",
  "Laguardia (Spain)",
  "Laguna de Duero (Spain)",
  "Lajares (Spain)",
  "Lalin (Spain)",
  "Lama (Spain)",
  "Lamadrid (Spain)",
  "Lanaja (Spain)",
  "Landa (Spain)",
  "Langa (Spain)",
  "Langa de Duero (Spain)",
  "Langreo (Spain)",
  "Lanjaron (Spain)",
  "Lantadilla (Spain)",
  "Laracha (Spain)",
  "Lardero (Spain)",
  "Laredo (Spain)",
  "Las Cabezas de San Juan (Spain)",
  "Las Galletas (Spain)",
  "Las Hortichuelas (Spain)",
  "Las Islas (Spain)",
  "Las Matas (Spain)",
  "Las Navas de la Concepcion (Spain)",
  "Las Navas del Marques (Spain)",
  "Las Palmas de Gran Canaria (Spain)",
  "Las Rozas de Madrid (Spain)",
  "Las Torres de Cotillas (Spain)",
  "Las Vegas (Spain)",
  "Las Ventanas (Spain)",
  "Las Ventas de Retamosa (Spain)",
  "Lasarte (Spain)",
  "Laudio-Llodio (Spain)",
  "Lazagurria (Spain)",
  "Lebrija (Spain)",
  "Ledana (Spain)",
  "Ledrada (Spain)",
  "Legasa (Spain)",
  "Legazpia (Spain)",
  "Legorreta (Spain)",
  "Lekeitio (Spain)",
  "Lepe (Spain)",
  "Lerin (Spain)",
  "Lerma (Spain)",
  "les Borges del Camp (Spain)",
  "Les Fonts (Spain)",
  "Lezama (Spain)",
  "Lezo (Spain)",
  "Liano (Spain)",
  "Librilla (Spain)",
  "Liencres (Spain)",
  "Lijar (Spain)",
  "Lillo (Spain)",
  "Limones (Spain)",
  "Limpias (Spain)",
  "Linares (Spain)",
  "Linyola (Spain)",
  "Lires (Spain)",
  "Llafranc (Spain)",
  "Llagostera (Spain)",
  "Llanera (Spain)",
  "Llanes (Spain)",
  "Llano (Spain)",
  "Lledo (Spain)",
  "Lleida (Spain)",
  "Llerena (Spain)",
  "Llers (Spain)",
  "Llinars del Valles (Spain)",
  "Lliria (Spain)",
  "Llofriu (Spain)",
  "Lloret de Mar (Spain)",
  "Llosa de Ranes (Spain)",
  "Lobios (Spain)",
  "Lodosa (Spain)",
  "Loeches (Spain)",
  "Loja (Spain)",
  "Lomo de Arico (Spain)",
  "Lopera (Spain)",
  "Lorca (Spain)",
  "Loriguilla (Spain)",
  "Lorqui (Spain)",
  "Los Alcazares (Spain)",
  "Los Angeles (Spain)",
  "Los Barrios (Spain)",
  "Los Corrales (Spain)",
  "Los Corrales de Buelna (Spain)",
  "Los Cristianos (Spain)",
  "Los Dolores (Spain)",
  "Los Gallardos (Spain)",
  "Los Garres (Spain)",
  "Los Hueros (Spain)",
  "Los Llanos de Aridane (Spain)",
  "Los Marines (Spain)",
  "Los Molinos (Spain)",
  "Los Montes (Spain)",
  "Los Narejos (Spain)",
  "Los Navalmorales (Spain)",
  "Los Navalucillos (Spain)",
  "Los Palacios (Spain)",
  "Los Palacios y Villafranca (Spain)",
  "Los Rios (Spain)",
  "Los Rosales (Spain)",
  "Los Santos de la Humosa (Spain)",
  "Los Silos (Spain)",
  "Los Villares (Spain)",
  "Los Yebenes (Spain)",
  "Losar de la Vera (Spain)",
  "Lousame (Spain)",
  "Luanco (Spain)",
  "Luarca (Spain)",
  "Lucena (Spain)",
  "Lucena del Cid (Spain)",
  "Lucena del Puerto (Spain)",
  "Luceni (Spain)",
  "Lucillos (Spain)",
  "Lugo (Spain)",
  "Lugones (Spain)",
  "Luou (Spain)",
  "Luyando (Spain)",
  "Luzaide (Spain)",
  "Luzmela (Spain)",
  "Macael (Spain)",
  "Macanet de la Selva (Spain)",
  "Maceira (Spain)",
  "Madrid (Spain)",
  "Madridanos (Spain)",
  "Madridejos (Spain)",
  "Madrigal de la Vera (Spain)",
  "Madrigalejo (Spain)",
  "Madrigueras (Spain)",
  "Madronera (Spain)",
  "Magan (Spain)",
  "Mahon (Spain)",
  "Mairena del Alcor (Spain)",
  "Mairena del Aljarafe (Spain)",
  "Majadahonda (Spain)",
  "Malagon (Spain)",
  "Malgrat de Mar (Spain)",
  "Maliano (Spain)",
  "Mallen (Spain)",
  "Malpartida de Caceres (Spain)",
  "Malpartida de Plasencia (Spain)",
  "Malpica (Spain)",
  "Manacor (Spain)",
  "Mancha Real (Spain)",
  "Manilva (Spain)",
  "Manises (Spain)",
  "Manlleu (Spain)",
  "Manresa (Spain)",
  "Manuel (Spain)",
  "Manzanares (Spain)",
  "Manzanares el Real (Spain)",
  "Maracena (Spain)",
  "Marbella (Spain)",
  "Marchamalo (Spain)",
  "Marchena (Spain)",
  "Marcilla (Spain)",
  "Margarida (Spain)",
  "Maria de Huerva (Spain)",
  "Maria de la Salut (Spain)",
  "Marin (Spain)",
  "Mariola (Spain)",
  "Marmolejo (Spain)",
  "Martin (Spain)",
  "Martinet (Spain)",
  "Martorell (Spain)",
  "Martos (Spain)",
  "Marugan (Spain)",
  "Marzagan (Spain)",
  "Masdenverge (Spain)",
  "Maside (Spain)",
  "Masllorenc (Spain)",
  "Maspalomas (Spain)",
  "Masquefa (Spain)",
  "Massamagrell (Spain)",
  "Mata (Spain)",
  "Matadepera (Spain)",
  "Mataelpino (Spain)",
  "Mataluenga (Spain)",
  "Matamorosa (Spain)",
  "Matapozuelos (Spain)",
  "Mayor (Spain)",
  "Mazagon (Spain)",
  "Meano (Spain)",
  "Mecina Fondales (Spain)",
  "Meco (Spain)",
  "Medina de Pomar (Spain)",
  "Medina del Campo (Spain)",
  "Medina-Sidonia (Spain)",
  "Medinya (Spain)",
  "Medio (Spain)",
  "Mediona (Spain)",
  "Medrano (Spain)",
  "Meira (Spain)",
  "Meis (Spain)",
  "Mejorada (Spain)",
  "Mejorada del Campo (Spain)",
  "Meliana (Spain)",
  "Melilla (Spain)",
  "Membrilla (Spain)",
  "Mendaro (Spain)",
  "Mengibar (Spain)",
  "Mentrida (Spain)",
  "Mequinensa / Mequinenza (Spain)",
  "Mercadal (Spain)",
  "Mestanza (Spain)",
  "Mezalocha (Spain)",
  "Miajadas (Spain)",
  "Mieres (Spain)",
  "Miguel Esteban (Spain)",
  "Miguelturra (Spain)",
  "Mijas (Spain)",
  "Milagro (Spain)",
  "Millares (Spain)",
  "Minas de Riotinto (Spain)",
  "Ministerio (Spain)",
  "Mino (Spain)",
  "Miono (Spain)",
  "Mira (Spain)",
  "Mirador (Spain)",
  "Miraflores de la Sierra (Spain)",
  "Miramar (Spain)",
  "Miranda de Arga (Spain)",
  "Miranda de Ebro (Spain)",
  "Miranda del Castanar (Spain)",
  "Mislata (Spain)",
  "Moana (Spain)",
  "Mocejon (Spain)",
  "Mogente (Spain)",
  "Moguer (Spain)",
  "Moia (Spain)",
  "Moja (Spain)",
  "Mojacar Pueblo (Spain)",
  "Mojados (Spain)",
  "Molina de Aragon (Spain)",
  "Molina de Segura (Spain)",
  "Molinos de Duero (Spain)",
  "Molins de Rei (Spain)",
  "Mollina (Spain)",
  "Mollo (Spain)",
  "Mombeltran (Spain)",
  "Monachil (Spain)",
  "Moncada (Spain)",
  "Moncofar (Spain)",
  "Monda (Spain)",
  "Mondariz (Spain)",
  "Mondonedo (Spain)",
  "Mondujar (Spain)",
  "Monesterio (Spain)",
  "Monforte de Lemos (Spain)",
  "Monforte del Cid (Spain)",
  "Monistrol de Calders (Spain)",
  "Monserrat (Spain)",
  "Mont (Spain)",
  "Montagut (Spain)",
  "Montalban de Cordoba (Spain)",
  "Montcada i Reixac (Spain)",
  "Monte (Spain)",
  "Monteagudo (Spain)",
  "Montealegre del Castillo (Spain)",
  "Montearagon (Spain)",
  "Montefrio (Spain)",
  "Montehermoso (Spain)",
  "Montejicar (Spain)",
  "Montellano (Spain)",
  "Montemayor de Pililla (Spain)",
  "Monterroso (Spain)",
  "Monterrubio de la Serena (Spain)",
  "Montesa (Spain)",
  "Montesquiu (Spain)",
  "Montfulla (Spain)",
  "Montgat (Spain)",
  "Montijo (Spain)",
  "Montilla (Spain)",
  "Montillana (Spain)",
  "Montmajor (Spain)",
  "Montroy (Spain)",
  "Montseny (Spain)",
  "Monzalbarba (Spain)",
  "Monzon (Spain)",
  "Mora (Spain)",
  "Mora de Rubielos (Spain)",
  "Moraleja de Enmedio (Spain)",
  "Moralzarzal (Spain)",
  "Morata de Jalon (Spain)",
  "Morata de Tajuna (Spain)",
  "Moratalla (Spain)",
  "Moreda (Spain)",
  "Morella (Spain)",
  "Moriscos (Spain)",
  "Morro del Jable (Spain)",
  "Mortera (Spain)",
  "Moscas del Paramo (Spain)",
  "Mosqueruela (Spain)",
  "Mostoles (Spain)",
  "Mota del Marques (Spain)",
  "Motilla del Palancar (Spain)",
  "Motril (Spain)",
  "Moya (Spain)",
  "Muchamiel (Spain)",
  "Mugardos (Spain)",
  "Mula (Spain)",
  "Mungia (Spain)",
  "Mura (Spain)",
  "Murcia (Spain)",
  "Muriedas (Spain)",
  "Muro (Spain)",
  "Muro del Alcoy (Spain)",
  "Muros (Spain)",
  "Muros de Nalon (Spain)",
  "Museros (Spain)",
  "Mutiloa (Spain)",
  "Mutilva Baja (Spain)",
  "Mutriku (Spain)",
  "Nalda (Spain)",
  "Nambroca (Spain)",
  "Nanclares de la Oca (Spain)",
  "Naquera (Spain)",
  "Nava de la Asuncion (Spain)",
  "Navacerrada (Spain)",
  "Navaconcejo (Spain)",
  "Navahermosa (Spain)",
  "Navalcan (Spain)",
  "Navalcarnero (Spain)",
  "Navalmoral de la Mata (Spain)",
  "Navalperal de Pinares (Spain)",
  "Navamorcuende (Spain)",
  "Navarcles (Spain)",
  "Navaridas (Spain)",
  "Navarres (Spain)",
  "Navarrete (Spain)",
  "Navas de Riofrio (Spain)",
  "Navas del Rey (Spain)",
  "Navata (Spain)",
  "Navatejera (Spain)",
  "Navezuelas (Spain)",
  "Navia (Spain)",
  "Neda (Spain)",
  "Negreira (Spain)",
  "Nembro (Spain)",
  "Nerja (Spain)",
  "Nerva (Spain)",
  "Nestares (Spain)",
  "Nigran (Spain)",
  "Nijar (Spain)",
  "Nino Perdido (Spain)",
  "Nivar (Spain)",
  "Noain (Spain)",
  "Noja (Spain)",
  "Nora (Spain)",
  "Norena (Spain)",
  "Novallas (Spain)",
  "Novelda (Spain)",
  "Noves (Spain)",
  "Nubledo (Spain)",
  "Nueva (Spain)",
  "Nuevo Baztan (Spain)",
  "Nuez de Ebro (Spain)",
  "Nules (Spain)",
  "Numancia de la Sagra (Spain)",
  "O Barco de Valdeorras (Spain)",
  "O Carballino (Spain)",
  "O Grove (Spain)",
  "O Mazo (Spain)",
  "Oasis (La Marina) (Spain)",
  "Obanos (Spain)",
  "Ocana (Spain)",
  "Odena (Spain)",
  "Odon (Spain)",
  "Ogijares (Spain)",
  "Ojen (Spain)",
  "Olaberria (Spain)",
  "Olazagutia (Spain)",
  "Oleiros (Spain)",
  "Olesa de Bonesvalls (Spain)",
  "Olesa de Montserrat (Spain)",
  "Oliana (Spain)",
  "Olias del Rey (Spain)",
  "Olite (Spain)",
  "Oliva (Spain)",
  "Oliva de Merida (Spain)",
  "Oliva de Plasencia (Spain)",
  "Olivares (Spain)",
  "Olivella (Spain)",
  "Olivenza (Spain)",
  "Olmedo (Spain)",
  "Olocau (Spain)",
  "Olot (Spain)",
  "Olula de Castro (Spain)",
  "Olula del Rio (Spain)",
  "Olvega (Spain)",
  "Olvera (Spain)",
  "Onda (Spain)",
  "Ondara (Spain)",
  "Ondarroa (Spain)",
  "Onil (Spain)",
  "Ontigola (Spain)",
  "Ontinena (Spain)",
  "Ontinyent (Spain)",
  "Ontur (Spain)",
  "Orce (Spain)",
  "Orcoyen (Spain)",
  "Ordes (Spain)",
  "Ordis (Spain)",
  "Orellana la Vieja (Spain)",
  "Oria (Spain)",
  "Orial (Spain)",
  "Orihuela (Spain)",
  "Orio (Spain)",
  "Oroso (Spain)",
  "Orpesa/Oropesa del Mar (Spain)",
  "Ortiguero (Spain)",
  "Oruna (Spain)",
  "Orusco (Spain)",
  "Osuna (Spain)",
  "Otero (Spain)",
  "Otura (Spain)",
  "Ouces (Spain)",
  "Ourense (Spain)",
  "Outes (Spain)",
  "Oviedo (Spain)",
  "Oyon (Spain)",
  "Oza de los Rios (Spain)",
  "Padilla del Ducado (Spain)",
  "Padul (Spain)",
  "Paiporta (Spain)",
  "Pajara (Spain)",
  "Palafolls (Spain)",
  "Palafrugell (Spain)",
  "Palau (Spain)",
  "Palencia (Spain)",
  "Palma de Mallorca (Spain)",
  "Palma del Rio (Spain)",
  "Palmeira (Spain)",
  "Palmera (Spain)",
  "Palmones (Spain)",
  "Palol (Spain)",
  "Palomares del Rio (Spain)",
  "Palos de la Frontera (Spain)",
  "Pals (Spain)",
  "Pamplona (Spain)",
  "Panillo (Spain)",
  "Panticosa (Spain)",
  "Pantoja (Spain)",
  "Para (Spain)",
  "Paracuellos (Spain)",
  "Paracuellos de Jarama (Spain)",
  "Parada (Spain)",
  "Paradas (Spain)",
  "Paradela (Spain)",
  "Parbayon (Spain)",
  "Parcent (Spain)",
  "Paredes de Nava (Spain)",
  "Parla (Spain)",
  "Parres (Spain)",
  "Pasai San Pedro (Spain)",
  "Pasaia (Spain)",
  "Pastrana (Spain)",
  "Paterna (Spain)",
  "Paterna del Campo (Spain)",
  "Pau (Spain)",
  "Pauels (Spain)",
  "Pedrajas (Spain)",
  "Pedrajas de San Esteban (Spain)",
  "Pedralba (Spain)",
  "Pedreguer (Spain)",
  "Pedrezuela (Spain)",
  "Pedro (Spain)",
  "Pedro Abad (Spain)",
  "Pedro Munoz (Spain)",
  "Pedrola (Spain)",
  "Pedrosillo de Alba (Spain)",
  "Pedrosillo el Ralo (Spain)",
  "Pego (Spain)",
  "Pelayos de la Presa (Spain)",
  "Peligros (Spain)",
  "Penafiel (Spain)",
  "Penaflor (Spain)",
  "Penagos (Spain)",
  "Penarroya-Pueblonuevo (Spain)",
  "Perafita (Spain)",
  "Perafort (Spain)",
  "Peralejo (Spain)",
  "Perales del Alfambra (Spain)",
  "Perales del Puerto (Spain)",
  "Peratallada (Spain)",
  "Perdiguera (Spain)",
  "Pereiro de Aguiar (Spain)",
  "Periedo (Spain)",
  "Perillo (Spain)",
  "Pescador (Spain)",
  "Pescueza (Spain)",
  "Petrel (Spain)",
  "Petres (Spain)",
  "Pezuela de las Torres (Spain)",
  "Piedra (Spain)",
  "Piedrabuena (Spain)",
  "Piera (Spain)",
  "Pilar de la Horadada (Spain)",
  "Pilas (Spain)",
  "Pina de Ebro (Spain)",
  "Pinar (Spain)",
  "Pineda de Mar (Spain)",
  "Pinos del Valle (Spain)",
  "Pinos Puente (Spain)",
  "Pinoso (Spain)",
  "Pinseque (Spain)",
  "Pinto (Spain)",
  "Pioz (Spain)",
  "Placencia (Spain)",
  "Plan (Spain)",
  "Planes (Spain)",
  "Plasencia (Spain)",
  "Plasenzuela (Spain)",
  "Playa (Spain)",
  "Playa Blanca (Spain)",
  "Plegamans (Spain)",
  "Plentzia (Spain)",
  "Poblete (Spain)",
  "Poboa de San Xulian (Spain)",
  "Poboleda (Spain)",
  "Pola de Laviana (Spain)",
  "Pola de Lena (Spain)",
  "Pola de Siero (Spain)",
  "Polan (Spain)",
  "Polinya (Spain)",
  "Polop (Spain)",
  "Pomar (Spain)",
  "Pomar de Cinca (Spain)",
  "Ponferrada (Spain)",
  "Pont de Molins (Spain)",
  "Pontejos (Spain)",
  "Pontevedra (Spain)",
  "Pontos (Spain)",
  "Ponts (Spain)",
  "Poris de Abona (Spain)",
  "Porrino (Spain)",
  "Port de Sagunt (Spain)",
  "Portillo (Spain)",
  "Portillo de Toledo (Spain)",
  "Porto (Spain)",
  "Porto Cristo (Spain)",
  "Portocolom (Spain)",
  "Portonovo (Spain)",
  "Portugalete (Spain)",
  "Portus (Spain)",
  "Posadas (Spain)",
  "Pozo de Guadalajara (Spain)",
  "Pozo de la Serna (Spain)",
  "Pozoamargo (Spain)",
  "Pozoblanco (Spain)",
  "Pozuelo del Rey (Spain)",
  "Pradejon (Spain)",
  "Prado del Rey (Spain)",
  "Pravia (Spain)",
  "Premia de Mar (Spain)",
  "Priego (Spain)",
  "Priego de Cordoba (Spain)",
  "Pruna (Spain)",
  "Pruvia (Spain)",
  "Pucol (Spain)",
  "Puebla de Alfinden (Spain)",
  "Puebla de Don Fadrique (Spain)",
  "Puebla de la Calzada (Spain)",
  "Puebla del Maestre (Spain)",
  "Puente Viesgo (Spain)",
  "Puente-Genil (Spain)",
  "Puentelarra (Spain)",
  "Puentenansa (Spain)",
  "Puerto (Spain)",
  "Puerto de Gandia (Spain)",
  "Puerto de la Cruz (Spain)",
  "Puerto del Carmen (Spain)",
  "Puerto del Rosario (Spain)",
  "Puerto Lumbreras (Spain)",
  "Puerto Real (Spain)",
  "Puertollano (Spain)",
  "Pueyo (Spain)",
  "Pueyo de Santa Cruz (Spain)",
  "Puig (Spain)",
  "Puigpelat (Spain)",
  "Pulgar (Spain)",
  "Pulianas (Spain)",
  "Purchil (Spain)",
  "Quart de Poblet (Spain)",
  "Quel (Spain)",
  "Quer (Spain)",
  "Querol (Spain)",
  "Quijorna (Spain)",
  "Quincoces de Yuso (Spain)",
  "Quintana de la Serena (Spain)",
  "Quintana de Raneros (Spain)",
  "Quintanar de la Orden (Spain)",
  "Quintanar del Rey (Spain)",
  "Quintes (Spain)",
  "Rafal (Spain)",
  "Rafelbunyol (Spain)",
  "Rafelcofer (Spain)",
  "Rafelguaraf (Spain)",
  "Rairiz de Veiga (Spain)",
  "Rajadell (Spain)",
  "Ramales de la Victoria (Spain)",
  "Rasines (Spain)",
  "Real (Spain)",
  "Real de Gandia (Spain)",
  "Rebolledo (Spain)",
  "Rebordanes (Spain)",
  "Recas (Spain)",
  "Redes (Spain)",
  "Redondela (Spain)",
  "Redovan (Spain)",
  "Reinosa (Spain)",
  "Rendar (Spain)",
  "Renedo (Spain)",
  "Reocin (Spain)",
  "Requena (Spain)",
  "Reus (Spain)",
  "Revellinos (Spain)",
  "Reyes (Spain)",
  "Rianxo (Spain)",
  "Riaza (Spain)",
  "Riba (Spain)",
  "Ribadavia (Spain)",
  "Ribadeo (Spain)",
  "Ribadesella (Spain)",
  "Ribarroja (Spain)",
  "Ribeira (Spain)",
  "Ribera del Fresno (Spain)",
  "Ribes de Freser (Spain)",
  "Ricla (Spain)",
  "Riells i Viabrea (Spain)",
  "Rielves (Spain)",
  "Rincon (Spain)",
  "Rincon de la Victoria (Spain)",
  "Rincon de Soto (Spain)",
  "Rioja (Spain)",
  "Riola (Spain)",
  "Rios (Spain)",
  "Ripoll (Spain)",
  "Ripollet (Spain)",
  "Riudellots de la Selva (Spain)",
  "Riudoms (Spain)",
  "Riumors (Spain)",
  "Rivabellosa (Spain)",
  "Roales (Spain)",
  "Robleda-Cervantes (Spain)",
  "Robledillo de Gata (Spain)",
  "Robledillo de la Vera (Spain)",
  "Robledo de Chavela (Spain)",
  "Rocafort (Spain)",
  "Roda de Bara (Spain)",
  "Rodonya (Spain)",
  "Rojales (Spain)",
  "Roldan (Spain)",
  "Ron (Spain)",
  "Ronda (Spain)",
  "Roquetas de Mar (Spain)",
  "Rosell (Spain)",
  "Roses (Spain)",
  "Rota (Spain)",
  "Rubielos de Mora (Spain)",
  "Ruente (Spain)",
  "Rupit (Spain)",
  "Rus (Spain)",
  "Rute (Spain)",
  "Saamasas (Spain)",
  "Sabadell (Spain)",
  "Sabero (Spain)",
  "Sabinanigo (Spain)",
  "Sabiote (Spain)",
  "Sabucedo (Spain)",
  "Sada (Spain)",
  "Sagra (Spain)",
  "Sagunto (Spain)",
  "Salamanca (Spain)",
  "Salas de los Infantes (Spain)",
  "Salceda (Spain)",
  "Salcedo (Spain)",
  "Saldana (Spain)",
  "Salinas (Spain)",
  "Sallent (Spain)",
  "Salobral (Spain)",
  "Salobre (Spain)",
  "Salou (Spain)",
  "Salt (Spain)",
  "Salteras (Spain)",
  "Salvatierra (Spain)",
  "Salvatierra de los Barros (Spain)",
  "Salvatierra de Mino (Spain)",
  "San Adrian (Spain)",
  "San Agustin de Guadalix (Spain)",
  "San Andres del Rabanedo (Spain)",
  "San Anton (Spain)",
  "San Antonio (Spain)",
  "San Blas (Spain)",
  "San Clemente (Spain)",
  "San Cristobal (Spain)",
  "San Cristobal de Entrevinas (Spain)",
  "San Esteban del Valle (Spain)",
  "San Felices (Spain)",
  "San Feliz de Torio (Spain)",
  "San Fernando (Spain)",
  "San Fernando de Henares (Spain)",
  "San Ildefonso (Spain)",
  "San Isidro (Spain)",
  "San Javier (Spain)",
  "San Jorge (Spain)",
  "San Juan (Spain)",
  "San Juan de Alicante (Spain)",
  "San Juan de Aznalfarache (Spain)",
  "San Juan de Moro (Spain)",
  "San Juan del Puerto (Spain)",
  "San Julian de Muskiz (Spain)",
  "San Luis de Sabinillas (Spain)",
  "San Martin (Spain)",
  "San Martin de la Vega (Spain)",
  "San Martin de Unx (Spain)",
  "San Martin de Valdeiglesias (Spain)",
  "San Martino (Spain)",
  "San Miguel (Spain)",
  "San Miguel de las Duenas (Spain)",
  "San Nicolas (Spain)",
  "San Nicolas del Puerto (Spain)",
  "San Pedro (Spain)",
  "San Pedro del Pinatar (Spain)",
  "San Rafael (Spain)",
  "San Roman (Spain)",
  "San Roque (Spain)",
  "San Sebastian (Spain)",
  "San Sebastian de los Ballesteros (Spain)",
  "San Vicent del Raspeig (Spain)",
  "San Vicente (Spain)",
  "San Vicente de Alcantara (Spain)",
  "San Vicente de Leon (Spain)",
  "Sancibrian (Spain)",
  "Sancti Spiritus (Spain)",
  "Sangonera la Verde (Spain)",
  "Sanlucar la Mayor (Spain)",
  "Sant Andreu de la Barca (Spain)",
  "Sant Andreu de Llavaneres (Spain)",
  "Sant Bartomeu del Grau (Spain)",
  "Sant Carles de la Rapita (Spain)",
  "Sant Feliu de Llobregat (Spain)",
  "Sant Joan de les Abadesses (Spain)",
  "Sant Joan de Vilatorrada (Spain)",
  "Sant Joan les Fonts (Spain)",
  "Sant Jordi (Spain)",
  "Sant Josep de sa Talaia (Spain)",
  "Sant Just Desvern (Spain)",
  "Sant Marti de Malda (Spain)",
  "Sant Pere de Ribes (Spain)",
  "Sant Pere de Riudebitlles (Spain)",
  "Sant Pere, Santa Caterina i La Ribera (Spain)",
  "Sant Vicenc de Castellet (Spain)",
  "Santa Barbara (Spain)",
  "Santa Brigida (Spain)",
  "Santa Coloma de Farners (Spain)",
  "Santa Coloma de Gramenet (Spain)",
  "Santa Coloma de Queralt (Spain)",
  "Santa Cristina (Spain)",
  "Santa Cristina de Aro (Spain)",
  "Santa Cruz (Spain)",
  "Santa Cruz de Bezana (Spain)",
  "Santa Cruz de La Palma (Spain)",
  "Santa Cruz de la Zarza (Spain)",
  "Santa Cruz de Mudela (Spain)",
  "Santa Cruz de Tenerife (Spain)",
  "Santa Eulalia (Spain)",
  "Santa Eulalia Bajera (Spain)",
  "Santa Fe (Spain)",
  "Santa Fe de Mondujar (Spain)",
  "Santa Lucia (Spain)",
  "Santa Margarida (Spain)",
  "Santa Maria (Spain)",
  "Santa Maria de Palautordera (Spain)",
  "Santa Maria del Cami (Spain)",
  "Santa Maria del Campo (Spain)",
  "Santa Maria del Paramo (Spain)",
  "Santa Marina del Rey (Spain)",
  "Santa Marta de Ortigueira (Spain)",
  "Santa Marta de Tormes (Spain)",
  "Santa Olalla (Spain)",
  "Santa Oliva (Spain)",
  "Santa Pau (Spain)",
  "Santa Perpetua de Mogoda (Spain)",
  "Santa Pola (Spain)",
  "Santa Uxia de Ribeira (Spain)",
  "Santalla (Spain)",
  "Santander (Spain)",
  "Santiago (Spain)",
  "Santiago de Cartes (Spain)",
  "Santiago de Compostela (Spain)",
  "Santiago de la Ribera (Spain)",
  "Santiago Pena (Spain)",
  "Santiponce (Spain)",
  "Santisteban del Puerto (Spain)",
  "Santiurde de Toranzo (Spain)",
  "Santo Angel (Spain)",
  "Santo Domingo (Spain)",
  "Santo Domingo de la Calzada (Spain)",
  "Santomera (Spain)",
  "Santona (Spain)",
  "Santovenia de Pisuerga (Spain)",
  "Santpedor (Spain)",
  "Santurtzi (Spain)",
  "Sarandones (Spain)",
  "Sardina (Spain)",
  "Sarinena (Spain)",
  "Saron (Spain)",
  "Sarria (Spain)",
  "Sarria de Ter (Spain)",
  "Sarroca de Lleida (Spain)",
  "Sax (Spain)",
  "Sayalonga (Spain)",
  "Sedavi (Spain)",
  "Segorbe (Spain)",
  "Segovia (Spain)",
  "Segura (Spain)",
  "Segura de la Sierra (Spain)",
  "Selaya (Spain)",
  "Selva (Spain)",
  "Senija (Spain)",
  "Seoane (Spain)",
  "Sepulveda (Spain)",
  "Serena (Spain)",
  "Serra (Spain)",
  "Serracines (Spain)",
  "Serranillos del Valle (Spain)",
  "Serrateix (Spain)",
  "ses Salines (Spain)",
  "Sesena (Spain)",
  "Sesena Nuevo (Spain)",
  "Sestao (Spain)",
  "Seva (Spain)",
  "Sevares (Spain)",
  "Sevilla La Nueva (Spain)",
  "Seville (Spain)",
  "Sierra (Spain)",
  "Sierra de Fuentes (Spain)",
  "Sierra de Luna (Spain)",
  "Siete Iglesias de Trabancos (Spain)",
  "Sigueiro (Spain)",
  "Siguenza (Spain)",
  "Silla (Spain)",
  "Silleda (Spain)",
  "Sils (Spain)",
  "Simancas (Spain)",
  "Sitges (Spain)",
  "Sixto (Spain)",
  "Sobarzo (Spain)",
  "Sobradiel (Spain)",
  "Sobremazas (Spain)",
  "Socuellamos (Spain)",
  "Sodupe (Spain)",
  "Sojuela (Spain)",
  "Solares (Spain)",
  "Sollana (Spain)",
  "Soller (Spain)",
  "Solsona (Spain)",
  "Somahoz (Spain)",
  "Somo (Spain)",
  "Son Curt (Spain)",
  "Son Servera (Spain)",
  "Soneja (Spain)",
  "Sopela (Spain)",
  "Sorbas (Spain)",
  "Soria (Spain)",
  "Sotillo de la Adrada (Spain)",
  "Soto (Spain)",
  "Soto de Cerrato (Spain)",
  "Soto de la Marina (Spain)",
  "Sotogrande (Spain)",
  "Sotopalacios (Spain)",
  "Sotrondio (Spain)",
  "Suances (Spain)",
  "Subirats (Spain)",
  "Sudanell (Spain)",
  "Sueca (Spain)",
  "Sufli (Spain)",
  "Tabernas (Spain)",
  "Taboada (Spain)",
  "Tacoronte (Spain)",
  "Tafalla (Spain)",
  "Tahiche (Spain)",
  "Talamanca de Jarama (Spain)",
  "Talarn (Spain)",
  "Talavera de la Reina (Spain)",
  "Tamames (Spain)",
  "Tamaraceite (Spain)",
  "Taradell (Spain)",
  "Tarancon (Spain)",
  "Tarazona (Spain)",
  "Tarazona de la Mancha (Spain)",
  "Tarifa (Spain)",
  "Tarragona (Spain)",
  "Tarrega (Spain)",
  "Teba (Spain)",
  "Tegueste (Spain)",
  "Teguise (Spain)",
  "Tejina (Spain)",
  "Telde (Spain)",
  "Tenteniguada (Spain)",
  "Teo (Spain)",
  "Termino (Spain)",
  "Teror (Spain)",
  "Terradillos (Spain)",
  "Terrassa (Spain)",
  "Terrer (Spain)",
  "Teruel (Spain)",
  "Teulada (Spain)",
  "Tiagua (Spain)",
  "Tiana (Spain)",
  "Tias (Spain)",
  "Tigaiga (Spain)",
  "Tijarafe (Spain)",
  "Tijola (Spain)",
  "Tineo (Spain)",
  "Titulcia (Spain)",
  "Tobarra (Spain)",
  "Toledo (Spain)",
  "Tolosa (Spain)",
  "Tomares (Spain)",
  "Tombrio de Arriba (Spain)",
  "Tomelloso (Spain)",
  "Tomino (Spain)",
  "Tona (Spain)",
  "Tora de Riubregos (Spain)",
  "Toral de los Vados (Spain)",
  "Tordera (Spain)",
  "Tordesillas (Spain)",
  "Tordillos (Spain)",
  "Toreno (Spain)",
  "Torija (Spain)",
  "Tormantos (Spain)",
  "Toro (Spain)",
  "Torralba de Calatrava (Spain)",
  "Torralba de Oropesa (Spain)",
  "Torre del Campo (Spain)",
  "Torre del Mar (Spain)",
  "Torre-Pacheco (Spain)",
  "Torreaguera (Spain)",
  "Torreblascopedro (Spain)",
  "Torrecaballeros (Spain)",
  "Torrecilla de Alcaniz (Spain)",
  "Torredembarra (Spain)",
  "Torredonjimeno (Spain)",
  "Torrefarrera (Spain)",
  "Torrefeta (Spain)",
  "Torrejon de la Calzada (Spain)",
  "Torrejon del Rey (Spain)",
  "Torrelaguna (Spain)",
  "Torrelavega (Spain)",
  "Torrelles de Llobregat (Spain)",
  "Torrelletas (Spain)",
  "Torrelodones (Spain)",
  "Torremenga (Spain)",
  "Torremocha del Campo (Spain)",
  "Torremolinos (Spain)",
  "Torrent (Spain)",
  "Torrenueva (Spain)",
  "Torreperogil (Spain)",
  "Torres (Spain)",
  "Torres de Berrellen (Spain)",
  "Torres de la Alameda (Spain)",
  "Torres de Segre (Spain)",
  "Torresandino (Spain)",
  "Torrevieja (Spain)",
  "Torrijos (Spain)",
  "Torroella de Fluvia (Spain)",
  "Torroella de Montgri (Spain)",
  "Torrox (Spain)",
  "Tortella (Spain)",
  "Tortola de Henares (Spain)",
  "Tortosa (Spain)",
  "Totana (Spain)",
  "Touro (Spain)",
  "Tous (Spain)",
  "Trabada (Spain)",
  "Traiguera (Spain)",
  "Traslavina (Spain)",
  "Trasona (Spain)",
  "Trazo (Spain)",
  "Trebujena (Spain)",
  "Tremp (Spain)",
  "Trespaderne (Spain)",
  "Trevino (Spain)",
  "Triana (Spain)",
  "Trillo (Spain)",
  "Trives (Spain)",
  "Trobajo del Camino (Spain)",
  "Trujillanos (Spain)",
  "Trujillo (Spain)",
  "Tudela (Spain)",
  "Tudela de Duero (Spain)",
  "Tuineje (Spain)",
  "Turia (Spain)",
  "Turis (Spain)",
  "Ubrique (Spain)",
  "Uceda (Spain)",
  "Ugena (Spain)",
  "Ugijar (Spain)",
  "Ujo (Spain)",
  "Ullastrell (Spain)",
  "Umbrete (Spain)",
  "Urda (Spain)",
  "Urdiain (Spain)",
  "Urduna (Spain)",
  "Urnieta (Spain)",
  "Urroz (Spain)",
  "Urtuella (Spain)",
  "Usansolo (Spain)",
  "Useras (Spain)",
  "Usurbil (Spain)",
  "Utebo (Spain)",
  "Utiel (Spain)",
  "Utrera (Spain)",
  "Vaciamadrid (Spain)",
  "Val de Santo Domingo (Spain)",
  "Valcabado (Spain)",
  "Valdeavero (Spain)",
  "Valdeaveruelo (Spain)",
  "Valdecabras (Spain)",
  "Valdecilla (Spain)",
  "Valdemaqueda (Spain)",
  "Valdemorillo (Spain)",
  "Valdemoro (Spain)",
  "Valdepenas (Spain)",
  "Valdepenas de la Sierra (Spain)",
  "Valdepolo (Spain)",
  "Valderrey (Spain)",
  "Valderrobres (Spain)",
  "Valderrubio (Spain)",
  "Valdesogo de Abajo (Spain)",
  "Valdetorres (Spain)",
  "Valdetorres de Jarama (Spain)",
  "Valdezufre (Spain)",
  "Valdilecha (Spain)",
  "Valencia (Spain)",
  "Valencia de Don Juan (Spain)",
  "Valencina de la Concepcion (Spain)",
  "Vall de Almonacid (Spain)",
  "Vallada (Spain)",
  "Valladolid (Spain)",
  "Valladolises (Spain)",
  "Valldoreix (Spain)",
  "Valle (Spain)",
  "Valle de Oro (Spain)",
  "Valles (Spain)",
  "Valleseco (Spain)",
  "Vallgorguina (Spain)",
  "Vallirana (Spain)",
  "Vallmoll (Spain)",
  "Valls (Spain)",
  "Valmojado (Spain)",
  "Valpalmas (Spain)",
  "Valsequillo de Gran Canaria (Spain)",
  "Valverde (Spain)",
  "Valverde del Camino (Spain)",
  "Valverde del Fresno (Spain)",
  "Valverde del Majano (Spain)",
  "Vedra (Spain)",
  "Vejer de la Frontera (Spain)",
  "Velez de Benaudalla (Spain)",
  "Velez-Rubio (Spain)",
  "Velilla (Spain)",
  "Velilla de San Antonio (Spain)",
  "Venta (Spain)",
  "Venta de Banos (Spain)",
  "Ventallo (Spain)",
  "Venturada (Spain)",
  "Vera (Spain)",
  "Verdu (Spain)",
  "Vergel (Spain)",
  "Verges (Spain)",
  "Verin (Spain)",
  "Viana (Spain)",
  "Viator (Spain)",
  "Vic (Spain)",
  "Vicar (Spain)",
  "Vicolozano (Spain)",
  "Vigo (Spain)",
  "Vila (Spain)",
  "Vila-real (Spain)",
  "Vila-seca (Spain)",
  "Vilabertran (Spain)",
  "Vilacolum (Spain)",
  "Viladecans (Spain)",
  "Viladrau (Spain)",
  "Vilafant (Spain)",
  "Vilagarcia de Arousa (Spain)",
  "Vilajuiga (Spain)",
  "Vilamalla (Spain)",
  "Vilamaniscle (Spain)",
  "Vilanova (Spain)",
  "Vilanova de Arousa (Spain)",
  "Vilaplana (Spain)",
  "Vilasantar (Spain)",
  "Vilassar de Mar (Spain)",
  "Vilatenim (Spain)",
  "Vilavella (Spain)",
  "Vilches (Spain)",
  "Villa (Spain)",
  "Villa de Don Fadrique (Spain)",
  "Villa del Prado (Spain)",
  "Villa del Rio (Spain)",
  "Villabalter (Spain)",
  "Villabanez (Spain)",
  "Villablino (Spain)",
  "Villabona (Spain)",
  "Villacanas (Spain)",
  "Villacarriedo (Spain)",
  "Villacarrillo (Spain)",
  "Villacastin (Spain)",
  "Villacondide (Spain)",
  "Villaconejos (Spain)",
  "Villacuende (Spain)",
  "Villafranca (Spain)",
  "Villafranca de Cordoba (Spain)",
  "Villafranca de los Barros (Spain)",
  "Villafranca de los Caballeros (Spain)",
  "Villafranca del Campo (Spain)",
  "Villafranqueza (Spain)",
  "Villahermosa (Spain)",
  "Villajoyosa (Spain)",
  "Villalar de los Comuneros (Spain)",
  "Villalba de los Alcores (Spain)",
  "Villalbilla (Spain)",
  "Villalonga (Spain)",
  "Villalpardo (Spain)",
  "Villaluenga (Spain)",
  "Villamalea (Spain)",
  "Villamanrique (Spain)",
  "Villamanrique de la Condesa (Spain)",
  "Villamanta (Spain)",
  "Villamartin (Spain)",
  "Villamayor (Spain)",
  "Villamayor de Santiago (Spain)",
  "Villamediana de Iregua (Spain)",
  "Villamiel de Toledo (Spain)",
  "Villamuriel de Cerrato (Spain)",
  "Villanubla (Spain)",
  "Villanueva (Spain)",
  "Villanueva de Azoague (Spain)",
  "Villanueva de Castellon (Spain)",
  "Villanueva de Cordoba (Spain)",
  "Villanueva de Gallego (Spain)",
  "Villanueva de Gomez (Spain)",
  "Villanueva de la Canada (Spain)",
  "Villanueva de la Jara (Spain)",
  "Villanueva de la Reina (Spain)",
  "Villanueva de la Serena (Spain)",
  "Villanueva de la Torre (Spain)",
  "Villanueva de Perales (Spain)",
  "Villanueva de Villaescusa (Spain)",
  "Villanueva del Ariscal (Spain)",
  "Villanueva del Arzobispo (Spain)",
  "Villanueva del Fresno (Spain)",
  "Villanueva del Pardillo (Spain)",
  "Villanueva del Rio (Spain)",
  "Villanueva del Rosario (Spain)",
  "Villaquilambre (Spain)",
  "Villar (Spain)",
  "Villar de Gallimazo (Spain)",
  "Villar de Olalla (Spain)",
  "Villar del Arzobispo (Spain)",
  "Villaralbo (Spain)",
  "Villarcayo (Spain)",
  "Villarejo (Spain)",
  "Villares de la Reina (Spain)",
  "Villares del Saz (Spain)",
  "Villaricos (Spain)",
  "Villarluengo (Spain)",
  "Villarquemado (Spain)",
  "Villarreal de Alava (Spain)",
  "Villarrobledo (Spain)",
  "Villarrubia (Spain)",
  "Villarrubia de los Ojos (Spain)",
  "Villarrubia de Santiago (Spain)",
  "Villarta de San Juan (Spain)",
  "Villasana de Mena (Spain)",
  "Villaseca de la Sagra (Spain)",
  "Villasequilla de Yepes (Spain)",
  "Villasevil (Spain)",
  "Villatuerta (Spain)",
  "Villaverde de los Cestos (Spain)",
  "Villaverde del Rio (Spain)",
  "Villaviad (Spain)",
  "Villaviciosa (Spain)",
  "Villaviciosa de Odon (Spain)",
  "Villena (Spain)",
  "Vinaixa (Spain)",
  "Vinales (Spain)",
  "Vinalesa (Spain)",
  "Vinaros (Spain)",
  "Vitigudino (Spain)",
  "Vitoria-Gasteiz (Spain)",
  "Viveda (Spain)",
  "Viveiro (Spain)",
  "Vivero (Spain)",
  "Xunqueira de Espadanedo (Spain)",
  "Yaiza (Spain)",
  "Yebes (Spain)",
  "Yecla (Spain)",
  "Yeles (Spain)",
  "Yepes (Spain)",
  "Yuncler (Spain)",
  "Yuncos (Spain)",
  "Yunquera de Henares (Spain)",
  "Zafra (Spain)",
  "Zahara de los Atunes (Spain)",
  "Zahinos (Spain)",
  "Zalamea la Real (Spain)",
  "Zamora (Spain)",
  "Zamudio (Spain)",
  "Zaragoza (Spain)",
  "Zaramillo (Spain)",
  "Zaratamo (Spain)",
  "Zaratan (Spain)",
  "Zarautz (Spain)",
  "Zarzalejo (Spain)",
  "Zarzuela (Spain)",
  "Ziga (Spain)",
  "Zorraquin (Spain)",
  "Zuazo de Vitoria (Spain)",
  "Zubia (Spain)",
  "Zubiri (Spain)",
  "Zuera (Spain)",
  "Zumaia (Spain)",
  "Zumarraga (Spain)",
  "Sri Lanka",
  "Badulla (Sri Lanka)",
  "Battaramulla South (Sri Lanka)",
  "Biyagama (Sri Lanka)",
  "Boralesgamuwa South (Sri Lanka)",
  "Colombo (Sri Lanka)",
  "Dehiwala (Sri Lanka)",
  "Dehiwala-Mount Lavinia (Sri Lanka)",
  "Eppawala (Sri Lanka)",
  "Gampaha (Sri Lanka)",
  "Gangodawila North (Sri Lanka)",
  "Hekitta (Sri Lanka)",
  "Homagama (Sri Lanka)",
  "Kaduwela (Sri Lanka)",
  "Kandana (Sri Lanka)",
  "Kandy (Sri Lanka)",
  "Katubedda (Sri Lanka)",
  "Katunayaka (Sri Lanka)",
  "Kelaniya (Sri Lanka)",
  "Kohuwala (Sri Lanka)",
  "Maharagama (Sri Lanka)",
  "Makola South (Sri Lanka)",
  "Matale (Sri Lanka)",
  "Moratuwa (Sri Lanka)",
  "Nattandiya Town (Sri Lanka)",
  "Negombo (Sri Lanka)",
  "Nugegoda (Sri Lanka)",
  "Orugodawatta (Sri Lanka)",
  "Padukka (Sri Lanka)",
  "Pannipitiya (Sri Lanka)",
  "Peradeniya (Sri Lanka)",
  "Piliyandala (Sri Lanka)",
  "Ragama (Sri Lanka)",
  "Rajagiriya (Sri Lanka)",
  "Sri Jayewardenepura Kotte (Sri Lanka)",
  "Talapathpitiya (Sri Lanka)",
  "Wattala (Sri Lanka)",
  "Wellampitiya (Sri Lanka)",
  "Sudan",
  "Kassala (Sudan)",
  "Khartoum (Sudan)",
  "Nyala (Sudan)",
  "Shendi (Sudan)",
  "Thabit (Sudan)",
  "Umm Durman (Sudan)",
  "Suriname",
  "Botopasi (Suriname)",
  "Brownsweg (Suriname)",
  "Friendship (Suriname)",
  "Groningen (Suriname)",
  "Moengo (Suriname)",
  "Nieuw Amsterdam (Suriname)",
  "Onverwacht (Suriname)",
  "Paramaribo (Suriname)",
  "Totness (Suriname)",
  "Swaziland",
  "Kwaluseni (Swaziland)",
  "Lobamba (Swaziland)",
  "Manzini (Swaziland)",
  "Mbabane (Swaziland)",
  "Piggs Peak (Swaziland)",
  "Sweden",
  "Aby (Sweden)",
  "AElmhult (Sweden)",
  "AElvdalen (Sweden)",
  "AElvkarleby (Sweden)",
  "AElvsbyn (Sweden)",
  "Agnesberg (Sweden)",
  "Agunnaryd (Sweden)",
  "Akarp (Sweden)",
  "Akers Styckebruk (Sweden)",
  "Akersberga (Sweden)",
  "Alafors (Sweden)",
  "Alandsbro (Sweden)",
  "Aled (Sweden)",
  "Alem (Sweden)",
  "Alfta (Sweden)",
  "Alingsas (Sweden)",
  "Allerum (Sweden)",
  "Almeboda (Sweden)",
  "Almunge (Sweden)",
  "Alno (Sweden)",
  "Alsen (Sweden)",
  "Alsterbro (Sweden)",
  "Alstermo (Sweden)",
  "Alta (Sweden)",
  "Altersbruk (Sweden)",
  "Alunda (Sweden)",
  "Alvangen (Sweden)",
  "Alvesta (Sweden)",
  "Alvsjo (Sweden)",
  "Amal (Sweden)",
  "Ambjornarp (Sweden)",
  "Amotfors (Sweden)",
  "Anderslov (Sweden)",
  "Anderstorp (Sweden)",
  "Aneby (Sweden)",
  "Ange (Sweden)",
  "Angered (Sweden)",
  "Ankarsrum (Sweden)",
  "Ankarsvik (Sweden)",
  "Annelov (Sweden)",
  "Annerstad (Sweden)",
  "Ansvar (Sweden)",
  "Aplared (Sweden)",
  "Arboga (Sweden)",
  "Arbra (Sweden)",
  "Arholma (Sweden)",
  "Arjaeng (Sweden)",
  "Arjeplog (Sweden)",
  "Arkelstorp (Sweden)",
  "Arla (Sweden)",
  "Arloev (Sweden)",
  "Arnasvall (Sweden)",
  "Arsunda (Sweden)",
  "Arvidsjaur (Sweden)",
  "Arvika (Sweden)",
  "Aryd (Sweden)",
  "As (Sweden)",
  "Asa (Sweden)",
  "Asarna (Sweden)",
  "Asarum (Sweden)",
  "Asbro (Sweden)",
  "Aseda (Sweden)",
  "Asele (Sweden)",
  "Asenhoga (Sweden)",
  "Asensbruk (Sweden)",
  "Ashammar (Sweden)",
  "Askersund (Sweden)",
  "Askim (Sweden)",
  "Askloster (Sweden)",
  "Asmundtorp (Sweden)",
  "Aspas (Sweden)",
  "Aspered (Sweden)",
  "Aspero (Sweden)",
  "Astorp (Sweden)",
  "Atorp (Sweden)",
  "Atran (Sweden)",
  "Atvidaberg (Sweden)",
  "Avesta (Sweden)",
  "Axvall (Sweden)",
  "Backa (Sweden)",
  "Backaryd (Sweden)",
  "Backe (Sweden)",
  "Backefors (Sweden)",
  "Balinge (Sweden)",
  "Ballingslov (Sweden)",
  "Balsta (Sweden)",
  "Bankeryd (Sweden)",
  "Bara (Sweden)",
  "Barkarby (Sweden)",
  "Barseback (Sweden)",
  "Barsebackshamn (Sweden)",
  "Bastad (Sweden)",
  "Beddinge Lage (Sweden)",
  "Bengtsfors (Sweden)",
  "Berga (Sweden)",
  "Bergeforsen (Sweden)",
  "Bergkvara (Sweden)",
  "Bergshamra (Sweden)",
  "Bergsjoe (Sweden)",
  "Bergvik (Sweden)",
  "Bettna (Sweden)",
  "Billdal (Sweden)",
  "Billeberga (Sweden)",
  "Billesholm (Sweden)",
  "Billinge (Sweden)",
  "Billingsfors (Sweden)",
  "Bjarnum (Sweden)",
  "Bjarred (Sweden)",
  "Bjartra (Sweden)",
  "Bjasta (Sweden)",
  "Bjorbo (Sweden)",
  "Bjoringe (Sweden)",
  "Bjorkhaga (Sweden)",
  "Bjorklinge (Sweden)",
  "Bjorkoby (Sweden)",
  "Bjorkvik (Sweden)",
  "Bjorna (Sweden)",
  "Bjorneborg (Sweden)",
  "Bjornlunda (Sweden)",
  "Bjuraker (Sweden)",
  "Bjurholm (Sweden)",
  "Bjursas (Sweden)",
  "Bjuv (Sweden)",
  "Blackstad (Sweden)",
  "Blasmark (Sweden)",
  "Blattnicksele (Sweden)",
  "Blentarp (Sweden)",
  "Bockara (Sweden)",
  "Bodafors (Sweden)",
  "Boden (Sweden)",
  "Bofors (Sweden)",
  "Bohus (Sweden)",
  "Boliden (Sweden)",
  "Bollebygd (Sweden)",
  "Bollnas (Sweden)",
  "Bollstabruk (Sweden)",
  "Bor (Sweden)",
  "Borensberg (Sweden)",
  "Borghamn (Sweden)",
  "Borgholm (Sweden)",
  "Borgstena (Sweden)",
  "Borrby (Sweden)",
  "Botsmark (Sweden)",
  "Bottnaryd (Sweden)",
  "Boxholm (Sweden)",
  "Braas (Sweden)",
  "Braecke (Sweden)",
  "Brakne-Hoby (Sweden)",
  "Bralanda (Sweden)",
  "Bramhult (Sweden)",
  "Branno (Sweden)",
  "Brastad (Sweden)",
  "Bredared (Sweden)",
  "Bredaryd (Sweden)",
  "Bro (Sweden)",
  "Broby (Sweden)",
  "Brokind (Sweden)",
  "Bromma (Sweden)",
  "Bromoella (Sweden)",
  "Brosarp (Sweden)",
  "Brottby (Sweden)",
  "Brunflo (Sweden)",
  "Brunnby (Sweden)",
  "Bua (Sweden)",
  "Bullmark (Sweden)",
  "Bunkeflostrand (Sweden)",
  "Burea (Sweden)",
  "Burtraesk (Sweden)",
  "Bygdea (Sweden)",
  "Bygdsiljum (Sweden)",
  "Byske (Sweden)",
  "Charlottenberg (Sweden)",
  "Dala-Floda (Sweden)",
  "Dala-Jarna (Sweden)",
  "Dalaro (Sweden)",
  "Dalby (Sweden)",
  "Dals Langed (Sweden)",
  "Dals Rostock (Sweden)",
  "Dalsjofors (Sweden)",
  "Dalstorp (Sweden)",
  "Danderyd (Sweden)",
  "Degeberga (Sweden)",
  "Degerfors (Sweden)",
  "Deje (Sweden)",
  "Delsbo (Sweden)",
  "Dikanaes (Sweden)",
  "Dingle (Sweden)",
  "Dio (Sweden)",
  "Djura (Sweden)",
  "Djuras (Sweden)",
  "Djurhamn (Sweden)",
  "Djursholm (Sweden)",
  "Domsjo (Sweden)",
  "Donso (Sweden)",
  "Dorotea (Sweden)",
  "Dosjebro (Sweden)",
  "Drottningholm (Sweden)",
  "Duved (Sweden)",
  "Dyltabruk (Sweden)",
  "Ed (Sweden)",
  "Edsbro (Sweden)",
  "Edsbyn (Sweden)",
  "Edsele (Sweden)",
  "Edshultshall (Sweden)",
  "Edsvalla (Sweden)",
  "Ekeby (Sweden)",
  "Ekenassjon (Sweden)",
  "Ekshaerad (Sweden)",
  "Eksjoe (Sweden)",
  "Eldsberga (Sweden)",
  "Elloes (Sweden)",
  "Emmaboda (Sweden)",
  "Enanger (Sweden)",
  "Enebyberg (Sweden)",
  "Eneryda (Sweden)",
  "Enkoping (Sweden)",
  "Enskede-Arsta-Vantoer (Sweden)",
  "Enviken (Sweden)",
  "Erikslund (Sweden)",
  "Eriksmala (Sweden)",
  "Eskilstuna (Sweden)",
  "Eslov (Sweden)",
  "Faergelanda (Sweden)",
  "Faerjestaden (Sweden)",
  "Fagelfors (Sweden)",
  "Fageras (Sweden)",
  "Fagerberg (Sweden)",
  "Fagered (Sweden)",
  "Fagerhult (Sweden)",
  "Fagersanna (Sweden)",
  "Fagersta (Sweden)",
  "Fagervik (Sweden)",
  "Falkenberg (Sweden)",
  "Fallfors (Sweden)",
  "Falun (Sweden)",
  "Farbo (Sweden)",
  "Farila (Sweden)",
  "Farlov (Sweden)",
  "Farna (Sweden)",
  "Farnas (Sweden)",
  "Faro (Sweden)",
  "Fellingsbro (Sweden)",
  "Fengersfors (Sweden)",
  "Figeholm (Sweden)",
  "Filipstad (Sweden)",
  "Finja (Sweden)",
  "Finnerodja (Sweden)",
  "Finspang (Sweden)",
  "Fiskeby (Sweden)",
  "Fjaeras station (Sweden)",
  "Fjalkinge (Sweden)",
  "Fjardhundra (Sweden)",
  "Fjugesta (Sweden)",
  "Flen (Sweden)",
  "Fleninge (Sweden)",
  "Flisby (Sweden)",
  "Floby (Sweden)",
  "Floda (Sweden)",
  "Flyinge (Sweden)",
  "Follinge (Sweden)",
  "Fors (Sweden)",
  "Forsa (Sweden)",
  "Forsbacka (Sweden)",
  "Forsby (Sweden)",
  "Forserum (Sweden)",
  "Forshaga (Sweden)",
  "Forsheda (Sweden)",
  "Forslov (Sweden)",
  "Frandefors (Sweden)",
  "Fransta (Sweden)",
  "Fredriksberg (Sweden)",
  "Frillesas (Sweden)",
  "Fristad (Sweden)",
  "Fritsla (Sweden)",
  "Froso (Sweden)",
  "Frovi (Sweden)",
  "Frufallan (Sweden)",
  "Furulund (Sweden)",
  "Furuvik (Sweden)",
  "Gabo (Sweden)",
  "Gagnef (Sweden)",
  "Gallo (Sweden)",
  "Gallstad (Sweden)",
  "Gamla stan (Sweden)",
  "Gamleby (Sweden)",
  "Gammelstad (Sweden)",
  "Ganghester (Sweden)",
  "Gards Kopinge (Sweden)",
  "Gargnas (Sweden)",
  "Garpenberg (Sweden)",
  "Garphyttan (Sweden)",
  "Garsas (Sweden)",
  "Garsnas (Sweden)",
  "Gemla (Sweden)",
  "Genarp (Sweden)",
  "Genevad (Sweden)",
  "Getinge (Sweden)",
  "Gidea (Sweden)",
  "Gimo (Sweden)",
  "Gislaved (Sweden)",
  "Glanshammar (Sweden)",
  "Glimakra (Sweden)",
  "Glommen (Sweden)",
  "Glommerstrask (Sweden)",
  "Glostorp (Sweden)",
  "Glumslov (Sweden)",
  "Gnarp (Sweden)",
  "Gnesta (Sweden)",
  "Gnosjoe (Sweden)",
  "Goetene (Sweden)",
  "Gota (Sweden)",
  "Gothenburg (Sweden)",
  "Grabo (Sweden)",
  "Graddo (Sweden)",
  "Graestorp (Sweden)",
  "Grangarde (Sweden)",
  "Grangesberg (Sweden)",
  "Grasmyr (Sweden)",
  "Graso (Sweden)",
  "Grebbestad (Sweden)",
  "Grillby (Sweden)",
  "Grimeton (Sweden)",
  "Grimsas (Sweden)",
  "Grimslov (Sweden)",
  "Grimstorp (Sweden)",
  "Grisslehamn (Sweden)",
  "Grondal (Sweden)",
  "Gronskara (Sweden)",
  "Grums (Sweden)",
  "Grundsund (Sweden)",
  "Grycksbo (Sweden)",
  "Gryt (Sweden)",
  "Grythyttan (Sweden)",
  "Gualov (Sweden)",
  "Guldsmedshyttan (Sweden)",
  "Gullbrandstorp (Sweden)",
  "Gullspang (Sweden)",
  "Gunnebo (Sweden)",
  "Gunnilse (Sweden)",
  "Gusselby (Sweden)",
  "Gustafs (Sweden)",
  "Gustavsberg (Sweden)",
  "Gusum (Sweden)",
  "Gyttorp (Sweden)",
  "Hackas (Sweden)",
  "Hacksvik (Sweden)",
  "Haegersten (Sweden)",
  "Haellefors (Sweden)",
  "Haerryda (Sweden)",
  "Haesselby (Sweden)",
  "Hagfors (Sweden)",
  "Hagglund (Sweden)",
  "Hajom (Sweden)",
  "Hakkas (Sweden)",
  "Haknas (Sweden)",
  "Haljarp (Sweden)",
  "Hallabro (Sweden)",
  "Halland (Sweden)",
  "Hallarod (Sweden)",
  "Hallberg (Sweden)",
  "Halleforsnas (Sweden)",
  "Hallekis (Sweden)",
  "Hallestad (Sweden)",
  "Hallevadsholm (Sweden)",
  "Hallingsjo (Sweden)",
  "Hallsberg (Sweden)",
  "Hallsta (Sweden)",
  "Hallstahammar (Sweden)",
  "Hallstavik (Sweden)",
  "Halmstad (Sweden)",
  "Halso (Sweden)",
  "Halta (Sweden)",
  "Hamburgsund (Sweden)",
  "Hammar (Sweden)",
  "Hammaro (Sweden)",
  "Hammarstrand (Sweden)",
  "Hammenhog (Sweden)",
  "Hammerdal (Sweden)",
  "Hamneda (Sweden)",
  "Hamrangefjarden (Sweden)",
  "Hanaskog (Sweden)",
  "Handen (Sweden)",
  "Haparanda (Sweden)",
  "Harads (Sweden)",
  "Harbo (Sweden)",
  "Harby (Sweden)",
  "Harestad (Sweden)",
  "Harmanger (Sweden)",
  "Harplinge (Sweden)",
  "Harslov (Sweden)",
  "Hasselby (Sweden)",
  "Hasselfors (Sweden)",
  "Hasslarp (Sweden)",
  "Hasslo (Sweden)",
  "Hastveda (Sweden)",
  "Havdhem (Sweden)",
  "Hebo (Sweden)",
  "Hedared (Sweden)",
  "Hede (Sweden)",
  "Hedekas (Sweden)",
  "Hedemora (Sweden)",
  "Hedesunda (Sweden)",
  "Helgum (Sweden)",
  "Helsingborg (Sweden)",
  "Hemmingsmark (Sweden)",
  "Hemse (Sweden)",
  "Henan (Sweden)",
  "Herrangen (Sweden)",
  "Herrljunga (Sweden)",
  "Hestra (Sweden)",
  "Hillared (Sweden)",
  "Hillerstorp (Sweden)",
  "Hindas (Sweden)",
  "Hjaerup (Sweden)",
  "Hjalteby (Sweden)",
  "Hjarnarp (Sweden)",
  "Hjartum (Sweden)",
  "Hjo (Sweden)",
  "Hoegsby (Sweden)",
  "Hoeoer (Sweden)",
  "Hoerby (Sweden)",
  "Hoernefors (Sweden)",
  "Hofors (Sweden)",
  "Hoganas (Sweden)",
  "Hogsaters (Sweden)",
  "Hogsjo (Sweden)",
  "Hok (Sweden)",
  "Hokerum (Sweden)",
  "Hollviken (Sweden)",
  "Holmen (Sweden)",
  "Holmsjo (Sweden)",
  "Holmsund (Sweden)",
  "Holmsveden (Sweden)",
  "Holo (Sweden)",
  "Holsbybrunn (Sweden)",
  "Holsljunga (Sweden)",
  "Hono (Sweden)",
  "Horn (Sweden)",
  "Horndal (Sweden)",
  "Horred (Sweden)",
  "Hortlax (Sweden)",
  "Hova (Sweden)",
  "Hovas (Sweden)",
  "Hoviksnas (Sweden)",
  "Hovmantorp (Sweden)",
  "Huarod (Sweden)",
  "Huddinge (Sweden)",
  "Hudiksvall (Sweden)",
  "Hultafors (Sweden)",
  "Hultsfred (Sweden)",
  "Hunnebostrand (Sweden)",
  "Hunnestad (Sweden)",
  "Huskvarna (Sweden)",
  "Husum (Sweden)",
  "Hyllinge (Sweden)",
  "Hyltebruk (Sweden)",
  "Hyssna (Sweden)",
  "Iggesund (Sweden)",
  "Immeln (Sweden)",
  "Indal (Sweden)",
  "Ingarp (Sweden)",
  "Ingelstad (Sweden)",
  "Ingmarso (Sweden)",
  "Insjon (Sweden)",
  "Jaemjoe (Sweden)",
  "Jaervsoe (Sweden)",
  "Jamshog (Sweden)",
  "Jarbo (Sweden)",
  "Jarlasa (Sweden)",
  "Jarna (Sweden)",
  "Jarpen (Sweden)",
  "Jarved (Sweden)",
  "Joern (Sweden)",
  "Johanneshov (Sweden)",
  "Johannishus (Sweden)",
  "Jokkmokk (Sweden)",
  "Jonaker (Sweden)",
  "Jonsered (Sweden)",
  "Jordbro (Sweden)",
  "Jorlanda (Sweden)",
  "Jude (Sweden)",
  "Junosuando (Sweden)",
  "Kaevlinge (Sweden)",
  "Kage (Sweden)",
  "Kagerod (Sweden)",
  "Kalix (Sweden)",
  "Kallby (Sweden)",
  "Kallered (Sweden)",
  "Kallinge (Sweden)",
  "Kallo (Sweden)",
  "Kalmar (Sweden)",
  "Kareby (Sweden)",
  "Karesuando (Sweden)",
  "Karl Gustav (Sweden)",
  "Karlsborg (Sweden)",
  "Karlshamn (Sweden)",
  "Karlskoga (Sweden)",
  "Karlskrona (Sweden)",
  "Karlstad (Sweden)",
  "Karna (Sweden)",
  "Karra (Sweden)",
  "Katrineholm (Sweden)",
  "Kattarp (Sweden)",
  "Katthammarsvik (Sweden)",
  "Kattilstorp (Sweden)",
  "Kil (Sweden)",
  "Kilafors (Sweden)",
  "Killeberg (Sweden)",
  "Kimstad (Sweden)",
  "Kinna (Sweden)",
  "Kinnared (Sweden)",
  "Kinnarp (Sweden)",
  "Kiruna (Sweden)",
  "Kisa (Sweden)",
  "Kista (Sweden)",
  "Kivik (Sweden)",
  "Kladesholmen (Sweden)",
  "Klagerup (Sweden)",
  "Klagshamn (Sweden)",
  "Klagstorp (Sweden)",
  "Klassbol (Sweden)",
  "Klavrestrom (Sweden)",
  "Klintehamn (Sweden)",
  "Klippan (Sweden)",
  "Klovedal (Sweden)",
  "Knared (Sweden)",
  "Knislinge (Sweden)",
  "Knivsta (Sweden)",
  "Knutby (Sweden)",
  "Kode (Sweden)",
  "Kolback (Sweden)",
  "Kolmarden (Sweden)",
  "Kolsva (Sweden)",
  "Konga (Sweden)",
  "Koping (Sweden)",
  "Kopingebro (Sweden)",
  "Kopmanholmen (Sweden)",
  "Kopparberg (Sweden)",
  "Koppom (Sweden)",
  "Korpilombolo (Sweden)",
  "Korsberga (Sweden)",
  "Korskrogen (Sweden)",
  "Koskullskulle (Sweden)",
  "Kosta (Sweden)",
  "Kramfors (Sweden)",
  "Kristdala (Sweden)",
  "Kristianstad (Sweden)",
  "Kristineberg (Sweden)",
  "Kristinehamn (Sweden)",
  "Krokom (Sweden)",
  "Krylbo (Sweden)",
  "Kulla (Sweden)",
  "Kullavik (Sweden)",
  "Kulltorp (Sweden)",
  "Kumla (Sweden)",
  "Kungsaengen (Sweden)",
  "Kungsbacka (Sweden)",
  "Kungsgarden (Sweden)",
  "Kungshamn (Sweden)",
  "Kungsoer (Sweden)",
  "Kvanum (Sweden)",
  "Kvibille (Sweden)",
  "Kvicksund (Sweden)",
  "Kvidinge (Sweden)",
  "Kvissleby (Sweden)",
  "Kyrkobyn (Sweden)",
  "Lagan (Sweden)",
  "Laholm (Sweden)",
  "Lammhult (Sweden)",
  "Landeryd (Sweden)",
  "Landfjarden (Sweden)",
  "Landsbro (Sweden)",
  "Landskrona (Sweden)",
  "Landvetter (Sweden)",
  "Langas (Sweden)",
  "Langasjo (Sweden)",
  "Langhem (Sweden)",
  "Langsele (Sweden)",
  "Langshyttan (Sweden)",
  "Langviksmon (Sweden)",
  "Lanna (Sweden)",
  "Lannavaara (Sweden)",
  "Larbro (Sweden)",
  "Larv (Sweden)",
  "Lavsjo (Sweden)",
  "Laxa (Sweden)",
  "Lekeryd (Sweden)",
  "Leksand (Sweden)",
  "Lenhovda (Sweden)",
  "Lerberget (Sweden)",
  "Lerdala (Sweden)",
  "Lerum (Sweden)",
  "Lessebo (Sweden)",
  "Liden (Sweden)",
  "Lidhult (Sweden)",
  "Lidingoe (Sweden)",
  "Lidkoping (Sweden)",
  "Likenas (Sweden)",
  "Lilla Edet (Sweden)",
  "Lillpite (Sweden)",
  "Lima (Sweden)",
  "Limhamn (Sweden)",
  "Limmared (Sweden)",
  "Lindas (Sweden)",
  "Lindesberg (Sweden)",
  "Lindome (Sweden)",
  "Linghem (Sweden)",
  "Linneryd (Sweden)",
  "Listerby (Sweden)",
  "Lit (Sweden)",
  "Ljugarn (Sweden)",
  "Ljung (Sweden)",
  "Ljungaverk (Sweden)",
  "Ljungby (Sweden)",
  "Ljungbyhed (Sweden)",
  "Ljungbyholm (Sweden)",
  "Ljungsarp (Sweden)",
  "Ljungsbro (Sweden)",
  "Ljungskile (Sweden)",
  "Ljusdal (Sweden)",
  "Ljusfallshammar (Sweden)",
  "Ljusne (Sweden)",
  "Ljustero (Sweden)",
  "Ljustorp (Sweden)",
  "Loberod (Sweden)",
  "Loddekopinge (Sweden)",
  "Loderup (Sweden)",
  "Lodose (Sweden)",
  "Lomma (Sweden)",
  "Lonashult (Sweden)",
  "Lonsboda (Sweden)",
  "Los (Sweden)",
  "Lotorp (Sweden)",
  "Lottorp (Sweden)",
  "Lovanger (Sweden)",
  "Lovestad (Sweden)",
  "Lovikka (Sweden)",
  "Ludvigsborg (Sweden)",
  "Ludvika (Sweden)",
  "Lugnvik (Sweden)",
  "Lund (Sweden)",
  "Lycke (Sweden)",
  "Lyckeby (Sweden)",
  "Lycksele (Sweden)",
  "Lysekil (Sweden)",
  "Lysvik (Sweden)",
  "Mala (Sweden)",
  "Maleras (Sweden)",
  "Malilla (Sweden)",
  "Malma (Sweden)",
  "Malmback (Sweden)",
  "Malmberget (Sweden)",
  "Malmkoping (Sweden)",
  "Malmo (Sweden)",
  "Malsryd (Sweden)",
  "Malung (Sweden)",
  "Mankarbo (Sweden)",
  "Mansarp (Sweden)",
  "Mantorp (Sweden)",
  "Marback (Sweden)",
  "Mardaklev (Sweden)",
  "Mariannelund (Sweden)",
  "Mariefred (Sweden)",
  "Marieholm (Sweden)",
  "Mariestad (Sweden)",
  "Markaryd (Sweden)",
  "Marma (Sweden)",
  "Marsta (Sweden)",
  "Marstrand (Sweden)",
  "Matfors (Sweden)",
  "Mellansel (Sweden)",
  "Mellbystrand (Sweden)",
  "Mellerud (Sweden)",
  "Mjallom (Sweden)",
  "Mjolby (Sweden)",
  "Mockfjard (Sweden)",
  "Moelle (Sweden)",
  "Moelndal (Sweden)",
  "Moensteras (Sweden)",
  "Moerbylanga (Sweden)",
  "Moheda (Sweden)",
  "Moholm (Sweden)",
  "Moliden (Sweden)",
  "Molkom (Sweden)",
  "Molltorp (Sweden)",
  "Molnbo (Sweden)",
  "Molnlycke (Sweden)",
  "Mora (Sweden)",
  "Morarp (Sweden)",
  "Morgongava (Sweden)",
  "Morlunda (Sweden)",
  "Morrum (Sweden)",
  "Morsil (Sweden)",
  "Morup (Sweden)",
  "Motala (Sweden)",
  "Mullhyttan (Sweden)",
  "Mullsjoe (Sweden)",
  "Munka-Ljungby (Sweden)",
  "Munkedal (Sweden)",
  "Munkfors (Sweden)",
  "Musko (Sweden)",
  "Myggenas (Sweden)",
  "Myresjo (Sweden)",
  "Nacka (Sweden)",
  "Nalden (Sweden)",
  "Nashulta (Sweden)",
  "Nassjo (Sweden)",
  "Nasum (Sweden)",
  "Nasviken (Sweden)",
  "Nattraby (Sweden)",
  "Navekvarn (Sweden)",
  "Navlinge (Sweden)",
  "Nissafors (Sweden)",
  "Njurundabommen (Sweden)",
  "Njutanger (Sweden)",
  "Nodinge (Sweden)",
  "Nodinge-Nol (Sweden)",
  "Nora (Sweden)",
  "Norberg (Sweden)",
  "Nordingra (Sweden)",
  "Nordmaling (Sweden)",
  "Norrahammar (Sweden)",
  "Norrala (Sweden)",
  "Norrfjarden (Sweden)",
  "Norrhult (Sweden)",
  "Norrsundet (Sweden)",
  "Norrtalje (Sweden)",
  "Norsborg (Sweden)",
  "Norsholm (Sweden)",
  "Norsjoe (Sweden)",
  "Nossebro (Sweden)",
  "Nybro (Sweden)",
  "Nygard (Sweden)",
  "Nyhammar (Sweden)",
  "Nyhamnslage (Sweden)",
  "Nykil (Sweden)",
  "Nykroppa (Sweden)",
  "Nykvarn (Sweden)",
  "Nyland (Sweden)",
  "Nynas (Sweden)",
  "Nynashamn (Sweden)",
  "Nysater (Sweden)",
  "Nyvang (Sweden)",
  "Obbola (Sweden)",
  "Ockelbo (Sweden)",
  "Odakra (Sweden)",
  "Odeborg (Sweden)",
  "Odensbacken (Sweden)",
  "OEckeroe (Sweden)",
  "OEdeshoeg (Sweden)",
  "OErkelljunga (Sweden)",
  "OEsterbymo (Sweden)",
  "OEstervala (Sweden)",
  "OEsthammar (Sweden)",
  "OEverkalix (Sweden)",
  "OEvertornea (Sweden)",
  "Ojebyn (Sweden)",
  "Ojersjo (Sweden)",
  "Olofstorp (Sweden)",
  "Olofstroem (Sweden)",
  "Olsfors (Sweden)",
  "Onnestad (Sweden)",
  "Orby (Sweden)",
  "Orbyhus (Sweden)",
  "Oregrund (Sweden)",
  "Ormaryd (Sweden)",
  "Orno (Sweden)",
  "Ornskoldsvik (Sweden)",
  "Orrefors (Sweden)",
  "Orsa (Sweden)",
  "Orsundsbro (Sweden)",
  "Orviken (Sweden)",
  "Osby (Sweden)",
  "Oskarshamn (Sweden)",
  "Oskarstrom (Sweden)",
  "Osmo (Sweden)",
  "Ostansjo (Sweden)",
  "Osterbybruk (Sweden)",
  "Osterhaninge (Sweden)",
  "Osterskar (Sweden)",
  "Ostra (Sweden)",
  "Ostra Frolunda (Sweden)",
  "Ostra Hindalebyn (Sweden)",
  "Ostra Karup (Sweden)",
  "Ostra Ryd (Sweden)",
  "Otterbacken (Sweden)",
  "Ovanaker (Sweden)",
  "Overhornas (Sweden)",
  "Overkovland (Sweden)",
  "Overlida (Sweden)",
  "Overum (Sweden)",
  "Oviken (Sweden)",
  "Oxaback (Sweden)",
  "Oxelosund (Sweden)",
  "Oxie (Sweden)",
  "Paarp (Sweden)",
  "Pajala (Sweden)",
  "Palange (Sweden)",
  "Palsboda (Sweden)",
  "Partille (Sweden)",
  "Paskallavik (Sweden)",
  "Perstorp (Sweden)",
  "Pilgrimstad (Sweden)",
  "Pixbo (Sweden)",
  "Raa (Sweden)",
  "Raettvik (Sweden)",
  "Ramdala (Sweden)",
  "Ramnas (Sweden)",
  "Ramsele (Sweden)",
  "Ramsjo (Sweden)",
  "Ramvik (Sweden)",
  "Ranea (Sweden)",
  "Rangedala (Sweden)",
  "Ransater (Sweden)",
  "Ransta (Sweden)",
  "Ravlanda (Sweden)",
  "Reftele (Sweden)",
  "Rejmyre (Sweden)",
  "Rekarne (Sweden)",
  "Rengsjo (Sweden)",
  "Reso (Sweden)",
  "Restad (Sweden)",
  "Riddarhyttan (Sweden)",
  "Rimbo (Sweden)",
  "Rimforsa (Sweden)",
  "Ring (Sweden)",
  "Ringarum (Sweden)",
  "Rinkaby (Sweden)",
  "Roback (Sweden)",
  "Robertsfors (Sweden)",
  "Rockneby (Sweden)",
  "Rodeby (Sweden)",
  "Roke (Sweden)",
  "Rolfstorp (Sweden)",
  "Romakloster (Sweden)",
  "Romelanda (Sweden)",
  "Ronnang (Sweden)",
  "Ronneby (Sweden)",
  "Ronninge (Sweden)",
  "Roro (Sweden)",
  "Rorvik (Sweden)",
  "Rosersberg (Sweden)",
  "Rosson (Sweden)",
  "Rostanga (Sweden)",
  "Rosvik (Sweden)",
  "Rotebro (Sweden)",
  "Rottne (Sweden)",
  "Ruda (Sweden)",
  "Rundvik (Sweden)",
  "Ryd (Sweden)",
  "Rydaholm (Sweden)",
  "Rydboholm (Sweden)",
  "Rydeback (Sweden)",
  "Rydobruk (Sweden)",
  "Rydsgard (Sweden)",
  "Ryssby (Sweden)",
  "Saeffle (Sweden)",
  "Saeter (Sweden)",
  "Saevar (Sweden)",
  "Saevast (Sweden)",
  "Saevsjoe (Sweden)",
  "Sagmyra (Sweden)",
  "Sala (Sweden)",
  "Saltsjo-Duvnas (Sweden)",
  "Saltsjobaden (Sweden)",
  "Sand (Sweden)",
  "Sandared (Sweden)",
  "Sandarne (Sweden)",
  "Sandby (Sweden)",
  "Sandhem (Sweden)",
  "Sandhult (Sweden)",
  "Sandviken (Sweden)",
  "Sankt Olof (Sweden)",
  "Saro (Sweden)",
  "Satila (Sweden)",
  "Savedalen (Sweden)",
  "Savsjostrom (Sweden)",
  "Saxtorp (Sweden)",
  "Segersta (Sweden)",
  "Seglora (Sweden)",
  "Segmon (Sweden)",
  "Sennan (Sweden)",
  "Sexdrega (Sweden)",
  "Sibbhult (Sweden)",
  "Sidensjo (Sweden)",
  "Sifferbo (Sweden)",
  "Sigtuna (Sweden)",
  "Siljansnas (Sweden)",
  "Simlangsdalen (Sweden)",
  "Simrishamn (Sweden)",
  "Sjalevad (Sweden)",
  "Sjoebo (Sweden)",
  "Sjomarken (Sweden)",
  "Sjotofta (Sweden)",
  "Sjovik (Sweden)",
  "Sjuntorp (Sweden)",
  "Ska (Sweden)",
  "Skaerhamn (Sweden)",
  "Skaerholmen (Sweden)",
  "Skallinge (Sweden)",
  "Skane (Sweden)",
  "Skanes Fagerhult (Sweden)",
  "Skanninge (Sweden)",
  "Skanor med Falsterbo (Sweden)",
  "Skara (Sweden)",
  "Skaraborg (Sweden)",
  "Skarblacka (Sweden)",
  "Skarplinge (Sweden)",
  "Skarpnaeck (Sweden)",
  "Skattkarr (Sweden)",
  "Skee (Sweden)",
  "Skelleftehamn (Sweden)",
  "Skene (Sweden)",
  "Skepplanda (Sweden)",
  "Skillingaryd (Sweden)",
  "Skillinge (Sweden)",
  "Skinnskatteberg (Sweden)",
  "Skivarp (Sweden)",
  "Skogas (Sweden)",
  "Skoghall (Sweden)",
  "Skogstorp (Sweden)",
  "Skoldinge (Sweden)",
  "Skollersta (Sweden)",
  "Skondal (Sweden)",
  "Skruv (Sweden)",
  "Skultuna (Sweden)",
  "Skummeslovsstrand (Sweden)",
  "Skurup (Sweden)",
  "Skyllberg (Sweden)",
  "Skyttorp (Sweden)",
  "Slite (Sweden)",
  "Sloinge (Sweden)",
  "Slottsbron (Sweden)",
  "Slutarp (Sweden)",
  "Smalandsstenar (Sweden)",
  "Smedjebacken (Sweden)",
  "Smedstorp (Sweden)",
  "Smoegen (Sweden)",
  "Soderakra (Sweden)",
  "Soderala (Sweden)",
  "Soderby (Sweden)",
  "Soderfors (Sweden)",
  "Sodergard (Sweden)",
  "Soderhamn (Sweden)",
  "Sodra Sandby (Sweden)",
  "Sodra Sunderbyn (Sweden)",
  "Sodra Vi (Sweden)",
  "Soederbaerke (Sweden)",
  "Soederkoeping (Sweden)",
  "Sollebrunn (Sweden)",
  "Solleftea (Sweden)",
  "Sollentuna (Sweden)",
  "Solna (Sweden)",
  "Sommen (Sweden)",
  "Soraker (Sweden)",
  "Sorberge (Sweden)",
  "Sorombacken (Sweden)",
  "Sorsele (Sweden)",
  "Sosdala (Sweden)",
  "Sovde (Sweden)",
  "Spanga (Sweden)",
  "Sparsor (Sweden)",
  "Spekerod (Sweden)",
  "Staffanstorp (Sweden)",
  "Stallarholmen (Sweden)",
  "Stalldalen (Sweden)",
  "Stanga (Sweden)",
  "Stehag (Sweden)",
  "Stenhamra (Sweden)",
  "Stenkullen (Sweden)",
  "Stensele (Sweden)",
  "Stenstorp (Sweden)",
  "Stenungsund (Sweden)",
  "Stigen (Sweden)",
  "Stigtomta (Sweden)",
  "Stjarnhov (Sweden)",
  "Stoby (Sweden)",
  "Stockaryd (Sweden)",
  "Stockholm (Sweden)",
  "Stocksund (Sweden)",
  "Stode (Sweden)",
  "Stollet (Sweden)",
  "Stora (Sweden)",
  "Stora Hoga (Sweden)",
  "Stora Mellosa (Sweden)",
  "Stora Skedvi (Sweden)",
  "Stora Vika (Sweden)",
  "Storebro (Sweden)",
  "Storfors (Sweden)",
  "Storlien (Sweden)",
  "Storuman (Sweden)",
  "Storvik (Sweden)",
  "Storvreta (Sweden)",
  "Stra (Sweden)",
  "Strangnas (Sweden)",
  "Stratjara (Sweden)",
  "Stravalla (Sweden)",
  "Stroemstad (Sweden)",
  "Stroemsund (Sweden)",
  "Stromsbruk (Sweden)",
  "Stromsholm (Sweden)",
  "Stromsnasbruk (Sweden)",
  "Strovelstorp (Sweden)",
  "Stugun (Sweden)",
  "Sturefors (Sweden)",
  "Sturko (Sweden)",
  "Styrso (Sweden)",
  "Sundborn (Sweden)",
  "Sundbyberg (Sweden)",
  "Sundsbruk (Sweden)",
  "Sundsvall (Sweden)",
  "Sunnansjo (Sweden)",
  "Sunne (Sweden)",
  "Sunnemo (Sweden)",
  "Surahammar (Sweden)",
  "Surte (Sweden)",
  "Svaloev (Sweden)",
  "Svaneholm (Sweden)",
  "Svanesund (Sweden)",
  "Svangsta (Sweden)",
  "Svardsjo (Sweden)",
  "Svarta (Sweden)",
  "Svartinge (Sweden)",
  "Svartsjo (Sweden)",
  "Svedala (Sweden)",
  "Sveg (Sweden)",
  "Svenljunga (Sweden)",
  "Svensbyn (Sweden)",
  "Svenshogen (Sweden)",
  "Svenstavik (Sweden)",
  "Taberg (Sweden)",
  "Taby (Sweden)",
  "Taenndalen (Sweden)",
  "Taernaby (Sweden)",
  "Taftea (Sweden)",
  "Tagarp (Sweden)",
  "Talje (Sweden)",
  "Taljo (Sweden)",
  "Tallasen (Sweden)",
  "Tallberg (Sweden)",
  "Tanumshede (Sweden)",
  "Tarnsjo (Sweden)",
  "Tavelsjo (Sweden)",
  "Teckomatorp (Sweden)",
  "Tegeltorp (Sweden)",
  "Tenhult (Sweden)",
  "Tibro (Sweden)",
  "Tidaholm (Sweden)",
  "Tidan (Sweden)",
  "Tierp (Sweden)",
  "Timmele (Sweden)",
  "Timmernabben (Sweden)",
  "Timmersdala (Sweden)",
  "Timra (Sweden)",
  "Tingsryd (Sweden)",
  "Tingstade (Sweden)",
  "Tjornarp (Sweden)",
  "Toecksfors (Sweden)",
  "Toere (Sweden)",
  "Toereboda (Sweden)",
  "Tolg (Sweden)",
  "Tollarp (Sweden)",
  "Tollered (Sweden)",
  "Tomelilla (Sweden)",
  "Torekov (Sweden)",
  "Tormestorp (Sweden)",
  "Torpshammar (Sweden)",
  "Torsaker (Sweden)",
  "Torsas (Sweden)",
  "Torsby (Sweden)",
  "Torshalla (Sweden)",
  "Torslanda (Sweden)",
  "Torup (Sweden)",
  "Tranas (Sweden)",
  "Tranemo (Sweden)",
  "Trangsund (Sweden)",
  "Transtrand (Sweden)",
  "Traryd (Sweden)",
  "Traslovslage (Sweden)",
  "Travad (Sweden)",
  "Trehorningsjo (Sweden)",
  "Trekanten (Sweden)",
  "Trelleborg (Sweden)",
  "Trollhattan (Sweden)",
  "Trosa (Sweden)",
  "Tullinge (Sweden)",
  "Tumba (Sweden)",
  "Tun (Sweden)",
  "Tungelsta (Sweden)",
  "Tvaaker (Sweden)",
  "Tvaralund (Sweden)",
  "Tving (Sweden)",
  "Tygelsjo (Sweden)",
  "Tyreso Strand (Sweden)",
  "Tyringe (Sweden)",
  "Tystberga (Sweden)",
  "Ucklum (Sweden)",
  "Uddebo (Sweden)",
  "Uddeholm (Sweden)",
  "Uddevalla (Sweden)",
  "Ugglarp (Sweden)",
  "Ullared (Sweden)",
  "Ulricehamn (Sweden)",
  "Ulrika (Sweden)",
  "Undersaker (Sweden)",
  "Unnaryd (Sweden)",
  "Uppharad (Sweden)",
  "Upplands Vasby (Sweden)",
  "Uppsala (Sweden)",
  "Urshult (Sweden)",
  "Ursviken (Sweden)",
  "Utansjo (Sweden)",
  "Uttran (Sweden)",
  "Vackelsang (Sweden)",
  "Vaddo (Sweden)",
  "Vaderstad (Sweden)",
  "Vadstena (Sweden)",
  "Vaeja (Sweden)",
  "Vaellingby (Sweden)",
  "Vaennaes (Sweden)",
  "Vaermdoe (Sweden)",
  "Vaggeryd (Sweden)",
  "Vagnharad (Sweden)",
  "Valadalen (Sweden)",
  "Valberg (Sweden)",
  "Valbo (Sweden)",
  "Valdemarsvik (Sweden)",
  "Valla (Sweden)",
  "Vallakra (Sweden)",
  "Vallda (Sweden)",
  "Vallentuna (Sweden)",
  "Vallsta (Sweden)",
  "Valskog (Sweden)",
  "Vanersborg (Sweden)",
  "Vankiva (Sweden)",
  "Vannasby (Sweden)",
  "Vansbro (Sweden)",
  "Vara (Sweden)",
  "Varberg (Sweden)",
  "Varby (Sweden)",
  "Varekil (Sweden)",
  "Vargarda (Sweden)",
  "Vargon (Sweden)",
  "Varing (Sweden)",
  "Varmland (Sweden)",
  "Varnamo (Sweden)",
  "Varnhem (Sweden)",
  "Varobacka (Sweden)",
  "Varsas (Sweden)",
  "Varsta (Sweden)",
  "Vartofta (Sweden)",
  "Vase (Sweden)",
  "Vassmolosa (Sweden)",
  "Vasterfarnebo (Sweden)",
  "Vasterljung (Sweden)",
  "Vastervik (Sweden)",
  "Vastra Amtervik (Sweden)",
  "Vastra Frolunda (Sweden)",
  "Vastra Karup (Sweden)",
  "Vastra Ramlosa (Sweden)",
  "Vastra Torup (Sweden)",
  "Vato (Sweden)",
  "Vattholma (Sweden)",
  "Vaxholm (Sweden)",
  "Vaxjo (Sweden)",
  "Vaxtorp (Sweden)",
  "Veberod (Sweden)",
  "Veddige (Sweden)",
  "Vedum (Sweden)",
  "Vega (Sweden)",
  "Vegby (Sweden)",
  "Veinge (Sweden)",
  "Vejbystrand (Sweden)",
  "Vellinge (Sweden)",
  "Vendelso (Sweden)",
  "Vessigebro (Sweden)",
  "Vetlanda (Sweden)",
  "Vidsel (Sweden)",
  "Vikarbyn (Sweden)",
  "Viken (Sweden)",
  "Vikingstad (Sweden)",
  "Vikmanshyttan (Sweden)",
  "Viksjofors (Sweden)",
  "Vilhelmina (Sweden)",
  "Villan (Sweden)",
  "Vimmerby (Sweden)",
  "Vinberg (Sweden)",
  "Vindeln (Sweden)",
  "Vingaker (Sweden)",
  "Vinninga (Sweden)",
  "Vinslov (Sweden)",
  "Vintrie (Sweden)",
  "Vintrosa (Sweden)",
  "Virsbo Bruk (Sweden)",
  "Virserum (Sweden)",
  "Visby (Sweden)",
  "Viskafors (Sweden)",
  "Vislanda (Sweden)",
  "Vissefjarda (Sweden)",
  "Vitaby (Sweden)",
  "Vittaryd (Sweden)",
  "Vittsjo (Sweden)",
  "Vollsjo (Sweden)",
  "Vrena (Sweden)",
  "Vreta Kloster (Sweden)",
  "Vretstorp (Sweden)",
  "Yngsjo (Sweden)",
  "Ystad (Sweden)",
  "Ytterby (Sweden)",
  "Zinkgruvan (Sweden)",
  "Switzerland",
  "Aadorf (Switzerland)",
  "Aarau (Switzerland)",
  "Aarberg (Switzerland)",
  "Aarburg (Switzerland)",
  "Abtwil (Switzerland)",
  "Adelboden (Switzerland)",
  "Adligenswil (Switzerland)",
  "Adliswil (Switzerland)",
  "Aesch (Switzerland)",
  "Aeschau (Switzerland)",
  "Aeschlen ob Gunten (Switzerland)",
  "Aettenschwil (Switzerland)",
  "Aeugst am Albis (Switzerland)",
  "Affoltern am Albis (Switzerland)",
  "Agno (Switzerland)",
  "Agra (Switzerland)",
  "Aigle (Switzerland)",
  "Airolo (Switzerland)",
  "Alberswil (Switzerland)",
  "Albligen (Switzerland)",
  "Alchenstorf (Switzerland)",
  "Allaman (Switzerland)",
  "Allschwil (Switzerland)",
  "Alpnach (Switzerland)",
  "Alt-Bachs (Switzerland)",
  "Altdorf (Switzerland)",
  "Altendorf (Switzerland)",
  "Altenrhein (Switzerland)",
  "Alterswil (Switzerland)",
  "Altishofen (Switzerland)",
  "Altnau (Switzerland)",
  "Altstatten (Switzerland)",
  "Amden (Switzerland)",
  "Amriswil (Switzerland)",
  "Amsoldingen (Switzerland)",
  "Andermatt (Switzerland)",
  "Andwil (Switzerland)",
  "Anieres (Switzerland)",
  "Appenzell (Switzerland)",
  "Apples (Switzerland)",
  "Apro (Switzerland)",
  "Arbaz (Switzerland)",
  "Arbedo (Switzerland)",
  "Arbon (Switzerland)",
  "Arcegno (Switzerland)",
  "Ardon (Switzerland)",
  "Arisdorf (Switzerland)",
  "Aristau (Switzerland)",
  "Arlesheim (Switzerland)",
  "Arni (Switzerland)",
  "Arogno (Switzerland)",
  "Arosa (Switzerland)",
  "Arth (Switzerland)",
  "Arzier (Switzerland)",
  "Arzo (Switzerland)",
  "Ascona (Switzerland)",
  "Assens (Switzerland)",
  "Attalens (Switzerland)",
  "Attinghausen (Switzerland)",
  "Attiswil (Switzerland)",
  "Au (Switzerland)",
  "Aubonne (Switzerland)",
  "Auenstein (Switzerland)",
  "Augst (Switzerland)",
  "Ausser-Dinhard (Switzerland)",
  "Autigny (Switzerland)",
  "Auvernier (Switzerland)",
  "Auw (Switzerland)",
  "Avenches (Switzerland)",
  "Avry (Switzerland)",
  "Ayent (Switzerland)",
  "Ayer (Switzerland)",
  "Baar (Switzerland)",
  "Bach (Switzerland)",
  "Bachenbulach (Switzerland)",
  "Bad Ragaz (Switzerland)",
  "Bad Zurzach (Switzerland)",
  "Baden (Switzerland)",
  "Balerna (Switzerland)",
  "Balgach (Switzerland)",
  "Ballwil (Switzerland)",
  "Balsthal (Switzerland)",
  "Balterswil (Switzerland)",
  "Banco (Switzerland)",
  "Bangerten (Switzerland)",
  "Bannwil (Switzerland)",
  "Barbereche (Switzerland)",
  "Baretswil (Switzerland)",
  "Bariswil (Switzerland)",
  "Basadingen (Switzerland)",
  "Basel (Switzerland)",
  "Basse-Nendaz (Switzerland)",
  "Bassecourt (Switzerland)",
  "Bassersdorf (Switzerland)",
  "Batterkinden (Switzerland)",
  "Baulmes (Switzerland)",
  "Bauma (Switzerland)",
  "Bavois (Switzerland)",
  "Bazenheid (Switzerland)",
  "Beckenried (Switzerland)",
  "Begnins (Switzerland)",
  "Beinwil (Switzerland)",
  "Belfaux (Switzerland)",
  "Bellerive (Switzerland)",
  "Bellevue (Switzerland)",
  "Bellinzona (Switzerland)",
  "Bellmund (Switzerland)",
  "Belmont-sur-Lausanne (Switzerland)",
  "Belp (Switzerland)",
  "Benken (Switzerland)",
  "Berg (Switzerland)",
  "Berikon (Switzerland)",
  "Beringen (Switzerland)",
  "Berlikon (Switzerland)",
  "Bern (Switzerland)",
  "Bern / Liebefeld (Switzerland)",
  "Berneck (Switzerland)",
  "Bernex (Switzerland)",
  "Beromuenster (Switzerland)",
  "Bettingen (Switzerland)",
  "Bettlach (Switzerland)",
  "Beuson (Switzerland)",
  "Bevaix (Switzerland)",
  "Bevilard (Switzerland)",
  "Bex (Switzerland)",
  "Biasca (Switzerland)",
  "Biberist (Switzerland)",
  "Biel-Benken (Switzerland)",
  "Biel/Bienne (Switzerland)",
  "Biere (Switzerland)",
  "Bigenthal (Switzerland)",
  "Bigorio (Switzerland)",
  "Bilten (Switzerland)",
  "Binningen (Switzerland)",
  "Bioggio (Switzerland)",
  "Birmensdorf (Switzerland)",
  "Birmenstorf (Switzerland)",
  "Birr (Switzerland)",
  "Birsfelden (Switzerland)",
  "Bissegg (Switzerland)",
  "Bissone (Switzerland)",
  "Bitsch (Switzerland)",
  "Blonay (Switzerland)",
  "Blumenstein (Switzerland)",
  "Bogis-Bossey (Switzerland)",
  "Boll (Switzerland)",
  "Bolligen (Switzerland)",
  "Bonaduz (Switzerland)",
  "Bonigen (Switzerland)",
  "Bonnefontaine (Switzerland)",
  "Bonstetten (Switzerland)",
  "Borex (Switzerland)",
  "Bosingen (Switzerland)",
  "Bottens (Switzerland)",
  "Bottenwil (Switzerland)",
  "Bottighofen (Switzerland)",
  "Bottmingen (Switzerland)",
  "Boudry (Switzerland)",
  "Bouveret (Switzerland)",
  "Bramois (Switzerland)",
  "Breitenbach (Switzerland)",
  "Bremgarten (Switzerland)",
  "Brenzikofen (Switzerland)",
  "Brienz (Switzerland)",
  "Brig (Switzerland)",
  "Brislach (Switzerland)",
  "Brissago (Switzerland)",
  "Bristen (Switzerland)",
  "Brittnau (Switzerland)",
  "Broc (Switzerland)",
  "Bronschhofen (Switzerland)",
  "Brugg (Switzerland)",
  "Brunnen (Switzerland)",
  "Brusino Arsizio (Switzerland)",
  "Brutten (Switzerland)",
  "Bruttisellen (Switzerland)",
  "Bubendorf (Switzerland)",
  "Bubikon (Switzerland)",
  "Buch (Switzerland)",
  "Buchberg (Switzerland)",
  "Buchrain (Switzerland)",
  "Buchs (Switzerland)",
  "Buchs / Buchs (Dorf) (Switzerland)",
  "Buetschwil (Switzerland)",
  "Buix (Switzerland)",
  "Bulach (Switzerland)",
  "Bulle (Switzerland)",
  "Buochs (Switzerland)",
  "Buonas (Switzerland)",
  "Burchen (Switzerland)",
  "Buren an der Aare (Switzerland)",
  "Buren nid dem Bach (Switzerland)",
  "Burgdorf (Switzerland)",
  "Burglen (Switzerland)",
  "Bursins (Switzerland)",
  "Busserach (Switzerland)",
  "Bussigny (Switzerland)",
  "Busswil (Switzerland)",
  "Buttes (Switzerland)",
  "Buttisholz (Switzerland)",
  "Butzberg (Switzerland)",
  "Buus (Switzerland)",
  "Cadempino (Switzerland)",
  "Cadenazzo (Switzerland)",
  "Cadro (Switzerland)",
  "Camorino (Switzerland)",
  "Campfer (Switzerland)",
  "Canobbio (Switzerland)",
  "Capolago (Switzerland)",
  "Carouge (Switzerland)",
  "Cartigny (Switzerland)",
  "Caslano (Switzerland)",
  "Castione (Switzerland)",
  "Cazis (Switzerland)",
  "Celerina (Switzerland)",
  "Celigny (Switzerland)",
  "Cernier (Switzerland)",
  "Chalais (Switzerland)",
  "Cham (Switzerland)",
  "Chambesy (Switzerland)",
  "Chamoson (Switzerland)",
  "Champagne (Switzerland)",
  "Champex (Switzerland)",
  "Champsec (Switzerland)",
  "Chancy (Switzerland)",
  "Chapelle (Switzerland)",
  "Chardonne (Switzerland)",
  "Charmey (Switzerland)",
  "Charrat-les-Chenes (Switzerland)",
  "Chatel-Saint-Denis (Switzerland)",
  "Chatelaine (Switzerland)",
  "Chavannes (Switzerland)",
  "Chavannes de Bogis (Switzerland)",
  "Chavannes-les-Forts (Switzerland)",
  "Chavornay (Switzerland)",
  "Cheiry (Switzerland)",
  "Chene-Bougeries (Switzerland)",
  "Chene-Bourg (Switzerland)",
  "Chene-Paquier (Switzerland)",
  "Cheseaux-sur-Lausanne (Switzerland)",
  "Chesieres (Switzerland)",
  "Chexbres (Switzerland)",
  "Cheyres (Switzerland)",
  "Chez-le-Bart (Switzerland)",
  "Chiasso (Switzerland)",
  "Chippis (Switzerland)",
  "Chur (Switzerland)",
  "Claro (Switzerland)",
  "Coeuve (Switzerland)",
  "Cointrin (Switzerland)",
  "Collombey (Switzerland)",
  "Colombier (Switzerland)",
  "Concise (Switzerland)",
  "Confignon (Switzerland)",
  "Conthey (Switzerland)",
  "Contone (Switzerland)",
  "Coppet (Switzerland)",
  "Corcelles (Switzerland)",
  "Cordast (Switzerland)",
  "Corgemont (Switzerland)",
  "Corminboeuf (Switzerland)",
  "Cornaux (Switzerland)",
  "Corsier (Switzerland)",
  "Cortaillod (Switzerland)",
  "Cossonay (Switzerland)",
  "Cottens (Switzerland)",
  "Courfaivre (Switzerland)",
  "Courgenay (Switzerland)",
  "Courrendlin (Switzerland)",
  "Courroux (Switzerland)",
  "Court (Switzerland)",
  "Courtedoux (Switzerland)",
  "Courtelary (Switzerland)",
  "Courtemaiche (Switzerland)",
  "Courtepin (Switzerland)",
  "Courtetelle (Switzerland)",
  "Courtion (Switzerland)",
  "Couvet (Switzerland)",
  "Crassier (Switzerland)",
  "Cremines (Switzerland)",
  "Cressier (Switzerland)",
  "Crissier (Switzerland)",
  "Cudrefin (Switzerland)",
  "Cugnasco (Switzerland)",
  "Cugy (Switzerland)",
  "Cully (Switzerland)",
  "Dachsen (Switzerland)",
  "Daettlikon (Switzerland)",
  "Dagmersellen (Switzerland)",
  "Daillens (Switzerland)",
  "Dallenwil (Switzerland)",
  "Daniken (Switzerland)",
  "Dardagny (Switzerland)",
  "Dattwil (Switzerland)",
  "Davos (Switzerland)",
  "Davos Dorf (Switzerland)",
  "Davos Platz (Switzerland)",
  "Degersheim (Switzerland)",
  "Deitingen (Switzerland)",
  "Densbueren (Switzerland)",
  "Derendingen (Switzerland)",
  "Diegten (Switzerland)",
  "Dielsdorf (Switzerland)",
  "Diepoldsau (Switzerland)",
  "Diessbach (Switzerland)",
  "Diessenhofen (Switzerland)",
  "Dietikon (Switzerland)",
  "Doettingen (Switzerland)",
  "Domat (Switzerland)",
  "Dombresson (Switzerland)",
  "Domdidier (Switzerland)",
  "Dompierre (Switzerland)",
  "Dornach (Switzerland)",
  "Dottikon (Switzerland)",
  "Dozwil (Switzerland)",
  "Dubendorf (Switzerland)",
  "Durnten (Switzerland)",
  "Durrenasch (Switzerland)",
  "Ebikon (Switzerland)",
  "Ebnat-Kappel (Switzerland)",
  "Ecublens (Switzerland)",
  "Ecuvillens (Switzerland)",
  "Effretikon (Switzerland)",
  "Egerkingen (Switzerland)",
  "Egg (Switzerland)",
  "Eggenwil (Switzerland)",
  "Eggersriet (Switzerland)",
  "Eggiwil (Switzerland)",
  "Eglisau (Switzerland)",
  "Egliswil (Switzerland)",
  "Egnach (Switzerland)",
  "Eich (Switzerland)",
  "Eichberg (Switzerland)",
  "Eiken (Switzerland)",
  "Einigen (Switzerland)",
  "Einsiedeln (Switzerland)",
  "Elgg (Switzerland)",
  "Ellikon an der Thur (Switzerland)",
  "Elsau-Raeterschen (Switzerland)",
  "Embrach (Switzerland)",
  "Emmen (Switzerland)",
  "Emmenbruecke (Switzerland)",
  "Emmetten (Switzerland)",
  "Endingen (Switzerland)",
  "Engelberg (Switzerland)",
  "Ennenda (Switzerland)",
  "Enney (Switzerland)",
  "Entlebuch (Switzerland)",
  "Epalinges (Switzerland)",
  "Epautheyres (Switzerland)",
  "Ependes (Switzerland)",
  "Erde (Switzerland)",
  "Erlach (Switzerland)",
  "Erlen (Switzerland)",
  "Erlenbach (Switzerland)",
  "Ermatingen (Switzerland)",
  "Erschwil (Switzerland)",
  "Ersigen (Switzerland)",
  "Erstfeld (Switzerland)",
  "Eschenbach (Switzerland)",
  "Eschlikon (Switzerland)",
  "Escholzmatt (Switzerland)",
  "Essertines-sur-Rolle (Switzerland)",
  "Estavayer-le-Lac (Switzerland)",
  "Ettingen (Switzerland)",
  "Euthal (Switzerland)",
  "Evilard (Switzerland)",
  "Evionnaz (Switzerland)",
  "Eysins (Switzerland)",
  "Fahrwangen (Switzerland)",
  "Fallanden (Switzerland)",
  "Faoug (Switzerland)",
  "Fehraltorf (Switzerland)",
  "Felben (Switzerland)",
  "Feldbach (Switzerland)",
  "Feldbrunnen (Switzerland)",
  "Feldmeilen (Switzerland)",
  "Felsberg (Switzerland)",
  "Felsenau (Switzerland)",
  "Ferden (Switzerland)",
  "Ferenbalm (Switzerland)",
  "Feuerthalen (Switzerland)",
  "Feusisberg (Switzerland)",
  "Finhaut (Switzerland)",
  "Fischenthal (Switzerland)",
  "Fischingen (Switzerland)",
  "Flaach (Switzerland)",
  "Flamatt (Switzerland)",
  "Flawil (Switzerland)",
  "Fleurier (Switzerland)",
  "Flims (Switzerland)",
  "Fluehli (Switzerland)",
  "Flueli (Switzerland)",
  "Flums (Switzerland)",
  "Flurlingen (Switzerland)",
  "Fontainemelon (Switzerland)",
  "Forel (Switzerland)",
  "Founex (Switzerland)",
  "Fraubrunnen (Switzerland)",
  "Frauenfeld (Switzerland)",
  "Freidorf (Switzerland)",
  "Freienbach (Switzerland)",
  "Frenkendorf (Switzerland)",
  "Fribourg (Switzerland)",
  "Frick (Switzerland)",
  "Froideville (Switzerland)",
  "Frutigen (Switzerland)",
  "Fulenbach (Switzerland)",
  "Full (Switzerland)",
  "Fullinsdorf (Switzerland)",
  "Fully (Switzerland)",
  "Furstenaubruck (Switzerland)",
  "Gachlingen (Switzerland)",
  "Gais (Switzerland)",
  "Gampel (Switzerland)",
  "Gampelen (Switzerland)",
  "Gandria (Switzerland)",
  "Ganterschwil (Switzerland)",
  "Gebenstorf (Switzerland)",
  "Gelfingen (Switzerland)",
  "Gelterkinden (Switzerland)",
  "Genestrerio (Switzerland)",
  "Geneva (Switzerland)",
  "Gerlafingen (Switzerland)",
  "Gerlikon (Switzerland)",
  "Gersau (Switzerland)",
  "Gerzensee (Switzerland)",
  "Gettnau (Switzerland)",
  "Geuensee (Switzerland)",
  "Gimel (Switzerland)",
  "Gingins (Switzerland)",
  "Gipf-Oberfrick (Switzerland)",
  "Giswil (Switzerland)",
  "Giubiasco (Switzerland)",
  "Givrins (Switzerland)",
  "Gland (Switzerland)",
  "Glaris (Switzerland)",
  "Glarus (Switzerland)",
  "Glattburg (Switzerland)",
  "Glattfelden (Switzerland)",
  "Glion (Switzerland)",
  "Glis (Switzerland)",
  "Gnosca (Switzerland)",
  "Goldach (Switzerland)",
  "Goldau (Switzerland)",
  "Goldiwil (Switzerland)",
  "Gommiswald (Switzerland)",
  "Gonten (Switzerland)",
  "Gontenschwil (Switzerland)",
  "Gordevio (Switzerland)",
  "Gordola (Switzerland)",
  "Goslikon (Switzerland)",
  "Gossau (Switzerland)",
  "Gottlieben (Switzerland)",
  "Grabs (Switzerland)",
  "Graenichen (Switzerland)",
  "Grancy (Switzerland)",
  "Grandcour (Switzerland)",
  "Grandson (Switzerland)",
  "Grandval (Switzerland)",
  "Grandvillard (Switzerland)",
  "Granges (Switzerland)",
  "Granges-pres-Sion (Switzerland)",
  "Gravesano (Switzerland)",
  "Greifensee (Switzerland)",
  "Grellingen (Switzerland)",
  "Grenchen (Switzerland)",
  "Greppen (Switzerland)",
  "Grimentz (Switzerland)",
  "Grimisuat (Switzerland)",
  "Grindelwald (Switzerland)",
  "Grolley (Switzerland)",
  "Grone (Switzerland)",
  "Grono (Switzerland)",
  "Grossandelfingen (Switzerland)",
  "Grosswangen (Switzerland)",
  "Grueningen (Switzerland)",
  "Grund (Switzerland)",
  "Gryon (Switzerland)",
  "Gstaad (Switzerland)",
  "Gumligen (Switzerland)",
  "Gummenen (Switzerland)",
  "Gunten (Switzerland)",
  "Gunzgen (Switzerland)",
  "Guttingen (Switzerland)",
  "Gwatt (Switzerland)",
  "Habkern (Switzerland)",
  "Habsburg (Switzerland)",
  "Hagenbuch (Switzerland)",
  "Hagendorf (Switzerland)",
  "Hagglingen (Switzerland)",
  "Hagneck (Switzerland)",
  "Haldenstein (Switzerland)",
  "Hasle (Switzerland)",
  "Hauptwil (Switzerland)",
  "Hausen (Switzerland)",
  "Hausen am Albis / Hausen (Dorf) (Switzerland)",
  "Hauterive (Switzerland)",
  "Hauteville (Switzerland)",
  "Heerbrugg (Switzerland)",
  "Heiden (Switzerland)",
  "Heimberg (Switzerland)",
  "Heimiswil (Switzerland)",
  "Heitenried (Switzerland)",
  "Hellbuehl (Switzerland)",
  "Hemmental (Switzerland)",
  "Henau (Switzerland)",
  "Hendschiken (Switzerland)",
  "Henggart (Switzerland)",
  "Herbetswil (Switzerland)",
  "Herdern (Switzerland)",
  "Hergiswil (Switzerland)",
  "Herisau (Switzerland)",
  "Hermance (Switzerland)",
  "Hermetschwil-Staffeln (Switzerland)",
  "Herrliberg (Switzerland)",
  "Herzogenbuchsee (Switzerland)",
  "Hettlingen (Switzerland)",
  "Hildisrieden (Switzerland)",
  "Hilterfingen (Switzerland)",
  "Himmelried (Switzerland)",
  "Hindelbank (Switzerland)",
  "Hinterkappelen (Switzerland)",
  "Hinwil (Switzerland)",
  "Hirzel-Kirche (Switzerland)",
  "Hittnau (Switzerland)",
  "Hitzkirch (Switzerland)",
  "Hochdorf (Switzerland)",
  "Hochwald (Switzerland)",
  "Hoerstetten (Switzerland)",
  "Hofstetten (Switzerland)",
  "Holderbank (Switzerland)",
  "Holstein (Switzerland)",
  "Hombrechtikon (Switzerland)",
  "Horgen (Switzerland)",
  "Horn (Switzerland)",
  "Hornussen (Switzerland)",
  "Horw (Switzerland)",
  "Hundwil (Switzerland)",
  "Hunenberg (Switzerland)",
  "Hunibach (Switzerland)",
  "Huntwangen (Switzerland)",
  "Hunzenschwil (Switzerland)",
  "Huttwil (Switzerland)",
  "Ibach (Switzerland)",
  "Igis (Switzerland)",
  "Ilanz (Switzerland)",
  "Illnau (Switzerland)",
  "Immensee (Switzerland)",
  "Ingenbohl (Switzerland)",
  "Ins (Switzerland)",
  "Interlaken (Switzerland)",
  "Inwil (Switzerland)",
  "Iragna (Switzerland)",
  "Iseo (Switzerland)",
  "Iserables (Switzerland)",
  "Islikon (Switzerland)",
  "Ittenthal (Switzerland)",
  "Jegenstorf (Switzerland)",
  "Jenaz (Switzerland)",
  "Jona (Switzerland)",
  "Jonen (Switzerland)",
  "Jonschwil (Switzerland)",
  "Jussy (Switzerland)",
  "Kaiseraugst (Switzerland)",
  "Kaiserstuhl (Switzerland)",
  "Kaisten (Switzerland)",
  "Kallnach (Switzerland)",
  "Kaltbrunn (Switzerland)",
  "Kappel (Switzerland)",
  "Kastanienbaum (Switzerland)",
  "Kefikon (Switzerland)",
  "Kehrsatz (Switzerland)",
  "Kempttal (Switzerland)",
  "Kernenried (Switzerland)",
  "Kerns (Switzerland)",
  "Kerzers (Switzerland)",
  "Kiesen (Switzerland)",
  "Kilchberg (Switzerland)",
  "Killwangen (Switzerland)",
  "Kirchberg (Switzerland)",
  "Kirchdorf (Switzerland)",
  "Kleinlutzel (Switzerland)",
  "Klingnau (Switzerland)",
  "Klosters Platz (Switzerland)",
  "Klosters Serneus (Switzerland)",
  "Kloten (Switzerland)",
  "Koblenz (Switzerland)",
  "Kollbrunn (Switzerland)",
  "Kolliken (Switzerland)",
  "Konolfingen (Switzerland)",
  "Koppigen (Switzerland)",
  "Kradolf (Switzerland)",
  "Krauchthal (Switzerland)",
  "Kreuzlingen (Switzerland)",
  "Kriegstetten (Switzerland)",
  "Kriens (Switzerland)",
  "Kronbuhl (Switzerland)",
  "Kuesnacht (Switzerland)",
  "Kulm (Switzerland)",
  "La Chaux (Switzerland)",
  "La Chaux-de-Fonds (Switzerland)",
  "La Chiesaz (Switzerland)",
  "La Neuveville (Switzerland)",
  "La Plaine (Switzerland)",
  "La Roche (Switzerland)",
  "La Sarraz (Switzerland)",
  "La Tour-de-Peilz (Switzerland)",
  "Laax (Switzerland)",
  "Lachen (Switzerland)",
  "Lamboing (Switzerland)",
  "Landquart (Switzerland)",
  "Langenbruck (Switzerland)",
  "Langenthal (Switzerland)",
  "Langnau (Switzerland)",
  "Langnau am Albis (Switzerland)",
  "Lauenen (Switzerland)",
  "Lauerz (Switzerland)",
  "Laufelfingen (Switzerland)",
  "Laufen (Switzerland)",
  "Laufenburg (Switzerland)",
  "Laupen (Switzerland)",
  "Lausanne (Switzerland)",
  "Lausen (Switzerland)",
  "Le Brassus (Switzerland)",
  "Le Bry (Switzerland)",
  "Le Cret (Switzerland)",
  "Le Grand-Saconnex (Switzerland)",
  "Le Landeron (Switzerland)",
  "Le Locle (Switzerland)",
  "Le Mont-sur-Lausanne (Switzerland)",
  "Le Noirmont (Switzerland)",
  "Le Sentier (Switzerland)",
  "Le Vaud (Switzerland)",
  "Leibstadt (Switzerland)",
  "Lengnau (Switzerland)",
  "Lenk (Switzerland)",
  "Lenz (Switzerland)",
  "Lenzburg (Switzerland)",
  "Lenzerheide (Switzerland)",
  "Les Acacias (Switzerland)",
  "Les Breuleux (Switzerland)",
  "Les Diablerets (Switzerland)",
  "Les Geneveys-sur-Coffrane (Switzerland)",
  "Les Genevez (Switzerland)",
  "Les Hauts-Geneveys (Switzerland)",
  "Les Pommerats (Switzerland)",
  "Les Verrieres (Switzerland)",
  "Leukerbad (Switzerland)",
  "Leutwil (Switzerland)",
  "Leysin (Switzerland)",
  "Lichtensteig (Switzerland)",
  "Liddes (Switzerland)",
  "Liebistorf (Switzerland)",
  "Lienz (Switzerland)",
  "Liestal (Switzerland)",
  "Ligerz (Switzerland)",
  "Lignieres (Switzerland)",
  "Lindau (Switzerland)",
  "Littau (Switzerland)",
  "Locarno (Switzerland)",
  "Loco (Switzerland)",
  "Lodrino (Switzerland)",
  "Lohn (Switzerland)",
  "Lommis (Switzerland)",
  "Losone (Switzerland)",
  "Lostorf (Switzerland)",
  "Lotzwil (Switzerland)",
  "Lucens (Switzerland)",
  "Lucerne (Switzerland)",
  "Lugano (Switzerland)",
  "Lungern (Switzerland)",
  "Lurtigen (Switzerland)",
  "Lutisburg (Switzerland)",
  "Lutry (Switzerland)",
  "Luvis (Switzerland)",
  "Lyss (Switzerland)",
  "Lyssach (Switzerland)",
  "Maerstetten-Dorf (Switzerland)",
  "Magadino (Switzerland)",
  "Magden (Switzerland)",
  "Maggia (Switzerland)",
  "Maisprach (Switzerland)",
  "Malleray (Switzerland)",
  "Malters (Switzerland)",
  "Mannedorf (Switzerland)",
  "Manno (Switzerland)",
  "Marin (Switzerland)",
  "Maroggia (Switzerland)",
  "Marsens (Switzerland)",
  "Martigny-Croix (Switzerland)",
  "Martigny-Ville (Switzerland)",
  "Maschwanden (Switzerland)",
  "Massagno (Switzerland)",
  "Matt (Switzerland)",
  "Matzendorf (Switzerland)",
  "Matzingen (Switzerland)",
  "Mauensee (Switzerland)",
  "Maur (Switzerland)",
  "Meggen (Switzerland)",
  "Meierskappel (Switzerland)",
  "Meilen (Switzerland)",
  "Meinier (Switzerland)",
  "Meinisberg (Switzerland)",
  "Meiringen (Switzerland)",
  "Meisterschwanden (Switzerland)",
  "Melano (Switzerland)",
  "Melchnau (Switzerland)",
  "Melide (Switzerland)",
  "Mellingen (Switzerland)",
  "Mels (Switzerland)",
  "Mendrisio (Switzerland)",
  "Menziken (Switzerland)",
  "Menzingen (Switzerland)",
  "Merenschwand (Switzerland)",
  "Merishausen (Switzerland)",
  "Messen (Switzerland)",
  "Mettendorf (Switzerland)",
  "Mettlen (Switzerland)",
  "Mettmenstetten (Switzerland)",
  "Meyrin (Switzerland)",
  "Miecourt (Switzerland)",
  "Minusio (Switzerland)",
  "Mohlin (Switzerland)",
  "Mollis (Switzerland)",
  "Monchaltorf (Switzerland)",
  "Montagnola (Switzerland)",
  "Montana (Switzerland)",
  "Montet (Switzerland)",
  "Monthey (Switzerland)",
  "Montreux (Switzerland)",
  "Moosleerau (Switzerland)",
  "Morbio Inferiore (Switzerland)",
  "Morcote (Switzerland)",
  "Morges (Switzerland)",
  "Morgins (Switzerland)",
  "Morigen (Switzerland)",
  "Moriken (Switzerland)",
  "Morschach (Switzerland)",
  "Morschwil (Switzerland)",
  "Mosnang (Switzerland)",
  "Motiers (Switzerland)",
  "Moudon (Switzerland)",
  "Moutier (Switzerland)",
  "Muerren (Switzerland)",
  "Muhen (Switzerland)",
  "Muhlau (Switzerland)",
  "Muhleberg (Switzerland)",
  "Mullheim (Switzerland)",
  "Mumliswil (Switzerland)",
  "Mumpf (Switzerland)",
  "Munchenbuchsee (Switzerland)",
  "Munchenstein (Switzerland)",
  "Munchwilen (Switzerland)",
  "Munsingen (Switzerland)",
  "Munster (Switzerland)",
  "Muolen (Switzerland)",
  "Muotathal (Switzerland)",
  "Murgenthal (Switzerland)",
  "Muri (Switzerland)",
  "Muri bei Bern (Switzerland)",
  "Murten (Switzerland)",
  "Muttenz (Switzerland)",
  "Nafels (Switzerland)",
  "Nanikon (Switzerland)",
  "Naters (Switzerland)",
  "Nax (Switzerland)",
  "Neftenbach (Switzerland)",
  "Nesslau (Switzerland)",
  "Netstal (Switzerland)",
  "Neu-Rheinau (Switzerland)",
  "Neuchatel (Switzerland)",
  "Neuenhof (Switzerland)",
  "Neuenkirch (Switzerland)",
  "Neuhausen (Switzerland)",
  "Neuheim (Switzerland)",
  "Neukirch (Switzerland)",
  "Neunkirch (Switzerland)",
  "Neyruz (Switzerland)",
  "Nidau (Switzerland)",
  "Niederbipp (Switzerland)",
  "Niederbuchsiten (Switzerland)",
  "Niederburen (Switzerland)",
  "Niedererlinsbach (Switzerland)",
  "Niedergosgen (Switzerland)",
  "Niederhallwil (Switzerland)",
  "Niederhelfenschwil (Switzerland)",
  "Niederlenz (Switzerland)",
  "Niederrohrdorf (Switzerland)",
  "Niederscherli (Switzerland)",
  "Niederurnen (Switzerland)",
  "Niederuzwil (Switzerland)",
  "Niederweningen (Switzerland)",
  "Nohl (Switzerland)",
  "Noreaz (Switzerland)",
  "Nottwil (Switzerland)",
  "Novaggio (Switzerland)",
  "Novazzano (Switzerland)",
  "Nunningen (Switzerland)",
  "Nurensdorf (Switzerland)",
  "Nussbaumen (Switzerland)",
  "Nyon (Switzerland)",
  "Ober Urdorf (Switzerland)",
  "Oberbalm (Switzerland)",
  "Oberbipp (Switzerland)",
  "Oberbuchsiten (Switzerland)",
  "Oberburg (Switzerland)",
  "Oberdiessbach (Switzerland)",
  "Oberdorf (Switzerland)",
  "Oberegg (Switzerland)",
  "Oberembrach (Switzerland)",
  "Oberengstringen (Switzerland)",
  "Oberentfelden (Switzerland)",
  "Obergerlafingen (Switzerland)",
  "Oberglatt (Switzerland)",
  "Obergosgen (Switzerland)",
  "Oberhelfenschwil (Switzerland)",
  "Oberhofen (Switzerland)",
  "Oberhofen bei Etzgen (Switzerland)",
  "Oberkirch (Switzerland)",
  "Oberlunkhofen (Switzerland)",
  "Oberonz (Switzerland)",
  "Oberrieden (Switzerland)",
  "Oberriet (Switzerland)",
  "Obersaxen (Switzerland)",
  "Oberschlatt (Switzerland)",
  "Oberstocken (Switzerland)",
  "Oberuzwil (Switzerland)",
  "Obervaz (Switzerland)",
  "Oberwangen (Switzerland)",
  "Oberweningen (Switzerland)",
  "Oberwil (Switzerland)",
  "Obfelden (Switzerland)",
  "Oensingen (Switzerland)",
  "Oetwil / Oetwil an der Limmat (Switzerland)",
  "Oetwil am See (Switzerland)",
  "Oftringen (Switzerland)",
  "Olten (Switzerland)",
  "Onex (Switzerland)",
  "Onnens (Switzerland)",
  "Opfikon (Switzerland)",
  "Orbe (Switzerland)",
  "Ormalingen (Switzerland)",
  "Oron-la-ville (Switzerland)",
  "Orpund (Switzerland)",
  "Orsonnens (Switzerland)",
  "Orvin (Switzerland)",
  "Ossingen (Switzerland)",
  "Ostermundigen (Switzerland)",
  "Otelfingen (Switzerland)",
  "Othmarsingen (Switzerland)",
  "Ottenbach (Switzerland)",
  "Ouchy (Switzerland)",
  "Ovronnaz (Switzerland)",
  "Palezieux (Switzerland)",
  "Pampigny (Switzerland)",
  "Paudex (Switzerland)",
  "Payerne (Switzerland)",
  "Perlen (Switzerland)",
  "Perroy (Switzerland)",
  "Pery (Switzerland)",
  "Peseux (Switzerland)",
  "Pfaeffikon (Switzerland)",
  "Pfaffnau (Switzerland)",
  "Pfeffikon (Switzerland)",
  "Pfeffingen (Switzerland)",
  "Pfungen (Switzerland)",
  "Pfyn (Switzerland)",
  "Pieterlen (Switzerland)",
  "Plaffeien (Switzerland)",
  "Plan-les-Ouates (Switzerland)",
  "Porrentruy (Switzerland)",
  "Porsel (Switzerland)",
  "Portalban- Dessous (Switzerland)",
  "Prangins (Switzerland)",
  "Pratteln (Switzerland)",
  "Pregassona (Switzerland)",
  "Preles (Switzerland)",
  "Preverenges (Switzerland)",
  "Prilly (Switzerland)",
  "Puidoux (Switzerland)",
  "Pully (Switzerland)",
  "Pura (Switzerland)",
  "Quartino (Switzerland)",
  "Rafz (Switzerland)",
  "Ramsen (Switzerland)",
  "Rances (Switzerland)",
  "Raperswilen (Switzerland)",
  "Rapperswil (Switzerland)",
  "Raron (Switzerland)",
  "Rebstein (Switzerland)",
  "Rechthalten (Switzerland)",
  "Reconvilier (Switzerland)",
  "Regensdorf (Switzerland)",
  "Rehetobel (Switzerland)",
  "Reichenburg (Switzerland)",
  "Reiden (Switzerland)",
  "Reigoldswil (Switzerland)",
  "Reinach (Switzerland)",
  "Rekingen (Switzerland)",
  "Renens (Switzerland)",
  "Rhazuns (Switzerland)",
  "Rheineck (Switzerland)",
  "Rheinfelden (Switzerland)",
  "Riaz (Switzerland)",
  "Richenthal (Switzerland)",
  "Richigen (Switzerland)",
  "Richterswil (Switzerland)",
  "Ricken (Switzerland)",
  "Rickenbach (Switzerland)",
  "Riddes (Switzerland)",
  "Ried (Switzerland)",
  "Riedholz (Switzerland)",
  "Rifferswil (Switzerland)",
  "Riggisberg (Switzerland)",
  "Rikon / Rikon (Dorfkern) (Switzerland)",
  "Ringgenberg (Switzerland)",
  "Riva San Vitale (Switzerland)",
  "Roche (Switzerland)",
  "Roemerswil (Switzerland)",
  "Roggwil (Switzerland)",
  "Rohr (Switzerland)",
  "Rohrbach (Switzerland)",
  "Rolle (Switzerland)",
  "Romainmotier (Switzerland)",
  "Romanel (Switzerland)",
  "Romanshorn (Switzerland)",
  "Romont (Switzerland)",
  "Rondchatel (Switzerland)",
  "Root (Switzerland)",
  "Rorbas (Switzerland)",
  "Rorschach (Switzerland)",
  "Roschenz (Switzerland)",
  "Rossens (Switzerland)",
  "Rothenbach (Switzerland)",
  "Rothenthurm (Switzerland)",
  "Rothrist (Switzerland)",
  "Rotkreuz (Switzerland)",
  "Roveredo (Switzerland)",
  "Rubigen (Switzerland)",
  "Rudlingen (Switzerland)",
  "Rudolfstetten (Switzerland)",
  "Rue (Switzerland)",
  "Rueggisberg (Switzerland)",
  "Rueti (Switzerland)",
  "Rueyres (Switzerland)",
  "Rufi (Switzerland)",
  "Rumlang (Switzerland)",
  "Rupperswil (Switzerland)",
  "Ruschlikon (Switzerland)",
  "Russikon (Switzerland)",
  "Russin (Switzerland)",
  "Ruswil (Switzerland)",
  "Ruthi (Switzerland)",
  "Saanen (Switzerland)",
  "Saanenmoser (Switzerland)",
  "Saas-Fee (Switzerland)",
  "Saas-Grund (Switzerland)",
  "Sachseln (Switzerland)",
  "Safenwil (Switzerland)",
  "Saignelegier (Switzerland)",
  "Saint-Aubin (Switzerland)",
  "Saint-Aubin-Sauges (Switzerland)",
  "Saint-Blaise (Switzerland)",
  "Saint-Cergue (Switzerland)",
  "Saint-Imier (Switzerland)",
  "Saint-Leonard (Switzerland)",
  "Saint-Livres (Switzerland)",
  "Saint-Maurice (Switzerland)",
  "Saint-Prex (Switzerland)",
  "Saint-Sulpice (Switzerland)",
  "Sainte-Croix (Switzerland)",
  "Salmsach (Switzerland)",
  "Samstagern (Switzerland)",
  "Sankt Gallenkappel (Switzerland)",
  "Sankt Margrethen (Switzerland)",
  "Sargans (Switzerland)",
  "Sariswil (Switzerland)",
  "Sarmenstorf (Switzerland)",
  "Sarn (Switzerland)",
  "Sarnen (Switzerland)",
  "Satigny (Switzerland)",
  "Sattel (Switzerland)",
  "Saviese (Switzerland)",
  "Savigny (Switzerland)",
  "Savognin (Switzerland)",
  "Saxon (Switzerland)",
  "Schachen (Switzerland)",
  "Schaffhausen (Switzerland)",
  "Schafisheim (Switzerland)",
  "Schanis (Switzerland)",
  "Schattdorf (Switzerland)",
  "Schenkon (Switzerland)",
  "Schiers (Switzerland)",
  "Schindellegi (Switzerland)",
  "Schinznach Bad (Switzerland)",
  "Schinznach Dorf (Switzerland)",
  "Schlatt (Switzerland)",
  "Schleitheim (Switzerland)",
  "Schlieren (Switzerland)",
  "Schmerikon (Switzerland)",
  "Schmitten (Switzerland)",
  "Schnottwil (Switzerland)",
  "Schoetz (Switzerland)",
  "Schoftland (Switzerland)",
  "Schonbuhl (Switzerland)",
  "Schonenberg (Switzerland)",
  "Schonenbuch (Switzerland)",
  "Schonenwerd (Switzerland)",
  "Schongau (Switzerland)",
  "Schonholzerswilen (Switzerland)",
  "Schonried (Switzerland)",
  "Schubelbach (Switzerland)",
  "Schuepfheim (Switzerland)",
  "Schupfen (Switzerland)",
  "Schwarzenberg (Switzerland)",
  "Schwarzenburg (Switzerland)",
  "Schwellbrunn (Switzerland)",
  "Schwyz (Switzerland)",
  "Scuol (Switzerland)",
  "Seedorf (Switzerland)",
  "Seegraben (Switzerland)",
  "Seelisberg (Switzerland)",
  "Seengen (Switzerland)",
  "Seewis im Pratigau (Switzerland)",
  "Seftigen (Switzerland)",
  "Seltisberg (Switzerland)",
  "Selzach (Switzerland)",
  "Sembrancher (Switzerland)",
  "Sementina (Switzerland)",
  "Sempach (Switzerland)",
  "Semsales (Switzerland)",
  "Sennwald (Switzerland)",
  "Seon (Switzerland)",
  "Sessa (Switzerland)",
  "Seuzach Dorf (Switzerland)",
  "Sevelen (Switzerland)",
  "Sezegnin (Switzerland)",
  "Siebnen (Switzerland)",
  "Sierre (Switzerland)",
  "Siglistorf (Switzerland)",
  "Sigriswil (Switzerland)",
  "Sils-Segl Maria (Switzerland)",
  "Sins (Switzerland)",
  "Sion (Switzerland)",
  "Sirnach (Switzerland)",
  "Siselen (Switzerland)",
  "Sissach (Switzerland)",
  "Siviriez (Switzerland)",
  "Solothurn (Switzerland)",
  "Sonceboz (Switzerland)",
  "Sonvico (Switzerland)",
  "Sonvilier (Switzerland)",
  "Sorengo (Switzerland)",
  "Sottens (Switzerland)",
  "Soyhieres (Switzerland)",
  "Speicher (Switzerland)",
  "Spiez (Switzerland)",
  "Spreitenbach (Switzerland)",
  "St. Gallen (Switzerland)",
  "Stabio (Switzerland)",
  "Stadel (Switzerland)",
  "Stafa (Switzerland)",
  "Stallikon (Switzerland)",
  "Stans (Switzerland)",
  "Stansstad (Switzerland)",
  "Staufen (Switzerland)",
  "Steckborn (Switzerland)",
  "Steffisburg (Switzerland)",
  "Steg (Switzerland)",
  "Stein (Switzerland)",
  "Stein am Rhein (Switzerland)",
  "Steinen (Switzerland)",
  "Steinhausen (Switzerland)",
  "Stettfurt (Switzerland)",
  "Stettlen (Switzerland)",
  "Stoos (Switzerland)",
  "Strengelbach (Switzerland)",
  "Subingen (Switzerland)",
  "Suhr (Switzerland)",
  "Sulgen (Switzerland)",
  "Sulz (Switzerland)",
  "Sumiswald (Switzerland)",
  "Sursee (Switzerland)",
  "Tafers (Switzerland)",
  "Tagerwilen (Switzerland)",
  "Tamins (Switzerland)",
  "Tanay (Switzerland)",
  "Tann (Switzerland)",
  "Tasch (Switzerland)",
  "Tauffelen (Switzerland)",
  "Tavannes (Switzerland)",
  "Tegerfelden (Switzerland)",
  "Tenero (Switzerland)",
  "Termen (Switzerland)",
  "Territet (Switzerland)",
  "Tesserete (Switzerland)",
  "Teufen AR (Switzerland)",
  "Teufenthal (Switzerland)",
  "Thalheim (Switzerland)",
  "Thalwil (Switzerland)",
  "Thayngen (Switzerland)",
  "Therwil (Switzerland)",
  "Thielle (Switzerland)",
  "Thierachern (Switzerland)",
  "Thierrens (Switzerland)",
  "Thonex (Switzerland)",
  "Thorishaus (Switzerland)",
  "Thun (Switzerland)",
  "Thundorf (Switzerland)",
  "Thusis (Switzerland)",
  "Toffen (Switzerland)",
  "Tramelan (Switzerland)",
  "Trasadingen (Switzerland)",
  "Travers (Switzerland)",
  "Treyvaux (Switzerland)",
  "Triengen (Switzerland)",
  "Trimbach (Switzerland)",
  "Trimmis (Switzerland)",
  "Trogen (Switzerland)",
  "Troistorrents (Switzerland)",
  "Trubbach (Switzerland)",
  "Trubschachen (Switzerland)",
  "Trun (Switzerland)",
  "Tschuggen (Switzerland)",
  "Tuggen (Switzerland)",
  "Turbenthal (Switzerland)",
  "Turgi (Switzerland)",
  "Twann (Switzerland)",
  "Udligenswil (Switzerland)",
  "Ueberstorf (Switzerland)",
  "Uerikon (Switzerland)",
  "Uerkheim (Switzerland)",
  "Uetendorf (Switzerland)",
  "Uetikon (Switzerland)",
  "Ufhusen (Switzerland)",
  "Uhwiesen (Switzerland)",
  "Uitikon (Switzerland)",
  "Ulrichen (Switzerland)",
  "Unter-Teufen (Switzerland)",
  "Unterageri (Switzerland)",
  "Unterehrendingen (Switzerland)",
  "Unterengstringen (Switzerland)",
  "Untererlinsbach (Switzerland)",
  "Unteriberg (Switzerland)",
  "Unterkulm (Switzerland)",
  "Unterlunkhofen (Switzerland)",
  "Unterseen (Switzerland)",
  "Untersiggenthal (Switzerland)",
  "Untervaz (Switzerland)",
  "Ursenbach (Switzerland)",
  "Urtenen (Switzerland)",
  "Uster (Switzerland)",
  "Utzenstorf (Switzerland)",
  "Uznach (Switzerland)",
  "Uzwil (Switzerland)",
  "Vacallo (Switzerland)",
  "Vallorbe (Switzerland)",
  "Vandoeuvres (Switzerland)",
  "Vauderens (Switzerland)",
  "Vaulruz (Switzerland)",
  "Veltheim (Switzerland)",
  "Verbier (Switzerland)",
  "Vercorin (Switzerland)",
  "Vernayaz (Switzerland)",
  "Vernier (Switzerland)",
  "Verscio (Switzerland)",
  "Versoix (Switzerland)",
  "Vesenaz (Switzerland)",
  "Vessy (Switzerland)",
  "Vetroz (Switzerland)",
  "Vevey (Switzerland)",
  "Vex (Switzerland)",
  "Veyrier (Switzerland)",
  "Veytaux (Switzerland)",
  "Vicques (Switzerland)",
  "Viganello (Switzerland)",
  "Villa (Switzerland)",
  "Villars (Switzerland)",
  "Villars-sur-Glane (Switzerland)",
  "Villars-sur-Ollon (Switzerland)",
  "Villaz-Saint-Pierre (Switzerland)",
  "Villeneuve (Switzerland)",
  "Villeret (Switzerland)",
  "Villigen (Switzerland)",
  "Villmergen (Switzerland)",
  "Villnachern (Switzerland)",
  "Vilters (Switzerland)",
  "Vinzel (Switzerland)",
  "Vionnaz (Switzerland)",
  "Visp (Switzerland)",
  "Vitznau (Switzerland)",
  "Volketswil (Switzerland)",
  "Vordemwald (Switzerland)",
  "Vouvry (Switzerland)",
  "Vuadens (Switzerland)",
  "Vuiteboeuf (Switzerland)",
  "Vullierens (Switzerland)",
  "Wabern (Switzerland)",
  "Wadenswil (Switzerland)",
  "Wagenhausen (Switzerland)",
  "Wahlen (Switzerland)",
  "Walchwil (Switzerland)",
  "Wald (Switzerland)",
  "Waldenburg (Switzerland)",
  "Waldhaus (Switzerland)",
  "Waldkirch (Switzerland)",
  "Waldstatt (Switzerland)",
  "Walkringen (Switzerland)",
  "Wallisellen (Switzerland)",
  "Waltenschwil (Switzerland)",
  "Walzenhausen (Switzerland)",
  "Wangen (Switzerland)",
  "Wangen an der Aare (Switzerland)",
  "Wangi (Switzerland)",
  "Wangs (Switzerland)",
  "Wasen (Switzerland)",
  "Wattwil (Switzerland)",
  "Wauwil (Switzerland)",
  "Weesen (Switzerland)",
  "Wegenstetten (Switzerland)",
  "Weggis (Switzerland)",
  "Weinfelden (Switzerland)",
  "Weiningen (Switzerland)",
  "Weissbad (Switzerland)",
  "Weisslingen (Switzerland)",
  "Welschenrohr (Switzerland)",
  "Wengen (Switzerland)",
  "Werthenstein (Switzerland)",
  "Wettingen (Switzerland)",
  "Wetzikon (Switzerland)",
  "Wichtrach (Switzerland)",
  "Wiedlisbach (Switzerland)",
  "Wiesendangen (Switzerland)",
  "Wigoltingen (Switzerland)",
  "Wikon (Switzerland)",
  "Wil (Switzerland)",
  "Wila (Switzerland)",
  "Wildegg (Switzerland)",
  "Wilderswil (Switzerland)",
  "Wileroltigen (Switzerland)",
  "Willisau (Switzerland)",
  "Wimmis (Switzerland)",
  "Windisch (Switzerland)",
  "Winkel (Switzerland)",
  "Winterberg (Switzerland)",
  "Winterthur (Switzerland)",
  "Wittenbach (Switzerland)",
  "Wittnau (Switzerland)",
  "Wohlen (Switzerland)",
  "Wolfenschiessen (Switzerland)",
  "Wolfgang (Switzerland)",
  "Wolfhalden (Switzerland)",
  "Wolfwil (Switzerland)",
  "Wolhusen (Switzerland)",
  "Wollerau (Switzerland)",
  "Worb (Switzerland)",
  "Worben (Switzerland)",
  "Worblaufen (Switzerland)",
  "Wuppenau (Switzerland)",
  "Wurenlingen (Switzerland)",
  "Wurenlos (Switzerland)",
  "Wynau (Switzerland)",
  "Yens (Switzerland)",
  "Yverdon-les-Bains (Switzerland)",
  "Yvonand (Switzerland)",
  "Yvorne (Switzerland)",
  "Zaziwil (Switzerland)",
  "Zeglingen (Switzerland)",
  "Zeihen (Switzerland)",
  "Zell (Switzerland)",
  "Zermatt (Switzerland)",
  "Ziegelbrucke (Switzerland)",
  "Zihlschlacht (Switzerland)",
  "Zizers (Switzerland)",
  "Zofingen (Switzerland)",
  "Zollbruck (Switzerland)",
  "Zollikofen (Switzerland)",
  "Zollikon (Switzerland)",
  "Zuchwil (Switzerland)",
  "Zuckenriet (Switzerland)",
  "Zufikon (Switzerland)",
  "Zug (Switzerland)",
  "Zullwil (Switzerland)",
  "Zumikon (Switzerland)",
  "Zunzgen (Switzerland)",
  "Zurich (Switzerland)",
  "Zuzgen (Switzerland)",
  "Zuzwil (Switzerland)",
  "Zwingen (Switzerland)",
  "Taiwan",
  "Budai (Taiwan)",
  "Caogang (Taiwan)",
  "Chang-hua (Taiwan)",
  "Checheng (Taiwan)",
  "Chiayi (Taiwan)",
  "Dahu (Taiwan)",
  "Douliu (Taiwan)",
  "Erlin (Taiwan)",
  "Fanlu (Taiwan)",
  "Fengshan (Taiwan)",
  "Gangshan (Taiwan)",
  "Gaozhongyicun (Taiwan)",
  "Hemei (Taiwan)",
  "Hengchun (Taiwan)",
  "Hsinchu (Taiwan)",
  "Hualian (Taiwan)",
  "Jincheng (Taiwan)",
  "Kao-sung (Taiwan)",
  "Kaohsiung City (Taiwan)",
  "Keelung (Taiwan)",
  "Linkou (Taiwan)",
  "Lugang (Taiwan)",
  "Ma-kung (Taiwan)",
  "Maru (Taiwan)",
  "Matsu (Taiwan)",
  "Meilun (Taiwan)",
  "Miaoli (Taiwan)",
  "Nanhua (Taiwan)",
  "Nankan (Taiwan)",
  "Nantou (Taiwan)",
  "Neihu (Taiwan)",
  "Pingtung (Taiwan)",
  "Pozi (Taiwan)",
  "Puli (Taiwan)",
  "Shalun (Taiwan)",
  "Shetou (Taiwan)",
  "Shoufeng (Taiwan)",
  "Shuilin (Taiwan)",
  "Shuiliu (Taiwan)",
  "Shuishang (Taiwan)",
  "Shulin (Taiwan)",
  "Shuyi (Taiwan)",
  "Suqin (Taiwan)",
  "Taichang (Taiwan)",
  "Taichung (Taiwan)",
  "Tainan (Taiwan)",
  "Taipei (Taiwan)",
  "Taipingding (Taiwan)",
  "Taitung (Taiwan)",
  "Taoyuan (Taiwan)",
  "Toucheng (Taiwan)",
  "Tuchang (Taiwan)",
  "Wan-hua (Taiwan)",
  "Wangtongwen (Taiwan)",
  "Wanluan (Taiwan)",
  "Wutai (Taiwan)",
  "Xinji (Taiwan)",
  "Xinzhuang (Taiwan)",
  "Yangmei (Taiwan)",
  "Yanping (Taiwan)",
  "Yilan (Taiwan)",
  "Yuli (Taiwan)",
  "Yunlin County (Taiwan)",
  "Zhubei (Taiwan)",
  "Zhunan (Taiwan)",
  "Zhuqi (Taiwan)",
  "Tanzania",
  "Arusha (Tanzania)",
  "Bukoba (Tanzania)",
  "Dar es Salaam (Tanzania)",
  "Dodoma (Tanzania)",
  "Morogoro (Tanzania)",
  "Mwanza (Tanzania)",
  "Njombe (Tanzania)",
  "Raha (Tanzania)",
  "Tanga (Tanzania)",
  "Zanzibar (Tanzania)",
  "Thailand",
  "Amnat Charoen (Thailand)",
  "Amphawa (Thailand)",
  "Amphoe Aranyaprathet (Thailand)",
  "Ang Thong (Thailand)",
  "Ayutthaya (Thailand)",
  "Ban Ang Thong (Thailand)",
  "Ban Bang Phli Nakhon (Thailand)",
  "Ban Bang Plong (Thailand)",
  "Ban Bueng (Thailand)",
  "Ban Don Sak (Thailand)",
  "Ban Hua Thale (Thailand)",
  "Ban Khlong Ngae (Thailand)",
  "Ban Khlong Prasong (Thailand)",
  "Ban Khlong Prawet (Thailand)",
  "Ban Khlong Takhian (Thailand)",
  "Ban Ko Pao (Thailand)",
  "Ban Na (Thailand)",
  "Ban Nam Hak (Thailand)",
  "Ban Pat Mon (Thailand)",
  "Ban Phichit (Thailand)",
  "Ban Phlam (Thailand)",
  "Ban Pong Lang (Thailand)",
  "Ban Pratunam Tha Khai (Thailand)",
  "Ban Talat Rangsit (Thailand)",
  "Ban Tha Duea (Thailand)",
  "Ban Tha Pai (Thailand)",
  "Ban Tha Ruea (Thailand)",
  "Ban Tham (Thailand)",
  "Ban Wang Yai (Thailand)",
  "Bang Kapi (Thailand)",
  "Bang Khae (Thailand)",
  "Bang Khun Thian (Thailand)",
  "Bang Kruai (Thailand)",
  "Bang Lamung (Thailand)",
  "Bang Na (Thailand)",
  "Bang Phlat (Thailand)",
  "Bang Sao Thong (Thailand)",
  "Bang Sue (Thailand)",
  "Bangkok (Thailand)",
  "Buriram (Thailand)",
  "Cha-am (Thailand)",
  "Chachoengsao (Thailand)",
  "Chaiyaphum (Thailand)",
  "Chanthaburi (Thailand)",
  "Chatuchak (Thailand)",
  "Chiang Mai (Thailand)",
  "Chiang Muan (Thailand)",
  "Chiang Rai (Thailand)",
  "Chon Buri (Thailand)",
  "Chulabhorn (Thailand)",
  "Chumphon (Thailand)",
  "Din Daeng (Thailand)",
  "Don Mueang (Thailand)",
  "Dusit (Thailand)",
  "Hat Yai (Thailand)",
  "Hua Hin (Thailand)",
  "Huai Khwang (Thailand)",
  "Ka Bang (Thailand)",
  "Kalasin (Thailand)",
  "Kamphaeng Phet (Thailand)",
  "Kanchanaburi (Thailand)",
  "Kathu (Thailand)",
  "Khan Na Yao (Thailand)",
  "Khlong Luang (Thailand)",
  "Khlong San (Thailand)",
  "Khlong Toei (Thailand)",
  "Khlung (Thailand)",
  "Khon Kaen (Thailand)",
  "Klaeng (Thailand)",
  "Kosum Phisai (Thailand)",
  "Krabi (Thailand)",
  "Krathum Baen (Thailand)",
  "Kumphawapi (Thailand)",
  "Lampang (Thailand)",
  "Lamphun (Thailand)",
  "Lat Krabang (Thailand)",
  "Loei (Thailand)",
  "Long (Thailand)",
  "Lopburi (Thailand)",
  "Mae Hong Son (Thailand)",
  "Mae Sot (Thailand)",
  "Maha Sarakham (Thailand)",
  "Makkasan (Thailand)",
  "Manorom (Thailand)",
  "Min Buri (Thailand)",
  "Muak Lek (Thailand)",
  "Mueang Nonthaburi (Thailand)",
  "Mueang Samut Prakan (Thailand)",
  "Mukdahan (Thailand)",
  "Nakhon Pathom (Thailand)",
  "Nakhon Ratchasima (Thailand)",
  "Nakhon Sawan (Thailand)",
  "Nakhon Si Thammarat (Thailand)",
  "Nan (Thailand)",
  "Narathiwat (Thailand)",
  "New Sukhothai (Thailand)",
  "Non Thai (Thailand)",
  "Nong Bua (Thailand)",
  "Nong Bua Lamphu (Thailand)",
  "Nong Khai (Thailand)",
  "Nong Prue (Thailand)",
  "Pak Kret (Thailand)",
  "Pathum Thani (Thailand)",
  "Pathum Wan (Thailand)",
  "Pattani (Thailand)",
  "Pattaya (Thailand)",
  "Phachi (Thailand)",
  "Phan (Thailand)",
  "Phang Khon (Thailand)",
  "Phang Nga (Thailand)",
  "Phanna Nikhom (Thailand)",
  "Phatthalung (Thailand)",
  "Phaya Thai (Thailand)",
  "Phayao (Thailand)",
  "Phetchabun (Thailand)",
  "Phetchaburi (Thailand)",
  "Phichit (Thailand)",
  "Phitsanulok (Thailand)",
  "Photharam (Thailand)",
  "Phrae (Thailand)",
  "Phuket (Thailand)",
  "Prachin Buri (Thailand)",
  "Prachuap Khiri Khan (Thailand)",
  "Prakanong (Thailand)",
  "Ranong (Thailand)",
  "Ratchaburi (Thailand)",
  "Rawai (Thailand)",
  "Rayong (Thailand)",
  "Roi Et (Thailand)",
  "Sa Kaeo (Thailand)",
  "Sakon Nakhon (Thailand)",
  "Salaya (Thailand)",
  "Sam Khok (Thailand)",
  "Sam Sen (Thailand)",
  "Samphanthawong (Thailand)",
  "Samut Sakhon (Thailand)",
  "Samut Songkhram (Thailand)",
  "San Sai (Thailand)",
  "Sathon (Thailand)",
  "Sattahip (Thailand)",
  "Satun (Thailand)",
  "Sena (Thailand)",
  "Si Racha (Thailand)",
  "Si Sa Ket (Thailand)",
  "Sing Buri (Thailand)",
  "Songkhla (Thailand)",
  "Suan Luang (Thailand)",
  "Sung Noen (Thailand)",
  "Suphan Buri (Thailand)",
  "Surin (Thailand)",
  "Tak (Thailand)",
  "Thalang (Thailand)",
  "Thanyaburi (Thailand)",
  "Thon Buri (Thailand)",
  "Trang (Thailand)",
  "Trat (Thailand)",
  "Ubon Ratchathani (Thailand)",
  "Udon Thani (Thailand)",
  "Uthai Thani (Thailand)",
  "Uttaradit (Thailand)",
  "Wang Thonglang (Thailand)",
  "Watthana (Thailand)",
  "Wiphawadi (Thailand)",
  "Yala (Thailand)",
  "Yasothon (Thailand)",
  "Trinidad and Tobago",
  "Arima (Trinidad and Tobago)",
  "Arouca (Trinidad and Tobago)",
  "Barataria (Trinidad and Tobago)",
  "California (Trinidad and Tobago)",
  "Carapichaima (Trinidad and Tobago)",
  "Carenage (Trinidad and Tobago)",
  "Caroni (Trinidad and Tobago)",
  "Chaguanas (Trinidad and Tobago)",
  "Claxton Bay (Trinidad and Tobago)",
  "Couva (Trinidad and Tobago)",
  "Cumuto (Trinidad and Tobago)",
  "Cunupia (Trinidad and Tobago)",
  "Curepe (Trinidad and Tobago)",
  "Debe (Trinidad and Tobago)",
  "Diego Martin (Trinidad and Tobago)",
  "El Dorado (Trinidad and Tobago)",
  "Freeport (Trinidad and Tobago)",
  "Fyzabad (Trinidad and Tobago)",
  "Gasparillo (Trinidad and Tobago)",
  "Golden Lane (Trinidad and Tobago)",
  "Laventille (Trinidad and Tobago)",
  "Lopinot (Trinidad and Tobago)",
  "Marabella (Trinidad and Tobago)",
  "Maraval (Trinidad and Tobago)",
  "Moriah (Trinidad and Tobago)",
  "Morvant (Trinidad and Tobago)",
  "Penal (Trinidad and Tobago)",
  "Petit Valley (Trinidad and Tobago)",
  "Phoenix Park (Trinidad and Tobago)",
  "Piarco (Trinidad and Tobago)",
  "Port of Spain (Trinidad and Tobago)",
  "Princes Town (Trinidad and Tobago)",
  "Rio Claro (Trinidad and Tobago)",
  "Roxborough (Trinidad and Tobago)",
  "Saint Augustine (Trinidad and Tobago)",
  "Saint Clair (Trinidad and Tobago)",
  "Saint James (Trinidad and Tobago)",
  "Saint Joseph (Trinidad and Tobago)",
  "San Fernando (Trinidad and Tobago)",
  "San Juan (Trinidad and Tobago)",
  "Sangre Grande (Trinidad and Tobago)",
  "Santa Cruz (Trinidad and Tobago)",
  "Scarborough (Trinidad and Tobago)",
  "Siparia (Trinidad and Tobago)",
  "Tunapuna (Trinidad and Tobago)",
  "Valsayn (Trinidad and Tobago)",
  "Victoria (Trinidad and Tobago)",
  "Ward of Tacarigua (Trinidad and Tobago)",
  "Woodbrook (Trinidad and Tobago)",
  "Tunisia",
  "Ariana (Tunisia)",
  "Beja (Tunisia)",
  "Gafsa (Tunisia)",
  "Hammamet (Tunisia)",
  "Le Bardo (Tunisia)",
  "Manouba (Tunisia)",
  "Monastir (Tunisia)",
  "Rades (Tunisia)",
  "Sfax (Tunisia)",
  "Sidi Bouzid (Tunisia)",
  "Sousse (Tunisia)",
  "Tunis (Tunisia)",
  "Turkey",
  "Abdullah (Turkey)",
  "Acibadem (Turkey)",
  "Ada (Turkey)",
  "Adana (Turkey)",
  "Adnan Menderes (Turkey)",
  "Afyonkarahisar (Turkey)",
  "Akhisar (Turkey)",
  "Aksaray (Turkey)",
  "Alanya (Turkey)",
  "Alpaslan (Turkey)",
  "Amasra (Turkey)",
  "Amasya (Turkey)",
  "Andac (Turkey)",
  "Ankara (Turkey)",
  "Antakya (Turkey)",
  "Antalya (Turkey)",
  "Ardahan (Turkey)",
  "Artvin (Turkey)",
  "Atakoy (Turkey)",
  "Ataturk (Turkey)",
  "Atlas (Turkey)",
  "Ayazaga (Turkey)",
  "Aydin (Turkey)",
  "Aydogan (Turkey)",
  "Aydogdu (Turkey)",
  "Babaeski (Turkey)",
  "Balcova (Turkey)",
  "Banaz (Turkey)",
  "Bandirma (Turkey)",
  "Bartin (Turkey)",
  "Basari (Turkey)",
  "Baskent (Turkey)",
  "Baskil (Turkey)",
  "Batman (Turkey)",
  "Bayburt (Turkey)",
  "Bayrampasa (Turkey)",
  "Belek (Turkey)",
  "Bergama (Turkey)",
  "Besiktas (Turkey)",
  "Beykent (Turkey)",
  "Beykoz (Turkey)",
  "Beysehir (Turkey)",
  "Biga (Turkey)",
  "Bilecik (Turkey)",
  "Bilgi (Turkey)",
  "Bingol (Turkey)",
  "Birbir (Turkey)",
  "Bitam (Turkey)",
  "Bitlis (Turkey)",
  "Bodrum (Turkey)",
  "Bogazici (Turkey)",
  "Bolu (Turkey)",
  "Bor (Turkey)",
  "Bornova (Turkey)",
  "Bozuyuk (Turkey)",
  "Buca (Turkey)",
  "Bucak (Turkey)",
  "Bueyuekcekmece (Turkey)",
  "Bugdayli (Turkey)",
  "Bulut (Turkey)",
  "Burdur (Turkey)",
  "Burgaz (Turkey)",
  "Burhaniye (Turkey)",
  "Bursa (Turkey)",
  "Calkaya (Turkey)",
  "Camliyayla (Turkey)",
  "Cekme (Turkey)",
  "Celal (Turkey)",
  "Cermik (Turkey)",
  "Ceyhan (Turkey)",
  "Cine (Turkey)",
  "Cukurova (Turkey)",
  "Dalaman (Turkey)",
  "Darica (Turkey)",
  "Dayanikli (Turkey)",
  "Demetevleri (Turkey)",
  "Deniz (Turkey)",
  "Denizli (Turkey)",
  "Doga (Turkey)",
  "Dogankoy (Turkey)",
  "Dogu (Turkey)",
  "Dogus (Turkey)",
  "Dokuz (Turkey)",
  "Dortyol (Turkey)",
  "Doruklu (Turkey)",
  "Dumlupinar (Turkey)",
  "Duran (Turkey)",
  "Duzce (Turkey)",
  "Edebey (Turkey)",
  "Edirne (Turkey)",
  "Edremit (Turkey)",
  "Elbistan (Turkey)",
  "Elek (Turkey)",
  "Erbaa (Turkey)",
  "Ercis (Turkey)",
  "Erciyes (Turkey)",
  "Erenkoy (Turkey)",
  "Erzin (Turkey)",
  "Erzincan (Turkey)",
  "Erzurum (Turkey)",
  "Esenkent (Turkey)",
  "Esentepe (Turkey)",
  "Esenyurt (Turkey)",
  "Eser (Turkey)",
  "Eskisehir (Turkey)",
  "Etimesgut (Turkey)",
  "Fatih (Turkey)",
  "Ferizli (Turkey)",
  "Fethiye (Turkey)",
  "Findikli (Turkey)",
  "Gazi (Turkey)",
  "Gaziantep (Turkey)",
  "Gebze (Turkey)",
  "Gediz (Turkey)",
  "Gelibolu (Turkey)",
  "Gemlik (Turkey)",
  "Germencik (Turkey)",
  "Geulzuk (Turkey)",
  "Giresun (Turkey)",
  "Girne (Turkey)",
  "Golmarmara (Turkey)",
  "Gonen (Turkey)",
  "Gunbuldu (Turkey)",
  "Guzelyurt (Turkey)",
  "Hakkari (Turkey)",
  "Halic (Turkey)",
  "Harbiye (Turkey)",
  "Harran (Turkey)",
  "Hendek (Turkey)",
  "Hurriyet (Turkey)",
  "Ibrahim (Turkey)",
  "Ikitelli (Turkey)",
  "Iskilip (Turkey)",
  "Isparta (Turkey)",
  "Istanbul (Turkey)",
  "Izmir (Turkey)",
  "Kadikoey (Turkey)",
  "Kadikoy (Turkey)",
  "Kadir (Turkey)",
  "Kadirli (Turkey)",
  "Kagithane (Turkey)",
  "Karabuk (Turkey)",
  "Karaca (Turkey)",
  "Karakoy (Turkey)",
  "Karaman (Turkey)",
  "Karamursel (Turkey)",
  "Karatekin (Turkey)",
  "Kars (Turkey)",
  "Kartal (Turkey)",
  "Kastamonu (Turkey)",
  "Kayseri (Turkey)",
  "Kecioeren (Turkey)",
  "Kemal (Turkey)",
  "Kibriskoy (Turkey)",
  "Kilis (Turkey)",
  "Kirikkale (Turkey)",
  "Kisir (Turkey)",
  "Kocakoy (Turkey)",
  "Kocatepe (Turkey)",
  "Konak (Turkey)",
  "Konya (Turkey)",
  "Korfez (Turkey)",
  "Kozan (Turkey)",
  "Kucukcekmece (Turkey)",
  "Kumluca (Turkey)",
  "Kusadasi (Turkey)",
  "Kuzey (Turkey)",
  "Magnesia ad Sipylum (Turkey)",
  "Mahmutkoy (Turkey)",
  "Malatya (Turkey)",
  "Maltepe (Turkey)",
  "Manavgat (Turkey)",
  "Mardin (Turkey)",
  "Marmaris (Turkey)",
  "Maslak (Turkey)",
  "Mehmet Akif Ersoy (Turkey)",
  "Meliksah (Turkey)",
  "Meric (Turkey)",
  "Mersin (Turkey)",
  "Merzifon (Turkey)",
  "Mezitli (Turkey)",
  "Milas (Turkey)",
  "Mugla (Turkey)",
  "Muradiye (Turkey)",
  "Muratpasa (Turkey)",
  "Mustafa (Turkey)",
  "Mustafa Kemal (Turkey)",
  "Mustafakemalpasa (Turkey)",
  "Nazilli (Turkey)",
  "Niksar (Turkey)",
  "Niluefer (Turkey)",
  "Nizip (Turkey)",
  "Ordu (Turkey)",
  "Orhangazi (Turkey)",
  "Orta (Turkey)",
  "Osmaneli (Turkey)",
  "Osmangazi (Turkey)",
  "Osmaniye (Turkey)",
  "Ostim (Turkey)",
  "Pamukkale (Turkey)",
  "Patnos (Turkey)",
  "Pendik (Turkey)",
  "Piri (Turkey)",
  "Rize (Turkey)",
  "Sabanci (Turkey)",
  "Safranbolu (Turkey)",
  "Saglik (Turkey)",
  "Sahinbey (Turkey)",
  "Salihli (Turkey)",
  "Samsun (Turkey)",
  "Sanayi (Turkey)",
  "Sanliurfa (Turkey)",
  "Sariyer (Turkey)",
  "Seferihisar (Turkey)",
  "Sehitkamil (Turkey)",
  "Selcuk (Turkey)",
  "Serdivan (Turkey)",
  "Serik (Turkey)",
  "Seydisehir (Turkey)",
  "Seyhan (Turkey)",
  "Siirt (Turkey)",
  "Sile (Turkey)",
  "Silifke (Turkey)",
  "Siliviri (Turkey)",
  "Sinankoy (Turkey)",
  "Sincan (Turkey)",
  "Sinop (Turkey)",
  "Sirnak (Turkey)",
  "Sisli (Turkey)",
  "Sisman (Turkey)",
  "Sivas (Turkey)",
  "Soeke (Turkey)",
  "Suleyman (Turkey)",
  "Sultanpinar (Turkey)",
  "Sungurlu (Turkey)",
  "Susurluk (Turkey)",
  "Taksim (Turkey)",
  "Tarsus (Turkey)",
  "Tasdelen (Turkey)",
  "Tatvan (Turkey)",
  "Tesvikiye (Turkey)",
  "Tire (Turkey)",
  "Tokat Province (Turkey)",
  "Toprak (Turkey)",
  "Torbali (Turkey)",
  "Toros (Turkey)",
  "Trabzon (Turkey)",
  "Trakya (Turkey)",
  "Tunceli (Turkey)",
  "Turgut (Turkey)",
  "Turgutlu (Turkey)",
  "Uchisar (Turkey)",
  "UEskuedar (Turkey)",
  "Ugur (Turkey)",
  "Ulker (Turkey)",
  "Uludag (Turkey)",
  "Ulus (Turkey)",
  "Umraniye (Turkey)",
  "Unieh (Turkey)",
  "Urgub (Turkey)",
  "Urla (Turkey)",
  "Uzun Keupru (Turkey)",
  "Van (Turkey)",
  "Yagcilar (Turkey)",
  "Yakakoy (Turkey)",
  "Yalova (Turkey)",
  "Yaman (Turkey)",
  "Yasarkoy (Turkey)",
  "Yeditepe (Turkey)",
  "Yeni (Turkey)",
  "Yenibosna (Turkey)",
  "Yildiz (Turkey)",
  "Yozgat (Turkey)",
  "Yuregir (Turkey)",
  "Zekeriya (Turkey)",
  "Zeytinburnu (Turkey)",
  "Zonguldak (Turkey)",
  "U.S. Virgin Islands",
  "Charlotte Amalie (U.S. Virgin Islands)",
  "Christiansted (U.S. Virgin Islands)",
  "Frederiksted (U.S. Virgin Islands)",
  "Kingshill (U.S. Virgin Islands)",
  "St John Island (U.S. Virgin Islands)",
  "Ukraine",
  "Aleksandriya (Ukraine)",
  "Aleksandrovka (Ukraine)",
  "Alekseyevo-Druzhkovka (Ukraine)",
  "Alupka (Ukraine)",
  "Alushta (Ukraine)",
  "Babin (Ukraine)",
  "Barashivka (Ukraine)",
  "Baryshivka (Ukraine)",
  "Belaya (Ukraine)",
  "Berdychiv (Ukraine)",
  "Berehove (Ukraine)",
  "Bila Hora (Ukraine)",
  "Bila Tserkva (Ukraine)",
  "Bolekhiv (Ukraine)",
  "Borodyanka (Ukraine)",
  "Bortnychi (Ukraine)",
  "Boryslav (Ukraine)",
  "Boryspil (Ukraine)",
  "Boryspil Raion (Ukraine)",
  "Boyarka (Ukraine)",
  "Bozhenka (Ukraine)",
  "Brody (Ukraine)",
  "Brovary (Ukraine)",
  "Bryanka (Ukraine)",
  "Bucha (Ukraine)",
  "Buchach (Ukraine)",
  "Bukova (Ukraine)",
  "Bushtyno (Ukraine)",
  "Cherkasy (Ukraine)",
  "Chernev (Ukraine)",
  "Chernihiv (Ukraine)",
  "Chernivtsi (Ukraine)",
  "Chervona Hirka (Ukraine)",
  "Chervonohrad (Ukraine)",
  "Chop (Ukraine)",
  "Darakhov (Ukraine)",
  "Davydov (Ukraine)",
  "Desna (Ukraine)",
  "Dimitrov (Ukraine)",
  "Dmitrovichi (Ukraine)",
  "Dnipropetrovsk (Ukraine)",
  "Donetsk (Ukraine)",
  "Drohobych (Ukraine)",
  "Druzhkovka (Ukraine)",
  "Dubno (Ukraine)",
  "Dzhankoy (Ukraine)",
  "Energodar (Ukraine)",
  "Fastiv (Ukraine)",
  "Feodosiya (Ukraine)",
  "Fontanka (Ukraine)",
  "Frunze (Ukraine)",
  "German (Ukraine)",
  "Goncharov (Ukraine)",
  "Gorlovka (Ukraine)",
  "Grebenyuk (Ukraine)",
  "Haysyn (Ukraine)",
  "Hlukhiv (Ukraine)",
  "Horlivka (Ukraine)",
  "Horodenka (Ukraine)",
  "Hoshiv (Ukraine)",
  "Illintsi (Ukraine)",
  "Irpin (Ukraine)",
  "Ivankov (Ukraine)",
  "Ivanov (Ukraine)",
  "Izmail (Ukraine)",
  "Kakhovka (Ukraine)",
  "Kalush (Ukraine)",
  "Kalynivka (Ukraine)",
  "Kaniv (Ukraine)",
  "Kerch (Ukraine)",
  "Kharkiv (Ukraine)",
  "Kherson (Ukraine)",
  "Khust (Ukraine)",
  "Khyzha (Ukraine)",
  "Kiev (Ukraine)",
  "Kirovohrad (Ukraine)",
  "Kirovsk (Ukraine)",
  "Knyazhychi (Ukraine)",
  "Kolomyia (Ukraine)",
  "Komsomolsk (Ukraine)",
  "Konotop (Ukraine)",
  "Korosten (Ukraine)",
  "Korostyshiv (Ukraine)",
  "Kovel (Ukraine)",
  "Kozyatyn (Ukraine)",
  "Kramatorsk (Ukraine)",
  "Krasnodon (Ukraine)",
  "Krasnohrad (Ukraine)",
  "Kremenchuk (Ukraine)",
  "Kremenets (Ukraine)",
  "Kreminna (Ukraine)",
  "Kryvyy Rih (Ukraine)",
  "Kuna (Ukraine)",
  "Kurakhovo (Ukraine)",
  "Kurylivka (Ukraine)",
  "Kuznetsovsk (Ukraine)",
  "Kvasyliv (Ukraine)",
  "Kyseliv (Ukraine)",
  "Kyyiv (Ukraine)",
  "Lanovka (Ukraine)",
  "Lenina (Ukraine)",
  "Letychiv (Ukraine)",
  "Lisichansk (Ukraine)",
  "Lityn (Ukraine)",
  "Lohvynove (Ukraine)",
  "Lubny (Ukraine)",
  "Lugansk (Ukraine)",
  "Luganskoye (Ukraine)",
  "Luhansk (Ukraine)",
  "Lutsk (Ukraine)",
  "Lutugino (Ukraine)",
  "Lviv (Ukraine)",
  "Makariv (Ukraine)",
  "Makiyivka (Ukraine)",
  "Maksim (Ukraine)",
  "Mala Tokmachka (Ukraine)",
  "Malyn (Ukraine)",
  "Mariupol (Ukraine)",
  "Markova (Ukraine)",
  "Mega (Ukraine)",
  "Melitopol (Ukraine)",
  "Merefa (Ukraine)",
  "Monastyryshche (Ukraine)",
  "Mukacheve (Ukraine)",
  "Mykolayiv (Ukraine)",
  "Mylove (Ukraine)",
  "Nadezhda (Ukraine)",
  "Netishyn (Ukraine)",
  "Nizhyn (Ukraine)",
  "Nosivka (Ukraine)",
  "Novi Sanzhary (Ukraine)",
  "Novomoskovsk (Ukraine)",
  "Novyy Buh (Ukraine)",
  "Obukhov (Ukraine)",
  "Odesa (Ukraine)",
  "Oleh (Ukraine)",
  "Oreanda (Ukraine)",
  "Osnova (Ukraine)",
  "Osypenko (Ukraine)",
  "Pavlograd (Ukraine)",
  "Pavlohrad (Ukraine)",
  "Petranka (Ukraine)",
  "Petrenky (Ukraine)",
  "Pobeda (Ukraine)",
  "Podol (Ukraine)",
  "Poltava (Ukraine)",
  "Poplavy (Ukraine)",
  "Pyrohiv (Ukraine)",
  "Rayhorodka (Ukraine)",
  "Rivne (Ukraine)",
  "Rodinskoye (Ukraine)",
  "Romaniv (Ukraine)",
  "Romny (Ukraine)",
  "Rovenki (Ukraine)",
  "Rubizhne (Ukraine)",
  "Saky (Ukraine)",
  "Selidovo (Ukraine)",
  "Semen (Ukraine)",
  "Sencha (Ukraine)",
  "Serhiyi (Ukraine)",
  "Sevastopol (Ukraine)",
  "Shepetivka (Ukraine)",
  "Shevchenka (Ukraine)",
  "Shevchenko (Ukraine)",
  "Shostka (Ukraine)",
  "Shpola (Ukraine)",
  "Simferopol (Ukraine)",
  "Slavuta (Ukraine)",
  "Sloviansk (Ukraine)",
  "Slynkivshchyna (Ukraine)",
  "Smila (Ukraine)",
  "Sokol (Ukraine)",
  "Sokoliv (Ukraine)",
  "Soroka (Ukraine)",
  "Soroki (Ukraine)",
  "Sorotskoye (Ukraine)",
  "Spas (Ukraine)",
  "Stakhanov (Ukraine)",
  "Stanislav (Ukraine)",
  "Stat (Ukraine)",
  "Stryi (Ukraine)",
  "Sudak (Ukraine)",
  "Sumy (Ukraine)",
  "Svalyava (Ukraine)",
  "Svessa (Ukraine)",
  "Teplodar (Ukraine)",
  "Teremky (Ukraine)",
  "Ternopil (Ukraine)",
  "Ternovka (Ukraine)",
  "Tkachenka (Ukraine)",
  "Tokmak (Ukraine)",
  "Toporov (Ukraine)",
  "Torez (Ukraine)",
  "Trypillia (Ukraine)",
  "Tsvetkov (Ukraine)",
  "Tsyurupinsk (Ukraine)",
  "Turka (Ukraine)",
  "Tyachiv (Ukraine)",
  "Ugledar (Ukraine)",
  "Ukrayinka (Ukraine)",
  "Uzhhorod (Ukraine)",
  "Vasylkiv (Ukraine)",
  "Vinnytsya (Ukraine)",
  "Volkov (Ukraine)",
  "Volnovakha (Ukraine)",
  "Voronovo (Ukraine)",
  "Vorovskiy (Ukraine)",
  "Voznesenka (Ukraine)",
  "Vyshhorod (Ukraine)",
  "Vyshneve (Ukraine)",
  "Yalta (Ukraine)",
  "Yasinovataya (Ukraine)",
  "Yenakiyeve (Ukraine)",
  "Zabolotiv (Ukraine)",
  "Zaporizhia (Ukraine)",
  "Zaporozhe (Ukraine)",
  "Zastava (Ukraine)",
  "Zavod (Ukraine)",
  "Zdolbuniv (Ukraine)",
  "Zhashkiv (Ukraine)",
  "Zhdanovka (Ukraine)",
  "Zhmerynka (Ukraine)",
  "Zhytomyr (Ukraine)",
  "Zlazne (Ukraine)",
  "Zmiyiv (Ukraine)",
  "Zolochiv (Ukraine)",
  "Zolotonosha (Ukraine)",
  "United Arab Emirates",
  "Abu Dhabi (United Arab Emirates)",
  "Al Ain (United Arab Emirates)",
  "Al Khan (United Arab Emirates)",
  "Ar Ruways (United Arab Emirates)",
  "As Satwah (United Arab Emirates)",
  "Dayrah (United Arab Emirates)",
  "Dubai (United Arab Emirates)",
  "Fujairah (United Arab Emirates)",
  "Ras al-Khaimah (United Arab Emirates)",
  "Sharjah (United Arab Emirates)",
  "United Kingdom",
  "Abberton (United Kingdom)",
  "Abbots Langley (United Kingdom)",
  "Aberaeron (United Kingdom)",
  "Aberchirder (United Kingdom)",
  "Abercynon (United Kingdom)",
  "Aberdare (United Kingdom)",
  "Aberdeen (United Kingdom)",
  "Aberfeldy (United Kingdom)",
  "Aberford (United Kingdom)",
  "Aberfoyle (United Kingdom)",
  "Abergavenny (United Kingdom)",
  "Abergele (United Kingdom)",
  "Abergwynfi (United Kingdom)",
  "Abergynolwyn (United Kingdom)",
  "Aberkenfig (United Kingdom)",
  "Aberlour (United Kingdom)",
  "Abersychan (United Kingdom)",
  "Abertillery (United Kingdom)",
  "Aberystwyth (United Kingdom)",
  "Abingdon (United Kingdom)",
  "Aboyne (United Kingdom)",
  "Accrington (United Kingdom)",
  "Acton (United Kingdom)",
  "Addington (United Kingdom)",
  "Addlestone (United Kingdom)",
  "Adlingfleet (United Kingdom)",
  "Aghalee (United Kingdom)",
  "Aintree (United Kingdom)",
  "Airdrie (United Kingdom)",
  "Akeley (United Kingdom)",
  "Albrighton (United Kingdom)",
  "Alcester (United Kingdom)",
  "Aldeburgh (United Kingdom)",
  "Alderholt (United Kingdom)",
  "Alderley Edge (United Kingdom)",
  "Aldermaston (United Kingdom)",
  "Aldershot (United Kingdom)",
  "Aldridge (United Kingdom)",
  "Alexandria (United Kingdom)",
  "Alfold (United Kingdom)",
  "Alford (United Kingdom)",
  "Alfreton (United Kingdom)",
  "Alloa (United Kingdom)",
  "Alness (United Kingdom)",
  "Alnwick (United Kingdom)",
  "Alresford (United Kingdom)",
  "Alrewas (United Kingdom)",
  "Alsager (United Kingdom)",
  "Alston (United Kingdom)",
  "Altham (United Kingdom)",
  "Alton (United Kingdom)",
  "Altrincham (United Kingdom)",
  "Alva (United Kingdom)",
  "Alvechurch (United Kingdom)",
  "Alveston (United Kingdom)",
  "Ambleside (United Kingdom)",
  "Amersham (United Kingdom)",
  "Amesbury (United Kingdom)",
  "Amlwch (United Kingdom)",
  "Ammanford (United Kingdom)",
  "Ampthill (United Kingdom)",
  "Andover (United Kingdom)",
  "Angmering (United Kingdom)",
  "Anlaby (United Kingdom)",
  "Annalong (United Kingdom)",
  "Annan (United Kingdom)",
  "Annesley (United Kingdom)",
  "Anniesland (United Kingdom)",
  "Anstey (United Kingdom)",
  "Anstruther (United Kingdom)",
  "Antrim (United Kingdom)",
  "Appleby (United Kingdom)",
  "Appleford (United Kingdom)",
  "Appleton (United Kingdom)",
  "Appley Bridge (United Kingdom)",
  "Arbroath (United Kingdom)",
  "Ardingly (United Kingdom)",
  "Ardmillan (United Kingdom)",
  "Ardrossan (United Kingdom)",
  "Arlesey (United Kingdom)",
  "Arley (United Kingdom)",
  "Armadale (United Kingdom)",
  "Armagh (United Kingdom)",
  "Armitage (United Kingdom)",
  "Arnold (United Kingdom)",
  "Arrochar (United Kingdom)",
  "Arthog (United Kingdom)",
  "Arundel (United Kingdom)",
  "Ascot (United Kingdom)",
  "Ash (United Kingdom)",
  "Ash Vale (United Kingdom)",
  "Ashbourne (United Kingdom)",
  "Ashburton (United Kingdom)",
  "Ashby de la Launde (United Kingdom)",
  "Ashby de la Zouch (United Kingdom)",
  "Ashdon (United Kingdom)",
  "Ashen (United Kingdom)",
  "Ashfield (United Kingdom)",
  "Ashford (United Kingdom)",
  "Ashington (United Kingdom)",
  "Ashley (United Kingdom)",
  "Ashmore (United Kingdom)",
  "Ashtead (United Kingdom)",
  "Ashton in Makerfield (United Kingdom)",
  "Ashton-under-Lyne (United Kingdom)",
  "Ashwell (United Kingdom)",
  "Askam in Furness (United Kingdom)",
  "Askern (United Kingdom)",
  "Askham (United Kingdom)",
  "Astley (United Kingdom)",
  "Aston (United Kingdom)",
  "Aston Clinton (United Kingdom)",
  "Atherstone (United Kingdom)",
  "Atherton (United Kingdom)",
  "Attleborough (United Kingdom)",
  "Atworth (United Kingdom)",
  "Auchinleck (United Kingdom)",
  "Auchterarder (United Kingdom)",
  "Auchtermuchty (United Kingdom)",
  "Augher (United Kingdom)",
  "Aughnacloy (United Kingdom)",
  "Aultbea (United Kingdom)",
  "Aveley (United Kingdom)",
  "Aviemore (United Kingdom)",
  "Avoch (United Kingdom)",
  "Avonmouth (United Kingdom)",
  "Axbridge (United Kingdom)",
  "Axminster (United Kingdom)",
  "Aycliffe (United Kingdom)",
  "Aylesbury (United Kingdom)",
  "Aylesford (United Kingdom)",
  "Aylsham (United Kingdom)",
  "Ayr (United Kingdom)",
  "Babraham (United Kingdom)",
  "Back (United Kingdom)",
  "Bacton (United Kingdom)",
  "Bacup (United Kingdom)",
  "Bagillt (United Kingdom)",
  "Bagshot (United Kingdom)",
  "Baildon (United Kingdom)",
  "Bainton (United Kingdom)",
  "Bakewell (United Kingdom)",
  "Bala (United Kingdom)",
  "Baldock (United Kingdom)",
  "Balerno (United Kingdom)",
  "Balham (United Kingdom)",
  "Ball (United Kingdom)",
  "Ballingry (United Kingdom)",
  "Balloch (United Kingdom)",
  "Ballybogy (United Kingdom)",
  "Ballycastle (United Kingdom)",
  "Ballyclare (United Kingdom)",
  "Ballymena (United Kingdom)",
  "Ballymoney (United Kingdom)",
  "Ballynahinch (United Kingdom)",
  "Bampton (United Kingdom)",
  "Banbridge (United Kingdom)",
  "Banbury (United Kingdom)",
  "Banchory (United Kingdom)",
  "Banff (United Kingdom)",
  "Bangor (United Kingdom)",
  "Bangor-is-y-coed (United Kingdom)",
  "Banham (United Kingdom)",
  "Banstead (United Kingdom)",
  "Barbaraville (United Kingdom)",
  "Bargoed (United Kingdom)",
  "Barking (United Kingdom)",
  "Barkway (United Kingdom)",
  "Barley (United Kingdom)",
  "Barmouth (United Kingdom)",
  "Barmston (United Kingdom)",
  "Barnard Castle (United Kingdom)",
  "Barnes (United Kingdom)",
  "Barnet (United Kingdom)",
  "Barnham (United Kingdom)",
  "Barnoldswick (United Kingdom)",
  "Barnsley (United Kingdom)",
  "Barnstaple (United Kingdom)",
  "Barnwell (United Kingdom)",
  "Barrow (United Kingdom)",
  "Barrow in Furness (United Kingdom)",
  "Barrow upon Humber (United Kingdom)",
  "Barrow upon Soar (United Kingdom)",
  "Barrowford (United Kingdom)",
  "Barry (United Kingdom)",
  "Barton in Fabis (United Kingdom)",
  "Barton on Sea (United Kingdom)",
  "Barton under Needwood (United Kingdom)",
  "Barton upon Humber (United Kingdom)",
  "Barton-le-Clay (United Kingdom)",
  "Barwell (United Kingdom)",
  "Basildon (United Kingdom)",
  "Basingstoke (United Kingdom)",
  "Baslow (United Kingdom)",
  "Bath (United Kingdom)",
  "Bathgate (United Kingdom)",
  "Batley (United Kingdom)",
  "Battle (United Kingdom)",
  "Bawtry (United Kingdom)",
  "Bayble (United Kingdom)",
  "Bayford (United Kingdom)",
  "Beach (United Kingdom)",
  "Beaconsfield (United Kingdom)",
  "Beal (United Kingdom)",
  "Bealings (United Kingdom)",
  "Beaminster (United Kingdom)",
  "Beaufort (United Kingdom)",
  "Beaulieu (United Kingdom)",
  "Beauly (United Kingdom)",
  "Beaumaris (United Kingdom)",
  "Beaumont (United Kingdom)",
  "Beaworthy (United Kingdom)",
  "Bebington (United Kingdom)",
  "Beccles (United Kingdom)",
  "Beckenham (United Kingdom)",
  "Beckingham (United Kingdom)",
  "Beckley (United Kingdom)",
  "Beckton (United Kingdom)",
  "Bedale (United Kingdom)",
  "Bedford (United Kingdom)",
  "Bedlington (United Kingdom)",
  "Bedminster (United Kingdom)",
  "Bedworth (United Kingdom)",
  "Beer (United Kingdom)",
  "Beeston (United Kingdom)",
  "Beith (United Kingdom)",
  "Bekesbourne (United Kingdom)",
  "Belbroughton (United Kingdom)",
  "Belcoo (United Kingdom)",
  "Belfast (United Kingdom)",
  "Belleek (United Kingdom)",
  "Bellshill (United Kingdom)",
  "Belmont (United Kingdom)",
  "Belper (United Kingdom)",
  "Belvedere (United Kingdom)",
  "Bembridge (United Kingdom)",
  "Ben Rhydding (United Kingdom)",
  "Benburb (United Kingdom)",
  "Benenden (United Kingdom)",
  "Benllech (United Kingdom)",
  "Benson (United Kingdom)",
  "Bentley (United Kingdom)",
  "Berkeley (United Kingdom)",
  "Berkhamstead (United Kingdom)",
  "Bermondsey (United Kingdom)",
  "Berwick (United Kingdom)",
  "Berwick-Upon-Tweed (United Kingdom)",
  "Besthorpe (United Kingdom)",
  "Betchworth (United Kingdom)",
  "Bettws (United Kingdom)",
  "Betws (United Kingdom)",
  "Beverley (United Kingdom)",
  "Bewdley (United Kingdom)",
  "Bexhill (United Kingdom)",
  "Bexleyheath (United Kingdom)",
  "Bicester (United Kingdom)",
  "Bickenhill (United Kingdom)",
  "Bickleigh (United Kingdom)",
  "Biddenden (United Kingdom)",
  "Biddenham (United Kingdom)",
  "Biddulph (United Kingdom)",
  "Bideford (United Kingdom)",
  "Bidford-on-Avon (United Kingdom)",
  "Bidston (United Kingdom)",
  "Bierton (United Kingdom)",
  "Biggar (United Kingdom)",
  "Biggin Hill (United Kingdom)",
  "Biggleswade (United Kingdom)",
  "Bignor (United Kingdom)",
  "Bildeston (United Kingdom)",
  "Billericay (United Kingdom)",
  "Billesley (United Kingdom)",
  "Billingham (United Kingdom)",
  "Billingshurst (United Kingdom)",
  "Bilsthorpe (United Kingdom)",
  "Bilston (United Kingdom)",
  "Binfield (United Kingdom)",
  "Bingham (United Kingdom)",
  "Bingley (United Kingdom)",
  "Binsted (United Kingdom)",
  "Birchanger (United Kingdom)",
  "Birchington (United Kingdom)",
  "Birdbrook (United Kingdom)",
  "Birkenhead (United Kingdom)",
  "Birmingham (United Kingdom)",
  "Birnam (United Kingdom)",
  "Birstall (United Kingdom)",
  "Birtley (United Kingdom)",
  "Bishop Auckland (United Kingdom)",
  "Bishop Burton (United Kingdom)",
  "Bishopbriggs (United Kingdom)",
  "Bishops Cleeve (United Kingdom)",
  "Bishops Waltham (United Kingdom)",
  "Bishopstoke (United Kingdom)",
  "Bishopstrow (United Kingdom)",
  "Bishopton (United Kingdom)",
  "Bisley (United Kingdom)",
  "Blackburn (United Kingdom)",
  "Blackford (United Kingdom)",
  "Blackheath (United Kingdom)",
  "Blackpool (United Kingdom)",
  "Blackwater (United Kingdom)",
  "Blackwood (United Kingdom)",
  "Blaenau-Ffestiniog (United Kingdom)",
  "Blaenavon (United Kingdom)",
  "Blaenwaun (United Kingdom)",
  "Blaina (United Kingdom)",
  "Blairgowrie (United Kingdom)",
  "Blakeney (United Kingdom)",
  "Blandford Forum (United Kingdom)",
  "Blaydon (United Kingdom)",
  "Bledlow (United Kingdom)",
  "Bletchingley (United Kingdom)",
  "Bletchley (United Kingdom)",
  "Blewbury (United Kingdom)",
  "Blidworth (United Kingdom)",
  "Bloomsbury (United Kingdom)",
  "Bloxwich (United Kingdom)",
  "Blunham (United Kingdom)",
  "Bluntisham (United Kingdom)",
  "Blyth (United Kingdom)",
  "Bodelwyddan (United Kingdom)",
  "Bodmin (United Kingdom)",
  "Bognor Regis (United Kingdom)",
  "Boldon Colliery (United Kingdom)",
  "Bollington (United Kingdom)",
  "Bolney (United Kingdom)",
  "Bolsover (United Kingdom)",
  "Bolton (United Kingdom)",
  "Bonar Bridge (United Kingdom)",
  "Bonnybridge (United Kingdom)",
  "Bonnyrigg (United Kingdom)",
  "Bookham (United Kingdom)",
  "Bootle (United Kingdom)",
  "Bordesley (United Kingdom)",
  "Boreham (United Kingdom)",
  "Borehamwood (United Kingdom)",
  "Borough Green (United Kingdom)",
  "Boroughbridge (United Kingdom)",
  "Bosbury (United Kingdom)",
  "Boscastle (United Kingdom)",
  "Boston (United Kingdom)",
  "Boston Spa (United Kingdom)",
  "Botesdale (United Kingdom)",
  "Bothwell (United Kingdom)",
  "Botley (United Kingdom)",
  "Bottesford (United Kingdom)",
  "Bourne (United Kingdom)",
  "Bourne End (United Kingdom)",
  "Bournemouth (United Kingdom)",
  "Bournville (United Kingdom)",
  "Bourton on the Water (United Kingdom)",
  "Bovey Tracey (United Kingdom)",
  "Bow (United Kingdom)",
  "Bowdon (United Kingdom)",
  "Bowes (United Kingdom)",
  "Bowness-on-Windermere (United Kingdom)",
  "Box (United Kingdom)",
  "Boxley (United Kingdom)",
  "Bozeat (United Kingdom)",
  "Brackley (United Kingdom)",
  "Bracknell (United Kingdom)",
  "Bradfield St George (United Kingdom)",
  "Bradford (United Kingdom)",
  "Bradford-on-Avon (United Kingdom)",
  "Bradwell on Sea (United Kingdom)",
  "Braintree (United Kingdom)",
  "Bramford (United Kingdom)",
  "Bramhall (United Kingdom)",
  "Bramley (United Kingdom)",
  "Brampton (United Kingdom)",
  "Brandesburton (United Kingdom)",
  "Brandon (United Kingdom)",
  "Bransford (United Kingdom)",
  "Bransgore (United Kingdom)",
  "Brasted (United Kingdom)",
  "Braunstone (United Kingdom)",
  "Braunton (United Kingdom)",
  "Brayton (United Kingdom)",
  "Brechin (United Kingdom)",
  "Brecon (United Kingdom)",
  "Bredbury (United Kingdom)",
  "Brede (United Kingdom)",
  "Brenchley (United Kingdom)",
  "Brentford (United Kingdom)",
  "Brentwood (United Kingdom)",
  "Brewood (United Kingdom)",
  "Bridge (United Kingdom)",
  "Bridgemary (United Kingdom)",
  "Bridgend (United Kingdom)",
  "Bridgham (United Kingdom)",
  "Bridgnorth (United Kingdom)",
  "Bridgwater (United Kingdom)",
  "Bridlington (United Kingdom)",
  "Bridport (United Kingdom)",
  "Brierfield (United Kingdom)",
  "Brierley Hill (United Kingdom)",
  "Brigg (United Kingdom)",
  "Brighouse (United Kingdom)",
  "Brightlingsea (United Kingdom)",
  "Brighton (United Kingdom)",
  "Brigstock (United Kingdom)",
  "Brimpton (United Kingdom)",
  "Brimscombe (United Kingdom)",
  "Bristol (United Kingdom)",
  "Briton Ferry (United Kingdom)",
  "Brixham (United Kingdom)",
  "Brixton Hill (United Kingdom)",
  "Brixworth (United Kingdom)",
  "Broad Blunsdon (United Kingdom)",
  "Broadstairs (United Kingdom)",
  "Broadstone (United Kingdom)",
  "Broadwas (United Kingdom)",
  "Broadway (United Kingdom)",
  "Brockenhurst (United Kingdom)",
  "Brockley Green (United Kingdom)",
  "Brockworth (United Kingdom)",
  "Bromborough (United Kingdom)",
  "Bromham (United Kingdom)",
  "Brompton (United Kingdom)",
  "Bromsgrove (United Kingdom)",
  "Bromyard (United Kingdom)",
  "Brook (United Kingdom)",
  "Brooke (United Kingdom)",
  "Brookland (United Kingdom)",
  "Broom (United Kingdom)",
  "Broseley (United Kingdom)",
  "Brough (United Kingdom)",
  "Broughton (United Kingdom)",
  "Broughty Ferry (United Kingdom)",
  "Brownhills (United Kingdom)",
  "Broxbourne (United Kingdom)",
  "Broxburn (United Kingdom)",
  "Bruton (United Kingdom)",
  "Buckden (United Kingdom)",
  "Buckfastleigh (United Kingdom)",
  "Buckhurst Hill (United Kingdom)",
  "Buckie (United Kingdom)",
  "Buckingham (United Kingdom)",
  "Buckland (United Kingdom)",
  "Buckley (United Kingdom)",
  "Bucknell (United Kingdom)",
  "Bude (United Kingdom)",
  "Budleigh Salterton (United Kingdom)",
  "Bugbrooke (United Kingdom)",
  "Builth Wells (United Kingdom)",
  "Bulford (United Kingdom)",
  "Bulkington (United Kingdom)",
  "Bulwell (United Kingdom)",
  "Bungay (United Kingdom)",
  "Buntingford (United Kingdom)",
  "Burbage (United Kingdom)",
  "Burford (United Kingdom)",
  "Burgess Hill (United Kingdom)",
  "Burgh le Marsh (United Kingdom)",
  "Burghclere (United Kingdom)",
  "Burley (United Kingdom)",
  "Burnham (United Kingdom)",
  "Burnham on Crouch (United Kingdom)",
  "Burnham-on-Sea (United Kingdom)",
  "Burnley (United Kingdom)",
  "Burnopfield (United Kingdom)",
  "Burntisland (United Kingdom)",
  "Burntwood (United Kingdom)",
  "Burry Port (United Kingdom)",
  "Burscough (United Kingdom)",
  "Burslem (United Kingdom)",
  "Burstwick (United Kingdom)",
  "Burton (United Kingdom)",
  "Burton Pidsea (United Kingdom)",
  "Burton-on-Trent (United Kingdom)",
  "Burwash (United Kingdom)",
  "Burwell (United Kingdom)",
  "Bury (United Kingdom)",
  "Bury St Edmunds (United Kingdom)",
  "Burythorpe (United Kingdom)",
  "Bushey (United Kingdom)",
  "Butterton (United Kingdom)",
  "Buxted (United Kingdom)",
  "Buxton (United Kingdom)",
  "Byfield (United Kingdom)",
  "Byfleet (United Kingdom)",
  "Cadbury (United Kingdom)",
  "Caddington (United Kingdom)",
  "Caernarfon (United Kingdom)",
  "Caerphilly (United Kingdom)",
  "Caersws (United Kingdom)",
  "Caister-on-Sea (United Kingdom)",
  "Caistor (United Kingdom)",
  "Caldicot (United Kingdom)",
  "Callander (United Kingdom)",
  "Callington (United Kingdom)",
  "Calne (United Kingdom)",
  "Calstock (United Kingdom)",
  "Calverton (United Kingdom)",
  "Cam (United Kingdom)",
  "Camberley (United Kingdom)",
  "Camberwell (United Kingdom)",
  "Camborne (United Kingdom)",
  "Cambridge (United Kingdom)",
  "Cambuslang (United Kingdom)",
  "Cambusnethan (United Kingdom)",
  "Camelford (United Kingdom)",
  "Campbeltown (United Kingdom)",
  "Canewdon (United Kingdom)",
  "Cannock (United Kingdom)",
  "Canterbury (United Kingdom)",
  "Capel (United Kingdom)",
  "Capenhurst (United Kingdom)",
  "Carbrooke (United Kingdom)",
  "Cardiff (United Kingdom)",
  "Cardigan (United Kingdom)",
  "Cardonald (United Kingdom)",
  "Cardross (United Kingdom)",
  "Carlisle (United Kingdom)",
  "Carlton (United Kingdom)",
  "Carlton le Moorland (United Kingdom)",
  "Carluke (United Kingdom)",
  "Carmarthen (United Kingdom)",
  "Carnforth (United Kingdom)",
  "Carnmoney (United Kingdom)",
  "Carnoustie (United Kingdom)",
  "Carrbridge (United Kingdom)",
  "Carrickfergus (United Kingdom)",
  "Carrowdore (United Kingdom)",
  "Carshalton (United Kingdom)",
  "Carterton (United Kingdom)",
  "Castle Cary (United Kingdom)",
  "Castle Donington (United Kingdom)",
  "Castle Douglas (United Kingdom)",
  "Castlederg (United Kingdom)",
  "Castleford (United Kingdom)",
  "Castlereagh (United Kingdom)",
  "Castleton (United Kingdom)",
  "Castlewellan (United Kingdom)",
  "Caston (United Kingdom)",
  "Caterham (United Kingdom)",
  "Catford (United Kingdom)",
  "Catherington (United Kingdom)",
  "Catterick (United Kingdom)",
  "Caversham (United Kingdom)",
  "Cawston (United Kingdom)",
  "Caxton (United Kingdom)",
  "Caythorpe (United Kingdom)",
  "Chacombe (United Kingdom)",
  "Chaddesden (United Kingdom)",
  "Chadwell (United Kingdom)",
  "Chalfont Saint Peter (United Kingdom)",
  "Chalgrove (United Kingdom)",
  "Chandlers Ford (United Kingdom)",
  "Chapel en le Frith (United Kingdom)",
  "Chapeltown (United Kingdom)",
  "Chard (United Kingdom)",
  "Charfield (United Kingdom)",
  "Charing Cross (United Kingdom)",
  "Charlestown (United Kingdom)",
  "Charlton on Otmoor (United Kingdom)",
  "Charlwood (United Kingdom)",
  "Charmouth (United Kingdom)",
  "Chartham (United Kingdom)",
  "Chasetown (United Kingdom)",
  "Chatham (United Kingdom)",
  "Chatteris (United Kingdom)",
  "Cheadle (United Kingdom)",
  "Cheadle Hulme (United Kingdom)",
  "Cheam (United Kingdom)",
  "Checkley (United Kingdom)",
  "Cheddar (United Kingdom)",
  "Chellaston (United Kingdom)",
  "Chelmsford (United Kingdom)",
  "Chelsfield (United Kingdom)",
  "Cheltenham (United Kingdom)",
  "Chepstow (United Kingdom)",
  "Chertsey (United Kingdom)",
  "Chesham (United Kingdom)",
  "Cheshunt (United Kingdom)",
  "Chessington (United Kingdom)",
  "Chester (United Kingdom)",
  "Chester-le-Street (United Kingdom)",
  "Chesterfield (United Kingdom)",
  "Chestfield (United Kingdom)",
  "Chichester (United Kingdom)",
  "Chigwell (United Kingdom)",
  "Chilbolton (United Kingdom)",
  "Chilcompton (United Kingdom)",
  "Childwall (United Kingdom)",
  "Chilton (United Kingdom)",
  "Chilton Trinity (United Kingdom)",
  "Chilwell (United Kingdom)",
  "Chingford (United Kingdom)",
  "Chinley (United Kingdom)",
  "Chinnor (United Kingdom)",
  "Chippenham (United Kingdom)",
  "Chipping Campden (United Kingdom)",
  "Chipping Norton (United Kingdom)",
  "Chipping Ongar (United Kingdom)",
  "Chipping Sodbury (United Kingdom)",
  "Chipstead (United Kingdom)",
  "Chislehurst (United Kingdom)",
  "Chiswick (United Kingdom)",
  "Choppington (United Kingdom)",
  "Chorley (United Kingdom)",
  "Christchurch (United Kingdom)",
  "Christleton (United Kingdom)",
  "Chryston (United Kingdom)",
  "Chulmleigh (United Kingdom)",
  "Church (United Kingdom)",
  "Church Gresley (United Kingdom)",
  "Church Leigh (United Kingdom)",
  "Church Stretton (United Kingdom)",
  "Churchill (United Kingdom)",
  "Cinderford (United Kingdom)",
  "Cirencester (United Kingdom)",
  "City of London (United Kingdom)",
  "City of Westminster (United Kingdom)",
  "Clackmannan (United Kingdom)",
  "Clacton-on-Sea (United Kingdom)",
  "Clapham (United Kingdom)",
  "Clarbeston Road (United Kingdom)",
  "Clare (United Kingdom)",
  "Claverdon (United Kingdom)",
  "Clavering (United Kingdom)",
  "Claygate (United Kingdom)",
  "Clayton West (United Kingdom)",
  "Cleator (United Kingdom)",
  "Cleator Moor (United Kingdom)",
  "Cleckheaton (United Kingdom)",
  "Clevedon (United Kingdom)",
  "Cleveleys (United Kingdom)",
  "Cliffe (United Kingdom)",
  "Clifton (United Kingdom)",
  "Clifton Hampden (United Kingdom)",
  "Clipstone (United Kingdom)",
  "Clitheroe (United Kingdom)",
  "Clovenfords (United Kingdom)",
  "Clun (United Kingdom)",
  "Clunderwen (United Kingdom)",
  "Clutton (United Kingdom)",
  "Clydach (United Kingdom)",
  "Clydebank (United Kingdom)",
  "Coalbrookdale (United Kingdom)",
  "Coalisland (United Kingdom)",
  "Coalville (United Kingdom)",
  "Coatbridge (United Kingdom)",
  "Cobham (United Kingdom)",
  "Cockerham (United Kingdom)",
  "Cockermouth (United Kingdom)",
  "Codsall (United Kingdom)",
  "Coed-Talon (United Kingdom)",
  "Cogenhoe (United Kingdom)",
  "Coggeshall (United Kingdom)",
  "Colchester (United Kingdom)",
  "Coleford (United Kingdom)",
  "Coleraine (United Kingdom)",
  "Colnbrook (United Kingdom)",
  "Colne (United Kingdom)",
  "Colwyn Bay (United Kingdom)",
  "Colyford (United Kingdom)",
  "Colyton (United Kingdom)",
  "Comber (United Kingdom)",
  "Compton (United Kingdom)",
  "Compton Dundon (United Kingdom)",
  "Comrie (United Kingdom)",
  "Congleton (United Kingdom)",
  "Conisbrough (United Kingdom)",
  "Connahs Quay (United Kingdom)",
  "Conon Bridge (United Kingdom)",
  "Consett (United Kingdom)",
  "Conway (United Kingdom)",
  "Cookham (United Kingdom)",
  "Cooksbridge (United Kingdom)",
  "Cookstown (United Kingdom)",
  "Coppenhall (United Kingdom)",
  "Coppull (United Kingdom)",
  "Corbridge (United Kingdom)",
  "Corby (United Kingdom)",
  "Corfe Castle (United Kingdom)",
  "Corfe Mullen (United Kingdom)",
  "Corpach (United Kingdom)",
  "Corringham (United Kingdom)",
  "Corsham (United Kingdom)",
  "Corwen (United Kingdom)",
  "Coseley (United Kingdom)",
  "Cosham (United Kingdom)",
  "Cotgrave (United Kingdom)",
  "Cottenham (United Kingdom)",
  "Cottered (United Kingdom)",
  "Cottingham (United Kingdom)",
  "Coulsdon (United Kingdom)",
  "Countess Wear (United Kingdom)",
  "Coupar Angus (United Kingdom)",
  "Covent Garden (United Kingdom)",
  "Coventry (United Kingdom)",
  "Cowbridge (United Kingdom)",
  "Cowden (United Kingdom)",
  "Cowdenbeath (United Kingdom)",
  "Cowley (United Kingdom)",
  "Cradley (United Kingdom)",
  "Craigavon (United Kingdom)",
  "Cramlington (United Kingdom)",
  "Cranbrook (United Kingdom)",
  "Cranfield (United Kingdom)",
  "Cranford (United Kingdom)",
  "Cranleigh (United Kingdom)",
  "Crathorne (United Kingdom)",
  "Craven Arms (United Kingdom)",
  "Crawley (United Kingdom)",
  "Crayford (United Kingdom)",
  "Crediton (United Kingdom)",
  "Crewe (United Kingdom)",
  "Crewkerne (United Kingdom)",
  "Criccieth (United Kingdom)",
  "Crick (United Kingdom)",
  "Crickhowell (United Kingdom)",
  "Cricklade (United Kingdom)",
  "Cricklewood (United Kingdom)",
  "Crieff (United Kingdom)",
  "Crofton (United Kingdom)",
  "Cromer (United Kingdom)",
  "Cromwell (United Kingdom)",
  "Crook (United Kingdom)",
  "Crookham (United Kingdom)",
  "Crosby (United Kingdom)",
  "Cross (United Kingdom)",
  "Cross in Hand (United Kingdom)",
  "Cross Keys (United Kingdom)",
  "Crossgar (United Kingdom)",
  "Crossgates (United Kingdom)",
  "Crosshouse (United Kingdom)",
  "Croston (United Kingdom)",
  "Croughton (United Kingdom)",
  "Crowborough (United Kingdom)",
  "Crowland (United Kingdom)",
  "Crowthorne (United Kingdom)",
  "Croxley Green (United Kingdom)",
  "Croydon (United Kingdom)",
  "Crumlin (United Kingdom)",
  "Crymych (United Kingdom)",
  "Cublington (United Kingdom)",
  "Cuckfield (United Kingdom)",
  "Cuffley (United Kingdom)",
  "Cullen (United Kingdom)",
  "Cullompton (United Kingdom)",
  "Cumbernauld (United Kingdom)",
  "Cumnock (United Kingdom)",
  "Cupar (United Kingdom)",
  "Curdridge (United Kingdom)",
  "Currie (United Kingdom)",
  "Cwmbran (United Kingdom)",
  "Cynwyd (United Kingdom)",
  "Dagenham (United Kingdom)",
  "Dalbeattie (United Kingdom)",
  "Dalkeith (United Kingdom)",
  "Dalry (United Kingdom)",
  "Dalton in Furness (United Kingdom)",
  "Daresbury (United Kingdom)",
  "Darfield (United Kingdom)",
  "Darlaston (United Kingdom)",
  "Darlington (United Kingdom)",
  "Dartford (United Kingdom)",
  "Dartmouth (United Kingdom)",
  "Darvel (United Kingdom)",
  "Darwen (United Kingdom)",
  "Datchet (United Kingdom)",
  "Daventry (United Kingdom)",
  "Dawley (United Kingdom)",
  "Dawlish (United Kingdom)",
  "Deal (United Kingdom)",
  "Dean (United Kingdom)",
  "Deighton (United Kingdom)",
  "Denbigh (United Kingdom)",
  "Denby (United Kingdom)",
  "Denham (United Kingdom)",
  "Denny (United Kingdom)",
  "Denton (United Kingdom)",
  "Deptford (United Kingdom)",
  "Derby (United Kingdom)",
  "Dereham (United Kingdom)",
  "Derwen (United Kingdom)",
  "Desborough (United Kingdom)",
  "Devizes (United Kingdom)",
  "Dewsbury (United Kingdom)",
  "Didcot (United Kingdom)",
  "Diddington (United Kingdom)",
  "Dinas Powys (United Kingdom)",
  "Dingestow (United Kingdom)",
  "Dingwall (United Kingdom)",
  "Dinnington (United Kingdom)",
  "Diss (United Kingdom)",
  "Doagh (United Kingdom)",
  "Dolgelly (United Kingdom)",
  "Dollar (United Kingdom)",
  "Dollis Hill (United Kingdom)",
  "Dolwyddelan (United Kingdom)",
  "Donaghadee (United Kingdom)",
  "Doncaster (United Kingdom)",
  "Donnington (United Kingdom)",
  "Dorchester (United Kingdom)",
  "Dorking (United Kingdom)",
  "Dorney (United Kingdom)",
  "Dornoch (United Kingdom)",
  "Dorridge (United Kingdom)",
  "Douglas (United Kingdom)",
  "Doune (United Kingdom)",
  "Dover (United Kingdom)",
  "Dovercourt (United Kingdom)",
  "Downend (United Kingdom)",
  "Downham Market (United Kingdom)",
  "Downpatrick (United Kingdom)",
  "Draperstown (United Kingdom)",
  "Draycott (United Kingdom)",
  "Draycott in the Moors (United Kingdom)",
  "Drayton (United Kingdom)",
  "Drayton Bassett (United Kingdom)",
  "Drayton Saint Leonard (United Kingdom)",
  "Driffield (United Kingdom)",
  "Drighlington (United Kingdom)",
  "Droitwich (United Kingdom)",
  "Dromara (United Kingdom)",
  "Dromore (United Kingdom)",
  "Dronfield (United Kingdom)",
  "Droxford (United Kingdom)",
  "Droylsden (United Kingdom)",
  "Drumahoe (United Kingdom)",
  "Drumchapel (United Kingdom)",
  "Drybrook (United Kingdom)",
  "Drymen (United Kingdom)",
  "Duddington (United Kingdom)",
  "Dudley (United Kingdom)",
  "Duffield (United Kingdom)",
  "Duffus (United Kingdom)",
  "Dukinfield (United Kingdom)",
  "Dulverton (United Kingdom)",
  "Dulwich (United Kingdom)",
  "Dumbarton (United Kingdom)",
  "Dumbleton (United Kingdom)",
  "Dumfries (United Kingdom)",
  "Dunbar (United Kingdom)",
  "Dunblane (United Kingdom)",
  "Dunchurch (United Kingdom)",
  "Dundee (United Kingdom)",
  "Dundonald (United Kingdom)",
  "Dunfermline (United Kingdom)",
  "Dungannon (United Kingdom)",
  "Dungiven (United Kingdom)",
  "Dunkeld (United Kingdom)",
  "Dunkeswell (United Kingdom)",
  "Dunmurry (United Kingdom)",
  "Dunning (United Kingdom)",
  "Dunoon (United Kingdom)",
  "Duns (United Kingdom)",
  "Dunstable (United Kingdom)",
  "Durham (United Kingdom)",
  "Durrington (United Kingdom)",
  "Dursley (United Kingdom)",
  "Duxford (United Kingdom)",
  "Dyce (United Kingdom)",
  "Dymock (United Kingdom)",
  "Dyserth (United Kingdom)",
  "Eagle (United Kingdom)",
  "Eaglesfield (United Kingdom)",
  "Eaglesham (United Kingdom)",
  "Earley (United Kingdom)",
  "Earlham (United Kingdom)",
  "Earls Colne (United Kingdom)",
  "Earls Court (United Kingdom)",
  "Earlston (United Kingdom)",
  "Earnley (United Kingdom)",
  "Easington (United Kingdom)",
  "Easingwold (United Kingdom)",
  "East Barnet (United Kingdom)",
  "East Bergholt (United Kingdom)",
  "East Boldon (United Kingdom)",
  "East Budleigh (United Kingdom)",
  "East Challow (United Kingdom)",
  "East Cowes (United Kingdom)",
  "East Down (United Kingdom)",
  "East Dulwich (United Kingdom)",
  "East Grinstead (United Kingdom)",
  "East Hagbourne (United Kingdom)",
  "East Ham (United Kingdom)",
  "East Hanningfield (United Kingdom)",
  "East Harling (United Kingdom)",
  "East Hoathly (United Kingdom)",
  "East Horsley (United Kingdom)",
  "East Keal (United Kingdom)",
  "East Kilbride (United Kingdom)",
  "East Leake (United Kingdom)",
  "East Linton (United Kingdom)",
  "East Malling (United Kingdom)",
  "East Molesey (United Kingdom)",
  "East Peckham (United Kingdom)",
  "East Preston (United Kingdom)",
  "East Retford (United Kingdom)",
  "East Stour (United Kingdom)",
  "East Tilbury (United Kingdom)",
  "Eastbourne (United Kingdom)",
  "Eastchurch (United Kingdom)",
  "Eastcote (United Kingdom)",
  "Eastham (United Kingdom)",
  "Eastington (United Kingdom)",
  "Eastleigh (United Kingdom)",
  "Easton (United Kingdom)",
  "Eastwood (United Kingdom)",
  "Ebbw Vale (United Kingdom)",
  "Eccles (United Kingdom)",
  "Eccleshall (United Kingdom)",
  "Edenbridge (United Kingdom)",
  "Edenfield (United Kingdom)",
  "Edgbaston (United Kingdom)",
  "Edgefield (United Kingdom)",
  "Edgware (United Kingdom)",
  "Edinburgh (United Kingdom)",
  "Edmonton (United Kingdom)",
  "Edwalton (United Kingdom)",
  "Edwinstowe (United Kingdom)",
  "Effingham (United Kingdom)",
  "Egerton (United Kingdom)",
  "Egham (United Kingdom)",
  "Egremont (United Kingdom)",
  "Egton (United Kingdom)",
  "Elderslie (United Kingdom)",
  "Elgin (United Kingdom)",
  "Elland (United Kingdom)",
  "Ellesmere (United Kingdom)",
  "Ellesmere Port (United Kingdom)",
  "Ellington (United Kingdom)",
  "Ellon (United Kingdom)",
  "Elloughton (United Kingdom)",
  "Elmley Lovett (United Kingdom)",
  "Elstead (United Kingdom)",
  "Elstree (United Kingdom)",
  "Elsworth (United Kingdom)",
  "Eltham (United Kingdom)",
  "Ely (United Kingdom)",
  "Empingham (United Kingdom)",
  "Emsworth (United Kingdom)",
  "Enfield (United Kingdom)",
  "Englefield Green (United Kingdom)",
  "Enniskillen (United Kingdom)",
  "Enstone (United Kingdom)",
  "Epping (United Kingdom)",
  "Epping Green (United Kingdom)",
  "Epsom (United Kingdom)",
  "Erdington (United Kingdom)",
  "Erith (United Kingdom)",
  "Esher (United Kingdom)",
  "Essendon (United Kingdom)",
  "Etchingham (United Kingdom)",
  "Eton (United Kingdom)",
  "Eversholt (United Kingdom)",
  "Evesham (United Kingdom)",
  "Ewelme (United Kingdom)",
  "Ewhurst (United Kingdom)",
  "Exeter (United Kingdom)",
  "Exminster (United Kingdom)",
  "Exmouth (United Kingdom)",
  "Eye (United Kingdom)",
  "Eyemouth (United Kingdom)",
  "Eynsham (United Kingdom)",
  "Failsworth (United Kingdom)",
  "Fairford (United Kingdom)",
  "Fairlight (United Kingdom)",
  "Fakenham (United Kingdom)",
  "Falkirk (United Kingdom)",
  "Falkland (United Kingdom)",
  "Falmouth (United Kingdom)",
  "Fangfoss (United Kingdom)",
  "Fareham (United Kingdom)",
  "Faringdon (United Kingdom)",
  "Farmborough (United Kingdom)",
  "Farnborough (United Kingdom)",
  "Farndon (United Kingdom)",
  "Farnham (United Kingdom)",
  "Farnham Royal (United Kingdom)",
  "Farningham (United Kingdom)",
  "Farnworth (United Kingdom)",
  "Fauldhouse (United Kingdom)",
  "Faulkland (United Kingdom)",
  "Faversham (United Kingdom)",
  "Felbridge (United Kingdom)",
  "Felixstowe (United Kingdom)",
  "Felsted (United Kingdom)",
  "Feltham (United Kingdom)",
  "Ferndale (United Kingdom)",
  "Ferryhill (United Kingdom)",
  "Ferryside (United Kingdom)",
  "Filey (United Kingdom)",
  "Fillongley (United Kingdom)",
  "Finchampstead (United Kingdom)",
  "Finchley (United Kingdom)",
  "Finedon (United Kingdom)",
  "Fintry (United Kingdom)",
  "Fishburn (United Kingdom)",
  "Fishguard (United Kingdom)",
  "Fitzwilliam (United Kingdom)",
  "Fivemiletown (United Kingdom)",
  "Fladbury (United Kingdom)",
  "Fleet (United Kingdom)",
  "Fleetwood (United Kingdom)",
  "Flint (United Kingdom)",
  "Flitwick (United Kingdom)",
  "Flordon (United Kingdom)",
  "Fochabers (United Kingdom)",
  "Folkestone (United Kingdom)",
  "Ford (United Kingdom)",
  "Fordingbridge (United Kingdom)",
  "Forest Row (United Kingdom)",
  "Forfar (United Kingdom)",
  "Formby (United Kingdom)",
  "Forres (United Kingdom)",
  "Fort William (United Kingdom)",
  "Four Marks (United Kingdom)",
  "Fowey (United Kingdom)",
  "Fownhope (United Kingdom)",
  "Framlingham (United Kingdom)",
  "Frant (United Kingdom)",
  "Fraserburgh (United Kingdom)",
  "Freckleton (United Kingdom)",
  "Frensham (United Kingdom)",
  "Freshwater (United Kingdom)",
  "Fressingfield (United Kingdom)",
  "Friern Barnet (United Kingdom)",
  "Frimley (United Kingdom)",
  "Fringford (United Kingdom)",
  "Frinton-on-Sea (United Kingdom)",
  "Friskney (United Kingdom)",
  "Frithville (United Kingdom)",
  "Frizington (United Kingdom)",
  "Frodsham (United Kingdom)",
  "Froggatt (United Kingdom)",
  "Frome (United Kingdom)",
  "Fulham (United Kingdom)",
  "Fulmer (United Kingdom)",
  "Gaerwen (United Kingdom)",
  "Gainsborough (United Kingdom)",
  "Galashiels (United Kingdom)",
  "Galston (United Kingdom)",
  "Gamlingay (United Kingdom)",
  "Gargrave (United Kingdom)",
  "Gargunnock (United Kingdom)",
  "Garrison (United Kingdom)",
  "Garstang (United Kingdom)",
  "Garston (United Kingdom)",
  "Garth (United Kingdom)",
  "Gateshead (United Kingdom)",
  "Gatwick (United Kingdom)",
  "Gaydon (United Kingdom)",
  "Gayton Thorpe (United Kingdom)",
  "Gelligaer (United Kingdom)",
  "Gifford (United Kingdom)",
  "Giggleswick (United Kingdom)",
  "Gillingham (United Kingdom)",
  "Gipping (United Kingdom)",
  "Girton (United Kingdom)",
  "Girvan (United Kingdom)",
  "Glasdrumman (United Kingdom)",
  "Glasgow (United Kingdom)",
  "Glastonbury (United Kingdom)",
  "Glenboig (United Kingdom)",
  "Glenrothes (United Kingdom)",
  "Glenshee (United Kingdom)",
  "Glentham (United Kingdom)",
  "Glossop (United Kingdom)",
  "Gloucester (United Kingdom)",
  "Gnosall (United Kingdom)",
  "Godalming (United Kingdom)",
  "Godmanchester (United Kingdom)",
  "Godmersham (United Kingdom)",
  "Godstone (United Kingdom)",
  "Golborne (United Kingdom)",
  "Gomersal (United Kingdom)",
  "Goodmayes (United Kingdom)",
  "Goodwick (United Kingdom)",
  "Goole (United Kingdom)",
  "Goostrey (United Kingdom)",
  "Gordon (United Kingdom)",
  "Gorebridge (United Kingdom)",
  "Goring (United Kingdom)",
  "Gorleston-on-Sea (United Kingdom)",
  "Gorseinon (United Kingdom)",
  "Gorslas (United Kingdom)",
  "Gorton (United Kingdom)",
  "Gosberton (United Kingdom)",
  "Gosfield (United Kingdom)",
  "Gosforth (United Kingdom)",
  "Gosport (United Kingdom)",
  "Goudhurst (United Kingdom)",
  "Gourock (United Kingdom)",
  "Granby (United Kingdom)",
  "Grange (United Kingdom)",
  "Grangemouth (United Kingdom)",
  "Grantham (United Kingdom)",
  "Grantown on Spey (United Kingdom)",
  "Grasmere (United Kingdom)",
  "Grateley (United Kingdom)",
  "Graveley (United Kingdom)",
  "Gravesend (United Kingdom)",
  "Grays (United Kingdom)",
  "Great Amwell (United Kingdom)",
  "Great Baddow (United Kingdom)",
  "Great Barton (United Kingdom)",
  "Great Billing (United Kingdom)",
  "Great Chesterford (United Kingdom)",
  "Great Dunmow (United Kingdom)",
  "Great Fransham (United Kingdom)",
  "Great Gidding (United Kingdom)",
  "Great Glemham (United Kingdom)",
  "Great Gransden (United Kingdom)",
  "Great Milton (United Kingdom)",
  "Great Missenden (United Kingdom)",
  "Great Ryburgh (United Kingdom)",
  "Great Staughton (United Kingdom)",
  "Great Torrington (United Kingdom)",
  "Great Waldingfield (United Kingdom)",
  "Great Yarmouth (United Kingdom)",
  "Greenfield (United Kingdom)",
  "Greenford (United Kingdom)",
  "Greenhead (United Kingdom)",
  "Greenhithe (United Kingdom)",
  "Greenisland (United Kingdom)",
  "Greenock (United Kingdom)",
  "Greensted (United Kingdom)",
  "Greenwich (United Kingdom)",
  "Grendon (United Kingdom)",
  "Grendon Underwood (United Kingdom)",
  "Gresford (United Kingdom)",
  "Gretna (United Kingdom)",
  "Gretna Green (United Kingdom)",
  "Gretton (United Kingdom)",
  "Grimsargh (United Kingdom)",
  "Grimsby (United Kingdom)",
  "Groombridge (United Kingdom)",
  "Grove (United Kingdom)",
  "Guildford (United Kingdom)",
  "Guisborough (United Kingdom)",
  "Guiseley (United Kingdom)",
  "Gullane (United Kingdom)",
  "Gunnislake (United Kingdom)",
  "Guthrie (United Kingdom)",
  "Hackbridge (United Kingdom)",
  "Hackney (United Kingdom)",
  "Haddenham (United Kingdom)",
  "Haddington (United Kingdom)",
  "Hadleigh (United Kingdom)",
  "Hadlow (United Kingdom)",
  "Hadlow Down (United Kingdom)",
  "Hagley (United Kingdom)",
  "Hailsham (United Kingdom)",
  "Halesowen (United Kingdom)",
  "Halesworth (United Kingdom)",
  "Halewood (United Kingdom)",
  "Halifax (United Kingdom)",
  "Halstead (United Kingdom)",
  "Halton (United Kingdom)",
  "Haltwhistle (United Kingdom)",
  "Halwell (United Kingdom)",
  "Hamble (United Kingdom)",
  "Hambleden (United Kingdom)",
  "Hambleton (United Kingdom)",
  "Hamilton (United Kingdom)",
  "Hammersmith (United Kingdom)",
  "Hampton (United Kingdom)",
  "Hampton Lucy (United Kingdom)",
  "Handcross (United Kingdom)",
  "Handforth (United Kingdom)",
  "Handsworth (United Kingdom)",
  "Hanley (United Kingdom)",
  "Hanwell (United Kingdom)",
  "Hanworth (United Kingdom)",
  "Hapton (United Kingdom)",
  "Harby (United Kingdom)",
  "Hardham (United Kingdom)",
  "Harefield (United Kingdom)",
  "Harlaxton (United Kingdom)",
  "Harlech (United Kingdom)",
  "Harlesden (United Kingdom)",
  "Harleston (United Kingdom)",
  "Harlow (United Kingdom)",
  "Harold Wood (United Kingdom)",
  "Harpenden (United Kingdom)",
  "Harrogate (United Kingdom)",
  "Harrold (United Kingdom)",
  "Harrow (United Kingdom)",
  "Harrow on the Hill (United Kingdom)",
  "Harrow Weald (United Kingdom)",
  "Hartfield (United Kingdom)",
  "Hartford (United Kingdom)",
  "Hartlepool (United Kingdom)",
  "Hartley (United Kingdom)",
  "Hartpury (United Kingdom)",
  "Hartwell (United Kingdom)",
  "Harwell (United Kingdom)",
  "Harwich (United Kingdom)",
  "Harworth (United Kingdom)",
  "Haslemere (United Kingdom)",
  "Haslingden (United Kingdom)",
  "Hassocks (United Kingdom)",
  "Hastings (United Kingdom)",
  "Hatch (United Kingdom)",
  "Hatfield (United Kingdom)",
  "Hatherleigh (United Kingdom)",
  "Hathersage (United Kingdom)",
  "Hatton (United Kingdom)",
  "Havant (United Kingdom)",
  "Haverfordwest (United Kingdom)",
  "Haverhill (United Kingdom)",
  "Havering atte Bower (United Kingdom)",
  "Hawarden (United Kingdom)",
  "Hawes (United Kingdom)",
  "Hawick (United Kingdom)",
  "Hawkhurst (United Kingdom)",
  "Hawkwell (United Kingdom)",
  "Hawley (United Kingdom)",
  "Haydock (United Kingdom)",
  "Haydon Bridge (United Kingdom)",
  "Hayes (United Kingdom)",
  "Hayle (United Kingdom)",
  "Haywards Heath (United Kingdom)",
  "Hazel Grove (United Kingdom)",
  "Hazelwood (United Kingdom)",
  "Headcorn (United Kingdom)",
  "Headington (United Kingdom)",
  "Headley (United Kingdom)",
  "Heanor (United Kingdom)",
  "Heath (United Kingdom)",
  "Heathfield (United Kingdom)",
  "Heathrow (United Kingdom)",
  "Hebburn (United Kingdom)",
  "Hebden Bridge (United Kingdom)",
  "Heckington (United Kingdom)",
  "Heckmondwike (United Kingdom)",
  "Hedgerley (United Kingdom)",
  "Hednesford (United Kingdom)",
  "Hedon (United Kingdom)",
  "Helens Bay (United Kingdom)",
  "Helensburgh (United Kingdom)",
  "Hellesdon (United Kingdom)",
  "Helmsley (United Kingdom)",
  "Helston (United Kingdom)",
  "Hemel Hempstead (United Kingdom)",
  "Hemingstone (United Kingdom)",
  "Hemswell (United Kingdom)",
  "Hemsworth (United Kingdom)",
  "Hendon (United Kingdom)",
  "Henfield (United Kingdom)",
  "Hengoed (United Kingdom)",
  "Henham (United Kingdom)",
  "Henley (United Kingdom)",
  "Henley-on-Thames (United Kingdom)",
  "Henlow (United Kingdom)",
  "Hennock (United Kingdom)",
  "Henstridge (United Kingdom)",
  "Hereford (United Kingdom)",
  "Heriot (United Kingdom)",
  "Hermitage (United Kingdom)",
  "Herne Bay (United Kingdom)",
  "Herriard (United Kingdom)",
  "Hersham (United Kingdom)",
  "Herstmonceux (United Kingdom)",
  "Hertford (United Kingdom)",
  "Hessle (United Kingdom)",
  "Heston (United Kingdom)",
  "Heswall (United Kingdom)",
  "Hever (United Kingdom)",
  "Hexham (United Kingdom)",
  "Heybridge (United Kingdom)",
  "Heysham (United Kingdom)",
  "Heythrop (United Kingdom)",
  "Heywood (United Kingdom)",
  "High Bentham (United Kingdom)",
  "High Blantyre (United Kingdom)",
  "High Halden (United Kingdom)",
  "High Legh (United Kingdom)",
  "High Peak (United Kingdom)",
  "High Peak Junction (United Kingdom)",
  "High Wycombe (United Kingdom)",
  "Higham Ferrers (United Kingdom)",
  "Higham on the Hill (United Kingdom)",
  "Highbridge (United Kingdom)",
  "Highbury (United Kingdom)",
  "Highcliffe (United Kingdom)",
  "Higher Bebington (United Kingdom)",
  "Hightown (United Kingdom)",
  "Highway (United Kingdom)",
  "Highworth (United Kingdom)",
  "Hilborough (United Kingdom)",
  "Hilderstone (United Kingdom)",
  "Hill (United Kingdom)",
  "Hillingdon (United Kingdom)",
  "Hillsborough (United Kingdom)",
  "Hillside (United Kingdom)",
  "Hilton (United Kingdom)",
  "Hinckley (United Kingdom)",
  "Hindhead (United Kingdom)",
  "Hindley (United Kingdom)",
  "Hindon (United Kingdom)",
  "Hingham (United Kingdom)",
  "Hinton St George (United Kingdom)",
  "Histon (United Kingdom)",
  "Hitcham (United Kingdom)",
  "Hitchin (United Kingdom)",
  "Hockley (United Kingdom)",
  "Hoddesdon (United Kingdom)",
  "Holbeach (United Kingdom)",
  "Holborn (United Kingdom)",
  "Holmes Chapel (United Kingdom)",
  "Holmewood (United Kingdom)",
  "Holmfirth (United Kingdom)",
  "Holsworthy (United Kingdom)",
  "Holt (United Kingdom)",
  "Holyhead (United Kingdom)",
  "Holywell (United Kingdom)",
  "Holywood (United Kingdom)",
  "Honingham (United Kingdom)",
  "Honiton (United Kingdom)",
  "Hook (United Kingdom)",
  "Hooke (United Kingdom)",
  "Hopwood (United Kingdom)",
  "Horam (United Kingdom)",
  "Horbling (United Kingdom)",
  "Horbury (United Kingdom)",
  "Horley (United Kingdom)",
  "Horncastle (United Kingdom)",
  "Hornchurch (United Kingdom)",
  "Horndean (United Kingdom)",
  "Horndon on the Hill (United Kingdom)",
  "Hornsea (United Kingdom)",
  "Hornsey (United Kingdom)",
  "Horrabridge (United Kingdom)",
  "Horsham (United Kingdom)",
  "Horsmonden (United Kingdom)",
  "Horsted Keynes (United Kingdom)",
  "Horton Kirby (United Kingdom)",
  "Horwich (United Kingdom)",
  "Hotham (United Kingdom)",
  "Houghton on the Hill (United Kingdom)",
  "Houghton Regis (United Kingdom)",
  "Houghton-le-Spring (United Kingdom)",
  "Houston (United Kingdom)",
  "Hove (United Kingdom)",
  "Howden (United Kingdom)",
  "Hoylake (United Kingdom)",
  "Hucknall Torkard (United Kingdom)",
  "Hucknall under Huthwaite (United Kingdom)",
  "Huddersfield (United Kingdom)",
  "Huish (United Kingdom)",
  "Hull (United Kingdom)",
  "Humberston (United Kingdom)",
  "Humbie (United Kingdom)",
  "Hungerford (United Kingdom)",
  "Hunstanton (United Kingdom)",
  "Huntingdon (United Kingdom)",
  "Huntly (United Kingdom)",
  "Huntspill (United Kingdom)",
  "Hursley (United Kingdom)",
  "Hurstbourne Tarrant (United Kingdom)",
  "Hurstpierpoint (United Kingdom)",
  "Hurworth (United Kingdom)",
  "Huyton (United Kingdom)",
  "Hyde (United Kingdom)",
  "Hythe (United Kingdom)",
  "Ibstock (United Kingdom)",
  "Ickenham (United Kingdom)",
  "Ifield (United Kingdom)",
  "Ilchester (United Kingdom)",
  "Ilford (United Kingdom)",
  "Ilfracombe (United Kingdom)",
  "Ilkeston (United Kingdom)",
  "Ilkley (United Kingdom)",
  "Ilminster (United Kingdom)",
  "Immingham (United Kingdom)",
  "Inchinnan (United Kingdom)",
  "Ingatestone (United Kingdom)",
  "Innerleithen (United Kingdom)",
  "Insch (United Kingdom)",
  "Inveraray (United Kingdom)",
  "Invergordon (United Kingdom)",
  "Inverkeilor (United Kingdom)",
  "Inverkeithing (United Kingdom)",
  "Inverkip (United Kingdom)",
  "Inverness (United Kingdom)",
  "Inverness-shire (United Kingdom)",
  "Inverurie (United Kingdom)",
  "Ipswich (United Kingdom)",
  "Irlam (United Kingdom)",
  "Irthlingborough (United Kingdom)",
  "Irvine (United Kingdom)",
  "Isham (United Kingdom)",
  "Isleham (United Kingdom)",
  "Isleworth (United Kingdom)",
  "Islington (United Kingdom)",
  "Islip (United Kingdom)",
  "Itchen (United Kingdom)",
  "Itchen Abbas (United Kingdom)",
  "Iver (United Kingdom)",
  "Ivybridge (United Kingdom)",
  "Iwerne Courtney (United Kingdom)",
  "Jarrow (United Kingdom)",
  "Jedburgh (United Kingdom)",
  "Johnstone (United Kingdom)",
  "Jordanstown (United Kingdom)",
  "Juniper Green (United Kingdom)",
  "Kedington (United Kingdom)",
  "Keele (United Kingdom)",
  "Keighley (United Kingdom)",
  "Keith (United Kingdom)",
  "Kelbrook (United Kingdom)",
  "Kelly (United Kingdom)",
  "Kelmarsh (United Kingdom)",
  "Kelsall (United Kingdom)",
  "Kelso (United Kingdom)",
  "Kelty (United Kingdom)",
  "Kelvedon (United Kingdom)",
  "Kempston (United Kingdom)",
  "Kendal (United Kingdom)",
  "Kenilworth (United Kingdom)",
  "Kenley (United Kingdom)",
  "Kennington (United Kingdom)",
  "Kennoway (United Kingdom)",
  "Kensington (United Kingdom)",
  "Kent (United Kingdom)",
  "Keresley (United Kingdom)",
  "Keston (United Kingdom)",
  "Keswick (United Kingdom)",
  "Ketley (United Kingdom)",
  "Kettering (United Kingdom)",
  "Keynsham (United Kingdom)",
  "Kibworth Harcourt (United Kingdom)",
  "Kidderminster (United Kingdom)",
  "Kidlington (United Kingdom)",
  "Kidsgrove (United Kingdom)",
  "Kidwelly (United Kingdom)",
  "Kilbarchan (United Kingdom)",
  "Kilbirnie (United Kingdom)",
  "Kilbride (United Kingdom)",
  "Kilkeel (United Kingdom)",
  "Killamarsh (United Kingdom)",
  "Killin (United Kingdom)",
  "Kilmacolm (United Kingdom)",
  "Kilmarnock (United Kingdom)",
  "Kilsyth (United Kingdom)",
  "Kilwinning (United Kingdom)",
  "Kimberley (United Kingdom)",
  "Kimbolton (United Kingdom)",
  "Kingham (United Kingdom)",
  "Kinghorn (United Kingdom)",
  "Kinglassie (United Kingdom)",
  "Kings Langley (United Kingdom)",
  "Kings Lynn (United Kingdom)",
  "Kings Norton (United Kingdom)",
  "Kings Sutton (United Kingdom)",
  "Kingsbridge (United Kingdom)",
  "Kingskerswell (United Kingdom)",
  "Kingsland (United Kingdom)",
  "Kingsteignton (United Kingdom)",
  "Kingston (United Kingdom)",
  "Kingston Seymour (United Kingdom)",
  "Kingswinford (United Kingdom)",
  "Kingswood (United Kingdom)",
  "Kingussie (United Kingdom)",
  "Kinloch Rannoch (United Kingdom)",
  "Kinmel (United Kingdom)",
  "Kinnerley (United Kingdom)",
  "Kinross (United Kingdom)",
  "Kirby Cross (United Kingdom)",
  "Kirk Ella (United Kingdom)",
  "Kirkby (United Kingdom)",
  "Kirkby in Ashfield (United Kingdom)",
  "Kirkby Stephen (United Kingdom)",
  "Kirkcaldy (United Kingdom)",
  "Kirkconnel (United Kingdom)",
  "Kirkcudbright (United Kingdom)",
  "Kirkham (United Kingdom)",
  "Kirkintilloch (United Kingdom)",
  "Kirkliston (United Kingdom)",
  "Kirkwall (United Kingdom)",
  "Kirriemuir (United Kingdom)",
  "Kirtlington (United Kingdom)",
  "Kirton in Lindsey (United Kingdom)",
  "Knaresborough (United Kingdom)",
  "Knebworth (United Kingdom)",
  "Kneeton (United Kingdom)",
  "Knighton (United Kingdom)",
  "Knottingley (United Kingdom)",
  "Knowsley (United Kingdom)",
  "Knutsford (United Kingdom)",
  "Kyle of Lochalsh (United Kingdom)",
  "Laindon (United Kingdom)",
  "Lakenheath (United Kingdom)",
  "Lambeth (United Kingdom)",
  "Lambourn (United Kingdom)",
  "Lampeter (United Kingdom)",
  "Lanark (United Kingdom)",
  "Lancaster (United Kingdom)",
  "Lancing (United Kingdom)",
  "Landrake (United Kingdom)",
  "Langho (United Kingdom)",
  "Langley (United Kingdom)",
  "Langport (United Kingdom)",
  "Langstone (United Kingdom)",
  "Lapworth (United Kingdom)",
  "Larbert (United Kingdom)",
  "Largs (United Kingdom)",
  "Larkhall (United Kingdom)",
  "Larne (United Kingdom)",
  "Lauder (United Kingdom)",
  "Laugharne (United Kingdom)",
  "Launceston (United Kingdom)",
  "Laurencekirk (United Kingdom)",
  "Lavant (United Kingdom)",
  "Lavendon (United Kingdom)",
  "Lawrencetown (United Kingdom)",
  "Laxfield (United Kingdom)",
  "Laxton (United Kingdom)",
  "Leatherhead (United Kingdom)",
  "Lechlade (United Kingdom)",
  "Leconfield (United Kingdom)",
  "Ledbury (United Kingdom)",
  "Lee (United Kingdom)",
  "Lee-on-the-Solent (United Kingdom)",
  "Leeds (United Kingdom)",
  "Leek (United Kingdom)",
  "Leek Wootton (United Kingdom)",
  "Leicester (United Kingdom)",
  "Leigh (United Kingdom)",
  "Leigh-on-Sea (United Kingdom)",
  "Leighton Buzzard (United Kingdom)",
  "Leiston (United Kingdom)",
  "Leitholm (United Kingdom)",
  "Lenham (United Kingdom)",
  "Leominster (United Kingdom)",
  "Lerwick (United Kingdom)",
  "Lesmahagow (United Kingdom)",
  "Letchworth (United Kingdom)",
  "Leuchars (United Kingdom)",
  "Leven (United Kingdom)",
  "Levenshulme (United Kingdom)",
  "Lewes (United Kingdom)",
  "Lewisham (United Kingdom)",
  "Leyburn (United Kingdom)",
  "Leyland (United Kingdom)",
  "Leysdown-on-Sea (United Kingdom)",
  "Leyton (United Kingdom)",
  "Lichfield (United Kingdom)",
  "Lidlington (United Kingdom)",
  "Lifton (United Kingdom)",
  "Limavady (United Kingdom)",
  "Limekilns (United Kingdom)",
  "Lincoln (United Kingdom)",
  "Lindal in Furness (United Kingdom)",
  "Lindfield (United Kingdom)",
  "Lingfield (United Kingdom)",
  "Linlithgow (United Kingdom)",
  "Linthwaite (United Kingdom)",
  "Linton (United Kingdom)",
  "Linton upon Ouse (United Kingdom)",
  "Liphook (United Kingdom)",
  "Lisburn (United Kingdom)",
  "Liskeard (United Kingdom)",
  "Lisnaskea (United Kingdom)",
  "Liss (United Kingdom)",
  "Litherland (United Kingdom)",
  "Little Barningham (United Kingdom)",
  "Little Canfield (United Kingdom)",
  "Little Eaton (United Kingdom)",
  "Little Gaddesden (United Kingdom)",
  "Little Hulton (United Kingdom)",
  "Little Kimble (United Kingdom)",
  "Little Lever (United Kingdom)",
  "Little Milton (United Kingdom)",
  "Little Paxton (United Kingdom)",
  "Littleborough (United Kingdom)",
  "Littlebury (United Kingdom)",
  "Littlehampton (United Kingdom)",
  "Littleport (United Kingdom)",
  "Liverpool (United Kingdom)",
  "Liversedge (United Kingdom)",
  "Livingston (United Kingdom)",
  "Llanbedr (United Kingdom)",
  "Llanddulas (United Kingdom)",
  "Llandeilo (United Kingdom)",
  "Llandovery (United Kingdom)",
  "Llandrillo (United Kingdom)",
  "Llandrindod Wells (United Kingdom)",
  "Llandudno (United Kingdom)",
  "Llandudno Junction (United Kingdom)",
  "Llanelli (United Kingdom)",
  "Llanfair-Dyffryn-Clwyd (United Kingdom)",
  "Llanfairfechan (United Kingdom)",
  "Llanfyllin (United Kingdom)",
  "Llanfyrnach (United Kingdom)",
  "Llangattock (United Kingdom)",
  "Llangefni (United Kingdom)",
  "Llangennech (United Kingdom)",
  "Llangollen (United Kingdom)",
  "Llanharan (United Kingdom)",
  "Llanidloes (United Kingdom)",
  "Llanishen (United Kingdom)",
  "Llanon (United Kingdom)",
  "Llanrwst (United Kingdom)",
  "Llansantffraid-ym-Mechain (United Kingdom)",
  "Llantrisant (United Kingdom)",
  "Llantwit Fardre (United Kingdom)",
  "Llantwit Major (United Kingdom)",
  "Llanwrda (United Kingdom)",
  "Llanwrtyd Wells (United Kingdom)",
  "Llanymynech (United Kingdom)",
  "Llwyngwril (United Kingdom)",
  "Llwynypia (United Kingdom)",
  "Loanhead (United Kingdom)",
  "Lochgelly (United Kingdom)",
  "Lochgilphead (United Kingdom)",
  "Lochmaddy (United Kingdom)",
  "Lochwinnoch (United Kingdom)",
  "Lockerbie (United Kingdom)",
  "Loddington (United Kingdom)",
  "London (United Kingdom)",
  "London Borough of Bromley (United Kingdom)",
  "London Borough of Hounslow (United Kingdom)",
  "London Borough of Wandsworth (United Kingdom)",
  "Londonderry (United Kingdom)",
  "Long Buckby (United Kingdom)",
  "Long Ditton (United Kingdom)",
  "Long Eaton (United Kingdom)",
  "Long Melford (United Kingdom)",
  "Long Stratton (United Kingdom)",
  "Longdown (United Kingdom)",
  "Longfield (United Kingdom)",
  "Longhope (United Kingdom)",
  "Longniddry (United Kingdom)",
  "Longport (United Kingdom)",
  "Longridge (United Kingdom)",
  "Longstanton (United Kingdom)",
  "Longton (United Kingdom)",
  "Looe (United Kingdom)",
  "Loppington (United Kingdom)",
  "Lossiemouth (United Kingdom)",
  "Lostwithiel (United Kingdom)",
  "Loudwater (United Kingdom)",
  "Loughborough (United Kingdom)",
  "Loughor (United Kingdom)",
  "Loughton (United Kingdom)",
  "Louth (United Kingdom)",
  "Low Ham (United Kingdom)",
  "Lowdham (United Kingdom)",
  "Lower Beeding (United Kingdom)",
  "Lower Brailes (United Kingdom)",
  "Lower Darwen (United Kingdom)",
  "Lowestoft (United Kingdom)",
  "Lowton (United Kingdom)",
  "Lubenham (United Kingdom)",
  "Ludlow (United Kingdom)",
  "Lupton (United Kingdom)",
  "Lurgan (United Kingdom)",
  "Lustleigh (United Kingdom)",
  "Luton (United Kingdom)",
  "Lutterworth (United Kingdom)",
  "Lydbury North (United Kingdom)",
  "Lydney (United Kingdom)",
  "Lyme Regis (United Kingdom)",
  "Lyminge (United Kingdom)",
  "Lymington (United Kingdom)",
  "Lymm (United Kingdom)",
  "Lympsham (United Kingdom)",
  "Lyndhurst (United Kingdom)",
  "Lyng (United Kingdom)",
  "Lytchett Minster (United Kingdom)",
  "Lytham (United Kingdom)",
  "Mablethorpe (United Kingdom)",
  "Macclesfield (United Kingdom)",
  "Macduff (United Kingdom)",
  "Machynlleth (United Kingdom)",
  "Maerdy (United Kingdom)",
  "Maesteg (United Kingdom)",
  "Maghera (United Kingdom)",
  "Magherafelt (United Kingdom)",
  "Magheralin (United Kingdom)",
  "Maghull (United Kingdom)",
  "Maida Vale (United Kingdom)",
  "Maiden Newton (United Kingdom)",
  "Maidenhead (United Kingdom)",
  "Maidstone (United Kingdom)",
  "Malden (United Kingdom)",
  "Maldon (United Kingdom)",
  "Mallaig (United Kingdom)",
  "Malmesbury (United Kingdom)",
  "Malpas (United Kingdom)",
  "Malton (United Kingdom)",
  "Malvern (United Kingdom)",
  "Manchester (United Kingdom)",
  "Manningtree (United Kingdom)",
  "Manor (United Kingdom)",
  "Mansfield (United Kingdom)",
  "Mansfield Woodhouse (United Kingdom)",
  "Manton (United Kingdom)",
  "March (United Kingdom)",
  "Marcham (United Kingdom)",
  "Marden (United Kingdom)",
  "Margate (United Kingdom)",
  "Marhamchurch (United Kingdom)",
  "Mark (United Kingdom)",
  "Market Deeping (United Kingdom)",
  "Market Drayton (United Kingdom)",
  "Market Harborough (United Kingdom)",
  "Market Rasen (United Kingdom)",
  "Market Weighton (United Kingdom)",
  "Markfield (United Kingdom)",
  "Marlborough (United Kingdom)",
  "Marlow (United Kingdom)",
  "Marnhull (United Kingdom)",
  "Marple (United Kingdom)",
  "Marr (United Kingdom)",
  "Marsham (United Kingdom)",
  "Marske (United Kingdom)",
  "Martin (United Kingdom)",
  "Martley (United Kingdom)",
  "Martock (United Kingdom)",
  "Maryhill (United Kingdom)",
  "Maryport (United Kingdom)",
  "Masham (United Kingdom)",
  "Matching (United Kingdom)",
  "Matlock (United Kingdom)",
  "Mattishall (United Kingdom)",
  "Mauchline (United Kingdom)",
  "Maulden (United Kingdom)",
  "Maybole (United Kingdom)",
  "Mayfair (United Kingdom)",
  "Mayfield (United Kingdom)",
  "Meanwood (United Kingdom)",
  "Measham (United Kingdom)",
  "Medmenham (United Kingdom)",
  "Medstead (United Kingdom)",
  "Meesden (United Kingdom)",
  "Meggies (United Kingdom)",
  "Meifod (United Kingdom)",
  "Melbourne (United Kingdom)",
  "Meldreth (United Kingdom)",
  "Melksham (United Kingdom)",
  "Mells (United Kingdom)",
  "Melrose (United Kingdom)",
  "Melton (United Kingdom)",
  "Melton Constable (United Kingdom)",
  "Melton Mowbray (United Kingdom)",
  "Menai Bridge (United Kingdom)",
  "Mendlesham (United Kingdom)",
  "Menheniot (United Kingdom)",
  "Menston (United Kingdom)",
  "Meopham (United Kingdom)",
  "Mere (United Kingdom)",
  "Merthyr Mawr (United Kingdom)",
  "Merthyr Tydfil (United Kingdom)",
  "Merton (United Kingdom)",
  "Metheringham (United Kingdom)",
  "Methil (United Kingdom)",
  "Mexborough (United Kingdom)",
  "Mickleton (United Kingdom)",
  "Mid Calder (United Kingdom)",
  "Middlesbrough (United Kingdom)",
  "Middleton (United Kingdom)",
  "Middleton One Row (United Kingdom)",
  "Middlewich (United Kingdom)",
  "Midford (United Kingdom)",
  "Midgham (United Kingdom)",
  "Midhurst (United Kingdom)",
  "Midsomer Norton (United Kingdom)",
  "Mildenhall (United Kingdom)",
  "Milford (United Kingdom)",
  "Milford Haven (United Kingdom)",
  "Milford on Sea (United Kingdom)",
  "Mill Hill (United Kingdom)",
  "Millbrook (United Kingdom)",
  "Millom (United Kingdom)",
  "Milltimber (United Kingdom)",
  "Milnathort (United Kingdom)",
  "Milnthorpe (United Kingdom)",
  "Milton (United Kingdom)",
  "Milton Keynes (United Kingdom)",
  "Milton on Stour (United Kingdom)",
  "Minchinhampton (United Kingdom)",
  "Minehead (United Kingdom)",
  "Minster (United Kingdom)",
  "Minster Lovell (United Kingdom)",
  "Minsterley (United Kingdom)",
  "Mirfield (United Kingdom)",
  "Mitcham (United Kingdom)",
  "Mitcheldean (United Kingdom)",
  "Mobberley (United Kingdom)",
  "Mochdre (United Kingdom)",
  "Moira (United Kingdom)",
  "Mold (United Kingdom)",
  "Molesey (United Kingdom)",
  "Mollington (United Kingdom)",
  "Moneymore (United Kingdom)",
  "Monifieth (United Kingdom)",
  "Monkswood (United Kingdom)",
  "Monkton (United Kingdom)",
  "Monmouth (United Kingdom)",
  "Montgomery (United Kingdom)",
  "Montrose (United Kingdom)",
  "Monyash (United Kingdom)",
  "Moorsholm (United Kingdom)",
  "Moorside (United Kingdom)",
  "Morden (United Kingdom)",
  "More (United Kingdom)",
  "Morecambe (United Kingdom)",
  "Moreton (United Kingdom)",
  "Moreton in Marsh (United Kingdom)",
  "Morley (United Kingdom)",
  "Morpeth (United Kingdom)",
  "Morriston (United Kingdom)",
  "Moseley (United Kingdom)",
  "Moss (United Kingdom)",
  "Mossley (United Kingdom)",
  "Moston (United Kingdom)",
  "Motherwell (United Kingdom)",
  "Moulton (United Kingdom)",
  "Moulton St Michael (United Kingdom)",
  "Mount Bures (United Kingdom)",
  "Mount Hamilton (United Kingdom)",
  "Mountain Ash (United Kingdom)",
  "Mountsorrel (United Kingdom)",
  "Much Hadham (United Kingdom)",
  "Much Hoole (United Kingdom)",
  "Much Wenlock (United Kingdom)",
  "Muir of Ord (United Kingdom)",
  "Mundesley (United Kingdom)",
  "Murton (United Kingdom)",
  "Musselburgh (United Kingdom)",
  "Myddle (United Kingdom)",
  "Mytholmroyd (United Kingdom)",
  "Myton on Swale (United Kingdom)",
  "Nafferton (United Kingdom)",
  "Nailsea (United Kingdom)",
  "Nairn (United Kingdom)",
  "Nantwich (United Kingdom)",
  "Nantyglo (United Kingdom)",
  "Napton on the Hill (United Kingdom)",
  "Narberth (United Kingdom)",
  "Naseby (United Kingdom)",
  "Nash (United Kingdom)",
  "Nassington (United Kingdom)",
  "Neasden (United Kingdom)",
  "Neath (United Kingdom)",
  "Needingworth (United Kingdom)",
  "Neilston (United Kingdom)",
  "Nelson (United Kingdom)",
  "Neston (United Kingdom)",
  "Nettlebed (United Kingdom)",
  "Nettleton (United Kingdom)",
  "New Barnet (United Kingdom)",
  "New Buckenham (United Kingdom)",
  "New Cross (United Kingdom)",
  "New Ferry (United Kingdom)",
  "New Mills (United Kingdom)",
  "New Milton (United Kingdom)",
  "New Quay (United Kingdom)",
  "New Romney (United Kingdom)",
  "New Southgate (United Kingdom)",
  "New Tredegar (United Kingdom)",
  "Newark on Trent (United Kingdom)",
  "Newbiggin-by-the-Sea (United Kingdom)",
  "Newbold (United Kingdom)",
  "Newbridge (United Kingdom)",
  "Newburgh (United Kingdom)",
  "Newbury (United Kingdom)",
  "Newcastle (United Kingdom)",
  "Newcastle Emlyn (United Kingdom)",
  "Newcastle upon Tyne (United Kingdom)",
  "Newcastle-under-Lyme (United Kingdom)",
  "Newent (United Kingdom)",
  "Newhall (United Kingdom)",
  "Newham (United Kingdom)",
  "Newhaven (United Kingdom)",
  "Newick (United Kingdom)",
  "Newlands (United Kingdom)",
  "Newmarket (United Kingdom)",
  "Newport (United Kingdom)",
  "Newport Pagnell (United Kingdom)",
  "Newport-On-Tay (United Kingdom)",
  "Newquay (United Kingdom)",
  "Newry (United Kingdom)",
  "Newton Abbot (United Kingdom)",
  "Newton Aycliffe (United Kingdom)",
  "Newton on Trent (United Kingdom)",
  "Newton Stewart (United Kingdom)",
  "Newton-le-Willows (United Kingdom)",
  "Newtonmore (United Kingdom)",
  "Newtown (United Kingdom)",
  "Newtownabbey (United Kingdom)",
  "Newtownards (United Kingdom)",
  "Norbury (United Kingdom)",
  "Nordelph (United Kingdom)",
  "Norham (United Kingdom)",
  "Normandy (United Kingdom)",
  "Normanton (United Kingdom)",
  "North Berwick (United Kingdom)",
  "North Elmsall (United Kingdom)",
  "North Ferriby (United Kingdom)",
  "North Hykeham (United Kingdom)",
  "North Kilworth (United Kingdom)",
  "North Leigh (United Kingdom)",
  "North Moreton (United Kingdom)",
  "North Newton (United Kingdom)",
  "North Petherton (United Kingdom)",
  "North Shields (United Kingdom)",
  "North Somercotes (United Kingdom)",
  "North Tawton (United Kingdom)",
  "North Walsham (United Kingdom)",
  "North Waltham (United Kingdom)",
  "North Weald (United Kingdom)",
  "Northallerton (United Kingdom)",
  "Northampton (United Kingdom)",
  "Northenden (United Kingdom)",
  "Northfield (United Kingdom)",
  "Northfleet (United Kingdom)",
  "Northleach (United Kingdom)",
  "Northolt (United Kingdom)",
  "Northop (United Kingdom)",
  "Northrepps (United Kingdom)",
  "Northwich (United Kingdom)",
  "Northwood (United Kingdom)",
  "Norton (United Kingdom)",
  "Norton Lindsey (United Kingdom)",
  "Norwich (United Kingdom)",
  "Norwood (United Kingdom)",
  "Nottingham (United Kingdom)",
  "Nuffield (United Kingdom)",
  "Nuneaton (United Kingdom)",
  "Nutfield (United Kingdom)",
  "Nutley (United Kingdom)",
  "Oadby (United Kingdom)",
  "Oakamoor (United Kingdom)",
  "Oakford (United Kingdom)",
  "Oakham (United Kingdom)",
  "Oakhill (United Kingdom)",
  "Oakley (United Kingdom)",
  "Oare (United Kingdom)",
  "Oban (United Kingdom)",
  "Odiham (United Kingdom)",
  "Offord Cluny (United Kingdom)",
  "Okehampton (United Kingdom)",
  "Old Basing (United Kingdom)",
  "Old Buckenham (United Kingdom)",
  "Old Colwyn (United Kingdom)",
  "Old Malton (United Kingdom)",
  "Old Windsor (United Kingdom)",
  "Oldbury (United Kingdom)",
  "Oldham (United Kingdom)",
  "Oldmeldrum (United Kingdom)",
  "Olney (United Kingdom)",
  "Omagh (United Kingdom)",
  "Ormesby (United Kingdom)",
  "Ormiston (United Kingdom)",
  "Ormskirk (United Kingdom)",
  "Orpington (United Kingdom)",
  "Ossett (United Kingdom)",
  "Oswaldtwistle (United Kingdom)",
  "Oswestry (United Kingdom)",
  "Otford (United Kingdom)",
  "Otley (United Kingdom)",
  "Otterburn (United Kingdom)",
  "Ottershaw (United Kingdom)",
  "Ottery St Mary (United Kingdom)",
  "Oulton (United Kingdom)",
  "Oundle (United Kingdom)",
  "Overton (United Kingdom)",
  "Oving (United Kingdom)",
  "Ovingdean (United Kingdom)",
  "Owslebury (United Kingdom)",
  "Oxenhope (United Kingdom)",
  "Oxford (United Kingdom)",
  "Oxshott (United Kingdom)",
  "Oxted (United Kingdom)",
  "Padbury (United Kingdom)",
  "Paddock Wood (United Kingdom)",
  "Padiham (United Kingdom)",
  "Padstow (United Kingdom)",
  "Paignton (United Kingdom)",
  "Painswick (United Kingdom)",
  "Paisley (United Kingdom)",
  "Palmers Green (United Kingdom)",
  "Pampisford (United Kingdom)",
  "Papworth Everard (United Kingdom)",
  "Par (United Kingdom)",
  "Parbold (United Kingdom)",
  "Partington (United Kingdom)",
  "Partridge Green (United Kingdom)",
  "Paston (United Kingdom)",
  "Patchway (United Kingdom)",
  "Pathhead (United Kingdom)",
  "Patrington (United Kingdom)",
  "Paul (United Kingdom)",
  "Peebles (United Kingdom)",
  "Pelsall (United Kingdom)",
  "Pembroke (United Kingdom)",
  "Pembroke Dock (United Kingdom)",
  "Pembury (United Kingdom)",
  "Penarth (United Kingdom)",
  "Pencader (United Kingdom)",
  "Pencaitland (United Kingdom)",
  "Pencarreg (United Kingdom)",
  "Pencoed (United Kingdom)",
  "Pendlebury (United Kingdom)",
  "Penicuik (United Kingdom)",
  "Penkridge (United Kingdom)",
  "Penn (United Kingdom)",
  "Pennington (United Kingdom)",
  "Penrice (United Kingdom)",
  "Penrith (United Kingdom)",
  "Penryn (United Kingdom)",
  "Penshurst (United Kingdom)",
  "Pentraeth (United Kingdom)",
  "Penwortham (United Kingdom)",
  "Penzance (United Kingdom)",
  "Perivale (United Kingdom)",
  "Perranporth (United Kingdom)",
  "Pershore (United Kingdom)",
  "Perth (United Kingdom)",
  "Peterborough (United Kingdom)",
  "Peterculter (United Kingdom)",
  "Peterhead (United Kingdom)",
  "Peterlee (United Kingdom)",
  "Petersfield (United Kingdom)",
  "Petworth (United Kingdom)",
  "Pevensey (United Kingdom)",
  "Pewsey (United Kingdom)",
  "Pickering (United Kingdom)",
  "Piddington (United Kingdom)",
  "Pilham (United Kingdom)",
  "Pilton (United Kingdom)",
  "Pinner (United Kingdom)",
  "Pinxton (United Kingdom)",
  "Pitlochry (United Kingdom)",
  "Pitsford (United Kingdom)",
  "Pittenweem (United Kingdom)",
  "Plaistow (United Kingdom)",
  "Plaitford (United Kingdom)",
  "Pleshey (United Kingdom)",
  "Plockton (United Kingdom)",
  "Plumstead (United Kingdom)",
  "Plymouth (United Kingdom)",
  "Plymstock (United Kingdom)",
  "Pocklington (United Kingdom)",
  "Polegate (United Kingdom)",
  "Polmont (United Kingdom)",
  "Polperro (United Kingdom)",
  "Ponders End (United Kingdom)",
  "Pontardawe (United Kingdom)",
  "Pontefract (United Kingdom)",
  "Ponteland (United Kingdom)",
  "Pontesbury (United Kingdom)",
  "Pontycymer (United Kingdom)",
  "Pontypool (United Kingdom)",
  "Pontypridd (United Kingdom)",
  "Poole (United Kingdom)",
  "Poplar (United Kingdom)",
  "Porlock (United Kingdom)",
  "Port Erroll (United Kingdom)",
  "Port Glasgow (United Kingdom)",
  "Port Sunlight (United Kingdom)",
  "Port Talbot (United Kingdom)",
  "Portadown (United Kingdom)",
  "Portaferry (United Kingdom)",
  "Portchester (United Kingdom)",
  "Portglenone (United Kingdom)",
  "Porth (United Kingdom)",
  "Porthcawl (United Kingdom)",
  "Porthleven (United Kingdom)",
  "Portishead (United Kingdom)",
  "Portlethen (United Kingdom)",
  "Portmadoc (United Kingdom)",
  "Portree (United Kingdom)",
  "Portrush (United Kingdom)",
  "Portslade-by-Sea (United Kingdom)",
  "Portsmouth (United Kingdom)",
  "Portstewart (United Kingdom)",
  "Postling (United Kingdom)",
  "Potters Bar (United Kingdom)",
  "Potterspury (United Kingdom)",
  "Potton (United Kingdom)",
  "Poulton le Fylde (United Kingdom)",
  "Powfoot (United Kingdom)",
  "Powick (United Kingdom)",
  "Poynton (United Kingdom)",
  "Prees (United Kingdom)",
  "Preesall (United Kingdom)",
  "Prescot (United Kingdom)",
  "Prestatyn (United Kingdom)",
  "Presteigne (United Kingdom)",
  "Preston (United Kingdom)",
  "Prestonpans (United Kingdom)",
  "Prestwich (United Kingdom)",
  "Prestwick (United Kingdom)",
  "Princes Risborough (United Kingdom)",
  "Princethorpe (United Kingdom)",
  "Privett (United Kingdom)",
  "Prudhoe (United Kingdom)",
  "Puckeridge (United Kingdom)",
  "Pudsey (United Kingdom)",
  "Pulborough village hall (United Kingdom)",
  "Pulloxhill (United Kingdom)",
  "Purfleet (United Kingdom)",
  "Purleigh (United Kingdom)",
  "Purley (United Kingdom)",
  "Pwllheli (United Kingdom)",
  "Pyle (United Kingdom)",
  "Quedgeley (United Kingdom)",
  "Queenborough (United Kingdom)",
  "Queensferry (United Kingdom)",
  "Quinton (United Kingdom)",
  "Radcliffe (United Kingdom)",
  "Radcliffe on Trent (United Kingdom)",
  "Radlett (United Kingdom)",
  "Radnage (United Kingdom)",
  "Radstock (United Kingdom)",
  "Rainford (United Kingdom)",
  "Rainham (United Kingdom)",
  "Rainhill (United Kingdom)",
  "Rainworth (United Kingdom)",
  "Ramsbottom (United Kingdom)",
  "Ramsbury (United Kingdom)",
  "Ramsey (United Kingdom)",
  "Ramsey Saint Marys (United Kingdom)",
  "Ramsgate (United Kingdom)",
  "Randalstown (United Kingdom)",
  "Ratcliffe on Soar (United Kingdom)",
  "Rathfriland (United Kingdom)",
  "Ravenstone (United Kingdom)",
  "Rawmarsh (United Kingdom)",
  "Rawreth (United Kingdom)",
  "Rawtenstall (United Kingdom)",
  "Rayleigh (United Kingdom)",
  "Rayne (United Kingdom)",
  "Raynes Park (United Kingdom)",
  "Reading (United Kingdom)",
  "Redbourn (United Kingdom)",
  "Redcar (United Kingdom)",
  "Reddish (United Kingdom)",
  "Redditch (United Kingdom)",
  "Redhill (United Kingdom)",
  "Redland (United Kingdom)",
  "Redruth (United Kingdom)",
  "Reed (United Kingdom)",
  "Reepham (United Kingdom)",
  "Reigate (United Kingdom)",
  "Renfrew (United Kingdom)",
  "Renton (United Kingdom)",
  "Repton (United Kingdom)",
  "Reydon (United Kingdom)",
  "Rhayader (United Kingdom)",
  "Rhondda (United Kingdom)",
  "Rhoose (United Kingdom)",
  "Rhos-on-Sea (United Kingdom)",
  "Rhyl (United Kingdom)",
  "Richmond (United Kingdom)",
  "Rickmansworth (United Kingdom)",
  "Ridingmill (United Kingdom)",
  "Rimington (United Kingdom)",
  "Ringmer (United Kingdom)",
  "Ringwood (United Kingdom)",
  "Ripe (United Kingdom)",
  "Ripley (United Kingdom)",
  "Ripon (United Kingdom)",
  "Ripponden (United Kingdom)",
  "Risca (United Kingdom)",
  "Risley (United Kingdom)",
  "River (United Kingdom)",
  "Rivington (United Kingdom)",
  "Roade (United Kingdom)",
  "Roath (United Kingdom)",
  "Robertsbridge (United Kingdom)",
  "Rochdale (United Kingdom)",
  "Roche (United Kingdom)",
  "Rochester (United Kingdom)",
  "Rochford (United Kingdom)",
  "Rock (United Kingdom)",
  "Rock Ferry (United Kingdom)",
  "Roehampton (United Kingdom)",
  "Roffey (United Kingdom)",
  "Rogerstone (United Kingdom)",
  "Rogiet (United Kingdom)",
  "Romford (United Kingdom)",
  "Romsey (United Kingdom)",
  "Ross on Wye (United Kingdom)",
  "Rosslea (United Kingdom)",
  "Rosyth (United Kingdom)",
  "Rothbury (United Kingdom)",
  "Rotherfield (United Kingdom)",
  "Rotherham (United Kingdom)",
  "Rothesay (United Kingdom)",
  "Rothwell (United Kingdom)",
  "Rottingdean (United Kingdom)",
  "Rowde (United Kingdom)",
  "Rowhedge (United Kingdom)",
  "Rowlands Castle (United Kingdom)",
  "Rowlands Gill (United Kingdom)",
  "Rowley Regis (United Kingdom)",
  "Roxwell (United Kingdom)",
  "Royal Leamington Spa (United Kingdom)",
  "Royal Tunbridge Wells (United Kingdom)",
  "Royal Wootton Bassett (United Kingdom)",
  "Roydon (United Kingdom)",
  "Royston (United Kingdom)",
  "Ruabon (United Kingdom)",
  "Ruddington (United Kingdom)",
  "Rugby (United Kingdom)",
  "Rugeley (United Kingdom)",
  "Ruislip (United Kingdom)",
  "Runcorn (United Kingdom)",
  "Rushden (United Kingdom)",
  "Rustington (United Kingdom)",
  "Rutherglen (United Kingdom)",
  "Ruthin (United Kingdom)",
  "Ryde (United Kingdom)",
  "Rye (United Kingdom)",
  "Ryton (United Kingdom)",
  "Sacriston (United Kingdom)",
  "Saffron Walden (United Kingdom)",
  "Saint Agnes (United Kingdom)",
  "Saint Annes on the Sea (United Kingdom)",
  "Saint Bees (United Kingdom)",
  "Saint Brides Major (United Kingdom)",
  "Saint Clears (United Kingdom)",
  "Saint Columb Major (United Kingdom)",
  "Saint Erme (United Kingdom)",
  "Saint Ives (United Kingdom)",
  "Saint Leonards-on-Sea (United Kingdom)",
  "Saint Neots (United Kingdom)",
  "Sale (United Kingdom)",
  "Salford (United Kingdom)",
  "Salfords (United Kingdom)",
  "Salisbury (United Kingdom)",
  "Saltash (United Kingdom)",
  "Saltburn-by-the-Sea (United Kingdom)",
  "Saltcoats (United Kingdom)",
  "Salthouse (United Kingdom)",
  "Sandbach (United Kingdom)",
  "Sanderstead (United Kingdom)",
  "Sandhurst (United Kingdom)",
  "Sandiacre (United Kingdom)",
  "Sandown (United Kingdom)",
  "Sandwell (United Kingdom)",
  "Sandwich (United Kingdom)",
  "Sandy (United Kingdom)",
  "Sandycroft (United Kingdom)",
  "Sanquhar (United Kingdom)",
  "Sarratt (United Kingdom)",
  "Saundersfoot (United Kingdom)",
  "Sawbridgeworth (United Kingdom)",
  "Sawley (United Kingdom)",
  "Saxmundham (United Kingdom)",
  "Saxtead (United Kingdom)",
  "Scalby (United Kingdom)",
  "Scapa (United Kingdom)",
  "Scarborough (United Kingdom)",
  "Scunthorpe (United Kingdom)",
  "Seacroft (United Kingdom)",
  "Seaford (United Kingdom)",
  "Seaham (United Kingdom)",
  "Seale (United Kingdom)",
  "Seascale (United Kingdom)",
  "Seaton (United Kingdom)",
  "Seaview (United Kingdom)",
  "Sedbergh (United Kingdom)",
  "Sedgeberrow (United Kingdom)",
  "Sedgefield (United Kingdom)",
  "Sedgley (United Kingdom)",
  "Seend (United Kingdom)",
  "Seghill (United Kingdom)",
  "Selborne (United Kingdom)",
  "Selby (United Kingdom)",
  "Selkirk (United Kingdom)",
  "Selsey (United Kingdom)",
  "Selston (United Kingdom)",
  "Send (United Kingdom)",
  "Settle (United Kingdom)",
  "Seven Kings (United Kingdom)",
  "Sevenoaks (United Kingdom)",
  "Shadoxhurst (United Kingdom)",
  "Shaftesbury (United Kingdom)",
  "Shalford (United Kingdom)",
  "Shanklin (United Kingdom)",
  "Shardlow (United Kingdom)",
  "Shaw (United Kingdom)",
  "Shawbury (United Kingdom)",
  "Sheering (United Kingdom)",
  "Sheerness (United Kingdom)",
  "Sheffield (United Kingdom)",
  "Shefford (United Kingdom)",
  "Sheldon (United Kingdom)",
  "Shelford (United Kingdom)",
  "Shenfield (United Kingdom)",
  "Shepperton (United Kingdom)",
  "Shepshed (United Kingdom)",
  "Shepton Mallet (United Kingdom)",
  "Sherborne (United Kingdom)",
  "Sherfield upon Loddon (United Kingdom)",
  "Sheringham (United Kingdom)",
  "Shifnal (United Kingdom)",
  "Shildon (United Kingdom)",
  "Shilton (United Kingdom)",
  "Shinfield (United Kingdom)",
  "Shipley (United Kingdom)",
  "Shipston on Stour (United Kingdom)",
  "Shirebrook (United Kingdom)",
  "Shirehampton (United Kingdom)",
  "Shireoaks (United Kingdom)",
  "Shoeburyness (United Kingdom)",
  "Shoreham-by-Sea (United Kingdom)",
  "Shotley Gate (United Kingdom)",
  "Shotton (United Kingdom)",
  "Shotts (United Kingdom)",
  "Shrewsbury (United Kingdom)",
  "Shrivenham (United Kingdom)",
  "Sidcot (United Kingdom)",
  "Sidcup (United Kingdom)",
  "Sidlesham (United Kingdom)",
  "Sidmouth (United Kingdom)",
  "Sileby (United Kingdom)",
  "Silloth (United Kingdom)",
  "Silsden (United Kingdom)",
  "Silsoe (United Kingdom)",
  "Silverdale (United Kingdom)",
  "Silverstone (United Kingdom)",
  "Sittingbourne (United Kingdom)",
  "Skegness (United Kingdom)",
  "Skelmersdale (United Kingdom)",
  "Skelmorlie (United Kingdom)",
  "Skelton (United Kingdom)",
  "Skilgate (United Kingdom)",
  "Skipton (United Kingdom)",
  "Slapton (United Kingdom)",
  "Slawitt (United Kingdom)",
  "Sleaford (United Kingdom)",
  "Slinfold (United Kingdom)",
  "Slough (United Kingdom)",
  "Smalley (United Kingdom)",
  "Smarden (United Kingdom)",
  "Smethwick (United Kingdom)",
  "Snaresbrook (United Kingdom)",
  "Snettisham (United Kingdom)",
  "Snodland (United Kingdom)",
  "Soham (United Kingdom)",
  "Solihull (United Kingdom)",
  "Somerset (United Kingdom)",
  "Somersham (United Kingdom)",
  "Somerton (United Kingdom)",
  "Sompting (United Kingdom)",
  "Sonning (United Kingdom)",
  "South Benfleet (United Kingdom)",
  "South Brent (United Kingdom)",
  "South Brewham (United Kingdom)",
  "South Carlton (United Kingdom)",
  "South Cave (United Kingdom)",
  "South Cerney (United Kingdom)",
  "South Elmsall (United Kingdom)",
  "South Hayling (United Kingdom)",
  "South Hetton (United Kingdom)",
  "South Killingholme (United Kingdom)",
  "South Milford (United Kingdom)",
  "South Molton (United Kingdom)",
  "South Normanton (United Kingdom)",
  "South Ockendon (United Kingdom)",
  "South Petherton (United Kingdom)",
  "South Shields (United Kingdom)",
  "Southall (United Kingdom)",
  "Southam (United Kingdom)",
  "Southampton (United Kingdom)",
  "Southbourne (United Kingdom)",
  "Southend-on-Sea (United Kingdom)",
  "Southgate (United Kingdom)",
  "Southminster (United Kingdom)",
  "Southport (United Kingdom)",
  "Southsea (United Kingdom)",
  "Southwark (United Kingdom)",
  "Southwater (United Kingdom)",
  "Southwell (United Kingdom)",
  "Southwick (United Kingdom)",
  "Southwold (United Kingdom)",
  "Sowerby Bridge (United Kingdom)",
  "Spalding (United Kingdom)",
  "Sparsholt (United Kingdom)",
  "Speke (United Kingdom)",
  "Speldhurst (United Kingdom)",
  "Spennymoor (United Kingdom)",
  "Spetisbury (United Kingdom)",
  "Spilsby (United Kingdom)",
  "Spittal (United Kingdom)",
  "Spondon (United Kingdom)",
  "Spratton (United Kingdom)",
  "Sprowston (United Kingdom)",
  "Square (United Kingdom)",
  "St Albans (United Kingdom)",
  "St Andrews (United Kingdom)",
  "St Asaph (United Kingdom)",
  "St Austell (United Kingdom)",
  "St Helens (United Kingdom)",
  "St Ives (United Kingdom)",
  "St. Dogmaels (United Kingdom)",
  "Stafford (United Kingdom)",
  "Staindrop (United Kingdom)",
  "Staines (United Kingdom)",
  "Stalham (United Kingdom)",
  "Stallingborough (United Kingdom)",
  "Stalybridge (United Kingdom)",
  "Stamford (United Kingdom)",
  "Stanbridge (United Kingdom)",
  "Standlake (United Kingdom)",
  "Stanford (United Kingdom)",
  "Stanground (United Kingdom)",
  "Stanhope (United Kingdom)",
  "Stanley (United Kingdom)",
  "Stanmore (United Kingdom)",
  "Stanstead (United Kingdom)",
  "Stansted (United Kingdom)",
  "Stanton (United Kingdom)",
  "Stanton Fitzwarren (United Kingdom)",
  "Stanwell (United Kingdom)",
  "Staple (United Kingdom)",
  "Staplehurst (United Kingdom)",
  "Star (United Kingdom)",
  "Staverton (United Kingdom)",
  "Stawell (United Kingdom)",
  "Steeple Claydon (United Kingdom)",
  "Steeton (United Kingdom)",
  "Stepps (United Kingdom)",
  "Stevenage (United Kingdom)",
  "Stevenston (United Kingdom)",
  "Steventon (United Kingdom)",
  "Stewarton (United Kingdom)",
  "Steyning (United Kingdom)",
  "Stiffkey (United Kingdom)",
  "Stifford (United Kingdom)",
  "Stillington (United Kingdom)",
  "Stirling (United Kingdom)",
  "Stisted (United Kingdom)",
  "Stock (United Kingdom)",
  "Stockbridge (United Kingdom)",
  "Stockcross (United Kingdom)",
  "Stockport (United Kingdom)",
  "Stocksbridge (United Kingdom)",
  "Stocksfield (United Kingdom)",
  "Stockton (United Kingdom)",
  "Stockton-on-Tees (United Kingdom)",
  "Stoke (United Kingdom)",
  "Stoke by Nayland (United Kingdom)",
  "Stoke Climsland (United Kingdom)",
  "Stoke Newington (United Kingdom)",
  "Stoke Poges (United Kingdom)",
  "Stoke Prior (United Kingdom)",
  "Stoke upon Tern (United Kingdom)",
  "Stoke-on-Trent (United Kingdom)",
  "Stokesley (United Kingdom)",
  "Stone (United Kingdom)",
  "Stone Allerton (United Kingdom)",
  "Stonehaven (United Kingdom)",
  "Stonehouse (United Kingdom)",
  "Stoneleigh (United Kingdom)",
  "Stonesfield (United Kingdom)",
  "Stony Stratford (United Kingdom)",
  "Stornoway (United Kingdom)",
  "Storrington (United Kingdom)",
  "Stotfold (United Kingdom)",
  "Stourbridge (United Kingdom)",
  "Stourport On Severn (United Kingdom)",
  "Stow (United Kingdom)",
  "Stow on the Wold (United Kingdom)",
  "Stowe (United Kingdom)",
  "Stowmarket (United Kingdom)",
  "Strabane (United Kingdom)",
  "Stranmillis (United Kingdom)",
  "Stranraer (United Kingdom)",
  "Stratfield Mortimer (United Kingdom)",
  "Stratford (United Kingdom)",
  "Stratford-upon-Avon (United Kingdom)",
  "Strathaven (United Kingdom)",
  "Strathmiglo (United Kingdom)",
  "Strathyre (United Kingdom)",
  "Streatham (United Kingdom)",
  "Streatley (United Kingdom)",
  "Stretford (United Kingdom)",
  "Stretton on Fosse (United Kingdom)",
  "Stromness (United Kingdom)",
  "Strood (United Kingdom)",
  "Stroud (United Kingdom)",
  "Stuartfield (United Kingdom)",
  "Studham (United Kingdom)",
  "Studley (United Kingdom)",
  "Sturmer (United Kingdom)",
  "Sturminster Newton (United Kingdom)",
  "Stutton (United Kingdom)",
  "Sudbury (United Kingdom)",
  "Sully (United Kingdom)",
  "Sunbury-on-Thames (United Kingdom)",
  "Sunderland (United Kingdom)",
  "Sundon Park (United Kingdom)",
  "Sunningdale (United Kingdom)",
  "Sunninghill (United Kingdom)",
  "Surbiton (United Kingdom)",
  "Surrey (United Kingdom)",
  "Sutton (United Kingdom)",
  "Sutton Bridge (United Kingdom)",
  "Sutton Coldfield (United Kingdom)",
  "Sutton Courtenay (United Kingdom)",
  "Sutton in Ashfield (United Kingdom)",
  "Sutton on Hull (United Kingdom)",
  "Sutton on the Hill (United Kingdom)",
  "Sutton on Trent (United Kingdom)",
  "Swadlincote (United Kingdom)",
  "Swaffham (United Kingdom)",
  "Swalwell (United Kingdom)",
  "Swanage (United Kingdom)",
  "Swanland (United Kingdom)",
  "Swanscombe (United Kingdom)",
  "Swansea (United Kingdom)",
  "Swavesey (United Kingdom)",
  "Swaythling (United Kingdom)",
  "Swindon (United Kingdom)",
  "Swindon Village (United Kingdom)",
  "Swinton (United Kingdom)",
  "Swynnerton (United Kingdom)",
  "Symington (United Kingdom)",
  "Syston (United Kingdom)",
  "Tackley (United Kingdom)",
  "Tadcaster (United Kingdom)",
  "Tadley (United Kingdom)",
  "Tadmarton (United Kingdom)",
  "Tadworth (United Kingdom)",
  "Tain (United Kingdom)",
  "Takeley (United Kingdom)",
  "Talgarth (United Kingdom)",
  "Talsarnau (United Kingdom)",
  "Talybont (United Kingdom)",
  "Tamworth (United Kingdom)",
  "Tandragee (United Kingdom)",
  "Tansley (United Kingdom)",
  "Taplow (United Kingdom)",
  "Tarbert (United Kingdom)",
  "Tarleton (United Kingdom)",
  "Tarporley (United Kingdom)",
  "Tatsfield (United Kingdom)",
  "Tattershall (United Kingdom)",
  "Taunton (United Kingdom)",
  "Tavistock (United Kingdom)",
  "Taynuilt (United Kingdom)",
  "Tayport (United Kingdom)",
  "Teddington (United Kingdom)",
  "Teignmouth (United Kingdom)",
  "Telford (United Kingdom)",
  "Temple (United Kingdom)",
  "Templecombe (United Kingdom)",
  "Templepatrick (United Kingdom)",
  "Tempo (United Kingdom)",
  "Tenbury Wells (United Kingdom)",
  "Tenby (United Kingdom)",
  "Tendring (United Kingdom)",
  "Tenterden (United Kingdom)",
  "Tetbury (United Kingdom)",
  "Tetsworth (United Kingdom)",
  "Tewin (United Kingdom)",
  "Tewkesbury (United Kingdom)",
  "Teynham (United Kingdom)",
  "Thakeham (United Kingdom)",
  "Thame (United Kingdom)",
  "Thames Ditton (United Kingdom)",
  "Thatcham (United Kingdom)",
  "The Hyde (United Kingdom)",
  "Theale (United Kingdom)",
  "Thetford (United Kingdom)",
  "Theydon Bois (United Kingdom)",
  "Thirsk (United Kingdom)",
  "Thornaby (United Kingdom)",
  "Thornbury (United Kingdom)",
  "Thornton (United Kingdom)",
  "Thornton Heath (United Kingdom)",
  "Thornton-in-Craven (United Kingdom)",
  "Thorpe St Peter (United Kingdom)",
  "Thorrington (United Kingdom)",
  "Thrapston (United Kingdom)",
  "Three Legged Cross (United Kingdom)",
  "Threlkeld (United Kingdom)",
  "Thurleigh (United Kingdom)",
  "Thurso (United Kingdom)",
  "Thurston (United Kingdom)",
  "Ticehurst (United Kingdom)",
  "Ticknall (United Kingdom)",
  "Tideswell (United Kingdom)",
  "Tilbury (United Kingdom)",
  "Tile Hill (United Kingdom)",
  "Tilehurst (United Kingdom)",
  "Tillicoultry (United Kingdom)",
  "Tillingham (United Kingdom)",
  "Timperley (United Kingdom)",
  "Tipton (United Kingdom)",
  "Tiptree (United Kingdom)",
  "Tisbury (United Kingdom)",
  "Tiverton (United Kingdom)",
  "Toddington (United Kingdom)",
  "Todmorden (United Kingdom)",
  "Tollard Royal (United Kingdom)",
  "Tollerton (United Kingdom)",
  "Tonbridge (United Kingdom)",
  "Tongham (United Kingdom)",
  "Tonypandy (United Kingdom)",
  "Tonyrefail (United Kingdom)",
  "Torpoint (United Kingdom)",
  "Torquay (United Kingdom)",
  "Totnes (United Kingdom)",
  "Tottenham (United Kingdom)",
  "Totteridge (United Kingdom)",
  "Totternhoe (United Kingdom)",
  "Totton (United Kingdom)",
  "Towcester (United Kingdom)",
  "Tranent (United Kingdom)",
  "Tredegar (United Kingdom)",
  "Trefonen (United Kingdom)",
  "Treforest (United Kingdom)",
  "Tregarth (United Kingdom)",
  "Trehafod (United Kingdom)",
  "Treharris (United Kingdom)",
  "Treherbert (United Kingdom)",
  "Trent (United Kingdom)",
  "Treorchy (United Kingdom)",
  "Treuddyn (United Kingdom)",
  "Trimdon (United Kingdom)",
  "Tring (United Kingdom)",
  "Troon (United Kingdom)",
  "Trowbridge (United Kingdom)",
  "Truro (United Kingdom)",
  "Trusthorpe (United Kingdom)",
  "Tunstall (United Kingdom)",
  "Turnberry (United Kingdom)",
  "Turriff (United Kingdom)",
  "Turvey (United Kingdom)",
  "Tweedmouth (United Kingdom)",
  "Twickenham (United Kingdom)",
  "Twyford (United Kingdom)",
  "Tyldesley (United Kingdom)",
  "Tynemouth (United Kingdom)",
  "Tywardreath (United Kingdom)",
  "Uckfield (United Kingdom)",
  "Uddingston (United Kingdom)",
  "Ufford (United Kingdom)",
  "Ulceby (United Kingdom)",
  "Ulcombe (United Kingdom)",
  "Ullesthorpe (United Kingdom)",
  "Ulverston (United Kingdom)",
  "Unstone (United Kingdom)",
  "Uphall (United Kingdom)",
  "Upminster (United Kingdom)",
  "Upper Beeding (United Kingdom)",
  "Upper Slaughter (United Kingdom)",
  "Uppingham (United Kingdom)",
  "Upton (United Kingdom)",
  "Upton upon Severn (United Kingdom)",
  "Upwell (United Kingdom)",
  "Urchfont (United Kingdom)",
  "Urmston (United Kingdom)",
  "Ushaw Moor (United Kingdom)",
  "Usk (United Kingdom)",
  "Uttoxeter (United Kingdom)",
  "Uxbridge (United Kingdom)",
  "Valley (United Kingdom)",
  "Ventnor (United Kingdom)",
  "Verwood (United Kingdom)",
  "Victoria (United Kingdom)",
  "Voe (United Kingdom)",
  "Waddesdon (United Kingdom)",
  "Wadebridge (United Kingdom)",
  "Wadhurst (United Kingdom)",
  "Wakefield (United Kingdom)",
  "Waldringfield (United Kingdom)",
  "Wales (United Kingdom)",
  "Walford (United Kingdom)",
  "Walgrave (United Kingdom)",
  "Walkden (United Kingdom)",
  "Walker (United Kingdom)",
  "Wallasey (United Kingdom)",
  "Wallingford (United Kingdom)",
  "Wallington (United Kingdom)",
  "Wallsend (United Kingdom)",
  "Walmer (United Kingdom)",
  "Walsall (United Kingdom)",
  "Waltham (United Kingdom)",
  "Waltham Abbey (United Kingdom)",
  "Waltham Cross (United Kingdom)",
  "Walthamstow (United Kingdom)",
  "Walton on Thames (United Kingdom)",
  "Walton on the Hill (United Kingdom)",
  "Walton-on-the-Naze (United Kingdom)",
  "Wanstead (United Kingdom)",
  "Wantage (United Kingdom)",
  "Warden (United Kingdom)",
  "Ware (United Kingdom)",
  "Wareham (United Kingdom)",
  "Warfield (United Kingdom)",
  "Wargrave (United Kingdom)",
  "Waringstown (United Kingdom)",
  "Warlingham (United Kingdom)",
  "Warmington (United Kingdom)",
  "Warminster (United Kingdom)",
  "Warrenpoint (United Kingdom)",
  "Warrington (United Kingdom)",
  "Warton (United Kingdom)",
  "Warwick (United Kingdom)",
  "Washington (United Kingdom)",
  "Watchet (United Kingdom)",
  "Watchfield (United Kingdom)",
  "Water Orton (United Kingdom)",
  "Waterbeach (United Kingdom)",
  "Waterlooville (United Kingdom)",
  "Watford (United Kingdom)",
  "Wath upon Dearne (United Kingdom)",
  "Watlington (United Kingdom)",
  "Wattisfield (United Kingdom)",
  "Watton (United Kingdom)",
  "Weald (United Kingdom)",
  "Wealdstone (United Kingdom)",
  "Weare (United Kingdom)",
  "Weaverham (United Kingdom)",
  "Wedmore (United Kingdom)",
  "Wednesbury (United Kingdom)",
  "Wednesfield (United Kingdom)",
  "Weedon (United Kingdom)",
  "Weedon Bec (United Kingdom)",
  "Well (United Kingdom)",
  "Welling (United Kingdom)",
  "Wellingborough (United Kingdom)",
  "Wellington (United Kingdom)",
  "Wells (United Kingdom)",
  "Welshpool (United Kingdom)",
  "Welwyn (United Kingdom)",
  "Welwyn Garden City (United Kingdom)",
  "Wem (United Kingdom)",
  "Wembley (United Kingdom)",
  "Wemyss Bay (United Kingdom)",
  "Wendover (United Kingdom)",
  "Wentworth (United Kingdom)",
  "Weobley (United Kingdom)",
  "West Ashby (United Kingdom)",
  "West Bromwich (United Kingdom)",
  "West Byfleet (United Kingdom)",
  "West Calder (United Kingdom)",
  "West Clandon (United Kingdom)",
  "West Cowes (United Kingdom)",
  "West Drayton (United Kingdom)",
  "West Grinstead (United Kingdom)",
  "West Horsley (United Kingdom)",
  "West Kilbride (United Kingdom)",
  "West Kirby (United Kingdom)",
  "West Langdon (United Kingdom)",
  "West Linton (United Kingdom)",
  "West Malling (United Kingdom)",
  "West Mersea (United Kingdom)",
  "West Raynham (United Kingdom)",
  "West Row (United Kingdom)",
  "West Rudham (United Kingdom)",
  "West Wickham (United Kingdom)",
  "West Wittering (United Kingdom)",
  "Westbury (United Kingdom)",
  "Westbury-sub-Mendip (United Kingdom)",
  "Westcott (United Kingdom)",
  "Westerham (United Kingdom)",
  "Westfield (United Kingdom)",
  "Westgate (United Kingdom)",
  "Westgate on Sea (United Kingdom)",
  "Westhay (United Kingdom)",
  "Westhoughton (United Kingdom)",
  "Westleigh (United Kingdom)",
  "Westleton (United Kingdom)",
  "Weston (United Kingdom)",
  "Weston in Gordano (United Kingdom)",
  "Weston under Lizard (United Kingdom)",
  "Weston-super-Mare (United Kingdom)",
  "Wetherby (United Kingdom)",
  "Wethersfield (United Kingdom)",
  "Weybridge (United Kingdom)",
  "Weymouth (United Kingdom)",
  "Whaley Bridge (United Kingdom)",
  "Whalley (United Kingdom)",
  "Wheathampstead (United Kingdom)",
  "Wheatley (United Kingdom)",
  "Wheaton Aston (United Kingdom)",
  "Whetstone (United Kingdom)",
  "Whickham (United Kingdom)",
  "Whimple (United Kingdom)",
  "Whisby (United Kingdom)",
  "Whitbourne (United Kingdom)",
  "Whitburn (United Kingdom)",
  "Whitby (United Kingdom)",
  "Whitchurch (United Kingdom)",
  "Whitefield (United Kingdom)",
  "Whitehaven (United Kingdom)",
  "Whitehouse (United Kingdom)",
  "Whiteparish (United Kingdom)",
  "Whitland (United Kingdom)",
  "Whitley Bay (United Kingdom)",
  "Whitnash (United Kingdom)",
  "Whitstable (United Kingdom)",
  "Whittlebury (United Kingdom)",
  "Whittlesey (United Kingdom)",
  "Whittlesford (United Kingdom)",
  "Whitton (United Kingdom)",
  "Whitwell (United Kingdom)",
  "Whitwick (United Kingdom)",
  "Whyteleafe (United Kingdom)",
  "Wick (United Kingdom)",
  "Wicken (United Kingdom)",
  "Wickersley (United Kingdom)",
  "Wickford (United Kingdom)",
  "Wickham (United Kingdom)",
  "Wickham Bishops (United Kingdom)",
  "Wickham Market (United Kingdom)",
  "Widdrington (United Kingdom)",
  "Widmerpool (United Kingdom)",
  "Widnes (United Kingdom)",
  "Wigan (United Kingdom)",
  "Wigston (United Kingdom)",
  "Wigton (United Kingdom)",
  "Wilburton (United Kingdom)",
  "Wilden (United Kingdom)",
  "Willaston (United Kingdom)",
  "Willenhall (United Kingdom)",
  "Willesden (United Kingdom)",
  "Willingale Doe (United Kingdom)",
  "Willingham (United Kingdom)",
  "Willington (United Kingdom)",
  "Willington Quay (United Kingdom)",
  "Wilmington (United Kingdom)",
  "Wilmslow (United Kingdom)",
  "Wilnecote (United Kingdom)",
  "Wilshamstead (United Kingdom)",
  "Wimbledon (United Kingdom)",
  "Wimborne Minster (United Kingdom)",
  "Wincanton (United Kingdom)",
  "Winchester (United Kingdom)",
  "Windermere (United Kingdom)",
  "Windlesham (United Kingdom)",
  "Windsor (United Kingdom)",
  "Wing (United Kingdom)",
  "Wingate (United Kingdom)",
  "Winkleigh (United Kingdom)",
  "Winlaton (United Kingdom)",
  "Winnersh (United Kingdom)",
  "Winnington (United Kingdom)",
  "Winsford (United Kingdom)",
  "Winslow (United Kingdom)",
  "Winterborne Kingston (United Kingdom)",
  "Winterbourne Dauntsey (United Kingdom)",
  "Winton (United Kingdom)",
  "Wirksworth (United Kingdom)",
  "Wisbech (United Kingdom)",
  "Wishaw (United Kingdom)",
  "Witham (United Kingdom)",
  "Withernsea (United Kingdom)",
  "Withernwick (United Kingdom)",
  "Withington (United Kingdom)",
  "Witley (United Kingdom)",
  "Witney (United Kingdom)",
  "Wix (United Kingdom)",
  "Woburn Sands (United Kingdom)",
  "Woking (United Kingdom)",
  "Wokingham (United Kingdom)",
  "Wold Newton (United Kingdom)",
  "Woldingham (United Kingdom)",
  "Wolstanton (United Kingdom)",
  "Wolverhampton (United Kingdom)",
  "Wolverley (United Kingdom)",
  "Wombourne (United Kingdom)",
  "Wombwell (United Kingdom)",
  "Wooburn (United Kingdom)",
  "Woodbridge (United Kingdom)",
  "Woodford (United Kingdom)",
  "Woodford Green (United Kingdom)",
  "Woodhall Spa (United Kingdom)",
  "Woodham Ferrers (United Kingdom)",
  "Woodhouse (United Kingdom)",
  "Woodley (United Kingdom)",
  "Woodmancote (United Kingdom)",
  "Woodsetts (United Kingdom)",
  "Woodstock (United Kingdom)",
  "Woolacombe (United Kingdom)",
  "Woolwich (United Kingdom)",
  "Woore (United Kingdom)",
  "Wootton (United Kingdom)",
  "Worcester (United Kingdom)",
  "Worcester Park (United Kingdom)",
  "Workington (United Kingdom)",
  "Worksop (United Kingdom)",
  "Worle (United Kingdom)",
  "Worminghall (United Kingdom)",
  "Worsley (United Kingdom)",
  "Worth (United Kingdom)",
  "Worthing (United Kingdom)",
  "Wotton Underwood (United Kingdom)",
  "Wotton-under-Edge (United Kingdom)",
  "Wragby (United Kingdom)",
  "Wrangaton (United Kingdom)",
  "Wrangle (United Kingdom)",
  "Wrawby (United Kingdom)",
  "Wraysbury (United Kingdom)",
  "Wrea Green (United Kingdom)",
  "Wrexham (United Kingdom)",
  "Wrington (United Kingdom)",
  "Writtle (United Kingdom)",
  "Wroughton (United Kingdom)",
  "Wroxall (United Kingdom)",
  "Wyke (United Kingdom)",
  "Wymeswold (United Kingdom)",
  "Wymondham (United Kingdom)",
  "Wythenshawe (United Kingdom)",
  "Wyton (United Kingdom)",
  "Wyverstone (United Kingdom)",
  "Yarm (United Kingdom)",
  "Yate (United Kingdom)",
  "Yatton (United Kingdom)",
  "Yeadon (United Kingdom)",
  "Yelverton (United Kingdom)",
  "Yeovil (United Kingdom)",
  "York (United Kingdom)",
  "Yoxford (United Kingdom)",
  "Ystalyfera (United Kingdom)",
  "Ystrad Mynach (United Kingdom)",
  "Ystradgynlais (United Kingdom)",
  "Zeals (United Kingdom)",
  "United States",
  "Abbeville (United States)",
  "Abbotsford (United States)",
  "Abbott (United States)",
  "Abbottstown (United States)",
  "Abercrombie (United States)",
  "Aberdeen (United States)",
  "Aberdeen Proving Ground (United States)",
  "Abernathy (United States)",
  "Abilene (United States)",
  "Abingdon (United States)",
  "Abington (United States)",
  "Abington Township (United States)",
  "Abiquiu (United States)",
  "Abita Springs (United States)",
  "Abrams (United States)",
  "Absarokee (United States)",
  "Absecon (United States)",
  "Acampo (United States)",
  "Access (United States)",
  "Accident (United States)",
  "Accokeek (United States)",
  "Accomac (United States)",
  "Accord (United States)",
  "Accoville (United States)",
  "Achille (United States)",
  "Achilles (United States)",
  "Ackerly (United States)",
  "Ackerman (United States)",
  "Ackley (United States)",
  "Acme (United States)",
  "Acton (United States)",
  "Acushnet (United States)",
  "Acworth (United States)",
  "Ada (United States)",
  "Adah (United States)",
  "Adair (United States)",
  "Adairsville (United States)",
  "Adairville (United States)",
  "Adak (United States)",
  "Adams (United States)",
  "Adams Center (United States)",
  "Adams Run (United States)",
  "Adamstown (United States)",
  "Adamsville (United States)",
  "Addieville (United States)",
  "Addis (United States)",
  "Addison (United States)",
  "Addyston (United States)",
  "Adel (United States)",
  "Adelanto (United States)",
  "Adell (United States)",
  "Adena (United States)",
  "Adger (United States)",
  "Adin (United States)",
  "Adkins (United States)",
  "Admire (United States)",
  "Adolphus (United States)",
  "Adona (United States)",
  "Adrian (United States)",
  "Advance (United States)",
  "Afton (United States)",
  "Agar (United States)",
  "Agate (United States)",
  "Agawam (United States)",
  "Agency (United States)",
  "Agoura Hills (United States)",
  "Agra (United States)",
  "Aguanga (United States)",
  "Aguila (United States)",
  "Ahoskie (United States)",
  "Ahwahnee (United States)",
  "Aiken (United States)",
  "Ailey (United States)",
  "Ainsworth (United States)",
  "Air Force Academy (United States)",
  "Airville (United States)",
  "Airway Heights (United States)",
  "Aitkin (United States)",
  "Ajo (United States)",
  "Akaska (United States)",
  "Akeley (United States)",
  "Akhiok (United States)",
  "Akiachak (United States)",
  "Akiak (United States)",
  "Akron (United States)",
  "Akutan (United States)",
  "Alabaster (United States)",
  "Alachua (United States)",
  "Alakanuk (United States)",
  "Alamance (United States)",
  "Alameda (United States)",
  "Alamo (United States)",
  "Alamogordo (United States)",
  "Alamosa (United States)",
  "Alanson (United States)",
  "Alapaha (United States)",
  "Alba (United States)",
  "Albany (United States)",
  "Albemarle (United States)",
  "Albers (United States)",
  "Albert City (United States)",
  "Albert Lea (United States)",
  "Alberton (United States)",
  "Albertson (United States)",
  "Albertville (United States)",
  "Albia (United States)",
  "Albin (United States)",
  "Albion (United States)",
  "Alborn (United States)",
  "Albright (United States)",
  "Albrightsville (United States)",
  "Albuquerque (United States)",
  "Alburg (United States)",
  "Alburnett (United States)",
  "Alburtis (United States)",
  "Alcalde (United States)",
  "Alcester (United States)",
  "Alcoa (United States)",
  "Alcolu (United States)",
  "Alda (United States)",
  "Alden (United States)",
  "Alderpoint (United States)",
  "Alderson (United States)",
  "Aldie (United States)",
  "Aldrich (United States)",
  "Aledo (United States)",
  "Alex (United States)",
  "Alexander (United States)",
  "Alexander City (United States)",
  "Alexandria (United States)",
  "Alexandria Bay (United States)",
  "Alexis (United States)",
  "Alford (United States)",
  "Alfred (United States)",
  "Alfred Station (United States)",
  "Alger (United States)",
  "Algodones (United States)",
  "Algoma (United States)",
  "Algona (United States)",
  "Algonac (United States)",
  "Algonquin (United States)",
  "Alhambra (United States)",
  "Alice (United States)",
  "Aliceville (United States)",
  "Aline (United States)",
  "Aliquippa (United States)",
  "Aliso Viejo (United States)",
  "Alkol (United States)",
  "Allamuchy (United States)",
  "Allardt (United States)",
  "Allegan (United States)",
  "Allegany (United States)",
  "Allen (United States)",
  "Allen Park (United States)",
  "Allendale (United States)",
  "Allenhurst (United States)",
  "Allenport (United States)",
  "Allenspark (United States)",
  "Allenton (United States)",
  "Allentown (United States)",
  "Allenwood (United States)",
  "Allerton (United States)",
  "Alliance (United States)",
  "Allison (United States)",
  "Allison Park (United States)",
  "Allons (United States)",
  "Alloway (United States)",
  "Allston (United States)",
  "Allyn (United States)",
  "Alma (United States)",
  "Alma Center (United States)",
  "Almena (United States)",
  "Almira (United States)",
  "Almo (United States)",
  "Almond (United States)",
  "Almont (United States)",
  "Alna (United States)",
  "Alpaugh (United States)",
  "Alpena (United States)",
  "Alpha (United States)",
  "Alpharetta (United States)",
  "Alpine (United States)",
  "Alplaus (United States)",
  "Alsea (United States)",
  "Alsey (United States)",
  "Alsip (United States)",
  "Alstead (United States)",
  "Alta (United States)",
  "Alta Vista (United States)",
  "Altadena (United States)",
  "Altamont (United States)",
  "Altamonte Springs (United States)",
  "Altaville (United States)",
  "Altavista (United States)",
  "Altenburg (United States)",
  "Altha (United States)",
  "Altmar (United States)",
  "Alto (United States)",
  "Alton (United States)",
  "Alton Bay (United States)",
  "Altona (United States)",
  "Altoona (United States)",
  "Altura (United States)",
  "Alturas (United States)",
  "Altus (United States)",
  "Alum Bank (United States)",
  "Alum Creek (United States)",
  "Alva (United States)",
  "Alvada (United States)",
  "Alvarado (United States)",
  "Alvaton (United States)",
  "Alverda (United States)",
  "Alverton (United States)",
  "Alvin (United States)",
  "Alviso (United States)",
  "Alvo (United States)",
  "Alvord (United States)",
  "Alvordton (United States)",
  "Ama (United States)",
  "Amagansett (United States)",
  "Amana (United States)",
  "Amanda (United States)",
  "Amargosa Valley (United States)",
  "Amarillo (United States)",
  "Amawalk (United States)",
  "Amazonia (United States)",
  "Amberg (United States)",
  "Ambler (United States)",
  "Amboy (United States)",
  "Ambridge (United States)",
  "Ambrose (United States)",
  "Amelia (United States)",
  "Amelia Court House (United States)",
  "Amenia (United States)",
  "America (United States)",
  "American Canyon (United States)",
  "American Falls (United States)",
  "American Fork (United States)",
  "Americus (United States)",
  "Amery (United States)",
  "Ames (United States)",
  "Amesbury (United States)",
  "Amesville (United States)",
  "Amherst (United States)",
  "Amherst Junction (United States)",
  "Amherstdale (United States)",
  "Amidon (United States)",
  "Amissville (United States)",
  "Amite (United States)",
  "Amity (United States)",
  "Amityville (United States)",
  "Ammon (United States)",
  "Amo (United States)",
  "Amoret (United States)",
  "Amory (United States)",
  "Amsterdam (United States)",
  "Amston (United States)",
  "Anacoco (United States)",
  "Anaconda (United States)",
  "Anacortes (United States)",
  "Anadarko (United States)",
  "Anaheim (United States)",
  "Anahola (United States)",
  "Anahuac (United States)",
  "Anamosa (United States)",
  "Anchor Point (United States)",
  "Anchorage (United States)",
  "Ancona (United States)",
  "Andale (United States)",
  "Andalusia (United States)",
  "Anderson (United States)",
  "Andersonville (United States)",
  "Andes (United States)",
  "Andover (United States)",
  "Andreas (United States)",
  "Andrew (United States)",
  "Andrews (United States)",
  "Andrews Air Force Base (United States)",
  "Angel Fire (United States)",
  "Angelica (United States)",
  "Angie (United States)",
  "Angier (United States)",
  "Angle Inlet (United States)",
  "Angleton (United States)",
  "Angola (United States)",
  "Angoon (United States)",
  "Angora (United States)",
  "Anguilla (United States)",
  "Angwin (United States)",
  "Animas (United States)",
  "Anita (United States)",
  "Aniwa (United States)",
  "Ankeny (United States)",
  "Anmoore (United States)",
  "Ann Arbor (United States)",
  "Anna (United States)",
  "Annabella (United States)",
  "Annandale (United States)",
  "Annandale-on-Hudson (United States)",
  "Annapolis (United States)",
  "Annawan (United States)",
  "Anniston (United States)",
  "Annona (United States)",
  "Annville (United States)",
  "Anoka (United States)",
  "Anselmo (United States)",
  "Ansley (United States)",
  "Anson (United States)",
  "Ansonia (United States)",
  "Ansted (United States)",
  "Antelope (United States)",
  "Anthem (United States)",
  "Anthon (United States)",
  "Anthony (United States)",
  "Antigo (United States)",
  "Antioch (United States)",
  "Antlers (United States)",
  "Antrim (United States)",
  "Antwerp (United States)",
  "Anza (United States)",
  "Apache (United States)",
  "Apache Junction (United States)",
  "Apalachicola (United States)",
  "Apalachin (United States)",
  "Apex (United States)",
  "Apison (United States)",
  "Aplington (United States)",
  "Apollo (United States)",
  "Apollo Beach (United States)",
  "Apopka (United States)",
  "Appalachia (United States)",
  "Apple Creek (United States)",
  "Apple River (United States)",
  "Apple Valley (United States)",
  "Applegate (United States)",
  "Appleton (United States)",
  "Appleton City (United States)",
  "Appling (United States)",
  "Appomattox (United States)",
  "Aptos (United States)",
  "Aquasco (United States)",
  "Aquashicola (United States)",
  "Aquebogue (United States)",
  "Aquilla (United States)",
  "Arab (United States)",
  "Arabi (United States)",
  "Aragon (United States)",
  "Aransas Pass (United States)",
  "Arapaho (United States)",
  "Arapahoe (United States)",
  "Ararat (United States)",
  "Arbela (United States)",
  "Arbon (United States)",
  "Arbovale (United States)",
  "Arbuckle (United States)",
  "Arbyrd (United States)",
  "Arcade (United States)",
  "Arcadia (United States)",
  "Arcanum (United States)",
  "Arcata (United States)",
  "Archbald (United States)",
  "Archbold (United States)",
  "Archer (United States)",
  "Archer City (United States)",
  "Archie (United States)",
  "Arco (United States)",
  "Arcola (United States)",
  "Arden (United States)",
  "Ardmore (United States)",
  "Ardsley (United States)",
  "Aredale (United States)",
  "Arena (United States)",
  "Arenas Valley (United States)",
  "Arendtsville (United States)",
  "Arenzville (United States)",
  "Argenta (United States)",
  "Argillite (United States)",
  "Argonia (United States)",
  "Argonne (United States)",
  "Argos (United States)",
  "Argusville (United States)",
  "Argyle (United States)",
  "Ariel (United States)",
  "Arimo (United States)",
  "Arispe (United States)",
  "Ariton (United States)",
  "Arivaca (United States)",
  "Arizona City (United States)",
  "Arkadelphia (United States)",
  "Arkansas City (United States)",
  "Arkdale (United States)",
  "Arkoma (United States)",
  "Arkville (United States)",
  "Arlee (United States)",
  "Arley (United States)",
  "Arlington (United States)",
  "Arlington Heights (United States)",
  "Arma (United States)",
  "Armada (United States)",
  "Armagh (United States)",
  "Armington (United States)",
  "Armona (United States)",
  "Armonk (United States)",
  "Armour (United States)",
  "Armstrong (United States)",
  "Armuchee (United States)",
  "Arnaudville (United States)",
  "Arnegard (United States)",
  "Arnett (United States)",
  "Arnold (United States)",
  "Arnoldsburg (United States)",
  "Arnoldsville (United States)",
  "Aroma Park (United States)",
  "Aromas (United States)",
  "Aroostook Band of Micmac Trust Land (United States)",
  "Arp (United States)",
  "Arpin (United States)",
  "Arriba (United States)",
  "Arrington (United States)",
  "Arrowsmith (United States)",
  "Arroyo Grande (United States)",
  "Arroyo Hondo (United States)",
  "Arroyo Seco (United States)",
  "Artesia (United States)",
  "Artesian (United States)",
  "Arthur (United States)",
  "Arthur City (United States)",
  "Arvada (United States)",
  "Arverne (United States)",
  "Arvin (United States)",
  "Arvonia (United States)",
  "Ary (United States)",
  "Asbury (United States)",
  "Asbury Park (United States)",
  "Ascutney (United States)",
  "Ash (United States)",
  "Ash Flat (United States)",
  "Ash Fork (United States)",
  "Ash Grove (United States)",
  "Ash Hill (United States)",
  "Ashaway (United States)",
  "Ashburn (United States)",
  "Ashburnham (United States)",
  "Ashby (United States)",
  "Ashcamp (United States)",
  "Ashdown (United States)",
  "Asheboro (United States)",
  "Asherton (United States)",
  "Asheville (United States)",
  "Ashfield (United States)",
  "Ashford (United States)",
  "Ashkum (United States)",
  "Ashland (United States)",
  "Ashland City (United States)",
  "Ashley (United States)",
  "Ashmore (United States)",
  "Ashtabula (United States)",
  "Ashton (United States)",
  "Ashville (United States)",
  "Askov (United States)",
  "Asotin (United States)",
  "Aspen (United States)",
  "Aspermont (United States)",
  "Aspers (United States)",
  "Assaria (United States)",
  "Assonet (United States)",
  "Assumption (United States)",
  "Astatula (United States)",
  "Aston Arms (United States)",
  "Astor (United States)",
  "Astoria (United States)",
  "Atalissa (United States)",
  "Atascadero (United States)",
  "Atascosa (United States)",
  "Atchison (United States)",
  "Atco (United States)",
  "Atglen (United States)",
  "Athelstane (United States)",
  "Athena (United States)",
  "Athens (United States)",
  "Atherton (United States)",
  "Athol (United States)",
  "Atkins (United States)",
  "Atkinson (United States)",
  "Atlanta (United States)",
  "Atlantic (United States)",
  "Atlantic Beach (United States)",
  "Atlantic City (United States)",
  "Atlantic Highlands (United States)",
  "Atlantic Mine (United States)",
  "Atlasburg (United States)",
  "Atmore (United States)",
  "Atoka (United States)",
  "Atqasuk (United States)",
  "Attalla (United States)",
  "Attapulgus (United States)",
  "Attica (United States)",
  "Attleboro (United States)",
  "Attleboro Falls (United States)",
  "Atwater (United States)",
  "Atwood (United States)",
  "Au Gres (United States)",
  "Au Sable Forks (United States)",
  "Au Train (United States)",
  "Auberry (United States)",
  "Aubrey (United States)",
  "Auburn (United States)",
  "Auburn Hills (United States)",
  "Auburndale (United States)",
  "Audubon (United States)",
  "Augusta (United States)",
  "Aulander (United States)",
  "Ault (United States)",
  "Aumsville (United States)",
  "Aurelia (United States)",
  "Aurora (United States)",
  "Austell (United States)",
  "Austerlitz (United States)",
  "Austin (United States)",
  "Austinburg (United States)",
  "Austinville (United States)",
  "Autaugaville (United States)",
  "Autryville (United States)",
  "Auxier (United States)",
  "Auxvasse (United States)",
  "Ava (United States)",
  "Avalon (United States)",
  "Avella (United States)",
  "Avenal (United States)",
  "Avenel (United States)",
  "Aventura (United States)",
  "Averill Park (United States)",
  "Avery (United States)",
  "Avila Beach (United States)",
  "Avilla (United States)",
  "Avinger (United States)",
  "Aviston (United States)",
  "Avoca (United States)",
  "Avon (United States)",
  "Avon Lake (United States)",
  "Avon Park (United States)",
  "Avon-by-the-Sea (United States)",
  "Avondale (United States)",
  "Avondale Estates (United States)",
  "Avonmore (United States)",
  "Awendaw (United States)",
  "Axis (United States)",
  "Axtell (United States)",
  "Axton (United States)",
  "Ayden (United States)",
  "Ayer (United States)",
  "Aylett (United States)",
  "Aynor (United States)",
  "Azalea (United States)",
  "Azle (United States)",
  "Aztec (United States)",
  "Azusa (United States)",
  "Babb (United States)",
  "Babbitt (United States)",
  "Babcock (United States)",
  "Babson Park (United States)",
  "Babylon (United States)",
  "Backus (United States)",
  "Bacliff (United States)",
  "Bad Axe (United States)",
  "Baden (United States)",
  "Badger (United States)",
  "Badin (United States)",
  "Bagdad (United States)",
  "Baggs (United States)",
  "Bagley (United States)",
  "Bagwell (United States)",
  "Bahama (United States)",
  "Bailey (United States)",
  "Bailey Island (United States)",
  "Baileys Harbor (United States)",
  "Baileyton (United States)",
  "Baileyville (United States)",
  "Bainbridge (United States)",
  "Bainbridge Island (United States)",
  "Bainville (United States)",
  "Baird (United States)",
  "Bairdford (United States)",
  "Baisden (United States)",
  "Baker (United States)",
  "Baker City (United States)",
  "Bakersfield (United States)",
  "Bakersville (United States)",
  "Bakewell (United States)",
  "Bala-Cynwyd (United States)",
  "Balaton (United States)",
  "Bald Knob (United States)",
  "Baldwin (United States)",
  "Baldwin City (United States)",
  "Baldwin Park (United States)",
  "Baldwin Place (United States)",
  "Baldwinsville (United States)",
  "Baldwinville (United States)",
  "Baldwyn (United States)",
  "Ball (United States)",
  "Ball Ground (United States)",
  "Ballantine (United States)",
  "Ballico (United States)",
  "Ballinger (United States)",
  "Ballston Lake (United States)",
  "Ballston Spa (United States)",
  "Ballwin (United States)",
  "Bally (United States)",
  "Balsam Lake (United States)",
  "Baltic (United States)",
  "Baltimore (United States)",
  "Bamberg (United States)",
  "Bancroft (United States)",
  "Bandera (United States)",
  "Bandon (United States)",
  "Bangor (United States)",
  "Bangs (United States)",
  "Banks (United States)",
  "Bankston (United States)",
  "Banner (United States)",
  "Banner Elk (United States)",
  "Banning (United States)",
  "Bannister (United States)",
  "Banquete (United States)",
  "Bantam (United States)",
  "Bapchule (United States)",
  "Baptistown (United States)",
  "Bar Harbor (United States)",
  "Baraboo (United States)",
  "Baraga (United States)",
  "Barbeau (United States)",
  "Barberton (United States)",
  "Barboursville (United States)",
  "Barbourville (United States)",
  "Barclay (United States)",
  "Bardstown (United States)",
  "Bardwell (United States)",
  "Bargersville (United States)",
  "Baring (United States)",
  "Bark River (United States)",
  "Barkhamsted (United States)",
  "Barling (United States)",
  "Barlow (United States)",
  "Barnard (United States)",
  "Barnardsville (United States)",
  "Barnegat (United States)",
  "Barnes (United States)",
  "Barnes City (United States)",
  "Barnesville (United States)",
  "Barnet (United States)",
  "Barneveld (United States)",
  "Barney (United States)",
  "Barnhart (United States)",
  "Barnsdall (United States)",
  "Barnstable (United States)",
  "Barnstead (United States)",
  "Barnum (United States)",
  "Barnwell (United States)",
  "Baroda (United States)",
  "Barre (United States)",
  "Barren Springs (United States)",
  "Barrett (United States)",
  "Barrington (United States)",
  "Barron (United States)",
  "Barronett (United States)",
  "Barrow (United States)",
  "Barry (United States)",
  "Barryton (United States)",
  "Barryville (United States)",
  "Barstow (United States)",
  "Bart Township (United States)",
  "Bartelso (United States)",
  "Bartlesville (United States)",
  "Bartlett (United States)",
  "Bartley (United States)",
  "Barto (United States)",
  "Barton (United States)",
  "Bartonsville (United States)",
  "Bartow (United States)",
  "Basalt (United States)",
  "Bascom (United States)",
  "Basehor (United States)",
  "Basile (United States)",
  "Basin (United States)",
  "Basking Ridge (United States)",
  "Bassett (United States)",
  "Bassfield (United States)",
  "Bastrop (United States)",
  "Basye (United States)",
  "Batavia (United States)",
  "Batchelor (United States)",
  "Batchtown (United States)",
  "Bates City (United States)",
  "Batesburg (United States)",
  "Batesville (United States)",
  "Bath (United States)",
  "Bathgate (United States)",
  "Baton Rouge (United States)",
  "Batson (United States)",
  "Battle Creek (United States)",
  "Battle Ground (United States)",
  "Battle Lake (United States)",
  "Battle Mountain (United States)",
  "Battleboro (United States)",
  "Baudette (United States)",
  "Bausman (United States)",
  "Bauxite (United States)",
  "Baxley (United States)",
  "Baxter (United States)",
  "Baxter Springs (United States)",
  "Bay City (United States)",
  "Bay Minette (United States)",
  "Bay Port (United States)",
  "Bay Saint Louis (United States)",
  "Bay Shore (United States)",
  "Bay Springs (United States)",
  "Bay Village (United States)",
  "Bayard (United States)",
  "Bayboro (United States)",
  "Bayfield (United States)",
  "Bayonne (United States)",
  "Bayport (United States)",
  "Bayside (United States)",
  "Baytown (United States)",
  "Bayview (United States)",
  "Bayville (United States)",
  "Bazine (United States)",
  "Beach (United States)",
  "Beach City (United States)",
  "Beach Haven (United States)",
  "Beach Lake (United States)",
  "Beachwood (United States)",
  "Beacon (United States)",
  "Beacon Falls (United States)",
  "Bealeton (United States)",
  "Beaman (United States)",
  "Bean Station (United States)",
  "Beans Purchase (United States)",
  "Bear (United States)",
  "Bear Creek (United States)",
  "Bear Lake (United States)",
  "Bear River City (United States)",
  "Bearden (United States)",
  "Beardstown (United States)",
  "Bearsville (United States)",
  "Beasley (United States)",
  "Beatrice (United States)",
  "Beattie (United States)",
  "Beatty (United States)",
  "Beattyville (United States)",
  "Beaufort (United States)",
  "Beaumont (United States)",
  "Beauty (United States)",
  "Beaver (United States)",
  "Beaver Bay (United States)",
  "Beaver Creek (United States)",
  "Beaver Crossing (United States)",
  "Beaver Dam (United States)",
  "Beaver Falls (United States)",
  "Beaver Meadows (United States)",
  "Beaver Springs (United States)",
  "Beaverdam (United States)",
  "Beaverton (United States)",
  "Beavertown (United States)",
  "Bechtelsville (United States)",
  "Beckemeyer (United States)",
  "Becker (United States)",
  "Becket (United States)",
  "Beckley (United States)",
  "Beckville (United States)",
  "Bedford (United States)",
  "Bedford Hills (United States)",
  "Bedford Park (United States)",
  "Bedias (United States)",
  "Bedminster (United States)",
  "Bee Branch (United States)",
  "Bee Spring (United States)",
  "Beebe (United States)",
  "Beech Bluff (United States)",
  "Beech Bottom (United States)",
  "Beech Creek (United States)",
  "Beech Grove (United States)",
  "Beech Island (United States)",
  "Beecher (United States)",
  "Beecher City (United States)",
  "Beechgrove (United States)",
  "Beechmont (United States)",
  "Beeville (United States)",
  "Beggs (United States)",
  "Bel Air (United States)",
  "Bel Alton (United States)",
  "Belcamp (United States)",
  "Belchertown (United States)",
  "Belcourt (United States)",
  "Belden (United States)",
  "Beldenville (United States)",
  "Belding (United States)",
  "Belen (United States)",
  "Belfair (United States)",
  "Belfast (United States)",
  "Belfield (United States)",
  "Belford (United States)",
  "Belfry (United States)",
  "Belgium (United States)",
  "Belgrade (United States)",
  "Belhaven (United States)",
  "Belington (United States)",
  "Belknap (United States)",
  "Bell (United States)",
  "Bell Buckle (United States)",
  "Bell City (United States)",
  "Bell Island Hot Springs (United States)",
  "Bella Vista (United States)",
  "Bellaire (United States)",
  "Bellbrook (United States)",
  "Belle (United States)",
  "Belle Center (United States)",
  "Belle Chasse (United States)",
  "Belle Fourche (United States)",
  "Belle Glade (United States)",
  "Belle Haven (United States)",
  "Belle Mead (United States)",
  "Belle Plaine (United States)",
  "Belle Rose (United States)",
  "Belle Valley (United States)",
  "Belle Vernon (United States)",
  "Belleair Beach (United States)",
  "Bellefontaine (United States)",
  "Bellefonte (United States)",
  "Bellemont (United States)",
  "Bellerose (United States)",
  "Belleview (United States)",
  "Belleville (United States)",
  "Bellevue (United States)",
  "Bellflower (United States)",
  "Bellingham (United States)",
  "Bellmawr (United States)",
  "Bellmore (United States)",
  "Bellows Falls (United States)",
  "Bellport (United States)",
  "Bells (United States)",
  "Bellville (United States)",
  "Bellvue (United States)",
  "Bellwood (United States)",
  "Belmar (United States)",
  "Belmond (United States)",
  "Belmont (United States)",
  "Beloit (United States)",
  "Belpre (United States)",
  "Belt (United States)",
  "Belton (United States)",
  "Beltrami (United States)",
  "Beltsville (United States)",
  "Belvidere (United States)",
  "Belvidere Center (United States)",
  "Belview (United States)",
  "Belvue (United States)",
  "Belzoni (United States)",
  "Bement (United States)",
  "Bemidji (United States)",
  "Bemus Point (United States)",
  "Ben Lomond (United States)",
  "Ben Wheeler (United States)",
  "Bena (United States)",
  "Benavides (United States)",
  "Bend (United States)",
  "Bendersville (United States)",
  "Benedict (United States)",
  "Benham (United States)",
  "Benicia (United States)",
  "Benkelman (United States)",
  "Benld (United States)",
  "Bennet (United States)",
  "Bennett (United States)",
  "Bennettsville (United States)",
  "Bennington (United States)",
  "Benoit (United States)",
  "Bensalem (United States)",
  "Bensenville (United States)",
  "Benson (United States)",
  "Bent (United States)",
  "Bent Mountain (United States)",
  "Bentleyville (United States)",
  "Benton (United States)",
  "Benton City (United States)",
  "Benton Harbor (United States)",
  "Benton Ridge (United States)",
  "Bentonia (United States)",
  "Bentonville (United States)",
  "Benwood (United States)",
  "Benzonia (United States)",
  "Berea (United States)",
  "Beresford (United States)",
  "Bergen (United States)",
  "Bergenfield (United States)",
  "Berger (United States)",
  "Bergheim (United States)",
  "Berkeley (United States)",
  "Berkeley Heights (United States)",
  "Berkeley Springs (United States)",
  "Berkley (United States)",
  "Berkshire (United States)",
  "Berlin (United States)",
  "Berlin Center (United States)",
  "Berlin Heights (United States)",
  "Bern (United States)",
  "Bernalillo (United States)",
  "Bernard (United States)",
  "Bernardston (United States)",
  "Bernardsville (United States)",
  "Berne (United States)",
  "Bernice (United States)",
  "Bernie (United States)",
  "Bernville (United States)",
  "Berrien Center (United States)",
  "Berrien Springs (United States)",
  "Berry (United States)",
  "Berry Creek (United States)",
  "Berrysburg (United States)",
  "Berryton (United States)",
  "Berryville (United States)",
  "Bertha (United States)",
  "Berthoud (United States)",
  "Bertram (United States)",
  "Bertrand (United States)",
  "Berwick (United States)",
  "Berwind (United States)",
  "Berwyn (United States)",
  "Beryl (United States)",
  "Bessemer (United States)",
  "Bessemer City (United States)",
  "Bethalto (United States)",
  "Bethania (United States)",
  "Bethany (United States)",
  "Bethany Beach (United States)",
  "Bethel (United States)",
  "Bethel Island (United States)",
  "Bethel Park (United States)",
  "Bethel Springs (United States)",
  "Bethesda (United States)",
  "Bethlehem (United States)",
  "Bethpage (United States)",
  "Bethune (United States)",
  "Betsy Layne (United States)",
  "Bettendorf (United States)",
  "Beulah (United States)",
  "Beulaville (United States)",
  "Beverly (United States)",
  "Beverly Hills (United States)",
  "Bevington (United States)",
  "Bickmore (United States)",
  "Bicknell (United States)",
  "Biddeford (United States)",
  "Bidwell (United States)",
  "Bieber (United States)",
  "Big Bay (United States)",
  "Big Bear (United States)",
  "Big Bear Lake (United States)",
  "Big Bend (United States)",
  "Big Cabin (United States)",
  "Big Cove Tannery (United States)",
  "Big Creek (United States)",
  "Big Falls (United States)",
  "Big Flats (United States)",
  "Big Horn (United States)",
  "Big Lake (United States)",
  "Big Pine (United States)",
  "Big Pine Key (United States)",
  "Big Piney (United States)",
  "Big Pool (United States)",
  "Big Rapids (United States)",
  "Big Rock (United States)",
  "Big Sandy (United States)",
  "Big Sky (United States)",
  "Big Spring (United States)",
  "Big Springs (United States)",
  "Big Stone City (United States)",
  "Big Stone Gap (United States)",
  "Big Sur (United States)",
  "Big Timber (United States)",
  "Big Wells (United States)",
  "Bigbend (United States)",
  "Bigelow (United States)",
  "Bigfoot (United States)",
  "Bigfork (United States)",
  "Biggs (United States)",
  "Biggsville (United States)",
  "Biglerville (United States)",
  "Bill (United States)",
  "Billerica (United States)",
  "Billings (United States)",
  "Biloxi (United States)",
  "Bim (United States)",
  "Binford (United States)",
  "Bingen (United States)",
  "Binger (United States)",
  "Bingham Lake (United States)",
  "Binghamton (United States)",
  "Biola (United States)",
  "Bippus (United States)",
  "Birch River (United States)",
  "Birch Run (United States)",
  "Birch Tree (United States)",
  "Birchleaf (United States)",
  "Birchwood (United States)",
  "Bird City (United States)",
  "Bird in Hand (United States)",
  "Bird Island (United States)",
  "Birdsboro (United States)",
  "Birdseye (United States)",
  "Birdsnest (United States)",
  "Birmingham (United States)",
  "Birnamwood (United States)",
  "Bisbee (United States)",
  "Biscoe (United States)",
  "Bishop (United States)",
  "Bishopville (United States)",
  "Bismarck (United States)",
  "Bison (United States)",
  "Bitely (United States)",
  "Bivalve (United States)",
  "Bivins (United States)",
  "Biwabik (United States)",
  "Bixby (United States)",
  "Blachly (United States)",
  "Black Canyon City (United States)",
  "Black Creek (United States)",
  "Black Diamond (United States)",
  "Black Eagle (United States)",
  "Black Earth (United States)",
  "Black Hawk (United States)",
  "Black Mountain (United States)",
  "Black River (United States)",
  "Black River Falls (United States)",
  "Black Rock (United States)",
  "Blackduck (United States)",
  "Blackfoot (United States)",
  "Blackhawk (United States)",
  "Blacklick (United States)",
  "Blacksburg (United States)",
  "Blackshear (United States)",
  "Blackstock (United States)",
  "Blackstone (United States)",
  "Blacksville (United States)",
  "Blackville (United States)",
  "Blackwater (United States)",
  "Blackwell (United States)",
  "Blackwood (United States)",
  "Bladenboro (United States)",
  "Bladensburg (United States)",
  "Blain (United States)",
  "Blaine (United States)",
  "Blair (United States)",
  "Blairs (United States)",
  "Blairs Mills (United States)",
  "Blairsburg (United States)",
  "Blairstown (United States)",
  "Blairsville (United States)",
  "Blakely (United States)",
  "Blakesburg (United States)",
  "Blakeslee (United States)",
  "Blanch (United States)",
  "Blanchard (United States)",
  "Blanchardville (United States)",
  "Blanchester (United States)",
  "Blanco (United States)",
  "Bland (United States)",
  "Blandburg (United States)",
  "Blandford (United States)",
  "Blanding (United States)",
  "Blandinsville (United States)",
  "Blandon (United States)",
  "Blauvelt (United States)",
  "Bledsoe (United States)",
  "Blencoe (United States)",
  "Blenheim (United States)",
  "Blenker (United States)",
  "Blessing (United States)",
  "Bliss (United States)",
  "Blissfield (United States)",
  "Block Island (United States)",
  "Blockton (United States)",
  "Blodgett (United States)",
  "Blodgett Mills (United States)",
  "Bloomburg (United States)",
  "Bloomdale (United States)",
  "Bloomer (United States)",
  "Bloomery (United States)",
  "Bloomfield (United States)",
  "Bloomfield Hills (United States)",
  "Blooming Glen (United States)",
  "Blooming Grove (United States)",
  "Blooming Prairie (United States)",
  "Bloomingburg (United States)",
  "Bloomingdale (United States)",
  "Bloomington (United States)",
  "Bloomington Springs (United States)",
  "Bloomsburg (United States)",
  "Bloomsbury (United States)",
  "Bloomsdale (United States)",
  "Bloomville (United States)",
  "Blossburg (United States)",
  "Blossom (United States)",
  "Blossvale (United States)",
  "Blountstown (United States)",
  "Blountsville (United States)",
  "Blountville (United States)",
  "Blowing Rock (United States)",
  "Blue (United States)",
  "Blue Ball (United States)",
  "Blue Bell (United States)",
  "Blue Creek (United States)",
  "Blue Earth (United States)",
  "Blue Eye (United States)",
  "Blue Grass (United States)",
  "Blue Hill (United States)",
  "Blue Island (United States)",
  "Blue Jay (United States)",
  "Blue Lake (United States)",
  "Blue Mound (United States)",
  "Blue Mounds (United States)",
  "Blue Mountain (United States)",
  "Blue Mountain Lake (United States)",
  "Blue Point (United States)",
  "Blue Rapids (United States)",
  "Blue Ridge (United States)",
  "Blue Ridge Summit (United States)",
  "Blue River (United States)",
  "Blue Springs (United States)",
  "Bluebell (United States)",
  "Bluefield (United States)",
  "Bluejacket (United States)",
  "Bluemont (United States)",
  "Bluewater (United States)",
  "Bluff (United States)",
  "Bluff City (United States)",
  "Bluff Dale (United States)",
  "Bluffs (United States)",
  "Bluffton (United States)",
  "Bluford (United States)",
  "Blum (United States)",
  "Bly (United States)",
  "Blythe (United States)",
  "Blytheville (United States)",
  "Blythewood (United States)",
  "Boalsburg (United States)",
  "Boardman (United States)",
  "Boaz (United States)",
  "Bob White (United States)",
  "Bobtown (United States)",
  "Boca Grande (United States)",
  "Boca Raton (United States)",
  "Bode (United States)",
  "Bodega Bay (United States)",
  "Bodfish (United States)",
  "Boelus (United States)",
  "Boerne (United States)",
  "Bogalusa (United States)",
  "Bogard (United States)",
  "Bogart (United States)",
  "Bogata (United States)",
  "Boggstown (United States)",
  "Bogota (United States)",
  "Bogue Chitto (United States)",
  "Bohannon (United States)",
  "Bohemia (United States)",
  "Boiceville (United States)",
  "Boiling Springs (United States)",
  "Boise (United States)",
  "Boise City (United States)",
  "Boissevain (United States)",
  "Bokchito (United States)",
  "Bokeelia (United States)",
  "Bolckow (United States)",
  "Boligee (United States)",
  "Bolinas (United States)",
  "Boling (United States)",
  "Bolingbroke (United States)",
  "Bolingbrook (United States)",
  "Bolivar (United States)",
  "Bolivia (United States)",
  "Bolt (United States)",
  "Bolton (United States)",
  "Bolton Landing (United States)",
  "Bomont (United States)",
  "Bon Aqua (United States)",
  "Bonaire (United States)",
  "Bonaparte (United States)",
  "Bondsville (United States)",
  "Bonduel (United States)",
  "Bondurant (United States)",
  "Bondville (United States)",
  "Bone Gap (United States)",
  "Bonfield (United States)",
  "Bonham (United States)",
  "Bonifay (United States)",
  "Bonita (United States)",
  "Bonita Springs (United States)",
  "Bonlee (United States)",
  "Bonne Terre (United States)",
  "Bonneau (United States)",
  "Bonner Springs (United States)",
  "Bonnerdale (United States)",
  "Bonners Ferry (United States)",
  "Bonney Lake (United States)",
  "Bonnie (United States)",
  "Bonnieville (United States)",
  "Bonnots Mill (United States)",
  "Bonnyman (United States)",
  "Bono (United States)",
  "Bonsall (United States)",
  "Boody (United States)",
  "Booker (United States)",
  "Boomer (United States)",
  "Boone (United States)",
  "Boones Mill (United States)",
  "Booneville (United States)",
  "Boonsboro (United States)",
  "Boonton (United States)",
  "Boonville (United States)",
  "Boothbay (United States)",
  "Boothbay Harbor (United States)",
  "Borden (United States)",
  "Bordentown (United States)",
  "Borger (United States)",
  "Boring (United States)",
  "Boron (United States)",
  "Borrego Springs (United States)",
  "Boscobel (United States)",
  "Bosque Farms (United States)",
  "Bossier City (United States)",
  "Bostic (United States)",
  "Boston (United States)",
  "Bostwick (United States)",
  "Boswell (United States)",
  "Bosworth (United States)",
  "Bothell (United States)",
  "Botkins (United States)",
  "Bottineau (United States)",
  "Boulder (United States)",
  "Boulder City (United States)",
  "Boulder Creek (United States)",
  "Boulder Town (United States)",
  "Boulevard (United States)",
  "Bound Brook (United States)",
  "Bountiful (United States)",
  "Bourbon (United States)",
  "Bourbonnais (United States)",
  "Bourg (United States)",
  "Boutte (United States)",
  "Bovard (United States)",
  "Bovey (United States)",
  "Bovina (United States)",
  "Bovina Center (United States)",
  "Bow (United States)",
  "Bow Center (United States)",
  "Bowdoin Center (United States)",
  "Bowdoinham (United States)",
  "Bowdon (United States)",
  "Bowerston (United States)",
  "Bowersville (United States)",
  "Bowie (United States)",
  "Bowler (United States)",
  "Bowling Green (United States)",
  "Bowlus (United States)",
  "Bowman (United States)",
  "Bowmanstown (United States)",
  "Bowmansville (United States)",
  "Box Elder (United States)",
  "Box Springs (United States)",
  "Boxford (United States)",
  "Boxholm (United States)",
  "Boyce (United States)",
  "Boyceville (United States)",
  "Boyd (United States)",
  "Boyden (United States)",
  "Boyds (United States)",
  "Boydton (United States)",
  "Boyers (United States)",
  "Boyertown (United States)",
  "Boyle (United States)",
  "Boylston (United States)",
  "Boyne City (United States)",
  "Boyne Falls (United States)",
  "Boynton (United States)",
  "Boynton Beach (United States)",
  "Bozeman (United States)",
  "Braceville (United States)",
  "Bracey (United States)",
  "Brackenridge (United States)",
  "Brackettville (United States)",
  "Brackney (United States)",
  "Braddock (United States)",
  "Bradenton (United States)",
  "Bradenton Beach (United States)",
  "Bradenville (United States)",
  "Bradford (United States)",
  "Bradley (United States)",
  "Bradley Beach (United States)",
  "Bradley Junction (United States)",
  "Bradner (United States)",
  "Bradshaw (United States)",
  "Brady (United States)",
  "Bradyville (United States)",
  "Bragg City (United States)",
  "Braham (United States)",
  "Braidwood (United States)",
  "Brainerd (United States)",
  "Braintree (United States)",
  "Braithwaite (United States)",
  "Braman (United States)",
  "Bramwell (United States)",
  "Branch (United States)",
  "Branch Township (United States)",
  "Branchland (United States)",
  "Branchville (United States)",
  "Brandamore (United States)",
  "Brandenburg (United States)",
  "Brandon (United States)",
  "Brandt (United States)",
  "Brandy Station (United States)",
  "Brandywine (United States)",
  "Branford (United States)",
  "Branson (United States)",
  "Brant (United States)",
  "Brant Lake (United States)",
  "Brantingham (United States)",
  "Braselton (United States)",
  "Brashear (United States)",
  "Brasher Falls (United States)",
  "Brasstown (United States)",
  "Brattleboro (United States)",
  "Brave (United States)",
  "Brawley (United States)",
  "Braxton (United States)",
  "Brayton (United States)",
  "Brazil (United States)",
  "Brazoria (United States)",
  "Brea (United States)",
  "Breaux Bridge (United States)",
  "Breckenridge (United States)",
  "Brecksville (United States)",
  "Breda (United States)",
  "Breese (United States)",
  "Breezewood (United States)",
  "Breezy Point (United States)",
  "Breinigsville (United States)",
  "Bremen (United States)",
  "Bremerton (United States)",
  "Bremo Bluff (United States)",
  "Brenham (United States)",
  "Brent (United States)",
  "Brentwood (United States)",
  "Bretton Woods (United States)",
  "Brevard (United States)",
  "Brewer (United States)",
  "Brewerton (United States)",
  "Brewster (United States)",
  "Brewton (United States)",
  "Brian Head (United States)",
  "Briarcliff Manor (United States)",
  "Bricelyn (United States)",
  "Briceville (United States)",
  "Brick Township (United States)",
  "Bridge City (United States)",
  "Bridgehampton (United States)",
  "Bridgeport (United States)",
  "Bridger (United States)",
  "Bridgeton (United States)",
  "Bridgeview (United States)",
  "Bridgeville (United States)",
  "Bridgewater (United States)",
  "Bridgewater Corners (United States)",
  "Bridgman (United States)",
  "Bridgton (United States)",
  "Brielle (United States)",
  "Brier Hill (United States)",
  "Brierfield (United States)",
  "Brigantine (United States)",
  "Briggs (United States)",
  "Briggsdale (United States)",
  "Brigham City (United States)",
  "Brighton (United States)",
  "Brightwaters (United States)",
  "Brightwood (United States)",
  "Brilliant (United States)",
  "Brillion (United States)",
  "Brimfield (United States)",
  "Brimley (United States)",
  "Bringhurst (United States)",
  "Brinkley (United States)",
  "Brisbane (United States)",
  "Briscoe (United States)",
  "Bristol (United States)",
  "Bristolville (United States)",
  "Bristow (United States)",
  "Britt (United States)",
  "Brittany (United States)",
  "Britton (United States)",
  "Broad Brook (United States)",
  "Broad Run (United States)",
  "Broadalbin (United States)",
  "Broaddus (United States)",
  "Broadlands (United States)",
  "Broadus (United States)",
  "Broadview (United States)",
  "Broadview Heights (United States)",
  "Broadway (United States)",
  "Brockport (United States)",
  "Brockton (United States)",
  "Brockway (United States)",
  "Brocton (United States)",
  "Brodhead (United States)",
  "Brodheadsville (United States)",
  "Brodnax (United States)",
  "Brogue (United States)",
  "Brohard (United States)",
  "Broken Arrow (United States)",
  "Broken Bow (United States)",
  "Bronaugh (United States)",
  "Bronson (United States)",
  "Bronston (United States)",
  "Bronte (United States)",
  "Bronwood (United States)",
  "Bronxville (United States)",
  "Brook (United States)",
  "Brook Park (United States)",
  "Brookdale (United States)",
  "Brookeland (United States)",
  "Brooker (United States)",
  "Brookeville (United States)",
  "Brookfield (United States)",
  "Brookhaven (United States)",
  "Brookings (United States)",
  "Brookland (United States)",
  "Brooklandville (United States)",
  "Brooklet (United States)",
  "Brooklin (United States)",
  "Brookline (United States)",
  "Brooklyn (United States)",
  "Brookport (United States)",
  "Brooks (United States)",
  "Brookshire (United States)",
  "Brookston (United States)",
  "Brooksville (United States)",
  "Brookton (United States)",
  "Brooktondale (United States)",
  "Brookville (United States)",
  "Brookwood (United States)",
  "Broomall (United States)",
  "Broomfield (United States)",
  "Brooten (United States)",
  "Broseley (United States)",
  "Broughton (United States)",
  "Broussard (United States)",
  "Browder (United States)",
  "Brown City (United States)",
  "Brownell (United States)",
  "Brownfield (United States)",
  "Browning (United States)",
  "Browns Mills (United States)",
  "Browns Summit (United States)",
  "Browns Valley (United States)",
  "Brownsboro (United States)",
  "Brownsburg (United States)",
  "Brownsdale (United States)",
  "Brownstown (United States)",
  "Brownsville (United States)",
  "Browntown (United States)",
  "Brownville (United States)",
  "Brownwood (United States)",
  "Broxton (United States)",
  "Bruce (United States)",
  "Bruceton (United States)",
  "Bruceton Mills (United States)",
  "Bruceville (United States)",
  "Bruin (United States)",
  "Brule (United States)",
  "Brundidge (United States)",
  "Bruner (United States)",
  "Bruni (United States)",
  "Bruning (United States)",
  "Bruno (United States)",
  "Brunsville (United States)",
  "Brunswick (United States)",
  "Brush (United States)",
  "Brush Prairie (United States)",
  "Brushton (United States)",
  "Brusly (United States)",
  "Brussels (United States)",
  "Bryan (United States)",
  "Bryans Road (United States)",
  "Bryant (United States)",
  "Bryant Pond (United States)",
  "Bryantville (United States)",
  "Bryce Canyon (United States)",
  "Bryce Canyon City (United States)",
  "Bryceville (United States)",
  "Bryn Athyn (United States)",
  "Bryn Mawr (United States)",
  "Bryson (United States)",
  "Bryson City (United States)",
  "Buchanan (United States)",
  "Buchanan Dam (United States)",
  "Buckatunna (United States)",
  "Buckeye (United States)",
  "Buckeystown (United States)",
  "Buckfield (United States)",
  "Buckhannon (United States)",
  "Buckhead (United States)",
  "Buckholts (United States)",
  "Buckingham (United States)",
  "Buckingham Township (United States)",
  "Buckland (United States)",
  "Buckley (United States)",
  "Bucklin (United States)",
  "Buckner (United States)",
  "Bucksport (United States)",
  "Bucyrus (United States)",
  "Bud (United States)",
  "Buda (United States)",
  "Budd Lake (United States)",
  "Bude (United States)",
  "Buellton (United States)",
  "Buena (United States)",
  "Buena Park (United States)",
  "Buena Vista (United States)",
  "Buffalo (United States)",
  "Buffalo Center (United States)",
  "Buffalo Gap (United States)",
  "Buffalo Grove (United States)",
  "Buffalo Lake (United States)",
  "Buffalo Valley (United States)",
  "Buford (United States)",
  "Buhl (United States)",
  "Buhler (United States)",
  "Buies Creek (United States)",
  "Bulan (United States)",
  "Bulger (United States)",
  "Bull Shoals (United States)",
  "Bullard (United States)",
  "Bullhead (United States)",
  "Bullhead City (United States)",
  "Bullock (United States)",
  "Bulls Gap (United States)",
  "Bullville (United States)",
  "Bulpitt (United States)",
  "Bulverde (United States)",
  "Bumpass (United States)",
  "Bumpus Mills (United States)",
  "Buna (United States)",
  "Bunceton (United States)",
  "Bunch (United States)",
  "Buncombe (United States)",
  "Bunker Hill (United States)",
  "Bunkie (United States)",
  "Bunn (United States)",
  "Bunnell (United States)",
  "Bunnlevel (United States)",
  "Bunola (United States)",
  "Buras (United States)",
  "Burbank (United States)",
  "Burden (United States)",
  "Burdett (United States)",
  "Burdick (United States)",
  "Bureau (United States)",
  "Burgaw (United States)",
  "Burgess (United States)",
  "Burgettstown (United States)",
  "Burgin (United States)",
  "Burkburnett (United States)",
  "Burke (United States)",
  "Burkesville (United States)",
  "Burket (United States)",
  "Burkeville (United States)",
  "Burleson (United States)",
  "Burley (United States)",
  "Burlingame (United States)",
  "Burlington (United States)",
  "Burlington Flats (United States)",
  "Burlington Junction (United States)",
  "Burlison (United States)",
  "Burnet (United States)",
  "Burnettsville (United States)",
  "Burney (United States)",
  "Burneyville (United States)",
  "Burnham (United States)",
  "Burns (United States)",
  "Burns Flat (United States)",
  "Burnside (United States)",
  "Burnsville (United States)",
  "Burnt Hills (United States)",
  "Burr (United States)",
  "Burr Hill (United States)",
  "Burrton (United States)",
  "Burt (United States)",
  "Burton (United States)",
  "Burtonsville (United States)",
  "Burtrum (United States)",
  "Burwell (United States)",
  "Busby (United States)",
  "Bush (United States)",
  "Bushkill (United States)",
  "Bushland (United States)",
  "Bushnell (United States)",
  "Bushton (United States)",
  "Buskirk (United States)",
  "Busy (United States)",
  "Butler (United States)",
  "Butlerville (United States)",
  "Butner (United States)",
  "Butte (United States)",
  "Butte des Morts (United States)",
  "Butterfield (United States)",
  "Butternut (United States)",
  "Buttonwillow (United States)",
  "Buxton (United States)",
  "Buzzards Bay (United States)",
  "Byers (United States)",
  "Byesville (United States)",
  "Byfield (United States)",
  "Byhalia (United States)",
  "Bylas (United States)",
  "Bynum (United States)",
  "Byram (United States)",
  "Byrdstown (United States)",
  "Byron (United States)",
  "Byron Center (United States)",
  "Cabazon (United States)",
  "Cabery (United States)",
  "Cabin Creek (United States)",
  "Cabin John (United States)",
  "Cable (United States)",
  "Cabool (United States)",
  "Cabot (United States)",
  "Cache (United States)",
  "Cactus (United States)",
  "Caddo (United States)",
  "Caddo Gap (United States)",
  "Caddo Mills (United States)",
  "Cade (United States)",
  "Cades (United States)",
  "Cadet (United States)",
  "Cadillac (United States)",
  "Cadiz (United States)",
  "Cadott (United States)",
  "Cadyville (United States)",
  "Cainsville (United States)",
  "Cairnbrook (United States)",
  "Cairo (United States)",
  "Calabasas (United States)",
  "Calabash (United States)",
  "Calais (United States)",
  "Calamus (United States)",
  "Calder (United States)",
  "Caldwell (United States)",
  "Caledonia (United States)",
  "Calera (United States)",
  "Calexico (United States)",
  "Calhan (United States)",
  "Calhoun (United States)",
  "Calhoun City (United States)",
  "Calhoun Falls (United States)",
  "Calico Rock (United States)",
  "Caliente (United States)",
  "Califon (United States)",
  "California (United States)",
  "California City (United States)",
  "Calimesa (United States)",
  "Calipatria (United States)",
  "Calistoga (United States)",
  "Callahan (United States)",
  "Callao (United States)",
  "Callaway (United States)",
  "Callicoon (United States)",
  "Calmar (United States)",
  "Calpella (United States)",
  "Calpine (United States)",
  "Calumet (United States)",
  "Calumet City (United States)",
  "Calvert (United States)",
  "Calvert City (United States)",
  "Calverton (United States)",
  "Calypso (United States)",
  "Camanche (United States)",
  "Camano (United States)",
  "Camarillo (United States)",
  "Camas (United States)",
  "Cambria (United States)",
  "Cambria Heights (United States)",
  "Cambridge (United States)",
  "Cambridge City (United States)",
  "Cambridge Springs (United States)",
  "Cambridgeport (United States)",
  "Camby (United States)",
  "Camden (United States)",
  "Camden Point (United States)",
  "Camdenton (United States)",
  "Cameron (United States)",
  "Cameron Mills (United States)",
  "Camilla (United States)",
  "Camillus (United States)",
  "Camino (United States)",
  "Camp Creek (United States)",
  "Camp Dennison (United States)",
  "Camp Douglas (United States)",
  "Camp H M Smith (United States)",
  "Camp Hill (United States)",
  "Camp Meeker (United States)",
  "Camp Point (United States)",
  "Camp Verde (United States)",
  "Camp Wood (United States)",
  "Campbell (United States)",
  "Campbell Hall (United States)",
  "Campbell Hill (United States)",
  "Campbellsburg (United States)",
  "Campbellsport (United States)",
  "Campbellsville (United States)",
  "Campbelltown (United States)",
  "Campo (United States)",
  "Campobello (United States)",
  "Campti (United States)",
  "Campton (United States)",
  "Campton Upper Village (United States)",
  "Campus (United States)",
  "Cana (United States)",
  "Canaan (United States)",
  "Canada (United States)",
  "Canadensis (United States)",
  "Canadian (United States)",
  "Canajoharie (United States)",
  "Canal Fulton (United States)",
  "Canal Point (United States)",
  "Canal Winchester (United States)",
  "Canalou (United States)",
  "Canandaigua (United States)",
  "Canastota (United States)",
  "Canby (United States)",
  "Candia (United States)",
  "Candler (United States)",
  "Cando (United States)",
  "Candor (United States)",
  "Canehill (United States)",
  "Caney (United States)",
  "Caneyville (United States)",
  "Canfield (United States)",
  "Canisteo (United States)",
  "Canmer (United States)",
  "Cannel City (United States)",
  "Cannelton (United States)",
  "Cannon (United States)",
  "Cannon Falls (United States)",
  "Cannonville (United States)",
  "Canoga Park (United States)",
  "Canon (United States)",
  "Canon City (United States)",
  "Canonsburg (United States)",
  "Canterbury (United States)",
  "Canton (United States)",
  "Cantonment (United States)",
  "Cantrall (United States)",
  "Canute (United States)",
  "Canutillo (United States)",
  "Canvas (United States)",
  "Canyon (United States)",
  "Canyon City (United States)",
  "Canyon Country (United States)",
  "Canyon Lake (United States)",
  "Canyonville (United States)",
  "Capac (United States)",
  "Cape Canaveral (United States)",
  "Cape Charles (United States)",
  "Cape Coral (United States)",
  "Cape Elizabeth (United States)",
  "Cape Fair (United States)",
  "Cape Girardeau (United States)",
  "Cape May (United States)",
  "Cape May Court House (United States)",
  "Cape May Point (United States)",
  "Cape Neddick (United States)",
  "Cape Porpoise (United States)",
  "Cape Vincent (United States)",
  "Capeville (United States)",
  "Capistrano Beach (United States)",
  "Capitan (United States)",
  "Capitola (United States)",
  "Capon Bridge (United States)",
  "Capron (United States)",
  "Captain Cook (United States)",
  "Captiva (United States)",
  "Caputa (United States)",
  "Caraway (United States)",
  "Carbon (United States)",
  "Carbon Hill (United States)",
  "Carbonado (United States)",
  "Carbondale (United States)",
  "Cardale (United States)",
  "Cardington (United States)",
  "Carefree (United States)",
  "Carencro (United States)",
  "Carey (United States)",
  "Careywood (United States)",
  "Caribou (United States)",
  "Carl Junction (United States)",
  "Carle Place (United States)",
  "Carleton (United States)",
  "Carlin (United States)",
  "Carlinville (United States)",
  "Carlisle (United States)",
  "Carlock (United States)",
  "Carlos (United States)",
  "Carlotta (United States)",
  "Carlsbad (United States)",
  "Carlsborg (United States)",
  "Carlstadt (United States)",
  "Carlton (United States)",
  "Carlyle (United States)",
  "Carman (United States)",
  "Carmel (United States)",
  "Carmel Valley (United States)",
  "Carmen (United States)",
  "Carmi (United States)",
  "Carmichael (United States)",
  "Carmichaels (United States)",
  "Carmine (United States)",
  "Carnation (United States)",
  "Carnegie (United States)",
  "Carnesville (United States)",
  "Carney (United States)",
  "Caro (United States)",
  "Caroga Lake (United States)",
  "Carol Stream (United States)",
  "Carolina (United States)",
  "Carolina Beach (United States)",
  "Carp Lake (United States)",
  "Carpentersville (United States)",
  "Carpinteria (United States)",
  "Carr (United States)",
  "Carrabelle (United States)",
  "Carrboro (United States)",
  "Carrie (United States)",
  "Carrier (United States)",
  "Carrier Mills (United States)",
  "Carriere (United States)",
  "Carrington (United States)",
  "Carrizo Springs (United States)",
  "Carrizozo (United States)",
  "Carroll (United States)",
  "Carrollton (United States)",
  "Carrolltown (United States)",
  "Carrsville (United States)",
  "Carson (United States)",
  "Carson City (United States)",
  "Carsonville (United States)",
  "Carter Lake (United States)",
  "Carteret (United States)",
  "Cartersville (United States)",
  "Carterville (United States)",
  "Carthage (United States)",
  "Cartwright (United States)",
  "Caruthers (United States)",
  "Caruthersville (United States)",
  "Carver (United States)",
  "Cary (United States)",
  "Caryville (United States)",
  "Casa Grande (United States)",
  "Casanova (United States)",
  "Casar (United States)",
  "Cascade (United States)",
  "Cascade Locks (United States)",
  "Cascilla (United States)",
  "Casco (United States)",
  "Caseville (United States)",
  "Casey (United States)",
  "Caseyville (United States)",
  "Cashiers (United States)",
  "Cashion (United States)",
  "Cashmere (United States)",
  "Cashton (United States)",
  "Cashtown (United States)",
  "Casnovia (United States)",
  "Casper (United States)",
  "Caspian (United States)",
  "Cass City (United States)",
  "Cass Lake (United States)",
  "Cassadaga (United States)",
  "Cassatt (United States)",
  "Cassel (United States)",
  "Casselberry (United States)",
  "Casselton (United States)",
  "Cassoday (United States)",
  "Cassopolis (United States)",
  "Casstown (United States)",
  "Cassville (United States)",
  "Castaic (United States)",
  "Castalia (United States)",
  "Castalian Springs (United States)",
  "Castile (United States)",
  "Castine (United States)",
  "Castle Dale (United States)",
  "Castle Hayne (United States)",
  "Castle Point (United States)",
  "Castle Rock (United States)",
  "Castleberry (United States)",
  "Castleton (United States)",
  "Castleton-on-Hudson (United States)",
  "Castlewood (United States)",
  "Castor (United States)",
  "Castorland (United States)",
  "Castro Valley (United States)",
  "Castroville (United States)",
  "Cat Spring (United States)",
  "Cataldo (United States)",
  "Catasauqua (United States)",
  "Cataula (United States)",
  "Cataumet (United States)",
  "Catawba (United States)",
  "Catawissa (United States)",
  "Catharpin (United States)",
  "Cathedral City (United States)",
  "Catherine (United States)",
  "Catheys Valley (United States)",
  "Cathlamet (United States)",
  "Catlett (United States)",
  "Catlettsburg (United States)",
  "Catlin (United States)",
  "Cato (United States)",
  "Catonsville (United States)",
  "Catoosa (United States)",
  "Catron (United States)",
  "Catskill (United States)",
  "Cattaraugus (United States)",
  "Caulfield (United States)",
  "Cavalier (United States)",
  "Cave City (United States)",
  "Cave Creek (United States)",
  "Cave Junction (United States)",
  "Cave Spring (United States)",
  "Cave Springs (United States)",
  "Cave-in-Rock (United States)",
  "Cavendish (United States)",
  "Cavour (United States)",
  "Cawood (United States)",
  "Cayce (United States)",
  "Cayucos (United States)",
  "Cayuga (United States)",
  "Cayuta (United States)",
  "Cazadero (United States)",
  "Cazenovia (United States)",
  "Cecil (United States)",
  "Cecilia (United States)",
  "Cecilton (United States)",
  "Cedar (United States)",
  "Cedar Bluff (United States)",
  "Cedar Bluffs (United States)",
  "Cedar Brook (United States)",
  "Cedar City (United States)",
  "Cedar Creek (United States)",
  "Cedar Crest (United States)",
  "Cedar Falls (United States)",
  "Cedar Glen (United States)",
  "Cedar Grove (United States)",
  "Cedar Hill (United States)",
  "Cedar Key (United States)",
  "Cedar Knolls (United States)",
  "Cedar Lake (United States)",
  "Cedar Park (United States)",
  "Cedar Point (United States)",
  "Cedar Rapids (United States)",
  "Cedar Ridge (United States)",
  "Cedar Springs (United States)",
  "Cedar Vale (United States)",
  "Cedar Valley (United States)",
  "Cedarburg (United States)",
  "Cedaredge (United States)",
  "Cedarhurst (United States)",
  "Cedartown (United States)",
  "Cedarville (United States)",
  "Celeste (United States)",
  "Celina (United States)",
  "Celoron (United States)",
  "Cement (United States)",
  "Cement City (United States)",
  "Centenary (United States)",
  "Center (United States)",
  "Center Barnstead (United States)",
  "Center City (United States)",
  "Center Conway (United States)",
  "Center Cross (United States)",
  "Center Harbor (United States)",
  "Center Hill (United States)",
  "Center Junction (United States)",
  "Center Line (United States)",
  "Center Moriches (United States)",
  "Center Ossipee (United States)",
  "Center Point (United States)",
  "Center Ridge (United States)",
  "Center Rutland (United States)",
  "Center Sandwich (United States)",
  "Center Tuftonboro (United States)",
  "Center Valley (United States)",
  "Centerbrook (United States)",
  "Centerburg (United States)",
  "Centereach (United States)",
  "Centerfield (United States)",
  "Centerport (United States)",
  "Centerton (United States)",
  "Centertown (United States)",
  "Centerview (United States)",
  "Centerville (United States)",
  "Centrahoma (United States)",
  "Central (United States)",
  "Central Bridge (United States)",
  "Central City (United States)",
  "Central Falls (United States)",
  "Central Islip (United States)",
  "Central Lake (United States)",
  "Central Point (United States)",
  "Central Square (United States)",
  "Central Valley (United States)",
  "Central Village (United States)",
  "Centralia (United States)",
  "Centre (United States)",
  "Centre Hall (United States)",
  "Centreville (United States)",
  "Centuria (United States)",
  "Century (United States)",
  "Ceres (United States)",
  "Ceresco (United States)",
  "Cerrillos (United States)",
  "Cerritos (United States)",
  "Cerro (United States)",
  "Cerro Gordo (United States)",
  "Cerulean (United States)",
  "Chadbourn (United States)",
  "Chadds Ford (United States)",
  "Chadron (United States)",
  "Chadwick (United States)",
  "Chaffee (United States)",
  "Chagrin Falls (United States)",
  "Chalfont (United States)",
  "Challis (United States)",
  "Chalmette (United States)",
  "Chama (United States)",
  "Chamberino (United States)",
  "Chamberlain (United States)",
  "Chambers (United States)",
  "Chambersburg (United States)",
  "Champaign (United States)",
  "Champion (United States)",
  "Champlain (United States)",
  "Champlin (United States)",
  "Chana (United States)",
  "Chancellor (United States)",
  "Chandler (United States)",
  "Chandlersville (United States)",
  "Chandlerville (United States)",
  "Chanhassen (United States)",
  "Channahon (United States)",
  "Channelview (United States)",
  "Channing (United States)",
  "Chantilly (United States)",
  "Chanute (United States)",
  "Chaparral (United States)",
  "Chapel Hill (United States)",
  "Chapin (United States)",
  "Chaplin (United States)",
  "Chapman (United States)",
  "Chapmansboro (United States)",
  "Chapmanville (United States)",
  "Chappaqua (United States)",
  "Chappell Hill (United States)",
  "Chardon (United States)",
  "Charenton (United States)",
  "Chariton (United States)",
  "Charlemont (United States)",
  "Charleroi (United States)",
  "Charles City (United States)",
  "Charles Town (United States)",
  "Charleston (United States)",
  "Charlestown (United States)",
  "Charlevoix (United States)",
  "Charlotte (United States)",
  "Charlotte Court House (United States)",
  "Charlotte Hall (United States)",
  "Charlottesville (United States)",
  "Charlotteville (United States)",
  "Charlton (United States)",
  "Charter Oak (United States)",
  "Charter Township of Clinton (United States)",
  "Chartley (United States)",
  "Chase (United States)",
  "Chase City (United States)",
  "Chaseburg (United States)",
  "Chaseley (United States)",
  "Chaska (United States)",
  "Chassell (United States)",
  "Chataignier (United States)",
  "Chatfield (United States)",
  "Chatham (United States)",
  "Chatom (United States)",
  "Chatsworth (United States)",
  "Chattahoochee (United States)",
  "Chattanooga (United States)",
  "Chattaroy (United States)",
  "Chaumont (United States)",
  "Chauncey (United States)",
  "Chautauqua (United States)",
  "Chauvin (United States)",
  "Chavies (United States)",
  "Chazy (United States)",
  "Chebanse (United States)",
  "Chebeague Island (United States)",
  "Cheboygan (United States)",
  "Checotah (United States)",
  "Chefornak (United States)",
  "Chehalis (United States)",
  "Chelan (United States)",
  "Chelan Falls (United States)",
  "Chelmsford (United States)",
  "Chelsea (United States)",
  "Cheltenham (United States)",
  "Chemung (United States)",
  "Chenango Forks (United States)",
  "Cheney (United States)",
  "Cheneyville (United States)",
  "Chenoa (United States)",
  "Chepachet (United States)",
  "Cheraw (United States)",
  "Cheriton (United States)",
  "Cherokee (United States)",
  "Cherokee Village (United States)",
  "Cherry Hill (United States)",
  "Cherry Log (United States)",
  "Cherry Point Marine Base Mobile Home Park (United States)",
  "Cherry Tree (United States)",
  "Cherry Valley (United States)",
  "Cherryfield (United States)",
  "Cherryvale (United States)",
  "Cherryville (United States)",
  "Chesaning (United States)",
  "Chesapeake (United States)",
  "Chesapeake Beach (United States)",
  "Chesapeake City (United States)",
  "Cheshire (United States)",
  "Chesnee (United States)",
  "Chester (United States)",
  "Chester Heights (United States)",
  "Chester Springs (United States)",
  "Chesterfield (United States)",
  "Chesterhill (United States)",
  "Chesterland (United States)",
  "Chesterton (United States)",
  "Chestertown (United States)",
  "Chestnut (United States)",
  "Chestnut Hill (United States)",
  "Chestnut Mountain (United States)",
  "Chestnut Ridge (United States)",
  "Cheswick (United States)",
  "Chetek (United States)",
  "Chetopa (United States)",
  "Chevak (United States)",
  "Chevy Chase (United States)",
  "Chewelah (United States)",
  "Cheyenne (United States)",
  "Cheyenne Wells (United States)",
  "Cheyney (United States)",
  "Chicago (United States)",
  "Chicago Heights (United States)",
  "Chicago Ridge (United States)",
  "Chichester (United States)",
  "Chickamauga (United States)",
  "Chickasha (United States)",
  "Chicken (United States)",
  "Chico (United States)",
  "Chicopee (United States)",
  "Chicora (United States)",
  "Chiefland (United States)",
  "Childersburg (United States)",
  "Childress (United States)",
  "Childs (United States)",
  "Chilhowee (United States)",
  "Chilhowie (United States)",
  "Chili (United States)",
  "Chillicothe (United States)",
  "Chilo (United States)",
  "Chilton (United States)",
  "Chimacum (United States)",
  "China Grove (United States)",
  "China Springs (United States)",
  "Chinchilla (United States)",
  "Chincoteague Island (United States)",
  "Chinese Camp (United States)",
  "Chinle (United States)",
  "Chino (United States)",
  "Chino Hills (United States)",
  "Chino Valley (United States)",
  "Chinook (United States)",
  "Chipley (United States)",
  "Chippewa Falls (United States)",
  "Chippewa Lake (United States)",
  "Chireno (United States)",
  "Chisago City (United States)",
  "Chisholm (United States)",
  "Chittenango (United States)",
  "Chittenden (United States)",
  "Chloride (United States)",
  "Chocorua (United States)",
  "Chocowinity (United States)",
  "Choctaw (United States)",
  "Chokio (United States)",
  "Choteau (United States)",
  "Choudrant (United States)",
  "Chouteau (United States)",
  "Chowchilla (United States)",
  "Chrisman (United States)",
  "Christchurch (United States)",
  "Christiana (United States)",
  "Christiansburg (United States)",
  "Christine (United States)",
  "Christmas (United States)",
  "Christmas Valley (United States)",
  "Christopher (United States)",
  "Christoval (United States)",
  "Chualar (United States)",
  "Chuckey (United States)",
  "Chugiak (United States)",
  "Chugwater (United States)",
  "Chula (United States)",
  "Chula Vista (United States)",
  "Chunchula (United States)",
  "Chunky (United States)",
  "Church Hill (United States)",
  "Church Point (United States)",
  "Church Rock (United States)",
  "Churchton (United States)",
  "Churchville (United States)",
  "Churdan (United States)",
  "Churubusco (United States)",
  "Cibola (United States)",
  "Cibolo (United States)",
  "Cicero (United States)",
  "Cimarron (United States)",
  "Cincinnati (United States)",
  "Cincinnatus (United States)",
  "Cinebar (United States)",
  "Circle (United States)",
  "Circle Pines (United States)",
  "Circleville (United States)",
  "Cisco (United States)",
  "Cisne (United States)",
  "Cissna Park (United States)",
  "Citra (United States)",
  "Citronelle (United States)",
  "Citrus Heights (United States)",
  "City of Angels (United States)",
  "City of Industry (United States)",
  "City of Picher (historical) (United States)",
  "City of Saint Marys (United States)",
  "City of Saint Peters (United States)",
  "Clackamas (United States)",
  "Claflin (United States)",
  "Claire City (United States)",
  "Clairton (United States)",
  "Clam Lake (United States)",
  "Clancy (United States)",
  "Clanton (United States)",
  "Clara City (United States)",
  "Clare (United States)",
  "Claremont (United States)",
  "Claremore (United States)",
  "Clarence (United States)",
  "Clarendon (United States)",
  "Clarendon Hills (United States)",
  "Claridge (United States)",
  "Clarinda (United States)",
  "Clarington (United States)",
  "Clarion (United States)",
  "Clarissa (United States)",
  "Clark (United States)",
  "Clark Fork (United States)",
  "Clark Mills (United States)",
  "Clarkesville (United States)",
  "Clarkfield (United States)",
  "Clarkia (United States)",
  "Clarklake (United States)",
  "Clarkrange (United States)",
  "Clarks (United States)",
  "Clarks Grove (United States)",
  "Clarks Hill (United States)",
  "Clarks Summit (United States)",
  "Clarksboro (United States)",
  "Clarksburg (United States)",
  "Clarksdale (United States)",
  "Clarkson (United States)",
  "Clarkston (United States)",
  "Clarksville (United States)",
  "Clarkton (United States)",
  "Claryville (United States)",
  "Clatonia (United States)",
  "Clatskanie (United States)",
  "Claude (United States)",
  "Claudville (United States)",
  "Claverack (United States)",
  "Clawson (United States)",
  "Claxton (United States)",
  "Clay (United States)",
  "Clay Center (United States)",
  "Clay City (United States)",
  "Claymont (United States)",
  "Claypool (United States)",
  "Claysburg (United States)",
  "Claysville (United States)",
  "Clayton (United States)",
  "Cle Elum (United States)",
  "Clear Brook (United States)",
  "Clear Fork (United States)",
  "Clear Lake (United States)",
  "Clear Spring (United States)",
  "Clearbrook (United States)",
  "Clearfield (United States)",
  "Clearlake (United States)",
  "Clearlake Oaks (United States)",
  "Clearmont (United States)",
  "Clearville (United States)",
  "Clearwater (United States)",
  "Clearwater Beach (United States)",
  "Cleburne (United States)",
  "Cleghorn (United States)",
  "Clementon (United States)",
  "Clements (United States)",
  "Clemmons (United States)",
  "Clemons (United States)",
  "Clemson (United States)",
  "Clendenin (United States)",
  "Clermont (United States)",
  "Cleveland (United States)",
  "Clever (United States)",
  "Cleves (United States)",
  "Clewiston (United States)",
  "Clifford (United States)",
  "Cliffside Park (United States)",
  "Cliffwood (United States)",
  "Clifton (United States)",
  "Clifton Forge (United States)",
  "Clifton Heights (United States)",
  "Clifton Park (United States)",
  "Clifton Springs (United States)",
  "Climax (United States)",
  "Climax Springs (United States)",
  "Clinchco (United States)",
  "Clint (United States)",
  "Clinton (United States)",
  "Clinton Corners (United States)",
  "Clintondale (United States)",
  "Clintonville (United States)",
  "Clintwood (United States)",
  "Clio (United States)",
  "Clive (United States)",
  "Clontarf (United States)",
  "Clopton (United States)",
  "Cloquet (United States)",
  "Closplint (United States)",
  "Closter (United States)",
  "Cloud (United States)",
  "Cloudcroft (United States)",
  "Clover (United States)",
  "Cloverdale (United States)",
  "Cloverport (United States)",
  "Clovis (United States)",
  "Clute (United States)",
  "Clyde (United States)",
  "Clyde Park (United States)",
  "Clyman (United States)",
  "Clymer (United States)",
  "Clyo (United States)",
  "Coachella (United States)",
  "Coahoma (United States)",
  "Coal Center (United States)",
  "Coal City (United States)",
  "Coal Hill (United States)",
  "Coal Mountain (United States)",
  "Coal Township (United States)",
  "Coal Valley (United States)",
  "Coaldale (United States)",
  "Coalfield (United States)",
  "Coalgate (United States)",
  "Coalinga (United States)",
  "Coalmont (United States)",
  "Coalport (United States)",
  "Coalville (United States)",
  "Coarsegold (United States)",
  "Coatesville (United States)",
  "Coats (United States)",
  "Coatsburg (United States)",
  "Cobalt (United States)",
  "Cobb (United States)",
  "Cobbs Creek (United States)",
  "Cobden (United States)",
  "Cobleskill (United States)",
  "Coburn (United States)",
  "Cochecton (United States)",
  "Cochise (United States)",
  "Cochiti Lake (United States)",
  "Cochiti Pueblo (United States)",
  "Cochran (United States)",
  "Cochrane (United States)",
  "Cochranton (United States)",
  "Cochranville (United States)",
  "Cockeysville (United States)",
  "Cocoa (United States)",
  "Cocoa Beach (United States)",
  "Cocolamus (United States)",
  "Coden (United States)",
  "Cody (United States)",
  "Coeburn (United States)",
  "Coeymans (United States)",
  "Coeymans Hollow (United States)",
  "Coffee Springs (United States)",
  "Coffeen (United States)",
  "Coffeeville (United States)",
  "Coffey (United States)",
  "Coffeyville (United States)",
  "Coffman Cove (United States)",
  "Coggon (United States)",
  "Cogswell (United States)",
  "Cohasset (United States)",
  "Cohocton (United States)",
  "Cohoes (United States)",
  "Cohutta (United States)",
  "Coin (United States)",
  "Coinjock (United States)",
  "Cokato (United States)",
  "Cokeburg (United States)",
  "Coker (United States)",
  "Cokeville (United States)",
  "Colbert (United States)",
  "Colby (United States)",
  "Colchester (United States)",
  "Colcord (United States)",
  "Cold Brook (United States)",
  "Cold Spring (United States)",
  "Cold Spring Harbor (United States)",
  "Cold Springs (United States)",
  "Colden (United States)",
  "Coldspring (United States)",
  "Coldwater (United States)",
  "Cole Camp (United States)",
  "Colebrook (United States)",
  "Coleman (United States)",
  "Colerain (United States)",
  "Coleraine (United States)",
  "Coleridge (United States)",
  "Colesburg (United States)",
  "Coleville (United States)",
  "Colfax (United States)",
  "Colgate (United States)",
  "College Corner (United States)",
  "College Grove (United States)",
  "College Park (United States)",
  "College Place (United States)",
  "College Point (United States)",
  "College Station (United States)",
  "Collegedale (United States)",
  "Collegeport (United States)",
  "Collegeville (United States)",
  "Colleyville (United States)",
  "Colliers (United States)",
  "Collierville (United States)",
  "Collingswood (United States)",
  "Collins (United States)",
  "Collinston (United States)",
  "Collinsville (United States)",
  "Collinwood (United States)",
  "Collison (United States)",
  "Colman (United States)",
  "Colmar (United States)",
  "Colmesneil (United States)",
  "Colo (United States)",
  "Cologne (United States)",
  "Coloma (United States)",
  "Colon (United States)",
  "Colona (United States)",
  "Colonia (United States)",
  "Colonial Beach (United States)",
  "Colonial Heights (United States)",
  "Colony (United States)",
  "Colora (United States)",
  "Colorado City (United States)",
  "Colorado Springs (United States)",
  "Colquitt (United States)",
  "Colstrip (United States)",
  "Colt (United States)",
  "Colton (United States)",
  "Colts Neck (United States)",
  "Columbia (United States)",
  "Columbia City (United States)",
  "Columbia Cross Roads (United States)",
  "Columbia Falls (United States)",
  "Columbia Station (United States)",
  "Columbiana (United States)",
  "Columbiaville (United States)",
  "Columbus (United States)",
  "Columbus Grove (United States)",
  "Columbus Junction (United States)",
  "Colusa (United States)",
  "Colver (United States)",
  "Colville (United States)",
  "Colwich (United States)",
  "Comanche (United States)",
  "Combined Locks (United States)",
  "Combs (United States)",
  "Comer (United States)",
  "Comfort (United States)",
  "Comfrey (United States)",
  "Commack (United States)",
  "Commerce (United States)",
  "Commerce City (United States)",
  "Commercial Point (United States)",
  "Commiskey (United States)",
  "Como (United States)",
  "Comptche (United States)",
  "Compton (United States)",
  "Comstock (United States)",
  "Comstock Park (United States)",
  "Conception Junction (United States)",
  "Concho (United States)",
  "Concord (United States)",
  "Concordia (United States)",
  "Concordville (United States)",
  "Concrete (United States)",
  "Condon (United States)",
  "Conestoga (United States)",
  "Conesus (United States)",
  "Conesville (United States)",
  "Confluence (United States)",
  "Conger (United States)",
  "Congers (United States)",
  "Congerville (United States)",
  "Congress (United States)",
  "Conifer (United States)",
  "Conklin (United States)",
  "Conley (United States)",
  "Conneaut (United States)",
  "Conneaut Lake (United States)",
  "Conneautville (United States)",
  "Connell (United States)",
  "Connellsville (United States)",
  "Connelly Springs (United States)",
  "Connersville (United States)",
  "Conover (United States)",
  "Conowingo (United States)",
  "Conrad (United States)",
  "Conrath (United States)",
  "Conroe (United States)",
  "Conroy (United States)",
  "Conshohocken (United States)",
  "Constableville (United States)",
  "Constantia (United States)",
  "Constantine (United States)",
  "Contact (United States)",
  "Content (United States)",
  "Continental (United States)",
  "Contoocook (United States)",
  "Convent (United States)",
  "Converse (United States)",
  "Convoy (United States)",
  "Conway (United States)",
  "Conway Springs (United States)",
  "Conyers (United States)",
  "Conyngham (United States)",
  "Cook (United States)",
  "Cookeville (United States)",
  "Cookstown (United States)",
  "Cooksville (United States)",
  "Coolidge (United States)",
  "Coolin (United States)",
  "Coolville (United States)",
  "Coon Rapids (United States)",
  "Coon Valley (United States)",
  "Cooper (United States)",
  "Cooper Landing (United States)",
  "Coopers Plains (United States)",
  "Coopersburg (United States)",
  "Cooperstown (United States)",
  "Coopersville (United States)",
  "Coos Bay (United States)",
  "Copake (United States)",
  "Copake Falls (United States)",
  "Copalis Beach (United States)",
  "Copan (United States)",
  "Cope (United States)",
  "Copeland (United States)",
  "Copenhagen (United States)",
  "Copeville (United States)",
  "Copiague (United States)",
  "Coplay (United States)",
  "Coppell (United States)",
  "Copper Center (United States)",
  "Copper Harbor (United States)",
  "Copperas Cove (United States)",
  "Copperhill (United States)",
  "Copperopolis (United States)",
  "Coquille (United States)",
  "Coral (United States)",
  "Coral Gables (United States)",
  "Coralville (United States)",
  "Coram (United States)",
  "Coraopolis (United States)",
  "Corbett (United States)",
  "Corbin (United States)",
  "Corcoran (United States)",
  "Cordele (United States)",
  "Cordell (United States)",
  "Cordesville (United States)",
  "Cordova (United States)",
  "Corfu (United States)",
  "Corinna (United States)",
  "Corinne (United States)",
  "Corinth (United States)",
  "Corn (United States)",
  "Cornelia (United States)",
  "Cornelius (United States)",
  "Cornell (United States)",
  "Cornersville (United States)",
  "Corning (United States)",
  "Cornish (United States)",
  "Cornish Flat (United States)",
  "Cornville (United States)",
  "Cornwall (United States)",
  "Cornwall Bridge (United States)",
  "Cornwall-on-Hudson (United States)",
  "Cornwallville (United States)",
  "Corolla (United States)",
  "Corona (United States)",
  "Corona del Mar (United States)",
  "Coronado (United States)",
  "Corpus Christi (United States)",
  "Corrales (United States)",
  "Correctionville (United States)",
  "Corrigan (United States)",
  "Corry (United States)",
  "Corryton (United States)",
  "Corsica (United States)",
  "Corsicana (United States)",
  "Cortaro (United States)",
  "Corte Madera (United States)",
  "Cortez (United States)",
  "Cortland (United States)",
  "Corunna (United States)",
  "Corvallis (United States)",
  "Corwith (United States)",
  "Cory (United States)",
  "Corydon (United States)",
  "Cos Cob (United States)",
  "Cosby (United States)",
  "Coshocton (United States)",
  "Cosmopolis (United States)",
  "Cosmos (United States)",
  "Costa Mesa (United States)",
  "Cotati (United States)",
  "Cottage Grove (United States)",
  "Cottage Hills (United States)",
  "Cottageville (United States)",
  "Cottekill (United States)",
  "Cotton (United States)",
  "Cotton Valley (United States)",
  "Cottondale (United States)",
  "Cottonport (United States)",
  "Cottontown (United States)",
  "Cottonwood (United States)",
  "Cotuit (United States)",
  "Cotulla (United States)",
  "Coudersport (United States)",
  "Coulee City (United States)",
  "Coulee Dam (United States)",
  "Coulterville (United States)",
  "Counce (United States)",
  "Council (United States)",
  "Council Bluffs (United States)",
  "Council Grove (United States)",
  "Council Hill (United States)",
  "Country Club Hills (United States)",
  "Coupeville (United States)",
  "Coupland (United States)",
  "Courtland (United States)",
  "Coushatta (United States)",
  "Cove (United States)",
  "Covelo (United States)",
  "Coventry (United States)",
  "Covert (United States)",
  "Covina (United States)",
  "Covington (United States)",
  "Cowan (United States)",
  "Cowansville (United States)",
  "Coward (United States)",
  "Cowden (United States)",
  "Cowen (United States)",
  "Coweta (United States)",
  "Cowiche (United States)",
  "Cowlesville (United States)",
  "Cowley (United States)",
  "Cowpens (United States)",
  "Coxs Creek (United States)",
  "Coxsackie (United States)",
  "Coyle (United States)",
  "Coyote (United States)",
  "Cozad (United States)",
  "Crab Orchard (United States)",
  "Crabtree (United States)",
  "Craftsbury (United States)",
  "Craig (United States)",
  "Craigmont (United States)",
  "Craigsville (United States)",
  "Craigville (United States)",
  "Cramerton (United States)",
  "Cranberry Township (United States)",
  "Cranbury (United States)",
  "Crandall (United States)",
  "Crandon (United States)",
  "Crane (United States)",
  "Crane Hill (United States)",
  "Crane Lake (United States)",
  "Cranesville (United States)",
  "Cranfills Gap (United States)",
  "Cranford (United States)",
  "Cranks (United States)",
  "Cranston (United States)",
  "Craryville (United States)",
  "Crawford (United States)",
  "Crawfordsville (United States)",
  "Crawfordville (United States)",
  "Crawley (United States)",
  "Creal Springs (United States)",
  "Cream Ridge (United States)",
  "Creede (United States)",
  "Creedmoor (United States)",
  "Creekside (United States)",
  "Creighton (United States)",
  "Creola (United States)",
  "Creole (United States)",
  "Cresbard (United States)",
  "Crescent (United States)",
  "Crescent City (United States)",
  "Crescent Hills (United States)",
  "Crescent Mills (United States)",
  "Crescent Valley (United States)",
  "Cresco (United States)",
  "Cresskill (United States)",
  "Cresson (United States)",
  "Crest Hill (United States)",
  "Crested Butte (United States)",
  "Crestline (United States)",
  "Creston (United States)",
  "Crestone (United States)",
  "Crestview (United States)",
  "Crestwood (United States)",
  "Creswell (United States)",
  "Crete (United States)",
  "Creve Coeur (United States)",
  "Crewe (United States)",
  "Crimora (United States)",
  "Cripple Creek (United States)",
  "Crisfield (United States)",
  "Crittenden (United States)",
  "Critz (United States)",
  "Crivitz (United States)",
  "Crocker (United States)",
  "Crockett (United States)",
  "Crofton (United States)",
  "Croghan (United States)",
  "Crompond (United States)",
  "Cromwell (United States)",
  "Crooks (United States)",
  "Crookston (United States)",
  "Crooksville (United States)",
  "Cropseyville (United States)",
  "Crosby (United States)",
  "Crosbyton (United States)",
  "Cross (United States)",
  "Cross City (United States)",
  "Cross Junction (United States)",
  "Cross Lake (United States)",
  "Cross Plains (United States)",
  "Cross River (United States)",
  "Crossett (United States)",
  "Crossville (United States)",
  "Crosswicks (United States)",
  "Croswell (United States)",
  "Crothersville (United States)",
  "Croton (United States)",
  "Croton Falls (United States)",
  "Croton-on-Hudson (United States)",
  "Crow Agency (United States)",
  "Crowder (United States)",
  "Crowell (United States)",
  "Crowheart (United States)",
  "Crowley (United States)",
  "Crown City (United States)",
  "Crown Point (United States)",
  "Crownpoint (United States)",
  "Crownsville (United States)",
  "Crows Landing (United States)",
  "Croydon (United States)",
  "Crozet (United States)",
  "Crucible (United States)",
  "Cruger (United States)",
  "Crum (United States)",
  "Crum Lynne (United States)",
  "Crump (United States)",
  "Crumpler (United States)",
  "Crystal (United States)",
  "Crystal City (United States)",
  "Crystal Falls (United States)",
  "Crystal Lake (United States)",
  "Crystal River (United States)",
  "Crystal Springs (United States)",
  "Cub Run (United States)",
  "Cuba (United States)",
  "Cuba City (United States)",
  "Cudahy (United States)",
  "Cuddebackville (United States)",
  "Cuddy Hill (United States)",
  "Cuero (United States)",
  "Culbertson (United States)",
  "Culdesac (United States)",
  "Cullen (United States)",
  "Culleoka (United States)",
  "Cullman (United States)",
  "Culloden (United States)",
  "Cullom (United States)",
  "Cullowhee (United States)",
  "Culpeper (United States)",
  "Culver (United States)",
  "Culver City (United States)",
  "Cumberland (United States)",
  "Cumberland Center (United States)",
  "Cumberland City (United States)",
  "Cumberland Foreside (United States)",
  "Cumberland Furnace (United States)",
  "Cumberland Gap (United States)",
  "Cumby (United States)",
  "Cumming (United States)",
  "Cummings (United States)",
  "Cummington (United States)",
  "Cunningham (United States)",
  "Cupertino (United States)",
  "Currie (United States)",
  "Currituck (United States)",
  "Curryville (United States)",
  "Curtice (United States)",
  "Curtis (United States)",
  "Curtis Bay (United States)",
  "Curtiss (United States)",
  "Curwensville (United States)",
  "Cushing (United States)",
  "Cusick (United States)",
  "Cusseta (United States)",
  "Custar (United States)",
  "Custer (United States)",
  "Custer City (United States)",
  "Cut Bank (United States)",
  "Cut Off (United States)",
  "Cutchogue (United States)",
  "Cuthbert (United States)",
  "Cutler (United States)",
  "Cuttingsville (United States)",
  "Cuyahoga Falls (United States)",
  "Cyclone (United States)",
  "Cygnet (United States)",
  "Cylinder (United States)",
  "Cynthiana (United States)",
  "Cypress (United States)",
  "Cypress Inn (United States)",
  "Cyril (United States)",
  "Dacoma (United States)",
  "Dacono (United States)",
  "Dacula (United States)",
  "Dade City (United States)",
  "Dadeville (United States)",
  "Daggett (United States)",
  "Dagsboro (United States)",
  "Dahlgren (United States)",
  "Dahlonega (United States)",
  "Daingerfield (United States)",
  "Daisetta (United States)",
  "Daisytown (United States)",
  "Dakota (United States)",
  "Dakota City (United States)",
  "Dale (United States)",
  "Daleville (United States)",
  "Dalhart (United States)",
  "Dallas (United States)",
  "Dallas Center (United States)",
  "Dallas City (United States)",
  "Dallastown (United States)",
  "Dalmatia (United States)",
  "Dalton (United States)",
  "Dalton City (United States)",
  "Daly City (United States)",
  "Dalzell (United States)",
  "Damariscotta (United States)",
  "Damascus (United States)",
  "Damon (United States)",
  "Dana (United States)",
  "Dana Point (United States)",
  "Danbury (United States)",
  "Danby (United States)",
  "Dandridge (United States)",
  "Dane (United States)",
  "Danese (United States)",
  "Danforth (United States)",
  "Dania Beach (United States)",
  "Daniels (United States)",
  "Danielson (United States)",
  "Danielsville (United States)",
  "Dannemora (United States)",
  "Dansville (United States)",
  "Dante (United States)",
  "Danube (United States)",
  "Danvers (United States)",
  "Danville (United States)",
  "Daphne (United States)",
  "Darby (United States)",
  "Dardanelle (United States)",
  "Darden (United States)",
  "Darien (United States)",
  "Darlington (United States)",
  "Darmstadt (United States)",
  "Darrington (United States)",
  "Darrouzett (United States)",
  "Darrow (United States)",
  "Darwin (United States)",
  "Dassel (United States)",
  "Dateland (United States)",
  "Datil (United States)",
  "Dauphin (United States)",
  "Dauphin Island (United States)",
  "Davenport (United States)",
  "David City (United States)",
  "Davidson (United States)",
  "Davidsonville (United States)",
  "Davis (United States)",
  "Davis City (United States)",
  "Davis Junction (United States)",
  "Davisboro (United States)",
  "Davisburg (United States)",
  "Davison (United States)",
  "Daviston (United States)",
  "Davisville (United States)",
  "Davy (United States)",
  "Dawes (United States)",
  "Dawn (United States)",
  "Dawson (United States)",
  "Dawson Springs (United States)",
  "Dawsonville (United States)",
  "Days Creek (United States)",
  "Dayton (United States)",
  "Daytona Beach (United States)",
  "Dayville (United States)",
  "Dazey (United States)",
  "De Beque (United States)",
  "De Borgia (United States)",
  "De Forest (United States)",
  "De Graff (United States)",
  "De Kalb (United States)",
  "De Kalb Junction (United States)",
  "De Leon (United States)",
  "De Leon Springs (United States)",
  "De Queen (United States)",
  "De Smet (United States)",
  "De Soto (United States)",
  "De Valls Bluff (United States)",
  "De Witt (United States)",
  "Deadwood (United States)",
  "Deal (United States)",
  "Deal Island (United States)",
  "Deale (United States)",
  "Deansboro (United States)",
  "Deanville (United States)",
  "Dearborn (United States)",
  "Dearborn Heights (United States)",
  "Dearing (United States)",
  "Death Valley Junction (United States)",
  "Deatsville (United States)",
  "Deaver (United States)",
  "DeBary (United States)",
  "Debord (United States)",
  "Decatur (United States)",
  "Decaturville (United States)",
  "Decherd (United States)",
  "Decker (United States)",
  "Deckerville (United States)",
  "Declo (United States)",
  "Decorah (United States)",
  "Dedham (United States)",
  "Deep River (United States)",
  "Deep Run (United States)",
  "Deep Water (United States)",
  "Deepwater (United States)",
  "Deer (United States)",
  "Deer Island (United States)",
  "Deer Isle (United States)",
  "Deer Lodge (United States)",
  "Deer Park (United States)",
  "Deer River (United States)",
  "Deer Trail (United States)",
  "Deerfield (United States)",
  "Deerfield Beach (United States)",
  "Deering (United States)",
  "Deerwood (United States)",
  "Defiance (United States)",
  "Deford (United States)",
  "DeFuniak Springs (United States)",
  "DeKalb (United States)",
  "Del Mar (United States)",
  "Del Norte (United States)",
  "Del Rey (United States)",
  "Del Rio (United States)",
  "Del Valle (United States)",
  "Delafield (United States)",
  "DeLand (United States)",
  "Delano (United States)",
  "Delanson (United States)",
  "Delaplaine (United States)",
  "Delavan (United States)",
  "Delaware (United States)",
  "Delaware City (United States)",
  "Delbarton (United States)",
  "Delcambre (United States)",
  "Delco (United States)",
  "Delevan (United States)",
  "Delhi (United States)",
  "Delight (United States)",
  "Dell (United States)",
  "Dell Rapids (United States)",
  "Delmar (United States)",
  "Delmont (United States)",
  "Deloit (United States)",
  "Delphi (United States)",
  "Delphos (United States)",
  "Delray (United States)",
  "Delray Beach (United States)",
  "Delta (United States)",
  "Deltana (United States)",
  "Deltaville (United States)",
  "Delton (United States)",
  "Deltona (United States)",
  "Dema (United States)",
  "Demarest (United States)",
  "Deming (United States)",
  "Demopolis (United States)",
  "Demorest (United States)",
  "DeMossville (United States)",
  "DeMotte (United States)",
  "Denair (United States)",
  "Dendron (United States)",
  "Denham Springs (United States)",
  "Denison (United States)",
  "Denmark (United States)",
  "Dennis (United States)",
  "Dennis Port (United States)",
  "Dennison (United States)",
  "Denniston (United States)",
  "Dennysville (United States)",
  "Dent (United States)",
  "Denton (United States)",
  "Denver (United States)",
  "Denver City (United States)",
  "Denville (United States)",
  "Depauw (United States)",
  "Depere (United States)",
  "Depew (United States)",
  "Depoe Bay (United States)",
  "Deposit (United States)",
  "Depue (United States)",
  "Deputy (United States)",
  "DeQuincy (United States)",
  "Derby (United States)",
  "Derby Center (United States)",
  "Derby Line (United States)",
  "DeRidder (United States)",
  "Derma (United States)",
  "Dermott (United States)",
  "Derry (United States)",
  "DeRuyter (United States)",
  "Derwood (United States)",
  "Des Allemands (United States)",
  "Des Arc (United States)",
  "Des Moines (United States)",
  "Des Plaines (United States)",
  "Descanso (United States)",
  "Desert Hot Springs (United States)",
  "Deshler (United States)",
  "DeSoto (United States)",
  "Destin (United States)",
  "Destrehan (United States)",
  "Detroit (United States)",
  "Detroit Lakes (United States)",
  "Devens (United States)",
  "Deville (United States)",
  "Devils Lake (United States)",
  "Devine (United States)",
  "Devon (United States)",
  "Dewar (United States)",
  "Dewey (United States)",
  "DeWitt (United States)",
  "Dewy Rose (United States)",
  "Dexter (United States)",
  "Dexter City (United States)",
  "Diablo (United States)",
  "Diagonal (United States)",
  "Diamond (United States)",
  "Diamond Bar (United States)",
  "Diamond Point (United States)",
  "Diamond Springs (United States)",
  "Diamondhead (United States)",
  "Diamondville (United States)",
  "Diana (United States)",
  "Diboll (United States)",
  "Dickerson (United States)",
  "Dickeyville (United States)",
  "Dickinson (United States)",
  "Dickinson Center (United States)",
  "Dickson (United States)",
  "Dickson City (United States)",
  "Dierks (United States)",
  "Dieterich (United States)",
  "Dietrich (United States)",
  "Diggs (United States)",
  "Dighton (United States)",
  "Dill City (United States)",
  "Dillard (United States)",
  "Diller (United States)",
  "Dilley (United States)",
  "Dillingham (United States)",
  "Dillon (United States)",
  "Dillon Beach (United States)",
  "Dillonvale (United States)",
  "Dillsboro (United States)",
  "Dillsburg (United States)",
  "Dillwyn (United States)",
  "Dime Box (United States)",
  "Dimmitt (United States)",
  "Dimock (United States)",
  "Dimondale (United States)",
  "Dingess (United States)",
  "Dingmans Ferry (United States)",
  "Dinuba (United States)",
  "Dinwiddie (United States)",
  "Discovery Bay (United States)",
  "Disputanta (United States)",
  "Distant (United States)",
  "District Heights (United States)",
  "Dittmer (United States)",
  "Divernon (United States)",
  "Divide (United States)",
  "Dividing Creek (United States)",
  "Dix (United States)",
  "Dixfield (United States)",
  "Dixie (United States)",
  "Dixmont (United States)",
  "Dixon (United States)",
  "Dixon Springs (United States)",
  "Dixons Mills (United States)",
  "Dixonville (United States)",
  "Dobbs Ferry (United States)",
  "Dobson (United States)",
  "Docena (United States)",
  "Dodd City (United States)",
  "Doddridge (United States)",
  "Dodge (United States)",
  "Dodge Center (United States)",
  "Dodge City (United States)",
  "Dodgeville (United States)",
  "Dodgewood (United States)",
  "Doerun (United States)",
  "Dolan Springs (United States)",
  "Doland (United States)",
  "Dolgeville (United States)",
  "Dollar Bay (United States)",
  "Dolliver (United States)",
  "Dolomite (United States)",
  "Dolores (United States)",
  "Dolphin (United States)",
  "Dolton (United States)",
  "Donald (United States)",
  "Donalds (United States)",
  "Donaldson (United States)",
  "Donaldsonville (United States)",
  "Donalsonville (United States)",
  "Donegal (United States)",
  "Dongola (United States)",
  "Donie (United States)",
  "Doniphan (United States)",
  "Donna (United States)",
  "Donnellson (United States)",
  "Donnelly (United States)",
  "Donnelsville (United States)",
  "Donner (United States)",
  "Donora (United States)",
  "Donovan (United States)",
  "Doon (United States)",
  "Dora (United States)",
  "Dorchester (United States)",
  "Dorena (United States)",
  "Dornsife (United States)",
  "Dorothy (United States)",
  "Dorr (United States)",
  "Dorrance (United States)",
  "Dorris (United States)",
  "Dorset (United States)",
  "Dorsey (United States)",
  "Dos Palos (United States)",
  "Doss (United States)",
  "Doswell (United States)",
  "Dothan (United States)",
  "Double Springs (United States)",
  "Dougherty (United States)",
  "Douglas (United States)",
  "Douglas City (United States)",
  "Douglass (United States)",
  "Douglassville (United States)",
  "Douglasville (United States)",
  "Dousman (United States)",
  "Dover (United States)",
  "Dover Plains (United States)",
  "Dover-Foxcroft (United States)",
  "Dow (United States)",
  "Dowagiac (United States)",
  "Dowell (United States)",
  "Dowelltown (United States)",
  "Dowling (United States)",
  "Downers Grove (United States)",
  "Downey (United States)",
  "Downieville (United States)",
  "Downingtown (United States)",
  "Downs (United States)",
  "Downsville (United States)",
  "Dows (United States)",
  "Doyle (United States)",
  "Doylestown (United States)",
  "Doyline (United States)",
  "Dozier (United States)",
  "Dracut (United States)",
  "Dragoon (United States)",
  "Drain (United States)",
  "Drakes Branch (United States)",
  "Drakesboro (United States)",
  "Draper (United States)",
  "Drasco (United States)",
  "Dravosburg (United States)",
  "Drayden (United States)",
  "Dresden (United States)",
  "Dresher (United States)",
  "Dresser (United States)",
  "Drewryville (United States)",
  "Drexel (United States)",
  "Drexel Hill (United States)",
  "Drift (United States)",
  "Drifting (United States)",
  "Driftwood (United States)",
  "Driggs (United States)",
  "Dripping Springs (United States)",
  "Driscoll (United States)",
  "Drummond (United States)",
  "Drummonds (United States)",
  "Drumore (United States)",
  "Drumright (United States)",
  "Drums (United States)",
  "Dry Branch (United States)",
  "Dry Creek (United States)",
  "Dry Fork (United States)",
  "Dry Prong (United States)",
  "Dry Ridge (United States)",
  "Dry Run (United States)",
  "Drybranch (United States)",
  "Dryden (United States)",
  "Dryfork (United States)",
  "Du Bois (United States)",
  "Du Quoin (United States)",
  "Duanesburg (United States)",
  "Duarte (United States)",
  "Dubach (United States)",
  "Dublin (United States)",
  "DuBois (United States)",
  "Dubois (United States)",
  "Dubuque (United States)",
  "Duchesne (United States)",
  "Duck (United States)",
  "Duck Creek Village (United States)",
  "Duck Hill (United States)",
  "Ducktown (United States)",
  "Dudley (United States)",
  "Due West (United States)",
  "Duff (United States)",
  "Duffield (United States)",
  "Dufur (United States)",
  "Dugger (United States)",
  "Dugway (United States)",
  "Duke (United States)",
  "Duke Center (United States)",
  "Dukedom (United States)",
  "Dulac (United States)",
  "Dulce (United States)",
  "Dulles (United States)",
  "Duluth (United States)",
  "Dulzura (United States)",
  "Dumas (United States)",
  "Dumfries (United States)",
  "Dumont (United States)",
  "Dunbar (United States)",
  "Dunbarton Center (United States)",
  "Duncan (United States)",
  "Duncannon (United States)",
  "Duncans Mills (United States)",
  "Duncansville (United States)",
  "Duncanville (United States)",
  "Duncombe (United States)",
  "Dundalk (United States)",
  "Dundas (United States)",
  "Dundee (United States)",
  "Dunedin (United States)",
  "Dunellen (United States)",
  "Dungannon (United States)",
  "Dunkerton (United States)",
  "Dunkirk (United States)",
  "Dunlap (United States)",
  "Dunlow (United States)",
  "Dunmor (United States)",
  "Dunmore (United States)",
  "Dunn (United States)",
  "Dunn Loring (United States)",
  "Dunnell (United States)",
  "Dunnellon (United States)",
  "Dunnsville (United States)",
  "Dunnville (United States)",
  "Dunseith (United States)",
  "Dunsmuir (United States)",
  "Dunstable (United States)",
  "Duplessis (United States)",
  "Dupo (United States)",
  "DuPont (United States)",
  "Dupont (United States)",
  "Dupuyer (United States)",
  "Duquesne (United States)",
  "Durand (United States)",
  "Durango (United States)",
  "Durant (United States)",
  "Durbin (United States)",
  "Durham (United States)",
  "Durhamville (United States)",
  "Duryea (United States)",
  "Dushore (United States)",
  "Duson (United States)",
  "Dustin (United States)",
  "Dutch Flat (United States)",
  "Dutch Harbor (United States)",
  "Dutch John (United States)",
  "Dutton (United States)",
  "Duvall (United States)",
  "Duxbury (United States)",
  "Dwale (United States)",
  "Dwight (United States)",
  "Dyer (United States)",
  "Dyersburg (United States)",
  "Dyersville (United States)",
  "Dysart (United States)",
  "Eads (United States)",
  "Eagar (United States)",
  "Eagle (United States)",
  "Eagle Bend (United States)",
  "Eagle Bridge (United States)",
  "Eagle Butte (United States)",
  "Eagle Creek (United States)",
  "Eagle Grove (United States)",
  "Eagle Lake (United States)",
  "Eagle Mountain (United States)",
  "Eagle Nest (United States)",
  "Eagle Pass (United States)",
  "Eagle Point (United States)",
  "Eagle River (United States)",
  "Eagle Rock (United States)",
  "Eagle Springs (United States)",
  "Eagleville (United States)",
  "Earl Park (United States)",
  "Earle (United States)",
  "Earleville (United States)",
  "Earlham (United States)",
  "Earlimart (United States)",
  "Earling (United States)",
  "Earlsboro (United States)",
  "Earlton (United States)",
  "Earlville (United States)",
  "Early (United States)",
  "Earlysville (United States)",
  "Earth (United States)",
  "Earth City (United States)",
  "Easley (United States)",
  "East Alton (United States)",
  "East Amherst (United States)",
  "East Andover (United States)",
  "East Aurora (United States)",
  "East Bank (United States)",
  "East Barre (United States)",
  "East Bend (United States)",
  "East Berkshire (United States)",
  "East Berlin (United States)",
  "East Bernard (United States)",
  "East Berne (United States)",
  "East Bernstadt (United States)",
  "East Blue Hill (United States)",
  "East Boothbay (United States)",
  "East Brady (United States)",
  "East Branch (United States)",
  "East Bridgewater (United States)",
  "East Brookfield (United States)",
  "East Brunswick (United States)",
  "East Burke (United States)",
  "East Butler (United States)",
  "East Calais (United States)",
  "East Canaan (United States)",
  "East Candia (United States)",
  "East Carbon City (United States)",
  "East Carondelet (United States)",
  "East Chatham (United States)",
  "East Chicago (United States)",
  "East China Township (United States)",
  "East Claridon (United States)",
  "East Corinth (United States)",
  "East Dennis (United States)",
  "East Dover (United States)",
  "East Dublin (United States)",
  "East Dubuque (United States)",
  "East Durham (United States)",
  "East Earl (United States)",
  "East Elmhurst (United States)",
  "East Fairfield (United States)",
  "East Fairview (United States)",
  "East Falmouth (United States)",
  "East Flat Rock (United States)",
  "East Freedom (United States)",
  "East Freetown (United States)",
  "East Galesburg (United States)",
  "East Granby (United States)",
  "East Grand Forks (United States)",
  "East Greenbush (United States)",
  "East Greenville (United States)",
  "East Greenwich (United States)",
  "East Haddam (United States)",
  "East Hampstead (United States)",
  "East Hampton (United States)",
  "East Hanover (United States)",
  "East Hardwick (United States)",
  "East Hartford (United States)",
  "East Hartland (United States)",
  "East Haven (United States)",
  "East Helena (United States)",
  "East Irvine (United States)",
  "East Islip (United States)",
  "East Jordan (United States)",
  "East Kingston (United States)",
  "East Lake (United States)",
  "East Lansing (United States)",
  "East Leroy (United States)",
  "East Liberty (United States)",
  "East Liverpool (United States)",
  "East Longmeadow (United States)",
  "East Lynn (United States)",
  "East Machias (United States)",
  "East Marion (United States)",
  "East McKeesport (United States)",
  "East Meadow (United States)",
  "East Meredith (United States)",
  "East Middlebury (United States)",
  "East Millinocket (United States)",
  "East Millsboro (United States)",
  "East Moline (United States)",
  "East Montpelier (United States)",
  "East Moriches (United States)",
  "East New Market (United States)",
  "East Northport (United States)",
  "East Norwich (United States)",
  "East Orange (United States)",
  "East Orleans (United States)",
  "East Otis (United States)",
  "East Palatka (United States)",
  "East Palestine (United States)",
  "East Peoria (United States)",
  "East Petersburg (United States)",
  "East Pharsalia (United States)",
  "East Pittsburgh (United States)",
  "East Point (United States)",
  "East Prairie (United States)",
  "East Prospect (United States)",
  "East Providence (United States)",
  "East Quogue (United States)",
  "East Randolph (United States)",
  "East Rochester (United States)",
  "East Rockaway (United States)",
  "East Rutherford (United States)",
  "East Saint Louis (United States)",
  "East Sandwich (United States)",
  "East Setauket (United States)",
  "East Sparta (United States)",
  "East Stone Gap (United States)",
  "East Stoneham (United States)",
  "East Stroudsburg (United States)",
  "East Syracuse (United States)",
  "East Taunton (United States)",
  "East Tawas (United States)",
  "East Thetford (United States)",
  "East Troy (United States)",
  "East Wakefield (United States)",
  "East Walpole (United States)",
  "East Wareham (United States)",
  "East Waterboro (United States)",
  "East Wenatchee (United States)",
  "East Weymouth (United States)",
  "East Windsor Hill (United States)",
  "Eastaboga (United States)",
  "Eastanollee (United States)",
  "Eastchester (United States)",
  "Eastern (United States)",
  "Eastford (United States)",
  "Eastham (United States)",
  "Easthampton (United States)",
  "Eastlake (United States)",
  "Eastland (United States)",
  "Eastman (United States)",
  "Easton (United States)",
  "Eastover (United States)",
  "Eastpoint (United States)",
  "Eastpointe (United States)",
  "Eastport (United States)",
  "Eastsound (United States)",
  "Eastview (United States)",
  "Eastville (United States)",
  "Eaton (United States)",
  "Eaton Rapids (United States)",
  "Eatonton (United States)",
  "Eatontown (United States)",
  "Eatonville (United States)",
  "Eau Claire (United States)",
  "Eau Galle (United States)",
  "Ebensburg (United States)",
  "Echo (United States)",
  "Eckert (United States)",
  "Eckerty (United States)",
  "Eclectic (United States)",
  "Economy (United States)",
  "Ecorse (United States)",
  "Ecru (United States)",
  "Ector (United States)",
  "Edcouch (United States)",
  "Eddington (United States)",
  "Eddy (United States)",
  "Eddyville (United States)",
  "Edelstein (United States)",
  "Eden (United States)",
  "Eden Prairie (United States)",
  "Eden Valley (United States)",
  "Edenton (United States)",
  "Edgar (United States)",
  "Edgard (United States)",
  "Edgartown (United States)",
  "Edgecomb (United States)",
  "Edgefield (United States)",
  "Edgeley (United States)",
  "Edgemont (United States)",
  "Edgemoor (United States)",
  "Edgerton (United States)",
  "Edgewater (United States)",
  "Edgewood (United States)",
  "Edina (United States)",
  "Edinboro (United States)",
  "Edinburg (United States)",
  "Edinburgh (United States)",
  "Edison (United States)",
  "Edisto Island (United States)",
  "Edmeston (United States)",
  "Edmond (United States)",
  "Edmonds (United States)",
  "Edmonton (United States)",
  "Edmore (United States)",
  "Edna (United States)",
  "Edneyville (United States)",
  "Edon (United States)",
  "Edson (United States)",
  "Edwall (United States)",
  "Edwards (United States)",
  "Edwards Air Force Base (United States)",
  "Edwardsburg (United States)",
  "Edwardsport (United States)",
  "Edwardsville (United States)",
  "Effie (United States)",
  "Effingham (United States)",
  "Effort (United States)",
  "Efland (United States)",
  "Egan (United States)",
  "Egg Harbor (United States)",
  "Egg Harbor City (United States)",
  "Eglin (United States)",
  "Eglon (United States)",
  "Egypt (United States)",
  "Ehrenberg (United States)",
  "Eielson Air Force Base (United States)",
  "Eight Mile (United States)",
  "Eighty Four (United States)",
  "Ekalaka (United States)",
  "Ekron (United States)",
  "El Cajon (United States)",
  "El Campo (United States)",
  "El Centro (United States)",
  "El Cerrito (United States)",
  "El Dorado (United States)",
  "El Dorado Hills (United States)",
  "El Dorado Springs (United States)",
  "El Granada (United States)",
  "El Macero (United States)",
  "El Mirage (United States)",
  "El Monte (United States)",
  "El Paso (United States)",
  "El Prado (United States)",
  "El Reno (United States)",
  "El Segundo (United States)",
  "El Sobrante (United States)",
  "Elba (United States)",
  "Elberon (United States)",
  "Elbert (United States)",
  "Elberta (United States)",
  "Elberton (United States)",
  "Elbow Lake (United States)",
  "Elbridge (United States)",
  "Elburn (United States)",
  "Elcho (United States)",
  "Elco (United States)",
  "Elderton (United States)",
  "Eldon (United States)",
  "Eldora (United States)",
  "Eldorado (United States)",
  "Eldred (United States)",
  "Eldridge (United States)",
  "Eleanor (United States)",
  "Electra (United States)",
  "Elephant Butte (United States)",
  "Eleva (United States)",
  "Elfrida (United States)",
  "Elgin (United States)",
  "Eliot (United States)",
  "Elizabeth (United States)",
  "Elizabeth City (United States)",
  "Elizabethton (United States)",
  "Elizabethtown (United States)",
  "Elizabethville (United States)",
  "Elizaville (United States)",
  "Elk (United States)",
  "Elk City (United States)",
  "Elk Falls (United States)",
  "Elk Garden (United States)",
  "Elk Grove (United States)",
  "Elk Grove Village (United States)",
  "Elk Horn (United States)",
  "Elk Mills (United States)",
  "Elk Mound (United States)",
  "Elk Park (United States)",
  "Elk Point (United States)",
  "Elk Rapids (United States)",
  "Elk River (United States)",
  "Elkader (United States)",
  "Elkfork (United States)",
  "Elkhart (United States)",
  "Elkhart Lake (United States)",
  "Elkhorn (United States)",
  "Elkhorn City (United States)",
  "Elkin (United States)",
  "Elkins (United States)",
  "Elkins Park (United States)",
  "Elkland (United States)",
  "Elkmont (United States)",
  "Elko (United States)",
  "Elkridge (United States)",
  "Elkton (United States)",
  "Elkview (United States)",
  "Elkville (United States)",
  "Elkwood (United States)",
  "Ellabell (United States)",
  "Ellaville (United States)",
  "Ellenboro (United States)",
  "Ellenburg Center (United States)",
  "Ellenburg Depot (United States)",
  "Ellendale (United States)",
  "Ellensburg (United States)",
  "Ellenton (United States)",
  "Ellenville (United States)",
  "Ellenwood (United States)",
  "Ellerbe (United States)",
  "Ellerslie (United States)",
  "Ellettsville (United States)",
  "Ellicott City (United States)",
  "Ellicottville (United States)",
  "Ellijay (United States)",
  "Ellington (United States)",
  "Ellinwood (United States)",
  "Elliott (United States)",
  "Elliottsburg (United States)",
  "Ellis (United States)",
  "Ellis Grove (United States)",
  "Elliston (United States)",
  "Ellisville (United States)",
  "Elloree (United States)",
  "Ellsinore (United States)",
  "Ellsworth (United States)",
  "Ellwood City (United States)",
  "Elm City (United States)",
  "Elm Creek (United States)",
  "Elm Grove (United States)",
  "Elma (United States)",
  "Elmdale (United States)",
  "Elmendorf (United States)",
  "Elmer (United States)",
  "Elmer City (United States)",
  "Elmhurst (United States)",
  "Elmira (United States)",
  "Elmo (United States)",
  "Elmont (United States)",
  "Elmora (United States)",
  "Elmore (United States)",
  "Elmore City (United States)",
  "Elmsford (United States)",
  "Elmwood (United States)",
  "Elmwood Park (United States)",
  "Elnora (United States)",
  "Elon (United States)",
  "Elora (United States)",
  "Eloy (United States)",
  "Elroy (United States)",
  "Elsa (United States)",
  "Elsah (United States)",
  "Elsberry (United States)",
  "Elsie (United States)",
  "Elsinore (United States)",
  "Elton (United States)",
  "Eltopia (United States)",
  "Elvaston (United States)",
  "Elverson (United States)",
  "Elverta (United States)",
  "Elwell (United States)",
  "Elwood (United States)",
  "Ely (United States)",
  "Elyria (United States)",
  "Elysburg (United States)",
  "Elysian (United States)",
  "Elysian Fields (United States)",
  "Embarrass (United States)",
  "Embudo (United States)",
  "Emden (United States)",
  "Emerado (United States)",
  "Emerald Isle (United States)",
  "Emerson (United States)",
  "Emery (United States)",
  "Emeryville (United States)",
  "Emigrant (United States)",
  "Emily (United States)",
  "Eminence (United States)",
  "Emlenton (United States)",
  "Emmaus (United States)",
  "Emmet (United States)",
  "Emmetsburg (United States)",
  "Emmett (United States)",
  "Emmitsburg (United States)",
  "Emmons (United States)",
  "Emory (United States)",
  "Empire (United States)",
  "Emporia (United States)",
  "Emporium (United States)",
  "Encampment (United States)",
  "Encinitas (United States)",
  "Encino (United States)",
  "Endeavor (United States)",
  "Enderlin (United States)",
  "Endicott (United States)",
  "Energy (United States)",
  "Enfield (United States)",
  "Engelhard (United States)",
  "England (United States)",
  "Englewood (United States)",
  "Englewood Cliffs (United States)",
  "Englishtown (United States)",
  "Enid (United States)",
  "Enigma (United States)",
  "Enka (United States)",
  "Ennice (United States)",
  "Ennis (United States)",
  "Enola (United States)",
  "Enon (United States)",
  "Enoree (United States)",
  "Enosburg Falls (United States)",
  "Enterprise (United States)",
  "Entiat (United States)",
  "Enumclaw (United States)",
  "Eolia (United States)",
  "Epes (United States)",
  "Ephraim (United States)",
  "Ephrata (United States)",
  "Epping (United States)",
  "Epps (United States)",
  "Epsom (United States)",
  "Epworth (United States)",
  "Equality (United States)",
  "Equinunk (United States)",
  "Erath (United States)",
  "Erbacon (United States)",
  "Erhard (United States)",
  "Erick (United States)",
  "Erie (United States)",
  "Erieville (United States)",
  "Erin (United States)",
  "Erlanger (United States)",
  "Eros (United States)",
  "Errol (United States)",
  "Erskine (United States)",
  "Erving (United States)",
  "Ervings Location (United States)",
  "Erwin (United States)",
  "Erwinna (United States)",
  "Erwinville (United States)",
  "Esbon (United States)",
  "Escalante (United States)",
  "Escalon (United States)",
  "Escanaba (United States)",
  "Escondido (United States)",
  "Esko (United States)",
  "Eskridge (United States)",
  "Esmond (United States)",
  "Esmont (United States)",
  "Espanola (United States)",
  "Esparto (United States)",
  "Esperance (United States)",
  "Essex (United States)",
  "Essex Fells (United States)",
  "Essex Junction (United States)",
  "Essexville (United States)",
  "Essie (United States)",
  "Essington (United States)",
  "Estacada (United States)",
  "Estancia (United States)",
  "Estell Manor (United States)",
  "Estelline (United States)",
  "Estero (United States)",
  "Estes Park (United States)",
  "Estherville (United States)",
  "Estherwood (United States)",
  "Estill (United States)",
  "Estill Springs (United States)",
  "Estillfork (United States)",
  "Ethel (United States)",
  "Ethelsville (United States)",
  "Ethridge (United States)",
  "Etna (United States)",
  "Etna Green (United States)",
  "Etoile (United States)",
  "Eton (United States)",
  "Etowah (United States)",
  "Etta (United States)",
  "Ettrick (United States)",
  "Eubank (United States)",
  "Eucha (United States)",
  "Euclid (United States)",
  "Eudora (United States)",
  "Eufaula (United States)",
  "Eugene (United States)",
  "Euless (United States)",
  "Eunice (United States)",
  "Eupora (United States)",
  "Eureka (United States)",
  "Eureka Springs (United States)",
  "Eustace (United States)",
  "Eustis (United States)",
  "Eutaw (United States)",
  "Eutawville (United States)",
  "Eva (United States)",
  "Evangeline (United States)",
  "Evans (United States)",
  "Evans City (United States)",
  "Evans Mills (United States)",
  "Evansdale (United States)",
  "Evanston (United States)",
  "Evansville (United States)",
  "Evant (United States)",
  "Evart (United States)",
  "Evarts (United States)",
  "Eveleth (United States)",
  "Evensville (United States)",
  "Everest (United States)",
  "Everett (United States)",
  "Everetts (United States)",
  "Everglades City (United States)",
  "Evergreen (United States)",
  "Evergreen Park (United States)",
  "Everly (United States)",
  "Everson (United States)",
  "Everton (United States)",
  "Evington (United States)",
  "Ewan (United States)",
  "Ewen (United States)",
  "Ewing (United States)",
  "Excel (United States)",
  "Excello (United States)",
  "Excelsior (United States)",
  "Excelsior Springs (United States)",
  "Exeland (United States)",
  "Exeter (United States)",
  "Exira (United States)",
  "Exline (United States)",
  "Exmore (United States)",
  "Experiment (United States)",
  "Export (United States)",
  "Exton (United States)",
  "Eyota (United States)",
  "Ezel (United States)",
  "Fabens (United States)",
  "Faber (United States)",
  "Fabius (United States)",
  "Fackler (United States)",
  "Factoryville (United States)",
  "Fair Bluff (United States)",
  "Fair Grove (United States)",
  "Fair Haven (United States)",
  "Fair Lawn (United States)",
  "Fair Oaks (United States)",
  "Fair Play (United States)",
  "Fairacres (United States)",
  "Fairbank (United States)",
  "Fairbanks (United States)",
  "Fairbanks North Star Borough (United States)",
  "Fairborn (United States)",
  "Fairburn (United States)",
  "Fairbury (United States)",
  "Fairchance (United States)",
  "Fairchild (United States)",
  "Fairchild Air Force Base (United States)",
  "Fairdale (United States)",
  "Fairdealing (United States)",
  "Fairfax (United States)",
  "Fairfax Station (United States)",
  "Fairfield (United States)",
  "Fairfield Bay (United States)",
  "Fairgrove (United States)",
  "Fairhaven (United States)",
  "Fairhope (United States)",
  "Fairland (United States)",
  "Fairlee (United States)",
  "Fairless Hills (United States)",
  "Fairmont (United States)",
  "Fairmont City (United States)",
  "Fairmount (United States)",
  "Fairplay (United States)",
  "Fairport (United States)",
  "Fairton (United States)",
  "Fairview (United States)",
  "Fairview Heights (United States)",
  "Fairview Village (United States)",
  "Faith (United States)",
  "Falcon (United States)",
  "Falconer (United States)",
  "Falfurrias (United States)",
  "Falkland (United States)",
  "Falkner (United States)",
  "Falkville (United States)",
  "Fall Branch (United States)",
  "Fall City (United States)",
  "Fall Creek (United States)",
  "Fall River (United States)",
  "Fall River Mills (United States)",
  "Fallbrook (United States)",
  "Falling Rock (United States)",
  "Falling Waters (United States)",
  "Fallon (United States)",
  "Falls (United States)",
  "Falls Church (United States)",
  "Falls City (United States)",
  "Falls Creek (United States)",
  "Falls Mills (United States)",
  "Falls of Rough (United States)",
  "Falls Village (United States)",
  "Fallsburg (United States)",
  "Fallston (United States)",
  "Falmouth (United States)",
  "Fancy Farm (United States)",
  "Fannettsburg (United States)",
  "Fanwood (United States)",
  "Far Hills (United States)",
  "Far Rockaway (United States)",
  "Farber (United States)",
  "Fargo (United States)",
  "Faribault (United States)",
  "Farina (United States)",
  "Farley (United States)",
  "Farlington (United States)",
  "Farmdale (United States)",
  "Farmer City (United States)",
  "Farmersburg (United States)",
  "Farmersville (United States)",
  "Farmerville (United States)",
  "Farmingdale (United States)",
  "Farmington (United States)",
  "Farmington Hills (United States)",
  "Farmingville (United States)",
  "Farmland (United States)",
  "Farmville (United States)",
  "Farnham (United States)",
  "Farnhamville (United States)",
  "Farragut (United States)",
  "Farrell (United States)",
  "Farson (United States)",
  "Farwell (United States)",
  "Faulkner (United States)",
  "Faulkton (United States)",
  "Fawn Grove (United States)",
  "Fawnskin (United States)",
  "Faxon (United States)",
  "Fayette (United States)",
  "Fayette City (United States)",
  "Fayetteville (United States)",
  "Fayville (United States)",
  "Feasterville-Trevose (United States)",
  "Federal Way (United States)",
  "Federalsburg (United States)",
  "Feeding Hills (United States)",
  "Felda (United States)",
  "Felicity (United States)",
  "Fellsmere (United States)",
  "Felton (United States)",
  "Fenelton (United States)",
  "Fennimore (United States)",
  "Fennville (United States)",
  "Fenton (United States)",
  "Fentress (United States)",
  "Fenwick (United States)",
  "Fenwick Island (United States)",
  "Ferdinand (United States)",
  "Fergus Falls (United States)",
  "Ferguson (United States)",
  "Fernandina Beach (United States)",
  "Ferndale (United States)",
  "Fernley (United States)",
  "Fernwood (United States)",
  "Ferriday (United States)",
  "Ferris (United States)",
  "Ferrisburgh (United States)",
  "Ferron (United States)",
  "Ferrum (United States)",
  "Ferrysburg (United States)",
  "Fertile (United States)",
  "Fessenden (United States)",
  "Festus (United States)",
  "Feura Bush (United States)",
  "Fiddletown (United States)",
  "Fidelity (United States)",
  "Fieldale (United States)",
  "Fielding (United States)",
  "Fieldon (United States)",
  "Fife Lake (United States)",
  "Fifty Lakes (United States)",
  "Filer (United States)",
  "Filley (United States)",
  "Fillmore (United States)",
  "Fincastle (United States)",
  "Finchville (United States)",
  "Findlay (United States)",
  "Finger (United States)",
  "Fingerville (United States)",
  "Finksburg (United States)",
  "Finlayson (United States)",
  "Finley (United States)",
  "Finleyville (United States)",
  "Firebaugh (United States)",
  "Firestone (United States)",
  "Firth (United States)",
  "Fischer (United States)",
  "Fish Camp (United States)",
  "Fish Creek (United States)",
  "Fish Haven (United States)",
  "Fishel (United States)",
  "Fisher (United States)",
  "Fishers (United States)",
  "Fishers Island (United States)",
  "Fishersville (United States)",
  "Fisherville (United States)",
  "Fishkill (United States)",
  "Fishtail (United States)",
  "Fisk (United States)",
  "Fiskdale (United States)",
  "Fitchburg (United States)",
  "Fitzgerald (United States)",
  "Fitzhugh (United States)",
  "Fitzpatrick (United States)",
  "Fitzwilliam (United States)",
  "Five Points (United States)",
  "Flag Pond (United States)",
  "Flagler (United States)",
  "Flagler Beach (United States)",
  "Flagstaff (United States)",
  "Flanagan (United States)",
  "Flanders (United States)",
  "Flandreau (United States)",
  "Flasher (United States)",
  "Flat Lick (United States)",
  "Flat Rock (United States)",
  "Flatgap (United States)",
  "Flatwoods (United States)",
  "Flaxton (United States)",
  "Flaxville (United States)",
  "Fleetwood (United States)",
  "Fleischmanns (United States)",
  "Fleming (United States)",
  "Flemingsburg (United States)",
  "Flemington (United States)",
  "Flensburg (United States)",
  "Fletcher (United States)",
  "Flint (United States)",
  "Flinton (United States)",
  "Flintstone (United States)",
  "Flintville (United States)",
  "Flippin (United States)",
  "Flomaton (United States)",
  "Floodwood (United States)",
  "Flora (United States)",
  "Flora Vista (United States)",
  "Florahome (United States)",
  "Floral City (United States)",
  "Floral Park (United States)",
  "Florala (United States)",
  "Florence (United States)",
  "Floresville (United States)",
  "Florham Park (United States)",
  "Florida (United States)",
  "Florien (United States)",
  "Florissant (United States)",
  "Flossmoor (United States)",
  "Flourtown (United States)",
  "Flovilla (United States)",
  "Flower Mound (United States)",
  "Floweree (United States)",
  "Flowery Branch (United States)",
  "Flowood (United States)",
  "Floyd (United States)",
  "Floydada (United States)",
  "Floyds Knobs (United States)",
  "Fluker (United States)",
  "Flushing (United States)",
  "Fly Creek (United States)",
  "Fogelsville (United States)",
  "Folcroft (United States)",
  "Foley (United States)",
  "Folkston (United States)",
  "Follansbee (United States)",
  "Follett (United States)",
  "Folly Beach (United States)",
  "Folsom (United States)",
  "Fombell (United States)",
  "Fond du Lac (United States)",
  "Fonda (United States)",
  "Fontana (United States)",
  "Fontanelle (United States)",
  "Foothill Ranch (United States)",
  "Forbes (United States)",
  "Forbes Road (United States)",
  "Ford (United States)",
  "Ford City (United States)",
  "Fordland (United States)",
  "Fordoche (United States)",
  "Fords (United States)",
  "Fordsville (United States)",
  "Fordville (United States)",
  "Fordyce (United States)",
  "Foreman (United States)",
  "Forest (United States)",
  "Forest City (United States)",
  "Forest Falls (United States)",
  "Forest Grove (United States)",
  "Forest Hill (United States)",
  "Forest Hills (United States)",
  "Forest Home (United States)",
  "Forest Junction (United States)",
  "Forest Knolls (United States)",
  "Forest Lake (United States)",
  "Forest Park (United States)",
  "Forest River (United States)",
  "Forestdale (United States)",
  "Foresthill (United States)",
  "Foreston (United States)",
  "Forestport (United States)",
  "Forestville (United States)",
  "Forgan (United States)",
  "Foristell (United States)",
  "Fork Union (United States)",
  "Forked River (United States)",
  "Forkland (United States)",
  "Forks (United States)",
  "Forksville (United States)",
  "Forman (United States)",
  "Formoso (United States)",
  "Forney (United States)",
  "Forrest (United States)",
  "Forrest City (United States)",
  "Forreston (United States)",
  "Forsyth (United States)",
  "Fort Ann (United States)",
  "Fort Apache (United States)",
  "Fort Ashby (United States)",
  "Fort Atkinson (United States)",
  "Fort Belvoir (United States)",
  "Fort Benning (United States)",
  "Fort Benton (United States)",
  "Fort Blackmore (United States)",
  "Fort Bragg (United States)",
  "Fort Branch (United States)",
  "Fort Bridger (United States)",
  "Fort Calhoun (United States)",
  "Fort Campbell North (United States)",
  "Fort Cobb (United States)",
  "Fort Collins (United States)",
  "Fort Davis (United States)",
  "Fort Defiance (United States)",
  "Fort Deposit (United States)",
  "Fort Dodge (United States)",
  "Fort Drum (United States)",
  "Fort Duchesne (United States)",
  "Fort Edward (United States)",
  "Fort Fairfield (United States)",
  "Fort Gaines (United States)",
  "Fort Garland (United States)",
  "Fort Gay (United States)",
  "Fort George Meade (United States)",
  "Fort Gibson (United States)",
  "Fort Gratiot Township (United States)",
  "Fort Greely (United States)",
  "Fort Hall (United States)",
  "Fort Irwin (United States)",
  "Fort Jennings (United States)",
  "Fort Jones (United States)",
  "Fort Kent (United States)",
  "Fort Knox (United States)",
  "Fort Lauderdale (United States)",
  "Fort Lee (United States)",
  "Fort Leonard Wood (United States)",
  "Fort Loramie (United States)",
  "Fort Loudon (United States)",
  "Fort Lupton (United States)",
  "Fort Madison (United States)",
  "Fort McCoy (United States)",
  "Fort McDowell (United States)",
  "Fort Meade (United States)",
  "Fort Mill (United States)",
  "Fort Mitchell (United States)",
  "Fort Montgomery (United States)",
  "Fort Morgan (United States)",
  "Fort Myers (United States)",
  "Fort Myers Beach (United States)",
  "Fort Oglethorpe (United States)",
  "Fort Payne (United States)",
  "Fort Pierre (United States)",
  "Fort Plain (United States)",
  "Fort Polk (United States)",
  "Fort Ransom (United States)",
  "Fort Recovery (United States)",
  "Fort Richardson (United States)",
  "Fort Riley-Camp Whiteside (United States)",
  "Fort Ripley (United States)",
  "Fort Rucker (United States)",
  "Fort Scott (United States)",
  "Fort Sill Indian School Reservation (historical) (United States)",
  "Fort Smith (United States)",
  "Fort Stewart (United States)",
  "Fort Stockton (United States)",
  "Fort Sumner (United States)",
  "Fort Thomas (United States)",
  "Fort Thompson (United States)",
  "Fort Totten (United States)",
  "Fort Valley (United States)",
  "Fort Walton Beach (United States)",
  "Fort Washakie (United States)",
  "Fort Washington (United States)",
  "Fort Wayne (United States)",
  "Fort White (United States)",
  "Fort Worth (United States)",
  "Fort Yates (United States)",
  "Fortine (United States)",
  "Fortson (United States)",
  "Fortuna (United States)",
  "Fortville (United States)",
  "Foss (United States)",
  "Fossil (United States)",
  "Fosston (United States)",
  "Foster (United States)",
  "Foster City (United States)",
  "Fosters (United States)",
  "Fostoria (United States)",
  "Fouke (United States)",
  "Fountain (United States)",
  "Fountain City (United States)",
  "Fountain Green (United States)",
  "Fountain Hills (United States)",
  "Fountain Inn (United States)",
  "Fountain Run (United States)",
  "Fountain Valley (United States)",
  "Fountaintown (United States)",
  "Fountainville (United States)",
  "Four Oaks (United States)",
  "Fowler (United States)",
  "Fowlerville (United States)",
  "Fox Island (United States)",
  "Fox Lake (United States)",
  "Fox River Grove (United States)",
  "Foxborough (United States)",
  "Foxburg (United States)",
  "Foxhome (United States)",
  "Foxworth (United States)",
  "Frackville (United States)",
  "Framingham (United States)",
  "Francestown (United States)",
  "Francesville (United States)",
  "Francisco (United States)",
  "Franconia (United States)",
  "Frankenmuth (United States)",
  "Frankewing (United States)",
  "Frankford (United States)",
  "Frankfort (United States)",
  "Franklin (United States)",
  "Franklin Grove (United States)",
  "Franklin Lakes (United States)",
  "Franklin Park (United States)",
  "Franklin Springs (United States)",
  "Franklin Square (United States)",
  "Franklinton (United States)",
  "Franklinville (United States)",
  "Frankston (United States)",
  "Franksville (United States)",
  "Frankton (United States)",
  "Franktown (United States)",
  "Frannie (United States)",
  "Fraser (United States)",
  "Frazee (United States)",
  "Frazer (United States)",
  "Frazeysburg (United States)",
  "Frazier Park (United States)",
  "Fraziers Bottom (United States)",
  "Fred (United States)",
  "Frederic (United States)",
  "Frederica (United States)",
  "Frederick (United States)",
  "Fredericksburg (United States)",
  "Fredericktown (United States)",
  "Fredonia (United States)",
  "Free Soil (United States)",
  "Free Union (United States)",
  "Freeburg (United States)",
  "Freeburn (United States)",
  "Freedom (United States)",
  "Freehold (United States)",
  "Freehold Borough (United States)",
  "Freeland (United States)",
  "Freeman (United States)",
  "Freeport (United States)",
  "Freer (United States)",
  "Freetown (United States)",
  "Freeville (United States)",
  "Fremont (United States)",
  "French Camp (United States)",
  "French Lick (United States)",
  "French Settlement (United States)",
  "French Village (United States)",
  "Frenchburg (United States)",
  "Frenchtown (United States)",
  "Frenchville (United States)",
  "Fresh Meadows (United States)",
  "Fresno (United States)",
  "Frewsburg (United States)",
  "Friant (United States)",
  "Friars Point (United States)",
  "Friday Harbor (United States)",
  "Friedens (United States)",
  "Friend (United States)",
  "Friendly (United States)",
  "Friendship (United States)",
  "Friendsville (United States)",
  "Friendswood (United States)",
  "Fries (United States)",
  "Friona (United States)",
  "Frisco (United States)",
  "Frisco City (United States)",
  "Fritch (United States)",
  "Frohna (United States)",
  "Froid (United States)",
  "Front Royal (United States)",
  "Frontenac (United States)",
  "Frost (United States)",
  "Frostburg (United States)",
  "Frostproof (United States)",
  "Fruita (United States)",
  "Fruitdale (United States)",
  "Fruitland (United States)",
  "Fruitland Park (United States)",
  "Fruitport (United States)",
  "Fruitvale (United States)",
  "Fryburg (United States)",
  "Fryeburg (United States)",
  "Ft. Pierce (United States)",
  "Ft. Washington (United States)",
  "Fulda (United States)",
  "Fullerton (United States)",
  "Fulshear (United States)",
  "Fulton (United States)",
  "Fultondale (United States)",
  "Fultonham (United States)",
  "Fultonville (United States)",
  "Fults (United States)",
  "Funkstown (United States)",
  "Fuquay-Varina (United States)",
  "Furlong (United States)",
  "Fyffe (United States)",
  "Gable (United States)",
  "Gackle (United States)",
  "Gadsden (United States)",
  "Gaffney (United States)",
  "Gagetown (United States)",
  "Gail (United States)",
  "Gaines (United States)",
  "Gainesboro (United States)",
  "Gainestown (United States)",
  "Gainesville (United States)",
  "Gaithersburg (United States)",
  "Galatia (United States)",
  "Galax (United States)",
  "Galena (United States)",
  "Galena Park (United States)",
  "Gales Ferry (United States)",
  "Galesburg (United States)",
  "Galesville (United States)",
  "Galien (United States)",
  "Galion (United States)",
  "Galivants Ferry (United States)",
  "Gallatin (United States)",
  "Gallatin Gateway (United States)",
  "Galliano (United States)",
  "Gallion (United States)",
  "Gallipolis (United States)",
  "Gallipolis Ferry (United States)",
  "Gallitzin (United States)",
  "Galloway (United States)",
  "Gallup (United States)",
  "Galt (United States)",
  "Galva (United States)",
  "Galveston (United States)",
  "Galway (United States)",
  "Gambier (United States)",
  "Gambrills (United States)",
  "Ganado (United States)",
  "Gans (United States)",
  "Gansevoort (United States)",
  "Gap (United States)",
  "Gap Mills (United States)",
  "Garards Fort (United States)",
  "Garber (United States)",
  "Garberville (United States)",
  "Garciasville (United States)",
  "Garden City (United States)",
  "Garden Grove (United States)",
  "Garden Plain (United States)",
  "Garden Prairie (United States)",
  "Garden Valley (United States)",
  "Gardena (United States)",
  "Gardendale (United States)",
  "Gardiner (United States)",
  "Gardner (United States)",
  "Gardners (United States)",
  "Gardnerville (United States)",
  "Garfield (United States)",
  "Garland (United States)",
  "Garnavillo (United States)",
  "Garner (United States)",
  "Garnerville (United States)",
  "Garnet Valley (United States)",
  "Garnett (United States)",
  "Garrard (United States)",
  "Garretson (United States)",
  "Garrett (United States)",
  "Garrettsville (United States)",
  "Garrison (United States)",
  "Garryowen (United States)",
  "Garvin (United States)",
  "Garwin (United States)",
  "Garwood (United States)",
  "Gary (United States)",
  "Garysburg (United States)",
  "Garyville (United States)",
  "Gas (United States)",
  "Gas City (United States)",
  "Gasport (United States)",
  "Gasquet (United States)",
  "Gassaway (United States)",
  "Gassville (United States)",
  "Gaston (United States)",
  "Gastonia (United States)",
  "Gastonville (United States)",
  "Gate City (United States)",
  "Gates (United States)",
  "Gates Mills (United States)",
  "Gatesville (United States)",
  "Gateway (United States)",
  "Gatlinburg (United States)",
  "Gatzke (United States)",
  "Gauley Bridge (United States)",
  "Gause (United States)",
  "Gautier (United States)",
  "Gay (United States)",
  "Gaylesville (United States)",
  "Gaylord (United States)",
  "Gaylordsville (United States)",
  "Gays Creek (United States)",
  "Gays Mills (United States)",
  "Gazelle (United States)",
  "Geary (United States)",
  "Geff (United States)",
  "Geigertown (United States)",
  "Geismar (United States)",
  "Genesee (United States)",
  "Geneseo (United States)",
  "Geneva (United States)",
  "Genoa (United States)",
  "Genoa City (United States)",
  "Gentry (United States)",
  "Gentryville (United States)",
  "George (United States)",
  "George West (United States)",
  "Georgetown (United States)",
  "Georgiana (United States)",
  "Gerald (United States)",
  "Geraldine (United States)",
  "Gerber (United States)",
  "Gering (United States)",
  "Gerlach (United States)",
  "German Valley (United States)",
  "Germansville (United States)",
  "Germanton (United States)",
  "Germantown (United States)",
  "Geronimo (United States)",
  "Gerrardstown (United States)",
  "Gerry (United States)",
  "Gervais (United States)",
  "Gettysburg (United States)",
  "Getzville (United States)",
  "Geuda Springs (United States)",
  "Geyser (United States)",
  "Geyserville (United States)",
  "Gheens (United States)",
  "Ghent (United States)",
  "Gibbon (United States)",
  "Gibbsboro (United States)",
  "Gibbstown (United States)",
  "Gibsland (United States)",
  "Gibson (United States)",
  "Gibson City (United States)",
  "Gibsonburg (United States)",
  "Gibsonia (United States)",
  "Gibsonton (United States)",
  "Gibsonville (United States)",
  "Giddings (United States)",
  "Gideon (United States)",
  "Gifford (United States)",
  "Gig Harbor (United States)",
  "Gila (United States)",
  "Gila Bend (United States)",
  "Gilbert (United States)",
  "Gilbertown (United States)",
  "Gilberts (United States)",
  "Gilbertsville (United States)",
  "Gilbertville (United States)",
  "Gilboa (United States)",
  "Gilby (United States)",
  "Gilcrest (United States)",
  "Gilford (United States)",
  "Gill (United States)",
  "Gillespie (United States)",
  "Gillett (United States)",
  "Gillett Grove (United States)",
  "Gillette (United States)",
  "Gillham (United States)",
  "Gillsville (United States)",
  "Gilman (United States)",
  "Gilman City (United States)",
  "Gilmanton (United States)",
  "Gilmanton Ironworks (United States)",
  "Gilmer (United States)",
  "Gilmore City (United States)",
  "Gilroy (United States)",
  "Gilson (United States)",
  "Gilsum (United States)",
  "Giltner (United States)",
  "Girard (United States)",
  "Girardville (United States)",
  "Girdwood (United States)",
  "Girvin (United States)",
  "Gladbrook (United States)",
  "Glade Spring (United States)",
  "Glade Valley (United States)",
  "Gladehill (United States)",
  "Gladewater (United States)",
  "Gladstone (United States)",
  "Gladwin (United States)",
  "Gladwyne (United States)",
  "Gladys (United States)",
  "Glasco (United States)",
  "Glasford (United States)",
  "Glasgow (United States)",
  "Glassboro (United States)",
  "Glassport (United States)",
  "Glastonbury (United States)",
  "Gleason (United States)",
  "Glen Allan (United States)",
  "Glen Allen (United States)",
  "Glen Arbor (United States)",
  "Glen Arm (United States)",
  "Glen Burnie (United States)",
  "Glen Campbell (United States)",
  "Glen Carbon (United States)",
  "Glen Cove (United States)",
  "Glen Daniel (United States)",
  "Glen Easton (United States)",
  "Glen Elder (United States)",
  "Glen Ellen (United States)",
  "Glen Ellyn (United States)",
  "Glen Flora (United States)",
  "Glen Fork (United States)",
  "Glen Gardner (United States)",
  "Glen Head (United States)",
  "Glen Hope (United States)",
  "Glen Lyon (United States)",
  "Glen Mills (United States)",
  "Glen Oaks (United States)",
  "Glen Ridge (United States)",
  "Glen Rock (United States)",
  "Glen Rose (United States)",
  "Glen Saint Mary (United States)",
  "Glen Spey (United States)",
  "Glen Ullin (United States)",
  "Glenallen (United States)",
  "Glenbeulah (United States)",
  "Glenbrook (United States)",
  "Glenburn (United States)",
  "Glencoe (United States)",
  "Glendale (United States)",
  "Glendale Heights (United States)",
  "Glendive (United States)",
  "Glendo (United States)",
  "Glendora (United States)",
  "Glenelg (United States)",
  "Glenfield (United States)",
  "Glenford (United States)",
  "Glengary (United States)",
  "Glenham (United States)",
  "Glenmont (United States)",
  "Glenmoore (United States)",
  "Glenmora (United States)",
  "Glenn (United States)",
  "Glenn Dale (United States)",
  "Glenns Ferry (United States)",
  "Glennville (United States)",
  "Glenolden (United States)",
  "Glenpool (United States)",
  "Glenrock (United States)",
  "Glens (United States)",
  "Glens Falls (United States)",
  "Glens Fork (United States)",
  "Glenshaw (United States)",
  "Glenside (United States)",
  "Glenview (United States)",
  "Glenvil (United States)",
  "Glenville (United States)",
  "Glenwood (United States)",
  "Glenwood City (United States)",
  "Glenwood Landing (United States)",
  "Glenwood Springs (United States)",
  "Glidden (United States)",
  "Globe (United States)",
  "Glorieta (United States)",
  "Gloster (United States)",
  "Gloucester (United States)",
  "Gloucester City (United States)",
  "Gloucester Point (United States)",
  "Glouster (United States)",
  "Glover (United States)",
  "Gloversville (United States)",
  "Glyndon (United States)",
  "Gobles (United States)",
  "Goddard (United States)",
  "Godfrey (United States)",
  "Godley (United States)",
  "Godwin (United States)",
  "Goehner (United States)",
  "Goessel (United States)",
  "Goff (United States)",
  "Goffstown (United States)",
  "Golconda (United States)",
  "Gold Bar (United States)",
  "Gold Beach (United States)",
  "Gold Canyon (United States)",
  "Gold Hill (United States)",
  "Golden (United States)",
  "Golden City (United States)",
  "Golden Eagle (United States)",
  "Golden Meadow (United States)",
  "Golden Valley (United States)",
  "Goldendale (United States)",
  "Goldenrod (United States)",
  "Goldens Bridge (United States)",
  "Goldfield (United States)",
  "Goldonna (United States)",
  "Goldsboro (United States)",
  "Goldsmith (United States)",
  "Goldston (United States)",
  "Goldthwaite (United States)",
  "Goldvein (United States)",
  "Goleta (United States)",
  "Goliad (United States)",
  "Goltry (United States)",
  "Gonvick (United States)",
  "Gonzales (United States)",
  "Goochland (United States)",
  "Good Hope (United States)",
  "Good Thunder (United States)",
  "Goode (United States)",
  "Goodells (United States)",
  "Goodfield (United States)",
  "Goodhue (United States)",
  "Gooding (United States)",
  "Goodland (United States)",
  "Goodlettsville (United States)",
  "Goodman (United States)",
  "Goodnews Bay (United States)",
  "Goodrich (United States)",
  "Goodridge (United States)",
  "Goodview (United States)",
  "Goodwater (United States)",
  "Goodwell (United States)",
  "Goodyear (United States)",
  "Goose Creek (United States)",
  "Gordo (United States)",
  "Gordon (United States)",
  "Gordonsville (United States)",
  "Gordonville (United States)",
  "Gore (United States)",
  "Gore Springs (United States)",
  "Goreville (United States)",
  "Gorham (United States)",
  "Gorin (United States)",
  "Gorman (United States)",
  "Goshen (United States)",
  "Gosport (United States)",
  "Gotha (United States)",
  "Gotham (United States)",
  "Gothenburg (United States)",
  "Gould (United States)",
  "Gouldsboro (United States)",
  "Gouverneur (United States)",
  "Gowanda (United States)",
  "Gowen (United States)",
  "Gower (United States)",
  "Gowrie (United States)",
  "Grabill (United States)",
  "Grace (United States)",
  "Grace City (United States)",
  "Gracemont (United States)",
  "Graceville (United States)",
  "Grady (United States)",
  "Gradyville (United States)",
  "Graettinger (United States)",
  "Graford (United States)",
  "Grafton (United States)",
  "Graham (United States)",
  "Grain Valley (United States)",
  "Grambling (United States)",
  "Gramercy (United States)",
  "Grammer (United States)",
  "Grampian (United States)",
  "Granada (United States)",
  "Granada Hills (United States)",
  "Granbury (United States)",
  "Granby (United States)",
  "Grand Bay (United States)",
  "Grand Blanc (United States)",
  "Grand Cane (United States)",
  "Grand Canyon (United States)",
  "Grand Coulee (United States)",
  "Grand Forks (United States)",
  "Grand Haven (United States)",
  "Grand Island (United States)",
  "Grand Isle (United States)",
  "Grand Junction (United States)",
  "Grand Lake (United States)",
  "Grand Ledge (United States)",
  "Grand Marais (United States)",
  "Grand Marsh (United States)",
  "Grand Meadow (United States)",
  "Grand Mound (United States)",
  "Grand Portage (United States)",
  "Grand Prairie (United States)",
  "Grand Rapids (United States)",
  "Grand Ridge (United States)",
  "Grand River (United States)",
  "Grand Rivers (United States)",
  "Grand Ronde (United States)",
  "Grand Saline (United States)",
  "Grand Terrace (United States)",
  "Grand Tower (United States)",
  "Grand View (United States)",
  "Grandfalls (United States)",
  "Grandfield (United States)",
  "Grandin (United States)",
  "Grandview (United States)",
  "Grandville (United States)",
  "Grandy (United States)",
  "Granger (United States)",
  "Grangeville (United States)",
  "Granite (United States)",
  "Granite Bay (United States)",
  "Granite City (United States)",
  "Granite Falls (United States)",
  "Granite Quarry (United States)",
  "Graniteville (United States)",
  "Grant (United States)",
  "Grant City (United States)",
  "Grant Park (United States)",
  "Grantham (United States)",
  "Granton (United States)",
  "Grants (United States)",
  "Grants Pass (United States)",
  "Grantsboro (United States)",
  "Grantsburg (United States)",
  "Grantsville (United States)",
  "Grantville (United States)",
  "Granville (United States)",
  "Granville Summit (United States)",
  "Grapeland (United States)",
  "Grapeview (United States)",
  "Grapevine (United States)",
  "Grasonville (United States)",
  "Grass Lake (United States)",
  "Grass Valley (United States)",
  "Grassflat (United States)",
  "Grassy Creek (United States)",
  "Grassy Meadows (United States)",
  "Gratiot (United States)",
  "Gratis (United States)",
  "Graton (United States)",
  "Gratz (United States)",
  "Gravel Switch (United States)",
  "Gravelly (United States)",
  "Gravette (United States)",
  "Gravity (United States)",
  "Gravois Mills (United States)",
  "Grawn (United States)",
  "Gray (United States)",
  "Gray Court (United States)",
  "Gray Summit (United States)",
  "Grayland (United States)",
  "Grayling (United States)",
  "Grayslake (United States)",
  "Grayson (United States)",
  "Graysville (United States)",
  "Graytown (United States)",
  "Grayville (United States)",
  "Great Barrington (United States)",
  "Great Bend (United States)",
  "Great Cacapon (United States)",
  "Great Falls (United States)",
  "Great Lakes (United States)",
  "Great Meadows (United States)",
  "Great Mills (United States)",
  "Great Neck (United States)",
  "Great River (United States)",
  "Greater Capitol Heights (United States)",
  "Greeley (United States)",
  "Greeleyville (United States)",
  "Green (United States)",
  "Green Bank (United States)",
  "Green Bay (United States)",
  "Green Castle (United States)",
  "Green City (United States)",
  "Green Cove Springs (United States)",
  "Green Forest (United States)",
  "Green Isle (United States)",
  "Green Lake (United States)",
  "Green Lane (United States)",
  "Green Mountain (United States)",
  "Green Mountain Falls (United States)",
  "Green Pond (United States)",
  "Green River (United States)",
  "Green Sea (United States)",
  "Green Springs (United States)",
  "Green Valley (United States)",
  "Green Village (United States)",
  "Greenacres (United States)",
  "Greenback (United States)",
  "Greenbackville (United States)",
  "Greenbank (United States)",
  "Greenbelt (United States)",
  "Greenbrae (United States)",
  "Greenbrier (United States)",
  "Greenbush (United States)",
  "Greencastle (United States)",
  "Greendale (United States)",
  "Greene (United States)",
  "Greeneville (United States)",
  "Greenfield (United States)",
  "Greenland (United States)",
  "Greenlawn (United States)",
  "Greenleaf (United States)",
  "Greenock (United States)",
  "Greenport (United States)",
  "Greens Farms (United States)",
  "Greensboro (United States)",
  "Greensburg (United States)",
  "Greentop (United States)",
  "Greentown (United States)",
  "Greenup (United States)",
  "Greenvale (United States)",
  "Greenview (United States)",
  "Greenville (United States)",
  "Greenway Downs (United States)",
  "Greenwell Springs (United States)",
  "Greenwich (United States)",
  "Greenwood (United States)",
  "Greenwood Lake (United States)",
  "Greenwood Springs (United States)",
  "Greenwood Village (United States)",
  "Greer (United States)",
  "Gregory (United States)",
  "Grelton (United States)",
  "Grenada (United States)",
  "Grenloch (United States)",
  "Grenville (United States)",
  "Gresham (United States)",
  "Grethel (United States)",
  "Gretna (United States)",
  "Grey Eagle (United States)",
  "Greybull (United States)",
  "Greycliff (United States)",
  "Gridley (United States)",
  "Griffin (United States)",
  "Griffith (United States)",
  "Griffithsville (United States)",
  "Griffithville (United States)",
  "Grifton (United States)",
  "Griggsville (United States)",
  "Grimes (United States)",
  "Grimesland (United States)",
  "Grimsley (United States)",
  "Grindstone (United States)",
  "Grinnell (United States)",
  "Griswold (United States)",
  "Groesbeck (United States)",
  "Groom (United States)",
  "Grosse Ile (United States)",
  "Grosse Pointe (United States)",
  "Grosse Tete (United States)",
  "Grosvenor Dale (United States)",
  "Groton (United States)",
  "Grottoes (United States)",
  "Grouse Creek (United States)",
  "Grove (United States)",
  "Grove City (United States)",
  "Grove Hill (United States)",
  "Grove Oak (United States)",
  "Groveland (United States)",
  "Groveport (United States)",
  "Grover (United States)",
  "Grover Beach (United States)",
  "Grover Hill (United States)",
  "Grovertown (United States)",
  "Groves (United States)",
  "Groveton (United States)",
  "Grovetown (United States)",
  "Gruetli-Laager (United States)",
  "Grundy (United States)",
  "Grundy Center (United States)",
  "Gruver (United States)",
  "Grygla (United States)",
  "Guadalupe (United States)",
  "Gualala (United States)",
  "Guatay (United States)",
  "Guerneville (United States)",
  "Guernsey (United States)",
  "Gueydan (United States)",
  "Guffey (United States)",
  "Guide Rock (United States)",
  "Guilderland (United States)",
  "Guilford (United States)",
  "Guin (United States)",
  "Guinda (United States)",
  "Gulf Breeze (United States)",
  "Gulf Shores (United States)",
  "Gulfport (United States)",
  "Gully (United States)",
  "Gum Spring (United States)",
  "Gunlock (United States)",
  "Gunnison (United States)",
  "Gunpowder (United States)",
  "Gunter (United States)",
  "Guntersville (United States)",
  "Guntown (United States)",
  "Gurley (United States)",
  "Gurnee (United States)",
  "Gustine (United States)",
  "Guston (United States)",
  "Guthrie (United States)",
  "Guthrie Center (United States)",
  "Guttenberg (United States)",
  "Guy (United States)",
  "Guymon (United States)",
  "Guys (United States)",
  "Guys Mills (United States)",
  "Guysville (United States)",
  "Guyton (United States)",
  "Gwinn (United States)",
  "Gwinner (United States)",
  "Gwynedd (United States)",
  "Gwynedd Valley (United States)",
  "Gwynn Oak (United States)",
  "Gypsum (United States)",
  "Gypsy (United States)",
  "Hacienda Heights (United States)",
  "Hackberry (United States)",
  "Hackensack (United States)",
  "Hackett (United States)",
  "Hackettstown (United States)",
  "Haddam (United States)",
  "Haddock (United States)",
  "Haddon Heights (United States)",
  "Haddonfield (United States)",
  "Hadley (United States)",
  "Hadlyme (United States)",
  "Hagan (United States)",
  "Hager City (United States)",
  "Hagerhill (United States)",
  "Hagerman (United States)",
  "Hagerstown (United States)",
  "Hahira (United States)",
  "Hahnville (United States)",
  "Haigler (United States)",
  "Hailey (United States)",
  "Haines (United States)",
  "Haines City (United States)",
  "Hainesport (United States)",
  "Hakalau (United States)",
  "Halbur (United States)",
  "Hale (United States)",
  "Haledon (United States)",
  "Hales Corners (United States)",
  "Halethorpe (United States)",
  "Haleyville (United States)",
  "Half Moon Bay (United States)",
  "Halfway (United States)",
  "Halifax (United States)",
  "Hall (United States)",
  "Hallam (United States)",
  "Hallandale (United States)",
  "Hallett (United States)",
  "Hallettsville (United States)",
  "Halliday (United States)",
  "Hallie (United States)",
  "Hallieford (United States)",
  "Hallock (United States)",
  "Hallowell (United States)",
  "Halls (United States)",
  "Hallstead (United States)",
  "Hallsville (United States)",
  "Halltown (United States)",
  "Hallwood (United States)",
  "Halma (United States)",
  "Halsey (United States)",
  "Halstad (United States)",
  "Halstead (United States)",
  "Haltom City (United States)",
  "Hambleton (United States)",
  "Hamburg (United States)",
  "Hamden (United States)",
  "Hamel (United States)",
  "Hamer (United States)",
  "Hamersville (United States)",
  "Hamilton (United States)",
  "Hamler (United States)",
  "Hamlet (United States)",
  "Hamlin (United States)",
  "Hammon (United States)",
  "Hammond (United States)",
  "Hammondsport (United States)",
  "Hammonton (United States)",
  "Hampden (United States)",
  "Hampden Sydney (United States)",
  "Hampshire (United States)",
  "Hampstead (United States)",
  "Hampton (United States)",
  "Hampton Bays (United States)",
  "Hampton Falls (United States)",
  "Hamptonville (United States)",
  "Hamtramck (United States)",
  "Hana (United States)",
  "Hanalei (United States)",
  "Hanapepe (United States)",
  "Hanceville (United States)",
  "Hancock (United States)",
  "Hancocks Bridge (United States)",
  "Hanford (United States)",
  "Hankamer (United States)",
  "Hankinson (United States)",
  "Hanksville (United States)",
  "Hanley Falls (United States)",
  "Hanlontown (United States)",
  "Hanna (United States)",
  "Hanna City (United States)",
  "Hannacroix (United States)",
  "Hannah (United States)",
  "Hannibal (United States)",
  "Hanover (United States)",
  "Hanover Park (United States)",
  "Hanoverton (United States)",
  "Hanska (United States)",
  "Hanson (United States)",
  "Hansville (United States)",
  "Happy (United States)",
  "Happy Camp (United States)",
  "Happy Jack (United States)",
  "Happy Valley (United States)",
  "Harbeson (United States)",
  "Harbor Beach (United States)",
  "Harbor City (United States)",
  "Harbor Springs (United States)",
  "Harborcreek (United States)",
  "Harborside (United States)",
  "Harborton (United States)",
  "Hardeeville (United States)",
  "Hardin (United States)",
  "Hardinsburg (United States)",
  "Hardtner (United States)",
  "Hardwick (United States)",
  "Hardy (United States)",
  "Hardyville (United States)",
  "Harford (United States)",
  "Harker Heights (United States)",
  "Harlan (United States)",
  "Harlem (United States)",
  "Harleton (United States)",
  "Harleysville (United States)",
  "Harlingen (United States)",
  "Harlowton (United States)",
  "Harmans (United States)",
  "Harmon (United States)",
  "Harmonsburg (United States)",
  "Harmony (United States)",
  "Harned (United States)",
  "Harold (United States)",
  "Harper (United States)",
  "Harper Woods (United States)",
  "Harpers Ferry (United States)",
  "Harpersville (United States)",
  "Harpster (United States)",
  "Harpswell (United States)",
  "Harpursville (United States)",
  "Harrah (United States)",
  "Harrells (United States)",
  "Harriet (United States)",
  "Harrietta (United States)",
  "Harriman (United States)",
  "Harrington (United States)",
  "Harrington Park (United States)",
  "Harris (United States)",
  "Harrisburg (United States)",
  "Harrison (United States)",
  "Harrison City (United States)",
  "Harrison Valley (United States)",
  "Harrisonburg (United States)",
  "Harrisonville (United States)",
  "Harrisville (United States)",
  "Harrod (United States)",
  "Harrodsburg (United States)",
  "Harrogate (United States)",
  "Harrold (United States)",
  "Harshaw (United States)",
  "Hart (United States)",
  "Hartfield (United States)",
  "Hartford (United States)",
  "Hartford City (United States)",
  "Hartington (United States)",
  "Hartland (United States)",
  "Hartley (United States)",
  "Hartline (United States)",
  "Hartly (United States)",
  "Hartman (United States)",
  "Harts (United States)",
  "Hartsburg (United States)",
  "Hartsdale (United States)",
  "Hartsel (United States)",
  "Hartselle (United States)",
  "Hartsfield (United States)",
  "Hartshorne (United States)",
  "Hartstown (United States)",
  "Hartsville (United States)",
  "Hartville (United States)",
  "Hartwell (United States)",
  "Hartwick (United States)",
  "Hartwood (United States)",
  "Harvard (United States)",
  "Harvest (United States)",
  "Harvey (United States)",
  "Harveys Lake (United States)",
  "Harveyville (United States)",
  "Harviell (United States)",
  "Harwich (United States)",
  "Harwich Port (United States)",
  "Harwick (United States)",
  "Harwinton (United States)",
  "Harwood (United States)",
  "Harwood Heights (United States)",
  "Hasbrouck Heights (United States)",
  "Haskell (United States)",
  "Haskins (United States)",
  "Haslet (United States)",
  "Haslett (United States)",
  "Hastings (United States)",
  "Hastings-on-Hudson (United States)",
  "Hasty (United States)",
  "Hat Creek (United States)",
  "Hatboro (United States)",
  "Hatch (United States)",
  "Hatfield (United States)",
  "Hathaway Pines (United States)",
  "Hathorne (United States)",
  "Hatley (United States)",
  "Hattiesburg (United States)",
  "Hattieville (United States)",
  "Haubstadt (United States)",
  "Haughton (United States)",
  "Hauppauge (United States)",
  "Havana (United States)",
  "Havelock (United States)",
  "Haven (United States)",
  "Havensville (United States)",
  "Haverford (United States)",
  "Haverhill (United States)",
  "Haverstraw (United States)",
  "Havertown (United States)",
  "Haviland (United States)",
  "Havre (United States)",
  "Havre de Grace (United States)",
  "Haw River (United States)",
  "Hawaiian Gardens (United States)",
  "Hawaiian Ocean View (United States)",
  "Hawarden (United States)",
  "Hawesville (United States)",
  "Hawkeye (United States)",
  "Hawkins (United States)",
  "Hawkinsville (United States)",
  "Hawley (United States)",
  "Haworth (United States)",
  "Hawthorn (United States)",
  "Hawthorne (United States)",
  "Haxtun (United States)",
  "Hay (United States)",
  "Hay Springs (United States)",
  "Hayden (United States)",
  "Haydenville (United States)",
  "Hayes (United States)",
  "Hayesville (United States)",
  "Hayfield (United States)",
  "Hayfork (United States)",
  "Haymarket (United States)",
  "Haynesville (United States)",
  "Hayneville (United States)",
  "Hays (United States)",
  "Haysi (United States)",
  "Haysville (United States)",
  "Hayti (United States)",
  "Hayward (United States)",
  "Hazard (United States)",
  "Hazel (United States)",
  "Hazel Crest (United States)",
  "Hazel Green (United States)",
  "Hazel Park (United States)",
  "Hazelhurst (United States)",
  "Hazelton (United States)",
  "Hazelwood (United States)",
  "Hazen (United States)",
  "Hazlehurst (United States)",
  "Hazlet (United States)",
  "Hazleton (United States)",
  "Head of Westport (United States)",
  "Headland (United States)",
  "Healdsburg (United States)",
  "Healdton (United States)",
  "Healy (United States)",
  "Hearne (United States)",
  "Heath (United States)",
  "Heath Springs (United States)",
  "Heathsville (United States)",
  "Heavener (United States)",
  "Hebbronville (United States)",
  "Heber (United States)",
  "Heber Springs (United States)",
  "Hebron (United States)",
  "Hector (United States)",
  "Hedgesville (United States)",
  "Hedrick (United States)",
  "Heflin (United States)",
  "Hegins (United States)",
  "Heidelberg (United States)",
  "Heidrick (United States)",
  "Heiskell (United States)",
  "Helen (United States)",
  "Helena (United States)",
  "Helendale (United States)",
  "Helenwood (United States)",
  "Helix (United States)",
  "Hellertown (United States)",
  "Helmetta (United States)",
  "Helotes (United States)",
  "Helper (United States)",
  "Helvetia (United States)",
  "Hematite (United States)",
  "Hemet (United States)",
  "Hemingford (United States)",
  "Hemingway (United States)",
  "Hemlock (United States)",
  "Hemphill (United States)",
  "Hempstead (United States)",
  "Henagar (United States)",
  "Henderson (United States)",
  "Hendersonville (United States)",
  "Hendricks (United States)",
  "Hendrix (United States)",
  "Henefer (United States)",
  "Hennepin (United States)",
  "Hennessey (United States)",
  "Henniker (United States)",
  "Henning (United States)",
  "Henrico (United States)",
  "Henrietta (United States)",
  "Henry (United States)",
  "Henryetta (United States)",
  "Henryville (United States)",
  "Hensley (United States)",
  "Hephzibah (United States)",
  "Hepler (United States)",
  "Heppner (United States)",
  "Hepzibah (United States)",
  "Herald (United States)",
  "Herculaneum (United States)",
  "Hercules (United States)",
  "Hereford (United States)",
  "Herington (United States)",
  "Herkimer (United States)",
  "Herlong (United States)",
  "Herman (United States)",
  "Hermann (United States)",
  "Hermansville (United States)",
  "Hermanville (United States)",
  "Herminie (United States)",
  "Hermiston (United States)",
  "Hermitage (United States)",
  "Hermon (United States)",
  "Hermosa (United States)",
  "Hermosa Beach (United States)",
  "Hernando (United States)",
  "Herndon (United States)",
  "Hernshaw (United States)",
  "Herod (United States)",
  "Heron (United States)",
  "Heron Lake (United States)",
  "Herrick (United States)",
  "Herriman (United States)",
  "Herrin (United States)",
  "Hershey (United States)",
  "Hertford (United States)",
  "Hesperia (United States)",
  "Hesperus (United States)",
  "Hessmer (United States)",
  "Hesston (United States)",
  "Hettick (United States)",
  "Hettinger (United States)",
  "Heuvelton (United States)",
  "Hewett (United States)",
  "Hewitt (United States)",
  "Hewlett (United States)",
  "Heyburn (United States)",
  "Heyworth (United States)",
  "Hialeah (United States)",
  "Hiawassee (United States)",
  "Hiawatha (United States)",
  "Hibbing (United States)",
  "Hibbs (United States)",
  "Hickman (United States)",
  "Hickory (United States)",
  "Hickory Corners (United States)",
  "Hickory Flat (United States)",
  "Hickory Hills (United States)",
  "Hickory Valley (United States)",
  "Hicksville (United States)",
  "Hico (United States)",
  "Hidalgo (United States)",
  "Hidden Valley Lake (United States)",
  "Hiddenite (United States)",
  "Higden (United States)",
  "Higdon (United States)",
  "Higganum (United States)",
  "Higgins (United States)",
  "Higginsville (United States)",
  "High Bridge (United States)",
  "High Falls (United States)",
  "High Island (United States)",
  "High Point (United States)",
  "High Ridge (United States)",
  "High Springs (United States)",
  "High View (United States)",
  "Highland (United States)",
  "Highland Falls (United States)",
  "Highland Home (United States)",
  "Highland Lakes (United States)",
  "Highland Mills (United States)",
  "Highland Park (United States)",
  "Highland Springs (United States)",
  "Highlands (United States)",
  "Highlandville (United States)",
  "Highmore (United States)",
  "Highspire (United States)",
  "Hightstown (United States)",
  "Highwood (United States)",
  "Higley (United States)",
  "Hiko (United States)",
  "Hilbert (United States)",
  "Hildale (United States)",
  "Hildebran (United States)",
  "Hildreth (United States)",
  "Hilham (United States)",
  "Hill (United States)",
  "Hill City (United States)",
  "Hillburn (United States)",
  "Hilliard (United States)",
  "Hillister (United States)",
  "Hillman (United States)",
  "Hills (United States)",
  "Hillsboro (United States)",
  "Hillsborough (United States)",
  "Hillsdale (United States)",
  "Hillside (United States)",
  "Hillsville (United States)",
  "Hilltop (United States)",
  "Hillview (United States)",
  "Hilmar (United States)",
  "Hilo (United States)",
  "Hilton (United States)",
  "Hilton Head Island (United States)",
  "Himrod (United States)",
  "Hinckley (United States)",
  "Hindman (United States)",
  "Hindsboro (United States)",
  "Hindsville (United States)",
  "Hines (United States)",
  "Hinesburg (United States)",
  "Hinesville (United States)",
  "Hingham (United States)",
  "Hinkley (United States)",
  "Hinsdale (United States)",
  "Hinton (United States)",
  "Hiram (United States)",
  "Hitchcock (United States)",
  "Hitterdal (United States)",
  "Hiwasse (United States)",
  "Hixson (United States)",
  "Hixton (United States)",
  "Ho-Ho-Kus (United States)",
  "Hoagland (United States)",
  "Hobart (United States)",
  "Hobbs (United States)",
  "Hobbsville (United States)",
  "Hobe Sound (United States)",
  "Hoboken (United States)",
  "Hobson (United States)",
  "Hockessin (United States)",
  "Hockley (United States)",
  "Hodge (United States)",
  "Hodgen (United States)",
  "Hodgenville (United States)",
  "Hodges (United States)",
  "Hoffman (United States)",
  "Hoffman Estates (United States)",
  "Hogansburg (United States)",
  "Hogansville (United States)",
  "Hohenwald (United States)",
  "Hoisington (United States)",
  "Hokah (United States)",
  "Holabird (United States)",
  "Holbrook (United States)",
  "Holcomb (United States)",
  "Holcombe (United States)",
  "Holden (United States)",
  "Holdenville (United States)",
  "Holderness (United States)",
  "Holdingford (United States)",
  "Holdrege (United States)",
  "Holgate (United States)",
  "Holiday (United States)",
  "Holland (United States)",
  "Holland Patent (United States)",
  "Hollandale (United States)",
  "Holley (United States)",
  "Holliday (United States)",
  "Hollidaysburg (United States)",
  "Hollis (United States)",
  "Hollis Center (United States)",
  "Hollister (United States)",
  "Holliston (United States)",
  "Holloman Air Force Base (United States)",
  "Hollow Rock (United States)",
  "Holloway (United States)",
  "Hollsopple (United States)",
  "Holly (United States)",
  "Holly Grove (United States)",
  "Holly Hill (United States)",
  "Holly Pond (United States)",
  "Holly Ridge (United States)",
  "Holly Springs (United States)",
  "Hollywood (United States)",
  "Holmdel (United States)",
  "Holmen (United States)",
  "Holmes (United States)",
  "Holmesville (United States)",
  "Holstein (United States)",
  "Holt (United States)",
  "Holton (United States)",
  "Holts Summit (United States)",
  "Holtsville (United States)",
  "Holtville (United States)",
  "Holtwood (United States)",
  "Holualoa (United States)",
  "Holy Cross (United States)",
  "Holyoke (United States)",
  "Holyrood (United States)",
  "Home (United States)",
  "Homedale (United States)",
  "Homeland (United States)",
  "Homer (United States)",
  "Homer City (United States)",
  "Homer Glen (United States)",
  "Homerville (United States)",
  "Homestead (United States)",
  "Hometown (United States)",
  "Homewood (United States)",
  "Homeworth (United States)",
  "Hominy (United States)",
  "Homosassa (United States)",
  "Honaker (United States)",
  "Honaunau (United States)",
  "Hondo (United States)",
  "Honea Path (United States)",
  "Honeoye (United States)",
  "Honeoye Falls (United States)",
  "Honesdale (United States)",
  "Honey Brook (United States)",
  "Honey Creek (United States)",
  "Honey Grove (United States)",
  "Honeydew (United States)",
  "Honeyville (United States)",
  "Honolulu (United States)",
  "Honor (United States)",
  "Hood River (United States)",
  "Hoodsport (United States)",
  "Hooker (United States)",
  "Hooks (United States)",
  "Hooksett (United States)",
  "Hookstown (United States)",
  "Hooper (United States)",
  "Hooper Bay (United States)",
  "Hoopeston (United States)",
  "Hoople (United States)",
  "Hoosick (United States)",
  "Hoosick Falls (United States)",
  "Hooversville (United States)",
  "Hop Bottom (United States)",
  "Hopatcong (United States)",
  "Hope (United States)",
  "Hope Hull (United States)",
  "Hope Mills (United States)",
  "Hope Valley (United States)",
  "Hopedale (United States)",
  "Hopewell (United States)",
  "Hopewell Junction (United States)",
  "Hopkins (United States)",
  "Hopkinsville (United States)",
  "Hopkinton (United States)",
  "Hopland (United States)",
  "Hopwood (United States)",
  "Hoquiam (United States)",
  "Horatio (United States)",
  "Horicon (United States)",
  "Horn Lake (United States)",
  "Hornbeak (United States)",
  "Hornbeck (United States)",
  "Hornell (United States)",
  "Horner (United States)",
  "Hornick (United States)",
  "Horntown (United States)",
  "Horse Branch (United States)",
  "Horse Cave (United States)",
  "Horse Shoe (United States)",
  "Horseheads (United States)",
  "Horseshoe Bay (United States)",
  "Horseshoe Bend (United States)",
  "Horsham (United States)",
  "Hortense (United States)",
  "Horton (United States)",
  "Hortonville (United States)",
  "Hoschton (United States)",
  "Hosford (United States)",
  "Hoskins (United States)",
  "Hoskinston (United States)",
  "Hosmer (United States)",
  "Hospers (United States)",
  "Hosston (United States)",
  "Hostetter (United States)",
  "Hot Springs (United States)",
  "Hot Springs National Park (United States)",
  "Hot Springs Village (United States)",
  "Hotchkiss (United States)",
  "Houghton (United States)",
  "Houghton Lake (United States)",
  "Houlka (United States)",
  "Houlton (United States)",
  "Houma (United States)",
  "House Springs (United States)",
  "Houston (United States)",
  "Houtzdale (United States)",
  "Hoven (United States)",
  "Hovland (United States)",
  "Howard (United States)",
  "Howard Beach (United States)",
  "Howard City (United States)",
  "Howard Lake (United States)",
  "Howardsville (United States)",
  "Howe (United States)",
  "Howell (United States)",
  "Howes Cave (United States)",
  "Howie In The Hills (United States)",
  "Howland (United States)",
  "Hoxie (United States)",
  "Hoyleton (United States)",
  "Hoyt (United States)",
  "Hoyt Lakes (United States)",
  "Huachuca City (United States)",
  "Hubbard (United States)",
  "Hubbard Lake (United States)",
  "Hubbardston (United States)",
  "Hubbardsville (United States)",
  "Hubbell (United States)",
  "Hubert (United States)",
  "Hubertus (United States)",
  "Huddleston (United States)",
  "Huddy (United States)",
  "Hudson (United States)",
  "Hudson Falls (United States)",
  "Hudsonville (United States)",
  "Huffman (United States)",
  "Huger (United States)",
  "Hughes (United States)",
  "Hughes Springs (United States)",
  "Hugheston (United States)",
  "Hughesville (United States)",
  "Hughson (United States)",
  "Hugo (United States)",
  "Hugoton (United States)",
  "Hulbert (United States)",
  "Hulett (United States)",
  "Hull (United States)",
  "Humansville (United States)",
  "Humarock (United States)",
  "Humbird (United States)",
  "Humble (United States)",
  "Humboldt (United States)",
  "Hume (United States)",
  "Humeston (United States)",
  "Hummelstown (United States)",
  "Humphrey (United States)",
  "Hundred (United States)",
  "Hungerford (United States)",
  "Hunker (United States)",
  "Hunlock Creek (United States)",
  "Hunt (United States)",
  "Hunt Valley (United States)",
  "Hunter (United States)",
  "Huntersville (United States)",
  "Huntertown (United States)",
  "Huntingburg (United States)",
  "Huntingdon (United States)",
  "Huntingdon Valley (United States)",
  "Huntington (United States)",
  "Huntington Beach (United States)",
  "Huntington Mills (United States)",
  "Huntington Park (United States)",
  "Huntington Station (United States)",
  "Huntington Woods (United States)",
  "Huntingtown (United States)",
  "Huntland (United States)",
  "Huntley (United States)",
  "Huntsburg (United States)",
  "Huntsville (United States)",
  "Hurdland (United States)",
  "Hurdle Mills (United States)",
  "Hurley (United States)",
  "Hurleyville (United States)",
  "Hurlock (United States)",
  "Huron (United States)",
  "Hurricane (United States)",
  "Hurst (United States)",
  "Hurt (United States)",
  "Hurtsboro (United States)",
  "Huson (United States)",
  "Hustisford (United States)",
  "Hustler (United States)",
  "Hustontown (United States)",
  "Hustonville (United States)",
  "Husum (United States)",
  "Hutchins (United States)",
  "Hutchinson (United States)",
  "Huttig (United States)",
  "Hutto (United States)",
  "Huttonsville (United States)",
  "Huxley (United States)",
  "Hyampom (United States)",
  "Hyannis (United States)",
  "Hyattsville (United States)",
  "Hyde (United States)",
  "Hyde Park (United States)",
  "Hyden (United States)",
  "Hydes (United States)",
  "Hydesville (United States)",
  "Hydeville (United States)",
  "Hydro (United States)",
  "Hygiene (United States)",
  "Hymera (United States)",
  "Hyndman (United States)",
  "Hyrum (United States)",
  "Hysham (United States)",
  "Iaeger (United States)",
  "Iberia (United States)",
  "Ickesburg (United States)",
  "Ida (United States)",
  "Ida Grove (United States)",
  "Idabel (United States)",
  "Idaho Falls (United States)",
  "Idaho Springs (United States)",
  "Idalia (United States)",
  "Idalou (United States)",
  "Idaville (United States)",
  "Ider (United States)",
  "Idledale (United States)",
  "Idyllwild (United States)",
  "Ignacio (United States)",
  "Igo (United States)",
  "Ijamsville (United States)",
  "Iliff (United States)",
  "Ilion (United States)",
  "Imlay City (United States)",
  "Imler (United States)",
  "Immaculata (United States)",
  "Immokalee (United States)",
  "Imogene (United States)",
  "Imperial (United States)",
  "Imperial Beach (United States)",
  "Ina (United States)",
  "Inchelium (United States)",
  "Incline Village (United States)",
  "Independence (United States)",
  "India (United States)",
  "Indialantic (United States)",
  "Indian Head (United States)",
  "Indian Hills (United States)",
  "Indian Lake (United States)",
  "Indian Mound (United States)",
  "Indian Orchard (United States)",
  "Indian River (United States)",
  "Indian Rocks Beach (United States)",
  "Indian Trail (United States)",
  "Indian Wells (United States)",
  "Indiana (United States)",
  "Indianapolis (United States)",
  "Indianola (United States)",
  "Indiantown (United States)",
  "Indio (United States)",
  "Industry (United States)",
  "Inez (United States)",
  "Ingalls (United States)",
  "Ingleside (United States)",
  "Inglewood (United States)",
  "Inglis (United States)",
  "Ingomar (United States)",
  "Ingraham (United States)",
  "Ingram (United States)",
  "Inkom (United States)",
  "Inkster (United States)",
  "Inland (United States)",
  "Inman (United States)",
  "Inola (United States)",
  "Institute (United States)",
  "Intercession City (United States)",
  "Intercourse (United States)",
  "Interlachen (United States)",
  "Interlaken (United States)",
  "Interlochen (United States)",
  "International Falls (United States)",
  "Inver Grove Heights (United States)",
  "Inverness (United States)",
  "Inwood (United States)",
  "Inyokern (United States)",
  "Iola (United States)",
  "Ione (United States)",
  "Ionia (United States)",
  "Iota (United States)",
  "Iowa (United States)",
  "Iowa City (United States)",
  "Iowa Falls (United States)",
  "Iowa Park (United States)",
  "Ipswich (United States)",
  "Iraan (United States)",
  "Irasburg (United States)",
  "Iredell (United States)",
  "Ireland (United States)",
  "Irene (United States)",
  "Ireton (United States)",
  "Irma (United States)",
  "Irmo (United States)",
  "Iron City (United States)",
  "Iron Junction (United States)",
  "Iron Mountain (United States)",
  "Iron Ridge (United States)",
  "Iron River (United States)",
  "Iron Station (United States)",
  "Irons (United States)",
  "Ironton (United States)",
  "Ironwood (United States)",
  "Iroquois (United States)",
  "Irrigon (United States)",
  "Irvine (United States)",
  "Irving (United States)",
  "Irvington (United States)",
  "Irwin (United States)",
  "Irwinton (United States)",
  "Isabel (United States)",
  "Isabella (United States)",
  "Isanti (United States)",
  "Iselin (United States)",
  "Ishpeming (United States)",
  "Islamorada (United States)",
  "Island (United States)",
  "Island Falls (United States)",
  "Island Heights (United States)",
  "Island Lake (United States)",
  "Island Park (United States)",
  "Islandia (United States)",
  "Islandton (United States)",
  "Isle (United States)",
  "Isle of Palms (United States)",
  "Isle of Wight (United States)",
  "Islesboro (United States)",
  "Islesford (United States)",
  "Isleta (United States)",
  "Isleton (United States)",
  "Islip (United States)",
  "Islip Terrace (United States)",
  "Ismay (United States)",
  "Issaquah (United States)",
  "Italia (United States)",
  "Italy (United States)",
  "Itasca (United States)",
  "Ithaca (United States)",
  "Itmann (United States)",
  "Itta Bena (United States)",
  "Iuka (United States)",
  "Iva (United States)",
  "Ivanhoe (United States)",
  "Ivel (United States)",
  "Ivins (United States)",
  "Ivor (United States)",
  "Ivoryton (United States)",
  "Ivydale (United States)",
  "Ixonia (United States)",
  "Jack (United States)",
  "Jackpot (United States)",
  "Jacks Creek (United States)",
  "Jacksboro (United States)",
  "Jackson (United States)",
  "Jackson Center (United States)",
  "Jackson Heights (United States)",
  "Jackson Township (United States)",
  "Jacksonboro (United States)",
  "Jacksonburg (United States)",
  "Jacksons Gap (United States)",
  "Jacksonville (United States)",
  "Jacksonville Beach (United States)",
  "Jacobs Creek (United States)",
  "Jacobsburg (United States)",
  "Jacobson (United States)",
  "Jacumba Hot Springs (United States)",
  "Jaffrey (United States)",
  "Jal (United States)",
  "Jamaica (United States)",
  "Jamaica Plain (United States)",
  "Jameson (United States)",
  "Jamesport (United States)",
  "Jamestown (United States)",
  "Jamesville (United States)",
  "Jamison (United States)",
  "Jamul (United States)",
  "Jane Lew (United States)",
  "Janesville (United States)",
  "Jaroso (United States)",
  "Jarreau (United States)",
  "Jarrell (United States)",
  "Jarrettsville (United States)",
  "Jarvisburg (United States)",
  "Jasonville (United States)",
  "Jasper (United States)",
  "Jay (United States)",
  "Jayess (United States)",
  "Jayton (United States)",
  "Jean (United States)",
  "Jeanerette (United States)",
  "Jeannette (United States)",
  "Jeddo (United States)",
  "Jeff (United States)",
  "Jeffers (United States)",
  "Jefferson (United States)",
  "Jefferson City (United States)",
  "Jefferson Valley (United States)",
  "Jeffersonton (United States)",
  "Jeffersonville (United States)",
  "Jeffrey (United States)",
  "Jekyll Island (United States)",
  "Jellico (United States)",
  "Jemez Pueblo (United States)",
  "Jemez Springs (United States)",
  "Jemison (United States)",
  "Jena (United States)",
  "Jenison (United States)",
  "Jenkins (United States)",
  "Jenkinsburg (United States)",
  "Jenkinsville (United States)",
  "Jenkintown (United States)",
  "Jenks (United States)",
  "Jenner (United States)",
  "Jennerstown (United States)",
  "Jennings (United States)",
  "Jensen Beach (United States)",
  "Jeremiah (United States)",
  "Jericho (United States)",
  "Jerico Springs (United States)",
  "Jermyn (United States)",
  "Jerome (United States)",
  "Jersey (United States)",
  "Jersey City (United States)",
  "Jersey Shore (United States)",
  "Jerseyville (United States)",
  "Jerusalem (United States)",
  "Jessup (United States)",
  "Jesup (United States)",
  "Jet (United States)",
  "Jetersville (United States)",
  "Jetmore (United States)",
  "Jewell (United States)",
  "Jewett (United States)",
  "Jewett City (United States)",
  "Jim Falls (United States)",
  "Jim Thorpe (United States)",
  "Joanna (United States)",
  "Joaquin (United States)",
  "Jobstown (United States)",
  "Joelton (United States)",
  "Joes (United States)",
  "Johannesburg (United States)",
  "John Day (United States)",
  "Johns Island (United States)",
  "Johnson (United States)",
  "Johnson City (United States)",
  "Johnson Creek (United States)",
  "Johnsonburg (United States)",
  "Johnsonville (United States)",
  "Johnston (United States)",
  "Johnston City (United States)",
  "Johnstown (United States)",
  "Joice (United States)",
  "Joiner (United States)",
  "Joliet (United States)",
  "Jolon (United States)",
  "Jones (United States)",
  "Jonesboro (United States)",
  "Jonesborough (United States)",
  "Jonesburg (United States)",
  "Jonesport (United States)",
  "Jonestown (United States)",
  "Jonesville (United States)",
  "Joplin (United States)",
  "Joppa (United States)",
  "Jordan (United States)",
  "Jordan Valley (United States)",
  "Joseph (United States)",
  "Josephine (United States)",
  "Joshua (United States)",
  "Joshua Tree (United States)",
  "Jourdanton (United States)",
  "Joy (United States)",
  "Jud (United States)",
  "Juda (United States)",
  "Judith Gap (United States)",
  "Judsonia (United States)",
  "Julesburg (United States)",
  "Juliaetta (United States)",
  "Julian (United States)",
  "Juliette (United States)",
  "Jumping Branch (United States)",
  "Junction (United States)",
  "Junction City (United States)",
  "June Lake (United States)",
  "Juneau (United States)",
  "Juniata (United States)",
  "Junior (United States)",
  "Juno Beach (United States)",
  "Jupiter (United States)",
  "Justice (United States)",
  "Justin (United States)",
  "Kadoka (United States)",
  "Kahoka (United States)",
  "Kahuku (United States)",
  "Kahului (United States)",
  "Kailua (United States)",
  "Kaiser (United States)",
  "Kake (United States)",
  "Kaktovik (United States)",
  "Kalaheo (United States)",
  "Kalama (United States)",
  "Kalamazoo (United States)",
  "Kaleva (United States)",
  "Kalida (United States)",
  "Kalispell (United States)",
  "Kalkaska (United States)",
  "Kalona (United States)",
  "Kalskag (United States)",
  "Kamas (United States)",
  "Kamiah (United States)",
  "Kampsville (United States)",
  "Kanab (United States)",
  "Kanawha (United States)",
  "Kandiyohi (United States)",
  "Kane (United States)",
  "Kaneville (United States)",
  "Kankakee (United States)",
  "Kannapolis (United States)",
  "Kanopolis (United States)",
  "Kansas (United States)",
  "Kansas City (United States)",
  "Kansasville (United States)",
  "Kapaau (United States)",
  "Kaplan (United States)",
  "Kapolei (United States)",
  "Karlstad (United States)",
  "Karnack (United States)",
  "Karnak (United States)",
  "Karnes City (United States)",
  "Karns City (United States)",
  "Karthaus (United States)",
  "Karval (United States)",
  "Kasota (United States)",
  "Kasson (United States)",
  "Kathleen (United States)",
  "Katonah (United States)",
  "Katy (United States)",
  "Kaufman (United States)",
  "Kaukauna (United States)",
  "Kaumakani (United States)",
  "Kaunakakai (United States)",
  "Kauneonga Lake (United States)",
  "Kaw City (United States)",
  "Kaweah (United States)",
  "Kawkawlin (United States)",
  "Kaycee (United States)",
  "Kayenta (United States)",
  "Kaysville (United States)",
  "Kealakekua (United States)",
  "Keams Canyon (United States)",
  "Keansburg (United States)",
  "Kearney (United States)",
  "Kearneysville (United States)",
  "Kearny (United States)",
  "Keasbey (United States)",
  "Keatchie (United States)",
  "Keauhou (United States)",
  "Keavy (United States)",
  "Kechi (United States)",
  "Keedysville (United States)",
  "Keego Harbor (United States)",
  "Keene (United States)",
  "Keene Valley (United States)",
  "Keenesburg (United States)",
  "Keeseville (United States)",
  "Keewatin (United States)",
  "Keezletown (United States)",
  "Kegley (United States)",
  "Keithville (United States)",
  "Keizer (United States)",
  "Kekaha (United States)",
  "Kelayres (United States)",
  "Kelford (United States)",
  "Keller (United States)",
  "Kellerton (United States)",
  "Kelley (United States)",
  "Kelliher (United States)",
  "Kellogg (United States)",
  "Kelly (United States)",
  "Kellyton (United States)",
  "Kellyville (United States)",
  "Kelseyville (United States)",
  "Kelso (United States)",
  "Kemah (United States)",
  "Kemmerer (United States)",
  "Kemp (United States)",
  "Kempner (United States)",
  "Kempton (United States)",
  "Kenai (United States)",
  "Kenansville (United States)",
  "Kenbridge (United States)",
  "Kendalia (United States)",
  "Kendall (United States)",
  "Kendall Park (United States)",
  "Kendallville (United States)",
  "Kendrick (United States)",
  "Kenduskeag (United States)",
  "Kenedy (United States)",
  "Kenesaw (United States)",
  "Kenilworth (United States)",
  "Kenly (United States)",
  "Kenmare (United States)",
  "Kenmore (United States)",
  "Kenna (United States)",
  "Kennan (United States)",
  "Kennard (United States)",
  "Kennebec (United States)",
  "Kennebunk (United States)",
  "Kennebunkport (United States)",
  "Kennedale (United States)",
  "Kennedy (United States)",
  "Kennedyville (United States)",
  "Kenner (United States)",
  "Kennerdell (United States)",
  "Kennesaw (United States)",
  "Kennett (United States)",
  "Kennett Square (United States)",
  "Kennewick (United States)",
  "Kenney (United States)",
  "Keno (United States)",
  "Kenosha (United States)",
  "Kenova (United States)",
  "Kensett (United States)",
  "Kensington (United States)",
  "Kent (United States)",
  "Kent City (United States)",
  "Kentfield (United States)",
  "Kentland (United States)",
  "Kenton (United States)",
  "Kents Hill (United States)",
  "Kents Store (United States)",
  "Kentwood (United States)",
  "Kenvil (United States)",
  "Kenyon (United States)",
  "Keokuk (United States)",
  "Keosauqua (United States)",
  "Keota (United States)",
  "Kerby (United States)",
  "Kerens (United States)",
  "Kerhonkson (United States)",
  "Kerkhoven (United States)",
  "Kerman (United States)",
  "Kermit (United States)",
  "Kernersville (United States)",
  "Kernville (United States)",
  "Kerrick (United States)",
  "Kerrville (United States)",
  "Kersey (United States)",
  "Kershaw (United States)",
  "Keshena (United States)",
  "Keswick (United States)",
  "Ketchikan (United States)",
  "Ketchum (United States)",
  "Kettle Falls (United States)",
  "Kettle Island (United States)",
  "Kettleman City (United States)",
  "Keuka Park (United States)",
  "Kevil (United States)",
  "Kevin (United States)",
  "Kew Gardens (United States)",
  "Kewadin (United States)",
  "Kewanee (United States)",
  "Kewanna (United States)",
  "Kewaskum (United States)",
  "Kewaunee (United States)",
  "Key Biscayne (United States)",
  "Key Largo (United States)",
  "Key West (United States)",
  "Keyes (United States)",
  "Keymar (United States)",
  "Keyport (United States)",
  "Keyser (United States)",
  "Keystone (United States)",
  "Keystone Heights (United States)",
  "Keysville (United States)",
  "Keytesville (United States)",
  "Kiamesha Lake (United States)",
  "Kiana (United States)",
  "Kiefer (United States)",
  "Kiel (United States)",
  "Kieler (United States)",
  "Kiester (United States)",
  "Kihei (United States)",
  "Kila (United States)",
  "Kilauea (United States)",
  "Kilbourne (United States)",
  "Kilgore (United States)",
  "Kilkenny (United States)",
  "Kilkenny Township (United States)",
  "Kill Devil Hills (United States)",
  "Killbuck (United States)",
  "Killdeer (United States)",
  "Killeen (United States)",
  "Killen (United States)",
  "Killington (United States)",
  "Killingworth (United States)",
  "Kilmarnock (United States)",
  "Kilmichael (United States)",
  "Kiln (United States)",
  "Kimball (United States)",
  "Kimballton (United States)",
  "Kimberling City (United States)",
  "Kimberly (United States)",
  "Kimberton (United States)",
  "Kimbolton (United States)",
  "Kimmell (United States)",
  "Kimper (United States)",
  "Kincaid (United States)",
  "Kinde (United States)",
  "Kinder (United States)",
  "Kinderhook (United States)",
  "Kindred (United States)",
  "King (United States)",
  "King and Queen Court House (United States)",
  "King City (United States)",
  "King Cove (United States)",
  "King George (United States)",
  "King of Prussia (United States)",
  "King Salmon (United States)",
  "King William (United States)",
  "Kingdom City (United States)",
  "Kingfield (United States)",
  "Kingfisher (United States)",
  "Kingman (United States)",
  "Kings Bay Base (United States)",
  "Kings Beach (United States)",
  "Kings Mills (United States)",
  "Kings Mountain (United States)",
  "Kings Park (United States)",
  "Kingsburg (United States)",
  "Kingsbury (United States)",
  "Kingsford (United States)",
  "Kingsland (United States)",
  "Kingsley (United States)",
  "Kingsport (United States)",
  "Kingston (United States)",
  "Kingston Springs (United States)",
  "Kingstree (United States)",
  "Kingsville (United States)",
  "Kingwood (United States)",
  "Kinmundy (United States)",
  "Kinnear (United States)",
  "Kinsley (United States)",
  "Kinsman (United States)",
  "Kinston (United States)",
  "Kinta (United States)",
  "Kintnersville (United States)",
  "Kintyre (United States)",
  "Kinzers (United States)",
  "Kiowa (United States)",
  "Kipling (United States)",
  "Kipnuk (United States)",
  "Kirby (United States)",
  "Kirbyville (United States)",
  "Kirk (United States)",
  "Kirkland (United States)",
  "Kirklin (United States)",
  "Kirksey (United States)",
  "Kirksville (United States)",
  "Kirkville (United States)",
  "Kirkwood (United States)",
  "Kiron (United States)",
  "Kirtland (United States)",
  "Kirwin (United States)",
  "Kismet (United States)",
  "Kissee Mills (United States)",
  "Kissimmee (United States)",
  "Kite (United States)",
  "Kittanning (United States)",
  "Kittery (United States)",
  "Kittery Point (United States)",
  "Kitts Hill (United States)",
  "Kitty Hawk (United States)",
  "Klamath (United States)",
  "Klamath Falls (United States)",
  "Klamath River (United States)",
  "Klawock (United States)",
  "Klemme (United States)",
  "Knapp (United States)",
  "Knife River (United States)",
  "Knightdale (United States)",
  "Knights Landing (United States)",
  "Knightsen (United States)",
  "Knightstown (United States)",
  "Knightsville (United States)",
  "Knippa (United States)",
  "Knob Lick (United States)",
  "Knob Noster (United States)",
  "Knott (United States)",
  "Knox (United States)",
  "Knox City (United States)",
  "Knox Dale (United States)",
  "Knoxville (United States)",
  "Kodak (United States)",
  "Kodiak (United States)",
  "Kohler (United States)",
  "Kokomo (United States)",
  "Koloa (United States)",
  "Konawa (United States)",
  "Kongiganak (United States)",
  "Kooskia (United States)",
  "Koppel (United States)",
  "Kopperl (United States)",
  "Kosciusko (United States)",
  "Kotlik (United States)",
  "Kountze (United States)",
  "Kouts (United States)",
  "Koyuk (United States)",
  "Krakow (United States)",
  "Kramer (United States)",
  "Krebs (United States)",
  "Kremmling (United States)",
  "Krum (United States)",
  "Kula (United States)",
  "Kulm (United States)",
  "Kulpmont (United States)",
  "Kuna (United States)",
  "Kunkletown (United States)",
  "Kure Beach (United States)",
  "Kurtistown (United States)",
  "Kuttawa (United States)",
  "Kutztown (United States)",
  "Kwethluk (United States)",
  "Kykotsmovi Village (United States)",
  "Kyle (United States)",
  "Kyles Ford (United States)",
  "La Barge (United States)",
  "La Belle (United States)",
  "La Blanca (United States)",
  "La Canada Flintridge (United States)",
  "La Center (United States)",
  "La Conner (United States)",
  "La Crescent (United States)",
  "La Crescenta (United States)",
  "La Crosse (United States)",
  "La Cygne (United States)",
  "La Farge (United States)",
  "La Fayette (United States)",
  "La Feria (United States)",
  "La Fontaine (United States)",
  "La Fox (United States)",
  "La Grande (United States)",
  "La Grange (United States)",
  "La Grange Park (United States)",
  "La Habra (United States)",
  "La Harpe (United States)",
  "La Honda (United States)",
  "La Jara (United States)",
  "La Jolla (United States)",
  "La Joya (United States)",
  "La Junta (United States)",
  "La Luz (United States)",
  "La Marque (United States)",
  "La Mesa (United States)",
  "La Mirada (United States)",
  "La Moille (United States)",
  "La Motte (United States)",
  "La Palma (United States)",
  "La Pine (United States)",
  "La Plata (United States)",
  "La Pointe (United States)",
  "La Porte (United States)",
  "La Porte City (United States)",
  "La Prairie (United States)",
  "La Pryor (United States)",
  "La Puente (United States)",
  "La Quinta (United States)",
  "La Rue (United States)",
  "La Sal (United States)",
  "La Salle (United States)",
  "La Valle (United States)",
  "La Vergne (United States)",
  "La Verne (United States)",
  "La Vernia (United States)",
  "La Veta (United States)",
  "La Vista (United States)",
  "Labadie (United States)",
  "Labadieville (United States)",
  "LaBelle (United States)",
  "LaBolt (United States)",
  "Lac du Flambeau (United States)",
  "Lacey (United States)",
  "Laceys Spring (United States)",
  "Laceyville (United States)",
  "Lachine (United States)",
  "Lackawaxen (United States)",
  "Lackey (United States)",
  "Lacombe (United States)",
  "Lacon (United States)",
  "Lacona (United States)",
  "Laconia (United States)",
  "Ladd (United States)",
  "Laddonia (United States)",
  "Ladera Ranch (United States)",
  "Ladoga (United States)",
  "Ladonia (United States)",
  "Ladora (United States)",
  "Ladson (United States)",
  "Lady Lake (United States)",
  "Ladysmith (United States)",
  "Lafayette (United States)",
  "LaFayette (United States)",
  "Lafferty (United States)",
  "Lafitte (United States)",
  "LaFollette (United States)",
  "Lagrange (United States)",
  "Lagrangeville (United States)",
  "Lagro (United States)",
  "Laguna (United States)",
  "Laguna Beach (United States)",
  "Laguna Hills (United States)",
  "Laguna Niguel (United States)",
  "Laguna Woods (United States)",
  "Lagunitas (United States)",
  "Lahaina (United States)",
  "Lahaska (United States)",
  "Laingsburg (United States)",
  "Lake (United States)",
  "Lake Alfred (United States)",
  "Lake Andes (United States)",
  "Lake Ann (United States)",
  "Lake Ariel (United States)",
  "Lake Arrowhead (United States)",
  "Lake Arthur (United States)",
  "Lake Benton (United States)",
  "Lake Bluff (United States)",
  "Lake Bronson (United States)",
  "Lake Buena Vista (United States)",
  "Lake Butler (United States)",
  "Lake Charles (United States)",
  "Lake City (United States)",
  "Lake Cormorant (United States)",
  "Lake Crystal (United States)",
  "Lake Dallas (United States)",
  "Lake Delton (United States)",
  "Lake Elmo (United States)",
  "Lake Elsinore (United States)",
  "Lake Forest (United States)",
  "Lake Geneva (United States)",
  "Lake George (United States)",
  "Lake Grove (United States)",
  "Lake Hamilton (United States)",
  "Lake Harmony (United States)",
  "Lake Havasu City (United States)",
  "Lake Helen (United States)",
  "Lake Hiawatha (United States)",
  "Lake Hopatcong (United States)",
  "Lake Hughes (United States)",
  "Lake in the Hills (United States)",
  "Lake Isabella (United States)",
  "Lake Jackson (United States)",
  "Lake Junaluska (United States)",
  "Lake Katrine (United States)",
  "Lake Lillian (United States)",
  "Lake Linden (United States)",
  "Lake Lure (United States)",
  "Lake Luzerne (United States)",
  "Lake Lynn (United States)",
  "Lake Mary (United States)",
  "Lake Mills (United States)",
  "Lake Monroe (United States)",
  "Lake Nebagamon (United States)",
  "Lake Norden (United States)",
  "Lake Odessa (United States)",
  "Lake Orion (United States)",
  "Lake Oswego (United States)",
  "Lake Ozark (United States)",
  "Lake Panasoffkee (United States)",
  "Lake Park (United States)",
  "Lake Peekskill (United States)",
  "Lake Placid (United States)",
  "Lake Pleasant (United States)",
  "Lake Preston (United States)",
  "Lake Providence (United States)",
  "Lake Saint Louis (United States)",
  "Lake Shasta (United States)",
  "Lake Station (United States)",
  "Lake Stevens (United States)",
  "Lake Tomahawk (United States)",
  "Lake Toxaway (United States)",
  "Lake View (United States)",
  "Lake Villa (United States)",
  "Lake Village (United States)",
  "Lake Waccamaw (United States)",
  "Lake Wales (United States)",
  "Lake Wilson (United States)",
  "Lake Winola (United States)",
  "Lake Worth (United States)",
  "Lake Zurich (United States)",
  "Lakebay (United States)",
  "Lakefield (United States)",
  "Lakehead (United States)",
  "Lakehurst (United States)",
  "Lakeland (United States)",
  "Lakemont (United States)",
  "Lakemore (United States)",
  "Lakeport (United States)",
  "Lakeside (United States)",
  "Laketown (United States)",
  "Lakeview (United States)",
  "Lakeville (United States)",
  "Lakewood (United States)",
  "Lakin (United States)",
  "Lakota (United States)",
  "Lamar (United States)",
  "Lambert (United States)",
  "Lamberton (United States)",
  "Lambertville (United States)",
  "Lambsburg (United States)",
  "Lame Deer (United States)",
  "Lamesa (United States)",
  "Lamoni (United States)",
  "Lamont (United States)",
  "LaMoure (United States)",
  "Lampasas (United States)",
  "Lamy (United States)",
  "Lanai (United States)",
  "Lanark (United States)",
  "Lancaster (United States)",
  "Lancing (United States)",
  "Landenberg (United States)",
  "Lander (United States)",
  "Landing (United States)",
  "Landis (United States)",
  "Landisburg (United States)",
  "Landisville (United States)",
  "Landrum (United States)",
  "Lanesboro (United States)",
  "Lanesville (United States)",
  "Lanett (United States)",
  "Laneville (United States)",
  "Lanexa (United States)",
  "Langdon (United States)",
  "Langeloth (United States)",
  "Langford (United States)",
  "Langhorne (United States)",
  "Langley (United States)",
  "Langston (United States)",
  "Langsville (United States)",
  "Lanham (United States)",
  "Lanoka Harbor (United States)",
  "Lansdale (United States)",
  "Lansdowne (United States)",
  "Lanse (United States)",
  "Lansford (United States)",
  "Lansing (United States)",
  "Lantry (United States)",
  "Laona (United States)",
  "Laotto (United States)",
  "Lapeer (United States)",
  "Lapel (United States)",
  "Lapine (United States)",
  "Laplace (United States)",
  "Laporte (United States)",
  "Lapwai (United States)",
  "Laquey (United States)",
  "Laramie (United States)",
  "Larchmont (United States)",
  "Larchwood (United States)",
  "Laredo (United States)",
  "Largo (United States)",
  "Larimore (United States)",
  "Larkspur (United States)",
  "Larned (United States)",
  "Larose (United States)",
  "Las Animas (United States)",
  "Las Cruces (United States)",
  "Las Vegas (United States)",
  "LaSalle (United States)",
  "Lascassas (United States)",
  "Latexo (United States)",
  "Latham (United States)",
  "Lathrop (United States)",
  "Latimer (United States)",
  "Laton (United States)",
  "Latonia (United States)",
  "Latrobe (United States)",
  "Latta (United States)",
  "Lattimer (United States)",
  "Lattimore (United States)",
  "Lauderdale (United States)",
  "Laughlin (United States)",
  "Laughlintown (United States)",
  "Laura (United States)",
  "Laurel (United States)",
  "Laurel Hill (United States)",
  "Laurel Springs (United States)",
  "Laurelton (United States)",
  "Laurelville (United States)",
  "Laurens (United States)",
  "Laurinburg (United States)",
  "Laurys Station (United States)",
  "Lavaca (United States)",
  "Lavalette (United States)",
  "Lavallette (United States)",
  "Laveen (United States)",
  "LaVerkin (United States)",
  "Laverne (United States)",
  "Lavon (United States)",
  "Lavonia (United States)",
  "Lawler (United States)",
  "Lawn (United States)",
  "Lawndale (United States)",
  "Lawnside (United States)",
  "Lawrence (United States)",
  "Lawrenceburg (United States)",
  "Lawrenceville (United States)",
  "Lawson (United States)",
  "Lawton (United States)",
  "Layton (United States)",
  "Laytonville (United States)",
  "Le Center (United States)",
  "Le Grand (United States)",
  "Le Mars (United States)",
  "Le Raysville (United States)",
  "Le Roy (United States)",
  "Le Sueur (United States)",
  "Lead (United States)",
  "Lead Hill (United States)",
  "Leadville (United States)",
  "Leadwood (United States)",
  "Leaf River (United States)",
  "League City (United States)",
  "Leakesville (United States)",
  "Leakey (United States)",
  "Leander (United States)",
  "Leary (United States)",
  "Leavenworth (United States)",
  "Leavittsburg (United States)",
  "Leawood (United States)",
  "Lebanon (United States)",
  "Lebanon Junction (United States)",
  "Lebec (United States)",
  "Lebo (United States)",
  "Leburn (United States)",
  "Lecanto (United States)",
  "LeClaire (United States)",
  "Lecompte (United States)",
  "Lecompton (United States)",
  "Ledbetter (United States)",
  "Lederach (United States)",
  "Ledgewood (United States)",
  "Ledyard (United States)",
  "Ledyard Center (United States)",
  "Lee (United States)",
  "Lee Center (United States)",
  "Leechburg (United States)",
  "Leedey (United States)",
  "Leeds (United States)",
  "Leeper (United States)",
  "Leesburg (United States)",
  "Leesport (United States)",
  "Leesville (United States)",
  "Leeton (United States)",
  "Leetonia (United States)",
  "Leetsdale (United States)",
  "Lefor (United States)",
  "Lefors (United States)",
  "Lehi (United States)",
  "Lehigh (United States)",
  "Lehigh Acres (United States)",
  "Lehighton (United States)",
  "Lehman (United States)",
  "Lehr (United States)",
  "Leicester (United States)",
  "Leigh (United States)",
  "Leighton (United States)",
  "Leipsic (United States)",
  "Leitchfield (United States)",
  "Leland (United States)",
  "Lemasters (United States)",
  "Lemmon (United States)",
  "Lemon Grove (United States)",
  "Lemont (United States)",
  "Lemont Furnace (United States)",
  "Lemoore (United States)",
  "Lemoyne (United States)",
  "Lempster (United States)",
  "Lena (United States)",
  "Lenapah (United States)",
  "Lenexa (United States)",
  "Lengby (United States)",
  "Lenhartsville (United States)",
  "Lenni (United States)",
  "Lennon (United States)",
  "Lennox (United States)",
  "Lenoir (United States)",
  "Lenoir City (United States)",
  "Lenora (United States)",
  "Lenore (United States)",
  "Lenox (United States)",
  "Leo (United States)",
  "Leola (United States)",
  "Leoma (United States)",
  "Leominster (United States)",
  "Leon (United States)",
  "Leona (United States)",
  "Leonard (United States)",
  "Leonardo (United States)",
  "Leonardsville (United States)",
  "Leonardtown (United States)",
  "Leonardville (United States)",
  "Leonia (United States)",
  "Leonidas (United States)",
  "Leonore (United States)",
  "Leonville (United States)",
  "Leopold (United States)",
  "Leopolis (United States)",
  "Leoti (United States)",
  "Lepanto (United States)",
  "Lerna (United States)",
  "LeRoy (United States)",
  "Leroy (United States)",
  "Lesage (United States)",
  "Leslie (United States)",
  "Lester (United States)",
  "Lester Prairie (United States)",
  "Lesterville (United States)",
  "Letart (United States)",
  "Letohatchee (United States)",
  "Letts (United States)",
  "Levan (United States)",
  "Levant (United States)",
  "Levelland (United States)",
  "Levelock (United States)",
  "Levels (United States)",
  "Leverett (United States)",
  "Levering (United States)",
  "Levittown (United States)",
  "Lewellen (United States)",
  "Lewes (United States)",
  "Lewis (United States)",
  "Lewis Center (United States)",
  "Lewis Run (United States)",
  "Lewisberry (United States)",
  "Lewisburg (United States)",
  "Lewisport (United States)",
  "Lewiston (United States)",
  "Lewiston Woodville (United States)",
  "Lewistown (United States)",
  "Lewisville (United States)",
  "Lexa (United States)",
  "Lexington (United States)",
  "Lexington Park (United States)",
  "Libby (United States)",
  "Liberal (United States)",
  "Liberty (United States)",
  "Liberty Center (United States)",
  "Liberty Corner (United States)",
  "Liberty Hill (United States)",
  "Liberty Lake (United States)",
  "Libertytown (United States)",
  "Libertyville (United States)",
  "Lick Creek (United States)",
  "Licking (United States)",
  "Lidgerwood (United States)",
  "Liebenthal (United States)",
  "Lignum (United States)",
  "Ligonier (United States)",
  "Lihue (United States)",
  "Likely (United States)",
  "Lilburn (United States)",
  "Lilesville (United States)",
  "Lillian (United States)",
  "Lillington (United States)",
  "Lilliwaup (United States)",
  "Lilly (United States)",
  "Lily Dale (United States)",
  "Lima (United States)",
  "Lime Ridge (United States)",
  "Lime Springs (United States)",
  "Limerick (United States)",
  "Limestone (United States)",
  "Limington (United States)",
  "Limon (United States)",
  "Lincoln (United States)",
  "Lincoln City (United States)",
  "Lincoln Park (United States)",
  "Lincolndale (United States)",
  "Lincolnshire (United States)",
  "Lincolnton (United States)",
  "Lincolnville (United States)",
  "Lincolnville Center (United States)",
  "Lincolnwood (United States)",
  "Lincroft (United States)",
  "Lindale (United States)",
  "Linden (United States)",
  "Lindenhurst (United States)",
  "Lindley (United States)",
  "Lindon (United States)",
  "Lindsay (United States)",
  "Lindsborg (United States)",
  "Lindside (United States)",
  "Lindstrom (United States)",
  "Linesville (United States)",
  "Lineville (United States)",
  "Linkwood (United States)",
  "Linn (United States)",
  "Linn Creek (United States)",
  "Linneus (United States)",
  "Linthicum Heights (United States)",
  "Linton (United States)",
  "Linville (United States)",
  "Linwood (United States)",
  "Lipan (United States)",
  "Lisbon (United States)",
  "Lisbon Falls (United States)",
  "Liscomb (United States)",
  "Lisle (United States)",
  "Lismore (United States)",
  "Listie (United States)",
  "Litchfield (United States)",
  "Litchfield Park (United States)",
  "Litchfield Plains (United States)",
  "Lithia (United States)",
  "Lithia Springs (United States)",
  "Lithonia (United States)",
  "Lithopolis (United States)",
  "Lititz (United States)",
  "Little America (United States)",
  "Little Chute (United States)",
  "Little Compton (United States)",
  "Little Elm (United States)",
  "Little Falls (United States)",
  "Little Ferry (United States)",
  "Little Hocking (United States)",
  "Little Meadows (United States)",
  "Little Mountain (United States)",
  "Little Neck (United States)",
  "Little River (United States)",
  "Little River-Academy (United States)",
  "Little Rock (United States)",
  "Little Rock Air Force Base (United States)",
  "Little Silver (United States)",
  "Little Suamico (United States)",
  "Little Valley (United States)",
  "Littlefield (United States)",
  "Littlefork (United States)",
  "Littlerock (United States)",
  "Littlestown (United States)",
  "Littleton (United States)",
  "Live Oak (United States)",
  "Livermore (United States)",
  "Livermore Falls (United States)",
  "Liverpool (United States)",
  "Livingston (United States)",
  "Livingston Manor (United States)",
  "Livonia (United States)",
  "Lizella (United States)",
  "Lizton (United States)",
  "Llano (United States)",
  "Loa (United States)",
  "Loachapoka (United States)",
  "Loami (United States)",
  "Lobelville (United States)",
  "Local (United States)",
  "Loch Sheldrake (United States)",
  "Lock Haven (United States)",
  "Lockbourne (United States)",
  "Locke (United States)",
  "Lockeford (United States)",
  "Lockesburg (United States)",
  "Lockhart (United States)",
  "Lockney (United States)",
  "Lockport (United States)",
  "Lockwood (United States)",
  "Loco (United States)",
  "Locust (United States)",
  "Locust Fork (United States)",
  "Locust Gap (United States)",
  "Locust Grove (United States)",
  "Locust Valley (United States)",
  "Loda (United States)",
  "Lodge (United States)",
  "Lodge Grass (United States)",
  "Lodi (United States)",
  "Log Lane Village (United States)",
  "Logan (United States)",
  "Logandale (United States)",
  "Logansport (United States)",
  "Loganton (United States)",
  "Loganville (United States)",
  "Lohman (United States)",
  "Lohn (United States)",
  "Lolita (United States)",
  "Lolo (United States)",
  "Loma (United States)",
  "Loma Linda (United States)",
  "Lomax (United States)",
  "Lombard (United States)",
  "Lomira (United States)",
  "Lomita (United States)",
  "Lompoc (United States)",
  "Lonaconing (United States)",
  "London (United States)",
  "London Mills (United States)",
  "Londonderry (United States)",
  "Lone Grove (United States)",
  "Lone Jack (United States)",
  "Lone Oak (United States)",
  "Lone Pine (United States)",
  "Lone Rock (United States)",
  "Lone Tree (United States)",
  "Lone Wolf (United States)",
  "Lonedell (United States)",
  "Long Beach (United States)",
  "Long Branch (United States)",
  "Long Grove (United States)",
  "Long Island (United States)",
  "Long Island City (United States)",
  "Long Lake (United States)",
  "Long Lane (United States)",
  "Long Pine (United States)",
  "Long Pond (United States)",
  "Long Prairie (United States)",
  "Long Valley (United States)",
  "Longboat Key (United States)",
  "Longbranch (United States)",
  "Longdale (United States)",
  "Longmeadow (United States)",
  "Longmont (United States)",
  "Longport (United States)",
  "Longs (United States)",
  "Longton (United States)",
  "Longview (United States)",
  "Longville (United States)",
  "Longwood (United States)",
  "Lonoke (United States)",
  "Lonsdale (United States)",
  "Loogootee (United States)",
  "Lookout (United States)",
  "Lookout Mountain (United States)",
  "Loomis (United States)",
  "Loon Lake (United States)",
  "Loop (United States)",
  "Loose Creek (United States)",
  "Lopez (United States)",
  "Lorain (United States)",
  "Loraine (United States)",
  "Loranger (United States)",
  "Lordsburg (United States)",
  "Lore City (United States)",
  "Lorena (United States)",
  "Lorenzo (United States)",
  "Loretto (United States)",
  "Lorimor (United States)",
  "Loris (United States)",
  "Lorman (United States)",
  "Lorraine (United States)",
  "Lorton (United States)",
  "Los Alamitos (United States)",
  "Los Alamos (United States)",
  "Los Altos (United States)",
  "Los Altos Hills (United States)",
  "Los Angeles (United States)",
  "Los Banos (United States)",
  "Los Fresnos (United States)",
  "Los Gatos (United States)",
  "Los Lunas (United States)",
  "Los Molinos (United States)",
  "Los Olivos (United States)",
  "Los Osos (United States)",
  "Losantville (United States)",
  "Lost City (United States)",
  "Lost Creek (United States)",
  "Lost Nation (United States)",
  "Lost Springs (United States)",
  "Lostant (United States)",
  "Lostine (United States)",
  "Lothian (United States)",
  "Lott (United States)",
  "Lottie (United States)",
  "Lottsburg (United States)",
  "Lotus (United States)",
  "Loudon (United States)",
  "Loudonville (United States)",
  "Louin (United States)",
  "Louisa (United States)",
  "Louisburg (United States)",
  "Louise (United States)",
  "Louisiana (United States)",
  "Louisville (United States)",
  "Loup City (United States)",
  "Lovejoy (United States)",
  "Lovelady (United States)",
  "Loveland (United States)",
  "Lovell (United States)",
  "Lovelock (United States)",
  "Lovely (United States)",
  "Loves Park (United States)",
  "Lovettsville (United States)",
  "Loveville (United States)",
  "Lovilia (United States)",
  "Loving (United States)",
  "Lovingston (United States)",
  "Lovington (United States)",
  "Low and Burbanks Grant (United States)",
  "Low Moor (United States)",
  "Lowber (United States)",
  "Lowden (United States)",
  "Lowell (United States)",
  "Lowellville (United States)",
  "Lower Brule (United States)",
  "Lower Lake (United States)",
  "Lower Salem (United States)",
  "Lowgap (United States)",
  "Lowman (United States)",
  "Lowmansville (United States)",
  "Lowndes (United States)",
  "Lowndesboro (United States)",
  "Lowry (United States)",
  "Lowry City (United States)",
  "Lowville (United States)",
  "Loxahatchee Groves (United States)",
  "Loxley (United States)",
  "Loyal (United States)",
  "Loyalhanna (United States)",
  "Loyall (United States)",
  "Loysville (United States)",
  "Lu Verne (United States)",
  "Lubbock (United States)",
  "Lubec (United States)",
  "Lucama (United States)",
  "Lucan (United States)",
  "Lucas (United States)",
  "Lucasville (United States)",
  "Lucedale (United States)",
  "Lucerne (United States)",
  "Lucerne Valley (United States)",
  "Lucinda (United States)",
  "Luck (United States)",
  "Luckey (United States)",
  "Ludington (United States)",
  "Ludlow (United States)",
  "Ludlow Falls (United States)",
  "Ludowici (United States)",
  "Lueders (United States)",
  "Lufkin (United States)",
  "Lugoff (United States)",
  "Lula (United States)",
  "Luling (United States)",
  "Lumberport (United States)",
  "Lumberton (United States)",
  "Lumberville (United States)",
  "Lummi Island (United States)",
  "Lumpkin (United States)",
  "Luna Pier (United States)",
  "Lund (United States)",
  "Lunenburg (United States)",
  "Lupton City (United States)",
  "Luray (United States)",
  "Lusby (United States)",
  "Lusk (United States)",
  "Lutcher (United States)",
  "Luther (United States)",
  "Luthersburg (United States)",
  "Luthersville (United States)",
  "Lutherville-Timonium (United States)",
  "Lutsen (United States)",
  "Luttrell (United States)",
  "Lutz (United States)",
  "Luverne (United States)",
  "Luxemburg (United States)",
  "Luxor (United States)",
  "Luxora (United States)",
  "Luzerne (United States)",
  "Lydia (United States)",
  "Lyerly (United States)",
  "Lykens (United States)",
  "Lyle (United States)",
  "Lyles (United States)",
  "Lyman (United States)",
  "Lyme (United States)",
  "Lynbrook (United States)",
  "Lynch (United States)",
  "Lynch Station (United States)",
  "Lynchburg (United States)",
  "Lynco (United States)",
  "Lynd (United States)",
  "Lyndeborough (United States)",
  "Lynden (United States)",
  "Lyndhurst (United States)",
  "Lyndon (United States)",
  "Lyndon Center (United States)",
  "Lyndon Station (United States)",
  "Lyndonville (United States)",
  "Lynn (United States)",
  "Lynn Center (United States)",
  "Lynn Haven (United States)",
  "Lynnfield (United States)",
  "Lynnville (United States)",
  "Lynnwood (United States)",
  "Lynwood (United States)",
  "Lyon (United States)",
  "Lyons (United States)",
  "Lytle (United States)",
  "Lytle Creek (United States)",
  "Mabank (United States)",
  "Mabel (United States)",
  "Mabelvale (United States)",
  "Maben (United States)",
  "Mableton (United States)",
  "Mabton (United States)",
  "Macclenny (United States)",
  "Macclesfield (United States)",
  "Macdoel (United States)",
  "Macedon (United States)",
  "Macedonia (United States)",
  "Macfarlan (United States)",
  "Machesney Park (United States)",
  "Machias (United States)",
  "Machiasport (United States)",
  "Mackay (United States)",
  "Mackinac Island (United States)",
  "Mackinaw (United States)",
  "Mackinaw City (United States)",
  "Macksburg (United States)",
  "Macomb (United States)",
  "Macon (United States)",
  "Macungie (United States)",
  "Mad River (United States)",
  "Madawaska (United States)",
  "Madbury (United States)",
  "Maddock (United States)",
  "Madelia (United States)",
  "Madera (United States)",
  "Madill (United States)",
  "Madison (United States)",
  "Madison Heights (United States)",
  "Madison Lake (United States)",
  "Madisonville (United States)",
  "Madras (United States)",
  "Madrid (United States)",
  "Magalia (United States)",
  "Magazine (United States)",
  "Magdalena (United States)",
  "Magee (United States)",
  "Maggie Valley (United States)",
  "Magna (United States)",
  "Magnolia (United States)",
  "Magnolia Springs (United States)",
  "Mahaffey (United States)",
  "Mahanoy City (United States)",
  "Mahaska (United States)",
  "Mahnomen (United States)",
  "Mahomet (United States)",
  "Mahopac (United States)",
  "Mahwah (United States)",
  "Maiden (United States)",
  "Maiden Rock (United States)",
  "Maidens (United States)",
  "Maidsville (United States)",
  "Mainesburg (United States)",
  "Maineville (United States)",
  "Maitland (United States)",
  "Maize (United States)",
  "Makanda (United States)",
  "Makawao (United States)",
  "Makinen (United States)",
  "Malabar (United States)",
  "Malad City (United States)",
  "Malaga (United States)",
  "Malakoff (United States)",
  "Malcom (United States)",
  "Malden (United States)",
  "Malibu (United States)",
  "Malin (United States)",
  "Malinta (United States)",
  "Mallory (United States)",
  "Malmo (United States)",
  "Malone (United States)",
  "Malott (United States)",
  "Malta (United States)",
  "Malvern (United States)",
  "Malverne (United States)",
  "Mamaroneck (United States)",
  "Mammoth (United States)",
  "Mammoth Lakes (United States)",
  "Mamou (United States)",
  "Man (United States)",
  "Manahawkin (United States)",
  "Manasquan (United States)",
  "Manassas (United States)",
  "Manassas Park (United States)",
  "Manawa (United States)",
  "Mancelona (United States)",
  "Manchaca (United States)",
  "Manchaug (United States)",
  "Manchester (United States)",
  "Manchester Center (United States)",
  "Manchester Township (United States)",
  "Manchester-by-the-Sea (United States)",
  "Mancos (United States)",
  "Mandan (United States)",
  "Mandaree (United States)",
  "Manderson (United States)",
  "Mandeville (United States)",
  "Mangum (United States)",
  "Manhasset (United States)",
  "Manhattan (United States)",
  "Manhattan Beach (United States)",
  "Manheim (United States)",
  "Manila (United States)",
  "Manistee (United States)",
  "Manistique (United States)",
  "Manito (United States)",
  "Manitou (United States)",
  "Manitou Springs (United States)",
  "Manitowoc (United States)",
  "Mankato (United States)",
  "Manlius (United States)",
  "Manly (United States)",
  "Mannford (United States)",
  "Manning (United States)",
  "Mannington (United States)",
  "Manns Choice (United States)",
  "Mannsville (United States)",
  "Manokotak (United States)",
  "Manomet (United States)",
  "Manor (United States)",
  "Manorville (United States)",
  "Manquin (United States)",
  "Mansfield (United States)",
  "Mansfield Center (United States)",
  "Mansfield Depot (United States)",
  "Manson (United States)",
  "Mansura (United States)",
  "Mantachie (United States)",
  "Manteca (United States)",
  "Mantee (United States)",
  "Manteno (United States)",
  "Manteo (United States)",
  "Manti (United States)",
  "Mantoloking (United States)",
  "Manton (United States)",
  "Mantorville (United States)",
  "Mantua (United States)",
  "Manvel (United States)",
  "Manville (United States)",
  "Many (United States)",
  "Many Farms (United States)",
  "Manzanita (United States)",
  "Manzanola (United States)",
  "Maple City (United States)",
  "Maple Falls (United States)",
  "Maple Grove (United States)",
  "Maple Heights (United States)",
  "Maple Hill (United States)",
  "Maple Lake (United States)",
  "Maple Park (United States)",
  "Maple Plain (United States)",
  "Maple Rapids (United States)",
  "Maple Shade (United States)",
  "Maple Valley (United States)",
  "Maplesville (United States)",
  "Mapleton (United States)",
  "Mapleville (United States)",
  "Maplewood (United States)",
  "Mappsville (United States)",
  "Maquoketa (United States)",
  "Marana (United States)",
  "Marathon (United States)",
  "Marble (United States)",
  "Marble Falls (United States)",
  "Marble Hill (United States)",
  "Marble Rock (United States)",
  "Marblehead (United States)",
  "Marblehill (United States)",
  "Marblemount (United States)",
  "Marbury (United States)",
  "Marceline (United States)",
  "Marcell (United States)",
  "Marcellus (United States)",
  "March Air Force Base (United States)",
  "Marco Island (United States)",
  "Marcus (United States)",
  "Marcus Hook (United States)",
  "Marcy (United States)",
  "Mardela Springs (United States)",
  "Marengo (United States)",
  "Marenisco (United States)",
  "Marfa (United States)",
  "Margaret (United States)",
  "Margaretville (United States)",
  "Margate City (United States)",
  "Maria Stein (United States)",
  "Marianna (United States)",
  "Maribel (United States)",
  "Maricopa (United States)",
  "Marienthal (United States)",
  "Marienville (United States)",
  "Marietta (United States)",
  "Marina (United States)",
  "Marina del Rey (United States)",
  "Marine (United States)",
  "Marine City (United States)",
  "Marine on Saint Croix (United States)",
  "Marinette (United States)",
  "Maringouin (United States)",
  "Marion (United States)",
  "Marion Center (United States)",
  "Marion Heights (United States)",
  "Marion Junction (United States)",
  "Marionville (United States)",
  "Mariposa (United States)",
  "Marissa (United States)",
  "Mark Center (United States)",
  "Marked Tree (United States)",
  "Markesan (United States)",
  "Markham (United States)",
  "Markle (United States)",
  "Markleeville (United States)",
  "Markleville (United States)",
  "Markleysburg (United States)",
  "Marks (United States)",
  "Marksville (United States)",
  "Marlboro (United States)",
  "Marlborough (United States)",
  "Marlette (United States)",
  "Marlin (United States)",
  "Marlinton (United States)",
  "Marlow (United States)",
  "Marlton (United States)",
  "Marmora (United States)",
  "Marne (United States)",
  "Maroa (United States)",
  "Marquand (United States)",
  "Marquette (United States)",
  "Marquez (United States)",
  "Marrero (United States)",
  "Marriottsville (United States)",
  "Mars (United States)",
  "Mars Hill (United States)",
  "Marseilles (United States)",
  "Marshall (United States)",
  "Marshalls Creek (United States)",
  "Marshalltown (United States)",
  "Marshallville (United States)",
  "Marshfield (United States)",
  "Marshfield Hills (United States)",
  "Marshville (United States)",
  "Marsing (United States)",
  "Marsteller (United States)",
  "Marstons Mills (United States)",
  "Mart (United States)",
  "Martell (United States)",
  "Martelle (United States)",
  "Martha (United States)",
  "Marthasville (United States)",
  "Marthaville (United States)",
  "Martin (United States)",
  "Martindale (United States)",
  "Martinez (United States)",
  "Martins Creek (United States)",
  "Martins Ferry (United States)",
  "Martinsburg (United States)",
  "Martinsdale (United States)",
  "Martinsville (United States)",
  "Martville (United States)",
  "Mary Esther (United States)",
  "Marydel (United States)",
  "Maryknoll (United States)",
  "Maryland (United States)",
  "Maryland Heights (United States)",
  "Marylhurst (United States)",
  "Maryneal (United States)",
  "Marysvale (United States)",
  "Marysville (United States)",
  "Maryville (United States)",
  "Mascot (United States)",
  "Mascotte (United States)",
  "Mascoutah (United States)",
  "Mashantucket (United States)",
  "Mashpee (United States)",
  "Maskell (United States)",
  "Mason (United States)",
  "Mason City (United States)",
  "Masontown (United States)",
  "Masonville (United States)",
  "Maspeth (United States)",
  "Mass City (United States)",
  "Massapequa (United States)",
  "Massapequa Park (United States)",
  "Massena (United States)",
  "Massey (United States)",
  "Massillon (United States)",
  "Mastic (United States)",
  "Mastic Beach (United States)",
  "Masury (United States)",
  "Matamoras (United States)",
  "Matawan (United States)",
  "Matewan (United States)",
  "Matfield Green (United States)",
  "Mather Field (United States)",
  "Mathews (United States)",
  "Mathias (United States)",
  "Mathis (United States)",
  "Mathiston (United States)",
  "Matinicus (United States)",
  "Matoaka (United States)",
  "Mattapan (United States)",
  "Mattapoisett (United States)",
  "Mattaponi (United States)",
  "Mattawa (United States)",
  "Mattawamkeag (United States)",
  "Mattawan (United States)",
  "Mattawana (United States)",
  "Matteson (United States)",
  "Matthews (United States)",
  "Mattituck (United States)",
  "Mattoon (United States)",
  "Mauckport (United States)",
  "Maud (United States)",
  "Mauk (United States)",
  "Mauldin (United States)",
  "Maumee (United States)",
  "Maumelle (United States)",
  "Maunaloa (United States)",
  "Maupin (United States)",
  "Maurepas (United States)",
  "Maurertown (United States)",
  "Maurice (United States)",
  "Mauriceville (United States)",
  "Maury (United States)",
  "Maury City (United States)",
  "Mauston (United States)",
  "Max (United States)",
  "Max Meadows (United States)",
  "Maxatawny (United States)",
  "Maxbass (United States)",
  "Maxton (United States)",
  "Maxwell (United States)",
  "May (United States)",
  "May Creek (United States)",
  "Maybee (United States)",
  "Maybrook (United States)",
  "Mayer (United States)",
  "Mayesville (United States)",
  "Mayetta (United States)",
  "Mayfield (United States)",
  "Mayflower (United States)",
  "Maylene (United States)",
  "Maynard (United States)",
  "Maynardville (United States)",
  "Mayo (United States)",
  "Mayodan (United States)",
  "Maypearl (United States)",
  "Mayport (United States)",
  "Mays Landing (United States)",
  "Maysel (United States)",
  "Maysville (United States)",
  "Mayville (United States)",
  "Maywood (United States)",
  "Mazeppa (United States)",
  "Mazomanie (United States)",
  "Mazon (United States)",
  "McAdoo (United States)",
  "McAlester (United States)",
  "McAlister (United States)",
  "McAlisterville (United States)",
  "McAllen (United States)",
  "McAlpin (United States)",
  "McArthur (United States)",
  "McBain (United States)",
  "McBee (United States)",
  "McCall (United States)",
  "McCalla (United States)",
  "McCamey (United States)",
  "McCammon (United States)",
  "McCaysville (United States)",
  "McCleary (United States)",
  "McClellan Park Census Designated Place (United States)",
  "McClelland (United States)",
  "McClellandtown (United States)",
  "McClellanville (United States)",
  "McCloud (United States)",
  "McClure (United States)",
  "McClusky (United States)",
  "McColl (United States)",
  "McComb (United States)",
  "McConnells (United States)",
  "McConnellsburg (United States)",
  "McConnelsville (United States)",
  "McCook (United States)",
  "McCool (United States)",
  "McCool Junction (United States)",
  "McCordsville (United States)",
  "McCormick (United States)",
  "McCoy (United States)",
  "McCune (United States)",
  "McCurtain (United States)",
  "McCutchenville (United States)",
  "McDaniels (United States)",
  "McDavid (United States)",
  "McDermitt (United States)",
  "McDermott (United States)",
  "McDonald (United States)",
  "McDonough (United States)",
  "McDowell (United States)",
  "McElhattan (United States)",
  "McEwen (United States)",
  "McFall (United States)",
  "McFarland (United States)",
  "McGaheysville (United States)",
  "McGehee (United States)",
  "McGraw (United States)",
  "McGregor (United States)",
  "McHenry (United States)",
  "McIntosh (United States)",
  "McKean Township (United States)",
  "McKee (United States)",
  "McKees Rocks (United States)",
  "McKeesport (United States)",
  "McKenna (United States)",
  "McKenzie (United States)",
  "McKinleyville (United States)",
  "McKinney (United States)",
  "McLain (United States)",
  "McLaughlin (United States)",
  "McLean (United States)",
  "McLeansboro (United States)",
  "McLeansville (United States)",
  "McLoud (United States)",
  "McMechen (United States)",
  "McMillan (United States)",
  "McMinnville (United States)",
  "McNabb (United States)",
  "McNary (United States)",
  "McNeal (United States)",
  "McPherson (United States)",
  "McQueeney (United States)",
  "McRae (United States)",
  "McSherrystown (United States)",
  "McVeytown (United States)",
  "McVille (United States)",
  "Meacham (United States)",
  "Mead (United States)",
  "Meade (United States)",
  "Meadow (United States)",
  "Meadow Bridge (United States)",
  "Meadow Grove (United States)",
  "Meadow Lands (United States)",
  "Meadow Valley (United States)",
  "Meadow Vista (United States)",
  "Meadowlands (United States)",
  "Meadows of Dan (United States)",
  "Meadowview (United States)",
  "Meadview (United States)",
  "Meadville (United States)",
  "Meally (United States)",
  "Meansville (United States)",
  "Mears (United States)",
  "Mebane (United States)",
  "Mecca (United States)",
  "Mechanic Falls (United States)",
  "Mechanicsburg (United States)",
  "Mechanicsville (United States)",
  "Mechanicville (United States)",
  "Mecosta (United States)",
  "Medanales (United States)",
  "Medaryville (United States)",
  "Medfield (United States)",
  "Medford (United States)",
  "Media (United States)",
  "Mediapolis (United States)",
  "Medical Lake (United States)",
  "Medicine Lake (United States)",
  "Medicine Lodge (United States)",
  "Medicine Park (United States)",
  "Medina (United States)",
  "Medinah (United States)",
  "Medon (United States)",
  "Medora (United States)",
  "Medway (United States)",
  "Meeker (United States)",
  "Meeteetse (United States)",
  "Meherrin (United States)",
  "Mehoopany (United States)",
  "Mekoryuk (United States)",
  "Melba (United States)",
  "Melbourne (United States)",
  "Melbourne Beach (United States)",
  "Melcher-Dallas (United States)",
  "Melcroft (United States)",
  "Melfa (United States)",
  "Melissa (United States)",
  "Mellen (United States)",
  "Mellenville (United States)",
  "Melrose (United States)",
  "Melrose Park (United States)",
  "Melstone (United States)",
  "Melville (United States)",
  "Melvin (United States)",
  "Melvindale (United States)",
  "Memphis (United States)",
  "Mena (United States)",
  "Menahga (United States)",
  "Menan (United States)",
  "Menard (United States)",
  "Menasha (United States)",
  "Mendenhall (United States)",
  "Mendham (United States)",
  "Mendocino (United States)",
  "Mendon (United States)",
  "Mendota (United States)",
  "Menifee (United States)",
  "Menlo (United States)",
  "Menlo Park (United States)",
  "Menno (United States)",
  "Meno (United States)",
  "Menominee (United States)",
  "Menomonee Falls (United States)",
  "Mentone (United States)",
  "Mentor (United States)",
  "Mequon (United States)",
  "Mer Rouge (United States)",
  "Meraux (United States)",
  "Merced (United States)",
  "Mercedes (United States)",
  "Mercer (United States)",
  "Mercer Island (United States)",
  "Mercersburg (United States)",
  "Merchantville (United States)",
  "Meredith (United States)",
  "Mereta (United States)",
  "Meriden (United States)",
  "Meridian (United States)",
  "Meridianville (United States)",
  "Merino (United States)",
  "Merion (United States)",
  "Merit (United States)",
  "Merkel (United States)",
  "Merlin (United States)",
  "Mermentau (United States)",
  "Merna (United States)",
  "Merrick (United States)",
  "Merrifield (United States)",
  "Merrill (United States)",
  "Merrillan (United States)",
  "Merrillville (United States)",
  "Merrimac (United States)",
  "Merrimack (United States)",
  "Merriman (United States)",
  "Merritt (United States)",
  "Merritt Island (United States)",
  "Merry Hill (United States)",
  "Merry Point (United States)",
  "Merryville (United States)",
  "Merton (United States)",
  "Mertzon (United States)",
  "Mertztown (United States)",
  "Mesa (United States)",
  "Mescalero (United States)",
  "Meshoppen (United States)",
  "Mesick (United States)",
  "Mesilla (United States)",
  "Mesilla Park (United States)",
  "Mesquite (United States)",
  "Metairie (United States)",
  "Metaline Falls (United States)",
  "Metamora (United States)",
  "Metcalf (United States)",
  "Methow (United States)",
  "Methuen (United States)",
  "Metlakatla (United States)",
  "Metropolis (United States)",
  "Metropolitan (United States)",
  "Metter (United States)",
  "Metuchen (United States)",
  "Metz (United States)",
  "Mexia (United States)",
  "Mexico (United States)",
  "Meyersdale (United States)",
  "Mi-Wuk Village (United States)",
  "Miami (United States)",
  "Miami Beach (United States)",
  "Miamisburg (United States)",
  "Miamitown (United States)",
  "Miamiville (United States)",
  "Micanopy (United States)",
  "Michie (United States)",
  "Michigan Center (United States)",
  "Michigan City (United States)",
  "Michigantown (United States)",
  "Mickleton (United States)",
  "Mico (United States)",
  "Micro (United States)",
  "Middle Bass (United States)",
  "Middle Granville (United States)",
  "Middle Grove (United States)",
  "Middle Haddam (United States)",
  "Middle Island (United States)",
  "Middle Point (United States)",
  "Middle River (United States)",
  "Middle Village (United States)",
  "Middleboro (United States)",
  "Middlebourne (United States)",
  "Middleburg (United States)",
  "Middleburgh (United States)",
  "Middlebury (United States)",
  "Middlefield (United States)",
  "Middleport (United States)",
  "Middlesboro (United States)",
  "Middlesex (United States)",
  "Middleton (United States)",
  "Middletown (United States)",
  "Middletown Springs (United States)",
  "Middleville (United States)",
  "Midfield (United States)",
  "Midkiff (United States)",
  "Midland (United States)",
  "Midland City (United States)",
  "Midland Park (United States)",
  "Midlothian (United States)",
  "Midvale (United States)",
  "Midville (United States)",
  "Midway (United States)",
  "Midway City (United States)",
  "Midway Park (United States)",
  "Midwest (United States)",
  "Mifflin (United States)",
  "Mifflinburg (United States)",
  "Mifflintown (United States)",
  "Mifflinville (United States)",
  "Mikado (United States)",
  "Milaca (United States)",
  "Milam (United States)",
  "Milan (United States)",
  "Milano (United States)",
  "Milbank (United States)",
  "Milburn (United States)",
  "Miles (United States)",
  "Miles City (United States)",
  "Milesburg (United States)",
  "Milford (United States)",
  "Milford Center (United States)",
  "Mililani Town (United States)",
  "Mill City (United States)",
  "Mill Creek (United States)",
  "Mill Hall (United States)",
  "Mill Neck (United States)",
  "Mill River (United States)",
  "Mill Shoals (United States)",
  "Mill Spring (United States)",
  "Mill Valley (United States)",
  "Milladore (United States)",
  "Millboro (United States)",
  "Millbrae (United States)",
  "Millbrook (United States)",
  "Millburn (United States)",
  "Millbury (United States)",
  "Milldale (United States)",
  "Milledgeville (United States)",
  "Millen (United States)",
  "Miller (United States)",
  "Miller Place (United States)",
  "Millers Creek (United States)",
  "Millersburg (United States)",
  "Millersport (United States)",
  "Millerstown (United States)",
  "Millersview (United States)",
  "Millersville (United States)",
  "Millerton (United States)",
  "Millfield (United States)",
  "Millheim (United States)",
  "Millican (United States)",
  "Milligan (United States)",
  "Milliken (United States)",
  "Millington (United States)",
  "Millinocket (United States)",
  "Millis (United States)",
  "Millmont (United States)",
  "Millport (United States)",
  "Millry (United States)",
  "Mills (United States)",
  "Mills River (United States)",
  "Millsap (United States)",
  "Millsboro (United States)",
  "Millstadt (United States)",
  "Millstone (United States)",
  "Milltown (United States)",
  "Millville (United States)",
  "Millwood (United States)",
  "Milmay (United States)",
  "Milner (United States)",
  "Milnesville (United States)",
  "Milnor (United States)",
  "Milo (United States)",
  "Milpitas (United States)",
  "Milroy (United States)",
  "Milton (United States)",
  "Milton Mills (United States)",
  "Milton-Freewater (United States)",
  "Miltona (United States)",
  "Miltonvale (United States)",
  "Milwaukee (United States)",
  "Milwaukie (United States)",
  "Mimbres (United States)",
  "Mims (United States)",
  "Minburn (United States)",
  "Minco (United States)",
  "Minden (United States)",
  "Minden City (United States)",
  "Mine Hill (United States)",
  "Mineola (United States)",
  "Mineral (United States)",
  "Mineral Bluff (United States)",
  "Mineral City (United States)",
  "Mineral Point (United States)",
  "Mineral Ridge (United States)",
  "Mineral Springs (United States)",
  "Mineral Wells (United States)",
  "Minersville (United States)",
  "Minerva (United States)",
  "Minford (United States)",
  "Mingo Junction (United States)",
  "Minier (United States)",
  "Minneapolis (United States)",
  "Minneola (United States)",
  "Minneota (United States)",
  "Minnesota Lake (United States)",
  "Minnetonka (United States)",
  "Minnewaukan (United States)",
  "Minnie (United States)",
  "Minoa (United States)",
  "Minocqua (United States)",
  "Minonk (United States)",
  "Minooka (United States)",
  "Minot (United States)",
  "Minotola (United States)",
  "Minster (United States)",
  "Minto (United States)",
  "Minturn (United States)",
  "Mio (United States)",
  "Mira Loma (United States)",
  "Miramar (United States)",
  "Miramar Beach (United States)",
  "Miranda (United States)",
  "Mishawaka (United States)",
  "Mishicot (United States)",
  "Mission (United States)",
  "Mission Hill (United States)",
  "Mission Hills (United States)",
  "Mission Viejo (United States)",
  "Missoula (United States)",
  "Missouri City (United States)",
  "Missouri Valley (United States)",
  "Mitchell (United States)",
  "Mitchellville (United States)",
  "Mize (United States)",
  "Moab (United States)",
  "Moapa (United States)",
  "Moatsville (United States)",
  "Moberly (United States)",
  "Mobile (United States)",
  "Mobridge (United States)",
  "Moccasin (United States)",
  "Mocksville (United States)",
  "Modale (United States)",
  "Modena (United States)",
  "Modesto (United States)",
  "Modoc (United States)",
  "Mogadore (United States)",
  "Mohave Valley (United States)",
  "Mohawk (United States)",
  "Mohegan Lake (United States)",
  "Mohnton (United States)",
  "Mohrsville (United States)",
  "Mojave (United States)",
  "Mokelumne Hill (United States)",
  "Mokena (United States)",
  "Molalla (United States)",
  "Molena (United States)",
  "Moline (United States)",
  "Molino (United States)",
  "Mollusk (United States)",
  "Molt (United States)",
  "Momence (United States)",
  "Mona (United States)",
  "Monaca (United States)",
  "Monahans (United States)",
  "Moncks Corner (United States)",
  "Moncure (United States)",
  "Mondamin (United States)",
  "Mondovi (United States)",
  "Monee (United States)",
  "Monessen (United States)",
  "Moneta (United States)",
  "Monett (United States)",
  "Monette (United States)",
  "Monkton (United States)",
  "Monmouth (United States)",
  "Monmouth Beach (United States)",
  "Monmouth Junction (United States)",
  "Monon (United States)",
  "Monona (United States)",
  "Monongahela (United States)",
  "Monponsett (United States)",
  "Monroe (United States)",
  "Monroe Center (United States)",
  "Monroe City (United States)",
  "Monroe Township (United States)",
  "Monroeton (United States)",
  "Monroeville (United States)",
  "Monrovia (United States)",
  "Monsey (United States)",
  "Monson (United States)",
  "Mont Belvieu (United States)",
  "Mont Clare (United States)",
  "Mont Vernon (United States)",
  "Montague (United States)",
  "Montandon (United States)",
  "Montara (United States)",
  "Montauk (United States)",
  "Montclair (United States)",
  "Monte Rio (United States)",
  "Monte Vista (United States)",
  "Monteagle (United States)",
  "Montebello (United States)",
  "Montegut (United States)",
  "Montello (United States)",
  "Monterey (United States)",
  "Monterey County (United States)",
  "Monterey Park (United States)",
  "Monterville (United States)",
  "Montesano (United States)",
  "Montevallo (United States)",
  "Montevideo (United States)",
  "Monteview (United States)",
  "Montezuma (United States)",
  "Montfort (United States)",
  "Montgomery (United States)",
  "Montgomery Center (United States)",
  "Montgomery City (United States)",
  "Montgomery Creek (United States)",
  "Montgomery Village (United States)",
  "Montgomeryville (United States)",
  "Monticello (United States)",
  "Montour (United States)",
  "Montour Falls (United States)",
  "Montoursville (United States)",
  "Montpelier (United States)",
  "Montreal (United States)",
  "Montreat (United States)",
  "Montrose (United States)",
  "Montross (United States)",
  "Montvale (United States)",
  "Montverde (United States)",
  "Montville (United States)",
  "Monument (United States)",
  "Monument Beach (United States)",
  "Moodus (United States)",
  "Moody (United States)",
  "Mooers (United States)",
  "Mooers Forks (United States)",
  "Moon (United States)",
  "Moonachie (United States)",
  "Moorcroft (United States)",
  "Moore (United States)",
  "Moore Haven (United States)",
  "Moorefield (United States)",
  "Mooreland (United States)",
  "Moores Hill (United States)",
  "Mooresboro (United States)",
  "Mooresburg (United States)",
  "Moorestown Township (United States)",
  "Mooresville (United States)",
  "Mooreton (United States)",
  "Mooreville (United States)",
  "Moorhead (United States)",
  "Mooringsport (United States)",
  "Moorland (United States)",
  "Moorpark (United States)",
  "Moose (United States)",
  "Moose Lake (United States)",
  "Moosic (United States)",
  "Moosup (United States)",
  "Mora (United States)",
  "Moraga (United States)",
  "Moran (United States)",
  "Moravia (United States)",
  "Moravian Falls (United States)",
  "Moreauville (United States)",
  "Morehead (United States)",
  "Morehead City (United States)",
  "Moreland (United States)",
  "Morenci (United States)",
  "Moreno Valley (United States)",
  "Moretown (United States)",
  "Morgan (United States)",
  "Morgan City (United States)",
  "Morgan Hill (United States)",
  "Morganfield (United States)",
  "Morganton (United States)",
  "Morgantown (United States)",
  "Morganville (United States)",
  "Moriah (United States)",
  "Moriarty (United States)",
  "Moriches (United States)",
  "Morley (United States)",
  "Morning Sun (United States)",
  "Morning View (United States)",
  "Moro (United States)",
  "Morocco (United States)",
  "Morongo Valley (United States)",
  "Moroni (United States)",
  "Morral (United States)",
  "Morrice (United States)",
  "Morrill (United States)",
  "Morrilton (United States)",
  "Morris (United States)",
  "Morris Chapel (United States)",
  "Morris Plains (United States)",
  "Morrisdale (United States)",
  "Morrison (United States)",
  "Morrisonville (United States)",
  "Morriston (United States)",
  "Morristown (United States)",
  "Morrisville (United States)",
  "Morro Bay (United States)",
  "Morrow (United States)",
  "Morse (United States)",
  "Morse Bluff (United States)",
  "Morton (United States)",
  "Morton Grove (United States)",
  "Mortons Gap (United States)",
  "Morven (United States)",
  "Mosca (United States)",
  "Moscow (United States)",
  "Moscow Mills (United States)",
  "Moseley (United States)",
  "Moses Lake (United States)",
  "Mosheim (United States)",
  "Mosier (United States)",
  "Mosinee (United States)",
  "Moss (United States)",
  "Moss Beach (United States)",
  "Moss Landing (United States)",
  "Moss Point (United States)",
  "Mossyrock (United States)",
  "Motley (United States)",
  "Mott (United States)",
  "Moulton (United States)",
  "Moultonborough (United States)",
  "Moultrie (United States)",
  "Mound (United States)",
  "Mound Bayou (United States)",
  "Mound City (United States)",
  "Moundridge (United States)",
  "Mounds (United States)",
  "Moundsville (United States)",
  "Moundville (United States)",
  "Mount Airy (United States)",
  "Mount Angel (United States)",
  "Mount Arlington (United States)",
  "Mount Auburn (United States)",
  "Mount Ayr (United States)",
  "Mount Berry (United States)",
  "Mount Bethel (United States)",
  "Mount Blanchard (United States)",
  "Mount Calvary (United States)",
  "Mount Carbon (United States)",
  "Mount Carmel (United States)",
  "Mount Carroll (United States)",
  "Mount Clemens (United States)",
  "Mount Cory (United States)",
  "Mount Crawford (United States)",
  "Mount Croghan (United States)",
  "Mount Eaton (United States)",
  "Mount Eden (United States)",
  "Mount Enterprise (United States)",
  "Mount Ephraim (United States)",
  "Mount Erie (United States)",
  "Mount Gay (United States)",
  "Mount Gilead (United States)",
  "Mount Gretna (United States)",
  "Mount Hermon (United States)",
  "Mount Holly (United States)",
  "Mount Holly Springs (United States)",
  "Mount Hope (United States)",
  "Mount Horeb (United States)",
  "Mount Ida (United States)",
  "Mount Jackson (United States)",
  "Mount Jewett (United States)",
  "Mount Joy (United States)",
  "Mount Juliet (United States)",
  "Mount Kisco (United States)",
  "Mount Laurel (United States)",
  "Mount Lookout (United States)",
  "Mount Morris (United States)",
  "Mount Nebo (United States)",
  "Mount Olive (United States)",
  "Mount Olivet (United States)",
  "Mount Orab (United States)",
  "Mount Pleasant (United States)",
  "Mount Pleasant Mills (United States)",
  "Mount Pocono (United States)",
  "Mount Prospect (United States)",
  "Mount Pulaski (United States)",
  "Mount Rainier (United States)",
  "Mount Royal (United States)",
  "Mount Saint Joseph (United States)",
  "Mount Savage (United States)",
  "Mount Shasta (United States)",
  "Mount Sidney (United States)",
  "Mount Sinai (United States)",
  "Mount Sterling (United States)",
  "Mount Storm (United States)",
  "Mount Tremper (United States)",
  "Mount Ulla (United States)",
  "Mount Union (United States)",
  "Mount Upton (United States)",
  "Mount Vernon (United States)",
  "Mount Victoria (United States)",
  "Mount Victory (United States)",
  "Mount Vision (United States)",
  "Mount Washington (United States)",
  "Mount Wilson (United States)",
  "Mount Wolf (United States)",
  "Mount Zion (United States)",
  "Mountain (United States)",
  "Mountain City (United States)",
  "Mountain Grove (United States)",
  "Mountain Home (United States)",
  "Mountain Iron (United States)",
  "Mountain Lake (United States)",
  "Mountain Lakes (United States)",
  "Mountain Pine (United States)",
  "Mountain Ranch (United States)",
  "Mountain Top (United States)",
  "Mountain View (United States)",
  "Mountain Village (United States)",
  "Mountainair (United States)",
  "Mountainburg (United States)",
  "Mountainside (United States)",
  "Mountainville (United States)",
  "Mountlake Terrace (United States)",
  "Mountville (United States)",
  "Mousie (United States)",
  "Mouth of Wilson (United States)",
  "Mouthcard (United States)",
  "Moville (United States)",
  "Moweaqua (United States)",
  "Moxee City (United States)",
  "Moyie Springs (United States)",
  "Moyock (United States)",
  "Mt Pleasant (United States)",
  "Mt. Dora (United States)",
  "Mt. Morris (United States)",
  "Mt. Pleasant (United States)",
  "Mt. Vernon (United States)",
  "Muenster (United States)",
  "Muir (United States)",
  "Mukilteo (United States)",
  "Mukwonago (United States)",
  "Mulberry (United States)",
  "Mulberry Grove (United States)",
  "Muldoon (United States)",
  "Muldraugh (United States)",
  "Muldrow (United States)",
  "Muleshoe (United States)",
  "Mulga (United States)",
  "Mulino (United States)",
  "Mulkeytown (United States)",
  "Mullan (United States)",
  "Mullen (United States)",
  "Mullica Hill (United States)",
  "Mulliken (United States)",
  "Mullin (United States)",
  "Mullins (United States)",
  "Mullinville (United States)",
  "Mulvane (United States)",
  "Muncie (United States)",
  "Muncy (United States)",
  "Munday (United States)",
  "Mundelein (United States)",
  "Munford (United States)",
  "Munfordville (United States)",
  "Munger (United States)",
  "Munich (United States)",
  "Munising (United States)",
  "Munith (United States)",
  "Munnsville (United States)",
  "Munroe Falls (United States)",
  "Munster (United States)",
  "Murchison (United States)",
  "Murdock (United States)",
  "Murfreesboro (United States)",
  "Murphy (United States)",
  "Murphys (United States)",
  "Murphysboro (United States)",
  "Murray (United States)",
  "Murrayville (United States)",
  "Murrells Inlet (United States)",
  "Murrieta (United States)",
  "Murrysville (United States)",
  "Muscatine (United States)",
  "Muscle Shoals (United States)",
  "Muscoda (United States)",
  "Muse (United States)",
  "Musella (United States)",
  "Muskego (United States)",
  "Muskegon (United States)",
  "Muskogee (United States)",
  "Mustang (United States)",
  "Mutual (United States)",
  "Myakka City (United States)",
  "Myerstown (United States)",
  "Myersville (United States)",
  "Mylo (United States)",
  "Myrtle (United States)",
  "Myrtle Beach (United States)",
  "Myrtle Creek (United States)",
  "Myrtle Point (United States)",
  "Mystic (United States)",
  "Myton (United States)",
  "N. Lawrence (United States)",
  "Nabb (United States)",
  "Nabesna (United States)",
  "Naco (United States)",
  "Nacogdoches (United States)",
  "Nacoochee (United States)",
  "Nageezi (United States)",
  "Nags Head (United States)",
  "Nahant (United States)",
  "Nahunta (United States)",
  "Naknek (United States)",
  "Nampa (United States)",
  "Nancy (United States)",
  "Nanjemoy (United States)",
  "Nanticoke (United States)",
  "Nantucket (United States)",
  "Nanty Glo (United States)",
  "Nanuet (United States)",
  "Naoma (United States)",
  "Napa (United States)",
  "Napavine (United States)",
  "Naper (United States)",
  "Naperville (United States)",
  "Napier (United States)",
  "Naples (United States)",
  "Napoleon (United States)",
  "Napoleonville (United States)",
  "Nappanee (United States)",
  "Nara Visa (United States)",
  "Narberth (United States)",
  "Narka (United States)",
  "Narragansett (United States)",
  "Narrows (United States)",
  "Narrowsburg (United States)",
  "Narvon (United States)",
  "Naselle (United States)",
  "Nash (United States)",
  "Nashoba (United States)",
  "Nashotah (United States)",
  "Nashport (United States)",
  "Nashua (United States)",
  "Nashville (United States)",
  "Nashwauk (United States)",
  "Nassau (United States)",
  "Nassawadox (United States)",
  "Natalbany (United States)",
  "Natalia (United States)",
  "Natchez (United States)",
  "Natchitoches (United States)",
  "Natick (United States)",
  "National City (United States)",
  "National Park (United States)",
  "Natrona Heights (United States)",
  "Natural Bridge (United States)",
  "Natural Bridge Station (United States)",
  "Naturita (United States)",
  "Naubinway (United States)",
  "Naugatuck (United States)",
  "Nauvoo (United States)",
  "Navajo (United States)",
  "Navarre (United States)",
  "Navasota (United States)",
  "Naylor (United States)",
  "Nazan Village (United States)",
  "Nazareth (United States)",
  "Neah Bay (United States)",
  "Nebo (United States)",
  "Nebraska City (United States)",
  "Necedah (United States)",
  "Neche (United States)",
  "Nederland (United States)",
  "Nedrow (United States)",
  "Needham (United States)",
  "Needham Heights (United States)",
  "Needles (United States)",
  "Needmore (United States)",
  "Needville (United States)",
  "Neely (United States)",
  "Neelyville (United States)",
  "Neenah (United States)",
  "Neeses (United States)",
  "Neffs (United States)",
  "Negaunee (United States)",
  "Negley (United States)",
  "Nehalem (United States)",
  "Neillsville (United States)",
  "Nekoosa (United States)",
  "Neligh (United States)",
  "Nellis (United States)",
  "Nellysford (United States)",
  "Nelson (United States)",
  "Nelsonville (United States)",
  "Nemacolin (United States)",
  "Nemaha (United States)",
  "Nemo (United States)",
  "Nenana (United States)",
  "Neodesha (United States)",
  "Neoga (United States)",
  "Neola (United States)",
  "Neosho (United States)",
  "Neosho Falls (United States)",
  "Neosho Rapids (United States)",
  "Neotsu (United States)",
  "Nephi (United States)",
  "Neponset (United States)",
  "Neptune Beach (United States)",
  "Neptune City (United States)",
  "Nerstrand (United States)",
  "Nesbit (United States)",
  "Nesconset (United States)",
  "Nescopeck (United States)",
  "Neshanic Station (United States)",
  "Neshkoro (United States)",
  "Nesquehoning (United States)",
  "Ness City (United States)",
  "Nester (United States)",
  "Netawaka (United States)",
  "Netcong (United States)",
  "Nettleton (United States)",
  "Nevada (United States)",
  "Nevada City (United States)",
  "Neversink (United States)",
  "Nevis (United States)",
  "New Albany (United States)",
  "New Albin (United States)",
  "New Alexandria (United States)",
  "New Almaden (United States)",
  "New Athens (United States)",
  "New Auburn (United States)",
  "New Baden (United States)",
  "New Baltimore (United States)",
  "New Bavaria (United States)",
  "New Bedford (United States)",
  "New Berlin (United States)",
  "New Berlinville (United States)",
  "New Bern (United States)",
  "New Bethlehem (United States)",
  "New Blaine (United States)",
  "New Bloomfield (United States)",
  "New Boston (United States)",
  "New Braintree (United States)",
  "New Braunfels (United States)",
  "New Bremen (United States)",
  "New Brighton (United States)",
  "New Britain (United States)",
  "New Brockton (United States)",
  "New Brunswick (United States)",
  "New Buffalo (United States)",
  "New Burnside (United States)",
  "New Cambria (United States)",
  "New Canaan (United States)",
  "New Caney (United States)",
  "New Canton (United States)",
  "New Carlisle (United States)",
  "New Castle (United States)",
  "New Church (United States)",
  "New City (United States)",
  "New Columbia (United States)",
  "New Concord (United States)",
  "New Creek (United States)",
  "New Cumberland (United States)",
  "New Cuyama (United States)",
  "New Deal (United States)",
  "New Derry (United States)",
  "New Douglas (United States)",
  "New Durham (United States)",
  "New Eagle (United States)",
  "New Effington (United States)",
  "New Egypt (United States)",
  "New England (United States)",
  "New Enterprise (United States)",
  "New Era (United States)",
  "New Fairfield (United States)",
  "New Florence (United States)",
  "New Franken (United States)",
  "New Franklin (United States)",
  "New Freedom (United States)",
  "New Galilee (United States)",
  "New Germany (United States)",
  "New Glarus (United States)",
  "New Gloucester (United States)",
  "New Goshen (United States)",
  "New Hampton (United States)",
  "New Harbor (United States)",
  "New Harmony (United States)",
  "New Hartford (United States)",
  "New Haven (United States)",
  "New Hebron (United States)",
  "New Hill (United States)",
  "New Holland (United States)",
  "New Holstein (United States)",
  "New Hope (United States)",
  "New Hudson (United States)",
  "New Hyde Park (United States)",
  "New Iberia (United States)",
  "New Ipswich (United States)",
  "New Johnsonville (United States)",
  "New Kensington (United States)",
  "New Kent (United States)",
  "New Knoxville (United States)",
  "New Laguna (United States)",
  "New Lebanon (United States)",
  "New Lenox (United States)",
  "New Lexington (United States)",
  "New Liberty (United States)",
  "New Lisbon (United States)",
  "New Llano (United States)",
  "New London (United States)",
  "New Lothrop (United States)",
  "New Madrid (United States)",
  "New Market (United States)",
  "New Marshfield (United States)",
  "New Martinsville (United States)",
  "New Meadows (United States)",
  "New Memphis (United States)",
  "New Middletown (United States)",
  "New Milford (United States)",
  "New Orleans (United States)",
  "New Oxford (United States)",
  "New Palestine (United States)",
  "New Paltz (United States)",
  "New Paris (United States)",
  "New Park (United States)",
  "New Philadelphia (United States)",
  "New Plymouth (United States)",
  "New Point (United States)",
  "New Port Richey (United States)",
  "New Portland (United States)",
  "New Prague (United States)",
  "New Preston (United States)",
  "New Providence (United States)",
  "New Raymer (United States)",
  "New Richland (United States)",
  "New Richmond (United States)",
  "New Riegel (United States)",
  "New Ringgold (United States)",
  "New River (United States)",
  "New Roads (United States)",
  "New Rochelle (United States)",
  "New Rockford (United States)",
  "New Ross (United States)",
  "New Salem (United States)",
  "New Salisbury (United States)",
  "New Sharon (United States)",
  "New Smyrna Beach (United States)",
  "New Springfield (United States)",
  "New Stanton (United States)",
  "New Straitsville (United States)",
  "New Stuyahok (United States)",
  "New Tazewell (United States)",
  "New Town (United States)",
  "New Tripoli (United States)",
  "New Troy (United States)",
  "New Ulm (United States)",
  "New Underwood (United States)",
  "New Vernon (United States)",
  "New Vienna (United States)",
  "New Vineyard (United States)",
  "New Virginia (United States)",
  "New Washington (United States)",
  "New Waterford (United States)",
  "New Waverly (United States)",
  "New Weston (United States)",
  "New Wilmington (United States)",
  "New Windsor (United States)",
  "New Woodstock (United States)",
  "New York (United States)",
  "New York Mills (United States)",
  "Newalla (United States)",
  "Newark (United States)",
  "Newark Valley (United States)",
  "Newaygo (United States)",
  "Newberg (United States)",
  "Newbern (United States)",
  "Newberry (United States)",
  "Newberry Springs (United States)",
  "Newborn (United States)",
  "Newburg (United States)",
  "Newburgh (United States)",
  "Newbury (United States)",
  "Newbury Center (United States)",
  "Newbury Old Town (United States)",
  "Newbury Park (United States)",
  "Newburyport (United States)",
  "Newcastle (United States)",
  "Newcomb (United States)",
  "Newcomerstown (United States)",
  "Newell (United States)",
  "Newellton (United States)",
  "Newfane (United States)",
  "Newfield (United States)",
  "Newfields (United States)",
  "Newfolden (United States)",
  "Newfoundland (United States)",
  "Newhall (United States)",
  "Newhope (United States)",
  "Newington (United States)",
  "Newkirk (United States)",
  "Newland (United States)",
  "Newman (United States)",
  "Newman Grove (United States)",
  "Newman Lake (United States)",
  "Newmanstown (United States)",
  "Newmarket (United States)",
  "Newnan (United States)",
  "Newport (United States)",
  "Newport Beach (United States)",
  "Newport Center (United States)",
  "Newport Coast (United States)",
  "Newport News (United States)",
  "Newry (United States)",
  "Newsoms (United States)",
  "Newton (United States)",
  "Newton Center (United States)",
  "Newton Falls (United States)",
  "Newton Grove (United States)",
  "Newton Highlands (United States)",
  "Newton Lower Falls (United States)",
  "Newton Upper Falls (United States)",
  "Newtonsville (United States)",
  "Newtonville (United States)",
  "Newtown (United States)",
  "Newtown Square (United States)",
  "Newville (United States)",
  "Nezperce (United States)",
  "Niagara (United States)",
  "Niagara Falls (United States)",
  "Niangua (United States)",
  "Niantic (United States)",
  "Nicasio (United States)",
  "Niceville (United States)",
  "Nicholasville (United States)",
  "Nicholls (United States)",
  "Nichols (United States)",
  "Nicholson (United States)",
  "Nicholville (United States)",
  "Nickelsville (United States)",
  "Nickerson (United States)",
  "Nicktown (United States)",
  "Nicollet (United States)",
  "Nicoma Park (United States)",
  "Nielsville (United States)",
  "Nikiski (United States)",
  "Niland (United States)",
  "Niles (United States)",
  "Nilwood (United States)",
  "Nine Mile Falls (United States)",
  "Ninety Six (United States)",
  "Nineveh (United States)",
  "Ninilchik (United States)",
  "Ninnekah (United States)",
  "Ninole (United States)",
  "Niobe (United States)",
  "Niobrara (United States)",
  "Niota (United States)",
  "Nipomo (United States)",
  "Nisswa (United States)",
  "Nitro (United States)",
  "Niwot (United States)",
  "Nixa (United States)",
  "Nixon (United States)",
  "Noatak (United States)",
  "Noble (United States)",
  "Nobleboro (United States)",
  "Noblesville (United States)",
  "Nocona (United States)",
  "Noel (United States)",
  "Nogal (United States)",
  "Nogales (United States)",
  "Nokesville (United States)",
  "Nokomis (United States)",
  "Nolanville (United States)",
  "Nolensville (United States)",
  "Nome (United States)",
  "Nondalton (United States)",
  "Nooksack (United States)",
  "Noonan (United States)",
  "Noorvik (United States)",
  "Nora Springs (United States)",
  "Norborne (United States)",
  "Norco (United States)",
  "Norcross (United States)",
  "Nordland (United States)",
  "Norfolk (United States)",
  "Norlina (United States)",
  "Norma (United States)",
  "Normal (United States)",
  "Normalville (United States)",
  "Norman (United States)",
  "Norman Park (United States)",
  "Normandy (United States)",
  "Normandy Beach (United States)",
  "Normangee (United States)",
  "Normantown (United States)",
  "Norridgewock (United States)",
  "Norris (United States)",
  "Norris City (United States)",
  "Norristown (United States)",
  "North (United States)",
  "North Adams (United States)",
  "North Amherst (United States)",
  "North Andover (United States)",
  "North Anson (United States)",
  "North Apollo (United States)",
  "North Arlington (United States)",
  "North Attleboro (United States)",
  "North Augusta (United States)",
  "North Aurora (United States)",
  "North Babylon (United States)",
  "North Baltimore (United States)",
  "North Bangor (United States)",
  "North Beach (United States)",
  "North Bend (United States)",
  "North Bennington (United States)",
  "North Bergen (United States)",
  "North Berwick (United States)",
  "North Billerica (United States)",
  "North Bloomfield (United States)",
  "North Bonneville (United States)",
  "North Branch (United States)",
  "North Branford (United States)",
  "North Bridgton (United States)",
  "North Brookfield (United States)",
  "North Brunswick (United States)",
  "North Carrollton (United States)",
  "North Carver (United States)",
  "North Charleston (United States)",
  "North Chatham (United States)",
  "North Chelmsford (United States)",
  "North Chicago (United States)",
  "North Chili (United States)",
  "North Clarendon (United States)",
  "North Collins (United States)",
  "North Conway (United States)",
  "North Creek (United States)",
  "North Dartmouth (United States)",
  "North Dighton (United States)",
  "North East (United States)",
  "North Eastham (United States)",
  "North Easton (United States)",
  "North English (United States)",
  "North Fairfield (United States)",
  "North Falmouth (United States)",
  "North Fork (United States)",
  "North Fort Myers (United States)",
  "North Franklin (United States)",
  "North Freedom (United States)",
  "North Garden (United States)",
  "North Grafton (United States)",
  "North Granby (United States)",
  "North Greece (United States)",
  "North Grosvenor Dale (United States)",
  "North Hampton (United States)",
  "North Hatfield (United States)",
  "North Haven (United States)",
  "North Haverhill (United States)",
  "North Henderson (United States)",
  "North Hero (United States)",
  "North Highlands (United States)",
  "North Hills (United States)",
  "North Hollywood (United States)",
  "North Jackson (United States)",
  "North Judson (United States)",
  "North Kingstown (United States)",
  "North Kingsville (United States)",
  "North Las Vegas (United States)",
  "North Lemmon (United States)",
  "North Lewisburg (United States)",
  "North Liberty (United States)",
  "North Lima (United States)",
  "North Little Rock (United States)",
  "North Loup (United States)",
  "North Manchester (United States)",
  "North Matewan (United States)",
  "North Miami Beach (United States)",
  "North Monmouth (United States)",
  "North Myrtle Beach (United States)",
  "North Newton (United States)",
  "North Olmsted (United States)",
  "North Oxford (United States)",
  "North Palm Beach (United States)",
  "North Pembroke (United States)",
  "North Plains (United States)",
  "North Platte (United States)",
  "North Point (United States)",
  "North Pole (United States)",
  "North Pomfret (United States)",
  "North Port (United States)",
  "North Powder (United States)",
  "North Prairie (United States)",
  "North Providence (United States)",
  "North Reading (United States)",
  "North Richland Hills (United States)",
  "North Ridgeville (United States)",
  "North Rose (United States)",
  "North Royalton (United States)",
  "North Salem (United States)",
  "North Salt Lake (United States)",
  "North San Juan (United States)",
  "North Sandwich (United States)",
  "North Scituate (United States)",
  "North Sioux City (United States)",
  "North Smithfield (United States)",
  "North Springfield (United States)",
  "North Stonington (United States)",
  "North Sutton (United States)",
  "North Tazewell (United States)",
  "North Tonawanda (United States)",
  "North Troy (United States)",
  "North Truro (United States)",
  "North Vernon (United States)",
  "North Versailles (United States)",
  "North Wales (United States)",
  "North Walpole (United States)",
  "North Waterboro (United States)",
  "North Webster (United States)",
  "North Weymouth (United States)",
  "North Wilkesboro (United States)",
  "North Windham (United States)",
  "North Woodstock (United States)",
  "North Zulch (United States)",
  "Northampton (United States)",
  "Northboro (United States)",
  "Northborough (United States)",
  "Northbridge (United States)",
  "Northbrook (United States)",
  "Northeast Harbor (United States)",
  "Northern Cambria (United States)",
  "Northfield (United States)",
  "Northfield Falls (United States)",
  "Northford (United States)",
  "Northfork (United States)",
  "Northome (United States)",
  "Northport (United States)",
  "Northridge (United States)",
  "Northumberland (United States)",
  "Northvale (United States)",
  "Northville (United States)",
  "Northway (United States)",
  "Northwood (United States)",
  "Norton (United States)",
  "Nortonville (United States)",
  "Norwalk (United States)",
  "Norway (United States)",
  "Norwell (United States)",
  "Norwich (United States)",
  "Norwood (United States)",
  "Norwood Young America (United States)",
  "Notasulga (United States)",
  "Notre Dame (United States)",
  "Nottingham (United States)",
  "Nova (United States)",
  "Novato (United States)",
  "Novelty (United States)",
  "Novi (United States)",
  "Novinger (United States)",
  "Nowata (United States)",
  "Noxapater (United States)",
  "Noxen (United States)",
  "Noxon (United States)",
  "Nucla (United States)",
  "Nuevo (United States)",
  "NuMine (United States)",
  "Nunda (United States)",
  "Nunica (United States)",
  "Nunn (United States)",
  "Nunnelly (United States)",
  "Nuremberg (United States)",
  "Nutley (United States)",
  "Nutrioso (United States)",
  "Nyack (United States)",
  "Nye (United States)",
  "Nyssa (United States)",
  "Oacoma (United States)",
  "Oak Bluffs (United States)",
  "Oak Brook (United States)",
  "Oak City (United States)",
  "Oak Creek (United States)",
  "Oak Forest (United States)",
  "Oak Grove (United States)",
  "Oak Harbor (United States)",
  "Oak Hill (United States)",
  "Oak Island (United States)",
  "Oak Lawn (United States)",
  "Oak Park (United States)",
  "Oak Ridge (United States)",
  "Oak Run (United States)",
  "Oak View (United States)",
  "Oakboro (United States)",
  "Oakdale (United States)",
  "Oakes (United States)",
  "Oakesdale (United States)",
  "Oakfield (United States)",
  "Oakham (United States)",
  "Oakhurst (United States)",
  "Oakland (United States)",
  "Oakland City (United States)",
  "Oakland Estates Mobile Home Park (United States)",
  "Oakland Gardens (United States)",
  "Oakley (United States)",
  "Oaklyn (United States)",
  "Oakman (United States)",
  "Oakmont (United States)",
  "Oaks (United States)",
  "Oakton (United States)",
  "Oakvale (United States)",
  "Oakville (United States)",
  "Oakwood (United States)",
  "Oberlin (United States)",
  "Obion (United States)",
  "Oblong (United States)",
  "Ocala (United States)",
  "Ocate (United States)",
  "Occidental (United States)",
  "Occoquan (United States)",
  "Ocean City (United States)",
  "Ocean Gate (United States)",
  "Ocean Grove (United States)",
  "Ocean Isle Beach (United States)",
  "Ocean Park (United States)",
  "Ocean Shores (United States)",
  "Ocean Springs (United States)",
  "Ocean View (United States)",
  "Oceana (United States)",
  "Oceano (United States)",
  "Oceanport (United States)",
  "Oceanside (United States)",
  "Oceanville (United States)",
  "Ochelata (United States)",
  "Ocheyedan (United States)",
  "Ochlocknee (United States)",
  "Ocilla (United States)",
  "Ocklawaha (United States)",
  "Ocoee (United States)",
  "Oconomowoc (United States)",
  "Oconto (United States)",
  "Oconto Falls (United States)",
  "Ocotillo (United States)",
  "Odanah (United States)",
  "Odebolt (United States)",
  "Odell (United States)",
  "Odem (United States)",
  "Oden (United States)",
  "Odenton (United States)",
  "Odenville (United States)",
  "Odessa (United States)",
  "Odin (United States)",
  "Odon (United States)",
  "Odum (United States)",
  "Oelrichs (United States)",
  "Oelwein (United States)",
  "Ogallah (United States)",
  "Ogallala (United States)",
  "Ogden (United States)",
  "Ogdensburg (United States)",
  "Ogema (United States)",
  "Ogilvie (United States)",
  "Oglesby (United States)",
  "Oglethorpe (United States)",
  "Ogunquit (United States)",
  "Ohatchee (United States)",
  "Ohio (United States)",
  "Ohio City (United States)",
  "Ohiopyle (United States)",
  "Oil City (United States)",
  "Oil Springs (United States)",
  "Oilton (United States)",
  "Ojai (United States)",
  "Ojo Caliente (United States)",
  "Okahumpka (United States)",
  "Okanogan (United States)",
  "Okarche (United States)",
  "Okatie (United States)",
  "Okay (United States)",
  "Okeana (United States)",
  "Okeechobee (United States)",
  "Okeene (United States)",
  "Okemah (United States)",
  "Okemos (United States)",
  "Oketo (United States)",
  "Oklahoma City (United States)",
  "Oklaunion (United States)",
  "Oklee (United States)",
  "Okmulgee (United States)",
  "Okoboji (United States)",
  "Okolona (United States)",
  "Oktaha (United States)",
  "Ola (United States)",
  "Olalla (United States)",
  "Olancha (United States)",
  "Olanta (United States)",
  "Olathe (United States)",
  "Olaton (United States)",
  "Old Bethpage (United States)",
  "Old Bridge (United States)",
  "Old Chatham (United States)",
  "Old Fields (United States)",
  "Old Forge (United States)",
  "Old Fort (United States)",
  "Old Greenwich (United States)",
  "Old Hickory (United States)",
  "Old Kotzebue (United States)",
  "Old Lyme (United States)",
  "Old Ocean (United States)",
  "Old Orchard Beach (United States)",
  "Old Pekin (United States)",
  "Old Saybrook (United States)",
  "Old Town (United States)",
  "Old Westbury (United States)",
  "Oldfort (United States)",
  "Oldsmar (United States)",
  "Oldtown (United States)",
  "Oldwick (United States)",
  "Olean (United States)",
  "Oley (United States)",
  "Olin (United States)",
  "Olive Branch (United States)",
  "Olive Hill (United States)",
  "Olivebridge (United States)",
  "Oliveburg (United States)",
  "Olivehurst (United States)",
  "Oliver Springs (United States)",
  "Olivet (United States)",
  "Olivette (United States)",
  "Olivia (United States)",
  "Olla (United States)",
  "Ollie (United States)",
  "Olmito (United States)",
  "Olmsted (United States)",
  "Olmsted Falls (United States)",
  "Olmstedville (United States)",
  "Olney (United States)",
  "Olney Springs (United States)",
  "Olpe (United States)",
  "Olsburg (United States)",
  "Olton (United States)",
  "Olympia (United States)",
  "Olympia Fields (United States)",
  "Olyphant (United States)",
  "Omaha (United States)",
  "Omak (United States)",
  "Omega (United States)",
  "Omena (United States)",
  "Omro (United States)",
  "Ona (United States)",
  "Onaga (United States)",
  "Onalaska (United States)",
  "Onamia (United States)",
  "Onancock (United States)",
  "Onarga (United States)",
  "Onawa (United States)",
  "Onaway (United States)",
  "Oneco (United States)",
  "Oneida (United States)",
  "Onekama (United States)",
  "Oneonta (United States)",
  "Onia (United States)",
  "Onley (United States)",
  "Onondaga (United States)",
  "Onset (United States)",
  "Onslow (United States)",
  "Onsted (United States)",
  "Ontario (United States)",
  "Ontonagon (United States)",
  "Onyx (United States)",
  "Oologah (United States)",
  "Ooltewah (United States)",
  "Oostburg (United States)",
  "Opa-locka (United States)",
  "Opdyke (United States)",
  "Opelika (United States)",
  "Opelousas (United States)",
  "Opheim (United States)",
  "Ophiem (United States)",
  "Opolis (United States)",
  "Opp (United States)",
  "Oquawka (United States)",
  "Oracle (United States)",
  "Oradell (United States)",
  "Oran (United States)",
  "Orange (United States)",
  "Orange Beach (United States)",
  "Orange City (United States)",
  "Orange Cove (United States)",
  "Orange Grove (United States)",
  "Orange Park (United States)",
  "Orange Springs (United States)",
  "Orangeburg (United States)",
  "Orangevale (United States)",
  "Orangeville (United States)",
  "Orchard (United States)",
  "Orchard Hill (United States)",
  "Orchard Park (United States)",
  "Ord (United States)",
  "Orderville (United States)",
  "Ordway (United States)",
  "Ore City (United States)",
  "Oreana (United States)",
  "Orefield (United States)",
  "Oregon (United States)",
  "Oregon City (United States)",
  "Oregon House (United States)",
  "Oregonia (United States)",
  "Oreland (United States)",
  "Orem (United States)",
  "Orford (United States)",
  "Orfordville (United States)",
  "Orient (United States)",
  "Oriental (United States)",
  "Orinda (United States)",
  "Orion (United States)",
  "Oriska (United States)",
  "Oriskany (United States)",
  "Oriskany Falls (United States)",
  "Orland (United States)",
  "Orland Park (United States)",
  "Orlando (United States)",
  "Orleans (United States)",
  "Orlinda (United States)",
  "Orma (United States)",
  "Ormond Beach (United States)",
  "Orofino (United States)",
  "Orondo (United States)",
  "Orono (United States)",
  "Oronoco (United States)",
  "Oronogo (United States)",
  "Orosi (United States)",
  "Oroville (United States)",
  "Orrington (United States)",
  "Orrs Island (United States)",
  "Orrstown (United States)",
  "Orrtanna (United States)",
  "Orrville (United States)",
  "Orting (United States)",
  "Ortonville (United States)",
  "Orwell (United States)",
  "Orwigsburg (United States)",
  "Osage (United States)",
  "Osage Beach (United States)",
  "Osage City (United States)",
  "Osakis (United States)",
  "Osawatomie (United States)",
  "Osborn (United States)",
  "Osborne (United States)",
  "Osburn (United States)",
  "Osceola (United States)",
  "Osceola Mills (United States)",
  "Osco (United States)",
  "Oscoda (United States)",
  "Osgood (United States)",
  "Oshkosh (United States)",
  "Oshtemo (United States)",
  "Oskaloosa (United States)",
  "Oslo (United States)",
  "Osmond (United States)",
  "Osprey (United States)",
  "Osseo (United States)",
  "Ossian (United States)",
  "Ossineke (United States)",
  "Ossining (United States)",
  "Ossipee (United States)",
  "Osteen (United States)",
  "Osterville (United States)",
  "Ostrander (United States)",
  "Oswego (United States)",
  "Osyka (United States)",
  "Otego (United States)",
  "Othello (United States)",
  "Otho (United States)",
  "Otis (United States)",
  "Otis Orchards (United States)",
  "Otisco (United States)",
  "Otisville (United States)",
  "Otoe (United States)",
  "Otsego (United States)",
  "Ottawa (United States)",
  "Otter (United States)",
  "Otter Lake (United States)",
  "Otterbein (United States)",
  "Ottertail (United States)",
  "Otto (United States)",
  "Ottosen (United States)",
  "Ottoville (United States)",
  "Ottsville (United States)",
  "Ottumwa (United States)",
  "Otway (United States)",
  "Ouray (United States)",
  "Outing (United States)",
  "Outlook (United States)",
  "Overbrook (United States)",
  "Overgaard (United States)",
  "Overland Park (United States)",
  "Overpeck (United States)",
  "Overton (United States)",
  "Ovid (United States)",
  "Oviedo (United States)",
  "Owaneco (United States)",
  "Owasso (United States)",
  "Owatonna (United States)",
  "Owego (United States)",
  "Owen (United States)",
  "Owens Cross Roads (United States)",
  "Owensboro (United States)",
  "Owensburg (United States)",
  "Owensville (United States)",
  "Owenton (United States)",
  "Owings (United States)",
  "Owings Mills (United States)",
  "Owingsville (United States)",
  "Owosso (United States)",
  "Oxford (United States)",
  "Oxford Junction (United States)",
  "Oxnard (United States)",
  "Oxon Hill (United States)",
  "Oyster Bay (United States)",
  "Ozan (United States)",
  "Ozark (United States)",
  "Ozawkie (United States)",
  "Ozona (United States)",
  "Ozone (United States)",
  "Ozone Park (United States)",
  "Pablo (United States)",
  "Pacific (United States)",
  "Pacific City (United States)",
  "Pacific Grove (United States)",
  "Pacific Junction (United States)",
  "Pacific Palisades (United States)",
  "Pacifica (United States)",
  "Packwood (United States)",
  "Pacoima (United States)",
  "Pacolet (United States)",
  "Paden (United States)",
  "Paden City (United States)",
  "Paducah (United States)",
  "Paeonian Springs (United States)",
  "Page (United States)",
  "Pageland (United States)",
  "Pageton (United States)",
  "Pagosa Springs (United States)",
  "Pahala (United States)",
  "Pahoa (United States)",
  "Pahokee (United States)",
  "Pahrump (United States)",
  "Paia (United States)",
  "Paige (United States)",
  "Painesdale (United States)",
  "Painesville (United States)",
  "Paint Lick (United States)",
  "Paint Rock (United States)",
  "Painted Post (United States)",
  "Painter (United States)",
  "Paintsville (United States)",
  "Pala (United States)",
  "Palacios (United States)",
  "Palatine (United States)",
  "Palatka (United States)",
  "Palermo (United States)",
  "Palestine (United States)",
  "Palisade (United States)",
  "Palisades (United States)",
  "Palisades Park (United States)",
  "Pall Mall (United States)",
  "Palm (United States)",
  "Palm Bay (United States)",
  "Palm Beach (United States)",
  "Palm Beach Gardens (United States)",
  "Palm City (United States)",
  "Palm Coast (United States)",
  "Palm Desert (United States)",
  "Palm Harbor (United States)",
  "Palm Springs (United States)",
  "Palmdale (United States)",
  "Palmer (United States)",
  "Palmer Lake (United States)",
  "Palmerdale (United States)",
  "Palmersville (United States)",
  "Palmerton (United States)",
  "Palmetto (United States)",
  "Palmyra (United States)",
  "Palo (United States)",
  "Palo Alto (United States)",
  "Palo Cedro (United States)",
  "Palo Verde (United States)",
  "Paloma (United States)",
  "Palos Heights (United States)",
  "Palos Hills (United States)",
  "Palos Park (United States)",
  "Palos Verdes Peninsula (United States)",
  "Palouse (United States)",
  "Pampa (United States)",
  "Pamplico (United States)",
  "Pamplin (United States)",
  "Pana (United States)",
  "Panaca (United States)",
  "Panacea (United States)",
  "Panama (United States)",
  "Panama City (United States)",
  "Panama City Beach (United States)",
  "Pandora (United States)",
  "Pangburn (United States)",
  "Panguitch (United States)",
  "Panhandle (United States)",
  "Panola (United States)",
  "Panora (United States)",
  "Panorama City (United States)",
  "Pansey (United States)",
  "Pantego (United States)",
  "Paola (United States)",
  "Paoli (United States)",
  "Paonia (United States)",
  "Papillion (United States)",
  "Parachute (United States)",
  "Paradis (United States)",
  "Paradise (United States)",
  "Paradise Valley (United States)",
  "Paradox (United States)",
  "Paragon (United States)",
  "Paragould (United States)",
  "Paramount (United States)",
  "Paramus (United States)",
  "Pardeeville (United States)",
  "Paris (United States)",
  "Parish (United States)",
  "Park (United States)",
  "Park City (United States)",
  "Park Falls (United States)",
  "Park Forest (United States)",
  "Park Hill (United States)",
  "Park Hills (United States)",
  "Park Rapids (United States)",
  "Park Ridge (United States)",
  "Park River (United States)",
  "Parker (United States)",
  "Parker City (United States)",
  "Parker Ford (United States)",
  "Parkers Lake (United States)",
  "Parkers Prairie (United States)",
  "Parkersburg (United States)",
  "Parkesburg (United States)",
  "Parkman (United States)",
  "Parksley (United States)",
  "Parkston (United States)",
  "Parkton (United States)",
  "Parkville (United States)",
  "Parlier (United States)",
  "Parlin (United States)",
  "Parma (United States)",
  "Parnell (United States)",
  "Parowan (United States)",
  "Parrish (United States)",
  "Parrottsville (United States)",
  "Parshall (United States)",
  "Parsippany (United States)",
  "Parsons (United States)",
  "Parsonsburg (United States)",
  "Parsonsfield (United States)",
  "Partlow (United States)",
  "Partridge (United States)",
  "Pasadena (United States)",
  "Pascagoula (United States)",
  "Pasco (United States)",
  "Pascoag (United States)",
  "Paso Robles (United States)",
  "Pass Christian (United States)",
  "Passaic (United States)",
  "Passumpsic (United States)",
  "Pataskala (United States)",
  "Patchogue (United States)",
  "Paterson (United States)",
  "Patoka (United States)",
  "Paton (United States)",
  "Patrick (United States)",
  "Patrick Springs (United States)",
  "Patriot (United States)",
  "Patten (United States)",
  "Patterson (United States)",
  "Pattersonville (United States)",
  "Pattison (United States)",
  "Patton (United States)",
  "Pattonsburg (United States)",
  "Pattonville (United States)",
  "Paul (United States)",
  "Paul Smiths (United States)",
  "Paulden (United States)",
  "Paulding (United States)",
  "Paulina (United States)",
  "Pauline (United States)",
  "Paullina (United States)",
  "Pauls Valley (United States)",
  "Paulsboro (United States)",
  "Pauma Valley (United States)",
  "Pavilion (United States)",
  "Pavillion (United States)",
  "Pavo (United States)",
  "Paw Paw (United States)",
  "Pawcatuck (United States)",
  "Pawhuska (United States)",
  "Pawlet (United States)",
  "Pawleys Island (United States)",
  "Pawling (United States)",
  "Pawnee (United States)",
  "Pawnee City (United States)",
  "Pawtucket (United States)",
  "Paxico (United States)",
  "Paxinos (United States)",
  "Paxton (United States)",
  "Payette (United States)",
  "Payne (United States)",
  "Paynesville (United States)",
  "Payneville (United States)",
  "Payson (United States)",
  "Pea Ridge (United States)",
  "Peabody (United States)",
  "Peace Dale (United States)",
  "Peach Bottom (United States)",
  "Peach Creek (United States)",
  "Peach Orchard (United States)",
  "Peach Springs (United States)",
  "Peachland (United States)",
  "Peachtree City (United States)",
  "Peak (United States)",
  "Peaks Island (United States)",
  "Peapack (United States)",
  "Pearblossom (United States)",
  "Pearce (United States)",
  "Pearcy (United States)",
  "Pearisburg (United States)",
  "Pearl (United States)",
  "Pearl City (United States)",
  "Pearl City, Manana (United States)",
  "Pearl River (United States)",
  "Pearland (United States)",
  "Pearlington (United States)",
  "Pearsall (United States)",
  "Pearson (United States)",
  "Pease (United States)",
  "Pebble Beach (United States)",
  "Pecatonica (United States)",
  "Peck (United States)",
  "Pecks Mill (United States)",
  "Peckville (United States)",
  "Peconic (United States)",
  "Pecos (United States)",
  "Peculiar (United States)",
  "Pedricktown (United States)",
  "Pedro Bay (United States)",
  "Peebles (United States)",
  "Peekskill (United States)",
  "Peel (United States)",
  "Peetz (United States)",
  "Peever (United States)",
  "Peggs (United States)",
  "Pegram (United States)",
  "Pekin (United States)",
  "Pelahatchie (United States)",
  "Pelham (United States)",
  "Pelican (United States)",
  "Pelican Lake (United States)",
  "Pelican Rapids (United States)",
  "Pelion (United States)",
  "Pelkie (United States)",
  "Pell City (United States)",
  "Pella (United States)",
  "Pellston (United States)",
  "Pelzer (United States)",
  "Pemberton (United States)",
  "Pemberville (United States)",
  "Pembina (United States)",
  "Pembine (United States)",
  "Pembroke (United States)",
  "Pembroke Pines (United States)",
  "Pen Argyl (United States)",
  "Penasco (United States)",
  "Pender (United States)",
  "Pendergrass (United States)",
  "Pendleton (United States)",
  "Penelope (United States)",
  "Penfield (United States)",
  "Pengilly (United States)",
  "Penhook (United States)",
  "Peninsula (United States)",
  "Penitas (United States)",
  "Penn (United States)",
  "Penn Laird (United States)",
  "Penn Run (United States)",
  "Penn Valley (United States)",
  "Penn Yan (United States)",
  "Pennellville (United States)",
  "Penney Farms (United States)",
  "Penngrove (United States)",
  "Pennington (United States)",
  "Pennington Gap (United States)",
  "Pennock (United States)",
  "Penns Grove (United States)",
  "Penns Park (United States)",
  "Pennsauken Township (United States)",
  "Pennsboro (United States)",
  "Pennsburg (United States)",
  "Pennsville Township (United States)",
  "Pennsylvania Furnace (United States)",
  "Pennville (United States)",
  "Penokee (United States)",
  "Penrose (United States)",
  "Penryn (United States)",
  "Pensacola (United States)",
  "Pentress (United States)",
  "Pentwater (United States)",
  "Peoria (United States)",
  "Peoria Heights (United States)",
  "Peosta (United States)",
  "Peotone (United States)",
  "Pepperell (United States)",
  "Pequannock (United States)",
  "Pequea (United States)",
  "Pequot Lakes (United States)",
  "Peralta (United States)",
  "Percy (United States)",
  "Perdido (United States)",
  "Perham (United States)",
  "Peridot (United States)",
  "Perkasie (United States)",
  "Perkins (United States)",
  "Perkinston (United States)",
  "Perkinsville (United States)",
  "Perkiomenville (United States)",
  "Perley (United States)",
  "Perrineville (United States)",
  "Perrinton (United States)",
  "Perris (United States)",
  "Perronville (United States)",
  "Perry (United States)",
  "Perry Hall (United States)",
  "Perry Point (United States)",
  "Perryman (United States)",
  "Perryopolis (United States)",
  "Perrysburg (United States)",
  "Perryton (United States)",
  "Perryville (United States)",
  "Pershing (United States)",
  "Persia (United States)",
  "Perth Amboy (United States)",
  "Peru (United States)",
  "Pescadero (United States)",
  "Peshastin (United States)",
  "Peshtigo (United States)",
  "Pesotum (United States)",
  "Petal (United States)",
  "Petaluma (United States)",
  "Peterborough (United States)",
  "Peterman (United States)",
  "Petersburg (United States)",
  "Petersham (United States)",
  "Peterson (United States)",
  "Peterstown (United States)",
  "Petoskey (United States)",
  "Petroleum (United States)",
  "Petrolia (United States)",
  "Petros (United States)",
  "Pettigrew (United States)",
  "Pevely (United States)",
  "Pewamo (United States)",
  "Pewaukee (United States)",
  "Pewee Valley (United States)",
  "Peyton (United States)",
  "Pfafftown (United States)",
  "Pflugerville (United States)",
  "Pharr (United States)",
  "Phelan (United States)",
  "Phelps (United States)",
  "Phenix (United States)",
  "Phenix City (United States)",
  "Phil Campbell (United States)",
  "Philadelphia (United States)",
  "Philip (United States)",
  "Philipp (United States)",
  "Philippi (United States)",
  "Philipsburg (United States)",
  "Phillips (United States)",
  "Phillipsburg (United States)",
  "Philo (United States)",
  "Philomath (United States)",
  "Philpot (United States)",
  "Phippsburg (United States)",
  "Phoenicia (United States)",
  "Phoenix (United States)",
  "Phoenixville (United States)",
  "Piasa (United States)",
  "Picabo (United States)",
  "Picayune (United States)",
  "Pickens (United States)",
  "Pickerington (United States)",
  "Pickett (United States)",
  "Pickstown (United States)",
  "Pickton (United States)",
  "Pico Rivera (United States)",
  "Picture Rocks (United States)",
  "Piedmont (United States)",
  "Pierce (United States)",
  "Pierce City (United States)",
  "Pierceton (United States)",
  "Piercy (United States)",
  "Piermont (United States)",
  "Pierpont (United States)",
  "Pierre (United States)",
  "Pierre Part (United States)",
  "Pierron (United States)",
  "Pierson (United States)",
  "Pierz (United States)",
  "Piffard (United States)",
  "Pigeon (United States)",
  "Pigeon Forge (United States)",
  "Piggott (United States)",
  "Pike Road (United States)",
  "Pikesville (United States)",
  "Piketon (United States)",
  "Pikeville (United States)",
  "Pillager (United States)",
  "Pilot (United States)",
  "Pilot Grove (United States)",
  "Pilot Hill (United States)",
  "Pilot Knob (United States)",
  "Pilot Mound (United States)",
  "Pilot Mountain (United States)",
  "Pilot Point (United States)",
  "Pilot Rock (United States)",
  "Pilot Station (United States)",
  "Pima (United States)",
  "Pima County (United States)",
  "Pimento (United States)",
  "Pinckney (United States)",
  "Pinckneyville (United States)",
  "Pinconning (United States)",
  "Pine (United States)",
  "Pine Beach (United States)",
  "Pine Bluff (United States)",
  "Pine Bluffs (United States)",
  "Pine Brook (United States)",
  "Pine Bush (United States)",
  "Pine City (United States)",
  "Pine Forge (United States)",
  "Pine Grove (United States)",
  "Pine Grove Mills (United States)",
  "Pine Hill (United States)",
  "Pine Island (United States)",
  "Pine Knot (United States)",
  "Pine Lake (United States)",
  "Pine Mountain (United States)",
  "Pine Mountain Club (United States)",
  "Pine Plains (United States)",
  "Pine Ridge (United States)",
  "Pine River (United States)",
  "Pine Top (United States)",
  "Pine Valley (United States)",
  "Pinebluff (United States)",
  "Pinecrest (United States)",
  "Pinedale (United States)",
  "Pinehill (United States)",
  "Pinehurst (United States)",
  "Pinellas Park (United States)",
  "Pineola (United States)",
  "Pinesdale (United States)",
  "Pinetop-Lakeside (United States)",
  "Pinetops (United States)",
  "Pinetown (United States)",
  "Pineville (United States)",
  "Pinewood (United States)",
  "Piney Creek (United States)",
  "Piney Flats (United States)",
  "Piney View (United States)",
  "Piney Woods (United States)",
  "Pingree (United States)",
  "Pink Hill (United States)",
  "Pinnacle (United States)",
  "Pinola (United States)",
  "Pinole (United States)",
  "Pinon (United States)",
  "Pinon Hills (United States)",
  "Pinson (United States)",
  "Pioche (United States)",
  "Pioneer (United States)",
  "Pioneertown (United States)",
  "Pipe Creek (United States)",
  "Piper City (United States)",
  "Pipersville (United States)",
  "Pipestone (United States)",
  "Piqua (United States)",
  "Pirtleville (United States)",
  "Piscataway (United States)",
  "Piseco (United States)",
  "Pisgah (United States)",
  "Pisgah Forest (United States)",
  "Pismo Beach (United States)",
  "Pitcairn (United States)",
  "Pitcher (United States)",
  "Pitkin (United States)",
  "Pitman (United States)",
  "Pittsboro (United States)",
  "Pittsburg (United States)",
  "Pittsburgh (United States)",
  "Pittsfield (United States)",
  "Pittsford (United States)",
  "Pittston (United States)",
  "Pittstown (United States)",
  "Pittsview (United States)",
  "Pittsville (United States)",
  "Pixley (United States)",
  "Placedo (United States)",
  "Placentia (United States)",
  "Placerville (United States)",
  "Placida (United States)",
  "Placitas (United States)",
  "Plain (United States)",
  "Plain City (United States)",
  "Plain Dealing (United States)",
  "Plainfield (United States)",
  "Plains (United States)",
  "Plainsboro (United States)",
  "Plainview (United States)",
  "Plainville (United States)",
  "Plainwell (United States)",
  "Plaistow (United States)",
  "Planada (United States)",
  "Plankinton (United States)",
  "Plano (United States)",
  "Plant City (United States)",
  "Plantation (United States)",
  "Plantersville (United States)",
  "Plantsville (United States)",
  "Plaquemine (United States)",
  "Plato (United States)",
  "Platte (United States)",
  "Platte City (United States)",
  "Plattekill (United States)",
  "Plattenville (United States)",
  "Platteville (United States)",
  "Plattsburg (United States)",
  "Plattsburgh (United States)",
  "Plattsmouth (United States)",
  "Playa del Rey (United States)",
  "Plaza (United States)",
  "Pleasant City (United States)",
  "Pleasant Dale (United States)",
  "Pleasant Garden (United States)",
  "Pleasant Grove (United States)",
  "Pleasant Hill (United States)",
  "Pleasant Hope (United States)",
  "Pleasant Lake (United States)",
  "Pleasant Mount (United States)",
  "Pleasant Plain (United States)",
  "Pleasant Plains (United States)",
  "Pleasant Prairie (United States)",
  "Pleasant Ridge (United States)",
  "Pleasant Shade (United States)",
  "Pleasant Unity (United States)",
  "Pleasant Valley (United States)",
  "Pleasant View (United States)",
  "Pleasanton (United States)",
  "Pleasantville (United States)",
  "Pleasureville (United States)",
  "Plentywood (United States)",
  "Plover (United States)",
  "Plum Branch (United States)",
  "Plum City (United States)",
  "Plumerville (United States)",
  "Plummer (United States)",
  "Plummers Landing (United States)",
  "Plumsteadville (United States)",
  "Plumville (United States)",
  "Plymouth (United States)",
  "Plymouth Meeting (United States)",
  "Plympton (United States)",
  "Poca (United States)",
  "Pocahontas (United States)",
  "Pocasset (United States)",
  "Pocatello (United States)",
  "Pocola (United States)",
  "Pocomoke City (United States)",
  "Pocono Pines (United States)",
  "Pocono Summit (United States)",
  "Poestenkill (United States)",
  "Point (United States)",
  "Point Arena (United States)",
  "Point Comfort (United States)",
  "Point Harbor (United States)",
  "Point Hope (United States)",
  "Point Lay (United States)",
  "Point Lookout (United States)",
  "Point of Rocks (United States)",
  "Point Pleasant (United States)",
  "Point Pleasant Beach (United States)",
  "Point Reyes Station (United States)",
  "Point Roberts (United States)",
  "Pointblank (United States)",
  "Polacca (United States)",
  "Poland (United States)",
  "Polk (United States)",
  "Polk City (United States)",
  "Polkton (United States)",
  "Polkville (United States)",
  "Pollard (United States)",
  "Pollock (United States)",
  "Pollock Pines (United States)",
  "Pollocksville (United States)",
  "Pollok (United States)",
  "Polo (United States)",
  "Polson (United States)",
  "Pomaria (United States)",
  "Pomerene (United States)",
  "Pomeroy (United States)",
  "Pomfret (United States)",
  "Pomfret Center (United States)",
  "Pomona (United States)",
  "Pomona Park (United States)",
  "Pompano Beach (United States)",
  "Pompey (United States)",
  "Pompton Lakes (United States)",
  "Pompton Plains (United States)",
  "Ponca (United States)",
  "Ponca City (United States)",
  "Ponce de Leon (United States)",
  "Ponchatoula (United States)",
  "Pond Creek (United States)",
  "Ponder (United States)",
  "Ponderay (United States)",
  "Ponemah (United States)",
  "Poneto (United States)",
  "Ponsford (United States)",
  "Ponte Vedra (United States)",
  "Ponte Vedra Beach (United States)",
  "Pontiac (United States)",
  "Pontotoc (United States)",
  "Pooler (United States)",
  "Poolesville (United States)",
  "Poolville (United States)",
  "Pope (United States)",
  "Pope Valley (United States)",
  "Poplar (United States)",
  "Poplar Bluff (United States)",
  "Poplar Branch (United States)",
  "Poplar Grove (United States)",
  "Poplarville (United States)",
  "Poquoson (United States)",
  "Porcupine (United States)",
  "Port Allegany (United States)",
  "Port Allen (United States)",
  "Port Angeles (United States)",
  "Port Aransas (United States)",
  "Port Arthur (United States)",
  "Port Austin (United States)",
  "Port Barre (United States)",
  "Port Bolivar (United States)",
  "Port Byron (United States)",
  "Port Carbon (United States)",
  "Port Charlotte (United States)",
  "Port Chester (United States)",
  "Port Clinton (United States)",
  "Port Crane (United States)",
  "Port Deposit (United States)",
  "Port Edwards (United States)",
  "Port Elizabeth (United States)",
  "Port Gibson (United States)",
  "Port Hadlock (United States)",
  "Port Henry (United States)",
  "Port Hope (United States)",
  "Port Hueneme (United States)",
  "Port Huron (United States)",
  "Port Isabel (United States)",
  "Port Jefferson (United States)",
  "Port Jefferson Station (United States)",
  "Port Jervis (United States)",
  "Port Lavaca (United States)",
  "Port Leyden (United States)",
  "Port Ludlow (United States)",
  "Port Mansfield (United States)",
  "Port Matilda (United States)",
  "Port Monmouth (United States)",
  "Port Murray (United States)",
  "Port Neches (United States)",
  "Port Norris (United States)",
  "Port Orange (United States)",
  "Port Orchard (United States)",
  "Port Orford (United States)",
  "Port Penn (United States)",
  "Port Reading (United States)",
  "Port Republic (United States)",
  "Port Richey (United States)",
  "Port Royal (United States)",
  "Port Saint Joe (United States)",
  "Port Saint Lucie (United States)",
  "Port Salerno (United States)",
  "Port Sanilac (United States)",
  "Port Tobacco (United States)",
  "Port Townsend (United States)",
  "Port Trevorton (United States)",
  "Port Washington (United States)",
  "Portage (United States)",
  "Portageville (United States)",
  "Portal (United States)",
  "Portales (United States)",
  "Porter (United States)",
  "Porter Corners (United States)",
  "Portersville (United States)",
  "Porterville (United States)",
  "Portland (United States)",
  "Portola (United States)",
  "Portola Valley (United States)",
  "Portsmouth (United States)",
  "Portville (United States)",
  "Porum (United States)",
  "Posen (United States)",
  "Poseyville (United States)",
  "Post (United States)",
  "Post Falls (United States)",
  "Post Mills (United States)",
  "Poston (United States)",
  "Postville (United States)",
  "Poteau (United States)",
  "Poteet (United States)",
  "Poth (United States)",
  "Potlatch (United States)",
  "Potomac (United States)",
  "Potosi (United States)",
  "Potsdam (United States)",
  "Potter (United States)",
  "Potter Valley (United States)",
  "Pottersville (United States)",
  "Potterville (United States)",
  "Potts Camp (United States)",
  "Pottsboro (United States)",
  "Pottstown (United States)",
  "Pottsville (United States)",
  "Potwin (United States)",
  "Poughkeepsie (United States)",
  "Poughquag (United States)",
  "Poulan (United States)",
  "Poulsbo (United States)",
  "Poultney (United States)",
  "Pound (United States)",
  "Pound Ridge (United States)",
  "Pounding Mill (United States)",
  "Poway (United States)",
  "Powder Springs (United States)",
  "Powderly (United States)",
  "Powell (United States)",
  "Powell Butte (United States)",
  "Powellsville (United States)",
  "Powellton (United States)",
  "Powers (United States)",
  "Powers Lake (United States)",
  "Powhatan (United States)",
  "Powhatan Point (United States)",
  "Pownal (United States)",
  "Poyen (United States)",
  "Poynette (United States)",
  "Poynor (United States)",
  "Poyntelle (United States)",
  "Prague (United States)",
  "Prairie (United States)",
  "Prairie City (United States)",
  "Prairie du Chien (United States)",
  "Prairie du Rocher (United States)",
  "Prairie du Sac (United States)",
  "Prairie Farm (United States)",
  "Prairie Grove (United States)",
  "Prairie View (United States)",
  "Prairie Village (United States)",
  "Prairieton (United States)",
  "Prairieville (United States)",
  "Prather (United States)",
  "Pratt (United States)",
  "Pratts (United States)",
  "Prattsburgh (United States)",
  "Prattsville (United States)",
  "Prattville (United States)",
  "Preble (United States)",
  "Preemption (United States)",
  "Premier (United States)",
  "Prentiss (United States)",
  "Prescott (United States)",
  "Prescott Valley (United States)",
  "Presho (United States)",
  "Presidio (United States)",
  "Presque Isle (United States)",
  "Presto (United States)",
  "Preston (United States)",
  "Preston City (United States)",
  "Preston Hollow (United States)",
  "Prestonsburg (United States)",
  "Prewitt (United States)",
  "Price (United States)",
  "Prichard (United States)",
  "Pride (United States)",
  "Priest River (United States)",
  "Primghar (United States)",
  "Primm Springs (United States)",
  "Primus (United States)",
  "Prince Frederick (United States)",
  "Prince George (United States)",
  "Princess Anne (United States)",
  "Princeton (United States)",
  "Princeton Junction (United States)",
  "Princeville (United States)",
  "Princewick (United States)",
  "Prineville (United States)",
  "Prinsburg (United States)",
  "Printer (United States)",
  "Prior Lake (United States)",
  "Pritchett (United States)",
  "Procious (United States)",
  "Proctor (United States)",
  "Proctorville (United States)",
  "Progreso (United States)",
  "Prompton (United States)",
  "Prophetstown (United States)",
  "Prospect (United States)",
  "Prospect Harbor (United States)",
  "Prospect Heights (United States)",
  "Prospect Park (United States)",
  "Prosper (United States)",
  "Prosperity (United States)",
  "Prosser (United States)",
  "Protection (United States)",
  "Protivin (United States)",
  "Providence (United States)",
  "Providence Forge (United States)",
  "Provincetown (United States)",
  "Provo (United States)",
  "Prudenville (United States)",
  "Prudhoe Bay (United States)",
  "Pryor (United States)",
  "Pueblo (United States)",
  "Puerto Rico (United States)",
  "Pukalani (United States)",
  "Pukwana (United States)",
  "Pulaski (United States)",
  "Pullman (United States)",
  "Pungoteague (United States)",
  "Punta Gorda (United States)",
  "Punxsutawney (United States)",
  "Purcell (United States)",
  "Purcellville (United States)",
  "Purchase (United States)",
  "Purdin (United States)",
  "Purdon (United States)",
  "Purdy (United States)",
  "Purdys (United States)",
  "Purgitsville (United States)",
  "Purlear (United States)",
  "Purling (United States)",
  "Purvis (United States)",
  "Puryear (United States)",
  "Put-in-Bay (United States)",
  "Putnam (United States)",
  "Putnam Station (United States)",
  "Putnam Valley (United States)",
  "Putney (United States)",
  "Puyallup (United States)",
  "Pylesville (United States)",
  "Quail (United States)",
  "Quakake (United States)",
  "Quaker City (United States)",
  "Quaker Hill (United States)",
  "Quakertown (United States)",
  "Quality (United States)",
  "Quanah (United States)",
  "Quantico (United States)",
  "Quapaw (United States)",
  "Quarryville (United States)",
  "Quartzsite (United States)",
  "Quasqueton (United States)",
  "Quebeck (United States)",
  "Quechee (United States)",
  "Queen Anne (United States)",
  "Queen City (United States)",
  "Queen Creek (United States)",
  "Queens Village (United States)",
  "Queensbury (United States)",
  "Queenstown (United States)",
  "Quemado (United States)",
  "Questa (United States)",
  "Quilcene (United States)",
  "Quimby (United States)",
  "Quincy (United States)",
  "Quinebaug (United States)",
  "Quinlan (United States)",
  "Quinnesec (United States)",
  "Quinter (United States)",
  "Quinton (United States)",
  "Quinwood (United States)",
  "Quitman (United States)",
  "Qulin (United States)",
  "Quogue (United States)",
  "Raccoon (United States)",
  "Raceland (United States)",
  "Racine (United States)",
  "Radcliff (United States)",
  "Radcliffe (United States)",
  "Radford (United States)",
  "Radisson (United States)",
  "Radnor (United States)",
  "Raeford (United States)",
  "Ragland (United States)",
  "Rahway (United States)",
  "Rainbow (United States)",
  "Rainbow City (United States)",
  "Rainelle (United States)",
  "Rainier (United States)",
  "Rainsville (United States)",
  "Raleigh (United States)",
  "Ralls (United States)",
  "Ralph (United States)",
  "Ralston (United States)",
  "Ramah (United States)",
  "Ramer (United States)",
  "Ramey (United States)",
  "Ramona (United States)",
  "Ramseur (United States)",
  "Ramsey (United States)",
  "Ranburne (United States)",
  "Ranchita (United States)",
  "Rancho Cordova (United States)",
  "Rancho Cucamonga (United States)",
  "Rancho Mirage (United States)",
  "Rancho Palos Verdes (United States)",
  "Rancho Santa Fe (United States)",
  "Rancho Santa Margarita (United States)",
  "Ranchos de Taos (United States)",
  "Rancocas (United States)",
  "Randall (United States)",
  "Randallstown (United States)",
  "Randle (United States)",
  "Randleman (United States)",
  "Randlett (United States)",
  "Randolph (United States)",
  "Randolph Center (United States)",
  "Randolph Township (United States)",
  "Random Lake (United States)",
  "Rangely (United States)",
  "Ranger (United States)",
  "Rankin (United States)",
  "Ransom (United States)",
  "Ransom Canyon (United States)",
  "Ransomville (United States)",
  "Ranson (United States)",
  "Rantoul (United States)",
  "Raphine (United States)",
  "Rapid City (United States)",
  "Rapid River (United States)",
  "Rapidan (United States)",
  "Raquette Lake (United States)",
  "Raritan (United States)",
  "Rathdrum (United States)",
  "Ratliff City (United States)",
  "Raton (United States)",
  "Rattan (United States)",
  "Ravalli (United States)",
  "Raven (United States)",
  "Ravena (United States)",
  "Ravencliff (United States)",
  "Ravenden (United States)",
  "Ravenel (United States)",
  "Ravenna (United States)",
  "Ravensdale (United States)",
  "Ravenswood (United States)",
  "Ravenwood (United States)",
  "Ravia (United States)",
  "Rawlings (United States)",
  "Rawlins (United States)",
  "Rawson (United States)",
  "Ray (United States)",
  "Ray Center (United States)",
  "Ray City (United States)",
  "Rayland (United States)",
  "Raymond (United States)",
  "Raymondville (United States)",
  "Raymore (United States)",
  "Rayne (United States)",
  "Raynham (United States)",
  "Rayville (United States)",
  "Raywick (United States)",
  "Readfield (United States)",
  "Reading (United States)",
  "Readlyn (United States)",
  "Readsboro (United States)",
  "Readyville (United States)",
  "Reamstown (United States)",
  "Reardan (United States)",
  "Rebecca (United States)",
  "Rebersburg (United States)",
  "Rector (United States)",
  "Red Bank (United States)",
  "Red Banks (United States)",
  "Red Bay (United States)",
  "Red Bluff (United States)",
  "Red Boiling Springs (United States)",
  "Red Bud (United States)",
  "Red Cloud (United States)",
  "Red Creek (United States)",
  "Red Hill (United States)",
  "Red Hook (United States)",
  "Red House (United States)",
  "Red Jacket (United States)",
  "Red Lake Falls (United States)",
  "Red Level (United States)",
  "Red Lion (United States)",
  "Red Lodge (United States)",
  "Red Oak (United States)",
  "Red River (United States)",
  "Red Rock (United States)",
  "Red Springs (United States)",
  "Red Wing (United States)",
  "Redan (United States)",
  "Redby (United States)",
  "Reddick (United States)",
  "Redding (United States)",
  "Redding Ridge (United States)",
  "Redfield (United States)",
  "Redford (United States)",
  "Redfox (United States)",
  "Redkey (United States)",
  "Redlands (United States)",
  "Redmon (United States)",
  "Redmond (United States)",
  "Redondo Beach (United States)",
  "Redwater (United States)",
  "Redway (United States)",
  "Redwood (United States)",
  "Redwood City (United States)",
  "Redwood Estates (United States)",
  "Redwood Falls (United States)",
  "Redwood Valley (United States)",
  "Reed City (United States)",
  "Reed Point (United States)",
  "Reedley (United States)",
  "Reeds (United States)",
  "Reeds Spring (United States)",
  "Reedsburg (United States)",
  "Reedsport (United States)",
  "Reedsville (United States)",
  "Reedville (United States)",
  "Reedy (United States)",
  "Reelsville (United States)",
  "Reese (United States)",
  "Reeseville (United States)",
  "Reevesville (United States)",
  "Reform (United States)",
  "Refugio (United States)",
  "Regent (United States)",
  "Register (United States)",
  "Rego Park (United States)",
  "Rehoboth (United States)",
  "Rehoboth Beach (United States)",
  "Reidsville (United States)",
  "Reidville (United States)",
  "Reinbeck (United States)",
  "Reinholds (United States)",
  "Reisterstown (United States)",
  "Reklaw (United States)",
  "Reliance (United States)",
  "Rembert (United States)",
  "Rembrandt (United States)",
  "Remer (United States)",
  "Remington (United States)",
  "Remlap (United States)",
  "Remote (United States)",
  "Remsen (United States)",
  "Remsenburg (United States)",
  "Remus (United States)",
  "Renfrew (United States)",
  "Renner (United States)",
  "Reno (United States)",
  "Renovo (United States)",
  "Rensselaer (United States)",
  "Rensselaer Falls (United States)",
  "Renton (United States)",
  "Rentz (United States)",
  "Renville (United States)",
  "Renwick (United States)",
  "Repton (United States)",
  "Republic (United States)",
  "Resaca (United States)",
  "Reseda (United States)",
  "Reserve (United States)",
  "Reston (United States)",
  "Revere (United States)",
  "Revillo (United States)",
  "Rex (United States)",
  "Rexburg (United States)",
  "Rexford (United States)",
  "Rexmont (United States)",
  "Rexville (United States)",
  "Reydell (United States)",
  "Reydon (United States)",
  "Reynolds (United States)",
  "Reynoldsburg (United States)",
  "Reynoldsville (United States)",
  "Rhame (United States)",
  "Rheems (United States)",
  "Rhine (United States)",
  "Rhinebeck (United States)",
  "Rhineland (United States)",
  "Rhinelander (United States)",
  "Rhoadesville (United States)",
  "Rhodelia (United States)",
  "Rhodell (United States)",
  "Rhodes (United States)",
  "Rhodesdale (United States)",
  "Rhodhiss (United States)",
  "Rhome (United States)",
  "Rialto (United States)",
  "Rib Lake (United States)",
  "Ribera (United States)",
  "Rice (United States)",
  "Rice Lake (United States)",
  "Rices Landing (United States)",
  "Riceville (United States)",
  "Rich Creek (United States)",
  "Rich Hill (United States)",
  "Rich Square (United States)",
  "Richards (United States)",
  "Richardson (United States)",
  "Richardton (United States)",
  "Richboro (United States)",
  "Richburg (United States)",
  "Richeyville (United States)",
  "Richfield (United States)",
  "Richfield Springs (United States)",
  "Richford (United States)",
  "Richgrove (United States)",
  "Richland (United States)",
  "Richland Center (United States)",
  "Richlands (United States)",
  "Richlandtown (United States)",
  "Richmond (United States)",
  "Richmond Hill (United States)",
  "Richton (United States)",
  "Richton Park (United States)",
  "Richvale (United States)",
  "Richview (United States)",
  "Richwood (United States)",
  "Rickman (United States)",
  "Rickreall (United States)",
  "Riddle (United States)",
  "Riddleton (United States)",
  "Riderwood (United States)",
  "Ridge (United States)",
  "Ridge Farm (United States)",
  "Ridge Spring (United States)",
  "Ridgecrest (United States)",
  "Ridgedale (United States)",
  "Ridgefield (United States)",
  "Ridgefield Park (United States)",
  "Ridgeland (United States)",
  "Ridgeley (United States)",
  "Ridgely (United States)",
  "Ridgeview (United States)",
  "Ridgeville (United States)",
  "Ridgeway (United States)",
  "Ridgewood (United States)",
  "Ridgway (United States)",
  "Ridley Park (United States)",
  "Ridott (United States)",
  "Riegelsville (United States)",
  "Riegelwood (United States)",
  "Rienzi (United States)",
  "Riesel (United States)",
  "Rifle (United States)",
  "Riga (United States)",
  "Rigby (United States)",
  "Riggins (United States)",
  "Riley (United States)",
  "Rillton (United States)",
  "Rimersburg (United States)",
  "Rimforest (United States)",
  "Rimrock (United States)",
  "Rinard (United States)",
  "Rincon (United States)",
  "Rindge (United States)",
  "Riner (United States)",
  "Rineyville (United States)",
  "Ringgold (United States)",
  "Ringle (United States)",
  "Ringling (United States)",
  "Ringoes (United States)",
  "Ringsted (United States)",
  "Ringtown (United States)",
  "Ringwood (United States)",
  "Rio (United States)",
  "Rio Dell (United States)",
  "Rio Grande (United States)",
  "Rio Grande City (United States)",
  "Rio Hondo (United States)",
  "Rio Linda (United States)",
  "Rio Nido (United States)",
  "Rio Rancho (United States)",
  "Rio Rico (United States)",
  "Rio Verde (United States)",
  "Rio Vista (United States)",
  "Ripley (United States)",
  "Ripon (United States)",
  "Rippey (United States)",
  "Ripplemead (United States)",
  "Ripton (United States)",
  "Rising City (United States)",
  "Rising Fawn (United States)",
  "Rising Star (United States)",
  "Rising Sun (United States)",
  "Risingsun (United States)",
  "Rison (United States)",
  "Rittman (United States)",
  "Ritzville (United States)",
  "Riva (United States)",
  "River Edge (United States)",
  "River Falls (United States)",
  "River Forest (United States)",
  "River Grove (United States)",
  "River Pines (United States)",
  "River Rouge (United States)",
  "Riverbank (United States)",
  "Riverdale (United States)",
  "Riverdale Hills (United States)",
  "Riverhead (United States)",
  "Riverside (United States)",
  "Riverside County (United States)",
  "Riverton (United States)",
  "Riverview (United States)",
  "Rives (United States)",
  "Rives Junction (United States)",
  "Rivesville (United States)",
  "Riviera (United States)",
  "Rixeyville (United States)",
  "Roach (United States)",
  "Roachdale (United States)",
  "Roan Mountain (United States)",
  "Roann (United States)",
  "Roanoke (United States)",
  "Roanoke Rapids (United States)",
  "Roaring Branch (United States)",
  "Roaring River (United States)",
  "Roaring Spring (United States)",
  "Robards (United States)",
  "Robbins (United States)",
  "Robbinsville (United States)",
  "Robersonville (United States)",
  "Robert (United States)",
  "Robert Lee (United States)",
  "Roberta (United States)",
  "Roberts (United States)",
  "Robertsdale (United States)",
  "Robertson (United States)",
  "Robertsville (United States)",
  "Robesonia (United States)",
  "Robins (United States)",
  "Robinson (United States)",
  "Robinson Creek (United States)",
  "Robinsonville (United States)",
  "Robstown (United States)",
  "Roby (United States)",
  "Roca (United States)",
  "Rochdale (United States)",
  "Rochelle (United States)",
  "Rochelle Park (United States)",
  "Rochester (United States)",
  "Rociada (United States)",
  "Rock (United States)",
  "Rock Creek (United States)",
  "Rock Falls (United States)",
  "Rock Hall (United States)",
  "Rock Hill (United States)",
  "Rock Island (United States)",
  "Rock Port (United States)",
  "Rock Rapids (United States)",
  "Rock River (United States)",
  "Rock Spring (United States)",
  "Rock Springs (United States)",
  "Rock Tavern (United States)",
  "Rock Valley (United States)",
  "Rock View (United States)",
  "Rockaway (United States)",
  "Rockaway Beach (United States)",
  "Rockaway Park (United States)",
  "Rockbridge (United States)",
  "Rockdale (United States)",
  "Rockfield (United States)",
  "Rockford (United States)",
  "Rockholds (United States)",
  "Rockhouse (United States)",
  "Rockingham (United States)",
  "Rockland (United States)",
  "Rockledge (United States)",
  "Rocklin (United States)",
  "Rockmart (United States)",
  "Rockport (United States)",
  "Rocksprings (United States)",
  "Rockton (United States)",
  "Rockvale (United States)",
  "Rockville (United States)",
  "Rockville Centre (United States)",
  "Rockwall (United States)",
  "Rockwell (United States)",
  "Rockwell City (United States)",
  "Rockwood (United States)",
  "Rocky (United States)",
  "Rocky Face (United States)",
  "Rocky Ford (United States)",
  "Rocky Gap (United States)",
  "Rocky Hill (United States)",
  "Rocky Mount (United States)",
  "Rocky Point (United States)",
  "Rocky River (United States)",
  "Rodanthe (United States)",
  "Rodeo (United States)",
  "Roderfield (United States)",
  "Rodman (United States)",
  "Rodney (United States)",
  "Roebling (United States)",
  "Roebuck (United States)",
  "Rogers (United States)",
  "Rogers City (United States)",
  "Rogersville (United States)",
  "Roggen (United States)",
  "Rogue River (United States)",
  "Rohnert Park (United States)",
  "Rohrersville (United States)",
  "Roland (United States)",
  "Rolesville (United States)",
  "Rolfe (United States)",
  "Roll (United States)",
  "Rolla (United States)",
  "Rolling Fork (United States)",
  "Rolling Meadows (United States)",
  "Rolling Prairie (United States)",
  "Rollingbay (United States)",
  "Rollingstone (United States)",
  "Rollinsford (United States)",
  "Roma (United States)",
  "Rome (United States)",
  "Rome City (United States)",
  "Romeo (United States)",
  "Romeoville (United States)",
  "Romney (United States)",
  "Romulus (United States)",
  "Ronald (United States)",
  "Ronan (United States)",
  "Ronceverte (United States)",
  "Ronda (United States)",
  "Ronkonkoma (United States)",
  "Ronks (United States)",
  "Roodhouse (United States)",
  "Roosevelt (United States)",
  "Rootstown (United States)",
  "Roper (United States)",
  "Ropesville (United States)",
  "Rosalia (United States)",
  "Rosamond (United States)",
  "Rosburg (United States)",
  "Roscoe (United States)",
  "Roscommon (United States)",
  "Rose Bud (United States)",
  "Rose City (United States)",
  "Rose Creek (United States)",
  "Rose Hill (United States)",
  "Roseau (United States)",
  "Roseboro (United States)",
  "Rosebud (United States)",
  "Roseburg (United States)",
  "Rosebush (United States)",
  "Rosedale (United States)",
  "Roseland (United States)",
  "Roselle (United States)",
  "Roselle Park (United States)",
  "Rosemary Beach (United States)",
  "Rosemead (United States)",
  "Rosemont (United States)",
  "Rosemount (United States)",
  "Rosenberg (United States)",
  "Rosendale (United States)",
  "Rosenhayn (United States)",
  "Rosepine (United States)",
  "Roseville (United States)",
  "Rosewood (United States)",
  "Rosharon (United States)",
  "Rosholt (United States)",
  "Rosiclare (United States)",
  "Rosine (United States)",
  "Roslindale (United States)",
  "Roslyn (United States)",
  "Roslyn Heights (United States)",
  "Rosman (United States)",
  "Ross (United States)",
  "Rosser (United States)",
  "Rossford (United States)",
  "Rossiter (United States)",
  "Rossville (United States)",
  "Roswell (United States)",
  "Rotan (United States)",
  "Rothbury (United States)",
  "Rothsay (United States)",
  "Rothschild (United States)",
  "Rotonda (United States)",
  "Rotterdam Junction (United States)",
  "Rougemont (United States)",
  "Rough and Ready (United States)",
  "Roulette (United States)",
  "Round Hill (United States)",
  "Round Lake (United States)",
  "Round Mountain (United States)",
  "Round O (United States)",
  "Round Rock (United States)",
  "Round Top (United States)",
  "Roundhill (United States)",
  "Roundup (United States)",
  "Rouses Point (United States)",
  "Rousseau (United States)",
  "Rowan (United States)",
  "Rowe (United States)",
  "Rowena (United States)",
  "Rowesville (United States)",
  "Rowland (United States)",
  "Rowland Heights (United States)",
  "Rowlesburg (United States)",
  "Rowlett (United States)",
  "Rowley (United States)",
  "Roxana (United States)",
  "Roxboro (United States)",
  "Roxbury (United States)",
  "Roxie (United States)",
  "Roy (United States)",
  "Roy-Winifred Junction (United States)",
  "Royal (United States)",
  "Royal Center (United States)",
  "Royal City (United States)",
  "Royal Oak (United States)",
  "Royal Palm Beach (United States)",
  "Royalton (United States)",
  "Royersford (United States)",
  "Royse City (United States)",
  "Royston (United States)",
  "Rozet (United States)",
  "Rubicon (United States)",
  "Ruby (United States)",
  "Ruckersville (United States)",
  "Rudd (United States)",
  "Rudolph (United States)",
  "Rudyard (United States)",
  "Ruffin (United States)",
  "Ruffs Dale (United States)",
  "Rugby (United States)",
  "Ruidoso (United States)",
  "Ruidoso Downs (United States)",
  "Rule (United States)",
  "Ruleville (United States)",
  "Rulo (United States)",
  "Rumford (United States)",
  "Rumney (United States)",
  "Rumsey (United States)",
  "Rumson (United States)",
  "Runge (United States)",
  "Runnells (United States)",
  "Runnemede (United States)",
  "Running Springs (United States)",
  "Rupert (United States)",
  "Rural Hall (United States)",
  "Rural Retreat (United States)",
  "Rural Ridge (United States)",
  "Rural Valley (United States)",
  "Rush (United States)",
  "Rush Center (United States)",
  "Rush City (United States)",
  "Rush Hill (United States)",
  "Rush Valley (United States)",
  "Rushford (United States)",
  "Rushmore (United States)",
  "Rushville (United States)",
  "Rusk (United States)",
  "Ruskin (United States)",
  "Russell (United States)",
  "Russell Springs (United States)",
  "Russells Point (United States)",
  "Russellton (United States)",
  "Russellville (United States)",
  "Russia (United States)",
  "Russian Mission (United States)",
  "Russiaville (United States)",
  "Rustburg (United States)",
  "Ruston (United States)",
  "Ruth (United States)",
  "Ruther Glen (United States)",
  "Rutherford (United States)",
  "Rutherfordton (United States)",
  "Ruthton (United States)",
  "Ruthven (United States)",
  "Rutland (United States)",
  "Rutledge (United States)",
  "Ryan (United States)",
  "Rydal (United States)",
  "Ryde (United States)",
  "Rye (United States)",
  "Sabattus (United States)",
  "Sabetha (United States)",
  "Sabillasville (United States)",
  "Sabin (United States)",
  "Sabina (United States)",
  "Sabinal (United States)",
  "Sabot (United States)",
  "Sabula (United States)",
  "Sac City (United States)",
  "Sacaton (United States)",
  "Sachse (United States)",
  "Sackets Harbor (United States)",
  "Saco (United States)",
  "Sacramento (United States)",
  "Sacred Heart (United States)",
  "Saddle Brook (United States)",
  "Saddle River (United States)",
  "Sadieville (United States)",
  "Sadler (United States)",
  "Saegertown (United States)",
  "Safety Harbor (United States)",
  "Safford (United States)",
  "Sag Harbor (United States)",
  "Sagamore (United States)",
  "Sagamore Beach (United States)",
  "Sagaponack (United States)",
  "Saginaw (United States)",
  "Sagle (United States)",
  "Saguache (United States)",
  "Sahuarita (United States)",
  "Sailor Springs (United States)",
  "Saint Agatha (United States)",
  "Saint Albans (United States)",
  "Saint Amant (United States)",
  "Saint Ann (United States)",
  "Saint Anne (United States)",
  "Saint Ansgar (United States)",
  "Saint Anthony (United States)",
  "Saint Bonaventure (United States)",
  "Saint Boniface (United States)",
  "Saint Bonifacius (United States)",
  "Saint Catharine (United States)",
  "Saint Charles (United States)",
  "Saint Clair (United States)",
  "Saint Clair Shores (United States)",
  "Saint Clairsville (United States)",
  "Saint Cloud (United States)",
  "Saint Croix Falls (United States)",
  "Saint David (United States)",
  "Saint Edward (United States)",
  "Saint Elizabeth (United States)",
  "Saint Elmo (United States)",
  "Saint Francis (United States)",
  "Saint Francisville (United States)",
  "Saint Gabriel (United States)",
  "Saint George (United States)",
  "Saint Germain (United States)",
  "Saint Hedwig (United States)",
  "Saint Helen (United States)",
  "Saint Helena (United States)",
  "Saint Helens (United States)",
  "Saint Henry (United States)",
  "Saint Hilaire (United States)",
  "Saint Ignace (United States)",
  "Saint Ignatius (United States)",
  "Saint Jacob (United States)",
  "Saint James (United States)",
  "Saint James City (United States)",
  "Saint Joe (United States)",
  "Saint John (United States)",
  "Saint Johns (United States)",
  "Saint Johnsbury (United States)",
  "Saint Johnsville (United States)",
  "Saint Joseph (United States)",
  "Saint Leo (United States)",
  "Saint Leonard (United States)",
  "Saint Libory (United States)",
  "Saint Louis (United States)",
  "Saint Louis Park (United States)",
  "Saint Louisville (United States)",
  "Saint Marie (United States)",
  "Saint Maries (United States)",
  "Saint Marks (United States)",
  "Saint Martin (United States)",
  "Saint Martinville (United States)",
  "Saint Mary (United States)",
  "Saint Marys (United States)",
  "Saint Matthews (United States)",
  "Saint Meinrad (United States)",
  "Saint Michael (United States)",
  "Saint Michaels (United States)",
  "Saint Nazianz (United States)",
  "Saint Onge (United States)",
  "Saint Paris (United States)",
  "Saint Paul (United States)",
  "Saint Paul Park (United States)",
  "Saint Pauls (United States)",
  "Saint Peter (United States)",
  "Saint Peters (United States)",
  "Saint Petersburg (United States)",
  "Saint Regis (United States)",
  "Saint Regis Falls (United States)",
  "Saint Robert (United States)",
  "Saint Simons Island (United States)",
  "Saint Stephen (United States)",
  "Saint Stephens (United States)",
  "Saint Thomas (United States)",
  "Sainte Marie (United States)",
  "Salado (United States)",
  "Salamanca (United States)",
  "Sale City (United States)",
  "Sale Creek (United States)",
  "Salem (United States)",
  "Salemburg (United States)",
  "Salesville (United States)",
  "Salida (United States)",
  "Salina (United States)",
  "Salinas (United States)",
  "Saline (United States)",
  "Salineville (United States)",
  "Salisbury (United States)",
  "Salisbury Mills (United States)",
  "Salix (United States)",
  "Salkum (United States)",
  "Sallis (United States)",
  "Sallisaw (United States)",
  "Salmon (United States)",
  "Salome (United States)",
  "Salt Lake City (United States)",
  "Salt Lick (United States)",
  "Salt Point (United States)",
  "Salt Rock (United States)",
  "Salters (United States)",
  "Saltillo (United States)",
  "Salton City (United States)",
  "Saltsburg (United States)",
  "Saltville (United States)",
  "Saluda (United States)",
  "Salvisa (United States)",
  "Salyer (United States)",
  "Salyersville (United States)",
  "Sammamish (United States)",
  "Samoa (United States)",
  "Samson (United States)",
  "San Andreas (United States)",
  "San Angelo (United States)",
  "San Anselmo (United States)",
  "San Antonio (United States)",
  "San Augustine (United States)",
  "San Benito (United States)",
  "San Bernardino (United States)",
  "San Bernardino County (United States)",
  "San Bruno (United States)",
  "San Carlos (United States)",
  "San Clemente (United States)",
  "San Cristobal (United States)",
  "San Diego (United States)",
  "San Dimas (United States)",
  "San Elizario (United States)",
  "San Felipe (United States)",
  "San Fernando (United States)",
  "San Fidel (United States)",
  "San Francisco (United States)",
  "San Gabriel (United States)",
  "San Geronimo (United States)",
  "San Gregorio (United States)",
  "San Jacinto (United States)",
  "San Joaquin (United States)",
  "San Jon (United States)",
  "San Jose (United States)",
  "San Juan (United States)",
  "San Juan Bautista (United States)",
  "San Juan Capistrano (United States)",
  "San Leandro (United States)",
  "San Lorenzo (United States)",
  "San Luis (United States)",
  "San Luis Obispo (United States)",
  "San Luis Rey Heights (United States)",
  "San Manuel (United States)",
  "San Marcos (United States)",
  "San Marino (United States)",
  "San Martin (United States)",
  "San Mateo (United States)",
  "San Miguel (United States)",
  "San Pablo (United States)",
  "San Pedro (United States)",
  "San Pierre (United States)",
  "San Rafael (United States)",
  "San Ramon (United States)",
  "San Saba (United States)",
  "San Simeon (United States)",
  "San Simon (United States)",
  "San Ysidro (United States)",
  "Sanborn (United States)",
  "Sanbornton (United States)",
  "Sanbornville (United States)",
  "Sand Coulee (United States)",
  "Sand Creek (United States)",
  "Sand Fork (United States)",
  "Sand Lake (United States)",
  "Sand Point (United States)",
  "Sand Springs (United States)",
  "Sandborn (United States)",
  "Sanders (United States)",
  "Sanderson (United States)",
  "Sandersville (United States)",
  "Sandia (United States)",
  "Sandia Park (United States)",
  "Sandoval (United States)",
  "Sandoval County (United States)",
  "Sandown (United States)",
  "Sandpoint (United States)",
  "Sandston (United States)",
  "Sandstone (United States)",
  "Sandusky (United States)",
  "Sandwich (United States)",
  "Sandy (United States)",
  "Sandy City (United States)",
  "Sandy Creek (United States)",
  "Sandy Hook (United States)",
  "Sandy Lake (United States)",
  "Sandy Ridge (United States)",
  "Sandy Spring (United States)",
  "Sandyville (United States)",
  "Sanford (United States)",
  "Sanger (United States)",
  "Sangerville (United States)",
  "Sanibel (United States)",
  "Santa Ana (United States)",
  "Santa Ana Heights (United States)",
  "Santa Anna (United States)",
  "Santa Barbara (United States)",
  "Santa Clara (United States)",
  "Santa Clarita (United States)",
  "Santa Claus (United States)",
  "Santa Cruz (United States)",
  "Santa Fe (United States)",
  "Santa Fe Springs (United States)",
  "Santa Margarita (United States)",
  "Santa Maria (United States)",
  "Santa Monica (United States)",
  "Santa Paula (United States)",
  "Santa Rosa (United States)",
  "Santa Rosa Beach (United States)",
  "Santa Teresa (United States)",
  "Santa Ynez (United States)",
  "Santa Ysabel (United States)",
  "Santaquin (United States)",
  "Santee (United States)",
  "Santo (United States)",
  "Sapphire (United States)",
  "Sapulpa (United States)",
  "Sarah (United States)",
  "Sarah Ann (United States)",
  "Sarahsville (United States)",
  "Saraland (United States)",
  "Saranac (United States)",
  "Saranac Lake (United States)",
  "Sarasota (United States)",
  "Saratoga (United States)",
  "Saratoga Springs (United States)",
  "Sarcoxie (United States)",
  "Sardinia (United States)",
  "Sardis (United States)",
  "Sarepta (United States)",
  "Sargent (United States)",
  "Sarita (United States)",
  "Sarona (United States)",
  "Saronville (United States)",
  "Sartell (United States)",
  "Sarver (United States)",
  "Sasakwa (United States)",
  "Satanta (United States)",
  "Satartia (United States)",
  "Satellite Beach (United States)",
  "Satsop (United States)",
  "Satsuma (United States)",
  "Saucier (United States)",
  "Saugatuck (United States)",
  "Saugerties (United States)",
  "Saugus (United States)",
  "Sauk Centre (United States)",
  "Sauk City (United States)",
  "Sauk Rapids (United States)",
  "Saukville (United States)",
  "Saulsbury (United States)",
  "Saulsville (United States)",
  "Sault Ste. Marie (United States)",
  "Saunderstown (United States)",
  "Saunemin (United States)",
  "Sauquoit (United States)",
  "Sausalito (United States)",
  "Savage (United States)",
  "Savanna (United States)",
  "Savannah (United States)",
  "Savonburg (United States)",
  "Savoy (United States)",
  "Sawyer (United States)",
  "Saxon (United States)",
  "Saxonburg (United States)",
  "Saxton (United States)",
  "Saxtons River (United States)",
  "Saybrook (United States)",
  "Saylorsburg (United States)",
  "Sayre (United States)",
  "Sayreville (United States)",
  "Sayville (United States)",
  "Scales Mound (United States)",
  "Scammon Bay (United States)",
  "Scandia (United States)",
  "Scandinavia (United States)",
  "Scappoose (United States)",
  "Scarborough (United States)",
  "Scarbro (United States)",
  "Scarsdale (United States)",
  "Scarville (United States)",
  "Scenery Hill (United States)",
  "Schaefferstown (United States)",
  "Schaghticoke (United States)",
  "Schaller (United States)",
  "Schaumburg (United States)",
  "Schellsburg (United States)",
  "Schenectady (United States)",
  "Schenevus (United States)",
  "Schenley (United States)",
  "Schererville (United States)",
  "Schertz (United States)",
  "Schiller Park (United States)",
  "Schlater (United States)",
  "Schleswig (United States)",
  "Schnecksville (United States)",
  "Schneider (United States)",
  "Schodack Landing (United States)",
  "Schoenchen (United States)",
  "Schofield (United States)",
  "Schoharie (United States)",
  "Schoolcraft (United States)",
  "Schroon Lake (United States)",
  "Schulenburg (United States)",
  "Schulter (United States)",
  "Schurz (United States)",
  "Schuyler (United States)",
  "Schuylerville (United States)",
  "Schuylkill Haven (United States)",
  "Schwenksville (United States)",
  "Science Hill (United States)",
  "Scio (United States)",
  "Sciota (United States)",
  "Scipio (United States)",
  "Scipio Center (United States)",
  "Scituate (United States)",
  "Scobey (United States)",
  "Scotch Plains (United States)",
  "Scotia (United States)",
  "Scotland (United States)",
  "Scotland Neck (United States)",
  "Scotrun (United States)",
  "Scott (United States)",
  "Scott Air Force Base (United States)",
  "Scott City (United States)",
  "Scott Depot (United States)",
  "Scottdale (United States)",
  "Scottown (United States)",
  "Scotts (United States)",
  "Scotts Hill (United States)",
  "Scotts Mills (United States)",
  "Scotts Valley (United States)",
  "Scottsbluff (United States)",
  "Scottsboro (United States)",
  "Scottsburg (United States)",
  "Scottsdale (United States)",
  "Scottsville (United States)",
  "Scottville (United States)",
  "Scranton (United States)",
  "Scribner (United States)",
  "Scroggins (United States)",
  "Scurry (United States)",
  "Sea Cliff (United States)",
  "Sea Girt (United States)",
  "Sea Island (United States)",
  "Sea Isle City (United States)",
  "Sea Ranch (United States)",
  "Seabeck (United States)",
  "Seabrook (United States)",
  "Seadrift (United States)",
  "Seaford (United States)",
  "Seagoville (United States)",
  "Seagraves (United States)",
  "Seagrove (United States)",
  "Seahurst (United States)",
  "Seal (United States)",
  "Seal Beach (United States)",
  "Seal Cove (United States)",
  "Seal Rock (United States)",
  "Seale (United States)",
  "Sealevel (United States)",
  "Sealy (United States)",
  "Seaman (United States)",
  "Searcy (United States)",
  "Searsboro (United States)",
  "Searsmont (United States)",
  "Seaside (United States)",
  "Seaside Heights (United States)",
  "Seaside Park (United States)",
  "Seaton (United States)",
  "Seatonville (United States)",
  "Seattle (United States)",
  "Sebastian (United States)",
  "Sebastopol (United States)",
  "Sebec (United States)",
  "Sebeka (United States)",
  "Sebewaing (United States)",
  "Sebree (United States)",
  "Sebring (United States)",
  "Secaucus (United States)",
  "Second Mesa (United States)",
  "Secor (United States)",
  "Section (United States)",
  "Sedalia (United States)",
  "Sedan (United States)",
  "Sedgwick (United States)",
  "Sedona (United States)",
  "Sedro-Woolley (United States)",
  "Seekonk (United States)",
  "Seeley (United States)",
  "Seeley Lake (United States)",
  "Seffner (United States)",
  "Seguin (United States)",
  "Seiling (United States)",
  "Selah (United States)",
  "Selawik (United States)",
  "Selby (United States)",
  "Selbyville (United States)",
  "Selden (United States)",
  "Seligman (United States)",
  "Selinsgrove (United States)",
  "Selkirk (United States)",
  "Sellersburg (United States)",
  "Sellersville (United States)",
  "Sells (United States)",
  "Selma (United States)",
  "Selmer (United States)",
  "Seminary (United States)",
  "Seminole (United States)",
  "Semmes (United States)",
  "Senath (United States)",
  "Senatobia (United States)",
  "Seneca (United States)",
  "Seneca Falls (United States)",
  "Senecaville (United States)",
  "Senoia (United States)",
  "Sentinel Butte (United States)",
  "Sequim (United States)",
  "Sequoia (United States)",
  "Serafina (United States)",
  "Serena (United States)",
  "Sergeant Bluff (United States)",
  "Sergeantsville (United States)",
  "Sesser (United States)",
  "Seth (United States)",
  "Seven Mile (United States)",
  "Seven Springs (United States)",
  "Seven Valleys (United States)",
  "Severance (United States)",
  "Severn (United States)",
  "Severna Park (United States)",
  "Severy (United States)",
  "Sevierville (United States)",
  "Seville (United States)",
  "Sewanee (United States)",
  "Seward (United States)",
  "Sewaren (United States)",
  "Sewell (United States)",
  "Sewickley (United States)",
  "Seymour (United States)",
  "Shabbona (United States)",
  "Shacklefords (United States)",
  "Shade Gap (United States)",
  "Shady Cove (United States)",
  "Shady Dale (United States)",
  "Shady Point (United States)",
  "Shady Side (United States)",
  "Shady Spring (United States)",
  "Shady Valley (United States)",
  "Shadyside (United States)",
  "Shafer (United States)",
  "Shafter (United States)",
  "Shaftsbury (United States)",
  "Shakopee (United States)",
  "Shalimar (United States)",
  "Shallotte (United States)",
  "Shallowater (United States)",
  "Shamokin (United States)",
  "Shamokin Dam (United States)",
  "Shamrock (United States)",
  "Shandaken (United States)",
  "Shandon (United States)",
  "Shanks (United States)",
  "Shanksville (United States)",
  "Shannon (United States)",
  "Shannon City (United States)",
  "Shapleigh (United States)",
  "Sharon (United States)",
  "Sharon Center (United States)",
  "Sharon Hill (United States)",
  "Sharon Springs (United States)",
  "Sharpsburg (United States)",
  "Sharpsville (United States)",
  "Sharptown (United States)",
  "Shartlesville (United States)",
  "Shasta (United States)",
  "Shattuck (United States)",
  "Shavertown (United States)",
  "Shaw (United States)",
  "Shaw Island (United States)",
  "Shawano (United States)",
  "Shawboro (United States)",
  "Shawnee (United States)",
  "Shawnee on Delaware (United States)",
  "Shawneetown (United States)",
  "Shawsville (United States)",
  "Sheboygan (United States)",
  "Sheboygan Falls (United States)",
  "Shedd (United States)",
  "Sheep Springs (United States)",
  "Sheffield (United States)",
  "Sheffield Lake (United States)",
  "Shelbiana (United States)",
  "Shelburn (United States)",
  "Shelburne (United States)",
  "Shelburne Falls (United States)",
  "Shelby (United States)",
  "Shelbyville (United States)",
  "Sheldahl (United States)",
  "Sheldon (United States)",
  "Sheldon Springs (United States)",
  "Shell (United States)",
  "Shell Knob (United States)",
  "Shell Lake (United States)",
  "Shell Rock (United States)",
  "Shelley (United States)",
  "Shellman (United States)",
  "Shellsburg (United States)",
  "Shelly (United States)",
  "Shelocta (United States)",
  "Shelter Island (United States)",
  "Shelter Island Heights (United States)",
  "Shelton (United States)",
  "Shenandoah (United States)",
  "Shenandoah Junction (United States)",
  "Shepherd (United States)",
  "Shepherdstown (United States)",
  "Shepherdsville (United States)",
  "Sherborn (United States)",
  "Sherburn (United States)",
  "Sherburne (United States)",
  "Sheridan (United States)",
  "Sherman (United States)",
  "Sherman Oaks (United States)",
  "Shermans Dale (United States)",
  "Sherrard (United States)",
  "Sherrill (United States)",
  "Sherrills Ford (United States)",
  "Sherrodsville (United States)",
  "Sherwood (United States)",
  "Shevlin (United States)",
  "Sheyenne (United States)",
  "Shickley (United States)",
  "Shickshinny (United States)",
  "Shidler (United States)",
  "Shiloh (United States)",
  "Shiner (United States)",
  "Shingle Springs (United States)",
  "Shinglehouse (United States)",
  "Shingleton (United States)",
  "Shingletown (United States)",
  "Shinnston (United States)",
  "Shiocton (United States)",
  "Shipman (United States)",
  "Shippensburg (United States)",
  "Shippenville (United States)",
  "Shippingport (United States)",
  "Shiprock (United States)",
  "Shipshewana (United States)",
  "Shirley (United States)",
  "Shirley Mills (United States)",
  "Shirleysburg (United States)",
  "Shoals (United States)",
  "Shobonier (United States)",
  "Shoemakersville (United States)",
  "Shohola (United States)",
  "Shokan (United States)",
  "Shongaloo (United States)",
  "Shoreham (United States)",
  "Shorewood (United States)",
  "Short Hills (United States)",
  "Shoshone (United States)",
  "Shoshoni (United States)",
  "Show Low (United States)",
  "Shreve (United States)",
  "Shreveport (United States)",
  "Shrewsbury (United States)",
  "Shrub Oak (United States)",
  "Shubuta (United States)",
  "Shumway (United States)",
  "Shungnak (United States)",
  "Shutesbury (United States)",
  "Sibley (United States)",
  "Sicily Island (United States)",
  "Sicklerville (United States)",
  "Side Lake (United States)",
  "Sidell (United States)",
  "Sidman (United States)",
  "Sidney (United States)",
  "Sidney Center (United States)",
  "Sidon (United States)",
  "Siemens (United States)",
  "Sierra Madre (United States)",
  "Sierra Vista (United States)",
  "Signal Hill (United States)",
  "Signal Mountain (United States)",
  "Sigourney (United States)",
  "Sikes (United States)",
  "Sikeston (United States)",
  "Silas (United States)",
  "Siler City (United States)",
  "Silex (United States)",
  "Siloam (United States)",
  "Siloam Springs (United States)",
  "Silsbee (United States)",
  "Silt (United States)",
  "Silva (United States)",
  "Silvana (United States)",
  "Silver Bay (United States)",
  "Silver City (United States)",
  "Silver Creek (United States)",
  "Silver Grove (United States)",
  "Silver Lake (United States)",
  "Silver Spring (United States)",
  "Silver Springs (United States)",
  "Silverado (United States)",
  "Silverdale (United States)",
  "Silverhill (United States)",
  "Silverstreet (United States)",
  "Silverthorne (United States)",
  "Silverton (United States)",
  "Silvis (United States)",
  "Simi Valley (United States)",
  "Simla (United States)",
  "Simmesport (United States)",
  "Simms (United States)",
  "Simon (United States)",
  "Simonton (United States)",
  "Simpson (United States)",
  "Simpsonville (United States)",
  "Sims (United States)",
  "Simsboro (United States)",
  "Simsbury (United States)",
  "Sinclair (United States)",
  "Sinclairville (United States)",
  "Sinks Grove (United States)",
  "Sinton (United States)",
  "Sioux Center (United States)",
  "Sioux City (United States)",
  "Sioux Falls (United States)",
  "Sioux Rapids (United States)",
  "Sipesville (United States)",
  "Sipsey (United States)",
  "Siren (United States)",
  "Sisseton (United States)",
  "Sister Bay (United States)",
  "Sisters (United States)",
  "Sistersville (United States)",
  "Sitka (United States)",
  "Six Lakes (United States)",
  "Six Mile (United States)",
  "Skaguay (United States)",
  "Skamokawa (United States)",
  "Skandia (United States)",
  "Skaneateles (United States)",
  "Skaneateles Falls (United States)",
  "Skanee (United States)",
  "Skellytown (United States)",
  "Skiatook (United States)",
  "Skidmore (United States)",
  "Skillman (United States)",
  "Skippack (United States)",
  "Skokie (United States)",
  "Skowhegan (United States)",
  "Skyforest (United States)",
  "Skykomish (United States)",
  "Skyland (United States)",
  "Slagle (United States)",
  "Slanesville (United States)",
  "Slate Hill (United States)",
  "Slater (United States)",
  "Slatersville (United States)",
  "Slatington (United States)",
  "Slaton (United States)",
  "Slaty Fork (United States)",
  "Slaughter (United States)",
  "Slaughters (United States)",
  "Slayton (United States)",
  "Sleepy Eye (United States)",
  "Slidell (United States)",
  "Sligo (United States)",
  "Slinger (United States)",
  "Slingerlands (United States)",
  "Slippery Rock (United States)",
  "Sloan (United States)",
  "Sloansville (United States)",
  "Sloatsburg (United States)",
  "Slocomb (United States)",
  "Sloughhouse (United States)",
  "Slovan (United States)",
  "Smackover (United States)",
  "Smarr (United States)",
  "Smartt (United States)",
  "Smethport (United States)",
  "Smilax (United States)",
  "Smiley (United States)",
  "Smith Center (United States)",
  "Smith River (United States)",
  "Smithers (United States)",
  "Smithfield (United States)",
  "Smithland (United States)",
  "Smiths (United States)",
  "Smiths Creek (United States)",
  "Smiths Grove (United States)",
  "Smithsburg (United States)",
  "Smithshire (United States)",
  "Smithton (United States)",
  "Smithtown (United States)",
  "Smithville (United States)",
  "Smithville Flats (United States)",
  "Smoaks (United States)",
  "Smock (United States)",
  "Smoke Run (United States)",
  "Smoketown (United States)",
  "Smyer (United States)",
  "Smyrna (United States)",
  "Smyrna Mills (United States)",
  "Sneads (United States)",
  "Sneads Ferry (United States)",
  "Sneedville (United States)",
  "Snelling (United States)",
  "Snellville (United States)",
  "Snohomish (United States)",
  "Snoqualmie (United States)",
  "Snover (United States)",
  "Snow Camp (United States)",
  "Snow Hill (United States)",
  "Snow Shoe (United States)",
  "Snowflake (United States)",
  "Snowmass (United States)",
  "Snowmass Village (United States)",
  "Snyder (United States)",
  "Soap Lake (United States)",
  "Sobieski (United States)",
  "Social Circle (United States)",
  "Society Hill (United States)",
  "Socorro (United States)",
  "Sod (United States)",
  "Soda Springs (United States)",
  "Soddy-Daisy (United States)",
  "Sodus (United States)",
  "Solana Beach (United States)",
  "Soldier (United States)",
  "Soldiers Grove (United States)",
  "Soldotna (United States)",
  "Solebury (United States)",
  "Soledad (United States)",
  "Solen (United States)",
  "Solomons (United States)",
  "Solon (United States)",
  "Solon Springs (United States)",
  "Solsberry (United States)",
  "Solvang (United States)",
  "Solway (United States)",
  "Somerdale (United States)",
  "Somers (United States)",
  "Somers Point (United States)",
  "Somerset (United States)",
  "Somerset Center (United States)",
  "Somersworth (United States)",
  "Somerton (United States)",
  "Somerville (United States)",
  "Somis (United States)",
  "Somonauk (United States)",
  "Sonoita (United States)",
  "Sonoma (United States)",
  "Sonora (United States)",
  "Sopchoppy (United States)",
  "Soper (United States)",
  "Soperton (United States)",
  "Sophia (United States)",
  "Soquel (United States)",
  "Sorento (United States)",
  "Sorrento (United States)",
  "Soso (United States)",
  "Soudan (United States)",
  "Souderton (United States)",
  "Soulsbyville (United States)",
  "Sound Beach (United States)",
  "Sour Lake (United States)",
  "South Amboy (United States)",
  "South Barre (United States)",
  "South Bay (United States)",
  "South Beach (United States)",
  "South Beloit (United States)",
  "South Bend (United States)",
  "South Berwick (United States)",
  "South Boardman (United States)",
  "South Boston (United States)",
  "South Bound Brook (United States)",
  "South Bristol (United States)",
  "South Burlington (United States)",
  "South Cairo (United States)",
  "South Canaan (United States)",
  "South Casco (United States)",
  "South Charleston (United States)",
  "South China (United States)",
  "South Dartmouth (United States)",
  "South Deerfield (United States)",
  "South Dennis (United States)",
  "South Easton (United States)",
  "South Egremont (United States)",
  "South El Monte (United States)",
  "South Elgin (United States)",
  "South English (United States)",
  "South Fallsburg (United States)",
  "South Fork (United States)",
  "South Freeport (United States)",
  "South Fulton (United States)",
  "South Gate (United States)",
  "South Gibson (United States)",
  "South Glastonbury (United States)",
  "South Glens Falls (United States)",
  "South Hackensack (United States)",
  "South Hadley (United States)",
  "South Hamilton (United States)",
  "South Harwich (United States)",
  "South Haven (United States)",
  "South Hero (United States)",
  "South Hill (United States)",
  "South Holland (United States)",
  "South Houston (United States)",
  "South Hutchinson (United States)",
  "South Jamesport (United States)",
  "South Jordan (United States)",
  "South Kent (United States)",
  "South Lake Tahoe (United States)",
  "South Lancaster (United States)",
  "South Lebanon (United States)",
  "South Londonderry (United States)",
  "South Lyon (United States)",
  "South Mills (United States)",
  "South Milwaukee (United States)",
  "South New Berlin (United States)",
  "South Newfane (United States)",
  "South Orange (United States)",
  "South Otselic (United States)",
  "South Ozone Park (United States)",
  "South Padre Island (United States)",
  "South Paris (United States)",
  "South Park Township (United States)",
  "South Pasadena (United States)",
  "South Pekin (United States)",
  "South Pittsburg (United States)",
  "South Plainfield (United States)",
  "South Plymouth (United States)",
  "South Point (United States)",
  "South Pomfret (United States)",
  "South Portland (United States)",
  "South Prairie (United States)",
  "South Range (United States)",
  "South River (United States)",
  "South Rockwood (United States)",
  "South Roxana (United States)",
  "South Royalton (United States)",
  "South Ryegate (United States)",
  "South Saint Paul (United States)",
  "South Salem (United States)",
  "South Sanford (United States)",
  "South Seaville (United States)",
  "South Shore (United States)",
  "South Sioux City (United States)",
  "South Solon (United States)",
  "South Sterling (United States)",
  "South Strafford (United States)",
  "South Tamworth (United States)",
  "South Vienna (United States)",
  "South Wales (United States)",
  "South Wayne (United States)",
  "South Webster (United States)",
  "South Wellfleet (United States)",
  "South West City (United States)",
  "South Weymouth (United States)",
  "South Whitley (United States)",
  "South Williamson (United States)",
  "South Willington (United States)",
  "South Wilmington (United States)",
  "South Windsor (United States)",
  "South Woodstock (United States)",
  "South Yarmouth (United States)",
  "Southampton (United States)",
  "Southaven (United States)",
  "Southborough (United States)",
  "Southbridge (United States)",
  "Southbury (United States)",
  "Southern Pines (United States)",
  "Southfield (United States)",
  "Southfields (United States)",
  "Southgate (United States)",
  "Southington (United States)",
  "Southlake (United States)",
  "Southmayd (United States)",
  "Southold (United States)",
  "Southport (United States)",
  "Southside (United States)",
  "Southview (United States)",
  "Southwest Harbor (United States)",
  "Southwick (United States)",
  "Spain (United States)",
  "Spalding (United States)",
  "Spanaway (United States)",
  "Spanish Fork (United States)",
  "Spanish Fort (United States)",
  "Spanishburg (United States)",
  "Sparkill (United States)",
  "Sparkman (United States)",
  "Sparks (United States)",
  "Sparland (United States)",
  "Sparrow Bush (United States)",
  "Sparrows Point (United States)",
  "Sparta (United States)",
  "Spartanburg (United States)",
  "Spartansburg (United States)",
  "Spavinaw (United States)",
  "Spearfish (United States)",
  "Spearman (United States)",
  "Spearsville (United States)",
  "Spearville (United States)",
  "Speculator (United States)",
  "Spencer (United States)",
  "Spencerport (United States)",
  "Spencertown (United States)",
  "Spencerville (United States)",
  "Sperry (United States)",
  "Sperryville (United States)",
  "Spiceland (United States)",
  "Spicer (United States)",
  "Spicewood (United States)",
  "Spickard (United States)",
  "Spindale (United States)",
  "Spinnerstown (United States)",
  "Spirit Lake (United States)",
  "Spiro (United States)",
  "Spivey (United States)",
  "Splendora (United States)",
  "Spofford (United States)",
  "Spokane (United States)",
  "Spooner (United States)",
  "Spotswood (United States)",
  "Spotsylvania (United States)",
  "Spout Spring (United States)",
  "Spraggs (United States)",
  "Sprakers (United States)",
  "Spreckels (United States)",
  "Spring (United States)",
  "Spring Arbor (United States)",
  "Spring Branch (United States)",
  "Spring Church (United States)",
  "Spring City (United States)",
  "Spring Creek (United States)",
  "Spring Glen (United States)",
  "Spring Green (United States)",
  "Spring Grove (United States)",
  "Spring Hill (United States)",
  "Spring Hope (United States)",
  "Spring House (United States)",
  "Spring Lake (United States)",
  "Spring Lake Park (United States)",
  "Spring Mills (United States)",
  "Spring Park (United States)",
  "Spring Run (United States)",
  "Spring Valley (United States)",
  "Springboro (United States)",
  "Springdale (United States)",
  "Springer (United States)",
  "Springerville (United States)",
  "Springfield (United States)",
  "Springfield Gardens (United States)",
  "Springfield Township (United States)",
  "Springhill (United States)",
  "Springport (United States)",
  "Springtown (United States)",
  "Springvale (United States)",
  "Springview (United States)",
  "Springville (United States)",
  "Springwater (United States)",
  "Spruce (United States)",
  "Spruce Pine (United States)",
  "Spurlockville (United States)",
  "Squaw Lake (United States)",
  "Squaw Valley (United States)",
  "Squires (United States)",
  "St Louis (United States)",
  "St. Augustine (United States)",
  "St. Charles (United States)",
  "St. Genevieve (United States)",
  "St. Helena (United States)",
  "St. Joseph (United States)",
  "St. Rose (United States)",
  "Staatsburg (United States)",
  "Stacy (United States)",
  "Stacyville (United States)",
  "Stafford (United States)",
  "Stafford Springs (United States)",
  "Staffordsville (United States)",
  "Stahlstown (United States)",
  "Staley (United States)",
  "Stamford (United States)",
  "Stanardsville (United States)",
  "Stanberry (United States)",
  "Stanchfield (United States)",
  "Standard (United States)",
  "Standish (United States)",
  "Stanfield (United States)",
  "Stanford (United States)",
  "Stanfordville (United States)",
  "Stanhope (United States)",
  "Stanley (United States)",
  "Stanton (United States)",
  "Stantonsburg (United States)",
  "Stanville (United States)",
  "Stanwood (United States)",
  "Staples (United States)",
  "Stapleton (United States)",
  "Star (United States)",
  "Star City (United States)",
  "Star Lake (United States)",
  "Star Prairie (United States)",
  "Starbuck (United States)",
  "Starford (United States)",
  "Stark (United States)",
  "Stark City (United States)",
  "Starke (United States)",
  "Starksboro (United States)",
  "Starkville (United States)",
  "Starkweather (United States)",
  "Starr (United States)",
  "Startex (United States)",
  "State Center (United States)",
  "State College (United States)",
  "State Line (United States)",
  "State Road (United States)",
  "State University (United States)",
  "Stateline (United States)",
  "Staten Island (United States)",
  "Statenville (United States)",
  "Statesboro (United States)",
  "Statesville (United States)",
  "Statham (United States)",
  "Staunton (United States)",
  "Stayton (United States)",
  "Steamboat Rock (United States)",
  "Steamboat Springs (United States)",
  "Steamburg (United States)",
  "Stearns (United States)",
  "Stebbins (United States)",
  "Stedman (United States)",
  "Steedman (United States)",
  "Steele (United States)",
  "Steele City (United States)",
  "Steeleville (United States)",
  "Steelville (United States)",
  "Steen (United States)",
  "Steens (United States)",
  "Steep Falls (United States)",
  "Steger (United States)",
  "Steilacoom (United States)",
  "Steinauer (United States)",
  "Steinhatchee (United States)",
  "Stella (United States)",
  "Stem (United States)",
  "Stephen (United States)",
  "Stephens (United States)",
  "Stephens City (United States)",
  "Stephenson (United States)",
  "Stephensport (United States)",
  "Stephentown (United States)",
  "Stephenville (United States)",
  "Sterling (United States)",
  "Sterling Forest (United States)",
  "Sterling Heights (United States)",
  "Sterlington (United States)",
  "Sterrett (United States)",
  "Stetson (United States)",
  "Stetsonville (United States)",
  "Steuben (United States)",
  "Steubenville (United States)",
  "Stevens (United States)",
  "Stevens Point (United States)",
  "Stevenson (United States)",
  "Stevenson Ranch (United States)",
  "Stevensville (United States)",
  "Stevinson (United States)",
  "Steward (United States)",
  "Stewardson (United States)",
  "Stewart (United States)",
  "Stewartstown (United States)",
  "Stewartsville (United States)",
  "Stewartville (United States)",
  "Stickney (United States)",
  "Stigler (United States)",
  "Stilesville (United States)",
  "Still Pond (United States)",
  "Stillman Valley (United States)",
  "Stillmore (United States)",
  "Stillwater (United States)",
  "Stilwell (United States)",
  "Stinesville (United States)",
  "Stinnett (United States)",
  "Stinson Beach (United States)",
  "Stirling (United States)",
  "Stites (United States)",
  "Stittville (United States)",
  "Stockbridge (United States)",
  "Stockdale (United States)",
  "Stockertown (United States)",
  "Stockholm (United States)",
  "Stockport (United States)",
  "Stockton (United States)",
  "Stockton Springs (United States)",
  "Stoddard (United States)",
  "Stokesdale (United States)",
  "Stollings (United States)",
  "Stone (United States)",
  "Stone Creek (United States)",
  "Stone Harbor (United States)",
  "Stone Lake (United States)",
  "Stone Mountain (United States)",
  "Stone Park (United States)",
  "Stone Ridge (United States)",
  "Stoneboro (United States)",
  "Stonefort (United States)",
  "Stoneham (United States)",
  "Stoneville (United States)",
  "Stonewall (United States)",
  "Stonington (United States)",
  "Stony Brook (United States)",
  "Stony Creek (United States)",
  "Stony Point (United States)",
  "Stonyford (United States)",
  "Storden (United States)",
  "Storm Lake (United States)",
  "Stormville (United States)",
  "Storrs (United States)",
  "Story (United States)",
  "Story City (United States)",
  "Stotts City (United States)",
  "Stoughton (United States)",
  "Stout (United States)",
  "Stoutland (United States)",
  "Stover (United States)",
  "Stow (United States)",
  "Stowe (United States)",
  "Stoystown (United States)",
  "Strafford (United States)",
  "Strasburg (United States)",
  "Stratford (United States)",
  "Stratham (United States)",
  "Strathmore (United States)",
  "Strattanville (United States)",
  "Stratton (United States)",
  "Straughn (United States)",
  "Strawberry (United States)",
  "Strawberry Plains (United States)",
  "Strawberry Point (United States)",
  "Strawn (United States)",
  "Streamwood (United States)",
  "Streator (United States)",
  "Street (United States)",
  "Streeter (United States)",
  "Streetman (United States)",
  "Streetsboro (United States)",
  "Stringer (United States)",
  "Stromsburg (United States)",
  "Strong (United States)",
  "Stronghurst (United States)",
  "Strongstown (United States)",
  "Strongsville (United States)",
  "Stroud (United States)",
  "Stroudsburg (United States)",
  "Strum (United States)",
  "Strunk (United States)",
  "Struthers (United States)",
  "Stryker (United States)",
  "Stuart (United States)",
  "Stuarts Draft (United States)",
  "Studio City (United States)",
  "Stump Creek (United States)",
  "Sturbridge (United States)",
  "Sturgeon (United States)",
  "Sturgeon Bay (United States)",
  "Sturgeon Lake (United States)",
  "Sturgis (United States)",
  "Sturtevant (United States)",
  "Stuttgart (United States)",
  "Stuyvesant (United States)",
  "Suamico (United States)",
  "Sublette (United States)",
  "Sublimity (United States)",
  "Succasunna (United States)",
  "Suches (United States)",
  "Sudan (United States)",
  "Sudbury (United States)",
  "Sudlersville (United States)",
  "Suffern (United States)",
  "Suffield (United States)",
  "Suffolk (United States)",
  "Sugar City (United States)",
  "Sugar Grove (United States)",
  "Sugar Hill (United States)",
  "Sugar Land (United States)",
  "Sugar Loaf (United States)",
  "Sugar Run (United States)",
  "Sugar Tree (United States)",
  "Sugar Valley (United States)",
  "Sugarcreek (United States)",
  "Sugarloaf (United States)",
  "Sugarloaf Township (United States)",
  "Sugartown (United States)",
  "Suisun (United States)",
  "Suitland (United States)",
  "Sula (United States)",
  "Sulligent (United States)",
  "Sullivan (United States)",
  "Sully (United States)",
  "Sulphur (United States)",
  "Sulphur Bluff (United States)",
  "Sulphur Springs (United States)",
  "Sultan (United States)",
  "Sumas (United States)",
  "Sumerduck (United States)",
  "Sumiton (United States)",
  "Summer Shade (United States)",
  "Summerdale (United States)",
  "Summerfield (United States)",
  "Summerhill (United States)",
  "Summerland (United States)",
  "Summerland Key (United States)",
  "Summers (United States)",
  "Summersville (United States)",
  "Summerton (United States)",
  "Summertown (United States)",
  "Summerville (United States)",
  "Summit (United States)",
  "Summit Hill (United States)",
  "Summit Lake (United States)",
  "Summit Station (United States)",
  "Summitville (United States)",
  "Sumner (United States)",
  "Sumrall (United States)",
  "Sumter (United States)",
  "Sun (United States)",
  "Sun City (United States)",
  "Sun City Center (United States)",
  "Sun City West (United States)",
  "Sun Prairie (United States)",
  "Sun River (United States)",
  "Sun Valley (United States)",
  "Sunapee (United States)",
  "Sunbright (United States)",
  "Sunburg (United States)",
  "Sunburst (United States)",
  "Sunbury (United States)",
  "Suncook (United States)",
  "Sundance (United States)",
  "Sunderland (United States)",
  "Sundown (United States)",
  "Sunflower (United States)",
  "Sunland (United States)",
  "Sunman (United States)",
  "Sunnyside (United States)",
  "Sunnyvale (United States)",
  "Sunol (United States)",
  "Sunray (United States)",
  "Sunrise (United States)",
  "Sunrise Beach (United States)",
  "Sunset (United States)",
  "Sunset Beach (United States)",
  "Sunshine (United States)",
  "Superior (United States)",
  "Supply (United States)",
  "Suquamish (United States)",
  "Surgoinsville (United States)",
  "Suring (United States)",
  "Surprise (United States)",
  "Surrency (United States)",
  "Surry (United States)",
  "Susanville (United States)",
  "Susquehanna (United States)",
  "Sussex (United States)",
  "Sutersville (United States)",
  "Sutherland (United States)",
  "Sutherland Springs (United States)",
  "Sutherlin (United States)",
  "Sutter (United States)",
  "Sutter Creek (United States)",
  "Sutton (United States)",
  "Suttons Bay (United States)",
  "Suwanee (United States)",
  "Suwannee (United States)",
  "Swainsboro (United States)",
  "Swaledale (United States)",
  "Swampscott (United States)",
  "Swan (United States)",
  "Swannanoa (United States)",
  "Swans Island (United States)",
  "Swansboro (United States)",
  "Swansea (United States)",
  "Swanton (United States)",
  "Swanville (United States)",
  "Swanzey (United States)",
  "Swarthmore (United States)",
  "Swartswood (United States)",
  "Swartz (United States)",
  "Swartz Creek (United States)",
  "Swayzee (United States)",
  "Swedesboro (United States)",
  "Sweeny (United States)",
  "Sweet (United States)",
  "Sweet Home (United States)",
  "Sweet Springs (United States)",
  "Sweet Valley (United States)",
  "Sweet Water (United States)",
  "Sweetser (United States)",
  "Sweetwater (United States)",
  "Swiftwater (United States)",
  "Swink (United States)",
  "Swisher (United States)",
  "Swisshome (United States)",
  "Switz City (United States)",
  "Switzer (United States)",
  "Swoope (United States)",
  "Sybertsville (United States)",
  "Sycamore (United States)",
  "Sykeston (United States)",
  "Sykesville (United States)",
  "Sylacauga (United States)",
  "Sylmar (United States)",
  "Sylva (United States)",
  "Sylvan Beach (United States)",
  "Sylvan Grove (United States)",
  "Sylvania (United States)",
  "Sylvester (United States)",
  "Symsonia (United States)",
  "Syosset (United States)",
  "Syracuse (United States)",
  "Taberg (United States)",
  "Tabernash (United States)",
  "Table Grove (United States)",
  "Tabor (United States)",
  "Tabor City (United States)",
  "Tacoma (United States)",
  "Taft (United States)",
  "Tafton (United States)",
  "Taftville (United States)",
  "Tahlequah (United States)",
  "Tahoe City (United States)",
  "Tahoe Valley (United States)",
  "Tahoe Vista (United States)",
  "Tahoka (United States)",
  "Taholah (United States)",
  "Tahoma (United States)",
  "Tahuya (United States)",
  "Takoma Park (United States)",
  "Talala (United States)",
  "Talbott (United States)",
  "Talbotton (United States)",
  "Talco (United States)",
  "Talcott (United States)",
  "Talent (United States)",
  "Talihina (United States)",
  "Talkeetna (United States)",
  "Talking Rock (United States)",
  "Tall Timbers (United States)",
  "Talladega (United States)",
  "Tallahassee (United States)",
  "Tallapoosa (United States)",
  "Tallassee (United States)",
  "Tallmadge (United States)",
  "Tallulah (United States)",
  "Talmo (United States)",
  "Talmoon (United States)",
  "Taloga (United States)",
  "Tama (United States)",
  "Tamaqua (United States)",
  "Tamassee (United States)",
  "Tamms (United States)",
  "Tampa (United States)",
  "Tampico (United States)",
  "Tamworth (United States)",
  "Taneytown (United States)",
  "Taneyville (United States)",
  "Tangent (United States)",
  "Tangerine (United States)",
  "Tangier (United States)",
  "Tangipahoa (United States)",
  "Tanner (United States)",
  "Tannersville (United States)",
  "Taos (United States)",
  "Taos Ski Valley (United States)",
  "Tappahannock (United States)",
  "Tappan (United States)",
  "Tappen (United States)",
  "Tar Heel (United States)",
  "Tarawa Terrace I (United States)",
  "Tarboro (United States)",
  "Tarentum (United States)",
  "Tariffville (United States)",
  "Tarkio (United States)",
  "Tarpon Springs (United States)",
  "Tarrs (United States)",
  "Tarrytown (United States)",
  "Tarzana (United States)",
  "Tasley (United States)",
  "Tatamy (United States)",
  "Tate (United States)",
  "Tatum (United States)",
  "Taunton (United States)",
  "Tavares (United States)",
  "Tavernier (United States)",
  "Tawas City (United States)",
  "Taylor (United States)",
  "Taylor Springs (United States)",
  "Taylors (United States)",
  "Taylors Falls (United States)",
  "Taylorsville (United States)",
  "Taylorville (United States)",
  "Tazewell (United States)",
  "Tea (United States)",
  "Teachey (United States)",
  "Teague (United States)",
  "Teaneck (United States)",
  "Teasdale (United States)",
  "Tebbetts (United States)",
  "Tecumseh (United States)",
  "Teec Nos Pos (United States)",
  "Tehachapi (United States)",
  "Tehama (United States)",
  "Tekamah (United States)",
  "Tekoa (United States)",
  "Tekonsha (United States)",
  "Telephone (United States)",
  "Telford (United States)",
  "Tell City (United States)",
  "Teller (United States)",
  "Tellico Plains (United States)",
  "Telluride (United States)",
  "Telogia (United States)",
  "Temecula (United States)",
  "Tempe (United States)",
  "Temperance (United States)",
  "Temple (United States)",
  "Temple City (United States)",
  "Temple Hills (United States)",
  "Templeton (United States)",
  "Ten Mile (United States)",
  "Ten Sleep (United States)",
  "Tenafly (United States)",
  "Tenaha (United States)",
  "Tenants Harbor (United States)",
  "Tenino (United States)",
  "Tennent (United States)",
  "Tennessee (United States)",
  "Tennessee Colony (United States)",
  "Tennessee Ridge (United States)",
  "Tennille (United States)",
  "Tensed (United States)",
  "Tenstrike (United States)",
  "Terlingua (United States)",
  "Terlton (United States)",
  "Terra Alta (United States)",
  "Terra Bella (United States)",
  "Terrace Park (United States)",
  "Terre Haute (United States)",
  "Terre Hill (United States)",
  "Terrebonne (United States)",
  "Terrell (United States)",
  "Terreton (United States)",
  "Terril (United States)",
  "Terry (United States)",
  "Terryville (United States)",
  "Tescott (United States)",
  "Tesuque (United States)",
  "Teterboro (United States)",
  "Teton (United States)",
  "Teton Village (United States)",
  "Tetonia (United States)",
  "Teutopolis (United States)",
  "Tewksbury (United States)",
  "Texarkana (United States)",
  "Texas City (United States)",
  "Texhoma (United States)",
  "Texico (United States)",
  "Texline (United States)",
  "Thackerville (United States)",
  "Thatcher (United States)",
  "Thaxton (United States)",
  "Thayer (United States)",
  "Thayne (United States)",
  "The Bronx (United States)",
  "The Colony (United States)",
  "The Dalles (United States)",
  "The Plains (United States)",
  "The Rock (United States)",
  "The Villages (United States)",
  "Thelma (United States)",
  "Thendara (United States)",
  "Theodore (United States)",
  "Theresa (United States)",
  "Thermal (United States)",
  "Thermopolis (United States)",
  "Thetford Center (United States)",
  "Thibodaux (United States)",
  "Thief River Falls (United States)",
  "Thiells (United States)",
  "Thiensville (United States)",
  "Thomas (United States)",
  "Thomasboro (United States)",
  "Thomaston (United States)",
  "Thomasville (United States)",
  "Thompson (United States)",
  "Thompson Falls (United States)",
  "Thompsontown (United States)",
  "Thompsonville (United States)",
  "Thomson (United States)",
  "Thonotosassa (United States)",
  "Thoreau (United States)",
  "Thorn Hill (United States)",
  "Thornburg (United States)",
  "Thorndale (United States)",
  "Thorndike (United States)",
  "Thorne Bay (United States)",
  "Thornton (United States)",
  "Thorntown (United States)",
  "Thornville (United States)",
  "Thornwood (United States)",
  "Thorofare (United States)",
  "Thorp (United States)",
  "Thousand Oaks (United States)",
  "Thousand Palms (United States)",
  "Thrall (United States)",
  "Three Bridges (United States)",
  "Three Forks (United States)",
  "Three Lakes (United States)",
  "Three Mile Bay (United States)",
  "Three Oaks (United States)",
  "Three Rivers (United States)",
  "Three Springs (United States)",
  "Throckmorton (United States)",
  "Thurmond (United States)",
  "Thurmont (United States)",
  "Thurston (United States)",
  "Tiburon (United States)",
  "Tickfaw (United States)",
  "Ticonderoga (United States)",
  "Tidioute (United States)",
  "Tierra Amarilla (United States)",
  "Tieton (United States)",
  "Tiffin (United States)",
  "Tifton (United States)",
  "Tigard (United States)",
  "Tiger (United States)",
  "Tigerton (United States)",
  "Tigerville (United States)",
  "Tignall (United States)",
  "Tijeras (United States)",
  "Tilden (United States)",
  "Tilghman (United States)",
  "Tiline (United States)",
  "Tillamook (United States)",
  "Tillar (United States)",
  "Tilleda (United States)",
  "Tillson (United States)",
  "Tilton (United States)",
  "Tiltonsville (United States)",
  "Timber Lake (United States)",
  "Timberlake (United States)",
  "Timberon (United States)",
  "Timberville (United States)",
  "Timbo (United States)",
  "Timewell (United States)",
  "Timmonsville (United States)",
  "Timpson (United States)",
  "Tingley (United States)",
  "Tinley Park (United States)",
  "Tioga (United States)",
  "Tiona (United States)",
  "Tionesta (United States)",
  "Tipp City (United States)",
  "Tipton (United States)",
  "Tiptonville (United States)",
  "Tire Hill (United States)",
  "Tiro (United States)",
  "Tishomingo (United States)",
  "Tiskilwa (United States)",
  "Titonka (United States)",
  "Titus (United States)",
  "Titusville (United States)",
  "Tiverton (United States)",
  "Tivoli (United States)",
  "Toano (United States)",
  "Tobaccoville (United States)",
  "Tobyhanna (United States)",
  "Toccoa (United States)",
  "Toccoa Falls (United States)",
  "Todd (United States)",
  "Toddville (United States)",
  "Tofte (United States)",
  "Togiak (United States)",
  "Toivola (United States)",
  "Tok (United States)",
  "Toksook Bay (United States)",
  "Tolar (United States)",
  "Toledo (United States)",
  "Tolland (United States)",
  "Tollesboro (United States)",
  "Tolleson (United States)",
  "Tollhouse (United States)",
  "Tolna (United States)",
  "Tolono (United States)",
  "Toluca (United States)",
  "Tom Bean (United States)",
  "Tomah (United States)",
  "Tomahawk (United States)",
  "Tomball (United States)",
  "Tombstone (United States)",
  "Tomkins Cove (United States)",
  "Tompkinsville (United States)",
  "Toms Brook (United States)",
  "Toms River (United States)",
  "Tonasket (United States)",
  "Tonawanda (United States)",
  "Toney (United States)",
  "Tonganoxie (United States)",
  "Tonica (United States)",
  "Tonkawa (United States)",
  "Tonopah (United States)",
  "Tontitown (United States)",
  "Tonto Basin (United States)",
  "Tony (United States)",
  "Tooele (United States)",
  "Toomsboro (United States)",
  "Toomsuba (United States)",
  "Toone (United States)",
  "Topanga (United States)",
  "Topeka (United States)",
  "Topock (United States)",
  "Toppenish (United States)",
  "Topping (United States)",
  "Topsfield (United States)",
  "Topsham (United States)",
  "Topton (United States)",
  "Tornado (United States)",
  "Tornillo (United States)",
  "Toronto (United States)",
  "Torrance (United States)",
  "Torreon (United States)",
  "Torrey (United States)",
  "Torrington (United States)",
  "Totowa (United States)",
  "Totz (United States)",
  "Tougaloo (United States)",
  "Toughkenamon (United States)",
  "Toulon (United States)",
  "Toutle (United States)",
  "Tovey (United States)",
  "Towaco (United States)",
  "Towanda (United States)",
  "Tower (United States)",
  "Tower City (United States)",
  "Tower Hill (United States)",
  "Town Creek (United States)",
  "Town of Boxborough (United States)",
  "Town of Bozrah (United States)",
  "Town of East Lyme (United States)",
  "Town of Mount Desert (United States)",
  "Towner (United States)",
  "Townsend (United States)",
  "Townshend (United States)",
  "Townville (United States)",
  "Towson (United States)",
  "Toxey (United States)",
  "Trabuco Canyon (United States)",
  "Tracy (United States)",
  "Tracy City (United States)",
  "Tracys Landing (United States)",
  "Tracyton (United States)",
  "Trade (United States)",
  "Traer (United States)",
  "Trafalgar (United States)",
  "Trafford (United States)",
  "Trail (United States)",
  "Transfer (United States)",
  "Traphill (United States)",
  "Trappe (United States)",
  "Travelers Rest (United States)",
  "Traverse City (United States)",
  "Treadwell (United States)",
  "Trego (United States)",
  "Treichlers (United States)",
  "Trementina (United States)",
  "Tremont (United States)",
  "Tremonton (United States)",
  "Trempealeau (United States)",
  "Trenary (United States)",
  "Trent (United States)",
  "Trenton (United States)",
  "Tres Pinos (United States)",
  "Tresckow (United States)",
  "Trevett (United States)",
  "Trevor (United States)",
  "Trevorton (United States)",
  "Trexlertown (United States)",
  "Treynor (United States)",
  "Trezevant (United States)",
  "Triadelphia (United States)",
  "Triangle (United States)",
  "Tribes Hill (United States)",
  "Tribune (United States)",
  "Trimble (United States)",
  "Trimont (United States)",
  "Trinidad (United States)",
  "Trinity (United States)",
  "Trion (United States)",
  "Tripoli (United States)",
  "Tripp (United States)",
  "Triumph (United States)",
  "Trivoli (United States)",
  "Trona (United States)",
  "Tropic (United States)",
  "Trosper (United States)",
  "Troup (United States)",
  "Troupsburg (United States)",
  "Trout Creek (United States)",
  "Trout Run (United States)",
  "Troutdale (United States)",
  "Troutman (United States)",
  "Troutville (United States)",
  "Troy (United States)",
  "Truchas (United States)",
  "Truckee (United States)",
  "Trufant (United States)",
  "Truman (United States)",
  "Trumann (United States)",
  "Trumansburg (United States)",
  "Trumbauersville (United States)",
  "Trumbull (United States)",
  "Truro (United States)",
  "Trussville (United States)",
  "Truth or Consequences (United States)",
  "Truxton (United States)",
  "Tryon (United States)",
  "Tsaile (United States)",
  "Tualatin (United States)",
  "Tuba City (United States)",
  "Tubac (United States)",
  "Tuckahoe (United States)",
  "Tuckasegee (United States)",
  "Tucker (United States)",
  "Tuckerman (United States)",
  "Tuckerton (United States)",
  "Tucson (United States)",
  "Tucumcari (United States)",
  "Tujunga (United States)",
  "Tulare (United States)",
  "Tulare County (United States)",
  "Tularosa (United States)",
  "Tulelake (United States)",
  "Tulia (United States)",
  "Tullahoma (United States)",
  "Tully (United States)",
  "Tulsa (United States)",
  "Tumacacori (United States)",
  "Tumtum (United States)",
  "Tumwater (United States)",
  "Tunas (United States)",
  "Tunbridge (United States)",
  "Tunica (United States)",
  "Tunkhannock (United States)",
  "Tunnel Hill (United States)",
  "Tunnelton (United States)",
  "Tuntutuliak (United States)",
  "Tuolumne (United States)",
  "Tupelo (United States)",
  "Tupper Lake (United States)",
  "Turbeville (United States)",
  "Turbotville (United States)",
  "Turin (United States)",
  "Turkey (United States)",
  "Turkey Creek (United States)",
  "Turlock (United States)",
  "Turner (United States)",
  "Turners Falls (United States)",
  "Turners Station (United States)",
  "Turnerville (United States)",
  "Turpin (United States)",
  "Turtle Creek (United States)",
  "Turtle Lake (United States)",
  "Tuscaloosa (United States)",
  "Tuscarawas (United States)",
  "Tuscola (United States)",
  "Tuscumbia (United States)",
  "Tuskegee (United States)",
  "Tustin (United States)",
  "Tutor Key (United States)",
  "Tuttle (United States)",
  "Tutwiler (United States)",
  "Tuxedo Park (United States)",
  "Twain Harte (United States)",
  "Twelve Mile (United States)",
  "Twentynine Palms (United States)",
  "Twin Bridges (United States)",
  "Twin City (United States)",
  "Twin Falls (United States)",
  "Twin Lake (United States)",
  "Twin Lakes (United States)",
  "Twin Peaks (United States)",
  "Twin Valley (United States)",
  "Twining (United States)",
  "Twinsburg (United States)",
  "Twisp (United States)",
  "Two Buttes (United States)",
  "Two Harbors (United States)",
  "Two Rivers (United States)",
  "Tyaskin (United States)",
  "Tybee Island (United States)",
  "Tybo (United States)",
  "Tygh Valley (United States)",
  "Tyler (United States)",
  "Tylertown (United States)",
  "Tyndall (United States)",
  "Tyner (United States)",
  "Tyngsboro (United States)",
  "Tyringham (United States)",
  "Tyrone (United States)",
  "Tyronza (United States)",
  "Ubly (United States)",
  "Udall (United States)",
  "Uhrichsville (United States)",
  "Ukiah (United States)",
  "Uledi (United States)",
  "Ulen (United States)",
  "Ullin (United States)",
  "Ulm (United States)",
  "Ulster (United States)",
  "Ulster Park (United States)",
  "Ulysses (United States)",
  "Umatilla (United States)",
  "Umpire (United States)",
  "Unadilla (United States)",
  "Unalakleet (United States)",
  "Unalaska (United States)",
  "Uncasville (United States)",
  "Underhill (United States)",
  "Underwood (United States)",
  "Unicoi (United States)",
  "Union (United States)",
  "Union Bridge (United States)",
  "Union City (United States)",
  "Union Dale (United States)",
  "Union Grove (United States)",
  "Union Hall (United States)",
  "Union Hill (United States)",
  "Union Lake (United States)",
  "Union Mills (United States)",
  "Union Point (United States)",
  "Union Springs (United States)",
  "Union Star (United States)",
  "Union Township (United States)",
  "Uniondale (United States)",
  "Uniontown (United States)",
  "Unionville (United States)",
  "Unity (United States)",
  "Universal City (United States)",
  "University (United States)",
  "University Park (United States)",
  "University Place (United States)",
  "Unorganized Territory of Camp Lejeune (United States)",
  "Upatoi (United States)",
  "Upland (United States)",
  "Upper Abbot (United States)",
  "Upper Black Eddy (United States)",
  "Upper Darby (United States)",
  "Upper Falls (United States)",
  "Upper Jay (United States)",
  "Upper Marlboro (United States)",
  "Upper Saddle River (United States)",
  "Upper Sandusky (United States)",
  "Upperco (United States)",
  "Upperville (United States)",
  "Upsala (United States)",
  "Upton (United States)",
  "Urbana (United States)",
  "Urbandale (United States)",
  "Urbanna (United States)",
  "Uriah (United States)",
  "Urich (United States)",
  "Ursa (United States)",
  "Ute (United States)",
  "Utica (United States)",
  "Utopia (United States)",
  "Uvalda (United States)",
  "Uvalde (United States)",
  "Uxbridge (United States)",
  "Vacaville (United States)",
  "Vacherie (United States)",
  "Vader (United States)",
  "Vail (United States)",
  "Vails Gate (United States)",
  "Valatie (United States)",
  "Valders (United States)",
  "Valdese (United States)",
  "Valdez (United States)",
  "Valdosta (United States)",
  "Vale (United States)",
  "Valencia (United States)",
  "Valentine (United States)",
  "Valentines (United States)",
  "Valera (United States)",
  "Valhalla (United States)",
  "Valhermoso Springs (United States)",
  "Valier (United States)",
  "Valle (United States)",
  "Vallecito (United States)",
  "Vallejo (United States)",
  "Valles Mines (United States)",
  "Valley (United States)",
  "Valley Bend (United States)",
  "Valley Center (United States)",
  "Valley City (United States)",
  "Valley Cottage (United States)",
  "Valley Falls (United States)",
  "Valley Farms (United States)",
  "Valley Forge (United States)",
  "Valley Green (United States)",
  "Valley Head (United States)",
  "Valley Mills (United States)",
  "Valley Park (United States)",
  "Valley Springs (United States)",
  "Valley Stream (United States)",
  "Valley View (United States)",
  "Valley Village (United States)",
  "Valleyford (United States)",
  "Valliant (United States)",
  "Vallonia (United States)",
  "Valmeyer (United States)",
  "Valparaiso (United States)",
  "Valrico (United States)",
  "Van (United States)",
  "Van Alstyne (United States)",
  "Van Buren (United States)",
  "Van Dyne (United States)",
  "Van Etten (United States)",
  "Van Horn (United States)",
  "Van Horne (United States)",
  "Van Lear (United States)",
  "Van Meter (United States)",
  "Van Nuys (United States)",
  "Van Vleck (United States)",
  "Van Vleet (United States)",
  "Van Wert (United States)",
  "Van Wyck (United States)",
  "Vance (United States)",
  "Vanceburg (United States)",
  "Vancleave (United States)",
  "Vancouver (United States)",
  "Vandalia (United States)",
  "Vanderbilt (United States)",
  "Vandergrift (United States)",
  "Vandiver (United States)",
  "Vanleer (United States)",
  "Vanlue (United States)",
  "Vansant (United States)",
  "Vanzant (United States)",
  "Vardaman (United States)",
  "Varna (United States)",
  "Varnville (United States)",
  "Vashon (United States)",
  "Vass (United States)",
  "Vassalboro (United States)",
  "Vassar (United States)",
  "Vaughan (United States)",
  "Vaughn (United States)",
  "Vauxhall (United States)",
  "Veblen (United States)",
  "Veedersburg (United States)",
  "Vega (United States)",
  "Velarde (United States)",
  "Velva (United States)",
  "Vendor (United States)",
  "Venedocia (United States)",
  "Veneta (United States)",
  "Venetia (United States)",
  "Venice (United States)",
  "Ventnor City (United States)",
  "Ventress (United States)",
  "Ventura (United States)",
  "Venus (United States)",
  "Veradale (United States)",
  "Verbank (United States)",
  "Verdi (United States)",
  "Verdigre (United States)",
  "Verdon (United States)",
  "Verdugo City (United States)",
  "Verdunville (United States)",
  "Vergas (United States)",
  "Vergennes (United States)",
  "Vermilion (United States)",
  "Vermillion (United States)",
  "Vermont (United States)",
  "Vermontville (United States)",
  "Vernal (United States)",
  "Verndale (United States)",
  "Vernon (United States)",
  "Vernon Center (United States)",
  "Vernon Hill (United States)",
  "Vernon Hills (United States)",
  "Vernonia (United States)",
  "Vero Beach (United States)",
  "Verona (United States)",
  "Verplanck (United States)",
  "Versailles (United States)",
  "Vershire (United States)",
  "Vesper (United States)",
  "Vesta (United States)",
  "Vestaburg (United States)",
  "Vestal (United States)",
  "Vesuvius (United States)",
  "Vevay (United States)",
  "Veyo (United States)",
  "Vian (United States)",
  "Viborg (United States)",
  "Viburnum (United States)",
  "Vicco (United States)",
  "Vici (United States)",
  "Vicksburg (United States)",
  "Victor (United States)",
  "Victoria (United States)",
  "Victorville (United States)",
  "Vida (United States)",
  "Vidalia (United States)",
  "Vidor (United States)",
  "Vienna (United States)",
  "Vilas (United States)",
  "Villa Grove (United States)",
  "Villa Park (United States)",
  "Villa Rica (United States)",
  "Villa Ridge (United States)",
  "Village (United States)",
  "Village Mills (United States)",
  "Villanova (United States)",
  "Villanueva (United States)",
  "Villard (United States)",
  "Villas (United States)",
  "Ville Platte (United States)",
  "Villisca (United States)",
  "Vilonia (United States)",
  "Vina (United States)",
  "Vinalhaven (United States)",
  "Vincennes (United States)",
  "Vincent (United States)",
  "Vincentown (United States)",
  "Vine Grove (United States)",
  "Vineland (United States)",
  "Vinemont (United States)",
  "Vineyard Haven (United States)",
  "Vining (United States)",
  "Vinita (United States)",
  "Vinton (United States)",
  "Vintondale (United States)",
  "Viola (United States)",
  "Violet (United States)",
  "Violet Hill (United States)",
  "Viper (United States)",
  "Virden (United States)",
  "Virgie (United States)",
  "Virgilina (United States)",
  "Virgin (United States)",
  "Virginia (United States)",
  "Virginia Beach (United States)",
  "Viroqua (United States)",
  "Visalia (United States)",
  "Vista (United States)",
  "Vivian (United States)",
  "Volborg (United States)",
  "Volcano (United States)",
  "Volga (United States)",
  "Voluntown (United States)",
  "Von Ormy (United States)",
  "Vona (United States)",
  "Vonore (United States)",
  "Voorhees Township (United States)",
  "Voorheesville (United States)",
  "Vulcan (United States)",
  "Waban (United States)",
  "Wabash (United States)",
  "Wabasha (United States)",
  "Wabasso (United States)",
  "Waccabuc (United States)",
  "Wachapreague (United States)",
  "Waco (United States)",
  "Waconia (United States)",
  "Waddell (United States)",
  "Waddy (United States)",
  "Wade (United States)",
  "Wadena (United States)",
  "Wadesboro (United States)",
  "Wadesville (United States)",
  "Wading River (United States)",
  "Wadley (United States)",
  "Wadmalaw Island (United States)",
  "Wadsworth (United States)",
  "Waelder (United States)",
  "Wagener (United States)",
  "Wagner (United States)",
  "Wagoner (United States)",
  "Wahiawa (United States)",
  "Wahkon (United States)",
  "Wahoo (United States)",
  "Wahpeton (United States)",
  "Waialua (United States)",
  "Waikoloa (United States)",
  "Wailuku (United States)",
  "Waimanalo (United States)",
  "Wainscott (United States)",
  "Wainwright (United States)",
  "Waipahu (United States)",
  "Waite Park (United States)",
  "Waitsburg (United States)",
  "Waitsfield (United States)",
  "Wakarusa (United States)",
  "Wake (United States)",
  "Wake Forest (United States)",
  "WaKeeney (United States)",
  "Wakefield (United States)",
  "Wakeman (United States)",
  "Wakita (United States)",
  "Wakonda (United States)",
  "Walbridge (United States)",
  "Walcott (United States)",
  "Walden (United States)",
  "Waldo (United States)",
  "Waldoboro (United States)",
  "Waldorf (United States)",
  "Waldport (United States)",
  "Waldron (United States)",
  "Waldwick (United States)",
  "Wales (United States)",
  "Waleska (United States)",
  "Walford (United States)",
  "Walhalla (United States)",
  "Walhonding (United States)",
  "Walker (United States)",
  "Walker Valley (United States)",
  "Walkersville (United States)",
  "Walkerton (United States)",
  "Walkertown (United States)",
  "Walkerville (United States)",
  "Wall (United States)",
  "Wall Lake (United States)",
  "Walla Walla (United States)",
  "Wallace (United States)",
  "Wallaceton (United States)",
  "Walland (United States)",
  "Wallback (United States)",
  "Wallburg (United States)",
  "Walled Lake (United States)",
  "Waller (United States)",
  "Wallingford (United States)",
  "Wallington (United States)",
  "Wallins Creek (United States)",
  "Wallis (United States)",
  "Wallkill (United States)",
  "Wallowa (United States)",
  "Walls (United States)",
  "Wallsburg (United States)",
  "Walnut (United States)",
  "Walnut Bottom (United States)",
  "Walnut Cove (United States)",
  "Walnut Creek (United States)",
  "Walnut Grove (United States)",
  "Walnut Ridge (United States)",
  "Walnut Shade (United States)",
  "Walnut Springs (United States)",
  "Walnutport (United States)",
  "Walpole (United States)",
  "Walsenburg (United States)",
  "Walsh (United States)",
  "Walshville (United States)",
  "Walstonburg (United States)",
  "Walterboro (United States)",
  "Walters (United States)",
  "Waltham (United States)",
  "Walthill (United States)",
  "Walton (United States)",
  "Waltonville (United States)",
  "Walworth (United States)",
  "Wamego (United States)",
  "Wampsville (United States)",
  "Wampum (United States)",
  "Wamsutter (United States)",
  "Wana (United States)",
  "Wanakena (United States)",
  "Wanamingo (United States)",
  "Wanaque (United States)",
  "Wanatah (United States)",
  "Wanchese (United States)",
  "Waneta (United States)",
  "Wanette (United States)",
  "Wannaska (United States)",
  "Wantagh (United States)",
  "Wapakoneta (United States)",
  "Wapato (United States)",
  "Wapella (United States)",
  "Wapello (United States)",
  "Wappapello (United States)",
  "Wappingers Falls (United States)",
  "Wapwallopen (United States)",
  "War (United States)",
  "Warba (United States)",
  "Ward (United States)",
  "Warden (United States)",
  "Wardensville (United States)",
  "Ware (United States)",
  "Ware Neck (United States)",
  "Ware Shoals (United States)",
  "Wareham (United States)",
  "Waretown (United States)",
  "Warfordsburg (United States)",
  "Warm Springs (United States)",
  "Warminster (United States)",
  "Warne (United States)",
  "Warner (United States)",
  "Warner Robins (United States)",
  "Warner Springs (United States)",
  "Warners (United States)",
  "Warren (United States)",
  "Warren Center (United States)",
  "Warren Township (United States)",
  "Warrendale (United States)",
  "Warrens (United States)",
  "Warrensburg (United States)",
  "Warrensville (United States)",
  "Warrenton (United States)",
  "Warrenville (United States)",
  "Warrington (United States)",
  "Warrior (United States)",
  "Warriors Mark (United States)",
  "Warroad (United States)",
  "Warsaw (United States)",
  "Wartburg (United States)",
  "Wartrace (United States)",
  "Warwick (United States)",
  "Wasco (United States)",
  "Waseca (United States)",
  "Washburn (United States)",
  "Washington (United States)",
  "Washington Boro (United States)",
  "Washington Court House (United States)",
  "Washington Crossing (United States)",
  "Washington Grove (United States)",
  "Washington Township (United States)",
  "Washingtonville (United States)",
  "Washougal (United States)",
  "Wasilla (United States)",
  "Waskom (United States)",
  "Wassaic (United States)",
  "Watauga (United States)",
  "Watchung (United States)",
  "Water Mill (United States)",
  "Water Valley (United States)",
  "Waterboro (United States)",
  "Waterbury (United States)",
  "Waterbury Center (United States)",
  "Waterford (United States)",
  "Waterford Flat (United States)",
  "Waterford Works (United States)",
  "Waterloo (United States)",
  "Waterman (United States)",
  "Waterport (United States)",
  "Waterproof (United States)",
  "Waters (United States)",
  "Watersmeet (United States)",
  "Watertown (United States)",
  "Waterville (United States)",
  "Waterville Valley (United States)",
  "Watervliet (United States)",
  "Watford City (United States)",
  "Wathena (United States)",
  "Watkins (United States)",
  "Watkins Glen (United States)",
  "Watkinsville (United States)",
  "Watonga (United States)",
  "Watseka (United States)",
  "Watson (United States)",
  "Watsontown (United States)",
  "Watsonville (United States)",
  "Watts (United States)",
  "Wattsburg (United States)",
  "Wattsville (United States)",
  "Waubun (United States)",
  "Wauchula (United States)",
  "Waucoma (United States)",
  "Wauconda (United States)",
  "Waukee (United States)",
  "Waukegan (United States)",
  "Waukesha (United States)",
  "Waukomis (United States)",
  "Waukon (United States)",
  "Waunakee (United States)",
  "Wauneta (United States)",
  "Waupaca (United States)",
  "Waupun (United States)",
  "Wauregan (United States)",
  "Waurika (United States)",
  "Wausa (United States)",
  "Wausau (United States)",
  "Wauseon (United States)",
  "Wautoma (United States)",
  "Waveland (United States)",
  "Waverly (United States)",
  "Waverly Hall (United States)",
  "Wawaka (United States)",
  "Waxahachie (United States)",
  "Waxhaw (United States)",
  "Waycross (United States)",
  "Wayland (United States)",
  "Waymart (United States)",
  "Wayne (United States)",
  "Wayne City (United States)",
  "Waynesboro (United States)",
  "Waynesburg (United States)",
  "Waynesfield (United States)",
  "Waynesville (United States)",
  "Waynetown (United States)",
  "Waynoka (United States)",
  "Wayzata (United States)",
  "Weare (United States)",
  "Weatherford (United States)",
  "Weatherly (United States)",
  "Weatogue (United States)",
  "Weaubleau (United States)",
  "Weaver (United States)",
  "Weaverville (United States)",
  "Webb (United States)",
  "Webb City (United States)",
  "Webbers Falls (United States)",
  "Webberville (United States)",
  "Weber City (United States)",
  "Webster (United States)",
  "Webster City (United States)",
  "Webster Springs (United States)",
  "Wedowee (United States)",
  "Weed (United States)",
  "Weedsport (United States)",
  "Weedville (United States)",
  "Weehawken (United States)",
  "Weeksbury (United States)",
  "Weems (United States)",
  "Weeping Water (United States)",
  "Weidman (United States)",
  "Weimar (United States)",
  "Weiner (United States)",
  "Weippe (United States)",
  "Weir (United States)",
  "Weirsdale (United States)",
  "Weirton (United States)",
  "Weiser (United States)",
  "Welaka (United States)",
  "Welch (United States)",
  "Welches (United States)",
  "Welcome (United States)",
  "Weldon (United States)",
  "Weleetka (United States)",
  "Wellborn (United States)",
  "Wellesley (United States)",
  "Wellesley Hills (United States)",
  "Wellfleet (United States)",
  "Wellford (United States)",
  "Wellington (United States)",
  "Wellman (United States)",
  "Wellpinit (United States)",
  "Wells (United States)",
  "Wellsboro (United States)",
  "Wellsburg (United States)",
  "Wellsley (United States)",
  "Wellston (United States)",
  "Wellsville (United States)",
  "Wellton (United States)",
  "Welsh (United States)",
  "Wenatchee (United States)",
  "Wendel (United States)",
  "Wendell (United States)",
  "Wenden (United States)",
  "Wendover (United States)",
  "Wenham (United States)",
  "Wenona (United States)",
  "Wenonah (United States)",
  "Wentworth (United States)",
  "Wentzville (United States)",
  "Wernersville (United States)",
  "Weskan (United States)",
  "Weslaco (United States)",
  "Wesley (United States)",
  "Wesley Chapel (United States)",
  "Wessington (United States)",
  "Wessington Springs (United States)",
  "Wesson (United States)",
  "West (United States)",
  "West Alexander (United States)",
  "West Alexandria (United States)",
  "West Alton (United States)",
  "West Babylon (United States)",
  "West Baldwin (United States)",
  "West Barnstable (United States)",
  "West Bend (United States)",
  "West Berlin (United States)",
  "West Blocton (United States)",
  "West Bloomfield (United States)",
  "West Boylston (United States)",
  "West Branch (United States)",
  "West Briar (United States)",
  "West Bridgewater (United States)",
  "West Brookfield (United States)",
  "West Burke (United States)",
  "West Burlington (United States)",
  "West Chatham (United States)",
  "West Chazy (United States)",
  "West Chester (United States)",
  "West Chesterfield (United States)",
  "West Chicago (United States)",
  "West Columbia (United States)",
  "West Concord (United States)",
  "West Corinth (United States)",
  "West Cornwall (United States)",
  "West Covina (United States)",
  "West Coxsackie (United States)",
  "West Creek (United States)",
  "West Danville (United States)",
  "West Decatur (United States)",
  "West Dennis (United States)",
  "West Des Moines (United States)",
  "West Dover (United States)",
  "West Dummerston (United States)",
  "West Dundee (United States)",
  "West Durham (United States)",
  "West Edmeston (United States)",
  "West End (United States)",
  "West Enfield (United States)",
  "West Falls (United States)",
  "West Falmouth (United States)",
  "West Fargo (United States)",
  "West Farmington (United States)",
  "West Finley (United States)",
  "West Fork (United States)",
  "West Frankfort (United States)",
  "West Friendship (United States)",
  "West Glover (United States)",
  "West Granby (United States)",
  "West Green (United States)",
  "West Greenwich (United States)",
  "West Groton (United States)",
  "West Grove (United States)",
  "West Hamlin (United States)",
  "West Harrison (United States)",
  "West Hartford (United States)",
  "West Hartland (United States)",
  "West Harwich (United States)",
  "West Haven (United States)",
  "West Haverstraw (United States)",
  "West Helena (United States)",
  "West Hempstead (United States)",
  "West Henrietta (United States)",
  "West Hills (United States)",
  "West Hollywood (United States)",
  "West Islip (United States)",
  "West Jefferson (United States)",
  "West Jordan (United States)",
  "West Kingston (United States)",
  "West Lafayette (United States)",
  "West Lebanon (United States)",
  "West Leyden (United States)",
  "West Liberty (United States)",
  "West Linn (United States)",
  "West Long Branch (United States)",
  "West Louisville (United States)",
  "West Manchester (United States)",
  "West Mansfield (United States)",
  "West McLean (United States)",
  "West Memphis (United States)",
  "West Middlesex (United States)",
  "West Middleton (United States)",
  "West Mifflin (United States)",
  "West Milford (United States)",
  "West Milton (United States)",
  "West Mineral (United States)",
  "West Minot (United States)",
  "West Monroe (United States)",
  "West New York (United States)",
  "West Newbury (United States)",
  "West Newton (United States)",
  "West Nyack (United States)",
  "West Olive (United States)",
  "West Orange (United States)",
  "West Paducah (United States)",
  "West Palm Beach (United States)",
  "West Paris (United States)",
  "West Park (United States)",
  "West Pawlet (United States)",
  "West Pittsburg (United States)",
  "West Plains (United States)",
  "West Point (United States)",
  "West Portsmouth (United States)",
  "West Richland (United States)",
  "West River (United States)",
  "West Rockport (United States)",
  "West Roxbury (United States)",
  "West Rutland (United States)",
  "West Sacramento (United States)",
  "West Salem (United States)",
  "West Sand Lake (United States)",
  "West Sayville (United States)",
  "West Shokan (United States)",
  "West Simsbury (United States)",
  "West Springfield (United States)",
  "West Stockbridge (United States)",
  "West Stockholm (United States)",
  "West Suffield (United States)",
  "West Sunbury (United States)",
  "West Swanzey (United States)",
  "West Terre Haute (United States)",
  "West Tisbury (United States)",
  "West Topsham (United States)",
  "West Townsend (United States)",
  "West Union (United States)",
  "West Unity (United States)",
  "West Valley (United States)",
  "West Van Lear (United States)",
  "West Wardsboro (United States)",
  "West Wareham (United States)",
  "West Warwick (United States)",
  "West Wendover (United States)",
  "West Winfield (United States)",
  "West Yarmouth (United States)",
  "West Yellowstone (United States)",
  "Westboro (United States)",
  "Westborough (United States)",
  "Westbrook (United States)",
  "Westbrookville (United States)",
  "Westbury (United States)",
  "Westby (United States)",
  "Westchester (United States)",
  "Westcliffe (United States)",
  "Westerlo (United States)",
  "Westerly (United States)",
  "Western Grove (United States)",
  "Western Springs (United States)",
  "Westernport (United States)",
  "Westernville (United States)",
  "Westerville (United States)",
  "Westfield (United States)",
  "Westfield Center (United States)",
  "Westford (United States)",
  "Westgate (United States)",
  "Westhampton (United States)",
  "Westhampton Beach (United States)",
  "Westhope (United States)",
  "Westlake (United States)",
  "Westlake Village (United States)",
  "Westland (United States)",
  "Westley (United States)",
  "Westminster (United States)",
  "Westmont (United States)",
  "Westmoreland (United States)",
  "Westmoreland City (United States)",
  "Weston (United States)",
  "Westover (United States)",
  "Westphalia (United States)",
  "Westpoint (United States)",
  "Westport (United States)",
  "Westside (United States)",
  "Westtown (United States)",
  "Westview (United States)",
  "Westville (United States)",
  "Westwego (United States)",
  "Westwood (United States)",
  "Wethersfield (United States)",
  "Wetmore (United States)",
  "Wetumka (United States)",
  "Wetumpka (United States)",
  "Wewahitchka (United States)",
  "Wewoka (United States)",
  "Wexford (United States)",
  "Weyauwega (United States)",
  "Weyerhaeuser (United States)",
  "Weyers Cave (United States)",
  "Weymouth (United States)",
  "Whaleyville (United States)",
  "Wharncliffe (United States)",
  "Wharton (United States)",
  "What Cheer (United States)",
  "Whately (United States)",
  "Whatley (United States)",
  "Wheat Ridge (United States)",
  "Wheatfield (United States)",
  "Wheatland (United States)",
  "Wheaton (United States)",
  "Wheeler (United States)",
  "Wheelersburg (United States)",
  "Wheeling (United States)",
  "Wheelwright (United States)",
  "Whigham (United States)",
  "Whippany (United States)",
  "Whipple (United States)",
  "Whitakers (United States)",
  "White (United States)",
  "White Bear Lake (United States)",
  "White Bird (United States)",
  "White Bluff (United States)",
  "White Castle (United States)",
  "White City (United States)",
  "White Cloud (United States)",
  "White Deer (United States)",
  "White Hall (United States)",
  "White Haven (United States)",
  "White Heath (United States)",
  "White House (United States)",
  "White Lake (United States)",
  "White Marsh (United States)",
  "White Mountain Lake (United States)",
  "White Oak (United States)",
  "White Pigeon (United States)",
  "White Pine (United States)",
  "White Plains (United States)",
  "White Post (United States)",
  "White River Junction (United States)",
  "White Salmon (United States)",
  "White Sands (United States)",
  "White Springs (United States)",
  "White Stone (United States)",
  "White Sulphur Springs (United States)",
  "White Swan (United States)",
  "White Water (United States)",
  "Whiteface (United States)",
  "Whitefield (United States)",
  "Whitefish (United States)",
  "Whiteford (United States)",
  "Whitehall (United States)",
  "Whitehall Township (United States)",
  "Whitehouse (United States)",
  "Whitehouse Station (United States)",
  "Whiteland (United States)",
  "Whiteman Air Force Base (United States)",
  "Whiteriver (United States)",
  "Whites Creek (United States)",
  "Whitesboro (United States)",
  "Whitesburg (United States)",
  "Whiteside (United States)",
  "Whitestone (United States)",
  "Whitestown (United States)",
  "Whitesville (United States)",
  "Whitethorn (United States)",
  "Whiteville (United States)",
  "Whitewater (United States)",
  "Whitewright (United States)",
  "Whiting (United States)",
  "Whitingham (United States)",
  "Whitinsville (United States)",
  "Whitley City (United States)",
  "Whitman (United States)",
  "Whitmire (United States)",
  "Whitmore (United States)",
  "Whitmore Lake (United States)",
  "Whitney (United States)",
  "Whitney Point (United States)",
  "Whitsett (United States)",
  "Whittemore (United States)",
  "Whittier (United States)",
  "Whittington (United States)",
  "Whitwell (United States)",
  "Wibaux (United States)",
  "Wichita (United States)",
  "Wichita Falls (United States)",
  "Wickenburg (United States)",
  "Wickes (United States)",
  "Wickliffe (United States)",
  "Wiconisco (United States)",
  "Widener (United States)",
  "Wiergate (United States)",
  "Wiggins (United States)",
  "Wilber (United States)",
  "Wilberforce (United States)",
  "Wilbraham (United States)",
  "Wilbur (United States)",
  "Wilburton (United States)",
  "Wilcox (United States)",
  "Wild Rose (United States)",
  "Wilder (United States)",
  "Wilderville (United States)",
  "Wildomar (United States)",
  "Wildorado (United States)",
  "Wildwood (United States)",
  "Wiley (United States)",
  "Wilkes Barre (United States)",
  "Wilkesboro (United States)",
  "Wilkinson (United States)",
  "Willacoochee (United States)",
  "Willamina (United States)",
  "Willapa (United States)",
  "Willard (United States)",
  "Willcox (United States)",
  "Willet (United States)",
  "Williams (United States)",
  "Williams Bay (United States)",
  "Williamsburg (United States)",
  "Williamsfield (United States)",
  "Williamson (United States)",
  "Williamsport (United States)",
  "Williamston (United States)",
  "Williamstown (United States)",
  "Williamsville (United States)",
  "Willimantic (United States)",
  "Willingboro (United States)",
  "Willis (United States)",
  "Willis Wharf (United States)",
  "Willisburg (United States)",
  "Williston (United States)",
  "Williston Park (United States)",
  "Willisville (United States)",
  "Willits (United States)",
  "Willmar (United States)",
  "Willoughby (United States)",
  "Willow Creek (United States)",
  "Willow Grove (United States)",
  "Willow Hill (United States)",
  "Willow Lake (United States)",
  "Willow River (United States)",
  "Willow Springs (United States)",
  "Willow Street (United States)",
  "Willowbrook (United States)",
  "Willows (United States)",
  "Wills Point (United States)",
  "Willsboro (United States)",
  "Willseyville (United States)",
  "Willshire (United States)",
  "Wilmar (United States)",
  "Wilmer (United States)",
  "Wilmerding (United States)",
  "Wilmette (United States)",
  "Wilmington (United States)",
  "Wilmont (United States)",
  "Wilmore (United States)",
  "Wilmot (United States)",
  "Wilsall (United States)",
  "Wilsey (United States)",
  "Wilseyville (United States)",
  "Wilson (United States)",
  "Wilsonville (United States)",
  "Wilton (United States)",
  "Wilton Manors (United States)",
  "Wimauma (United States)",
  "Wimberley (United States)",
  "Wimbledon (United States)",
  "Winamac (United States)",
  "Winchendon (United States)",
  "Winchester (United States)",
  "Winchester Center (United States)",
  "Wind Gap (United States)",
  "Wind Ridge (United States)",
  "Windber (United States)",
  "Winder (United States)",
  "Windermere (United States)",
  "Windfall (United States)",
  "Windham (United States)",
  "Windham Center (United States)",
  "Windom (United States)",
  "Window Rock (United States)",
  "Windsor (United States)",
  "Windsor Heights (United States)",
  "Windsor Locks (United States)",
  "Windsor Mill (United States)",
  "Windthorst (United States)",
  "Winesburg (United States)",
  "Winfield (United States)",
  "Wing (United States)",
  "Wingate (United States)",
  "Wingdale (United States)",
  "Winger (United States)",
  "Wingina (United States)",
  "Wingo (United States)",
  "Winifrede (United States)",
  "Wink (United States)",
  "Winlock (United States)",
  "Winnebago (United States)",
  "Winneconne (United States)",
  "Winnemucca (United States)",
  "Winner (United States)",
  "Winnetka (United States)",
  "Winnett (United States)",
  "Winnfield (United States)",
  "Winnie (United States)",
  "Winnsboro (United States)",
  "Winona (United States)",
  "Winona Lake (United States)",
  "Winooski (United States)",
  "Winside (United States)",
  "Winslow (United States)",
  "Winslow Township (United States)",
  "Winsted (United States)",
  "Winston (United States)",
  "Winston-Salem (United States)",
  "Winter (United States)",
  "Winter Garden (United States)",
  "Winter Harbor (United States)",
  "Winter Haven (United States)",
  "Winter Park (United States)",
  "Winter Springs (United States)",
  "Winterhaven (United States)",
  "Winterport (United States)",
  "Winters (United States)",
  "Winterset (United States)",
  "Winterthur (United States)",
  "Winterville (United States)",
  "Winthrop (United States)",
  "Winthrop Harbor (United States)",
  "Winton (United States)",
  "Wiota (United States)",
  "Wirtz (United States)",
  "Wiscasset (United States)",
  "Wisconsin Dells (United States)",
  "Wisconsin Rapids (United States)",
  "Wisdom (United States)",
  "Wise (United States)",
  "Wishek (United States)",
  "Wisner (United States)",
  "Wister (United States)",
  "Withams (United States)",
  "Withee (United States)",
  "Witt (United States)",
  "Witten (United States)",
  "Wittenberg (United States)",
  "Wittensville (United States)",
  "Wittmann (United States)",
  "Wixom (United States)",
  "Woburn (United States)",
  "Woden (United States)",
  "Wofford Heights (United States)",
  "Wolbach (United States)",
  "Wolcott (United States)",
  "Wolcottville (United States)",
  "Wolf Point (United States)",
  "Wolfe City (United States)",
  "Wolfeboro (United States)",
  "Wolfeboro Falls (United States)",
  "Wolfforth (United States)",
  "Wolford (United States)",
  "Wolverton (United States)",
  "Womelsdorf (United States)",
  "Wonder Lake (United States)",
  "Wonewoc (United States)",
  "Wood Dale (United States)",
  "Wood Lake (United States)",
  "Wood River (United States)",
  "Wood River Junction (United States)",
  "Wood-Ridge (United States)",
  "Woodacre (United States)",
  "Woodberry Forest (United States)",
  "Woodbine (United States)",
  "Woodbourne (United States)",
  "Woodbridge (United States)",
  "Woodburn (United States)",
  "Woodbury (United States)",
  "Woodbury Heights (United States)",
  "Woodcliff Lake (United States)",
  "Woodford (United States)",
  "Woodgate (United States)",
  "Woodhaven (United States)",
  "Woodhull (United States)",
  "Woodinville (United States)",
  "Woodlake (United States)",
  "Woodland (United States)",
  "Woodland Hills (United States)",
  "Woodland Mills (United States)",
  "Woodland Park (United States)",
  "Woodlawn (United States)",
  "Woodleaf (United States)",
  "Woodlyn (United States)",
  "Woodmere (United States)",
  "Woodridge (United States)",
  "Woodruff (United States)",
  "Woods Cross (United States)",
  "Woods Hole (United States)",
  "Woodsboro (United States)",
  "Woodsfield (United States)",
  "Woodside (United States)",
  "Woodson (United States)",
  "Woodstock (United States)",
  "Woodstown (United States)",
  "Woodsville (United States)",
  "Woodville (United States)",
  "Woodward (United States)",
  "Woodway (United States)",
  "Woodworth (United States)",
  "Woolstock (United States)",
  "Woolwich (United States)",
  "Woonsocket (United States)",
  "Wooster (United States)",
  "Wooton (United States)",
  "Worcester (United States)",
  "Worden (United States)",
  "Worland (United States)",
  "Worley (United States)",
  "Worth (United States)",
  "Wortham (United States)",
  "Worthing (United States)",
  "Worthington (United States)",
  "Worthington Center (United States)",
  "Worthington Springs (United States)",
  "Worthville (United States)",
  "Worton (United States)",
  "Wray (United States)",
  "Wrens (United States)",
  "Wrenshall (United States)",
  "Wrentham (United States)",
  "Wright (United States)",
  "Wright City (United States)",
  "Wrightstown (United States)",
  "Wrightsville (United States)",
  "Wrightsville Beach (United States)",
  "Wrightwood (United States)",
  "Wurtsboro (United States)",
  "Wyaconda (United States)",
  "Wyalusing (United States)",
  "Wyandanch (United States)",
  "Wyandotte (United States)",
  "Wyanet (United States)",
  "Wyckoff (United States)",
  "Wyco (United States)",
  "Wye Mills (United States)",
  "Wykoff (United States)",
  "Wylie (United States)",
  "Wylliesburg (United States)",
  "Wymore (United States)",
  "Wynantskill (United States)",
  "Wyncote (United States)",
  "Wyndmere (United States)",
  "Wynne (United States)",
  "Wynnewood (United States)",
  "Wynona (United States)",
  "Wynot (United States)",
  "Wyocena (United States)",
  "Wyoming (United States)",
  "Wysox (United States)",
  "Wytheville (United States)",
  "Xenia (United States)",
  "Yachats (United States)",
  "Yacolt (United States)",
  "Yadkinville (United States)",
  "Yakima (United States)",
  "Yalaha (United States)",
  "Yale (United States)",
  "Yamhill (United States)",
  "Yampa (United States)",
  "Yanceyville (United States)",
  "Yankeetown (United States)",
  "Yankton (United States)",
  "Yantis (United States)",
  "Yaphank (United States)",
  "Yarmouth (United States)",
  "Yarmouth Port (United States)",
  "Yates Center (United States)",
  "Yates City (United States)",
  "Yatesboro (United States)",
  "Yatesville (United States)",
  "Yazoo City (United States)",
  "Yeaddiss (United States)",
  "Yellow Spring (United States)",
  "Yellow Springs (United States)",
  "Yellville (United States)",
  "Yelm (United States)",
  "Yemassee (United States)",
  "Yerington (United States)",
  "Yermo (United States)",
  "Yoakum (United States)",
  "Yoder (United States)",
  "Yolyn (United States)",
  "Yoncalla (United States)",
  "Yonkers (United States)",
  "Yorba Linda (United States)",
  "York (United States)",
  "York Beach (United States)",
  "York Corner (United States)",
  "York Harbor (United States)",
  "York Haven (United States)",
  "York Springs (United States)",
  "Yorkshire (United States)",
  "Yorktown (United States)",
  "Yorktown Heights (United States)",
  "Yorkville (United States)",
  "Yosemite Valley (United States)",
  "Young (United States)",
  "Young America (United States)",
  "Young Harris (United States)",
  "Youngstown (United States)",
  "Youngsville (United States)",
  "Youngtown (United States)",
  "Youngwood (United States)",
  "Yountville (United States)",
  "Ypsilanti (United States)",
  "Yreka (United States)",
  "Yuba City (United States)",
  "Yucaipa (United States)",
  "Yucca (United States)",
  "Yucca Valley (United States)",
  "Yukon (United States)",
  "Yulan (United States)",
  "Yulee (United States)",
  "Yuma (United States)",
  "Yutan (United States)",
  "Zachary (United States)",
  "Zaleski (United States)",
  "Zalma (United States)",
  "Zanesfield (United States)",
  "Zanesville (United States)",
  "Zapata (United States)",
  "Zarephath (United States)",
  "Zavalla (United States)",
  "Zearing (United States)",
  "Zebulon (United States)",
  "Zeeland (United States)",
  "Zeigler (United States)",
  "Zelienople (United States)",
  "Zellwood (United States)",
  "Zenda (United States)",
  "Zephyr (United States)",
  "Zephyr Cove (United States)",
  "Zephyrhills (United States)",
  "Zieglersville (United States)",
  "Zillah (United States)",
  "Zimmerman (United States)",
  "Zion (United States)",
  "Zion Grove (United States)",
  "Zionhill (United States)",
  "Zionsville (United States)",
  "Zionville (United States)",
  "Zirconia (United States)",
  "Zolfo Springs (United States)",
  "Zortman (United States)",
  "Zullinger (United States)",
  "Zumbrota (United States)",
  "Zuni (United States)",
  "Zurich (United States)",
  "Zwingle (United States)",
  "Uruguay",
  "Barra de Carrasco (Uruguay)",
  "Canelones (Uruguay)",
  "Colonia del Sacramento (Uruguay)",
  "Durazno (Uruguay)",
  "Florida (Uruguay)",
  "La Floresta (Uruguay)",
  "La Paz (Uruguay)",
  "Las Piedras (Uruguay)",
  "Maldonado (Uruguay)",
  "Mercedes (Uruguay)",
  "Montevideo (Uruguay)",
  "Punta del Este (Uruguay)",
  "Salto (Uruguay)",
  "San Carlos (Uruguay)",
  "Toledo (Uruguay)",
  "Union (Uruguay)",
  "Venezuela",
  "Acarigua (Venezuela)",
  "Anaco (Venezuela)",
  "Araure (Venezuela)",
  "Bachaquero (Venezuela)",
  "Barcelona (Venezuela)",
  "Barinas (Venezuela)",
  "Barquisimeto (Venezuela)",
  "Bejuma (Venezuela)",
  "Bolivar (Venezuela)",
  "Cabimas (Venezuela)",
  "Cabudare (Venezuela)",
  "Cagua (Venezuela)",
  "Caja de Agua (Venezuela)",
  "Calabozo (Venezuela)",
  "Campo de Carabobo (Venezuela)",
  "Caraballeda (Venezuela)",
  "Caracas (Venezuela)",
  "Caripe (Venezuela)",
  "Carrizal (Venezuela)",
  "Catia La Mar (Venezuela)",
  "Charallave (Venezuela)",
  "Chivacoa (Venezuela)",
  "Ciudad Guayana (Venezuela)",
  "Ciudad Ojeda (Venezuela)",
  "Ciudad Piar (Venezuela)",
  "Colonia Tovar (Venezuela)",
  "Coro (Venezuela)",
  "Cua (Venezuela)",
  "Curazaito (Venezuela)",
  "El Chorro (Venezuela)",
  "El Recreo (Venezuela)",
  "El Roque (Venezuela)",
  "El Rosal (Venezuela)",
  "El Tigre (Venezuela)",
  "El Vigia (Venezuela)",
  "Guachara (Venezuela)",
  "Guanare (Venezuela)",
  "Guarenas (Venezuela)",
  "Guatire (Venezuela)",
  "Guayana (Venezuela)",
  "Gueiria (Venezuela)",
  "La Castellana (Venezuela)",
  "La Cruz (Venezuela)",
  "La Guaira (Venezuela)",
  "La Urbina (Venezuela)",
  "La Verdad (Venezuela)",
  "La Victoria (Venezuela)",
  "La Villa del Rosario (Venezuela)",
  "Londres (Venezuela)",
  "Los Chorros (Venezuela)",
  "Los Teques (Venezuela)",
  "Macuto (Venezuela)",
  "Maiquetia (Venezuela)",
  "Maracaibo (Venezuela)",
  "Maracay (Venezuela)",
  "Maria (Venezuela)",
  "Mariguitar (Venezuela)",
  "Matanzas (Venezuela)",
  "Miranda (Venezuela)",
  "Monte Cristo (Venezuela)",
  "Naguanagua (Venezuela)",
  "Ocumare (Venezuela)",
  "Pampatar (Venezuela)",
  "Porlamar (Venezuela)",
  "Puerto Cabello (Venezuela)",
  "Puerto La Cruz (Venezuela)",
  "Puerto Ordaz and San Felix (Venezuela)",
  "Punto Fijo (Venezuela)",
  "Sabaneta (Venezuela)",
  "San Antonio (Venezuela)",
  "San Antonio de Los Altos (Venezuela)",
  "San Diego (Venezuela)",
  "San Felipe (Venezuela)",
  "San Felix (Venezuela)",
  "San Francisco (Venezuela)",
  "San Joaquin (Venezuela)",
  "San Jose de Guanipa (Venezuela)",
  "Santa Cruz del Zulia (Venezuela)",
  "Santa Fe (Venezuela)",
  "Santa Teresa (Venezuela)",
  "Solano (Venezuela)",
  "Tariba (Venezuela)",
  "Torre (Venezuela)",
  "Trujillo (Venezuela)",
  "Tucupita (Venezuela)",
  "Turmero (Venezuela)",
  "Valencia (Venezuela)",
  "Valera (Venezuela)",
  "Valle (Venezuela)",
  "Vargas (Venezuela)",
  "Vietnam",
  "An Dinh (Vietnam)",
  "An Giang (Vietnam)",
  "An Nhon (Vietnam)",
  "An Tam (Vietnam)",
  "An Thanh (Vietnam)",
  "Ap Sai Gon (Vietnam)",
  "Ap Trung (Vietnam)",
  "Bac Giang (Vietnam)",
  "Bac Kan (Vietnam)",
  "Bac Ninh (Vietnam)",
  "Bach Ma (Vietnam)",
  "Bang (Vietnam)",
  "Ben Nghe (Vietnam)",
  "Ben Than (Vietnam)",
  "Bien Hoa (Vietnam)",
  "Binh Phuoc (Vietnam)",
  "Binh Thanh (Vietnam)",
  "Binh Thoai (Vietnam)",
  "Buon Ma Thuot (Vietnam)",
  "Ca Mau (Vietnam)",
  "Cam Pha (Vietnam)",
  "Can Tho (Vietnam)",
  "Cho Ha (Vietnam)",
  "Chuong Duong (Vietnam)",
  "Co Giang (Vietnam)",
  "Da Lat (Vietnam)",
  "Da Nang (Vietnam)",
  "Dac Loc (Vietnam)",
  "Di An (Vietnam)",
  "Dien Bien Phu (Vietnam)",
  "Dinh Long (Vietnam)",
  "Dong (Vietnam)",
  "Dong Nai (Vietnam)",
  "Dong Thap (Vietnam)",
  "Duc Thang (Vietnam)",
  "Giong Ngai (Vietnam)",
  "Giong Truong (Vietnam)",
  "Go Vap (Vietnam)",
  "Ha Dong (Vietnam)",
  "Ha Long (Vietnam)",
  "Ha Nam (Vietnam)",
  "Ha Tinh (Vietnam)",
  "Haiphong (Vietnam)",
  "Hanoi (Vietnam)",
  "Ho Chi Minh City (Vietnam)",
  "Hoang Mai (Vietnam)",
  "Hung Yen (Vietnam)",
  "Huong Tinh (Vietnam)",
  "Khanh Hoa (Vietnam)",
  "Kon Tum (Vietnam)",
  "Lang Dung (Vietnam)",
  "Lang Hong (Vietnam)",
  "Lang Son (Vietnam)",
  "Lao Cai (Vietnam)",
  "Le Loi (Vietnam)",
  "Long An (Vietnam)",
  "Long Tan (Vietnam)",
  "Long Van (Vietnam)",
  "Luong Hoi (Vietnam)",
  "Minh Khai (Vietnam)",
  "Nghi Loc (Vietnam)",
  "Nha Be (Vietnam)",
  "Nha Trang (Vietnam)",
  "Phan Thon (Vietnam)",
  "Phu Nhuan (Vietnam)",
  "Phu Tho (Vietnam)",
  "Phu Xuyen (Vietnam)",
  "Phu Yen (Vietnam)",
  "Phuong Tan (Vietnam)",
  "Pleiku (Vietnam)",
  "Quan Ngoc (Vietnam)",
  "Quan Trang (Vietnam)",
  "Quang Nam (Vietnam)",
  "Quang Tri (Vietnam)",
  "Quang Trung (Vietnam)",
  "Qui Nhon (Vietnam)",
  "Soc Trang (Vietnam)",
  "Tam Ky (Vietnam)",
  "Tan Binh (Vietnam)",
  "Tan Thuan Dong (Vietnam)",
  "Tang (Vietnam)",
  "Tay Ninh (Vietnam)",
  "Thach That (Vietnam)",
  "Thanh Nguyen (Vietnam)",
  "Thanh Pho Ha Giang (Vietnam)",
  "Thanh Pho Hai Duong (Vietnam)",
  "Thanh Pho Hoa Binh (Vietnam)",
  "Thanh Pho Ninh Binh (Vietnam)",
  "Thanh Pho Thai Binh (Vietnam)",
  "Thanh Pho Thai Nguyen (Vietnam)",
  "Thanh Pho Tuyen Quang (Vietnam)",
  "Thon Bao An (Vietnam)",
  "Thong Nhat (Vietnam)",
  "Thu Dau Mot (Vietnam)",
  "Thu Duc (Vietnam)",
  "Thuan An (Vietnam)",
  "Thuan Cong (Vietnam)",
  "Tra Vinh (Vietnam)",
  "Trao (Vietnam)",
  "Truong Lam (Vietnam)",
  "Truong Son (Vietnam)",
  "Van Hai (Vietnam)",
  "Van Tan (Vietnam)",
  "Vi Thanh (Vietnam)",
  "Vinh (Vietnam)",
  "Vinh Long (Vietnam)",
  "Vinh Yen (Vietnam)",
  "Xom Phuoc My (Vietnam)",
  "Xom Thang (Vietnam)",
  "Xom Truong Dinh (Vietnam)",
  "Yen Bai (Vietnam)",
  "Yen Thanh (Vietnam)",
  "Zambia",
  "Kalomo (Zambia)",
  "Kitwe (Zambia)",
  "Livingstone (Zambia)",
  "Lusaka (Zambia)",
  "Macha (Zambia)",
  "Mumbwa (Zambia)",
  "Ndola (Zambia)",
  "Siavonga (Zambia)",
  "Solwezi (Zambia)",
  "Zimbabwe",
  "Bulawayo (Zimbabwe)",
  "Chinhoyi (Zimbabwe)",
  "Greendale (Zimbabwe)",
  "Gwanda (Zimbabwe)",
  "Harare (Zimbabwe)",
  "Kwekwe (Zimbabwe)",
  "Mufakose (Zimbabwe)",
  "Mutare (Zimbabwe)",
  "Victoria Falls (Zimbabwe)",
];
